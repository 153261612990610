<ng-container *ngIf="isRangeTrend!=2">
  <div id="edit-threshold" class="c-modal c-edit-threshold" tabindex="-1" aria-labelledby="modalDialogTitle" aria-modal="true" role="dialog">
    <div class="c-modal__overlay -dismiss-modal"></div>
    <div class="c-modal__container -size-medium">
      <div class="c-modal__content">
        <div class="c-modal__header">
          <h6 id="modalDialogTitle">Edit Metrics Threshold</h6>
          <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close" alt="Close" (click)="onCancelClick(1)"></button>
        </div>
        <div class="c-modal__body">
          <div *ngIf="isError" class="alert alert-danger" style="color: red;">
            <strong>Error! </strong>{{Error}}
          </div>
          <div class="c-edit-threshold-metric">
            <div>Metric(s) Selected:</div>
            <div class="c-edit-threshold-selected">
              <ul class="c-edit-threshold-list -customScroll">
                <li *ngFor="let threshold of allSelectedArray">
                  {{threshold.Metric.Name}}  <span class="floatNone" *ngIf="threshold.Metric.Abbreviation">
                    ({{threshold.Metric.Abbreviation}})
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="c-myWizard-form__input">
            <div class="c-myWizard-form__input-area">
              <div class="o-input  -disabled    ">
                <label for="type">Threshold Type:</label>
                <input type="text" [disabled]="accessFlag" 
                       class="o-input__input"
                       placeholder=""
                       [value]="isRangeTrend==1?'Range':isRangeTrend==4?'Relative Trend':''"
                       id="type"
                       disabled />
              </div>
            </div>
            <div class="c-myWizard-form__input-area">
              <div class="c-select-dropdown   ">
                <label for="trend">Select Trend Direction</label>
                <search-dropdown id="trendDirection"
                                 name="trendDirection"
                                 [labelInfo]="'trendDirection'"
                                 [options]="trendDirectionDropDown"
                                 [isShow]="true"
                                 [required]="false"
                                 [selectText]="'Select'"
                                 [selected]="selectedTrendId"
                                 (callback)="chnageDirection()"
                                 [disableDropdown]="accessFlag"
                                 [(ngModel)]="selectedTrendId">
                </search-dropdown>

              </div>
            </div>
            <div class="c-myWizard-form__input-area" *ngIf="IsTrackByPhasePanel">
              <div class="c-checkbox   ">
                <input type="checkbox" id="check-one" class="c-checkbox__input" name="unitcheckbox" checked="" [disabled]="!this.isGridDisabled || accessFlag" [(ngModel)]="IsTrackByPhase" (change)="isPhaseChecked()">
                <i class="c-checkbox__checked"></i>
                <label for="check-one" class="c-checkbox__label">
                  Track by Phase(Recommended)
                </label>
              </div>
            </div>
          </div>

          <ng-container *ngIf="!IsTrackByPhase">
            <div class="c-table-legend" [ngStyle]="{'display':rowUp?'none':''}">
              <div class="c-table-legend-item">
                <i style="background-color: #FF3246;"></i>
                <span>&lt;</span>
                <div class="o-input">
                  <input type="text" *ngIf="!isTimeSpanUint"
                         class="o-input__input"
                         [ngClass]="(((tvalUp1 == '' || tvalUp1 == null) && IsStandMetricNull) || tvalup1Error) ? 'border_red':''"
                         name="tvalUp1"
                         maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/"
                         step="0.0001"
                         [(ngModel)]="tvalUp1"
                         [disabled]="accessFlag"
                         (keypress)="validateNumbersWithDecimal($event,$event.target.value,'tvalUp1')" />

                  <input type="text" [disabled]="accessFlag" 
                         *ngIf="isTimeSpanUint"
                         class="o-input__input datePlaceholder"
                         [ngClass]="(((tvalUp1 == '' || tvalUp1 == null) && IsStandMetricNull) || tvalup1Error) ? 'border_red':''"
                         name="tvalUp1TymSpan" maxlength="8" [(ngModel)]="tvalUp1" (blur)="validateTimespanValue($event.target.value,'tvalUp1TymSpan')"
                         [placeholder]="(isTimeSpanUint==true)?'hh:mm:ss':''" />

                </div>
              </div>
              <div class="c-table-legend-item">
                <i style="background-color: #FF7800;"></i>
                <span>&gt; = &lt; </span>
                <div class="o-input ">
                  <input type="text" [disabled]="accessFlag" *ngIf="!isTimeSpanUint" class="o-input__input" [ngClass]="(((tvalUp2 == '' || tvalUp2 == null) && IsStandMetricNull) || tvalup2Error) ? 'form-control border_red':'form-control '" name="tvalUp2" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" [(ngModel)]="tvalUp2" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'tvalUp2')" />
                  <input type="text" [disabled]="accessFlag" *ngIf="isTimeSpanUint" class="o-input__input datePlaceholder" [ngClass]="(((tvalUp2 == '' || tvalUp2 == null) && IsStandMetricNull) || tvalup2Error) ? 'form-control border_red':'form-control '" name="tvalUp2TymSpan" maxlength="8" [(ngModel)]="tvalUp2" (blur)="validateTimespanValue($event.target.value,'tvalUp2TymSpan')" [placeholder]="(isTimeSpanUint==true)?'hh:mm:ss':''" />
                </div>
              </div>
              <div class="c-table-legend-item">
                <i style="background-color: #5AA700;"></i>
                <span>&gt;=</span>
              </div>
            </div>
            <div class="c-table-legend" [ngStyle]="{'display':rowDown?'none':''}" *ngIf="!mirflag">
              <div class="c-table-legend-item">
                <i style="background-color: #5AA700;"></i>
                <span>&lt;=</span>
                <div class="o-input    ">
                  <input type="text" [disabled]="accessFlag" *ngIf="!isTimeSpanUint" class="o-input__input" name="tvalDwn1" [ngClass]="(((tvalDwn1 == '' || tvalDwn1 == null) && IsStandMetricNull) || tvaldwn1Error) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" [(ngModel)]="tvalDwn1" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'tvalDwn1')" />
                  <input type="text" [disabled]="accessFlag" *ngIf="isTimeSpanUint" class="o-input__input datePlaceholder" name="tvalDwn1TymSpan" [ngClass]="(((tvalDwn1 == '' || tvalDwn1 == null) && IsStandMetricNull) || tvaldwn1Error) ? 'form-control border_red':'form-control '" maxlength="8" [(ngModel)]="tvalDwn1" (blur)="validateTimespanValue($event.target.value,'tvalDwn1TymSpan')" [placeholder]="(isTimeSpanUint==true)?'hh:mm:ss':''" />
                </div>
              </div>
              <div class="c-table-legend-item">
                <i style="background-color: #FF7800;"></i>
                <span>&gt; &lt;= </span>
                <div class="o-input ">
                  <input type="text" [disabled]="accessFlag" *ngIf="!isTimeSpanUint" class="o-input__input" name="tvalDwn2" [ngClass]="(((tvalDwn2 == '' || tvalDwn2 == null) && IsStandMetricNull) || tvaldwn2Error) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" [(ngModel)]="tvalDwn2" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'tvalDwn2')" />
                  <input type="text" [disabled]="accessFlag" *ngIf="isTimeSpanUint" class="o-input__input datePlaceholder" name="tvalDwn2TymSpan" [ngClass]="(((tvalDwn2 == '' || tvalDwn2 == null) && IsStandMetricNull) || tvaldwn2Error) ? 'form-control border_red':'form-control '" maxlength="8" [(ngModel)]="tvalDwn2" (blur)="validateTimespanValue($event.target.value,'tvalDwn2TymSpan')" [placeholder]="(isTimeSpanUint==true)?'hh:mm:ss':''" />
                </div>
              </div>
              <div class="c-table-legend-item">
                <i style="background-color: #FF3246;"></i>
                <span>&gt;</span>
              </div>
            </div>
            <div class="c-table-legend" [ngStyle]="{'display':rowDown?'none':''}" *ngIf="mirflag">
              <div class="c-table-legend-item">
                <i style="background-color: #5AA700;"></i>
                <span>=</span>
                <div class="o-input">
                  <input type="text" [disabled]="accessFlag" *ngIf="!isTimeSpanUint" class="o-input__input" name="tvalDwn1" [ngClass]="(((tvalDwn1 == '' || tvalDwn1 == null) && IsStandMetricNull) || tvaldwn1Error) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" [(ngModel)]="tvalDwn1" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'tvalDwn1')" />
                  <input type="text" [disabled]="accessFlag" *ngIf="isTimeSpanUint" class="o-input__input datePlaceholder" name="tvalDwn1TymSpan" [ngClass]="(((tvalDwn1 == '' || tvalDwn1 == null) && IsStandMetricNull) || tvaldwn1Error) ? 'form-control border_red':'form-control '" maxlength="8" [(ngModel)]="tvalDwn1" (blur)="validateTimespanValue($event.target.value,'tvalDwn1TymSpan')" [placeholder]="(isTimeSpanUint==true)?'hh:mm:ss':''" />
                </div>
              </div>
              <div class="c-table-legend-item">
                <i style="background-color: #FF7800;"></i>
                <span>&gt; &lt; </span>
                <div class="o-input ">
                  <input type="text" [disabled]="accessFlag" *ngIf="!isTimeSpanUint" class="o-input__input" name="tvalDwn2" [ngClass]="(((tvalDwn2 == '' || tvalDwn2 == null) && IsStandMetricNull) || tvaldwn2Error) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" [(ngModel)]="tvalDwn2" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'tvalDwn2')" />
                  <input type="text" [disabled]="accessFlag" *ngIf="isTimeSpanUint" class="o-input__input datePlaceholder" name="tvalDwn2TymSpan" [ngClass]="(((tvalDwn2 == '' || tvalDwn2 == null) && IsStandMetricNull) || tvaldwn2Error) ? 'form-control border_red':'form-control '" maxlength="8" [(ngModel)]="tvalDwn2" (blur)="validateTimespanValue($event.target.value,'tvalDwn2TymSpan')" [placeholder]="(isTimeSpanUint==true)?'hh:mm:ss':''" />
                </div>

              </div>
              <div class="c-table-legend-item">
                <i style="background-color: #FF3246;"></i>
                <span>&gt;=</span>
              </div>
            </div>
            <div class="c-table-legend" [ngStyle]="{'display':rowBidirectional?'none':''}">
              <div class="c-table-legend-item">
                <i style="background-color: #FF3246;"></i>
                <span>&lt;</span>
                <div class="o-input">
                  <input type="text" [disabled]="accessFlag" *ngIf="!isTimeSpanUint" class="o-input__input" name="tvalBi1" [ngClass]="(((tvalBi1 == '' || tvalBi1 == null) && IsStandMetricNull) || tvalbi1Error) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" [(ngModel)]="tvalBi1" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'tvalBi1')" />
                  <input type="text" [disabled]="accessFlag" *ngIf="isTimeSpanUint" class="o-input__input datePlaceholder" name="tvalBi1TymSpan" [ngClass]="(((tvalBi1 == '' || tvalBi1 == null) && IsStandMetricNull) || tvalbi1Error) ? 'form-control border_red':'form-control '" maxlength="8" [(ngModel)]="tvalBi1" (blur)="validateTimespanValue($event.target.value,'tvalBi1TymSpan')" [placeholder]="(isTimeSpanUint==true)?'hh:mm:ss':''" />
                </div>
              </div>
              <div class="c-table-legend-item">
                <i style="background-color: #FF7800;"></i>
                <span>&gt; = &lt; </span>
                <div class="o-input    ">
                  <input type="text" [disabled]="accessFlag"  *ngIf="!isTimeSpanUint" class="o-input__input" name="tvalBi2" [ngClass]="(((tvalBi2 == '' || tvalBi2 == null) && IsStandMetricNull) || tvalbi2Error) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" [(ngModel)]="tvalBi2" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'tvalBi2')" />
                  <input type="text" [disabled]="accessFlag"  *ngIf="isTimeSpanUint" class="o-input__input datePlaceholder" name="tvalBi2TymSpan" [ngClass]="(((tvalBi2 == '' || tvalBi2 == null) && IsStandMetricNull) || tvalbi2Error) ? 'form-control border_red':'form-control '" maxlength="8" [(ngModel)]="tvalBi2" (blur)="validateTimespanValue($event.target.value,'tvalBi2TymSpan')" [placeholder]="(isTimeSpanUint==true)?'hh:mm:ss':''" />
                </div>
              </div>
              <div class="c-table-legend-item">
                <i style="background-color: #5AA700;"></i>
                <span>&gt; = &lt; = </span>
                <div class="o-input    ">
                  <input type="text" [disabled]="accessFlag"  *ngIf="!isTimeSpanUint" class="o-input__input" name="tvalBi3" [ngClass]="(((tvalBi3 == '' || tvalBi3 == null) && IsStandMetricNull) || tvalbi3Error) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" [(ngModel)]="tvalBi3" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'tvalBi3')" />
                  <input type="text" [disabled]="accessFlag"  *ngIf="isTimeSpanUint" class="o-input__input datePlaceholder" name="tvalBi3TymSpan" [ngClass]="(((tvalBi3 == '' || tvalBi3 == null) && IsStandMetricNull) || tvalbi3Error) ? 'form-control border_red':'form-control '" maxlength="8" [(ngModel)]="tvalBi3" (blur)="validateTimespanValue($event.target.value,'tvalBi3TymSpan')" [placeholder]="(isTimeSpanUint==true)?'hh:mm:ss':''" />
                </div>
              </div>
              <div class="c-table-legend-item">
                <i style="background-color: #FF7800;"></i>
                <span>&gt; &lt; = </span>
                <div class="o-input    ">
                  <input type="text" [disabled]="accessFlag"  *ngIf="!isTimeSpanUint" class="o-input__input" name="tvalBi4" [ngClass]="(((tvalBi4 == '' || tvalBi4 == null) && IsStandMetricNull) || tvalbi4Error) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" [(ngModel)]="tvalBi4" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'tvalBi4')" />
                  <input type="text" [disabled]="accessFlag"  *ngIf="isTimeSpanUint" class="o-input__input datePlaceholder" name="tvalBi4TymSpan" [ngClass]="(((tvalBi4 == '' || tvalBi4 == null) && IsStandMetricNull) || tvalbi4Error) ? 'form-control border_red':'form-control '" maxlength="8" [(ngModel)]="tvalBi4" (blur)="validateTimespanValue($event.target.value,'tvalBi4TymSpan')" [placeholder]="(isTimeSpanUint==true)?'hh:mm:ss':''" />
                </div>
              </div>
              <div class="c-table-legend-item">
                <i style="background-color: #FF3246;"></i>
                <span>&gt;</span>
              </div>
            </div>
          </ng-container>

          <div *ngIf="isTimeSpanUint==true && timeSpanNote!=0">
            <small> Note: Please enter values in hh:mm:ss(24 hours) format</small>
          </div>
          <ng-container *ngIf="IsTrackByPhase">
            <div class="c-table-responsive">
              <table class="c-table">
                <tbody *ngFor="let threshold of allSelectedArray">
                  <tr [ngStyle]="{'display':rowUp?'none':''}" *ngIf="threshold.DGPhasesThreshold !=null">
                    <td scope="col">
                      <input type="checkbox" [(ngModel)]="threshold.IsAllPhaseSelected" (change)="onPhaseChange($event,threshold.Metric.Name)" class="c-checkbox__input" />
                      <i class="c-checkbox__checked"></i>
                      <label class="c-checkbox__label">Select All</label>
                    </td>
                    <td scope="col" colspan="3">{{threshold.Metric.Name}}</td>
                  </tr>
                  <ng-container *ngFor="let DGthreshold1 of threshold.DGPhasesThreshold">
                    <tr [ngStyle]="{'display':rowUp?'none':''}">
                      <td>
                        <div class="c-checkbox   ">
                          <input type="checkbox" [(ngModel)]="DGthreshold1.chkDGValue" (change)="ifAllPhaseSelected($event,threshold.Metric.Name)" class="c-checkbox__input" />
                          <i class="c-checkbox__checked"></i>
                          <label for="earnedval" class="c-checkbox__label">{{DGthreshold1._MetricThreshold.Metric.Stage}}</label>
                        </div>
                      </td>
                      <td>
                        <div class="c-table-legend">
                          <div class="c-table-legend-item">
                            <i style="background-color: #FF3246;"></i>
                            <span>&lt;</span>
                            <div class="o-input">
                              <input type="text" [disabled]="accessFlag"  class="o-input__input" [(ngModel)]="DGthreshold1._MetricThreshold.Threshold1" [ngClass]="(DGthreshold1.chkPhaseValue && DGthreshold1._MetricThreshold.IsValidThreshold1) ? 'form-control border_red':'form-control '" name="tvalUp1" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'DefectGrid')" />
                            </div>
                          </div>
                          <div class="c-table-legend-item">
                            <i style="background-color: #FF7800;"></i>
                            <span>&gt; = &lt; </span>
                            <div class="o-input    ">
                              <input type="text" [disabled]="accessFlag"  class="o-input__input" [(ngModel)]="DGthreshold1._MetricThreshold.Threshold2" [ngClass]="(DGthreshold1.chkPhaseValue && DGthreshold1._MetricThreshold.IsValidThreshold2) ? 'form-control border_red':'form-control '" name="tvalUp2" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'DefectGrid')" />
                            </div>

                          </div>
                          <div class="c-table-legend-item">
                            <i style="background-color: #5AA700;"></i>
                            <span>&gt;=</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>


                  <tr [ngStyle]="{'display':rowDown?'none':''}" *ngIf="threshold.DGPhasesThreshold !=null">
                    <td scope="col">
                      <div class="c-checkbox   ">
                        <input type="checkbox" [disabled]="accessFlag" [(ngModel)]="threshold.IsAllPhaseSelected" (change)="onPhaseChange($event,threshold.Metric.Name)" class="c-checkbox__input" />
                        <i class="c-checkbox__checked"></i>
                        <label class="c-checkbox__label">Select All</label>
                      </div>
                    </td>
                    <td scope="col" colspan="3">{{threshold.Metric.Name}}</td>
                  </tr>

                  <ng-container *ngFor="let DGthreshold1 of threshold.DGPhasesThreshold">
                    <tr [ngStyle]="{'display':rowDown?'none':''}">
                      <td>
                        <div class="c-checkbox   ">
                          <input type="checkbox" [disabled]="accessFlag" [(ngModel)]="DGthreshold1.chkDGValue" (change)="ifAllPhaseSelected($event,threshold.Metric.Name)" class="c-checkbox__input" />
                          <i class="c-checkbox__checked"></i>
                          <label for="earnedval" class="c-checkbox__label">{{DGthreshold1._MetricThreshold.Metric.Stage}}</label>
                        </div>
                      </td>
                      <td>
                        <div class="c-table-legend">
                          <div class="c-table-legend-item">
                            <i style="background-color: #5AA700;"></i>
                            <span>&lt;=</span>
                            <div class="o-input">
                              <input type="text" [disabled]="accessFlag"  class="o-input__input" name="tvalDwn1" [(ngModel)]="DGthreshold1._MetricThreshold.Threshold1" [ngClass]="(DGthreshold1.chkPhaseValue && DGthreshold1._MetricThreshold.IsValidThreshold1) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'DefectGrid')" />
                            </div>
                          </div>
                          <div class="c-table-legend-item">
                            <i style="background-color: #FF7800;"></i>
                            <span>&gt;&lt;= </span>
                            <div class="o-input    ">
                              <input type="text" [disabled]="accessFlag"  class="o-input__input" name="tvalDwn2" [(ngModel)]="DGthreshold1._MetricThreshold.Threshold2" [ngClass]="(DGthreshold1.chkPhaseValue && DGthreshold1._MetricThreshold.IsValidThreshold2) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'DefectGrid')" />
                            </div>

                          </div>
                          <div class="c-table-legend-item">
                            <i style="background-color: #FF3246;"></i>
                            <span>&gt;=</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>


                  <tr [ngStyle]="{'display':rowBidirectional?'none':''}" *ngIf="threshold.DGPhasesThreshold !=null">
                    <td scope="col">
                      <div class="c-checkbox   ">
                        <input type="checkbox" [disabled]="accessFlag" [(ngModel)]="threshold.IsAllPhaseSelected" (change)="onPhaseChange($event,threshold.Metric.Name)" class="c-checkbox__input" />
                        <i class="c-checkbox__checked"></i>
                        <label class="c-checkbox__label">Select All</label>
                      </div>
                    </td>
                    <td scope="col" colspan="3">{{threshold.Metric.Name}}</td>
                  </tr>

                  <ng-container *ngFor="let DGthreshold1 of threshold.DGPhasesThreshold">
                    <tr [ngStyle]="{'display':rowBidirectional?'none':''}">
                      <td>
                        <div class="c-checkbox   ">
                          <input type="checkbox"  [disabled]="accessFlag"[(ngModel)]="DGthreshold1.chkDGValue" (change)="ifAllPhaseSelected($event,threshold.Metric.Name)" class="c-checkbox__input" />
                          <i class="c-checkbox__checked"></i>
                          <label for="earnedval" class="c-checkbox__label">{{DGthreshold1._MetricThreshold.Metric.Stage}}</label>
                        </div>
                      </td>
                      <td>
                        <div class="c-table-legend">
                          <div class="c-table-legend-item">
                            <i style="background-color: #FF3246;"></i>
                            <span>&lt;</span>
                            <div class="o-input">
                              <input type="text" [disabled]="accessFlag"  class="o-input__input" name="tvalBi1" [(ngModel)]="DGthreshold1._MetricThreshold.Threshold1" [ngClass]="(DGthreshold1.chkPhaseValue && DGthreshold1._MetricThreshold.IsValidThreshold1) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'DefectGrid')" />
                            </div>
                          </div>
                          <div class="c-table-legend-item">
                            <i style="background-color: #FF7800;"></i>
                            <span>&gt; = &lt; </span>
                            <div class="o-input    ">
                              <input type="text" [disabled]="accessFlag"  class="o-input__input" name="tvalBi2" [(ngModel)]="DGthreshold1._MetricThreshold.Threshold2" [ngClass]="(DGthreshold1.chkPhaseValue && DGthreshold1._MetricThreshold.IsValidThreshold2) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'DefectGrid')" />
                            </div>
                          </div>
                          <div class="c-table-legend-item">
                            <i style="background-color: #5AA700;"></i>
                            <span>&gt; = &lt; = </span>
                            <div class="o-input    ">
                              <input type="text" [disabled]="accessFlag"  class="o-input__input" name="tvalBi3" [(ngModel)]="DGthreshold1._MetricThreshold.Threshold3" [ngClass]="(DGthreshold1.chkPhaseValue && DGthreshold1._MetricThreshold.IsValidThreshold3) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'DefectGrid')" />
                            </div>
                          </div>
                          <div class="c-table-legend-item">
                            <i style="background-color: #FF7800;"></i>
                            <span>&gt; &lt; = </span>
                            <div class="o-input    ">
                              <input type="text" [disabled]="accessFlag"  class="o-input__input" name="tvalBi4" [(ngModel)]="DGthreshold1._MetricThreshold.Threshold4" [ngClass]="(DGthreshold1.chkPhaseValue && DGthreshold1._MetricThreshold.IsValidThreshold4) ? 'form-control border_red':'form-control '" maxlength="21" pattern="/^\d{1,12}(\.\d{1,2})?$/" step="0.0001" (keypress)="validateNumbersWithDecimal($event,$event.target.value,'DefectGrid')" />
                            </div>
                          </div>
                          <div class="c-table-legend-item">
                            <i style="background-color: #FF3246;"></i>
                            <span>&gt;</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>

          <div class="c-edit-threshold__text">
            {{lastUpdated}}
          </div>
        </div>
        <div class="c-modal__footer">
          <div class="c-modal__footer-btnGroup">
            <!--add class -dismiss-modal to buttons that'll close the modal
          Primary Button starts from here-->
            <button class="o-secondary-button" role="button" [ngClass]="accessFlag?'-disabled':(btnRestore?'-disabled':'')" (click)="onRestoreDefaultClick()" tabindex="-1">
              Restore Default Configuration
            </button>
            <!--Primary Button ends from here     Primary Button starts from here-->
            <button role="button" class="o-primary-button  -dismiss-modal" [ngClass]="!accessFlag ?'':'-disabled'" (click)="onSaveThresholdClick()" tabindex="-1">
              Save
            </button>
            <!--Primary Button ends from here-->
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="isRangeTrend==2">
  <threshold-trend [selectedThrendData]="allSelectedArray" (_onRestore)="restoreConfig($event)"(_onCancelClick)="onCancelClick($event)" (_onSave)="CloseTrendPopup($event)" [changeTrigger]="changeTrigger"></threshold-trend>
</ng-container>
