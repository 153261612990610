import { Component, Input } from '@angular/core';
import { TileService } from '../../core/services/tile.service';
import { SessionStorage } from '../../core/context/sessionstorage';
import { UtilityService } from '../../core/services/utility.service';

@Component({
  selector: 'am-command-center',
  templateUrl: './am.command.center.component.html',
  providers: [UtilityService]
})

export class AmCommandCenterComponent {
  AMCriticalSLA: string;
  AMSLA: string;
  AMCriticalSLARagIndicator: string;
  AMSLARagIndicator: string;

  @Input()
  tile: any;
  constructor(private tileService: TileService, private session: SessionStorage,private utilityService: UtilityService) { }
  ngOnInit() {
    this.getAMCommandCenter();
  }
  getAMCommandCenter() {
    var dateSplit = this.session.statusAsOnPeriod.split('-');
    this.tileService.getCommandCenterMetrics(dateSplit[0], dateSplit[1], dateSplit[2], this.session.endToEndUId, null, null, 1).subscribe((response:any) => {

      if (response) {
        this.session.commandCenter = response;
        this.fillCommandCenter(response);
      }
      
      });
  }
  fillCommandCenter(response: any): void {
   
    let MetricValues = this.utilityService.commandCenter(response.AMCriticalSLA,response.AMSLA);
    
    this.AMCriticalSLA = MetricValues.FirstMetricValue;
    this.AMSLA = MetricValues.SecondMetricValue;
    this.AMCriticalSLARagIndicator = response.AMCriticalSLARagIndicator;
    this.AMSLARagIndicator = response.AMSLARagIndicator;
  }
}
