import { Component } from '@angular/core';
import { TileService } from '../core/services/tile.service';
import { SessionStorage } from '../core/context/sessionstorage';
import { UserPreferenceService } from '../core/services/user.preference.service';
import { UserService } from '../core/services/user.service';
import { ReleaseNoteService } from '../core/services/release.note.service';
import cloneDeep from 'lodash/cloneDeep';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ReleaseNoteHistoryComponent } from './release-note-history.component';
import { enumArea, ManageWorkTile } from '../core/context/enum';
import { NotificationsAlertsComponent } from '../welcome/notifications-alerts.component';

declare var window: any;
var $ = (<any>window).$;

@Component({
  templateUrl: './announcementdialog.component.html',
  selector: 'announcementModal',
  providers: [TileService, BsModalService]
})
export class AnnouncementdialogComponent {
  ReleaseNotes: any
  tiles: any;
  Releases: any;
  ReleaseNoteHistory: any;
  SelectedRelease: any;
  openSlide: boolean = true;// TODO: testing purpose make it as true
  ReleaseNote: any;
  ReleaseName: any;
  ReleaseDate: any;
  ReleaseNameHistory: any;
  ReleaseDateHistory: any;
  InitReleaseNote: any;
  InitReleaseNoteHistory: any;
  SelfSupportURL: any;
  IsAnnouncement: any;
  latestReleaseName: any;
  GlobalValue: any;
  Enhancement: any;
  ReleaseNotesPreferenceValue: any;
  CurrentReleaseName: any;
  CurrentReleaseDate: any;
  PreviousReleaseName: any;
  PreviousReleaseDate: any;
  IschangeCriticalText: string = "Critical! ";
  isCritical: boolean = null;
  releaseNotesPreferenceValue: boolean;
  prevReleaseNote: any = [];
  finalReleaseNote: any = [];
  IndexNumbersReleaseNote: any = [];
  IndexNumbersReleaseNoteHistory: any = [];
  modalRef_announcement: BsModalRef;
  showMandatoryAlert: any;
  modalRef_releaseNote: BsModalRef;

  constructor(
    private tileService: TileService,
    private releaseService: ReleaseNoteService,
    private sessionStorage: SessionStorage,
    private router: Router,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private userPreferenceService: UserPreferenceService,
    private userService: UserService) { }

  ngOnInit() {
   
    this.getReleaseNotes();
    
    setTimeout(function () {
      $("body").removeClass("modal-open");
    }, 0);

  }
  //LoadReleases() {
  //  this.closeSlide();
  //  this.modalRef_releaseNote = this.modalService.show(ReleaseNoteHistoryComponent, {
  //    backdrop: "static",
  //    keyboard: false
  //  });
  //  $(".modal-dialog").addClass('custom-modal-releasenote');
  //}
  //ViewLatestRelease() {
  //  this.router.navigate(['/mywizard/welcome/announcement'], { relativeTo: this.activatedRoute });
  //}


  LoadUserPreference() {
    this.userService.getReleaseNoteUserPreference(this.sessionStorage.enterpriseId).subscribe((response: any) => {
      this.ReleaseNotesPreferenceValue = response;
      if (this.GlobalValue != undefined) {
        this.openSlide = (this.GlobalValue == true && this.ReleaseNotesPreferenceValue == false) ? true : false;
      }
    }, function (error) {
      this.ReleaseNotesPreferenceValue = false;
      this.openSlide = true;
    });
  }

  //getTiles() {
  //  this.tileService.getTiles(enumArea.Manage)
  //    .subscribe((tiles: any) => {
  //      this.tiles = tiles;
  //      this.configureReleaseNotesAnnouncement();
  //      this.ShowUserNotificationPopup();
  //    });
  //}
  //configureReleaseNotesAnnouncement() {
  //  this.userService.getReleaseNoteUserPreference(this.sessionStorage.enterpriseId).subscribe((response: any) => {
  //    this.releaseNotesPreferenceValue = false //response;
  //    $('#mainContent').click(); // temporary fix for page not refreshed post token generation
  //    this.openSlide = (this.releaseNotesPreferenceValue == false) ? true : false;
  //    if (!this.releaseNotesPreferenceValue && !this.sessionStorage.isReleaseAnnouncementClosed)
        
  //      console.log(this.releaseNotesPreferenceValue);
  //  }, function (error) {
  //    this.releaseNotesPreferenceValue = false;
  //    this.openSlide = true;
  //  });
  //}
  ShowUserNotificationPopup() {
    this.initializeUserNotificationContent();
    setTimeout(function () {
      $('#navbar-menu').click(); // temporary fix for page not refreshed post token generation
    }, 2000);
  }
  initializeUserNotificationContent(): void {
    const content = {
      DataSourceUId: "00100000-0010-0000-0000-000000000000",
      TemplateUId: "00200000-0010-0000-0000-000000000000",
      ServiceUrl: '/assets/data/UserNotification.json',
      ActiveLanguage: sessionStorage.getItem('Culture'),
      LoggedInUserId: sessionStorage.getItem('enterpriseId')
    }
    // userNotification.init(content);
  }

  toggleSlide() {
    this.modalRef_announcement = this.modalService.show(AnnouncementdialogComponent, {
      backdrop: "static",
      keyboard: false
    });
    $(".modal-dialog").addClass('custom-modal-announcement');
  }

  OpenNotificationsPopUp() {
  
    const initialState = {
      loadAnnouncement : true
    };
    this.closeSlide();
    this.modalRef_announcement = this.modalService.show(NotificationsAlertsComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
    $(".modal-dialog").addClass('custom-modal-announcement');
    
  }

  getReleaseNotes() {
    this.tileService.getReleaseNotes(false,false).subscribe((response: any) => {
      var releaseNote = response.ReleaseNotes[0];
      if (releaseNote) { 
        this.ReleaseName = releaseNote.ReleaseName;
        this.ReleaseDate = releaseNote.ReleaseDate;
    }
      setTimeout(() => {
        window.collapseComponent();
      }, 0)
    });
  }

  closeSlide() {
    this.sessionStorage.isReleaseAnnouncementClosed = true;
    this.bsModalRef.hide();
  }


  SaveUserPreferenceForReleaseNote(preferencevalue) {
    this.userService.saveReleaseNoteUserPreference(preferencevalue).subscribe((response: any) => {
      this.sessionStorage.isReleaseAnnouncementClosed = true;
      this.bsModalRef.hide();
    });
  }
}
