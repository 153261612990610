import { Component, Input } from '@angular/core';

@Component({
  selector: 'pca-manage',
  templateUrl: './pca.manage.component.html'
})

export class PCAManageComponent {
  @Input()
  tile: any;


  constructor() { }

  ngOnInit() {

  }
}
