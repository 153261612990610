<div id="notification" class="c-flyout c-contract__flyout -open" tabindex="-1" aria-hidden="true" aria-labelledby="flyoutTitle" style="visibility:visible">

  <div class="c-flyout__header">
    <h6 id="flyoutTitle">Notifications</h6>
    <button type="button" class="-dismiss-flyout" (click)="closeSlide()" aria-label="Close" alt="Close"></button>
  </div>
  <div class="c-flyout__body -customScroll">
    <div class="c-form-tab">
      <div class="c-form-tab__wrapper">
        <div class="c-scrollable-tabs">
          <div class="c-scrollable-tabs__container" role="tablist">
            <button class="c-form-tab__item" role="tab" *ngIf = "isScopeSelectorVisible" aria-controls="alerts" [attr.aria-selected]="loadAnnouncement ? false : true">Alerts ({{alertNotificationCount}})</button>
            <button class="c-form-tab__item" role="tab" aria-controls="announcement" [attr.aria-selected]="loadAnnouncement ? true : false">Announcements</button>
            <button class="c-form-tab__item" role="tab" aria-controls="releasenote" aria-selected="false">Release Notes history</button>

          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel" id="alerts" [ngClass]="{ '-show' : !loadAnnouncement && isScopeSelectorVisible }">
      <div *ngIf="alertNotificationCount == 0">
        No Records Found
      </div>
      <ul class="c-myWizard-list" *ngIf="alertNotificationCount != 0">
        <li *ngIf="!tiles.IsValidEndDate && !showMandatoryAlert">
          <div>
            E2E End Date specified in
            <a href="javascript:void(0);" [ngClass]="{'team-disabled' :isDisableAlertNavigation}"
               (click)="AlertNotificationProfile(tiles.TileId)">Profile</a>
            tab is in the past. Please update it with a valid date or initiate an E2E closure request, as appropriate.
          </div>
        </li>
        <li *ngIf="!tiles.IsValidEndToEndOverallDeliveryLead && !showMandatoryAlert">
          <div>
            Overall Delivery Lead Listed in the
            <a href="javascript:void(0);" [ngClass]="{'team-disabled' :isDisableAlertNavigation}"
               (click)="AlertKeyContact(tiles.TileId)">Key Contacts</a>
            tab is unavailable or no longer valid. Please update it with an active enterprise id.
          </div>
        </li>
        <li *ngIf="showMandatoryAlert">
          <div>
            EndToEnd profile is not complete. Please fill in all the mandatory fields in
            <a href="javascript:void(0);" [ngClass]="{'team-disabled' :isDisableAlertNavigation}" class="notification-link"
               (click)="AlertNotificationProfile(tiles.TileId)">Profile/Scope/Key Contacts</a>
            to access other applications.
          </div>
        </li>
        <li *ngIf="!tiles.IsValidDeliveryTypeBasedWorkStream && !showMandatoryAlert">
          <div>
            Workstream(s) are not created for some delivery type(s). This will lead to missing core metrics. Click
            <a href="javascript:void(0);" [ngClass]="{'team-disabled' :isDisableAlertNavigation}"
               (click)="AlertWorkStream(tiles.TileId)">Add Workstream</a>
            to create the required workstream(s) or
            <a href="javascript:void(0);" [ngClass]="{'team-disabled' :isDisableAlertNavigation}"
               (click)="AlertScope(tiles.TileId)">Scope</a>
            to update the active delivery types.
          </div>
        </li>
        <li *ngIf="tiles.IsMissingCoreMetrics && !showMandatoryAlert">
          <div>
            Due to missing thresholds, Performance(Red/Amber/Green) of few core metrics cannot be determined. Click
            <a href="javascript:void(0);"
               (click)="AlertMissingCoreMetrics()">here</a>
            to add thresholds for core metrics.
          </div>
        </li>
        <li *ngIf="!tiles.IsValidServiceGroupDeliveryType && !showMandatoryAlert">
          <div>
            The Delivery Type selection is not aligned with the applicable Service Group.
            Please select the appropriate Delivery Type from
            <a href="javascript:void(0);" [ngClass]="{'team-disabled' :isDisableAlertNavigation}"
               (click)="AlertScope(tiles.TileId)">Scope</a>
          </div>
        </li>
        <li *ngIf="tiles.IsAnticipatedContractAvailableInCMW && !showMandatoryAlert">
          <div>
            Anticipated Contract is available in CMW. Please initiate transition request.
          </div>
        </li>
        <li *ngIf="tiles.IsPMOLeadMandatory && !showMandatoryAlert">
          <div>
            Please update <a target="_blank" rel="noopener noreferrer"
                             href="https://in.accenture.com/deploymentenablement/si-transformation/ipc-landing/">intelligent Program Control</a> (iPC) Lead name under
            <a href="javascript:void(0);" [ngClass]="{'team-disabled' :isDisableAlertNavigation}"
               (click)="AlertKeyContact(tiles.TileId)">Alternative Contacts</a>.
          </div>
        </li>
        <li *ngIf=" endToEndType == 'C' && tiles.IsCurrentSustainabilityLevelUpdated && !showMandatoryAlert">
          <div>
            Current Sustainability Level is not updated for the contract. Click <a href="javascript:void(0);" (click)="AlertScope(tiles.TileId)">here</a> to review and update the Target and Current Sustainability Level.
          </div>
        </li>
        <ng-container *ngIf="tiles.automationJourneyAlert && !showMandatoryAlert">
          <li *ngFor="let alert of tiles.automationJourneyAlert;">
            <div>  <a href="javascript:void(0);" (click)="AlertClick(alert.URL)">Automation Journey</a> :&nbsp; <div  class="aj-alerts" [innerHTML]="alert.Message"></div></div>
          </li>
        </ng-container>
      </ul>
    </div>
    <annoucement [loadAnnouncement]="loadAnnouncement" [isScopeSelectorVisible]="isScopeSelectorVisible"></annoucement>
    <releasenote></releasenote>
  </div>
</div>
<div id="flyout" class="c-flyout__overlay"></div>
