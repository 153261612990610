<div class="app-wrap -flex">
  <!-- body -->
  <div class="c-myWizard c-user-guide-stories -collapsed">
    <div class="c-myWizard-content">
      <div class="c-myWizard-container">
        <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': content1}">
          <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/threshold-user-guide-1.jpg" alt="UserGuide" role="presentation" /></div>
          <div class="c-user-guide-stories-container -threshold-userguide1">
            <div class="c-user-guide-stories-data">
              <div class="c-user-guide-stories-data__header">
                <a href="javascript:;" role="button"  data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom"  (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
              </div>
              <div class="c-user-guide-stories-data__body">
                <div class="c-user-guide-stories__text">Select multiple Metrics using the checkbox to be able to edit the threshold for multiple metrics at the same time</div>
              </div>
              <div class="c-user-guide-stories-data__footer">
                <div class="c-user-guide-stories__progress">
                  <div class="c-user-guide-stories__progress-bar">
                    <span style="--value:33%"></span>
                  </div>
                  <div class="c-user-guide-stories__progress-data">
                    <span>1</span>
                    <span>/ 3</span>
                  </div>
                </div>
                <div class="c-user-guide-stories__actions">
                  <a href="javascript:;" role="button" class="-prev" title="Previous" [ngClass]="{'-disabled': prevButton} ">PREV</a>
                  <a href="javascript:;" role="button" class="-next" title="Next" [ngClass]="{'-disabled': nextButton}" (click)="DisplayNextContent(1)">NEXT</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': content2}">
          <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/threshold-user-guide-2.jpg" alt="UserGuide" role="presentation" /></div>
          <div class="c-user-guide-stories-container -threshold-userguide2">
            <div class="c-user-guide-stories-data">
              <div class="c-user-guide-stories-data__header">
                <a href="javascript:;" role="button"  data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom"  (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
              </div>
              <div class="c-user-guide-stories-data__body">
                <div class="c-user-guide-stories__text">The Metrics Threshold Settings are cooler-coded: (refer to example % Earned-initiation)</div>
                <div class="c-user-guide-stories__text"><span class="-red">Red - </span> The setting that is considered as below the threshold</div>
                <div class="c-user-guide-stories__text"><span class="-amber">Amber - </span> The setting that is considered as within the threshold </div>
                <div class="c-user-guide-stories__text"><span class="-green">Green - </span> The setting that is considered as above the threshold</div>
              </div>
              <div class="c-user-guide-stories-data__footer">
                <div class="c-user-guide-stories__progress">
                  <div class="c-user-guide-stories__progress-bar">
                    <span style="--value:66%"></span>
                  </div>
                  <div class="c-user-guide-stories__progress-data">
                    <span>2</span>
                    <span>/ 3</span>
                  </div>
                </div>
                <div class="c-user-guide-stories__actions">
                  <a href="javascript:;" role="button" class="-prev" title="Previous" [ngClass]="{'-disabled': prevButton}" (click)="DisplayPrevContent(2)">PREV</a>
                  <a href="javascript:;" role="button" class="-next" title="Next" [ngClass]="{'-disabled': nextButton}" (click)="DisplayNextContent(2)">NEXT</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': content3}">
          <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/threshold-user-guide-3.jpg" alt="UserGuide" role="presentation" /></div>
          <div class="c-user-guide-stories-container -threshold-userguide3">
            <div class="c-user-guide-stories-data">
              <div class="c-user-guide-stories-data__header">
                <a href="javascript:;" role="button"  data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom"  (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
              </div>
              <div class="c-user-guide-stories-data__body">
                <div class="c-user-guide-stories__text">If needed, click the Restore Default Configuration button to restore Global Threshold default settings</div>
              </div>
              <div class="c-user-guide-stories-data__footer">
                <div class="c-user-guide-stories__progress">
                  <div class="c-user-guide-stories__progress-bar">
                    <span style="--value:100%"></span>
                  </div>
                  <div class="c-user-guide-stories__progress-data">
                    <span>3</span>
                    <span>/ 3</span>
                  </div>
                </div>
                <div class="c-user-guide-stories__actions">
                  <a href="javascript:;" role="button" class="-prev" title="Previous" [ngClass]="{'-disabled': prevButton}" (click)="DisplayPrevContent(3)">PREV</a>
                  <a href="javascript:;" role="button" class="-next" title="Next" [ngClass]="{'-disabled': nextButton}">NEXT</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>    <!-- /body -->
 </div>
