import { Component, Input } from '@angular/core'
import { SessionStorage } from '../../core/context/sessionstorage';
import { TileService } from '../../core/services/tile.service';
var $ = (<any>window).$;
declare var window: any;

@Component({
    selector: 'managment',
    templateUrl: './management.tile.component.html'
})
export class ManagementTileComponent {
    NarrativeStatus: any;
    RedCount: number;
    GreenCount: number;
    AmberCount: number;
    @Input()
    tile: any;
    constructor(private tileService: TileService, private sessionStorage: SessionStorage) { }
    ngOnInit() {
        this.getNarrativeStatusDetails();
        this.getMetricDetails();
       }
  getNarrativeStatusDetails() {
    this.tileService.getGovernanceNarrativeStatus(this.sessionStorage.selectedSubmissionType).subscribe((response: any) => {
            this.NarrativeStatus = response;
            setTimeout(() => {
                window.TooltipFunc();
              }, 0);
        });
    }
    getMetricDetails() {
        var dateSplit = this.sessionStorage.currentPeriod.split('-');
        this.tileService.getProjectMetrics(dateSplit[0], dateSplit[1], dateSplit[2], this.sessionStorage.endToEndUId ?
            this.sessionStorage.endToEndUId : null, this.sessionStorage.clientWMSUId ? this.sessionStorage.clientWMSUId : null,
            this.sessionStorage.workstreamUId ? this.sessionStorage.workstreamUId : null, this.sessionStorage.teamUId ? this.sessionStorage.teamUId : null,
            this.sessionStorage.selectedSubmissionType).subscribe((response: any) => {
            this.RedCount = parseInt(response.RedMetricCount || 0);
            this.GreenCount = parseInt(response.GreenMetricCount || 0);
            this.AmberCount = parseInt(response.AmberMetricCount || 0);

        });

    }
}



