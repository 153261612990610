import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[restrictTags]'
})
export class RestrictTags {

  regexStr = '[^><]';

  constructor(private _el: ElementRef) { }

  @HostListener('keypress', ['$event'])
  onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event'])
  restrictTagsPaste(event: ClipboardEvent) {
    event.preventDefault();
    const data = event.clipboardData.getData('text/plain').split('<').join('').split('>').join('');
    document.execCommand('insertHTML', false, data);
  }
}
