import { forwardRef, ElementRef, Component, Input } from '@angular/core';
import { SessionStorage } from '../context/sessionstorage';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'hierarchy-dropdown',
  templateUrl: './hierarchy.dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HiearchyDropDownComponent),
      multi: true
    }

  ]
})

export class HiearchyDropDownComponent implements ControlValueAccessor {

  constructor(private sessionStorage: SessionStorage,
    private _element: ElementRef) { }

  @Input() options: any;
  @Input() disableDropdown: any;
  @Input() selected: any = [];
  @Input() selectedparent: any;
  @Input() ishierarchy: boolean;
  @Input() ariaLabelledby: string
  @Input() required: boolean;
  @Input() formname: NgForm;
  @Input() name: string;
  @Input() selectText: string;

  isShowValidation: boolean = false;
  isDirty: boolean = false;
  IsEditable: boolean = this.sessionStorage.roleAccessCode == "E" ? true : false;
  SFEDisable: boolean = this.sessionStorage.SFEDisable;
  otherdisabled: boolean;
  selectedItem: string;
  TempOptions: any;
  optionsValue: any;
  SelectorOpen: boolean;
  isrequired: boolean;
  //  form: any;
  ngOnInit() {
    this.LoadInit();
  }
  LoadInit() {
    this.otherdisabled = true;
    this.selectedItem = this.selectText;
    
      this.options.forEach((cms1: any, key) => {
        cms1.IsSelected = false;
        cms1.WorkstreamCMSListLevel2.forEach((cms2: any, key) => {
          cms2.IsSelected = false;
          cms2.WorkstreamCMSListLevel3.forEach((cms3: any, key) => {
            cms3.IsSelected = false;
            cms3.WorkstreamCMSListLevel4.forEach((cms4: any, key) => {
              cms4.IsSelected = false;
              cms4.WorkstreamCMSListLevel5.forEach((cms5: any, key) => {
                cms5.IsSelected = false;
              });
            });
          });
        });
      });

      if (this.selected && this.selected.length > 0) {
        var selectedOptions = this.selected.map(({ Id }) => Id)
        this.options.forEach((cms1: any, key) => {
          this.SetSelected(selectedOptions, cms1);
          cms1.WorkstreamCMSListLevel2.forEach((cms2: any, key) => {
            this.SetSelected(selectedOptions, cms2);
            cms2.WorkstreamCMSListLevel3.forEach((cms3: any, key) => {
              this.SetSelected(selectedOptions, cms3);
              cms3.WorkstreamCMSListLevel4.forEach((cms4: any, key) => {
                this.SetSelected(selectedOptions, cms4);
                cms4.WorkstreamCMSListLevel5.forEach((cms5: any, key) => {
                  this.SetSelected(selectedOptions, cms5);
                });
              });
            });
          });
        });
      }
    //this.ValidateMultiSelect();
    this.validateCustomControls();
  }

  SetSelected = function (selectedOptions, value) {
    if (selectedOptions.indexOf(value.Id) > -1) {
      value.IsSelected = true;
      // this.SelectedItems = (this.SelectedItems != "" && this.SelectedItems != "None Selected") ? this.SelectedItems + ", " + value.Name : value.Name;
      this.selectedItem = value.Name;
      if (value.Id == 0) {
        this.otherdisabled = false;
      }
    }
  }

  openSelector = function () {
    if (this.SelectorOpen) {
      this.SelectorOpen = false;
    }
    else {
      this.SelectorOpen = true;
    }
  }


 

  OnSelect(option) {

    if (option.IsSelected) {
      option.IsSelected = false;
      var item = this.selected.filter(x => x.Id == option.Id)[0];
      var index = this.selected.indexOf(item);
      if (index >= 0) {
        this.selected.splice(index, 1);
      }

      if (option.Id == 0) {
        this.otherdisabled = true;
      }
      this.openSelector();
    }
    else {
        if (this.selected != null && this.selected != undefined) {
          option.IsSelected = true;
          if (this.selected.length < 1) {
            this.selected.push(option);
          }
          else {
            this.selected = [];
            this.selected.push(option);
          }
          if (option.Id == 0) {
            this.otherdisabled = false;
          }
        }
        else {
          option.IsSelected = true;
          this.options.forEach((cms1: any, key) => {
            cms1.IsSelected = false;
            cms1.WorkstreamCMSListLevel2.forEach((cms2: any, key) => {
              cms2.IsSelected = false;
              cms2.WorkstreamCMSListLevel3.forEach((cms3: any, key) => {
                cms3.IsSelected = false;
                cms3.WorkstreamCMSListLevel4.forEach((cms4: any, key) => {
                  cms4.IsSelected = false;
                  cms4.WorkstreamCMSListLevel5.forEach((cms5: any, key) => {
                    cms5.IsSelected = false;
                  });
                });
              });
            });
          });
          this.selected = [];
          this.selected.push(option);
          if (option.Id == 0) {
            this.otherdisabled = false;
          }
          var selectedOptions = this.selected.map(({ Id }) => Id)
          this.options.forEach((cms1: any, key) => {
            this.SetSelected(selectedOptions, cms1);
            cms1.WorkstreamCMSListLevel2.forEach((cms2: any, key) => {
              this.SetSelected(selectedOptions, cms2);
              cms2.WorkstreamCMSListLevel3.forEach((cms3: any, key) => {
                this.SetSelected(selectedOptions, cms3);
                cms3.WorkstreamCMSListLevel4.forEach((cms4: any, key) => {
                  this.SetSelected(selectedOptions, cms4);
                  cms4.WorkstreamCMSListLevel5.forEach((cms5: any, key) => {
                    this.SetSelected(selectedOptions, cms5);
                  });
                });
              });
            });
          });
        }
        this.openSelector();
     
   
    }
    this.LoadInit();
    this.isDirty = true;

    if (this.formname != null && this.formname != undefined) {
      this.formname?.controls[this.name]?.markAsDirty();
    }
    this.validateCustomControls();
  }

  CheckIfSelected(items) {
    var isExist = items.filter(x => x.IsSelected == true);
    if (isExist.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  validateCustomControls() {

    if (this.required && this.name && this.formname) {
      let dropDownField = this.formname.controls[this.name];
      if (dropDownField) {
        if (this.selectedItem == null) {
          dropDownField.setErrors({ 'incorrect': true });
          dropDownField.setValidators([Validators.required])
        } else {
          dropDownField.setValidators([]);
          dropDownField.updateValueAndValidity()
        }
      }
    }
  }


  //ValidateMultiSelect() {
  //  if (this.isrequired) {
  //    /* if (this.SelectedItems == "None Selected") {*/
  //    if (this.SelectedItems?.length <= 0) {
  //      this.isShowValidation = true;
  //      if (this.form != null && this.form != undefined) {
  //        this.form.$invalid = true;
  //      }
  //    }
  //    else {
  //      this.isShowValidation = false;
  //      if (this.form != null && this.form != undefined) {
  //        if (this.form.$valid) {
  //          this.form.$invalid = false;
  //        }
  //        else {
  //          this.form.$invalid = true;
  //        }
  //      }
  //    }
  //  }
  //  else {
  //    this.isShowValidation = false;
  //  }
  //}

  MultiSelectKeyPress(option, $event, ariaLabelledby, type, last) {
    if (event != null) {
      var keycode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keycode == 13) { // code for ENTER key
        if (type == 'input')
          this.OnSelect(option);

        if (type == 'anchor') {
          setTimeout(function () {
            this._element.nativeElement.querySelector('button[name="multiselect_btn_' + this.ariaLabelledby + '"]').focus();
          });
        }
        setTimeout(function () {
          this._element.nativeElement.querySelector('input[name="' + ariaLabelledby + '_focus_0"]').focus();
        });

      }
      else if (keycode == 27) {
        this.SelectorOpen = false;
        setTimeout(function () {
          this._element.nativeElement.querySelector('button[name="multiselect_btn_' + this.ariaLabelledby + '"]').focus();
        });
      }
      else if (keycode == 9) { //TAB key check
        this.SearchFirstElement(ariaLabelledby, type, last);
      }
      else {
        return;
      }
    }
  }

  SearchFirstElement(ariaLabelledby, type, last) {
    if (last == true) {
      setTimeout(function () {
        if (type == 'input')
          this._element.nativeElement.querySelector('input[name="' + ariaLabelledby + '_focus_0"]').focus();
        else if (type == 'anchor')
          this._element.nativeElement.querySelector('a[name="' + ariaLabelledby + '_focus_0"]').focus();
      });
    }
  }




  GetUniqueItems(array, property) {
    var unique = {};
    var distinct = [];
    for (var i in array) {
      if (typeof (unique[array[i][property]]) == "undefined") {
        distinct.push(array[i]);
      }
      unique[array[i][property]] = 0;
    }
    return distinct;
  }

  @Input('value') _value = false;
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;

  }


  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
      this.LoadInit();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  trackByName(index: number, selectedRow: any): string {
    return selectedRow.Name;
  }
}
