import { Component,Output,EventEmitter, HostListener } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { ConfigureMetricService } from '../../core/services/configure.metrics.services';
import { SessionStorage } from '../../core/context/sessionstorage';
import { enumProfileTab } from '../../core/context/enum';
import * as dayjs from 'dayjs';
import { SharedService } from '../../core/services/shared.service';
import { InitiateProfileComponent } from '../initiate.profile.component/initiate.profile.component';
import { forEach } from 'lodash';
import { InitiateProfileSharedService } from '../initiate.profile.component/initiate.profile.shared.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

declare var window: any;
var $ = (<any>window).$;
@Component({
  selector: 'other-configurations',
  templateUrl: './otherconfiguration.settings.component.html',
  providers: [ConfigureMetricService]
})
export class OtherConfigurationSettings {
  constructor(
    private sessionStorage: SessionStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private metricService: ConfigureMetricService,
    private initiateProfileComponent: InitiateProfileComponent,
    private initiateProfileSharedService: InitiateProfileSharedService,
  ) {
  }
  title = 'Configure Metrics - MyWizard';
  accessFlag: boolean;
  e2eUId: any;
  isAggregated: boolean;
  tempIsAggregated: boolean;
  isTeamsModified: boolean;
  isDirty: boolean = false;
  aggregatedTeams: any;
  tempAggregatedTeams: any;
  count: number = 0;
  selectedTeams: any = [];
  Roleaccesscode: any;

  @Output() InitiateProfileTabSetup = new EventEmitter();
  keyContactTabHighlight: boolean;
  ProfileTabHighlight: boolean;

  e2eflag: string;

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    
    if (this.isDirty || this.count>0) {
      return confirm('Changes you made may not be saved.');
    }
    else { return true; }

  }
  ngOnInit() {
    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['MyWizard/Back/Profile'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId, profileTabCode: 'OtherConfig' } });
      return false;
    }
    if (this.sessionStorage.teamUId != null && this.sessionStorage.teamUId != '') {
      this.sessionStorage.profileTab = enumProfileTab.TeamStructure;
      this.router.navigate(['/mywizard/teamstructure/E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }
    //this.initiateProfileSharedService.ValidateDemographicTabHighlight(this);
    //this.initiateProfileSharedService.errorTabHighlight.subscribe(result => {
    //  this.InitiateProfileTabSetup.emit({ keyContactTabHighlight: this.keyContactTabHighlight, ProfileTabHighlight: this.ProfileTabHighlight, tabId: enumProfileTab.OtherConfigureSettings });
    //});
    this.Roleaccesscode = this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);

    this.accessFlag = this.Roleaccesscode == 'E' ? false : true;
    this.e2eUId = this.sessionStorage.endToEndUId;
    this.getTeamsForE2E(this.e2eUId);
    
  }

  getTeamsForE2E(e2eUId: any): void {
    this.metricService.getAggregatedTeams(e2eUId).subscribe(
      data => {
        this.aggregatedTeams = data.AggregatedTeams;
        this.e2eflag = data.e2eflag;
        this.aggregatedTeams = this.aggregatedTeams.map((x) => {
          x.IsSelected = x.TeamIndicator == 'Y' ? true : false;
          return x;
        })
       //console.log(this.aggregatedTeams);
        this.selectedTeams = this.aggregatedTeams.filter(x => x.IsSelected == true).map(x => x);
        this.tempAggregatedTeams = JSON.parse(JSON.stringify(this.aggregatedTeams));
        this.isAggregated = this.selectedTeams.length > 0 ? true : false;
        this.tempIsAggregated = JSON.parse(JSON.stringify(this.isAggregated));
      });
  }

  changeaggregation(event) {
    this.isAggregated = !this.isAggregated;
    this.isDirty = true;
  }
  UpdateAggregatedTeams() {
    if (this.accessFlag) {
      return;
    }
    this.isDirty = false;
    this.count = 0;
    if (!this.isAggregated) {
      this.aggregatedTeams = this.aggregatedTeams.map((x) => {
        x.IsSelected = false;
        return x;
      })
    }   
      this.aggregatedTeams = this.aggregatedTeams.map((x) => {
        x.TeamIndicator = x.IsSelected == true ? 'Y' : 'N';
        return x;
      })
 
    this.metricService.updateAgrregatedTeams(this.aggregatedTeams, this.e2eUId).subscribe(
      res => {
        if (res == "Ok") {
          this.sharedService.openAlertPopup("Saved successfully", window.scrollY);
          this.getTeamsForE2E(this.e2eUId);
        }
      });
  }
  cancelSettings() {
    this.aggregatedTeams = JSON.parse(JSON.stringify(this.tempAggregatedTeams));
    var selectedTeam = this.aggregatedTeams.filter(x => x.IsSelected == true);
    this.isAggregated = selectedTeam.length > 0 ? true : false;
  }
  OnBackClick() {
    this.initiateProfileComponent.OnBackClick();
  }

  isButtonDisable(btnType: string) {
    var isModified = 0;
    if (this.aggregatedTeams) {
      this.aggregatedTeams.forEach((team) => {
        var preValue = this.tempAggregatedTeams.filter(x => x.UId == team.UId).map(x => x.IsSelected)[0]
        if (team.IsSelected != preValue) {
          isModified = 1;
        }
      })

      if (isModified == 1 && this.isAggregated) {
        return true
      }
      else {
        var selectedTeamsCnt = this.aggregatedTeams.filter(x => x.IsSelected == true).length;
        if (btnType == 'save') {          
          if (selectedTeamsCnt == 0 && this.isAggregated == true) {
            return false;
          }
        }
        if (this.isAggregated != this.tempIsAggregated) {
          if (this.isAggregated) {
            if (selectedTeamsCnt <= 0) {
              return false;
            }
          }
          else {
            return true
          }
        }
        //if (!this.isAggregated) {
        //  return true;
        //}
        return false;
      }

    }
  }
  onChange(teamUId:any) {
   
    var temp = this.aggregatedTeams.filter(y => y.UId == teamUId).map(x => x);
    if (temp[0].IsSelected && temp[0].TeamIndicator == null) {
      this.count++;
    }
    else if (!temp[0].IsSelected && temp[0].TeamIndicator == null) {
      this.count--;
    }
    else if (!temp[0].IsSelected && temp[0].TeamIndicator == "Y"){
      this.count++;
    }
    else { this.count--; }
  }
  DisplayOtherconfigUserGuide() {
    this.router.navigate(['/mywizard/otherconfiguserguide']);
  }

}
