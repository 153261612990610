import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionStorage } from './../context/sessionstorage';


@Injectable()
export class TMSService {
  constructor(private httpService: HttpService, private sessionStorage: SessionStorage) { }

  getTeamsByEndToEnd() {
    return this.httpService.get('/api/Team/' + this.sessionStorage.endToEndId +
      '?cmsId=' + this.sessionStorage.clientWMSId + '&tmsId=' + this.sessionStorage.teamId + '&workstreamId=' + this.sessionStorage.workstreamId , true);
  }

  getTeamsByTeamId(tmsId: Number, teamGroupId: Number) {
    return this.httpService.get('/api/Team/Edit' +
                    '?tmsId=' + tmsId + '&teamGroupId=' + teamGroupId + '&endToEndId=' + this.sessionStorage.endToEndId, true);
  }

  getStructure() {
    return this.httpService.get('/api/Structure/' + this.sessionStorage.endToEndId +'/'+this.sessionStorage.endToEndUId+
      '?cmsId=' + this.sessionStorage.clientWMSId + '&tmsId=' + this.sessionStorage.teamId + '&workstreamId=' + this.sessionStorage.workstreamId, true);
  }

  saveTeamForStructure(data: any) {
    return this.httpService.post('/api/Structure/Team/Save', data, true);
  }

  saveTeamGroupForStructure(data: any) {
    return this.httpService.post('/api/Structure/TeamGroup/Save', data, true);
  }

  saveDraggedTeam(data: any) {
    return this.httpService.post('/api/Team/Update', data, true);
  }

  deleteTeam(data: any) {
    return this.httpService.post('/api/Team/Delete', data, true);
  }

  getTeamMembers() {
    return this.httpService.get('/api/Team/EndToEndMembers/' + this.sessionStorage.endToEndId + '/' + this.sessionStorage.endToEndUId + '?deployRegion=' + this.sessionStorage.deployRegion, true);
  }
         
}
