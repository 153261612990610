<div id="edit-threshold-state" class="c-modal c-edit-threshold" tabindex="-1" aria-hidden="false" aria-labelledby="modalDialogTitle" aria-modal="true" role="dialog">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">Edit Metrics Threshold</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close" alt="Close" (click)="onCancelClick()"></button>
      </div>
      <div class="c-modal__body">
        <!--<div *ngIf="isError" class="alert alert-danger">
          <strong>Error! </strong>{{Error}}
        </div>-->
        <div class="c-edit-threshold-metric" *ngIf="selectedThrendData.length>1">
          <div>Metric(s) Selected:</div>
          <div class="c-edit-threshold-selected">
            <ul class="c-edit-threshold-list -customScroll">
              <li *ngFor="let threshold of selectedThrendData">
                {{threshold.Metric.Name}}  <span class="floatNone" *ngIf="threshold.Metric.Abbreviation">
                  ({{threshold.Metric.Abbreviation}})
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div class="c-myWizard-form__input">
          <div class="c-myWizard-form__input-area" *ngIf="selectedThrendData.length==1">
            <div class="o-input  ">
              <label for="metric">Metric(s) Selected:</label>
              <input type="text"
                     class="o-input__input  -disabled   "
                     placeholder="% Earned - Initiation"
                     [attr.value]="selectedThrendData[0].Metric.Name"
                     id="metric"
                     disabled />
            </div>
          </div>
          <div class="c-myWizard-form__input-area">
            <div class="o-input    ">
              <label for="type">Threshold Type:</label>
              <input type="text"
                     class="o-input__input -disabled"
                     placeholder="Trend"
                     value="Trend"
                     id="type" />
            </div>
          </div>
        </div>
        <div>Threshold Trends</div>
        <div class="c-edit-threshold-reported-title">Since Last Reported:</div>
        <div class="c-configuration-input">
          <div class="c-configuration-input-item">
            <div class="c-select-dropdown   " [ngClass]="!accessFlag ?'':'-disabled'" >
              <div class="c-dropdown" id="decreased" [ngClass]="(selectedtrend1==0&&isError) || (selectedtrend1==selectedtrend2||selectedtrend1==selectedtrend3)?'has-error':''">
                <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down -decreased">
                  <span *ngIf="selectedtrend1!=0">{{selectedTrend1Name}}</span>
                  <span *ngIf="selectedtrend1==0">Select</span>
                </a>
                <div class="c-dropdown-menu">
                  <div class="c-dropdown-body">
                    <a href="javascript:;" role="button" class="c-dropdown-item -decreased" *ngFor="let data  of MyData" [ngClass]="data.id==selectedtrend1?'-active':''" (click)="selectTrend('trend1',data)">{{data.name}}</a>
                  </div>
                </div>
              </div>
              <p class="c-select__error-text show" *ngIf="selectedtrend1==0&&isError">Please select unique threshold trends</p>
              <p class="c-select__error-text show" *ngIf="(selectedtrend1==selectedtrend2||selectedtrend1==selectedtrend3)&&selectedtrend1!=0">Please select unique threshold trends</p>
            </div>
          </div>
          <div class="c-configuration-input-item">
            <div class="c-select-dropdown   "[ngClass]="!accessFlag ?'':'-disabled'" >
              <div class="c-dropdown" id="stable" [ngClass]="(selectedtrend2==0&&isError) || (selectedtrend2==selectedtrend3||selectedtrend2==selectedtrend1)?'has-error':''">
                <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down -stable">
                  <span *ngIf="selectedtrend2!=0">{{selectedTrend2Name}}</span>
                  <span *ngIf="selectedtrend2==0">Select</span>
                </a>
                <div class="c-dropdown-menu">
                  <div class="c-dropdown-body">
                    <a href="javascript:;" role="button" class="c-dropdown-item -stable" *ngFor="let data  of MyData" [ngClass]="data.id==selectedtrend2?'-active':''" (click)="selectTrend('trend2',data)">{{data.name}}</a>
                  </div>
                </div>
              </div>
              <p class="c-select__error-text show" *ngIf="selectedtrend2==0&&isError">Please select unique threshold trends</p>
              <p class="c-select__error-text show" *ngIf="(selectedtrend2==selectedtrend3||selectedtrend2==selectedtrend1)&&selectedtrend2!=0">Please select unique threshold trends</p>
            </div>
          </div>
          <div class="c-configuration-input-item">
            <div class="c-select-dropdown   -error"[ngClass]="!accessFlag ?'':'-disabled'" >
              <div class="c-dropdown" id="increased" [ngClass]="((selectedtrend3==0&&isError) || (selectedtrend3==selectedtrend2||selectedtrend3==selectedtrend1))?'has-error':''">
                <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down -increased">
                  <span *ngIf="selectedtrend3!=0">{{selectedTrend3Name}}</span>
                  <span *ngIf="selectedtrend3==0">Select</span>
                </a>
                <div class="c-dropdown-menu">
                  <div class="c-dropdown-body">
                    <a href="javascript:;" role="button" class="c-dropdown-item -increased" *ngFor="let data  of MyData" [ngClass]="data.id==selectedtrend3?'-active':''" (click)="selectTrend('trend3',data)">{{data.name}}</a>
                  </div>
                </div>
              </div>
              <p class="c-select__error-text show" *ngIf="selectedtrend3==0&&isError">Please select unique threshold trends</p>
              <p class="c-select__error-text show" *ngIf="(selectedtrend3==selectedtrend2||selectedtrend3==selectedtrend1)&&selectedtrend3!=0">Please select unique threshold trends</p>
            </div>
          </div>
        </div>
      </div>
      <div class="c-modal__footer">
        <div class="c-modal__footer-btnGroup">
          <!-- add class -dismiss-modal to buttons that'll close the modal -->
          <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" class="o-secondary-button" [ngClass]="accessFlag?'-disabled':(btnRestore?'-disabled':'')" (click)="onRestoreDefault(selectedThrendData)" tabindex="-1">
            <!---->
            Restore Default Configuration
          </a>
          <!--Primary Button ends from here-->                    <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" class="o-primary-button" [ngClass]="!accessFlag ?'':'-disabled'" (click)="saveMetricThreshConfig(selectedThrendData)" tabindex="-1">
            <!---->
            Save
          </a>
          <!--Primary Button ends from here-->
        </div>
      </div>
    </div>
  </div>
</div>
