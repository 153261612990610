<form name="reportingaddeditform" #reportingaddeditform="ngForm" novalidate>
  <div id="key-portfolio" class="c-flyout c-contract__flyout -open" tabindex="-1" style="visibility: visible;" aria-labelledby="flyoutTitle" aria-modal="true" role="dialog">
    <div class="c-flyout__header">
      <div class="-flyoutTitle" id="flyoutTitle">{{action}} Reporting Portfolio</div>
      <button type="button" class="-dismiss-flyout" aria-label="Close" (click)="CloseModal()" alt="Close"></button>
    </div>
    <div class="c-flyout__body -customScroll">
      <div id="accordionParent-contracts">
        <div class="c-accordion -open">
          <button type="button" class="c-collapse" aria-expanded="true" aria-controls="active-contract-1"><span>Primary Details</span></button>
          <div id="active-contract-1" #ac1 class="-collapse -show" data-parentid="accordionParent-contracts">
            <div class="c-accordion__container">
              <div class="c-myWizard-form__input">
                <div class="c-myWizard-form__input-area" *ngIf="!showCGGU">
                  <div class="c-custom-select__wrapper -required">
                    <label for="atc">Delivery Center</label>
                    <div class="c-dropdown" id="atc">

                      <div *ngIf="action=='Edit'" class="c-custom-select__wrapper  -disabled">
                        <search-dropdown *ngIf="action=='Edit'" [options]="DCforOppInit" [isShow]="true" [labelInfo]="'ATC'" id="deliveryCenterIdEdit" name="deliveryCenterId" #deliveryCenterId="ngModel"
                                         [selected]="DeliveryCenterId" [selectText]="'Select'"
                                         [formname]="reportingaddeditform"
                                         [disableDropdown]="true"
                                         [(ngModel)]="DeliveryCenterId">
                        </search-dropdown>
                      </div>
                      <search-dropdown *ngIf="action=='Add'" [options]="DCforOppInit" [isShow]="true" [labelInfo]="'ATC'" id="deliveryCenterIdAdd" name="deliveryCenterId" #deliveryCenterId="ngModel"
                                       [selected]="DeliveryCenterId" [selectText]="'Select'"
                                       (callback)="DcChangeforNewRecord(DeliveryCenterId)"
                                       [formname]="reportingaddeditform"
                                       [required]="true"
                                       [errormessage]="'Please select Delivery Center'"
                                       [(ngModel)]="DeliveryCenterId">
                      </search-dropdown>
                    </div>
                    <p class="c-select__error-text">Enter the value</p>
                    <!--<select #deliverycenter="ngModel" name="deliverycenter" [ngClass]="{'has-error': deliverycenter.invalid}" aria-labelledby="DeliveryCenter"
                            class="form-control" [disabled]="RoleAccess=='V'"
                            role="listbox"
                            [(ngModel)]="KeyContactsDetails.DeliveryCenterId"
                            (change)="DcChangeforNewRecord(KeyContactsDetails.DeliveryCenterId)">
                      <option role="option" value="">--Select--</option>
                      <option *ngFor="let item of DCforOppInit | orderBy:'Name'; trackBy : trackById;" role="option" [value]="item.Id">{{item.Name}}</option>
                    </select>-->
                  </div>
                </div>
                <div class="c-myWizard-form__input-area">
                  <div class="o-input    ">
                    <label for="delivery_lead">Delivery Lead / POC</label>
                    <auto-complete id="deliveryLeadPortfolio" #deliveryLeadPortfolio="ngModel" name="deliveryLeadPortfolio" [disabled]="RoleAccess == 'V'"
                                   hiddenFieldID="hiddenDeliveryLead" ariaLabelledby="DeliveryLead"
                                   [selected]="DeliveryLead"
                                   [(ngModel)]="DeliveryLead"
                                   [formname]="reportingaddeditform"
                                   class="form-control"
                                   [required]="false"
                                   (selectPeoplePicker)="DeiveryLeadChange(DeliveryLead,$event)">
                    </auto-complete>
                    <p class="o-input__error-text">Enter the value</p>
                    <span class="o-input__char-text">30/30 characters</span>

                    <!--<auto-complete id="deliveryLeadPortfolio" #deliveryLeadPortfolio="ngModel" name="deliveryLeadPortfolio" [disabled]="RoleAccess=='V'"
                                   hiddenFieldID="hiddenDeliveryLead" ariaLabelledby="DeliveryLead"
                                   [selected]="DeliveryLead"
                                   [(ngModel)]="DeliveryLead"
                                   [formname]="contactsform" [required]="false"
                                   (selectPeoplePicker)="DeiveryLeadChange(DeliveryLead,$event)">
                    </auto-complete>-->
                  </div>
                </div>
                <div class="c-myWizard-form__input-area" *ngIf="!showCGGU">
                  <div class="c-custom-select__wrapper -required">
                    <label for="account_group">Account Group</label>
                    <div class="c-dropdown" id="account_group">

                      <search-dropdown *ngIf="action == 'Edit'" [options]="AccountGroups" [isShow]="true" [labelInfo]="'accountgroup'" id="accountGroupId" name="accountGroupId" #accountGroupId="ngModel"
                                       [selected]="AccountGroupId" [selectText]="'Select'"
                                       (callback)="AccountGroupsChangeforNewRecord(AccountGroupId)"
                                       [formname]="reportingaddeditform"
                                       [required]="true"
                                       [disableDropdown]="RoleAccess=='V'"
                                       [errormessage]="'Please select Account Group'"
                                       [(ngModel)]="AccountGroupId">
                      </search-dropdown>

                      <search-dropdown *ngIf="action == 'Add'" [options]="AccountGroups" [isShow]="true" [labelInfo]="'accountgroup'" id="accountGroupId" name="accountGroupId" #accountGroupId="ngModel"
                                       [selected]="AccountGroupId" [selectText]="'Select'"
                                       (callback)="AccountGroupsChangeforNewRecord(AccountGroupId)"
                                       [formname]="reportingaddeditform"
                                       [required]="true"
                                       [errormessage]="'Please select Account Group'"
                                       [(ngModel)]="AccountGroupId">
                      </search-dropdown>

                    </div>
                    <p class="c-select__error-text">Enter the value</p>
                  </div>

                </div>
                <div class="c-myWizard-form__input-area" *ngIf="!showCGGU">
                  <div class="o-input    -disabled">
                    <label for="ag_lead">Account Group Lead</label>
                    <input type="text"
                           class="o-input__input"
                           placeholder=""
                           value="{{AccountGroupLead}}"
                           id="ag_lead" disabled/>
                    <p class="o-input__error-text">Enter the value</p>
                    <span class="o-input__char-text">30/30 characters</span>
                  </div>
                </div>
                <div class="c-myWizard-form__input-area" *ngIf="!showCGGU">
                  <div class="c-custom-select__wrapper -required">
                    <label for="delivery_group">Delivery Group</label>
                    <div class="c-dropdown" id="delivery_group">
                      <search-dropdown *ngIf="action=='Edit'" [options]="DeliveryGroups" [isShow]="true" [labelInfo]="'deliverygroups'" id="deliveryGroupId" name="deliveryGroupId" #deliveryGroupId="ngModel"
                                       [selected]="DeliveryGroupId" [selectText]="'Select'"
                                       (callback)="DeliveryGroupsChangeforNewRecord(DeliveryGroupId)"
                                       [formname]="reportingaddeditform"
                                       [required]="true"
                                       [disableDropdown]="RoleAccess=='V'"
                                       [errormessage]="'Please select Delivery Group'"
                                       [(ngModel)]="DeliveryGroupId">
                      </search-dropdown>

                      <search-dropdown *ngIf="action=='Add'" [options]="DeliveryGroups" [isShow]="true" [labelInfo]="'deliverygroups'" id="deliveryGroupId" name="deliveryGroupId" #deliveryGroupId="ngModel"
                                       [selected]="DeliveryGroupId" [selectText]="'Select'"
                                       (callback)="DeliveryGroupsChangeforNewRecord(DeliveryGroupId)"
                                       [formname]="reportingaddeditform"
                                       [required]="true"
                                       [errormessage]="'Please select Delivery Group'"
                                       [(ngModel)]="DeliveryGroupId">
                      </search-dropdown>

                    </div>
                  </div>
                </div>
                <div class="c-myWizard-form__input-area " *ngIf="!showCGGU">
                  <div class="o-input   -disabled ">
                    <label for="dg_lead">Delivery Group Lead</label>
                    <input type="text"
                           class="o-input__input"
                           placeholder=""
                           value="{{DeliveryGroupLead}}"
                           id="dg_lead" disabled/>
                  </div>
                </div>
                <div class="c-myWizard-form__input-area " *ngIf="!showCGGU">
                  <div class="c-custom-select__wrapper -required">
                    <label for="delivery_unit">Delivery Unit</label>
                    <div class="c-dropdown" id="delivery_unit">
                      <search-dropdown *ngIf="action == 'Edit'" [options]="DeliveryUnits" [isShow]="true" [labelInfo]="'deliveryunits'" id="deliveryUnitId" name="deliveryUnitId" #deliveryUnitId="ngModel"
                                       [selected]="DeliveryUnitId" [selectText]="'Select'"
                                       [formname]="reportingaddeditform"
                                       [required]="true"
                                       [disableDropdown]="RoleAccess=='V'"
                                       [errormessage]="'Please select Delivery Unit'"
                                       [(ngModel)]="DeliveryUnitId">
                      </search-dropdown>
                      <search-dropdown *ngIf="action == 'Add'" [options]="DeliveryUnits" [isShow]="true" [labelInfo]="'deliveryunits'" id="deliveryUnitId" name="deliveryUnitId" #deliveryUnitId="ngModel"
                                       [selected]="DeliveryUnitId" [selectText]="'Select'"
                                       [formname]="reportingaddeditform"
                                       [required]="true"
                                       [errormessage]="'Please select Delivery Unit'"
                                       [(ngModel)]="DeliveryUnitId">
                      </search-dropdown>
                    </div>
                  </div>
                </div>
                <div class="c-myWizard-form__input-area " *ngIf="!showCGGU">
                  <div class="o-input   -disabled ">
                    <label for="du_lead">Delivery Unit Lead</label>
                    <input type="text"
                           class="o-input__input"
                           placeholder=""
                           value="{{DeliveryUnitLead}}"
                           id="du_lead" disabled/>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="action == 'Edit' && deliveryCenter.EndToEndDeliveryCenterId != null" class="c-accordion">
          <button type="button" class="c-collapse" aria-expanded="true" aria-controls="active-contract-2"><span>Facility Details</span></button>
          <div id="active-contract-2" class="-collapse -show" data-parentid="accordionParent-contracts">
            <div class="c-accordion__container">
              <metro-cities (MetroCityDetailsOutputEmitter)="UpdateMetroCityDetails($event)" (MetroCityFormValidEmitter)="UpdateMetroCityFormStatus($event)" [deliveryCenter]="deliveryCenter" [RoleAccess]="RoleAccess" [E2eDemoGraphics]="E2eDemoGraphics"></metro-cities>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-flyout__footer">
      <div class="c-key-flyout-btn">
        <!--Primary Button starts from here-->
        <a href="javascript:void(0)" *ngIf="E2EType == 'C'" (click)="AddDataContract(DeliveryCenterId,AccountGroupId,DeliveryGroupId,DeliveryUnitId,DeliveryLead,reportingaddeditform);" role="button" type="submit" class="o-primary-button -flyout-close" [ngClass]="{'-disabled' : RoleAccess=='V'}">
          Save
        </a>
        <a href="javascript:void(0)" *ngIf="E2EType == 'I' || E2EType == 'O'" (click)="AddData(DeliveryCenterId,AccountGroupId,DeliveryGroupId,DeliveryUnitId,DeliveryLead,reportingaddeditform);" role="button" type="submit" class="o-primary-button -flyout-close" [ngClass]="{'-disabled' : RoleAccess=='V'}">
          <!---->
          Save
        </a>
        <!--Primary Button ends from here-->
      </div>
    </div>
  </div>
  <div class="c-flyout__overlay"></div>
</form>
