import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

// Pipes
import { CommaSeparatedPipe } from './core/pipes/comma.separated.pipe';
import { CutPipe } from './core/pipes/cut.pipe';
import { FilterListPipe, FilterByText, FilterByParentId } from './core/pipes/filter.list.pipe';
import { CharactersPipe } from './core/pipes/characters.pipe';
import { OrderByPipe } from './core/pipes/orderby.pipe';
import { ActiveListPipe } from './core/pipes/active.list.pipe';

// Directives
//import { NgConfirmClickDirective } from './core/directives/ng.confirm.click.directive';
import { ClickElsewhereDirective } from './core/directives/click-elsewhere-directive';
import { RestrictTags } from './core/directives/restrict-tags.directive';
import { DatePickerDirective } from './core/directives/date.picker.directive';

// Common Components
import { AutoCompleteComponent } from './core/components/auto.complete.component';
import { SearchDropDownComponent } from './core/components/search.dropdown.component';
import { HiearchyDropDownComponent } from './core/components/hierarchy.dropdown.component';
import { DownloadFileComponent } from './core/components/download.file.component/download.file.component';
import { NgxSpinnerModule } from "ngx-spinner"; // Import library module
import { MultiSelectdDropDownComponent } from './core/components/multiselect.dropdown.component';
import { TimePickerComponent } from './core/components/timepicker.component';
import { WorkstationDateTimeConversionPipe } from './core/pipes/workstation.datetime.conversion.pipe';
import { CMMISearchDropDownComponent } from './core/components/cmmi.search.dropdown.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule
  ],
  declarations: [
    
    DownloadFileComponent,
    AutoCompleteComponent,
    SearchDropDownComponent,
    HiearchyDropDownComponent,
    CommaSeparatedPipe,
    CutPipe,
    FilterListPipe,
    FilterByText,
    FilterByParentId,
    CharactersPipe,
    OrderByPipe,
    WorkstationDateTimeConversionPipe,
    ActiveListPipe,
    //NgConfirmClickDirective,
    ClickElsewhereDirective,
    RestrictTags,
    DatePickerDirective,
    MultiSelectdDropDownComponent, TimePickerComponent, CMMISearchDropDownComponent
  ],
  exports: [
    DownloadFileComponent,
    AutoCompleteComponent,
    SearchDropDownComponent,
    HiearchyDropDownComponent,
    CommaSeparatedPipe,
    CutPipe,
    FilterListPipe,
    FilterByText,
    FilterByParentId,
    CharactersPipe,
    OrderByPipe,
    WorkstationDateTimeConversionPipe,
    ActiveListPipe,
    //NgConfirmClickDirective,
    ClickElsewhereDirective,
    RestrictTags,
    DatePickerDirective,
    CommonModule,
    FormsModule,
    MultiSelectdDropDownComponent, TimePickerComponent, CMMISearchDropDownComponent
  ]
})

export class SharedModule { }
