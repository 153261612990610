
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';

export interface CanDeactivateGuard {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class ChangesGuard  {
  constructor() {

  }

  canDeactivate(
    component: CanDeactivateGuard
  ): boolean | Observable<boolean> {

    return component.canDeactivate();

    //return component.canDeactivate()
    //  ? true
    //  : confirm(
    //    "Changes you made may not be saved."
    //  );
  }

}  
