import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../services/http.service'
import { SessionStorage } from '../context/sessionstorage';

@Injectable()
export class DemographicsService {
  constructor(private http: HttpClient, private httpService: HttpService, private sessionStorage: SessionStorage) { }

  downloadTemplate(fileName, elementId, type) {
    return this.httpService.getFile('/api/download/' + fileName + '/' + type, elementId, fileName, type, true);
  };
  getE2EDemographics(endToEndId: number, endToEndType: string, tabCode: string, IsAdmin: boolean, CreatePage: boolean) {
    return this.httpService.get('/api/Demographics/' + endToEndId + '/' + tabCode + '/' + endToEndType + '/' + CreatePage + '?IsAdmin=' + IsAdmin, true);
  };
  getInitiateProfile(endToEndId: number) {
    return this.httpService.get('/api/InitiateProfile/' + endToEndId);
  };
    getOpportunityInitiativeDetails(endToEndType: string) {
    return this.httpService.get('/api/Demographics/' + endToEndType);
  };
  postE2EDemographics(data: any) {
    return this.httpService.post('/api/Demographics/', data, true);
  };
  getKeyContactsTabDetails(endToEndId: number, endToEndType: string, tabCode: string, IsAdmin: boolean, CreatePage: boolean) {
    return this.httpService.get('/api/Demographics/' + endToEndId + '/' + tabCode + '/' + endToEndType + '/' + CreatePage + '?IsAdmin=' + IsAdmin, true);
  };
  postKeyContactsTabDetails(data: any) {
    return this.httpService.post('/api/Demographics/', data, true);
  };
  peoplepicker(data: any) {
    return this.httpService.get('/api/PeoplePicker/PeopleAutoComplete/' + data + '/', false);
  };
  getDeliveryLeadLocation(enterpriseId: any, type: any) {
    return this.httpService.get('/api/deliveryleadlocation/deliverylead/' + enterpriseId + '/' + type, true);
  };
  searchCustomer(data: any, e2eType: string) {
    return this.httpService.get("/api/Demographics/MapCustomer/" +  e2eType + '?name='+data, false)
  };
  masterClientName(id: number) {
    return this.httpService.get("/api/Demographics/MasterClient/" + id, true)
  };

  DeleteDCData(EndtoEndId, DCLeadingdeliveryCenterId, DeliveryCenterId, DeliveryUnitId, endToEndType, EndtoEndDeliveryCenterId, revokeAccessEnterpriseId) {
    return this.httpService.get("/api/Demographics/DeleteDCData/" + EndtoEndId + '/' + DCLeadingdeliveryCenterId + '/' + DeliveryCenterId + '/' + DeliveryUnitId + '/' + endToEndType + '/' + this.sessionStorage.enterpriseId + '/' + EndtoEndDeliveryCenterId + '?revokeAccessEnterpriseId=' + revokeAccessEnterpriseId, true)
  };
  GetMigData(EndtoEndId: number) {
    return this.httpService.get("/api/Demographics/MigrationDetails/" + EndtoEndId)
  };
  closeCMSTMSWorkstream(endToEndUId: string) {
    return this.httpService.post('/api/CloseCMSTMSWorkstream/' + endToEndUId + '/' + this.sessionStorage.enterpriseId + '/');
  };
  processDemographicAttribute(endToEndId: number, changeType: string) {
    return this.httpService.post('/api/ProcessDemographicAttribute/' + endToEndId + '/Type/' + (changeType ? changeType : "All") + '?endtoendUId=' + this.sessionStorage.endToEndUId);
  };
  getSelectedContractInfo(endtoendId: number, contractCode: string) {
    return this.httpService.get("/api/Demographics/ContractInfo/" + endtoendId + '/' + contractCode, true)
  };
  validateContract(contractCode: string, endToEndId: number, clientId: number, companyId: number, lifecyclePhaseId: number, deployRegion: string) {
    return this.httpService.get("/api/Demographics/ValidateContract/" + endToEndId + '/' + contractCode + '/' + clientId + '/' + companyId + '/' + lifecyclePhaseId + '/' + deployRegion + '/' + this.sessionStorage.enterpriseId, true)
  };
  DeatagContract(contractCode: string, isBaseEndToEnd: number, baseEndToEndId: number) {
    return this.httpService.get("/api/Demographics/DetagContract/" + contractCode + '/' + isBaseEndToEnd + '/' + baseEndToEndId + '/' + this.sessionStorage.enterpriseId, true)
  };
  getMetroCityDetails(endtoendDeliveryCenterId: number, gdnType: any, endtoendId: number) {
    return this.httpService.get("/api/demographics/portfolio/metrocity/" + endtoendId + '/' + endtoendDeliveryCenterId + '/' + gdnType, true)
  };
  SaveMetroCityDetails(data: any) {
    return this.httpService.post('/api/demographics/portfolio/metrocity/' + this.sessionStorage.enterpriseId, data, true);
  };
  getContractTransitionStatus(contractID: number) {
    return this.httpService.get("/api/demographics/contract/" + contractID + "/transition/status", true)
  };
  savePhoenixBaseUrlConfiguration(data: any) {
    return this.httpService.post('/api/phoenix/baseurl/configuration/clientId/', data, true);
  };
  savePhoenixConfiguration(data: any) {
    return this.httpService.post('/api/phoenix/configuration/endtoend', data, true);
  };
  validatePhoenixMapping(endtoendId: number, cmsId: number, data: any) {
    return this.httpService.post('/api/phoenix/mapping/validation?endtoendId=' + endtoendId + '&cmsId=' + cmsId, data, true);
  }
  getCurrentSustainabilityLevelName(endToEndUId: string) {
    return this.httpService.get('/api/Demographics/' + endToEndUId + '/currentsustainabilitylevel', true);
  };

  getPrimaryIndustryDetails(endtoendId: number, managementMarketId: number, managementMarketUnitId: number, managementClientGroupId: number, clientId: number, calculatePrimaryIndustrySegment: boolean, primaryClientIndustrySegmentId: any, industryInputs: string = "") {
    return this.httpService.get('/api/demographics/primaryindustrydetails?endtoendId=' + endtoendId + '&managementMarketId=' + managementMarketId + '&managementMarketUnitId=' + managementMarketUnitId + '&managementClientGroupId=' + managementClientGroupId + '&clientId=' + clientId + '&calculatePrimaryIndustrySegment=' + calculatePrimaryIndustrySegment + '&primaryClientIndustrySegmentId=' + primaryClientIndustrySegmentId + '&industryInputs=' + industryInputs, true);
  };
}
