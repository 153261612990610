<!--Process.Review.Summary-->
<!--<div *ngIf="tile && tile.ShortDescription=='PRS'">
  <div class="row tile-metrics tile-metrics-xs">
    <div class="col-xs-6">
      <div class="metrics-value"><strong [innerHtml]="tile && tile.AuditSummary!=null? tile.AuditSummary.Scheduled:0"></strong></div>
      <div class="metrics-text"><span>Scheduled</span></div>
    </div>
    <div class="col-xs-6">
      <div class="metrics-value"><strong [innerHtml]="tile && tile.AuditSummary!=null? tile.AuditSummary.Inprogress:0"></strong></div>
      <div class="metrics-text"><span>In Progress</span></div>
    </div>
  </div>
  <hr />
  <div class="row tile-metrics tile-metrics-xs">
    <div class="col-xs-6">
      <div class="metrics-value"><strong [innerHtml]="tile && tile.AuditSummary!=null? tile.AuditSummary.Delayed:0"></strong></div>
      <div class="metrics-text"><span>Delayed</span></div>
    </div>
    <div class="col-xs-6">
      <div class="metrics-value"><strong [innerHtml]="tile && tile.AuditSummary!=null? tile.AuditSummary.Completed:0"></strong></div>
      <div class="metrics-text"><span>Completed</span></div>
    </div>
  </div>
</div>-->


    <!--<div class="c-portfolio-card__body" *ngIf="tile && tile.ShortDescription=='PRS'">-->
      <div class="c-portfolio-summary" *ngIf="tile && tile.ShortDescription=='PRS'">
        <div class="c-portfolio-summary-col">
          <div class="c-portfolio-summary-item">
            <p>Scheduled</p>
            <span [innerHtml]="tile && tile.AuditSummary!=null? tile.AuditSummary.Scheduled:0"></span>
          </div>
        </div>
        <div class="c-portfolio-summary-col">
          <div class="c-portfolio-summary-item">
            <p>In progress</p>
            <span [innerHtml]="tile && tile.AuditSummary!=null? tile.AuditSummary.Inprogress:0"></span>
          </div>
        </div>
        <div class="c-portfolio-summary-col">
          <div class="c-portfolio-summary-item">
            <p>Delayed</p>
            <span [innerHtml]="tile && tile.AuditSummary!=null? tile.AuditSummary.Delayed:0"></span>
          </div>
        </div>
        <div class="c-portfolio-summary-col">
          <div class="c-portfolio-summary-item">
            <p>Completed</p>
            <span [innerHtml]="tile && tile.AuditSummary!=null? tile.AuditSummary.Completed:0"></span>
          </div>
        </div>
      </div>
    <!--</div>-->
   

<!--Stage Gate Review Summary-->
<!--<div *ngIf="tile && tile.ShortDescription=='SGRS'">
  <div><strong>SGR1</strong></div>
  <div class="row tile-metrics tile-metrics-xs">
    <div class="col-xs-4">
      <div class="metrics-value"><strong [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR1.Scheduled:0"></strong></div>
      <div class="metrics-text" style="font-size:12.5px;"><span>Scheduled</span></div>
    </div>
    <div class="col-xs-4">
      <div class="metrics-value"><strong [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR1.Delayed:0"></strong></div>
      <div class="metrics-text" style="font-size:12.5px;"><span>Delayed</span></div>
    </div>
    <div class="col-xs-4">
      <div class="metrics-value"><strong [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR1.Completed:0"></strong></div>
      <div class="metrics-text" style="font-size:12.5px;"><span>Completed</span></div>
    </div>
  </div>
  <hr class="margin-sgr" />
  <div><strong>SGR2</strong></div>
  <div class="row tile-metrics tile-metrics-xs">
    <div class="col-xs-4">
      <div class="metrics-value"><strong [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR2.Scheduled:0"></strong></div>
      <div class="metrics-text" style="font-size:12.5px;"><span>Scheduled</span></div>
    </div>
    <div class="col-xs-4">
      <div class="metrics-value"><strong [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR2.Delayed:0"></strong></div>
      <div class="metrics-text" style="font-size:12.5px;"><span>Delayed</span></div>
    </div>
    <div class="col-xs-4">
      <div class="metrics-value"><strong [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR2.Completed:0"></strong></div>
      <div class="metrics-text" style="font-size:12.5px;"><span>Completed</span></div>
    </div>
  </div>
</div>-->


    <!--<div class="c-portfolio-card__body" *ngIf="tile && tile.ShortDescription=='SGRS'">-->
      <div class="c-portfolio-review" *ngIf="tile && tile.ShortDescription=='SGRS'">
        <div class="c-portfolio-review-item">
          <p>SGR1</p>
          <p>SGR2</p>
        </div>
        <div class="c-portfolio-review-item">
          <span [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR1.Scheduled:0"></span>
          <div>Scheduled</div>
          <span [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR2.Scheduled:0"></span>
        </div>
        <div class="c-portfolio-review-item">
          <span [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR1.Delayed:0"></span>
          <div>Delayed</div>
          <span [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR2.Delayed:0"></span>
        </div>
        <div class="c-portfolio-review-item">
          <span [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR1.Completed:0"></span>
          <div>Completed</div>
          <span [innerHTML]="tile && tile.AuditSummarySGR!=null? tile.AuditSummarySGR.SGR2.Completed:0"></span>
        </div>
      </div>
    <!--</div>-->
   


<!--ICIS Dashboard-->
<div *ngIf="tile && tile.ShortDescription=='ICIDB'">
  <div class="row tile-metrics tile-metrics-xs">
    <div class="col-xs-6">
      <div class="metrics-value"><strong [innerHtml]="tile && tile.AuditSummaryICISMA!=null? tile.AuditSummaryICISMA.Scheduled:0"></strong></div>
      <div class="metrics-text"><span>Scheduled</span></div>
    </div>
    <div class="col-xs-6">
      <div class="metrics-value"><strong [innerHtml]="tile && tile.AuditSummaryICISMA!=null? tile.AuditSummaryICISMA.Inprogress:0"></strong></div>
      <div class="metrics-text"><span>In Progress</span></div>
    </div>
  </div>
  <hr class="margin-sgr" />

  <div class="row tile-metrics tile-metrics-xs">
    <div class="col-xs-6">
      <div class="metrics-value"><strong [innerHtml]="tile && tile.AuditSummaryICISMA!=null? tile.AuditSummaryICISMA.Delayed:0"></strong></div>
      <div class="metrics-text"><span>Delayed</span></div>
    </div>

    <div class="col-xs-6">
      <div class="metrics-value"><strong [innerHtml]="tile && tile.AuditSummaryICISMA!=null? tile.AuditSummaryICISMA.Completed:0"></strong></div>
      <div class="metrics-text"><span>Completed</span></div>
    </div>

  </div>

</div>


