import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionStorage } from '../context/sessionstorage';
import { Observable } from 'rxjs';

@Injectable()
export class PlatformAccessService {

  constructor(private sessionStorage: SessionStorage, private httpService: HttpService) { }

  getPlatformData() {
    return this.httpService.get('/api/Resource/PlatformAccess/' + this.sessionStorage.endToEndId + '/' + this.sessionStorage.endToEndUId +
      '?deployRegion=' + this.sessionStorage.deployRegion, true)
  }
  saveUsers(data: any): Observable<any> {
    return this.httpService.post('/api/Resource/PlatformAccess/Save', data)
  }
}
