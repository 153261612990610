import * as d3 from 'd3';

class Common {
  barSpacingCharacter = '___';

  public addAttributes(attrs : any): any {
    const attributes : any[] = [];
    Object.getOwnPropertyNames(attrs).forEach((property) => {
      const attr = { name: property, value: attrs[property] };
      attributes.push(attr);
    });
    return attributes;
  }

  public getSVG(selector: any, width: any, height: any, isSelectorClass?: any) {
    if (isSelectorClass) {
      return d3.select(`.${selector}`).append('svg')
        .attr('id', 'app-svg')
        .attr('class', 'svg-style')
        .attr('width', width)
        .attr('height', height)
        .attr('preserveAspectRatio', 'xMidYMid meet');
    }
    return d3.select(`#${selector}`).append('svg')
      .attr('id', 'app-svg')
      .attr('class', 'svg-style')
      .attr('width', width)
      .attr('height', height)
      .attr('preserveAspectRatio', 'xMidYMid meet');
  }

  public clearSVG(selector?: any) {
    if (selector) {
      d3.select(`#${selector}`).select('#app-svg').remove();
    } else {
      d3.select('#app-svg').remove();
    }
  }

  public clearLegendSVG(selector: any) {
    if (selector) {
      d3.select(`#${selector}`).select('#app-legend-svg').remove();
    } else {
      d3.select('#app-legend-svg').remove();
    }
  }

  /**
 * to generate equal spacing between bars for  last page view only.
 * @param viewData
 * @param noOfBars
 * @param dataFormat
 * @param xScalePropName
 * @returns data
 */
  enabledBarSpacing(viewData: any, noOfBars: any, dataFormat: any, xScalePropName: any): any {
    const diff = noOfBars - viewData.length;
    for (let index = 0; index < diff; index++) {
      const data = { ...dataFormat };
      data[xScalePropName] = this.barSpacingCharacter + index.toString();
      viewData.push(data);
    }
    return viewData;
  }

  exportHTMLToWord(id: string, fileName: string) {
    const header = `<html xmlns:o='urn:schemas-microsoft-com:office:office' 
          xmlns:w='urn:schemas-microsoft-com:office:word'
          xmlns='http://www.w3.org/TR/REC-html40'>
          <head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>`;
    const footer = '</body></html>';
    const x = document.getElementById(id);
    const sourceHTML = x !== null ? header + x.innerHTML + footer : '';
    const source = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(sourceHTML)}`;
    const fileDownload = document.createElement('a');
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = `${fileName}.doc`;
    fileDownload.click();
    document.body.removeChild(fileDownload);
  }
}
const common = new Common();
export { common };
