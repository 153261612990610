import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionStorage } from '../context/sessionstorage';

@Injectable()

export class AvanadeEACService {

  constructor(private sessionStorage: SessionStorage, private httpService: HttpService) { }

  GetAvanadeEAC() {
    return this.httpService.get('/api/avanadeEAC/' + this.sessionStorage.endToEndId, true);
  }
  SaveAvanadeEAC(data: any) {
    return this.httpService.post('/api/avanadeEAC/', data, true);
  }
}
