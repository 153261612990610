import { Component, ElementRef, Output, EventEmitter, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { DemographicsService } from '../../core/services/demographics.service';
import { authServices } from '../../core/context/constants';
import { ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
declare var $: any;
import cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'auto-complete',
  templateUrl: './auto.complete.component.html',
  providers: [DemographicsService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true
    }

  ]
})

export class AutoCompleteComponent implements ControlValueAccessor {
  @Input() hiddenFieldID: string;
  @Output() selectPeoplePicker = new EventEmitter();
  @Input() selected: any;
  @Input() isInValidAccentureEmp: boolean
  @Input() required: boolean;
  @Input() txtStyle: string;
  @Input() formname: NgForm;
  @Input() name: string;
  @Input() id: string;
  @Input() disabled: boolean;
  @Input() ariaLabelledby: string; // reused for class
  @Input() placeholderText: string;
  hiddenValue: string;
  gridPeoplePickerValid: boolean = true;
  selectedEnterpriseId: string;
  constructor(private demographics: DemographicsService, private _element: ElementRef, private cdref: ChangeDetectorRef) { }
  ngOnInit() {

  }
  ngOnChanges() {
    if (!this.selected) {
      this.validateCustomControls();

    }
  }
  ngAfterViewInit() {
    this.hiddenValue = cloneDeep(this.selected);
    this.cdref.detectChanges();
    // autocomplete start
    var demographics = this.demographics;
    var eventID = 'peoplePicker_' + this.id;
    $("#" + eventID).autocomplete({
      source: (request, response) => {
        this.formname?.controls[this.name]?.markAsDirty();
        if (this.ariaLabelledby == "DeliveryLead" || this.ariaLabelledby == "reportingPortfolioDeliveryLead" || this.ariaLabelledby == "serviceGroupDeliveryLead" || this.ariaLabelledby == "EnterpriseId" || this.ariaLabelledby == "OverallDeliveryLead" || this.ariaLabelledby.indexOf("poc_teamlead") > 1) {
          this.gridPeoplePickerValid = this.selected != null && this.selected != '' ? false : true;
        }
        if (this.selected && this.selected.length >= 2) {
          authServices.IsLocal = false;
          if (authServices.IsLocal) {
            var json = [{ EnterpriseId: 'bharat.verma' }, { EnterpriseId: 'girish.hulkoti' }, { EnterpriseId: 's.j.nagarajan' },
            { EnterpriseId: 'shanmuganathan.pethu' }, { EnterpriseId: 'p.d.radhakrishnan' }, { EnterpriseId: 'siby.abraham' }];
            response($.map(json, function (item) {
              return {
                value: item.EnterpriseId
              }
            }));
          }
          else {
            demographics.peoplepicker(this.selected).subscribe((json: any) => {
              response($.map(json, function (item) {
                return {
                  value: item.EnterpriseId
                }
              }));
            });
          }
        }
      },
      select: (event, ui) => {
        this.value = ui.item.value;
        this.selected = ui.item.value;
        this.onChange(this.value);
        this.validateCustomControls();
        this.hiddenValue = ui.item.value;
        this.selectPeoplePicker.emit();
        this.selectedEnterpriseId = ui.item.value;
        if (this.ariaLabelledby == "DeliveryLead" || this.ariaLabelledby == "reportingPortfolioDeliveryLead" || this.ariaLabelledby == "EnterpriseId" || this.ariaLabelledby == "OverallDeliveryLead" || this.ariaLabelledby == "serviceGroupDeliveryLead" || this.ariaLabelledby.indexOf("poc_teamlead") > 1) {
          this.gridPeoplePickerValid = true;
        }
        return false;
      },
      appendTo: $('#' + 'peoplePicker_div' + this.id)
    });
    // autocomplete end
  }

  validateCustomControls() {
    this.value = this.selected;
    this.onChange(this.value);

    if (this.required && this.name && this.formname) {
      let dropDownField = this.formname.controls[this.name];
      if (dropDownField) {
        if (this.selected == null || this.selected == '') {
          dropDownField.setErrors({ 'incorrect': true });
          dropDownField.setValidators([Validators.required])
        } else {
          dropDownField.setValidators([]);
          dropDownField.updateValueAndValidity()
        }
      }
    }
  }
  @Input('value') _value = false;
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;

  }


  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
      this.selected = this.value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
