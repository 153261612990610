<div class="c-myWizard-container">
  <div class="c-myWizard__header">
    <div class="c-myWizard__header-content-left">
      <div class="c-myWizard__header-content-head">
        <a href="javascript:;" class="icon-banner-arrow" role="button" alt="Back" aria-label="Back" (click)="OnBackClick()"></a>
        <div class="-header">Metric Selection & Context</div>
      </div>
      <p class="c-myWizard-text">Metrics displayed in this tab is based on the Delivery type and Functions</p>
    </div>
    <div class="c-myWizard__header-content-right">
      <div class="c-header-data">
        <div class="c-header-dropdown">
          <div class="c-dropdown -right -static">
            <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true"  class="-dropdown-icon"><diV class="display-inline" data-myw-title="Download" data-myw-toggle="tooltip" data-myw-placement="bottom"><img src="../assets/toolkit/images/key-contacts/download.svg" alt="Download" /></diV></a>
            <div class="c-dropdown-menu">
              <div class="c-dropdown-body">
                <a href="javascript:;" class="c-dropdown-item" role="button" data-myw-title="Click here to download metrics applicability details" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="showApplicabilityPopup()">Metrics Applicability</a>
              </div>
            </div>
          </div>
          <a href="javascript:;" role="button" data-myw-title="Filter" data-myw-toggle="tooltip" data-myw-placement="bottom" class="-dropdown-icon" data-flyoutid="metric-filter-flyout" (click)="displayRefineBy()"><img src="../assets/toolkit/images/thresholds/filter.svg" alt="Filter" /></a>
          <a href="javascript:;" role="button" data-myw-title="User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="DisplayMetricsuserUserGuide()" class="-dropdown-icon"><img src="../assets/toolkit/images/user-guide-stories/user-guide.svg" alt="User Guide" /></a>
        </div>
      </div>
      <div class="c-header-data">
        <a href="javascript:;" class="c-myWizard__header-details" [ngClass]="accessFlag?' -disabled':''" role="button" (click)="onCustomize(-1)" data-flyoutid="add-custom-metric" tabindex="-1">
          <img src="../assets/toolkit/images/key-contacts/add.svg" alt="Add" role="presentation" />
          <span>Custom Metric</span>
        </a>
      </div>
    </div>
  </div>
  <div class="c-table-responsive -metric-table">
    <table class="c-table c-table-fixed">
      <thead>
        <tr>
          <!--(sortBy=='MetricName'?'c-table-sort':'')-->
          <th scope="col" width="230px"><a href="javascript:;" [ngClass]="(reverse1 == true)?'c-table-sort active':'c-table-sort'" (click)="changeSorting('MetricName')">Metric Name (Abbreviation)</a></th>
          <th scope="col" width="200px"><a href="javascript:;" [ngClass]="(reverse2 == true)?'c-table-sort active':'c-table-sort'" (click)="changeSorting('ApplicabilitySource')">Recommended Source</a></th>
          <th scope="col" width="120px"><a href="javascript:;" [ngClass]="(reverse3 == true)?'c-table-sort active':'c-table-sort'" (click)="changeSorting('Applicability')">Applicability</a></th>
          <th scope="col" width="100px" class="c-text-center">In scope</th>
          <th scope="col" width="220px">Business Context</th>
          <th scope="col" width="200px" *ngIf="commentflag==true">Comment</th>
        </tr>
      </thead>
      <tbody *ngIf="tabledefaultheight==true && (metricsConfigData.length==1 || metricsConfigData.length==2)" style="height:230px;">
        <tr *ngIf="metricsConfigData == null || metricsConfigData == undefined ||metricsConfigData.length==0">
          <td colspan="6"> No Records Found </td>
        </tr>
        <tr *ngFor="let metricConfig of metricsConfigData;let i=index">
          <td><a href="javascript:;" role="button" data-flyoutid="metric-detail" (click)="onCustomize(metricConfig.MetricId)" (keypress.enter)="onCustomize(metricConfig.MetricId)">{{metricConfig.MetricName}}<span *ngIf="metricConfig.Abbreviation">({{metricConfig.Abbreviation}})</span></a></td>
          <td>{{metricConfig.ApplicabilitySource}}</td>
          <td (mouseover)="getApplicability(metricConfig.MetricId)" (focus)="getApplicability(metricConfig.MetricId)" title="{{ApplicabilitySourceData}}">{{metricConfig.Applicability}}</td>
          <td class="c-text-center" *ngIf="accessFlag">
            <div class="c-checkbox  ">
              <input type="checkbox"
                     id="check-one"
                     class="c-checkbox__input"
                     [attr.name]="'unitcheckbox'+i"
                     [(ngModel)]="metricConfig.IsSelected"
                     [disabled]="metricConfig.Applicability == 'Mandatory' ? true : false || accessFlag"
                     (change)="changeSwitchs(metricConfig)" />
              <i class="c-checkbox__checked"></i>
            </div>
          </td>
          <td class="c-text-center" *ngIf="!accessFlag">
            <div class="c-checkbox  ">
              <input type="checkbox"
                     id="check-one"
                     class="c-checkbox__input"
                     [attr.name]="'unitcheckbox'+i"
                     [(ngModel)]="metricConfig.IsSelected"
                     [disabled]="metricConfig.Applicability == 'Mandatory' ? true : false || accessFlag"
                     (change)="changeSwitchs(metricConfig)" />
              <i class="c-checkbox__checked"></i>
            </div>
          </td>
          <td>
            <div class="c-myWizard-form__input-area" style="margin-top: 20px !important;">
              <div class="c-custom-select__wrapper">
                <search-dropdown [attr.id]="'bc'+i"
                                 [options]="metricConfig.businessContexts"
                                 [disableDropdown]="(!metricConfig.IsSelected || selectedLevelId || accessFlag)"
                                 [isShow]="true"
                                 [selected]="metricConfig.BusinessContextId"
                                 [selectText]="'Select'"
                                 [labelInfo]="'Business Context'"
                                 [(ngModel)]="metricConfig.BusinessContextId"
                                 (callback)="changeContext(metricConfig.BusinessContextId,i,metricConfig)"
                                 (click)="settableheight(metricsConfigData.length)"
                                 click-elsewhere (clickOutside)="Resettableheight(1)">
                </search-dropdown>

                <!--<div class="c-select-dropdown " [ngClass]="(!metricConfig.IsSelected || selectedLevelId || accessFlag)?'-disabled':''">
          <div class="c-dropdown -right" [attr.id]="'bc'+i">
            <a href="javascript:;" [attr.id]="'a'+i" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">
              {{metricConfig.BusinessContextValue}}
            </a>
            <div class="c-dropdown-menu">
              <div class="c-dropdown-body">
                <a href="javascript:;" role="button" class="c-dropdown-item" [attr.title]="'select'" (click)="changeContext(null,i,metricConfig)"> Select </a>
                <a href="javascript:;" role="button" class="c-dropdown-item" [attr.title]="context.Name" *ngFor="let context of businessContexts" (click)="changeContext(context.Id,i,metricConfig)"> {{context.Name}}</a>
              </div>
            </div>
          </div>-->
                <!--<p class="c-select__error-text">Enter the value</p>-->
                <!--</div>-->
                <br />
                <!--<div class="o-input" *ngIf="metricConfig.BusinessContextId==2 || metricConfig.BusinessContextId==3">
            <input [disabled]="!metricConfig.IsSelected || selectedLevelId || accessFlag"
                   type="text"
                   class="o-input__input" id="text" value=""
                   [(ngModel)]="metricConfig.PenaltyDescription" />
          </div>-->
              </div>
            </div>

          </td>
          <td *ngIf="commentflag==true">
            <div class="o-input" *ngIf="metricConfig.BusinessContextId==2 || metricConfig.BusinessContextId==3">
              <input [disabled]="!metricConfig.IsSelected || selectedLevelId || accessFlag"
                     (change)="onchangecomment(metricConfig,$event.target.value)"
                     type="text"
                     class="o-input__input" id="text" value=""
                     [(ngModel)]="metricConfig.PenaltyDescription" />
            </div>
            <div class="o-input" style="pointer-events:none" *ngIf="metricConfig.BusinessContextId!=2 && metricConfig.BusinessContextId!=3">
              <input [disabled]="1==1"
                     type="text"
                     class="o-input__input" id="text" value="" />
            </div>

          </td>
        </tr>
      </tbody>
      <tbody *ngIf="tabledefaultheight==false">
        <tr *ngIf="metricsConfigData == null || metricsConfigData == undefined ||metricsConfigData.length==0">
          <td colspan="6"> No Records Found </td>
        </tr>
        <tr *ngFor="let metricConfig of metricsConfigData;let i=index">
          <td><a href="javascript:;" role="button" data-flyoutid="metric-detail" (click)="onCustomize(metricConfig.MetricId)" (keypress.enter)="onCustomize(metricConfig.MetricId)">{{metricConfig.MetricName}}<span *ngIf="metricConfig.Abbreviation">({{metricConfig.Abbreviation}})</span></a></td>
          <td>{{metricConfig.ApplicabilitySource}}</td>
          <td (mouseover)="getApplicability(metricConfig.MetricId)" (focus)="getApplicability(metricConfig.MetricId)" title="{{ApplicabilitySourceData}}">{{metricConfig.Applicability}}</td>
          <td class="c-text-center" *ngIf="accessFlag">
            <div class="c-checkbox  ">
              <input type="checkbox"
                     id="check-one"
                     class="c-checkbox__input"
                     [attr.name]="'unitcheckbox'+i"
                     [(ngModel)]="metricConfig.IsSelected"
                     [disabled]="metricConfig.Applicability == 'Mandatory' ? true : false || accessFlag"
                     (change)="changeSwitchs(metricConfig)" />
              <i class="c-checkbox__checked"></i>
            </div>
          </td>
          <td class="c-text-center" *ngIf="!accessFlag">
            <div class="c-checkbox  ">
              <input type="checkbox"
                     id="check-one"
                     class="c-checkbox__input"
                     [attr.name]="'unitcheckbox'+i"
                     [(ngModel)]="metricConfig.IsSelected"
                     [disabled]="metricConfig.Applicability == 'Mandatory' ? true : false || accessFlag"
                     (change)="changeSwitchs(metricConfig)" />
              <i class="c-checkbox__checked"></i>
            </div>
          </td>
          <td>
            <div class="c-myWizard-form__input-area" style="margin-top: 20px !important;">
              <div class="c-custom-select__wrapper">
                <search-dropdown [attr.id]="'bc'+i"
                                 [options]="metricConfig.businessContexts"
                                 [disableDropdown]="(!metricConfig.IsSelected || selectedLevelId || accessFlag)"
                                 [isShow]="true"
                                 [selected]="metricConfig.BusinessContextId"
                                 [selectText]="'Select'"
                                 [labelInfo]="'Business Context'"
                                 [(ngModel)]="metricConfig.BusinessContextId"
                                 (callback)="changeContext(metricConfig.BusinessContextId,i,metricConfig)"
                                 (click)="settableheight(metricsConfigData.length)"
                                 click-elsewhere (clickOutside)="Resettableheight(2)">
                </search-dropdown>

                <!--<div class="c-select-dropdown " [ngClass]="(!metricConfig.IsSelected || selectedLevelId || accessFlag)?'-disabled':''">
            <div class="c-dropdown -right" [attr.id]="'bc'+i">
              <a href="javascript:;" [attr.id]="'a'+i" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">
                {{metricConfig.BusinessContextValue}}
              </a>
              <div class="c-dropdown-menu">
                <div class="c-dropdown-body">
                  <a href="javascript:;" role="button" class="c-dropdown-item" [attr.title]="'select'" (click)="changeContext(null,i,metricConfig)"> Select </a>
                  <a href="javascript:;" role="button" class="c-dropdown-item" [attr.title]="context.Name" *ngFor="let context of businessContexts" (click)="changeContext(context.Id,i,metricConfig)"> {{context.Name}}</a>
                </div>
              </div>
            </div>-->
                <!--<p class="c-select__error-text">Enter the value</p>-->
                <!--</div>-->
                <br />
                <!--<div class="o-input" *ngIf="metricConfig.BusinessContextId==2 || metricConfig.BusinessContextId==3">
              <input [disabled]="!metricConfig.IsSelected || selectedLevelId || accessFlag"
                     type="text"
                     class="o-input__input" id="text" value=""
                     [(ngModel)]="metricConfig.PenaltyDescription" />
            </div>-->
              </div>
            </div>

          </td>
          <td *ngIf="commentflag==true">
            <div class="o-input" *ngIf="metricConfig.BusinessContextId==2 || metricConfig.BusinessContextId==3">
              <input [disabled]="!metricConfig.IsSelected || selectedLevelId || accessFlag"
                     (change)="onchangecomment(metricConfig,$event.target.value)"
                     type="text"
                     class="o-input__input" id="text" value=""
                     [(ngModel)]="metricConfig.PenaltyDescription" />
            </div>
            <div class="o-input" style="pointer-events:none" *ngIf="metricConfig.BusinessContextId!=2 && metricConfig.BusinessContextId!=3">
              <input [disabled]="1==1"
                     type="text"
                     class="o-input__input" id="text" value="" />
            </div>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="c-pagination-container">
    <nav>
      <ul class="c-pagination_list">
        <li class="c-pagination_arrow">
          <a href="javascript:;" class="c-pagination_previous" (click)="curPage=curPage-1;pagination(curPage)"
             [ngClass]="{'disablebtn': curPage == 0}" [disabled]="curPage == 0" tabindex="-1">
            <img src=" ../assets/toolkit/images/pagination-icon.svg" alt="Previous">
          </a>
        </li>
        <li class="c-pagination_page-item">
          <a class="c-pagination_link"  tabindex="-1">
            <span class="c-pagination_count" [disabled]="curPage == 0">{{curPage + 1}}</span>
            <span class="c-pagination_target" [disabled]="curPage >=  totalMetrics/pageSize - 1"> <span>of </span>  {{noOfPages}}</span>
          </a>
        </li>
        <li class="c-pagination_arrow">
          <a href="javascript:;" class="c-pagination_next"
             (click)="curPage=curPage+1;pagination(curPage)"
             [ngClass]="{'disablebtn': curPage >=  totalMetrics/pageSize - 1}" tabindex="-1">
            <img src=" ../assets/toolkit/images/pagination-icon.svg" alt="Next">
          </a>
        </li>
      </ul>
    </nav>
    <div class="position-relative">
      <label class="sr-only">Page Selection</label>
    </div>
  </div>
</div>
<div class="c-myWizard-update__container ">
  <div class="c-myWizard-container">
    <div class="c-myWizard-update__row">
      <div class="c-myWizard-update__details" *ngIf="lastUpdateOn!=null">
        <div class="c-myWizard-update__input">
          <label>Last Updated On:</label>
          <span class="-input">{{lastUpdateOn}} (GMT)</span>
        </div>
        <div class="c-myWizard-update__input">
          <label>Last Updated By:</label>
          <span class="-input">{{lastUpdateBy}}</span>
        </div>
      </div>     
      <div class="c-myWizard-update-link">
        <a href="https://in.accenture.com/accenturedeliverysuite/metrics" target="_blank" rel="noopener noreferrer" aria-label="ADS (Open in a new tab)"><i class="icon-external-link"></i><div>Launch <span>ADS </span>to view more details on Metrics</div></a>
      </div>
    </div>
  </div>
</div>



<div class="c-select-card">
  <div class="container">
    <div class="c-select-card__wrapper">
      <div class="c-select-card__left">
        <div class="c-select-card__text">Changes made will be lost unless confirmed. Please click 'Save' button to confirm.</div>
      </div>
      <div class="c-select-card__right">
        <button class="o-secondary-button" role="button" [ngClass]="isMetricModified()||!accessFlag?'':'-disabled'"  (click)="resetChanges()">Reset</button>
        <button class="o-primary-button " role="button" [ngClass]="isMetricModified()||!accessFlag?'':'-disabled'" 
                (click)="saveConfig(metricsConfigData)"
                (keyup.enter)="saveConfig(metricsConfigData)">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<div class="c-select-card__placeholder"></div>


