<div class="c-dropdown" [ngClass]="{'has-error':required && formname.submitted && (SelectedItems==null||SelectedItems==undefined || SelectedItems.length <= 0)}">
  <span id="info_multiselectdropdown" class="screenReaderOnly">Use the tab key to navigate through the items in the list. </span>
  <a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-custom-select__selected-text icon-arrow-down" (click)="SortSelection()">
    <div class="c-custom-select__chipWrap">
      <div [attr.name]="'multiselect_btn_' + ariaLabelledby"
           class="c-custom-select__chip"
           [ngClass]="{'has-error': isShowValidation}"
           [attr.aria-labelledby]="ariaLabelledby"
           aria-describedby="info_multiselectdropdown">
        <ng-container *ngFor="let option of SelectedItems;let i= index;">
          <div *ngIf="i< maxChipCount" class="c-myWizard__selected-chip">
            <span>{{option.Name}}</span>
            <i [ngClass]="{'multiselect-close-hide': disableDropdown}" class="icon-cross" aria-label="close" (click)="RemoveItem(option,$event)"></i>
          </div>
        </ng-container>
        <div *ngIf="!SelectedItems || SelectedItems.length <= 0">{{selectText}}</div>
      </div>
    </div>
    <div *ngIf="SelectedItems &&  SelectedItems.length > maxChipCount" class="c-myWizard__selected-chip-more"><span>{{SelectedItems &&  SelectedItems.length > maxChipCount ? "+" + (SelectedItems.length - maxChipCount) : ""}}</span></div>
  </a>

  <div class="c-dropdown-menu" *ngIf="!ishierarchy && (selectedparent == null || selectedparent == undefined)">
    <div role="listbox" class="c-dropdown-body">
      <div class="c-custom-control" *ngIf="options?.length > 5">
        <span><i class="icon-search" aria-label="search"></i></span>
        <input type="text" placeholder="Search" [(ngModel)]="query" />
      </div>
      <div>
        <a *ngIf="query && (options | filterByText: query : filterId).length <=0 " class="c-dropdown-item">No values found</a>
        <ng-container *ngIf="options?.length <= 5">
          <div *ngFor="let option of options;let i= index;let l= last; trackBy : trackByName;" role="option"
               href="javascript:;"
               class=" -selectable c-dropdown-item" tabindex="0">
            <div class="c-checkbox " [ngClass]="{'-disabled': disableDropdown}">
              <input type="checkbox" id="{{ariaLabelledby +'_'+ i}}" name="{{ariaLabelledby + '_focus_'+ i}}"
                     (keydown)="MultiSelectKeyPress(option,$event,ariaLabelledby,'input',l);"
                     class="c-checkbox__input" [disabled]="disableDropdown"
                     [checked]="option.IsSelected" (click)="OnSelect(option)" />
              <i class="c-checkbox__checked"></i>
              <label class="c-checkbox__label" for="{{ariaLabelledby +'_'+ i}}">{{option.Name}}</label>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="options?.length > 5">

          <div *ngFor="let option of options| filterByText:query : filterId;let i= index;let l= last; trackBy : trackByName;" role="option"
               href="javascript:;"
               class=" -selectable c-dropdown-item" tabindex="0">
            <div class="c-checkbox " [ngClass]="{'-disabled': disableDropdown}">
              <input type="checkbox" id="{{ariaLabelledby +'_'+ i}}" name="{{ariaLabelledby + '_focus_'+ i}}"
                     (keydown)="MultiSelectKeyPress(option,$event,ariaLabelledby,'input',l);"
                     class="c-checkbox__input" [disabled]="disableDropdown"
                     [checked]="option.IsSelected" (click)="OnSelect(option)" />
              <i class="c-checkbox__checked"></i>
              <label class="c-checkbox__label" for="{{ariaLabelledby +'_'+ i}}">{{option.Name}}</label>
            </div>
          </div>

        </ng-container>
      </div>

    </div>

  </div>
  <div class="c-dropdown-menu" *ngIf="!ishierarchy && selectedparent != null && selectedparent != undefined">
    <div role="listbox" class="c-dropdown-body">
      <div class="c-custom-control" *ngIf="optionsValue.length > 5">
        <span><i class="icon-search" aria-label="search"></i></span>
        <input type="text" placeholder="Search" [(ngModel)]="query" />
      </div>
      <div>
        <a *ngIf="query && (optionsValue | filterByText: query : filterId).length <=0 " class="c-dropdown-item">No values found</a>
        <ng-container *ngIf="optionsValue.length <= 5">
          <div *ngFor="let option of optionsValue;let i= index;let l= last; trackBy : trackByName;" role="option"
               href="javascript:;"
               class=" -selectable c-dropdown-item" tabindex="0">
            <div class="c-checkbox " [ngClass]="{'-disabled': disableDropdown}">
              <input type="checkbox" id="{{ariaLabelledby +'_'+ i}}" name="{{ariaLabelledby + '_focus_'+ i}}"
                     (keydown)="MultiSelectKeyPress(option,$event,ariaLabelledby,'input',l);"
                     class="c-checkbox__input" [disabled]="disableDropdown"
                     [checked]="option.IsSelected" (click)="OnSelect(option)" />
              <i class="c-checkbox__checked"></i>
              <label class="c-checkbox__label" for="{{ariaLabelledby +'_'+ i}}" >{{option.Name}}</label>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="optionsValue.length > 5">

          <div *ngFor="let option of optionsValue| filterByText:query : filterId;let i= index;let l= last; trackBy : trackByName;" role="option"
               href="javascript:;"
               class=" -selectable c-dropdown-item" tabindex="0">
            <div class="c-checkbox " [ngClass]="{'-disabled': disableDropdown}">
              <input type="checkbox" id="{{ariaLabelledby +'_'+ i}}" name="{{ariaLabelledby + '_focus_'+ i}}"
                     (keydown)="MultiSelectKeyPress(option,$event,ariaLabelledby,'input',l);"
                     class="c-checkbox__input" [disabled]="disableDropdown"
                     [checked]="option.IsSelected" (click)="OnSelect(option)" />
              <i class="c-checkbox__checked"></i>
              <label class="c-checkbox__label" for="{{ariaLabelledby +'_'+ i}}">{{option.Name}}</label>
            </div>
          </div>

        </ng-container>
      </div>

    </div>

  </div>
</div>
<p aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'show':(required && formname.submitted  && (selected==''||selected==null||selected==undefined))}">{{errormessage}}</p>


