import { Component, OnInit, HostListener } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { AppConfig } from './core/services/config.service';
import { SharedService } from './core/services/shared.service';
import { Title } from '@angular/platform-browser';
import * as $ from '../JS/jquery-3.5.1.min.js';
import { Router, ActivatedRouteSnapshot, ActivationStart } from '@angular/router';
import { Subject } from 'rxjs'
import { filter, map, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { SimpleGlobal } from 'ng2-simple-global';

@Component({
  selector: 'app-root-component',
  template: `<router-outlet></router-outlet>`,
  providers: [SharedService]
})
export class AppComponent implements OnInit {
  title = 'MyWizard';
  timeOutVariable: any;
  isTimeOutReached: boolean = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(public config: AppConfig,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private sharedService: SharedService, private router: Router,
    private titleService: Title, private global: SimpleGlobal) {

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
        var loggedinUser = this.msalService.instance.getActiveAccount()?.username!;
        this.config.loggedInuser = loggedinUser.substring(0, loggedinUser.indexOf("@"));
        sessionStorage.setItem('enterpriseId', this.config.loggedInuser);
        this.global["enterpriseId"] = this.config.loggedInuser;
        var currentDateTime = new Date();
        this.global["LoginStatus"] = "success";
        currentDateTime.setMinutes(currentDateTime.getMinutes() + 60);
        document.cookie = "UserName=" + this.config.loggedInuser + ";expires=" + currentDateTime.toUTCString() + ";secure;samesite=strict";
      });
  }
  ngOnInit() {
    this.changeTitle();
    $.noConflict();
    if (environment.isAuthRequired) {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe((result: InteractionStatus) => {
          this.checkAndSetActiveAccount();
          var loggedinUser = this.msalService.instance.getActiveAccount()?.username!;
          if (loggedinUser != null) {
            this.config.loggedInuser = loggedinUser.substring(0, loggedinUser.indexOf("@"));
            sessionStorage.setItem('enterpriseId', this.config.loggedInuser);
            this.global["enterpriseId"] = this.config.loggedInuser;
            this.global["LoginStatus"] = "success";
            this.initializeUserAccess();


          }
        })
    }
    else {
      this.config.loggedInuser = 'siby.abraham';
      //this.config.loggedInuser = 'v.sandeep.nagothi';
      sessionStorage.setItem('enterpriseId', this.config.loggedInuser);
      var currentDateTime = new Date();
      currentDateTime.setMinutes(currentDateTime.getMinutes() + 60);
      document.cookie = "UserName=" + this.config.loggedInuser + ";expires=" + currentDateTime.toUTCString() + ";secure;samesite=strict";
      this.global["enterpriseId"] = this.config.loggedInuser;
    }
    this.SetTimeOut();
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  changeTitle() {
    this.router.events.pipe(
      filter(event => event instanceof ActivationStart),
      map(event => (<ActivationStart>event).snapshot),
      map(snapshot => (<ActivatedRouteSnapshot>snapshot).data)).subscribe(data => {
        this.titleService.setTitle(data.title);
      });
  }

  SetTimeOut() {
    if (this.isTimeOutReached == false) {
      this.timeOutVariable = setTimeout(() => {
        this.isTimeOutReached = true;
        var confirmationModel = this.sharedService.openAlertPopup("We have lost connection to the server, most likely due to session time-out. Please try refreshing the page to renew the connection", 0,"Refresh", false, "Your session is about to expire!")
        confirmationModel.content.confirmationResult.subscribe(response => {
          if (response.result) {
            window.location.reload();
          }
        });
      }, 3300000);
    }
  }
  ClearTimeOut() {
    clearTimeout(this.timeOutVariable);
  }

  @HostListener('document:click', ['$event.target'])
  public hideTimeOutPopup() {
    if (this.isTimeOutReached == false) {
      this.ClearTimeOut();
      this.SetTimeOut();
    }
  }
  initializeUserAccess() {

  }

}
