
<div class="c-portfolio">
  <div class="app-container">
    <div class="c-myWizard-card">
      <h1 class="sr-only" title="Managework">Managework</h1>
      <ng-container *ngFor="let tile of tiles; trackBy : trackByIndex;">
        <div class="c-myWizard-card-tile" [ngClass]="{'-col-span': tile.ShortDescription == 'PR' || tile.ShortDescription == 'RRI'}">
          <tile [tile]="tile"></tile>
        </div>
      </ng-container>
    </div>
  </div>

</div>
