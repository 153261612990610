import { Injectable } from '@angular/core'



@Injectable()
export class UtilityService {
  metricValue: any = {};

  commandCenter(firstValue: string, secondValue: string): any {
    let calculatedText = "&nbsp;";
    if (firstValue != null && firstValue != "") {
      if (firstValue.toString().match(/^(\d|\d\.|\.\d)/) != null) {
        calculatedText = parseFloat(firstValue).toFixed(2);
      }
      else if (firstValue.toString().toUpperCase().trim() == "N/A") {
        calculatedText = "N/A";
      }
      else {
        calculatedText = firstValue
      }
    }
    this.metricValue.FirstMetricValue = calculatedText;
    calculatedText = "&nbsp;";
    if (secondValue != null && secondValue != "") {
      if (secondValue.toString().match(/^(\d|\d\.|\.\d)/) != null) {
        calculatedText = parseFloat(secondValue).toFixed(2);
      }
      else if (secondValue.toString().toUpperCase().trim() == "N/A") {
        calculatedText = "N/A";
      }
      else {
        calculatedText = secondValue
      }
    }
    this.metricValue.SecondMetricValue = calculatedText;
    return this.metricValue;
  }

}
