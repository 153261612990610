import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionStorage } from './../context/sessionstorage';


@Injectable()
export class CMSMenuService {
  constructor(private httpService: HttpService, private sessionStorage: SessionStorage) { }

  CMSHierarchyStructure() {
    return this.httpService.get('/api/CMSHierarchy/' + this.sessionStorage.endToEndId +
      '?cmsId=' + this.sessionStorage.clientWMSId +
      '&workstreamId=' + this.sessionStorage.workstreamId, true);
  }

  GETCMS(Id: Number) {
    return this.httpService.get('/api/CMS/' + Id, true);
  }

  GETWorkstream(Id: Number) {
    return this.httpService.get('/api/Workstream/' + this.sessionStorage.endToEndId + '/' + Id, true);
  }

  SaveCMS(data: any) {
    return this.httpService.post('/api/SaveCMS/', data, true);
  }

  SaveWorkStream(data: any) {
    return this.httpService.post('/api/WorkStream/', data, true);
  }

  SaveDraggedCMS(data: any) {
    return this.httpService.post('/api/SaveDraggedCMS/', data, true);
  }

  DeleteCMS(data: any) {
    return this.httpService.post('/api/CMS/DELETE', data, true);
  }

  DeleteWorkstream(data: any) {
    return this.httpService.post('/api/WorkStream/DELETE', data, true);
  }

  RetireCMS(cmsId: Number) {
    return this.httpService.post('/api/CMS/RetireCMS/' + cmsId + '/' + this.sessionStorage.enterpriseId + '/', true);
  }

  GETAllowCMSClosed(Id: Number) {
    return this.httpService.get('/api/CMS/AllowClosed/' + Id, true);
  }

  UpdateUserPreferenceForAutoCreatedWS(userPreferenceDetail: any) {
    return this.httpService.post('/api/preference/workstreams/auto/', userPreferenceDetail, true);
  }

  savePhoenixConfiguration(data: any) {
    return this.httpService.post('/api/phoenix/configuration/cms', data.Result, true);
  }
}
