
<div class="automation-journey-bar-chart-container">
  <div class="c-automation-progress-value" >
    <span>Total Opportunities</span>
    <div >{{TotalOpportunitiesCount}}</div>
    </div>
    <div id="automation-journey-bar-chart-section" class="automation-journey-bar-chart-section">
    </div>
  </div>
  <div class="c-automation-progress" id="progress_bar">
    <div class="c-automation-progress-value">
      <span>Total Value Planned</span>
      <div>{{totalValuePlaned}}</div>
    </div>
    <div class="c-automation-progress-bar">
      <div class="c-automation-progress-bar-value">
        <div class="c-automation-progress-bar-content">
          <div class="c-automation-progress-item" *ngIf="SavingsGraphData && SavingsGraphData.length > 0" [ngClass]="(SavingsGraphData[0].CurrentActualPercentage == 0) ? 'c-automation-progress-item -no-data':'c-automation-progress-item'" [ngStyle]="{'--value': SavingsGraphData[0].CurrentActualPercentage + '%'}" style="--bg-color: #D5ADFA; "></div>
          <span>{{currentActual}}</span>
        </div>
        <div class="c-automation-progress-bar-content">
          <div class="c-automation-progress-item" *ngIf="SavingsGraphData && SavingsGraphData.length > 0" [ngClass]="(SavingsGraphData[0].CurrentPlanPercentage == 0) ? 'c-automation-progress-item -no-data': 'c-automation-progress-item'" [ngStyle]="{'--value': SavingsGraphData[0].CurrentPlanPercentage + '%' }" style="--bg-color: #E2C3FF; "></div>
          <span>{{currentPlan}}</span>
        </div>
      </div>
      <div class="c-legends">
        <div class="c-legends-item">
          <i style="background-color: #E2C3FF;"></i>
          <span>Current Plan</span>
        </div>
        <div class="c-legends-item">
          <i style="background-color: #D5ADFA;"></i>
          <span>Current Actual</span>
        </div>
      </div>
    </div>
  </div>

