import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStorage } from '../../core/context/sessionstorage';
import { enumRoleType, enumSubmissionType, enumModuleAction } from '../../core/context/enum';
import { UserService } from '../../core/services/user.service';
import { SharedService } from '../../core/services/shared.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HeaderComponent } from '../../home/header.component/header.component';
import { environment } from '../../../environments/environment';
var $ = (<any>window).$;

@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [UserService, SharedService, BsModalService, HeaderComponent]
})

export class WelcomeComponent implements OnInit {

  enterpriseId: string;
  date = new Date();
  isUserHasAccess: any = false;

  constructor(
    private router: Router,
    private sessionStorage: SessionStorage,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private headerComponent: HeaderComponent,
    private sharedService: SharedService
  ) { }


  ngOnInit() {
    this.enterpriseId = this.sessionStorage.enterpriseId;
    this.userService.getUserAccess(this.enterpriseId).subscribe((response: any) => {
      if (response != undefined && response != null) {
        this.sessionStorage.isUserHasAccess = response;
        this.loadWelcomePage();
      }
    });
    this.userService.logUserInformation(this.enterpriseId).subscribe((result) => {
    });
  }

  loadWelcomePage() {
    this.initializeSessionStorage();
    this.isUserHasAccess = this.sessionStorage.isUserHasAccess;

    this.userService.getClientAccess(this.enterpriseId).subscribe((response: any) => {
      this.sessionStorage.cardinalClients = response.Clients;
      $('#mainContent').click(); // temporary fix for page not refreshed post token generation
      var clientCountForCardinal = response.Clients.filter(x => x.MasterClientId == this.sessionStorage.clientId).length;
      if (response.ClientIndicator == 'Y') {
        this.sessionStorage.clientAccessEnabled = true;
      }
      if (clientCountForCardinal > 0) {
        this.sessionStorage.isAdminTabVisible = false;
      }
    });

    if (this.isUserHasAccess) {
      this.getStarted();
    } else {
      this.router.navigate(['/accessdenied'], { relativeTo: this.activatedRoute });
    }
    
  }

  initializeSessionStorage() {
    this.sessionStorage.clientId = 0;
    this.sessionStorage.clientUId = '';
    this.sessionStorage.userId = 0;
    this.sessionStorage.roleType = "";
    this.sessionStorage.clientId = 0;
    this.sessionStorage.endToEndId = 0;
    this.sessionStorage.endToEndUId = '';
    this.sessionStorage.userId = 0;
    this.sessionStorage.selectedSubmissionType = 0;
    this.sessionStorage.clientWMSUId = '';
    this.sessionStorage.workstreamUId = '';
    this.sessionStorage.teamUId = '';
    this.sessionStorage.deployRegion = '';
    this.sessionStorage.isAdminTabVisible = false;
    this.sessionStorage.isSupportTabVisible = false;
    this.sessionStorage.statusAsOnPeriod = '';
    this.sessionStorage.isReleaseAnnouncementClosed = false;
    this.sessionStorage.IsProfileCompleted = null;

  }

  getStarted() {
    this.userService.getScopeDetails().subscribe((response: any) => {
      if (response.SelectedClient) {
        var selectedClient = response.SelectedClient;
        var selectedEndToEndGroup = selectedClient.SelectedContractOpportunityInitiativeGroup;
        var selectedEndToEnd = selectedEndToEndGroup;
        this.sessionStorage.clientName = selectedClient.Name;
        this.sessionStorage.clientId = selectedClient.Id;
        this.sessionStorage.clientUId = selectedClient.ClientUId;
        this.sessionStorage.endToEndName = selectedEndToEnd.EndToEndName;
        this.sessionStorage.endToEndId = selectedEndToEnd.EndToEndId;
        this.sessionStorage.endToEndUId = selectedEndToEnd.EndToEndUId;
        this.sessionStorage.roleTypeCode = selectedEndToEnd.RoleTypeCode;
        this.sessionStorage.restrictedInstanceIndicator = selectedEndToEnd.RestrictedInstanceIndicator;
        //this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode == 'V' ? selectedEndToEnd.RoleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : selectedEndToEnd.RoleAccessCode);
        this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode;
        this.sessionStorage.endToEndType = selectedEndToEnd.EndToEndType;
        this.sessionStorage.endToEndRefCode = selectedEndToEnd.EndToEndRefCode;
        this.sessionStorage.roleType = selectedEndToEnd.RoleType;
        this.sessionStorage.deployRegion = selectedEndToEnd.Instance;
        if (selectedEndToEnd.SelectedCMS) {
          this.sessionStorage.clientWMSId = selectedEndToEnd.SelectedCMS.Id;
          this.sessionStorage.clientWMSUId = selectedEndToEnd.SelectedCMS.CMSUId;
          this.sessionStorage.selectedSubmissionType = enumSubmissionType.ClientWMS;
          this.sessionStorage.cmsName = selectedEndToEnd.SelectedCMS.Name;
        }
        else {
          this.sessionStorage.cmsName = "";
          this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
        }
        this.sessionStorage.workstreamName = "";
        this.sessionStorage.workstreamId = 0;
        this.sessionStorage.teamId = 0;
        this.sessionStorage.teamUId = '';
        this.sessionStorage.tmsName = "";
        this.sessionStorage.statusAsOnPeriod = response.CurrentEndPeriod;
        this.sessionStorage.currentPeriod = response.CurrentEndPeriod;
        this.userService.getTabVisibility(this.sessionStorage.enterpriseId).subscribe((response: any) => {
          if (response != null) {
            this.sessionStorage.isSupportTabVisible = response.ISupportTabVisible;
            this.sessionStorage.isAdminTabVisible = response.IsAdminTabVisible;
            this.sessionStorage.AdminAccessType = response.AdminAccessType;
            this.sessionStorage.customRoleTypeCode = response.CustomRoleTypeCode;
            this.headerComponent.DisplayTab();
          }
        });

        if (this.sessionStorage.roleTypeCode == enumRoleType.TeamMember) {
          this.router.navigate(['/mywizard/tasks', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
        }
        else {
          this.router.navigate(['/mywizard/home/E2E', this.sessionStorage.endToEndUId]);
        }
      }
      else {
        this.userService.getTabVisibility(this.sessionStorage.enterpriseId).subscribe((response: any) => {
          if (response != null) {
            this.sessionStorage.isAdminTabVisible = response.IsAdminTabVisible;
            this.sessionStorage.AdminAccessType = response.AdminAccessType;
            this.sessionStorage.customRoleTypeCode = response.CustomRoleTypeCode;
            this.sessionStorage.roleTypeCode = response.CustomRoleTypeCode;
            this.sessionStorage.isSupportTabVisible = response.ISupportTabVisible; //true;
            this.headerComponent.DisplayTab();
            this.router.navigate(['/mywizard/support']);
          }
        });
      }
      this.sharedService.applicationUsageLogging(enumModuleAction.ScopeSelector, null, null).subscribe();

    });
  };




}
