import { Component, Input } from '@angular/core';
import { SessionStorage } from '../../core/context/sessionstorage';
import * as d3 from 'd3';
var Math = window.Math;
import { RIMSummaryService } from '../../core/services/rim.summary.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'issue',
  templateUrl: './issue.component.html'
})

export class IssueComponent {
  EscalationIssue: any;
  RIMSummaryCount: any = {};
  openCount: number;
  activeCount: any;
  OverdueCount: number;
  UpComingCount: number;
  VerifyCount: number;
  escalations: any;
  escalationsCount: any;
  othersCount: any;
  verification: any = 0;
  other: any = 0;
  active: any;
  upcoming: any;
  overdue: any = 0;
  opacityHover: any = 1;
  otherOpacityOnHover: any = .8;
  duration: any = 750;
  padding: any = 10;
  opacity: any = .8;
  @Input()
  tile: any;
  response: any;
  RIMSummarySubscription: Subscription;
 
  constructor(private sessionStorage: SessionStorage, private rimSummaryService: RIMSummaryService) { }

  ngOnInit() {
    this.RIMSummarySubscription = this.rimSummaryService.getData().subscribe((response) => {
      if (response != null && response != undefined)
        this.getRIMSummary(response);
    });
  }

  ngOnDestroy() {
    this.RIMSummarySubscription.unsubscribe();
  }

  getColorForBars(data) {
    if (data == 'Active') {
      return "#FBE7F8";
    }
    else if (data == 'Upcoming') {
      return "#F3B0DE";
    }
    else if (data == 'Overdue') {
      return "#BE8DC3";
    }
    else if (data == 'Escalations') {
      return "#8F7EBF";
    }
    else {
      return "#E1E1E1";
    }
  }

  getRIMSummary(data) {
    this.response = data;
    //if (this.response != undefined || this.response != null) {
    this.RIMSummaryCount = this.response.Summary;
    var EndToEndId = this.sessionStorage.endToEndId;
    var Type = this.sessionStorage.selectedSubmissionType == 1 ? "E" : "Cms";
    var CmsId = this.sessionStorage.selectedSubmissionType == 2 ? this.sessionStorage.clientWMSId : null;

    this.activeCount = parseInt(this.RIMSummaryCount.ActiveIssueOverAll);
    //this.openCount = parseInt(this.RIMSummaryCount.ActiveIssueOverAll);
    this.UpComingCount = parseInt(this.RIMSummaryCount.UpcomingIssuesOverAll);
    this.OverdueCount = parseInt(this.RIMSummaryCount.OverdueIssuesOverAll);
    this.escalationsCount = parseInt(this.RIMSummaryCount.TotalIssues) - parseInt(this.RIMSummaryCount.ClosedIssue);
    this.othersCount = this.activeCount - this.escalationsCount - this.OverdueCount;

    if (this.activeCount > 0) {
      this.active = (this.activeCount / parseInt(this.RIMSummaryCount.TotalIssuesOverAll)) * 100;
      this.upcoming = (this.UpComingCount / this.activeCount) * 100;
      this.overdue = (this.OverdueCount / this.activeCount) * 100;
      this.escalations = (this.escalationsCount / this.RIMSummaryCount.ActiveIssueOverAll) * 100;
      this.other = ((this.activeCount - this.escalationsCount - this.OverdueCount) / this.activeCount) * 100;
    }
    if ((this.activeCount == 0 && this.escalationsCount == 0) || this.activeCount == 0) {
      // d3.select("#reviewIssues-chart").html('<div class="no-alert" style="width: 250px; height: 90px; margin:0 auto;">No Records Found</div>');
      d3.select("#reviewIssues-chart").html('<div>No data to display</div>');
    }
    else {
      var issueData =
        [
          { name: 'Active', value: this.active >= 0 && this.active != null ? Number(this.active.toFixed(2)) : 0, slicedValue: this.active >= 0 && this.active != null ? Math.round(this.active.toString()) : 0 },
          { name: 'Upcoming', value: this.upcoming >= 0 && this.upcoming != null ? Number(this.upcoming.toFixed(2)) : 0, slicedValue: this.upcoming >= 0 && this.upcoming != null ? Math.round(this.upcoming.toString()) : 0 },
          { name: 'Overdue', value: this.overdue >= 0 && this.overdue != null ? Number(this.overdue.toFixed(2)) : 0, slicedValue: this.overdue >= 0 && this.overdue != null ? Math.round(this.overdue.toString()) : 0 },
          { name: 'Escalations', value: this.escalations >= 0 && this.escalations != null ? Number(this.escalations.toFixed(2)) : 0, slicedValue: this.escalations >= 0 && this.escalations != null ? Math.round(this.escalations.toString()) : 0 },
          { name: 'Others', value: this.other >= 0 && this.other != null ? Number(this.other.toFixed(2)) : 0, slicedValue: this.other >= 0 && this.other != null ? Math.round(this.other.toString()) : 0 }
        ];

      let margin = { top: 10, right: 10, bottom: 30, left: 69 };
      let e = document.getElementById('issue_tile_content');
      let svgWidth = e.clientWidth - margin.left + 20, svgHeight = e.clientHeight - margin.bottom;
      let height = svgHeight - margin.top - margin.bottom, width = svgWidth - margin.left - margin.right - 40;
      let sourceNames = [], sourceCount = [];

      let x = d3.scaleLinear().rangeRound([0, width]);
      let y = d3.scaleBand().rangeRound([0, height]).padding(0.1);
      issueData.forEach(x => getChartData(x));

      function getChartData(values) {
        sourceNames.push(values.name);
        sourceCount.push(values.value);
      }
      x.domain([0, d3.max(sourceCount, function (d) { return d; })]);
      y.domain(sourceNames);

      var element = document.getElementById('reviewIssues-chart');
      var svgElements = element.querySelectorAll('svg');
      if (svgElements.length == 0) {
        let svg = d3.select("#reviewIssues-chart").append("svg");
        svg.attr('height', svgHeight)
          .attr('width', svgWidth);

        svg = svg.append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        svg.append("g")
          .call(d3.axisLeft(y))      

          .call((d: any) => {

            d.selectAll('text')

              .attr('x', `-${5}`)

              .attr('font-size', '12px')

              .attr('font-family', 'Graphik')

              .attr('font-weight', 400)

              .attr('text-anchor', 'end')
              .attr('color', "grey");
          })
          //.attr('x',20)
          //.attr('color', 'grey')
          //.attr('font-size', '12px')
          .selectAll('.domain, .tick line').remove();

        // Create rectangles
        let bars = svg.selectAll('.bar')
          .data(sourceNames)
          .enter()
          .append("g");

        bars.append('rect')
          .attr('class', 'bar')
          .attr('fill', d => this.getColorForBars(d))
          .attr("x", function (d) { return 0; })
          .attr("y", function (d) { return y(d); })
          .attr("width", function (d) { return x(issueData.find(x => x.name == d).value) })
          .attr("height", function (d) { return issueData.find(x => x.name == d).value == 0 ? y.bandwidth() : y.bandwidth(); });

        bars.append("text")
          .text(function (d) {
            return issueData.find(x => x.name == d).value + '%';
          })
          .attr("x", function (d) {
            return issueData.find(x => x.name == d).value == 0 ? 14 : x(issueData.find(x => x.name == d).value) + 4;
          })
          .attr("y", function (d) {
            console.log(y.bandwidth());
            return y(d) + y.bandwidth() * 0.5 ; // here 0.1 is the padding scale
          })
          .attr('dy','0.4em')
          .attr("font-family", "sans-serif")
          .attr("font-size", "12px")
          .attr("fill", "grey")
          .attr("text-anchor", "start");
      }

    }

    //}
    //else {
    //  return d3.select("#reviewIssues-chart").html('<div class="no-alert" style="width: 250px; height: 90px; margin:0 auto;">No Records Found</div>');
    //}
  }
}
