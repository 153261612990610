<!--Process.Review-->
<!--<div *ngIf="tile && tile.ShortDescription=='PR'">
  <div class="row"><strong class="col-xs-12 col-sm-5">Due Date<span style="float:right;">:</span></strong><span class="col-xs-12 col-sm-7" style="margin-left:-25px;" [innerHTML]="tile && tile.SelfAudit!=null ? tile.SelfAudit.CompletionDate:0" ></span><br /></div>
  <div class="row"><br /></div>
  <div class="row"><strong class="col-xs-12 col-sm-5">Status<span style="float:right;">:</span></strong><span class="col-xs-12 col-sm-7" style="margin-left:-25px;" [innerHTML]="tile && tile.SelfAudit!=null ? tile.SelfAudit.AuditStatus:0" ></span><br /></div>
  <div class="row"><br /></div>
  <div class="row"><strong class="col-xs-12 col-sm-5">PMI (PM)<span style="float:right;">:</span></strong><span class="col-xs-12 col-sm-7" style="margin-left:-25px;" [ngClass]="{'red-color': tile.SelfAudit!=null && tile.SelfAudit.PMRAG == 'red', 'amber-color': tile.SelfAudit!=null && tile.SelfAudit.PMRAG == 'amber','green-color': tile.SelfAudit!=null && tile.SelfAudit.PMRAG == 'green'}" [innerHTML]="tile && tile.SelfAudit!=null? tile.SelfAudit.PMScore:0" ></span></div>
  <div class="row"><br /></div>
  <div class="row"><strong class="col-xs-12 col-sm-5">PMI (PR)<span style="float:right;">:</span></strong><span class="col-xs-12 col-sm-7" style="margin-left:-25px;" [ngClass]="{'red-color': tile.SelfAudit!=null && tile.SelfAudit.PeerReviewRAG == 'red', 'amber-color': tile.SelfAudit!=null && tile.SelfAudit.PeerReviewRAG == 'amber','green-color': tile.SelfAudit!=null && tile.SelfAudit.PeerReviewRAG == 'green'}" [innerHTML]="tile && tile.SelfAudit!=null? tile.SelfAudit.PeerReviewScore:0" ></span></div>
  <div class="row"><br /></div>
  <div class="row"><strong class="col-xs-12 col-sm-5">PMI (Audit)<span style="float:right;">:</span></strong><span class="col-xs-12 col-sm-7" style="margin-left:-25px;" [ngClass]="{'red-color': tile.SelfAudit!=null && tile.SelfAudit.CARAG == 'red', 'amber-color': tile.SelfAudit!=null && tile.SelfAudit.CARAG == 'amber','green-color': tile.SelfAudit!=null && tile.SelfAudit.CARAG == 'green'}" [innerHTML]="tile && tile.SelfAudit!=null? tile.SelfAudit.CAScore:0" ></span></div>
</div>-->


  <!--<div class="c-portfolio-card__body" *ngIf="tile && tile.ShortDescription=='PR'">-->
    <div class="c-manage-dashboard-review" *ngIf="tile && tile.ShortDescription=='PR'">
      <div class="c-manage-dashboard-review-col">
        <ul class="c-manage-dashboard-process">
          <li>
            <div>Due Date</div>
            <span [innerHTML]="tile && tile.SelfAudit!=null ? tile.SelfAudit.CompletionDate:0"></span>
          </li>
          <li>
            <div>Status</div>
            <span [innerHTML]="tile && tile.SelfAudit!=null ? tile.SelfAudit.AuditStatus:0"></span>
          </li>
          <li><div>PMI (PM) <span [innerHTML]="tile && tile.SelfAudit!=null? tile.SelfAudit.PMScore:0"></span></div></li>
          <li><div>PMI (PR) <span [innerHTML]="tile && tile.SelfAudit!=null? tile.SelfAudit.PeerReviewScore:0"></span></div></li>
          <li><div>PMI (Audit) <span [innerHTML]="tile && tile.SelfAudit!=null? tile.SelfAudit.CAScore:0"></span></div></li>
        </ul>
      </div>
      <div class="c-manage-dashboard-review-col">


        <p class="c-manage-dashboard-review-text" [innerHTML]="tile.Description">

        </p>
      </div>
    </div>
  <!--</div>-->



<!--Stage.Gate.Review-->
       
          <!--<div class="c-portfolio-card__body" *ngIf="tile && tile.ShortDescription =='SGR'">-->
            <div class="c-manage-dashboard-state" *ngIf="tile && tile.ShortDescription =='SGR'">
            <div class="c-manage-dashboard-state-row">
              <div class="c-manage-dashboard-state-item">
                <label>Phase</label>
              </div>
              <div class="c-manage-dashboard-state-data">
                <span [innerHTML]="tile && tile.SelfAuditSGR!=null?tile.SelfAuditSGR.Phase:0"></span>
              </div>
              <div class="c-manage-dashboard-state-item">
                <label>Due Date</label>
              </div>
              <div class="c-manage-dashboard-state-data">
                <span [innerHTML]="tile && tile.SelfAuditSGR!=null?tile.SelfAuditSGR.DueDate:0"></span>
              </div>
              <div class="c-manage-dashboard-state-item">
                <label>Scores</label>
              </div>
              <div class="c-manage-dashboard-state-data">
                <div class="c-lead__table c-manage-dashboard__table">
                  <table class="c-table">
                    <thead>
                      <tr>
                        <th>DL/PM</th>
                        <th>SME</th>
                        <th>MD</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{tile && tile.SelfAuditSGR!=null? tile.SelfAuditSGR.PMScore:0}}</td>
                        <td>{{tile && tile.SelfAuditSGR!=null? tile.SelfAuditSGR.SMEScore:0}}</td>
                        <td>{{tile && tile.SelfAuditSGR!=null? tile.SelfAuditSGR.MDScore:0}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="c-manage-dashboard-state-items">
              <label>Status</label>
              <span [innerHTML]="tile && tile.SelfAuditSGR!=null?tile.SelfAuditSGR.AuditStatus:0"></span>
            </div>
          </div>
          <p class="c-manage-dashboard-state__text" *ngIf="tile && tile.ShortDescription =='SGR'" [innerHTML]="tile.Description"></p>
          <!--</div>-->

        <!--ICIServiceMaturityAssessment-->
        <div *ngIf="tile && tile.ShortDescription=='ICISM'">
          <div class="row"><strong class="col-xs-12 col-sm-6">Status<span style="float:right;">:</span></strong><span class="col-xs-12 col-sm-6" style="margin-left:-25px;" [innerHTML]="tile && tile.SelfAuditICISMA!=null? tile.SelfAuditICISMA.AuditStatus:0"></span><br /></div>
          <div class="row"><br /></div>
          <div class="row"><strong class="col-xs-12 col-sm-6">Due Date<span style="float:right;">:</span></strong><span class="col-xs-12 col-sm-6" style="margin-left:-25px;" [innerHTML]="tile && tile.SelfAuditICISMA!=null? tile.SelfAuditICISMA.CompletionDate:0"></span><br /></div>
          <div class="row"><br /></div>
          <div class="row"><strong class="col-xs-12 col-sm-6">Score<span style="float:right;">:</span></strong><span class="col-xs-12 col-sm-6" style="margin-left:-25px;" [ngClass]="{'red-color': tile.SelfAuditICISMA!=null && tile.SelfAuditICISMA.MDRAG == 'red', 'amber-color': tile.SelfAuditICISMA!=null && tile.SelfAuditICISMA.MDRAG == 'amber','green-color': tile.SelfAuditICISMA!=null && tile.SelfAuditICISMA.MDRAG == 'green'}" [innerHTML]="tile && tile.SelfAuditICISMA!=null? tile.SelfAuditICISMA.MDScore:0"></span></div>
          <div class="row"><br /></div>
          <div class="row"><strong class="col-xs-12 col-sm-6">Reason<span style="float:right;">:</span></strong><span class="col-xs-12 col-sm-6" style="margin-left:-25px;" [innerHTML]="tile && tile.SelfAuditICISMA!=null? tile.SelfAuditICISMA.Reason:0"></span><br /></div>
        </div>

