import { Injectable } from '@angular/core';
import { Subject,BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RIMSummaryService {

  private rimSource = new BehaviorSubject<any>(null);

  public _broadcastToGirdView = new Subject<any>();

  broadcastToGirdView$ = this._broadcastToGirdView.asObservable();

  constructor() {
  }

  getData(): Observable<any> {
    return this.rimSource.asObservable();
  }

  broadcastRIMSummary(data) {
    this.rimSource.next(data);
  }

  onBroadcastToGridView(value: string) {
    if (value) {
      this._broadcastToGirdView.next(value);
    }

  }
}
