import { Component, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var $: any;
@Component({
  templateUrl: './confirmation.popup.component.html'
})

export class ConfirmationPopupComponent {
  message: string;
  yesButton: string;
  noButton: string;
  Headertitle: string;
  scrollYPosition: any;
  yesEvent = new EventEmitter();
  noEvent = new EventEmitter();
  public confirmationResult: EventEmitter<any> = new EventEmitter();
  yesFunction: () => void;
  noFunction: () => void;
  constructor(
    private bsModalRef: BsModalRef
    , private modalService: BsModalService) { }

  ngOnInit() {
    setTimeout(function () {
      $('.close').focus();
    });
  }
  ngOnDestroy() {
    window.scrollTo(0, this.scrollYPosition);
  }
  closePopup() {
    this.confirmationResult.emit({ result: false , type :"close" });
    this.bsModalRef.hide();
  }
  Confirmed() {
    this.confirmationResult.emit({ result: true });
    this.bsModalRef.hide();
  }
  ConfirmedNo() {
    this.confirmationResult.emit({ result: false });
    this.bsModalRef.hide();
  }

}
