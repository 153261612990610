import { ChangeDetectorRef, Component, Output, EventEmitter } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import * as Enumerable from 'linq';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorage } from '../../../core/context/sessionstorage';

var $ = (<any>window).$;
declare var window: any;

@Component({
  templateUrl: './cms.userguide.component.html'
})

export class CMSUserGuideComponent {

  prevContent: boolean = false;
  nextContent: boolean = true;
  prevButton: boolean = true;
  nextButton: boolean = false;
   constructor(
     private modalService: BsModalService,
     private sessionStorage: SessionStorage,
     private ref: ChangeDetectorRef,
     private router: Router,
     private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
   
  }

  OnClose() {
    this.router.navigate(['/mywizard/clientstructure', 'E2E', this.sessionStorage.endToEndUId]);
  }

  DisplayNextContent() {
    this.prevContent = true;
    this.prevButton = false;
    this.nextButton = true;
    this.nextContent = false;
    window.scrollTo(0,806);
  }

  DisplayPrevContent() {
    this.prevContent = false;
    this.prevButton = true;
    this.nextButton = false;
    this.nextContent = true;
    window.scrollTo(0, 137);
  }
}
