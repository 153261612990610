import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
var $ = (<any>window).$;

@Component({
  selector: 'data-privacy',
  templateUrl: './data.privacy.component.html'
})

export class DataPrivacyComponent {

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
    setTimeout(function () {
      $('.close').focus();
    }, 0)
  }

  closeModal() {
    this.bsModalRef.hide();
  }

}
