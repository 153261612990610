
      <div class="it-spend-selector" id="it-spend-selector" style="height: 110px;">

      </div>

      <p class="c-manage-dashboard__image-text" style="-webkit-line-clamp: 5;">{{tile.Description}}</p>

  




