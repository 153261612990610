import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
var $ = (<any>window).$;
declare var window: any;

@Component({
  templateUrl: './team.members.filter.component.html',
})

export class TeamMemberFilterComponent {
  public getFilteredParams: EventEmitter<any> = new EventEmitter();
  RoleAccess: any;
  teamMemberFilterAttribute: any = [];
  
  constructor( private bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.teamMemberFilterAttribute.CareerLevel = this.teamMemberFilterAttribute.CareerLevel == undefined || this.teamMemberFilterAttribute.CareerLevel == null ? [] : this.teamMemberFilterAttribute.CareerLevel;
    this.teamMemberFilterAttribute.WorkLocation = this.teamMemberFilterAttribute.WorkLocation == undefined || this.teamMemberFilterAttribute.WorkLocation == null ? [] : this.teamMemberFilterAttribute.WorkLocation;
    this.teamMemberFilterAttribute.Technology = this.teamMemberFilterAttribute.Technology == undefined || this.teamMemberFilterAttribute.Technology == null ? [] : this.teamMemberFilterAttribute.Technology;
    this.teamMemberFilterAttribute.Skills = this.teamMemberFilterAttribute.Skills == undefined || this.teamMemberFilterAttribute.Skills == null ? [] : this.teamMemberFilterAttribute.Skills;
    this.teamMemberFilterAttribute.SkillProficiency = this.teamMemberFilterAttribute.SkillProficiency == undefined || this.teamMemberFilterAttribute.SkillProficiency == null ? [] : this.teamMemberFilterAttribute.SkillProficiency;
    this.teamMemberFilterAttribute.CareerRole = this.teamMemberFilterAttribute.CareerRole == undefined || this.teamMemberFilterAttribute.CareerRole == null ? [] : this.teamMemberFilterAttribute.CareerRole;
    setTimeout(function test() {
      $('.close').focus();
      window.dropdownComponent();
    }, 0);
  }

  CancelFilter() {
    this.bsModalRef.hide();
  }

  ClearSelection() {
    this.teamMemberFilterAttribute.SelectedCarreerLevel = {};
    this.teamMemberFilterAttribute.SelectedWorkLocation = {};
    this.teamMemberFilterAttribute.SelectedSkills = {};
    this.teamMemberFilterAttribute.SelectedSkillProficiency = {};
    this.teamMemberFilterAttribute.SelectedTechnology = {};
    this.teamMemberFilterAttribute.SelectedCareerRole = {};
  }

  CloseFilter(event: any = null) {
    if (event != null) {
      if (event.keyCode == 13 || event.keyCode == 32) {
        this.bsModalRef.hide();
      }
    }
    else {
      this.bsModalRef.hide();
    }
    $('#Filter_Info').focus();
    this.ClearSelection();
    this.GetFilterParams();
    this.getFilteredParams.emit({ filteredValue: this.teamMemberFilterAttribute, enableScrollBar: true });
    return true;
  }

  GetFilterParams() {
    if (this.teamMemberFilterAttribute) {
      this.teamMemberFilterAttribute.SelectedCarreerLevel = typeof this.teamMemberFilterAttribute.SelectedCarreerLevel == 'object' ? "" : this.teamMemberFilterAttribute.SelectedCarreerLevel;
      this.teamMemberFilterAttribute.SelectedWorkLocation = typeof this.teamMemberFilterAttribute.SelectedWorkLocation == 'object' ? "" : this.teamMemberFilterAttribute.SelectedWorkLocation;
      this.teamMemberFilterAttribute.SelectedSkills = typeof this.teamMemberFilterAttribute.SelectedSkills == 'object' ? "" : this.teamMemberFilterAttribute.SelectedSkills;
      this.teamMemberFilterAttribute.SelectedSkillProficiency = typeof this.teamMemberFilterAttribute.SelectedSkillProficiency == 'object' ? "" : this.teamMemberFilterAttribute.SelectedSkillProficiency;
      this.teamMemberFilterAttribute.SelectedTechnology = typeof this.teamMemberFilterAttribute.SelectedTechnology == 'object' ? "" : this.teamMemberFilterAttribute.SelectedTechnology;
      this.teamMemberFilterAttribute.SelectedCareerRole = typeof this.teamMemberFilterAttribute.SelectedCareerRole == 'object' ? "" : this.teamMemberFilterAttribute.SelectedCareerRole;
      this.getFilteredParams.emit({ filteredValue : this.teamMemberFilterAttribute, enableScrollBar: true });
      this.bsModalRef.hide();
    }
  } 
}
