<div id="add-custom-metric" class="c-flyout c-contract__flyout -open" tabindex="-1" aria-labelledby="flyoutTitle" style="visibility: visible;" aria-modal="true" role="dialog">
  <div class="c-flyout__header -client-header">
    <div class="c-flyout-head__title">
      <div class="-flyoutTitle" id="flyoutTitle">{{popup}}</div>
    </div>
    <button type="button" class="-dismiss-flyout" aria-label="Close" alt="Close" (click)="ClosePop()"></button>
  </div>
  <div class="c-flyout__body -customScroll">
    <div id="accordionParentaddcontracts">
      <div class="c-accordion -open">
        <button type="button" class="c-collapse" aria-expanded="true" aria-controls="addmetric1"
                style="font-size: 1.125rem; font-weight: 500 !important; " (click)="toggleAccordion('details')" id="details">
          Details
        </button>
        <div id="addmetric1" class="-collapse -show" data-parentid="accordionParentaddcontracts">
          <div class="c-accordion__container">
            <div class="c-myWizard-form__input">
              <div class="c-myWizard-form__input-area">
                <div class="o-input    ">
                  <label for="metric-name">Name </label>
                  <input type="text"
                         class="o-input__input"
                         placeholder="Enter Metric Name"
                         (change)="onmetricNameChange($event)"
                         maxlength="255"
                         (blur)="ValidateField($event.target.value,'name')"
                         name="city" list="metrics_lst"
                         value="" [(ngModel)]="metricsName"
                         id="metric-name"
                         [ngClass]="metricnameError || metricError?'has-error':''" />
                  <datalist id="metrics_lst">
                    <option *ngFor="let item of metricsSearch" [value]="item.name">{{item.name}}</option>
                  </datalist>
                </div>
                <p class="o-input__error-text" [ngClass]="metricnameError ?'show':''">Enter Metric Name</p>
                <p class="o-input__error-text show" *ngIf="metricError"> Metric with same name already exists. Please enter a new name </p>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="o-input    ">
                  <label for="metric-abbreviation">Abbreviation</label>
                  <input type="text"
                         class="o-input__input"
                         placeholder="Enter Abbreviation"
                         name="abbreviation" (change)="InputValueChanged($event)"
                         maxlength="50"
                         value="" [(ngModel)]="Abbreviation"
                         id="metric-abbreviation" />
                </div>
              </div>
              <div class="c-myWizard-form__input-area -comp-col">
                <div class="o-text-area">
                  <label for="desp">Definition</label>
                  <textarea class="o-text-area__input"
                            name="definition"
                            placeholder="Enter Definition" maxlength="3000"
                            [(ngModel)]="definition"
                            (blur)="ValidateField($event.target.value,'def')" (change)="InputValueChanged($event)"
                            id="desp" style="height: 75px"
                            [ngClass]="DefinationError?'has-error':''">                              
                  </textarea>

                </div>
                <p class="o-input__error-text" [ngClass]="DefinationError ?'show':''">Enter definition</p>
              </div>
            </div>
            <div class="c-myWizard-form__input three-col-input">
              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown">
                  <label for="unit">Unit</label>
                  <div class="c-dropdown">
                    <search-dropdown id="ddlUnit"
                                     [options]="UnitList"
                                     [isShow]="true"
                                     [errormessage]="'Please select Unit'"
                                     [selectText]="'Select'"
                                     [selected]="SelectedUnit"
                                     [disableDropdown]="IsUpdateFlag"
                                     (callback)="ValidateField($event.target.value,'Unit');InputValueChanged($event);"
                                     [(ngModel)]="SelectedUnit"
                                     [ngClass]="unitError ?'has-error':''">

                    </search-dropdown>

                  </div>
                  <p class="c-select__error-text" [ngClass]="unitError ?'show':''">Select Unit</p>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown   ">
                  <label for="reported-against">Reported Against</label>
                  <div class="c-dropdown" id="reported-against">
                    <multi-selectdropdown name="reportedAgainst"
                                          id="reportedAgainst"
                                          [options]="workstreamTypes==null?[]:workstreamTypes"                                      
                                          [selected]="selectedWSTypes"
                                          [(ngModel)]="selectedWSTypes"
                                          [ariaLabelledby]="'ReportAgainst'"
                                          [maxChipCount]="1"
                                          (callback)="onReportedAgainstaChange($event)"
                                          [ngClass]="ReportedError ?'has-error':''">
                    </multi-selectdropdown>
                  </div>
                  <p class="c-select__error-text" [ngClass]="ReportedError ?'show':''">Select Reported Against</p>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown   ">
                  <label for="delivery-funct">Delivery Functions</label>
                  <div class="c-dropdown" id="delivery-funct" *ngIf="!IsUpdateFlag">
                    <multi-selectdropdown name="deliveryFunctions"
                                          id="deliveryFunctions"
                                          [options]="ProcessList==null?[]:ProcessList"
                                          [selected]="selectedDeliveryFunction"
                                          [(ngModel)]="selectedDeliveryFunction"
                                          [maxChipCount]="1"
                                          (callback)="onDeliveryFunctionChange($event)"
                                          [ngClass]="processError ?'has-error':''">

                    </multi-selectdropdown>
                  </div>
                  <p class="c-select__error-text" [ngClass]="processError ?'show':''">Select Delivery Functions</p>
                </div>
              </div>

              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown   ">
                  <div class="c-select-dropdown">
                    <label for="metric-category">Category</label>
                    <div class="c-dropdown" id="metric-category">
                      <search-dropdown id="ddlCategory"
                                       [options]="ProcessCategory"
                                       [isShow]="true"
                                       [errormessage]="'Please select Category'"
                                       [selectText]="'Select'"
                                       [selected]="SelectedCategory"
                                       [(ngModel)]="SelectedCategory"
                                       (callback)="ValidateField($event.target.value,'Category');InputValueChanged($event);"
                                       [ngClass]="categoryError ?'has-error':''">
                      </search-dropdown>
                    </div>
                  </div>
                  <p class="c-select__error-text" [ngClass]="categoryError ?'show':''">Select Category</p>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown   ">
                  <label for="threshold-type">Threshold Type </label>
                  <div class="c-dropdown" id="threshold-type">
                    <search-dropdown id="ddlThreshold"
                                     [options]="ThresholdList"
                                     [isShow]="true"
                                     [errormessage]="'Please select Threshold'"
                                     [selectText]="'Select'"
                                     [selected]="SelectedThreshold"
                                     [disableDropdown]="IsUpdateFlag"
                                     [(ngModel)]="SelectedThreshold"
                                     (callback)="ValidateField($event.target.value,'Threshold');InputValueChanged($event);"
                                     [ngClass]="thresholdError ?'has-error':''">

                    </search-dropdown>
                  </div>
                  <p class="c-select__error-text" [ngClass]="thresholdError ?'show':''">Select Threshold</p>
                </div>
              </div>          
              <!--Check if this label must be present-->
              <div class="c-myWizard-form__input-area" *ngIf="isInfraWS">
                <div class="c-select-dropdown   ">
                  <label for="metric-source">Metric Category</label>
                  <div class="c-dropdown" id="metric-source">
                    <div>
                      <input type="text" class="o-input__input -disabled" disabled
                             value="Week-Wise"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown   ">
                  <label for="metric-source" >Recommended Source</label>
                  <div class="c-dropdown" id="metric-source">
                    <div>
                      <input type="text"
                             class="o-input__input -disabled" disabled 

                             value="{{sourcibility}}"
                             id="metric-name" />
                    </div>
                  </div>
                  <p class="c-select__error-text">Enter the value</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-accordion">
        <button type="button" class="c-collapse" aria-expanded="false" aria-controls="addmetric2"
                style="font-size: 1.125rem; font-weight: 500 !important; display: flex; align-items: center;" (click)="toggleAccordion('associate_measures')" id="assoc_measures">
          Associated Measure Details
          <div class="-collapse-text" style=" font-size: 14px; color: #505050; margin-left: auto; font-weight: 400;"><span>{{AssociatedMeasures.length}}</span> MEASURES</div>
        </button>
        <div id="addmetric2" class="-collapse" data-parentid="accordionParentaddcontracts">
          <div class="c-accordion__container">
            <div class="c-form-tab">
              <div class="c-form-tab__wrapper">
                <div class="c-scrollable-tabs">
                  <span class="c-scrollable-tabs__arrow icon-chevron-left -left -hidden"></span>
                  <div class="c-scrollable-tabs__container-wrapper">
                    <div class="c-scrollable-tabs__container" role="tablist" style="position: relative; transform: translateX(0px);">
                      <button class="c-form-tab__item" role="tab" aria-controls="add-measure" aria-selected="true">
                        Add Measure
                      </button>
                      <button class="c-form-tab__item" role="tab" aria-controls="add-custom" aria-selected="false">
                        Add Custom Measure
                      </button>
                    </div>
                  </div>
                  <span class="c-scrollable-tabs__arrow icon-chevron-right -right -hidden"></span>
                </div>
              </div>
            </div>
            <div role="tabpanel" id="add-measure" class="-show">
              <div class="c-table-responsive c-lead__table">
                <table class="c-table">
                  <thead>
                    <tr>
                      <th scope="col">Measure Name</th>
                      <th scope="col">Definition</th>
                      <th scope="col">Sourcing</th>
                      <th scope="col" width="60px" class="c-text-center">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="AssociatedMeasures.length==0">
                      <tr>
                        <td colspan="4"> No Records Found </td>
                      </tr>
                    </ng-container>
                    <tr *ngFor="let m of AssociatedMeasures">
                      <td>
                        {{m.Name}}
                      </td>
                      <td>{{m.MeasureDefinition}}</td>
                      <td>{{m.Source}}</td>
                      <td class="c-text-center"><a href="javascript:;" class="icon-delete" (click)="RemoveAssociateMeasure(m.Id)" [disabled]="isDelete || accessFlag" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete"></a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p class="o-input__error-text show" *ngIf="RemoveFormulaMeasure">
                Measure is used in the formula. Please update the formula before deletion
              </p>
              <form #addMeasureForm="ngForm" (ngSubmit)="AddSelectedMeasure(addMeasureForm)">
                <div class="c-metric-measure">
                  <div class="c-metric-measure-list">
                    <div class="c-select-dropdown">
                      <label for="id1">Measure List</label>
                      <search-dropdown id="measure_id"
                                       name="measureid"
                                       #measure_id="ngModel"
                                       [labelInfo]="'measure'"
                                       [options]="MeasureList == null ? [] : MeasureList"
                                       [isShow]="true"
                                       [errormessage]="'Please select Measure'"
                                       [formname]="addMeasureForm"
                                       [required]="true"
                                       [selectText]="'Select'"
                                       [selected]="selectedMeasureId"
                                       [(ngModel)]="selectedMeasureId"
                                       (callback)="getMeasureExist(selectedMeasureId)"
                                       [ngClass]="measureExist ? 'has-error' : ''">
                      </search-dropdown>
                      <p class="o-input__error-text"
                         [ngClass]="measureExist ? 'show' : ''"
                         *ngIf="measureExist && measure_id.valid">
                        Measure already exists in the list. Please check again with a different measure.
                      </p>
                    </div>

                  </div>
                  <div class="c-metric-measure-btn"style="margin-top: 38px !important;" >
                    <!-- Primary Button starts from here -->
                    <button type="submit" role="button" class="o-secondary-button -large" >

                      Add Measure

                    </button>

                    <!-- Primary Button ends from here -->

                  </div>
                </div>

              </form>
            </div>
            <div role="tabpanel" id="add-custom">
              <form #addcustomForm="ngForm" (ngSubmit)="saveCustomMeasure(addcustomForm)">
                <div class="c-myWizard-form__input">
                  <div class="c-myWizard-form__input-area">
                    <div class="o-input    ">
                      <label for="end-metric-name">Name</label>
                      <input type="text" class="o-input__input" [ngClass]="(!measure_name.valid && (measure_name.touched || addcustomForm.submitted))||measureNameError?'has-error':''"
                             placeholder="Enter Measure Name"
                             ngModel
                             #measure_name="ngModel"
                             id="end-metric-name"
                             maxlength="255" (blur)="ValidateFieldMeasure($event.target.value,'name')"
                             (change)="onMeasureChange($event.target.value)"
                             name="measure_name" list="Measurename"
                             required>
                      <datalist id="Measurename">
                        <option *ngFor="let item of customMeasureList" [value]="item.Name">{{item.Name}}</option>
                        <option value="Cambridge">
                      </datalist>
                      <p class="o-input__error-text" [ngClass]="!measure_name.valid && (measure_name.touched || addcustomForm.submitted) ?'show':''">Measure Name is required</p>
                      <p class="o-input__error-text" [ngClass]="measureNameError?'show':''" *ngIf="measureNameError && measure_name.valid"> Measure Name already exists.</p>
                    </div>
                  </div>
                  <div class="c-myWizard-form__input-area">
                    <div class="o-input    ">
                      <label for="custom-abbreviation">Abbreviation</label>
                      <input type="text" class="o-input__input"
                             ngModel
                             #abbreviation="ngModel"
                             placeholder="Enter Abbreviation"
                             name="abbreviation" value="" id="custom-abbreviation"
                             maxlength="50">
                    </div>
                  </div>
                  <div class="c-myWizard-form__input-area -comp-col">
                    <div class="o-text-area">
                      <label for="custom-desp">Definition</label>
                      <textarea class="o-text-area__input" [ngClass]="(!Defination.valid && (Defination.touched|| addcustomForm.submitted))?'has-error':''"
                                placeholder="Enter Definition"
                                ngModel
                                #Defination="ngModel"
                                id="custom-desp"
                                name="definition" rows="3" cols="3" maxlength="1000" style="height:75px"
                                required></textarea>
                      <p class="o-input__error-text" [ngClass]="!Defination.valid && (Defination.touched|| addcustomForm.submitted) ?'show':''">Definition is required</p>
                    </div>
                  </div>
                </div>
                <div class="c-myWizard-form__input three-col-input">
                  <div class="c-myWizard-form__input-area">
                    <div class="c-select-dropdown   ">
                      <label for="custom-unit">Unit</label>
                      <div class="c-dropdown" id="custom-unit">
                        <search-dropdown id="cust_Unit"
                                         name="unit"
                                         #cust_unit="ngModel"
                                         [labelInfo]="'unit'"
                                         [options]="customUnitList==null?[]:customUnitList"
                                         [isShow]="true"
                                         [errormessage]="'Please select Unit'"
                                         [formname]="addcustomForm"
                                         [required]="true"
                                         [selectText]="'Select'"
                                         [selected]="selectedCustomUnitId"
                                         [(ngModel)]="selectedCustomUnitId"
                                         (callback)="getFieldCheck(selectedCustomUnitId)">
                        </search-dropdown>
                      </div>
                      <p class="c-select__error-text">Enter the value</p>
                    </div>
                  </div>
                  <div class="c-myWizard-form__input-area">
                    <div class="c-select-dropdown   ">
                      <label for="field-check">Field Check</label>
                      <div class="c-dropdown" id="field-check">
                        <multi-selectdropdown name="fieldcheck"
                                              #fieldcheck="ngModel"
                                              [options]="fieldCheckList?fieldCheckList:[]"
                                              [selected]="selectedFieldCheckId"
                                              [selectedparent]=""
                                              [(ngModel)]="selectedFieldCheckId"
                                              [ishierarchy]="false"
                                              [ariaLabelledby]="'FieldCheck'"
                                              [maxChipCount]="1">
                        </multi-selectdropdown>
                      </div>
                      <div *ngIf="IsEdit" class="form-group autocompleteInput">
                        <div class="col-xs-12 col-sm-12" *ngFor="let item of SelectedFieldCheck">
                          {{item.name}}
                        </div>
                      </div>
                      <p class="c-select__error-text">Enter the value</p>
                    </div>
                  </div>
                  <div class="c-myWizard-form__input-area">
                    <div class="c-select-dropdown    ">
                      <label for="recommended-source">Recommended Source</label>
                      <div class="c-dropdown" id="recommended-source">
                        <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down -disabled" >Manual</a>
                        <div class="c-dropdown-menu">
                          <div class="c-dropdown-body">
                            <span>Manual</span>
                          </div>
                        </div>
                      </div>
                      <p class="c-select__error-text">Enter the value</p>
                    </div>
                  </div>
                </div>
                <div class="c-metric-measure-btn">
                  <!--Primary Button starts from here-->
                  <button type="submit" role="button" class="o-secondary-button -left" style="left:82%">
                    Save Measure
                  </button>
                  <!--Primary Button ends from here-->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="c-accordion">
        <button type="button" class="c-collapse -metric-formula" aria-expanded="false" aria-controls="addmetric3" id="formula"
                style="font-size: 1.125rem; font-weight: 500 !important;" (click)="toggleAccordion('formula')">
          Formula
        </button>
        <div id="addmetric3" class="-collapse" data-parentid="accordionParentaddcontracts">
          <div class="c-accordion__container">
            <div class="c-metric-input" *ngIf="!showMetricFormula">
              <div class="c-myWizard-form__input">
                <div class="c-myWizard-form__input-area -comp-col">
                  <div class="o-text-area">
                    <textarea [disabled]="!CreateAction" style="height: 75px;"
                              class="o-text-area__input" placeholder="Enter the Text" id="Solution_Description"
                              [(ngModel)]="EnteredFormula"
                              name="EnteredFormula" onpaste="return false;"
                              (keyup)="Addseperator($event.target.value)"
                              (blur)="ValidateField($event.target.value,'formula')" (change)="InputValueChanged($event)"
                              [ngClass]="(formulaError||CalculatedMetricValueError||CheckFormluleaError||TestMetricFormulaError)?'has-error':''" >
                    </textarea>

                    <!--<p class="c-select__error-text" [ngClass]="formulaError||CalculatedMetricValueError||CheckFormluleaError||TestMetricFormulaError ?'show':''">Enter formula</p>-->
                    <p class="o-input__error-text show" *ngIf="EnteredFormula==''||EnteredFormula==undefined">Enter metric formula</p>
                    <p class="o-input__error-text show" *ngIf="formulaError && !(EnteredFormula==''||EnteredFormula==undefined)">Please correct the metric formula</p>
                    <p class="o-input__error-text show" *ngIf="TotalFormulaCheck && !(EnteredFormula==''||EnteredFormula==undefined)">
                      All the measures from associated measures grid is not added in the formula, please correct it
                    </p>
                    <p class="o-input__error-text show" *ngIf="TestMetricFormulaError && EnteredFormula!=''">Please enter a valid formula</p>
                    <p class="o-input__error-text show" *ngIf="CalculatedMetricValueError"> Please test the formula before proceeding</p>
                  </div>
                </div>

              </div>
              <div class="c-metric-chip" *ngIf="MatchedMeasures.length>0">
                <a href="javascript:;" role="button" class="c-metric-chip-item" *ngFor="let measure of MatchedMeasures" (click)="ChangeFormulaMeasure(measure)">{{measure.value}}</a>
              </div>
            </div>
            <div class="c-metric-formula" *ngIf="showMetricFormula">
              <div class="-header formula-header">Metric Formula</div>
              <p>{{EnteredFormula}}</p>
              <div class="c-myWizard-form__input" style="display:inline !important">
                <div class="c-myWizard-form__input-area" *ngFor="let source of EnteredMeasures">
                  <div class="o-input    ">
                    <label for="ATD">{{source.name}}</label>
                    <input [(ngModel)]="source.value"
                           type="text"
                           class="o-input__input"
                           maxlength="19"
                           (ngModelChange)="syncMeasureValue(EnteredMeasures,source.id,source.value,source.unit)"
                           (keypress)="validateNumbersWithDecimal($event,$event.target.value)"
                           (keyup)="validateMeasureValue($event.target.value)" *ngIf="source.unit != 7">
                    <input type="date" [(ngModel)]="source.value" class="o-input__input" *ngIf="source.unit == 7"
                           (ngModelChange)="syncMeasureValue(EnteredMeasures,source.id,source.value, source.unit)">
                  </div>
                </div>


              </div>
              <div class="c-myWizard-form__input">
                <div class="c-myWizard-form__input-area -comp-col">
                  <div class="o-input">
                    <label for="ws_name">{{metricsName}}</label>
                    <input type="text" autocomplete="off" maxlength="50" class="o-input__input -disabled" id="calculateMetricValue" placeholder="" [(ngModel)]="CalculatedMetricValueTemp" disabled>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="c-key-flyout-btn" style="display:flex; justify-content:right;gap:24px">
            <a href="javascript:void(0)" role="button" class="o-primary-button  -test-metric" *ngIf="accordianType=='formula'&& accordionExpanded==true && !showMetricFormula" (click)="Show_TestFormula()">Test Metric Formula</a>
            <a href="javascript:void(0)" role="button" class="o-secondary-button  align-left -test-metric" *ngIf="accordianType=='formula'&& accordionExpanded==true && showMetricFormula" (click)="showFormulaPage()">
              Cancel
            </a>
            <a href="javascript:void(0)" role="button" class="o-primary-button  -test-metric" *ngIf="accordianType=='formula'&& accordionExpanded==true && showMetricFormula" (click)="calculateTestFormula(EnteredMeasures,true)">Test Formula</a>
          </div>
        </div>
        

      </div>
    </div>
    <!--<p class="error-text show">Please enter a valid formula</p>-->
  </div>

  <div class="c-flyout__footer">
    <div class="c-key-flyout-btn" style="display:flex; justify-content:right;gap:24px">
      <!--Primary Button starts from here-->
      <a href="javascript:void(0)" role="button" class="o-primary-button  -test-metric" [ngClass]="" (click)="SaveMetric()">Save</a>
    </div>
  </div>
</div>
<div id="flyout" class="c-flyout__overlay"></div>
