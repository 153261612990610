

<div class="c-dropdown" [ngClass]="{'-disabled':disableDropdown,'has-error':(required && formname.submitted && (selected==''||selected==null||selected==undefined))}" click-elsewhere (clickOutside)="SelectorOpen=false">


  <input type="hidden" name="{{'searchDropdown_' + ariaLabelledby}}" [required]="required==true" [value]="value" />
  <a href="javascript:;" name="{{'searchdropdown_btn_' + ariaLabelledby }}" attr.aria-labelledby="{{ariaLabelledby}}"
   role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down"
     (click)="openSelector()">{{selectedItem}}</a>
  <div class="c-dropdown-menu" *ngIf="SelectorOpen">
    <div class="c-dropdown-body">
      <ng-container>
        <ul class="hierarchy">
          <li role="option" [ngClass]="{'active':L1.IsSelected}" [attr.data-item]="L1" *ngFor="let L1 of options;let i= inext;let l= last; trackBy : trackByName;"
              (keydown)="MultiSelectKeyPress(L1,$event,ariaLabelledby,'anchor',l);">
            <a name="{{ariaLabelledby + '_focus_'+ i}}" href="javascript:void(0);" class="c-dropdown-item dropdown-hirarchy-label" (click)="OnSelect(L1)">
              <span [ngClass]="{'option-selected':L1.IsSelected}">{{L1.Name}}</span>
            </a>
            <ul>
              <li role="option" [ngClass]="{'active':L2.IsSelected}" [attr.data-item]="L2" *ngFor="let L2 of L1.WorkstreamCMSListLevel2; trackBy : trackByName;">
                <a href="javascript:void(0);" class="c-dropdown-item dropdown-hirarchy-label" (click)="OnSelect(L2)">
                  <span [ngClass]="{'option-selected':L2.IsSelected}">{{L2.Name}}</span>
                </a>
                <ul>
                  <li role="option" [ngClass]="{'active':L3.IsSelected}" [attr.data-item]="L3" *ngFor="let L3 of L2.WorkstreamCMSListLevel3; trackBy : trackByName;">
                    <a href="javascript:void(0);" class="c-dropdown-item dropdown-hirarchy-label" (click)="OnSelect(L3)">
                      <span [ngClass]="{'option-selected':L3.IsSelected}">{{L3.Name}}</span>
                    </a>
                    <ul>
                      <li role="option" [ngClass]="{'active':L4.IsSelected}" [attr.data-item]="L4" *ngFor="let L4 of L3.WorkstreamCMSListLevel4; trackBy : trackByName;">
                        <a href="javascript:void(0);" class="c-dropdown-item dropdown-hirarchy-label" (click)="OnSelect(L4)">
                          <span [ngClass]="{'option-selected':L4.IsSelected}">{{L4.Name}}</span>
                        </a>
                        <ul class="open">
                          <li role="option" [ngClass]="{'active':L5.IsSelected}" [attr.data-item]="L5" *ngFor="let L5 of L4.WorkstreamCMSListLevel5; trackBy : trackByName;">
                            <a href="javascript:void(0);" class="c-dropdown-item dropdown-hirarchy-label" (click)="OnSelect(L5)">
                              <span [ngClass]="{'option-selected':L5.IsSelected}">{{L5.Name}}</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </ng-container>



    </div>
  </div>


</div>
<!--p aria-live="polite" aria-atomic="true" role="status" class="c-select__error-text" [ngClass]="{'show':(required && formname.submitted  && (selected==''||selected==null||selected==undefined))}">{{errormessage}}</p>-->

