import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from 'rxjs';
import { SessionStorage } from './../context/sessionstorage';

@Injectable()
export class HttpService {

  constructor(private httpClient: HttpClient, private spinner: NgxSpinnerService, private sessionStorage: SessionStorage) { }

  delete(route: string, enableLoader: boolean = false) {
    return this.httpClient.delete(environment.serviceUrl + route);
  }

  getObservable(url, enableLoader): any {
    return function (observer: any) {
      this.httpClient.get(url).subscribe(
        (response: any) => {

          if (enableLoader) this.spinner.hide();
          observer.next(response);
          observer.complete();
        }
        ,
        (error: any) => observer.error(error));
    }.bind(this)
  }

  postObservable(url, data, enableLoader): any {
    return function (observer: any) {
      this.httpClient.post(url, data).subscribe(
        (response: any) => {

          if (enableLoader) this.spinner.hide();
          observer.next(response);
          observer.complete();
        }
        ,
        (error: any) => observer.error(error));
    }.bind(this)
  }

  get(route, enableLoader: boolean = false, isSubscribeRequired: boolean = true): Observable<any> {

    if (enableLoader) this.spinner.show();
    if (isSubscribeRequired) {
      return Observable.create(this.getObservable(environment.serviceUrl + route, enableLoader));

    }
    else {
      var responseGet = this.httpClient.get(environment.serviceUrl + route);
      if (enableLoader) this.spinner.hide();
      return responseGet;
    }
  }
  getWeb(route: string) {
    return this.httpClient.get(environment.clientUrl + route);
  };

  post(route: string, data: any = null, enableLoader: boolean = false, isSubscribeRequired: boolean = true): Observable<any> {

    if (enableLoader) this.spinner.show();
    if (isSubscribeRequired) {
      return Observable.create(this.postObservable(environment.serviceUrl + route, data, enableLoader));
    }
    else {
      var responsePost = this.httpClient.post(environment.serviceUrl + route, data);
      if (enableLoader) this.spinner.hide();
      return responsePost;
    }
  };
  put(route: string, data: any) {
    return this.httpClient.put(environment.serviceUrl + route, data)
  }

  postFile(file: any, route: string, data: any): Observable<any> {
    let stringifiedJson;
    try {
      stringifiedJson = JSON.stringify(data);
    } catch (exception) {
      stringifiedJson = null;
    }
    var formData = new FormData();
    formData.append('file', file);
    formData.append("filepath", stringifiedJson);
    formData.append("enterpriseid", this.sessionStorage.enterpriseId);
    //var responsePost = this.httpClient.post(environment.clientUrl + route, formData, {
    //  //transformRequest: $.identity,
    //  headers: { 'Content-Type': undefined }
    //});

    var responsePost = this.httpClient.post(environment.serviceUrl + route, formData);
    return responsePost;
}

  getFile(route: string, elementId: string, fileName: string, type: string, IsLoaderShow: boolean = false) {
    var contentType = "";
    if (IsLoaderShow) {
      this.spinner.show();
      return this.httpClient.get(environment.serviceUrl + route, { responseType: 'blob' }).subscribe((response: any) => {
        var exceldata = response;
        if (type == "docx") {
          contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        }
        else if (type == "pptx") {
          contentType = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        }
        else {
          contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        }
        if (IsLoaderShow) {
          this.spinner.hide();
        }
        fileName = fileName + "." + type;
        var blob = new Blob([exceldata], { type: contentType });
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
        }
        else {
          var linkelement = document.getElementById(elementId);
          linkelement.setAttribute('download-active', "true");
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          linkelement.setAttribute('download-active', "false");
        }
        return response;
      }, function (error) {
        console.log(error);
        return error;
      });
    }
  }
}
