import { Component, EventEmitter, Output } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { DemographicsService } from '../../core/services/demographics.service';
import { enumProfileTab, ManageWorkTile } from '../../core/context/enum';
import { ProfileConstants } from '../../core/context/constants';
import { SessionStorage } from '../../core/context/sessionstorage';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
/*import { SlideShowComponent } from './model.popup.component/slide.show.component';*/
var $ = (<any>window).$;
import { Location } from '@angular/common';
import { HeaderComponent } from '../header.component/header.component';
import { SharedService } from '../../core/services/shared.service';
import { InitiateProfileSharedService } from './initiate.profile.shared.service';
import { ApplicationLoggingService } from '../../core/services/application.logging.service';
import { enumModuleAction, enumTile, enumFeatureAccess } from '../../core/context/enum';
import { TileService } from '../../core/services/tile.service';
import { BroadCastService } from '../../core/services/broadcast.service';


declare var userNotification: any;
declare var window: any;

@Component({
  templateUrl: './initiate.profile.component.html',
  providers: [DemographicsService, BsModalService, HeaderComponent, SharedService, InitiateProfileSharedService, ApplicationLoggingService, TileService, HeaderComponent]
})

export class InitiateProfileComponent {

  constructor(private sessionStorage: SessionStorage, private router: Router, private activatedRoute: ActivatedRoute,
    private demographicService: DemographicsService, private modalService: BsModalService, private location: Location,
    private sharedService: SharedService, private initiateProfileSharedService: InitiateProfileSharedService,
    private headerComponent: HeaderComponent, private applicationLoggingService: ApplicationLoggingService, private tileService: TileService, private broadCastService: BroadCastService) {

  

  }

  UserPreferenceDetail: any;
  modalRef_cmstms: BsModalRef;
  enumProfileTab = enumProfileTab;
  DisableTabMessage: string = '';
  Path = this.router.url;
  IsHelpModal: boolean;
  IsDeliveryMandatoryDataSet: boolean;
  //IsPlatformAccessMandatory: boolean;
  SubmissionType = this.sessionStorage.selectedSubmissionType;
  CreatePage = false;
  DeliveryProfile: string = "Initiate Profile";
  _session = this.sessionStorage;
  EnableDeliveryProfileGuide: boolean = true;
  ShowDominantInfo: boolean = false;
  endToEndType: string = this.sessionStorage.endToEndType;
  path = this.location.path();
  ParentTileId: any = this.sessionStorage.parentTileId;
  redirectId = this.sessionStorage.endToEndUId;
  IsAdmin = this.sharedService.isAdmin(this.sessionStorage.roleTypeCode);
  IsOppInitNotCreated: boolean;
  ProfileTabHighlight: boolean = false;
  keyContactTabHighlight: boolean;
  tabId: number;
  activeTabId: number;
  ActiveTab: enumProfileTab;
  @Output() ScopeSelecorVisible = new EventEmitter();
  InitiateProfileTabSetup = new EventEmitter();
  sideMenuCollapsed: boolean = false;
  tiles: any;
  disableOtherMetricConfig: boolean = false;
  disableMetricselection: boolean = false;
  disableThreshold: boolean = false;
  selectedSideMenuAccordion: string;
  sideMenuExapand: boolean=true;
  configureMetricsTabIds: any[] = [enumProfileTab.MetricSelection, enumProfileTab.Thresholds, enumProfileTab.OtherConfigureSettings];
  isOpportunityCreator: boolean;
  isInitiativeCreator: boolean;
  IsOpportunityInitiativeCreator: boolean;
  accordianHeader: string = "Initiation & Configuration";
  accordianTitle: string = "Initiate Profile";

  ngOnInit() {
    this.initiateProfileSharedService.SetupProfileAndAdminPage(this);
    if (!Boolean(this.sessionStorage.endToEndUId) && !this.IsOpportunityInitiativeCreator) {
      return false;
    }
    this.router.onSameUrlNavigation = 'ignore';
    this.setActiveTab();
    if (this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1) {
      this.CreatePage = true;
      this.loadAdminTabContent(this.sessionStorage.profileTab);
      this.accordianHeader = this.IsOpportunityInitiativeCreator && !(this.sessionStorage.roleTypeCode == 'AA' || this.sessionStorage.roleTypeCode == 'AV' || this.sessionStorage.roleTypeCode == 'AC') ? 'Support' : 'Admin';
    } else {
      this.loadTabContentBasedOnMandatoryAttributes();
      this.accordianHeader = "Initiation & Configuration";
    }
    if (!this.CreatePage && (this.sessionStorage.teamId || this.sessionStorage.workstreamId || this.sessionStorage.clientWMSId)) {
      this.DisableTabMessage = ProfileConstants.DisableTabMessage
    }
    //setTimeout(() => {
    //  window.sideMenu();
    //})
    if (this.sessionStorage.workstreamId || this.sessionStorage.clientWMSId) {
      this.disableMetricselection = true;
      this.disableThreshold = true;
      this.disableOtherMetricConfig = true;
    }
    if (this.sessionStorage.teamUId != null && this.sessionStorage.teamUId != '') {
      this.disableOtherMetricConfig = true;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      window.collapseComponent();
    }, 0)
  }
  onActivate(componentReference) {
    componentReference.InitiateProfileTabSetup.subscribe((data) => {
      this.ProfileTabHighlight = data.ProfileTabHighlight;
      this.IsOppInitNotCreated = data.IsOppInitNotCreated;
      this.keyContactTabHighlight = data.keyContactTabHighlight;   
    });
  }
 
  loadTabForAdminAndProfile(tabId) {
    //this.tabId = tabId;
    if (this.CreatePage) {
      this.loadAdminTabContent(tabId);
    } else {
      this.loadTabContent(tabId);
    }
  }
  loadTabContent(tabId: number): void {
    this.IsHelpModal = false;
    switch (tabId) {
      case enumProfileTab.Profile:
        this.ShowDominantInfo = true;
        this.router.navigate(['/mywizard/profile/E2E',this.sessionStorage.endToEndUId]);
        break;
      case enumProfileTab.Scope:
        this.router.navigate(['/mywizard/scope/E2E', this.sessionStorage.endToEndUId]);
        break;
      case enumProfileTab.KeyContacts:
        this.router.navigate(['/mywizard/keycontacts/E2E', this.sessionStorage.endToEndUId]);
        break;
      case enumProfileTab.ClientStructure:
        this.router.navigate(['/mywizard/clientstructure/E2E', this.sessionStorage.endToEndUId]);
        this.IsHelpModal = true;
        break;
      case enumProfileTab.TeamStructure:
        this.router.navigate(['/mywizard/teamstructure/E2E', this.sessionStorage.endToEndUId]);
        this.IsHelpModal = true;
        break;
      case enumProfileTab.MatrixStructure:
        this.router.navigate(['/mywizard/matrixstructure/E2E', this.sessionStorage.endToEndUId]);
        this.IsHelpModal = true;
        break;
      case enumProfileTab.DeliveryPortfolio:
        this.router.navigate(['/mywizard/deliveryportfolio/E2E', this.sessionStorage.endToEndUId]);
        break;
      case enumProfileTab.MetricSelection:
        this.router.navigate(['/mywizard/metricselection/E2E', this.sessionStorage.endToEndUId]);
        break;
      case enumProfileTab.Thresholds:
        this.router.navigate(['/mywizard/metricthreshold/E2E', this.sessionStorage.endToEndUId]);
        break;
      //case enumProfileTab.PlatformAccess:
      //  this.router.navigate(['/mywizard/platformaccess/E2E', this.sessionStorage.endToEndUId]);
      //  break;
      case enumProfileTab.OtherConfigureSettings:
        this.router.navigate(['/mywizard/otherconfigsettings/E2E', this.sessionStorage.endToEndUId]);
        break;
      default:
        this.router.navigate(['/mywizard/profile/E2E', this.sessionStorage.endToEndUId]);
        break;
    }
  }

  loadAdminTabContent(tabId: number): void {
    this.IsHelpModal = false;
    this.accordianTitle = this.path.indexOf('opportunity') > -1 ? 'Opportunity' : 'Internal Initiative';
    var pageName = this.path.indexOf('opportunity') > -1 ? '/mywizard/opportunity/' : '/mywizard/internalinitiative/';
    switch (tabId) {
      case enumProfileTab.Profile:
        this.ShowDominantInfo = true;
        this.router.navigate([pageName + 'profile'], { relativeTo: this.activatedRoute });
        break;
      case enumProfileTab.Scope:
        this.router.navigate([pageName + 'scope'], { relativeTo: this.activatedRoute });
        break;
      case enumProfileTab.TeamStructure:
        this.router.navigate([pageName + 'TeamStructure'], { relativeTo: this.activatedRoute });
        break;
      case enumProfileTab.KeyContacts:
        this.router.navigate([pageName + 'keycontacts'], { relativeTo: this.activatedRoute });
        break;
      case enumProfileTab.DeliveryPortfolio:
        this.router.navigate([pageName + 'deliveryportfolio'], { relativeTo: this.activatedRoute });
        break;
      default:
        this.router.navigate([pageName + 'profile'], { relativeTo: this.activatedRoute });
        break;
    }
  }

  setActiveTab() {
    if (this.splitURL(this.router.url) == "/mywizard/profile" || this.splitCreatePageURL(this.router.url) == "/mywizard/opportunity/profile" || this.splitCreatePageURL(this.router.url) == "/mywizard/internalinitiative/profile") {
      this.tabId = this.sessionStorage.profileTab = 1;
    }
    else if (this.splitURL(this.router.url) == "/mywizard/scope" || this.splitCreatePageURL(this.router.url) == "/mywizard/opportunity/scope" || this.splitCreatePageURL(this.router.url) == "/mywizard/internalinitiative/scope") {
      this.tabId = this.sessionStorage.profileTab = 2;
    }
    else if (this.splitURL(this.router.url) == "/mywizard/TeamStructure" || this.splitCreatePageURL(this.router.url) == "/mywizard/opportunity/scope") {
      this.tabId = this.sessionStorage.profileTab = 2;
    }
    else if (this.splitURL(this.router.url) == "/mywizard/keycontacts" || this.splitCreatePageURL(this.router.url) == "/mywizard/opportunity/keycontacts" || this.splitCreatePageURL(this.router.url) == "/mywizard/internalinitiative/keycontacts") {
      this.tabId = this.sessionStorage.profileTab = 3;
    }
    else if (this.splitURL(this.router.url) == "/mywizard/clientstructure") {
      this.tabId = this.sessionStorage.profileTab = 4;
    }
    else if (this.splitURL(this.router.url) == "/mywizard/teamstructure") {
      this.tabId = this.sessionStorage.profileTab = 5;
    }
    else if (this.splitURL(this.router.url) == "/mywizard/matrixstructure") {
      this.tabId = this.sessionStorage.profileTab = 6;
    }
    else if (this.splitCreatePageURL(this.router.url) == "/mywizard/opportunity/deliveryportfolio" || this.splitCreatePageURL(this.router.url) == "/mywizard/internalinitiative/deliveryportfolio") {
      this.tabId = this.sessionStorage.profileTab = 8;
    }
    else if (this.splitURL(this.router.url) == "/mywizard/metricselection") {
      this.tabId = this.sessionStorage.profileTab = 9;
    
    }
    else if (this.splitURL(this.router.url) == "/mywizard/metricthreshold") {
      this.tabId = this.sessionStorage.profileTab = 10;
    }
    //else if (this.splitURL(this.router.url) == "/mywizard/platformaccess") {
    //  this.tabId = this.sessionStorage.profileTab = 12;
    //}
    else if (this.splitURL(this.router.url) == "/mywizard/otherconfigsettings") {
      this.tabId = this.sessionStorage.profileTab = 11;
    }
    if (this.configureMetricsTabIds.indexOf(this.tabId)>-1) {
      this.selectedSideMenuAccordion = 'configuremetrics'
    }
    else {
      this.selectedSideMenuAccordion = 'initiateprofile';
    }
  }

  splitURL(url: string) {
    var hashes: string[] = url.split('/');
    var urlMyWizard = hashes[1];
    var urlPage = hashes[2];
    return '/' + urlMyWizard + '/' + urlPage;
  }

  splitCreatePageURL(url: string) {
    var hashes: string[] = url.split('/');
    var urlMyWizard = hashes[1];
    var urlPage = hashes[2];
    var urlPageTwo = hashes[3];
    return '/' + urlMyWizard + '/' + urlPage + '/' + urlPageTwo;
  }

  loadTabContentBasedOnMandatoryAttributes() {
    this.demographicService.getInitiateProfile(this.sessionStorage.endToEndId).subscribe((response: any) => {
      if (response && response.DeliveryProfileMandatoryAttributeSet == 0) {
        this.IsDeliveryMandatoryDataSet = true;
        //this.IsPlatformAccessMandatory = true;
        if (this.sessionStorage.profileTab != enumProfileTab.Profile && this.sessionStorage.profileTab != enumProfileTab.Scope &&
          this.sessionStorage.profileTab != enumProfileTab.KeyContacts && this.sessionStorage.profileTab != enumProfileTab.DeliveryPortfolio) {
          this.sessionStorage.profileTab = enumProfileTab.Profile;
        }
      }
      else {
        this.IsDeliveryMandatoryDataSet = false;
        //this.IsPlatformAccessMandatory = false;
        if ((this.SubmissionType == 2 || this.SubmissionType == 3) && this.sessionStorage.profileTab == enumProfileTab.Profile) {
          this.sessionStorage.profileTab = enumProfileTab.ClientStructure;

        }
      }
      this.loadTabContent(this.sessionStorage.profileTab);

    });
  }

  //LoadSlideShow() {
  //  const initialState = { IscmsHelpModalClick: true };
  //  this.modalRef_cmstms = this.modalService.show(SlideShowComponent, {
  //    initialState,
  //    backdrop: "static",
  //    keyboard: false
  //  });
  //  $(".modal-dialog").addClass('custom-modal-cmstmspopup');
  //}

  LoadMandatoryDataAttrSet() {
    if (!this.CreatePage) {
    
      this.demographicService.getInitiateProfile(this.sessionStorage.endToEndId).subscribe((response: any) => {
        if (response && response.DeliveryProfileMandatoryAttributeSet == 0) {
          this.sessionStorage.IsProfileCompleted = false;
          this.IsDeliveryMandatoryDataSet = true;
          //this.IsPlatformAccessMandatory = true;
          this.ActiveTab = this.sessionStorage.profileTab = enumProfileTab.Profile;
        }
        else {
          this.sessionStorage.IsProfileCompleted = true;
          this.IsDeliveryMandatoryDataSet = false;
          //this.IsPlatformAccessMandatory = false;
          if ((this.SubmissionType == 2 || this.SubmissionType == 3) && this.sessionStorage.profileTab == enumProfileTab.Profile) {
            this.ActiveTab = this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
          }
        }
        this.broadCastService.broadcastProfileCompletionStatus(this.sessionStorage.IsProfileCompleted);

      });
    }
  }

 

  OnBackClick() {

    if (!this.CreatePage) {
      if (this.IsDeliveryMandatoryDataSet) {
        this.router.navigate(['/mywizard/home', 'E2E', this.redirectId], { relativeTo: this.activatedRoute });
      } else {
        this.router.navigate(['/mywizard/manage', 'E2E', this.redirectId], { relativeTo: this.activatedRoute });
      }
    }
    else if (this.CreatePage && !this.IsAdmin) {
      this.router.navigate(['/mywizard/support'], { relativeTo: this.activatedRoute });
    }
    else if (this.CreatePage && this.IsAdmin) {
      this.router.navigate(['/mywizard/admin'], { relativeTo: this.activatedRoute });
    }
    this.broadCastService.broadcastScopeSelector({
      isScopeSelectorVisible: true, isScopeSelectorDropDownVisible: true,
      isSetAsDefaultVisible: true
    });
  }



  DownloadTemplate(fileName, elementId, type) {
      userNotification.showUserNotificationModalPopup();
      const btnClose = document.querySelector("#IUnderstandAction");
      if (btnClose) {
        btnClose.addEventListener('click', () => {
          this.demographicService.downloadTemplate(fileName, elementId, type);
          this.UsageLogging(enumModuleAction.Template, enumTile.InitiateProfile, enumFeatureAccess.DeliveryProfileGuide);
        });
      }
  }

  UsageLogging(moduleAction, tileID, featureAccessID) {
    this.applicationLoggingService.applicationUsageLogging(moduleAction, tileID, featureAccessID).subscribe();
  }

  accordionChange(accordion, tileId) { //Temporary method. once RDDA and Mapping app moved to console, we can remove this logic.
    if (this.selectedSideMenuAccordion == accordion) {
      this.sideMenuExapand = !this.sideMenuExapand;
    }
    else {
      this.selectedSideMenuAccordion = accordion;
      this.sideMenuExapand = true;
    }

    if (tileId) { 
    this.tileService.getTileforParent(ManageWorkTile.InitiationAndConfiguration).subscribe((response) => {
      var tile = response.ChildTiles.filter(x => x.TileId == tileId)[0];
      if (tile) {
        var endtoendUId = this.sessionStorage.endToEndUId ? this.sessionStorage.endToEndUId : "";
        var enterpriseId = this.sessionStorage.enterpriseId ? this.sessionStorage.enterpriseId : "";
        var url = tile.Url;
        url = url.replace('{E2EUID}', endtoendUId == "" ? "null" : endtoendUId);
        url = url.replace('{endToEndUId}', endtoendUId == "" ? "null" : endtoendUId);
        url = url.replace('{EnterpriseID}', enterpriseId);
        window.open(url, "_blank");
      }

    });
  }

  }
}



