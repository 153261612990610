import { Component, EventEmitter } from '@angular/core'
import { ConfigureMetricService } from '../../core/services/configure.metrics.services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionStorage } from '../../core/context/sessionstorage';
import { enumMetricDeliveryLevel } from '../../core/context/enum';
import { SharedService } from '../../core/services/shared.service';
import { RIMSummaryService } from '../../core/services/rim.summary.service';
import * as dayjs from 'dayjs';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';

declare var window: any;
var $ = (<any>window).$;
@Component({
  selector: 'edit-threshold',
  templateUrl: './metric.edit.threshold.component.html',
  providers: [ConfigureMetricService, BsModalService]
})
export class MetricEditThresholdComponent {


  rowUp: any=true;
  rowDown: any;
  mirflag: any;
  rowBidirectional: any;
  allSelectedArray: any;
  isRangeTrend: any;
  trendDirectionOptions: any;
  selectedTrend: any;
  IsTrackByPhase: any;
  isTimeSpanUint: any;
  timeSpanNote: any;
  IsStandMetricNull: any;
  tvalup1Error: any;
  tvalup2Error: any;
  tvaldwn1Error: any;
  tvaldwn2Error: any;
  tvalbi1Error: any;
  tvalbi2Error: any;
  tvalbi3Error: any;
  tvalbi4Error: any;
  isTimeSpanError: any;
  timeSpanErrorMsg: any;
  isError: any=false;
  thresholdData: any;
  thresholdSaveData: any;
  selectedTrendId: any;
  TempAllSelectedBothdirectionMetrics: any;
  TempAllSelectedUpwardMetrics: any;
  TempAllSelectedDownwardMetrics: any;
  isDisabled: any;
  ToolTipMsg: any;
  allSelectedArrayClone: any;
  IsTrackByPhasePanel: boolean;
  isGridDisabled: boolean;
  GridCategory: any;
  //undefined variables
  tvalUp1: any;
  tvalUp2: any;
  tvalDwn1: any;
  tvalDwn2: any;
  tvalBi1: any;
  tvalBi2: any;
  tvalBi3: any;
  tvalBi4: any;
  tempTrend: number;
  tempVal1: any;
  tempVal2: any;
  tempVal3: any;
  tempVal4: any;
  TTid: any;

  errorMessage: any;
  tempDeliveryStructId: any;
  deliveryLevelId: number;
  deliveryId: any;
  mir: any;
  isPhaseSelected: any;
  Error: any;
  lastUpdated: any;
  btnRestore: any;
  isUpdated: any;
  accessFlag: boolean;
  Roleaccesscode: any;

  public setAfterSave: EventEmitter<any> = new EventEmitter();  // .. Child Popup -> Parent
  modalConfirmation: BsModalRef;
  modalManage: BsModalRef;
  dayjs: any = dayjs;
  trendDirectionDropDown: any = [];
  changeTrigger: number = 1;
  showModal: any = false;
  tempIsRangeTrend: number = 0;
  

  constructor(private sessionStorage: SessionStorage,
    private bsModalRef: BsModalRef, private metricService: ConfigureMetricService,
    private sharedService: SharedService, private rIMSummaryService: RIMSummaryService  ) {
  }


  ngOnInit() {
    console.log("Edit Threshold", JSON.stringify(this.trendDirectionOptions));
    this.Roleaccesscode = this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);
    this.accessFlag = this.Roleaccesscode == 'E' ? false : true;
    this.trendDirectionOptions.forEach((x) => {
      if (x.Id != 0) {
        this.trendDirectionDropDown.push({ "Id": x.Id, "Name": x.Name });
      }
    })
    setTimeout(() => {
      window.dropdownComponent();
      
    }, 0);
    console.log("Edit Threshold", JSON.stringify(this.trendDirectionOptions));
  }
  //Trend Direction dropdown selection change
  chnageDirection() {      //Trend direction dropdown change call
    this.RemoveErrorMessages();
    var option = this.selectedTrendId;
    this.selectedTrend = this.trendDirectionOptions.filter((x: any) => x.Id == this.selectedTrendId).map(x => x.Name);
    if (this.IsTrackByPhase == false) {
      if (this.trendDirectionDropDown.length == 3) {
        switch (option) {
          case 1:
            this.rowUp = false
            this.rowDown = true
            this.rowBidirectional = true
            //this.selectedTrend = this.trendDirectionOptions.filter((x: any) => x.Id == this.selectedTrendId).map(x => x.Name);
            //this.selectedTrendId = 0;
            this.timeSpanNote = 1;//set value to show note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            break;
          case 2:
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = false
            //this.selectedTrend = this.trendDirectionOptions.filter((x: any) => x.Id == this.selectedTrendId).map(x => x.Name);
            //this.selectedTrendId = 1;
            this.timeSpanNote = this.selectedTrendId;//set value to show note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            break;

          case 3:
            this.rowUp = true
            this.rowDown = false
            this.rowBidirectional = true
            //this.selectedTrend = this.trendDirectionOptions.filter((x: any) => x.Id == this.selectedTrendId).map(x => x.Name);
            //this.selectedTrendId = 2;
            this.timeSpanNote = this.selectedTrendId;//set value to show note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            break;

          default:
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = true
            this.selectedTrend = this.trendDirectionOptions[0].Name;
            this.selectedTrendId = 0;
            this.timeSpanNote = 1;//set value to show note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            break;

        }
      }
      else {
        switch (option) {

          case 1:
            this.rowUp = false
            this.rowDown = true
            this.rowBidirectional = true
            //this.selectedTrend = this.trendDirectionOptions[1].Name;
            //this.selectedTrendId = 1;
            this.timeSpanNote = this.selectedTrendId;//set value to show note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            break;
          case 2:
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = false
            //this.selectedTrend = this.trendDirectionOptions[2].Name;
            //this.selectedTrendId = 2;
            this.timeSpanNote = this.selectedTrendId;//set value to show note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            break;

          case 3:
            this.rowUp = true
            this.rowDown = false
            this.rowBidirectional = true
            //this.selectedTrend = this.trendDirectionOptions[3].Name;
            //this.selectedTrendId = 3;
            this.timeSpanNote = this.selectedTrendId;//set value to show note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            break;

          default:
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = true
            this.selectedTrend = this.trendDirectionOptions[0].Name;
            this.selectedTrendId = 0;
            this.timeSpanNote = 0;//set default value to hide note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            break;

        }
      }

    }
    else {

      if (this.trendDirectionOptions.length == 3) {
        var x = false;

        for (var i = 0; i < this.allSelectedArray.length; i++) {
          for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

            this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
            this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
            this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = false;
            this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = false;

          }
        }

        switch (option) {
          case 1:
            this.rowUp = false
            this.rowDown = true
            this.rowBidirectional = true
            //this.selectedTrend = this.trendDirectionOptions[0].Name;
            //this.selectedTrendId = 0;
            this.allSelectedArray = this.TempAllSelectedUpwardMetrics.map((x: any) => Object.assign({}, x));

            for (var i = 0; i < this.allSelectedArray.length; i++) {
              for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                  x = true;
                  continue;
                }
                else {
                  x = false;
                  break;
                }

              }

              this.allSelectedArray[i].IsAllPhaseSelected = x;
            }

            break;
          case 2:
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = false
            //this.selectedTrend = this.trendDirectionOptions[1].Name;
            //this.selectedTrendId = 1;
            this.allSelectedArray = this.TempAllSelectedBothdirectionMetrics.map((x: any) => Object.assign({}, x));

            for (var i = 0; i < this.allSelectedArray.length; i++) {

              for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {


                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                  x = true;
                  continue;
                }
                else {
                  x = false;
                  break;
                }
              }
              this.allSelectedArray[i].IsAllPhaseSelected = x;
            }

            break;

          case 3:

            this.rowUp = true
            this.rowDown = false
            this.rowBidirectional = true
            //this.selectedTrend = this.trendDirectionOptions[2].Name;
            //this.selectedTrendId = 2;
            this.allSelectedArray = this.TempAllSelectedDownwardMetrics.map((x: any) => Object.assign({}, x));

            for (var i = 0; i < this.allSelectedArray.length; i++) {

              for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                  x = true;
                  continue;
                }
                else {
                  x = false;
                  break;
                }
              }
              this.allSelectedArray[i].IsAllPhaseSelected = x;
            }

            break;

          default:
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = true
            this.selectedTrend = this.trendDirectionOptions[0].Name;
            this.selectedTrendId = 0;

            for (var i = 0; i < this.allSelectedArray.length; i++) {
              for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
                this.allSelectedArray[i].IsAllPhaseSelected = false;
                this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue = false;
              }
            }

            break;

        }


      }
      else {
        var x = false;
        for (var i = 0; i < this.allSelectedArray.length; i++) {
          for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

            this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
            this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
            this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = false;
            this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = false;

          }
        }

        switch (option) {

          case 1:
            this.rowUp = false
            this.rowDown = true
            this.rowBidirectional = true
            //this.selectedTrend = this.trendDirectionOptions[1].Name;
            //this.selectedTrendId = 1;
            this.allSelectedArray = this.TempAllSelectedUpwardMetrics.map((x: any) => Object.assign({}, x));

            for (var i = 0; i < this.allSelectedArray.length; i++) {

              for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                  x = true;
                  continue;
                }
                else {
                  x = false;
                  break;
                }
              }
              this.allSelectedArray[i].IsAllPhaseSelected = x;
            }

            break;
          case 2:
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = false
            //this.selectedTrend = this.trendDirectionOptions[2].Name;
            //this.selectedTrendId = 2;
            this.allSelectedArray = this.TempAllSelectedBothdirectionMetrics.map((x: any) => Object.assign({}, x));

            for (var i = 0; i < this.allSelectedArray.length; i++) {

              for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                  x = true;
                  continue;
                }
                else {
                  x = false;
                  break;
                }
              }
              this.allSelectedArray[i].IsAllPhaseSelected = x;
            }

            break;

          case 3:
            this.rowUp = true
            this.rowDown = false
            this.rowBidirectional = true
            //this.selectedTrend = this.trendDirectionOptions[3].Name;
            //this.selectedTrendId = 3;
            this.allSelectedArray = this.TempAllSelectedDownwardMetrics.map((x: any) => Object.assign({}, x));

            for (var i = 0; i < this.allSelectedArray.length; i++) {

              for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                  x = true;
                  continue;
                }
                else {
                  x = false;
                  break;
                }
              }
              this.allSelectedArray[i].IsAllPhaseSelected = x;
            }

            break;

          default:
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = true
            //this.selectedTrend = this.trendDirectionOptions[0].Name;
            //this.selectedTrendId = 0;

            for (var i = 0; i < this.allSelectedArray.length; i++) {
              for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
                this.allSelectedArray[i].IsAllPhaseSelected = false;
                this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue = false;
              }
            }

            break;
        }

      }
    }  
  }

  //Validate special characters in range threshold values in pop-up, restricting user to input just one decimal point and one minus symbol
  return_result: boolean = true;
  validateNumbersWithDecimal(keyevent: any, inputvalue: any, textboxname_1: any): boolean {
    var before_decimalpoint = "";
    var after_decimalpoint = "";
    var charCode = (keyevent.which) ? keyevent.which : keyevent.keyCode;

    var dotcount = (inputvalue.match(/\./g) || []).length;
    var findsDot = new RegExp(/\./g);

    var minuscount = (inputvalue.match(/\-/g) || []).length;
    var findsMinus = new RegExp(/\-/g);

    var containsDot = inputvalue.match(findsDot);
    var containsMinus = inputvalue.match(findsMinus);

    var measure_value = inputvalue.toString().split(".");
    before_decimalpoint = measure_value[0];
    after_decimalpoint = measure_value[1];


    if (charCode != 46 && charCode > 31
      && (charCode < 45 || charCode == 47 || charCode > 57))
      return false;

    if (containsDot != null && ([46, 110, 190].indexOf(keyevent.which) > -1)) {
      keyevent.preventDefault();
      return false;

    }

    if (containsDot == null) {

      if (inputvalue.length == 16 && charCode != 46) {
        return false;
      }
    }

    if (containsDot != null) {
      if (before_decimalpoint.length >= 16 && (charCode <= 57 || charCode >= 48)) {
        this.return_result = false;
      }
      if (after_decimalpoint.length >= 4) {
        this.return_result = false;
      }
    }


    if (containsMinus != null && ([45, 110, 190].indexOf(keyevent.which) > -1)) {
      keyevent.preventDefault();
      return false;
    }

    if (inputvalue != "") {

      if (inputvalue.toString().length == 17) {
        var n = inputvalue.includes(".");

        if (n == true) {
          return true;
        }

        else {

          inputvalue = inputvalue.substr(0, 16);
          switch (textboxname_1) {

            case 'tvalUp1':
              this.tvalUp1 = inputvalue;

              break;

            case 'tvalUp2':
              this.tvalUp2 = inputvalue;

              break;

            case 'tvalDwn1':
              this.tvalDwn1 = inputvalue;
              break;


            case 'tvalDwn2':
              this.tvalDwn2 = inputvalue;
              break;

            case 'tvalBi1':
              this.tvalBi1 = inputvalue;
              break;

            case 'tvalBi2':
              this.tvalBi2 = inputvalue;
              break;

            case 'tvalBi3':
              this.tvalBi3 = inputvalue;
              break;

            case 'tvalBi4':
              this.tvalBi4 = inputvalue;
              break;

            default:
              break;
          };



          return false;
        }
      }

      else if (inputvalue.length < 17) {

        var n = inputvalue.includes(".");

        if (n == true) {
          if (before_decimalpoint.length >= 16) {
            this.return_result = false;
          }
          else { this.return_result = true; }


          return this.return_result;

        }

        else {
          return true;
        }

      }
      else {
        var n = inputvalue.includes(".");
        if (n == true) {


          if (after_decimalpoint.length > 3) {

            this.return_result = false;
          }
          else this.return_result = true;

          if (before_decimalpoint.length > 16) {
            this.return_result = false;
          }
          else { this.return_result = true; }

          return this.return_result;
        }

        else {
          return true;
        }


      }

    }
  }


  //===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
  //This method has been used to validate time span and assign value of textbox with formatting 
  //This method is called on Blur event of timespan textboxes
  validateTimespanValue(inputvalue: any, textboxname: any) {

    if (this.isTimeSpanUint == true) {

      if (inputvalue.length == 0) {//if length is zero then default message will come so set all error flags to false
        this.isTimeSpanError = true;
        this.timeSpanErrorMsg = "Please enter valid Timespan with hh:mm:ss (24 hours) format";
        switch (textboxname) {

          case 'tvalUp1TymSpan':
            this.tvalup1Error = false;

            break;

          case 'tvalUp2TymSpan':
            this.tvalup2Error = false;

            break;

          case 'tvalDwn1TymSpan':
            this.tvaldwn1Error = false;
            break;


          case 'tvalDwn2TymSpan':
            this.tvaldwn2Error = false;
            break;

          case 'tvalBi1TymSpan':
            this.tvalbi1Error = false;
            break;

          case 'tvalBi2TymSpan':
            this.tvalbi2Error = false;
            break;

          case 'tvalBi3TymSpan':
            this.tvalbi3Error = false;
            break;

          case 'tvalBi4TymSpan':
            this.tvalbi4Error = false;
            break;

          default:
            break;
        };
      }
      else {//if length is not zero then validate the value 
        var result = this.validateTimeSpan(inputvalue);// this method is to validate that entered value is valid time span or not
        if (result == false) {//if value is not a valid time span set error flag to true

          this.isTimeSpanError = true;
          this.timeSpanErrorMsg = "Please enter valid Timespan with hh:mm:ss (24 hours) format";
          switch (textboxname) {

            case 'tvalUp1TymSpan':
              this.tvalup1Error = true;

              break;

            case 'tvalUp2TymSpan':
              this.tvalup2Error = true;

              break;

            case 'tvalDwn1TymSpan':
              this.tvaldwn1Error = true;
              break;


            case 'tvalDwn2TymSpan':
              this.tvaldwn2Error = true;
              break;

            case 'tvalBi1TymSpan':
              this.tvalbi1Error = true;
              break;

            case 'tvalBi2TymSpan':
              this.tvalbi2Error = true;
              break;

            case 'tvalBi3TymSpan':
              this.tvalbi3Error = true;
              break;

            case 'tvalBi4TymSpan':
              this.tvalbi4Error = true;
              break;

            default:
              break;
          };
        }
        else {
          switch (textboxname) {// else set the error flag to false and format the value

            case 'tvalUp1TymSpan':
              this.tvalUp1 = moment.utc(inputvalue, "hh:mm:ss").format('HH:mm:ss');// formatting the single digit entry for hh:mm:ss
              this.tvalup1Error = false;
              break;

            case 'tvalUp2TymSpan':
              this.tvalUp2 = moment.utc(inputvalue, "hh:mm:ss").format('HH:mm:ss');// formatting the single digit entry for hh:mm:ss
              this.tvalup2Error = false;
              break;

            case 'tvalDwn1TymSpan':
              this.tvalDwn1 = moment.utc(inputvalue, "hh:mm:ss").format('HH:mm:ss');// formatting the single digit entry for hh:mm:ss
              this.tvaldwn1Error = false;
              break;


            case 'tvalDwn2TymSpan':
              this.tvalDwn2 = moment.utc(inputvalue, "hh:mm:ss").format('HH:mm:ss');// formatting the single digit entry for hh:mm:ss
              this.tvaldwn2Error = false;
              break;

            case 'tvalBi1TymSpan':
              this.tvalBi1 = moment.utc(inputvalue, "hh:mm:ss").format('HH:mm:ss');// formatting the single digit entry for hh:mm:ss
              this.tvalbi1Error = false;
              break;

            case 'tvalBi2TymSpan':
              this.tvalBi2 = moment.utc(inputvalue, "hh:mm:ss").format('HH:mm:ss');// formatting the single digit entry for hh:mm:ss
              this.tvalbi2Error = false;
              break;

            case 'tvalBi3TymSpan':
              this.tvalBi3 = moment.utc(inputvalue, "hh:mm:ss").format('HH:mm:ss');// formatting the single digit entry for hh:mm:ss
              this.tvalbi3Error = false;
              break;

            case 'tvalBi4TymSpan':
              this.tvalBi4 = moment.utc(inputvalue, "hh:mm:ss").format('HH:mm:ss');// formatting the single digit entry for hh:mm:ss
              this.tvalbi4Error = false;
              break;

            default:
              break;
          };


        }
      }
    }
  }

  validateTimeSpan(measureValue): boolean {
    var flag = true;
    if (measureValue != "" && measureValue != null) {
      var tsPart = measureValue.split(":");
      if (tsPart.length == 3) {

        if (tsPart[0] == '24') {
          flag = false;
        }
        else {
          var DT1 = moment.utc(measureValue, "hh:mm:ss").format('HH:mm:ss');
          if (DT1 === "Invalid date" || DT1 === "Invalid Date" ) {
            flag = false;
          }
          else {
            var tsValue = measureValue.split(":")
            var isnum1 = /^\d+$/.test(tsValue[0]);
            var isnum2 = /^\d+$/.test(tsValue[1]);
            var isnum3 = /^\d+$/.test(tsValue[2]);
            if (tsValue[0].length <= 2 && tsValue[1].length <= 2 && tsValue[2].length <= 2) {

              if (isnum1 == false || isnum2 == false || isnum3 == false) {
                flag = false;
              }
            }
            else {
              flag = false;
            }
          }
        }

      }
      else {
        flag = false;
      }
    }
    return flag;
  }

  onPhaseChange(event: any, PhaseName: any) {
    var isChecked = event.currentTarget.checked;
    for (var i = 0; i < this.allSelectedArray.length; i++) {
      var MetricName = this.allSelectedArray[i].Metric.Name
      if (MetricName == PhaseName) {
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

          if (isChecked == true) {
            this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue = true;
          }
          else {
            this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue = false;
          }
        }
      }
    }
  }


  ifAllPhaseSelected(event: any, PhaseName: any) {
    var isChecked = event.currentTarget.checked;
    var isAllSelected = false;
    var isanyPhaseNotSelected = false;
    for (var i = 0; i < this.allSelectedArray.length; i++) {
      var MetricName = this.allSelectedArray[i].Metric.Name
      if (MetricName == PhaseName) {
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

          if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
            isAllSelected = true;
            continue;
          }
          else {
            isAllSelected = false;
            isanyPhaseNotSelected = true;
          }
        }

        if (isChecked == true && isanyPhaseNotSelected == false) {
          this.allSelectedArray[i].IsAllPhaseSelected = isAllSelected;
        }
        else {
          if (isanyPhaseNotSelected == true)
            this.allSelectedArray[i].IsAllPhaseSelected = false;
          else
            this.allSelectedArray[i].IsAllPhaseSelected = true;
        }
      }
    }
  }


  RemoveErrorMessages() {

    this.isError = false;
    this.IsStandMetricNull = false;
    this.tvalup1Error = false;
    this.tvalup2Error = false;
    this.tvaldwn1Error = false;
    this.tvaldwn2Error = false;
    this.tvalbi1Error = false;
    this.tvalbi2Error = false;
    this.tvalbi3Error = false;
    this.tvalbi4Error = false;
  }


  defaultData: any;
  tempDeliveryLevelId: any;
  DeliveryLevelId: any;
  //Restore Default Configuration button click Threshold pop up
  onRestoreDefaultClick() {

    this.RemoveErrorMessages();

    if (this.sessionStorage.teamUId != null && this.sessionStorage.teamUId != '') {
      this.deliveryLevelId = enumMetricDeliveryLevel.Team;
      this.deliveryId = this.sessionStorage.teamUId
    }
    else {
      this.deliveryLevelId = enumMetricDeliveryLevel.E2E;
      this.deliveryId = this.sessionStorage.endToEndUId
    }
    this.tempDeliveryStructId = this.deliveryId;
    this.tempDeliveryLevelId = this.deliveryLevelId;

    this.defaultData = new Array<any>();
    for (var i = 0; i < (this.allSelectedArray.length); i++) {

      if (this.GridCategory == "Overall" || this.GridCategory == undefined) {

        if ((this.allSelectedArray[i].DeliveryStructLevelId == this.deliveryLevelId) &&
          this.allSelectedArray[i].TrendTypeid != null) {
          this.defaultData.push({

            MetricThresholdId: this.allSelectedArray[i].MetricThresholdId,
            MetricId: this.allSelectedArray[i].MetricId,
            DeliveryStructId: this.allSelectedArray[i].DeliveryStructId,
            DeliveryStructLevelId: this.tempDeliveryLevelId,
          })
        }
      }
      else {
        //To fix defect Restore default configuation
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

          if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.DeliveryStructLevelId == this.deliveryLevelId)) {
            this.defaultData.push({

              MetricThresholdId: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.MetricThresholdId,
              MetricId: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.MetricId,
              DeliveryStructId: this.allSelectedArray[i].DeliveryStructId,
              DeliveryStructLevelId: this.tempDeliveryLevelId,
            })
          }
        }
      }
    }
    this.restoreConfig(this.defaultData);

  }

  //Restore Default config call(Pop-Up)
  restoreConfig(deleteData: any): void {
    //this.load = true;
    //this.showModal = false;
    $('.modal-backdrop').hide();
    $('body').removeClass('modal-open');

    this.metricService.restoreThresholds(deleteData).subscribe(
      contexts => {
        var res = contexts;
        this.bsModalRef.hide();
        this.allSelectedArray = [];
        this.logicForButton(this.allSelectedArray);
        this.thresholdData.forEach(item => item.chkValue = false);
        this.sharedService.openAlertPopup("Data restored Successfully!", window.scrollY);
        this.rIMSummaryService.onBroadcastToGridView(res);
      },
      error => { this.errorMessage = <any>error; console.log(error); });
  }

  //Customize button disable logic
  logicForButton(_selectedThreshold: any) {

    var distinctThresholdType = {};
    var distinctTrackbyPhases = {};
    var distinctMetrics = {};
    var distinctDGPhases = {};
    //===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    var distinctUnitId = {};//used to get distinct metric unit id to show timespan placeholder
    this.isTimeSpanUint = false;
    this.isTimeSpanError = false;
    this.timeSpanErrorMsg = "";
    var distinctTrend = {};//used to get distinct tendtype id to show note for timespan metrics
    //==============================end=================
    for (var i = 0; i < this.allSelectedArray.length; i++) {
      // for distinct threshold type
      var value = this.allSelectedArray[i].ThresholdType.Name;
      var metricUnit = this.allSelectedArray[i].Metric.Unitid;// assigned metric id===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
      // for distinct Defect grid threshold
      var DGPhase = this.allSelectedArray[i].DGPhasesThreshold;
      // for distinct trackbyphases on/off
      var trackbyphases = this.allSelectedArray[i].IsTrackbyPhases;
      var trendId = this.allSelectedArray[i].TrendTypeid;// assigned trendtype id===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
      var metricNames = this.allSelectedArray[i].Metric.Name;

      distinctThresholdType[value] = '';
      distinctTrackbyPhases[trackbyphases] = '';
      distinctDGPhases[DGPhase] = '';
      distinctUnitId[metricUnit] = '';// assigned all metric id in collection===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
      distinctTrend[trendId] = '';// assigned all trendtype id in collection===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
      distinctMetrics[metricNames] = '';

    };
    var distincType = Object.keys(distinctThresholdType);
    var distinctDGThreshold = Object.keys(distinctDGPhases);
    var distinctPhase = Object.keys(distinctTrackbyPhases);
    var distinctMetriUnit = Object.keys(distinctUnitId);//getting keys of array to do further enumerable operations===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    var distinctMetricNames = Object.keys(distinctMetrics);
    //Getting distinct trackbyphase either on or off
    var phase = Object.keys(distinctTrackbyPhases)[0];
    var distinctTrendId = Object.keys(distinctTrend);//getting keys of array to do further enumerable operations===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    var trendIdToDisplay = Object.keys(distinctTrend)[0];//getting value of first element===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    //check length of the array and set value to show note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    if (distinctTrendId.length == 1) {
      this.timeSpanNote = +trendIdToDisplay;//to convert string to number
    }
    else {
      this.timeSpanNote = 0;//set default value to hide note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    }
    //=======================end==============================
    if (distincType.length == 1) {

      if (distinctPhase.length == 1) {
        //If trackbyphase is off and DG and Non DG metrics are paired up then customize button is enabled
        if (phase.includes("false")) {
          this.isDisabled = true;
          this.ToolTipMsg = "";
        }
        //If trackbyphase is on and all Non DG metrics paired up then customize button is enabled
        else if (phase.includes("true") && distinctDGThreshold.length == 1 && distinctDGThreshold[0].includes("null")) {
          this.isDisabled = true;
          this.ToolTipMsg = "";
        }

        else if (phase.includes("true") && distinctDGThreshold.length > 0) {
          var isNonDG = distinctDGThreshold.find(x => x.includes("null"));
          //If trackbyphase is on and DG and Non DG metrics are paired up then customize button is disable
          if (isNonDG == "null") {
            this.isDisabled = false;
            this.ToolTipMsg = "Please do not select Defect Grid metrics with Non Defect Grid metrics.";
          }
          //If trackbyphase is on and DG metrics are paired up then customize button is enabled
          else {
            this.isDisabled = true;
            this.ToolTipMsg = "";
          }
        }
        //Checked length and the unitid is present or not in array ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
        if (distinctMetriUnit.length == 1) {
          if (distinctMetriUnit.indexOf('28') > -1) {//element is present then set the flag true else false
            this.isTimeSpanUint = true;
          }
          else {
            this.isTimeSpanUint = false;
          }
        }
        else {   //if more user selects timespan metric with non timespan metric 
          if (distinctMetriUnit.indexOf('28') > -1) {//element is present then set the flag false and return error message
            this.isDisabled = false;

            this.ToolTipMsg = "Please do not select Timespan(hh:mm:ss) metrics with non Timespan(hh:mm:ss) metrics.";
            this.isTimeSpanUint = false;
          }
          else {
            this.isDisabled = true;
            this.ToolTipMsg = "";
            this.isTimeSpanUint = false;
          }
        }
        //=============end============================================================
      }
    }
    else {
      this.isDisabled = false;

      this.ToolTipMsg = "Please select metrics with same type of thresholds.";
    }
    if (this.allSelectedArray.length == 0) {
      this.ToolTipMsg = "Please select any metrics.";
    }

    //To disable customize button - Story 1051466: Global thresholds to be updated for Number of Major Incidents core metric
    for (var m = 0; m < this.allSelectedArray.length; m++) {
      for (var n = 0; n < distinctMetricNames.length; n++) {
        this.mir = false;
        if (distinctMetricNames[n] == 'Number of Major Incidents Reported') {
          this.mir = true;
          break;
        }
      }
    }

    if (this.mir && distinctMetricNames.length != 1) {
      this.isDisabled = false;
      this.ToolTipMsg = "Number of Major Incidents Reported(MIR) cannot be customized with other metric(s).";
    }
  }



  //Save button click Threshold pop up
  onSaveThresholdClick() {
    
    //this.objGlobal.setSessionValue("showThreshold", "false");//code added as part of story : 2179221
    this.isPhaseSelected = false;
    this.isError = false;

    this.IsStandMetricNull = false;
    this.selectedTrend;
    this.thresholdSaveData = new Array<any>();


    if (this.sessionStorage.teamUId != null && this.sessionStorage.teamUId != '') {
      this.deliveryLevelId = enumMetricDeliveryLevel.Team;
      this.deliveryId = this.sessionStorage.teamUId
    }
    else {
      this.deliveryLevelId = enumMetricDeliveryLevel.E2E;
      this.deliveryId = this.sessionStorage.endToEndUId
    }
    var enterPriseId = this.sessionStorage.enterpriseId;
    this.tempDeliveryStructId = this.deliveryId;
    this.tempDeliveryLevelId = this.deliveryLevelId;

    if (this.selectedTrendId == 0 ) {
      this.isError = true;
      this.Error = "Please select Trend Direction."
      return;
    }
    var count: number = 0;
    for (var i = 0; i < (this.allSelectedArray.length); i++) {
      //For overall metric and normal metric
      if (this.GridCategory == "Overall" || this.GridCategory == undefined) {
        var enterPriseId = this.sessionStorage.enterpriseId;

        this.thresholdSaveData.push({
          MetricThresholdId: this.allSelectedArray[i].MetricThresholdId,
          MetricId: this.allSelectedArray[i].MetricId,
          ThresholdTypeid: this.isRangeTrend == 4 ? 3 : 1,
          OptRefinementid: 2,
          TrendTypeid: 1,
          DeliveryStructId: this.allSelectedArray[i].DeliveryStructId,
          DeliveryStructLevelId: this.tempDeliveryLevelId,
          Threshold1: this.tvalUp1,
          Threshold2: this.tvalUp2,
          Threshold3: this.tvalUp1,
          Threshold4: this.tvalUp2,
          CreatedByUser: enterPriseId,
          RowVersion: this.allSelectedArray[i].RowVersion,
          CreatedOn: this.allSelectedArray[i].CreatedOn,
          CreatedByApp: this.allSelectedArray[i].CreatedByApp,
          ModifiedByUser: enterPriseId,
          ModifiedOn: this.allSelectedArray[i].CreatedOn,
          ModifiedByApp: this.allSelectedArray[i].CreatedByApp,
          DeliveryStructUId: this.tempDeliveryStructId,
        })
        if (this.trendDirectionOptions.length == 3) {
          switch (this.selectedTrendId) {

            case 1:

              if ((this.tvalUp1 == null || this.tvalUp2 == null) || (this.tvalUp1 == "" || this.tvalUp2 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }
              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalUp1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalUp2);

                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                  this.isError = true;
                }

                this.tvalup1Error = DecimalCheckThreshold1;
                this.tvalup2Error = DecimalCheckThreshold2;
              }
              else {
                if (this.tvalup1Error || this.tvalup2Error) { //set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                 
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }

              if (this.tvalUp1 != null && this.tvalUp2 != null) {

                this.thresholdSaveData[i].TrendTypeid = 1;
                this.thresholdSaveData[i].Threshold1 = this.tvalUp1;
                this.thresholdSaveData[i].Threshold2 = this.tvalUp2;
                this.thresholdSaveData[i].Threshold3 = null;
                this.thresholdSaveData[i].Threshold4 = null;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if (this.tvalUp1.substr(0, 1) == '.' && this.tvalUp1.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalUp1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalUp2;
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if (this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = this.tvalUp1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalUp2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if ((this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1) && (this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalUp1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalUp2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }
                }
              }
              break;

            case 2:

              if ((this.tvalBi1 == null || this.tvalBi2 == null || this.tvalBi3 == null || this.tvalBi4 == null) || (this.tvalBi1 == "" || this.tvalBi2 == "" || this.tvalBi3 == "" || this.tvalBi4 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }
              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===

                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalBi1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalBi2);
                var DecimalCheckThreshold3 = this.ValidateThresholdValue(this.tvalBi3);
                var DecimalCheckThreshold4 = this.ValidateThresholdValue(this.tvalBi4);
                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                  this.isError = true;
                }

                this.tvalbi1Error = DecimalCheckThreshold1;
                this.tvalbi2Error = DecimalCheckThreshold2;
                this.tvalbi3Error = DecimalCheckThreshold3;
                this.tvalbi4Error = DecimalCheckThreshold4;
              }
              else {
                if (this.tvalbi1Error || this.tvalbi2Error || this.tvalbi3Error || this.tvalbi4Error) {     //set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                                              
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }

              if (this.tvalBi1 != null && this.tvalBi2 != null && this.tvalBi3 != null && this.tvalBi4 != null) {
                this.thresholdSaveData[i].TrendTypeid = 2;
                this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                this.thresholdSaveData[i].Threshold4 = this.tvalBi4;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if ((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && ((this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;
                  }

                  if ((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if ((this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }

                  if ((this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }

                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1)) && ((this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && ((this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }

                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if ((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                }
              }
              break;

            case 3:

              if ((this.tvalDwn1 == null || this.tvalDwn2 == null) || (this.tvalDwn1 == "" || this.tvalDwn2 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }
              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalDwn1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalDwn2);

                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                  this.isError = true;
                }

                this.tvaldwn1Error = DecimalCheckThreshold1;
                this.tvaldwn2Error = DecimalCheckThreshold2;
              }
              else {
                if (this.tvaldwn1Error || this.tvaldwn2Error) { //set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                                              
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }


              if (this.tvalDwn1 != null && this.tvalDwn2 != null) {

                this.thresholdSaveData[i].TrendTypeid = 3;
                this.thresholdSaveData[i].Threshold1 = this.tvalDwn1;
                this.thresholdSaveData[i].Threshold2 = this.tvalDwn2;
                this.thresholdSaveData[i].Threshold3 = null;
                this.thresholdSaveData[i].Threshold4 = null;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if (this.tvalDwn1.substr(0, 1) == '.' && this.tvalDwn1.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalDwn1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalDwn2;
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;
                  }

                  if (this.tvalDwn2.substr(0, 1) == '.' && this.tvalDwn2.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = this.tvalDwn1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalDwn2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;
                  }

                  if ((this.tvalDwn1.substr(0, 1) == '.' && this.tvalDwn1.length != 1) && (this.tvalDwn2.substr(0, 1) == '.' && this.tvalDwn2.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalDwn1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalDwn2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;
                  }
                }
              }
              break;

            default:
              break;
          }
        }
        else {
          switch (this.selectedTrendId) {

            case 0:

              if ((this.tvalUp1 == null || this.tvalUp2 == null) || (this.tvalUp1 == "" || this.tvalUp2 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }

              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalUp1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalUp2);

                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                  this.isError = true;

                }

                this.tvalup1Error = DecimalCheckThreshold1;
                this.tvalup2Error = DecimalCheckThreshold2;

              }
              else {
                if (this.tvalup1Error || this.tvalup2Error) { //set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                       
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }

              if (this.tvalUp1 != null && this.tvalUp2 != null) {

                this.thresholdSaveData[i].TrendTypeid = 1;
                this.thresholdSaveData[i].Threshold1 = this.tvalUp1;
                this.thresholdSaveData[i].Threshold2 = this.tvalUp2;
                this.thresholdSaveData[i].Threshold3 = null;
                this.thresholdSaveData[i].Threshold4 = null;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if (this.tvalUp1.substr(0, 1) == '.' && this.tvalUp1.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalUp1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalUp2;
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if (this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = this.tvalUp1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalUp2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if ((this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1) && (this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalUp1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalUp2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;
                  }
                }
              }

              break;

            case 1:

              if ((this.tvalBi1 == null || this.tvalBi2 == null || this.tvalBi3 == null || this.tvalBi4 == null) || (this.tvalBi1 == "" || this.tvalBi2 == "" || this.tvalBi3 == "" || this.tvalBi4 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }
              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalBi1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalBi2);
                var DecimalCheckThreshold3 = this.ValidateThresholdValue(this.tvalBi3);
                var DecimalCheckThreshold4 = this.ValidateThresholdValue(this.tvalBi4);

                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                  this.isError = true;
                }

                this.tvalbi1Error = DecimalCheckThreshold1;
                this.tvalbi2Error = DecimalCheckThreshold2;
                this.tvalbi3Error = DecimalCheckThreshold3;
                this.tvalbi4Error = DecimalCheckThreshold4;
              }
              else {
                if (this.tvalbi1Error || this.tvalbi2Error || this.tvalbi3Error || this.tvalbi4Error) {//set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                 
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }

              if (this.tvalBi1 != null && this.tvalBi2 != null && this.tvalBi3 != null && this.tvalBi4 != null) {


                this.thresholdSaveData[i].TrendTypeid = 2;
                this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                this.thresholdSaveData[i].Threshold4 = this.tvalBi4;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if ((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && ((this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }


                  if ((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if ((this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }

                  if ((this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }

                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1)) && ((this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && ((this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }

                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if ((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);
                  }
                }
              }
              break;

            case 2:

              if ((this.tvalDwn1 == null || this.tvalDwn2 == null) || (this.tvalDwn1 == "" || this.tvalDwn2 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }

              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalDwn1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalDwn2);

                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                  this.isError = true;
                }

                this.tvaldwn1Error = DecimalCheckThreshold1;
                this.tvaldwn2Error = DecimalCheckThreshold2;
              }
              else {
                if (this.tvaldwn1Error || this.tvaldwn2Error) {//set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                 
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }


              if (this.tvalDwn1 != null && this.tvalDwn2 != null) {

                this.thresholdSaveData[i].TrendTypeid = 3;
                this.thresholdSaveData[i].Threshold1 = this.tvalDwn1;
                this.thresholdSaveData[i].Threshold2 = this.tvalDwn2;
                this.thresholdSaveData[i].Threshold3 = null;
                this.thresholdSaveData[i].Threshold4 = null;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if (this.tvalDwn1.substr(0, 1) == '.' && this.tvalDwn1.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalDwn1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalDwn2;
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if (this.tvalDwn2.substr(0, 1) == '.' && this.tvalDwn2.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = this.tvalDwn1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalDwn2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if ((this.tvalDwn2.substr(0, 1) == '.' && this.tvalDwn2.length != 1) && (this.tvalDwn1.substr(0, 1) == '.' && this.tvalDwn1.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalDwn1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalDwn2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;
                  }
                }
              }
              break;

            default:
              break;
          };
        }
      }
      else {
        //For defect grid - phases     
        this.isPhaseSelected = false;    //Defect fix- 278474     
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
          if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
            this.isPhaseSelected = true;
          }

          this.thresholdSaveData.push({
            MetricThresholdId: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.MetricThresholdId,
            MetricId: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.MetricId,
            ThresholdTypeid: this.isRangeTrend == 4 ? 3 : 1,
            OptRefinementid: 2,
            TrendTypeid: 1,
            DeliveryStructId: this.allSelectedArray[i].DeliveryStructId,
            DeliveryStructLevelId: this.tempDeliveryLevelId,
            Threshold1: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1,
            Threshold2: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2,
            Threshold3: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1,
            Threshold4: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2,
            CreatedByUser: enterPriseId,
            RowVersion: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.RowVersion,
            CreatedOn: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.CreatedOn,
            CreatedByApp: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.CreatedByApp,
            ModifiedByUser: enterPriseId,
            ModifiedOn: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.CreatedOn,
            ModifiedByApp: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.CreatedByApp,
            DeliveryStructUId: this.tempDeliveryStructId,
          })


          if (this.trendDirectionOptions.length == 4) {
            switch (this.selectedTrendId) {

              case 1:

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null;
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;

                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "")) {


                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.isError = true;
                      this.Error = "Please enter threshold values.";
                    }
                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;


                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null) {

                    this.thresholdSaveData[count].TrendTypeid = 1;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = null;
                    this.thresholdSaveData[count].Threshold4 = null;

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                  }
                }
                break;

              case 2:
                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null;
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = false;

                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                  var DecimalCheckThreshold3 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                  var DecimalCheckThreshold4 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 == "")) {


                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.Error = "Please enter threshold values.";
                    }
                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = DecimalCheckThreshold3;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = DecimalCheckThreshold4;

                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 != null) {

                    this.thresholdSaveData[count].TrendTypeid = 2;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                    this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }

                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)))) {

                      this.thresholdSaveData[j].TrendTypeid = 2;
                      this.thresholdSaveData[j].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[j].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[j].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[j].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }

                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                  }
                }
                break;

              case 3:

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null;
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;

                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "")) {


                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.Error = "Please enter threshold values.";
                    }
                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;


                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null) {

                    this.thresholdSaveData[count].TrendTypeid = 3;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = null;
                    this.thresholdSaveData[count].Threshold4 = null;

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;
                    }
                  }
                }
                break;

              default:
                break;
            };
          }
          else {
            switch (this.selectedTrendId) {

              case 1:

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null;
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "")) {



                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.Error = "Please enter threshold values.";
                    }
                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;


                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null) {


                    this.thresholdSaveData[count].TrendTypeid = 1;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = null;
                    this.thresholdSaveData[count].Threshold4 = null;

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                  }

                }
                break;

              case 2:

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null; //TrendTypeid = 2
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = false;
                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                  var DecimalCheckThreshold3 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                  var DecimalCheckThreshold4 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 == "")) {


                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.Error = "Please enter threshold values.";
                    }

                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = DecimalCheckThreshold3;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = DecimalCheckThreshold4;

                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 != null) {


                    this.thresholdSaveData[count].TrendTypeid = 2;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                    this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }


                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }

                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }

                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);
                    }
                  }
                }
                break;

              case 3:

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null;
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;

                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "")) {


                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.Error = "Please enter threshold values.";
                    }
                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;


                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null) {

                    this.thresholdSaveData[count].TrendTypeid = 3;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = null;
                    this.thresholdSaveData[count].Threshold4 = null;

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                  }

                }
                break;

              default:
                break;
            };

          }

          count++;

        }

        if (this.isPhaseSelected == false) {

          //this.alertBox.show("Error:", "Track by phases is set on , but none of the phases are selected. Please mark Track by phases OFF and then continue with save.", "No");
          return;
        }
      }
    }
    if (this.isError == true) {
      return;
    }

    this.saveThreshold(this.thresholdSaveData);
    this.allSelectedArrayClone = [];
  }

  ValidateThresholdValue(Threshold: any) {
    var errorFlag = false;// if errorflag is true then error is there
    if (Threshold != null && Threshold != '') {
      var pattern = /^(\-)?[0-9]{0,}((\.){1}[0-9]{1,}){0,1}$/;  //PRB0060759
      if ((Threshold.substr(Threshold.length - 1) == '.')
        || (Threshold.substr(0, 1) == '.' && Threshold.length == 1)
        || (Threshold.substr(0, 1) == '-' && Threshold.length == 1)
        || (!Threshold.toString().match(pattern))) {  //PRB0060759
        this.Error = "Please enter valid decimal values"
        return true;
      }

      var returnValBeforeDec = false;
      var retrunValAfterDec = false;

      var value_tval = Threshold.split(".");;
      var before_decimalpoint_tval = value_tval[0];
      var after_decimalpoint_tval = value_tval[1];


      if (before_decimalpoint_tval != undefined) {
        if (before_decimalpoint_tval.length > 16) {
          returnValBeforeDec = true;
          this.Error = "You can enter maximum 16 digits before and 4 digits after decimal"
        }
        else {
          returnValBeforeDec = false;
        }
      }
      if (after_decimalpoint_tval != undefined) {
        if (after_decimalpoint_tval.length > 4) {
          retrunValAfterDec = true;
          this.Error = "You can enter maximum 16 digits before and 4 digits after decimal"
        }
        else {
          retrunValAfterDec = false;
        }
      }
      if (retrunValAfterDec == true || returnValBeforeDec == true) {
        errorFlag = true;
      }

      else {

        errorFlag = false
      }
    }
    else {

      errorFlag = true;
    }
    return errorFlag;
  }

  saveThreshold(saveData: any): void {
    // Defect id 1064994 : issue 2 -Threshold pop up is appearing twice before getting saved by sagar
    //this.loadingStatus = true;
    //this.showModal = false;
    $('.modal-backdrop').hide();
    $('body').removeClass('modal-open');
    this.metricService.saveThresholds(saveData, this.IsTrackByPhase, this.GridCategory).subscribe(
      contexts => {
        var res = contexts;
        this.bsModalRef.hide();
        this.sharedService.openAlertPopup("Data saved Successfully!", window.scrollY);
        this.rIMSummaryService.onBroadcastToGridView(res);
      },
      error => { this.errorMessage = <any>error; console.log(error); });

    /* this.applyFilter();*/
  }
  onCancelClick(value: any) {
    this.bsModalRef.hide();
  }
  // toggling between Overall to Phases
  isPhaseChecked() {
    this.RemoveErrorMessages();
    if (this.IsTrackByPhase == true) {
      this.phase();
      return !this.IsTrackByPhase;
    }
    else {
      //Checking gridcategory for overall metric
      for (var i = 0; i < this.allSelectedArray.length; i++) {
        this.GridCategory = this.allSelectedArray[i].Metric.GridCategory;
        if (this.GridCategory == "Overall")
          break;
      }
      this.overAllCustomize();
      return this.IsTrackByPhase;
    }
  }
  //Defect grid - Phase data binding
  phase() {
    this.btnRestore = true;
    this.isUpdated = true;

    //To find grid category of metric of phase 
    for (var i = 0; i < this.allSelectedArray.length; i++) {
      for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
        this.GridCategory = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Metric.GridCategory;
        if (this.GridCategory == "DefectGrid")
          break;
      }
    }

    if (this.trendDirectionOptions.length == 4) {
      this.trendDirectionOptions.splice(0, 1);
      //deleting   select option in trend direction pop up
    }

    var option;

    //Find trend Direction of single metric selection
    if (this.allSelectedArray.length == 1) {
      option = this.allSelectedArray[0].DGPhasesThreshold[0]._MetricThreshold.TrendTypeid;
      if (option == null) {
        for (var i = 0; i < (this.allSelectedArray.length); i++) {
          for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
            //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
            if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid != null) {
              option = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid;
              break;
            }
            else
              continue
          }
        }
      }
    }
    else {
      //Find trend Direction of multiple metric selection
      for (var i = 0; i < (this.allSelectedArray.length - 1); i++) {
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
          if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid != null)) {
            for (var k = 0; k < (this.allSelectedArray[i + 1].DGPhasesThreshold.length); k++) {
              if (this.allSelectedArray[i + 1].DGPhasesThreshold[k]._MetricThreshold.TrendTypeid == this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid) {
                option = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid;
                break;
              }
              else
                continue;
            }
          }
          else
            continue;
        }
      }
    }

    var Direction = '';

    if (option == 1) {
      Direction = 'Upward';
    }
    if (option == 2) {
      Direction = 'Bi';
    }
    if (option == 3) {
      Direction = 'Downward';
    }

    // Assigning threshold values of phases in one Trend type and removing threshold value for other trend types        

    if (this.TempAllSelectedUpwardMetrics != null && (Direction == "Bi" || Direction == "Downward")) {
      for (var i = 0; i < (this.TempAllSelectedUpwardMetrics.length); i++) {

        for (var j = 0; j < (this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold.length); j++) {
          this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 = null;
          this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 = null;
          this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 = null;
          this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 = null;
          //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
          this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold[j].chkDGValue = false;
        }
      }
    }

    if (this.TempAllSelectedDownwardMetrics != null && (Direction == "Upward" || Direction == "Bi")) {
      for (var i = 0; i < (this.TempAllSelectedDownwardMetrics.length); i++) {
        for (var j = 0; j < (this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold.length); j++) {
          this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 = null;
          this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 = null;
          this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 = null;
          this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 = null;
          //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
          this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold[j].chkDGValue = false;
        }
      }
    }

    if (this.TempAllSelectedBothdirectionMetrics != null && (Direction == "Upward" || Direction == "Downward")) {
      for (var i = 0; i < (this.TempAllSelectedBothdirectionMetrics.length); i++) {

        for (var j = 0; j < (this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold.length); j++) {
          this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 = null;
          this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 = null;
          this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 = null;
          this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 = null;
          //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
          this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold[j].chkDGValue = false;
        }
      }
    }

    if (this.allSelectedArray.length == 1) {
      var x = false;
      for (var i = 0; i < this.allSelectedArray.length; i++) {
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = false;

        }
      }

      switch (Direction) {
        case 'Upward':
          this.rowUp = false
          this.rowDown = true
          this.rowBidirectional = true
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedUpwardMetrics.map((x: any) => Object.assign({}, x));
          this.selectedTrend = this.trendDirectionOptions[0].Name;
          this.selectedTrendId = 1;

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        case 'Downward':
          this.rowUp = true
          this.rowDown = false
          this.rowBidirectional = true
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedDownwardMetrics.map((x: any) => Object.assign({}, x));
          this.selectedTrend = this.trendDirectionOptions[2].Name;
          this.selectedTrendId = 3;

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        case 'Bi':
          this.rowUp = true
          this.rowDown = true
          this.rowBidirectional = false
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedBothdirectionMetrics.map((x: any) => Object.assign({}, x));
          this.selectedTrend = this.trendDirectionOptions[1].Name;
          this.selectedTrendId = 2;

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        default:
          if (this.trendDirectionOptions.length != 4) {
            this.trendDirectionOptions.unshift({ TrendTypeId: 0, Name: "--Select--" }) //binding select option in trend direction pop up
          }

          this.rowUp = true;
          this.rowDown = true;
          this.rowBidirectional = true;
          this.selectedTrendId = 0;
          this.selectedTrend = this.trendDirectionOptions[0].Name;

          if (this.TempAllSelectedDownwardMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedDownwardMetrics.length); m++) {
              for (var n = 0; n < (this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedDownwardMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }
          if (this.TempAllSelectedUpwardMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedUpwardMetrics.length); m++) {
              for (var n = 0; n < (this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedUpwardMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }
          if (this.TempAllSelectedBothdirectionMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedBothdirectionMetrics.length); m++) {

              for (var n = 0; n < (this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedBothdirectionMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }


          break;
      }
      if (this.allSelectedArray[0].DGPhasesThreshold[0]._MetricThreshold.DeliveryStructLevelId == this.DeliveryLevelId) {
        this.isUpdated = false;

       // var date = this.formatDate(this.allSelectedArray[0].DGPhasesThreshold[0]._MetricThreshold.ModifiedOn);
        //this.lastUpdated = 'Last updated on ' + date + ' GMT by ' + this.allSelectedArray[0].DGPhasesThreshold[0]._MetricThreshold.ModifiedByUser;
      }

    }
    else {
      //logic for checking multiple records for trend directions and thresholds, if values are same then only binding for Range threshold pop up

      var Ttype = this.allSelectedArray[0].ThresholdType.Name;
      this.changeTrigger++;

      if (Ttype == "Relative Trend") { // new logic added as part of story : 2824892
        this.btnRestore = true;
        this.isError = false;
        this.showModal = true;
        this.tempIsRangeTrend = 4;
        this.isRangeTrend = 4;
        this.isUpdated = true;
      }
      else if (Ttype == "Trend") {
        this.showModal = true;
        this.tempIsRangeTrend = 2;
        this.isRangeTrend = 2;
      }
      else {
        this.btnRestore = true;
        this.isError = false;
        this.showModal = true;
        this.tempIsRangeTrend = 1;
        this.isRangeTrend = 1;

        this.isUpdated = true;
      }
      var x = false;
      for (var i = 0; i < this.allSelectedArray.length; i++) {
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = false;

        }
      }

      switch (Direction) {

        case 'Upward':
          this.rowUp = false
          this.rowDown = true
          this.rowBidirectional = true
          this.selectedTrend = this.trendDirectionOptions[0].Name;
          this.selectedTrendId = 1;
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedUpwardMetrics.map((x: any) => Object.assign({}, x));

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        case 'Downward':
          this.rowUp = true
          this.rowDown = false
          this.rowBidirectional = true
          this.selectedTrend = this.trendDirectionOptions[2].Name;
          this.selectedTrendId = 3;
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedDownwardMetrics.map((x: any) => Object.assign({}, x));

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        case 'Bi':
          this.rowUp = true
          this.rowDown = true
          this.rowBidirectional = false
          this.selectedTrend = this.trendDirectionOptions[1].Name;
          this.selectedTrendId = 2;
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedBothdirectionMetrics.map((x: any) => Object.assign({}, x));

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        default:
          if (this.trendDirectionOptions.length != 4) {
            this.trendDirectionOptions.unshift({ TrendTypeId: 0, Name: "--Select--" }) //binding select option in trend direction pop up
          }

          //Setting threshold data as null when trend directions are mismatched

          this.rowUp = true;
          this.rowDown = true;
          this.rowBidirectional = true;
          this.selectedTrendId = 0;
          this.selectedTrend = this.trendDirectionOptions[0].Name;

          if (this.TempAllSelectedDownwardMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedDownwardMetrics.length); m++) {
              for (var n = 0; n < (this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedDownwardMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }
          if (this.TempAllSelectedUpwardMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedUpwardMetrics.length); m++) {
              for (var n = 0; n < (this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedUpwardMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }
          if (this.TempAllSelectedBothdirectionMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedBothdirectionMetrics.length); m++) {

              for (var n = 0; n < (this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedBothdirectionMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }
          break;
      }

    }

    this.disableRestoreButton();
  }
  disableRestoreButton(): void {
    if (this.IsTrackByPhase == false) {
      for (var i = 0; i < this.allSelectedArray.length; i++) {
        if ((this.allSelectedArray[i].DeliveryStructLevelId == this.DeliveryLevelId) && this.allSelectedArray[i].TrendTypeid != null) {
          this.btnRestore = false;

        }
        if (this.allSelectedArray[i].IsCustom == true) {
          this.btnRestore = true;
          break;
        }
      }
    }
    else {
      //To fix defect Restore default configuation
      for (var i = 0; i < this.allSelectedArray.length; i++) {
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
          if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.DeliveryStructLevelId == this.DeliveryLevelId) && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid != null) {
            this.btnRestore = false;
            break;
          }
          if (this.allSelectedArray[i].IsCustom == true) {
            this.btnRestore = true;
            break;
          }
        }
      }
    }
  }
  CloseTrendPopup(data: any): void {
    this.saveThreshold(data);
  }
  overAllCustomize() {
    var Ttype = this.allSelectedArray[0].ThresholdType.Name;
    this.changeTrigger++;

    if (Ttype == "Trend") {
      this.showModal = true;
      this.tempIsRangeTrend = 2;
      this.isRangeTrend = 2;
    }
    else {
      this.btnRestore = true;
      this.isError = false;
      this.showModal = true;
      if (Ttype == "Relative Trend") {// new logic added as part of story : 2824892
        this.tempIsRangeTrend = 4;
        this.isRangeTrend = 4;
      }
      else {
        this.tempIsRangeTrend = 1;
        this.isRangeTrend = 1;
      }

      this.tvalUp1 = null;
      this.tvalUp2 = null;
      this.tvalDwn1 = null;
      this.tvalDwn2 = null;
      this.tvalBi1 = null;
      this.tvalBi2 = null;
      this.tvalBi3 = null;
      this.tvalBi4 = null;
      this.isUpdated = true;


      if (this.trendDirectionOptions.length == 4) {
        this.trendDirectionOptions.splice(0, 1);
        //deleting   select option in trend direction pop up
      }

      if (this.allSelectedArray.length == 1) {  //for single record data bind based on direction for Range threshold pop up

        this.tempVal1 = this.allSelectedArray[0].Threshold1;
        this.tempVal2 = this.allSelectedArray[0].Threshold2;
        this.tempVal3 = this.allSelectedArray[0].Threshold3;
        this.tempVal4 = this.allSelectedArray[0].Threshold4;

        if (this.tempTrend == null) {
          this.tempTrend = 0;
        }
        switch (this.allSelectedArray[0].TrendTypeid) {

          case 1:                                                           //Upward Trending
            this.rowUp = false
            this.rowDown = true
            this.rowBidirectional = true
            this.tvalUp1 = this.allSelectedArray[0].Threshold1;
            this.tvalUp2 = this.allSelectedArray[0].Threshold2;
            this.selectedTrend = this.trendDirectionOptions[0].Name;
            this.selectedTrendId = 1;
            this.tempTrend = 1;
            break;
          case 3:                                                            //Downward Trending
            this.rowUp = true
            this.rowDown = false
            this.rowBidirectional = true
            this.tvalDwn1 = this.allSelectedArray[0].Threshold1;
            this.tvalDwn2 = this.allSelectedArray[0].Threshold2;
            this.selectedTrend = this.trendDirectionOptions[2].Name;
            this.selectedTrendId = 3;
            this.tempTrend = 3;
            break;
          case 2:                                                            //Bi-Directional Trending
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = false
            this.tvalBi1 = this.allSelectedArray[0].Threshold1;
            this.tvalBi2 = this.allSelectedArray[0].Threshold2;
            this.tvalBi3 = this.allSelectedArray[0].Threshold3;
            this.tvalBi4 = this.allSelectedArray[0].Threshold4;
            this.selectedTrend = this.trendDirectionOptions[1].Name;
            this.selectedTrendId = 2;
            this.tempTrend = 2;
            break;

          default:
            this.TTid = this.allSelectedArray[0].TrendTypeid;
            if ((this.TTid == null || this.TTid == 0) && this.trendDirectionOptions.length != 4) {
              //this.trendDirectionOptions.unshift({ TrendTypeId: 0, Name: "--Select--" }) //binding select option in trend direction pop up
              this.timeSpanNote = 0;//set default value to hide note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            }
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = true
            // this.selectedTrend = this.trendDirectionOptions[0].Name;
            this.selectedTrendId = 0;
            this.tempTrend = 0;
            break;
        }
        if (this.allSelectedArray[0].DeliveryStructLevelId == this.deliveryLevelId) {

          this.isUpdated = false;
          //var date = this.formatDate(this.allSelectedArray[0].ModifiedOn);

          //this.lastUpdated = 'Last updated on ' + date + ' GMT by ' + this.allSelectedArray[0].ModifiedByUser;
        }

      }
      else {

        for (var i = 0; i < (this.allSelectedArray.length - 1);) {                       //logic for checking multiple records for trend directions and thresholds, if values are same then only binding for Range threshold pop up

          if (this.allSelectedArray[i].TrendTypeid == this.allSelectedArray[i + 1].TrendTypeid) {
            i++;
            if (i == (this.allSelectedArray.length - 1)) {                              //Upward Trending
              switch (this.allSelectedArray[i].TrendTypeid) {
                case 1:
                  this.rowUp = false
                  this.rowDown = true
                  this.rowBidirectional = true
                  this.selectedTrend = this.trendDirectionOptions[0].Name;
                  this.selectedTrendId = 1;
                  this.tempTrend = 1;
                  for (var j = 0; j < (this.allSelectedArray.length - 1);) {
                    if (this.allSelectedArray[j].Threshold1 == this.allSelectedArray[j + 1].Threshold1 &&
                      this.allSelectedArray[j].Threshold2 == this.allSelectedArray[j + 1].Threshold2) {
                      j++;
                      if (j == (this.allSelectedArray.length - 1)) {
                        this.tvalUp1 = this.allSelectedArray[j].Threshold1;
                        this.tvalUp2 = this.allSelectedArray[j].Threshold2;


                        this.tempVal1 = this.allSelectedArray[j].Threshold1;
                        this.tempVal2 = this.allSelectedArray[j].Threshold2;
                        this.tempVal3 = this.allSelectedArray[j].Threshold3;
                        this.tempVal4 = this.allSelectedArray[j].Threshold4;
                        if (this.tempTrend == null) {
                          this.tempTrend = 0;
                        }

                      }

                    }
                    else {
                      this.tvalUp1 = '';
                      this.tvalUp2 = '';



                      this.tempVal1 = '';
                      this.tempVal2 = '';
                      if (this.tempTrend == null) {
                        this.tempTrend = 0;
                      }

                      break;
                    }

                  }
                  break;

                case 3:                                                                   //Downward Trending
                  this.rowUp = true
                  this.rowDown = false
                  this.rowBidirectional = true
                  this.selectedTrend = this.trendDirectionOptions[2].Name;
                  this.selectedTrendId = 3;
                  this.tempTrend = 3;
                  for (var j = 0; j < (this.allSelectedArray.length - 1);) {
                    if (this.allSelectedArray[j].Threshold1 == this.allSelectedArray[j + 1].Threshold1 &&
                      this.allSelectedArray[j].Threshold2 == this.allSelectedArray[j + 1].Threshold2) {
                      j++;
                      if (j == (this.allSelectedArray.length - 1)) {
                        this.tvalDwn1 = this.allSelectedArray[j].Threshold1;
                        this.tvalDwn2 = this.allSelectedArray[j].Threshold2;


                        this.tempVal1 = this.allSelectedArray[j].Threshold1;
                        this.tempVal2 = this.allSelectedArray[j].Threshold2;
                        this.tempVal3 = this.allSelectedArray[j].Threshold3;
                        this.tempVal4 = this.allSelectedArray[j].Threshold4;
                        if (this.tempTrend == null) {
                          this.tempTrend = 1;
                        }
                      }

                    }
                    else {

                      this.tvalDwn1 = '';
                      this.tvalDwn2 = '';

                      this.tempVal1 = '';
                      this.tempVal2 = '';
                      if (this.tempTrend == null) {
                        this.tempTrend = 0;
                      }
                      break;
                    }

                  }
                  break;
                case 2:                                                                   //Bi-Directional Trending
                  this.rowUp = true
                  this.rowDown = true
                  this.rowBidirectional = false
                  this.selectedTrend = this.trendDirectionOptions[1].Name;
                  this.selectedTrendId = 2;
                  this.tempTrend = 2;
                  for (var j = 0; j < (this.allSelectedArray.length - 1);) {
                    if (this.allSelectedArray[j].Threshold1 == this.allSelectedArray[j + 1].Threshold1 &&
                      this.allSelectedArray[j].Threshold2 == this.allSelectedArray[j + 1].Threshold2 &&
                      this.allSelectedArray[j].Threshold3 == this.allSelectedArray[j + 1].Threshold3 &&
                      this.allSelectedArray[j].Threshold4 == this.allSelectedArray[j + 1].Threshold4) {
                      j++;

                      if (j == (this.allSelectedArray.length - 1)) {
                        this.tvalBi1 = this.allSelectedArray[j].Threshold1;
                        this.tvalBi2 = this.allSelectedArray[j].Threshold2;
                        this.tvalBi3 = this.allSelectedArray[j].Threshold3;
                        this.tvalBi4 = this.allSelectedArray[j].Threshold4;


                        this.tempVal1 = this.allSelectedArray[j].Threshold1;
                        this.tempVal2 = this.allSelectedArray[j].Threshold2;
                        this.tempVal3 = this.allSelectedArray[j].Threshold3;
                        this.tempVal4 = this.allSelectedArray[j].Threshold4;
                        if (this.tempTrend == null) {
                          this.tempTrend = 0;
                        }

                      }

                    }
                    else {

                      this.tvalBi1 = '';
                      this.tvalBi2 = '';
                      this.tvalBi3 = '';
                      this.tvalBi4 = '';



                      this.tempVal1 = '';
                      this.tempVal2 = '';
                      this.tempVal3 = '';
                      this.tempVal4 = '';
                      if (this.tempTrend == null) {
                        this.tempTrend = 0;
                      }
                      break;
                    }

                  }
                  break;

                default:
                  this.TTid = this.allSelectedArray[0].TrendTypeid;
                  //if (this.trendDirectionOptions.length != 4) {
                  //  this.trendDirectionOptions.unshift({ TrendTypeId: 0, Name: "--Select--" }) //binding select option in trend direction pop up
                  //  this.timeSpanNote = 0;//set default value to hide note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  //}

                  this.rowUp = true
                  this.rowDown = true
                  this.rowBidirectional = true
                  //this.selectedTrend = this.trendDirectionOptions[0].Name;
                  this.selectedTrendId = 0;

                  this.tempTrend = 0;
                  this.tempVal1 = '';
                  this.tempVal2 = '';
                  this.tempVal3 = '';
                  this.tempVal4 = '';
                  break;

              }

            }
          }
          else {
            this.TTid = this.allSelectedArray[0].TrendTypeid;
            //if (this.trendDirectionOptions.length != 4) {
            //  //this.trendDirectionOptions.unshift({ TrendTypeId: 0, Name: "--Select--" }) //binding select option in trend direction pop up
            //  this.timeSpanNote = 0;//set default value to hide note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            //}

            this.rowUp = true;
            this.rowDown = true;
            this.rowBidirectional = true;
            this.selectedTrendId = 0;
            //this.selectedTrend = this.trendDirectionOptions[0].Name;
            this.tempTrend = 0;
            this.tempVal1 = '';
            this.tempVal2 = '';
            this.tempVal3 = '';
            this.tempVal4 = '';
            break;
          }

        }
      }
    }
    this.disableRestoreButton();
  }

}
