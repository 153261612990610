<!--<div id="mapCustomer" class="c-modal c-add-contact" tabindex="-1" aria-modal="true" role="dialog" aria-labelledby="modalDialogTitle">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">Map Customer</h6>
        <button type="button" data-dismiss="modal" class="c-modal__header-close -dismiss-modal" aria-label="Close map customer popup" (click)="cancel()" title="Close"></button>
      </div>
      <div class="c-modal__body">
        <div class="c-add-customer__form">
          <div class="c-add-customer__form-input">
            <div class="c-add-customer__form-area">
              <div class="o-input">
                <label for="cusName">Customer Name</label>
                <input type="text"
                       class="o-input__input"
                       placeholder="Enter minimum three characters to search"
                       aria-labelledby="CustomerName"
                       onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || event.charCode == 45 || (event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 33 && event.charCode <= 47) || (event.charCode >= 58 && event.charCode <= 64) || (event.charCode >= 91 && event.charCode <= 96) || (event.charCode >= 123 && event.charCode <= 126));"
                       [(ngModel)]="profileDetails.CustomerSearchName" />
              </div>
            </div>
            <a href="javascript:void(0)" role="button" aria-label="click to find the customer name" class="o-primary-button customerBtn" (click)="SearchCustomer()">Find</a>
          </div>
        </div>
        <p id="Customer" class="screenReaderOnly">List of Customers</p>
        <div class="c-table-responsive">
          <table class="c-table" aria-describedby="Customer">
            <thead>
              <tr>
                <th scope="col" id="Select">Select</th>
                <th scope="col"></th>
                <th scope="col" width="20%"></th>
              </tr>
            </thead>
            <tbody id="SubIndustrykeyval">
              <tr *ngIf="profileDetails.CustomerList == null || profileDetails.CustomerList == undefined">
                <td colspan="3"> No Records Found </td>
              </tr>
              <tr *ngFor="let item of profileDetails.CustomerList; let i=index; trackBy : trackById;">
                <td aria-labelledby="Select">
                  <input type="radio" aria-label="Click to select customer name." name="{{ 'customer_' + i}}"
                         [value]="item.Id" [(ngModel)]="profileDetails.SelectedId" />
                </td>
                <td>{{item.Name}}</td>
                <td>{{item.Id}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="c-modal__footer">
        <div class="c-modal__footer-btnGroup">
          <a href="javascript:void(0)" role="button" class="o-secondary-button  -dismiss-modal" aria-label="Click to cancel" data-dismiss="modal" (click)="cancel()">Cancel</a>
          <a href="javascript:void(0)" role="button" class="o-primary-button  -dismiss-modal" aria-label="Click to save" (click)="SaveCustomer()" data-dismiss="modal">Save</a>
        </div>
      </div>
    </div>
  </div>
</div>-->


<div id="input-search-modal" class="c-modal c-input-search-modal" tabindex="-1" aria-labelledby="modalDialogTitle">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">Search Customer</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close" alt="Close" (click)="cancel()"></button>
      </div>
      <div class="c-modal__body">
        <div class="c-custom-select__wrapper c-input-search  ">
          <label>Enter Customer name to get the details.</label>
          <div class="c-dropdown" [ngClass]="{'-open':SelectorOpen}" click-elsewhere (clickOutside)="SelectorOpen=false">
            <div role="button" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text">
              <i class="o-input-search__icon icon-search" aria-label="Search"></i>
              <input type="text" name="CustomerName" id="CustomerName" placeholder="Enter minimum three characters to search"
                     role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text"
                     [(ngModel)]="profileDetails.CustomerSearchName"
                     (paste)="profileDetails.CustomerSearchName = OnPaste($event)" (keypress)="KeyPressAlphanumeric($event)"
                     autocomplete="off"
                     aria-labelledby="CustomerName"
                     (input)="OnCustomerChange($event, profileDetails.CustomerSearchName)">
            </div>
            <div [ngClass]="{'c-dropdown-menu':SelectorOpen}" *ngIf="!ShowLoader">
              <div [ngClass]="{'c-dropdown-body':SelectorOpen}" *ngIf="!ShowLoader">
                <div class="c-custom-select__wrapper-body" *ngIf="!ShowLoader">
                  <div class="c-custom-control" *ngIf="SelectorOpen && (profileDetails.CustomerList!= undefined && profileDetails.CustomerList.length > 5)"></div>
                  <a *ngIf="SelectorOpen && showMessage " class="c-dropdown-item">No values found</a>
                  <!--<a role="option" *ngIf="SelectorOpen &&  !profileDetails.CustomerSearchName" href="javascript:;" [ngClass]="{'option-selected': isNoneSelected}" (click)="onSelect(null);" class="c-dropdown-item">{{selectText}}</a>-->
                  <ng-container *ngIf="SelectorOpen && (profileDetails.CustomerList!= undefined && profileDetails.CustomerList.length <= 5)">
                    <a role="option" *ngFor="let item of profileDetails.CustomerList;let i = index;let l = last; trackBy : trackByName;" href="javascript:;" class="c-dropdown-item"
                       [ngClass]="{'option-selected':item.IsSelected}" (click)="onSelect(item);" name=""><div class="c-radio">{{item.Name}}</div><span>{{item.Id}}</span></a>
                  </ng-container>
                  <ng-container *ngIf="SelectorOpen && (profileDetails.CustomerList!= undefined && profileDetails.CustomerList.length > 5)">
                    <a role="option" *ngFor="let item of profileDetails.CustomerList | filterByText:profileDetails.CustomerSearchName : filterId;let i = index;let l = last; trackBy : trackByName" href="javascript:;" class="c-dropdown-item"
                       [ngClass]="{'option-selected':item.IsSelected}" (click)="onSelect(item);" name=""><div class="c-radio">{{item.Name}}</div><span>{{item.Id}}</span></a>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <p class="c-custom-select__error-text">Enter the value</p>
        </div>
        <div class="loader-manage-customer-selector" *ngIf="ShowLoader"></div>
      </div>
      <div class="c-modal__footer">
        <div class="c-modal__footer-btnGroup">
          <!-- add class -dismiss-modal to buttons that'll close the modal -->
          <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" class="o-secondary-button  -dismiss-modal" (click)="cancel()">Cancel</a>
          <!--Primary Button ends from here-->                    <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" class="o-primary-button  -dismiss-modal" (click)="SaveCustomer()">Ok</a>
          <!--Primary Button ends from here-->
        </div>
      </div>
    </div>
  </div>
</div>
