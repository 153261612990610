import { Component, EventEmitter } from '@angular/core';
import * as Enumerable from 'linq';
import { SessionStorage } from '../../../core/context/sessionstorage';
import { BsModalRef } from 'ngx-bootstrap/modal';
import cloneDeep from 'lodash/cloneDeep';
import { Location } from '@angular/common';
import { enumAlternativeContacts } from '../../../core/context/enum';
import { SharedService } from '../../../core/services/shared.service';
import { environment } from '../../../../environments/environment';
var $ = (<any>window).$;
declare var window: any;

@Component({
  selector: 'alternat-contact',
  templateUrl: './alternate.contact.component.html',
  providers: []
})
export class AlternateContactComponent {

  constructor(private bsModalRef: BsModalRef, private sharedService: SharedService, private sessionStorage: SessionStorage, private location: Location) { }

  public altnernateContactDetails: EventEmitter<any> = new EventEmitter();  // .. Child Popup -> Parent
  public alternateSaveDetails: EventEmitter<any> = new EventEmitter();  // .. Child Popup -> Parent

  KeyContactsDetails: any;        // .. Parent -> Child Popup
  TempListAlternateContact: any; // .. Parent -> Child Popup
  NewListAlternateContact: any =[];
  AlternateContactEnterpriseId: string;
  TempRoleOptions: any;
  tempRoleId: any;
  item: any;
  path = this.location.path();
  RoleAccess = this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1 ? this.sessionStorage.roleAccessCode : this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);
  IsOtherRoleEmpty: boolean = false;
  action: string = "Add";
  ngOnInit() {
    $('body').addClass('stop-scrolling');
      setTimeout(() => {
        window.dropdownComponent();
        window.tabsComponent();
        window.TooltipFunc();
      }, 0);
    $('.c-modal__header button').focus();
    setTimeout(function () {
      $('.c-modal__header-close').focus();
    }, 0)
    if (this.item != null) {
      this.action = "Edit";
      this.AlternateContactEnterpriseId = this.item.EnterpriseId;
      this.KeyContactsDetails.SelectedContactRoleId = this.item.AlternateContactRoleTypeId;
      this.tempRoleId = this.item.AlternateContactRoleTypeId;
      this.KeyContactsDetails.OtherRole = this.item.OtherRole;
     /* this.TempListAlternateContact = this.TempListAlternateContact.filter(x => x.EnterpriseId != this.item.EnterpriseId && x.AlternateContactRoleName != this.item.AlternateContactRoleName && x.AlternateContactRoleTypeId != this.item.AlternateContactRoleTypeId);*/
    }
  }

  onDropdownChange() {
    
    this.KeyContactsDetails.OtherRole = "";
  }

  ngOnDestroy() {
    $('body').removeClass('stop-scrolling');
  }

  AddContacts(enterpriseId) {
      var duplicate = false;
    enterpriseId = this.AlternateContactEnterpriseId;
    if (this.KeyContactsDetails.SelectedContactRoleId == null && ($('.hiddenAlternateContactEnterpriseId').val() == "" || enterpriseId != $('.hiddenAlternateContactEnterpriseId').val())) {
      this.sharedService.openAlertPopup("Please enter the valid Enterprise ID and Role", window.scrollY, "Ok", false);
      this.DisableScroll();
      return false;
    }
    else if ($('.hiddenAlternateContactEnterpriseId').val() == "" || enterpriseId != $('.hiddenAlternateContactEnterpriseId').val() || enterpriseId == "") {
      this.sharedService.openAlertPopup("Please enter the valid Enterprise ID", window.scrollY, "Ok", false);
      this.DisableScroll();
      return false;
    }
    else if (this.KeyContactsDetails.SelectedContactRoleId == null) {
      this.sharedService.openAlertPopup("Please enter the valid Role", window.scrollY, "Ok", false);
      this.DisableScroll();
      return false;
    }
    else if (this.KeyContactsDetails.SelectedContactRoleId == 4) {
      if (this.KeyContactsDetails.OtherRole == null || this.KeyContactsDetails.OtherRole.trim() == "") {
        this.sharedService.openAlertPopup("Please enter the description for Other role", window.scrollY, "Ok", false);
        this.DisableScroll();
        return false;
      }
    }

      if (enterpriseId != "" && this.KeyContactsDetails.SelectedContactRoleId != null) {
        var contactRole = this.KeyContactsDetails.AlternateContactsList.filter(x => x.Id == this.KeyContactsDetails.SelectedContactRoleId)[0];
        var contactRoleName = contactRole != null ? contactRole.Name : "";
        var object = {
          EnterpriseId: enterpriseId,
          AlternateContactRoleName: contactRoleName,
          AlternateContactRoleTypeId: this.KeyContactsDetails.SelectedContactRoleId,
          OtherRole: this.KeyContactsDetails.OtherRole
        }

        this.TempListAlternateContact.forEach((value: any) => {

          {
            if (object.EnterpriseId.replace(/ /g, "") == value['EnterpriseId'].replace(/ /g, "") && object.AlternateContactRoleTypeId == value['AlternateContactRoleTypeId']) {
              duplicate = true;

            }
          }
        });
        if (duplicate == false) {
          if (this.action == "Add") {

            this.TempListAlternateContact.push(object);
            this.NewListAlternateContact.push(object);
            $('#peoplePicker_alternateContactEnterpriseId').val('');
            if (this.KeyContactsDetails.SelectedContactRoleId == enumAlternativeContacts.PMOLead) {
              for (var i = 0; i < this.KeyContactsDetails.AlternateContactsList.length; i++) {
                if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.PMOLead) {
                  this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
                  break;
                }
              }
            }
            if (this.KeyContactsDetails.SelectedContactRoleId == enumAlternativeContacts.TransformationDirector) {
              for (var i = 0; i < this.KeyContactsDetails.AlternateContactsList.length; i++) {
                if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.TransformationDirector) {
                  this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
                  break;
                }
              }
            }
            this.KeyContactsDetails.SelectedContactRoleId = null;
            this.KeyContactsDetails.AlternateContactsList.forEach((x: any) => x.IsSelected = false);
            $('#peoplePicker_alternateContactEnterpriseId').val('');
            this.sharedService.openAlertPopup(contactRoleName + ' ' + 'role has been provided for' + ' ' + enterpriseId + '.', window.scrollY, "Ok", false);
            this.AlternateContactEnterpriseId = null;
            this.KeyContactsDetails.OtherRole = null;
            this.KeyContactsDetails.AlternateContactsList.forEach(x => { if (x.IsSelected) { x.IsSelected = false } });
            this.DisableScroll();
          }
          else {
            for (var k = 0; k < this.TempListAlternateContact.length; k++) {
              var existingNew = this.TempListAlternateContact[k].EnterpriseId.replace(/ /g, "");
              if (this.TempListAlternateContact[k].AlternateContactRoleTypeId == this.tempRoleId && existingNew == enterpriseId) {
                this.TempListAlternateContact[k].AlternateContactRoleTypeId = this.KeyContactsDetails.SelectedContactRoleId;
                this.TempListAlternateContact[k].EnterpriseId = this.AlternateContactEnterpriseId;
                this.TempListAlternateContact[k].OtherRole = this.KeyContactsDetails.OtherRole;
                var contactRole = this.KeyContactsDetails.AlternateContactsList.filter(x => x.Id == this.KeyContactsDetails.SelectedContactRoleId)[0];
                var contactRoleName = contactRole != null ? contactRole.Name : "";
                this.TempListAlternateContact[k].AlternateContactRoleName = contactRoleName;
                this.sharedService.openAlertPopup(contactRoleName + ' ' + 'role has been provided for' + ' ' + enterpriseId + '.',window.scrollY, "Ok", false);
                this.DisableScroll();
                break;
              }
            }

          }
        }
        else {
          this.sharedService.openAlertPopup("The contact with same role already exists.", window.scrollY, "Ok", false);
          this.DisableScroll();
        }
      }
   
  }

  DisableScroll() {
    $("body").css("overflow", "hidden");
  }

  EnableScroll() {
    $("body").css("overflow", "");
  }

  SaveAlternativeContact = function () {
    this.KeyContactsDetails.EndToEndAlternateContacts = cloneDeep(this.TempListAlternateContact);
    $('.alternateContactEnterpriseId').val('');
    this.KeyContactsDetails.SelectedContactRoleId = null;
    var pmoLead = Enumerable.from(this.KeyContactsDetails.EndToEndAlternateContacts).where((x: any) => x.AlternateContactRoleTypeId == enumAlternativeContacts.PMOLead).select((s: any) => new Object({ Id: s.AlternateContactRoleTypeId, Name: s.AlternateContactRoleName })).firstOrDefault();
    if (this.KeyContactsDetails.IsSIEnabledContract && pmoLead != undefined)
      this.KeyContactsDetails.IsPMOLeadRoleRequired = false;
    else if (this.KeyContactsDetails.IsSIEnabledContract && pmoLead == undefined)
      this.KeyContactsDetails.IsPMOLeadRoleRequired = true;
    this.alternateSaveDetails.emit({ saveData: true });
    this.EnableScroll();
    this.bsModalRef.hide();
  }
  CancelContacts() {
    this.TempListAlternateContact = cloneDeep(this.KeyContactsDetails.EndToEndAlternateContacts);
    $('.alternateContactEnterpriseId').val('');
    this.altnernateContactDetails.emit({ TempListAlternateContact: this.TempListAlternateContact });
    this.KeyContactsDetails.AlternateContactsList = cloneDeep(this.TempRoleOptions);
    for (var i = 0; i < this.TempListAlternateContact.length; i++) {
      if (this.KeyContactsDetails.EndToEndAlternateContacts[i].AlternateContactRoleTypeId == enumAlternativeContacts.TransformationDirector) {
        for (var j = 0; j < this.KeyContactsDetails.AlternateContactsList.length; j++) {
          if (this.KeyContactsDetails.AlternateContactsList[j].Id == enumAlternativeContacts.TransformationDirector) {
            this.KeyContactsDetails.AlternateContactsList.splice(j, 1);
            break;
          }
        }
      }
    }
    for (var i = 0; i < this.TempListAlternateContact.length; i++) {
      if (this.KeyContactsDetails.EndToEndAlternateContacts[i].AlternateContactRoleTypeId == enumAlternativeContacts.PMOLead) {
        for (var j = 0; j < this.KeyContactsDetails.AlternateContactsList.length; j++) {
          if (this.KeyContactsDetails.AlternateContactsList[j].Id == enumAlternativeContacts.PMOLead) {
            this.KeyContactsDetails.AlternateContactsList.splice(j, 1);
            break;
          }
        }
      }
    }
    this.EnableScroll();
    this.bsModalRef.hide();
  }

  RemoveContact(roleId, enterpriseid, roleName) {
    var deleted = enterpriseid.replace(/ /g, "");
    var endToEndType = this.sessionStorage.endToEndType;
    if (this.TempListAlternateContact != null && this.TempListAlternateContact != 'undefined') {
      for (var i = 0; i < this.TempListAlternateContact.length; i++) {
        var existing = this.TempListAlternateContact[i].EnterpriseId.replace(/ /g, "");
        if (this.TempListAlternateContact[i].AlternateContactRoleTypeId == roleId && existing == deleted) {
          this.TempListAlternateContact.splice(i, 1);
          for (var k = 0; k < this.NewListAlternateContact.length; k++) {
            var existingNew = this.NewListAlternateContact[k].EnterpriseId.replace(/ /g, "");
            if (this.NewListAlternateContact[k].AlternateContactRoleTypeId == roleId && existingNew == deleted) {
              this.NewListAlternateContact.splice(k, 1);
              break;
            }
          }
          this.NewListAlternateContact.
          break;
        }
      }
      if (roleId == enumAlternativeContacts.PMOLead || enumAlternativeContacts.TransformationDirector) {
        this.KeyContactsDetails.AlternateContactsList = cloneDeep(this.TempRoleOptions);
        for (var i = 0; i < this.TempListAlternateContact.length; i++) {
          if (this.TempListAlternateContact[i].AlternateContactRoleTypeId == enumAlternativeContacts.TransformationDirector) {
            for (var j = 0; j < this.KeyContactsDetails.AlternateContactsList.length; j++) {
              if (this.KeyContactsDetails.AlternateContactsList[j].Id == enumAlternativeContacts.TransformationDirector) {
                this.KeyContactsDetails.AlternateContactsList.splice(j, 1);
              }
            }
          }
        }
        for (var i = 0; i < this.TempListAlternateContact.length; i++) {
          if (this.TempListAlternateContact[i].AlternateContactRoleTypeId == enumAlternativeContacts.PMOLead) {
            for (var j = 0; j < this.KeyContactsDetails.AlternateContactsList.length; j++) {
              if (this.KeyContactsDetails.AlternateContactsList[j].Id == enumAlternativeContacts.PMOLead) {
                this.KeyContactsDetails.AlternateContactsList.splice(j, 1);
              }
            }
          }
        }
      }
      if (endToEndType == "I") {
        for (var i = 0; i < this.KeyContactsDetails.AlternateContactsList.length; i++) {
          if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.AutomationArchitect)
            this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
          if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.OPERALead)
            this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
        }
      }
      if (endToEndType == "I" || endToEndType == "O") {
        for (var i = 0; i < this.KeyContactsDetails.AlternateContactsList.length; i++) {
          if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.TransformationDirector)
            this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
          if (this.KeyContactsDetails.AlternateContactsList[i] != undefined && this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.ClientExperienceLead)
            this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
            //if (this.KeyContactsDetails.AlternateContactsList[i] != undefined && this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.ContractManager)
          //  this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
        }
      }
      this.sharedService.openAlertPopup('Delete enterpriseid ' + enterpriseid + ' and role ' + roleName + ' Success', window.scrollY, "Ok", false);
    }
  }

  RoleCheck(roleName) {
    if ((roleName == "Global Client Account Lead") || (roleName == "GDN Account Group Lead") || (roleName == "GDN Delivery Group Lead") || (roleName == "Delivery QA Director") || (roleName == "Global Technology Service Lead") || (roleName == "Contract Manager")) {
      return true;
    }
    else {
      return false;
    }
  }
  trackByIndex(index, item): number {
    return index;
  }
  RestrictNumeric(event) {
    var iKeyCode = event.keyCode;
    if ((iKeyCode >= 65 && iKeyCode <= 90) || (iKeyCode >= 97 && iKeyCode <= 122) || (iKeyCode == 40 || iKeyCode == 41 || iKeyCode == 32)) {
      return true;
    }
    event.stopPropagation();
    event.preventDefault();
  }

}
