<div role="tabpanel" id="releasenote">
  <div class="c-critical-release">
    <div class="c-custom-select__wrapper">
      <label>Release</label>
      <search-dropdown id="releasenotehistory"
                       name="releasenotehistory"
                       #releasenotehistory="ngModel"
                       aria-labelledby="ReleaseNoteHistory"
                       [options]="Releases"
                       [selected]="SelectedReleaseId"
                       [isShow]="true"
                       (callback)="GetReleaseDetails(SelectedRelease)"
                       [(ngModel)]="SelectedRelease">
      </search-dropdown>

    </div>
    <div class="c-critical-checkbox">
      <div class="c-checkbox  ">
        <input type="checkbox" id="critical-changes" class="c-checkbox__input" [(ngModel)]="isCritical"  name="unitcheckbox"/>
        <i class="c-checkbox__checked"></i>
        <label for="critical-changes" class="c-checkbox__label">Critical Changes</label>
      </div>
    </div>
  </div>
  <div class="c-table-responsive c-lead__table c-notification-table">
    <table class="c-table">
      <thead>
        <tr>
          <th scope="col">Application</th>
          <th scope="col">Description</th>
          <th scope="col">User Impact</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="SelectedRelease&&SelectedRelease.length > 0">
          <ng-container class="tr-releasenote" *ngFor="let row of SelectedRelease;let i = index; trackBy: trackById;">
            <tr *ngIf="((isCritical && row.IsChangeCritical == 'Y') || !isCritical)">
              <td style="width:551px!important;" [innerHTML]="row.ApplicationName"></td>
              <td style="width:547px!important;" [innerHTML]="row.FeatureName"></td>
              <td style="width:756px!important;" [innerHTML]="row.Impact"></td>
            </tr>
          </ng-container>
        </ng-container>
        <tr *ngIf="criticalChangesCount == 0 && isCritical" >
          <td  class="table-no-data" colspan="3">
            No Records Found
          </td>
        </tr>

      </tbody>
    </table>
  </div>
</div>
