import { Component, ViewChild, HostListener } from '@angular/core';
import { AvanadeEACService } from '../../core/services/avanadeEAC.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStorage } from '../../core/context/sessionstorage';
import { NgForm } from '@angular/forms';
var $ = (<any>window).$;
import { Observable } from 'rxjs';
import { HeaderComponent } from '../header.component/header.component';
import { BroadCastService } from '../../core/services/broadcast.service';
import { SharedService } from '../../core/services/shared.service';

@Component({
  templateUrl: './avanadeEAC.component.html',
  providers: [AvanadeEACService, HeaderComponent]

})
export class AvanadeEACComponent {
  TabChanges = false;
  isDisabled: Boolean = false;
  tabChanges: Boolean = false;
  redirectId = this.sessionStorage.endToEndUId;
  endToEndId = this.sessionStorage.endToEndId;
  parentTileId = this.sessionStorage.parentTileId;
  roleAccess = this.sessionStorage.roleAccessCode;
  roleTypeCode = this.sessionStorage.roleTypeCode;
  confirmationMessage: any;
  EacLikeLiHoodPopUp: boolean = false;
  EACSectionPopUp: boolean = false;
  AvanadeEAC: any = {};
  quarter: any;
  monthList: any;
  months: any;
  isUserAlert: boolean = false;
  EACLikelihood: any;
  isRevenueRecognition: boolean = false;

  @ViewChild('avandeEACform') avandeEACform: NgForm;
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    if (this.avandeEACform.form.dirty) {
      return confirm('Changes you made may not be saved.');
    }
    else {
      return true;
    }
  }

  constructor(private avanadeEacService: AvanadeEACService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionStorage: SessionStorage,
    private headerComponent: HeaderComponent,
    private broadCastService: BroadCastService,
    private sharedService: SharedService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['MyWizard/Back/AvanadeEAC'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId } });
      return false;
    }

    this.EACLikelihood = [{ Id: 1, Name: 'Select' }, { Id: 2, Name: 'High' }, { Id: 3, Name: 'Medium' }, { Id: 4, Name: 'Low' }, { Id: 5, Name: '"No Unapproved EACs Pending"' }];
    // Setting default values for dropdowns
    this.AvanadeEAC.EacLikelihoodId = (this.AvanadeEAC.EacLikelihoodId == undefined || this.AvanadeEAC.EacLikelihoodId == null) ? "0" : this.AvanadeEAC.EacLikelihoodId;
    //this.AvanadeEAC.Name = (this.AvanadeEAC.Name == undefined || this.AvanadeEAC.Name == null) ? "" : this.AvanadeEAC.Name;
    this.AvanadeEAC.EacRevenueRecognitionId = (this.AvanadeEAC.EacRevenueRecognitionId == undefined || this.AvanadeEAC.EacRevenueRecognitionId == null) ? null : this.AvanadeEAC.EacRevenueRecognitionId;
    this.AvanadeEAC.EacForecastedChange = (this.AvanadeEAC.EacForecastedChange == undefined || this.AvanadeEAC.EacForecastedChange == null) ? "0" : this.AvanadeEAC.EacForecastedChange;
    this.AvanadeEAC.EacMonthChange = (this.AvanadeEAC.EacMonthChange == undefined || this.AvanadeEAC.EacMonthChange == null) ? "0" : this.AvanadeEAC.EacMonthChange;
    this.AvanadeEAC.EacExecutionId = (this.AvanadeEAC.EacExecutionId == undefined || this.AvanadeEAC.EacExecutionId == null) ? "0" : this.AvanadeEAC.EacExecutionId;
    this.isRevenueRecognition = (this.roleAccess == 'V' || this.AvanadeEAC.EacLikelihoodId == 0);

    this.EacForecastChange();
    this.LoadAvanadeEACForecast();

    this.broadCastService.broadcastScopeSelector({
      isScopeSelectorVisible: true,
      isScopeSelectorDropDownVisible: false,
      isClientDropDownDisabled: false,
      isSetAsDefaultVisible: false,
      isDisableTeamScopeSelector: true
    });
  }
  LoadAvanadeEACForecast() {
    this.roleAccess = this.sessionStorage.roleAccessCode;
    this.avanadeEacService.GetAvanadeEAC().subscribe((response: any) => {
      this.AvanadeEAC = response;

      // Setting default values for dropdowns
      this.AvanadeEAC.EacLikelihoodId = (this.AvanadeEAC.EacLikelihoodId == undefined || this.AvanadeEAC.EacLikelihoodId == null) ? "0" : this.AvanadeEAC.EacLikelihoodId;
      this.AvanadeEAC.Names = response.EACLikelihood;
      this.AvanadeEAC.EacRevenueRecognitionId = (this.AvanadeEAC.EacRevenueRecognitionId == undefined || this.AvanadeEAC.EacRevenueRecognitionId == null) ? null : this.AvanadeEAC.EacRevenueRecognitionId;
      this.AvanadeEAC.EacForecastedChange = (this.AvanadeEAC.EacForecastedChange == undefined || this.AvanadeEAC.EacForecastedChange == null) ? "0" : this.AvanadeEAC.EacForecastedChange;
      this.AvanadeEAC.EacMonthChange = (this.AvanadeEAC.EacMonthChange == undefined || this.AvanadeEAC.EacMonthChange == null) ? "0" : this.AvanadeEAC.EacMonthChange;
      this.AvanadeEAC.EacExecutionId = (this.AvanadeEAC.EacExecutionId == undefined || this.AvanadeEAC.EacExecutionId == null) ? "0" : this.AvanadeEAC.EacExecutionId;

      if (this.AvanadeEAC.EacForecastedChange) {
        if (this.AvanadeEAC.EacForecastedChange.slice(-2) == "Q1") {
          this.months = [{ Name: "Sep" }, { Name: "Oct" }, { Name: "Nov" }];
        }
        if (this.AvanadeEAC.EacForecastedChange.slice(-2) == "Q2") {
          this.months = [{ Name: "Dec" }, { Name: "Jan" }, { Name: "Feb" }];
        }
        if (this.AvanadeEAC.EacForecastedChange.slice(-2) == "Q3") {
          this.months = [{ Name: "Mar" }, { Name: "Apr" }, { Name: "May" }];
        }
        if (this.AvanadeEAC.EacForecastedChange.slice(-2) == "Q4") {
          this.months = [{ Name: "Jun" }, { Name: "Jul" }, { Name: "Aug" }];
        }
        if (this.AvanadeEAC.EacForecastedChange == "TBC") {
          this.months = [{ Name: "Jan" }, { Name: "Feb" }, { Name: "Mar" }, { Name: "Apr" }, { Name: "May" }, { Name: "Jun" }, { Name: "Jul" },
          { Name: "Aug" }, { Name: "Sep" }, { Name: "Oct" }, { Name: "Nov" }, { Name: "Dec" }, { Name: "TBC" }];
        }
      }

      this.OnChangeQuarter(this.AvanadeEAC.EacForecastedChange, true);
      if (this.AvanadeEAC.EacLikelihoodId == 4 || this.AvanadeEAC.EacLikelihoodId == 0) {
        this.isDisabled = true;
      }
      else { this.isDisabled = false; }
    });
  }

  cancelEAC(event: any) {
    //closing popup = btnClose_EACLikelyhood
    //closing popup & retain values = btnClose_EACSection, btnCancel_EACSection
    //reloading page = btnCancel_Main, btnCancel_EACLikelyHood

    var cancelButtonId = event.currentTarget.id;
    if (cancelButtonId == "btnClose_EACSection" || cancelButtonId == "btnCancel_EACSection") {
      this.EacLikeLiHoodPopUp = false;
      this.EACSectionPopUp = false;
      if (cancelButtonId == "btnCancel_EACSection") {
        this.avandeEACform.form.markAsPristine();
        this.avandeEACform.form.markAsUntouched();
      }
    }
    else if (cancelButtonId == "btnCancel_Main" || cancelButtonId == "btnClose_EACLikelyHood" || cancelButtonId == "btnCancel_EACLikelyHood") {
      this.avandeEACform.resetForm();
      this.AvanadeEAC = {};
      this.EacLikeLiHoodPopUp = false;
      this.EACSectionPopUp = false;
      this.EacForecastChange();
      this.LoadAvanadeEACForecast();
    }
  }

  OnBackClick() {
    this.router.navigate(['/mywizard/childtiles', this.parentTileId, 'E2E', this.redirectId], { relativeTo: this.activatedRoute });
  }

  ResetDefaultScope(avandeEACform: NgForm) {
    if (avandeEACform != undefined) avandeEACform.form.markAsPristine();
    this.tabChanges = false;
  }

  ChangeEACLikelihood(eacLikelihoodId) {
    if (eacLikelihoodId == 4 || eacLikelihoodId == 0 || eacLikelihoodId == null) {
      this.confirmationMessage = 'This selection will clear all pre-existing EAC amounts.Select OK to continue.';
      this.EacLikeLiHoodPopUp = true;
      if (eacLikelihoodId == 0 || eacLikelihoodId == null) {

        this.isRevenueRecognition = true;
        this.isDisabled = true;
      }
      else
        this.isDisabled = false;
      setTimeout(function closeChangeEACLikelihoodPopup() {
        $('.close').focus();
      }, 0);
    }
    else {
      this.isDisabled = false;
    }
  }


  Confirm() {
    if (this.AvanadeEAC.EacLikelihoodId == 4) {
      this.AvanadeEAC.LikelyOutcome = "";
      this.AvanadeEAC.WorstCase = "";
      this.AvanadeEAC.EstimatedFullContract = "";
      this.AvanadeEAC.EacExecutionId = "0";
      this.AvanadeEAC.EacForecastedChange = "0";
      this.AvanadeEAC.EacMonthChange = "0";
      this.isDisabled = true;
      this.EacLikeLiHoodPopUp = false;
    }
    else {
      this.AvanadeEAC.LikelyOutcome = "";
      this.AvanadeEAC.WorstCase = "";
      this.AvanadeEAC.EstimatedFullContract = "";
      this.AvanadeEAC.EacExecutionId = "0";
      this.AvanadeEAC.EacForecastedChange = "0";
      this.AvanadeEAC.EacMonthChange = "0";
      this.AvanadeEAC.EacRevenueRecognitionId = null;
      this.isDisabled = true;
      this.EacLikeLiHoodPopUp = false;
    }
  }


  OnChangeQuarter(EacForecastedChange, IsPageReload: boolean = false) {
    EacForecastedChange = EacForecastedChange == null ? "0" : EacForecastedChange;
    if (EacForecastedChange != "0") {
      if (this.AvanadeEAC && EacForecastedChange != "TBC")
        this.months = this.monthList.filter(x => x.quarterIndex == EacForecastedChange.slice(-2))
      else
        this.months = this.monthList.filter(x => x.quarterIndex == EacForecastedChange)
    }
    else {
      this.months = [];
    }
    if (!IsPageReload) {
      this.AvanadeEAC.EacMonthChange = "0";
    }

  }

  async SaveAvanade(formvalid: any, avandeEACform: NgForm) {
    var _this = this;
    this.AvanadeEAC.EacMonthChange = (this.AvanadeEAC.EacMonthChange == undefined || this.AvanadeEAC.EacMonthChange == null) ? "0" : this.AvanadeEAC.EacMonthChange;
    this.AvanadeEAC.EacExecutionId = (this.AvanadeEAC.EacExecutionId == undefined || this.AvanadeEAC.EacExecutionId == null) ? "0" : this.AvanadeEAC.EacExecutionId;
    if (this.AvanadeEAC.EacLikelihoodId == 4) {
      formvalid = true;
    }
    if (formvalid && (this.AvanadeEAC.EacLikelihoodId != 0) && $("[name='avandeEACform']").find('.has-error').length < 1) {
        if (
          (this.AvanadeEAC.EacForecastedChange == 0 && this.isDisabled == false)
          ||
          (this.AvanadeEAC.EacMonthChange == 0 && this.isDisabled == false)
          ||
          (avandeEACform.form.value.SelectMonth == 0 && this.isDisabled == false)
          ||
          (!parseInt(this.AvanadeEAC.LikelyOutcome) && this.isDisabled == false)
          ||
          (!parseInt(this.AvanadeEAC.WorstCase) && this.isDisabled == false)
          ||
          (!parseInt(this.AvanadeEAC.EstimatedFullContract) && this.isDisabled == false)
          ||
          (this.AvanadeEAC.EacExecutionId == 0 && this.isDisabled == false)
          ||
          (avandeEACform.form.value.Execution == 0 && this.isDisabled == false)
        ) {
          this.confirmationMessage = 'The EAC section has been left blank. It is recommended that the timing and amount of the EAC be entered. Do you want to continue?';
          this.EACSectionPopUp = true;
          setTimeout(function closePopup() {
            $('.close').focus();
          }, 0);
        }
        else {
          _this.EACSectionPopUp = false;
          this.AvanadeEAC.EnterpriseId = this.sessionStorage.enterpriseId;
          this.AvanadeEAC.endToEndId = this.sessionStorage.endToEndId;
          this.avanadeEacService.SaveAvanadeEAC(this.AvanadeEAC).subscribe((response: any) => {
            this.sharedService.openAlertPopup('Saved Successfully', window.scrollY);
            this.AvanadeEAC = {};
            _this.EacForecastChange();
            if (avandeEACform != undefined) avandeEACform.form.markAsPristine();
            _this.LoadAvanadeEACForecast();

          });
        }
      }
      else {
      this.sharedService.openAlertPopup('Please fill all required Fields.', window.scrollY);
        await this.focusErrorField();
        this.EACSectionPopUp = false;
        avandeEACform.form.markAsDirty();
      }
    this.TabChanges = false;
  }

  async ConfirmSave(formvalid: any, avandeEACform: NgForm) {
    var _this = this;
    this.EACSectionPopUp = false;
    if (this.AvanadeEAC.EacLikelihoodId != 0 && $("[name='avandeEACform']").find('.has-error').length < 1) {
      this.AvanadeEAC.EnterpriseId = this.sessionStorage.enterpriseId;
      this.AvanadeEAC.endToEndId = this.sessionStorage.endToEndId;
      this.avanadeEacService.SaveAvanadeEAC(this.AvanadeEAC).subscribe((response: any) => {
        this.sharedService.openAlertPopup('Saved Successfully', window.scrollY);
        if (avandeEACform != undefined) avandeEACform.form.markAsPristine();
      });
    }
    else {
      this.sharedService.openAlertPopup('Please fill all required Fields.', window.scrollY);
      await this.focusErrorField();
      this.EACSectionPopUp = false;
    }
  }

  EacForecastChange() {
    var quarterMonths = [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8];
    var quarterMap = ["Q1", "Q2", "Q3", "Q4"];
    var date = new Date();
    var currentMonthIndex = quarterMonths.indexOf(date.getMonth() + 1);
    if (currentMonthIndex == 0 || currentMonthIndex == 1 || currentMonthIndex == 2 || currentMonthIndex == 3) {
      var currentYear = new Date().getFullYear() + 1;
    }
    else {
      var currentYear = new Date().getFullYear();
    }
    var nextYear = currentYear + 1;
    var fyPrefix = 'FY' + String(currentYear).slice(-2) + '-';
    var fyNextPrefix = 'FY' + String(nextYear).slice(-2) + '-';
    var i = 0;
    var fyQuarterArray = [];
    this.quarter = null;
    currentMonthIndex = parseInt(currentMonthIndex.toLocaleString());
    var currentQuarterPosition: number = currentMonthIndex / 3;
    currentQuarterPosition = parseInt(currentQuarterPosition.toLocaleString());
    var fyToBeUsed = fyPrefix;
    while (i < 5) {
      var fy = fyToBeUsed + quarterMap[currentQuarterPosition];
      var monthIQuarterIndex = 0;
      fyQuarterArray.push({ Id: fy, Name: fy });
      currentQuarterPosition++;
      if (currentQuarterPosition == (quarterMap.length)) {
        fyToBeUsed = fyNextPrefix;
        currentQuarterPosition = 0;
      }
      i++;
      var fiscal = "TBC";
    }
    this.quarter = fyQuarterArray;
    this.quarter.push({ Id: fiscal, Name: fiscal });
    this.monthList = [
      {
        Id: 9,
        Name: "Sep",
        quarterIndex: "Q1",
        MonthId: 9
      },
      {
        Id: 10,
        Name: "Oct",
        quarterIndex: "Q1",
        MonthId: 10
      },
      {
        Id: 11,
        Name: "Nov",
        quarterIndex: "Q1",
        MonthId: 11
      },
      {
        Id: 12,
        Name: "Dec",
        quarterIndex: "Q2",
        MonthId: 12
      },
      {
        Id: 1,
        Name: "Jan",
        quarterIndex: "Q2",
        MonthId: 1
      },
      {
        Id: 2,
        Name: "Feb",
        quarterIndex: "Q2",
        MonthId: 2

      },

      {
        Id: 3,
        Name: "Mar",
        quarterIndex: "Q3",
        MonthId: 3
      },
      {
        Id: 4,
        Name: "Apr",
        quarterIndex: "Q3",
        MonthId: 4
      },
      {
        Id: 5,
        Name: "May",
        quarterIndex: "Q3",
        MonthId: 5

      },
      {
        Id: 6,
        Name: "Jun",
        quarterIndex: "Q4",
        MonthId: 6
      },
      {
        Id: 7,
        Name: "Jul",
        quarterIndex: "Q4",
        MonthId: 7
      },
      {
        Id: 8,
        Name: "Aug",
        quarterIndex: "Q4",
        MonthId: 8
      },
      {
        Id: 1,
        Name: "Jan",
        quarterIndex: "TBC",
      },
      {
        Id: 2,
        Name: "Feb",
        quarterIndex: "TBC",
      },
      {
        Id: 3,
        Name: "Mar",
        quarterIndex: "TBC",
      },
      {
        Id: 4,
        Name: "Apr",
        quarterIndex: "TBC",
      },
      {
        Id: 5,
        Name: "May",
        quarterIndex: "TBC",
      },
      {
        Id: 6,
        Name: "Jun",
        quarterIndex: "TBC",
      },
      {
        Id: 7,
        Name: "Jul",
        quarterIndex: "TBC",
      },
      {
        Id: 8,
        Name: "Aug",
        quarterIndex: "TBC",
      },
      {
        Id: 9,
        Name: "Sep",
        quarterIndex: "TBC",
      },
      {
        Id: 10,
        Name: "Oct",
        quarterIndex: "TBC",

      },
      {
        Id: 11,
        Name: "Nov",
        quarterIndex: "TBC",
      },
      {
        Id: 12,
        Name: "Dec",
        quarterIndex: "TBC",
      },
      {
        Id: 13,
        Name: "TBC",
        quarterIndex: "TBC",
      },

    ];
  }
  KeyPressNumeric(event, data) {
    var iKeyCode = event.keyCode;
    if (iKeyCode == 45 && data.includes("-")) {
      return false;
    }
    if (iKeyCode >= 48 && iKeyCode <= 57 || iKeyCode == 45) {
      return true;
    }
    event.stopPropagation();
    event.preventDefault();
  }

  PasteNumeric(event: ClipboardEvent): string {
    event.preventDefault();
    let clipboardData = event.clipboardData.getData('text');
    var pastedText = clipboardData;
    var value = "";
    for (var i = 0; i < pastedText.length; i++) {
      var iKeyCode = pastedText.charCodeAt(i);
      if (iKeyCode == 45 && value.includes("-")) {
        continue;
      }
      if (iKeyCode >= 48 && iKeyCode <= 57 || iKeyCode == 45) {
        value += pastedText.charAt(i);
      }
    }
    event.stopPropagation();
    event.preventDefault();
    return (value.length > 10) ? value.substring(0, 10) : value;
  }

  focusErrorField() {
    return new Promise(done => {
      setTimeout(() => {
        $("[name='avandeEACform']").find('.has-error')[0].focus();
      }, 0);
    });
  }

  trackByName(selectedRow: any): string {
    return selectedRow.Name;
  }

  trackByQuarter(selectedRow: any): string {
    return selectedRow.quarter;
  }
}


