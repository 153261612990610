import { Injectable } from '@angular/core'
import { SessionStorage } from '../context/sessionstorage';
import { HttpService } from './http.service';

@Injectable()
export class UserService {

  constructor(private sessionStorage: SessionStorage, private httpService: HttpService) { }
  getEnterpriseId() {
    return 'p.d.radhakrishnan';
  }
  getUserAccess(enterpriseId) {
    return this.httpService.get('/api/access/user/' + enterpriseId + '/');
  };
  getScopeDetails() {
    return this.httpService.get('/api/securitydetails/user/' + this.sessionStorage.enterpriseId + '/?endToEndUId=' +
      this.sessionStorage.endToEndUId + '&clientWMSUId=' + this.sessionStorage.clientWMSUId + '&workstreamUId=' + this.sessionStorage.workstreamUId +
      '&isInternal=' + true +
      '&clientUId=' + this.sessionStorage.clientUId, true

    );
  };
  getClientHierarchyByUID(enterpriseId, endToEndUId, clientWMSUId, workstreamUId, clientUId) {
    return this.httpService.get('/api/securitydetails/user/' + enterpriseId + '/?endToEndUId=' +
      endToEndUId + '&clientWMSUId=' + clientWMSUId + '&workstreamUId=' + workstreamUId +
      '&isInternal=' + true +
      '&clientUId=' + clientUId, true
    );
  };
  getEndToEndByClient(clientId) {
    return this.httpService.get('/api/endtoend/user/' + this.sessionStorage.enterpriseId + '/client/' + clientId);
  };
  GetPreferenceValue(enterpriseId, preferenceId) {
    return this.httpService.get('/api/Users/' + enterpriseId + '/Preferences/' + preferenceId);
  };
  getTMSDetails(endtoendid) {
    return this.httpService.get('/api/TMSDetails/' + endtoendid, true);
  };
  getFilteredScopeDetails(searchText, endToEndId, clientWMSId) {
    return this.httpService.get('/api/SearchScopeDetails/' + this.sessionStorage.enterpriseId + '/?searchText=' + encodeURIComponent(searchText));
  };
  getTeamsByEndtoEndOrCMS() {
    return this.httpService.get('/api/Team/FilterByEndToEndOrCMS' + '?endToEndUId=' + this.sessionStorage.endToEndUId +
      (this.sessionStorage.clientWMSUId ? '&cmsUId=' + this.sessionStorage.clientWMSUId : '') + (this.sessionStorage.workstreamUId ? '&workstreamUId=' + this.sessionStorage.workstreamUId : '') + (this.sessionStorage.teamUId ? '&tmsUId=' + this.sessionStorage.teamUId : ''), false);
  };
  IsDeliveryMandatoryFilled(endtoendid) {
    return this.httpService.get('/api/IsDeliveryMandatoryFilled/' + endtoendid);
  };
  SaveUserPreference(userPreference) {
    return this.httpService.post('/api/User/Preference', userPreference);
  };
  getReleaseNoteUserPreference(enterpriseId) {
    return this.httpService.get('/api/Users/ReleaseNoteUserPreference/' + enterpriseId + '/');
  };
  saveReleaseNoteUserPreference(preferencevalue) {
    return this.httpService.post('/api/Users/SaveReleaseNoteUserPreference/Enterpriseid/' + this.sessionStorage.enterpriseId + '/PreferenceValue/' + preferencevalue, null);
  };
  getClientAccess(enterpriseId) {
    return this.httpService.get('/api/User/' + enterpriseId + '/ClientAccess');
  };
  getTabVisibility(enterpriseId) {
    return this.httpService.get('/api/User/' + enterpriseId + '/TabAccess');
  };
  logUserInformation(enterpriseId) {
    return this.httpService.post('/api/log/user/' + enterpriseId + '/');
  };
  logUserName(enterpriseId) {
    return this.httpService.getWeb('Angular/LogEnterpriseId?enterpriseId=' + enterpriseId);
  };
}



