<a href="javascript:void(0)" *ngIf="RoleAccess!='V'" (click)="setDisplay()" class="c-add-facility" data-flyoutid="add-shift" role="button">
  <img src="../assets/toolkit/images/key-contacts/add.svg" alt="Details" role="presentation" />
  <span>Facility</span>
</a>

<div *ngIf="isDisplay" class="c-added-facilities -show">
  <form name="metrocitiesaddeditform" #metrocitiesaddeditform="ngForm" novalidate>
    <div class="c-myWizard-form__input">
      <div class="c-myWizard-form__input-area">
        <div class="c-custom-select__wrapper   " [ngClass]="{'-required' : !IsATC }">
          <label for="country">Country</label>
          <div class="c-dropdown" [ngClass]="{'-disabled':RoleAccess=='V' || IsATC}" id="country">
            <!-- <select [disabled]="RoleAccess=='V' || IsATC" class="form-control" role="listbox"
                  [(ngModel)]="Country.Id" (change)="CountryChange(Country.Id);FacilityId='';MetroCityId=''">
            <option role="option" value="">--Select--</option>
            <option role="option" *ngFor="let item of CountryList | orderBy: 'Name'; trackBy : trackById;" [ngValue]="item.Id">{{item.Name}}</option>
          </select>-->

            <search-dropdown [disabled]="RoleAccess=='V' || IsATC" [options]="CountryList" [isShow]="true" [labelInfo]="'Country Id'" id="countryId" name="countryId" #countryId="ngModel"
                             [selected]="Country.Id" [selectText]="'Select'"
                             (callback)="CountryChange(Country.Id);FacilityId='';MetroCityId=''"
                             [formname]="metrocitiesaddeditform"
                             [required]="!IsATC"
                             [errormessage]="'Please select Country'"
                             [(ngModel)]="Country.Id">
            </search-dropdown>
          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area">
        <div class="c-custom-select__wrapper   " [ngClass]="{'-required' : IsATC || Country.Id != null}">
          <label for="metro_city">Metro City</label>
          <div class="c-dropdown" id="metro_city">

            <!--
            <select [disabled]="RoleAccess=='V'" class="form-control" role="listbox"
                    [ngClass]="{'has-error':(!IsATC ? Country.Id != '' && MetroCityId == '' : '')}"
                    [(ngModel)]="MetroCityId" (change)="MetroCityChange(MetroCityId);FacilityId=''">
              <option role="option" value="">--Select--</option>
              <option role="option" *ngFor="let item of MetroCityList | orderBy: 'Name'; trackBy : trackById;" [ngValue]="item.Id">{{item.Name}}</option>
            </select>
          -->

            <search-dropdown [options]="MetroCityList" [isShow]="true" [labelInfo]="'MetroCity Id'" id="metroCityId" name="metroCityId" #metroCityId="ngModel"
                             [ngClass]="{'-error':(!IsATC ? Country.Id != '' && MetroCityId == '' : '')}"
                             [selected]="MetroCityId" [selectText]="'Select'"
                             (callback)="MetroCityChange(MetroCityId);FacilityId=''"
                             [formname]="metrocitiesaddeditform"
                             [required]="true"
                             [disabled]="RoleAccess=='V'"
                             [errormessage]="'Please select Metro City'"
                             [(ngModel)]="MetroCityId">
            </search-dropdown>


          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area -required">
        <div class="c-custom-select__wrapper" [ngClass]="{'-required' : ((MetroCityId != '' && InteractionStyleId != 3) || (!IsATC ? Country.Id != '' && InteractionStyleId != 3 : ''))}">
          <label for="facility">Facility</label>
          <div class="c-dropdown" id="facility" [ngClass]="{'-disabled':RoleAccess=='V' || InteractionStyleId == 3}">
            <!--
          <select [disabled]="RoleAccess=='V' || InteractionStyleId == 3" class="form-control" role="listbox"
                  [ngClass]="{'has-error':((MetroCityId != '' && InteractionStyleId != 3 && FacilityId == '') || (!IsATC ? Country.Id != '' && InteractionStyleId != 3 && FacilityId == '' : ''))}"
                  [(ngModel)]="FacilityId">
            <option role="option" value="">--Select--</option>
            <option role="option" *ngFor="let item of FacilityList | orderBy: 'Name'; trackBy : trackById;" [ngValue]="item.Id">{{item.Name}}</option>
          </select>

          -->

            <search-dropdown [options]="FacilityList" [isShow]="true" [labelInfo]="'Facility Id'" id="facilityId" name="facilityId" #facilityId="ngModel"
                             [ngClass]="{'-error':((MetroCityId != '' && InteractionStyleId != 3 && FacilityId == '') || (!IsATC ? Country.Id != '' && InteractionStyleId != 3 && FacilityId == '' : ''))}"
                             [selected]="FacilityId" [selectText]="'Select'"
                             [disabled]="RoleAccess=='V' || InteractionStyleId == 3"
                             [formname]="metrocitiesaddeditform"
                             [required]="((MetroCityId != '' && InteractionStyleId != 3) || (!IsATC ? Country.Id != '' && InteractionStyleId != 3 : ''))"
                             [errormessage]="'Please select Facility'"
                             [(ngModel)]="FacilityId">
            </search-dropdown>

          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area">
        <div class="c-custom-select__wrapper   ">
          <label for="interaction_style">Interaction Style</label>
          <div class="c-dropdown" id="interaction_style">
            <!--
           <select [disabled]="RoleAccess=='V'" class="form-control" role="listbox"
                  [(ngModel)]="InteractionStyleId" (change)="InteractionStyleChange(InteractionStyleId);InteractionStyleId == 3 ? FacilityId = '' : FacilityId">
            <option role="option" value="">--Select--</option>
            <option role="option" *ngFor="let item of InteractionStyles | orderBy: 'Name'; trackBy : trackById;" [ngValue]="item.Id">{{item.Name}}</option>
          </select>

          -->

            <search-dropdown [options]="InteractionStyles" [isShow]="true" [labelInfo]="'InteractionStyle Id'" id="interactionStyleId" name="interactionStyleId" #interactionStyleId="ngModel"
                             [selected]="InteractionStyleId" [selectText]="'Select'"
                             [formname]="metrocitiesaddeditform"
                             [errormessage]="'Please select Interaction Style'"
                             (callback)="InteractionStyleChange(InteractionStyleId);InteractionStyleId == 3 ? FacilityId = '' : FacilityId"
                             [disabled]="RoleAccess=='V'"
                             [(ngModel)]="InteractionStyleId">
            </search-dropdown>

          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area">
        <!---->
      </div>
      <div class="c-myWizard-form__input-area">
        <!--         [ngClass]="{'DisabledTab': ((RoleAccess == 'V') || (Country.Id == '' || MetroCityId == '' || (InteractionStyleId != 3 && FacilityId == '')))}"
      -->
        <!--
          <a href="javascript:void(0)" (click)="AddMetroCity(Country.Id,MetroCityId,FacilityId,InteractionStyleId);MetroCityId='';FacilityId='';InteractionStyleId=''; !IsATC ? Country.Id='' : Country.Id" role="button" class="o-primary-button">


      -->

        <a href="javascript:void(0)" style="float:right" (click)="AddMetroCity(Country.Id,MetroCityId,FacilityId,InteractionStyleId,metrocitiesaddeditform);" role="button" class="o-primary-button c-float">
          {{action}}
        </a>
      </div>
    </div>
    </form>
</div>
<div class="c-table-responsive">
  <table class="c-table c-table-fixed">
    <thead>
      <tr>
        <th scope="col" width="100px">&nbsp;</th>
        <th scope="col" width="100px">Country</th>
        <th scope="col" width="120px">Metro City</th>
        <th scope="col" width="250px">Facility</th>
        <th scope="col" width="350px">Interaction Style</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="MetroCity == null || MetroCity.PortfolioMetroCities.length == 0">
        <td colspan="5"> No Records Found </td>
      </tr>
      <ng-container *ngIf="MetroCity!=null">
        <tr [hidden]="row.IsMarkedForDeletion" *ngFor="let row of MetroCity.PortfolioMetroCities;let i = index; trackBy : trackByIndex; ">
          <td>
            <div class="c-key-icon">
              <a href="javascript:;" class="icon-edit" role="button" (click)="EditMetroCity(i)" [ngClass]="{'-disabled': RoleAccess=='V'}" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit"></a>
              <a href="javascript:;" class="icon-delete" [ngClass]="{'-disabled': RoleAccess=='V'}" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" 
                 (click)="DeleteMetroCity(i);"
                 role="button"></a>
            </div>
          </td>
          <td>
            {{row.Country}}
          </td>
          <td>
            {{row.MetroCityName}}
          </td>
          <td>
            {{row.Facility}}
          </td>
          <td>
            {{row.InteractionStyle}}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
