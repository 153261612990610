<div class="c-dropdown" [ngClass]="{'-disabled':disableDropdown,'has-error':(required && formname.submitted && (selected==''||selected==null||selected==undefined))}" click-elsewhere (clickOutside)="SelectorOpen=false">
  <input type="hidden" name="{{'searchDropdown_' + ariaLabelledby}}" [required]="required==true" [value]="value" />
  <a href="javascript:;" name="{{'searchdropdown_btn_' + ariaLabelledby }}" *ngIf="isShow" attr.aria-labelledby="{{ariaLabelledby}}" [attr.tabindex]="(isDisableTeamScopeSelector || disableDropdown) ? -1 : null"
      role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down" [ngClass]="{'-disabled' : isDisableTeamScopeSelector}"
        (click)="openSelector()"  (keydown)="openSelector()"><span>{{selectedItem}}</span></a>
  <div [ngClass]="{'c-dropdown-menu':SelectorOpen}">
    <div [ngClass]="{'c-dropdown-body':SelectorOpen}">
      <div class="c-custom-control" *ngIf="SelectorOpen && (options!= undefined && options.length > 5)">
        <span><i class="icon-search" aria-label="search"></i></span>
        <input type="text" placeholder="Search" [(ngModel)]="query" />
      </div>
      <a *ngIf="SelectorOpen && query && (options | filterByText: query : filterId).length <=0 " class="c-dropdown-item">No values found</a>
      <a role="option" *ngIf="SelectorOpen && selectText != undefined &&  !query" href="javascript:;" [ngClass]="{'option-selected': isNoneSelected}" (keydown)="searchDropDownKeyPress(null,$event,ariaLabelledby);" (click)="onSelect(null);callbackFunction($event);" class="c-dropdown-item">{{selectText}}</a>
      <ng-container *ngIf="SelectorOpen && (options!= undefined && options.length <= 5)">
        <a role="option" *ngFor="let item of options;let i = index;let l = last; trackBy : trackByName;" href="javascript:;" class="c-dropdown-item"
           [ngClass]="{'option-selected':item.IsSelected}" (keydown)="searchDropDownKeyPress(item,$event,ariaLabelledby);" (click)="onSelect(item);callbackFunction($event);" name="{{ariaLabelledby + '_focus_'+ i}}"
           >{{item.Name}}</a>
      </ng-container>
      <ng-container *ngIf="SelectorOpen && (options!= undefined && options.length > 5)">
        <a role="option" *ngFor="let item of options | filterByText:query : filterId;let i = index;let l = last; trackBy : trackByName" href="javascript:;" class="c-dropdown-item"
           [ngClass]="{'option-selected':item.IsSelected}" (keydown)="searchDropDownKeyPress(item,$event,ariaLabelledby);" (click)="onSelect(item);callbackFunction($event);" name="{{ariaLabelledby + '_focus_'+ i}}"
            >{{item.Name}}</a>
      </ng-container>
    </div>
  </div>
</div>
<p aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'show':(required && formname.submitted  && (selected==''||selected==null||selected==undefined))}">{{errormessage}}</p>

