export enum ManageWorkTile {
  ProvisionDeliveryPlatform = '42',
  ProjectContext = '43',
  InitiateMyADProject = '44',
  ReportHPDPMetrics = '45',
  ReviewMetrics = '46',
  ValueDashboard = '47',
  ApplicationLifecycleManagement = '48',
  WatsonAnalytics = '49',
  NarrativeStatus = '50',
  ReviewMilestone = '51',
  ReviewRisksandIssues = '52',
  ClientStatusReporting = '53',
  MyClientPortfolio = '54',
  ADCommandCenter = '55',
  AMCommandCenter = '56',
  AgileCommandCenter = '57',
  InitiateMyAMProject = '58',
  InitiateMyAgileProject = '59',
  MyEngagementPortfolio = '60',
  AIAM = '61',
  ADOP = '62',
  ValueWallet = '67',
  GovernanceDeliveryDashboard = '214',
  InitiationAndConfiguration = '176'
}



export enum UserRoleType {
  EngagementViewer = '1',
  EngagementEditor = '2',
  DeliveryWMUViewer = '3',
  DeliveryWMUEditor = '4',
  PortfolioViewer = '5',
  PortfolioEditor = '6',
  Administrator = '7',
  SuperUser = '8',
  DeliveryManager = '9',
  TeamMember = '10'
}

export enum UserPreference {
  ReportingFrequencyPreference = 4,
  MilestonesWithOutScroll = 3,
  RiskMilestoneIssuesUserPreference = 21,
  EndToEndPreference = 5,
  EngagementPreference = 7,
  VAAudioPreference = 14,
  EngagementPreferenceToCopyNS = 17,
  SelfHealingPreference = 6,
  SupportTabVisibility = 17,
  ManageTileConfigurationAccess = 21,
  DevelopmentSupport = 22

}

export enum WelcomeEnum {
  DPS = 135,
  GTOAA = 134,
  GTOM = 132,
  GTOP = 136,
  GTOT = 133
}

export enum PeriodEnum {
  Current = "current",
  Next = "next",
  Last = "last"
}

export enum SortingEnum {
  Ascending = "ascending",
  Descending = "descending"
}

export enum ViewEnum {
  Week = "week",
  Month = "month"
}

export enum enumNarrativeAttributes {
  OverAll = "OV",
  Risk = "RK",
  Client = "CL",
  Delivery = "DL",
  ServiceIntroduction = "SI",
  FacilitiesInfrastructure = "FI",
  InformationSecurity = "IS",
  Financial = "FL",
  Quality = "QL",
  PeopleEngagement = "PE",
  StaffingResourcing = "SF",
  CopyPriorPeriod = "CP",
  SubmitEngagement = "ES",
  SLastQAReview = "SQA",
  DLastqareview = "DQA",
  Achievements = "ACV",
  ActivityPlanned = "AP"
}

export enum enumPortfolioType {
  AccentureOG = 1,
  AccentureGDN = 2,
  AvanadeGU = 3
}

export enum enumSubmissionType {
  EndToEnd = 1,
  ClientWMS = 2,
  Workstream = 3,
  Team = 4,
  CMSTMS = 5,
  WorkstreamTMS = 6
}

export enum enumRowStatus {
  InActive = 0,
  Active = 1,
  Draft = 2
}

export enum enumNarrativeStatus {
  NotReported = 0,
  Alert = 1,
  UnderControl = 5,
  Good = 3,
  NotApplicable = 4
}

export enum enumRoleAccess {
  Editor = "E",
  Viewer = "V"
}

export enum enumRoleType {
  AdminSuper = "AA",
  AdminAccenture = "AC",
  AdminAvanade = "AV",
  PortfolioEditor = "PE",
  PortfolioViewer = "PV",
  DelLeadEditor = "DE",
  DelLeadViewer = "DV",
  ProjectManager = "PM",
  TeamMember = "TM",
  OpportunityCreator = "OC",
  InitiativeCreator = "IC"
}

export enum enumDeliveryAccountability {
  None = 0,
  AccentureOG = 1,
  AccentureGDN = 2,
  AvanadeGU = 3,
  AccountSpecific = 4
}

export enum enumUserPreference {
  DefaultEndToEndPreference = 1,
  DefaultCMSPreference = 2,
  HideSlideShow = 3
}
export enum enumAssociationType {
  None = "None",
  Full = "Full",
  Partial = "Partial"
}
export enum enumProfileTab {
  Profile = 1,
  Scope = 2,
  KeyContacts = 3,
  ClientStructure = 4,
  TeamStructure = 5,
  MatrixStructure = 6,
  MigrationDetails = 7,
  DeliveryPortfolio = 8,

  /*configure metrics enums */
  MetricSelection = 9,
  Thresholds = 10,
  OtherConfigureSettings = 11,

  /*PlatformAccess enum*/
  /*PlatformAccess = 12*/

}
export enum enumDeliveryType {
  AD = 1,
  Agile = 2,
  AM = 3,
  InfraStructure = 4,
  Testing = 5,
  Ops = 6,
  Sec = 7
}
export enum enumDeployRegion {
  EU = "EU",
  US = "US",
  ANZ = "ANZ"
}

export enum enumDeliveryFunction {
  NonSIAD = "7,12,18,20,24,27",// Defect Management - 7, Issue Management - 12, Requirements Management - 18, Risk Management - 20, Test Execution - 24, Work Plan Management - 27
  SIAD = "2,7,27,24",// Build Management - 2, Defect Management - 7, Work Plan Management - 27, Test Execution - 24
  SIADTestingAMInfraSecCSEnable = "12,18,20", // Issue Management - 12, Requirements Management - 18, Risk Management - 20
  SIADAgileTestingEnable = "12,18,20,31",  // Issue Management - 12, Requirements Management - 18, Risk Management - 20, Backlog Management - 31
  SIADOpsEnable = "18,20", //Requirements Management - 18, Risk Management - 20
  SIADDisable = "2",// Build Management - 2
  NonSIAgile = "7,17,24,31", //Defect Management - 7, Release Management - 17, Test Execution - 24, Backlog Management - 31
  SIAgile = "2,7,17", // Build Management - 2, Defect Management - 7, Release Management - 17
  SIAgileAMInfraSecCSEnable = "24,31", // Test Execution - 24, Backlog Management - 31
  SIAgileTestingEnable = "31", // Backlog Management - 31
  SIAgileDisable = "2",// Build Management - 2
  AM = "11,15,22,38", // Incident Management- 11, Problem Management - 15, Service Request Management - 22, Service Delivery - 38
  InfraStructure = "11,22,29,38", // Incident Management- 11, Service Request Management - 22, Change Management- 29, Service Delivery - 38
  Testing = "7,24", // Defect Management - 7, Test Execution - 24
  Ops = "12,38", // Issue Management - 12, Service Delivery - 38
  Sec = "49,50,51", // Transformation Services - 49, Strategy Services - 50, Industry Services - 51
  CapacityServices = "19" //Resource Management - 19
}

export enum enumModuleAction {
  ScopeSelector = "Scope Selector",
  Tile = "Tile",
  GenericFeature = "Generic",
  Template = "Template"
}

export enum enumEntityType {
  EndToEnd = "ENDTOEND",
  CMS = "CMS",
  Workstream = "WS",
  TMS = "TMS"
}

export enum ApplicationMessages {
  AgileWorkstreamsAutoApprovalMessageForYes = "Auto-Approval set to 'Yes' indicates that any workstream created in the external tool will be automatically considered as a valid workstream, and no review/approval is needed from the user. Do you wish to continue?",
  AgileWorkstreamsAutoApprovalMessageForNo = "Auto-Approval set to 'No' indicates that any system-generated workstreams which are pulled from external tools, must be reviewed/approved by the user in this screen to be considered as a valid workstream. Do you wish to continue?",
  AgileWorkstreamsAutoApprovalMessageDuringProcessing = "The preference set previously is currently being processed. Kindly try again after some time, to change the setting for future approvals."
}

export enum enumCMS {
  OpenCMS = 1,
  ClosedCMS = 2
}

export enum enumFeatureAccess {
  Profile = 1,
  Scope = 2,
  KeyContacts = 3,
  ClientStructure = 4,
  TeamStructure = 5,
  MatrixStructure = 6,
  Portfolio = 7,
  Manage = 8,
  Tasks = 9,
  Admin = 10,
  ReleaseNotes = 11,
  LiveChat = 12,
  UserHelp = 13,
  Logout = 15,
  DeliveryProfileGuide = 16,
  DeliveryScopeDetails = 18,
  ClassAssetsDetails = 19
}

export enum enumTile {
  InitiateProfile = 141,
  ManageReleaseNotes = 223
}

export enum enumArea {
    Welcome = 'Welcome',
    Manage = 'Manage Work',
    Portfolio = 'Review Portfolio',
    Tasks = 'Execute Tasks',
    Admin = 'Admin',
    HelpSelection = 'Help Section',
    Support = 'Support',
    InitiateAndConfigure = "Initiation&Config",
     ManageInitiateAndConfigure = "Manage-I&C"
}

export enum enumPath {
  Portfolio = 'portfolio',
  Manage = 'manage',
  Admin = 'admin',
  Opportunity = 'opportunity',
  Configuration = 'configuration',
  Support = 'support',
  Tasks = 'tasks',
  CMMI = 'cmmi',
  InternalInitiative = 'internalinitiative',
  AvanadeEACForecast = 'avanadeeacforecast',
  DeliveryExcellence = 'deliveryexcellence',
  CMSMenu = 'cmsmenu',
  TMSMenu = 'tmsMenu',
  Structure = 'structure',
  ChildTiles = 'childtiles',
  PlatformAccess = 'platformaccess',
  Security = 'security',
  ReleaseNotes = 'releasenotes',
  DevelopmentSupport = 'developementsupport',
  TileConfiguration = 'tileconfiguration',
  Home = 'home'
}

export enum enumTileShortNames {
  TermsOfUse = 'TOU'
}

export enum enumSubCategories {
  PlantheResources = 1,
  DistributedWorkManagement = 2,
  HardwareSoftwareInfrastructureDetails = 3,
  PlanStakeholderInvolvement = 4,
  DeliveryExcellenceReview = 5,
  ConfigurationManagementApproach = 6,
  ConfigurationControl = 7,
  EstablishEstimates = 8,
  Metrics = 9,
  WorkPlanning = 10,
  WorkMonitoring = 11,
  ContinuousImprovement = 12,
  ADMAndContractualProcess = 13,
  DeliveryProcess = 14
}

export enum enumMethodology {
  AD = 1,
  Agile = 2

}
export enum enumAlternativeContacts {
  PMOLead = 14,
  TransformationDirector = 15,
  AutomationArchitect = 16,
  OPERALead = 17,
  ClientExperienceLead = 18,
  ContractManager = 19
}

export enum enumPlatformScopeOfWork {
  CMMScopeOfWork = 100,
  CDMScopeOfWork = 104
}
export enum enumConfigureMetricesTab {
  MetricSelection = 1,
  Thresholds = 2,
  OtherConfigureSettings = 3
}

export enum enumMetricDeliveryLevel {
  E2E = 4,
  Team = 6
}
