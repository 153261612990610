<div id="edit-service" class="c-modal c-edit-service" tabindex="-1">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">Edit Service Group Network</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close" alt="Close" (click)="CancelService()"></button>
      </div>
      <div class="c-modal__body">
        <div class="c-myWizard-form__input">
          <div class="c-myWizard-form__input-area">
            <div class="o-input">
              <label for="serviceGroup">Service Group</label>
              <input type="text"
                     class="o-input__input"
                     placeholder="Consulting"
                     value="{{item.ServiceGroupName}}"
                     id="serviceGroup"
                     disabled />
            </div>
          </div>
          <div class="c-myWizard-form__input-area">
            <div class="o-input">
              <label for="allocation">Allocation(%)</label>
              <input type="text"
                     class="o-input__input"
                     placeholder="0%"
                     value="{{item.Allocation}}"
                     id="allocation"
                     disabled />
            </div>
          </div>
          <div class="c-myWizard-form__input-area">
            <div class="o-input    ">
              <label for="deliveryLead">Delivery Lead / POC</label>
              <auto-complete id="serviceGroupLead" #serviceGroupLead="ngModel" name="serviceGroupLead" [disabled]="RoleAccess=='V'"
                             hiddenFieldID="{{'hiddenserviceGroupLead_'}}" ariaLabelledby="serviceGroupDeliveryLead"
                             [selected]="DeliveryLead"
                             [(ngModel)]="DeliveryLead"
                             (selectPeoplePicker)="DeiveryLeadChange(DeliveryLead, $event)">
              </auto-complete>
            </div>
          </div>
        </div>
      </div>
      <div class="c-modal__footer">
        <div class="c-modal__footer-btnGroup">
          <!-- add class -dismiss-modal to buttons that'll close the modal -->
          <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" class="o-secondary-button  -dismiss-modal" (click)="CancelService()"  [ngClass]="{'-disabled': RoleAccess === 'V'}"  [disabled]="RoleAccess=='V'">
            <!---->
            Cancel
          </a>
          <!--Primary Button ends from here-->                    <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" class="o-primary-button  -dismiss-modal" (click)="SaveService()" [ngClass]="{'-disabled': RoleAccess === 'V'}" [disabled]="RoleAccess=='V'">
            <!---->
            Save
          </a>
          <!--Primary Button ends from here-->
        </div>
      </div>
    </div>
  </div>
</div>
