<div id="termsModal" class="c-modal c-terms-of-use" tabindex="-1" aria-modal="true" role="dialog" aria-labelledby="modalDialogTitle">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">Terms Of Use</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" (click)="closeModal()" aria-label="Close" alt="Close" 
        ></button>
      </div>
      <div class="c-modal__body">
        <div class="c-modal__subhead">myWizard Privacy Statement Supplement</div>
        <div class="c-modal__privacystatement">
          <p>The Accenture Portal Privacy Statement is supplemented as follows:</p>
          <p>Effective Date: 1.29.2016</p>
        </div>
        <p>When using the myWizard, please be aware that your information will be collected in accordance the Portal Privacy Statement and monitored as described in the below myWizard Terms of Use, updated 1.29.2016.</p>
        <div class="c-modal__subhead">Terms of Use for Accenture Internal Online Publishing</div>
        <p>Your access to and use of this site is subject to the following terms and conditions “Terms of Use” contained within this document, and all applicable laws. By accessing and using this site, you accept the following terms and conditions, without limitation or qualification:</p>
        <p>
          <span class="-note">Conduct</span> Your conduct relating to uploading and downloading functions on the myWizard should comply with these Terms of Use as well as Accenture Policies and our Code of Business Ethics (COBE). Policies with particular relevance include <a href="https://policies.accenture.com/pages/0001-0100/0057.aspx" target="_blank" rel="noopener noreferrer" data-myw-title="Policy 57(Open a new window)" data-myw-toggle="tooltip" data-myw-placement="bottom" class="-link">Policy 57</a> - Acceptable Use of Information, Devices, and Technology, <a href="https://policies.accenture.com/Pages/0001-0100/0069.aspx" target="_blank" data-myw-title="Policy 69(Open a new window)" data-myw-toggle="tooltip" data-myw-placement="bottom" class="-link">Policy 69</a>- Confidentiality, <a href="https://policies.accenture.com/policy/1001" target="_blank" data-myw-title="Policy 1001(Open a new window)" data-myw-toggle="tooltip" data-myw-placement="bottom" class="-link">Policy 1001</a>- Respecting the Individual, and <a href="https://policies.accenture.com/Pages/1401-1500/1461.aspx" target="_blank" data-myw-title="Policy 1461(Open a new window)" data-myw-toggle="tooltip" data-myw-placement="bottom" class="-link">Policy 1461</a>- Social Media.
        </p>
        <p>
          <span class="-note">Ownership and Intellectual Property:</span> Respect the intellectual property rights of others by first ensuring that prior to copying any content found online, you have a legal right to do so. References: <a href="https://policies.accenture.com/pages/0001-0100/0057.aspx" target="_blank" rel="noopener noreferrer" data-myw-title="Policy 57(Open a new window)" data-myw-toggle="tooltip" data-myw-placement="bottom" class="-link">Policy 57</a>- Acceptable Use of Information, Devices, and Technology, <a href="https://policies.accenture.com/Pages/1401-1500/1461.aspx" target="_blank" data-myw-title="Policy 1461(Open a new window)" data-myw-toggle="tooltip" data-myw-placement="bottom" class="-link">Policy 1461</a>- Social Media, and Accenture's <a href="https://policies.accenture.com/policy/0091" target="_blank" data-myw-title="Intellectual Property site (Open a new window)" data-myw-toggle="tooltip" data-myw-placement="bottom" class="-link"> Intellectual Property site</a>
        </p>
        <p>The information provided on this site is free of charge and for informational purposes and internal use only. Unless otherwise stated, the contents of this site including, but not limited to, the text and images contained herein and their arrangement are the property of Accenture. All trademarks used or referred to in this website are the property of their respective owners</p>
        <p>Nothing contained in this site shall be construed as conferring by implication, estoppel, or otherwise, any license or right to any copyright, patent, trademark or other proprietary interest of Accenture or any third party. </p>
        <p class="-note">This site and the content provided in this site, including, but not limited to, graphic images, audio, video, html code, buttons, and text, may not be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, without the prior written consent of Accenture, except that you may download, display, and print one copy of the materials on any single computer solely for your personal, non-commercial use, provided that you do not modify the material in any way and you keep intact all copyright, trademark, and other proprietary notices.</p>
        <p>Links on this site may lead to services or sites not operated by Accenture. No judgment or warranty is made with respect to such other services or sites and Accenture takes no responsibility for such other sites or services. A link to another site or service is not an endorsement of that site or service. Any use you make of the information provided on this site, or any site or service linked to by this site, is at your own risk.</p>
        <p><span class="-note">Warranties: </span>This site and its contents are provided “as is” and Accenture makes no representation or warranty of any kind with respect to this site or any site or service accessible through this site. Accenture expressly disclaims all express and implied warranties including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. In no event will Accenture be liable to any party for any direct, indirect, incidental, special, exemplary, consequential, or other damages (including, but not limited to, lost profits, business interruption, loss of programs or data) without regard to the form of action and whether in contract, tort, negligence, strict liability, or otherwise, arising out of or in connection with this site, any content on or accessed through this site or any site service linked to, or any copying, displaying, or use thereof.</p>
        <p>
          <span class="-note">Legal Proceedings:</span> Accenture maintains this site in Illinois, U.S.A. and you agree that these terms of use and any legal action or proceeding relating to this site shall be governed by the laws of the State of Illinois without reference to its choice of law rules. If you attempt to bring any legal proceedings against Accenture you specifically acknowledge that Accenture is free to choose the jurisdiction of our preference as to where such action against us may be held. As you have agreed by using this site to choose the laws of the State of Illinois to govern any such proceedings, we will probably choose to defend any such action in Illinois and we can make this decision entirely as it suits us, without regard to where in the world you are located, or from where in the world you visited this site.
          <span class="-note">You are responsible for complying with the laws of the jurisdiction from which you are accessing this site and you agree that you will not access or use the information on this site in violation of such laws.</span>
          Unless expressly stated otherwise herein, any information submitted by you through this site shall be deemed non-confidential and non-proprietary
          <span class="-note">You represent that you have the lawful right to submit and/or download such information and agree that you will not submit and or download any information unless you are legally entitled to do so. References:</span>
          <a href="https://policies.accenture.com/Pages/0001-0100/0069.aspx" target="_blank" rel="noopener noreferrer" data-myw-title="Policy 69(Open a new window)" data-myw-toggle="tooltip" data-myw-placement="bottom" class="-link">Policy 69</a> - Confidentiality, and Accenture's  <a href="https://go.accenture.com/DataClassification" target="_blank" data-myw-title="Data Classification and Protection standards (Open a new window)" data-myw-toggle="tooltip" data-myw-placement="bottom" class="-link">Data Classification and Protection standards</a>
        </p>
        <p><span class="-note">Monitoring:</span> As permitted by applicable law, Accenture reserves the right to monitor activity on its myWizard. Such monitoring may include a review of the volumes and frequency of materials being uploaded or downloaded, and a review of the content reported as inappropriate by other employees. Should it be determined that the volume of uploading or downloading substantially exceeds that of the average user, then there may be a further review of the nature and substance of the content and its relation to the role of the employee and myWizard user.</p>
        <p>If you see content on Accenture's myWizard that violates Accenture Policies or our COBE, please report it via a provided flagging mechanism on the specific platform if it exists, or to your supervisor, career counselor, anyone in Accenture Leadership, Legal or Human Resources. If you are uncomfortable using any of these channels, please seek additional direction on how to report violations from <a href="https://policies.accenture.com/Pages/0901-1000/1000.aspx" target="_blank" rel="noopener noreferrer" data-myw-title="Policy 1000 – Raising Legal and Ethics Issues and Prohibiting Retaliation. (Open a new window)" data-myw-toggle="tooltip" data-myw-placement="bottom" class="-link"> Policy 1000 - Raising Legal and Ethics Issues and Prohibiting Retaliation.</a></p>
        <p><span class="-note">Consequences:</span>Violations of Accenture Policies or these Terms of Use may result in appropriate, lawful discipline, up to and including termination of employment. Additionally, for non-employees, Accenture may choose to sever its relationship or revoke the credentials of the user for violations of these Terms of Use. No provision of these Terms of Use, however, shall be interpreted or applied in a manner that would interfere with any employee rights that are protected by applicable laws or regulations.</p>

      </div>
    </div>
  </div>
</div> 
