<div id="multicontractinfo" class="c-modal c-priority-modal" tabindex="-1" aria-labelledby="modalDialogTitle" aria-modal="true" role="dialog">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">Guidelines</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close Contract Info Popup" alt="Close" (click)="closeModal()"></button>
      </div>
      <div class="c-modal__body">
        <p>a. Contracts which satisfy the below criteria will be associated to this EndToEnd :</p>
        <ul id="multiContractInfoLists">
          <li><div>Contract belongs to same Company as EndToEnd.</div></li>
          <li><div>Contract belongs to same Client as EndToEnd.</div></li>
          <li><div>Contract is not associated to any other EndToEnd.</div></li>
          <li><div>Contract is financially active.</div></li>
        </ul>
        <p>
          If the contract does not satisfy these criteria, they will be rejected with Status : “Rejected” and Comments updated accordingly.
        </p>
        <p>
          b. Contracts which are not available in the source system, will be marked as “Requested”
        </p>
        <p>
          c. Contracts with deploy region different from that of the EndToEnd, will be associated but with a warning message.
        </p>
      </div>
    </div>
  </div>
</div>
