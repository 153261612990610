<div id="addContact" class="c-modal c-add-contact" tabindex="-1" aria-labelledby="modalDialogTitle" aria-modal="true" role="dialog">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">{{action}} Alternative Contacts</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" (click)="CancelContacts();AlternateContactEnterpriseId=null;KeyContactsDetails.SelectedContactRoleId=null" aria-label="Close" alt="Close"></button>
      </div>
      <div class="c-modal__body">
        <div class="c-add-contact__form">
          <div class="c-add-contact__form-input">
            <div class="c-add-contact__form-area">
              <div class="o-input    ">
                <label for="alternateContactEnterpriseId" id="EnterpriseId">Enterprise ID</label>
                <auto-complete id="alternateContactEnterpriseId" #contractdeliverylead="ngModel" name="txtalternativecontact"
                               hiddenFieldID="hiddenAlternateContactEnterpriseId" ariaLabelledby="EnterpriseId"
                               [selected]="AlternateContactEnterpriseId"
                               [(ngModel)]="AlternateContactEnterpriseId">
                </auto-complete>
              </div>
            </div>
            <div class="c-add-contact__form-area">
              <div class="c-custom-select__wrapper   ">
                <label for="role" id="Role">Role</label>
                <div class="c-dropdown" id="role">

                  <search-dropdown [options]="KeyContactsDetails.AlternateContactsList" [isShow]="true" [labelInfo]="'Role'"
                                   [selected]="KeyContactsDetails.SelectedContactRoleId" [selectText]="'Select'"
                                   [(ngModel)]="KeyContactsDetails.SelectedContactRoleId" (callback)="onDropdownChange()">
                  </search-dropdown>
                </div>
              </div>
              <br *ngIf="KeyContactsDetails.SelectedContactRoleId=='4'"/>
              <div class="c-add-contact__form-area">
                <div class="o-input   -required   " *ngIf="KeyContactsDetails.SelectedContactRoleId=='4'">
                  <input type="text"
                         class="o-input__input"
                         name="otherrole"
                         #otherrole="ngModel"
                         aria-labelledby="OtherRole"
                         [(ngModel)]="KeyContactsDetails.OtherRole"
                         (keypress)="RestrictNumeric($event)"
                         [ngClass]="{ 'has-error': KeyContactsDetails.OtherRole == '' || otherrole.errors?.required}"
                         id="OtherRole"
                         maxlength="30"
                        
                         placeholder="Specify the Other role"
                        />
                  <p *ngIf="KeyContactsDetails.OtherRole == '' " aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{ 'is-error':KeyContactsDetails.OtherRole == '' || otherrole.errors?.required }">Please enter the exact Role </p>
                </div>
              </div>
            </div>
          </div>
          <a href="javascript:;" class="c-add-contact__form-img"><img src="assets/toolkit/images/key-contacts/add.svg" alt="Add"  aria-labelledby="Click to add role" (click)="AddContacts(AlternateContactEnterpriseId);" aria-label="Add Alternative Contact"></a>
        </div>
        <div class="c-table-responsive" *ngIf="action == 'Add'">
          <table class="c-table">
            <thead>
              <tr>
                <th scope="col" width="25%">Enterprise ID</th>
                <th scope="col" width="20%">Role</th>
                <th scope="col" >&nbsp;</th>
                <th scope="col" class="c-text-center">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="NewListAlternateContact.length == 0">
                <td colspan="4"> No Records Found </td>
              </tr>
              <tr *ngFor="let item of NewListAlternateContact; trackBy : trackByIndex;">
                <td aria-labelledby="EnterpriseId">{{item.EnterpriseId}}</td>
                <td aria-labelledby="Role">{{item.AlternateContactRoleName}}</td>
                <td>{{item.AlternateContactRoleTypeId == "4" ? item.OtherRole : ""}}</td>
                <td *ngIf="RoleAccess!='V'" class="c-text-center">
                  <a href="javascript:;" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" class="icon-delete" [ngClass]="(RoleCheck(item.AlternateContactRoleName) ) ? '-disabled' : ''" (click)="RemoveContact(item.AlternateContactRoleTypeId,item.EnterpriseId,item.AlternateContactRoleName)"></a> <!--[ngClass]="(RoleCheck(item.AlternateContactRoleName) ) ? 'hyper-link-disable' : ''"-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
        <div class="c-modal__footer">
          <div class="c-modal__footer-btnGroup">
            <a href="javascript:void(0)" role="button" (click)="CancelContacts();AlternateContactEnterpriseId=null;KeyContactsDetails.SelectedContactRoleId=null" class="o-secondary-button  -dismiss-modal">
              Cancel
            </a>
            <a href="javascript:void(0)" role="button" (click)="SaveAlternativeContact(); AlternateContactEnterpriseId=null" class="o-primary-button  -dismiss-modal">
              Save
            </a>
          </div>
        </div>
      </div>
  </div>
</div>
