import { Component, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { DatePipe } from '@angular/common';
import { enumCMS, enumDeliveryType } from '../../../core/context/enum';
import { SessionStorage } from '../../../core/context/sessionstorage';
import { CMSMenuService } from '../../../core/services/cms.menu.service';
import { DemographicsService } from '../../../core/services/demographics.service';
var $ = (<any>window).$;
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ManageContractsComponent } from './manage.contracts.component'
import { SharedService } from '../../../core/services/shared.service';
import * as dayjs from 'dayjs';
import { environment } from '../../../../environments/environment';

declare var window: any;

@Component({
  selector: 'cms-popup',
  templateUrl: './cms.add.edit.component.html',
  providers: [CMSMenuService, DemographicsService, DatePipe, BsModalService]
})

export class CMSAddEditComponent {
  public setAfterSave: EventEmitter<any> = new EventEmitter();  // .. Child Popup -> Parent
  modalConfirmation: BsModalRef;
  modalManage: BsModalRef;
  dayjs: any = dayjs;
  AssociatedContractsEndToEnd: any = [];
  cmsHierarchy: any = {};
  currentSelectedCMS: any = {};
  currentSelectedWS: any = {};
  SICoreMetricsIndicator: any;
  TempcurrentSelectedCMS: any = {};
  TempcurrentSelectedWS: any = {};
  DeletedWorkstream: any = [];
  SelectedLifeCycle: any = {};
  scrollYPosition: any;
  Action: string;
  Message: string;
  confirmationMessage: string;
  PopupTitle: string;
  popupIdCMS: string = "cmsAddEdit";
  popupCMSDetailsTab: string = "cmsBasicDetails";
  phoenixMappingContent: string = "cmsPhoenixMappingDetails";
  isFlagUpdated: boolean = false;

  CmsLifeCycle: any = [];
  DisableTypeOption: boolean;
  EndToEndType = this.sessionStorage.endToEndType;
  EndToEndStartDate: any;
  EndToEndEndDate: any;
  IsAdmin: boolean = this.sessionStorage.roleTypeCode == "AA" || this.sessionStorage.roleTypeCode == "AC" || this.sessionStorage.roleTypeCode == "AV" ? true : false;
  IsEditable: boolean = this.sessionStorage.roleAccessCode == 'V' ? false : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? false : true);
  IsReset: boolean;
  IsCMSEdit: boolean;
  IsCMSClosed: boolean = false;
  IsMultiSelect: boolean = false;
  IsNotValidDeliverytype: boolean = false;
  IsShowMultiContractLink: boolean;
  IsStatusDropdownDisable: boolean = false;
  SFEDisable: boolean;
  SFEdropdownDisable: boolean;
  displayAssociateWorkstreamPopup: boolean = false;
  displayWorkstreamTypePopup: boolean = false;
  active0: any; active1: any; active2: any; active3: any; active4: any; active5: any; active6: any;
  DateValidation: any = {
    IsValidStartDate: true, IsValidStartDateEndDate: true, IsValidEndDate: true, IsValidEndDateStartDate: true,
  };
  SelectorOpen: boolean;
  activePhoenix: boolean = false;
  activeBasic: boolean = false;
  isActive: boolean = true;
  isClientStakeHolderFieldsFilled: boolean = true;
  activeTempIndex: any = null;
  workstreamEndDate: any;
  codeReviewDropDown: any = [{Id: 'Y', Name:'Yes' }, { Id: 'N',Name:'No' }]
  endDateValue: boolean = true;
  startDateValue: boolean = true;
  constructor(
    private CMSMenuService: CMSMenuService,
    private demographicsService: DemographicsService,
    private sessionStorage: SessionStorage,
    private datePipe: DatePipe,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    //ux revamp : changes to flyup popup
    //$('body').addClass('block-scroll');
    setTimeout(() => {
      window.dropdownComponent();
      window.tabsComponent();
      window.TooltipFunc();
    }, 0);
    this.SICoreMetricsIndicator = this.cmsHierarchy.SICoreMetricsIndicator;
    if (this.Action.toLowerCase() == 'edit') {
      this.PrepareEditCMSWS();
    }
    else if (this.Action.toLowerCase() == 'add') {
      this.LoadCMSWSData();
      this.PrepareAddCMSWS();
    }
    setTimeout(function test() {
      $('.close').focus();
    }, 0);
    this.ActivateCMSFirstTab();
  }

  ngOnDestroy() {
    window.scrollTo(0, this.scrollYPosition);
  }

  RestoreScrollbar() {
    $("body").css("overflow", "");
    $("modal-container").css("overflow-y", "");
  }

  UpdateClass() {
    if ($("#cmsBasicDetails").hasClass("active")) {
      this.isActive = true;
    }
    else {
      this.isActive = false;
    }
  }

  PrepareEditCMSWS() {
    this.IsCMSEdit = true;
    if (this.currentSelectedWS) { //for workstream edit
      this.SetTimePicker(false);
      this.currentSelectedWS.StartDate = this.datePipe.transform(this.currentSelectedWS.StartDate, 'dd MMM yyyy');
      this.currentSelectedWS.EndDate = this.datePipe.transform(this.currentSelectedWS.EndDate, 'dd MMM yyyy');
      this.workstreamEndDate = this.currentSelectedWS.EndDate;
      this.SFEdropdownDisable = (this.currentSelectedWS.ClientEndToEndId != null) ? true : false;
      var deliveryType = this.cmsHierarchy.WorkstreamTypes.filter((x: any) => x.Id == this.currentSelectedWS.WorkstreamTypeId);
      if (deliveryType.length > 0) {
        this.IsNotValidDeliverytype = (deliveryType[0].ParentId == enumDeliveryType.AD || deliveryType[0].ParentId == enumDeliveryType.Agile || deliveryType[0].ParentId == enumDeliveryType.Ops || deliveryType[0].ParentId == enumDeliveryType.Sec) ? false : true;
      }
      this.IsMultiSelect = true;
      $('#hiddenWSDeliveryLead').val(this.currentSelectedWS.DeliveryLead);
      this.DateValidationLogic();
    }
    else if (this.currentSelectedCMS) { //for CMS Edit
      var cmsstatus = {};
      cmsstatus = [{ Id: 1, Name: "Open" }];
      this.CmsLifeCycle = cmsstatus;
      this.SelectedLifeCycle = this.CmsLifeCycle[0];

      this.IsCMSClosed = false;
      this.CMSMenuService.GETAllowCMSClosed(this.currentSelectedCMS.CMSId).subscribe((response: any) => {
        if (response && response >= 1) {
          var cmsClosedStatus = { Id: 2, Name: "Closed" };
          var cmsUpdatedCycle = cloneDeep(this.CmsLifeCycle);
          cmsUpdatedCycle.push(cmsClosedStatus);
          this.CmsLifeCycle = cmsUpdatedCycle;

          if (response == 1) {
            this.SelectedLifeCycle = this.CmsLifeCycle[1];
            this.IsStatusDropdownDisable = true;
            this.IsCMSClosed = true;
          }
          else {
            this.IsStatusDropdownDisable = false;
            this.IsCMSClosed = false;
          }
        }
      });
      $('#hiddenCMSDeliveryLead').val(this.currentSelectedCMS.DeliveryLead);
    }
  }

  PrepareAddCMSWS() {
    this.IsCMSEdit = false;
    this.SFEdropdownDisable = false;
    this.sessionStorage.SFEDisable = this.SFEDisable;
    var cmsstatus = {};
    if (this.cmsHierarchy.TypeId == 1) {
      cmsstatus = [{ Id: 1, Name: "Open" }];
      this.CmsLifeCycle = cmsstatus;
      this.SelectedLifeCycle = this.CmsLifeCycle[0];
      this.IsStatusDropdownDisable = true;
      this.IsCMSClosed = false;
      $('.close').focus();
    }
    if (this.cmsHierarchy.TypeId == 2) {
      this.IsMultiSelect = true;
      this.cmsHierarchy.TempWorkstreamStatus = cloneDeep(this.cmsHierarchy.WorkstreamStatus); //newly added
      this.currentSelectedWS.SelectedTechnology = [];
    }
  }

  LoadCMSWSData() {
    if (this.currentSelectedCMS != null && this.currentSelectedCMS.Tier == 5) {
      //this.cmsHierarchy.TypeId = 2;
      this.DisableTypeOption = true;
    }

    if (this.cmsHierarchy.TypeId == 1) {
      if (this.TempcurrentSelectedCMS != null) {
        this.currentSelectedCMS.SelectedParentId = this.TempcurrentSelectedCMS.CMSId == 0 ? null : this.TempcurrentSelectedCMS.CMSId;
      }
      else {
        this.currentSelectedCMS = {};
        this.currentSelectedCMS.SelectedParentId = null;
        this.currentSelectedCMS.EndToEndId = this.sessionStorage.endToEndId;
      }
      this.currentSelectedCMS.CMSId = 0;
      this.currentSelectedCMS.CMSName = "";
      this.currentSelectedCMS.DeliveryLead = "";
      this.currentSelectedCMS.CMSClientStakeholders = [];
      this.currentSelectedCMS.Contracts = [];
      this.currentSelectedCMS.PhoenixMapping = {};
      this.currentSelectedCMS.PhoenixMapping.IsPhoenixMappingEnabled = this.cmsHierarchy.IsPhoenixMappingEnabled;
    }
    else if (this.cmsHierarchy.TypeId == 2) {
      this.currentSelectedWS = {};
      this.currentSelectedWS.SelectedWorkstreamCms = [];
      if (this.TempcurrentSelectedCMS != null) {
        this.currentSelectedWS.CMSId = cloneDeep(this.TempcurrentSelectedCMS.CMSId);
        var SelectCMS = {
          Id: parseInt(this.TempcurrentSelectedCMS.CMSId),
          Name: this.TempcurrentSelectedCMS.CMSName,
        };
        this.currentSelectedWS.SelectedWorkstreamCms.push(SelectCMS);
      }
      else {
        this.currentSelectedWS.CMSId = null;
      }
      this.currentSelectedWS.WorkstreamId = 0;
      this.currentSelectedWS.WorkStreamName = "";
      this.currentSelectedWS.DeliveryLead = "";
      this.currentSelectedWS.StartDate = null;
      this.currentSelectedWS.EndDate = null;
      this.currentSelectedWS.IsCodeReview = null;
      this.currentSelectedWS.CodeReviewOutofScopeReasonId = null;
      this.currentSelectedWS.WorkstreamStatusId = ""; //set as default value , null
      this.currentSelectedWS.WorkstreamTypeId = null;
      this.cmsHierarchy.IsTypeStatusShow = true;
      this.cmsHierarchy.TempWorkstreamStatus = [];
      this.currentSelectedWS.SelectedWorkstreamAvailablityWindow = cloneDeep(this.cmsHierarchy.GetDefaultWeekDays);
      this.currentSelectedWS.SelectedDays = [];
      $('#startdate').val('');
      $('#enddate').val('');
      this.currentSelectedWS.EndToEndId = this.sessionStorage.endToEndId;
    }
    if (this.cmsHierarchy.WorkstreamTypes.findIndex((x : any) => x.Id == 12) > -1) { // Hide Ops in ADD WS
      this.cmsHierarchy.WorkstreamTypes.splice(this.cmsHierarchy.WorkstreamTypes.findIndex((x : any) => x.Id == 12),1);
    }
  }

  showManagePopup(attributeVal, name, entity, showStatus) {
    var inputGridValues = cloneDeep(attributeVal);
    var inputDropdownValues = cloneDeep(this.AssociatedContractsEndToEnd);
    const initialState = {
      attributeName: name,
      gridData: inputGridValues,
      AssociatedContractsEndToEnd: inputDropdownValues,
      entity: entity,
      showStatus: showStatus,
      CMSId: this.currentSelectedCMS ? this.currentSelectedCMS.CMSId : null,
      WorkstreamId: this.currentSelectedWS ? this.currentSelectedWS.WorkStreamId : null

    };

    this.modalManage = this.modalService.show(ManageContractsComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });

    this.modalManage.content.contracts.subscribe(result => {
      if (result.popupAction == 'GetData') {
        if (entity == "cms") {
          if (result.enableScrollBar) {
            $("body").css("overflow", "hidden");
          }
          this.currentSelectedCMS.Contracts = result.contracts;  //need to check
          $('#Manage_Info_cms').focus();
        }
        else if (entity == "ws") {
          if (result.enableScrollBar) {
            $("body").css("overflow", "hidden");
            $("modal-container").css("overflow-y", "scroll");
          }
          this.currentSelectedWS.Contracts = result.contracts; //need to check
          $('#Manage_Info_ws').focus();
        }
      }
      else if (result.popupAction == 'Close') {
        if (entity == "cms") {
          if (result.enableScrollBar) {
            $("body").css("overflow", "hidden");
          }
          $('#Manage_Info_cms').focus();
        }
        if (entity == "ws") {
          if (result.enableScrollBar) {
            $("body").css("overflow", "hidden");
            $("modal-container").css("overflow-y", "scroll");
          }
          $('#Manage_Info_ws').focus();
        }
      }

    });
  }

  SetTimePicker(isTypeChange) {
    var deliveryType = this.cmsHierarchy.WorkstreamTypes.filter((x: any) => x.Id == this.currentSelectedWS.WorkstreamTypeId);
    if (deliveryType.length > 0) {
      if (deliveryType[0].ParentId == enumDeliveryType.AD || deliveryType[0].ParentId == enumDeliveryType.Agile || deliveryType[0].ParentId == enumDeliveryType.Ops || deliveryType[0].ParentId == enumDeliveryType.Sec) {
        this.IsNotValidDeliverytype = false;
      }
      else {
        this.IsNotValidDeliverytype = true;
        this.currentSelectedWS.BAC = null;
      }
    }

    if (isTypeChange) {
      this.currentSelectedWS.CutOffForPlannedValue = 7;
      this.cmsHierarchy.TempWorkstreamStatus = cloneDeep(this.cmsHierarchy.WorkstreamStatus);
      this.currentSelectedWS.WorkstreamStatusId = "";
      var ParentType = this.cmsHierarchy.WorkstreamTypes.filter((x: any) => x.Id == this.currentSelectedWS.WorkstreamTypeId);
      if (ParentType.length > 0) {
        this.cmsHierarchy.TempWorkstreamStatus = this.cmsHierarchy.WorkstreamStatus.filter((x: any) => x.ParentId == ParentType[0].ParentId);
      }
      else {
        var WSStatusParent = this.cmsHierarchy.WorkstreamStatus.filter((x: any) => x.Id == this.currentSelectedWS.WorkstreamStatusId);
        if (WSStatusParent.length > 0) {
          this.cmsHierarchy.TempWorkstreamStatus = this.cmsHierarchy.WorkstreamStatus.filter((x: any) => x.ParentId == WSStatusParent[0].ParentId);
        }
      }
    }
    else {
      var WSStatusParent = this.cmsHierarchy.WorkstreamStatus.filter((x: any) => x.Id == this.currentSelectedWS.WorkstreamStatusId);
      if (WSStatusParent.length > 0) {
        this.cmsHierarchy.TempWorkstreamStatus = this.cmsHierarchy.WorkstreamStatus.filter((x: any) => x.ParentId == WSStatusParent[0].ParentId);
      }
    }
  }

  dayChange(day, active) {
    if (active)
      this.currentSelectedWS.SelectedDays.push(day.WeekdayId);
    else
      this.currentSelectedWS.SelectedDays.splice(this.currentSelectedWS.SelectedDays.indexOf(day.WeekdayId), 1);
    this.setTimeControl();
  }


  setTimeControl() {
    this.currentSelectedWS.SelectedWorkstreamAvailablityWindow.forEach((ws: any, key) => {
      if (this.currentSelectedWS.SelectedDays.indexOf(ws.WeekdayId) < 0) {
        ws.StartTime = null;
        ws.EndTime = null;
      }
    });
  }

  DateValidationLogic() {
    var isValidWorkstreamDates = true;
    if (this.currentSelectedWS.WorkstreamStatusName != 'Closed') {
      this.EndToEndStartDate = this.datePipe.transform(this.cmsHierarchy.EndToEndStartDate, 'dd MMM yyyy');
      this.EndToEndEndDate = this.datePipe.transform(this.cmsHierarchy.EndToEndEndDate, 'dd MMM yyyy');

      var e2eStartDate = new Date(this.EndToEndStartDate)
      var wsStartDate = new Date(this.currentSelectedWS.StartDate);
      var e2eEndDate = new Date(this.EndToEndEndDate)
      var wsEndDate = new Date(this.currentSelectedWS.EndDate);

      if (wsStartDate < e2eStartDate) {
        isValidWorkstreamDates = this.DateValidation.IsValidStartDate = false;
      }
      else {
        this.DateValidation.IsValidStartDate = true;
      }

      if (wsEndDate > e2eEndDate) {
        isValidWorkstreamDates = this.DateValidation.IsValidEndDate = false;
      }
      else {
        this.DateValidation.IsValidEndDate = true;
      }

      if (wsEndDate < e2eStartDate) {
        isValidWorkstreamDates = this.DateValidation.IsValidEndDateStartDate = false;
      }
      else {
        this.DateValidation.IsValidEndDateStartDate = true;
      }

      if (wsStartDate > e2eEndDate) {
        isValidWorkstreamDates = this.DateValidation.IsValidStartDateEndDate = false;
      }
      else {
        this.DateValidation.IsValidStartDateEndDate = true;
      }
    }
    return isValidWorkstreamDates;
  }

  onChangeDate(date) {
    if (date == "StartDate") {
      this.DateValidation.IsValidStartDate = true;
      this.DateValidation.IsValidStartDateEndDate = true;
    }
    this.DateValidation.IsValidEndDate = true;
    this.DateValidation.IsValidEndDateStartDate = true;
  }

  ResetSelectionCMS(cmswsform: NgForm, event) {
    this.RestoreScrollbar();
    if (event != null) {
      var keycode = event.keyCode;
      if (keycode == 13) { // code for ENTER key
        if (this.cmsHierarchy.TypeId == 1 && this.Action.toLowerCase() == "edit") {
          $('#' + 'CMS' + this.currentSelectedCMS.CMSId).focus();
          $('#cmsAddEdit').modal('hide');
        }
        else if (this.cmsHierarchy.TypeId == 1 && this.Action.toLowerCase() == "add") {
          $('#AddCMSDetails').focus();
          $('#cmsAddEdit').modal('hide');
        }
        else if (this.cmsHierarchy.TypeId == 2 && this.Action.toLowerCase() == "add") {
          $('#AddWSDetails').focus();
          $('#cmsAddEdit').modal('hide');
        }
        else {
          $("#" + 'WS' + this.currentSelectedWS.WorkstreamId).focus();
          $('#cmsAddEdit').modal('hide');
        }
      }
    }
    
    if (cmswsform != null) {
      cmswsform.form.markAsPristine();
    }
    this.IsMultiSelect = false;
    this.bsModalRef.hide();
    $("body").removeClass("hide-parent-scroll");
    this.setAfterSave.emit({ parentActionToPerform: 'Reset', popupAction: this.Action, isFlageUpdated: this.isFlagUpdated });
  }


  IsDisableCMSWSDropdown() {
    if (this.currentSelectedWS) {
      if (this.currentSelectedWS.CMSStatusId == 2) { //CMS Closed
        return this.IsClosedWorkstream();
      }
      else {//CMS Open
        return (this.currentSelectedWS.RowStatusId != 2 && this.currentSelectedWS.WorkstreamStatusName == 'Closed')
      }
    }
    else {
      return false;
    }
  }

  IsClosedWorkstream() {
    if (this.currentSelectedWS) {
      return (this.currentSelectedWS.WorkstreamStatusId == 3 || this.currentSelectedWS.WorkstreamStatusId == 8 ||
        this.currentSelectedWS.WorkstreamStatusId == 10 || this.currentSelectedWS.WorkstreamStatusId == 16);
    }
    else {
      return false;
    }
  }

  ActivateCMSFirstTab() {
    $("#" + this.phoenixMappingContent).removeClass('active');
    $('#' + this.popupCMSDetailsTab).addClass('active');
    $("#tabPhoenixMapping").removeClass('active');
    $("#tabCMSDetails").addClass('active');
  }


  Save(form, formname: NgForm) {
    this.isClientStakeHolderFieldsFilled = true;
    if (this.Validate(form, formname)) {
      if (this.cmsHierarchy.TypeId == 1) {
        if (this.ValidateDuplicate()) {
          if (this.SelectedLifeCycle && this.SelectedLifeCycle.Id == 2) {
            var closedWorkstreams = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSId == this.currentSelectedCMS.CMSId);
            var closedCMS = this.cmsHierarchy.CMSDataList.filter((x: any) => x.SelectedParentId == this.currentSelectedCMS.CMSId && x.CmsStatusId == 2);
            if ((closedWorkstreams && closedWorkstreams.length > 0) || (closedCMS && closedCMS.length > 0)) {
              this.confirmationMessage = "You are trying to close the CMS. CMS will not be visible in the system once all the closed WS under it completes the grace period. Please click on Ok to proceed.";
            }
            else {
              this.confirmationMessage = 'You are trying to close the standalone CMS. CMS will not be visible in the system once updated to "Closed". Please click on Ok to proceed.';
            }
            var confirmationModel = this.sharedService.openConfirmationPopup(this.confirmationMessage, "Ok", "Cancel", window.scrollY);
            this.EnableScrollBar();
            confirmationModel.content.confirmationResult.subscribe(response => {
              if (response.result) {
                this.ConfirmedYes();
              } else {
                this.EnableScrollBar();
              }
            });
            return false;
          }
          else {
            this.SaveCMSAndWorkstream();
          }
          formname.form.markAsPristine();
        }
      }
      else if (this.cmsHierarchy.TypeId == 2 && this.DateValidationLogic() && $('#basicInfoWaterAgileTab').find('.ng-invalid').length < 1) {
        var enterpriseId = $('#peoplePicker_wsDeliveryLead').val();
        if (enterpriseId.length > 0) {
          if ($('#hiddenWSDeliveryLead').val() != enterpriseId) {
            var popUpData = this.sharedService.openAlertPopup('Please enter valid Enterprise ID', window.scrollY);
            popUpData.content.confirmationResult.subscribe((result) => {
              if (result.result) {
                $("body").addClass("hide-parent-scroll");
              }
            })
            return false;
          }
        }        
        if (this.currentSelectedWS.ClientEndToEndId != null) {
          this.SFEdropdownDisable = true;
          this.currentSelectedWS.FactoryWorkstreamIndicator = "Y";
        }
        if (!this.ValidateStartAndEndDate()) {
          return false;
        }
        this.currentSelectedWS.StartDate = $('#startdate').val();
        this.currentSelectedWS.EndDate = $('#enddate').val();

        // *** CMS Hierarchy Control Logic to get selected Value

        var selectedWorkstreamCms = [];
        var objSelectedWorkstreamCms = {};
        this.cmsHierarchy.WorkstreamCMSListLevel1.forEach((cms1: any) => {
          if (cms1.IsSelected) {
            objSelectedWorkstreamCms = cms1;
          }
          else {
            cms1.WorkstreamCMSListLevel2.forEach((cms2: any) => {
              if (cms2.IsSelected) {
                objSelectedWorkstreamCms = cms2;
              }
              else {
                cms2.WorkstreamCMSListLevel3.forEach((cms3: any) => {
                  if (cms3.IsSelected) {
                    objSelectedWorkstreamCms = cms3;
                  }
                  else {
                    cms3.WorkstreamCMSListLevel4.forEach((cms4: any) => {
                      if (cms4.IsSelected) {
                        objSelectedWorkstreamCms = cms4;
                      }
                      else {
                        cms4.WorkstreamCMSListLevel5.forEach((cms5: any) => {
                          if (cms5.IsSelected) {
                            objSelectedWorkstreamCms = cms5;
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
        if (Object.keys(objSelectedWorkstreamCms).length > 0)
          selectedWorkstreamCms.push(objSelectedWorkstreamCms);
        this.currentSelectedWS.SelectedWorkstreamCms = selectedWorkstreamCms;

        var cmsStatus = this.currentSelectedWS.SelectedWorkstreamCms.filter((x: any) => x.CmsStatusId == 0)[0];
        if (this.currentSelectedWS.CMSStatusId == enumCMS.ClosedCMS && !this.IsClosedWorkstream() && (cmsStatus != undefined || this.currentSelectedWS.SelectedWorkstreamCms.length <= 0)) {
          this.IsReset = false;
          this.confirmationMessage = "The workstream being reopened is not associated to an active CMS. If you wish to re-open it as a standalone workstream click Ok else Cancel";
          var confirmationModel = this.sharedService.openConfirmationPopup(this.confirmationMessage, "Ok", "Cancel", window.scrollY);
          this.EnableScrollBar();
          confirmationModel.content.confirmationResult.subscribe(response => {
            if (response.result) {
              this.SaveStatus();
            } else {
              this.EnableScrollBar();
            }
          });
          return false;
        }

        formname.form.markAsPristine();
        this.SaveStatus();
      }
    }
    else {
      if (this.cmsHierarchy.TypeId == 1) {
        $("[name='currentSelectedCMSform']").find('.ng-invalid:visible:first').focus();
      }
      else if (this.cmsHierarchy.TypeId == 2) {
        let invalidField = $("[name='currentSelectedWSform']").find('.ng-invalid:visible:first')[0].id;
        if (invalidField == "WorkstreamTypeSearchDropdown") {
          $('#WorkstreamTypeSearchDropdown .multiselect').focus();
        }
        else {
          $('#' + invalidField).focus();
        }
      }
    }
  }

  SaveCMSAndWorkstream() {
    if (this.cmsHierarchy.TypeId == 1) {
      this.currentSelectedCMS.UserName = this.sessionStorage.enterpriseId;
      this.currentSelectedCMS.LifeCycle = this.SelectedLifeCycle.Id;
      this.currentSelectedCMS.EndToEndType = this.sessionStorage.endToEndType;
      var cms = this.cmsHierarchy.CMSDataList.filter((x: any) => x.CMSId == this.currentSelectedCMS.CMSId)[0];
      this.FormateMultiContractForInsertDelete(this.currentSelectedCMS.Contracts, cms ? cms.Contracts : []);

      if (this.currentSelectedCMS.PhoenixMapping.DeliveryConstructId) {
        this.demographicsService.validatePhoenixMapping(this.sessionStorage.endToEndId, this.currentSelectedCMS.CMSId, this.currentSelectedCMS.PhoenixMapping).subscribe((result: any) => {
          if (result != "" && result != null) {
            this.Message = result;
            this.IsReset = false;
            var popUpData = this.sharedService.openAlertPopup(this.Message, window.scrollY);
            popUpData.content.confirmationResult.subscribe((result) => {
              if (result.result) {
                $("body").addClass("hide-parent-scroll");
              }
            })
          }
          else {
            this.saveCMSDetails();
          }
        });
      }
      else {
        this.saveCMSDetails();
      }
    }
    else if (this.cmsHierarchy.TypeId == 2) {
      this.currentSelectedWS.UserName = this.sessionStorage.enterpriseId;
      this.currentSelectedWS.EndToEndType = this.sessionStorage.endToEndType;
      var workstream = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.WorkstreamId == this.currentSelectedWS.WorkstreamId)[0];
      this.FormateMultiContractForInsertDelete(this.currentSelectedWS.Contracts, workstream ? workstream.Contracts : []);
      var cmsStatus = this.currentSelectedWS.SelectedWorkstreamCms.filter((x: any) => x.CmsStatusId == 0)[0];
      if (this.currentSelectedWS.CMSStatusId == enumCMS.ClosedCMS && !this.IsClosedWorkstream() && cmsStatus != undefined) {
        this.currentSelectedWS.SelectedWorkstreamCms = [];
      }
      this.saveWSDetails();
    }
  }

  ValidateDuplicate() {
    if (this.cmsHierarchy.TypeId == 1) {
      var CMSNames = cloneDeep(this.cmsHierarchy.AllCMSDataList);
      if (CMSNames == null || CMSNames == '')
        return true;
      CMSNames.forEach((cms: any, key) => {
        cms.CMSName = cms.CMSName.replace(new RegExp("'", 'g'), " ");
      });

      var currentCMS = CMSNames.filter((x: any) => x.CMSId == this.currentSelectedCMS.CMSId);
      var selectedCMS = CMSNames.filter((x: any) => x.CMSId != this.currentSelectedCMS.CMSId && x.CMSName.toUpperCase() == this.currentSelectedCMS.CMSName.toUpperCase().replace(new RegExp("'", 'g'), " "));
      var cmsName = currentCMS && currentCMS.length > 0 ? currentCMS[0].CMSName : "";
      var exsistingCMSName = selectedCMS && selectedCMS.length > 0 ? selectedCMS[0].CMSName : "";
      if (selectedCMS && selectedCMS.length > 0 && cmsName != exsistingCMSName) {
        this.confirmationMessage = "CMS name already exists. Please click Ok to continue.";
        var confirmationModel = this.sharedService.openConfirmationPopup(this.confirmationMessage, "Ok", "Cancel", window.scrollY);
        this.EnableScrollBar();
        confirmationModel.content.confirmationResult.subscribe(response => {
          if (response.result) {
            this.ConfirmedYes();
          } else {
            this.EnableScrollBar();
          }
        });
        return false;
      }
      return true;
    }
    else if (this.cmsHierarchy.TypeId == 2) {
      var WSNames = cloneDeep(this.cmsHierarchy.AllWorkstreamDataList);
      if (WSNames == null || WSNames == '')
        return true;
      WSNames.forEach((ws: any, key) => {
        ws.WorkStreamName = ws.WorkStreamName.replace(new RegExp("'", 'g'), " ");
      });

      var selectedWS = WSNames.filter((x: any) => x.WorkstreamId != this.currentSelectedWS.WorkstreamId && x.WorkStreamName.toUpperCase() == this.currentSelectedWS.WorkStreamName.toUpperCase().replace(new RegExp("'", 'g'), " "));
      var currentWS = WSNames.filter((x: any) => x.WorkstreamId == this.currentSelectedWS.WorkstreamId);
      var workstreamName = currentWS && currentWS.length > 0 ? currentWS[0].WorkStreamName : "";
      var exsistingworkstreamName = selectedWS && selectedWS.length > 0 ? selectedWS[0].WorkStreamName : "";
      if (selectedWS && selectedWS.length > 0 && workstreamName != exsistingworkstreamName) {
        this.confirmationMessage = "Workstream name already exists. Please click Ok to continue.";
        var confirmationModel = this.sharedService.openConfirmationPopup(this.confirmationMessage, "Ok", "Cancel", window.scrollY);
        this.EnableScrollBar();
        confirmationModel.content.confirmationResult.subscribe(response => {
          if (response.result) {
            //this.SaveStatus();
            this.ConfirmedYes();
          } else {
            this.EnableScrollBar();
          }
        });
        return false;
      }

      if (this.TempcurrentSelectedWS != null && (this.currentSelectedWS.WorkstreamStatusId == 3 || this.currentSelectedWS.WorkstreamStatusId == 8 ||
        this.currentSelectedWS.WorkstreamStatusId == 10)
        && (this.TempcurrentSelectedWS.WorkstreamStatusId == 3 || this.TempcurrentSelectedWS.WorkstreamStatusId == 8 ||
          this.TempcurrentSelectedWS.WorkstreamStatusId == 10) && this.currentSelectedWS.RowStatusId != 2) {
        return false;
      }
      return true;
    }
  }

  ConfirmedYes() {
    if (this.Action == "ADD" || this.Action == "Edit") {
      this.SaveCMSAndWorkstream();
    }
  }

  SaveStatus() {
    if (this.ValidateDuplicate()) {
      this.SaveCMSAndWorkstream();
      this.IsMultiSelect = false;
    }
  }

  saveCMSDetails() {
    this.CMSMenuService.SaveCMS(this.currentSelectedCMS).subscribe((response) => {
      this.RestoreScrollbar();
      this.IsMultiSelect = false;
      if (this.cmsHierarchy.IsPhoenixMappingEnabled && this.IsAdmin) {
        this.CMSMenuService.savePhoenixConfiguration(response).subscribe((response) => {
          this.bsModalRef.hide();
          $("body").removeClass("hide-parent-scroll");
          this.setAfterSave.emit({
            parentActionToPerform: 'SaveCMS',
            currentSelectedCMS: cloneDeep(this.currentSelectedCMS)
          });
        });
      }
      else {
        this.bsModalRef.hide();
        $("body").removeClass("hide-parent-scroll");
        this.setAfterSave.emit({
          parentActionToPerform: 'SaveCMS',
          currentSelectedCMS: cloneDeep(this.currentSelectedCMS)
        });
      }
    });
  }

  //newly added for workstream Save
  saveWSDetails() {
    this.currentSelectedWS.IsCodeReview = this.currentSelectedWS.IsCodeReview == "null" ? null : this.currentSelectedWS.IsCodeReview;
    this.currentSelectedWS.CodeReviewOutofScopeReasonId = this.currentSelectedWS.CodeReviewOutofScopeReasonId == "null" ? null : this.currentSelectedWS.CodeReviewOutofScopeReasonId;
    this.CMSMenuService.SaveWorkStream(this.currentSelectedWS).subscribe((response: any) => {
      this.IsMultiSelect = false;
      this.RestoreScrollbar();
      this.bsModalRef.hide();
      $("body").removeClass("hide-parent-scroll");
      if (response.Result != "" && response.Result != null)
        this.isFlagUpdated = true;
      this.setAfterSave.emit({
        parentActionToPerform: 'SaveWS',
        popupAction: this.Action,
        isFlageUpdated: this.isFlagUpdated,
        responseMessage: response,
        currentSelectedWS: cloneDeep(this.currentSelectedWS)
      });
    });
  }

  FormateMultiContractForInsertDelete(updatedContractList, oldContractList) {
    var tempUpdatedContractList = cloneDeep(updatedContractList);
    var tempOldContractList = cloneDeep(oldContractList);
    if (tempOldContractList && tempOldContractList.length > 0) {
      if (tempUpdatedContractList && tempUpdatedContractList.length > 0) {
        tempUpdatedContractList.forEach((contract: any, key) => {
          var isExists = tempOldContractList.filter((x: any) => x.ContractCode == contract.ContractCode)[0];
          if (!isExists) {
            contract.Action = "Insert";
          }
        });
        if (tempOldContractList && tempOldContractList.length > 0) {
          tempOldContractList.forEach((contract: any, key) => {
            var isExists = tempUpdatedContractList.filter((x: any) => x.ContractCode == contract.ContractCode)[0];
            if (!isExists) {
              contract.Action = "Delete";
              tempUpdatedContractList.push(contract);
            }
          });
        }
      }
    }
    else {
      if (tempUpdatedContractList && tempUpdatedContractList.length > 0) {
        tempUpdatedContractList.forEach((contract: any, key) => {
          contract.Action = "Insert";
        });
      }
    }
    if (this.cmsHierarchy.TypeId == 1)
      this.currentSelectedCMS.Contracts = (tempUpdatedContractList == undefined || tempUpdatedContractList == null) ? [] : tempUpdatedContractList;
    if (this.cmsHierarchy.TypeId == 2)
      this.currentSelectedWS.Contracts = (tempUpdatedContractList == undefined || tempUpdatedContractList == null) ? [] : tempUpdatedContractList;
  }

  Validate(form, formname) {
    if (!form) {
      if (this.cmsHierarchy.TypeId == 1) {
        for (var field in formname.form.controls) { // 'field' is a string
          var control = formname.form.get(field); // 'control' is a FormControl
          if (field == "phoenixClientId" || field == "deliveryConstructId") {
            if (control.status == "VALID" || control.status == "DISABLED") {
              this.activePhoenix = false;
            }
            else {
              this.activePhoenix = true;
              break;
            }
          }
        }
        for (var field in formname.form.controls) {
          var control = formname.form.get(field); // 'control' is a FormControl
          if (field != "phoenixClientId" && field != "deliveryConstructId") {
            if (control.status == "VALID" || control.status == "DISABLED") {
              this.activeBasic = false;
            }
            else {
              this.activeBasic = true;
              break;
            }
          }
        }

        $("#tabPhoenixMapping").removeClass('tab-highlight-error');
        $("#tabCMSDetails").removeClass('tab-highlight-error');
        if (this.activePhoenix) {
          $("#tabPhoenixMapping").addClass('tab-highlight-error');
        }
        if (this.activeBasic) {
          $("#tabCMSDetails").addClass('tab-highlight-error');
        }
        this.UpdateClass();
      }
      return false;
    }
    if (this.cmsHierarchy.TypeId == 2) {
      if (this.currentSelectedWS.WorkstreamTypeId == null) {
        return false;
      }
      if (this.currentSelectedWS.WorkstreamStatusId == "") {
        return false;
      }
      if (this.currentSelectedWS.WorkStreamName == "" || this.currentSelectedWS.WorkStreamName == undefined || $('#startdate').val() == '' || $('#enddate').val() == '') {
        return false;
      }
      this.currentSelectedWS.StartDate = $('#startdate').val();
      this.currentSelectedWS.EndDate = $('#enddate').val();
      if (!this.validateDateFormat(this.currentSelectedWS.StartDate.toString()))
        return false;
      if (!this.validateDateFormat(this.currentSelectedWS.EndDate.toString()))
        return false;
      this.cmsHierarchy.EndToEndStartDate = this.datePipe.transform(this.cmsHierarchy.EndToEndStartDate, 'dd MMM yyyy');
      this.cmsHierarchy.EndToEndEndDate = this.datePipe.transform(this.cmsHierarchy.EndToEndEndDate, 'dd MMM yyyy');

      var invalidTime = false;
      this.currentSelectedWS.SelectedWorkstreamAvailablityWindow.forEach((ws: any, key) => {
        if (!invalidTime) {
          if (this.currentSelectedWS.SelectedDays.indexOf(ws.WeekdayId) > -1) {
            if (ws.StartTime == null || ws.StartTime == '') {
              var popUpData = this.sharedService.openAlertPopup("Please select shift start time for the selected Days", window.scrollY);
              popUpData.content.confirmationResult.subscribe((result) => {
                if (result.result) {
                  $("body").addClass("hide-parent-scroll");
                }
              })
              invalidTime = true;
            }
            else if (ws.EndTime == null || ws.EndTime == '') {
              var popUpData = this.sharedService.openAlertPopup("Please select shift end time for the selected Days", window.scrollY);
              popUpData.content.confirmationResult.subscribe((result) => {
                if (result.result) {
                  $("body").addClass("hide-parent-scroll");
                }
              })
              invalidTime = true;
            }
            else if (ws.StartTime > ws.EndTime) {
              var popUpData = this.sharedService.openAlertPopup("Please select shift end time greater than the start time", window.scrollY);
              popUpData.content.confirmationResult.subscribe((result) => {
                if (result.result) {
                  $("body").addClass("hide-parent-scroll");
                }
              })
              invalidTime = true;
            }
          }
        }
      });

      if (invalidTime) {
        return false;
      }
    }

    if (this.cmsHierarchy.TypeId == 1) {
      if (!form) {
        return false;
      }
      if ($('#peoplePicker_cmsDeliveryLead').val() != "" && $('#peoplePicker_cmsDeliveryLead').val() != $('#hiddenCMSDeliveryLead').val()) {
        $('#hiddenCMSDeliveryLead').val('');
        var popUpData = this.sharedService.openAlertPopup('Please Enter Valid Enterprise ID', window.scrollY);
        popUpData.content.confirmationResult.subscribe((result) => {
          if (result.result) {
            $("body").addClass("hide-parent-scroll");
          }
        })
        return false;
      }
    }
    return true;
  }

  ValidateStartAndEndDate() {
    var startDate = new Date(this.currentSelectedWS.StartDate);
    var endDate = new Date(this.currentSelectedWS.EndDate);
    if (!(dayjs(this.currentSelectedWS.EndDate, 'DD MMM YYYY').format('DD MMM YYYY') === this.currentSelectedWS.EndDate)) {
      return this.endDateValue = false;
    }
    if (!(dayjs(this.currentSelectedWS.StartDate, 'DD MMM YYYY').format('DD MMM YYYY') === this.currentSelectedWS.StartDate)) {
      return this.startDateValue = false;
    }
    if (startDate > endDate) {
      var popUpData = this.sharedService.openAlertPopup('Start date can not be greater than the End date.', window.scrollY);
      popUpData.content.confirmationResult.subscribe((result) => {
        if (result.result) {
          $("body").addClass("hide-parent-scroll");
        }
      })
      return false;
    }
    else {
      return true;
    }
  }

  OnPaste(event, field) {
    var totalValue: any;
    var pastedValue = '';
    if ((window as any).clipboardData && (window as any).clipboardData.getData) { // IE
      pastedValue = (window as any).clipboardData.getData('Text');
    }
    else if (event.clipboardData && event.clipboardData.getData) { // other browsers
      pastedValue = event.clipboardData.getData('text/plain');
    }
    if (this.ValidationNumeric(pastedValue)) {
      totalValue = pastedValue;
      pastedValue = '';
      if (field == 'BAC') {
        this.currentSelectedWS.BAC = parseFloat(totalValue.substring(0, 16));
      }
    }
    event.stopPropagation();
    event.preventDefault();
  }

  ValidationNumeric(string) {
    var pattern = /^(0|[1-9]\d*)(\.\d+)?$/;
    return pattern.test(string);
  }

  IsCodeReviewUpdated() {
    if (!(this.currentSelectedWS.IsCodeReview == 'N') ) {
      this.currentSelectedWS.CodeReviewOutofScopeReasonId = null;
    }
    // new ux revamp change
    return this.currentSelectedWS.IsCodeReview;
  }

  DeleteWS() {
    if (this.currentSelectedWS != undefined && this.currentSelectedWS != null) {
      this.DeletedWorkstream = [];
      this.cmsHierarchy.TypeId = 2;
      var workstream = {
        WorkstreamId: this.currentSelectedWS.WorkstreamId,
        CMSId: this.currentSelectedWS.CMSId,
        UserName: this.sessionStorage.enterpriseId,
        EndToEndId: this.sessionStorage.endToEndId,
        FactoryWorkstreamIndicator: this.currentSelectedWS.FactoryWorkstreamIndicator
      }
      if (this.currentSelectedWS.FactoryWorkstreamIndicator != "Y") {
        this.DeletedWorkstream.push(workstream);
        this.confirmationMessage = "The workstream you are about to delete will no longer exist. Are you sure you want to proceed?";
        var confirmationModel = this.sharedService.openConfirmationPopup(this.confirmationMessage, "Ok", "Cancel", window.scrollY);
        $("body").css("overflow", "hidden");
        $("modal-container").css("overflow-y", "scroll");
        confirmationModel.content.confirmationResult.subscribe(response => {
          if (response.result) {
            this.RestoreScrollbar();
            this.bsModalRef.hide();
            this.setAfterSave.emit({
              parentActionToPerform: 'DeleteWS',
              popupAction: "Delete",
              DeletedWorkstream: cloneDeep(this.DeletedWorkstream)
            });
          }
        });
      }
      else {
        this.sharedService.openAlertPopup("Factory workstream can not be deleted", window.scrollY);
      }
    }
  }

  lifecyclechange(SelectedLifeCycle) {
    this.SelectedLifeCycle.Id = SelectedLifeCycle;
  }

  AddClientStakeholder() {
    if (this.IsEditable) {
      this.isClientStakeHolderFieldsFilled = true;
      if (this.currentSelectedCMS.CMSClientStakeholders.length == 0) {
        this.currentSelectedCMS.CMSClientStakeholders.push({
          EmailAddress: '',
          Name: '',
          RoleName: '',
          CmsId: this.currentSelectedCMS.CMSId
        });
      }
      else if (this.currentSelectedCMS.CMSClientStakeholders.length > 0) {
        for (var i = 0; i < this.currentSelectedCMS.CMSClientStakeholders.length; i++) {
          if ((this.currentSelectedCMS.CMSClientStakeholders[i].EmailAddress == '' || this.currentSelectedCMS.CMSClientStakeholders[i].EmailAddress == null)&& this.currentSelectedCMS.CMSClientStakeholders[i].Name == '' && this.currentSelectedCMS.CMSClientStakeholders[i].RoleName == '') {
            this.sharedService.openAlertPopup('Highlighted fields has missing values. Please update them before proceeding.', window.scrollY);
            this.isClientStakeHolderFieldsFilled = false;
            this.activeTempIndex = i;
          }
        }
        if (this.isClientStakeHolderFieldsFilled) {
          this.currentSelectedCMS.CMSClientStakeholders.push({
            EmailAddress: '',
            Name: '',
            RoleName: '',
            CmsId: this.currentSelectedCMS.CMSId
          });
        } 
      }

      setTimeout(function test() {
        $('input[name="enterpriseId"]').focus();
        window.TooltipFunc();
      }, 0);
      this.EnableScrollBar();
    }
  }

  EnableScrollBar() {
    $("body").css("overflow", "hidden");
    $("modal-container").css("overflow-y", "scroll");
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  DeleteClientStakeholder(index) {
    this.currentSelectedCMS.CMSClientStakeholders.splice(index, 1)
  }

  KeyPressNumeric(event) {
    var iKeyCode = event.keyCode;
    if (iKeyCode >= 48 && iKeyCode <= 57) {
      return true;
    }
    event.stopPropagation();
    event.preventDefault();
  }

  trackById(index: number, item: any): any {
    return item.Id;
  }
  showModalPopup(fieldName) {
    if (fieldName == 'workstreamType') {
      this.displayWorkstreamTypePopup = true;
    } else if (fieldName == 'associateToCMS') {
      this.displayAssociateWorkstreamPopup = true;
    }
  }
  closeModal(fieldName) {
    if (fieldName == 'workstreamType') {
      this.displayWorkstreamTypePopup = false;
    } else if (fieldName == 'associateToCMS') {
      this.displayAssociateWorkstreamPopup = false;
    }
  }

  validateDateFormat(dateValue) {
    if (dateValue != undefined && dateValue != "" && dateValue != null) {
      if (dateValue.match(/^(([0-9])|([0-2][0-9])|([3][0-1]))\ (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\ \d{4}$/))
        return true;
      else
        return false;
    }
    else {
      return false;
    }
  }
  //ngOnDestroy() {
  //  $('body').removeClass('block-scroll');
  //}
} // end of component
