import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { SimpleGlobal } from 'ng2-simple-global';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
//import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSpinnerModule } from "ngx-spinner"; // Import library module
import { environment } from '../../src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { SessionStorage } from './core/context/sessionstorage';
import { SharedModule } from './shared.module';


// Welcome Components
import { AppComponent } from './app.component';
import { ExternalApplicationLandingComponent } from './core/components/external.application.landing.component';
import { AnnouncementComponent } from './welcome/announcement.component';
import { AnnouncementdialogComponent } from './welcome/announcementdialog.component';
import { ReleaseNoteHistoryComponent } from './welcome/release-note-history.component';
import { WelcomeComponent } from './home/home.component/welcome.component';

// Common Services
import { ApplicationLoggingService } from './core/services/application.logging.service';
import { SharedService } from './core/services/shared.service';
import { UserPreferenceService } from './core/services/user.preference.service';
import { UserService } from './core/services/user.service';
import { HttpService } from './core/services/http.service';
import { AuthInterceptor } from './core/services/interceptor.service';
import { LoaderService } from './core/services/loader.service';
import { TileService } from './core/services/tile.service';
import { ChangesGuard } from './core/services/can.deactivate.service';
import { DownTimeAlertService } from './core/services/downtime.alert.service';
import { UnsavedChangesService } from './core/services/unsaved.changes.service';

// Manage Components
import { AdminComponent } from './home/admin.component/admin.component';
import { HeaderComponent } from './home/header.component/header.component';
import { FooterComponent } from './home/footer.component/footer.component';
import { HomeComponent } from './home/home.component/home.component';
import { ManageComponent } from './home/manage.component/manage.component';
import { ManageLandingComponent } from './home/manage.component/manage.landing.component';
import { PortfolioComponent } from './home/portfolio.component/portfolio.component';
import { ScopeSelectorComponent } from './home/scope.selector.component/scope.selector.component';
import { TaskComponent } from './home/task.component/task.component';
import { TileComponent } from './home/tile.component/tile.component';
import { AmCommandCenterComponent } from './home/tile.types.component/am.command.center.component';
import { ManagementTileComponent } from './home/tile.types.component/management.tile.component';

// Initiate and Configuration Tile Components
import { ChildtileComponent } from './home/child.tile.component/child.tile.component';
import { ClientStructureComponent } from './home/initiate.profile.component/client.structure.component';
import { DeliveryPortfolioComponent } from './home/initiate.profile.component/delivery.portfolio.component';
import { InitiateProfileComponent } from './home/initiate.profile.component/initiate.profile.component';
import { KeyContactsComponent } from './home/initiate.profile.component/keycontacts.component';
import { MatrixStructureComponent } from './home/initiate.profile.component/matrix.structure.component';
import { AlternateContactComponent } from './home/initiate.profile.component/model.popup.component/alternate.contact.component';
import { ReportingPortfolioAddEditComponent } from './home/initiate.profile.component/model.popup.component/reporting.portfolio.add.edit.component';
import { BCMPriorityDescriptionComponent } from './home/initiate.profile.component/model.popup.component/bcm.priority.description.component/bcm.priority.description.component';
import { CMSAddEditComponent } from './home/initiate.profile.component/model.popup.component/cms.add.edit.component';
import { ManageContractsComponent } from './home/initiate.profile.component/model.popup.component/manage.contracts.component';
import { ManageCustomerComponent } from './home/initiate.profile.component/model.popup.component/manage.customer.component';
//import { ManageDemographicsComponent } from './home/initiate.profile.component/model.popup.component/manage.demographics.component';
//import { MetroCitiesComponent } from './home/initiate.profile.component/model.popup.component/metro.cities.component';
import { PhoenixUrlConfigurationComponent } from './home/initiate.profile.component/model.popup.component/phoenix.url.configuration.component/phoenix.url.configuration.component';
//import { PrimaryTechnologyComponent } from './home/initiate.profile.component/model.popup.component/primary.technology.component';
//import { ServiceUsedComponent } from './home/initiate.profile.component/model.popup.component/service.used.component';
//import { SlideShowComponent } from './home/initiate.profile.component/model.popup.component/slide.show.component';
import { TeamAddEditComponent } from './home/initiate.profile.component/model.popup.component/team.add.edit.component';
import { TeamMemberFilterComponent } from './home/initiate.profile.component/model.popup.component/team.members.filter.component';
import { ProfileComponent } from './home/initiate.profile.component/profile.component';
import { ScopeComponent } from './home/initiate.profile.component/scope.component';
import { TeamStructureComponent } from './home/initiate.profile.component/team.structure.component';
import { PlatformAccess } from './home/platform.access.component/platfrom.access.component';

// Management Tile Components
import { AvanadeEACComponent } from './home/avanadeEAC.component/avanadeEAC.component';
import { DEXComponent } from './home/delivery.excellence.component/dex.component';
import { AutomationJourneyComponent } from './home/tile.types.component/automation.journey.component';
import { DeliveryDashboardComponent } from './home/tile.types.component/delivery.dashboard.component';
import { EACForecastComponent } from './home/tile.types.component/eac.forecast.component';
import { IssueComponent } from './home/tile.types.component/issue.component';
import { MilestoneTileComponent } from './home/tile.types.component/milestone.tile.component';
import { ReportingTileComponent } from './home/tile.types.component/reporting.tile.component';
import { RiskTileComponent } from './home/tile.types.component/risk.tile.component';

// Miscellaneous Components
import { AccessDeniedComponent } from './core/components/accessdenied.component/accessdenied.component';
import { AlertPopupComponent } from './core/components/alert.popup.component';
import { ConfirmationPopupComponent } from './core/components/confirmation.popup.component';
import { MultiSelectdDropDownComponent } from './core/components/multiselect.dropdown.component';

//import { DataPrivacyComponent } from './home/data.privacy.component/data.privacy.component';
import { MultiContractDetailComponent } from './home/initiate.profile.component/model.popup.component/multi.contract.details.component';
import { MultiContractInfoComponent } from './home/initiate.profile.component/model.popup.component/multi.contract.info.component';
//import { SitemapComponent } from './home/sitemap.component/sitemap.component';
import { SupportComponent } from './home/support.component/support.component';
//import { TermsUseComponent } from './home/terms.use.component/terms.use.component';
//import { ExternalClientsComponent } from './home/tile.configuration.component/external.clients.component';
//import { TileConfigurationComponent } from './home/tile.configuration.component/tile.configuration.component';
//import { TileInfoComponent } from './home/tile.configuration.component/tile.info.component';
import { ActionManagerComponent } from './home/tile.types.component/action.manager.component';
import { PCAManageComponent } from './home/tile.types.component/pca.manage.component';
import { PCAPortfolioComponent } from './home/tile.types.component/pca.portfolio.component';
import { DownTimeAlertComponent } from './welcome/downtime.alert.component';
import { ReleaseNoteService } from './core/services/release.note.service';
import { SecurityService } from './core/services/security.service';
import { NotificationsAlertsComponent } from './welcome/notifications-alerts.component';
import { MetricSelectionContextComponent } from './home/configure.metrics.component/metricselection.context.component';
import { MetricFilterComponent } from './home/configure.metrics.component/metrics.filter.component';
import { MetricThresholdComponent } from './home/configure.metrics.component/metric.threshold.component';
import { OtherConfigurationSettings } from './home/configure.metrics.component/otherconfiguration.settings.component';
import { DownloadFileComponent } from './core/components/download.file.component/download.file.component';
import { AdminMetricComponent } from './home/configure.metrics.component/metric.add.edit.component';
import { AdminMeasureComponent } from './home/configure.metrics.component/measure.add.component';
import { ManageReleaseNotesModule } from './managereleasenotes.module';
//import { EncryptionService } from './core/services/encrypton.service';

import { MetricEditThresholdComponent } from './home/configure.metrics.component/metric.edit.threshold.component'
import { DemographicsService } from './core/services/demographics.service';
import { ServiceGroupNetworkEditComponent } from './home/initiate.profile.component/model.popup.component/service.group.network.edit.component';
import { MetroCitiesComponent } from './home/initiate.profile.component/model.popup.component/metro.cities.component';
import { DeliveryFunctionsDescriptionComponent } from './home/initiate.profile.component/model.popup.component/delivery.functions.description.component';

import { MetricViewComponent } from './home/configure.metrics.component/metric.view.component';
import { MetricEditTrendComponent } from './home/configure.metrics.component/metric.edit.trend.threshold'
import { MatrixUserGuideComponent } from './home/initiate.profile.component/user.guide/matrix.userguide.component';
import { TMSUserGuideComponent } from './home/initiate.profile.component/user.guide/tms.userguide.component';
import { CMSUserGuideComponent } from './home/initiate.profile.component/user.guide/cms.userguide.component';
import { OtherConfigurationUserGuideComponent } from './home/configure.metrics.component/user.guide/otherconfiguration.userguide.component';
import { ThresholdUserGuideComponent } from './home/configure.metrics.component/user.guide/threshold.userguide.component';
import { MetricsUserGuideComponent } from './home/configure.metrics.component/user.guide/metrics.userguide.component';



const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function initializeApp(): IPublicClientApplication {
  return new PublicClientApplication({
    auth:
    {
      clientId: environment.azureAD.clientId,
      redirectUri: environment.azureAD.redirectUri,
      authority: environment.azureAD.authority,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE
    },
    system:
    {
      loadFrameTimeout: 3600000
    }
  });
}

export function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.azureAD.resourceURL, environment.azureAD.resourceId);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  }
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    //TooltipModule.forRoot(),
    ModalModule.forRoot(),
    NgxSpinnerModule,
    MsalModule,
    SharedModule
  ],
  declarations: [
    AppComponent,
    ExternalApplicationLandingComponent,
    AnnouncementComponent,
    AnnouncementdialogComponent,
    NotificationsAlertsComponent,
    ReleaseNoteHistoryComponent,
    WelcomeComponent,
    AdminComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ManageComponent,
    ManageLandingComponent,
    PortfolioComponent,
    ScopeSelectorComponent,
    TaskComponent,
    TileComponent,
    AmCommandCenterComponent,
    ManagementTileComponent,
    ChildtileComponent,
    ClientStructureComponent,
    CMSUserGuideComponent,
    DeliveryPortfolioComponent,
    InitiateProfileComponent,
    KeyContactsComponent,
    MatrixStructureComponent,
    MatrixUserGuideComponent,
    AlternateContactComponent,
    ReportingPortfolioAddEditComponent,
    ServiceGroupNetworkEditComponent,
    MetroCitiesComponent,
    BCMPriorityDescriptionComponent,
    DeliveryFunctionsDescriptionComponent,
    CMSAddEditComponent,
    AdminMetricComponent,
    AdminMeasureComponent,   
    ManageContractsComponent,
    ManageCustomerComponent,
    //ManageDemographicsComponent,
    //MetroCitiesComponent,
    PhoenixUrlConfigurationComponent,
    //PrimaryTechnologyComponent,
    //ServiceUsedComponent,
    //SlideShowComponent,
    TeamAddEditComponent,
    TeamMemberFilterComponent,
    ProfileComponent,
    ScopeComponent,
    TeamStructureComponent,
    TMSUserGuideComponent,
    PlatformAccess,
    AvanadeEACComponent,
    DEXComponent,
    AutomationJourneyComponent,
    DeliveryDashboardComponent,
    EACForecastComponent,
    IssueComponent,
    MilestoneTileComponent,
    ReportingTileComponent,
    RiskTileComponent,
    AccessDeniedComponent,
    AlertPopupComponent,
    ConfirmationPopupComponent,
   // MultiSelectdDropDownComponent,  
    //DataPrivacyComponent,
    MultiContractDetailComponent,
    MultiContractInfoComponent,
    //SitemapComponent,
    SupportComponent,
    //TermsUseComponent,
    //ExternalClientsComponent,
    //TileConfigurationComponent,
    //TileInfoComponent,
    ActionManagerComponent,
    PCAManageComponent,
    PCAPortfolioComponent,
    DownTimeAlertComponent,
    MetricSelectionContextComponent,
    MetricFilterComponent,
    MetricViewComponent,
    MetricThresholdComponent,
    OtherConfigurationSettings,
    //InfoModalComponent,
    //CMMIComponent,
    //DownTimeAlertComponent,
    //DownloadFileComponent
    MetricEditThresholdComponent,
    MetricEditTrendComponent,
    OtherConfigurationUserGuideComponent,
    ThresholdUserGuideComponent,
    MetricsUserGuideComponent
  ],

  providers: [
    TileService,
    HttpService,
    SessionStorage,
    SimpleGlobal,
    BsModalService,
    BsModalRef,
    Title,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    LoaderService,
    SharedService,
    UserService,
    ApplicationLoggingService,
    UserPreferenceService,
    ChangesGuard,
    UnsavedChangesService,
    DownTimeAlertService,
    ReleaseNoteService,
    SecurityService,
    //EncryptionService,
    DemographicsService,
    ScopeComponent,
    {
      provide: MSAL_INSTANCE,
      useFactory: initializeApp
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    } 

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
