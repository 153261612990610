import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { IAdalConfig } from '../interfaces/adal.config';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class AppConfig {

  public adalDetails: IAdalConfig;
  public loggedInuser: string;
  public appAdalConfig: any;
  httpClient: HttpClient;

  constructor(httpBackend: HttpBackend) {
    this.httpClient = new HttpClient(httpBackend);
  }

  loadAppConfig() {
    let url = environment.name == 'localhost' ? 'assets/config/config.localhost.json' : '/assets/config/config.' + environment.name + '.json';
    return this.httpClient
      .get(url)
      .toPromise()
      .then(data => {
        this.appAdalConfig = data;
      });
  }
  getAdalConfig() {
    return this.appAdalConfig;
  }
}
