<!--<div class="modal-header">
  <button type="button" id="Team_Resource_filter" class="close" data-dismiss="modal" (keypress)="CloseFilter($event)" (click)="CloseFilter()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <h4 class="modal-title">Resource Filter</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xs-12 col-md-12 form-horizontal">
      <div class="form-group">
        <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="CareerLevel" id="CarrerLevelInfo">
          <span class="glyphicon glyphicon-briefcase" style="color:#010c81;"></span>
          Career Level
        </label>
        <div class="col-xs-12 col-sm-7 col-lg-8">
          <search-dropdown id="CareerLevel" name="CareerLevel" [labelInfo]="'CarrerLevelInfo'" [selectText]="'Select'"
                           [options]="teamMemberFilterAttribute.CareerLevel" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                           [selected]="teamMemberFilterAttribute.SelectedCarreerLevel" [(ngModel)]="teamMemberFilterAttribute.SelectedCarreerLevel" [isTeamMemberValue]="true">
          </search-dropdown>
        </div>
      </div>
      <div class="form-group">
        <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="CurrentWorkLocation" id="CurrentWorkLocationInfo">
          <img class="resource-img" alt="" src="assets/images/TMS/Current Work Location.PNG" />
          Current Work Location
        </label>
        <div class="col-xs-12 col-sm-7 col-lg-8">
          <search-dropdown id="CurrentWorkLocation" name="CurrentWorkLocation" [labelInfo]="'CurrentWorkLocationInfo'" [selectText]="'Select'"
                           [options]="teamMemberFilterAttribute.WorkLocation" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                           [selected]="teamMemberFilterAttribute.SelectedWorkLocation" [(ngModel)]="teamMemberFilterAttribute.SelectedWorkLocation" [isTeamMemberValue]="true">
          </search-dropdown>
        </div>
      </div>

      <div class="form-group">

        <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="Technology" id="TechnologyInfo">
          <img class="resource-img" alt="" src="assets/images/TMS/Technology.PNG" />Technology
        </label>
        <div class="col-xs-12 col-sm-7 col-lg-8">
          <search-dropdown id="Technology" name="Technology" [labelInfo]="'TechnologyInfo'" [selectText]="'Select'"
                           [options]="teamMemberFilterAttribute.Technology" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                           [selected]="teamMemberFilterAttribute.SelectedTechnology" [(ngModel)]="teamMemberFilterAttribute.SelectedTechnology" [isTeamMemberValue]="true">
          </search-dropdown>
        </div>
      </div>
      <div class="form-group">

        <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="Skill" id="SkillInfo"><img class="resource-img" alt="" src="assets/images/TMS/Skill.PNG" />Skill</label>

        <div class="col-xs-12 col-sm-7 col-lg-8">
          <search-dropdown id="Skill" name="Skill" [labelInfo]="'SkillInfo'" [selectText]="'Select'"
                           [options]="teamMemberFilterAttribute.Skills" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                           [selected]="teamMemberFilterAttribute.SelectedSkills" [(ngModel)]="teamMemberFilterAttribute.SelectedSkills" [isTeamMemberValue]="true">
          </search-dropdown>
        </div>
      </div>
      <div class="form-group">

        <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="SkillProficiency" id="SkillProficiencyInfo"><img class="resource-img" alt="" src="assets/images/TMS/Skill Proficiency.PNG" />Skill Proficiency</label>
        <div class="col-xs-12 col-sm-7 col-lg-8">
          <search-dropdown id="SkillProficiency" name="SkillProficiency" [labelInfo]="'SkillProficiencyInfo'" [selectText]="'Select'"
                           [options]="teamMemberFilterAttribute.SkillProficiency" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                           [selected]="teamMemberFilterAttribute.SelectedSkillProficiency" [(ngModel)]="teamMemberFilterAttribute.SelectedSkillProficiency" [isTeamMemberValue]="true">
          </search-dropdown>
        </div>
      </div>

      <div class="form-group">
        <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="CareerRole" id="CareerRoleInfo"><img class="resource-img" alt="" src="assets/images/TMS/CareerRole.PNG" />Career Role</label>
        <div class="col-xs-12 col-sm-7 col-lg-8">
          <search-dropdown id="CareerRole" name="CareerRole" [labelInfo]="'CareerRoleInfo'" [selectText]="'Select'"
                           [options]="teamMemberFilterAttribute.CareerRole" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                           [selected]="teamMemberFilterAttribute.SelectedCareerRole" [(ngModel)]="teamMemberFilterAttribute.SelectedCareerRole" [isTeamMemberValue]="true">
          </search-dropdown>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" [disabled]="RoleAccess=='V'" class="btn btn-primary" (click)="GetFilterParams()" data-dismiss="modal" aria-label="Ok">Ok</button>
  <button type="button" [disabled]="RoleAccess=='V'" class="btn btn-primary" (click)="CloseFilter()" data-dismiss="modal" aria-label="Cancel">Cancel</button>
  <button type="button" [disabled]="RoleAccess=='V'" class="btn btn-primary" value="Clear" (click)="ClearSelection()" aria-label="Clear All">Clear All</button>
</div>-->


<div id="editRelease" class="c-flyout c-contract__flyout -open" style="visibility: visible;" aria-modal="true" role="dialog" tabindex="-1" aria-labelledby="flyoutTitle">
  <div class="c-flyout__header -client-header">
    <div class="c-flyout-head__title">
      <div class="-flyoutTitle" id="flyoutTitle">
       Resource Filter
      </div>
    </div>
    <button type="button" class="-dismiss-flyout" (keypress)="CloseFilter($event)" (click)="CloseFilter()" aria-label="Close" alt="Close"></button>
  </div>
  <div class="c-flyout__body -customScroll c-manage-role-flyout">

    <div class="c-manage-role-content">
    
      <div class="c-myWizard-form__input">

        <div class="c-myWizard-form__input-area">
          <div class="c-custom-select__wrapper">
            <label for="CareerLevel">Career Level</label>
            <search-dropdown id="CareerLevel" name="CareerLevel" [labelInfo]="'CarrerLevelInfo'" [selectText]="'Select'"
                             [options]="teamMemberFilterAttribute.CareerLevel" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                             [selected]="teamMemberFilterAttribute.SelectedCarreerLevel" [(ngModel)]="teamMemberFilterAttribute.SelectedCarreerLevel" [isTeamMemberValue]="true">
            </search-dropdown>
          </div>
        </div>

        <div class="c-myWizard-form__input-area">
          <div class="c-custom-select__wrapper">
            <label for="CurrentWorkLocation">Current Work Location</label>
            <search-dropdown id="CurrentWorkLocation" name="CurrentWorkLocation" [labelInfo]="'CurrentWorkLocationInfo'" [selectText]="'Select'"
                             [options]="teamMemberFilterAttribute.WorkLocation" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                             [selected]="teamMemberFilterAttribute.SelectedWorkLocation" [(ngModel)]="teamMemberFilterAttribute.SelectedWorkLocation" [isTeamMemberValue]="true">
            </search-dropdown>
          </div>
        </div>

        <div class="c-myWizard-form__input-area">
          <div class="c-custom-select__wrapper">
            <label for="Technology">Technology</label>
            <search-dropdown id="Technology" name="Technology" [labelInfo]="'TechnologyInfo'" [selectText]="'Select'"
                             [options]="teamMemberFilterAttribute.Technology" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                             [selected]="teamMemberFilterAttribute.SelectedTechnology" [(ngModel)]="teamMemberFilterAttribute.SelectedTechnology" [isTeamMemberValue]="true">
            </search-dropdown>
          </div>
        </div>

        <div class="c-myWizard-form__input-area">
          <div class="c-custom-select__wrapper">
            <label for="Skill">Skill</label>
            <search-dropdown id="Skill" name="Skill" [labelInfo]="'SkillInfo'" [selectText]="'Select'"
                             [options]="teamMemberFilterAttribute.Skills" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                             [selected]="teamMemberFilterAttribute.SelectedSkills" [(ngModel)]="teamMemberFilterAttribute.SelectedSkills" [isTeamMemberValue]="true">
            </search-dropdown>
          </div>
        </div>

        <div class="c-myWizard-form__input-area">
          <div class="c-custom-select__wrapper">
            <label for="SkillProficiency">Skill Proficiency</label>
            <search-dropdown id="SkillProficiency" name="SkillProficiency" [labelInfo]="'SkillProficiencyInfo'" [selectText]="'Select'"
                             [options]="teamMemberFilterAttribute.SkillProficiency" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                             [selected]="teamMemberFilterAttribute.SelectedSkillProficiency" [(ngModel)]="teamMemberFilterAttribute.SelectedSkillProficiency" [isTeamMemberValue]="true">
            </search-dropdown>
          </div>
        </div>

        <div class="c-myWizard-form__input-area">
          <div class="c-custom-select__wrapper">
            <label for="CareerRole">Career Role</label>
            <search-dropdown id="CareerRole" name="CareerRole" [labelInfo]="'CareerRoleInfo'" [selectText]="'Select'"
                             [options]="teamMemberFilterAttribute.CareerRole" [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                             [selected]="teamMemberFilterAttribute.SelectedCareerRole" [(ngModel)]="teamMemberFilterAttribute.SelectedCareerRole" [isTeamMemberValue]="true">
            </search-dropdown>
          </div>
        </div>

      </div>
    </div>

  </div>

  <div class="c-flyout__footer">
    <div class="flyout-tab">
      <div class="-flyout-btn">
        <!--Primary Button starts from here-->
        <a href="javascript:void(0)" [disabled]="RoleAccess=='V'" (click)="GetFilterParams()" role="button" class="o-primary-button  ">
          <!---->
          Apply
        </a>
        <!--Primary Button ends from here-->
        <!--Primary Button starts from here-->
        <!--<a href="javascript:void(0)" [disabled]="RoleAccess=='V'" (click)="CloseFilter()" role="button" class="o-primary-button  ">-->
          <!---->
          <!--Cancel
        </a>-->
        <!--Primary Button ends from here-->                    <!--Primary Button starts from here-->
        <a href="javascript:void(0)" [disabled]="RoleAccess=='V'" (click)="ClearSelection()" role="button" class="o-primary-button  -apply">
          <!---->
          Clear All
        </a>
        <!--Primary Button ends from here-->

      </div>
    </div>
  </div>

</div>
<div class="c-flyout__overlay"></div>
