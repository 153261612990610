<!--<div class="wrapper" style="position:relative;height:auto!important;min-height:100%">
  <div class="wrap">
    <div>
      <header-section></header-section>
      <ngx-spinner template="<img src='assets/images/Tiles/loading.gif' width='64px' height='64px'/>"></ngx-spinner>
      <div id="mainContentHome" class="content-wrap container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
    <footer role="contentinfo" class="footer text-center">
        &copy; 2001-{{date|date:'yyyy'}} Accenture. All Rights Reserved. Accenture Confidential. For Internal Use Only.
        <a class="link-color" href="javascript:void(0);" (click)="openTermsUse()" title="Terms of Use" aria-label="Terms of Use(open a Popup)">Terms of Use</a>&nbsp;|&nbsp;
        <a class="link-color" href="javascript:void(0);" (click)="openDataPrivacy()" title="Data Privacy Statement" aria-label="Data Privacy Statement(opens a Popup)">Data Privacy Statement</a>&nbsp;
        <span *ngIf="isSiteMapVisible">|&nbsp;<a class="link-color" href="javascript:void(0);" (click)="openSitemap()" target="_self" title="SiteMap" aria-label="Sitemap (Open a Popup)">SiteMap</a></span>
    </footer>


  </div>
</div>-->
<div class="app-wrapper app-index">
  <header-section></header-section>
  <ngx-spinner template="<img src='assets/toolkit/images/loading.gif' width='64px' height='64px'/>"></ngx-spinner>
  <div id="mainContentHome" class="app-wrap">
    <router-outlet>
      <down-time-alert applicationCode="mywizard"></down-time-alert>
     </router-outlet>
   
  </div>
  <footer-section></footer-section>
</div>
