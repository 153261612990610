import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BroadCastService {

  private scopeSelectorSource = new BehaviorSubject<any>(null);
  private refreshScopeSelector = new BehaviorSubject<any>(null);
  private refreshScopeTitle = new BehaviorSubject<any>(null);
  private refreshProfileCompletionStatus = new BehaviorSubject<any>(null);
  private teamList = new BehaviorSubject<any>(null);
  private updatedGroupName = new BehaviorSubject<any>(null);

  constructor() {
  }

  getData(): Observable<any> {
    return this.scopeSelectorSource.asObservable();
  }

  broadcastScopeSelector(data) {
    this.scopeSelectorSource.next(data);
  }  
  refreshScopeSelectorData(): Observable<any> {
    return this.refreshScopeSelector.asObservable();
  }
  broadcastRefreshScopeSelectorData(data) {
    this.refreshScopeSelector.next(data);
  }

  refreshScopeSelectorTitle(): Observable<any> {
    return this.refreshScopeTitle.asObservable();
  }
  broadcastRefreshScopeSelectorTitle(data) {
    this.refreshScopeTitle.next(data);
  }

  refreshProfileCompletion(): Observable<any> {
    return this.refreshProfileCompletionStatus.asObservable();
  }
  broadcastProfileCompletionStatus(data) {
    this.refreshProfileCompletionStatus.next(data);
  }
  broadcastTeamList(data) {
    this.teamList.next(data);
  }
  refreshTeamList(): Observable<any> {
    return this.teamList.asObservable();
  }

}
