import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable()

export class ReleaseNoteService {

  constructor(private httpService: HttpService) { }

  getReleases() {
    return this.httpService.get('/api/releasenotes/releases', false);
  }

  getReleaseDetails (releaseNumberId: any, IsCurrentRelease: any, isChangeCriticalId : any) {
    return this.httpService.get('/api/releasenotes/selectedrelease/' + releaseNumberId + '/' + IsCurrentRelease + '?isChangeCriticalId=' + (isChangeCriticalId == undefined || isChangeCriticalId == "" ? null : isChangeCriticalId), false);
  }

  saveReleaseNote (releaseNote: any): Observable<any> {
    return this.httpService.post('/api/releasenotes/save/selectedrelease/', releaseNote, true);
  }

  deleteReleaseNote(releaseNoteId : any) {
    return this.httpService.post('/api/releasenotes/delete/selectedrelease/' + releaseNoteId, true);
  }

  downloadReleaseNotes(releaseNumber: any, elementId: any, fileName: any, type: any, isChangeCriticalId: any) {
    return this.httpService.getFile('/api/release/' + releaseNumber + '/file/' + fileName + '?isChangeCriticalId=' + (isChangeCriticalId == undefined || isChangeCriticalId == "" ? null : isChangeCriticalId), elementId, fileName, type, true);
  } 

}
