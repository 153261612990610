import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DownTimeAlertService } from '../core/services/downtime.alert.service';

@Component({
  selector: 'down-time-alert',
  template: ` <div *ngIf="isDownTimeNotificationAlert" class="c-myWizard__support">
    <div class="app-container">
       <div class="c-myWizard__support-data">
           <img src="../assets/toolkit/images/client-structure/support.svg" alt="Support" role="presentation"/>
           <p  [innerHtml]="downTimeNotificationMessage" ></p>
           </div>
   </div>
 </div>  
  `
})

export class DownTimeAlertComponent implements OnInit {

  @Input() public applicationCode: string;
  isDBDown: boolean;
  isDownTimeNotificationAlert: boolean;
  downTimeNotificationMessage: string;
  currentDate = new Date();
  pipe = new DatePipe('en-IN');

  constructor(private downTimeAlertService: DownTimeAlertService) { }

  ngOnInit() {
    this.downtimeAlert();
  }

  downtimeAlert() {
    this.downTimeAlertService.getDownTimeNotificationAlert(this.applicationCode).subscribe((response) => {
      if (response) {
        if (response != null) {
          var AlertInfo = response;
          this.isDownTimeNotificationAlert = false;
          this.downTimeNotificationMessage = "";
          if (AlertInfo.AlertStatus != null && AlertInfo.DowntimeStartIST != null && AlertInfo.DowntimeEndIST != null) {
            var currentISTDateTime = this.pipe.transform(this.currentDate, 'yyyy-MM-ddTHH:mm:ss', '+0530');
            if (currentISTDateTime >= AlertInfo.DowntimeStartIST && currentISTDateTime <= AlertInfo.DowntimeEndIST && AlertInfo.AlertStatus.toString().toUpperCase() == "Y") {
              this.isDownTimeNotificationAlert = true;
              this.downTimeNotificationMessage = AlertInfo.DowntimeMessage;
            }
          }
        }
      }
    });
  }
}
