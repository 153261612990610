import { ChangeDetectorRef, Component, OnInit, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { SessionStorage } from '../../core/context/sessionstorage';
import { enumAssociationType, enumDeliveryAccountability, enumEntityType, enumFeatureAccess, enumModuleAction, enumSubmissionType, enumTile, enumUserPreference, enumProfileTab } from '../../core/context/enum';
import { UserPreferenceService } from '../../core/services/user.preference.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
//import { SlideShowComponent } from './model.popup.component/slide.show.component';
import { TMSService } from '../../core/services/tms.service';
import cloneDeep from 'lodash/cloneDeep';
import { DatePipe } from '@angular/common';
declare var $: any;
import { DemographicsService } from '../../core/services/demographics.service';
import { ApplicationLoggingService } from '../../core/services/application.logging.service';
import { TeamAddEditComponent } from './model.popup.component/team.add.edit.component';
import { SharedService } from '../../core/services/shared.service';
import { TeamStructureSharedService } from './team.structure.shared.service';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { UnsavedChangesService } from '../../core/services/unsaved.changes.service';
import { InitiateProfileSharedService } from './initiate.profile.shared.service';
import { TileService } from '../../core/services/tile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { HeaderComponent } from '../header.component/header.component';
import { InitiateProfileComponent } from './initiate.profile.component';
import { BroadCastService } from '../../core/services/broadcast.service';
import { environment } from '../../../environments/environment';
var $ = (<any>window).$;
declare var window: any;
@Component({
  templateUrl: './team.structure.component.html',
  providers: [
    TMSService,
    DemographicsService,
    ApplicationLoggingService,
    UserPreferenceService,
    DatePipe,
    SharedService,
    TeamStructureSharedService,
    HeaderComponent
  ]
})

export class TeamStructureComponent implements OnInit {

  @ViewChild('teamstructureform') teamstructureForm: NgForm;
  unsavedSubscription: Subscription;
  unsavedConfirm: boolean = false;
  tmsDetail: any;
  TeamMemberDetails: any;
  tmsHierarchy: any;
  currentSelectedTMS: any;
  tmsGroupDetail: any;
  TempAccountGroups: any[];
  TempDeliveryUnits: any[];
  TempDeliveryGroups: any[];
  confirmationMessage: string;
  teamGroup: any[];
  team: any[];
  ReportingType: string; delete
  workStreamTypeIcon: any;
  teamMemberFilterAttribute: any;
  EndToEndStartDate: string;
  EndToEndEndDate: string;
  DisableAddButton: any = false;
  IsDisableAccType: any = false;
  IsDisableAGDGDU: any = false;
  DisableAddGroupButton: any = false;
  ActiveDropdownDirective: any = false;
  PopUpHeader: any = "";
  PopUpTitle: any = "";
  Action: any = "";
  type: any = "";
  DraggedItems: any = [];
  dragging: any = 0;
  draggdropcount: any = 0;
  tmsRDDA: any = {};
  teamStatus: any = {};
  teamStatusDropDownDisable: any = true;
  IsEditable: any = this.sessionStorage.roleAccessCode == 'V' ? false : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? false : true);
  dragDropMessage: any = "Please save the drag & drop changes to continue";
  Message = this.dragDropMessage;
  isDeleteOnNoButton: any = false;
  IsEditTMS: any = false;
  IsTMSShowModifiedDetails: any = true;
  EndToEndName: any = this.sessionStorage.endToEndName;
  EndToEndType: any = this.sessionStorage.endToEndType;
  EndToEndRefCode: any = this.sessionStorage.endToEndRefCode;
  popupId: any = "tmsAddEdit";
  popupTMSDetailsTab: any = "tmsTeamDetail";
  UserPreferenceDetail: any;
  keyContactTabHighlight: boolean;
  ProfileTabHighlight: boolean;
  IsslideShowChecked: boolean;
  CollapseExpand: boolean = false;
  modalRef_cmstms: BsModalRef;
  modalRef_teamAddEditPopup: BsModalRef;
  trackExpandedElements: any = [];
  toggleCollpaseAndExpand: any = [];
  @Output() InitiateProfileTabSetup = new EventEmitter();
  popupGroupName: any;
  GroupNameSubscription: Subscription;

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedConfirm) {
      if (this.teamstructureForm.form.dirty) {return confirm('Changes you made may not be saved.');
    }
    else { return true; }
    } else {
      return true;
    }
  }

  constructor(
    private tileService: TileService,
    private sessionStorage: SessionStorage,
    private userPreferenceService: UserPreferenceService,
    private modalService: BsModalService,
    private tmsService: TMSService,
    private datepipe: DatePipe,
    private demographicService: DemographicsService,
    private applicationLoggingService: ApplicationLoggingService,
    private ref: ChangeDetectorRef,
    private sharedService: SharedService,
    private initiateProfileSharedService: InitiateProfileSharedService,
    private teamStructureSharedService: TeamStructureSharedService,
    private headerComponent: HeaderComponent,
    private unsavedChangesService: UnsavedChangesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private initiateProfileComponent: InitiateProfileComponent, private broadCastService:
      BroadCastService
  ) {
    this.unsavedSubscription = this.unsavedChangesService.currentMessage.subscribe((data) => {
      if (data == "teamstructure") {
        if (this.teamstructureForm.form.dirty) {
          if (confirm("Changes you made may not be saved.")) {
            this.unsavedConfirm = true;
            this.sessionStorage.isUserAlert = false;
          } else {
            this.sessionStorage.isUserAlert = true;
          }
        }
        else {
          this.sessionStorage.isUserAlert = false;
        }
      }
    });

    //this.GroupNameSubscription = this.broadCastService.getNewGroupName().subscribe((result) => {
    //  if (result != null) {
    //    this.DisableAddButton = result.DisableAddButton;
    //    this.DisableAddGroupButton = result.DisableAddGroupButton;
    //   if (result.updatedGroupName) {
    //      if (this.tmsHierarchy != undefined)
    //        this.teamStructureSharedService.UpdateTMSGroupAndTMS(this, this.trackExpandedElements);
    //   }
    //    if (this.sessionStorage.tmsName && this.sessionStorage.tmsName != this.tmsDetail.TMSName && this.Action == "Edit") {
    //      this.sessionStorage.tmsName = this.tmsDetail.TMSName;
    //    }
    //    this.headerComponent.RefreshTeamScopeSelector();
    //  }
    //});
  }

  ngOnInit() {
    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['MyWizard/Back/Profile'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId } });
      return false;
    }
    this.initiateProfileSharedService.ValidateDemographicTabHighlight(this);
    this.initiateProfileSharedService.errorTabHighlight.subscribe(result => {
      this.InitiateProfileTabSetup.emit({ keyContactTabHighlight: this.keyContactTabHighlight, ProfileTabHighlight: this.ProfileTabHighlight, tabId: enumProfileTab.TeamStructure });
    });
    this.GetTMSHierarchy(true);
    setTimeout(() => {
      window.TooltipFunc();
      window.collapseComponent();
      //window.twoSideToggle();
    }, 500)
  }

  ngOnDestroy() {
    this.unsavedSubscription.unsubscribe();
  }

  OnBackClick() {
    this.initiateProfileComponent.OnBackClick();
  }

  DateValidation: any = {
    IsValidStartDate: true,
    IsValidStartDateEndDate: true,
    IsValidEndDate: true,
    IsValidEndDateStartDate: true,
  }

  ExpandTeamStructureList(element, elementId) {
    var parentnode = element.currentTarget.closest('.c-list')
    if (parentnode) {
      if (parentnode.classList.contains('expand')) {
        var close = parentnode.closest('li').querySelectorAll('.c-list');
        close.forEach((ele) => {
          if (ele.classList.contains('expand')) {
            ele.classList.remove('expand');
            ele.nextSibling.classList.remove('open');
            var index = this.trackExpandedElements.findIndex(x => x == elementId);
            if (index > -1)
              this.trackExpandedElements.splice(index, 1);
            else {
              var innerIndex = this.trackExpandedElements.findIndex(x => x == ele.closest('li').dataset.tmsgroupid.toString());
              this.trackExpandedElements.splice(innerIndex, 1);
            }
            if (this.toggleCollpaseAndExpand.sort().toString() !== this.trackExpandedElements.sort().toString()) {
              var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
              toggleState.forEach(e => {
                $(e).prop('checked', false);
                this.CollapseExpand = false;
              })
              this.ref.detectChanges();
            }
          }
        })
      }
      else {
        parentnode.classList.add('expand');
        parentnode.nextSibling.classList.add('open');
        var index = this.trackExpandedElements.findIndex(x => x == elementId.toString())
        if(index == -1)
          this.trackExpandedElements.push(elementId.toString());

        this.toggleCollpaseAndExpand = [];
        var allElements = document.querySelectorAll('li');
        allElements.forEach(ele => {
          var childElement = ele.querySelector('.c-list-child');
          if (childElement != null) {
            var expandId = childElement.closest('li');
            if (expandId.dataset.tmsgroupid != undefined) {
              var allChildIndex = this.toggleCollpaseAndExpand.indexOf(expandId.dataset.tmsgroupid.toString());
              if (allChildIndex == -1)
                this.toggleCollpaseAndExpand.push(expandId.dataset.tmsgroupid.toString());
            }
          }
        })

        if (this.toggleCollpaseAndExpand.sort().toString() === this.trackExpandedElements.sort().toString()) {
          var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
          toggleState.forEach(e => {
            $(e).prop('checked', true);
            this.CollapseExpand = true;
          })
          this.ref.detectChanges();
        }

      }
    }
  }


  GetTMSHierarchy(isPageReload: boolean) {
    this.tmsService.getTeamsByEndToEnd().subscribe((response: any) => {
      this.tmsHierarchy = response;
      setTimeout(function () {
        window.TooltipFunc();
      }, 0);
      if (this.tmsHierarchy.TMSGroups.length == 0 && this.tmsHierarchy.Teams.length == 0) {
        this.IsTMSShowModifiedDetails = false;
      }
      this.teamStructureSharedService.UpdateModel(this);
      if (this.IsEditable) {
        //get this values before going to plugin
        var myObj = this;
        $('.cms-tms-list').nestedSortable({
          forcePlaceholderSize: true, handle: 'div.c-list-moveable', helper: 'clone', items: 'li', placeholder: 'cms-tms-item-placeholder',
          revert: 250, tabSize: 50, tolerance: 'pointer', toleranceElement: '> div', isTree: false, listType: 'ul',
          leafClass: 'cms-tms-item-leaf', disableNestingClass: 'cms-tms-item-no-nesting', protectRoot: false, maxLevels: 5,
          sort: function (event, ui) {
            myObj.dragging = 1;
          },
          change: function (event, ui) {
            myObj.dragging = 1;
          },
          stop: function (event, ui) {
            myObj.dragging = 0;
            if (myObj.draggdropcount >= 3) {
              myObj.ShowAlert(myObj.dragDropMessage);
              return false;
            }
            var draggedItemType = ui.item[0].firstElementChild.dataset.type;
            if (draggedItemType == "Group") {
              var draggedTMSGroupId = ui.item[0].dataset.tmsgroupid;
              var droppedTMSGroupId = ui.item[0].parentNode.parentNode.dataset.tmsgroupid;
              var draggedItem = ui.item[0].dataset.item;
              var droppedItem = ui.item[0].parentNode.parentNode.dataset.item;
              var result = myObj.DropTMSGroup(draggedTMSGroupId, droppedTMSGroupId, draggedItem, droppedItem, ui);
              if (result) {
                if (droppedTMSGroupId != undefined) {
                  var indexOfElements = myObj.trackExpandedElements.findIndex(x => x == droppedTMSGroupId);
                  if (indexOfElements == -1)
                    myObj.trackExpandedElements.push(droppedTMSGroupId);
                  var indexOfAllElements = myObj.toggleCollpaseAndExpand.findIndex(x => x == droppedTMSGroupId);
                  if (indexOfAllElements == -1)
                    myObj.toggleCollpaseAndExpand.push(droppedTMSGroupId);
                  if (myObj.trackExpandedElements.length > 0)
                    myObj.expandParent(myObj.trackExpandedElements);
                }
                else {
                  myObj.toggleCollpaseAndExpand = [];
                  var allElements = document.querySelectorAll('li');
                  allElements.forEach(ele => {
                    var childElement = ele.querySelector('.c-list-child');
                    if (childElement != null) {
                      var expandId = childElement.closest('li');
                      myObj.toggleCollpaseAndExpand.push(expandId.dataset.tmsgroupid.toString());
                    }
                  })
                  if (myObj.toggleCollpaseAndExpand.length == 0) {
                    myObj.trackExpandedElements = [];
                    var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
                    toggleState.forEach(e => {
                      $(e).prop('checked', false);
                      myObj.CollapseExpand = false;
                    })
                    myObj.ref.detectChanges();
                  }
                  else {
                    if (myObj.trackExpandedElements.length > 0) {
                      var elementToDelete = [];
                      myObj.trackExpandedElements.forEach(e => {
                        if (myObj.toggleCollpaseAndExpand.indexOf(e.toString()) == -1) {
                          elementToDelete.push(e.toString());
                        }
                      });
                      if (elementToDelete.length > 0) {
                        elementToDelete.forEach(x => {
                          var indexToDelete = myObj.trackExpandedElements.indexOf(x.toString());
                          if (indexToDelete > -1) {
                            myObj.trackExpandedElements.splice(indexToDelete, 1);
                            myObj.expandParent(myObj.trackExpandedElements);
                          }
                        })
                      }
                      else {
                        myObj.expandParent(myObj.trackExpandedElements);
                      }
                    }
                    else {
                      var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
                      toggleState.forEach(e => {
                        $(e).prop('checked', false);
                        myObj.CollapseExpand = false;
                      })
                      myObj.ref.detectChanges();
                    }

                  }
                }
              }
              return result;
            }
            else {
              var draggedTMSId = ui.item[0].dataset.tmsid;
              var droppedTMSId = ui.item[0].parentNode.parentNode.dataset.tmsgroupid;
              var draggedItem = ui.item[0].dataset.item;
              var droppedItem = ui.item[0].parentNode.parentNode.dataset.item;
              var result = myObj.DropTMS(draggedTMSId, droppedTMSId, draggedItem, droppedItem);
              if (result) {
                if (droppedTMSId != undefined) {
                  var indexOfElements = myObj.trackExpandedElements.findIndex(x => x == droppedTMSId);
                  if (indexOfElements == -1)
                    myObj.trackExpandedElements.push(droppedTMSId);
                  var indexOfAllElements = myObj.toggleCollpaseAndExpand.findIndex(x => x == droppedTMSId);
                  if (indexOfAllElements == -1)
                    myObj.toggleCollpaseAndExpand.push(droppedTMSId);
                  if (myObj.trackExpandedElements.length > 0)
                    myObj.expandParent(myObj.trackExpandedElements);
                }
                else {
                  myObj.toggleCollpaseAndExpand = [];
                  var allElements = document.querySelectorAll('li');
                  allElements.forEach(ele => {
                    var childElement = ele.querySelector('.c-list-child');
                    if (childElement != null) {
                      var expandId = childElement.closest('li');
                      myObj.toggleCollpaseAndExpand.push(expandId.dataset.tmsgroupid.toString());
                    }
                  })
                  if (myObj.toggleCollpaseAndExpand.length == 0) {
                    myObj.trackExpandedElements = [];
                    var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
                    toggleState.forEach(e => {
                      $(e).prop('checked', false);
                      myObj.CollapseExpand = false;
                    })
                    myObj.ref.detectChanges();
                  }
                  else {
                    if (myObj.trackExpandedElements.length > 0) {
                      var elementToDelete = [];
                      myObj.trackExpandedElements.forEach(e => {
                        if (myObj.toggleCollpaseAndExpand.indexOf(e.toString()) == -1) {
                          elementToDelete.push(e.toString());
                        }
                      });
                      if (elementToDelete.length > 0) {
                        elementToDelete.forEach(x => {
                          var indexToDelete = myObj.trackExpandedElements.indexOf(x.toString());
                          if (indexToDelete > -1) {
                            myObj.trackExpandedElements.splice(indexToDelete, 1);
                            myObj.expandParent(myObj.trackExpandedElements);
                          }
                        })
                      }
                      else {
                        myObj.expandParent(myObj.trackExpandedElements);
                      }
                    }
                    else {
                      var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
                      toggleState.forEach(e => {
                        $(e).prop('checked', false);
                        myObj.CollapseExpand = false;
                      })
                      myObj.ref.detectChanges();
                    }

                  }
                }
              }
              return result;
            }
          }
        });

        this.dragging = myObj.dragging;
        this.toggleCollpaseAndExpand = [];
        var allElements = document.querySelectorAll('li');
        allElements.forEach(ele => {
          var childElement = ele.querySelector('.c-list-child');
          if (childElement != null) {
            var expandId = childElement.closest('li');
            var allChildIndex = this.toggleCollpaseAndExpand.indexOf(expandId.dataset.tmsgroupid.toString());
            if (allChildIndex == -1)
              this.toggleCollpaseAndExpand.push(expandId.dataset.tmsgroupid.toString());
          }
        })
          this.trackExpandedElements = [];
          var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
          toggleState.forEach(e => {
            $(e).prop('checked', false);
            this.CollapseExpand = false;
          })
          this.ref.detectChanges();
      }
      window.scrollTo(0, $('.dynamic-content').offsetTop);

    });
    this.UsageLogging(enumModuleAction.Tile, enumTile.InitiateProfile, enumFeatureAccess.TeamStructure);
  }

  DropTMSGroup(draggedTMSGroupId: any, droppedTMSGroupId: any, draggedItem: any, droppedItem: any, ui: any) {
    if (!draggedTMSGroupId) {
      return false;
    }
    var teamGroup = this.tryJsonParse(draggedItem);
    let droppedItemJson = this.tryJsonParse(droppedItem);
    droppedTMSGroupId = droppedTMSGroupId == null || droppedTMSGroupId == undefined ? null : droppedTMSGroupId;
    var teamGroupDrop = droppedItem != undefined ? droppedItemJson : droppedItem;
    if (!droppedTMSGroupId && teamGroup.Tier == 1) { //Restrict to drag drop from first level to first level
      return false;
    }
    if (teamGroupDrop && teamGroupDrop.Tier == 5) { //Restrict team group upto five level
      return false;
    }
    if (teamGroup.ParentTMSId == droppedTMSGroupId) {//Restrict movement count for invalid move
      return false;
    }
    if (!this.ValidateFiveLevel(draggedItem, droppedItem)) {
      return false;
    }
    var draggedTeamGroup = this.DraggedItems.filter((x: any) => x.TMSGroupId == draggedTMSGroupId);
    if (draggedTeamGroup == null || draggedTeamGroup == undefined || draggedTeamGroup.length <= 0) {
      draggedItem = {
        TMSGroupId: draggedTMSGroupId,
        TMSGroupParentId: droppedTMSGroupId,
        IsGroup: true,
        EndToEndId: this.sessionStorage.endToEndId,
        UserName: this.sessionStorage.enterpriseId
      }
      this.DraggedItems.push(draggedItem);
      this.draggdropcount = this.draggdropcount + 1;
    }
    else {
      draggedTeamGroup[0].TMSGroupParentId = droppedTMSGroupId;
      this.draggdropcount = this.draggdropcount + 1;
    }
    var selectedTMSGroups = this.tmsHierarchy.TMSGroups.filter((x: any) => x.TMSGroupId == parseInt(draggedTMSGroupId));
    if (selectedTMSGroups && selectedTMSGroups.length > 0) {
      selectedTMSGroups[0].ParentTMSId = droppedTMSGroupId == null ? null : parseInt(droppedTMSGroupId);
    }
    this.removeElement();
    this.teamStructureSharedService.UpdateModel(this);
    this.teamstructureForm.form.markAsDirty();
    return true;
  }

  DropTMS(draggedTMSId: any, droppedTMSId: any, draggedItem: any, droppedItem: any) {
    if (!draggedTMSId) {
      return false;
    }
    var team = this.tryJsonParse(draggedItem);
    droppedTMSId = droppedTMSId == null || droppedTMSId == undefined ? null : droppedTMSId;
    if (droppedTMSId == team.ParentTMSId) { //Restrict movement count for invalid move
      return false;
    }
    var draggedTeam = this.DraggedItems.filter((x: any) => x.TMSId == draggedTMSId);
    if (draggedTeam == null || draggedTeam == undefined || draggedTeam.length <= 0) {
      draggedItem = {
        TMSId: draggedTMSId,
        TMSParentId: droppedTMSId,
        IsGroup: false,
        EndToEndId: this.sessionStorage.endToEndId,
        UserName: this.sessionStorage.enterpriseId
      }
      this.DraggedItems.push(draggedItem);
      this.draggdropcount = this.draggdropcount + 1;
      team.ParentTMSId = droppedTMSId;
    }
    else {
      draggedTeam[0].TMSParentId = droppedTMSId;
      this.draggdropcount = this.draggdropcount + 1;
      team.ParentTMSId = droppedTMSId;
    }
    var selectedTMS = this.tmsHierarchy.Teams.filter((x: any) => x.TMSId == parseInt(draggedTMSId));
    if (selectedTMS && selectedTMS.length > 0) {
      selectedTMS[0].ParentTMSId = droppedTMSId == null ? null : parseInt(droppedTMSId);
    }
    this.removeElement();
    this.teamStructureSharedService.UpdateModel(this);
    
    this.teamstructureForm.form.markAsDirty();

    return true;
  }

  removeElement() {
    var elements = $('.ui-sortable li');
    if (elements.length > 0) {
      elements.toArray().forEach((el: any) => {
        $(el).remove();
      });
    }
  }

  SaveDragAndDrop() {
    if (this.DraggedItems && this.DraggedItems.length > 0) {
      this.tmsService.saveDraggedTeam(this.DraggedItems).subscribe((response: any) => {
        this.teamStructureSharedService.UpdateTMSGroupAndTMS(this, this.trackExpandedElements);
        this.Message = "Saved successfully";
        this.ShowAlert(this.Message);
        setTimeout(function () {
          $('.close').focus();
        });
        this.ProcessDemographicAttribute(enumEntityType.EndToEnd + "|" + enumEntityType.TMS, null);
      });
    }
    this.teamstructureForm.form.markAsPristine();
  }

  ResetPage() {
    var elements = $('.ui-sortable li');
    elements.toArray().forEach((el: any) => {
      $(el).remove();
    });
    this.DraggedItems = [];
    this.draggdropcount = 0;
    this.ActiveDropdownDirective = false;
    this.DisableAddButton = false;
    this.DisableAddGroupButton = false;
    this.currentSelectedTMS = null;
    this.dragging = 0;
    this.GetTMSHierarchy(false);
    this.ResetSelection();
  }

  CancelDragAndDrop() {
    if (this.DraggedItems && this.DraggedItems.length > 0) {
      this.ResetPage();
    }
    this.teamstructureForm.form.markAsPristine();
  }

  SelectTMSGroup(tms) {
    if (this.dragging) {
      return false;
    }
    this.ResetSelection();
    tms.IsChildCollapsed = !tms.IsChildCollapsed;
    this.currentSelectedTMS = cloneDeep(tms);
    if (tms.IsChildCollapsed) {
      this.currentSelectedTMS = null;
    }
    else {
      tms.IsSelected = true;
    }
    if (this.currentSelectedTMS && this.currentSelectedTMS.Tier == 5 && tms.IsSelected) {
      this.DisableAddGroupButton = true;
    }
    else {
      this.DisableAddGroupButton = false;
    }
    this.DisableAddButton = false;
    this.ActiveDropdownDirective = false;
    this.type = "Group";
    var selectedTMSGroup = this.tmsHierarchy.TMSGroups.filter((x: any) => x.TMSGroupId == tms.TMSGroupId);
    if (selectedTMSGroup && selectedTMSGroup.length > 0) {
      selectedTMSGroup[0].IsChildCollapsed = tms.IsChildCollapsed;
      selectedTMSGroup[0].IsSelected = tms.IsSelected
    }
  }

  SelectTMS(tms) {
    if (this.dragging) {
      return false;
    }
    var isSelected = tms.IsSelected;
    this.ResetSelection();
    tms.IsSelected = !isSelected;
    tms.IsChildCollapsed = !tms.IsChildCollapsed;
    this.currentSelectedTMS = cloneDeep(tms);
    this.ActiveDropdownDirective = false;
    this.type = "TMS";
    if (tms.IsSelected == false) {
      this.currentSelectedTMS = null;
      this.DisableAddButton = false;
      this.DisableAddGroupButton = false;
    }
    else {
      this.DisableAddButton = true;
      this.DisableAddGroupButton = true;
    }
    var selectedTMS = this.tmsHierarchy.Teams.filter((x: any) => x.TMSId == tms.TMSId);
    if (selectedTMS && selectedTMS.length > 0) {
      selectedTMS[0].IsChildCollapsed = tms.IsChildCollapsed;
      selectedTMS[0].IsSelected = tms.IsSelected;
    }
  }

  EditTMS(tmsGroup, tms) {
    this.DateValidation.IsValidStartDate = true;
    this.DateValidation.IsValidEndDate = true;
    this.DateValidation.IsValidEndDateStartDate = true;
    this.DateValidation.IsValidStartDateEndDate = true;
    this.teamStatus.Id = "1";
    this.teamStatusDropDownDisable = false;
    this.IsEditTMS = false;
    this.ActiveDropdownDirective = false;
    if (this.DraggedItems && this.DraggedItems.length > 0) {
      this.ShowAlert(this.dragDropMessage);
      return false;
    }

    this.Action = "Edit";
    setTimeout(function () {
      $('.close').focus();
    });
    this.ResetSelection();
    if (tmsGroup) {
      this.tmsHierarchy.Type = this.type = "Group";
      this.currentSelectedTMS = tmsGroup;
      tmsGroup.IsSelected = true;
      this.Action = 'Edit Group';
      this.tmsGroupDetail = cloneDeep(this.currentSelectedTMS);
      this.PopUpHeader = "Edit Group Name";
      this.popupGroupName = this.tmsGroupDetail.TMSGroupName
      this.DisableAddButton = false;
      this.DisableAddGroupButton = false;
      var selectedTMSGroup = this.tmsHierarchy.TMSGroups.filter((x: any) => x.TMSGroupId == tmsGroup.TMSGroupId);
      if (selectedTMSGroup && selectedTMSGroup.length > 0) {
        selectedTMSGroup[0].IsSelected = tmsGroup.IsSelected
      }

      //
      this.ActivateFirstTab();
      $("#teamLead").removeClass('input-blank-error');

      setTimeout(function () {
        this.ActiveDropdownDirective = true;
        $('.close').focus();
      });
      if (!$('#' + this.popupId).hasClass('in')) {
        $('#' + this.popupId + ' .modal-dialog').draggable({ containment: 'parent' });
        this.IsEditTMS = true;
        const initialState = {
          DateValidation: this.DateValidation, tmsHierarchy: this.tmsHierarchy, teamStatus: this.teamStatus, teamStatusDropDownDisable: this.teamStatusDropDownDisable,
          IsEditTMS: this.IsEditTMS, Action: this.Action, tmsGroupDetail: this.tmsGroupDetail, tmsDetail: this.tmsGroupDetail, TempAccountGroups: this.TempAccountGroups,
          TempDeliveryUnits: this.TempDeliveryUnits, TempDeliveryGroups: this.TempDeliveryGroups, IsDisableAccType: this.IsDisableAccType, IsDisableAGDGDU: this.IsDisableAGDGDU,
          EndToEndRefCode: this.EndToEndRefCode, EndToEndName: this.EndToEndName, EndToEndType: this.EndToEndType, EndToEndStartDate: this.EndToEndStartDate,
          EndToEndEndDate: this.EndToEndEndDate, popupTMSDetailsTab: this.popupTMSDetailsTab, IsEditable: this.IsEditable, ActiveDropdownDirective: this.ActiveDropdownDirective, PopUpHeader: this.PopUpHeader, popupGroupName: this.popupGroupName, scrollYPosition: window.scrollY
        };
        this.modalRef_teamAddEditPopup = this.modalService.show(TeamAddEditComponent, {
          initialState,
          backdrop: "static",
          keyboard: false
        });
      
      }
    }
    else {
      this.tmsHierarchy.Type = this.type = "TMS";
      this.currentSelectedTMS = tms;
      tms.IsSelected = true;
      this.tmsDetail = cloneDeep(this.currentSelectedTMS);
      this.PopUpHeader = "Edit : " + this.tmsDetail.TMSName;
      this.DeliveryAccountabilityType();
      this.tmsDetail.SelectedEndToEndTeamMember = [];
      this.tmsDetail.SelectedTeamMember = [];
      this.tmsDetail.searchText = "";
      this.DisableAddButton = true;
      this.DisableAddGroupButton = true;
      this.tmsDetail.StartDate = this.datepipe.transform(this.tmsDetail.StartDate, 'dd MMM yyyy');
      this.tmsDetail.EndDate = this.datepipe.transform(this.tmsDetail.EndDate, 'dd MMM yyyy');
      this.tmsDetail.MMEWMUId = "";
      $('#hiddenTeamLead').val(this.tmsDetail.TMSLead);
      var selectedTMS = this.tmsHierarchy.Teams.filter((x: any) => x.TMSId == tms.TMSId);
      if (selectedTMS && selectedTMS.length > 0) {
        selectedTMS[0].IsSelected = tms.IsSelected;
      }
      this.TeamDateValidation();
      this.UpdateCMSModel(this.tmsDetail.CMSDetails, this.tmsDetail.WorkStreamDetails);
      this.FilterAGListData();
      this.FilterDUListData();
      this.FilterDGListData();


      this.ActivateFirstTab();
      $("#teamLead").removeClass('input-blank-error');

      setTimeout(function () {
        this.ActiveDropdownDirective = true;
        $('.close').focus();
      });
      if (!$('#' + this.popupId).hasClass('in')) {
        $('#' + this.popupId + ' .modal-dialog').draggable({ containment: 'parent' });
        this.IsEditTMS = true;
        const initialState = {
          DateValidation: this.DateValidation, tmsHierarchy: this.tmsHierarchy, teamStatus: this.teamStatus, teamStatusDropDownDisable: this.teamStatusDropDownDisable,
          IsEditTMS: this.IsEditTMS, Action: this.Action, tmsGroupDetail: this.tmsGroupDetail, tmsDetail: this.tmsDetail, TempAccountGroups: this.TempAccountGroups,
          TempDeliveryUnits: this.TempDeliveryUnits, TempDeliveryGroups: this.TempDeliveryGroups, IsDisableAccType: this.IsDisableAccType, IsDisableAGDGDU: this.IsDisableAGDGDU,
          EndToEndRefCode: this.EndToEndRefCode, EndToEndName: this.EndToEndName, EndToEndType: this.EndToEndType, EndToEndStartDate: this.EndToEndStartDate,
          EndToEndEndDate: this.EndToEndEndDate, popupTMSDetailsTab: this.popupTMSDetailsTab, IsEditable: this.IsEditable, ActiveDropdownDirective: this.ActiveDropdownDirective, PopUpHeader: this.PopUpHeader, popupGroupName: this.popupGroupName, scrollYPosition: window.scrollY
        };
        this.modalRef_teamAddEditPopup = this.modalService.show(TeamAddEditComponent, {
          initialState,
          backdrop: "static",
          keyboard: false
        });
        //this.modalRef_teamAddEditPopup.content.teamAddEditPopupData.subscribe(result => {
        //  this.DisableAddButton = result.DisableAddButton;
        //  this.DisableAddGroupButton = result.DisableAddGroupButton;
        //  if (result.isSaveButtonClick) {
        //    this.teamStructureSharedService.UpdateTMSGroupAndTMS(this, this.trackExpandedElements);
        //    this.toggleCollpaseAndExpand = [];
        //    var allElements = document.querySelectorAll('li');
        //    allElements.forEach(ele => {
        //      var childElement = ele.querySelector('.c-list-child');
        //      if (childElement != null) {
        //        var expandId = childElement.closest('li');
        //        if (expandId.dataset.tmsgroupid != undefined) {
        //          var allChildIndex = this.toggleCollpaseAndExpand.indexOf(expandId.dataset.tmsgroupid.toString());
        //          if (allChildIndex == -1)
        //            this.toggleCollpaseAndExpand.push(expandId.dataset.tmsgroupid.toString());
        //        }
        //      }
        //    })
        //    this.trackExpandedElements = [];
        //    var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
        //    toggleState.forEach(e => {
        //      $(e).prop('checked', false);
        //      this.CollapseExpand = false;
        //    })
        //    var child = document.querySelectorAll(".c-list");
        //    child.forEach((ele) => {
        //      if (ele.classList.contains('expand')) {
        //        ele.classList.remove('expand');
        //        ele.nextElementSibling.classList.remove('open');
        //      }
        //    })
        //    this.ref.detectChanges();
           
        //  }
        //  if (this.sessionStorage.tmsName && this.sessionStorage.tmsName != this.tmsDetail.TMSName && this.Action == "Edit") {
        //    this.sessionStorage.tmsName = this.tmsDetail.TMSName;
        //  }
        //  this.headerComponent.RefreshTeamScopeSelector();
        //});
      }
    }
    this.modalRef_teamAddEditPopup.content.teamAddEditPopupData.subscribe(result => {
      this.DisableAddButton = result.DisableAddButton;
      this.DisableAddGroupButton = result.DisableAddGroupButton;
      if (result.isSaveButtonClick) {
        this.teamStructureSharedService.UpdateTMSGroupAndTMS(this, this.trackExpandedElements);
        this.toggleCollpaseAndExpand = [];
        var allElements = document.querySelectorAll('li');
        allElements.forEach(ele => {
          var childElement = ele.querySelector('.c-list-child');
          if (childElement != null) {
            var expandId = childElement.closest('li');
            if (expandId.dataset.tmsgroupid != undefined) {
              var allChildIndex = this.toggleCollpaseAndExpand.indexOf(expandId.dataset.tmsgroupid.toString());
              if (allChildIndex == -1)
                this.toggleCollpaseAndExpand.push(expandId.dataset.tmsgroupid.toString());
            }
          }
        })
        this.trackExpandedElements = [];
        var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
        toggleState.forEach(e => {
          $(e).prop('checked', false);
          this.CollapseExpand = false;
        })
        var child = document.querySelectorAll(".c-list");
        child.forEach((ele) => {
          if (ele.classList.contains('expand')) {
            ele.classList.remove('expand');
            ele.nextElementSibling.classList.remove('open');
          }
        })
        this.ref.detectChanges();

      }
      if (this.sessionStorage.tmsName && this.sessionStorage.tmsName != this.tmsDetail.TMSName && this.Action == "Edit") {
        this.sessionStorage.tmsName = this.tmsDetail.TMSName;
      }
      this.headerComponent.RefreshTeamScopeSelector();
    });
  }
  ShowAlert(message) {
    if (!$('#tms_alertPopUp').hasClass('in')) {
      this.Message = message;
      this.sharedService.openAlertPopup(this.Message, window.scrollY, "Ok")
    }
  }

  DeliveryAccountabilityType() {
    this.IsDisableAccType = false;
    if (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureOG) {
      this.tmsHierarchy.DeliveryAccountabilityTypes = cloneDeep(this.tmsHierarchy.ManagementClientGroups);
      this.tmsDetail.SelectedDeliveryAccountabilityType = this.tmsDetail.SelectedClientGroup;
      this.IsDisableAGDGDU = true;
    }
    else if (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureGDN) {
      this.tmsHierarchy.DeliveryAccountabilityTypes = cloneDeep(this.tmsHierarchy.DeliveryCenters);
      this.tmsDetail.SelectedDeliveryAccountabilityType = this.tmsDetail.SelectedDeliveryCenter;
      this.IsDisableAGDGDU = false;
    }
    else if (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AvanadeGU) {
      this.tmsHierarchy.DeliveryAccountabilityTypes = cloneDeep(this.tmsHierarchy.AvanadeGeographicUnits);
      this.tmsDetail.SelectedDeliveryAccountabilityType = this.tmsDetail.SelectedAvanadeGeographicUnit;
      this.IsDisableAGDGDU = true;
    }
    else {
      this.tmsHierarchy.DeliveryAccountabilityTypes = [];
      this.tmsDetail.SelectedDeliveryAccountabilityType = null;
      this.IsDisableAccType = true;
    }
  }

  AddTMS(type) {
    this.DateValidation.IsValidEndDate = true;
    this.DateValidation.IsValidEndDateStartDate = true;
    this.DateValidation.IsValidStartDateEndDate = true;
    this.DateValidation.IsValidStartDate = true;
    this.tmsHierarchy.Type = type;
    this.teamStatus.Id = "1";
    this.teamStatusDropDownDisable = true;
    if (this.DraggedItems && this.DraggedItems.length > 0) {
      this.ShowAlert(this.dragDropMessage);
      return false;
    }
    else {
      this.Action = "ADD";
      this.ActiveDropdownDirective = false;
      if (this.tmsHierarchy.Type == "Group") {
        this.tmsGroupDetail = {};
        this.tmsGroupDetail.ParentTMSId = this.currentSelectedTMS ? this.currentSelectedTMS.TMSGroupId : null;
        setTimeout(function () {
          $('.close').focus();
        });
      }
      else {
        this.tmsDetail = {};
        this.tmsDetail.ParentTMSId = this.currentSelectedTMS ? this.currentSelectedTMS.TMSGroupId : null;
        this.tmsDetail.TeamMembers = [];
        this.tmsDetail.SelectedEndToEndTeamMember = [];
        this.tmsDetail.SelectedTeamMember = [];
        this.tmsDetail.CMSDetails = [];
        this.tmsDetail.WorkStreamDetails = [];
        this.TempAccountGroups = [];
        this.TempDeliveryUnits = [];
        this.TempDeliveryGroups = [];
        this.tmsDetail.searchText = "";
        this.UpdateCMSModel(this.tmsHierarchy.CMSDetails, this.tmsHierarchy.WorkStreamDetails);
        this.tmsDetail.MMEWMUId = "";
        this.tmsDetail.SelectedDeliveryAccountability = null;
      }
      this.ActiveDropdownDirective = true;
      this.PopUpHeader = "Add";
      this.PopUpHeader = this.tmsHierarchy.Type == 'TMS' ? 'Add Team Management Structure' : 'Add Team Management Structure Group'
      this.IsDisableAccType = true;
      this.IsDisableAGDGDU = true;
      this.ActivateFirstTab();
      $("#teamLead").removeClass('input-blank-error');
      setTimeout(function () {
        $('.close').focus();
      });
      if (!$('#' + this.popupId).hasClass('in')) {
        $('#' + this.popupId + ' .modal-dialog').draggable({ containment: 'parent' });
        this.IsEditTMS = false;
        const initialState = {
          DateValidation: this.DateValidation, tmsHierarchy: this.tmsHierarchy, teamStatus: this.teamStatus, teamStatusDropDownDisable: this.teamStatusDropDownDisable,
          IsEditTMS: this.IsEditTMS, Action: this.Action, tmsGroupDetail: this.tmsGroupDetail, tmsDetail: this.tmsDetail, TempAccountGroups: this.TempAccountGroups,
          TempDeliveryUnits: this.TempDeliveryUnits, TempDeliveryGroups: this.TempDeliveryGroups, IsDisableAccType: this.IsDisableAccType, IsDisableAGDGDU: this.IsDisableAGDGDU,
          EndToEndRefCode: this.EndToEndRefCode, EndToEndName: this.EndToEndName, EndToEndType: this.EndToEndType, EndToEndStartDate: this.EndToEndStartDate,
          EndToEndEndDate: this.EndToEndEndDate, popupTMSDetailsTab: this.popupTMSDetailsTab, IsEditable: this.IsEditable, PopUpHeader: this.PopUpHeader, scrollYPosition: window.scrollY
        };
        this.modalRef_teamAddEditPopup = this.modalService.show(TeamAddEditComponent, {
          initialState,
          backdrop: "static",
          keyboard: false
        });
      
        this.modalRef_teamAddEditPopup.content.teamAddEditPopupData.subscribe(result => {
          this.ActiveDropdownDirective = result.ActiveDropdownDirective;
          this.currentSelectedTMS = result.currentSelectedTMS;
          if (result.isSaveButtonClick) {
            this.teamStructureSharedService.UpdateTMSGroupAndTMS(this, this.trackExpandedElements);
            this.toggleCollpaseAndExpand = [];
            var allElements = document.querySelectorAll('li');
            allElements.forEach(ele => {
              var childElement = ele.querySelector('.c-list-child');
              if (childElement != null) {
                var expandId = childElement.closest('li');
                if (expandId.dataset.tmsgroupid != undefined) {
                  var allChildIndex = this.toggleCollpaseAndExpand.indexOf(expandId.dataset.tmsgroupid.toString());
                  if (allChildIndex == -1)
                    this.toggleCollpaseAndExpand.push(expandId.dataset.tmsgroupid.toString());
                }
              }
            })
            this.trackExpandedElements = [];
            var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
            toggleState.forEach(e => {
              $(e).prop('checked', false);
              this.CollapseExpand = false;
            })
            var child = document.querySelectorAll(".c-list");
            child.forEach((ele) => {
              if (ele.classList.contains('expand')) {
                ele.classList.remove('expand');
                ele.nextElementSibling.classList.remove('open');
              }
            })
            this.ref.detectChanges();
            
          }
          this.headerComponent.RefreshTeamScopeSelector();
        });
      }
    }
  }

  ChangeTeamLead($event, teamLead) {
    if (!(($event.ctrlKey == true || $event.key == "Control") && ($event.keyCode == 67 || $event.keyCode == 17 || $event.keyCode == 65))) {
      if (teamLead == null || teamLead == '') {
        $($event.currentTarget).removeClass('input-blank-error');
      }
      else if ($("#hiddenTeamLead").val() != "" && $("#hiddenTeamLead").val() != teamLead) {
        $($event.currentTarget).addClass('input-blank-error');
      }
      else {
        return false;
      }
    }
  }

  AccountabilityChange() {
    this.ActiveDropdownDirective = false;
    if (this.tmsDetail.SelectedDeliveryAccountability) {
      this.DeliveryAccountabilityType();
      this.IsDisableAccType = this.tmsDetail.SelectedDeliveryAccountability == 4 || this.tmsDetail.SelectedDeliveryAccountability == 0 ? true : false;
      this.IsDisableAGDGDU = (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureGDN) ? false : true;
      this.tmsDetail.SelectedDeliveryAccountabilityType = null;
    }
    else {
      this.tmsDetail.SelectedDeliveryAccountabilityType = null;
      this.IsDisableAccType = true;
      this.IsDisableAGDGDU = true;
      this.tmsHierarchy.DeliveryAccountabilityTypes = [];
      this.tmsDetail.SelectedDeliveryAccountabilityType = null;
    }
    this.ActiveDropdownDirective = true;
  }

  AccountabilityTypeChange() {
    this.tmsDetail.MMEWMUIds = [];
    this.FilterAGListData();
    this.tmsDetail.SelectedAccountGroupId = null;
    this.tmsDetail.SelectedDeliveryGroupId = null;
    this.tmsDetail.SelectedDeliveryUnitId = null;
    this.tmsDetail.SelectedInteractionStyleId = null;
    this.FilterAGListData();
    this.FilterDUListData();
    this.FilterDGListData();
  }

  FilterAGListData() {
    var MainList = cloneDeep(this.tmsHierarchy.AccountGroups);
    this.TempAccountGroups = cloneDeep(MainList.filter((x: any) => x.ParentId == this.tmsDetail.SelectedDeliveryAccountabilityType));
  }

  FilterDGListData() {
    var MainList = cloneDeep(this.tmsHierarchy.DeliveryGroups);
    this.TempDeliveryGroups = cloneDeep(MainList.filter((x: any) => x.ParentId == this.tmsDetail.SelectedAccountGroupId));
  }

  FilterDUListData() {
    var MainList = cloneDeep(this.tmsHierarchy.DeliveryUnits);
    this.TempDeliveryUnits = cloneDeep(MainList.filter((x: any) => x.ParentId == this.tmsDetail.SelectedDeliveryGroupId));
  }

  AddTeamMember() {
    if (this.tmsGroupDetail.enterPriseId) {
      this.tmsGroupDetail.SelectedTeamMembers = this.tmsGroupDetail.SelectedTeamMembers ? this.tmsGroupDetail.SelectedTeamMembers + ", " + this.tmsGroupDetail.enterPriseId : this.tmsGroupDetail.enterPriseId;
      this.tmsGroupDetail.enterPriseId = "";
    }
  }

  expandParent(elements: any) {
    elements.forEach(se => {
      document.querySelectorAll('li').forEach(x => {
        if (x.dataset.tmsgroupid == se.toString()) {
          var element = x.querySelector('.c-list-child');
          if (element != null) {
            element.classList.add('expand');
            $(element.nextSibling).addClass('open');
          }
        }
      })
    })

    if ((this.toggleCollpaseAndExpand.sort().toString() === this.trackExpandedElements.sort().toString()) && (this.toggleCollpaseAndExpand.length > 0 && this.trackExpandedElements.length > 0)) {
      var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
      toggleState.forEach(e => {
        $(e).prop('checked', true);
        this.CollapseExpand = true;
      })
    }
  }

  collapseParent(e) {
    var track = this.trackExpandedElements
    var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
    var child = document.querySelectorAll(".c-list");
    function expand() {
      child.forEach((ele) => {
        if (ele.querySelector('.-expand')) {
          ele.classList.add('expand');
          ele.nextElementSibling.classList.add('open');
          var elementLI = ele.closest('li');
          var index = track.findIndex(x => x == elementLI.dataset.tmsgroupid);
          if (index == -1)
            track.push(elementLI.dataset.tmsgroupid);

        }
      })
    }
    function collapse() {
      child.forEach((ele) => {
        if (ele.classList.contains('expand')) {
          ele.classList.remove('expand');
          ele.nextElementSibling.classList.remove('open');
          var elementLI = ele.closest('li');
          var index = track.findIndex(x => x == elementLI.dataset.tmsgroupid);
          if (index > -1)
            track.splice(index, 1);

        }
      })
    }
    toggleState.forEach(() => {
      if (e.target.checked) {
        expand();
        this.trackExpandedElements = track;
        this.CollapseExpand = true;
      }
      else {
        collapse();
        this.trackExpandedElements = track;
        this.CollapseExpand = false;
      }
    })
  }

  ResetSelection() {
    this.currentSelectedTMS = null;
    if (this.tmsHierarchy) {
      this.tmsHierarchy.TMSGroupLevelOne.forEach((tmsGroup1: any, key: any) => {
        tmsGroup1.IsSelected = false;
        tmsGroup1.TMS.forEach((tms: any, key: any) => {
          tms.IsSelected = false;
        });
        tmsGroup1.TMSGroupLevelTwo.forEach((tmsGroup2: any, key: any) => {
          tmsGroup2.IsSelected = false;
          tmsGroup2.TMS.forEach((tms: any, key: any) => {
            tms.IsSelected = false;
          });
          tmsGroup2.TMSGroupLevelThree.forEach((tmsGroup3: any, key: any) => {
            tmsGroup3.IsSelected = false;
            tmsGroup3.TMS.forEach((tms: any, key: any) => {
              tms.IsSelected = false;
            });
            tmsGroup3.TMSGroupLevelFour.forEach((tmsGroup4: any, key: any) => {
              tmsGroup4.IsSelected = false;
              tmsGroup4.TMS.forEach((tms: any, key: any) => {
                tms.IsSelected = false;
              });
              tmsGroup4.TMSGroupLevelFive.forEach((tmsGroup5: any, key: any) => {
                tmsGroup5.IsSelected = false;
                tmsGroup5.TMS.forEach((tms: any, key: any) => {
                  tms.IsSelected = false;
                });
              });
            });
          });
        });

      });
      this.tmsHierarchy.TMS.forEach((tms: any, key: any) => {
        tms.IsSelected = false;
      });
      this.tmsHierarchy.Teams.forEach((tms: any, key: any) => {
        tms.IsSelected = false;
      });
      this.tmsHierarchy.TMSGroups.forEach((tms: any, key: any) => {
        tms.IsSelected = false;
      });
      this.DisableAddButton = false;
      this.DisableAddGroupButton = false;
    }
  }

  ValidateFiveLevel(draggedItem, droppedItem) {
    var teamGroupDrag = this.tryJsonParse(draggedItem);
    var droppedItemJson = this.tryJsonParse(droppedItem);
    var teamGroupDrop = droppedItem != undefined ? droppedItemJson : droppedItem;
    var draggedGroupLevelCount = this.LevelCount(teamGroupDrag);
    var dropedItemTier = teamGroupDrop ? teamGroupDrop.Tier : 0;
    if (draggedGroupLevelCount + dropedItemTier <= 5) {
      return true;
    } else {
      return false;
    }
  }

  LevelCount(teamGroupDrag) {
    var level = 1;
    if (teamGroupDrag.Tier == 1) {
      level = level > 1 ? level : 1;
      teamGroupDrag.TMSGroupLevelTwo.forEach((tmsGroup2: any, key: any) => {
        level = level > 2 ? level : 2;
        tmsGroup2.TMSGroupLevelThree.forEach((tmsGroup3: any, key: any) => {
          level = level > 3 ? level : 3;
          tmsGroup3.TMSGroupLevelFour.forEach((tmsGroup4: any, key: any) => {
            level = level > 4 ? level : 4;
            tmsGroup4.TMSGroupLevelFive.forEach((tmsGroup5: any, key: any) => {
              level = level > 5 ? level : 5;
            });
          });
        });
      });
    }
    else if (teamGroupDrag.Tier == 2) {
      level = level > 1 ? level : 1;
      teamGroupDrag.TMSGroupLevelThree.forEach((tmsGroup3: any, key: any) => {
        level = level > 2 ? level : 2;
        tmsGroup3.TMSGroupLevelFour.forEach((tmsGroup4: any, key: any) => {
          level = level > 3 ? level : 3;
          tmsGroup4.TMSGroupLevelFive.forEach((tmsGroup5: any, key: any) => {
            level = level > 4 ? level : 4;
          });
        });
      });
    }
    else if (teamGroupDrag.Tier == 3) {
      level = level > 1 ? level : 1;
      teamGroupDrag.TMSGroupLevelFour.forEach((tmsGroup4: any, key: any) => {
        level = level > 2 ? level : 2;
        tmsGroup4.TMSGroupLevelFive.forEach((tmsGroup5: any, key: any) => {
          level = level > 3 ? level : 3;
        });
      });
    }
    else if (teamGroupDrag.Tier == 4) {
      level = level > 1 ? level : 1;
      teamGroupDrag.TMSGroupLevelFive.forEach((tmsGroup5: any, key: any) => {
        level = level > 2 ? level : 2;
      });
    }
    else {
      level = level > 1 ? level : 1;
    }
    return level;
  }

  DeleteTMS(tmsGroup, tms, event) {
    this.isDeleteOnNoButton = false;
    if (this.DraggedItems && this.DraggedItems.length > 0) {
      this.ShowAlert(this.dragDropMessage);
      return false;
    }
    this.Action = "Delete";
    this.ResetSelection();
    if (tmsGroup) {
      tmsGroup.IsSelected = true;
      this.DisableAddButton = false;
      this.DisableAddGroupButton = false;
      this.currentSelectedTMS = tmsGroup;
      this.confirmationMessage = "The team group structure you are about to delete will no longer exist. Are you sure you want to proceed?";
    }
    else {
      tms.IsSelected = true;
      this.DisableAddButton = true;
      this.DisableAddGroupButton = true;
      this.currentSelectedTMS = tms;
      this.confirmationMessage = "The team management structure you are about to delete will no longer exist. Are you sure you want to proceed?";
    }
    this.teamGroup = [];
    this.team = [];
    var tmsGroupandTeamList = $(event.currentTarget).parent('div').parent('div').parent('li').find('.c-list');
    tmsGroupandTeamList.toArray().forEach((item: any) => {
      var type = $(item).attr('data-type');
      if (type == "Group") {
        var tmsGroupDetails = {
          TMSGroupId: $(item).parent('li').attr('data-tmsgroupid'),
          UserName: this.sessionStorage.enterpriseId,
          EndToEndId: this.sessionStorage.endToEndId
        }
        this.teamGroup.push(tmsGroupDetails);
      }
      else {
        var tmsDetails = {
          TMSId: $(item).parent('li').attr('data-tmsId'),
          UserName: this.sessionStorage.enterpriseId,
          EndToEndId: this.sessionStorage.endToEndId
        }
        this.team.push(tmsDetails);
      }
    });
    var totalItems = this.team.length + this.teamGroup.length;
    if (totalItems > 1) {
      this.confirmationMessage = "The team group that you are attempting to delete will no longer exist. Would you like to drag and drop the structure associated with this team group before doing so?";
      this.isDeleteOnNoButton = true;
    }
    var confirmationModel = this.sharedService.openConfirmationPopup(this.confirmationMessage, "Yes", "No", window.scrollY);
    confirmationModel.content.confirmationResult.subscribe(response => {
      if (response.result) {
        this.Confirmed();
      } else if (response.result == false && response.type != "close") {
        this.ConfirmedNo();
      }

    });
    
    setTimeout(function () {
      $('.close').focus();
    });

  }

  Confirmed() {
    if (this.Action == "Delete") {     
      if (!this.isDeleteOnNoButton) {
        this.DeleteTMSStructure();
      }
    }
  }

  DeleteTMSStructure() {
    var tmHierarchDetails = {
      DeletedTeams: this.team,
      DeletedTMSGroups: this.teamGroup
    }
    this.tmsService.deleteTeam(tmHierarchDetails).subscribe((response: any) => {
      this.currentSelectedTMS = null;
      if (this.sessionStorage.teamId) {
        this.sessionStorage.teamId = 0;
        this.sessionStorage.tmsName = "";
        this.sessionStorage.teamUId = "";
        if (this.sessionStorage.clientWMSId) {
          this.sessionStorage.selectedSubmissionType = enumSubmissionType.ClientWMS;
        }
        else {
          this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
        }
      }
      else {
        this.teamStructureSharedService.UpdateTMSGroupAndTMS(this, this.trackExpandedElements = []);
      }
      this.DisableAddButton = false;
      this.DisableAddGroupButton = false;
      this.Message = "Deleted successfully";
      this.headerComponent.RefreshTeamScopeSelector();   
      this.ShowAlert(this.Message);
      this.CollapseTMS();
      setTimeout(function () {
        $('.close').focus();      
      });
      this.ProcessDemographicAttribute(enumEntityType.EndToEnd + "|" + enumEntityType.TMS, null);
      //if (this.trackExpandedElements.length > 0)
      //  this.expandParent(this.trackExpandedElements);     
    });
  }
  ConfirmedNo = function () {
    if (this.Action == "Delete" && this.isDeleteOnNoButton) {
      this.DeleteTMSStructure();
    }
  }

  ActivateFirstTab() {
    $("#teamMembers").removeClass('active');
    $('#' + this.popupTMSDetailsTab).addClass('active');
    $("#tabTeamMembers").removeClass('active');
    $("#tabTeamDetails").addClass('active');
  }

  CollapseTMS() {
    var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
    toggleState.forEach(e => {
      $(e).prop('checked', false);
      this.CollapseExpand = false;
    })
    this.CollapseExpand = false;
    this.ref.detectChanges();
  }

  UpdateCMSModel(cmsDetail, workstreamDetail) {
    this.tmsDetail.CMSLevelOne = [];
    this.tmsDetail.WorkStreamStructure = [];
    var cmsDetails = cloneDeep(cmsDetail);
    var workstreamDetails = cloneDeep(workstreamDetail);
    this.tmsDetail.CMSDetails = cmsDetails;
    this.tmsDetail.WorkStreamDetails = workstreamDetails;
    var cmsLevelOne = cmsDetails.filter((x: any) => x.SelectedParentId == null);
    cmsLevelOne.forEach((cms1: any, key: any) => {
      cms1.Tier = 1;
      cms1.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms1.CMSId);
      var cmsLevelTwo = cmsDetails.filter((x: any) => x.SelectedParentId == cms1.CMSId);
      if (cms1.WorkStreamStructure.length > 0 || cmsLevelTwo.length > 0) {
        var noneSelectedWorkstream = cms1.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
        var noneSelectedcms = cmsLevelTwo.filter((x: any) => x.SelectionMode == enumAssociationType.None);
        var fullSelectedWorkstream = cms1.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
        var fullSelectedcms = cmsLevelTwo.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
        if (cms1.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelTwo.length == fullSelectedcms.length) {
          cms1.SelectionMode = enumAssociationType.Full;
        }
        else if (cms1.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelTwo.length > noneSelectedcms.length) {
          cms1.SelectionMode = enumAssociationType.Partial;
        }
      }
      cms1.WorkStreamStructure.forEach((ws1: any, key: any) => {
        ws1.SelectionMode = cms1.SelectionMode == enumAssociationType.Full || ws1.SelectionMode == enumAssociationType.Full
          ? enumAssociationType.Full
          : enumAssociationType.None;
        this.UpdateSelectionModelWorkstream(ws1.SelectionMode, ws1.WorkstreamId, ws1.CMSId);
      });
      cmsLevelTwo.forEach((cms2: any, key: any) => {
        cms2.Tier = 2;
        cms2.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms2.CMSId);
        var cmsLevelThree = cmsDetails.filter((x: any) => x.SelectedParentId == cms2.CMSId);
        if (cms2.WorkStreamStructure.length > 0 || cmsLevelThree.length > 0) {
          var noneSelectedWorkstream = cms2.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
          var noneSelectedcms = cmsLevelThree.filter((x: any) => x.SelectionMode == enumAssociationType.None);
          var fullSelectedWorkstream = cms2.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
          var fullSelectedcms = cmsLevelThree.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
          if (cms2.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelThree.length == fullSelectedcms.length) {
            cms2.SelectionMode = enumAssociationType.Full;
          }
          else if (cms2.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelThree.length > noneSelectedcms.length) {
            cms1.SelectionMode = enumAssociationType.Partial;
            cms2.SelectionMode = enumAssociationType.Partial;
          }
        }
        cms2.WorkStreamStructure.forEach((ws2: any, key: any) => {
          ws2.SelectionMode = cms2.SelectionMode == enumAssociationType.Full || ws2.SelectionMode == enumAssociationType.Full ? enumAssociationType.Full : enumAssociationType.None;
          this.UpdateSelectionModelWorkstream(ws2.SelectionMode, ws2.WorkstreamId, ws2.CMSId);
        });
        cmsLevelThree.forEach((cms3: any, key: any) => {
          cms3.Tier = 3;
          cms3.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms3.CMSId);
          var cmsLevelFour = cmsDetails.filter((x: any) => x.SelectedParentId == cms3.CMSId);
          if (cms3.WorkStreamStructure.length > 0 || cmsLevelFour.length > 0) {
            var noneSelectedWorkstream = cms3.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
            var noneSelectedcms = cmsLevelFour.filter((x: any) => x.SelectionMode == enumAssociationType.None);
            var fullSelectedWorkstream = cms3.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
            var fullSelectedcms = cmsLevelFour.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
            if (cms3.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelFour.length == fullSelectedcms.length) {
              cms3.SelectionMode = enumAssociationType.Full;
            }
            else if (cms3.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelFour.length > noneSelectedcms.length) {
              cms1.SelectionMode = enumAssociationType.Partial;
              cms2.SelectionMode = enumAssociationType.Partial;
              cms3.SelectionMode = enumAssociationType.Partial;
            }
          }
          cms3.WorkStreamStructure.forEach((ws3: any, key: any) => {
            ws3.SelectionMode = cms3.SelectionMode == enumAssociationType.Full || ws3.SelectionMode == enumAssociationType.Full ? enumAssociationType.Full : enumAssociationType.None;
            this.UpdateSelectionModelWorkstream(ws3.SelectionMode, ws3.WorkstreamId, ws3.CMSId);
          });
          cmsLevelFour.forEach((cms4: any, key: any) => {
            cms4.Tier = 4;
            cms4.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms4.CMSId);
            var cmsLevelFive = cmsDetails.filter((x: any) => x.SelectedParentId == cms4.CMSId);
            if (cms4.WorkStreamStructure.length > 0 || cmsLevelFive.length > 0) {
              var noneSelectedWorkstream = cms4.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
              var noneSelectedcms = cmsLevelFive.filter((x: any) => x.SelectionMode == enumAssociationType.None);
              var fullSelectedWorkstream = cms4.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
              var fullSelectedcms = cmsLevelFive.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
              if (cms4.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelFive.length == fullSelectedcms.length) {
                cms4.SelectionMode = enumAssociationType.Full;
              }
              else
                if (cms4.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelFive.length > noneSelectedcms.length) {
                  cms1.SelectionMode = enumAssociationType.Partial;
                  cms2.SelectionMode = enumAssociationType.Partial;
                  cms3.SelectionMode = enumAssociationType.Partial;
                  cms4.SelectionMode = enumAssociationType.Partial;
                }
            }
            cms4.WorkStreamStructure.forEach((ws4: any, key: any) => {
              ws4.SelectionMode = cms4.SelectionMode == enumAssociationType.Full || ws4.SelectionMode == enumAssociationType.Full ? enumAssociationType.Full : enumAssociationType.None;
              this.UpdateSelectionModelWorkstream(ws4.SelectionMode, ws4.WorkstreamId, ws4.CMSId);
            });
            cmsLevelFive.forEach((cms5: any, key: any) => {
              cms5.Tier = 5;
              cms5.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms5.CMSId);
              if (cms5.WorkStreamStructure.length > 0) {
                var noneSelectedWorkstream = cms5.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
                var fullSelectedWorkstream = cms5.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
                if (cms5.WorkStreamStructure.length == fullSelectedWorkstream.length) {
                  cms5.SelectionMode = enumAssociationType.Full;
                }
                else if (cms5.WorkStreamStructure.length > noneSelectedWorkstream.length) {
                  cms1.SelectionMode = enumAssociationType.Partial;
                  cms2.SelectionMode = enumAssociationType.Partial;
                  cms3.SelectionMode = enumAssociationType.Partial;
                  cms4.SelectionMode = enumAssociationType.Partial;
                  cms5.SelectionMode = enumAssociationType.Partial;
                  this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, cms3.CMSId, cms4.CMSId, cms5.CMSId);
                }
              }
              cms5.WorkStreamStructure.forEach((ws5: any, key: any) => {
                ws5.SelectionMode = cms5.SelectionMode == enumAssociationType.Full || ws5.SelectionMode == enumAssociationType.Full ? enumAssociationType.Full : enumAssociationType.None;
                this.UpdateSelectionModelWorkstream(ws5.SelectionMode, ws5.WorkstreamId, ws5.CMSId);
              });
            });
            cms4.CMSLevelFive = cmsLevelFive;
          });
          cms3.CMSLevelFour = cmsLevelFour;
        });
        cms2.CMSLevelThree = cmsLevelThree;
      });
      cms1.CMSLevelTwo = cmsLevelTwo;
    });
    this.tmsDetail.CMSLevelOne = cmsLevelOne;
    this.tmsDetail.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSName == null);
  }

  PeoplePickerSelection(workstreamId, cmsId, poc, IsPOCValid, event) {
    if ((event != null && event != undefined && event.keyCode == 9) || ((event.ctrlKey == true || event.key == "Control") && (event.keyCode == 67 || event.keyCode == 17 || event.keyCode == 65))) {
      return false;
    }
    else {
      if (workstreamId) {
        var workStreamDetail = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == workstreamId && x.CMSId == cmsId);
        if (poc == '') {
          var workStreamDetails = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == workstreamId);
          workStreamDetails.forEach((ws: any, key: any) => {//foreach  for shared workstream can have same POC
            if (ws.SelectionMode == enumAssociationType.Full) {
              ws.POC = poc;
            }
          });
        }
      }
      else {
        var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId);
        if (poc == '') {
          cmsDetail.POC = poc;
        }
      }
    }
  }

  UpdateSelectionModeCMS(selectionMode, cmsId1, cmsId2, cmsId3, cmsId4, cmsId5) {
    if (cmsId1) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId1);
      cmsDetail.SelectionMode = selectionMode;
    }
    if (cmsId2) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId2);
      cmsDetail.SelectionMode = selectionMode;
    }
    if (cmsId3) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId3);
      cmsDetail.SelectionMode = selectionMode;
    }
    if (cmsId4) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId4);
      cmsDetail.SelectionMode = selectionMode;
    }
    if (cmsId5) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId5);
      cmsDetail.SelectionMode = selectionMode;
    }
  }

  UpdateSelectionModelWorkstream(selectionMode, workstreamId, cmsId) {
    var workStreamDetails = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == workstreamId && x.CMSId == cmsId);
    workStreamDetails.SelectionMode = selectionMode;
  }

  AddMMEWMUId(MMEWMUId) {
    if (MMEWMUId && parseInt(MMEWMUId)) {
      var mmeId = parseInt(MMEWMUId);
      var selectedMMEWMUId = this.tmsDetail.MMEWMUIds.filter((x: any) => x.MMEWMUId == mmeId);
      if (selectedMMEWMUId && selectedMMEWMUId.length > 0) {
        return false;
      }
      else {
        var MMEWMUIds = {
          MMEWMUId: mmeId,
          EntityType: "T",
          EndToEndId: this.sessionStorage.endToEndId,
          TmsId: this.tmsDetail.TMSId,
          UserName: this.sessionStorage.enterpriseId,
          TMSIdList: []
        }
        this.tmsDetail.MMEWMUIds.push(MMEWMUIds);
        this.tmsDetail.MMEWMUId = "";
      }
    }
  }

  DeleteMMEWMUId(MMEWMUId) {
    var mmeId = parseInt(MMEWMUId);
    var selectedMMEWMUId = this.tmsDetail.MMEWMUIds.filter((x: any) => x.MMEWMUId == mmeId);
    if (selectedMMEWMUId) {
      this.tmsDetail.MMEWMUIds.splice(this.tmsDetail.MMEWMUIds.indexOf(selectedMMEWMUId), 1);
    }
  }

  KeyPressNumeric(event) {
    var iKeyCode = event.keyCode;
    if (iKeyCode >= 48 && iKeyCode <= 57) {
      return true;
    }
    event.stopPropagation();
    event.preventDefault();
  }

  TeamDateValidation() {
    var isValidTeamDates = true;
    this.EndToEndStartDate = this.datepipe.transform(this.tmsHierarchy.EndToEndStartDate, 'dd MMM yyyy');
    this.EndToEndEndDate = this.datepipe.transform(this.tmsHierarchy.EndToEndEndDate, 'dd MMM yyyy');
    var e2eStartDate = new Date(this.EndToEndStartDate)
    var e2eEndDate = new Date(this.EndToEndEndDate)
    if (this.tmsDetail.StartDate != null) {
      var tmsStartDate = new Date(this.tmsDetail.StartDate);
    }
    if (this.tmsDetail.EndDate != null) {
      var tmsEndDate = new Date(this.tmsDetail.EndDate);
    }

    if (tmsStartDate < e2eStartDate) {
      isValidTeamDates = this.DateValidation.IsValidStartDate = false;
    }

    if (tmsEndDate > e2eEndDate) {
      isValidTeamDates = this.DateValidation.IsValidEndDate = false;
    }

    if (tmsEndDate < e2eStartDate) {
      isValidTeamDates = this.DateValidation.IsValidEndDateStartDate = false;
    }
    if (tmsStartDate > e2eEndDate) {
      isValidTeamDates = this.DateValidation.IsValidStartDateEndDate = false;
    }
    return isValidTeamDates;
  }

  onChangeDate(date) {
    if (date == "StartDate") {
      this.DateValidation.IsValidStartDate = true;
      this.DateValidation.IsValidStartDateEndDate = true;
    }
    this.DateValidation.IsValidEndDate = true;
    this.DateValidation.IsValidEndDateStartDate = true;
  }

  ProcessDemographicAttribute(sectionName, endtoEndId) {
    this.demographicService.processDemographicAttribute(endtoEndId ? endtoEndId : this.sessionStorage.endToEndId, sectionName).subscribe((response: any) => {
    });
  }

  UsageLogging(moduleAction, tileID, featureAccessID) {
    this.applicationLoggingService.applicationUsageLogging(moduleAction, tileID, featureAccessID).subscribe();
  }
  // General function for try/catch
  tryJsonParse(data) {
    let parsedJson;
    try {
      parsedJson = JSON.parse(data);
    } catch (exception) {
      parsedJson = null;
    }
    return parsedJson;
  }

  DisplayTMSUserGuide() {
    this.router.navigate(['/mywizard/tmsuserguide']);
  }
}
