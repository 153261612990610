import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
declare var window: any;
var $ = (<any>window).$;

@Component({
  templateUrl: './bcm.priority.description.component.html'
})

export class BCMPriorityDescriptionComponent{
  scrollYPosition: any;
  constructor(private bsModalRef: BsModalRef ) { }
  ngOnDestroy() {
    window.scrollTo(0, this.scrollYPosition);
  }
  closeModal() {
    this.bsModalRef.hide();
  }

}
