import { Directive, ElementRef, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
declare var $: any;
@Directive({
  selector: '[datepicker]',
  providers: [DatePipe]
})
export class DatePickerDirective {

  constructor(private _element: ElementRef, private datePipe: DatePipe) { }


  @Output() onSelectDate = new EventEmitter();
  ngAfterViewInit() {

    var element = $("#" + this._element.nativeElement.id);
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    element.datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: 'dd M yy',
      showOn: "button",
      buttonText: '',
      onSelect: (dateText: any, inst: any) => {
        this.onSelectDate.emit({ value: dateText });
        if (inst.id == "EndDate") {
          var selectedDate = new Date(dateText)
          this.onSelectDate.emit({ value: dateText, IsValidEndDate: true, IsFutureEndDate: false });
          if (selectedDate < currentDate) {
            this.onSelectDate.emit({ value: dateText, IsValidEndDate: true, IsFutureEndDate: false });
          } else {
            this.onSelectDate.emit({ value: dateText, IsValidEndDate: true, IsFutureEndDate: true });
          }
        }
        if (inst.id == "enddate" || inst.id == "tmsenddate") {
          this.onSelectDate.emit({ value: dateText, IsValidEndDate: true, IsFutureEndDate: false });
        }
        if (inst.id == "startdate" || inst.id == "tmsstartdate") {
          this.onSelectDate.emit({ value: dateText, IsValidStartDate: true, IsValidStartDateEndDate: true });
        }

      },
      beforeShow: function (inp, inst) {
        if (element.attr('disabled') == 'disabled') {
          element.datepicker("hide");
          return false;
        }
        setTimeout(function () {
          inst.dpDiv.position({ my: 'left top', at: 'left bottom', of: inp });
        }, 1);
      }
    }).next(".ui-datepicker-trigger").attr("aria-label", element.attr('data-showmessage')).attr("aria-describedby", element.attr('data-described')).attr("title", element.attr('data-showmessage')).addClass("o-input-date__icon icon-calender");

  }
}
