import { Component, Input, OnInit, OnChanges, SimpleChanges, Injectable, EventEmitter, Output, ViewChild } from '@angular/core';
import { ConfigureMetricService } from '../../core/services/configure.metrics.services';
import { SessionStorage } from '../../core/context/sessionstorage';
import { enumMetricDeliveryLevel } from '../../core/context/enum';
import { environment } from '../../../environments/environment';

declare var require: any;
declare var $: any;

@Component({
  selector: 'threshold-trend',
  templateUrl: './metric.edit.trend.component.html',
})

@Injectable()
export class MetricEditTrendComponent {

  accessFlag: boolean;
  isUpdated: any = false
  lastUpdated: any = '';
  tempEnterpriseId: any = "";
  tempDeliveryLevelId: any;

  tempDeliveryStructId: any;
  thresholdtrendid: number;
  ThresholdSetting1: string = '';
  ThresholdSetting2: string = '';
  ThresholdSetting3: string = '';
  isFooter: boolean = false;
  @Input()
  selectedThrendData: any[];
  @Input() changeTrigger: any;
  @Input()
  showWarningMsg: any
  metricThresholdSetting1: any = [];
  metricThresholdSetting2: any = [];
  metricThresholdSetting3: any = [];
  thresholdTrends: any[];
  errorMessage: '';
  message = '';
  selectedtrend1: any;
  selectedTrend1Name: any;
  btnRestore: any;
  selectedtrend2: any;
  selectedTrend2Name: any;

  selectedtrend3: any;
  selectedTrend3Name: any;

  tempTrend1: any;
  tempTrend2: any;
  tempTrend3: any;
  DeliveryId: any;
  DeliveryLevelId: any;
  E2EId: any;

  dropdownvalue: any;
  dropdowntext: any;
  showModal: any = false;
  TrendFirstArray: Array<any> = new Array<any>();
  thresholdSaveData: Array<any> = new Array<any>();
  set1: any;
  set2: any;
  set3: any;
  erormessage: any;
  isError = false;
  Error: any;

  trendid: number;
  firstthresholdsetting: number;
  secondthresholdsetting: number;
  thirdthresholdsetting: number;
  Roleaccesscode: any;

  selected: any = [];
  @Output() _onCancelClick = new EventEmitter<any>();
  @Output() _onSave = new EventEmitter<any>();
  @Output() _onRestore = new EventEmitter<any>();
  @Output() _showWarning = new EventEmitter<any>();

  // Creating event to call Get updated threshold data on grid on saving threshold from pop-up

  MyData = [

    { id: 1, name: 'Decreased' },
    { id: 2, name: 'Stable' },
    { id: 3, name: 'Increased' },
  ]

  constructor(private metricService: ConfigureMetricService, private sessionStorage: SessionStorage,) {

  }

  // Initialization method of ThresholdComponent 
  ngOnInit(): void {
    this.Roleaccesscode = this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);
    this.accessFlag = this.Roleaccesscode == 'E' ? false : true;
    
    if (this.sessionStorage.teamUId != null && this.sessionStorage.teamUId != '') {
      this.DeliveryLevelId = enumMetricDeliveryLevel.Team;
      this.DeliveryId = this.sessionStorage.teamUId
    }
    else {
      this.DeliveryLevelId = enumMetricDeliveryLevel.E2E;
      this.DeliveryId = this.sessionStorage.endToEndUId
    }
    this.tempDeliveryStructId = this.DeliveryId
    this.tempDeliveryLevelId = this.DeliveryLevelId
    this.DeliveryId = this.DeliveryId
    this.DeliveryLevelId = this.DeliveryLevelId;
    this.E2EId = this.sessionStorage.endToEndUId
  //  this.getThresholdTrend();
    this.disableRestoreButton();
  }

  // Get the dropdownlist of threshold trends in customize trend pop-up
  getThresholdTrend(): void {

    //this.metricService.getThresholdTrend().subscribe(
    //  trends => this.thresholdTrends = trends,
    //  error => this.errorMessage = <any>error);

  }

  // Save  metric threshold settings in customize trend popup
  saveMetricThreshConfig(metricThresholdData: any[]): void {

    this.isError = false;
    if (this.MyData.length == 4) {
      if (this.selectedtrend1 == this.MyData[0].id || this.selectedtrend2 == this.MyData[0].id || this.selectedtrend3 == this.MyData[0].id || this.thresholdtrendid == 0) {

        this.isError = true;
        this.Error = "Please select threshold Trends."
        return;
      }
    }

    if (this.selectedtrend1 == this.selectedtrend2 || this.selectedtrend2 == this.selectedtrend3 || this.selectedtrend1 == this.selectedtrend3) {

      this.isError = true;
      this.Error = "Please select unique threshold trends."
      return;
    }
    else {


      for (var i = 0; i < metricThresholdData.length; i++) {

        this.set1 = this.TextFromValue(this.selectedtrend1);
        this.set2 = this.TextFromValue(this.selectedtrend2);
        this.set3 = this.TextFromValue(this.selectedtrend3);

        var enterPriseId = this.sessionStorage.enterpriseId

        this.thresholdSaveData.push({
          MetricThresholdId: metricThresholdData[i].MetricThresholdId,
          MetricId: metricThresholdData[i].MetricId,
          ThresholdTypeid: 2,
          OptRefinementid: null,
          TrendTypeid: null,
          ThresholdTrendId: this.thresholdtrendid,
          DeliveryStructId: metricThresholdData[i].DeliveryStructId,
          DeliveryStructLevelId: this.tempDeliveryLevelId,
          Threshold1: this.set1,
          Threshold2: this.set2,
          Threshold3: this.set3,
          Threshold4: null,
          CreatedByUser: enterPriseId,
          RowVersion: metricThresholdData[i].RowVersion,
          CreatedOn: metricThresholdData[i].CreatedOn,
          CreatedByApp: metricThresholdData[i].CreatedByApp,
          ModifiedByUser: enterPriseId,
          ModifiedOn: metricThresholdData[i].CreatedOn,
          ModifiedByApp: metricThresholdData[i].CreatedByApp,
          DeliveryStructUId: this.DeliveryId,
        })
      }

      this._onSave.emit(this.thresholdSaveData);


    }
  }

  //ngOnChanges event of ThresholdComponent
  ngOnChanges(changes: SimpleChanges) {

    if (changes['changeTrigger']) {

      if (this.changeTrigger != 0) {
        this.MoreThanOne();
      }
      else {
        this.setPreviousValues();
      }
    }

    if (changes['showWarningMsg']) {
    }
  }

  // Get trend threshold settings of one or more selected metrics in customize trend popup
  MoreThanOne() {


    this.thresholdTrends;
    this.isError = false;

    if (this.selectedThrendData.length == 1) {


      this.selectedtrend1 = this.ValueFromText(this.selectedThrendData[0].Threshold1);
      this.selectedTrend1Name = this.MyData.filter(x => x.id == this.selectedtrend1)[0].name
      this.selectedtrend2 = this.ValueFromText(this.selectedThrendData[0].Threshold2);
      this.selectedTrend2Name = this.MyData.filter(x => x.id == this.selectedtrend2)[0].name
      this.selectedtrend3 = this.ValueFromText(this.selectedThrendData[0].Threshold3);
      this.selectedTrend3Name = this.MyData.filter(x => x.id == this.selectedtrend3)[0].name

      this.tempTrend1 = this.ValueFromText(this.selectedThrendData[0].Threshold1);
      this.tempTrend2 = this.ValueFromText(this.selectedThrendData[0].Threshold2);
      this.tempTrend3 = this.ValueFromText(this.selectedThrendData[0].Threshold3);


      this.thresholdtrendid = this.selectedThrendData[0].ThresholdTrendId;

      this.isFooter = false;
      this.isUpdated = false;
      this.DeliveryLevelId = this.DeliveryLevelId
      // Show last updated footer details only when data is present for the contract/E2E level

      if (this.selectedThrendData[0].DeliveryStructLevelId == this.DeliveryLevelId) {

        this.isUpdated = false;
        var date = this.formatDate(this.selectedThrendData[0].ModifiedOn);

        this.lastUpdated = 'Last updated on ' + date + ' GMT by ' + this.selectedThrendData[0].ModifiedByUser
      }

      else {
        this.isFooter = true;

      }



      if (this.selectedtrend1 == undefined && this.selectedtrend2 == undefined && this.selectedtrend3 == undefined) {
        if (this.MyData.length != 4) {
          this.MyData.unshift({ id: 0, name: "--Select--" }) //binding select option in trend direction pop up
        }
        this.selectedtrend1 = "0"
        this.selectedtrend2 = "0"
        this.selectedtrend3 = "0"

        this.tempTrend1 = "0";
        this.tempTrend2 = "0";
        this.tempTrend3 = "0";
      }
    }
    else {

      var distinctelement1 = {};
      var distinctelement2 = {};
      var distinctelement3 = {};
      var distinctThrTrend = {};

      for (var i = 0; i < this.selectedThrendData.length; i++) {

        var value1 = this.selectedThrendData[i].Threshold1;
        distinctelement1[value1] = '';

        var value2 = this.selectedThrendData[i].Threshold2;
        distinctelement2[value2] = '';

        var value3 = this.selectedThrendData[i].Threshold3;
        distinctelement3[value3] = '';

        var thresholdTrend = this.selectedThrendData[i].ThresholdTrendId;
        distinctThrTrend[thresholdTrend] = '';


      }
      var distinctType1 = Object.keys(distinctelement1);
      var distinctType2 = Object.keys(distinctelement2);
      var distinctType3 = Object.keys(distinctelement3);
      var distinctThrTrendType = Object.keys(distinctThrTrend);

      if (distinctType1.length == 1 && distinctType2.length == 1 && distinctType3.length == 1/* && distinctThrTrendType.length == 1*/) {

        this.selectedtrend1 = this.ValueFromText(distinctType1[0]);
        this.selectedtrend2 = this.ValueFromText(distinctType2[0]);
        this.selectedtrend3 = this.ValueFromText(distinctType3[0]);


        this.tempTrend1 = this.ValueFromText(distinctType1[0]);
        this.tempTrend2 = this.ValueFromText(distinctType2[0]);
        this.tempTrend3 = this.ValueFromText(distinctType3[0]);

      }
      else {
        if (this.MyData.length != 4) {
          this.MyData.unshift({ id: 0, name: "--Select--" }) //binding select option in trend direction pop up
        }
        this.selectedtrend1 = "0"
        this.selectedtrend2 = "0"
        this.selectedtrend3 = "0"
        this.thresholdtrendid = 0

        this.tempTrend1 = "0"
        this.tempTrend2 = "0"
        this.tempTrend3 = "0"
      }

      if (distinctThrTrendType.length == 1) {
        this.thresholdtrendid = this.selectedThrendData[0].ThresholdTrendId;
      }
      else {
        this.thresholdtrendid = 0
      }

      this.isFooter = true;

    }
    this.trendid = this.thresholdtrendid;
    this.firstthresholdsetting = this.selectedtrend1;
    this.secondthresholdsetting = this.selectedtrend2;
    this.thirdthresholdsetting = this.selectedtrend3;

  }

  // Convert threshold settings array value to text
  TextFromValue(threshvalue: number) {

    for (var i = 0; i < this.MyData.length; i++) {
      if (this.MyData[i].id == threshvalue) {
        this.dropdowntext = this.MyData[i].name;
      }
    }

    return this.dropdowntext;
  }

  // Convert threshold settings array text to value
  ValueFromText(txt: string) {
    for (var i = 0; i < this.MyData.length; i++) {

      if (this.MyData[i].name == txt) {
        this.dropdownvalue = this.MyData[i].id;
      }
    }

    return this.dropdownvalue;
  }

  // Clear off the metric threshold  settings on click of cancel button 
  onCancelClick() {

    //this.objGlobal.setSessionValue("tempTrend1", this.selectedtrend1);
    //this.objGlobal.setSessionValue("tempTrend2", this.selectedtrend2);
    //this.objGlobal.setSessionValue("tempTrend3", this.selectedtrend3);
    //this.objGlobal.setSessionValue("tempThresholdTrendId", this.thresholdtrendid.toString());

    if (this.selectedtrend1 != this.tempTrend1 || this.selectedtrend2 != this.tempTrend2 || this.selectedtrend3 != this.tempTrend3) {
      this._onCancelClick.emit(2);
    }
    else {
      this._onCancelClick.emit(3);
    }
  }
  onRestoreDefault(metricThresholdData: any[]): void {

      for (var i = 0; i < metricThresholdData.length; i++) {

        this.set1 = this.TextFromValue(this.selectedtrend1);
        this.set2 = this.TextFromValue(this.selectedtrend2);
        this.set3 = this.TextFromValue(this.selectedtrend3);

        var enterPriseId = this.sessionStorage.enterpriseId

        this.thresholdSaveData.push({
          MetricThresholdId: metricThresholdData[i].MetricThresholdId,
          MetricId: metricThresholdData[i].MetricId,
          DeliveryStructId: metricThresholdData[i].DeliveryStructId,
          DeliveryStructLevelId: this.tempDeliveryLevelId
        })
    }


    this._onRestore.emit(this.thresholdSaveData);


  }

  // Show the metric threshold setting based on the selected threshold trend 
  changeThresholdTrend(event: any) {
    this.thresholdTrends;
    var option = event.currentTarget.selectedIndex;
    this.thresholdtrendid = option;
    if (option == this.trendid) {
      this.selectedtrend1 = this.firstthresholdsetting;
      this.selectedtrend2 = this.secondthresholdsetting;
      this.selectedtrend3 = this.thirdthresholdsetting;
    }
    else {

      this.selectedtrend1 = "0"
      this.selectedtrend2 = "0"
      this.selectedtrend3 = "0"
    }
  }
  //117507 fullforms of days and months to be in sync with submission and config screens
  //Formatting date for last updated label in customize trend popup
  formatDate(_date: any) {
    var date = new Date(_date);
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var dateStr = this.selectedThrendData[0].ModifiedOn.toString();
    var part = dateStr.split("-");
    var dtStr = part[2];
    var dt = dtStr.split("T");
    var hrStr = dt[1];
    var hr = hrStr.split(":");
    var formatedDate = days[date.getDay()] + ', ' + months[date.getMonth()] + ' ' + dt[0] + ', ' + part[0] + '-' + hr[0] + ':' + hr[1]
    return formatedDate;

  }

  selectTrend(trendType, trend) {
    if (trendType == 'trend1') {
      this.selectedtrend1 = trend.id;
      this.selectedTrend1Name = trend.name;
    }
    else if (trendType == 'trend2') {
      this.selectedtrend2 = trend.id;
      this.selectedTrend2Name = trend.name;
    }
    else if (trendType == 'trend3') {
      this.selectedtrend3 = trend.id;
      this.selectedTrend3Name = trend.name;
    }
  }
  disableRestoreButton(): void {
      for (var i = 0; i < this.selectedThrendData.length; i++) {
        if ((this.selectedThrendData[i].DeliveryStructLevelId == this.DeliveryLevelId) && this.selectedThrendData[i].TrendTypeid != null) {
          this.btnRestore = false;

        }
        if (this.selectedThrendData[i].IsCustom == true) {
          this.btnRestore = true;
          break;
        }
    }
  }

  setPreviousValues() {

    //this.thresholdtrendid = parseInt(this.objGlobal.getSessionValue("tempThresholdTrendId"));
    //this.selectedtrend1 = this.objGlobal.getSessionValue("tempTrend1");
    //this.selectedtrend2 = this.objGlobal.getSessionValue("tempTrend2");
    //this.selectedtrend3 = this.objGlobal.getSessionValue("tempTrend3");
  }
}







