<div role="tabpanel" id="announcement" [ngClass]="{ '-show' : loadAnnouncement}">
					<div class="c-critical-checkbox">
						
						<div class="c-checkbox   ">
							<input type="checkbox" id="HideAnnouncement"  (change)="SaveUserPreferenceForReleaseNote(ReleaseNotesPreferenceValue)" [(ngModel)]="ReleaseNotesPreferenceValue" class="c-checkbox__input" name="Release_preference" />
							<i class="c-checkbox__checked"></i>
							<label for="HideAnnouncement" class="c-checkbox__label">Hide till next release</label>
						  </div>
						<div class="c-checkbox  ">
							<input type="checkbox" id="critical-changes"  [(ngModel)]="isCritical" class="c-checkbox__input" name="unitcheckbox" />
							<i class="c-checkbox__checked" ></i>
							<label for="critical-changes" class="c-checkbox__label">Critical Changes</label>
						</div>
					</div>
					<div id="announcementAccordion">
						<div class="c-accordion" *ngFor="let notes of ReleaseNotes;let i=index; trackBy: trackByReleaseName;">
							<a href="javascript:;" role="button" class="c-collapse" aria-expanded="false" [attr.aria-controls]="'announcement' + i">	
								<div class="c-collapse__inner" *ngIf="notes.ReleaseName!=Enhancement">

									<div class="-title">Release Name : <span> {{notes.ReleaseName}} ({{notes.ReleaseDate | date:'dd MMM yyyy'}})</span></div>

								</div>
								</a>
								<div id="{{'announcement' + i}}" class="-collapse" data-parentid="announcementAccordion">
									<div class="c-accordion__container">
										<div class="c-accordion c-inner-accordion" *ngFor="let application of notes.ReleaseNoteApplications;let i=index;">
											<a href="javascript:;" role="button" class="c-collapse" aria-expanded="false" [attr.aria-controls]="'announcement_inner' + notes.ReleaseName + i">
												<div class="c-collapse__inner">
													<div class="-title">Application : {{application.Name }}</div>
												</div>
											</a>
											<div id="{{'announcement_inner' + notes.ReleaseName + i}}" class="-collapse" data-parentid="{{'announcement' + i}}">
												<div class="c-accordion__container">
													<div class="c-table-responsive c-lead__table c-notification-table">
														<table class="c-table">
															<thead>
																<tr>
																	<th scope="col">Change</th>
																	<th scope="col">Description</th>
																	<th scope="col">User Impact</th>
																</tr>
															</thead>
															<tbody>
																<ng-container *ngIf="((isCritical && application.IsAppCriticalChange) || !isCritical);else elseBlock ">

																	<ng-container *ngFor="let releaseNote of application.ReleaseNotesList;let j=index;">
																		<tr *ngIf="((isCritical && releaseNote.IsChangeCritical == 'Y') || !isCritical)">
																			<td><span [ngClass]="{'-amber': releaseNote.ReleaseNoteChangeTypeId == 3,'font-blue' : releaseNote.ReleaseNoteChangeTypeId == 2, '-green' : releaseNote.ReleaseNoteChangeTypeId == 1,'font-brown' : releaseNote.ReleaseNoteChangeTypeId == 4 }" [innerHTML]="releaseNote.ReleaseNoteChangeType"></span></td>
																			<td [innerHTML]="releaseNote.FeatureName"></td>
																			<td [innerHTML]="releaseNote.Impact"></td>
																		</tr>
																	</ng-container>
																</ng-container>
																	<ng-template #elseBlock>	<tr><td colspan="3">No Records Found</td></tr></ng-template>
															</tbody>
														
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>






							</div>
						<!--<div class="c-accordion">
							<a href="javascript:;" role="button" class="c-collapse" aria-expanded="false" aria-controls="announcement2"></a>
								<div class="c-collapse__inner">
									<div *ngIf="ReleaseNoteHistory && ReleaseNoteHistory.length >  0" >
										<div *ngFor="let notes of prevReleaseNote;let i=index; trackBy: trackByReleaseName;">
											<div *ngIf="notes.ReleaseName!=Enhancement">
												<div class="-title"><strong>Release Name : {{notes.ReleaseName}} ({{notes.ReleaseDate | date:'dd MMM yyyy'}})</strong></div>
											</div>

							
											<div id="announcement2" class="-collapse" data-parentid="announcementAccordion">
												<div class="c-accordion__container">
													<div class="c-accordion c-inner-accordion" *ngFor="let ApplicationName of prevApplicationNames;">

														<a href="javascript:;" role="button" class="c-collapse" aria-expanded="false" aria-controls="announcement_inner1" (click)="announcement_inner1.classList.toggle('-show')"></a>
														<div class="c-collapse__inner">
															<div class="-title">Application : {{ApplicationName}}</div>
														</div>
														<div id="announcement_inner1" #announcement_inner1 class="-collapse -open" data-parentid="announcement1">
															<div class="c-accordion__container">
																<div class="c-table-responsive c-lead__table c-notification-table">
																	<table class="c-table">
																		<thead>
																			<tr>
																				<th scope="col">Change</th>
																				<th scope="col">Description</th>
																				<th scope="col">User Impact</th>
																			</tr>
																		</thead>
																		<tbody *ngFor="let applicationNotes of notes.ReleaseNotesList;let j=index;">
																			<tr class="tr-announcement" *ngFor="let note of applicationNotes;let k=index; trackBy: rowTrackByReleaseNoteId;">
																				<td [ngClass]="{'font-orange': note.ReleaseNoteChangeTypeId == 3,'font-blue' : note.ReleaseNoteChangeTypeId == 2, 'font-green' : note.ReleaseNoteChangeTypeId == 1,'font-brown' : note.ReleaseNoteChangeTypeId == 4 }" [innerHTML]="note.ReleaseNoteChangeType"></td>
																				<td [innerHTML]="note.FeatureName"></td>
																				<td [innerHTML]="note.Impact"></td>
																			</tr>

																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
													</div>
												</div>
											</div>
										</div>
									</div>
							</div>-->
					</div>
				</div>
	

