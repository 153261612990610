import { Component, Output, EventEmitter, Input, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { SessionStorage } from '../context/sessionstorage';
import { SharedService } from '../services/shared.service';

var $ = (<any>window).$;
declare var window: any;
@Component({
  selector: 'search-dropdown',
  templateUrl: './search.dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchDropDownComponent),
      multi: true
    }

  ]
})

export class SearchDropDownComponent implements ControlValueAccessor {
  constructor(private sharedService: SharedService, private sessionStorage: SessionStorage) { }

  endToEndType = this.sessionStorage.endToEndType;
  @Input() options: any;
  @Input() selected: any;
  // callback: '&',
  @Input() selectText: string;
  @Input() selectedItem: string;
  @Input() disableDropdown: boolean;
  @Input() required: boolean;
  @Input() modelType: string;
  @Input() filterId: any;
  @Input() isIdzero: boolean;
  @Input() isShow: boolean;  
  @Input() isTeamMemberValue: boolean;
  @Input() ariaLabelledby: string;
  @Input() errormessage: string;
  @Input() formname: NgForm;
  @Input() isNoneSelected: boolean;
  @Input() labelInfo: string;
  query: string;
  SelectorOpen: boolean;
  @Input() name: string;
  @Input() isDisableTeamScopeSelector: any;
  @Output() callback = new EventEmitter<any>();
 

  ngOnInit() {
    this.loadData();
    this.SelectorOpen = false;
  }
  ngOnChanges() {
    if (!this.selected && this.selectedItem != 'None') {
      this.isNoneSelected = true;
      this.selectedItem = (this.selectText == undefined) ? (this.options == undefined ? "Select" : this.options[0].Name) : this.selectText;
      this.validateCustomControls();

    }
    setTimeout(() => {
      window.dropdownComponent();
    }, 0);
  }
  callbackFunction(agreed: any) {
    this.callback.emit(agreed);
  }

  loadData() {
    this.options?.forEach(opt => {
      opt.IsSelected = false;

    });

    if (this.options && (this.selected || this.isIdzero)) {
      if (this.modelType == 'object') {
        var selectedItem = this.options.filter(x => x.Id == this.selected.Id)[0];

      } else {
        if (this.isTeamMemberValue) {
          var selectedItem = this.options.filter(x => x.Name == this.selected)[0];
        }
        else {
          var selectedItem = this.options.filter(x => x.Id == this.selected)[0];
        }
      }
      if (this.modelType == 'object' && (this.selected.AppAccessScopeCode != 0)) {
        var selectedItem = this.options.filter(x => x.AppAccessScopeCode == this.selected.AppAccessScopeCode)[0];

      } 
      if (selectedItem) {
        this.selectedItem = selectedItem.Name;
        selectedItem.IsSelected = true;
        this.isNoneSelected = false;
      }
      else {
        this.isNoneSelected = true;
        this.selectedItem = (this.selectText == undefined) ? (this.options == undefined ? "Select" : this.options[0].Name) : this.selectText;

      }
    }
    else {
      this.isNoneSelected = true;
      this.selectedItem = (this.selectText == undefined) ? (this.options == undefined ? "Select" : this.options[0].Name) : this.selectText

    }   
    this.validateCustomControls();

  }

  validateCustomControls() {

    if (this.required && this.name && this.formname) {
      let dropDownField = this.formname.controls[this.name];
      if (dropDownField) {
        if (this.selected == null) {
          dropDownField.setErrors({ 'incorrect': true });
          dropDownField.setValidators([Validators.required])
        } else {
          dropDownField.setValidators([]);
          dropDownField.updateValueAndValidity()
        }
      }
    }
  }

  openSelector = function () {
    //if (this.SelectorOpen) {
    //  this.SelectorOpen = false;
    //}
    //else {
      this.SelectorOpen = true;
   // }
    this.query = "";
  }

  onSelect = function (option) {
    this.formname?.controls[this.name]?.markAsDirty();
    this.options.forEach(opt => {
      opt.IsSelected = false;
    });   
    if (option) {
      option.IsSelected = true;
      if (this.isTeamMemberValue) {
        this.selected = this.modelType == 'object' ? option : option.Name;
      }
      else {
        this.selected = this.modelType == 'object' ? option : option.Id;
      }
      if (this.endToEndType != 'C' && this.name == "rde" && option.Id == 4) {
        this.sharedService.openAlertPopup("EndToEnd cannot be mapped to Avanade RDE. Please choose a different RDE.", window.scrollY, "Ok", false);
        this.selected = null;
      }
    }
   
    else {
      this.selected = null;
    }
    this.value = this.selected;
    this.onChange(this.value);
    this.loadData();
    this.SelectorOpen = false;
    this.validateCustomControls();
    $(".multiselect-container").animate({ scrollTop: 0 }, "fast");
  }

  searchDropDownKeyPress(option: any, event: any, ariaLabelledby: string) {
    if (event != null) {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == 13) { // code for ENTER key
        this.onSelect(option);
        setTimeout(function () {
          $('button[name="searchdropdown_btn_' + this.ariaLabelledby + '"]').focus();
        });
      }
      else if (keycode == 27) { //ESC key check
        this.SelectorOpen = false;
        setTimeout(function () {
          $('button[name="searchdropdown_btn_' + this.ariaLabelledby + '"]').focus();
        });
      }
      else if (keycode == 9) { //TAB key check
       // this.searchFocusFirstElement(ariaLabelledby, last);
      }
      else {
        return;
      }
    }
  }

  searchFocusFirstElement(ariaLabelledby: string, last: boolean) {
    if (last == true) {
      setTimeout(function () {
        $('a[name="' + ariaLabelledby + '_focus_0"]').focus();
      });
    }
  }

  @Input('value') _value = false;
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;

  }


  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
      this.loadData();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  trackByName(selectedRow: any): string {
    return selectedRow.Name;
  }
}





