import { Component, ChangeDetectorRef } from '@angular/core';
import { TileService } from '../../core/services/tile.service';
import { SessionStorage } from '../../core/context/sessionstorage';
import { Router, ActivatedRoute } from '@angular/router';
import { RIMSummaryService } from '../../core/services/rim.summary.service';
import { BroadCastService } from '../../core/services/broadcast.service';

@Component({
  templateUrl: './child.tile.component.html',
  providers: [TileService]
})

export class ChildtileComponent {

  Tiles: any;
  ChildTiles: any;
  rimResponse: any;
  constructor(private tileService: TileService, private sessionStorage: SessionStorage, private router: Router, private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef, private rimSummaryService: RIMSummaryService, private broadCastService:
      BroadCastService) {
  }

  ngOnInit() {
    if (!this.sessionStorage.endToEndUId) {
      var tabCode = "MyWizard/Back/Manage";
      if (this.activatedRoute.snapshot.params.tileId == "98")
        tabCode = "MyWizard/Back/Management";
      if (this.activatedRoute.snapshot.params.tileId == "176")
        tabCode = "MyWizard/Back/Initiate";
      this.router.navigate([tabCode], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId } });
      return false;
    }
    this.broadCastService.broadcastScopeSelector({
      isScopeSelectorVisible: true,
      isScopeSelectorDropDownVisible: true,
      isClientDropDownDisabled: false,
      isSetAsDefaultVisible: false,
      isDisableTeamScopeSelector: false
    });;
    this.tileService.getTileforParent(this.activatedRoute.params["value"].tileId).subscribe((response) => {
      this.ChildTiles = response;
      this.ref.markForCheck();
    });

    if ((this.activatedRoute.params["value"].tileId) == 98) {
      this.tileService.getRIM().subscribe(response => { 
        this.rimResponse = response;
        this.rimSummaryService.broadcastRIMSummary(response);
      });
    }
  }

  OnBackClick() {
    var pageDetail = this.GetAreaDetail();
    this.router.navigate(['/mywizard/manage', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });//TODO : testing purpose
  }

  GetAreaDetail() {
    if (this.ChildTiles != null && this.ChildTiles != undefined && this.ChildTiles.ChildTiles.length > 0) {
      return this.ChildTiles.ChildTiles[0].Area;
    }
  }

  trackByName(selectedRow: any): string {
    return selectedRow.Name;
  }
}

