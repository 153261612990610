<ng-container *ngIf="tmsHierarchy.Type == 'TMS'">
  <form  id="tmsAddEditForm" name="tmsAddEditForm" #tmsAddEditForm="ngForm" novalidate (ngSubmit)="SaveTMS(tmsAddEditForm.valid,tmsAddEditForm)">
    <div class="c-flyout c-contract__flyout -open" tabindex="-1" aria-labelledby="flyoutTitle" aria-modal="true" role="dialog" style="visibility:visible;">
      <div class="c-flyout__header -client-header">
        <div class="c-flyout-head__title">
          <div class="-flyoutTitle" id="flyoutTitle">{{PopUpTitle}}</div>
          <span>Add your details below</span>
        </div>
        <button type="button" class="-dismiss-flyout" aria-label="Close" alt="Close" (keypress)="ClosePopUp(tmsAddEditForm,$event)" (click)="ClosePopUp(tmsAddEditForm,$event)"></button>
      </div>
      <div class="c-flyout__body -customScroll">
        <div class="c-form-tab">
          <div class="c-form-tab__wrapper">
            <div class="c-scrollable-tabs">
              <span class="c-scrollable-tabs__arrow icon-chevron-left -left -hidden"></span>
              <div class="c-scrollable-tabs__container-wrapper">
                <div class="c-scrollable-tabs__container" role="tablist" style="position: relative; transform: translateX(0px);">
                  <button class="c-form-tab__item" type="button" role="tab" [attr.aria-controls]="popupTMSDetailsTab" aria-selected="true" [ngClass]="{'active':isActive, 'tab-highlight-error' : tmsAddEditForm.submitted && tmsName.errors?.required}" aria-label="Team Details">Team Details </button>
                  <button class="c-form-tab__item" type="button" role="tab" [attr.aria-controls]="popupTMSMembersTab" aria-selected="false" aria-label="Team Members" [ngClass]="{'DisabledTab disable-tab-color' : tmsDetail.PhoenixCreationIndicator }" (click)="GetRddaTeamMembers()">Team Members</button>
                </div>
              </div>
              <span class="c-scrollable-tabs__arrow icon-chevron-right -right -hidden"></span>
            </div>
          </div>
        </div>
        <div role="tabpanel" id="{{popupTMSDetailsTab}}" class="-show">
          <div class="c-myWizard-form__input">
            <div class="c-myWizard-form__input-area">
              <div class="o-input   -required   ">
                <label for="team_name">Team Name</label>
                <input type="text"
                       class="o-input__input"
                       placeholder="Enter team name"
                       value=""
                       id="team_name"
                       [disabled]="RoleAccess=='V' || tmsDetail.PhoenixCreationIndicator"
                       [ngClass]="{'has-error':tmsAddEditForm.submitted && tmsName.errors?.required}"
                       maxlength="50" autocomplete="off" required name="tmsName"
                       #tmsName="ngModel" [(ngModel)]="tmsDetail.TMSName" />
                <p aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':tmsAddEditForm.submitted && tmsName.errors?.required}">Please enter Team Name</p>
                <p class="o-input__char-text">30/30 characters</p>
              </div>
            </div>
            <div class="c-myWizard-form__input-area">
              <div class="c-custom-select__wrapper   ">
                <label for="TeamStatuslbl">Status</label>
                <search-dropdown id="TeamStatuslbl"
                                 name="TeamStatuslbl"
                                 #TeamStatuslbl="ngModel"
                                 [disableDropdown]="teamStatusDropDownDisable ||  RoleAccess=='V'|| tmsDetail.PhoenixCreationIndicator"
                                 [(ngModel)]="teamStatus.Id"
                                 [options]="StatusFlag"
                                 [formname]="tmsAddEditForm"
                                 [selected]="teamStatus.Id"
                                 [isShow]="true"
                                 [labelInfo]="'Status'" label="'Status'">

                </search-dropdown>
              </div>
            </div>
            <div class="c-myWizard-form__input-area">
              <div class="o-input-date  ">
                <label for="startDate">Start Date</label>
                <input type="text"
                       name="startdate"
                       #startdate="ngModel"
                       (onSelectDate)="tmsDetail.StartDate = $event.value;"
                       data-described="Calendar_Control_tmsstartdate"
                       [required]="tmsDetail?.EndDate?.length > 0"
                       [disabled]="!IsEditable"
                       id="tmsstartdate"
                       datepicker
                       [ngClass]="{'has-error':(!DateValidation.IsValidStartDate)
                       || (!DateValidation.IsValidStartDateEndDate)
                       || tmsAddEditForm.submitted
                       && (startdate.errors?.required 
                       || dayjs(startdate, 'DD MMM YYYY', true).isValid()
                       || !validateDateFormat(tmsDetail.StartDate)
                       || !DateValidation.IsValidStartDate
                       || !DateValidation.IsValidStartDateEndDate
                       || !startDateValue)}"
                       class="o-input-date__input"
                       placeholder="DD MMM YYYY"
                       value=""
                       [(ngModel)]="tmsDetail.StartDate"
                       aria-label="Start Date"
                       autocomplete="off"
                       maxlength="11" />

                <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text"
                     [ngClass]="{'is-error':(!DateValidation.IsValidStartDate)
                     || (!DateValidation.IsValidStartDateEndDate)
                     || (tmsAddEditForm.submitted
                     && (startdate.invalid
                     || dayjs(startdate, 'DD MMM YYYY', true).isValid()
                     || !validateDateFormat(tmsDetail.StartDate)
                     || !DateValidation.IsValidStartDate
                     || !DateValidation.IsValidStartDateEndDate
                     || !startDateValue))}">
                  <span *ngIf="startdate.invalid">Please enter Start Date</span>
                  <span *ngIf="tmsDetail.StartDate != null && tmsDetail.StartDate != '' && !validateDateFormat(tmsDetail.StartDate) || !startDateValue">Please enter the date in "dd mmm yyyy" format</span>
                  <span *ngIf="!DateValidation.IsValidStartDate && tmsDetail.StartDate != null && tmsDetail.StartDate != '' && DateValidation.IsValidStartDateEndDate">
                    Team Start Date is lesser than E2E Start Date ({{EndToEndStartDate}})
                  </span>
                  <span *ngIf="!DateValidation.IsValidStartDateEndDate && tmsDetail.StartDate != null && tmsDetail.StartDate != '' && DateValidation.IsValidStartDate">
                    Team Start Date is greater than E2E End Date ({{EndToEndEndDate}})
                  </span>
                </div>
                <p id="Calendar_Control_tmsstartdate" class="screenReaderOnly">
                  1.Ctrl + UP/DOWN/LEFT/RIGHT keys for move to previous / next day<br />
                  2.PAGE UP/PAGE DOWN keys for move to previous / next month<br />
                  3.SHIFT + CTRL + PAGE UP/PAGE DOWN keys for move to previous/ next year
                </p>
              </div>
            </div>

            <div class="c-myWizard-form__input-area">
              <div class="o-input-date  ">
                <label for="endDate">End Date</label>
                <input type="text"
                       name="enddate"
                       #enddate="ngModel"
                       (onSelectDate)="tmsDetail.EndDate = $event.value;"
                       data-described="Calendar_Control_tmsenddate"
                       [required]="tmsDetail.StartDate != null && tmsDetail.StartDate != ''"
                       [disabled]="!IsEditable"
                       id="tmsenddate"
                       datepicker
                       [ngClass]="{'has-error': (!DateValidation.IsValidEndDate)
                       ||(!DateValidation.IsValidEndDateStartDate)
                       ||(tmsAddEditForm.submitted
                       && ((enddate.invalid && !startdate.invalid)
                       || dayjs(enddate, 'DD MMM YYYY', true).isValid()
                       || !validateDateFormat(tmsDetail.EndDate)
                       || !DateValidation.IsValidEndDate
                       || !DateValidation.IsValidEndDateStartDate
                       || !endDateValue))}"
                       maxlength="11"
                       class="o-input-date__input"
                       placeholder="DD MMM YYYY"
                       value=""
                       [(ngModel)]="tmsDetail.EndDate"
                       aria-label="End Date"
                       autocomplete="off" />
                <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text"
                     [ngClass]="{'is-error':(!DateValidation.IsValidEndDate) || (!DateValidation.IsValidEndDateStartDate) || (tmsAddEditForm.submitted && ((enddate.invalid && !startdate.invalid) || !validateDateFormat(tmsDetail.EndDate) || !DateValidation.IsValidEndDate || !DateValidation.IsValidEndDateStartDate || !endDateValue))}">
                  <span *ngIf="(enddate.invalid && !startdate.invalid)">Please enter End Date</span>
                  <span *ngIf="tmsDetail.EndDate != null && tmsDetail.EndDate != '' && !validateDateFormat(tmsDetail.EndDate) || !endDateValue">Please enter the date in "dd mmm yyyy" format</span>
                  <span *ngIf="!DateValidation.IsValidEndDate">Team End Date is greater than E2E End Date ({{EndToEndEndDate}}) </span>
                  <span *ngIf="!DateValidation.IsValidEndDateStartDate">Team End Date is lesser than E2E Start Date ({{EndToEndStartDate}})</span>
                </div>
                <p id="Calendar_Control_tmsenddate" class="screenReaderOnly">
                  1.Ctrl + UP/DOWN/LEFT/RIGHT keys for move to previous / next day<br />
                  2.PAGE UP/PAGE DOWN keys for move to previous / next month<br />
                  3.SHIFT + CTRL + PAGE UP/PAGE DOWN keys for move to previous/ next year
                </p>

              </div>
            </div>

            <div class="c-myWizard-form__input-area">
              <div class="c-custom-select__wrapper -required">
                <label for="report_portfolio">Team Reporting Portfolio</label>
                <div class="c-dropdown" id="TeamReportSearchDropdown">
                  <search-dropdown id="TeamReportSearchDropdown"
                                   name="TeamReportSearchDropdown"
                                   #TeamReportSearchDropdown="ngModel"
                                   [options]="tmsHierarchy.DeliveryAccountability"
                                   [disableDropdown]="RoleAccess=='V' || tmsDetail.PhoenixCreationIndicator"
                                   [formname]="tmsAddEditForm"
                                   [errormessage]="'Please select Team Reporting Portfolio'"
                                   [selected]="tmsDetail.SelectedDeliveryAccountability" [selectText]="'Select'"
                                   (callback)="AccountabilityChange()" [isShow]="true" [isIdzero]="true"
                                   [labelInfo]="'Team_Reporting_Portfolio_Type'" [required]="tmsDetail.SelectedDeliveryAccountability == null" required-aria="true" label="'Team Reporting Portfolio Type'"
                                   [(ngModel)]="tmsDetail.SelectedDeliveryAccountability">
                  </search-dropdown>
                </div>
              </div>
            </div>
            <div class="c-myWizard-form__input-area">
              <div class="c-custom-select__wrapper" [ngClass]="(tmsDetail.SelectedDeliveryAccountability != null && tmsDetail.SelectedDeliveryAccountability != 0 && tmsDetail.SelectedDeliveryAccountability != 4) ? '-required' : ''">
                <label for="location">Location</label>
                <div class="c-dropdown" id="location">
                  <search-dropdown id="TeamReportTypeSearchDropdown"
                                   name="TeamReportTypeSearchDropdown"
                                   #TeamReportTypeSearchDropdown="ngModel"
                                   [options]="tmsHierarchy.DeliveryAccountabilityTypes"
                                   [selectText]="'Select'" [formname]="tmsAddEditForm" [errormessage]="'Please select Team Reporting Portfolio'"
                                   [disableDropdown]="IsDisableAccType || RoleAccess=='V' || tmsDetail.PhoenixCreationIndicator"
                                   [required]="tmsDetail.SelectedDeliveryAccountability != null && tmsDetail.SelectedDeliveryAccountability != 0 && tmsDetail.SelectedDeliveryAccountability != 4 && tmsHierarchy.Type == 'TMS'"
                                   [selected]="tmsDetail.SelectedDeliveryAccountabilityType" [isShow]="true"
                                   (callback)="AccountabilityTypeChange()"
                                   [labelInfo]="'Team_Reporting_Portfolio_Type'" required-aria="true" label="'Location'"
                                   [(ngModel)]="tmsDetail.SelectedDeliveryAccountabilityType">
                  </search-dropdown>
                </div>
                <p class="c-select__error-text">Enter the value</p>
              </div>
            </div>

            <div class="c-myWizard-form__input-area" *ngIf="!IsDisableAGDGDU && tmsDetail.SelectedDeliveryAccountabilityType">
              <div class="c-custom-select__wrapper" [ngClass]="(IsDisableAGDGDU == false && tmsDetail.SelectedAccountGroupId == null)? '-required':''">
                <label for="report_portfolio">Account Group</label>
                <div class="c-dropdown" id="report_portfolio">
                  <search-dropdown id="AccountGroupsSearchDropdown"
                                   name="AccountGroupsSearchDropdown"
                                   #AccountGroupsSearchDropdown="ngModel"
                                   [options]="TempAccountGroups"
                                   [selectText]="'Select'"
                                   [formname]="tmsAddEditForm"
                                   [errormessage]="'Please select Account Group'"
                                   [disableDropdown]="IsDisableAGDGDU || RoleAccess=='V'"
                                   [required]="IsDisableAGDGDU == false && tmsDetail.SelectedAccountGroupId == null"
                                   [selected]="tmsDetail.SelectedAccountGroupId"
                                   [isShow]="true"
                                   (callback)="AccountGroupChangeForTMS()"
                                   [filterId]="tmsDetail.SelectedDeliveryAccountabilityType"
                                   [(ngModel)]="tmsDetail.SelectedAccountGroupId">
                  </search-dropdown>
                </div>
                <p class="c-select__error-text">Enter the value</p>
              </div>
            </div>

            <div class="c-myWizard-form__input-area" *ngIf="!IsDisableAGDGDU && tmsDetail.SelectedDeliveryAccountabilityType">
              <div class="c-custom-select__wrapper" [ngClass]="(IsDisableAGDGDU == false && tmsDetail.SelectedDeliveryGroupId == null)? '-required':''">
                <label for="report_portfolio">Delivery Group</label>
                <div class="c-dropdown" id="report_portfolio">
                  <search-dropdown id="DeliveryGroupsSearchDropdown"
                                   name="DeliveryGroupsSearchDropdown"
                                   #DeliveryGroupsSearchDropdown="ngModel"
                                   [options]="TempDeliveryGroups" [selectText]="'Select'" [formname]="tmsAddEditForm" [errormessage]="'Please select Delivery Group'"
                                   [disableDropdown]="IsDisableAGDGDU || RoleAccess=='V'" [required]="IsDisableAGDGDU == false && tmsDetail.SelectedDeliveryGroupId == null"
                                   [selected]="tmsDetail.SelectedDeliveryGroupId" [isShow]="true"
                                   (callback)="DeliveryGroupChangeForTMS()"
                                   [filterId]="tmsDetail.SelectedAccountGroupId"
                                   [(ngModel)]="tmsDetail.SelectedDeliveryGroupId">
                  </search-dropdown>
                </div>
                <p class="c-select__error-text">Enter the value</p>
              </div>
            </div>

            <div class="c-myWizard-form__input-area" *ngIf="!IsDisableAGDGDU && tmsDetail.SelectedDeliveryAccountabilityType">
              <div class="c-custom-select__wrapper" [ngClass]="(IsDisableAGDGDU == false && tmsDetail.SelectedDeliveryUnitId == null)? '-required':''">
                <label for="report_portfolio">Delivery Unit</label>
                <div class="c-dropdown" id="report_portfolio">
                  <search-dropdown id="DeliveryUnitsSearchDropdown"
                                   name="DeliveryUnitsSearchDropdown"
                                   #DeliveryUnitsSearchDropdown="ngModel"
                                   [options]="TempDeliveryUnits" [selectText]="'Select'" [formname]="tmsAddEditForm" [errormessage]="'Please select Delivery Unit'"
                                   [disableDropdown]="IsDisableAGDGDU || RoleAccess=='V'" [required]="IsDisableAGDGDU == false && tmsDetail.SelectedDeliveryUnitId == null"
                                   [selected]="tmsDetail.SelectedDeliveryUnitId" [isShow]="true"
                                   [filterId]="tmsDetail.SelectedDeliveryGroupId"
                                   [(ngModel)]="tmsDetail.SelectedDeliveryUnitId">
                  </search-dropdown>
                </div>
                <p class="c-select__error-text">Enter the value</p>
              </div>
            </div>
            <div class="c-myWizard-form__input-area" *ngIf="!IsDisableAGDGDU && tmsDetail.SelectedDeliveryAccountabilityType">
              <div class="c-custom-select__wrapper">
                <label for="report_portfolio">Interaction Style</label>
                <div class="c-dropdown" id="report_portfolio">
                  <!--<select name="interactionStyle" #interactionStyle="ngModel" [disabled]="RoleAccess=='V' || IsDisableAGDGDU"
                class="form-control" [ngClass]="{'form-control-static-label':RoleAccess=='V'}" role="listbox"
                [(ngModel)]="tmsDetail.SelectedInteractionStyleId">
          <option role="option" [value]='null'>--Select--</option>
          <option *ngFor="let item of tmsHierarchy.InteractionStyle | orderBy:'Name'; trackBy : trackById;" [value]="item.Id">{{item.Name}}</option>
        </select>-->
                  <search-dropdown id="interactionStyle"
                                   name="interactionStyle"
                                   #interactionStyle="ngModel"
                                   [options]="tmsHierarchy.InteractionStyle"
                                   [selectText]="'Select'"
                                   [formname]="tmsAddEditForm"
                                   [disableDropdown]="RoleAccess=='V' || IsDisableAGDGDU"
                                   [required]="false"
                                   [selected]="tmsDetail.SelectedInteractionStyleId"
                                   [isShow]="true"
                                   [(ngModel)]="tmsDetail.SelectedInteractionStyleId">
                  </search-dropdown>
                </div>
                <p class="c-select__error-text">Enter the value</p>
              </div>
            </div>
          </div>
          <div class="c-list-table">
            <div class="-add-team -customScroll ">
              <div class="c-list-header">
                <div class="c-list-moveable">&nbsp;</div>
                <div class="c-list-text">Team Involvement</div>
                <div class="c-list-input -required"><span>POC/Team Lead</span></div>
              </div>
            </div>
            <ul>
              <li class="c-list_content">
                <div class="c-list" *ngIf="EndToEndType == 'I'" click-elsewhere="ResetSelection()" id="teamleadinitiative">
                  <div class="c-list-moveable">
                    <div class="c-checkbox  ">
                      <input type="checkbox" class="c-checkbox__input" checked disabled id="TeamInvolve_Info" name="TeaminvolvementInitiative" aria-labelledby="team_involvement initiative" />
                      <i class="c-checkbox__checked"></i>
                    </div>
                    <!--<input type="checkbox"
                           id="TeamInvolve_Info"
                           name="TeaminvolvementInitiative"
                           [disabled]="true"
                           class="associationCheckbox"
                           checked
                           aria-labelledby="team_involvement initiative" />-->
                  </div>

                  <div class="c-list-text">
                    <span tabindex="0" id="initiative" aria-label="Initiative">
                      {{EndToEndName}}
                    </span>
                  </div>
                  <div class="c-list-input" style="top: 15px !important" aria-live="polite" aria-atomic="true" role="status"
                       [ngClass]="{'is-error':tmsAddEditForm.submitted && teamLead.errors?.required}">
                    <div class="o-input">
                      <auto-complete id="teamLead" hiddenFieldID="hiddenTeamLead" required
                                     #teamLead="ngModel" name="teamLead" [disabled]="RoleAccess=='V'"
                                     class="pocDetails"
                                     ariaLabelledby="teamleadinitiative poc_teamlead initiative"
                                     [selected]="tmsDetail.TMSLead"
                                     [(ngModel)]="tmsDetail.TMSLead"
                                     [ngClass]="{'is-error':tmsAddEditForm.submitted && teamLead.errors?.required}">
                      </auto-complete>
                      <p class="o-input__error-text" [ngClass]="{'is-error':tmsAddEditForm.submitted && teamLead.errors?.required}">Please enter POC/Team Lead</p>
                    </div>
                  </div>
                </div>
                <div class="c-list" *ngIf="EndToEndType == 'O'" click-elsewhere="ResetSelection()" id="teamleadopportunity">
                  <div class="c-list-moveable">
                    <div class="c-checkbox  ">
                      <input type="checkbox" class="c-checkbox__input" checked disabled id="TeamInvolve_Opportunity" name="Teaminvolvementopportunity" aria-labelledby="team_involvement opportunity" />
                      <i class="c-checkbox__checked"></i>
                    </div>
                    <!--<input type="checkbox"
                           id="TeamInvolve_Opportunity"
                           name="Teaminvolvementopportunity"
                           [disabled]="true"
                           class="associationCheckbox"
                           checked
                           aria-labelledby="team_involvement opportunity" />-->
                  </div>

                  <div class="c-list-text">
                    <span tabindex="0" id="opportunity" aria-label="Opportunity">
                      {{EndToEndRefCode}} : {{EndToEndName}}
                    </span>
                  </div>
                  <div class="c-list-input" style="top: 15px !important" aria-live="polite" aria-atomic="true" role="status"
                       [ngClass]="{'is-error':tmsAddEditForm.submitted && teamLead.errors?.required}">
                    <div class="o-input">
                      <auto-complete id="teamLead" hiddenFieldID="hiddenTeamLead" required
                                     #teamLead="ngModel" name="teamLead" [disabled]="RoleAccess=='V'"
                                     class="pocDetails"
                                     ariaLabelledby="teamleadopportunity poc_teamlead opportunity"
                                     [selected]="tmsDetail.TMSLead"
                                     [(ngModel)]="tmsDetail.TMSLead"
                                     [ngClass]="{'is-error':tmsAddEditForm.submitted && teamLead?.errors?.required}">
                      </auto-complete>
                      <p class="o-input__error-text" [ngClass]="{'is-error':tmsAddEditForm.submitted && teamLead.errors?.required}">Please enter POC/Team Lead</p>
                    </div>
                  </div>
                </div>
                <div class="c-list" *ngIf="EndToEndType == 'C'" click-elsewhere="ResetSelection()" id="teamleadcontract">
                  <div class="c-list-moveable">
                    <div class="c-checkbox  ">
                      <input type="checkbox" class="c-checkbox__input" checked disabled id="TeamInvolve_Contract" name="Teaminvolvementcontract" aria-labelledby="team_involvement contract" />
                      <i class="c-checkbox__checked"></i>
                    </div>
                    <!--<input type="checkbox"
                           id="TeamInvolve_Contract"
                           name="Teaminvolvementcontract"
                           [disabled]="true"
                           class="associationCheckbox"
                           checked
                           aria-labelledby="team_involvement contract" />-->
                  </div>
                  <div class="c-list-text"><span tabindex="0" id="contract" aria-label="Contract">{{EndToEndRefCode}} : {{EndToEndName}}</span></div>
                  <div class="c-list-input" style="top: 15px !important" aria-live="polite" aria-atomic="true" role="status"
                       [ngClass]="{'is-error':tmsAddEditForm.submitted && teamLead.errors?.required}">
                    <div class="o-input    ">
                      <auto-complete id="teamLead" hiddenFieldID="hiddenTeamLead" required
                                     #teamLead="ngModel" name="teamLead" [disabled]="RoleAccess=='V'" class="pocDetails"
                                     ariaLabelledby="teamleadcontract poc_teamlead contract"
                                     [selected]="tmsDetail.TMSLead"
                                     [(ngModel)]="tmsDetail.TMSLead"
                                     [ngClass]="{'is-error':tmsAddEditForm.submitted && teamLead?.errors?.required}">
                      </auto-complete>
                      <p class="o-input__error-text" [ngClass]="{'is-error':tmsAddEditForm.submitted && teamLead.errors?.required}">Please enter POC/Team Lead</p>
                    </div>
                  </div>
                </div>

              </li>
              <li class="c-list_content cms-tms-item-leaf cms-tms-item-no-nesting" *ngFor="let ws0 of tmsDetail.WorkStreamStructure; trackBy : trackByWorkstreamId; let indW0=index">
                <div class="c-list" [attr.data-type]="'Workstream'" [ngClass]="{'cms-tms-item-selected' : ws0.IsSelected}" id="POCworkstream0">
                  <div class="c-list-moveable">
                    <div class="c-checkbox">
                      <input type="checkbox"
                             id="ws0SelectionMode_{{indW0}}" name="ws0SelectionMode_{{indW0}}"
                             #selectionMode="ngModel"
                             
                             [disabled]="RoleAccess=='V' || ws0.WorkstreamStatusName == 'Closed' || tmsDetail.PhoenixCreationIndicator"
                             [(ngModel)]="ws0.SelectionMode"
                             [attr.data-selectionmode]="ws0.SelectionMode"
                             [value]="ws0.SelectionMode"
                             class="associationCheckbox c-checkbox__input"
                             (change)="ws0.SelectionMode = changeCheckboxValue($event);ChangeSelectionMode(ws0.SelectionMode,ws0,null,null,$event)"
                             aria-labelledby="team_involvement ws0rowid" />
                      <i class="c-checkbox__checked" [attr.data-selectionmode]="ws0.SelectionMode"></i>
                      <!--<i class="c-checkbox__checked"  [attr.data-selectionmode]="ws0.SelectionMode"></i>-->
                      <!--<i class="c-checkbox__checked"></i>-->
                      <!--class="c-checkbox__input"-->
                      <!--<span (click)="ChangeSelectionMode(ws0.SelectionMode,ws0,null,null,$event)"
                      (keypress)="ChangeSelectionMode(ws0.SelectionMode,ws0,null,null,$event)"
                      tabindex="0" role="checkbox" aria-checked="false" aria-labelledby="CmsTmsWs team_involvement"></span>-->
                    </div>
                  </div>
                  <div class="c-list-text">
                    <!--<img src="../assets/toolkit/images/client-structure/agile.svg" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>-->
                    <span tabindex="0" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!='')">{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}},&nbsp;<span class="small">{{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}}</span></span>
                    <span tabindex="0" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate=='')">{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}</span>
                    <div id="ws0rowid" class="c-list-text-item" tabindex="0" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!='')"><img class="workstream-img" [attr.data-myw-title]="ws0.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="Workstream Type-{{ws0.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;&nbsp;({{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}})</span></div>
                    <div tabindex="0" class="c-list-text-item" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate=='')"><img class="workstream-img" [attr.data-myw-title]="ws0.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="Workstream Type-{{ws0.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;&nbsp;</span></div>
                  </div>
                  <div class="c-list-input">
                    <div class="o-input    ">
                      <auto-complete id="ws0rowid_teamLead_{{indW0}}" hiddenFieldID="hidden_ws0rowid_teamLead_{{indW0}}"
                                     #pocTeamLead="ngModel" name="ws0rowid_teamLead_{{indW0}}" class="pocDetails"
                                     [disabled]="ws0.SelectionMode != 'Full' || RoleAccess=='V' || ws0.WorkstreamStatusName == 'Closed' || tmsDetail.PhoenixCreationIndicator"
                                     ariaLabelledby="POCworkstream0 poc_teamlead ws0rowid"
                                     [selected]="ws0.POC"
                                     [(ngModel)]="ws0.POC">
                      </auto-complete>
                    </div>
                  </div>
                </div>
              </li>
              <li class="c-list_content open" *ngFor="let cms1 of tmsDetail.CMSLevelOne; trackBy : trackByCMSId ; let indC1=index">
                <div class="c-list c-list-child expand" [attr.data-type]="'CMS'" id="POCCMS1">
                  <div class="c-list-moveable">
                    <div class="c-checkbox  ">
                      <input type="checkbox"
                             id="cms1SelectionMode_{{indC1}}"
                             name="cms1SelectionMode_{{indC1}}"
                             #cms1SelectionMode="ngModel"
                             [disabled]="RoleAccess=='V' || tmsDetail.PhoenixCreationIndicator"
                             [(ngModel)]="cms1.SelectionMode"
                             [attr.data-selectionmode]="cms1.SelectionMode"
                             aria-labelledby="team_involvement cms1rowid"
                             [value]="cms1.SelectionMode"
                             class="associationCheckbox c-checkbox__input"
                             (change)="cms1.SelectionMode = changeCheckboxValue($event); ChangeSelectionMode(cms1.SelectionMode,null,cms1,null,$event)" />
                      <i class="c-checkbox__checked" [attr.data-selectionmode]="cms1.SelectionMode"></i>
                      <!--<i class="c-checkbox__checked"></i>-->
                      <!--<span (click)="ChangeSelectionMode(cms1.SelectionMode,null,cms1,null,$event)" (keypress)="ChangeSelectionMode(cms1.SelectionMode,null,cms1,null,$event)" role="checkbox" aria-checked="false" tabindex="0" aria-labelledby="cmsTmsCheck1 team_involvement"></span>-->
                    </div>
                  </div>
                  <div class="c-list-text cms-tms-item-text">
                    <a href="javascript:;" class="icon-arrow-down -expand" *ngIf="cms1.CMSLevelTwo.length > 0 || cms1.WorkStreamStructure.length > 0" aria-lable="Expand" data-myw-title="Expand" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                    <!--<img src="../assets/toolkit/images/client-structure/io.svg" alt="IO"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>-->
                    <span tabindex="0" id="cms1rowid" class="cms-tms-item-text" [ngClass]="{'closed-cms' : cms1.CmsStatusId == 2}">{{cms1.CmsStatusId == 2 ? "[CLOSED]" + cms1.CMSName : cms1.CMSName}}</span>
                  </div>
                  <div class="c-list-input">
                    <div class="o-input    ">
                      <auto-complete id="cms1rowid_teamLead_{{indC1}}"
                                     hiddenFieldID="hidden_cms1rowid_teamLead_{{indC1}}"
                                     #pocTeamLead="ngModel"
                                     name="cms1rowid_teamLead_{{indC1}}"
                                     class="pocDetails"
                                     [disabled]="cms1.SelectionMode != 'Full' || RoleAccess=='V' ||cms1.CmsStatusId == 2 || tmsDetail.PhoenixCreationIndicator"
                                     ariaLabelledby="POCCMS1 poc_teamlead cms1rowid"
                                     [selected]="cms1.POC"
                                     [(ngModel)]="cms1.POC">
                      </auto-complete>
                    </div>
                  </div>
                </div>
                <ul class="open">
                  <li class="c-list_content open" *ngFor="let ws1 of cms1.WorkStreamStructure; trackBy : trackByWorkstreamId; let indW1=index">
                    <div class="c-list" [attr.data-type]="'Workstream'" id="POCworkstream1">
                      <div class="c-list-moveable">
                        <div class="c-checkbox  ">
                          <input type="checkbox"
                                 id="ws1SelectionMode_{{indC1 + '_' + indW1}}"
                                 name="ws1SelectionMode_{{indC1 + '_' + indW1}}"
                                 #ws1SelectionMode="ngModel"
                                 [disabled]="RoleAccess=='V'|| ws1.WorkstreamStatusName == 'Closed' || tmsDetail.PhoenixCreationIndicator"
                                 [(ngModel)]="ws1.SelectionMode"
                                 [attr.data-selectionmode]="ws1.SelectionMode"
                                 [value]="ws1.SelectionMode"
                                 aria-labelledby="team_involvement ws1rowid"
                                 class="associationCheckbox c-checkbox__input"
                                 (change)="ws1.SelectionMode = changeCheckboxValue($event); ChangeSelectionMode(ws1.SelectionMode,ws1,null,null,$event)" />
                          <i class="c-checkbox__checked"  [attr.data-selectionmode]="ws1.SelectionMode"></i>
                          <!--<i class="c-checkbox__checked"></i>-->
                          <!--<span (click)="ChangeSelectionMode(ws1.SelectionMode,ws1,null,null,$event)" (keypress)="ChangeSelectionMode(ws1.SelectionMode,ws1,null,null,$event)" role="checkbox" aria-checked="false" tabindex="0" aria-labelledby="cmsTmsCheck2 team_involvement"></span>-->
                        </div>
                      </div>
                      <div class="c-list-text">
                        <!--<img src="../assets/toolkit/images/client-structure/io.svg" alt="IO"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>-->

                        <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!='')">{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}},&nbsp;<span class="small">{{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}}</span></span>
                        <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate=='')">{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}</span>
                        <div tabindex="0" class="c-list-text-item" id="ws1rowid" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!='')"><img class="workstream-img" [attr.data-myw-title]="ws1.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="Workstream Type-{{ws1.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;&nbsp;({{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}})</span></div>
                        <div tabindex="0" class="c-list-text-item" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate=='')"><img class="workstream-img" [attr.data-myw-title]="ws1.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="Workstream Type-{{ws1.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;&nbsp;</span></div>
                      </div>
                      <div class="c-list-input">
                        <div class="o-input    ">
                          <auto-complete id="ws1rowid_teamLead_{{indC1 + '_' + indW1}}" hiddenFieldID="hidden_ws1rowid_teamLead_{{indC1 + '_' + indW1}}"
                                         #pocTeamLead="ngModel" name="ws1rowid_teamLead_{{indC1 + '_' + indW1}}"
                                         [disabled]="ws1.SelectionMode != 'Full' || RoleAccess=='V' || ws1.WorkstreamStatusName == 'Closed' || tmsDetail.PhoenixCreationIndicator"
                                         ariaLabelledby="POCworkstream1 poc_teamlead ws1rowid"
                                         [selected]="ws1.POC"
                                         [(ngModel)]="ws1.POC">
                          </auto-complete>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li *ngFor="let cms2 of cms1.CMSLevelTwo; trackBy : trackByCMSId; let indC2=index" class="c-list_content">
                    <div class="c-list c-list-child expand" [attr.data-type]="'CMS'" id="POCCMS2">
                      <div class="c-list-moveable">
                        <div class="c-checkbox  ">
                          <input id="cms2SelectionMode_{{indC1 + '_' + indC2}}"
                                 name="cms2SelectionMode_{{indC1 + '_' + indC2}}"
                                 #cms2SelectionMode="ngModel" type="checkbox"
                                [disabled]="RoleAccess=='V' || tmsDetail.PhoenixCreationIndicator"
                                 [(ngModel)]="cms2.SelectionMode"
                                 [attr.data-selectionmode]="cms2.SelectionMode"
                                 [value]="cms2.SelectionMode"
                                 class="associationCheckbox c-checkbox__input"
                                 (change)="cms2.SelectionMode =changeCheckboxValue($event); ChangeSelectionMode(cms2.SelectionMode,null,cms2,null,$event)"
                                 aria-labelledby="team_involvement cms2rowid" />
                          <i class="c-checkbox__checked"  [attr.data-selectionmode]="cms2.SelectionMode"></i>
                          <!--<i class="c-checkbox__checked"></i>-->
                          <!--<span (click)="ChangeSelectionMode(cms2.SelectionMode,null,cms2,null,$event)" (keypress)="ChangeSelectionMode(cms2.SelectionMode,null,cms2,null,$event)" role="checkbox" aria-checked="false" aria-labelledby="cmsTmsCheck3 team_involvement"></span>-->
                        </div>
                      </div>
                      <div class="c-list-text cms-tms-item-text">
                        <a href="javascript:;" class="icon-arrow-down -expand" *ngIf="cms2.CMSLevelThree.length > 0 || cms2.WorkStreamStructure.length > 0" aria-lable="Expand" data-myw-title="Expand" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                        <!--<img src="../assets/toolkit/images/client-structure/io.svg" alt="IO"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>-->
                        <span tabindex="0" id="cms2rowid" [ngClass]="{'closed-cms' : cms2.CmsStatusId == 2}">{{cms2.CmsStatusId == 2 ? "[CLOSED]" + cms2.CMSName : cms2.CMSName}}</span>
                      </div>
                      <div class="c-list-input">
                        <div class="o-input    ">
                          <auto-complete id="cms2rowid_teamLead_{{indC1 + '_' + indC2}}"
                                         hiddenFieldID="hidden_cms2rowid_teamLead__{{indC1 + '_' + indC2}}"
                                         #pocTeamLead="ngModel" name="cms2rowid_teamLead_{{indC1 + '_' + indC2}}"
                                         [disabled]="cms2.SelectionMode != 'Full' || RoleAccess=='V' || cms2.CmsStatusId == 2 || tmsDetail.PhoenixCreationIndicator"
                                         ariaLabelledby="POCCMS2 poc_teamlead cms2rowid"
                                         [selected]="cms2.POC"
                                         [(ngModel)]="cms2.POC">
                          </auto-complete>
                        </div>
                      </div>
                    </div>
                    <ul class="open">
                      <li *ngFor="let ws2 of cms2.WorkStreamStructure; trackBy : trackByWorkstreamId; let indW2=index;" class="c-list_content open">
                        <div class="c-list" [attr.data-type]="'Workstream'" id="POCworkstream2">
                          <div class="c-list-moveable">
                            <div class="c-checkbox  ">
                              <input id="ws2SelectionMode_{{indC1 + '_'+ indC2 + '_' + indW2}}"
                                     name="ws2SelectionMode_{{indC1 + '_'+ indC2 + '_' + indW2}}"
                                     #ws2SelectionMode="ngModel" type="checkbox"  [disabled]="RoleAccess=='V' ||ws2.WorkstreamStatusName == 'Closed' || tmsDetail.PhoenixCreationIndicator"
                                     [(ngModel)]="ws2.SelectionMode" [attr.data-selectionmode]="ws2.SelectionMode" [value]="ws2.SelectionMode"
                                     class="associationCheckbox c-checkbox__input"
                                     aria-labelledby="team_involvement ws2rowid" (change)="ws2.SelectionMode = changeCheckboxValue($event); ChangeSelectionMode(ws2.SelectionMode,ws2,null,null,$event)" />
                              <i class="c-checkbox__checked" [attr.data-selectionmode]="ws2.SelectionMode" ></i>
                              <!--<i class="c-checkbox__checked"></i>-->
                              <!--<span (click)="ChangeSelectionMode(ws2.SelectionMode,ws2,null,null,$event)" (keypress)="ChangeSelectionMode(ws2.SelectionMode,ws2,null,null,$event)" role="checkbox" aria-checked="false" tabindex="0" aria-labelledby="cmsTmsCheck4 team_involvement"></span>-->
                            </div>
                          </div>
                          <div class="c-list-text">
                            <!--<img src="../assets/toolkit/images/client-structure/io.svg" alt="IO"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>-->

                            <span tabindex="0" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!='')">{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}},&nbsp;<span class="small">{{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}}</span></span>
                            <span tabindex="0" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='')">{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}</span>
                            <div tabindex="0" class="c-list-text-item" id="ws2rowid" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!='')"><img class="workstream-img" [attr.data-myw-title]="ws2.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws2.WorkstreamTypeId)" alt="Workstream Type-{{ws2.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;&nbsp;({{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}})</span></div>
                            <div tabindex="0" class="c-list-text-item" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='')"><img class="workstream-img" [attr.data-myw-title]="ws2.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws2.WorkstreamTypeId)" alt="Workstream Type-{{ws2.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;&nbsp;</span></div>
                          </div>
                          <div class="c-list-input">
                            <div class="o-input    ">
                              <auto-complete id="ws2rowidteamLead_{{indC1 + '_'+ indC2 + '_' + indW2}}" hiddenFieldID="hidden_ws2rowid_teamLead_{{indC1 + '_'+ indC2 + '_' + indW2}}"
                                             #pocTeamLead="ngModel" name="ws2rowid_teamLead_{{indC1 + '_'+ indC2 + '_' + indW2}}"
                                             [disabled]="ws2.SelectionMode != 'Full' || RoleAccess=='V' || ws2.WorkstreamStatusName == 'Closed' || tmsDetail.PhoenixCreationIndicator"
                                             ariaLabelledby="POCworkstream2 poc_teamlead ws2rowid"
                                             [selected]="ws2.POC"
                                             [(ngModel)]="ws2.POC">
                              </auto-complete>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li *ngFor="let cms3 of cms2.CMSLevelThree ; trackBy : trackByCMSId; let indC3=index" class="c-list_content open">
                        <div class="c-list c-list-child expand" [attr.data-type]="'CMS'" id="POCCMS3_{{indC3}}">
                          <div class="c-list-moveable">
                            <div class="c-checkbox  ">
                              <input id="cms3SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3}}"
                                     name="cms3SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3}}"
                                     #cms3SelectionMode="ngModel" type="checkbox"  [disabled]="RoleAccess=='V' || tmsDetail.PhoenixCreationIndicator"
                                     [(ngModel)]="cms3.SelectionMode" [attr.data-selectionmode]="cms3.SelectionMode"
                                     aria-labelledby="team_involvement cms3rowid" [value]="cms3.SelectionMode"
                                     class="associationCheckbox c-checkbox__input"
                                     (change)="cms3.SelectionMode = changeCheckboxValue($event); ChangeSelectionMode(cms3.SelectionMode,null,cms3,null,$event)" />
                              <i class="c-checkbox__checked" [attr.data-selectionmode]="cms3.SelectionMode"></i>
                              <!--<i class="c-checkbox__checked"></i>-->
                              <!--<span (click)="ChangeSelectionMode(cms3.SelectionMode,null,cms3,null,$event)" (keypress)="ChangeSelectionMode(cms3.SelectionMode,null,cms3,null,$event)" role="checkbox" aria-checked="false" tabindex="0" aria-labelledby="cmsTmsCheck5 team_involvement"></span>-->
                            </div>
                          </div>
                          <div class="c-list-text cms-tms-item-text">
                            <a href="javascript:;" class="icon-arrow-down -expand" *ngIf="cms3.CMSLevelFour.length > 0 || cms3.WorkStreamStructure.length > 0" aria-lable="Expand" data-myw-title="Expand" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                            <!--<img src="../assets/toolkit/images/client-structure/io.svg" alt="IO"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>-->

                            <span tabindex="0" id="cms3rowid" [ngClass]="{'closed-cms' : cms3.CmsStatusId == 2}">{{cms3.CmsStatusId == 2 ? "[CLOSED]" + cms3.CMSName : cms3.CMSName}}</span>
                          </div>
                          <div class="c-list-input">
                            <div class="o-input    ">
                              <auto-complete id="cm3rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3}}" hiddenFieldID="hidden_cm3rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3}}"
                                             #pocTeamLead="ngModel" name="cm3rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3}}"
                                             [disabled]="cms3.SelectionMode != 'Full' || RoleAccess=='V' || cms3.CmsStatusId == 2 || tmsDetail.PhoenixCreationIndicator"
                                             ariaLabelledby="POCCMS3 poc_teamlead cms3rowid"
                                             [selected]="cms3.POC"
                                             [(ngModel)]="cms3.POC">
                              </auto-complete>
                            </div>
                          </div>
                        </div>
                        <ul class="open">
                          <li *ngFor="let ws3 of cms3.WorkStreamStructure ; trackBy : trackByWorkstreamId; let indW3=index" class="c-list_content">
                            <div class="c-list" [attr.data-type]="'Workstream'" id="POCworkstream3">
                              <div class="c-list-moveable">
                                <div class="c-checkbox  ">
                                  <input id="ws3SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3 + '_' +indW3}}"
                                         name="ws3SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3 + '_' +indW3}}"
                                         #ws3SelectionMode="ngModel" type="checkbox" 
                                         [disabled]="RoleAccess=='V' ||ws3.WorkstreamStatusName == 'Closed' || tmsDetail.PhoenixCreationIndicator "
                                         [(ngModel)]="ws3.SelectionMode" [attr.data-selectionmode]="ws3.SelectionMode"
                                         [value]="ws3.SelectionMode" aria-labelledby="team_involvement ws3rowid"
                                         class="associationCheckbox c-checkbox__input"
                                         (change)="ws3.SelectionMode = changeCheckboxValue($event); ChangeSelectionMode(ws3.SelectionMode,ws3,null,null,$event)" />
                                  <i class="c-checkbox__checked" [attr.data-selectionmode]="ws3.SelectionMode"></i>
                                  <!--<i class="c-checkbox__checked"></i>-->
                                  <!--<span (click)="ChangeSelectionMode(ws3.SelectionMode,ws3,null,null,$event)" (keypress)="ChangeSelectionMode(ws3.SelectionMode,ws3,null,null,$event)" role="checkbox" aria-checked="false" tabindex="0" aria-labelledby="cmsTmsCheck6 team_involvement"></span>-->
                                </div>
                              </div>
                              <div class="c-list-text">
                                <!--<img src="../assets/toolkit/images/client-structure/io.svg" alt="IO"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>-->

                                <span tabindex="0" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!='')">{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}},&nbsp;<span class="small">{{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}}</span></span>
                                <span tabindex="0" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='')">{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}</span>
                                <div tabindex="0" class="c-list-text-item" id="ws3rowid" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!='')"><img class="workstream-img" [attr.data-myw-title]="ws3.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws3.WorkstreamTypeId)" alt="Workstream Type-{{ws3.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;&nbsp;({{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}})</span></div>
                                <div tabindex="0" class="c-list-text-item" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='')"><img class="workstream-img" [attr.data-myw-title]="ws3.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws3.WorkstreamTypeId)" alt="Workstream Type-{{ws3.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;&nbsp;</span></div>
                              </div>
                              <div class="c-list-input">
                                <div class="o-input    ">
                                  <auto-complete id="ws3rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' +indW3}}" hiddenFieldID="hidden_ws3rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' +indW3}}"
                                                 #pocTeamLead="ngModel" name="ws3rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' +indW3}}"
                                                 [disabled]="ws3.SelectionMode != 'Full' || RoleAccess=='V' || ws3.WorkstreamStatusName == 'Closed' || tmsDetail.PhoenixCreationIndicator"
                                                 ariaLabelledby="POCworkstream3 poc_teamlead ws3rowid"
                                                 [selected]="ws3.POC"
                                                 [(ngModel)]="ws3.POC">
                                  </auto-complete>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="c-list_content open" *ngFor="let cms4 of cms3.CMSLevelFour ; trackBy : trackByCMSId; let indC4=index">
                            <div class="c-list c-list-child expand" [attr.data-type]="'CMS'" id="POCCMS4">
                              <div class="c-list-moveable">
                                <div class="c-checkbox  ">
                                  <input id="cms4SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4}}"
                                         name="cms4SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4}}"
                                         #cms4SelectionMode="ngModel" type="checkbox" 
                                         [disabled]="RoleAccess=='V'|| tmsDetail.PhoenixCreationIndicator"
                                         [(ngModel)]="cms4.SelectionMode" [attr.data-selectionmode]="cms4.SelectionMode"
                                         [value]="cms4.SelectionMode" aria-labelledby="team_involvement cms4rowid"
                                         class="associationCheckbox c-checkbox__input"
                                         (change)="cms4.SelectionMode = changeCheckboxValue($event); ChangeSelectionMode(cms4.SelectionMode,null,cms4,null,$event)" />
                                  <i class="c-checkbox__checked" [attr.data-selectionmode]="cms4.SelectionMode"></i>
                                  <!--<i class="c-checkbox__checked"></i>-->
                                  <!--<span (click)="ChangeSelectionMode(cms4.SelectionMode,null,cms4,null,$event)" (keypress)="ChangeSelectionMode(cms4.SelectionMode,null,cms4,null,$event)" role="checkbox" aria-checked="false" tabindex="0" aria-labelledby="cmsTmsCheck7 team_involvement"></span>-->
                                </div>
                              </div>
                              <div class="c-list-text cms-tms-item-text">
                                <a href="javascript:;" class="icon-arrow-down -expand" *ngIf="cms4.CMSLevelFive.length > 0 || cms4.WorkStreamStructure.length > 0" aria-lable="Expand" data-myw-title="Expand" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                                <!--<img src="../assets/toolkit/images/client-structure/io.svg" alt="IO"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>-->

                                <span tabindex="0" id="cms4rowid" [ngClass]="{'closed-cms' : cms4.CmsStatusId == 2}">{{cms4.CmsStatusId == 2 ? "[CLOSED]" + cms4.CMSName : cms4.CMSName}}</span>
                              </div>
                              <div class="c-list-input">
                                <div class="o-input    ">
                                  <auto-complete id="cms4rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4}}" hiddenFieldID="hidden_cms4rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4}}"
                                                 #pocTeamLead="ngModel" name="cms4rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4}}"
                                                 [disabled]="cms4.SelectionMode != 'Full' || RoleAccess=='V' || cms4.CmsStatusId == 2 || tmsDetail.PhoenixCreationIndicator"
                                                 ariaLabelledby="POCCMS4 poc_teamlead cms4rowid"
                                                 [selected]="cms4.POC"
                                                 [(ngModel)]="cms4.POC">
                                  </auto-complete>
                                </div>
                              </div>
                            </div>
                            <ul class="open">
                              <li *ngFor="let ws4 of cms4.WorkStreamStructure; trackBy : trackByWorkstreamId; let indW4=index" class="c-list_content">
                                <div class="c-list" [attr.data-type]="'Workstream'" id="POCworkstream4">
                                  <div class="c-list-moveable">
                                    <div class="c-checkbox  ">
                                      <input id="ws4SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indW4}}"
                                             name="ws4SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indW4}}"
                                             #ws4SelectionMode="ngModel" type="checkbox" 
                                             [disabled]="RoleAccess=='V' ||ws4.WorkstreamStatusName == 'Closed' || tmsDetail.PhoenixCreationIndicator"
                                             [(ngModel)]="ws4.SelectionMode" [attr.data-selectionmode]="ws4.SelectionMode"
                                             [value]="ws4.SelectionMode" aria-labelledby="team_involvement ws4rowid"
                                             class="associationCheckbox c-checkbox__input"
                                             (change)="ws4.SelectionMode = changeCheckboxValue($event); ChangeSelectionMode(ws4.SelectionMode,ws4,null,null,$event)" />
                                      <i class="c-checkbox__checked" [attr.data-selectionmode]="ws4.SelectionMode"></i>
                                      <!--<i class="c-checkbox__checked"></i>-->
                                    </div>
                                  </div>
                                  <div class="c-list-text">
                                    <!--<img src="../assets/toolkit/images/client-structure/io.svg" alt="IO"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>-->
                                    <!--<span (click)="ChangeSelectionMode(ws4.SelectionMode,ws4,null,null,$event)" (keypress)="ChangeSelectionMode(ws4.SelectionMode,ws4,null,null,$event)" role="checkbox" aria-checked="false" tabindex="0" aria-labelledby="cmsTmsCheck8 team_involvement"></span>-->
                                    <span tabindex="0" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!='')">{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}},&nbsp;<span class="small">{{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}}</span></span>
                                    <span tabindex="0" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='')">{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}</span>
                                    <div tabindex="0" class="c-list-text-item" id="ws4rowid" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!='')"><img class="workstream-img" [attr.data-myw-title]="ws4.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws4.WorkstreamTypeId)" alt="Workstream Type-{{ws4.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;&nbsp;({{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}})</span></div>
                                    <div tabindex="0" class="c-list-text-item" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='')"><img class="workstream-img" [attr.data-myw-title]="ws4.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws4.WorkstreamTypeId)" alt="Workstream Type-{{ws4.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;&nbsp;</span></div>
                                  </div>
                                  <div class="c-list-input">
                                    <div class="o-input    ">
                                      <auto-complete id="ws4rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indW4}}" hiddenFieldID="hidden_ws4rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indW4}}"
                                                     #pocTeamLead="ngModel" name="ws4rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indW4}}"
                                                     [disabled]="ws4.SelectionMode != 'Full' || RoleAccess=='V' || ws4.WorkstreamStatusName == 'Closed' || tmsDetail.PhoenixCreationIndicator"
                                                     ariaLabelledby="POCworkstream4 poc_teamlead ws4rowid"
                                                     [selected]="ws4.POC"
                                                     [(ngModel)]="ws4.POC">
                                      </auto-complete>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li *ngFor="let cms5 of cms4.CMSLevelFive; trackBy : trackByCMSId; let indC5=index" class="c-list_content open">
                                <div class="c-list c-list-child expand" [attr.data-type]="'CMS'" id="POCCMS5_{{indC5}}">
                                  <div class="c-list-moveable">
                                    <div class="c-checkbox  ">
                                      <input id="cms5SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indC5}}"
                                             name="cms5SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indC5}}"
                                             #cms5SelectionMode="ngModel" type="checkbox" 
                                             [disabled]="RoleAccess=='V' || tmsDetail.PhoenixCreationIndicator" [(ngModel)]="cms5.SelectionMode"
                                             [attr.data-selectionmode]="cms5.SelectionMode"
                                             [value]="cms5.SelectionMode" aria-labelledby="team_involvement cms5rowid"
                                             class="associationCheckbox c-checkbox__input"
                                             (change)="cms5.SelectionMode = changeCheckboxValue($event); ChangeSelectionMode(cms5.SelectionMode,null,cms5,null,$event)" />
                                      <i class="c-checkbox__checked" [attr.data-selectionmode]="cms5.SelectionMode"></i>
                                      <!--<i class="c-checkbox__checked"></i>-->
                                    </div>
                                  </div>
                                  <div class="c-list-text">
                                    <!--<img src="../assets/toolkit/images/client-structure/io.svg" alt="IO"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>-->
                                    <!--<span (click)="ChangeSelectionMode(cms5.SelectionMode,null,cms5,null,$event)" (keypress)="ChangeSelectionMode(cms5.SelectionMode,null,cms5,null,$event)" role="checkbox" aria-checked="false" tabindex="0" aria-labelledby="cmsTmsCheck9 team_involvement"></span>-->
                                    <span tabindex="0" id="cms5rowid_{{indC5}}" [ngClass]="{'closed-cms' : cms5.CmsStatusId == 2}">{{cms5.CmsStatusId == 2 ? "[CLOSED]" + cms5.CMSName : cms5.CMSName}}</span>
                                  </div>
                                  <div class="c-list-input">
                                    <div class="o-input    ">
                                      <auto-complete id="cms5rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indC5}}" hiddenFieldID="hidden_cms5rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indC5}}"
                                                     #pocTeamLead="ngModel" name="cms5rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indC5}}"
                                                     [disabled]="cms5.SelectionMode != 'Full' || RoleAccess=='V' || cms5.CmsStatusId == 2 || tmsDetail.PhoenixCreationIndicator"
                                                     ariaLabelledby="POCCMS5 poc_teamlead cms5rowid"
                                                     [selected]="cms5.POC"
                                                     [(ngModel)]="cms5.POC">
                                      </auto-complete>
                                    </div>
                                  </div>
                                </div>
                                <ul class="open">
                                  <li *ngFor="let ws5 of cms5.WorkStreamStructure ; trackBy : trackByWorkstreamId; let indW5=index" class="c-list_content">
                                    <div class="c-list" [attr.data-type]="'Workstream'" id="POCworksream5">
                                      <div class="c-list-moveable">
                                        <div class="c-checkbox  ">
                                          <input id="ws5SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indC5 + '_' + indW5}}"
                                                 name="ws5SelectionMode_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indC5 + '_' + indW5}}"
                                                 #ws5SelectionMode="ngModel" type="checkbox" 
                                                 [disabled]="RoleAccess=='V' || ws5.WorkstreamStatusName == 'Closed'|| tmsDetail.PhoenixCreationIndicator"
                                                 [(ngModel)]="ws5.SelectionMode" [attr.data-selectionmode]="ws5.SelectionMode"
                                                 [value]="ws5.SelectionMode" aria-labelledby="team_involvement ws5rowid"
                                                 class="associationCheckbox c-checkbox__input"
                                                 (change)="ws5.SelectionMode = changeCheckboxValue($event); ChangeSelectionMode(ws5.SelectionMode,ws5,null,null,$event)" />
                                          <i class="c-checkbox__checked" [attr.data-selectionmode]="ws5.SelectionMode"></i>
                                          <!--<i class="c-checkbox__checked"></i>-->
                                          <!--<span (click)="ChangeSelectionMode(ws5.SelectionMode,ws5,null,null,$event)" (keypress)="ChangeSelectionMode(ws5.SelectionMode,ws5,null,null,$event)" role="checkbox" aria-checked="false" tabindex="0" aria-labelledby="cmsTmsCheck0 team_involvement"></span>-->
                                        </div>
                                      </div>
                                      <div class="c-list-text">
                                        <!--<img src="../assets/toolkit/images/client-structure/io.svg" alt="IO"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>-->

                                        <span tabindex="0" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!='')">{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}},&nbsp;<span class="small">{{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}}</span></span>
                                        <span tabindex="0" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='')">{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}</span>
                                        <div tabindex="0" class="c-list-text-item" id="ws5rowid" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!='')"><img class="workstream-img" [attr.data-myw-title]="ws5.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws5.WorkstreamTypeId)" alt="Workstream Type-{{ws5.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;&nbsp;({{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}})</span></div>
                                        <div tabindex="0" class="c-list-text-item" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='')"><img class="workstream-img" [attr.data-myw-title]="ws5.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws5.WorkstreamTypeId)" alt="Workstream Type-{{ws5.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;&nbsp;</span></div>
                                      </div>
                                      <div class="c-list-input">
                                        <div class="o-input    ">
                                          <auto-complete id="ws5rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indC5 + '_' + indW5}}" hiddenFieldID="hidden_ws5rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indC5 + '_' + indW5}}"
                                                         #pocTeamLead="ngModel" name="ws5rowid_teamLead_{{indC1 + '_' + indC2 + '_' + indC3 + '_' + indC4 + '_' + indC5 + '_' + indW5}}"
                                                         [disabled]="ws5.SelectionMode != 'Full' || RoleAccess=='V' || ws5.WorkstreamStatusName == 'Closed' || tmsDetail.PhoenixCreationIndicator"
                                                         ariaLabelledby="POCworksream5 poc_teamlead ws5rowid"
                                                         [selected]="ws5.POC"
                                                         [(ngModel)]="ws5.POC">
                                          </auto-complete>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div role="tabpanel" id="{{popupTMSMembersTab}}">
          <div class="c-header-dropdown team-add-edit-filter-btn" style="display:block;">
            <a href="javascript:;" role="button" data-myw-title="Filter" data-myw-toggle="tooltip" data-myw-placement="bottom"
            class="-dropdown-icon" (click)="OpenFilterPopup()" name="Apply Filter(s)" aria-label="Apply Filter(s) (Open a popup)"><img src="../assets/toolkit/images/thresholds/filter.svg" alt="Filter"></a>
          </div>
          <div class="c-search-container">
            <div class="c-search-icon">
              <a href="javascript:;" role="button"><i class="icon-search" aria-label="search"></i></a>
            </div>
            <input type="text" name="tmsDetailSearch" #tmsDetailSearch="ngModel" [disabled]="RoleAccess=='V'" class="c-search-control" [(ngModel)]="tmsDetail.searchText" (keyup)="ApplyFilter(null)" placeholder="Search Enterprise Id">
          </div>
          <div class="c-myWizard-team__row">
            <div class="c-myWizard-team__col">
              <div class="o-text-area">
                <label for="ResourceRoster">Resource Roster</label>
                <div class="o-text-area__input -customScroll">
                  <ul>
                    <li [ngClass]="{'_disabled' : (RoleAccess=='V'? true : false), 'resourceRoster': (resourceFlag==i ? true : false)}"
                        *ngFor="let item of tmsDetail.EndToEndTeamMembers; trackBy : trackByName;let i= index;">
                      <a href="javascript:;" (click)="onSelectTeamMember(item, i)" [value]="item">{{item.Name}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="c-myWizard-team__col-assign">
              <a href="javascript:;" class="icon-chevron-right" (click)="AddResourceToTeam()" [ngClass]="{'-disabled' : tmsDetail.SelectedEndToEndTeamMember.length <= 0 || RoleAccess=='V'}" role="button" data-myw-title="Add" data-myw-toggle="tooltip" data-myw-placement="bottom"
              aria-label="Add"></a>
              <a href="javascript:;" class="icon-chevron-left" (click)="RemoveResourceToTeam()" [ngClass]="{'-disabled' : tmsDetail.SelectedTeamMember.length <= 0 || RoleAccess=='V' }" role="button" data-myw-title="Remove" data-myw-toggle="tooltip" data-myw-placement="bottom"
              aria-label="Remove"></a>
            </div>
            <div class="c-myWizard-team__col">
              <div class="o-text-area ">
                <label>Associated Resources for Team</label>
                <div class="o-text-area__input -customScroll">
                  <ul>
                    <li *ngFor="let item of tmsDetail.TeamMembers; trackBy : trackByName; let i = index" [ngClass]="{'_disabled' : (RoleAccess=='V'? true : false), 'resourceRoster': (associatedResourceFlag==i ? true : false)}">
                      <a href="javascript:;" (click)="onSelectRemoveTeamMember(item, i)" [ngValue]="item">{{item.Name}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="c-flyout__footer">
        <div class="-flyout-btn">
          <button type="submit" [disabled]="RoleAccess=='V'" [ngClass]="{'-disabled': RoleAccess=='V' }" class="o-primary-button  ">Save</button>
        </div>
        <div class="-updation" *ngIf="IsEditTMS && tmsHierarchy.Type == 'TMS'">
          <span *ngIf="tmsHierarchy">Last Updated by :</span>&nbsp;{{tmsDetail.ModifiedByUser}}
        </div>
        <div class="-updation" *ngIf="IsEditTMS && tmsHierarchy.Type == 'TMS'">
          <span *ngIf="tmsHierarchy">Last Updated on :</span>&nbsp;{{tmsDetail.ModifiedOn |date:'dd MMM yyyy hh:mm a'}} (GMT)
        </div>

      </div>
    </div>


    <div class="c-flyout__overlay"></div>
  </form>
</ng-container>
<ng-container *ngIf="tmsHierarchy.Type == 'Group'">
  <form id="tmsAddEditForm" name="tmsAddEditForm" #tmsAddEditForm="ngForm" novalidate (ngSubmit)="SaveTMS(tmsAddEditForm.valid,tmsAddEditForm)">
    <div class="c-modal c-add-contact -open" style="visibility:visible;" tabindex="-1" aria-labelledby="modalDialogTitle">
      <div class="c-modal__container -size-medium">
        <div class="c-modal__content">
          <div class="c-modal__header">
            <div>
              <h6 id="modalDialogTitle">{{PopUpTitle}}</h6>
              <div style="font-size: 1.5rem; word-break: break-all;">{{popupGroupName}}</div>
            </div>
            <button type="button" class="c-modal__header-close -dismiss-modal" (keypress)="ClosePopUp(tmsAddEditForm,$event)" (click)="ClosePopUp(tmsAddEditForm,$event)" aria-label="Close" alt="Close"></button>
          </div>
          <div class="c-modal__body">
            <div class="c-add-contact__form">
              <div class="c-add-contact__form-area">
                <div class="o-input   -required   ">
                  <label for="tmsGroupName">Group Name</label>
                  <input type="text"
                         class="o-input__input"
                         [ngClass]="{'has-error':tmsAddEditForm.submitted && tmsGroupName.errors?.required}"
                         [disabled]="RoleAccess=='V'"
                         placeholder="Enter group name"
                         [(ngModel)]="tmsGroupDetail.TMSGroupName"
                         name="tmsGroupName" #tmsGroupName="ngModel"
                         [required]="true"
                         maxlength="50" autocomplete="off"
                         id="tmsGroupName" />
                  <p class="o-input__error-text" [ngClass]="{'is-error':tmsAddEditForm.submitted && tmsGroupName.errors?.required}">Please enter Group Name</p>
                  <p class="o-input__char-text">30/30 characters</p>
                </div>
              </div>
            </div>
          </div>
          <div class="c-flyout__footer">
            <div class="-flyout-btn">
              <button role="button" type="button" class="o-secondary-button -dismiss-modal" (keypress)="ClosePopUp(tmsAddEditForm,$event)" (click)="ClosePopUp(tmsAddEditForm,$event)">
                Cancel
              </button>
              <button type="submit" [disabled]="RoleAccess=='V'" class="o-primary-button" [ngClass]="{'-disabled': RoleAccess=='V' }">Save</button>
            </div>
            <div class="-updation" *ngIf="IsEditTMS && tmsHierarchy.Type == 'Group'">
              <span *ngIf="tmsHierarchy">Last Updated by :</span>&nbsp;{{tmsGroupDetail.ModifiedByUser}}
            </div>
            <div class="-updation" *ngIf="IsEditTMS && tmsHierarchy.Type == 'Group'">
              <span *ngIf="tmsHierarchy">Last Updated on :</span>&nbsp;{{tmsGroupDetail.ModifiedOn |date:'dd MMM yyyy hh:mm a'}} (GMT)
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-flyout__overlay"></div>
  </form>
</ng-container>
