<!--<div class="row page-title">
  <div class="col-xs-9">
    <a href="javascript:void(0)" (click)="OnBackClick()" aria-label="Back" class="back-link">
      <span class="glyphicon glyphicon-menu-left"></span>Back
    </a>

    <h1 [attr.aria-label]="DeliveryProfile">
      {{DeliveryProfile}}
      <a href="javascript:void(0)" id="downloadDeliveryProfileGuide" aria-label="Click to download a help file for a brief description on each field"
         title="Click to download a help file for a brief description on each field" *ngIf="EnableDeliveryProfileGuide"
         class="glyphicon glyphicon-info-sign" style="font-size:14px;color:black;text-decoration-line:none;" (click)="DownloadTemplate('DeliveryProfileGuide','downloadDeliveryProfileGuide','xlsx')"></a>
    </h1>
  </div>
  <div class="col-xs-3 text-right">
    &nbsp;
  </div>
  <div class="col-xs-12" style="font-size:13px;padding-top:8px;margin-bottom:-10px;" *ngIf="ShowDominantInfo && endToEndType == 'C'">
    E2Es with more than one contract association have demographics of the highest revenue contract highlighted in bold.
  </div>
</div>
<div class="fds-wrap">
  <nav role="navigation" aria-label="Initiate Profile Navigation">
    <ul class="fds-tab-nav">
      <li [title]="DisableTabMessage" [ngClass]="{'activeTab': tabId == enumProfileTab.Profile,'DisabledTab disable-tab-color' : this.DisableTabMessage != '','tab-highlight-error':ProfileTabHighlight && !CreatePage}"
          (click)="loadTabForAdminAndProfile(enumProfileTab.Profile)">
        <a data-toggle="tab" href="#profile" aria-current="page" aria-label="Profile">Profile</a>
      </li>
      <li [title]="DisableTabMessage" [ngClass]="{'activeTab': tabId == enumProfileTab.Scope,'DisabledTab disable-tab-color' : this.DisableTabMessage != '' || (this.IsOppInitNotCreated && this.CreatePage)}" (click)="loadTabForAdminAndProfile(enumProfileTab.Scope)">
        <a data-toggle="tab" href="#scope" aria-current="page" aria-label="Scope">Scope</a>
      </li>
      <li *ngIf="CreatePage" [ngClass]="{'activeTab': tabId ==  enumProfileTab.DeliveryPortfolio,'DisabledTab disable-tab-color' : IsOppInitNotCreated}" (click)="loadTabForAdminAndProfile(enumProfileTab.DeliveryPortfolio)">
        <a data-toggle="tab" href="#deliveryPortfolio" aria-current="page" aria-label="Delivery Portfolio">Delivery Portfolio</a>
      </li>
      <li [title]="DisableTabMessage" [ngClass]="{'activeTab':tabId == enumProfileTab.KeyContacts,'DisabledTab disable-tab-color' : this.DisableTabMessage != '' || (this.IsOppInitNotCreated && this.CreatePage),'tab-highlight-error' : keyContactTabHighlight && !CreatePage}" (click)="loadTabForAdminAndProfile(enumProfileTab.KeyContacts)">
        <a data-toggle="tab" href="#keycontacts" aria-current="page" aria-label="Key Contacts">Key Contacts</a>
      </li>
      <li *ngIf="!CreatePage" title="Client Structure" [ngClass]="{'activeTab':tabId == enumProfileTab.ClientStructure,'DisabledTab disable-tab-color': IsDeliveryMandatoryDataSet}" (click)="loadTabForAdminAndProfile(enumProfileTab.ClientStructure)">
        <a data-toggle="tab" href="#cmstructure" aria-current="page" aria-label="Client Structure">Client Structure</a>
      </li>
      <li *ngIf="!CreatePage" title="Team Structure" [ngClass]="{'activeTab': tabId == enumProfileTab.TeamStructure,'DisabledTab disable-tab-color': IsDeliveryMandatoryDataSet}" (click)="loadTabForAdminAndProfile( enumProfileTab.TeamStructure)">
        <a data-toggle="tab" href="#teamstructure" aria-current="page" aria-label="Team Structure">Team Structure</a>
      </li>
      <li *ngIf="!CreatePage" title="Matrix Structure" [ngClass]="{'activeTab':tabId == enumProfileTab.MatrixStructure,'DisabledTab disable-tab-color': IsDeliveryMandatoryDataSet}" (click)="loadTabForAdminAndProfile( enumProfileTab.MatrixStructure)">
        <a data-toggle="tab" href="#matrixstructure" aria-current="page" aria-label="Matrix Structure">Matrix Structure</a>
      </li>
      <li *ngIf="IsHelpModal" class="pull-right">
        <span>
          <a href="#cmsHelpModal" (click)="LoadSlideShow()" class="cms-tms-nav cms_modal" data-toggle="modal" style="height:36px;background-size: 20px auto;">
          </a>
        </span>
      </li>
    </ul>
  </nav>
  <div class="tab-content">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
</div>-->


<div class="c-myWizard" [ngClass]="{'-collapsed' : sideMenuCollapsed}">
  <div class="c-sideMenu">
    <div class="c-sideMenu-header">
      <span>{{ accordianHeader }}</span>
      <a href="javascript:;" class="-expand" (click) ="sideMenuCollapsed = !sideMenuCollapsed" aria-label="Collapse">
      <img class="-hide" src="../assets/toolkit/images/side-menu.svg" alt="Collapse" role="presentation">
      <img class="-open" src="../assets/toolkit/images/sidemenu-expand.svg" alt="Expand" role="presentation"></a>
    </div>
    <div role="navigation" aria-label="Initiate Profile Navigation" class="c-sideMenu-body -customScroll" id="sideMenuAccord">
      <div class="c-sideMenu-item">
        <div class="c-accordion">
          <a href="javascript:;" role="button" (click)="accordionChange('initiateprofile',null)" class="c-collapse" [ngClass]="{' -active' : selectedSideMenuAccordion == 'initiateprofile'}"
            [attr.aria-expanded]="selectedSideMenuAccordion == 'initiateprofile'&&sideMenuExapand?'true':'false'">
            <div class="c-collapse__inner">
              <img src="../assets/toolkit/images/initiate-active.svg" role="presentation" alt="Initiate Profile" />
              <span class="-title">{{ accordianTitle }}</span>
            </div>
          </a>
          <div  class="-collapse" data-parentid="sideMenuAccord" [ngClass]="selectedSideMenuAccordion == 'initiateprofile'&& sideMenuExapand?'-show':''">
            <ul class="c-accordion__container">
              <li>
                <a data-toggle="tab" href="#profile" aria-current="page" aria-label="Profile" [ngClass]="{'-active': tabId == enumProfileTab.Profile, '-disabled' : DisableTabMessage != '','error-text':ProfileTabHighlight && !CreatePage}" [attr.tabindex]="(DisableTabMessage != '') ? -1 : null" (click)="loadTabForAdminAndProfile(enumProfileTab.Profile)">Profile</a>
              </li>
              <li>
                <a data-toggle="tab" href="#scope" aria-current="page" aria-label="Scope" [ngClass]="{'-active': tabId == enumProfileTab.Scope, '-disabled' : DisableTabMessage != '' || (IsOppInitNotCreated && CreatePage)}" [attr.tabindex]="DisableTabMessage != '' || (IsOppInitNotCreated && CreatePage) ? -1 : null" (click)="loadTabForAdminAndProfile(enumProfileTab.Scope)">Scope</a>
              </li>
              <li *ngIf="CreatePage">
                <a data-toggle="tab" href="#deliveryPortfolio" aria-current="page" aria-label="Delivery Portfolio" [ngClass]="{'-active': tabId ==  enumProfileTab.DeliveryPortfolio,'-disabled' : IsOppInitNotCreated}" [attr.tabindex]="DisableTabMessage != '' || (IsOppInitNotCreated && CreatePage) ? -1 : null" (click)="loadTabForAdminAndProfile(enumProfileTab.DeliveryPortfolio)">Delivery Portfolio</a>
              </li>
              <li>
                <a data-toggle="tab" href="javascript:;" aria-current="page" aria-label="Key Contacts" [ngClass]="{'-active':tabId == enumProfileTab.KeyContacts,'-disabled' : DisableTabMessage != '' || (IsOppInitNotCreated && CreatePage),'error-text' : keyContactTabHighlight && !CreatePage}" [attr.tabindex]="DisableTabMessage != '' || (IsOppInitNotCreated && CreatePage) ? -1 : null" (click)="loadTabForAdminAndProfile(enumProfileTab.KeyContacts)">Key Contacts</a>
              </li>
              <li *ngIf="!CreatePage">
                <a data-toggle="tab" href="#cmstructure" aria-current="page" aria-label="Client Structure" [ngClass]="{'-active':tabId == enumProfileTab.ClientStructure, '-disabled': IsDeliveryMandatoryDataSet}" [attr.tabindex]="IsDeliveryMandatoryDataSet ? -1 : null" (click)="loadTabForAdminAndProfile(enumProfileTab.ClientStructure)">Client Structure</a>
              </li>
              <li *ngIf="!CreatePage">
                <a data-toggle="tab" href="#teamstructure" aria-current="page" aria-label="Team Structure" [ngClass]="{'-active': tabId == enumProfileTab.TeamStructure, '-disabled': IsDeliveryMandatoryDataSet}" [attr.tabindex]="IsDeliveryMandatoryDataSet ? -1 : null" (click)="loadTabForAdminAndProfile( enumProfileTab.TeamStructure)">Team Structure</a>
              </li>
              <li *ngIf="!CreatePage">
                <a data-toggle="tab" href="#matrixstructure" aria-current="page" aria-label="Matrix Structure" [ngClass]="{'-active':tabId == enumProfileTab.MatrixStructure, '-disabled': IsDeliveryMandatoryDataSet}" [attr.tabindex]="IsDeliveryMandatoryDataSet ? -1 : null" (click)="loadTabForAdminAndProfile( enumProfileTab.MatrixStructure)">Matrix Structure</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="c-sideMenu-item" *ngIf="!CreatePage">
        <div class="c-accordion">
          <a href="javascript:;" role="button" 
             class="c-collapse" [attr.aria-expanded]="selectedSideMenuAccordion == 'configuremetrics'&&sideMenuExapand?'true':'false'" [ngClass]="{'-active' : selectedSideMenuAccordion == 'configuremetrics','-disabled': IsDeliveryMandatoryDataSet}" 
             [attr.tabindex]="IsDeliveryMandatoryDataSet ? -1 : null" (click)="accordionChange('configuremetrics',null)">
            <div class="c-collapse__inner">
              <img src="../assets/toolkit/images/configure-metrics-active.svg" role="presentation" alt="Configure" />
              <span class="-title">Configure Metrics</span>
            </div>
          </a>
          <div class="-collapse" data-parentid="sideMenuAccord"  [ngClass]="selectedSideMenuAccordion == 'configuremetrics'&&sideMenuExapand?'-show':''">
            <ul class="c-accordion__container">
              <li><a href="javascript:;" class=" " [ngClass]="{'-active': tabId == enumProfileTab.MetricSelection,'-disabled':disableMetricselection}" [attr.tabindex]="disableMetricselection ? -1 : null" (click)="loadTabForAdminAndProfile(enumProfileTab.MetricSelection)">Metric Selection & Context</a></li>
              <li><a href="javascript:;" class=" " [ngClass]="{'-active': tabId == enumProfileTab.Thresholds,'-disabled':disableThreshold}" [attr.tabindex]="disableThreshold ? -1 : null" (click)="loadTabForAdminAndProfile(enumProfileTab.Thresholds)">Thresholds</a></li>
              <li><a href="javascript:;" class=" " [ngClass]="{'-active': tabId == enumProfileTab.OtherConfigureSettings,'-disabled':disableOtherMetricConfig}" [attr.tabindex]="disableOtherMetricConfig ? -1 : null" (click)="loadTabForAdminAndProfile(enumProfileTab.OtherConfigureSettings)">Other Configuration Settings</a></li>
            </ul>
          </div>
        </div>
      </div>
      <!--<div class="c-sideMenu-item" *ngIf="!CreatePage">
        <div class="c-accordion">
          <a href="javascript:;" role="button" title="Orphaned Data Summary"  [ngClass]="{' -active' : selectedSideMenuAccordion == 'orphandata','-disabled': IsDeliveryMandatoryDataSet}"
             (click)="accordionChange('orphandata',207)"  class="c-collapse" aria-expanded="false" aria-controls="sideMenuAccordion3">
            <div class="c-collapse__inner">
              <img src="../assets/toolkit/images/orphaned-active.svg" role="presentation" alt="Orphaned" />
              <span class="-title">Orphaned Data Summary</span>
            </div>
          </a>-->
          <!--<div id="sideMenuAccordion3" class="-collapse" data-parentid="sideMenuAccord">
            <ul class="c-accordion__container">
              <li><a href="javascript:;" title="Client Structure">Client Structure</a></li>
              <li><a href="javascript:;" title="Key Structure">Key Structure</a></li>
              <li><a href="javascript:;" title="Matrix Structure">Matrix Structure</a></li>
            </ul>
          </div>-->
        <!--</div>
      </div>-->
      <!--<div class="c-sideMenu-item" *ngIf="!CreatePage">
        <div class="c-accordion -no-child">
          <a href="javascript:;" role="button" class="c-collapse" title="Platform Access" [ngClass]="{'-active' : tabId == enumProfileTab.PlatformAccess,'-disabled': IsPlatformAccessMandatory}"
            (click)="loadTabForAdminAndProfile(enumProfileTab.PlatformAccess)" aria-expanded="false" aria-controls="sideMenuAccordion4">
            <div class="c-collapse__inner">
              <img src="../assets/toolkit/images/platform-active.svg" role="presentation" alt="Platform" />
              <span class="-title">Platform Access</span>
            </div>
          </a>
        </div>
      </div>-->
      <!--<div class="c-sideMenu-item" *ngIf="!CreatePage">
        <div class="c-accordion">
          <a href="javascript:;" role="button" title="Manage Employee"   [ngClass]="{' -active' : selectedSideMenuAccordion == 'manageemployee','-disabled': IsDeliveryMandatoryDataSet}" (click)="accordionChange('manageemployee',153)" aria-label="Manage Employee / Contractor" class="c-collapse" aria-expanded="false" aria-controls="sideMenuAccordion5">
            <div class="c-collapse__inner">
              <img src="../assets/toolkit/images/manage-active.svg" role="presentation" alt="Platform" />
              <span class="-title">Manage Employee / Contractor</span>
            </div>
          </a>-->
          <!--<div id="sideMenuAccordion5" class="-collapse" data-parentid="sideMenuAccord">
            <ul class="c-accordion__container">
              <li><a href="javascript:;" title="Profile">Profile</a></li>
              <li><a href="javascript:;" title="Scope">Scope</a></li>
            </ul>
          </div>-->
        <!--</div>
      </div>-->
    </div>
  </div>
  <div class="c-myWizard-content">
    <div class="flex_adjust">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>
</div>
