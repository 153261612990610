import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '../environments/environment';

/*import { WelcomeComponent } from './welcome/welcome.component';*/
import { ExternalApplicationLandingComponent } from './core/components/external.application.landing.component';
import { AccessDeniedComponent } from './core/components/accessdenied.component/accessdenied.component';
import { ManageComponent } from './home/manage.component/manage.component';
import { PortfolioComponent } from './home/portfolio.component/portfolio.component';
import { TaskComponent } from './home/task.component/task.component';
import { AdminComponent } from './home/admin.component/admin.component';
import { HomeComponent } from './home/home.component/home.component';
import { InitiateProfileComponent } from './home/initiate.profile.component/initiate.profile.component';
import { ClientStructureComponent } from './home/initiate.profile.component/client.structure.component';
import { TeamStructureComponent } from './home/initiate.profile.component/team.structure.component';
import { MatrixStructureComponent } from './home/initiate.profile.component/matrix.structure.component';
import { ScopeComponent } from './home/initiate.profile.component/scope.component';
import { KeyContactsComponent } from './home/initiate.profile.component/keycontacts.component';
import { ProfileComponent } from './home/initiate.profile.component/profile.component';
import { DeliveryPortfolioComponent } from './home/initiate.profile.component/delivery.portfolio.component';
import { ChildtileComponent } from './home/child.tile.component/child.tile.component';
import { AvanadeEACComponent } from './home/avanadeEAC.component/avanadeEAC.component';
import { DEXComponent } from './home/delivery.excellence.component/dex.component';
import { PlatformAccess } from './home/platform.access.component/platfrom.access.component';
//import { SitemapComponent } from './home/sitemap.component/sitemap.component';
import { SupportComponent } from './home/support.component/support.component';
import { ChangesGuard } from './core/services/can.deactivate.service';
import { WelcomeComponent } from './home/home.component/welcome.component';
//import { TileConfigurationComponent } from './home/tile.configuration.component/tile.configuration.component';
import { MetricSelectionContextComponent } from './home/configure.metrics.component/metricselection.context.component';
import { MetricThresholdComponent } from './home/configure.metrics.component/metric.threshold.component';
import { OtherConfigurationSettings } from './home/configure.metrics.component/otherconfiguration.settings.component';
import { ManageLandingComponent } from './home/manage.component/manage.landing.component';
import { MatrixUserGuideComponent } from './home/initiate.profile.component/user.guide/matrix.userguide.component';
import { TMSUserGuideComponent } from './home/initiate.profile.component/user.guide/tms.userguide.component';
import { CMSUserGuideComponent } from './home/initiate.profile.component/user.guide/cms.userguide.component';
import { OtherConfigurationUserGuideComponent } from './home/configure.metrics.component/user.guide/otherconfiguration.userguide.component';
import { ThresholdUserGuideComponent } from './home/configure.metrics.component/user.guide/threshold.userguide.component';
import { MetricsUserGuideComponent } from './home/configure.metrics.component/user.guide/metrics.userguide.component';

//import { CMMIHomeComponent } from './home/cmmi.component/cmmi.home.component';

const AuthGuard: any[] = environment.isAuthRequired ? [MsalGuard] : [];

const routes: Routes = [
  {
    path: '', component: WelcomeComponent, canActivate: AuthGuard, data: { title: 'MyWizard' }
  },
  {
    path: 'MyWizard/Back/:tabCode', component: ExternalApplicationLandingComponent, canActivate: AuthGuard
  },
  {
     path: 'myWizard/back/:tabCode/:endToEndUId/:cmsUId/:workstreamUId/:teamUId/:app/:profileTabCode', component: ExternalApplicationLandingComponent
   },
  {
    path: 'accessdenied', component: AccessDeniedComponent, data: { title: 'Access Denied' }
  },
  {
    path: 'mywizard', component: HomeComponent, canActivate: AuthGuard, data: { title: 'MyWizard' },
    children: [
      {
        path: 'manage/:redirectValue/:redirectId', component: ManageComponent, data: { title: 'Manage - MyWizard' }
      },
      {
        path: 'home/:redirectValue/:redirectId', component: ManageLandingComponent, data: { title: 'Manage - MyWizard' }
      },

      {
        path: 'portfolio/:redirectValue/:redirectId', component: PortfolioComponent, data: { title: 'Portfolio - MyWizard' }
      },
      {
        path: 'tasks/:redirectValue/:redirectId', component: TaskComponent, data: { title: 'Tasks - MyWizard' }
      },
      {
        path: 'admin', component: AdminComponent, data: { title: 'Admin - MyWizard' }
      },
      //{
      //  path: 'cmmi/:deliverycenter/:redirectValue/:redirectId', component: CMMIHomeComponent, data: { title: 'CMMI - MyWizard' }
      //},
      //{
      //  path: 'cmmi/:redirectValue/:redirectId', component: CMMIComponent, data: { title: 'CMMI Plan - MyWizard' }
      //},
      {
        path: 'support', component: SupportComponent, data: { title: 'Support - MyWizard' }
      },
      {
        path: 'releasenotes', loadChildren: () => import('./managereleasenotes.module').then(m => m.ManageReleaseNotesModule),
      },
      {
        path: 'developementsupport', loadChildren: () => import('./development.support.module').then(m => m.DevelopmentSupportModule),
      },
      {
        path: 'childtiles/:tileId/:redirectValue/:redirectId', component: ChildtileComponent, data: { title: 'Manage - MyWizard' }
      },
      {
        path: '',
        component: InitiateProfileComponent,
        data: { title: 'Initiate Profile - MyWizard' },
        children: [
          {
            path: 'profile/:redirectValue/:redirectId',
            component: ProfileComponent,
            data: { title: 'Initiate Profile - MyWizard' },
            canDeactivate: [ChangesGuard]
          },
          {
            path: 'scope/:redirectValue/:redirectId',
            component: ScopeComponent,
            data: { title: 'Initiate Profile - MyWizard' },
            canDeactivate: [ChangesGuard]
          }, {
            path: 'keycontacts/:redirectValue/:redirectId',
            component: KeyContactsComponent,
            data: { title: 'Initiate Profile - MyWizard' },
            canDeactivate: [ChangesGuard]
          }, {
            path: 'clientstructure/:redirectValue/:redirectId',
            component: ClientStructureComponent,
            data: { title: 'Initiate Profile - MyWizard' },
            canDeactivate: [ChangesGuard]
          },
          {
            path: 'matrixstructure/:redirectValue/:redirectId',
            component: MatrixStructureComponent,
            data: { title: 'Initiate Profile - MyWizard' },
            //canDeactivate: [ChangesGuard]
          },
          {
            path: 'teamstructure/:redirectValue/:redirectId',
            component: TeamStructureComponent,
            data: { title: 'Initiate Profile - MyWizard' },
            canDeactivate: [ChangesGuard]
          },
          //{
          //  path: 'platformaccess/:redirectValue/:redirectId',
          //  component: PlatformAccess,
          //  data: { title: 'Platform Access - MyWizard' },
          //  canDeactivate: [ChangesGuard]
          //},
          {
            path: '',
            children: [
              {
                path: 'metricselection/:redirectValue/:redirectId',
                component: MetricSelectionContextComponent,
                data: { title: 'Configure Metrics - MyWizard' },
                canDeactivate: [ChangesGuard]
              },
              {
                path: 'metricthreshold/:redirectValue/:redirectId',
                component: MetricThresholdComponent,
                data: { title: 'Configure Metrics - MyWizard' },
               //canDeactivate: [ChangesGuard]
              }
              ,
              {
                path: 'otherconfigsettings/:redirectValue/:redirectId',
                component: OtherConfigurationSettings,
                data: { title: 'Configure Metrics - MyWizard' },
                canDeactivate: [ChangesGuard]
              }
            ]
          },


        ]
      },
      {
        path: 'matrixuserguide',
        component: MatrixUserGuideComponent
      },
      {
        path: 'tmsuserguide',
        component: TMSUserGuideComponent
      },
      {
        path: 'cmsuserguide',
        component: CMSUserGuideComponent
      },
      {
        path: 'otherconfiguserguide',
        component: OtherConfigurationUserGuideComponent
      },
      {
        path: 'thresholduserguide',
        component: ThresholdUserGuideComponent
      },
      {
        path: 'metricsuserguide',
        component: MetricsUserGuideComponent
      },
      {
        path: 'opportunity',
        component: InitiateProfileComponent,
        data: { title: 'Opportunity - MyWizard' },
        children: [
          {
            path: 'profile',
            component: ProfileComponent,
            data: { title: 'Opportunity - MyWizard' },
            canDeactivate: [ChangesGuard]
          },
          {
            path: 'scope',
            component: ScopeComponent,
            data: { title: 'Opportunity - MyWizard' },
            canDeactivate: [ChangesGuard]
          },
          {
            path: 'keycontacts',
            component: KeyContactsComponent,
            data: { title: 'Opportunity - MyWizard' },
            canDeactivate: [ChangesGuard]
          },
          {
            path: 'deliveryportfolio',
            component: DeliveryPortfolioComponent,
            data: { title: 'Opportunity - MyWizard' },
            canDeactivate: [ChangesGuard]
          },
        ]
      },
      {
        path: 'internalinitiative',
        component: InitiateProfileComponent,
        data: { title: 'Internal Initiative - MyWizard' },
        children: [
          {
            path: 'profile',
            component: ProfileComponent,
            data: { title: 'Internal Initiative - MyWizard' },
            canDeactivate: [ChangesGuard]
          },
          {
            path: 'scope',
            component: ScopeComponent,
            data: { title: 'Internal Initiative - MyWizard' },
            canDeactivate: [ChangesGuard]
          },
          {
            path: 'keycontacts',
            component: KeyContactsComponent,
            data: { title: 'Internal Initiative - MyWizard' },
            canDeactivate: [ChangesGuard]
          },
          {
            path: 'deliveryportfolio',
            component: DeliveryPortfolioComponent,
            data: { title: 'Internal Initiative - MyWizard' },
            canDeactivate: [ChangesGuard]
          },
        ]
      },
      {
        path: 'admin', component: AdminComponent, data: { title: 'Admin - MyWizard' }
      },
      {
        path: 'cmmi', loadChildren: () => import('./cmmi.module').then(m => m.CmmiModule),
      },
      {
        path: 'security', loadChildren: () => import('./security.module').then(m => m.SecurityModule),
      },
      {
        path: 'avanadeeacforecast/:redirectValue/:redirectId', component: AvanadeEACComponent, data: { title: 'Avanade EAC Forecast - MyWizard' }, canDeactivate: [ChangesGuard]
      },
      {
        path: 'deliveryexcellenceprinciples/:redirectValue/:redirectId', component: DEXComponent, data: { title: 'Delivery Excellence Principles - MyWizard' }, canDeactivate: [ChangesGuard]
      },
      {
        path: 'platformaccess/:redirectValue/:redirectId', component: PlatformAccess, data: { title: 'Platform Access - MyWizard' }
      },

    ]
    //}]
  },
  //{
  //  path: 'manage', component: ManageComponent, canActivate: AuthGuard, data: { title: 'Manage - MyWizard' },
  //},
  //  children: [
  //    {
  //      path: 'manage/:redirectValue/:redirectId', component: ManageComponent, data: { title: 'Manage - MyWizard' }
  //    },
  //    {
  //      path: 'portfolio/:redirectValue/:redirectId', component: PortfolioComponent, data: { title: 'Portfolio - MyWizard' }
  //    },
  //    {
  //      path: 'tasks/:redirectValue/:redirectId', component: TaskComponent, data: { title: 'Tasks - MyWizard' }
  //    },
  //    {
  //      path: 'admin', component: AdminComponent, data: { title: 'Admin - MyWizard' }
  //    },
  //    {
  //      path: 'support', component: SupportComponent, data: { title: 'Support - MyWizard' }
  //    },
  //    {
  //      path: 'childtiles/:tileId/:redirectValue/:redirectId', component: ChildtileComponent, data: { title: 'Manage - MyWizard' }
  //    },
  //    {
  //      path: 'tileconfiguration', component: TileConfigurationComponent, data: { title: 'Tile Configuration - MyWizard' }
  //    },
  //    {
  //      path: '',
  //      component: InitiateProfileComponent,
  //      data: { title: 'Initiate Profile - MyWizard' },
  //      children: [
  //        {
  //          path: 'profile/:redirectValue/:redirectId',
  //          component: ProfileComponent,
  //          data: { title: 'Initiate Profile - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //        {
  //          path: 'scope/:redirectValue/:redirectId',
  //          component: ScopeComponent,
  //          data: { title: 'Initiate Profile - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //        {
  //          path: 'keycontacts/:redirectValue/:redirectId',
  //          component: KeyContactsComponent,
  //          data: { title: 'Initiate Profile - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //        {
  //          path: 'clientstructure/:redirectValue/:redirectId',
  //          component: ClientStructureComponent,
  //          data: { title: 'Initiate Profile - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //        {
  //          path: 'teamstructure/:redirectValue/:redirectId',
  //          component: TeamStructureComponent,
  //          data: { title: 'Initiate Profile - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //        {
  //          path: 'matrixstructure/:redirectValue/:redirectId',
  //          component: MatrixStructureComponent,
  //          data: { title: 'Initiate Profile - MyWizard' },
  //        },
  //        {
  //          path: 'deliveryportfolio/:redirectValue/:redirectId',
  //          component: DeliveryPortfolioComponent,
  //          data: { title: 'Initiate Profile - MyWizard' },
  //        },

  //      ]
  //    },
  //    {
  //      path: 'opportunity',
  //      component: InitiateProfileComponent,
  //      data: { title: 'Opportunity - MyWizard' },
  //      children: [
  //        {
  //          path: 'profile',
  //          component: ProfileComponent,
  //          data: { title: 'Opportunity - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //        {
  //          path: 'scope',
  //          component: ScopeComponent,
  //          data: { title: 'Opportunity - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //        {
  //          path: 'keycontacts',
  //          component: KeyContactsComponent,
  //          data: { title: 'Opportunity - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //        {
  //          path: 'deliveryportfolio',
  //          component: DeliveryPortfolioComponent,
  //          data: { title: 'Opportunity - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //      ]
  //    },
  //    {
  //      path: 'internalinitiative',
  //      component: InitiateProfileComponent,
  //      data: { title: 'Internal Initiative - MyWizard' },
  //      children: [
  //        {
  //          path: 'profile',
  //          component: ProfileComponent,
  //          data: { title: 'Internal Initiative - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //        {
  //          path: 'scope',
  //          component: ScopeComponent,
  //          data: { title: 'Internal Initiative - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //        {
  //          path: 'keycontacts',
  //          component: KeyContactsComponent,
  //          data: { title: 'Internal Initiative - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //        {
  //          path: 'deliveryportfolio',
  //          component: DeliveryPortfolioComponent,
  //          data: { title: 'Internal Initiative - MyWizard' },
  //          canDeactivate: [ChangesGuard]
  //        },
  //      ]
  //    },
  //    {
  //      path: 'avanadeeacforecast/:redirectValue/:redirectId', component: AvanadeEACComponent, data: { title: 'Avanade EAC Forecast - MyWizard' }, canDeactivate: [ChangesGuard]
  //    },
  //{
  //  path: 'deliveryexcellenceprinciples/:redirectValue/:redirectId', component: DEXComponent, data: { title: 'Delivery Excellence Principles - MyWizard' }, canDeactivate: [ChangesGuard]
  //},
  //    {
  //      path: 'platformaccess/:redirectValue/:redirectId', component: PlatformAccess, data: { title: 'Platform Access - MyWizard' }
  //    },

  //    {
  //      path: 'security', loadChildren: () => import('./security.module').then(m => m.SecurityModule),
  //    },

  //    {
  //      path: 'developementsupport', loadChildren: () => import('./development.support.module').then(m => m.DevelopmentSupportModule),
  //    },
  //    {
  //      path: 'myWizard/back/:tabCode/:endToEndUId/:cmsUId/:workstreamUId/:teamUId/:app/:profileTabCode', component: ExternalApplicationLandingComponent
  //    }
  //  ]
  //},
  //{
  //  path: 'SiteMap', component: SitemapComponent, data: { title: 'Sitemap' }
  //},
  { path: '**', redirectTo: '', pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
