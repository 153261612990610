  <input type="text"
         [attr.aria-labelledby]="ariaLabelledby"
         autocomplete="off"
         id="{{'peoplePicker_' + id}}"
         name="{{'peoplePicker_' + name}}"
         class="{{'o-input__input ' + ariaLabelledby}}"
         [(ngModel)]="selected"
         (input)="validateCustomControls()"
         maxlength=50
         [disabled]="disabled"
         placeholder="{{placeholderText}}"
         [ngStyle]="ariaLabelledby=='OverallDeliveryLead' ? txtStyle : ''"
         [ngClass]="{ 'has-error': (required && formname.submitted && (selected==null||selected=='')) || isInValidAccentureEmp , 'input-blank-error' : !gridPeoplePickerValid && selected != ''}"
         [attr.data]="selectedEnterpriseId"/>
  <input type="hidden" id={{hiddenFieldID}} class="{{hiddenFieldID}}" value="{{hiddenValue}}" aria-labelledby="EnterpriseIdlbl" />
  <div id="{{'peoplePicker_div' + id}}"></div>
