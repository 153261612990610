import { Component, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStorage } from '../../core/context/sessionstorage';
import { TileConstants } from '../../core/context/constants';
import { environment } from '../../../environments/environment';
import { enumProfileTab } from '../../core/context/enum'
import { TileService } from '../../core/services/tile.service';

var $ = (<any>window).$;

@Component({
  selector: 'tile',
  templateUrl: './tile.component.html'
})
export class TileComponent {

  IsStacked: boolean;
  IsInlineStyle: boolean
  IsHrRow: boolean;
  IsHRRowDescription: boolean;
  Alerts: any;
  @Input()
  tile: any
  @Input() rimResponse: any;
  isDisableAlertNavigation: any;
  showMandatoryAlert: any;
  endToEndType = this.sessionStorage.endToEndType;

  constructor(private sessionStorage: SessionStorage, private router: Router, private activatedRoute: ActivatedRoute,private tileService: TileService) { }
  ngOnChanges() {
    this.rimResponse;
  }
  ngOnInit() {
    if (this.tile.Type.toUpperCase() == "STACK") {
      this.IsStacked = true;
    }
    if (this.tile.ShortDescription == "CMMI") {
      this.IsInlineStyle = true;
    }
    this.IsHrRow = TileConstants.HRRow.indexOf(this.tile.ShortDescription) > -1;
    this.IsHRRowDescription = TileConstants.HRRowDescription.indexOf(this.tile.ShortDescription) > -1;

  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.tile.ShortDescription == 'IC') {
        //this.alertNotificationCount = this.AlertNotificationCount();
        this.isDisableAlertNavigation = this.IsDisableAlertNavigation();
        this.showMandatoryAlert = this.ShowMandatoryAlert();
      }
    });
  }

  AlertClick = function (url) {
    if (url != null && this.tileService.isValidString(url))
      window.open(url, '_blank');
  }
  GetURL(): boolean {
    let url = this.tile.Url;
    if (url.indexOf("javascript:void(0)") > -1 || url == "") return false;
    this.sessionStorage.parentTileId = this.tile.ParentTileId;
    let target = this.tile.TargetPage;
    if (url == "/mywizard/childtiles" && this.tile.ShortDescription != "IC") {
      this.router.navigate(['/mywizard/childtiles', this.tile.TileId, 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }

    else {
      var clientUId = this.tileService.isValidGUId(this.sessionStorage.clientUId) ? this.sessionStorage.clientUId : "";
      var endtoendUId = this.tileService.isValidGUId(this.sessionStorage.endToEndUId) ? this.sessionStorage.endToEndUId : "";
      var cmsUId = this.tileService.isValidGUId(this.sessionStorage.clientWMSUId) ? this.sessionStorage.clientWMSUId : "";
      var workstreamUId = this.tileService.isValidGUId(this.sessionStorage.workstreamUId) ? this.sessionStorage.workstreamUId : "";
      var teamUId = this.tileService.isValidGUId(this.sessionStorage.teamUId) ? this.sessionStorage.teamUId : "";
      var endtoendId = this.tileService.isValidId(this.sessionStorage.endToEndId) ? this.sessionStorage.endToEndId : "";
      var cmsId = this.tileService.isValidId(this.sessionStorage.clientWMSId) ? this.sessionStorage.clientWMSId : "";
      var workstreamId = this.tileService.isValidId(this.sessionStorage.workstreamId) ? this.sessionStorage.workstreamId : "";
      var role = this.tileService.isValidString(this.sessionStorage.roleType) ? this.sessionStorage.roleType : "";
      var enterpriseId = this.tileService.isValidString(this.sessionStorage.enterpriseId) ? this.sessionStorage.enterpriseId : "";
      var endtoendName = this.tileService.isValidString(this.sessionStorage.endToEndName) ? this.sessionStorage.endToEndName : "";
      var cmsName = this.tileService.isValidString(this.sessionStorage.cmsName) ? this.sessionStorage.cmsName : "";
      var wsName = this.tileService.isValidString(this.sessionStorage.workstreamName) ? this.sessionStorage.workstreamName : "";
      var tmsName = this.tileService.isValidString(this.sessionStorage.tmsName) ? this.sessionStorage.tmsName : "";

      switch (this.tile.ShortDescription) {
        case "IMAMP":
        case "IMADP":
        case "IMFP":
        case "MAA":
        case "MAAT":
          url = url + endtoendUId;
          break;
        case "PDP":
        case "ATAL":
          url = url + this.sessionStorage.endToEndId;
          break;
        case "AITR":
          if (endtoendUId == "") { endtoendUId = null; }
          if (cmsUId == "") { cmsUId = null; }
          if (teamUId == "") { teamUId = null; }
          if (workstreamUId == "") { workstreamUId = null; }
          if (this.sessionStorage.cmsName == "" && workstreamUId != null) {
            cmsUId = null;
            url = url + "E2EUID=" + endtoendUId + "&CMSUID=" + cmsUId + "&TMSUID=" + teamUId + "&WSUID=" + workstreamUId;
          }
          else {
            url = url + "E2EUID=" + endtoendUId + "&CMSUID=" + cmsUId + "&TMSUID=" + teamUId + "&WSUID=" + workstreamUId;
          }
          break;
        case "RRI":
          if (this.sessionStorage.cmsName == "")
            url = url + "/Risks?endtoenduid=" + endtoendUId + "&cmsuid=&workstreamuid=" + workstreamUId + "&teamuid=" + teamUId + "&app=MYHPDP&entityid=";
          else
            url = url + "/Risks?endtoenduid=" + endtoendUId + "&cmsuid=" + cmsUId + "&workstreamuid=" + workstreamUId + "&teamuid=" + teamUId + "&app=MYHPDP&entityid=";
          break;
        case "RM":
          if (this.sessionStorage.cmsName == "")
            url = url + "/Milestones?endtoenduid=" + endtoendUId + "&cmsuid=&workstreamuid=" + workstreamUId + "&teamuid=" + teamUId + "&app=MYHPDP&entityid=";
          else
            url = url + "/Milestones?endtoenduid=" + endtoendUId + "&cmsuid=" + cmsUId + "&workstreamuid=" + workstreamUId + "&teamuid=" + teamUId + "&app=MYHPDP&entityid=";
          break;
        case "RI":
          if (this.sessionStorage.cmsName == "")
            url = url + "/Issues?endtoenduid=" + endtoendUId + "&cmsuid=&workstreamuid=" + workstreamUId + "&teamuid=" + teamUId + "&app=MYHPDP&entityid=";
          else
            url = url + "/Issues?endtoenduid=" + endtoendUId + "&cmsuid=" + cmsUId + "&workstreamuid=" + workstreamUId + "&teamuid=" + teamUId + "&app=MYHPDP&entityid=";
          break;
        case "MVD":
          url = url + "?projectId=" + this.sessionStorage.endToEndId + "&isInternalCall=" + true;
          break;
        case "PD":
        case "IC":
          if (this.sessionStorage.teamId) {
            this.sessionStorage.profileTab = enumProfileTab.TeamStructure;
            url = "/mywizard/teamstructure/E2E/" + this.sessionStorage.endToEndUId;
           // this.router.navigate(['/mywizard/teamstructure', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          }
          else if (this.sessionStorage.workstreamId || this.sessionStorage.clientWMSId) {
            this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
            url = "/mywizard/clientstructure/E2E/" + this.sessionStorage.endToEndUId;
            //this.router.navigate(['/mywizard/clientstructure', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          } else {
            url = "/mywizard/profile/E2E/" + this.sessionStorage.endToEndUId;
            this.sessionStorage.profileTab = enumProfileTab.Profile;
          }
          //this.router.navigate([url, this.sessionStorage.endToEndUId]);
          break;
        case "OPPOR":
        case "OPR":
          this.sessionStorage.createdE2EId = 0;
          this.sessionStorage.profileTab = enumProfileTab.Profile;
          this.router.navigate([url, { projectId: 0, currentPeriod: this.sessionStorage.statusAsOnPeriod }]);
          break;
        case "Initiative":
        case "Initi":
          this.sessionStorage.createdE2EId = 0;
          this.sessionStorage.profileTab = enumProfileTab.Profile;
          this.router.navigate([url, { projectId: 0, currentPeriod: this.sessionStorage.statusAsOnPeriod }]);
          break;
        case "PHC":
        case "CRM":
          url = url.replace('{endToEndUId}', endtoendUId);
          break;
        case "TMC":
          url = url + endtoendUId + "/" + cmsUId + "/" + teamUId;
          break;
        case "CPF":
          url = url;
          break;
        case "PM":
        case "MCC":
          var type = this.tile.ShortDescription == "PM" ? "RRM" : "PMPE2E";
          teamUId = this.sessionStorage.selectedSubmissionType == 4 ? teamUId : null;
          url = url + "?EndToEndUId=" + endtoendUId + "&Type=" + type + "&TeamId=" + teamUId;
          break;
        default:
          if (this.sessionStorage.cmsName == "") {
            cmsUId = "";
          }
          url = this.tile.Url;
          url = url.replace('{ClientUID}', this.tileService.isValidGUId(clientUId) ? clientUId : "null");
          url = url.replace('{E2EUID}', this.tileService.isValidGUId(endtoendUId) ? endtoendUId : "null");
          url = url.replace('{CMSUID}', this.tileService.isValidGUId(cmsUId) ? cmsUId : "null");
          url = url.replace('{WorkstreamUID}', this.tileService.isValidGUId(workstreamUId) ? workstreamUId : "null");
          url = url.replace('{TeamUID}', this.tileService.isValidGUId(teamUId) ? teamUId : "null");
          url = url.replace('{EnterpriseID}', this.tileService.isValidString(enterpriseId) ? enterpriseId : '');
          url = url.replace('{E2EName}', this.tileService.isValidString(endtoendName) ? endtoendName : '');
          url = url.replace('{CMSName}', this.tileService.isValidString(cmsName) ? cmsName : '');
          url = url.replace('{WSName}', this.tileService.isValidString(wsName) ? wsName : '');
          url = url.replace('{TMSName}', this.tileService.isValidString(tmsName) ? tmsName : '');
          break;
      }

      if (url != "" && TileConstants.InternalTiles.indexOf(this.tile.ShortDescription) == -1) {
        if (target.toUpperCase() == "_BLANK") {
          window.open(url, target);
        }
        else {
        window.open(environment.clientUrl + url, target);
        }
      }
      else {

        if (TileConstants.InternalTilesWithoutE2E.indexOf(this.tile.ShortDescription) == -1) {
          this.router.navigate([url, 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });

        }
        else {
          this.router.navigate([url], { relativeTo: this.activatedRoute });
        }
      }
    }
  }
  AlertKeyContact = function (tileId) {
    this.sessionStorage.parentTileId = tileId;
    this.sessionStorage.profileTab = enumProfileTab.KeyContacts;
    this.router.navigate(['/mywizard/keycontacts', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });

  }
  AlertNotificationProfile = function (tileId) {
    this.sessionStorage.parentTileId = tileId;
    this.sessionStorage.profileTab = enumProfileTab.Profile;
    this.router.navigate(['/mywizard/profile', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
  }
  AlertWorkStream = function (tileId) {
    this.sessionStorage.parentTileId = tileId;
    this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
    this.router.navigate(['/mywizard/clientstructure', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
  }
  AlertScope = function (tileId) {
    this.sessionStorage.parentTileId = tileId;
    this.sessionStorage.profileTab = enumProfileTab.Scope;
    this.router.navigate(['/mywizard/scope', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
  }
  IsDisableAlertNavigation = function () {
    if (this.sessionStorage.teamId || this.sessionStorage.clientWMSId || this.sessionStorage.workstreamId) {
      return true;
    }
    return false;
  }
  AlertMissingCoreMetrics = function () {
    var url = environment.MetricsUrl;
    var endtoendUId = this.tileservice.isValidGUId(this.sessionStorage.endToEndUId) ? this.sessionStorage.endToEndUId : "";
    url = url + "?EndToEndUId=" + endtoendUId + "&Type=TMP" + "&TeamId=null";
    window.open(url, "_blank");
  }
  ShowMandatoryAlert = function () {
    if ($('.tile-title').length <= 1) {
      return true;
    }
    return false;
  }

  formateDate(date): string {
    var monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
      "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ]
    var splittedDate = date.split('-');
    var day = splittedDate[2];
    var month = parseInt(splittedDate[1]);
    var monthText = monthNames[month - 1];
    var year = splittedDate[0];
    var formatedDate = day + "-" + monthText + "-" + year
    return formatedDate;

  }
  trackByIndex(index: number, item: any): number {
    return index;
  }
}
