import { Component, HostListener, ViewChild, Output, EventEmitter } from '@angular/core';
import { TileService } from '../../core/services/tile.service';
import { SessionStorage } from '../../core/context/sessionstorage';
import { DemographicsService } from '../../core/services/demographics.service';
import { enumModuleAction, enumTile, enumFeatureAccess, enumProfileTab, enumDeliveryFunction, enumMethodology, enumDeliveryType, enumPlatformScopeOfWork } from '../../core/context/enum';
import { SharedService } from '../../core/services/shared.service';
import * as Enumerable from 'linq';
/*import { ManageDemographicsComponent } from './model.popup.component/manage.demographics.component';*/
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { InitiateProfileSharedService } from './initiate.profile.shared.service';
import { ScopeSelectorComponent } from '../scope.selector.component/scope.selector.component';
import { HeaderComponent } from '../header.component/header.component';
import { InitiateProfileComponent } from './initiate.profile.component';

import { PhoenixUrlConfigurationComponent } from './model.popup.component/phoenix.url.configuration.component/phoenix.url.configuration.component';
import { UnsavedChangesService } from '../../core/services/unsaved.changes.service';
import cloneDeep from 'lodash/cloneDeep';
import { SecurityDeliveryFunctionConstants } from '../../core/context/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationLoggingService } from '../../core/services/application.logging.service';
import { DeliveryFunctionsDescriptionComponent } from './model.popup.component/delivery.functions.description.component';
import { BroadCastService } from '../../core/services/broadcast.service';
import { environment } from '../../../environments/environment';
import { forEach } from 'lodash';
import { CurrentSustainabilityDescriptionComponent } from './model.popup.component/current.sustainability.description.component'
declare var userNotification: any;
var $ = (<any>window).$;
declare var window: any;
@Component({
  templateUrl: './scope.component.html',
  providers: [TileService, InitiateProfileSharedService, ScopeSelectorComponent, HeaderComponent, DemographicsService, BsModalService]
})

export class ScopeComponent {

  @ViewChild('scopeform') scopeForm: NgForm;
  phoenixBaseUrlConfiguration = {};
  serviceusedSelection: any;
  confirmationMessage: string;
  alertModalHeader: string;
  primarytechnologiesSelection: any;
  platformSelection: any;
  workstreamDelivery: any;
  event: any;
  unsavedSubscription: Subscription;
  unsavedConfirm: boolean = false;
  endToEndType = this.sessionStorage.endToEndType;
  CreatePage: boolean;
  scopeDetails: any;
  E2eDemoGraphics: any;
  involvedFactoriesList: any;
  //selectedInvolvedFactory: any;
  //involvedfactoriesgriddata: any;
  deliverySelection: any;
  IsRequireWorkstreamRefresh: boolean;
  RoleAccess = this.sessionStorage.roleAccessCode;
  IsAdmin = this.sharedService.isAdmin(this.sessionStorage.roleTypeCode);
  SubmissionType = this.sessionStorage.selectedSubmissionType;
  action: string;
  currentSelectedMethodology: any;
  modalRefManageDemographicPopup: BsModalRef;
  modalRefMapCustomerPopup: BsModalRef;
  modalRefConfirmationPopup: BsModalRef;
  profileComponent: BsModalRef;
  modalRef_load: BsModalRef;
  IsOppInitNotCreated: boolean = false;
  path = this.location.path();
  ActiveTab: enumProfileTab;
  IsDeliveryMandatoryDataSet: boolean;
  ProfileTabHighlight: boolean;
  keyContactTabHighlight: boolean;
  showDeliveryScopeValidation: boolean;
  endToEndId = this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1 ? this.sessionStorage.createdE2EId : this.sessionStorage.endToEndId;
  RoleTypeCode = (this.sessionStorage.roleTypeCode == null || this.sessionStorage.roleTypeCode == undefined) ? false : (this.sessionStorage.roleTypeCode.indexOf('A') == 0) ? true : false;
  isCMMScopeOfWork: boolean = false;
  isCDMScopeOfWork: boolean = false;
  HideDeliveryScope: boolean = false;
  DeliveryTypeMandatory: boolean = false;
  isShowValue: boolean = false;
  isShowClassValue: boolean = true;
  isShowValuefn: boolean = true;
  flagAccordian: boolean = false;
  hideSICoreMetricsIndicator: boolean = false;
  @Output() InitiateProfileTabSetup = new EventEmitter();
  ProfileCompletionSubscription: Subscription;
  IsProfileCompleted: boolean;
  isOpportunityCreator: boolean;
  isInitiativeCreator: boolean;
  IsOpportunityInitiativeCreator: boolean;
  GenAI: any;
  currentSustainabilityAssessmentUrl: any;
  newArray = [];

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedConfirm && this.sessionStorage.endToEndUId) {
      if(this.scopeForm.form.dirty) {return confirm('Changes you made may not be saved.');
      }
      else { return true; }
    } else {
      return true;
    }
  }

  constructor(
    private tileService: TileService,
    private sessionStorage: SessionStorage,
    private sharedService: SharedService,
    private demographicsService: DemographicsService,
    private modalService: BsModalService,
    private location: Location,
    private initiateProfileSharedService: InitiateProfileSharedService,
    private scopeSelectorComponent: ScopeSelectorComponent,
    private headerComponent: HeaderComponent,
    private initiateProfileComponent: InitiateProfileComponent,
    private unsavedChangesService: UnsavedChangesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private applicationLoggingService: ApplicationLoggingService,
    private broadCastService: BroadCastService

  ) {
    this.unsavedSubscription = this.unsavedChangesService.currentMessage.subscribe((data) => {
      if (data == "scope") {
        if (this.scopeForm.form.dirty) {
          if (confirm("Changes you made may not be saved.")) {
            this.unsavedConfirm = true
            this.sessionStorage.isUserAlert = false;
          } else {
            this.sessionStorage.isUserAlert = true;
          }
        }
        else {
          this.sessionStorage.isUserAlert = false;
        }
      }
    });
    this.ProfileCompletionSubscription = this.broadCastService.refreshProfileCompletion().subscribe((response) => {
      if (response != null) {
        this.profileCompletedStatus(response);
      }
    });
  }

  ngOnInit() {
    this.initiateProfileSharedService.SetupProfileAndAdminPage(this);
    if (!this.sessionStorage.endToEndUId && !this.IsOpportunityInitiativeCreator) {
      this.router.navigate(['MyWizard/Back/Profile'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId, profileTabCode: 'Scope' } });
      return false;
    }
    this.endToEndId = this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1 ? this.sessionStorage.createdE2EId : this.sessionStorage.endToEndId;
    this.LoadScopeTab();
    this.IsProfileCompleted = this.sessionStorage.IsProfileCompleted;
    setTimeout(() => {
      window.TooltipFunc();
    }, 0);
  }

  ngAfterViewInit() {
    this.IsProfileCompleted = this.sessionStorage.IsProfileCompleted;
  }

  ngOnDestroy() {
    this.unsavedSubscription.unsubscribe();
    this.ProfileCompletionSubscription.unsubscribe();
  }

  OnBackClick() {
    this.initiateProfileComponent.OnBackClick();
  }
  profileCompletedStatus(status) {
    this.IsProfileCompleted = status;
  }
  LoadScopeTab(setfocus: boolean = false) {
    this.demographicsService.getE2EDemographics(this.endToEndId, this.endToEndType, "Scope", this.RoleTypeCode, this.CreatePage).subscribe((response: any) => {
      this.E2eDemoGraphics = response;
      this.scopeDetails = response.Scope;

      if (this.endToEndType == 'C') {
        this.scopeDetails.classAAssets.forEach((item) => {
          const duplicate = this.newArray.find((obj) => obj.ClassAAsset === item.ClassAAsset);
          if (!duplicate) {
            const unique = { ClassAAsset: '', Initiative: [], SubAsset: [] };
            unique.ClassAAsset = item.ClassAAsset;
            unique.Initiative.push(item.Initiative);
            unique.SubAsset.push(item.SubAsset);
            this.newArray.push(unique);
          }
          else {
            const element = this.newArray.find((obj) => obj.ClassAAsset === duplicate.ClassAAsset);
            element.Initiative.push(item.Initiative);
            element.SubAsset.push(item.SubAsset);

          }
        })
      }
      if (!this.CreatePage) {
        this.RoleAccess = this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);
      }
      this.scopeDetails.PlatformMapping = this.scopeDetails.PlatformMapping != null && this.scopeDetails.PlatformMapping != undefined ? this.scopeDetails.PlatformMapping : "";
      this.deliverySelection = this.scopeDetails.DeliveryTypes;
      this.IsRequireWorkstreamRefresh = false;
      if (setfocus) {
        $("[name='scopeform']").find('a[name="manageprimarytech"]').focus();
      }
      this.UpdateDeliveryFunctionShowHide();
      this.UpdateDeliveryScopeShowHide();
      this.deliveryScopeValidation();
      this.enableScopeOfWork();
      this.hideDeliveryScopeForSecurity();
      this.deliveryTypeMandate();
      this.showInvolvedFactories(this.scopeDetails);
      this.GenAI = [{ Id: null, Name: 'Select' }, { Id: 'Y', Name: 'Yes' }, { Id: 'N', Name: 'No' }];
      (window as any).scrollTo(0, $('.dynamic-content').offsetTop);
      setTimeout(() => {
        //window.dropdownComponent();
        // window.tabsComponent();
        window.collapseComponent();
      }, 0);
      //if (this.scopeDetails.TempDeliveryFunctionSMList.length > 0) {
      //  setTimeout(() => { this.fixDeliveryFunctionAlignment() }, 1000);
      //}
    });
    this.IsOppInitNotCreated = false;
    this.initiateProfileSharedService.ValidateDemographicTabHighlight(this);
    if (this.CreatePage) {
      this.InitiateProfileTabSetup.emit({ IsOppInitNotCreated: this.IsOppInitNotCreated, keyContactTabHighlight: this.keyContactTabHighlight, ProfileTabHighlight: this.ProfileTabHighlight, tabId: enumProfileTab.Scope });
    }
    this.initiateProfileSharedService.errorTabHighlight.subscribe(result => {
      this.InitiateProfileTabSetup.emit({ IsOppInitNotCreated: this.IsOppInitNotCreated, keyContactTabHighlight: this.keyContactTabHighlight, ProfileTabHighlight: this.ProfileTabHighlight, tabId: enumProfileTab.Scope });
    });
    this.sharedService.UsageLogging(enumModuleAction.Tile, enumTile.InitiateProfile, enumFeatureAccess.Scope);
    setTimeout(function () {
      window.TooltipFunc();
    }, 0);
  }

  showInvolvedFactories(scopeDetails) {
    if (this.endToEndType == "I" || this.endToEndType == "O") {
      this.involvedFactoriesList = scopeDetails.InvolvedFactories.filter((x: any) => x.ParentId == null && x.DeliveryCenterId == null);
      //this.involvedFactoriesList = scopeDetails.InvolvedFactories.remove((x: any) => x.ParentId != null && x.DeliveryCenterId != null);
    }
    else {
      this.involvedFactoriesList = scopeDetails.InvolvedFactories;
    }
    //this.selectedInvolvedFactory = scopeDetails.InvolvedFactoryInputs;
    //var gridData = Enumerable.from(scopeDetails.InvolvedFactories).join(
    //  this.selectedInvolvedFactory, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();
    //this.involvedfactoriesgriddata = gridData;
  }

  async SaveDelivery(formvalid, form) {
    if (this.scopeDetails && this.scopeDetails.PhoenixMapping && this.scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled) {
      //no. of rows having methodology as selected
      var selectedMethodology = this.scopeDetails.PhoenixMapping.IterationTypeMethodologies.filter((x: any) => x.MethodologyTypeId != null && x.MethodologyTypeId != '').length;
      if (selectedMethodology && selectedMethodology >= 1) {
        this.scopeDetails.PhoenixMapping.IterationTypeMethodologies.forEach((data, index) => {
          var methodologyControl = form.form.controls['methodology_' + index];
          var iterationControl = form.form.controls['iteration_' + index];
          if (methodologyControl?.value === '' || methodologyControl?.value === null) {
            methodologyControl.setValidators([]);
            methodologyControl.updateValueAndValidity();
            iterationControl.setValidators([]);
            iterationControl.updateValueAndValidity();
          }
        });
      }
    }
    if (form.valid) {
      if (!this.scopeDetails.CapacityServices) {
        if (this.scopeDetails.DeliveryTypes.length <= 0 && this.DeliveryTypeMandatory) {
          this.sharedService.openAlertPopup("Please select any one delivery type.", window.scrollY);
          return false;
        }
      }
      if (this.scopeDetails.DeliveryTypes.length > 0 && !this.hideDeliveryScopeAlert()) {
        let result = this.scopeDetails.TempDeliveryScopeList.some(e => e.IsSelected == true);
        if (!result) {
          this.sharedService.openAlertPopup("Please select any one delivery scope.", window.scrollY);
          return false;
        }
      }
      this.deliveryScopeValidation();
      if (this.showDeliveryScopeValidation && !this.HideDeliveryScope) {
        this.sharedService.openAlertPopup("Please review the highlighted warning message and update accordingly.", window.scrollY);
        return false;
      }

      this.E2eDemoGraphics.EnterpriseId = this.sessionStorage.enterpriseId;
      if (this.scopeDetails.PhoenixMapping.DeliveryConstructId) {
        this.demographicsService.validatePhoenixMapping(this.endToEndId, null, this.scopeDetails.PhoenixMapping).subscribe((result) => {
          if (result != "") {
            this.sharedService.openAlertPopup(result, window.scrollY);
          } else {
            this.saveScopeDetails(form);
          }
        });
      } else { this.saveScopeDetails(form); }
    }
    else {
      this.sharedService.openAlertPopup('Please review the highlighted fields and update accordingly.', window.scrollY);
      await this.initiateProfileSharedService.focusErrorField('scopeform');
    }
  }

  updateDeliveryScopeId() {
    this.scopeDetails.DeliveryScopeId = Enumerable.from(this.scopeDetails.TempDeliveryScopeList).where((x: any) => x.IsSelected == true).select((s: any) => s.Id).toArray();
  }

  saveScopeDetails(form) {
    var tempSustainabilityLevelName = this.scopeDetails.CurrentSustainabilityLevelName;
    this.demographicsService.getCurrentSustainabilityLevelName(this.sessionStorage.endToEndUId).subscribe((response: any) => {
      this.scopeDetails.CurrentSustainabilityLevelName = response;
      if (this.scopeDetails.CurrentSustainabilityLevelName == "" && this.endToEndType == 'C' && this.scopeDetails.IsDisplaySustainability == true) {
        this.sharedService.openAlertPopup("Current Sustainability Level is not updated. Please take the assessment", window.scrollY, "Ok", false, "Alert", false);
        return false;
      } else if (tempSustainabilityLevelName != this.scopeDetails.CurrentSustainabilityLevelName && this.endToEndType == 'C' && this.scopeDetails.IsDisplaySustainability == true) {
        this.sharedService.openAlertPopup('Current Sustainability Level is not updated. Click on <img src="../assets/images/refresh-icon.svg"/> icon before saving.', window.scrollY, "Ok", false, "Alert", false);
        this.scopeDetails.CurrentSustainabilityLevelName = tempSustainabilityLevelName;
        return false;
      }
      this.scopeDetails.IsRequireWorkstreamRefresh = this.IsRequireWorkstreamRefresh; //For accessing this property for parallel ProcessDemographic service
      if (this.HideDeliveryScope)
        this.E2eDemoGraphics.Scope.DeliveryScopeId = null;
      //Making Master data as null to avoid performance issue on save due to huge data passed in the payload
      this.scopeDetails.PrimaryTechnologies = null;
      this.scopeDetails.DeliverySubgroupServicesUsed = null;
      this.scopeDetails.PlatformList = null;
      this.scopeDetails.InvolvedFactories = null;
      this.E2eDemoGraphics.RoleTypeCode = this.RoleAccess;
      this.demographicsService.postE2EDemographics(this.E2eDemoGraphics).subscribe((response: any) => {
        this.demographicsService.savePhoenixConfiguration(this.E2eDemoGraphics).subscribe((response: any) => {
          this.sharedService.openAlertPopup('Saved Successfully', window.scrollY);
          if (form != undefined) form.resetForm();
          this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });
          this.LoadScopeTab();
          this.initiateProfileComponent.LoadMandatoryDataAttrSet();
        });
        //this.sharedService.ProcessDemographicAttribute((this.IsRequireWorkstreamRefresh ?
        //  enumEntityType.EndToEnd + "|" + enumEntityType.Workstream : enumEntityType.EndToEnd), this.E2eDemoGraphics.E2EId, this.demographicsService);
      });
    });

  }

  showPrimaryTechnologies() {
    //this.primarytechnologiesSelection;
    //var primarytechnologieslist = this.scopeDetails.PrimaryTechnologies;
    //var selectedprimarytechnology = this.scopeDetails.PrimaryTechonologyInputs;
    //var technologiesgriddata = Enumerable.from(primarytechnologieslist).join(
    //  selectedprimarytechnology, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();
    //const initialState = { title: "Primary Technologies", label: "Primary Technology", gridData: technologiesgriddata, masterData: this.scopeDetails.PrimaryTechnologies };
    //this.modalRefManageDemographicPopup = this.modalService.show(ManageDemographicsComponent, {
    //  initialState,
    //  backdrop: "static",
    //  keyboard: false
    //});
    //$(".modal-dialog").addClass('custom-modal');
    //this.modalRefManageDemographicPopup.content.manageDemographicOutput.subscribe(result => {
    //  this.scopeDetails.PrimayTechnology = result.gridData.map(x => x.Name).join();
    //  this.scopeDetails.PrimaryTechonologyInputs = result.gridData.map(({ Id }) => Id);
    //  this.scopeForm.form.markAsDirty();
    //});
  }

  //showPlatform() {
  //  this.platformSelection;
  //  var platformlist = this.scopeDetails.PlatformList;
  //  var selectedplatform = this.scopeDetails.PlatformInputs;
  //  var platformgriddata = Enumerable.from(platformlist).join(
  //    selectedplatform, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();
  //  const initialState = { title: "Platform/ Domain/ Offering", label: "Platform/ Domain/ Offering", gridData: platformgriddata, masterData: this.scopeDetails.PlatformList };
  //  this.modalRefManageDemographicPopup = this.modalService.show(ManageDemographicsComponent, {
  //    initialState,
  //    backdrop: "static",
  //    keyboard: false
  //  });
  //  $(".modal-dialog").addClass('custom-modal');
  //  this.modalRefManageDemographicPopup.content.manageDemographicOutput.subscribe(result => {
  //    this.scopeDetails.PlatformMapping = result.gridData.map(x => x.Name).join();
  //    this.scopeDetails.PlatformInputs = result.gridData.map(({ Id }) => Id);
  //    this.scopeForm.form.markAsDirty();
  //  });
  //}
  showServiceUsed() {
    this.serviceusedSelection;
    var serviceusedlist = this.scopeDetails.DeliverySubgroupServicesUsed;
    var selectedserviceused = this.scopeDetails.ServicesUsedInputs;
    var serviceusedgriddata = Enumerable.from(serviceusedlist).join(
      selectedserviceused, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();
    const initialState = { title: "Services/Factories Used", label: "Service Used", gridData: serviceusedgriddata, masterData: this.scopeDetails.DeliverySubgroupServicesUsed };
    //this.modalRefManageDemographicPopup = this.modalService.show(ManageDemographicsComponent, {
    //  initialState,
    //  backdrop: "static",
    //  keyboard: false
    //});
    $(".modal-dialog").addClass('custom-modal');
    this.modalRefManageDemographicPopup.content.manageDemographicOutput.subscribe(result => {
      this.scopeDetails.ServicesUsed = result.gridData.map(x => x.Name).join();
      this.scopeDetails.ServicesUsedInputs = result.gridData.map(({ Id }) => Id);
      this.scopeForm.form.markAsDirty();
    });
  }

  methodologyChange(iterationMethodology, index) {
    var temp = cloneDeep(iterationMethodology);
    if (temp.MethodologyTypeId != null) {
      var isDeliveryTypeSelected = false;
      if (temp.MethodologyTypeId == enumDeliveryType.AD || temp.MethodologyTypeId == enumDeliveryType.Agile) {
        var isDeliveryTypeSelected = this.scopeDetails.DeliveryTypes.filter((x: any) => x == temp.MethodologyTypeId).length > 0;
        var isDeliveryTypeSelectedTesting = this.scopeDetails.DeliveryTypes.filter((x: any) => x == enumDeliveryType.Testing).length > 0;
        isDeliveryTypeSelected = isDeliveryTypeSelected || isDeliveryTypeSelectedTesting;
      }
      if (!isDeliveryTypeSelected) {
        this.confirmationMessage = 'The Methodology is not aligned with Delivery Type scope of E2E. Please confirm if the Delivery Type can be updated to proceed with auto-creation.';
        this.action = "phoenix";
        this.currentSelectedMethodology = temp.MethodologyTypeId;
        this.workstreamConfirmationPopUp(this.confirmationMessage, null, null, null);
      }
    }
    else {
      this.scopeForm.form.controls['methodology_' + index].setValidators([]);
      this.scopeForm.form.controls['methodology_' + index].updateValueAndValidity();
      this.scopeForm.form.controls['iteration_' + index].setValidators([]);
      this.scopeForm.form.controls['iteration_' + index].updateValueAndValidity();
    }
    this.scopeDetails.PhoenixMapping.IterationTypeMethodologies[index].IterationTypeId = null;
    this.scopeDetails.PhoenixMapping.IterationTypeMethodologies[index].IterationTypes.forEach((x: any) => x.IsSelected = false);
  }

  validateMethodology(formStatus) {
    if (this.scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled) {
      var selectedIterations = this.scopeDetails.PhoenixMapping.IterationTypeMethodologies.filter((x: any) => x.MethodologyTypeId == null).length;
      return this.scopeDetails.PhoenixMapping.IterationTypeMethodologies.length != selectedIterations ? false : true;
    }
    else {
      return false;
    }
  }

  disableMethodology(iterationMethodology) {
    if (this.RoleAccess != 'E')
      return true;
    else {
      var isDeliveryTypeSelected = this.scopeDetails.DeliveryTypes.filter((x: any) => x == iterationMethodology.MethodologyType).length > 0;
      var isDeliveryTypeSelectedTesting = this.scopeDetails.DeliveryTypes.filter((x: any) => x == enumDeliveryType.Testing).length > 0;
      return !(isDeliveryTypeSelected || isDeliveryTypeSelectedTesting);
    }
  }

  trackByIndex(index, item): number {
    return index;
  }


  phoenixMappingChange(event, form) {
    if (this.scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled) {
      var isDataFilled = this.scopeDetails.PhoenixMapping.PhoenixClientId || this.scopeDetails.PhoenixMapping.ServiceInstanceId || this.scopeDetails.PhoenixMapping.PhoenixClientId;
      this.scopeDetails.PhoenixMapping.IterationTypeMethodologies.forEach((data) => {
        if (data.IterationTypeId != null || data.MethodologyTypeId != null) {
          isDataFilled = true;
        }
      });
      if (isDataFilled) {
        event.target.checked = true;
        this.confirmationMessage = 'Setting to "No" will delete mapping details and any associated workstreams. If you wish to continue, please confirm by clicking on "OK" else click "Cancel" to prevent data loss.';
        this.action = "phoenixdescope";
        this.workstreamConfirmationPopUp(this.confirmationMessage, null, null, event);
      }
      else {
        this.scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled = false;
      }
      this.scopeDetails.PhoenixMapping.IterationTypeMethodologies.forEach((data, index) => {
        var methodologyControl = form.form.controls['methodology_' + index];
        var iterationControl = form.form.controls['iteration_' + index];
        if (methodologyControl.value == '' || methodologyControl.value == null) {
          methodologyControl.setValidators([]);
          methodologyControl.updateValueAndValidity();
          iterationControl.setValidators([]);
          iterationControl.updateValueAndValidity();
        }
      });
    }
    else {
      this.scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled = true;
    }
  }

  showPhoenixBaseUrlConfiguration() {
    this.phoenixBaseUrlConfiguration = {};
    const initialState = { scrollYPosition: window.scrollY };
    this.modalRef_load = this.modalService.show(PhoenixUrlConfigurationComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });

    this.modalRef_load.content.apiModalPopupData.subscribe(result => {
      if (result.saveData) {
        this.scopeForm.form.markAsPristine();
      }
    });
  }

  descopeMethodology(delivery) {
    if ((delivery.Id == enumDeliveryType.AD && this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Testing) <= -1) || (delivery.Id == enumDeliveryType.Testing && this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AD) <= -1)) {
      var iterationMethodology = this.scopeDetails.PhoenixMapping.IterationTypeMethodologies.filter((x: any) => x.MethodologyTypeId == enumMethodology.AD)[0];
      if (iterationMethodology) {
        iterationMethodology.MethodologyTypeId = null;
        iterationMethodology.IterationTypeId = null;
      }
    }
    if ((delivery.Id == enumDeliveryType.Agile && this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Testing) <= -1) || (delivery.Id == enumDeliveryType.Testing && this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Agile) <= -1)) {
      var iterationMethodology = this.scopeDetails.PhoenixMapping.IterationTypeMethodologies.filter(x => x.MethodologyTypeId == enumMethodology.Agile)[0];
      if (iterationMethodology) {
        iterationMethodology.MethodologyTypeId = null;
        iterationMethodology.IterationTypeId = null;
      }
    }
  }

  capacityServiceChange() {
    if (this.scopeDetails.CapacityServices) {
      this.UpdateDeliveryFunctionShowHide();
      this.UpdateDeliveryFunctionAutoselection(0);
    }
    else {
      this.UpdateDeliveryFunctionShowHide();
      this.UpdateDeliveryFunctionDeselection();
    }
    this.hideDeliveryScopeForSecurity();
  }

  deliveryChange(delivery, $event) {
    this.confirmationMessage = "";
    if (delivery.Id == enumDeliveryType.InfraStructure && !delivery.IsSelected) {
      this.alertModalHeader = 'Infrastructure Delivery Type Info'
      var message = 'You are scoping in Infrastructure Delivery Type. If your EndtoEnd has a Consulting component kindly Scope-In "Infra Consulting and Project" Delivery Function.';
      this.sharedService.openAlertPopup(message, window.scrollY, "Ok", false, this.alertModalHeader);
      $('.close').focus();
    }
    if (delivery.IsSelected == true) {
      if (this.scopeDetails.WorkstreamDeliveryTypes.indexOf(delivery.Id) > -1) {
        if ((delivery.Id == enumDeliveryType.AD || delivery.Id == enumDeliveryType.Agile) && this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Testing) > -1) {
          if (!this.showDeScopeMethodologyAlert($event, delivery)) {
            this.SetDeliveryTypeFalse(delivery);
          }
        }
        else {
          $event.target.checked = true;
          this.confirmationMessage = 'Setting delivery type to "No" will result in the <b>deactivation of any workstream associated to this delivery type.</b> If you wish to continue, please confirm by clicking on "OK" else click "Cancel" to retain the workstream and prevent data loss.';
          this.event = $event;
          this.workstreamDelivery = delivery;
          this.action = "workstream";
          this.workstreamConfirmationPopUp(this.confirmationMessage, delivery, null, $event);

          $('.close').focus();
        }
      }
      else if (delivery.Id == enumDeliveryType.Testing &&
        (((this.scopeDetails.WorkstreamDeliveryTypes.indexOf(1) > -1) && (this.scopeDetails.DeliveryTypes.indexOf(1) < 0))
          || ((this.scopeDetails.WorkstreamDeliveryTypes.indexOf(2) > -1) && (this.scopeDetails.DeliveryTypes.indexOf(2) < 0))
          || ((this.scopeDetails.WorkstreamDeliveryTypes.indexOf(3) > -1) && (this.scopeDetails.DeliveryTypes.indexOf(3) < 0))
          || ((this.scopeDetails.WorkstreamDeliveryTypes.indexOf(4) > -1) && (this.scopeDetails.DeliveryTypes.indexOf(4) < 0)))) {

        $event.target.checked = true;
        this.confirmationMessage = 'Setting delivery type to "No" will result in the <b>deactivation of any workstream associated to this delivery type.</b> If you wish to continue, please confirm by clicking on "OK" else click "Cancel" to retain the workstream and prevent data loss.';
        this.event = $event;
        this.workstreamDelivery = delivery;
        this.action = "workstream";
        this.workstreamConfirmationPopUp(this.confirmationMessage, delivery, null, $event);
        $('.close').focus();
      }
      else if (delivery.Id == enumDeliveryType.Ops || delivery.Id == enumDeliveryType.Sec) {
        $event.target.checked = true;
        this.confirmationMessage = 'Setting delivery type to "No" will result in the <b>deactivation of any workstream associated to this delivery type.</b> If you wish to continue, please confirm by clicking on "OK" else click "Cancel" to retain the workstream and prevent data loss.';
        this.event = $event;
        this.workstreamDelivery = delivery;
        this.action = "workstream";
        this.workstreamConfirmationPopUp(this.confirmationMessage, delivery, null, $event);
        $('.close').focus();
      }
      else {
        if (!this.showDeScopeMethodologyAlert($event, delivery)) {
          this.SetDeliveryTypeFalse(delivery);
        }
      }
    }
    else {
      this.SetDeliveryTypeTrue(delivery);
    }
    this.scopeForm.form.markAsDirty();
  };

  workstreamConfirmationPopUp(message, delivery, deliveryFunctionItem, $event) {
    var confirmationModel = this.sharedService.openConfirmationPopup(message, "Ok", "Cancel", window.scrollY)//,function(){this.test()}, function(){this.ConfirmedNo()}); //ConfirmedYes(), ConfirmedNo());
    confirmationModel.content.confirmationResult.subscribe(response => {
      if (response.result) {
        this.ConfirmedYes(delivery, $event);
      } else {
        this.ConfirmedNo(deliveryFunctionItem, $event);
      }
    });
  }

  showDeScopeMethodologyAlert($event, delivery) {
    if (this.scopeDetails.PhoenixMapping && this.scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled) {
      var adIterationMethodology = this.scopeDetails.PhoenixMapping.IterationTypeMethodologies.filter((x: any) => x.MethodologyTypeId == enumMethodology.AD)[0];
      var agileIterationMethodology = this.scopeDetails.PhoenixMapping.IterationTypeMethodologies.filter((x: any) => x.MethodologyTypeId == enumMethodology.Agile)[0];
      if ((adIterationMethodology && ((delivery.Id == enumDeliveryType.AD && this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Testing) <= -1) || (delivery.Id == enumDeliveryType.Testing && this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AD) <= -1))) ||
        (agileIterationMethodology && ((delivery.Id == enumDeliveryType.Agile && this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Testing) <= -1) || (delivery.Id == enumDeliveryType.Testing && this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Agile) <= -1)))) {
        $event.target.checked = true;
        this.confirmationMessage = 'Setting to "No" will delete Phoenix mapping details. If you wish to continue, please confirm by clicking on "OK" else click "Cancel" to prevent data loss.';
        this.event = $event;
        this.workstreamDelivery = delivery;
        this.action = "workstream";
        this.workstreamConfirmationPopUp(this.confirmationMessage, delivery, null, $event);
        $('.close').focus();
        return true;
      }
    }
    return false;
  }

  ConfirmedYes(delivery, event) {
    if (this.action == "workstream") {
      this.SetDeliveryTypeFalse(delivery);
      event.target.checked = false;
      this.IsRequireWorkstreamRefresh = true;
    }
    else if (this.action == "phoenix") {
      $("#" + this.currentSelectedMethodology + "_deliverytypeid").click();
    }
    else if (this.action == "phoenixdescope") {
      this.scopeDetails.PhoenixMapping.DeliveryConstructId = null;
      this.scopeDetails.PhoenixMapping.PhoenixClientId = null;
      this.scopeDetails.PhoenixMapping.ServiceInstanceId = null;
      this.scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled = false;
    }
    else if (this.action == "siCoreIndicator") {
      this.scopeDetails.SICoreMetricsIndicator = !this.scopeDetails.SICoreMetricsIndicator;
      this.scopeDetails.IsNewSIMetricsDisabled = this.scopeDetails.SICoreMetricsIndicator;
      //this.updateDeliveryFunctionForSIMetrics();
      this.UpdateDeliveryFunctionShowHide();
    }
  };

  ConfirmedNo(deliveryFunctionItem, event) {
    if (this.action == "phoenix") {
      var methodology = this.scopeDetails.PhoenixMapping.IterationTypeMethodologies.filter((x: any) => x.MethodologyTypeId == this.currentSelectedMethodology)[0];
      if (methodology) {
        methodology.MethodologyTypeId = null;
      }
    }
    else if (this.action == "phoenixdescope") {
      this.scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled = true;
    }
    else if (this.action == "security") {
      if ((this.scopeDetails.DeliveryFunctionSSId.indexOf(deliveryFunctionItem.Id) > -1) && (deliveryFunctionItem.Name.trim().toLowerCase() != "managed security services"))
        this.scopeDetails.DeliveryFunctionSSId.splice(this.scopeDetails.DeliveryFunctionSSId.indexOf(deliveryFunctionItem.Id), 1);
      if (deliveryFunctionItem.Name.trim().toLowerCase() == "managed security services") {
        this.scopeDetails.DeliveryFunctionSSId.push(deliveryFunctionItem.Id);
        event.target.checked = true;
      } else {
        event.target.checked = false;
      }
    }
    else if (this.action == "siCoreIndicator") {
      event.target.checked = false;
    }
    else {
      event.target.checked = true;
    }
  };


  SetDeliveryTypeFalse(delivery) {
    this.scopeDetails.DeliveryTypes.splice(this.scopeDetails.DeliveryTypes.indexOf(delivery.Id), 1);
    delivery.IsSelected = false;
    this.UpdateDeliveryFunctionShowHide();
    this.UpdateDeliveryFunctionDeselection();
    this.UpdateDeliveryScopeDeselection();
    this.descopeMethodology(delivery);
    this.deliveryScopeValidation();
    this.hideDeliveryScopeForSecurity();
  };

  SetDeliveryTypeTrue(delivery) {
    this.scopeDetails.DeliveryTypes.push(delivery.Id);
    delivery.IsSelected = true;
    this.UpdateDeliveryFunctionShowHide();
    this.UpdateDeliveryFunctionAutoselection(delivery);
    this.UpdateDeliveryScopeAutoselection(delivery);
    this.deliveryScopeValidation();
    this.hideDeliveryScopeForSecurity();
    //if (this.scopeDetails.TempDeliveryFunctionSMList.length > 0) {
    //  setTimeout(() => { this.fixDeliveryFunctionAlignment() }, 2);
    //}
  };


  UpdateDeliveryFunctionDeselection() {
    this.scopeDetails.DeliveryFunctionPPSMList.forEach((ppsmList: any) => {
      var TempData = this.scopeDetails.TempDeliveryFunctionPPSMList.filter((x: any) => x.Id == ppsmList.Id);
      if (TempData == null || TempData == undefined || TempData.length <= 0) {
        ppsmList.IsSelected = false;
        ppsmList.IsDisabled = this.DisableDeliveryFunction(ppsmList.Id);
        if (this.scopeDetails.DeliveryFunctionPPSMId.indexOf(ppsmList.Id) > -1)
          this.scopeDetails.DeliveryFunctionPPSMId.splice(this.scopeDetails.DeliveryFunctionPPSMId.indexOf(ppsmList.Id), 1);
      }
    });
    this.scopeDetails.DeliveryFunctionDTMList.forEach((dtmList: any) => {
      var TempData = this.scopeDetails.TempDeliveryFunctionDTMList.filter((x: any) => x.Id == dtmList.Id);
      if (TempData == null || TempData == undefined || TempData.length <= 0) {
        dtmList.IsSelected = false;
        dtmList.IsDisabled = this.DisableDeliveryFunction(dtmList.Id);
        if (this.scopeDetails.DeliveryFunctionDTMId.indexOf(dtmList.Id) > -1)
          this.scopeDetails.DeliveryFunctionDTMId.splice(this.scopeDetails.DeliveryFunctionDTMId.indexOf(dtmList.Id), 1);
      }
    });
    this.scopeDetails.DeliveryFunctionSMList.forEach((smList: any) => {
      var TempData = this.scopeDetails.TempDeliveryFunctionSMList.filter((x: any) => x.Id == smList.Id);
      if (TempData == null || TempData == undefined || TempData.length <= 0) {
        smList.IsSelected = false;
        smList.IsDisabled = this.DisableDeliveryFunction(smList.Id);
        if (this.scopeDetails.DeliveryFunctionSMId.indexOf(smList.Id) > -1)
          this.scopeDetails.DeliveryFunctionSMId.splice(this.scopeDetails.DeliveryFunctionSMId.indexOf(smList.Id), 1);
      }
    });
    this.scopeDetails.DeliveryFunctionSSList.forEach((ssList: any) => {
      var TempData = this.scopeDetails.TempDeliveryFunctionSSList.filter((x: any) => x.Id == ssList.Id);
      if (TempData == null || TempData == undefined || TempData.length <= 0) {
        ssList.IsSelected = false;
        ssList.IsDisabled = this.DisableDeliveryFunction(ssList.Id);
        if (this.scopeDetails.DeliveryFunctionSSId.indexOf(ssList.Id) > -1)
          this.scopeDetails.DeliveryFunctionSSId.splice(this.scopeDetails.DeliveryFunctionSSId.indexOf(ssList.Id), 1);
      }
    });
  };

  UpdateDeliveryScopeDeselection() {
    this.scopeDetails.DeliveryScopeList.forEach((scopeList: any) => {
      var TempData = this.scopeDetails.TempDeliveryScopeList.filter((x: any) => x.Id == scopeList.Id);
      if (TempData == null || TempData == undefined || TempData.length <= 0) {
        scopeList.IsSelected = false;
        if (this.scopeDetails.DeliveryScopeId.indexOf(scopeList.Id) > -1)
          this.scopeDetails.DeliveryScopeId.splice(this.scopeDetails.DeliveryScopeId.indexOf(scopeList.Id), 1);
      }
    });
  }

  UpdateDeliveryScopeShowHide() {
    this.scopeDetails.TempDeliveryScopeList = [];
    this.scopeDetails.DeliveryScopeList.forEach((ds: any) => {
      if (ds != undefined) {
        this.scopeDetails.TempDeliveryScopeList.push(ds);
      }
    });
    // Commented for future reference
    // Show all Delivery Scope fields with No default and Yes/No for existing records
    //if (this.scopeDetails.DeliveryTypes.length > 0) {
    //  this.scopeDetails.TempDeliveryScopeList = [];
    //  this.scopeDetails.DeliveryScopeList.forEach((ds: any) => {
    //    if (ds != undefined) {
    //      this.scopeDetails.TempDeliveryScopeList.push(ds);
    //    }
    //  });
    //} else { // When no Delivery Type is selected
    //  this.scopeDetails.TempDeliveryScopeList = [];
    //  this.scopeDetails.DeliveryScopeList.forEach((ds: any) => {
    //    if (ds != undefined) {
    //      ds.IsSelected = false;
    //      this.scopeDetails.TempDeliveryScopeList.push(ds);
    //    }
    //  });
    //}
  }

  UpdateDeliveryFunctionShowHide() {
    this.scopeDetails.TempDeliveryFunctionPPSMList = [];
    this.scopeDetails.TempDeliveryFunctionDTMList = [];
    this.scopeDetails.TempDeliveryFunctionSMList = [];
    this.scopeDetails.TempDeliveryFunctionSSList = [];
    this.scopeDetails.DeliveryTypes.forEach((delivery: any) => {
      var deliveryFunctionsPPSM = Enumerable.from(this.scopeDetails.DeliveryTypeDeliveryFunction).where((x: any) => x.ParentId == delivery).toArray();
      deliveryFunctionsPPSM.forEach((fd: any) => {
        let item: any = Enumerable.from(this.scopeDetails.DeliveryFunctionPPSMList).where((x: any) => x.Id == fd.Id).firstOrDefault();
        if (item != undefined) {
          item.IsDisabled = this.DisableDeliveryFunction(fd.Id);
          this.scopeDetails.TempDeliveryFunctionPPSMList.push(item);
        }
      });
      var deliveryFunctionsDTM = Enumerable.from(this.scopeDetails.DeliveryTypeDeliveryFunction).where((x: any) => x.ParentId == delivery).toArray();
      deliveryFunctionsDTM.forEach((fd: any) => {
        var item: any = Enumerable.from(this.scopeDetails.DeliveryFunctionDTMList).where((x: any) => x.Id == fd.Id).firstOrDefault();

        if (item != undefined) {
          item.IsDisabled = this.DisableDeliveryFunction(fd.Id);
          if (this.scopeDetails.SICoreMetricsIndicator) {
            item.IsSelected = this.EnableSICoreMetricsDeliveryFunction(fd.Id, item.IsSelected);
            if (item.IsSelected && this.scopeDetails.DeliveryFunctionDTMId.indexOf(fd.Id) < 0) {
              this.scopeDetails.DeliveryFunctionDTMId.push(fd.Id);
            }
          }
          this.scopeDetails.TempDeliveryFunctionDTMList.push(item);
        }
      });
      this.scopeDetails.TempDeliveryFunctionPPSMList = this.GetUniqueItems(this.scopeDetails.TempDeliveryFunctionPPSMList, 'Id');
      this.scopeDetails.TempDeliveryFunctionDTMList = this.GetUniqueItems(this.scopeDetails.TempDeliveryFunctionDTMList, 'Id');
      var deliveryFunctionsSM = Enumerable.from(this.scopeDetails.DeliveryTypeDeliveryFunction).where((x: any) => x.ParentId == delivery).toArray();
      deliveryFunctionsSM.forEach((fd: any) => {
        var item: any = Enumerable.from(this.scopeDetails.DeliveryFunctionSMList).where((x: any) => x.Id == fd.Id).firstOrDefault();
        if (item != undefined) {
          item.IsDisabled = this.DisableDeliveryFunction(fd.Id);
          this.scopeDetails.TempDeliveryFunctionSMList.push(item);
        }
      });
      this.scopeDetails.TempDeliveryFunctionSMList = this.GetUniqueItems(this.scopeDetails.TempDeliveryFunctionSMList, 'Id');
      var deliveryFunctionsSS = Enumerable.from(this.scopeDetails.DeliveryTypeDeliveryFunction).where((x: any) => x.ParentId == delivery).toArray();
      deliveryFunctionsSS.forEach((fd: any) => {
        var item: any = Enumerable.from(this.scopeDetails.DeliveryFunctionSSList).where((x: any) => x.Id == fd.Id).firstOrDefault();
        if (item != undefined) {
          item.IsDisabled = this.DisableDeliveryFunction(fd.Id);
          this.scopeDetails.TempDeliveryFunctionSSList.push(item);
        }
      });
      this.scopeDetails.TempDeliveryFunctionSSList = this.GetUniqueItems(this.scopeDetails.TempDeliveryFunctionSSList, 'Id');
    });
    if (this.scopeDetails.CapacityServices) {
      this.scopeDetails.CapacityServiceDeliveryFunction.forEach((csfd: any) => {
        var item: any = Enumerable.from(this.scopeDetails.DeliveryFunctionPPSMList).where((x: any) => x.Id == csfd.Id).firstOrDefault();
        if (item != undefined) {
          item.IsDisabled = this.DisableDeliveryFunction(csfd.Id);
          this.scopeDetails.TempDeliveryFunctionPPSMList.push(item);
        }
        var deliveryFunctionsDTM = Enumerable.from(this.scopeDetails.DeliveryFunctionDTMList).where((x: any) => x.Id == csfd.Id).firstOrDefault();
        if (deliveryFunctionsDTM != undefined)
          this.scopeDetails.TempDeliveryFunctionDTMList.push(deliveryFunctionsDTM);
        var deliveryFunctionsSM = Enumerable.from(this.scopeDetails.DeliveryFunctionSMList).where((x: any) => x.Id == csfd.Id).firstOrDefault();
        if (deliveryFunctionsSM != undefined)
          this.scopeDetails.TempDeliveryFunctionSMList.push(deliveryFunctionsSM);
        this.scopeDetails.TempDeliveryFunctionPPSMList = this.GetUniqueItems(this.scopeDetails.TempDeliveryFunctionPPSMList, 'Id');
        this.scopeDetails.TempDeliveryFunctionDTMList = this.GetUniqueItems(this.scopeDetails.TempDeliveryFunctionDTMList, 'Id');
        this.scopeDetails.TempDeliveryFunctionSMList = this.GetUniqueItems(this.scopeDetails.TempDeliveryFunctionSMList, 'Id');
      });
    }
  };

  UpdateDeliveryScopeAutoselection(delivery) {
    this.scopeDetails.TempDeliveryScopeList.forEach((scopeList: any) => {
      var item = Enumerable.from(this.scopeDetails.DeliveryTypeDeliveryScope).where((x: any) => x.ParentId == delivery.Id && x.Id == scopeList.Id).firstOrDefault();
      if (item != undefined) {
        if (!scopeList.IsSelected)
          scopeList.IsSelected = true;
        if (this.scopeDetails.DeliveryScopeId.indexOf(scopeList.Id) < 0) {
          this.scopeDetails.DeliveryScopeId.push(scopeList.Id);
        }
      }
    });
  }

  UpdateDeliveryFunctionAutoselection(delivery) {
    this.scopeDetails.TempDeliveryFunctionPPSMList.forEach((ppsmList: any) => {
      var item = Enumerable.from(this.scopeDetails.DeliveryTypeDeliveryFunction).where((x: any) => x.ParentId == delivery.Id && x.Id == ppsmList.Id && x.IsSelected == true).firstOrDefault();
      var capacityserviceitem = Enumerable.from(this.scopeDetails.CapacityServiceDeliveryFunction).where((x: any) => x.Id == ppsmList.Id && x.IsSelected == true).firstOrDefault();
      if (item != undefined || (capacityserviceitem != undefined && this.scopeDetails.CapacityServices)) {
        ppsmList.IsSelected = true;
        if (this.scopeDetails.DeliveryFunctionPPSMId.indexOf(ppsmList.Id) < 0) {
          this.scopeDetails.DeliveryFunctionPPSMId.push(ppsmList.Id);
        }
      }
    });
    this.scopeDetails.TempDeliveryFunctionDTMList.forEach((dtmList: any) => {
      var item = Enumerable.from(this.scopeDetails.DeliveryTypeDeliveryFunction).where((x: any) => x.ParentId == delivery.Id && x.Id == dtmList.Id && x.IsSelected == true).firstOrDefault();
      var capacityserviceitem = Enumerable.from(this.scopeDetails.CapacityServiceDeliveryFunction).where((x: any) => x.Id == dtmList.Id && x.IsSelected == true).firstOrDefault();
      if (item != undefined || (capacityserviceitem != undefined && this.scopeDetails.CapacityServices)) {
        dtmList.IsSelected = true;
        if (this.scopeDetails.DeliveryFunctionDTMId.indexOf(dtmList.Id) < 0) {
          this.scopeDetails.DeliveryFunctionDTMId.push(dtmList.Id);
        }
      }
    });
    this.scopeDetails.TempDeliveryFunctionSMList.forEach((smList: any) => {
      var item = Enumerable.from(this.scopeDetails.DeliveryTypeDeliveryFunction).where((x: any) => x.ParentId == delivery.Id && x.Id == smList.Id && x.IsSelected == true).firstOrDefault();
      var capacityserviceitem = Enumerable.from(this.scopeDetails.CapacityServiceDeliveryFunction).where((x: any) => x.Id == smList.Id && x.IsSelected == true).firstOrDefault();
      if (item != undefined || (capacityserviceitem != undefined && this.scopeDetails.CapacityServices)) {
        smList.IsSelected = true;
        if (this.scopeDetails.DeliveryFunctionSMId.indexOf(smList.Id) < 0) {
          this.scopeDetails.DeliveryFunctionSMId.push(smList.Id);
        }
      }
    });
    this.scopeDetails.TempDeliveryFunctionSSList.forEach((ssList: any) => {
      var item = Enumerable.from(this.scopeDetails.DeliveryTypeDeliveryFunction).where((x: any) => x.ParentId == delivery.Id && x.Id == ssList.Id && x.IsSelected == true).firstOrDefault();
      var capacityserviceitem = Enumerable.from(this.scopeDetails.CapacityServiceDeliveryFunction).where((x: any) => x.Id == ssList.Id && x.IsSelected == true).firstOrDefault();
      if (item != undefined || (capacityserviceitem != undefined && this.scopeDetails.CapacityServices)) {
        ssList.IsSelected = true;
        if (this.scopeDetails.DeliveryFunctionSSId.indexOf(ssList.Id) < 0) {
          this.scopeDetails.DeliveryFunctionSSId.push(ssList.Id);
        }
      }
    });
  }

  EnableSICoreMetricsDeliveryFunction(deliveryTypeDeliveryFunctionId, IsSelected) {
    deliveryTypeDeliveryFunctionId = deliveryTypeDeliveryFunctionId.toString();
    if (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AD) > -1 && this.scopeDetails.SICoreMetricsIndicator && enumDeliveryFunction.SIAD.split(',').indexOf(deliveryTypeDeliveryFunctionId) > -1) {
      return true;
    }
    else if (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Agile) > -1 && this.scopeDetails.SICoreMetricsIndicator && enumDeliveryFunction.SIAgile.split(',').indexOf(deliveryTypeDeliveryFunctionId) > -1) {
      return true;
    }
    return IsSelected;
  }

  DisableDeliveryFunction(deliveryTypeDeliveryFunctionId) {
    deliveryTypeDeliveryFunctionId = deliveryTypeDeliveryFunctionId.toString();
    if (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AD) > -1 && this.scopeDetails.SICoreMetricsIndicator && enumDeliveryFunction.SIAD.split(',').indexOf(deliveryTypeDeliveryFunctionId) > -1) {
      return true;
    }
    else if (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AD) > -1 && !this.scopeDetails.SICoreMetricsIndicator && enumDeliveryFunction.NonSIAD.split(',').indexOf(deliveryTypeDeliveryFunctionId) > -1) {
      return true;
    }
    else if (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Agile) > -1 && this.scopeDetails.SICoreMetricsIndicator && enumDeliveryFunction.SIAgile.split(',').indexOf(deliveryTypeDeliveryFunctionId) > -1) {
      return true;
    }
    else if (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Agile) > -1 && !this.scopeDetails.SICoreMetricsIndicator && enumDeliveryFunction.NonSIAgile.split(',').indexOf(deliveryTypeDeliveryFunctionId) > -1) {
      return true;
    }
    else if (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AM) > -1 && enumDeliveryFunction.AM.split(',').indexOf(deliveryTypeDeliveryFunctionId) > -1) {
      return true;
    }
    else if (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.InfraStructure) > -1 && enumDeliveryFunction.InfraStructure.split(',').indexOf(deliveryTypeDeliveryFunctionId) > -1) {
      return true;
    }
    else if (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Testing) > -1 && enumDeliveryFunction.Testing.split(',').indexOf(deliveryTypeDeliveryFunctionId) > -1) {
      return true;
    }
    else if (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Ops) > -1 && enumDeliveryFunction.Ops.split(',').indexOf(deliveryTypeDeliveryFunctionId) > -1) {
      return true;
    } else if (this.scopeDetails.CapacityServices && enumDeliveryFunction.CapacityServices.split(',').indexOf(deliveryTypeDeliveryFunctionId) > -1) {
      return true;
    }
    return false;

  }

  deliveryFunctionClick(Type, item, $event) {
    if (Type == "PPSM") {
      this.DeliveryFunction(item.Id, item, this.scopeDetails.DeliveryFunctionPPSMId, $event);
    }
    else if (Type == "DTM") {
      this.DeliveryFunction(item.Id, item, this.scopeDetails.DeliveryFunctionDTMId, $event);
    }
    else if (Type == "SM") {
      this.DeliveryFunction(item.Id, item, this.scopeDetails.DeliveryFunctionSMId, $event);
    } else {
      this.DeliveryFunction(item.Id, item, this.scopeDetails.DeliveryFunctionSSId, $event);
    }
  }
  cancel(form) {
    var formname = 'scopeform'
    if (form != undefined) {
      form.resetForm();
      formname = form.$name;
    }
    this.LoadScopeTab();
  }

  DeliveryFunction(Id, item, selecteddeliveryfunction, $event) {
    if (!$event.target.checked) { // Descoping Delivery Function
      selecteddeliveryfunction.splice(selecteddeliveryfunction.indexOf(Id), 1);
      item.IsSelected = false;
      if ((this.scopeDetails.DeliveryFunctionSSId.some(s => enumDeliveryFunction.Sec.includes(s.toString()))) && (item.Name.toLowerCase() == "managed security services" && item.IsSelected == false))
        this.showMilestoneWarningForSecurity(item, $event);
    }
    else { // Scoping Delivery Function
      selecteddeliveryfunction.push(Id);
      item.IsSelected = true;
      if ((SecurityDeliveryFunctionConstants.SecurityDeliveryFunctions.some(s => s.trim().toLowerCase() == item.Name.trim().toLowerCase())))
        this.showMilestoneWarningForSecurity(item, $event);
    }
    this.scopeForm.form.markAsDirty();
  }

  DeliveryScope(Id, item) {
    if (item.IsSelected) {
      this.scopeDetails.DeliveryScopeId.splice(this.scopeDetails.DeliveryScopeId.indexOf(Id), 1);
      item.IsSelected = false;
    }
    else {
      this.scopeDetails.DeliveryScopeId.push(Id);
      item.IsSelected = true;
    }
    this.deliveryScopeValidation();
    this.scopeForm.form.markAsDirty();
  }

  deliveryScopeValidation() {
    this.updateDeliveryScopeId();
    var deliveryScopeDeliveryTypeList = []; // Delivery scope items of selected delivery type
    var selectedDeliveryScopeList = this.scopeDetails.DeliveryScopeId.sort(function (a, b) { return a - b }); // Sort and store all user selected delivery scope list 
    var splicedSelectedDeliveryScopeList = []; // To store delivery scope items of single delivery type

    for (var i = 0; i < this.scopeDetails.DeliveryTypes.length; i++) {
      deliveryScopeDeliveryTypeList = this.getDeliveryScopeDeliveryTypeIds(this.scopeDetails.DeliveryTypes[i]); // Get all delivery scope tied to selected delivery type
      deliveryScopeDeliveryTypeList = deliveryScopeDeliveryTypeList.sort(function (a, b) { return a - b }); // Sort all deliveryScopeDeliveryType list

      var splicedSelectedDeliveryScope = selectedDeliveryScopeList.filter(x => deliveryScopeDeliveryTypeList.includes(x)); // Get selected delivery scope tied selected delivery type
      splicedSelectedDeliveryScopeList.push(splicedSelectedDeliveryScope);

      var result = splicedSelectedDeliveryScope.every(val => deliveryScopeDeliveryTypeList.includes(val)); // Check if seletced delivery scope items are tied to selected delivery types
      if (result) {
        this.showDeliveryScopeValidation = false;
      } else {
        this.showDeliveryScopeValidation = true;
      }
      deliveryScopeDeliveryTypeList = []; // Empty for next delivery type check
    }

    splicedSelectedDeliveryScopeList = [].concat.apply([], splicedSelectedDeliveryScopeList); // Merge all spliced selected delivery scope arrays

    splicedSelectedDeliveryScopeList = splicedSelectedDeliveryScopeList.filter((c, index) => { // Remove duplicates from splicedSelectedDeliveryScopeList list
      return splicedSelectedDeliveryScopeList.indexOf(c) === index;
    });

    this.showDeliveryScopeValidation = splicedSelectedDeliveryScopeList.length != selectedDeliveryScopeList.length ? true : false; // Check if number of selected delivery scope items does not match with expected length
  }

  getDeliveryScopeDeliveryTypeIds(id) { // Return all delivery scopes tied to delivery type
    var deliveryTypeDeliveryScopeIds = Enumerable.from(this.scopeDetails.DeliveryTypeDeliveryScope).select((s: any) => s.ParentId).toArray();
    var deliveryScopeDeliveryTypeList = [];
    for (var i = 0; i < deliveryTypeDeliveryScopeIds.length; i++) {
      for (var j = 0; j < 1; j++) {
        if (deliveryTypeDeliveryScopeIds[i] == this.scopeDetails.DeliveryType[id - 1].Id) {
          deliveryScopeDeliveryTypeList.push(this.scopeDetails.DeliveryTypeDeliveryScope[i].Id);
        }
      }
    }
    return deliveryScopeDeliveryTypeList;
  }

  downloadTemplate(fileName, elementId, type) {
    userNotification.showUserNotificationModalPopup();
    const btnClose = document.querySelector("#IUnderstandAction");
    if (btnClose) {
      btnClose.addEventListener('click', () => {
        this.demographicsService.downloadTemplate(fileName, elementId, type);
        switch (elementId) {
          case 'deliveryScopeDetails':
            this.applicationLoggingService.applicationUsageLogging(enumModuleAction.Template, enumTile.InitiateProfile, enumFeatureAccess.DeliveryScopeDetails).subscribe();
            break;
          case 'classAssetsDetails':
            this.applicationLoggingService.applicationUsageLogging(enumModuleAction.Template, enumTile.InitiateProfile, enumFeatureAccess.ClassAssetsDetails).subscribe();
            break;
        }
      });
    }
  }

  //LoadMandatoryDataAttrSet() {
  //  if (!this.CreatePage) {
  //    this.demographicsService.getInitiateProfile(this.sessionStorage.endToEndId).subscribe((response: any) => {
  //      if (response && response.DeliveryProfileMandatoryAttributeSet == 0) {
  //        this.IsDeliveryMandatoryDataSet = true;
  //        this.ActiveTab = this.sessionStorage.profileTab = enumProfileTab.Profile;
  //      }
  //      else {
  //        this.IsDeliveryMandatoryDataSet = false;
  //        if ((this.SubmissionType == 2 || this.SubmissionType == 3) && this.sessionStorage.profileTab == enumProfileTab.Profile) {
  //          this.ActiveTab = this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
  //        }
  //      }

  //    });
  //  }
  //}

  GetUniqueItems(array, property) {
    var unique = {};
    var distinct = [];
    for (var i in array) {
      if (typeof (unique[array[i][property]]) == "undefined") {
        distinct.push(array[i]);
      }
      unique[array[i][property]] = 0;
    }
    return distinct;
  }

  ToggleScope(event) {
    if (event.keyCode == 13) {
      this.scopeDetails.CapacityServices = this.scopeDetails.CapacityServices ? false : true;
    }
  }
  ToggleScopePhoenix(event) {
    if (event.keyCode == 13) {
      this.scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled = this.scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled ? false : true;
    }
  }


  updateDeliveryFunctionForSIMetrics() {
    let deliveryFunctionLists = [this.scopeDetails.TempDeliveryFunctionPPSMList, this.scopeDetails.TempDeliveryFunctionDTMList];
    if (this.scopeDetails.DeliveryTypes.length == 1 && this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AD) > -1) {
      enumDeliveryFunction.SIADTestingAMInfraSecCSEnable.split(",").forEach(DfId => {
        this.enableDeliveryFunction(DfId, deliveryFunctionLists);
      });
      enumDeliveryFunction.SIADDisable.split(",").forEach(DfId => {
        this.disableDeliveryFunction(DfId, deliveryFunctionLists);
      });
    }
    if (this.scopeDetails.DeliveryTypes.length == 2 &&
      this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AD) > -1 &&
      (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Testing) > -1 ||
        this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AM) > -1 ||
        this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.InfraStructure) > -1 ||
        this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Sec) > -1 ||
        this.scopeDetails.CapacityServices)) {
      enumDeliveryFunction.SIADTestingAMInfraSecCSEnable.split(",").forEach(DfId => {
        this.enableDeliveryFunction(DfId, deliveryFunctionLists);
      });
      enumDeliveryFunction.SIADDisable.split(",").forEach(DfId => {
        this.disableDeliveryFunction(DfId, deliveryFunctionLists);
      });
    }
    if (this.scopeDetails.DeliveryTypes.length == 2 &&
      this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AD) > -1 &&
      (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Agile) > -1)) {
      enumDeliveryFunction.SIADAgileTestingEnable.split(",").forEach(DfId => {
        this.enableDeliveryFunction(DfId, deliveryFunctionLists);
      });
      enumDeliveryFunction.SIADDisable.split(",").forEach(DfId => {
        this.disableDeliveryFunction(DfId, deliveryFunctionLists);
      });
    }
    if (this.scopeDetails.DeliveryTypes.length == 3 &&
      this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AD) > -1 &&
      this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Agile) > -1 &&
      this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Testing) > -1) {
      enumDeliveryFunction.SIADAgileTestingEnable.split(",").forEach(DfId => {
        this.enableDeliveryFunction(DfId, deliveryFunctionLists);
      });
      enumDeliveryFunction.SIADDisable.split(",").forEach(DfId => {
        this.disableDeliveryFunction(DfId, deliveryFunctionLists);
      });
    }
    if (this.scopeDetails.DeliveryTypes.length == 2 &&
      this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AD) > -1 &&
      this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Ops) > -1) {
      enumDeliveryFunction.SIADOpsEnable.split(",").forEach(DfId => {
        this.enableDeliveryFunction(DfId, deliveryFunctionLists);
      });
      enumDeliveryFunction.SIADDisable.split(",").forEach(DfId => {
        this.disableDeliveryFunction(DfId, deliveryFunctionLists);
      });
    } if (this.scopeDetails.DeliveryTypes.length == 1 && this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Agile) > -1) {
      enumDeliveryFunction.SIAgileAMInfraSecCSEnable.split(",").forEach(DfId => {
        this.enableDeliveryFunction(DfId, deliveryFunctionLists);
      });
      enumDeliveryFunction.SIAgileDisable.split(",").forEach(DfId => {
        this.disableDeliveryFunction(DfId, deliveryFunctionLists);
      });
    }
    if (this.scopeDetails.DeliveryTypes.length == 2 &&
      this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Agile) > -1 &&
      (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.AM) > -1 ||
        this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.InfraStructure) > -1 ||
        this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Ops) > -1 ||
        this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Sec) > -1 ||
        this.scopeDetails.CapacityServices)) {
      enumDeliveryFunction.SIAgileAMInfraSecCSEnable.split(",").forEach(DfId => {
        this.enableDeliveryFunction(DfId, deliveryFunctionLists);
      });
      enumDeliveryFunction.SIAgileDisable.split(",").forEach(DfId => {
        this.disableDeliveryFunction(DfId, deliveryFunctionLists);
      });
    }
    if (this.scopeDetails.DeliveryTypes.length == 2 &&
      this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Agile) > -1 &&
      (this.scopeDetails.DeliveryTypes.indexOf(enumDeliveryType.Testing) > -1)) {
      enumDeliveryFunction.SIAgileTestingEnable.split(",").forEach(DfId => {
        this.enableDeliveryFunction(DfId, deliveryFunctionLists);
      });
      enumDeliveryFunction.SIAgileDisable.split(",").forEach(DfId => {
        this.disableDeliveryFunction(DfId, deliveryFunctionLists);
      });
    }
  }

  enableDeliveryFunction(deliveryFunctionId, deliveryFunctionLists) {
    deliveryFunctionLists.forEach(deliveryFunctionList => {
      let index = deliveryFunctionList.findIndex(f => f.Id == deliveryFunctionId);
      if (index > -1)
        deliveryFunctionList[index].IsDisabled = false;
    })
  }

  disableDeliveryFunction(deliveryFunctionId, deliveryFunctionLists) {
    deliveryFunctionLists.forEach(deliveryFunctionList => {
      let index = deliveryFunctionList.findIndex(f => f.Id == deliveryFunctionId);
      if (index > -1) {
        deliveryFunctionList[index].IsDisabled = true;
        deliveryFunctionList[index].IsSelected = true;
        switch (deliveryFunctionList[index].ParentId) {
          case 1:
            if (this.scopeDetails.DeliveryFunctionPPSMId.indexOf(deliveryFunctionList[index].Id) < 0)
              this.scopeDetails.DeliveryFunctionPPSMId.push(deliveryFunctionList[index].Id);
            break;
          case 2:
            if (this.scopeDetails.DeliveryFunctionDTMId.indexOf(deliveryFunctionList[index].Id) < 0)
              this.scopeDetails.DeliveryFunctionDTMId.push(deliveryFunctionList[index].Id);
            break;
        }
      }
    })
  }

  enableScopeOfWork() {
    if (this.scopeDetails.PlatformMappingInputs != null && this.scopeDetails.PlatformMappingInputs != undefined) {
      if (this.scopeDetails.PlatformMappingInputs.indexOf(enumPlatformScopeOfWork.CMMScopeOfWork.toString()) > -1)
        this.isCMMScopeOfWork = true;
      if (this.scopeDetails.PlatformMappingInputs.indexOf(enumPlatformScopeOfWork.CDMScopeOfWork.toString()) > -1)
        this.isCDMScopeOfWork = true;
    }
  }

  showScopeOfWork(platform: any) {
    if (platform == "cmm") {
      var CMMScopeOfWorksList = this.scopeDetails.CMMScopeOfWorks;
      var selectedCMMScopeOfWork = this.scopeDetails.CMMScopeOfWorkInputs;
      var CMMScopeOfWorkgriddata = Enumerable.from(CMMScopeOfWorksList).join(
        selectedCMMScopeOfWork, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();

      //const initialState = { title: "Cloud Delivery - Migration Scope Of Work", label: "Scope Of Work", gridData: CMMScopeOfWorkgriddata, masterData: this.scopeDetails.CMMScopeOfWorks };
      //this.modalRefManageDemographicPopup = this.modalService.show(ManageDemographicsComponent, {
      //  initialState,
      //  backdrop: "static",
      //  keyboard: false
      //});
      $(".modal-dialog").addClass('custom-modal');

      this.modalRefManageDemographicPopup.content.manageDemographicOutput.subscribe(result => {
        this.scopeDetails.CMMScopeOfWork = result.gridData.map(x => x.Name).join();
        this.scopeDetails.CMMScopeOfWorkInputs = result.gridData.map(({ Id }) => Id);
        this.scopeForm.form.markAsDirty();
      });
    } else if (platform == "cdm") {
      var CDMScopeOfWorksList = this.scopeDetails.CDMScopeOfWorks;
      var selectedCDMScopeOfWork = this.scopeDetails.CDMScopeOfWorkInputs;
      var CDMScopeOfWorkgriddata = Enumerable.from(CDMScopeOfWorksList).join(
        selectedCDMScopeOfWork, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();

      const initialState = { title: "Cloud Data Migration Scope Of Work", label: "Scope Of Work", gridData: CDMScopeOfWorkgriddata, masterData: this.scopeDetails.CDMScopeOfWorks };
      //this.modalRefManageDemographicPopup = this.modalService.show(ManageDemographicsComponent, {
      //  initialState,
      //  backdrop: "static",
      //  keyboard: false
      //});
      $(".modal-dialog").addClass('custom-modal');

      this.modalRefManageDemographicPopup.content.manageDemographicOutput.subscribe(result => {
        this.scopeDetails.CDMScopeOfWork = result.gridData.map(x => x.Name).join();
        this.scopeDetails.CDMScopeOfWorkInputs = result.gridData.map(({ Id }) => Id);
        this.scopeForm.form.markAsDirty();
      });
    }
  }

  //fixDeliveryFunctionAlignment() { // Temporary Multi line label alignment fix for Service Management Delivery Function column
  //  const labels = Array.from(document.getElementsByClassName('col-md-3')[2].getElementsByClassName('switch-text') as HTMLCollectionOf < HTMLElement > ) // Get all labels under Service Management Delivery Function column
  //  labels.forEach((element) => {
  //      if (window.screen.width <= 1280) { // For screen resolution < 1280 px
  //          if (element.innerHTML.length > 29) {
  //            element.parentElement.parentElement.parentElement.style.paddingRight = "20px";
  //            element.parentElement.style.display = "block";
  //            element.style.paddingTop = "0px";
  //            element.style.bottom = "8px";
  //          }
  //      } else if (window.screen.width > 1280 && window.screen.width < 1600) { // For screen resolution > 1280 px and < 1600 px
  //          if (element.innerHTML.length > 44) {
  //            element.parentElement.parentElement.parentElement.style.paddingRight = "20px";
  //            element.parentElement.style.display = "block";
  //            element.style.paddingTop = "0px";
  //            element.style.bottom = "8px";
  //          }
  //      }
  //  })
  //}

  hideDeliveryScopeForSecurity() { // Hide Delivery Scope section only when Security DT alone is selected  
    this.HideDeliveryScope = true ? (this.scopeDetails.DeliveryTypes.length === 1 && this.scopeDetails.DeliveryType.some(s => s.Name.trim().toLowerCase() == "security service" && s.IsSelected == true) && !this.scopeDetails.CapacityServices) : false;
  }

  showMilestoneWarningForSecurity(deliveryFunctionItem, $event) { // Milestone track note warning for when Security DF's are selected
    this.confirmationMessage = 'If the scope of delivery involves Transformation Services, Strategy Services, or Industry Services, it is recommended to track milestones. Please click on ‘Ok’ to continue with the changes or ‘Cancel’ to revisit the current selection.';
    this.action = "security";
    this.workstreamConfirmationPopUp(this.confirmationMessage, null, deliveryFunctionItem, $event);
    $('.close').focus();
  }

  deliveryTypeMandate() { // Delivery type mandatory when RBE is Tech/Operations/Security
    this.DeliveryTypeMandatory = true ? (this.scopeDetails.IsRBETech || this.scopeDetails.RBEName.trim().toLowerCase() === 'operations' || this.scopeDetails.RBEName.trim().toLowerCase() === 'security') : false;
  }

  hideDeliveryScopeAlert() { // Hide Delivery Scope warning when either alone or both Operations/Security DT is selected
    const selectedDeliveryType = this.scopeDetails.DeliveryType.filter(f => f.IsSelected == true);
    if ((this.scopeDetails.DeliveryTypes.length <= 2) && (selectedDeliveryType.every(e => (e.Name.trim().toLowerCase() == "operations" && e.IsSelected == true) || (e.Name.trim().toLowerCase() == "security service" && e.IsSelected == true)))) {
      return true;
    } else {
      return false;
    }
  }
  trackById(index: number, item: any): any {
    return item.Id;
  }
  trackByMethodologyTypeId(index: number, item: any): any {
    return item.MethodologyTypeId;
  }

  toggleDeliveryFunction() {
    if (this.isShowValuefn) {
      this.isShowValuefn = false;
    }
    else {
      this.isShowValuefn = true;
    }
  }

  showDeliveryFunctionDescription() {
    const initialState = { scrollYPosition: window.scrollY };
    this.modalService.show(DeliveryFunctionsDescriptionComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
  }
  currentSustainabilityAssessment() {
    var url = environment.currentSustainabilityDAFUrl;
    this.currentSustainabilityAssessmentUrl = url + "/" + this.sessionStorage.endToEndUId + "/Assessment";
    window.open(this.currentSustainabilityAssessmentUrl, "_BLANK");
  }
  getCurrentSustainabilityLevel() {
    this.demographicsService.getCurrentSustainabilityLevelName(this.sessionStorage.endToEndUId).subscribe((response: any) => {
      this.scopeDetails.CurrentSustainabilityLevelName = response;
    });
  }

}
