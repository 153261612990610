import { Component, ElementRef,Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import cloneDeep from 'lodash/cloneDeep';
import { enumMetricDeliveryLevel, enumProfileTab } from '../../core/context/enum';
import { SessionStorage } from '../../core/context/sessionstorage';
import { ConfigureMetricService } from '../../core/services/configure.metrics.services';
import { MetricFilterComponent } from './metrics.filter.component';
import { MetricEditThresholdComponent } from '../configure.metrics.component/metric.edit.threshold.component';
import { SharedService } from '../../core/services/shared.service';
import { RIMSummaryService } from '../../core/services/rim.summary.service';
import { InitiateProfileComponent } from '../initiate.profile.component/initiate.profile.component';
import { InitiateProfileSharedService } from '../initiate.profile.component/initiate.profile.shared.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
declare var window: any;
var $ = (<any>window).$;


@Component({
  selector: 'metric-threshold',
  templateUrl: './metric.threshold.component.html',
  providers: [ConfigureMetricService, BsModalService]
})
export class MetricThresholdComponent{
  trendDirectionOptions: any[];
  accessFlag: boolean;
  errorMessage: any;
  deliveryLevelId: number;
  deliveryId: any;
  thresholdData: any;
  thresholdAllData: any;
  tempThresholdData: any;
  filterThresholdData: any;
  sortBy: any = 'Metric';
  sortBy1: any = 'Name';

  /*Paging*/
  curPage: number = 0;
  curPageAFSM: number = 0;
  pageSize: number = 10;
  totalMetrics: number;
  noOfPages: number;
  startIndex: number = 0;
  endIndex: number;
  filterMetricData: any = [];

  /* Filters */
  metricDropDown: any = [];
  deliverFunctionDropDown: any = [];
  reportAgainstDropdown: any = [];
  businessContextDropdown: any = [];
  inScopeDropdown: any = [];
  applicabilityDropdown: any = [];

  selectedMetricFilterId: number = 0;
  selectedDFFilterId: number = 0;
  selectedReportAgainstFilterId: number = 0;
  selectedbusinessContextFilterId: number = 0;
  selectedIsScopeFilterId: number = 0;
  selectedApplicabilityId: number = 0;

  //Edit Threshold Properties
  allSelectedArray: any = [];
  allSelectedArrayClone: any = [];
  mirflag: boolean;

  isTimeSpanUint: boolean = false;
  isTimeSpanError: boolean = false;
  timeSpanErrorMsg: any;
  timeSpanNote: any;

  IsTrackByPhase: boolean;
  IsTrackByPhaseClone: boolean;//defect 1065410 to use in on calcel button on popup
  IsTrackByPhasePanel: boolean = false;
  IsTrackByPhaseDisabled: boolean = false;
  GridCategory: any;
  isGridDisabled: boolean = false;
  TempAllSelectedUpwardMetrics: Array<any> = new Array<any>();
  TempAllSelectedDownwardMetrics: Array<any> = new Array<any>();
  TempAllSelectedBothdirectionMetrics: Array<any> = new Array<any>();
  changeTrigger = 1;
  isError: any = false;
  btnRestore: any = "true";

  isReverse: boolean = false;
  isReverse1: boolean = false;
  isReverse2: boolean = false;
  isDisabled: boolean = false;
  isRangeTrend: number = 0;
  isWarning: boolean = false;
  rowUp = true;
  rowDown = true;
  rowBidirectional = true;
  isUpdated: any = false;
  loadingStatus = true;
  showModal: any = false;

  hideModal: boolean = false;
  //For RefineBy hide/show
  refineByStatus: boolean = true;
  elemRef: ElementRef;


  deletedIndex: any;
  tvalUp1: any;
  tvalUp2: any;
  tvalDwn1: any;
  tvalDwn2: any;
  tvalBi1: any;
  tvalBi2: any;
  tvalBi3: any;
  tvalBi4: any;
  tvalup1Error: boolean = false;
  tvalup2Error: boolean = false;
  tvaldwn1Error: boolean = false;
  tvaldwn2Error: boolean = false;
  tvalbi1Error: boolean = false;
  tvalbi2Error: boolean = false;
  tvalbi3Error: boolean = false;
  tvalbi4Error: boolean = false;
  IsStandMetricNull: boolean = false;
  selectedTrend: any;
  selectedTrendId: any;
  isDefault: any;
  ToolTipMsg: any = "Please select any metrics";
  Error: any;
  TTid: any;

  defaultData: Array<any> = new Array<any>();
  thresholdSaveData: Array<any> = new Array<any>();
  missedThresolds: Array<any> = new Array<any>();
  thresholdScreenPopupModal: boolean = false;
  EnterpriseId: any;
  lastUpdated: any = '';
  tempDeliveryLevelId: any;
  tempDeliveryStructId: any;
  tempEnterpriseId: any = "temp.user";
  TeamName: string;
  navIsFixed: boolean = false;
  tempIsRangeTrend: number = 0;
  tempTrend: number;
  tempVal1: any;
  tempVal2: any;
  tempVal3: any;
  tempVal4: any;
  //Flag to disable Customize button when MIR is selected- Story 1051466: Global thresholds to be updated for Number of Major Incidents core metric
  mir: boolean = false;
  showAlertPopup: boolean;

  sortType: string = 'asc';

  modalRef: BsModalRef;
  customizePopup: BsModalRef;
  isPhaseSelected: boolean = false;
  @Output() InitiateProfileTabSetup = new EventEmitter();
  keyContactTabHighlight: boolean;
  ProfileTabHighlight: boolean;
  onSave: boolean=false;
  e2eflag: string;
  Roleaccesscode: any;
  constructor(private metricService: ConfigureMetricService,
    private sessionStorage: SessionStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private sharedService: SharedService,
    private initiateProfileSharedService: InitiateProfileSharedService,
    private initiateProfileComponent: InitiateProfileComponent,
private rIMSummaryService: RIMSummaryService
  ) {

  }
  ngOnInit() {

    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['MyWizard/Back/Profile'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId, profileTabCode: 'MetricThreshold' } });
      return false;
    }

    //this.initiateProfileSharedService.ValidateDemographicTabHighlight(this);
    //this.initiateProfileSharedService.errorTabHighlight.subscribe(result => {
    //  this.InitiateProfileTabSetup.emit({ keyContactTabHighlight: this.keyContactTabHighlight, ProfileTabHighlight: this.ProfileTabHighlight, tabId: enumProfileTab.Thresholds });
    //});

    setTimeout(() => {
      // window.flyoutComponent();
      window.modalComponent();
    }, 500)
    
    setTimeout(function () {
      window.TooltipFunc();
    }, 0);
    this.Roleaccesscode = this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);
    this.accessFlag = this.Roleaccesscode == 'E' ? false : true;
    if (this.sessionStorage.teamUId != null && this.sessionStorage.teamUId != '') {
      this.deliveryLevelId = enumMetricDeliveryLevel.Team;
      this.deliveryId = this.sessionStorage.teamUId
    }
    else {
      this.deliveryLevelId = enumMetricDeliveryLevel.E2E;
      this.deliveryId = this.sessionStorage.endToEndUId
    }
    this.getTrendDirection();
    this.getThresholdConfigData();
    this.isTimeSpanUint = false;
    this.isTimeSpanError = false;
    this.timeSpanErrorMsg = "";
    this.timeSpanNote = 0;

    this.rIMSummaryService.broadcastToGirdView$.subscribe((data) => {
      var res = data;
      console.log("inside threshold Constructor");

      this.allSelectedArray = [];
      this.logicForButton(this.allSelectedArray);
      this.thresholdData.forEach(item => item.chkValue = false);
      this.filterThresholdData.forEach(item => item.chkValue = false);
      this.thresholdAllData.forEach(item => item.chkValue = false);
      this.getTrendDirection();
      this.getThresholdConfigData();
      this.isTimeSpanUint = false;
      this.isTimeSpanError = false;
      this.timeSpanErrorMsg = "";
      this.timeSpanNote = 0;
      if (res == "Done") {
        this.onSave = true;
      }
      
    });
  }
  //Trend direction dropdown master data call(Pop-Up)
  getTrendDirection(): void {
    this.metricService.getTrendDirection(this.deliveryId).subscribe(
      contexts => {
        this.trendDirectionOptions = contexts.genericDropDownModels;
        this.e2eflag = contexts.e2eflag;
        setTimeout(function () {
          window.TooltipFunc();
        }, 0);
      },      
      error => { this.errorMessage = <any>error; console.log(error); });  
  }


  //To populate threshold grid details
  getThresholdConfigData() {
    this.selectedMetricFilterId = 0;
    this.selectedDFFilterId = 0;
    this.selectedReportAgainstFilterId = 0;
    this.selectedbusinessContextFilterId = 0;
    //this.selectedIsScopeFilterId = result.selectedIsScopeFilterId,
    this.selectedApplicabilityId = 0;
    this.metricService.getThresholdConfig(this.deliveryId, this.deliveryLevelId, this.sessionStorage.enterpriseId).subscribe(
      data => {
        this.thresholdAllData = data;
        setTimeout(function () {
          window.TooltipFunc();
        }, 0);
        //this.thresholdAllData = data.MetricThresholds;

        /*filter dropdown initialization*/
        this.loadFilterDropdownOptions(data);
       

        this.tempThresholdData = JSON.parse(JSON.stringify(this.thresholdAllData));
        this.filterThresholdData = JSON.parse(JSON.stringify(this.thresholdAllData));
       

        //this.thresholdAllData = this.thresholdAllData.sort((a, b) => {
        //  if (a.Metric.Name < b.Metric.Name) {
        //    return -1;
        //  }
        //});
        if (this.sortType == 'asc') {
          this.thresholdAllData = this.thresholdAllData.sort((a, b) => {
            if ((a[this.sortBy][this.sortBy1]).toLowerCase() < (b[this.sortBy][this.sortBy1]).toLowerCase()) {
              return -1;
            }
          });
          this.tempThresholdData = this.tempThresholdData.sort((a, b) => {
            if ((a[this.sortBy][this.sortBy1]).toLowerCase() < (b[this.sortBy][this.sortBy1]).toLowerCase()) {
              return -1;
            }
          });
          this.filterThresholdData = this.filterThresholdData.sort((a, b) => {
            if ((a[this.sortBy][this.sortBy1]).toLowerCase() < (b[this.sortBy][this.sortBy1]).toLowerCase()) {
              return -1;
            }
          });

        }
        else {
          this.thresholdAllData = this.thresholdAllData.sort((a, b) => {
            if ((a[this.sortBy][this.sortBy1]).toLowerCase() > (b[this.sortBy][this.sortBy1]).toLowerCase()) {
              return -1;
            }
          });
          this.tempThresholdData = this.tempThresholdData.sort((a, b) => {
            if ((a[this.sortBy][this.sortBy1]).toLowerCase() > (b[this.sortBy][this.sortBy1]).toLowerCase()) {
              return -1;
            }
          });
          this.filterThresholdData = this.filterThresholdData.sort((a, b) => {
            if ((a[this.sortBy][this.sortBy1]).toLowerCase() > (b[this.sortBy][this.sortBy1]).toLowerCase()) {
              return -1;
            }
          });
        }
        /*Paging*/
        this.totalMetrics = this.thresholdAllData.length;
        if (this.endIndex == undefined) { this.endIndex= this.pageSize; }
        this.thresholdData = this.thresholdAllData.slice(this.startIndex, this.endIndex);
        this.noOfPages = this.numberOfPages();

        //this.missingThresolds(data.MetricThresholds);
        if (!this.onSave) { this.missingThresolds(data); }
       
      }
      
    );
  }
  OnBackClick() {
    this.initiateProfileComponent.OnBackClick();
  }
  loadFilterDropdownOptions(metrics: any) {
    this.metricDropDown = metrics.reduce((acc, cur) => {
      if (acc.filter(x => x.Id == cur.MetricId).length == 0) {
        acc.push({ Id: cur.MetricId, Name: cur.Metric.Name, isActive: true })
      }
      return acc;
    }, []).sort((a, b) => {
      if (a.Name < b.Name)
        return -1;
    });

    this.deliverFunctionDropDown = metrics.reduce((acc, cur) => {
      if ((cur.BusiWSMappng.length > 0)) {
        cur.BusiWSMappng.forEach((item) => {
          let bpId = item.BusinnesProcessId == 0 ? -1 : item.BusinnesProcessId;
          if (acc.filter(x => x.Id === bpId).length == 0) {
            acc.push({ Id: bpId, Name: item.BusinnesProcessName, isActive: true })
          }
        })

      }
      return acc;
    }, []).sort((a, b) => {
      if (a.Name < b.Name)
        return -1;
    });

    this.reportAgainstDropdown = metrics.reduce((acc, cur) => {
      if (cur.BusiWSMappng.length > 0) {
        cur.BusiWSMappng.forEach((item) => {
          let wsId = item.WorkstreamTypeId == 0 ? -1 : item.WorkstreamTypeId;
          if (acc.filter(x => x.Id === wsId).length == 0) {
            acc.push({ Id: wsId, Name: item.WorkstreamTypeName, isActive: true });

          }
        })
      }
      return acc;

    }, []).sort((a, b) => {
      if (a.Name < b.Name)
        return -1;
    });
    this.ReportedagainstSuffixAdd(this.reportAgainstDropdown);

    this.businessContextDropdown = metrics.filter(x => x.BusinessContextId != null && x.BusinessContextId != 0).reduce((acc, cur) => {
      if (cur.BusinessContextId != null && cur.BusinessContextId != undefined) {
        if (acc.filter(x => x.Id == cur.BusinessContextId).length == 0) {
          acc.push({ Id: cur.BusinessContextId == 0 ? -1 : cur.BusinessContextId, Name: cur.BusinessContextName, isActive: true })
        }
      }
      return acc;
    }, []).sort((a, b) => {
      if (a.Name < b.Name)
        return -1;
    });
    this.applicabilityDropdown = metrics.reduce((acc, cur) => {
      if (cur.ApplicabilityId != null && cur.ApplicabilityId != undefined) {
        if (acc.filter(x => x.Id == cur.ApplicabilityId).length == 0) {
          acc.push({ Id: cur.ApplicabilityId == 0 ? -1 : cur.ApplicabilityId, Name: cur.Applicability, isActive: true })
        }
      }
      return acc;
    }, []).sort((a, b) => {
      if (a.Name < b.Name)
        return -1;
    });

  }

  //FUnction to add suffix to reported against in filter
  ReportedagainstSuffixAdd(reprotedagainst: any)
  {
    reprotedagainst.forEach((item) => {
      if (item.Id == 1 || item.Id == 3 || item.Id == 5 || item.Id == 6 || item.Id == 11) {
        item.Name = item.Name + "(s)";
      }
      if (item.Id == 2) {
        item.Name = "Application(s) / Application Group(s)";
      }
      if (item.Id == 4) {
        item.Name = "Agile Sprint(s) 0";
      }
    })
  }
  missingThresolds(metrics: any[]): void {
    this.missedThresolds = new Array<any>();
    for (var i = 0; i < metrics.length; i++) {
      if ((metrics[i].Applicability == "Mandatory") && ((metrics[i].Threshold1 == "" || metrics[i].Threshold1 == null) && (metrics[i].Threshold2 == "" || metrics[i].Threshold2 == null)) && (metrics[i].WorkstreamTypeId != "12")) {//User Story 1734898: As a MSE User, I should be notified of missing thresholds for core metrics in Configure metrics (Thresholds screen)
        this.missedThresolds.push(metrics[i]);
      }
    }
    this.showAlertPopup = this.missedThresolds.length > 0 ? true : false;
   }

  numberOfPages() {
    if (this.filterThresholdData != undefined && this.filterThresholdData != null) {
      let pageCnt = Math.ceil(this.filterThresholdData.length / this.pageSize);
      return pageCnt == 0 ? 1 : pageCnt;
    }
    else
      return 0;
  }

  pagination(pageNo: number) {
    this.startIndex = (pageNo) * this.pageSize;
    this.endIndex = (pageNo) * this.pageSize + this.pageSize;
    this.thresholdData = this.filterThresholdData.slice(this.startIndex, this.endIndex);
    setTimeout(function () {
      window.TooltipFunc();
    }, 0);
  }

  onChange(event: any, selectedThreshold: any, index: any) {
   
    var isChecked = event.currentTarget.checked;
    let indextoUpdate = this.filterThresholdData.findIndex(x => x.MetricId == selectedThreshold.MetricId);
    let indexThreshold = this.thresholdAllData.findIndex(x => x.MetricId == selectedThreshold.MetricId);
    if (isChecked == true) {
      if (this.allSelectedArray.indexOf(selectedThreshold) == -1) {
        this.allSelectedArray.push(selectedThreshold);
        this.allSelectedArrayClone.push(selectedThreshold);//defect 1065410
      }
      this.filterThresholdData[indextoUpdate].chkValue = true;
      this.thresholdAllData[indexThreshold].chkValue = true
      this.logicForButton(selectedThreshold);
      //Story 1051466: Global thresholds to be updated for Number of Major Incidents core metric
      this.mirflag = false;
      if (selectedThreshold.Metric.Name == 'Number of Major Incidents Reported') {
        this.mirflag = true;
      }
    }
    else {
      this.deletedIndex = this.allSelectedArray.indexOf(selectedThreshold);
      this.allSelectedArray.splice(this.deletedIndex, 1)

      this.allSelectedArrayClone.splice(this.deletedIndex, 1)   // Added for bug fix 1084759
      this.filterThresholdData[indextoUpdate].chkValue = false;
      this.thresholdAllData[indexThreshold].chkValue = false;
      this.logicForButton(selectedThreshold);
    }
  }

  //Customize button disable logic
  logicForButton(_selectedThreshold: any) {

    var distinctThresholdType = {};
    var distinctTrackbyPhases = {};
    var distinctMetrics = {};
    var distinctDGPhases = {};
    //===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    var distinctUnitId = {};//used to get distinct metric unit id to show timespan placeholder
    this.isTimeSpanUint = false;
    this.isTimeSpanError = false;
    this.timeSpanErrorMsg = "";
    var distinctTrend = {};//used to get distinct tendtype id to show note for timespan metrics
    //==============================end=================
    for (var i = 0; i < this.allSelectedArray.length; i++) {
      // for distinct threshold type
      var value = this.allSelectedArray[i].ThresholdType.Name;
      var metricUnit = this.allSelectedArray[i].Metric.Unitid;// assigned metric id===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
      // for distinct Defect grid threshold
      var DGPhase = this.allSelectedArray[i].DGPhasesThreshold;
      // for distinct trackbyphases on/off
      var trackbyphases = this.allSelectedArray[i].IsTrackbyPhases;
      var trendId = this.allSelectedArray[i].TrendTypeid;// assigned trendtype id===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
      var metricNames = this.allSelectedArray[i].Metric.Name;

      distinctThresholdType[value] = '';
      distinctTrackbyPhases[trackbyphases] = '';
      distinctDGPhases[DGPhase] = '';
      distinctUnitId[metricUnit] = '';// assigned all metric id in collection===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
      distinctTrend[trendId] = '';// assigned all trendtype id in collection===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
      distinctMetrics[metricNames] = '';

    };
    var distincType = Object.keys(distinctThresholdType);
    var distinctDGThreshold = Object.keys(distinctDGPhases);
    var distinctPhase = Object.keys(distinctTrackbyPhases);
    var distinctMetriUnit = Object.keys(distinctUnitId);//getting keys of array to do further enumerable operations===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    var distinctMetricNames = Object.keys(distinctMetrics);
    //Getting distinct trackbyphase either on or off
    var phase = Object.keys(distinctTrackbyPhases)[0];
    var distinctTrendId = Object.keys(distinctTrend);//getting keys of array to do further enumerable operations===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    var trendIdToDisplay = Object.keys(distinctTrend)[0];//getting value of first element===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    //check length of the array and set value to show note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    if (distinctTrendId.length == 1) {
      this.timeSpanNote = +trendIdToDisplay;//to convert string to number
    }
    else {
      this.timeSpanNote = 0;//set default value to hide note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    }
    //=======================end==============================
    if (distincType.length == 1) {

      if (distinctPhase.length == 1) {
        //If trackbyphase is off and DG and Non DG metrics are paired up then customize button is enabled
        if (phase.includes("false")) {
          this.isDisabled = true;
          this.ToolTipMsg = "";
        }
        //If trackbyphase is on and all Non DG metrics paired up then customize button is enabled
        else if (phase.includes("true") && distinctDGThreshold.length == 1 && distinctDGThreshold[0].includes("null")) {
          this.isDisabled = true;
          this.ToolTipMsg = "";
        }

        else if (phase.includes("true") && distinctDGThreshold.length > 0) {
          var isNonDG = distinctDGThreshold.find(x => x.includes("null"));
          //If trackbyphase is on and DG and Non DG metrics are paired up then customize button is disable
          if (isNonDG == "null") {
            this.isDisabled = false;
            this.ToolTipMsg = "Please do not select Defect Grid metrics with Non Defect Grid metrics.";
          }
          //If trackbyphase is on and DG metrics are paired up then customize button is enabled
          else {
            this.isDisabled = true;
            this.ToolTipMsg = "";
          }
        }
        //Checked length and the unitid is present or not in array ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
        if (distinctMetriUnit.length == 1) {
          if (distinctMetriUnit.indexOf('28') > -1) {//element is present then set the flag true else false
            this.isTimeSpanUint = true;
          }
          else {
            this.isTimeSpanUint = false;
          }
        }
        else {   //if more user selects timespan metric with non timespan metric 
          if (distinctMetriUnit.indexOf('28') > -1) {//element is present then set the flag false and return error message
            this.isDisabled = false;

            this.ToolTipMsg = "Please do not select Timespan(hh:mm:ss) metrics with non Timespan(hh:mm:ss) metrics.";
            this.isTimeSpanUint = false;
          }
          else {
            this.isDisabled = true;
            this.ToolTipMsg = "";
            this.isTimeSpanUint = false;
          }
        }
        //=============end============================================================
      }
    }
    else {
      this.isDisabled = false;

      this.ToolTipMsg = "Please select metrics with same type of thresholds.";
    }
    if (this.allSelectedArray.length == 0) {
      this.ToolTipMsg = "Please select any metrics.";
    }

    //To disable customize button - Story 1051466: Global thresholds to be updated for Number of Major Incidents core metric
    for (var m = 0; m < this.allSelectedArray.length; m++) {
      for (var n = 0; n < distinctMetricNames.length; n++) {
        this.mir = false;
        if (distinctMetricNames[n] == 'Number of Major Incidents Reported') {
          this.mir = true;
          break;
        }
      }
    }

    if (this.mir && distinctMetricNames.length != 1) {
      this.isDisabled = false;
      this.ToolTipMsg = "Number of Major Incidents Reported(MIR) cannot be customized with other metric(s).";
    }
  }

  //to show filter popup
  displayRefineBy(): void {
    const initialState = {
      configureMetrics: this.thresholdAllData,
      metricDropDown: this.metricDropDown,
      deliverFunctionDropDown: this.deliverFunctionDropDown,
      reportAgainstDropdown: this.reportAgainstDropdown,
      businessContextDropdown: this.businessContextDropdown,
      inScopeDropdown: this.inScopeDropdown,
      applicabilityDropdown: this.applicabilityDropdown,
      selectedMetricFilterId: this.selectedMetricFilterId,
      selectedDFFilterId: this.selectedDFFilterId,
      selectedReportAgainstFilterId: this.selectedReportAgainstFilterId,
      selectedbusinessContextFilterId: this.selectedbusinessContextFilterId,
      selectedIsScopeFilterId: this.selectedIsScopeFilterId,
      selectedApplicabilityId: this.selectedApplicabilityId,
      isThreshold: true
    };
    this.modalRef = this.modalService.show(MetricFilterComponent, { initialState, backdrop: "static", keyboard: false });
    this.modalRef.content.applyFilters.subscribe(result => {
      this.selectedMetricFilterId = result.selectedMetricFilterId,
        this.selectedDFFilterId = result.selectedDFFilterId,
        this.selectedReportAgainstFilterId = result.selectedReportAgainstFilterId,
        this.selectedbusinessContextFilterId = result.selectedbusinessContextFilterId,
        //this.selectedIsScopeFilterId = result.selectedIsScopeFilterId,
        this.selectedApplicabilityId = result.selectedApplicabilityId
      this.applyFilter();
    })
  }

  applyFilter() {  
    this.filterThresholdData = JSON.parse(JSON.stringify(this.thresholdAllData)); //to avoid two way binding
    let filterId = 0;
    if (this.selectedMetricFilterId != null && this.selectedMetricFilterId != 0) {
      filterId = this.selectedMetricFilterId == -1 ? 0 : this.selectedMetricFilterId;
      this.filterThresholdData = this.filterThresholdData.filter(x => x.MetricId == filterId).map(x => x);
    }
    if (this.selectedDFFilterId != null && this.selectedDFFilterId != 0) {
      filterId = this.selectedDFFilterId == -1 ? 0 : this.selectedDFFilterId;
      this.filterThresholdData = this.filterThresholdData.filter(x => x.BusinessProcessId == filterId).map(x => x);
    }
    if (this.selectedReportAgainstFilterId != null && this.selectedReportAgainstFilterId != 0) {
      filterId = this.selectedReportAgainstFilterId == -1 ? 0 : this.selectedReportAgainstFilterId;
      this.filterThresholdData = this.filterThresholdData.filter(x => x.WorkstreamTypeId == filterId).map(x => x);
    }
    if (this.selectedbusinessContextFilterId != null && this.selectedbusinessContextFilterId != 0) {
      filterId = this.selectedbusinessContextFilterId == -1 ? 0 : this.selectedbusinessContextFilterId;
      this.filterThresholdData = this.filterThresholdData.filter(x => x.BusinessContextId == filterId).map(x => x);
    }

    if (this.selectedApplicabilityId != null && this.selectedApplicabilityId != 0) {
      filterId = this.selectedApplicabilityId == -1 ? 0 : this.selectedApplicabilityId;
      this.filterThresholdData = this.filterThresholdData.filter(x => x.ApplicabilityId == filterId).map(x => x);
    }
    this.sortMetrics();
    this.thresholdData = JSON.parse(JSON.stringify(this.filterThresholdData));

    /*pagination */
    this.totalMetrics = this.filterThresholdData.length;
    this.noOfPages = this.numberOfPages();
    this.curPage = 0;
    this.pagination(0);
  }

  sortMetrics() {
    if (this.sortType == 'asc') {
      this.filterThresholdData = this.filterThresholdData.sort((a, b) => {
        if ((a[this.sortBy][this.sortBy1]).toLowerCase() < (b[this.sortBy][this.sortBy1]).toLowerCase()) {
          return -1;
        }
      });

    }
    else {
      this.filterThresholdData = this.filterThresholdData.sort((a, b) => {
        if ((a[this.sortBy][this.sortBy1]).toLowerCase() > (b[this.sortBy][this.sortBy1]).toLowerCase()) {
          return -1;
        }
      });
    }
  }

  changeSorting() {
    this.sortType = this.sortType == 'asc' ? 'desc' : 'asc';
    this.applyFilter();
  }


  showCustomizePopup() {
    for (var i = 0; i < this.allSelectedArray.length; i++) {
      if (!this.allSelectedArray[i].chkValue) {
        this.allSelectedArray.splice(this.allSelectedArray.indexOf(this.allSelectedArray[i]), 1);
      }
    }
    this.lastUpdated = null;
    this.onCustomize();
    const initialState = {
      rowUp: this.rowUp,
      rowDown: this.rowDown,
      mirflag: this.mirflag,
      rowBidirectional: this.rowBidirectional,
      allSelectedArray: cloneDeep(this.allSelectedArray),
      isRangeTrend: this.isRangeTrend,
      trendDirectionOptions: cloneDeep(this.trendDirectionOptions),
      selectedTrend: this.selectedTrend,
      IsTrackByPhase: this.IsTrackByPhase,
      isTimeSpanUint: this.isTimeSpanUint,
      timeSpanNote: this.timeSpanNote,
      IsStandMetricNull: this.IsStandMetricNull,
      tvalup1Error: this.tvalup1Error,
      tvalup2Error: this.tvalup2Error,
      tvaldwn1Error: this.tvaldwn1Error,
      tvaldwn2Error: this.tvaldwn2Error,
      tvalbi1Error: this.tvalbi1Error,
      tvalbi2Error: this.tvalbi2Error,
      tvalbi3Error: this.tvalbi3Error,
      isTimeSpanError: this.isTimeSpanError,
      timeSpanErrorMsg: this.timeSpanErrorMsg,
      isError: this.isError,
      thresholdData: cloneDeep(this.thresholdData),
      thresholdSaveData: cloneDeep(this.thresholdSaveData),
      selectedTrendId: this.selectedTrendId,
      TempAllSelectedBothdirectionMetrics: cloneDeep(this.TempAllSelectedBothdirectionMetrics),
      TempAllSelectedUpwardMetrics: cloneDeep(this.TempAllSelectedUpwardMetrics),
      TempAllSelectedDownwardMetrics: cloneDeep(this.TempAllSelectedDownwardMetrics),
      isDisabled: this.isDisabled,
      ToolTipMsg: this.ToolTipMsg,
      allSelectedArrayClone: cloneDeep(this.allSelectedArrayClone),
      IsTrackByPhasePanel: this.IsTrackByPhasePanel,
      isGridDisabled: this.isGridDisabled,
      tvalUp1: this.tvalUp1,
      tvalUp2: this.tvalUp2,
      tvalDwn1: this.tvalDwn1,
      tvalDwn2: this.tvalDwn2,
      tvalBi1: this.tvalBi1,
      tvalBi2: this.tvalBi2,
      tvalBi3: this.tvalBi3,
      tvalBi4: this.tvalBi4,
      GridCategory: this.GridCategory,
      lastUpdated: this.lastUpdated,
      btnRestore: this.btnRestore 
    };
    this.customizePopup = this.modalService.show(MetricEditThresholdComponent, { initialState, backdrop: "static", keyboard: false });
    this.customizePopup.content.setAfterSave.subscribe(result => {
      debugger;
    })
  }

  //--Edit Threshold 
  editThreshold(threshold: any, index: any, type: any): void {
    this.lastUpdated = null;
    if (type == "12") {
      return;
    }
    this.isDisabled=false;
    this.thresholdData.forEach(item => item.chkValue = false);
    this.filterThresholdData.forEach(item => item.chkValue = false);
    this.thresholdAllData.forEach(item => item.chkValue = false);
    this.allSelectedArray = [];
    this.allSelectedArrayClone = []; //defect 1065410 to use in on calcel button on popup                        

    this.allSelectedArray.push(threshold);
    this.allSelectedArrayClone.push(threshold);//defect 1065410 to use in on cancel button on popup
    this.onCustomize();

    this.mirflag = false;
    if (threshold.Metric.Name == 'Number of Major Incidents Reported') {
      this.mirflag = true;
    }

    const initialState = {
      rowUp: this.rowUp,
      rowDown: this.rowDown,
      mirflag: this.mirflag,
      rowBidirectional: this.rowBidirectional,
      allSelectedArray: cloneDeep(this.allSelectedArray),
      isRangeTrend: this.isRangeTrend,
      trendDirectionOptions: cloneDeep(this.trendDirectionOptions),
      selectedTrend: this.selectedTrend,
      IsTrackByPhase: this.IsTrackByPhase,
      isTimeSpanUint: this.isTimeSpanUint,
      timeSpanNote: this.timeSpanNote,
      IsStandMetricNull: this.IsStandMetricNull,
      tvalup1Error: this.tvalup1Error,
      tvalup2Error: this.tvalup2Error,
      tvaldwn1Error: this.tvaldwn1Error,
      tvaldwn2Error: this.tvaldwn2Error,
      tvalbi1Error: this.tvalbi1Error,
      tvalbi2Error: this.tvalbi2Error,
      tvalbi3Error: this.tvalbi3Error,
      isTimeSpanError: this.isTimeSpanError,
      timeSpanErrorMsg: this.timeSpanErrorMsg,
      isError: this.isError,
      thresholdData: cloneDeep(this.thresholdData),
      thresholdSaveData: cloneDeep(this.thresholdSaveData),
      selectedTrendId: this.selectedTrendId,
      TempAllSelectedBothdirectionMetrics: cloneDeep(this.TempAllSelectedBothdirectionMetrics),
      TempAllSelectedUpwardMetrics: cloneDeep(this.TempAllSelectedUpwardMetrics),
      TempAllSelectedDownwardMetrics: cloneDeep(this.TempAllSelectedDownwardMetrics),
      isDisabled: this.isDisabled,
      ToolTipMsg: this.ToolTipMsg,
      allSelectedArrayClone: cloneDeep(this.allSelectedArrayClone),
      IsTrackByPhasePanel: this.IsTrackByPhasePanel,
      isGridDisabled: this.isGridDisabled,
      tvalUp1: this.tvalUp1,
      tvalUp2: this.tvalUp2,
      tvalDwn1: this.tvalDwn1,
      tvalDwn2: this.tvalDwn2,
      tvalBi1: this.tvalBi1,
      tvalBi2: this.tvalBi2,
      tvalBi3: this.tvalBi3,
      tvalBi4: this.tvalBi4,
      GridCategory: this.GridCategory,
      lastUpdated: this.lastUpdated,
      btnRestore: this.btnRestore
    };
    this.customizePopup = this.modalService.show(MetricEditThresholdComponent, { initialState, backdrop: "static", keyboard: false });
    this.customizePopup.content.setAfterSave.subscribe(result => {
      debugger;
    })

  }
  onCustomize() {
    //===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    this.isTimeSpanUint = false;
    this.isTimeSpanError = false;
    this.timeSpanErrorMsg = "";
    var distinctUnitId = {};//used to get distinct metric unit id to show timespan placeholder
    var distinctTrend = {};//used to get distinct tendtype id to show note for timespan metrics
    //==============================end=================
    var distinctDGType = {};
    for (var i = 0; i < this.allSelectedArray.length; i++) {
      var GC = this.allSelectedArray[i].Metric.GridCategory;
      var metricUnit = this.allSelectedArray[i].Metric.Unitid;// assigned metric id===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
      var trendId = this.allSelectedArray[i].TrendTypeid;// assigned trendtype id===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
      distinctDGType[GC] = '';
      distinctUnitId[metricUnit] = '';// assigned all metric id in collection===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
      distinctTrend[trendId] = '';// assigned all trendtype id in collection===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    }

    var distincType = Object.keys(distinctDGType);
    var distinctMetriUnit = Object.keys(distinctUnitId);//getting keys of array to do further enumerable operations===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    var distinctTrendId = Object.keys(distinctTrend);//getting keys of array to do further enumerable operations===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    var trendIdToDisplay = Object.keys(distinctTrend)[0];//getting value of first element===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    //Checked length and the unitid is present or not in array ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    if (distinctMetriUnit.length == 1) {
      if (distinctMetriUnit.indexOf('28') > -1) {//element is present then set the flag true else false
        this.isTimeSpanUint = true;
      }
      else {
        this.isTimeSpanUint = false;
      }
    }
    //=====================end===================
    //Checked length and if it's one then assigned the value to show note ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    if (distinctTrendId.length == 1) {
      this.timeSpanNote = +trendIdToDisplay;//to convert string to integer
    }
    else {
      this.timeSpanNote = 0;//set default value to hide note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
    }
    //=======================end===================
    if (distincType.length == 1) {
      var key = Object.keys(distincType)[0];
      var value = distincType[key]
      if (value == "null") {
        this.IsTrackByPhase = false;
        this.IsTrackByPhasePanel = false;
        this.GridCategory = undefined;
      }
      else {
        this.IsTrackByPhase = this.allSelectedArray[0].IsTrackbyPhases;
        this.IsTrackByPhasePanel = true;
        this.isGridDisabled = true;
        this.GridCategory = "Overall";
      }
    }
    else {
      for (i = 0; i < distincType.length; i++) {

        var key = Object.keys(distincType)[i];
        var value = distincType[key]
        if (value == "Overall") {
          this.IsTrackByPhase = false;
          this.IsTrackByPhasePanel = true;
          this.isGridDisabled = false;
          this.GridCategory = "Overall";
          break;
        }
      }
    }

    // Defect id 1065600 : Saved Data is becoming blank when user changes the trend direction and makes track by phase off and then makes it on

    // Assigning Selected array details in 3 temp variables for Upward,Downward,Double-Sided
    this.TempAllSelectedUpwardMetrics = $.parseJSON(JSON.stringify(this.allSelectedArray.map((x: any) => Object.assign({}, x))));
    this.TempAllSelectedDownwardMetrics = $.parseJSON(JSON.stringify(this.allSelectedArray.map((x: any) => Object.assign({}, x))));
    this.TempAllSelectedBothdirectionMetrics = $.parseJSON(JSON.stringify(this.allSelectedArray.map((x: any) => Object.assign({}, x))));

    if (this.IsTrackByPhase == true) {

      this.IsTrackByPhasePanel = true;
      this.isGridDisabled = true;
      this.changeTrigger++;

      var Ttype = this.allSelectedArray[0].ThresholdType.Name;

      if (Ttype == "Relative Trend") {
        this.btnRestore = true;
        this.isError = false;
        this.showModal = true;
        this.tempIsRangeTrend = 4;
        this.isRangeTrend = 4;
        this.isUpdated = true;

        this.phase();
      }
      else if (Ttype == "Trend") {
        this.showModal = true;
        this.tempIsRangeTrend = 2;
        this.isRangeTrend = 2;
      }
      else {
        this.btnRestore = true;
        this.isError = false;
        this.showModal = true;
        this.tempIsRangeTrend = 1;
        this.isRangeTrend = 1;
        this.isUpdated = true;

        this.phase();
      }


    }

    else {
      this.overAllCustomize();
    }
    this.disableRestoreButton();
    this.IsTrackByPhaseClone = this.IsTrackByPhase; //defect 1065410 to use in on calcel button on popup

  }
  phase() {
    this.btnRestore = true;
    this.isUpdated = true;

    //To find grid category of metric of phase 
    for (var i = 0; i < this.allSelectedArray.length; i++) {
      for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
        this.GridCategory = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Metric.GridCategory;
        if (this.GridCategory == "DefectGrid")
          break;
      }
    }

    if (this.trendDirectionOptions.length == 4) {
      this.trendDirectionOptions.splice(0, 1);
      //deleting   select option in trend direction pop up
    }

    var option;

    //Find trend Direction of single metric selection
    if (this.allSelectedArray.length == 1) {
      option = this.allSelectedArray[0].DGPhasesThreshold[0]._MetricThreshold.TrendTypeid;
      if (option == null) {
        for (var i = 0; i < (this.allSelectedArray.length); i++) {
          for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
            //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
            if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid != null) {
              option = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid;
              break;
            }
            else
              continue
          }
        }
      }
    }
    else {
      //Find trend Direction of multiple metric selection
      for (var i = 0; i < (this.allSelectedArray.length - 1); i++) {
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
          if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid != null)) {
            for (var k = 0; k < (this.allSelectedArray[i + 1].DGPhasesThreshold.length); k++) {
              if (this.allSelectedArray[i + 1].DGPhasesThreshold[k]._MetricThreshold.TrendTypeid == this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid) {
                option = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid;
                break;
              }
              else
                continue;
            }
          }
          else
            continue;
        }
      }
    }

    var Direction = '';

    if (option == 1) {
      Direction = 'Upward';
    }
    if (option == 2) {
      Direction = 'Bi';
    }
    if (option == 3) {
      Direction = 'Downward';
    }

    // Assigning threshold values of phases in one Trend type and removing threshold value for other trend types        

    if (this.TempAllSelectedUpwardMetrics != null && (Direction == "Bi" || Direction == "Downward")) {
      for (var i = 0; i < (this.TempAllSelectedUpwardMetrics.length); i++) {

        for (var j = 0; j < (this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold.length); j++) {
          this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 = null;
          this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 = null;
          this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 = null;
          this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 = null;
          //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
          this.TempAllSelectedUpwardMetrics[i].DGPhasesThreshold[j].chkDGValue = false;
        }
      }
    }

    if (this.TempAllSelectedDownwardMetrics != null && (Direction == "Upward" || Direction == "Bi")) {
      for (var i = 0; i < (this.TempAllSelectedDownwardMetrics.length); i++) {
        for (var j = 0; j < (this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold.length); j++) {
          this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 = null;
          this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 = null;
          this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 = null;
          this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 = null;
          //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
          this.TempAllSelectedDownwardMetrics[i].DGPhasesThreshold[j].chkDGValue = false;
        }
      }
    }

    if (this.TempAllSelectedBothdirectionMetrics != null && (Direction == "Upward" || Direction == "Downward")) {
      for (var i = 0; i < (this.TempAllSelectedBothdirectionMetrics.length); i++) {

        for (var j = 0; j < (this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold.length); j++) {
          this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 = null;
          this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 = null;
          this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 = null;
          this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 = null;
          //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
          this.TempAllSelectedBothdirectionMetrics[i].DGPhasesThreshold[j].chkDGValue = false;
        }
      }
    }

    if (this.allSelectedArray.length == 1) {
      var x = false;
      for (var i = 0; i < this.allSelectedArray.length; i++) {
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = false;

        }
      }

      switch (Direction) {
        case 'Upward':
          this.rowUp = false
          this.rowDown = true
          this.rowBidirectional = true
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedUpwardMetrics.map((x: any) => Object.assign({}, x));
          this.selectedTrend = this.trendDirectionOptions[0].Name;
          this.selectedTrendId = 1;

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        case 'Downward':
          this.rowUp = true
          this.rowDown = false
          this.rowBidirectional = true
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedDownwardMetrics.map((x: any) => Object.assign({}, x));
          this.selectedTrend = this.trendDirectionOptions[2].Name;
          this.selectedTrendId = 3;

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        case 'Bi':
          this.rowUp = true
          this.rowDown = true
          this.rowBidirectional = false
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedBothdirectionMetrics.map((x: any) => Object.assign({}, x));
          this.selectedTrend = this.trendDirectionOptions[1].Name;
          this.selectedTrendId = 2;

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        default:
          if (this.trendDirectionOptions.length != 4) {
          this.trendDirectionOptions.unshift({ TrendTypeId: 0, Name: "--Select--" }) //binding select option in trend direction pop up
        }
       
          this.rowUp = true;
          this.rowDown = true;
          this.rowBidirectional = true;
          this.selectedTrendId = 0;
          this.selectedTrend = this.trendDirectionOptions[0].Name;

          if (this.TempAllSelectedDownwardMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedDownwardMetrics.length); m++) {
              for (var n = 0; n < (this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedDownwardMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }
          if (this.TempAllSelectedUpwardMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedUpwardMetrics.length); m++) {
              for (var n = 0; n < (this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedUpwardMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }
          if (this.TempAllSelectedBothdirectionMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedBothdirectionMetrics.length); m++) {

              for (var n = 0; n < (this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedBothdirectionMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }


          break;
      }
      if (this.allSelectedArray[0].DGPhasesThreshold[0]._MetricThreshold.DeliveryStructLevelId == this.deliveryLevelId) {
        this.isUpdated = false;

        var date = this.formatDate(this.allSelectedArray[0].DGPhasesThreshold[0]._MetricThreshold.ModifiedOn);
        this.lastUpdated = 'Last updated on ' + date + ' GMT by ' + this.allSelectedArray[0].DGPhasesThreshold[0]._MetricThreshold.ModifiedByUser;
      }

    }
    else {
      //logic for checking multiple records for trend directions and thresholds, if values are same then only binding for Range threshold pop up

      var Ttype = this.allSelectedArray[0].ThresholdType.Name;
      this.changeTrigger++;

      if (Ttype == "Relative Trend") { // new logic added as part of story : 2824892
        this.btnRestore = true;
        this.isError = false;
        this.showModal = true;
        this.tempIsRangeTrend = 4;
        this.isRangeTrend = 4;
        this.isUpdated = true;
      }
      else if (Ttype == "Trend") {
        this.showModal = true;
        this.tempIsRangeTrend = 2;
        this.isRangeTrend = 2;
      }
      else {
        this.btnRestore = true;
        this.isError = false;
        this.showModal = true;
        this.tempIsRangeTrend = 1;
        this.isRangeTrend = 1;

        this.isUpdated = true;
      }
      var x = false;
      for (var i = 0; i < this.allSelectedArray.length; i++) {
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = false;
          this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = false;

        }
      }

      switch (Direction) {

        case 'Upward':
          this.rowUp = false
          this.rowDown = true
          this.rowBidirectional = true
          this.selectedTrend = this.trendDirectionOptions[0].Name;
          this.selectedTrendId = 1;
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedUpwardMetrics.map((x: any) => Object.assign({}, x));

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        case 'Downward':
          this.rowUp = true
          this.rowDown = false
          this.rowBidirectional = true
          this.selectedTrend = this.trendDirectionOptions[2].Name;
          this.selectedTrendId = 3;
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedDownwardMetrics.map((x: any) => Object.assign({}, x));

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        case 'Bi':
          this.rowUp = true
          this.rowDown = true
          this.rowBidirectional = false
          this.selectedTrend = this.trendDirectionOptions[1].Name;
          this.selectedTrendId = 2;
          this.allSelectedArray = [];
          this.allSelectedArray = this.TempAllSelectedBothdirectionMetrics.map((x: any) => Object.assign({}, x));

          for (var i = 0; i < this.allSelectedArray.length; i++) {

            for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {

              if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
                x = true;
                continue;
              }
              else {
                x = false;
                break;
              }
            }
            this.allSelectedArray[i].IsAllPhaseSelected = x;
          }

          break;

        default:
          if (this.trendDirectionOptions.length != 4) {
            this.trendDirectionOptions.unshift({ TrendTypeId: 0, Name: "--Select--" }) //binding select option in trend direction pop up
          }
          //Setting threshold data as null when trend directions are mismatched

          this.rowUp = true;
          this.rowDown = true;
          this.rowBidirectional = true;
          this.selectedTrendId = 0;
          this.selectedTrend = this.trendDirectionOptions[0].Name;

          if (this.TempAllSelectedDownwardMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedDownwardMetrics.length); m++) {
              for (var n = 0; n < (this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedDownwardMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedDownwardMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }
          if (this.TempAllSelectedUpwardMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedUpwardMetrics.length); m++) {
              for (var n = 0; n < (this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedUpwardMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedUpwardMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }
          if (this.TempAllSelectedBothdirectionMetrics != null) {
            for (var m = 0; m < (this.TempAllSelectedBothdirectionMetrics.length); m++) {

              for (var n = 0; n < (this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold.length); n++) {
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold1 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold2 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold3 = null;
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n]._MetricThreshold.Threshold4 = null;
                //fix for defect 1070239 - Defect grid - when user combines COR and Defect phase wise metrics , previously selected phases should be unchecked
                this.TempAllSelectedBothdirectionMetrics[m].DGPhasesThreshold[n].chkDGValue = false;
                this.TempAllSelectedBothdirectionMetrics[m].IsAllPhaseSelected = false;
              }
            }
          }
          break;
      }

    }

    this.disableRestoreButton();
  }
  //Default cutomize button functionality
  overAllCustomize() {
    debugger
    var Ttype = this.allSelectedArray[0].ThresholdType.Name;
    this.changeTrigger++;

    if (Ttype == "Trend") {
      this.showModal = true;
      this.tempIsRangeTrend = 2;
      this.isRangeTrend = 2;
    }
    else {
      this.btnRestore = true;
      this.isError = false;
      this.showModal = true;
      if (Ttype == "Relative Trend") {// new logic added as part of story : 2824892
        this.tempIsRangeTrend = 4;
        this.isRangeTrend = 4;
      }
      else {
        this.tempIsRangeTrend = 1;
        this.isRangeTrend = 1;
      }

      this.tvalUp1 = null;
      this.tvalUp2 = null;
      this.tvalDwn1 = null;
      this.tvalDwn2 = null;
      this.tvalBi1 = null;
      this.tvalBi2 = null;
      this.tvalBi3 = null;
      this.tvalBi4 = null;
      this.isUpdated = true;


      if (this.trendDirectionOptions.length == 4) {
        this.trendDirectionOptions.splice(0, 1);
        //deleting   select option in trend direction pop up
      }

      if (this.allSelectedArray.length == 1) {  //for single record data bind based on direction for Range threshold pop up

        this.tempVal1 = this.allSelectedArray[0].Threshold1;
        this.tempVal2 = this.allSelectedArray[0].Threshold2;
        this.tempVal3 = this.allSelectedArray[0].Threshold3;
        this.tempVal4 = this.allSelectedArray[0].Threshold4;

        if (this.tempTrend == null) {
          this.tempTrend = 0;
        }
        switch (this.allSelectedArray[0].TrendTypeid) {

          case 1:                                                           //Upward Trending
            this.rowUp = false
            this.rowDown = true
            this.rowBidirectional = true
            this.tvalUp1 = this.allSelectedArray[0].Threshold1;
            this.tvalUp2 = this.allSelectedArray[0].Threshold2;
            this.selectedTrend = this.trendDirectionOptions[0].Name;
            this.selectedTrendId = 1;
            this.tempTrend = 1;
            break;
          case 3:                                                            //Downward Trending
            this.rowUp = true
            this.rowDown = false
            this.rowBidirectional = true
            this.tvalDwn1 = this.allSelectedArray[0].Threshold1;
            this.tvalDwn2 = this.allSelectedArray[0].Threshold2;
            this.selectedTrend = this.trendDirectionOptions[2].Name;
            this.selectedTrendId = 3;
            this.tempTrend = 3;
            break;
          case 2:                                                            //Bi-Directional Trending
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = false
            this.tvalBi1 = this.allSelectedArray[0].Threshold1;
            this.tvalBi2 = this.allSelectedArray[0].Threshold2;
            this.tvalBi3 = this.allSelectedArray[0].Threshold3;
            this.tvalBi4 = this.allSelectedArray[0].Threshold4;
            this.selectedTrend = this.trendDirectionOptions[1].Name;
            this.selectedTrendId = 2;
            this.tempTrend = 2;
            break;

          default:
            this.TTid = this.allSelectedArray[0].TrendTypeid;
            if ((this.TTid == null || this.TTid == 0) && this.trendDirectionOptions.length != 4) {
              //this.trendDirectionOptions.unshift({ TrendTypeId: 0, Name: "--Select--" }) //binding select option in trend direction pop up
              this.timeSpanNote = 0;//set default value to hide note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            }
            this.rowUp = true
            this.rowDown = true
            this.rowBidirectional = true
            // this.selectedTrend = this.trendDirectionOptions[0].Name;
            this.selectedTrendId = 0;
            this.tempTrend = 0;
            break;
        }
        if (this.allSelectedArray[0].DeliveryStructLevelId == this.deliveryLevelId) {

          this.isUpdated = false;
          var date = this.formatDate(this.allSelectedArray[0].ModifiedOn);

          this.lastUpdated = 'Last updated on ' + date + ' GMT by ' + this.allSelectedArray[0].ModifiedByUser;
        }

      }
      else {

        for (var i = 0; i < (this.allSelectedArray.length - 1);) {                       //logic for checking multiple records for trend directions and thresholds, if values are same then only binding for Range threshold pop up

          if (this.allSelectedArray[i].TrendTypeid == this.allSelectedArray[i + 1].TrendTypeid) {
            i++;
            if (i == (this.allSelectedArray.length - 1)) {                              //Upward Trending
              switch (this.allSelectedArray[i].TrendTypeid) {
                case 1:
                  this.rowUp = false
                  this.rowDown = true
                  this.rowBidirectional = true
                  this.selectedTrend = this.trendDirectionOptions[0].Name;
                  this.selectedTrendId = 1;
                  this.tempTrend = 1;
                  for (var j = 0; j < (this.allSelectedArray.length - 1);) {
                    if (this.allSelectedArray[j].Threshold1 == this.allSelectedArray[j + 1].Threshold1 &&
                      this.allSelectedArray[j].Threshold2 == this.allSelectedArray[j + 1].Threshold2) {
                      j++;
                      if (j == (this.allSelectedArray.length - 1)) {
                        this.tvalUp1 = this.allSelectedArray[j].Threshold1;
                        this.tvalUp2 = this.allSelectedArray[j].Threshold2;


                        this.tempVal1 = this.allSelectedArray[j].Threshold1;
                        this.tempVal2 = this.allSelectedArray[j].Threshold2;
                        this.tempVal3 = this.allSelectedArray[j].Threshold3;
                        this.tempVal4 = this.allSelectedArray[j].Threshold4;
                        if (this.tempTrend == null) {
                          this.tempTrend = 0;
                        }

                      }

                    }
                    else {
                      this.tvalUp1 = '';
                      this.tvalUp2 = '';



                      this.tempVal1 = '';
                      this.tempVal2 = '';
                      if (this.tempTrend == null) {
                        this.tempTrend = 0;
                      }

                      break;
                    }

                  }
                  break;

                case 3:                                                                   //Downward Trending
                  this.rowUp = true
                  this.rowDown = false
                  this.rowBidirectional = true
                  this.selectedTrend = this.trendDirectionOptions[2].Name;
                  this.selectedTrendId = 3;
                  this.tempTrend = 3;
                  for (var j = 0; j < (this.allSelectedArray.length - 1);) {
                    if (this.allSelectedArray[j].Threshold1 == this.allSelectedArray[j + 1].Threshold1 &&
                      this.allSelectedArray[j].Threshold2 == this.allSelectedArray[j + 1].Threshold2) {
                      j++;
                      if (j == (this.allSelectedArray.length - 1)) {
                        this.tvalDwn1 = this.allSelectedArray[j].Threshold1;
                        this.tvalDwn2 = this.allSelectedArray[j].Threshold2;


                        this.tempVal1 = this.allSelectedArray[j].Threshold1;
                        this.tempVal2 = this.allSelectedArray[j].Threshold2;
                        this.tempVal3 = this.allSelectedArray[j].Threshold3;
                        this.tempVal4 = this.allSelectedArray[j].Threshold4;
                        if (this.tempTrend == null) {
                          this.tempTrend = 1;
                        }
                      }

                    }
                    else {

                      this.tvalDwn1 = '';
                      this.tvalDwn2 = '';

                      this.tempVal1 = '';
                      this.tempVal2 = '';
                      if (this.tempTrend == null) {
                        this.tempTrend = 0;
                      }
                      break;
                    }

                  }
                  break;
                case 2:                                                                   //Bi-Directional Trending
                  this.rowUp = true
                  this.rowDown = true
                  this.rowBidirectional = false
                  this.selectedTrend = this.trendDirectionOptions[1].Name;
                  this.selectedTrendId = 2;
                  this.tempTrend = 2;
                  for (var j = 0; j < (this.allSelectedArray.length - 1);) {
                    if (this.allSelectedArray[j].Threshold1 == this.allSelectedArray[j + 1].Threshold1 &&
                      this.allSelectedArray[j].Threshold2 == this.allSelectedArray[j + 1].Threshold2 &&
                      this.allSelectedArray[j].Threshold3 == this.allSelectedArray[j + 1].Threshold3 &&
                      this.allSelectedArray[j].Threshold4 == this.allSelectedArray[j + 1].Threshold4) {
                      j++;

                      if (j == (this.allSelectedArray.length - 1)) {
                        this.tvalBi1 = this.allSelectedArray[j].Threshold1;
                        this.tvalBi2 = this.allSelectedArray[j].Threshold2;
                        this.tvalBi3 = this.allSelectedArray[j].Threshold3;
                        this.tvalBi4 = this.allSelectedArray[j].Threshold4;


                        this.tempVal1 = this.allSelectedArray[j].Threshold1;
                        this.tempVal2 = this.allSelectedArray[j].Threshold2;
                        this.tempVal3 = this.allSelectedArray[j].Threshold3;
                        this.tempVal4 = this.allSelectedArray[j].Threshold4;
                        if (this.tempTrend == null) {
                          this.tempTrend = 0;
                        }

                      }

                    }
                    else {

                      this.tvalBi1 = '';
                      this.tvalBi2 = '';
                      this.tvalBi3 = '';
                      this.tvalBi4 = '';



                      this.tempVal1 = '';
                      this.tempVal2 = '';
                      this.tempVal3 = '';
                      this.tempVal4 = '';
                      if (this.tempTrend == null) {
                        this.tempTrend = 0;
                      }
                      break;
                    }

                  }
                  break;

                default:
                  this.TTid = this.allSelectedArray[0].TrendTypeid;
                  //if (this.trendDirectionOptions.length != 4) {
                  //  this.trendDirectionOptions.unshift({ TrendTypeId: 0, Name: "--Select--" }) //binding select option in trend direction pop up
                  //  this.timeSpanNote = 0;//set default value to hide note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  //}

                  this.rowUp = true
                  this.rowDown = true
                  this.rowBidirectional = true
                  //this.selectedTrend = this.trendDirectionOptions[0].Name;
                  this.selectedTrendId = 0;

                  this.tempTrend = 0;
                  this.tempVal1 = '';
                  this.tempVal2 = '';
                  this.tempVal3 = '';
                  this.tempVal4 = '';
                  break;

              }

            }
          }
          else {
            this.TTid = this.allSelectedArray[0].TrendTypeid;
            //if (this.trendDirectionOptions.length != 4) {
            //  //this.trendDirectionOptions.unshift({ TrendTypeId: 0, Name: "--Select--" }) //binding select option in trend direction pop up
            //  this.timeSpanNote = 0;//set default value to hide note===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
            //}

            this.rowUp = true;
            this.rowDown = true;
            this.rowBidirectional = true;
            this.selectedTrendId = 0;
            //this.selectedTrend = this.trendDirectionOptions[0].Name;
            this.tempTrend = 0;
            this.tempVal1 = '';
            this.tempVal2 = '';
            this.tempVal3 = '';
            this.tempVal4 = '';
            break;
          }

        }
      }
    }
    this.disableRestoreButton();
  }
  disableRestoreButton(): void {

    if (this.IsTrackByPhase == false) {
      for (var i = 0; i < this.allSelectedArray.length; i++) {
        if ((this.allSelectedArray[i].DeliveryStructLevelId == this.deliveryLevelId) && this.allSelectedArray[i].TrendTypeid != null) {
          this.btnRestore = false;

        }
        if (this.allSelectedArray[i].IsCustom == true) {
          this.btnRestore = true;
          break;
        }
      }
    }
    else {
      //To fix defect Restore default configuation
      for (var i = 0; i < this.allSelectedArray.length; i++) {
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
          if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.DeliveryStructLevelId == this.deliveryLevelId) && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.TrendTypeid != null) {
            this.btnRestore = false;
            break;
          }
          if (this.allSelectedArray[i].IsCustom == true) {
            this.btnRestore = true;
            break;
          }
        }
      }
    }
  }
  //117507 fullforms of days and months to be in sync with submission and config screens
  //formatting date for last updated label on pop up
  formatDate(_date: any) {
    var date = new Date(_date);
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    var dateStr = _date.toString();
    var part = dateStr.split("-");
    var dtStr = part[2];
    var dt = dtStr.split("T");
    var hrStr = dt[1];
    var hr = hrStr.split(":");
    var formatedDate = days[date.getDay()] + ', ' + months[date.getMonth()] + ' ' + dt[0] + ', ' + part[0] + '-' + hr[0] + ':' + hr[1]

    return formatedDate;

  }
  closeAlertPopup() {
    this.showAlertPopup = false;
  }
  //Save button click Threshold pop up
  onSaveThresholdClick() {
    debugger;
    //this.objGlobal.setSessionValue("showThreshold", "false");//code added as part of story : 2179221
    this.isPhaseSelected = false;
    this.isError = false;

    this.IsStandMetricNull = false;
    this.selectedTrend;
    this.thresholdSaveData = new Array<any>();
    var enterPriseId = this.sessionStorage.enterpriseId;
    this.tempDeliveryStructId = this.deliveryId;
    this.tempDeliveryLevelId = this.deliveryLevelId;

    if (this.selectedTrendId == 0 && this.trendDirectionOptions.length == 4) {
      this.isError = true;
      this.Error = "Please select Trend Direction."
      return;
    }
    var count: number = 0;
    for (var i = 0; i < (this.allSelectedArray.length); i++) {
      //For overall metric and normal metric
      if (this.GridCategory == "Overall" || this.GridCategory == undefined) {
        var enterPriseId = this.sessionStorage.enterpriseId;

        this.thresholdSaveData.push({
          MetricThresholdId: this.allSelectedArray[i].MetricThresholdId,
          MetricId: this.allSelectedArray[i].MetricId,
          ThresholdTypeid: this.isRangeTrend == 4 ? 3 : 1,
          OptRefinementid: 2,
          TrendTypeid: 1,
          DeliveryStructId: this.allSelectedArray[i].DeliveryStructId,
          DeliveryStructLevelId: this.tempDeliveryLevelId,
          Threshold1: this.tvalUp1,
          Threshold2: this.tvalUp2,
          Threshold3: this.tvalUp1,
          Threshold4: this.tvalUp2,
          CreatedByUser: enterPriseId,
          RowVersion: this.allSelectedArray[i].RowVersion,
          CreatedOn: this.allSelectedArray[i].CreatedOn,
          CreatedByApp: this.allSelectedArray[i].CreatedByApp,
          ModifiedByUser: enterPriseId,
          ModifiedOn: this.allSelectedArray[i].CreatedOn,
          ModifiedByApp: this.allSelectedArray[i].CreatedByApp,
          DeliveryStructUId: this.tempDeliveryStructId,
        })
        if (this.trendDirectionOptions.length == 4) {
          switch (this.selectedTrendId) {

            case 1:

              if ((this.tvalUp1 == null || this.tvalUp2 == null) || (this.tvalUp1 == "" || this.tvalUp2 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }
              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalUp1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalUp2);

                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                  this.isError = true;
                }

                this.tvalup1Error = DecimalCheckThreshold1;
                this.tvalup2Error = DecimalCheckThreshold2;
              }
              else {
                if (this.tvalup1Error || this.tvalup2Error) { //set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                 
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }

              if (this.tvalUp1 != null && this.tvalUp2 != null) {

                this.thresholdSaveData[i].TrendTypeid = 1;
                this.thresholdSaveData[i].Threshold1 = this.tvalUp1;
                this.thresholdSaveData[i].Threshold2 = this.tvalUp2;
                this.thresholdSaveData[i].Threshold3 = null;
                this.thresholdSaveData[i].Threshold4 = null;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if (this.tvalUp1.substr(0, 1) == '.' && this.tvalUp1.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalUp1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalUp2;
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if (this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = this.tvalUp1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalUp2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if ((this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1) && (this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalUp1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalUp2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }
                }
              }
              break;

            case 2:

              if ((this.tvalBi1 == null || this.tvalBi2 == null || this.tvalBi3 == null || this.tvalBi4 == null) || (this.tvalBi1 == "" || this.tvalBi2 == "" || this.tvalBi3 == "" || this.tvalBi4 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }
              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===

                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalBi1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalBi2);
                var DecimalCheckThreshold3 = this.ValidateThresholdValue(this.tvalBi3);
                var DecimalCheckThreshold4 = this.ValidateThresholdValue(this.tvalBi4);
                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                  this.isError = true;
                }

                this.tvalbi1Error = DecimalCheckThreshold1;
                this.tvalbi2Error = DecimalCheckThreshold2;
                this.tvalbi3Error = DecimalCheckThreshold3;
                this.tvalbi4Error = DecimalCheckThreshold4;
              }
              else {
                if (this.tvalbi1Error || this.tvalbi2Error || this.tvalbi3Error || this.tvalbi4Error) {     //set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                                              
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }

              if (this.tvalBi1 != null && this.tvalBi2 != null && this.tvalBi3 != null && this.tvalBi4 != null) {
                this.thresholdSaveData[i].TrendTypeid = 2;
                this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                this.thresholdSaveData[i].Threshold4 = this.tvalBi4;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if ((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && ((this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;
                  }

                  if ((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if ((this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }

                  if ((this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }

                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1)) && ((this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && ((this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }

                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if ((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                }
              }
              break;

            case 3:

              if ((this.tvalDwn1 == null || this.tvalDwn2 == null) || (this.tvalDwn1 == "" || this.tvalDwn2 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }
              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalDwn1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalDwn2);

                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                  this.isError = true;
                }

                this.tvaldwn1Error = DecimalCheckThreshold1;
                this.tvaldwn2Error = DecimalCheckThreshold2;
              }
              else {
                if (this.tvaldwn1Error || this.tvaldwn2Error) { //set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                                              
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }


              if (this.tvalDwn1 != null && this.tvalDwn2 != null) {

                this.thresholdSaveData[i].TrendTypeid = 3;
                this.thresholdSaveData[i].Threshold1 = this.tvalDwn1;
                this.thresholdSaveData[i].Threshold2 = this.tvalDwn2;
                this.thresholdSaveData[i].Threshold3 = null;
                this.thresholdSaveData[i].Threshold4 = null;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if (this.tvalDwn1.substr(0, 1) == '.' && this.tvalDwn1.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalDwn1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalDwn2;
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;
                  }

                  if (this.tvalDwn2.substr(0, 1) == '.' && this.tvalDwn2.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = this.tvalDwn1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalDwn2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;
                  }

                  if ((this.tvalDwn1.substr(0, 1) == '.' && this.tvalDwn1.length != 1) && (this.tvalDwn2.substr(0, 1) == '.' && this.tvalDwn2.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalDwn1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalDwn2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;
                  }
                }
              }
              break;

            default:
              break;
          }
        }
        else {
          switch (this.selectedTrendId) {

            case 0:

              if ((this.tvalUp1 == null || this.tvalUp2 == null) || (this.tvalUp1 == "" || this.tvalUp2 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }

              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalUp1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalUp2);

                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                  this.isError = true;

                }

                this.tvalup1Error = DecimalCheckThreshold1;
                this.tvalup2Error = DecimalCheckThreshold2;

              }
              else {
                if (this.tvalup1Error || this.tvalup2Error) { //set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                       
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }

              if (this.tvalUp1 != null && this.tvalUp2 != null) {

                this.thresholdSaveData[i].TrendTypeid = 1;
                this.thresholdSaveData[i].Threshold1 = this.tvalUp1;
                this.thresholdSaveData[i].Threshold2 = this.tvalUp2;
                this.thresholdSaveData[i].Threshold3 = null;
                this.thresholdSaveData[i].Threshold4 = null;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if (this.tvalUp1.substr(0, 1) == '.' && this.tvalUp1.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalUp1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalUp2;
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if (this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = this.tvalUp1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalUp2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if ((this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1) && (this.tvalUp2.substr(0, 1) == '.' && this.tvalUp2.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 1;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalUp1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalUp2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;
                  }
                }
              }

              break;

            case 1:

              if ((this.tvalBi1 == null || this.tvalBi2 == null || this.tvalBi3 == null || this.tvalBi4 == null) || (this.tvalBi1 == "" || this.tvalBi2 == "" || this.tvalBi3 == "" || this.tvalBi4 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }
              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalBi1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalBi2);
                var DecimalCheckThreshold3 = this.ValidateThresholdValue(this.tvalBi3);
                var DecimalCheckThreshold4 = this.ValidateThresholdValue(this.tvalBi4);

                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                  this.isError = true;
                }

                this.tvalbi1Error = DecimalCheckThreshold1;
                this.tvalbi2Error = DecimalCheckThreshold2;
                this.tvalbi3Error = DecimalCheckThreshold3;
                this.tvalbi4Error = DecimalCheckThreshold4;
              }
              else {
                if (this.tvalbi1Error || this.tvalbi2Error || this.tvalbi3Error || this.tvalbi4Error) {//set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                 
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }

              if (this.tvalBi1 != null && this.tvalBi2 != null && this.tvalBi3 != null && this.tvalBi4 != null) {


                this.thresholdSaveData[i].TrendTypeid = 2;
                this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                this.thresholdSaveData[i].Threshold4 = this.tvalBi4;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if ((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && ((this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }


                  if ((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if ((this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }

                  if ((this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }

                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1)) && ((this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && ((this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && ((this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1)))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }

                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1)) && (this.tvalBi4.substr(0, 1) != '.' && (this.tvalBi4.length != 1 || this.tvalBi4.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = this.tvalBi4;

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi3.substr(0, 1) != '.' && (this.tvalBi3.length != 1 || this.tvalBi3.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = this.tvalBi3;
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi2.substr(0, 1) != '.' && (this.tvalBi2.length != 1 || this.tvalBi2.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalBi2;
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if (((this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) && (this.tvalBi1.substr(0, 1) != '.' && (this.tvalBi1.length != 1 || this.tvalBi1.length == 1))) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = this.tvalBi1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);

                  }
                  if ((this.tvalBi1.substr(0, 1) == '.' && this.tvalBi1.length != 1) && (this.tvalBi2.substr(0, 1) == '.' && this.tvalBi2.length != 1) && (this.tvalBi3.substr(0, 1) == '.' && this.tvalBi3.length != 1) && (this.tvalBi4.substr(0, 1) == '.' && this.tvalBi4.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 2;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalBi1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalBi2);
                    this.thresholdSaveData[i].Threshold3 = ('0' + this.tvalBi3);
                    this.thresholdSaveData[i].Threshold4 = ('0' + this.tvalBi4);
                  }
                }
              }
              break;

            case 2:

              if ((this.tvalDwn1 == null || this.tvalDwn2 == null) || (this.tvalDwn1 == "" || this.tvalDwn2 == "")) {
                this.isError = true;
                this.Error = "Please enter threshold values."
                this.IsStandMetricNull = true;
              }

              if (!this.isTimeSpanUint) {//Checked for timespan flag and set error message===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.tvalDwn1);
                var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.tvalDwn2);

                if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                  this.isError = true;
                }

                this.tvaldwn1Error = DecimalCheckThreshold1;
                this.tvaldwn2Error = DecimalCheckThreshold2;
              }
              else {
                if (this.tvaldwn1Error || this.tvaldwn2Error) {//set error message ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===                                 
                  this.Error = this.timeSpanErrorMsg;
                  this.isError = true;
                }
              }


              if (this.tvalDwn1 != null && this.tvalDwn2 != null) {

                this.thresholdSaveData[i].TrendTypeid = 3;
                this.thresholdSaveData[i].Threshold1 = this.tvalDwn1;
                this.thresholdSaveData[i].Threshold2 = this.tvalDwn2;
                this.thresholdSaveData[i].Threshold3 = null;
                this.thresholdSaveData[i].Threshold4 = null;
                if (!this.isTimeSpanUint) {//Checked for timespan flag ===1.9 strory 396904: MSE: Requirements for timespan IO metrics(Configuration)===
                  if (this.tvalDwn1.substr(0, 1) == '.' && this.tvalDwn1.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalDwn1);
                    this.thresholdSaveData[i].Threshold2 = this.tvalDwn2;
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if (this.tvalDwn2.substr(0, 1) == '.' && this.tvalDwn2.length != 1) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = this.tvalDwn1;
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalDwn2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;

                  }

                  if ((this.tvalDwn2.substr(0, 1) == '.' && this.tvalDwn2.length != 1) && (this.tvalDwn1.substr(0, 1) == '.' && this.tvalDwn1.length != 1)) {

                    this.thresholdSaveData[i].TrendTypeid = 3;
                    this.thresholdSaveData[i].Threshold1 = ('0' + this.tvalDwn1);
                    this.thresholdSaveData[i].Threshold2 = ('0' + this.tvalDwn2);
                    this.thresholdSaveData[i].Threshold3 = null;
                    this.thresholdSaveData[i].Threshold4 = null;
                  }
                }
              }
              break;

            default:
              break;
          };
        }
      }
      else {
        //For defect grid - phases     
        this.isPhaseSelected = false;    //Defect fix- 278474     
        for (var j = 0; j < (this.allSelectedArray[i].DGPhasesThreshold.length); j++) {
          if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == true) {
            this.isPhaseSelected = true;
          }

          this.thresholdSaveData.push({
            MetricThresholdId: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.MetricThresholdId,
            MetricId: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.MetricId,
            ThresholdTypeid: this.isRangeTrend == 4 ? 3 : 1,
            OptRefinementid: 2,
            TrendTypeid: 1,
            DeliveryStructId: this.allSelectedArray[i].DeliveryStructId,
            DeliveryStructLevelId: this.tempDeliveryLevelId,
            Threshold1: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1,
            Threshold2: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2,
            Threshold3: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1,
            Threshold4: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2,
            CreatedByUser: enterPriseId,
            RowVersion: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.RowVersion,
            CreatedOn: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.CreatedOn,
            CreatedByApp: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.CreatedByApp,
            ModifiedByUser: enterPriseId,
            ModifiedOn: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.CreatedOn,
            ModifiedByApp: this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.CreatedByApp,
            DeliveryStructUId: this.tempDeliveryStructId,
          })


          if (this.trendDirectionOptions.length == 4) {
            switch (this.selectedTrendId) {

              case 1:

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null;
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;

                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "")) {


                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.isError = true;
                      this.Error = "Please enter threshold values.";
                    }
                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;


                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null) {

                    this.thresholdSaveData[count].TrendTypeid = 1;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = null;
                    this.thresholdSaveData[count].Threshold4 = null;

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                  }
                }
                break;

              case 2:
                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null;
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = false;

                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                  var DecimalCheckThreshold3 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                  var DecimalCheckThreshold4 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 == "")) {


                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.Error = "Please enter threshold values.";
                    }
                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = DecimalCheckThreshold3;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = DecimalCheckThreshold4;

                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 != null) {

                    this.thresholdSaveData[count].TrendTypeid = 2;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                    this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }

                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)))) {

                      this.thresholdSaveData[j].TrendTypeid = 2;
                      this.thresholdSaveData[j].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[j].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[j].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[j].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }

                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                  }
                }
                break;

              case 3:

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null;
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;

                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "")) {


                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.Error = "Please enter threshold values.";
                    }
                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;


                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null) {

                    this.thresholdSaveData[count].TrendTypeid = 3;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = null;
                    this.thresholdSaveData[count].Threshold4 = null;

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;
                    }
                  }
                }
                break;

              default:
                break;
            };
          }
          else {
            switch (this.selectedTrendId) {

              case 0:

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null;
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "")) {



                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.Error = "Please enter threshold values.";
                    }
                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;


                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null) {


                    this.thresholdSaveData[count].TrendTypeid = 1;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = null;
                    this.thresholdSaveData[count].Threshold4 = null;

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 1;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                  }

                }
                break;

              case 1:

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null; //TrendTypeid = 2
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = false;
                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                  var DecimalCheckThreshold3 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                  var DecimalCheckThreshold4 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 == "")) {


                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.Error = "Please enter threshold values.";
                    }

                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true || DecimalCheckThreshold3 == true || DecimalCheckThreshold4 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold3 = DecimalCheckThreshold3;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold4 = DecimalCheckThreshold4;

                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4 != null) {


                    this.thresholdSaveData[count].TrendTypeid = 2;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                    this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }


                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }

                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1)))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }

                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4;

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3;
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if (((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) != '.' && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1 || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length == 1))) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);

                    }
                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3.length != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 2;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold3);
                      this.thresholdSaveData[count].Threshold4 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold4);
                    }
                  }
                }
                break;

              case 2:

                if (this.allSelectedArray[i].DGPhasesThreshold[j].chkDGValue == false) {
                  this.thresholdSaveData[count].TrendTypeid = null;
                  this.thresholdSaveData[count].Threshold1 = null;
                  this.thresholdSaveData[count].Threshold2 = null;
                  this.thresholdSaveData[count].Threshold3 = null;
                  this.thresholdSaveData[count].Threshold4 = null;
                  this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = false;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;

                }
                else {

                  var DecimalCheckThreshold1 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                  var DecimalCheckThreshold2 = this.ValidateThresholdValue(this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);

                  if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == null || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == null) || (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 == "" || this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 == "")) {


                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                      this.Error = "Please enter threshold values.";
                    }
                  }
                  else {
                    if (DecimalCheckThreshold1 == true || DecimalCheckThreshold2 == true) {
                      this.isError = true;
                      this.allSelectedArray[i].DGPhasesThreshold[j].chkPhaseValue = true;
                    }
                  }

                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold1 = DecimalCheckThreshold1;
                  this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.IsValidThreshold2 = DecimalCheckThreshold2;


                  if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1 != null && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != null) {

                    this.thresholdSaveData[count].TrendTypeid = 3;
                    this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                    this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                    this.thresholdSaveData[count].Threshold3 = null;
                    this.thresholdSaveData[count].Threshold4 = null;

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2;
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.length != 1) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1;
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                    if ((this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2 != 1) && (this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.substr(0, 1) == '.' && this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1.length != 1)) {

                      this.thresholdSaveData[count].TrendTypeid = 3;
                      this.thresholdSaveData[count].Threshold1 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold1);
                      this.thresholdSaveData[count].Threshold2 = ('0' + this.allSelectedArray[i].DGPhasesThreshold[j]._MetricThreshold.Threshold2);
                      this.thresholdSaveData[count].Threshold3 = null;
                      this.thresholdSaveData[count].Threshold4 = null;

                    }

                  }

                }
                break;

              default:
                break;
            };

          }

          count++;

        }

        //if (this.isPhaseSelected == false) {

        //  this.alertBox.show("Error:", "Track by phases is set on , but none of the phases are selected. Please mark Track by phases OFF and then continue with save.", "No");
        //  return;
        //}
      }
    }
    if (this.isError == true) {
      return;
    }

    this.saveThreshold(this.thresholdSaveData);
    this.allSelectedArrayClone = [];
  }

  ValidateThresholdValue(Threshold: any) {
    var errorFlag = false;// if errorflag is true then error is there
    if (Threshold != null && Threshold != '') {
      var pattern = /^(\-)?[0-9]{0,}((\.){1}[0-9]{1,}){0,1}$/;  //PRB0060759
      if ((Threshold.substr(Threshold.length - 1) == '.')
        || (Threshold.substr(0, 1) == '.' && Threshold.length == 1)
        || (Threshold.substr(0, 1) == '-' && Threshold.length == 1)
        || (!Threshold.toString().match(pattern))) {  //PRB0060759
        this.Error = "Please enter valid decimal values"
        return true;
      }

      var returnValBeforeDec = false;
      var retrunValAfterDec = false;

      var value_tval = Threshold.split(".");;
      var before_decimalpoint_tval = value_tval[0];
      var after_decimalpoint_tval = value_tval[1];


      if (before_decimalpoint_tval != undefined) {
        if (before_decimalpoint_tval.length > 16) {
          returnValBeforeDec = true;
          this.Error = "You can enter maximum 16 digits before and 4 digits after decimal"
        }
        else {
          returnValBeforeDec = false;
        }
      }
      if (after_decimalpoint_tval != undefined) {
        if (after_decimalpoint_tval.length > 4) {
          retrunValAfterDec = true;
          this.Error = "You can enter maximum 16 digits before and 4 digits after decimal"
        }
        else {
          retrunValAfterDec = false;
        }
      }
      if (retrunValAfterDec == true || returnValBeforeDec == true) {
        errorFlag = true;
      }

      else {

        errorFlag = false
      }
    }
    else {

      errorFlag = true;
    }
    return errorFlag;
  }

  onCancelClick() {
      this.allSelectedArray = [];
      this.allSelectedArrayClone = [];//defect fix -278561 
      this.logicForButton(this.allSelectedArray)
      this.thresholdData.forEach(item => item.chkValue = false);
      this.filterThresholdData.forEach(item => item.chkValue = false);
      this.thresholdAllData.forEach(item => item.chkValue = false);
      this.showModal = false
      $('.modal-backdrop').hide();
      $('body').removeClass('modal-open');

    this.RemoveErrorMessages();

  }
  RemoveErrorMessages() {

    this.isError = false;
    this.IsStandMetricNull = false;
    this.tvalup1Error = false;
    this.tvalup2Error = false;
    this.tvaldwn1Error = false;
    this.tvaldwn2Error = false;
    this.tvalbi1Error = false;
    this.tvalbi2Error = false;
    this.tvalbi3Error = false;
    this.tvalbi4Error = false;
  }

  //Save threshold call(Pop-Up)
  saveThreshold(saveData: any): void {
    // Defect id 1064994 : issue 2 -Threshold pop up is appearing twice before getting saved by sagar
    this.loadingStatus = true;
    this.showModal = false;
    $('.modal-backdrop').hide();
    $('body').removeClass('modal-open');
    //debugger;
    this.metricService.saveThresholds(saveData, this.IsTrackByPhase, this.GridCategory).subscribe(
      message => {
        this.loadingStatus = false;
        // this.alertBox.show("Threshold Configuration :", message, "No");

      },
      error => this.errorMessage = <any>error,
    );
    this.sharedService.openAlertPopup("Data saved Successfully!", window.scrollY)
    this.allSelectedArray = [];
    this.logicForButton(this.allSelectedArray)
    this.thresholdData.forEach(item => item.chkValue = false);
    this.filterThresholdData.forEach(item => item.chkValue = false);
    this.thresholdAllData.forEach(item => item.chkValue = false);
    this.applyFilter();
  }
  DisplayThresholduserUserGuide() {
    this.router.navigate(['/mywizard/thresholduserguide']);
  }

}
