<div class="app-wrap -flex">
  <!-- body -->
  <div class="c-user-guide-stories -collapsed">
    <div class="c-myWizard-content">
      <div class="c-myWizard-container">
        <div class="c-user-guide-stories-content">
          <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/configuration-user-guide-1.jpg" alt="UserGuide" role="presentation" /></div>
          <div class="c-user-guide-stories-container -configuration-userguide1">
            <div class="c-user-guide-stories-data">
              <div class="c-user-guide-stories-data__header">
                <a href="javascript:;" role="button" data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
              </div>
              <div class="c-user-guide-stories-data__body">
                <div class="c-user-guide-stories__text">Select Yes to combine the manually acquired Measures of one or more Teams<p>Use the tick box to select one or more Teams</p></div>
              </div>
              <div class="c-user-guide-stories-data__footer">
                <div class="c-user-guide-stories__progress">
                  <div class="c-user-guide-stories__progress-bar">
                    <span style="--value:100%"></span>
                  </div>
                  <div class="c-user-guide-stories__progress-data">
                    <span>1</span>
                    <span>/ 1</span>
                  </div>
                </div>
                <div class="c-user-guide-stories__actions">
                  <a href="javascript:;" role="button" class="-prev" title="Previous">PREV</a>
                  <a href="javascript:;" role="button" class="-next" title="Next">NEXT</a>
                </div>
              </div>
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>    <!-- /body -->
    </div>
