import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { AddMeasureService } from '../../core/services/add.measure.service'
//import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
//import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
//import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SessionStorage } from '../../core/context/sessionstorage';
var $ = (<any>window).$;
declare var window: any;

@Component({
  selector: 'measure-ui',
  templateUrl: './measure.add.component.html',
  providers: [AddMeasureService],
})

export class AdminMeasureComponent {

  @Input() selectedMeasure: any;
  @Output() _onCancelClick = new EventEmitter<any>();
  @Output() _MeasureSave = new EventEmitter<any>();
  accessFlag: boolean;
  Abrrivation: any;
  alertCss: string = "";
  AccociateMeasurMetric: Array<string> = [];
  //complexForm: UntypedFormGroup;
  Defination: any;
  DefinationError: boolean = false;
  DeliveryLevelId: any;
  //dropdownSettings: IDropdownSettings = {}; e
  //dropdownSettingsDisabled: IDropdownSettings = {}; e
  EnterpriseId: any;
  Error: any = "Please fill in all the required fields.";
  errorMessage: string;
  fieldCheckError: boolean = false;
  fieldCheckIds: any = [];
  FieldcheckVisible: boolean = true;
  IsAddCustomMeasureButton: boolean = false;
  IsAddMeasureButton: boolean = false;
  isAlertBox: boolean = false;
  IsCustom: boolean;
  IsEdit: boolean = true;
  IsEditMeasure: boolean;
  isexists: any = false;
  IsDisable: boolean = false;
  loadingStatus: boolean = false; //Added to show loading symbol.
  measureData: Array<any> = new Array<any>();
  measureError: boolean = false;
  MeasureDataForEdit: any[];
  measureId: any;
  MeasureIDlist: Array<string> = [];
  MeasureList: any = [];
  measureName: any;
  measureNameError: boolean = false;
  MName: any;
  //myOptions: IMultiSelectOption[] = [];
  myOptionsFieldCheck: any [];
  newMeasureList: any = [];
  optionsModel: Array<any> = new Array<any>();
  optionsModelFieldCheck: Array<any> = new Array<any>();
  popup_source: boolean = false;
  RecommendedSource: any[];
  SelectedFieldCheck: Array<string> = [];
  Selected_Source: Array<string> = [];
  Sfield: any = "";
  showRA = false;
  SourceType: Array<string> = [];
  Sourcibility: string;
  Unit: any = 0;
  unitError: boolean = false;
  UnitList: any[];


  constructor(private measureService: AddMeasureService, private sessionStorage: SessionStorage) { }

  ngOnInit(): void {

    this.loadingStatus = true; //Added to show loading symbol. Defect# 706344
    this.showRA = false;
    //this.accessFlag = this.objGlobal.getAccessFlag();  e
    //this.EnterpriseId = this.objGlobal.getSessionValue("EnterpriseId");  e
    //this.DeliveryLevelId = this.objGlobal.getSessionValue("DeliveryLevelId");  e
    this.getMeasureNamelIst();
    setTimeout(function () {
      window.TooltipFunc();
    }, 0);

    this.measureId = this.selectedMeasure.MeasureId;
    if (this.measureId != null) {
      this.EditMeasure(this.measureId);
      this.BindMeasureMetric(this.measureId);
      this.IsEditMeasure = true;
      this.getUnitList(false);
    }
    else {
      this.IsEdit = false;
      this.IsEditMeasure = false;
      this.IsAddMeasureButton = true;
      this.AccociateMeasurMetric = [];
      this.IsAddCustomMeasureButton = true;
      this.getUnitList(true);
    }
    //this.dropdownSettings = {  e
    //  singleSelection: false,
    //  idField: 'id',
    //  textField: 'name',
    //  selectAllText: 'Select All',
    //  unSelectAllText: 'UnSelect All',
    //  itemsShowLimit: 1,
    //  allowSearchFilter: true,
    //  enableCheckAll: false
    //};

    //this.dropdownSettingsDisabled = {  e
    //  singleSelection: false,
    //  idField: 'id',
    //  textField: 'name',
    //  selectAllText: 'Select All',
    //  unSelectAllText: 'UnSelect All',
    //  itemsShowLimit: 3,
    //  allowSearchFilter: true,

    //};
  }

  AddMeasure(Data): void {

    this.loadingStatus = true;//Loding sing for defect 1006518 Measure is getting doubled each time user adds a new measure while creating custom metric. by sagar
    if (this.Unit == 0 || this.Unit == undefined) {
      this.unitError = true;
    }
    else {
      this.unitError = false;
    }

    if (this.measureName == "" || this.measureName == undefined) {

      this.measureNameError = true;

    } else {
      this.measureError = false;
      this.measureNameError = false;
      this.Error = false;
      for (var i = 0; i < this.MeasureList.length; i++) {
        if (this.MeasureList[i].toLowerCase() === this.measureName.toLowerCase()) {
          if (this.measureId != null) {
            if (this.MName == this.measureName) {
              this.measureError = false;
            }
            else {
              this.measureError = true;
              this.loadingStatus = false;//Loding sing for defect 1006518 Measure is getting doubled each time user adds a new measure while creating custom metric. by sagar
              return;
            }
          }
          else {
            this.measureError = true;
            this.loadingStatus = false;
            return;
          }

        }
      }
    }
    if (this.Defination == "" || this.Defination == undefined) {
      this.DefinationError = true;

    } else {
      this.DefinationError = false;
    }

    if (this.Unit == 0 || this.Unit == undefined
      || this.measureName == "" || this.measureName == undefined || this.Defination == "" || this.Defination == undefined) {

      this.Error = "Please fill in all the required fields.";
      this.loadingStatus = false;//Loding sing for defect 1006518 Measure is getting doubled each time user adds a new measure while creating custom metric. by sagar
    }
    else {
     
      if (this.measureId == null)
      {

        this.measureData.push({
          MeasureId: this.measureId,
          Name: this.measureName,
          Abbreviation: this.Abrrivation,
          Definition: this.Defination,
          UnitId: this.Unit,
          IsCustom: true,
          MeasureSources: [5],
          FiledCheck: this.fieldCheckIds,
          IsInsert: "Add",
          EnterpriseId: this.sessionStorage.enterpriseId
        })
        this.measureService.saveMeasure(this.measureData, this.EnterpriseId, "Add").subscribe(
          message => {
            this.loadingStatus = false;

            if (message == true) {
              //if measure already exists, then show error.
              this.measureError = true;

            }
            else if (message != true) {
              this.isAlertBox = true;
            }
            this.measureData = [];
          },
          error => this.errorMessage = <any>error);
      }
      else {

        this.measureData.push({
          MeasureId: this.measureId,
          Name: this.measureName,
          Abbreviation: this.Abrrivation,
          Definition: this.Defination,
          UnitId: this.Unit,
          IsCustom: true,
          MeasureSources: [5],
          FiledCheck: this.fieldCheckIds,
          IsInsert: "Update",
          EnterpriseId: this.sessionStorage.enterpriseId
        })

        this.measureService.saveMeasure(this.measureData, this.EnterpriseId, "Update").subscribe(
          message => {
            debugger;
            this.loadingStatus = false;//Loding sing for defect 1006518 Measure is getting doubled each time user adds a new measure while creating custom metric. by sagar
            this.isAlertBox = true;
          },
          error => this.errorMessage = <any>error);
        this._MeasureSave.emit(this.measureData)
      }
    }

  }

  EditMeasure(Measure: any) {
    this.measureService.getMeasureForEdit(Measure, this.DeliveryLevelId).subscribe(
      Data => {
        this.MeasureDataForEdit = Data

        this.BindMeasure(this.MeasureDataForEdit);
      },

      error => this.errorMessage = <any>error);
  }

  BindMeasure(MeasureData: any) {
    this.MName = MeasureData.Name;
    this.measureName = MeasureData.Name;
    this.Abrrivation = MeasureData.Abbreviation;
    this.Defination = MeasureData.Definition;
    this.Unit = MeasureData.UnitId;
    this.getFieldCheckForEditMeasure(this.Unit, MeasureData.FiledCheck);
    this.RecommendedSource = MeasureData.RecommendedSource;
    this.IsCustom = MeasureData.IsCustom;
    this.loadingStatus = false //Added to show loading symbol. Defect# 706344

  }

  BindMeasureMetric(MeasurId: any) {
    this.measureService.getAssociateMetricMeasure(MeasurId).subscribe(
      checks => {
        this.AccociateMeasurMetric = checks;
      }
      ,
      error => this.errorMessage = <any>error);
  }

  ClosePopup(): void {
    /*this.complexForm.reset();*/
    this.optionsModel = [];
    this.optionsModelFieldCheck = [];
    this.selectedMeasure = [];
    this._onCancelClick.emit(3);
  }

  ValidateField(fieldValue: any, field) {
    switch (field) {

      case 'name':

        if (fieldValue == "" || fieldValue == undefined) {

          this.measureNameError = true;
          this.Error = "Please fill in all the required fields.";
        }
        else {
          this.Error = "";
          this.onmeasureChange(fieldValue)
        }
        break;
      case 'defi':

        if (fieldValue == "" || fieldValue == undefined) {
          this.DefinationError = true;
          this.Error = "Please fill in all the required fields.";

        }
        else {
          this.DefinationError = false;
        }
        break;
    }
  }

  EditMeasureClick() {

    this.IsEdit = !this.IsEdit;
    this.IsDisable = true;
  }

  fillMeasureName(MeasureList: any[]): void {

    for (var i = 0; i < MeasureList.length; i++) {
      if (MeasureList[i].SourceId != 7) {
        this.newMeasureList.push({ id: MeasureList[i].MeasureId, name: MeasureList[i].Name });
      }
      this.MeasureList.push(MeasureList[i].Name);
      this.MeasureIDlist.push(MeasureList[i].MeasureId);
    };
    this.loadingStatus = false;
  }

  fillFieldcheck(data: any[]): void {

    //for (var i = 0; i < data.length; i++) {
    //  this.myOptionsFieldCheck.push({ id: data[i].FieldCheckId, name: data[i].Definition })

    //};
    this.showRA = true;
  }

  fillsource(wstype: any[]): void {

    //for (var i = 0; i < wstype.length; i++) {
    //  this.myOptions.push({ id: wstype[i].SourceId, name: wstype[i].Name })

    //};
  }

  getFieldCheckForEditMeasure(UnitId: any, slectedFieldCheck: any): void {
    this.measureService.getFieldCheck(UnitId).subscribe(
      checks => {
        this.Sfield = "";
        this.FieldcheckVisible = false;
        this.fillFieldcheck(checks)
        this.optionsModelFieldCheck = slectedFieldCheck;
        for (var i = 0; i < this.optionsModelFieldCheck.length; i++) {
          //var Fcheck = this.getFCName(this.myOptionsFieldCheck, this.optionsModelFieldCheck[i]);
          //if (Fcheck != null) {
          //  this.SelectedFieldCheck.push(Fcheck);

          //}

        }
        for (var j = 0; j < this.SelectedFieldCheck.length; j++) {
          if (j == 0) {

            this.Sfield = this.SelectedFieldCheck[j];
          }
          else {
            this.Sfield = this.Sfield + ',' + this.SelectedFieldCheck[j];
          }
        }
      }
      ,
      error => this.errorMessage = <any>error);

  }

  getFCName(data, value) {
    for (var k = 0; k < data.length; k++) {
      if (data[k].id == value) {
        return data[k];
      }
    }
  }

  getMeasureNamelIst(): void {
    this.measureService.getMeasureNameList().subscribe(
      source => {
        //this.newMeasureList = source;
        this.fillMeasureName(source);
        this.MeasureIDlist = source;
      }
      ,
      error => this.errorMessage = <any>error);
  }

  getFieldCheck(UnitId: any): void {
    //   debugger;
    this.measureService.getFieldCheck(UnitId).subscribe(
      checks => {
        /*this.myOptionsFieldCheck = [];*/
        this.optionsModelFieldCheck = [];
        //this.FiledCheckList = checks,
        this.FieldcheckVisible = false;
        this.fillFieldcheck(checks)
      }
      ,
      error => this.errorMessage = <any>error);
  }

  getSourceList(): void {
    this.measureService.getSourceList().subscribe(
      source => {

        this.fillsource(source)
      }
      ,
      error => this.errorMessage = <any>error);
  }

  getUnitList(FlagEdit: boolean): void {
    this.measureService.getUnitList(FlagEdit).subscribe(
      unit => {
        this.UnitList = unit
        //this.loadingStatus = false //Added to show loading symbol. Defect# 706344
      },
      error => this.errorMessage = <any>error);

  }

  changeUnit(value: any) {

    this.FieldcheckVisible = true;
    if (value == 0) {
      this.FieldcheckVisible = true;
      this.unitError = true;
      this.Error = "Please fill in all the required fields.";
    }
    else {
      this.unitError = false
      this.getFieldCheck(value);
    }

  }

  onChangeFieldCheck() {
    this.fieldCheckIds = [];

    if (this.optionsModelFieldCheck.length > 0) {
      this.fieldCheckError = false;
      this.alertCss = "";
    }
    else {
      this.fieldCheckError = true;
    }

    for (var x = 0; x < this.optionsModelFieldCheck.length; x++) {
      this.fieldCheckIds.push(this.optionsModelFieldCheck[x].id);
    }

  }

  onmeasureChange(selectedMeasure: any): void {

    this.measureError = false;
    this.measureNameError = false;
    for (var i = 0; i < this.MeasureList.length; i++) {

      if (this.MeasureList[i].toLowerCase() === this.measureName.toLowerCase()) {
        if (this.measureId != null) {
          if (this.MName == this.measureName) {
            this.measureError = false;
          }
          else {
            this.measureError = true;
            return;
          }
        }
        else {
          this.measureError = true;
          if (this.Error == "Please fill in all the required fields.") {
            this.Error = "";
          }
          return;
        }
      }
    }
  }

  showsource(): void {

    this.popup_source = !this.popup_source;
  }

  onsourceChange(SourceName: string): void {
    this.Selected_Source.push(SourceName);

  }

  AddSelectedSource(): void {

    for (var i = 0; i < this.Selected_Source.length; i++) {
      this.isexists = false;
      if (this.SourceType.length == 0)
        this.SourceType.push(this.Selected_Source[i]);
      else {
        for (var j = 0; j < this.SourceType.length; j++) {
          if (this.Selected_Source[i] == this.SourceType[j]) {
            this.isexists = true;
            break;
          }

        }
        if (this.isexists != true)
          this.SourceType.push(this.Selected_Source[i]);
      }
    }
  }

  RemoveSource(SourceName: string): void {

    var index = this.SourceType.indexOf(SourceName, 0);
    var i = this.Selected_Source.indexOf(SourceName, 0);
    if (index > -1) {
      this.SourceType.splice(index, 1);
      this.Selected_Source.splice(i, 1);
    }
  }

  saveSource(): void {

    for (var i = 0; i < this.SourceType.length; i++) {
      if (i == 0)
        this.Sourcibility = this.SourceType[0];
      else this.Sourcibility = this.Sourcibility + ", " + this.SourceType[i];
    }
    this.showsource();


  }

  /*
   * ngOnInit
   * constructor
   * submitForm - not needed
   *   getSourceList
   *   showsource
   *   onsourceChange
   *   AddSelectedSource
   *   RemoveSource
   *   saveSource
   *   fillsource
   *   AddMeasure
   *   ClosePopup
   *   EditMeasureClick
   * */
}
