import { Location } from "@angular/common";
import { SessionStorage } from '../../core/context/sessionstorage';

export class AppConstantsDescription {
  public static WelcomePageDescription = 'an intelligent automation platform that augments human technologists with virtual agents powered by artificial intelligence. You can talk and interact with these agents like any other member of your team and they are always available to guide you through the platform.';
  public static ThemeEnable = 'N';
  public static GTOP = 'GTOP';
  public static TechnologyInvolvedService = 'Technology';
};

export class TileConstants {
  public static InternalTiles = ["CT", "PD", "NNS", "EDD", "ED", "CPF", "SEPF", "DD", "CMS", "TMS", "OPPOR", "Initiative", "PA", "MR", "MRN"
    , "CMMI", "MMI", "MDP", "EAC", "DEP", "OPR", "Initi","TC", "DC"];

  public static InternalTilesWithoutE2E = ["OPPOR", "Initiative", "ED", "CRM", "CT", "MR", "MRN", "OPR", "Initi", "DC", "TC"];

  public static HRRow = ['AIAM', 'BCM', 'CMMI', 'IC', 'AVPR', 'AVPRE', 'MRN', 'ODS', 'AIME', 'MUPLOAD', 'AIAM-ATR', 'ICIDB', 'ICISM', 'ASA', 'MRI', 'AOH', 'SSP', 'AOHAD'];
  public static HRRowDescription = ['AIAM', 'ADOP', 'BCM', 'CMMI', 'IC', 'AVPR', 'AVPRE', 'MRN', 'ODS', 'AIME', 'MUPLOAD', 'AIAM-ATR', 'ICIDB', 'ICISM', 'ASA', 'MRI', 'AOH', 'SSP', 'AOHAD'];
  public static ProfileTiles = ['profile', 'scope', 'keycontacts', 'clientstructure', 'teamstructure', 'matrixstructure'];
  public static ConfigTiles = ['metricselection', 'metricthreshold', 'otherconfigsettings'];
}
export class ProfileConstants {
  public static DisableTabMessage = "Profile, Scope, Key contacts & Migrations are applicable at EndToEnd and hence not available for Client Structure and Team Structure";

}

export class ImagePath {
  public static ViewerImage = '/assets/images/icon_dashboard_read.png';
  public static EditorImage = '/assets/images/narrative_edit.png';
  public static ADImage = '/assets/toolkit/images/banner/workstream.svg';
  public static AMImage = '/assets/toolkit/images/banner/workstream.svg';
  public static AgileImage = '/assets/toolkit/images/banner/workstream.svg';
  public static InfrastructureImage = '/assets/toolkit/images/banner/workstream.svg';
  public static OperationsImage = '/assets/toolkit/images/banner/workstream.svg';
  public static SecurityImage = '/assets/toolkit/images/banner/workstream.svg';
}

export class authServices {
  constructor(private sessionStorage: SessionStorage,
    private location: Location) { }
  public static accessTokenKey = 'fsToken';
  public static IsLocal = false;

}

export class SecurityDeliveryFunctionConstants {
  public static SecurityDeliveryFunctions = ["Transformation Services", "Strategy Services", "Industry Services"];
}


