import { Component } from '@angular/core'
import { TileService } from '../../core/services/tile.service';
import { enumArea } from '../../core/context/enum';
import { SessionStorage } from '../../core/context/sessionstorage';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadCastService } from '../../core/services/broadcast.service';
@Component({

  templateUrl: './admin.component.html',
  providers: [TileService]
})

export class AdminComponent {

  tiles: any;

  constructor(private tileService: TileService, private sessionStorage: SessionStorage, private activatedRoute: ActivatedRoute, private router: Router,
    private broadCastService: BroadCastService  ) { }

  ngOnInit() {
    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['welcome']);
      return false;
    }
    this.tileService.getTiles(enumArea.Admin).subscribe((response) => {
      this.tiles = response;
    });
    this.broadCastService.broadcastScopeSelector({
      isScopeSelectorVisible: false, isScopeSelectorDropDownVisible: false,
      isSetAsDefaultVisible: false
    });
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
}
