<!--<form name="deliveryexcellenceprinciplesform" #deliveryexcellenceprinciplesform="ngForm" novalidate (ngSubmit)="SaveDex(deliveryexcellenceprinciplesform.valid,deliveryexcellenceprinciplesform)" id="dexprinciples">
  <div class="row page-title" style="margin-top:20px;">
    <div class="col-xs-12 col-md-7">
      <h1>
        <a href="javascript:void(0)" class="back-link" (click)="OnBackClick(deliveryexcellenceprinciplesform)"><span class="glyphicon glyphicon-menu-left"></span>Back</a>
        Delivery Excellence Principles
      </h1>
    </div>
  </div>


  <div class="table-responsive">
    <p id="Deliveryexcellenceprinciples_Info" class="screenReaderOnly"> This table lists Overall Managed Delivery Adherence in the DeliveryPhase  </p>
    <table class="table table-striped table-hover" aria-describedby="Deliveryexcellenceprinciples_Info">
      <thead>
        <tr>
          <th scope="col" style="width:40%">1. {{sectionName}}</th>
          <th style="width:10%" scope="col" id="Guidelines">Guidelines</th>
          <th style="width:25%;padding-left:1.9%" scope="col" id="Status_Info">Status</th>
          <th style="width:25%" scope="col" id="Rationale">Rationale</th>
        </tr>
      </thead>
      <tbody *ngIf="section1ID==1">
        <tr *ngFor="let data of deliveryExcellencePrinciples.DexData; let i=index; trackBy: trackByDexQuestionId;">
          <td *ngIf="data.SectionId==1"><span [innerHTML]="dexQuestion"></span></td>
          <td style="width:10%" *ngIf="data.SectionId==1">
            <img src="./assets/images/Guide.png" class="dex-guide" alt="Guidelines" (click)="ManagedDeliveryPopup(data.DexQuestionId)" aria-labelledby="" aria-label="GuideLines(Open a popup)" title="GuideLines" />
          </td>
          <td style="width:25%" *ngIf="data.SectionId==1">
            <div class="form-group">
              <div class="col-xs-12 col-sm-7 col-lg-12">
                <select id="dexOverallType"
                        name="dexOverallType"
                        #dexOverallType="ngModel"
                        [(ngModel)]="data.DexAnswerId"
                        [disabled]="1==1"
                        *ngIf="data.SectionId==1"
                        class="form-control" style="border-radius: 0px 4px 4px 0px !important;">
                  <option value="">--Select--</option>
                  <option *ngFor="let mission of data.DexAnswers; trackBy: trackById;" role="option" value="{{mission.Id}}">{{mission.Name}}</option>
                </select>
              </div>
            </div>
          </td>
          <td style="width:25%" *ngIf="data.SectionId==1">
            <textarea name="Rationale" maxlength="2000" aria-labelledby="Rationale" [disabled]="roleAccess=='V'" rows="2" [(ngModel)]="data.DexRationalComment" class="form-control text-box-view" placeholder="Max char limit 2000"></textarea>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <p><strong>Further information on  Managed Delivery can be found on the </strong> <a href="https://avanade.sharepoint.com/sites/AvanadeManagedDelivery" target="_blank" class="link" aria-label="Click here for Further information on the Managed Delivery(opens a new window)"> Avanade MD Intranet site </a><strong> and </strong><a href="https://avanade.sharepoint.com/sites/policies/Policies2/Managed%20Delivery%20Usage/5005_ManagedDeliveryUsage.pdf" target="_blank" class="link" aria-label="Click here to Policy Link(opens a new window)"> Policy Link </a></p>
          </td>
          <td colspan="2"></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <div>

    </div>
  </div>

  <div class="table-responsive myw-table-fixed" style="max-height:380px !important">
    <p id="SpecificDeliveryPrinciple_Info" class="screenReaderOnly"> This table lists Specific Delivery principle Adherence in the DeliveryPhase  </p>
    <table class="table table-striped table-hover " aria-describedby="SpecificDeliveryPrinciple_Info">
      <thead>
        <tr>
          <th scope="col" style="width: 40%">2. {{section2Name}}</th>
          <th style="width:10%" scope="col" id="Guidelines_Info">Guidelines</th>
          <th style="width:25%;padding-left:1.9%" scope="col" id="Response_Info">Response</th>
          <th style="width:25%" scope="col" id="Rationale_Info">Rationale</th>
        </tr>
      </thead>
      <tbody *ngIf="section2ID==2">
        <ng-container *ngFor="let data of deliveryExcellencePrinciples.DexData;let i = index; trackBy: trackByDexQuestionId;">
          <tr *ngIf="data.SectionId==2">
            <td>
              <span [innerHTML]="data.DexQuestionName"></span>
            </td>
            <td style="width:10%">
              <img src="./assets/images/Guide.png" aria-labelledby="Guidelines_Info" class="dex-guide" alt="Guidelines" (click)="ManagedDeliveryPopup(data.DexQuestionId)" aria-label="GuideLines(open a Popup)" title="GuideLines" />
            </td>
            <td style="width:25%">
              <div class="form-group">
                <div class="col-xs-12 col-sm-7 col-lg-12">
                  <select name="dextype-{{i}}"
                          #dextype="ngModel"
                          [disabled]="roleAccess=='V'"
                          class="form-control cursor"
                          [(ngModel)]="data.DexAnswerId"
                          *ngIf="data.SectionId==2"
                          [ngClass]="{'has-error':deliveryexcellenceprinciplesform.submitted && (data.DexAnswerId ==null|| data.DexAnswerId ==0)}"
                          (ngModelChange)="ChangeDexAnswers($event);">
                    <option value="">--Select--</option>
                    <option *ngFor="let mission of data.DexAnswers; trackBy: trackById;" role="option" value="{{mission.Id}}">{{mission.Name}}</option>
                  </select>
                  <div id="dextypeerrormessage-{{i}}" name="dextypeerrormessage-{{i}}" aria-live="polite" aria-atomic="true" role="status" class="form-errormessage" [ngClass]="{'is-error': deliveryexcellenceprinciplesform.submitted && (data.DexAnswerId ==null||data.DexAnswerId==0)}">Please select Dex Answer</div>
                </div>
              </div>
            </td>
            <td style="width:25%">
              <textarea name="RationaleTest-{{i}}" #RationaleTest="ngModel" [ngClass]="{'has-error':deliveryexcellenceprinciplesform.submitted && data.DexAnswerId == 10 && !data.DexRationalComment}" maxlength="2000" [disabled]="roleAccess=='V'" rows="2" [(ngModel)]="data.DexRationalComment" class="form-control text-box-view" placeholder="Max char limit 2000"></textarea>
              <div id="RationaleTesterrormessage-{{i}}" name="RationaleTesterrormessage-{{i}}" aria-live="polite" aria-atomic="true" role="status" class="form-errormessage" [ngClass]="{'is-error':deliveryexcellenceprinciplesform.submitted && data.DexAnswerId == 10 && !data.DexRationalComment}">Please enter Rationale</div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="row">
    <div class="col-md-6 col-lg-6 pull-left text-left">
      <span style="font-weight:bold" class="small">Updated By: </span><span class="small">{{lastModifiedBy}}</span><br>
      <span style="font-weight:bold" class="small">Updated On: </span><span class="small">{{lastModifiedOn|date:'dd MMM yyyy h:mm a'}}{{lastModifiedOn == null ? "" : " (GMT)"}}</span>
    </div>
    <div class="col-md-6 col-lg-6 text-right">
      <button class="btn btn-primary" type="submit" value="submit" [disabled]="roleAccess=='V'" aria-label="Save">Save</button>
      <button class="btn btn-primary" type="button" [disabled]="roleAccess=='V'" (click)="cancel()" aria-label="Cancel">Cancel</button>
    </div>
  </div>

  <div class="modal" id="Question" data-backdrop="static" data-keyboard="false" [ngStyle]="{'display': mdlOpen ? 'block' : 'none', 'opacity': 1}">
    <div class="modal-dialog-wrap">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" id="Close_Guidelines" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Guidelines</h4>
          </div>
          <div class="modal-body dex-modal-height" *ngIf="questionId == 1">
            In order to auto-populate overall Managed Delivery adherence, all six criteria listed below must be satisfied (or with approved Waiver if not applicable).
          </div>
          <div class="modal-body dex-modal-height" *ngIf="questionId == 2">
            <b>Delivery Lead Certification</b>
            <br style="line-height:10px">A Certified Delivery Lead works as part of the Client account team with responsibility for leading the delivery of the Avanade work.  The Delivery Lead is empowered to make decisions on behalf of Avanade and is the accountable representative for delivering the Avanade component of the contract.
            <br /><br /><b>Detailed Checklist Guidelines:</b>
            <br /><br />The Certification requirements for the Delivery Lead can include one of the following
            <br /><br />&nbsp;&nbsp;• Certified SI/IO/AO/TC Delivery Lead from Accenture (at any level) for all engagements
            <br /><br />&nbsp;&nbsp;• Avanade DMA Certifications apply through FY21
            <br /><br />&nbsp;&nbsp;• Service Delivery DMA Manager for Service Delivery engagements
            <br /><br />&nbsp;&nbsp;• Avanade Agile Delivery Lead for Agile engagements
          </div>
          <div class="modal-body dex-modal-height" *ngIf="questionId == 3">
            <b>Mobilization</b>
            <br style="line-height:10px">A Mobilization Toolkit or Starter Kit is completed and saved to the defined central location after validation by either Delivery Executive and/or QAD
            <br /><br /><b>Detailed Checklist Guidelines:</b>
            <br /><br /><a class="link" target="_blank" href="https://avanade.sharepoint.com/sites/marketplace/pages/assetdetails.aspx?asseturl=https%3a//avanade.sharepoint.com/sites/Knowledge/Contributions/c2b921f3-1cfd-4ce5-afd3-7271c673a1a8">Delivery Leads (Onshore & Offshore) are to use the Delivery Mobilization Toolkit, which has two key tasks that articulate mobilization considerations:</a>
            <br /><br />• <b>Sales to Delivery Handover</b> tab as the basis of the attendees, meetings and discussions of the transition from the Solutioning Team (including Solution Architect, BD/CE, MU Lead, Legal etc.) to the Delivery Lead & Delivery Team
            <br /><br />• <b>Project Mobilization</b> tab as the basis for the 'Plan and Mobilize' activities. The Delivery Lead should review every line item to make sure there are no gaps in the transition and all mobilization activities are completed. 
            <br /><br />The primary goal of the Toolkit is to make sure the Delivery Lead has the knowledge and completed the mobilization activities to set the project up for success. The actions must be built into the Delivery Plan and appropriate knowledge shared with the rest of the team.
          </div>
          <div class="modal-body dex-modal-height" *ngIf="questionId == 4">
            <b>Standardization</b>
            <br /> Depending on the type of engagement there are different work products that must always be produced. While most work products are optional the following sections list the work products that are REQUIRED to be produced for Systems Integration and Managed Services engagements. As with other work products the format can vary but the production of these work products is mandatory.
            <br /><br />
            <div>
              <table class="table table-condensed table-bordered">
                <thead>
                  <tr>
                    <th id="Category_Info">Category</th>
                    <th id="SystemIntegrationDeliveryEngagements_Info">System Integration Delivery Engagements</th>
                    <th id="ManagedServiceEngagements_Info">Managed Service Engagements *</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="3" style="padding-top:34px">Solution Planning Work Products</td>
                    <td>
                      <span><a href="https://adf.avanade.com/display/ADF/Solution+Plan" class="link" target="_blank" aria-label="Click here for Solution Plan(opens a new window)">Solution Plan</a></span>
                    </td>
                    <td><span>Solution Plan</span></td>
                  </tr>
                  <tr>
                    <td><span><a href="https://adf.avanade.com/display/ADF/Client+Expectations" class="link" target="_blank" aria-label="Click here for Client Expectations(opens a new window)">Client Expectations</a></span></td>
                    <td><span>Client Expectations</span></td>
                  </tr>
                  <tr>
                    <td><span><a href="https://adf.avanade.com/display/ADF/Project+Scope+Definition" class="link" target="_blank" aria-label="Click here for Project Scope Definition(opens a new window)">Project Scope Definition</a></span></td>
                    <td><span>Project Scope Definition</span></td>
                  </tr>
                  <tr>
                    <td rowspan="3" style="padding-top:34px">Management Plan Work Products</td>
                    <td><span><a href="https://adf.avanade.com/display/ADF/Management+Plan" class="link" target="_blank" aria-label="Click here for Management Plan(opens a new window)">Management Plan</a></span></td>
                    <td><span>Management Plan</span></td>
                  </tr>
                  <tr>
                    <td><span><a href="https://adf.avanade.com/display/ADF/Client+Data+Protection+Risk+Assessment" class="link" target="_blank" aria-label="Click here for Client Data Protection Risk Assessment(opens a new window)">Client Data Protection Risk Assessment</a></span></td>
                    <td><span>Client Data Protection Risk Assessment</span></td>
                  </tr>
                  <tr>
                    <td><span><a href="https://adf.avanade.com/display/ADF/Client+Data+Protection+Plan" class="link" target="_blank" aria-label="Click here for Client Data Protection Plan(opens a new window)">Client Data Protection Plan</a></span></td>
                    <td><span>Client Data Protection Plan</span></td>
                  </tr>
                  <tr>
                    <td rowspan="4" style="padding-top:50px">Delivery Plan Work Products</td>
                    <td><span><a href="https://adf.avanade.com/display/ADF/Product+Backlog" class="link" target="_blank" aria-label="Click here for Product Backlog(opens a new window)">Product Backlog</a> **</span></td>
                    <td><span></span></td>
                  </tr>
                  <tr>
                    <td><span><a href="https://adf.avanade.com/display/ADF/Deliverable+Responsibility+Matrix" class="link" target="_blank" aria-label="Click here for Deliverable Responsibility Matrix(opens a new window)">Deliverable Responsibility Matrix</a></span></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><span><a href="https://adf.avanade.com/display/ADF/RAID+Log" class="link" target="_blank" aria-label="Click here for RAID Log(opens a new window)">RAID Log</a></span></td>
                    <td><span>RAID Log ***</span></td>
                  </tr>
                  <tr>
                    <td><span><a href="https://adf.avanade.com/display/ADF/Status+Report" class="link" target="_blank" aria-label="Click here for Project Scope Definition(opens a new window)">Status Report</a></span></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br /><i>** The Product Backlog combines a work plan and requirement traceability matrix using delivery tooling such as Azure DevOps. If you are using alternative tools you must ensure you have both a Work Plan and a method of tracking requirements through to the delivered solution.</i>
            <br /><br />For more information, visit <a href="https://adf.avanade.com/display/ADF/Mandatory+Work+Products" class="link" target="_blank" aria-label="Click here for ADF(opens a new window)">ADF</a>.
          </div>
          <div class="modal-body dex-modal-height" *ngIf="questionId == 5">
            <b> Delivery Metrics produced</b>
            <br /> Delivery Leads demonstrate track and report progress to plan effectively to stakeholders (client and Avanade) for example traditional waterfall with SPI/CPI; Managed Services with SLAs to contract; Agile with burndown. It is recommended to add the Metrics in myWizard to communicate to Avanade stakeholders. Metrics will be validated by QAD.
            <br /><br />The list below is a recommended set of Avanade Delivery metrics. Delivery Leads may choose to track and report metrics that are applicable to them from a contractual point of view.
            <br /><br />More information on this can be found in <a href="https://avanade.sharepoint.com/sites/DeliveryHub/SitePages/myWizard-Metrics.aspx" class="link" target="_blank" aria-label="Click here for Avanade Delivery Metrics(opens a new window)">Avanade Delivery Metrics </a>page.
            <br /><br /><b><u>Detailed Checklist Guidelines:</u></b>
            <br /><br /><b>1. Key Avanade E2E Delivery Metrics i.e. Cost Performance Index (CPI), Schedule Performance Index (SPI), End Date Variance & Variance at Completion%</b>
            <br /> The metrics are calculated correctly (using myWizard) against the agreed baseline estimate and plan and are compared to agreed tolerances and limits. Where the metrics are outside of agreed tolerances (e.g. +/- 10% for CPI and SPI) corrective actions have been identified and are being tracked to closure.
            <br /><br /><b> 2. Key Avanade Service Delivery Metrics i.e.  Percentage SLAs Met</b>
            <br /> At a minimum, the Contract correctly calculates (using myWizard) Key Avanade Service Delivery Metrics. Where the metrics are outside of agreed tolerances corrective actions have been identified and are being tracked to closure. The Contract should understand Service Level performance Percentage SLAs Met) along with key operational metrics as applicable.
            <br /><br /><b>3. Key Avanade Agile Delivery Metrics i.e.  Delivered Product Quality Index, Release Burnchart Performance Indicator, Story Point Effort Performance Indicator, Agile Release Backlog Volatility - Must-Have Priority, Running Tested Features - Release, Release Slippage Risk Indicator_Velocity</b>
            <br /> <br />The metrics are calculated correctly (using myWizard) against the agreed baseline estimate and plan and are compared to agreed tolerances and limits. Where the metrics are outside of agreed tolerances corrective actions have been identified and are being tracked to closure. For more details, see the myWizard information <a href="https://avanade.sharepoint.com/sites/DeliveryHub/SitePages/myWizard.aspx" class="link" target="_blank" aria-label="Click here for site(opens a new window)">site</a>.

          </div>
          <div class="modal-body dex-modal-height" *ngIf="questionId == 7">
            <b>Financial Ownership</b>
            <br />The Avanade Delivery Lead is accountable for proactively managing to the cost estimate for the Avanade work and understand the financial goals, constraints, and performance of the end-to-end Contract.
            <br /> <br /><b><u> Detailed Checklist Guidelines:</u></b>
            <br /> <br /><b>1. Proactive Cost Management</b>
            <br />The Avanade Delivery Lead proactively manages the cost estimate of the Avanade work. This includes active EAC management for the Avanade component of the work and providing appropriate information for input to MME / SAP.
            <br /> <br /><b>2. Delivery Leads Financial Understanding</b>
            <br />Avanade Delivery Lead understand the financial goals, constraints, and key payment milestones. This includes understanding of the contract type, key terms and conditions, payment milestones and penalties.
            <br /><br /><b>3. Current Financial Status</b>
            <br />Avanade Delivery Lead understands the current Contract Controllable Income (CCI) position of the Contract and how this relates to the target predicted CCI.
            <br /><br /><b>4. Cost-to-serve Optimization</b>
            <br />The Avanade Delivery Lead should optimize the overall cost-to-serve by using an appropriate mix of delivery locations and workforces balanced against an appropriate level of delivery risk. See ADF process of <a href="https://adf.avanade.com/display/ADF/Establish+Financial+Management" class="link" target="_blank" aria-label="Click here for establishing Financial Management(opens a new window)">establishing Financial Management</a> for details.
          </div>
          <div class="modal-body dex-modal-height" *ngIf="questionId == 8">
            <p style="text-align:center"><b>Note: Internal Status Reporting of Narrative Status & Metrics to be reported at least once a month in myWizard</b></p>
            <br /><b>Status Reporting</b>
            <br />The Avanade Delivery Leads complete accurate and comprehensive standardized status reports every period, which includes close management of all delivery risks and issues. These reports are sent to the Client and the relevant Avanade and ATC delivery portfolio management groups, with proactive escalation as appropriate.
            <br /> <br /><b><u>Detailed Checklist Guidelines:</u></b>
            <br /><br /><b>1. Contract Status Reporting</b>
            <br />The Avanade Delivery Lead completes an accurate and comprehensive standardized Contract status report every period that will be provided to Contract Leadership (Client Lead, Client etc.). As a minimum it should include:
            <br />a) An overall management summary covering overall RAG status, status summary, achievements this period, planned next period
            <br />b) All open management issues and risks (including actions)
            <br />c) Key E2E metrics traceable to the original budget and change requests
            <br />Includes appropriate internal reporting in myWizard for Narrative Status, Metrics and Delivery Compliance. See metrics guidance for details.
            <br /><br /><b>2. Proactive Issue and Risk Management</b>
            <br />The Contract status report is sent to the Client Lead and forms the basis of regular status meetings with the Contract leadership team. The Contract status report includes all management issues and risks and maintains an audit trail of all agreed actions and outcomes.
            <br /><i>Related ADF Deliverables: <a href="https://adf.avanade.com/display/ADF/Risk+Management+Plan" class="link" target="_blank" aria-label="Click here for Risk Management Plan(opens a new window)">Risk Management Plan</a>,  and <a href="https://adf.avanade.com/display/ADF/RAID+Log" class="link" target="_blank" aria-label="Click here for RAID Log.(opens a new window)">RAID Log</a>.</i>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Cancel" (click)="closeModal()">Close</button>
          </div>
        </div>
        <span tabindex="0" onfocus="document.getElementById('Close_Guidelines').focus();"> </span>
      </div>
    </div>
  </div>
</form>-->

<form name="deliveryexcellenceprinciplesform" #deliveryexcellenceprinciplesform="ngForm" novalidate (ngSubmit)="SaveDex(deliveryexcellenceprinciplesform.valid,deliveryexcellenceprinciplesform)" id="dexprinciples">
  <div class="app-wrapper app-index">
    <div class="app-wrap">
      <!-- body -->
      <div class="c-delivery-excellence">
        <div class="app-container">
          <div class="c-myWizard__header">
            <div class="c-myWizard__header-content-left">
              <div class="c-myWizard__header-content-head">
                <a href="javascript:;" class="icon-banner-arrow" role="button" alt="Back" (click)="OnBackClick(deliveryexcellenceprinciplesform)" aria-label="Back"></a>
                <div class="-header">Delivery Excellence Principles</div>
              </div>
            </div>
          </div>
          <div id="deliveryAccordion">
            <div class="c-accordion -open">
              <div class="c-scope-accordion-header">
                <div class="-header">{{sectionName}}</div>
                <div class="c-scope-accordion-header-collapse">
                  <div class="-text">Know More on <a href="https://avanade.sharepoint.com/sites/AvanadeManagedDelivery" target="_blank" rel="noopener noreferrer" >Avanade MD Intranet</a> site and <a href="https://avanade.sharepoint.com/sites/policies/Policies2/Managed%20Delivery%20Usage/5005_ManagedDeliveryUsage.pdf" target="_blank" >Policy Link</a></div>
                  <button type="button" class="c-collapse" aria-expanded="true" [attr.aria-controls]="'delivery-overall'"></button>
                </div>
              </div>
              <div *ngIf="section1ID==1" id="delivery-overall" class="-collapse -show" data-parentid="deliveryAccordion">
                <ng-container *ngFor="let data of deliveryExcellencePrinciples.DexData; let i=index; trackBy: trackByDexQuestionId;">
                  <div *ngIf="data.SectionId==1" class="c-accordion__container">
                    <div class="c-form-tab">
                      <div class="c-form-tab__wrapper">
                        <div class="c-scrollable-tabs">
                          <div class="c-scrollable-tabs__container" role="tablist">
                            <button class="c-form-tab__item" type="button" role="tab" style="margin-left: -40px;" [attr.aria-controls]="'manage-delivery'" aria-selected="true">{{dexQuestion.substring(6,33)}}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="data.SectionId==1" role="tabpanel" id="manage-delivery" class="-show">
                      <div class="c-plan-content">
                        <div class="c-delivery-title">
                          <div>{{dexQuestion.substring(6,33)}}</div>
                          <p>{{dexQuestion.substring(42,dexQuestion.length)}}</p>
                        </div>
                        <div class="c-myWizard-form__input">
                          <div class="c-myWizard-form__input-area">
                            <div class="c-select-dropdown -disabled" *ngIf="data.SectionId==1">
                              <label for="dexOverallType">Status</label>
                              <search-dropdown id="dexOverallType"
                                               name="dexOverallType"
                                               #dexOverallType="ngModel"
                                               [disableDropdown]="1==1"
                                               [(ngModel)]="data.DexAnswerId"
                                               [options]="data.DexAnswers"
                                               [formname]="deliveryexcellenceprinciplesform"
                                               [selected]="data.DexAnswerId"
                                               [isShow]="true"
                                               [selectText]="'Select'"
                                               [labelInfo]="'Status'" label="'Status'">

                              </search-dropdown>
                            </div>
                          </div>
                          <div class="c-myWizard-form__input-area">
                            <div class="o-input    ">
                              <label for="Rationale">Rationale</label>
                              <input type="text"
                                     class="o-input__input"
                                     placeholder="Enter the Text"
                                     value=""
                                     id="Rationale"
                                     name="Rationale"
                                     maxlength="2000"
                                     aria-labelledby="Rationale"
                                     [disabled]="roleAccess=='V'"
                                     [(ngModel)]="data.DexRationalComment" />
                              <span class="o-text-area__total-count">Max Limit 2000 Characters</span>
                            </div>
                          </div>
                        </div>
                        <div class="c-delivery-guidelines" [ngClass]="{'-show': showGuidelinesflag }">
                          <a href="javascript:;" class="-guidelines" (click)="showGuidelines(showGuidelinesflag)"><img src="../assets/toolkit/images/profile/file.svg" alt="Guidelines" role="presentation" /><span>Guidelines for Managed Delivery Compliance</span></a>
                          <div class="c-delivery-guidelines-details">
                            <p>In order to auto-populate overall Managed Delivery adherence, all six criteria listed below must be satisfied (or with approved Waiver if not applicable).</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="c-accordion">
              <div class="c-scope-accordion-header">
                <div class="-header">{{section2Name}}</div>
                <div class="c-scope-accordion-header-collapse">
                  <button type="button" class="c-collapse" aria-expanded="false" aria-controls="delivery-specific"></button>
                </div>
              </div>
              <div *ngIf="section2ID==2">
                <div id="delivery-specific" class="-collapse" data-parentid="deliveryAccordion">
                  <div class="c-form-tab">
                    <div class="c-form-tab__wrapper">
                      <div class="c-scrollable-tabs" style="padding-left: 0;">
                        <div class="c-scrollable-tabs__container" role="tablist">
                          <ng-container *ngFor="let data of deliveryExcellencePrinciples.DexData;let i = index; trackBy: trackByDexQuestionId;">
                            <button *ngIf="data.SectionId==2" type="button" (click)="viewTabData(data)" class="c-form-tab__item" role="tab" aria-controls="deliverylead" [attr.aria-selected]="data.DexQuestionId == DexQuesId">{{getTabName(data.DexQuestionName)}}</button>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let data of dexTabData;let i = index; trackBy: trackByDexQuestionId;">
                    <div *ngIf="data.SectionId==2" role="tabpanel" class="-show">
                      <div class="c-delivery-title">
                        <div>{{getTabName(data.DexQuestionName)}}</div>
                        <p>{{getQuestion(data.DexQuestionName)}}</p>
                      </div>
                      <div class="c-myWizard-form__input">
                        <div class="c-myWizard-form__input-area">
                          <div class="c-custom-select__wrapper">
                            <label for="dextype-{{i}}">Response</label>
                            <search-dropdown id="dextype-{{i}}"
                                             *ngIf="data.SectionId==2"
                                             name="dextype-{{i}}"
                                             #dextype="ngModel"
                                             [disableDropdown]="roleAccess=='V'"
                                             [(ngModel)]="data.DexAnswerId"
                                             [options]="data.DexAnswers"
                                             [formname]="deliveryexcellenceprinciplesform"
                                             [selectText]="'Select'"
                                             [selected]="data.DexAnswerId"
                                             [required]="true"
                                             [ngClass]="{'has-error':deliveryexcellenceprinciplesform.submitted && (data.DexAnswerId ==null|| data.DexAnswerId ==0)}"
                                             [isShow]="true" [isIdzero]="true"
                                             [labelInfo]="'Response'" label="'Response'"
                                             [errormessage]="'Please select Response'"
                                             (callback)="ChangeDexAnswers($event);">
                            </search-dropdown>
                          </div>
                        </div>
                        <div class="c-myWizard-form__input-area">
                          <div class="o-input    ">
                            <label for="RationaleTest-{{i}}">Rationale</label>
                            <input type="text"
                                   class="o-input__input"
                                   name="RationaleTest-{{i}}"
                                   #RationaleTest="ngModel"
                                   placeholder="Enter the Text"
                                   [ngClass]="{'has-error':deliveryexcellenceprinciplesform.submitted && data.DexAnswerId == 10 && !data.DexRationalComment}"
                                   maxlength="2000"
                                   [disabled]="roleAccess=='V'"
                                   [(ngModel)]="data.DexRationalComment"
                                   [required]="true"
                                   id="RationaleTest-{{i}}" />
                            <span class="o-text-area__total-count">Max Limit 2000 Characters</span>
                            <p id="RationaleTesterrormessage-{{i}}" name="RationaleTesterrormessage-{{i}}" aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':deliveryexcellenceprinciplesform.submitted && data.DexAnswerId == 10 && !data.DexRationalComment}">Please enter Rationale</p>
                          </div>
                        </div>
                      </div>
                      <div class="c-delivery-guidelines" [ngClass]="{'-show' : deliveryLeadFlag}">
                        <a href="javascript:;"  class="-guidelines" (click)="showGuidelinesForSpecificDelivery(deliveryLeadFlag)"><img src="../assets/toolkit/images/profile/file.svg" alt="Guidelines" role="presentation" /><span>Guidelines for {{getGuidelineName(data.DexQuestionName)}}</span></a>
                        <div class="c-delivery-guidelines-details" *ngIf="data.DexQuestionId == 2">
                          <div class="c-delivery-title">
                            <div>Delivery Lead Certification</div>
                            <p>A Certified Delivery Lead works as a part of the Client account team with responsibility for leading the delivery of the Avanade work. The delivery lead is empowered to make decisions on behalf of Avanade and is the accountable representative for delivering the Avanade component of the contract.</p>
                          </div>
                          <div class="c-delivery-title">
                            <div>Detailed Checklist Guidelines:</div>
                            <p>The Certification requirements for the Delivery Lead can include one of the following</p>
                          </div>
                          <ul class="c-myWizard-list">
                            <li><div>Certified SI/IO/AO/TC Delivery Lead from Accenture (at any level) for all engagements</div></li>
                            <li><div>Avanade DMA Certifications apply through FY21</div></li>
                            <li><div>Service Delivery DMA Manager for Service Delivery engagements</div></li>
                            <li><div>Avanade Agile Delivery Lead for Agile engagements</div></li>
                          </ul>
                        </div>
                        <div class="c-delivery-guidelines-details" *ngIf="data.DexQuestionId == 3">
                          <div class="c-delivery-title">
                            <div>Mobilization</div>
                            <p>A Mobilization Toolkit or Starter kit is completed and saved to the defined central location after validation by either Delivery Executive and/or QAD</p>
                          </div>
                          <div class="c-delivery-title">
                            <div>Detailed Checklist Guidelines:</div>
                            <p>Delivery Leads (Onshore & Offshore) are to use the <a target="_blank" rel="noopener noreferrer" href="https://avanade.sharepoint.com/sites/marketplace/pages/assetdetails.aspx?asseturl=https%3a//avanade.sharepoint.com/sites/Knowledge/Contributions/c2b921f3-1cfd-4ce5-afd3-7271c673a1a8">Delivery Mobilization Toolkit</a>, which has two key tasks that articulate mobilization considerations:</p>
                          </div>
                          <ul class="c-myWizard-list">
                            <li><div><span>Sales to Delivery Handover</span> tab as the basis of the attendees, meetings and discussions of the transition from the Solutioning Team (including Solution Architect, BD/CE, MU Lead, Legal etc.) to the Delivery Lead & Delivery Team</div></li>
                            <li><div><span>Project Mobilization</span> tab as the basis for the 'Plan and Mobilize' activities. The Delivery Lead should review every line item to make sure there are no gaps in the transition and all mobilization activities are completed.</div></li>
                          </ul>
                          <p>The primary goal of the Toolkit is to make sure the Delivery Lead has the knowledge and completed the mobilization activities to set the project up for success. The actions must be built into the Delivery Plan and appropriate knowledge shared with the rest of the team.</p>
                        </div>
                        <div class="c-delivery-guidelines-details" *ngIf="data.DexQuestionId == 4">
                          <div class="c-delivery-title">
                            <div>Standardization</div>
                            <p>Depending on the type of engagement there are different work products that must always be produced. While most work products are optional the following sections list the work products that are REQUIRED to be produced for Systems Integration and Managed Services engagements. As with other work products the format can vary but the production of these work products is mandatory.</p>
                          </div>
                          <ul class="c-myWizard-list">
                            <li><div>The Product Backlog combines a work plan and requirement traceability matrix using delivery tooling such as Azure DevOps. If you are using alternative tools you must ensure you have both a Work Plan and a method of tracking requirements through to the delivered solution. For more information, visit <a href="https://adf.avanade.com/display/ADF/Mandatory+Work+Products" target="_blank" rel="noopener noreferrer"> ADF</a></div></li>
                          </ul>
                          <div class="c-table-responsive">
                            <table class="c-table">
                              <thead>
                                <tr>
                                  <th scope="col">Category</th>
                                  <th scope="col">System Integration Delivery Engagements</th>
                                  <th scope="col">Managed Service Engagements</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Solution Planning Work Products</td>
                                  <td><a href="https://adf.avanade.com/display/ADF/Solution+Plan" target="_blank" rel="noopener noreferrer">Solution Plan</a></td>
                                  <td>Solution Plan</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td><a href="https://adf.avanade.com/display/ADF/Client+Expectations" target="_blank" rel="noopener noreferrer" >Client Expectations</a></td>
                                  <td>Client Expectations</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td><a href="https://adf.avanade.com/display/ADF/Project+Scope+Definition" target="_blank" rel="noopener noreferrer" >Project Scope Definition</a></td>
                                  <td>Project Scope Definition</td>
                                </tr>
                                <tr>
                                  <td>Management Plan Work Products</td>
                                  <td><a href="https://adf.avanade.com/display/ADF/Management+Plan" target="_blank" rel="noopener noreferrer">Management Plan</a></td>
                                  <td>Management Plan</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td><a href="https://adf.avanade.com/display/ADF/Client+Data+Protection+Risk+Assessment" target="_blank" rel="noopener noreferrer">Client Data Protection Risk Assessment</a></td>
                                  <td>Client Data Protection Risk Assessment</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td><a href="https://adf.avanade.com/display/ADF/Client+Data+Protection+Plan" target="_blank" rel="noopener noreferrer">Client Data Protection Plan</a></td>
                                  <td>Client Data Protection Plan</td>
                                </tr>
                                <tr>
                                  <td>Delivery Plan Work Products</td>
                                  <td><a href="https://adf.avanade.com/display/ADF/Product+Backlog" target="_blank" rel="noopener noreferrer">Product Backlog<span class="c-asset-sym"></span></a></td>
                                  <!--<td>Product Backlog<span class="c-asset-sym"></span></td>-->
                                  <td>&nbsp;</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td><a href="https://adf.avanade.com/display/ADF/Deliverable+Responsibility+Matrix" target="_blank" rel="noopener noreferrer">Deliverable Responsibility Matrix</a></td>
                                  <td>&nbsp;</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td><a href="https://adf.avanade.com/display/ADF/RAID+Log" target="_blank" rel="noopener noreferrer">RAID Log</a></td>
                                  <td>RAID Log<span class="c-asset-sym"></span></td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td><a href="https://adf.avanade.com/display/ADF/Status+Report" target="_blank" rel="noopener noreferrer">Status Report</a></td>
                                  <td>&nbsp;</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="c-delivery-guidelines-details" *ngIf="data.DexQuestionId == 5">
                          <div class="c-delivery-title">
                            <div>Delivery Metrics produced</div>
                            <p>Delivery Leads demonstrate track and report progress to plan effectively to stakeholders (client and Avanade) for example traditional waterfall with SPI/CPI; Managed Services with SLAs to contract; Agile with burndown. It is recommended to add the Metrics in myWizard to communicate to Avanade stakeholders. Metrics will be validated by QAD</p>
                            <p>The list below is a recommended set of Avanade Delivery metrics. Delivery Leads may choose to track and report metrics that are applicable to them from a contractual point of view. For more information on this can be found in <a href="https://avanade.sharepoint.com/sites/DeliveryHub/SitePages/myWizard-Metrics.aspx" target="_blank" rel="noopener noreferrer" > Avanade Delivery Metrics</a> page.</p>
                          </div>
                          <div class="c-delivery-title"><div>Detailed Checklist Guidelines</div></div>
                          <ul class="c-myWizard-list">
                            <li><div><span>Key Avanade E2E Delivery Metrics i.e. Cost Performance Index (CPI), Schedule Performance Index (SPI), End Date Variance & Variance at Completion% </span> The metrics are calculated correctly (using myWizard) against the agreed baseline estimate and plan and are compared to agreed tolerances and limits. Where the metrics are outside of agreed tolerances (e.g. +/- 10% for CPI and SPI) corrective actions have been identified and are being tracked to closure.</div></li>
                            <li><div><span>Key Avanade Service Delivery Metrics i.e. Percentage SLAs Met </span> At a minimum, the Contract correctly calculates (using myWizard) Key Avanade Service Delivery Metrics. Where the metrics are outside of agreed tolerances corrective actions have been identified and are being tracked to closure. The Contract should understand Service Level performance Percentage SLAs Met along with key operational metrics as applicable.</div></li>
                            <li><div><span>Key Avanade Agile Delivery Metrics i.e. Delivered Product Quality Index, Release Burnchart Performance Indicator, Story Point Effort Performance Indicator, Agile Release Backlog Volatility - Must-Have Priority, Running Tested Features - Release, Release Slippage Risk Indicator_Velocity </span> The metrics are calculated correctly (using myWizard) against the agreed baseline estimate and plan and are compared to agreed tolerances and limits. Where the metrics are outside of agreed tolerances corrective actions have been identified and are being tracked to closure. For more details, see the myWizard information site.</div></li>
                          </ul>
                        </div>
                        <div class="c-delivery-guidelines-details" *ngIf="data.DexQuestionId == 7">
                          <div class="c-delivery-title">
                            <div>Financial Ownership</div>
                            <p>The Avanade Delivery Lead is accountable for proactively managing to the cost estimate for the Avanade work and understand the financial goals, constraints, and performance of the end-to-end Contract.</p>
                          </div>
                          <div class="c-delivery-title"><div>Detailed Checklist Guidelines:</div></div>
                          <ul class="c-myWizard-list">
                            <li><div><span>Proactive Cost Management </span> The Avanade Delivery Lead proactively manages the cost estimate of the Avanade work. This includes active EAC management for the Avanade component of the work and providing appropriate information for input to MME / SAP.</div></li>
                            <li><div><span>Delivery Leads Financial Understanding </span> Avanade Delivery Lead understand the financial goals, constraints, and key payment milestones. This includes understanding of the contract type, key terms and conditions, payment milestones and penalties.</div></li>
                            <li><div><span>Current Financial Status </span> Avanade Delivery Lead understands the current Contract Controllable Income (CCI) position of the Contract and how this relates to the target predicted CCI.</div></li>
                            <li><div><span>Cost-to-serve Optimization </span> The Avanade Delivery Lead should optimize the overall cost-to-serve by using an appropriate mix of delivery locations and workforces balanced against an appropriate level of delivery risk. See ADF process of <a href="https://adf.avanade.com/display/ADF/Establish+Financial+Management" target="_blank" rel="noopener noreferrer"> establishing Financial Management </a> for details.</div></li>
                          </ul>
                        </div>
                        <div class="c-delivery-guidelines-details" *ngIf="data.DexQuestionId == 8">
                          <div class="c-delivery-title">
                            <div>Status Reporting</div>
                            <p>The Avanade Delivery Leads complete accurate and comprehensive standardized status reports every period, which includes close management of all delivery risks and issues. These reports are sent to the Client and the relevant Avanade and ATC delivery portfolio management groups, with proactive escalation as appropriate.</p>
                          </div>
                          <div class="c-delivery-title"><div>Detailed Checklist Guidelines:</div></div>
                          <ul class="c-myWizard-list">
                            <li>
                              <div>
                                <span>Contract Status Reporting </span> The Avanade Delivery Lead completes an accurate and comprehensive standardised Contract status report every period that will be provided to Contract Leadership (Client Lead, Client etc.). As a minimum it should include:
                                <ol class="c-alpha-list">
                                  <li>An overall management summary covering overall RAG status, status summary, achievements this period, planned next period</li>
                                  <li>All open management issues and risks (including actions)</li>
                                  <li>Key E2E metrics traceable to the original budget and change requests</li>
                                </ol>
                                <p>Includes appropriate internal reporting in myWizard for Narrative Status, Metrics and Delivery Compliance. See metrics guidance for details.</p>
                              </div>
                            </li>
                            <li>
                              <div>
                                <span>Proactive Issue and Risk Management </span> The Contract status report is sent to the Client Lead and forms the basis of regular status meetings with the Contract leadership team. The Contract status report includes all management issues and risks and maintains an audit trail of all agreed actions and outcomes.
                                <p>Related ADF Deliverables: <a href="https://adf.avanade.com/display/ADF/Risk+Management+Plan" target="_blank" rel="noopener noreferrer"> Risk Management Plan</a>, and <a href="https://adf.avanade.com/display/ADF/RAID+Log" target="_blank" rel="noopener noreferrer"> RAID Log</a></p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-myWizard-update__container ">
        <div class="app-container">
          <div class="c-myWizard-update__row">
            <div class="c-myWizard-update__details">
              <div class="c-myWizard-update__input">
                <label>Last Updated By:</label>
                <span class="-input">{{lastModifiedBy}}</span>
              </div>
              <div class="c-myWizard-update__input">
                <label>Last Updated On:</label>
                <span class="-input">{{lastModifiedOn|date:'dd MMM yyyy h:mm a'}}{{lastModifiedOn == null ? "" : " (GMT)"}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-select-card">
        <div class="app-container">
          <div class="c-select-card__wrapper">
            <div class="c-select-card__left">
              <div class="c-select-card__text">Changes made will be lost unless confirmed. Please click 'Save' button to confirm.</div>
            </div>
            <div class="c-select-card__right">
              <button class="o-secondary-button" type="button" [disabled]="roleAccess=='V'" (click)="cancel()" role="button" [ngClass]="{'-disabled': roleAccess=='V' }" >Reset</button>
              <button class="o-primary-button" role="button" [disabled]="roleAccess=='V'" [ngClass]="{'-disabled': roleAccess=='V' }" >Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="c-select-card__placeholder"></div>
      <!-- /body -->
    </div>
  </div>
</form>
