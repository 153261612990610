<!--<div class="content-wrap container-fluid">
  <div class="row page-title">
    <div class="col-xs-12">
      <h1>
        <a href="javascript:void(0)" class="back-link" (click)="OnBackClick()"><span class="glyphicon glyphicon-menu-left"></span>Back</a>
        Platform Access
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 form-inline tcc-form">
      <div class="form-group">
        <label style="margin-right: 5px !important;">Select Role: </label>
        <select style="border-radius:1px !important" name="roletype"
                class="form-control"
                [disabled]="disableField"
                [(ngModel)]="selectedRoleMyWizard"
                (change)="ChangeRole($event)">
          <option *ngFor="let mission of roledataMyWizard; trackBy : trackByIndex;" role="option" value="{{mission}}">{{mission}}</option>
        </select>
      </div>

      <div class="form-group">
        <button type="button" class="btn btn-primary" [disabled]="disableAccess() == 0 || (!platformAccess.FactoryIndicator && !platformAccess.MyWizardIndicator && platformAccess.DeliveryTypes.length==0) || disableField" (click)="grantRevokeAccess(true)">Grant Access</button>
        &nbsp;
        <button type="button" class="btn btn-primary" [disabled]="disableAccess() == 0 || (!platformAccess.FactoryIndicator && !platformAccess.MyWizardIndicator && platformAccess.DeliveryTypes.length==0) || disableField" (click)="grantRevokeAccess(false)">Revoke Access</button>
      </div>
    </div>
  </div>
  <div class="tcc-table">
    <table cellspacing="0" class="table table-striped table-hover" fixed-header id="platformaccess">
      <thead>
        <tr>
          <th class="text-nowrap"><input type="checkbox" [(ngModel)]="selectedAll" (click)="checkAll($event)" [disabled]="disableField"/> Select</th>
          <th style="text-align:center">Enterprise ID</th>
          <th style="text-align:center">Resource Name</th>
          <th style="text-align:center">Resource Email</th>
          <th style="text-align:center">Country/Location</th>
          <th style="text-align:center">Role</th>
        </tr>
        <tr class="tcc-table-row" *ngIf="platformAccess && platformAccess.ListPlatformUsers && platformAccess.ListPlatformUsers.length > 0">
          <td>&nbsp;</td>
          <td><input type="text" id="searchTextEntirepriseId" [(ngModel)]="searchTextEntirepriseId" (keyup)="search()" class="form-control" /></td>
          <td><input type="text" id="searchTextResName" [(ngModel)]="searchTextResourceName" (keyup)="searchTextResourceNames()" class="form-control" /></td>
          <td><input type="text" id="searchTextResEmail" [(ngModel)]="searchTextEmail" (keyup)="searchTextEmails()" class="form-control" /></td>
          <td><input type="text" id="country" [(ngModel)]="searchTextCountry" (keyup)="searchTextCountrys()" class="form-control" /></td>
          <td><input type="text" id="role" [(ngModel)]="searchTextRole" (keyup)="searchTextRoles()" class="form-control" /></td>
        </tr>
        <tr *ngIf="platformAccess && platformAccess.ListPlatformUsers && platformAccess.ListPlatformUsers.length == 0">
          <td style="width:1300px;text-align:center;border-bottom:1px solid" colspan="7">
            No records found
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of Users; trackBy : trackByIndex;">
          <td><input type="checkbox" [(ngModel)]="user.Selected" (click)="selectUser($event,user)" [disabled]="disableField"/></td>
          <td>{{user.EnterpriseId}}</td>
          <td>{{user.FullName}}</td>
          <td>{{user.EmailID}}</td>
          <td>{{user.Country}}</td>
          <td>{{user.RoleUi}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="tcc-table-pager" *ngIf="Users && Users.length!=0">
    <button class="glyphicon glyphicon-arrow-left" (click)="curPage=curPage-1;paginationClick(curPage)" [disabled]="curPage == 0" [ngClass]="{'disabled-class': curPage == 0}"></button> Page {{curPage + 1}} of {{NoOfPages}}
    <button class="glyphicon glyphicon-arrow-right" (click)="curPage=curPage+1;paginationClick(curPage)" [disabled]="curPage >=  TotalUserscount/pageSize - 1" [ngClass]="{'disabled-class': curPage >=  TotalUserscount/pageSize - 1}"></button>
  </div>
  <br />
  <span class="span-footer">&nbsp;Please be Informed that access to users should be removed Immediately once not in use (Example - In case of Roll Off / Role change)&nbsp;</span>
</div>-->



<div class="app-wrap">
  <div class="c-myWizard-content">
    <div class="app-container c-platform-access">
      <div class="c-myWizard__header">
        <div class="c-myWizard__header-content-left">
          <div class="c-myWizard__header-content-head">
            <a href="javascript:;" class="icon-banner-arrow" role="button" alt="Back" (click)="OnBackClick()" aria-label="Back"></a>
            <div class="-header">Platform Access</div>
          </div>
          <p class="c-myWizard-text"></p>
        </div>
      </div>
      <div class="c-platform-access-title">
        <div class="c-search-container">
          <div class="c-search-icon">
            <a href="javascript:;" role="button"><i class="icon-search" aria-label="search"></i></a>
          </div>
          <input type="text" class="c-search-control" placeholder="Search enterprise ID, etc…" id="searchTextEntirepriseId" [(ngModel)]="searchTextEntirepriseId" (keyup)="search()">
        </div>
        <div class="c-select-dropdown  ">
          <search-dropdown id="roletype" name="roletype"
                           [isShow]="true" #roletype="ngModel"
                           [disableDropdown]="disableField || isViewerRole"
                           [options]="roledataMyWizard"
                           [selected]="selectedRoleMyWizard"
                           [(ngModel)]="selectedRoleMyWizard">
          </search-dropdown>
          <p class="c-select__error-text">Enter the value</p>
        </div>
      </div>
      <div class="c-table-responsive -customScroll">
        <table class="c-table">
          <thead>
            <tr>
              <th scope="col" width="50px">
                <div class="c-checkbox  ">
                  <input type="checkbox"
                         id="check-one"
                         class="c-checkbox__input"
                         name="unitcheckbox" [(ngModel)]="selectedAll" (click)="checkAll($event)" [disabled]="disableField" />
                  <i class="c-checkbox__checked"></i>
                </div>
              </th>
              <th scope="col">Enterprise ID</th>
              <th scope="col">Resource Name</th>
              <th scope="col">Resource Email</th>
              <th scope="col">Country/Location</th>
              <th scope="col">Role</th>
            </tr>
          </thead>
          <tbody>
            <!--<tr *ngIf="!Users || Users.length==0">-->
            <tr *ngIf="platformAccess && platformAccess.ListPlatformUsers && platformAccess.ListPlatformUsers.length == 0">
              <td colspan="6"> No Records Found </td>
            </tr>
            <tr *ngFor="let user of Users; trackBy : trackByIndex;">
              <td>
                <div class="c-checkbox  ">
                  <input type="checkbox"
                         id="check-one"
                         class="c-checkbox__input"
                         name="unitcheckbox" [(ngModel)]="user.Selected" (click)="selectUser($event,user)" [disabled]="disableField" />
                  <i class="c-checkbox__checked"></i>
                </div>
              </td>
              <td>{{user.EnterpriseId}}</td>
              <td>{{user.FullName}}</td>
              <td>{{user.EmailID}}</td>
              <td>{{user.Country}}</td>
              <td>{{user.RoleUi}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="c-pagination-container" *ngIf="Users && Users.length!=0">
        <nav>
          <ul class="c-pagination_list">
            <li class="c-pagination_arrow">
              <a *ngIf="curPage != 0" href="javascript:;" class="c-pagination_previous"
                 (click)="curPage=curPage-1;paginationClick(curPage)">
                <img src=" ../assets/toolkit/images/pagination-icon.svg" alt="Previous">
              </a>
              <a *ngIf="curPage == 0" href="javascript:;" class="c-pagination_previous" tabindex="-1"
                 (click)="curPage=curPage-1;paginationClick(curPage)"
                 [ngClass]="{'disablebtn': curPage == 0}" [disabled]="curPage == 0">
                <img src=" ../assets/toolkit/images/pagination-icon.svg" alt="Previous">
              </a>
            </li>
            <li class="c-pagination_page-item">
              <a href="javascript:;" class="c-pagination_link">
                <span class="c-pagination_count" [disabled]="curPage == 0">{{curPage + 1}}</span>
                <span class="c-pagination_target" [disabled]="curPage >=  TotalUserscount/pageSize - 1"> <span>of </span>  {{NoOfPages}}</span>
              </a>
            </li>
            <li class="c-pagination_arrow">
              <a *ngIf="curPage <  TotalUserscount/pageSize - 1" href="javascript:;" class="c-pagination_next"
                 (click)="curPage=curPage+1;paginationClick(curPage)">
                <img src=" ../assets/toolkit/images/pagination-icon.svg" alt="Next">
              </a>
              <a *ngIf="curPage >=  TotalUserscount/pageSize - 1" href="javascript:;" class="c-pagination_next" tabindex="-1"
                 (click)="curPage=curPage+1;paginationClick(curPage)" [ngClass]="{'disablebtn': curPage >=  TotalUserscount/pageSize - 1}"
                 [disabled]="curPage >=  TotalUserscount/pageSize - 1">
                <img src=" ../assets/toolkit/images/pagination-icon.svg" alt="Next">
              </a>
            </li>
          </ul>
        </nav>
        <div class="position-relative">
          <label class="sr-only">Page Selection</label>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="c-select-card -fixed">
    <div class="container">
      <div class="c-select-card__wrapper">
        <div class="c-select-card__left">
          <div class="c-select-card__text">Are you sure you want to Revoke/Grant the access</div>
        </div>
        <div class="c-select-card__right">
          <button href="javascript:void(0)" class="o-secondary-button" role="button" [ngClass]="disableAccess() == 0 || (!platformAccess.FactoryIndicator && !platformAccess.MyWizardIndicator && platformAccess.DeliveryTypes.length==0) || disableField ? '-disabled': ''" (click)="grantRevokeAccess(false)">Revoke Access</button>
          <button href="javascript:void(0)" class="o-primary-button " role="button" [ngClass]="disableAccess() == 0 || (!platformAccess.FactoryIndicator && !platformAccess.MyWizardIndicator && platformAccess.DeliveryTypes.length==0) || disableField? '-disabled': ''" (click)="grantRevokeAccess(true)">Grant Access</button>
        </div>
      </div>
    </div>
  </div>
  <div class="c-select-card__placeholder"></div>
