import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  templateUrl: './multi.contract.info.component.html'
})

export class MultiContractInfoComponent {

  constructor(private bsModalRef: BsModalRef) { }
 closeModal() {
    this.bsModalRef.hide();
  }

}
