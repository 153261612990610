<div id="metric-detail" class="c-flyout c-contract__flyout c-metric-flyout  -open" tabindex="-1" aria-hidden="false" aria-labelledby="flyoutTitle" style="visibility:visible">
  <div class="c-flyout__header -client-header">
    <div class="c-flyout-head__title">
      <div class="-flyoutTitle" id="flyoutTitle">Metric Details</div>
    </div>
    <button type="button" class="-dismiss-flyout" aria-label="Close" alt="Close" (click)="closePopup()"></button>
  </div>
  <div class="c-flyout__body -customScroll">
    <div id="accordionParent-contracts">
      <div class="c-accordion -open">
        <button type="button" class="c-collapse" aria-expanded="true" aria-controls="metric-1">Details</button>
        <div id="metric-1" class="-collapse -show" data-parentid="accordionParent-contracts">
          <div class="c-accordion__container">
            <div class="c-myWizard-form__input">
              <div class="c-myWizard-form__input-area" *ngIf="IsCustom==false||Isedit==true">
                <div class="o-input ">
                  <label for="ag_lead">Name</label>
                  <input type="text"
                         class="o-input__input  -disabled" disabled
                         placeholder=""
                         [attr.value]="metricData.Name[0].Name"
                         id="ag_lead" />

                </div>
              </div>
              <div class="c-myWizard-form__input-area" *ngIf="Isedit==false && IsCustom==true">
                <div class="o-input    ">
                  <label for="metric-name">Name </label>
                  <input type="text"
                         class="o-input__input"
                         placeholder="End Metric Name"
                         (change)="onmetricNameChange($event)"
                         maxlength="255"
                         (blur)="ValidateField($event.target.value,'name')"
                         name="city" list="metrics_lst"
                         value="" [(ngModel)]="metricsName"
                         id="metric-name"
                         [ngClass]="metricnameError || metricError?'has-error':''" />
                  <datalist id="metrics_lst">
                    <option *ngFor="let item of metricsSearch" [value]="item.name">{{item.name}}</option>
                  </datalist>
                </div>
                <p class="o-input__error-text" [ngClass]="metricnameError ?'show':''">Enter Metric Name</p>
                <p class="o-input__error-text show" *ngIf="metricError"> Metric with same name already exists. Please enter a new name </p>
              </div>
              <div class="c-myWizard-form__input-area" *ngIf="IsCustom==false||Isedit==true">
                <div class="o-input  ">
                  <label for="ag_lead">Abbreviation</label>
                  <input type="text"
                         class="o-input__input -disabled" disabled
                         placeholder=""
                         [attr.value]="metricData.Abbreviation"
                         id="ag_lead" />
                  <!--<p class="o-input__error-text">Enter the value</p>
      <p class="o-input__char-text">30/30 characters</p>-->
                </div>
              </div>
              <div class="c-myWizard-form__input-area" *ngIf="Isedit==false && IsCustom==true">
                <div class="o-input    ">
                  <label for="metric-abbreviation">Abbreviation</label>
                  <!--(change)="InputValueChanged($event)"-->
                  <input type="text"
                         class="o-input__input"
                         placeholder="Enter Abbreviation"
                         name="abbreviation"
                         maxlength="50"
                         value="" [(ngModel)]="Abbreviation"
                         id="metric-abbreviation" />
                </div>
              </div>
              <div class="c-myWizard-form__input-area -comp-col" *ngIf="IsCustom==false||Isedit==true">
                <div class="o-text-area ">
                  <label for="Solution_Description">Definition</label>
                  <textarea class="o-text-area__input -customScroll "
                            placeholder="" id="Solution_Description" readonly>{{metricData.Definition}}</textarea>
                </div>
              </div>
              <div class="c-myWizard-form__input-area -comp-col" *ngIf="Isedit==false && IsCustom==true">
                <div class="o-text-area">
                  <label for="desp">Definition</label>
                  <!--(change)="InputValueChanged($event)"-->
                  <textarea class="o-text-area__input"
                            name="definition"
                            placeholder="Enter the Text" maxlength="3000"
                            [(ngModel)]="definition"
                            (blur)="ValidateField($event.target.value,'def')"
                            id="desp" style="height: 75px"
                            [ngClass]="DefinationError?'has-error':''">                              
                  </textarea>

                </div>
                <p class="o-input__error-text" [ngClass]="DefinationError ?'show':''">Enter definition</p>
              </div>
            </div>
            <div class="c-myWizard-form__input three-col-input">
              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown  ">
                  <label for="unit">Unit</label>
                  <div class="c-dropdown" id="unit">
                    <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down  -disabled"><span>{{metricData.Unit[0].Name}}</span></a>
                    <div class="c-dropdown-menu">
                      <div class="c-dropdown-body">
                        <a href="javascript:;" role="button" class="c-dropdown-item">{{metricData.Unit[0].Name}}</a>                       
                      </div>
                    </div>
                  </div>
                  <p class="c-select__error-text">Enter the value</p>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown   ">
                  <label for="unit">Reported Against</label>
                  <div class="c-dropdown" id="unit">
                    <multi-selectdropdown name="reportedAgainst"
                                          id="reportedAgainst"
                                          [options]="workstreamTypes==null?[]:workstreamTypes"                                      
                                          [selected]="workstreamTypes"
                                         [(ngModel)]="workstreamTypes"
                                          [ariaLabelledby]="'ReportAgainst'"
                                          [disableDropdown]="(1==1)">
                    </multi-selectdropdown>
                  </div>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown   ">
                  <label for="unit">Delivery Functions</label>
                  <div class="c-dropdown" id="unit">
                    <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down  -disabled" ><span>{{metricData.selectedDeliveryFunctions}}</span></a>
                    <div class="c-dropdown-menu">
                      <div class="c-dropdown-body">
                        <a href="javascript:;" role="button" class="c-dropdown-item">{{metricData.Deliveryfunctions[0].Name}}</a>                    
                      </div>
                    </div>
                  </div>
                  <p class="c-select__error-text">Enter the value</p>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown   ">
                  <label for="unit">Category</label>
                  <div class="c-dropdown" id="unit">
                    <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down -disabled"><span>{{metricData.Category[0].Name}}</span></a>
                    <div class="c-dropdown-menu">
                      <div class="c-dropdown-body">
                        <a href="javascript:;" role="button" class="c-dropdown-item" >{{metricData.Category[0].Name}}</a>
                      </div>
                    </div>
                  </div>
                  <p class="c-select__error-text">Enter the value</p>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown   ">
                  <label for="unit">Threshold Type</label>
                  <div class="c-dropdown" id="unit">
                    <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down  -disabled"><span>{{metricData.Thresholdtype[0].Name}}</span></a>
                    <div class="c-dropdown-menu">
                      <div class="c-dropdown-body">
                        <a href="javascript:;" role="button" class="c-dropdown-item">{{metricData.Thresholdtype[0].Name}}</a>
                      </div>
                    </div>
                  </div>
                  <p class="c-select__error-text">Enter the value</p>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-select-dropdown   ">
                  <label for="unit">Recommended Source</label>
                  <div class="c-dropdown" id="unit">
                    <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down  -disabled"><span>{{metricData.RecommendedSource}}</span></a>
                    <div class="c-dropdown-menu">
                      <div class="c-dropdown-body">
                        <a href="javascript:;" role="button" class="c-dropdown-item">{{metricData.RecommendedSource}}</a>
                      </div>
                    </div>
                  </div>
                  <p class="c-select__error-text">Enter the value</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-accordion">
        <button type="button" class="c-collapse" aria-expanded="false" aria-controls="metric-2">Associated Measure Details
        <div class="-collapse-text"><span>{{metricData.AssociatedMeasures.length}}</span> MEASURES</div></button>
        <div id="metric-2" class="-collapse" data-parentid="accordionParent-contracts">
          <div class="c-accordion__container">
            <div class="c-accordion c-inner-accordion" *ngFor="let assoMeasure of metricData.AssociatedMeasures;let i=index">
              <button type="button" class="c-collapse" aria-expanded="false" [attr.aria-controls]="'metric-child-'+i">{{'Measure Name: '+ assoMeasure.Name }}</button>
              <div [attr.id]="'metric-child-'+i" class="-collapse" data-parentid="metric-2">
                <div class="c-accordion__container">
                  <div class="c-myWizard-form__input">
                    <div class="c-myWizard-form__input-area">
                      <div class="o-input    ">
                        <label for="name">Name</label>
                        <input type="text"
                               class="o-input__input  -disabled" disabled
                               placeholder=""
                               [attr.value]="assoMeasure.Name"
                               id="name" />
                      </div>
                    </div>
                    <div class="c-myWizard-form__input-area">
                      <div class="o-input     ">
                        <label for="abbreviation">Abbreviation</label>
                        <input type="text"
                               class="o-input__input  -disabled" disabled
                               placeholder=""
                               [attr.value]="assoMeasure.MeasureAbbreviation"
                               id="abbreviation" />
                      </div>
                    </div>
                    <div class="c-myWizard-form__input-area -comp-col">
                      <div class="o-text-area   ">
                        <label for="description">Definition</label>
                        <textarea class="o-text-area__input -customScroll "
                                  placeholder="Enter the Text" id="defination" readonly>{{assoMeasure.MeasureDefinition}}</textarea>
                      </div>
                    </div>
                  </div>
                  <div class="c-myWizard-form__input three-col-input">
                    <div class="c-myWizard-form__input-area">
                      <div class="c-select-dropdown    ">
                        <label for="unit-date">Unit</label>
                        <div class="c-dropdown" id="unit-date">
                          <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down  -disabled"><span>{{assoMeasure.Unit[0].Name}}</span></a>
                          <div class="c-dropdown-menu">
                            <div class="c-dropdown-body">
                              <a href="javascript:;" role="button" class="c-dropdown-item">{{assoMeasure.Unit[0].Name}}</a>                       
                            </div>
                          </div>
                        </div>
                        <p class="c-select__error-text">Enter the value</p>
                      </div>
                    </div>
                    <div class="c-myWizard-form__input-area">
                      <div class="c-select-dropdown    ">
                        <label for="Field Check">Field Check</label>
                        <div class="c-dropdown" id="Field Check">
                          <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down -disabled" *ngIf="assoMeasure.FieldCheck.length>0"><span>{{assoMeasure.FieldCheck[0].Name}}</span></a>
                          <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down -disabled" *ngIf="assoMeasure.FieldCheck.length==0"><span>Select</span></a>
                          <div class="c-dropdown-menu">
                            <div class="c-dropdown-body">
                              <a href="javascript:;" role="button" class="c-dropdown-item" data-myw-title="Option 1" data-myw-toggle="tooltip" data-myw-placement="bottom"
                              *ngIf="assoMeasure.FieldCheck.length>0">{{assoMeasure.FieldCheck[0].Name}}</a>
                            </div>
                          </div>
                        </div>
                        <p class="c-select__error-text">Enter the value</p>
                      </div>
                    </div>
                    <div class="c-myWizard-form__input-area">
                      <div class="c-select-dropdown    ">
                        <label for="app-development">Recommended Source</label>
                        <div class="c-dropdown" id="app-development">
                          <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down  -disabled"><span>{{assoMeasure.Source}}</span></a>
                          <div class="c-dropdown-menu">
                            <div class="c-dropdown-body">
                              <a href="javascript:;" role="button" class="c-dropdown-item">{{assoMeasure.Source}}</a>
                            </div>
                          </div>
                        </div>
                        <p class="c-select__error-text">Enter the value</p>
                      </div>
                    </div>
                  </div>
                  <div class="c-metric-header">Associated Metric Details</div>
                  <div class="c-table-responsive c-lead__table">
                    <table class="c-table">
                      <thead>
                        <tr>
                          <th scope="col" width="200px">Metric Name</th>
                          <th scope="col">Definition </th>
                        </tr>
                      </thead>
                      <tbody>
                          <ng-container *ngIf="assoMeasure.AssociatedMetric.length>0">
                            <tr *ngFor="let assoMetric of assoMeasure.AssociatedMetric">
                              <td>{{assoMetric.Name}}</td>
                              <td>{{assoMetric.Definition}}</td>
                            </tr>
                          </ng-container>
                          <tr *ngIf="assoMeasure.AssociatedMetric.length==0">
                            <td colspan="2">No records found</td>
                          </tr>                   
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
      <div class="c-accordion">
        <button type="button" class="c-collapse" aria-expanded="false" aria-controls="metric-3">Formula</button>
        <div id="metric-3" class="-collapse" data-parentid="accordionParent-contracts">
          <div class="c-accordion__container">
            <div class="c-myWizard-form__input">
              <div class="c-myWizard-form__input-area -comp-col">
                <div class="o-text-area  ">
                  <textarea readonly class="o-text-area__input -customScroll"
                            placeholder="" id="Solution_Description">{{metricData.Formula}}</textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="c-flyout__footer">
    <div class="c-key-flyout-btn">
      <a href="javascript:void(0)" role="button" class="o-primary-button " style="margin-right: 16px" *ngIf="Isedit==true && IsCustom==true && accessFlag==false" (click)="editclick()">
        Edit
      </a>
      <a href="javascript:void(0)" role="button" class="o-primary-button -disabled" style="margin-right: 16px" *ngIf="(Isedit==false && IsCustom==true)||(IsCustom==true && accessFlag==true)">
        Edit
      </a>
      <a href="javascript:void(0)" role="button" class="o-primary-button " style="margin-right: 16px" *ngIf="Isedit==false && IsCustom==true&& accessFlag==false" (click)="Savemetric()">
        Save
      </a>
      <a href="javascript:void(0)" role="button" class="o-primary-button -disabled" style="margin-right: 16px" *ngIf="(Isedit==true && IsCustom==true)||(IsCustom==true && accessFlag==true)">
        Save
      </a>
      <a href="javascript:void(0)" role="button" class="o-primary-button " style="margin-right: 16px" *ngIf="IsCustom==true" (click)="closePopup()">
        Cancel
      </a>     
      <!--Primary Button starts from here-->
      <a href="javascript:void(0)" role="button" class="o-primary-button  " *ngIf="IsCustom==false" (click)="closePopup()">
        <!---->
        Ok
      </a>
      <!--Primary Button ends from here-->
    </div>
  </div>
</div>
<div class="c-flyout__overlay"></div>
