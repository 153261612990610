import { Component, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule } from "@angular/forms"
import { Validators } from '@angular/forms';
//import { IDropdownSettings } from 'ng-multiselect-dropdown';
//import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
//import { AddMetricService } from '../../core/services/add.metric.service'
import { ConfigureMetricService } from '../../core/services/configure.metrics.services';
import { CommonModule } from '@angular/common';
import { SessionStorage } from '../../core/context/sessionstorage';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SearchDropDownComponent } from '../../core/components/search.dropdown.component'
import { AdminMeasureComponent } from './measure.add.component';
import { enumMetricDeliveryLevel } from '../../core/context/enum';
import { SharedService } from '../../core/services/shared.service';
var $ = (<any>window).$;
declare var window: any;
@Component({
  selector: 'metric-add',
  templateUrl: './metric.add.edit.component.html',
  providers: [ConfigureMetricService, BsModalService],
})


export class AdminMetricComponent {


  //@Output() _onLoadingStatus = new EventEmitter<any>();
  @Output() _onSaveClick = new EventEmitter<any>();
  accessFlag: boolean;
  Abbreviation: string;
  Abrrivation: any;
  Defination: any;
  IsAddMeasureButton: boolean = false;
  Unit: any = 0;
  RecommendedSource: any[];
  optionsModelFieldCheck: Array<any> = new Array<any>();
  myOptionsFieldCheck: any[];
  addcustom: string = "addcustom";
  addmeasure: string = "addmeasure"
  addMeasure: boolean = false;
  addWSP: boolean = false;
  allMetricDetails: any;
  allMetricNames: Array<any> = new Array<any>();
  allSelectedArray: Array<any> = new Array<any>();
  applicabledelivery: string;
  BracketError: boolean = false;
  CalculatedMetricValue: string;
  CalculatedMetricValueError: boolean = false;
  CalculatedMetricValueTemp: any;
  categoryError: boolean = false;
  CheckFormluleaError: boolean = false;
  count: any;
  countMeasure: number = 0;
  CreateAction: boolean = false;
  CloseMetric: boolean = false;
  DateMeasure: boolean = false;
  DefinationError: boolean = false;
  definition: string;
  DeliveryLevelId: any;
  DeliveryId: any;
  //dropdownSettings: IDropdownSettings = {};
  //dropdownSettingsDisabled: IDropdownSettings = {};
  EnterpriseId: any;
  EnteredFormula: string = '';
  EnteredMeasures: Array<any> = new Array<any>();
  EnteredMeasuresForEdit: Array<any> = new Array<any>();
  Error: any;
  errorMessage: string;
  ErrorMsg: string;
  e2eUId: any;
  existsMeasure: boolean = false;
  existingMetricId: number;
  FinalFormula: string;
  formulaError: boolean = false;
  flag: number = 0;
  FormulaMeasurelist: Array<any> = new Array<any>();
  Frequencys: string;
  isAlertBox: boolean = false;
  IsEditMetric: boolean;
  IsCustom: boolean = false;
  IsCustomFlag: boolean = false;
  isDelete: boolean = true;
  IsEdit: boolean = true;
  isexist: boolean = false;
  isexists: any = false;
  isInfraWS: boolean = false;
  isShowValue: boolean = true;
  isShowValuefn: boolean = true;
  isShowValueFormula: boolean = true;
  IsUpdateFlag: boolean = false;
  LableList: Array<string> = new Array<string>("a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l",
    "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "aa", "ab", "ac", "ad", "ae", "af", "ag", "ah", "ai", "aj",
    "ak", "al", "am", "an", "ao", "ap", "aq", "ar", "as", "at", "au", "av", "aw", "ax", "ay", "az");
  loadingStatus: boolean = false;
  MatchedMeasures: Array<any> = new Array<any>();
  @Input() selectedMeasure: any;
  @Output() _MeasureSave = new EventEmitter<any>();
  measureName: any;
  newMeasureList: any = [];
  MeasureIDlist: Array<string> = [];
  measureError: boolean = false;
  measureNameError: boolean = false;
  MName: any;
  measureId: any;
  MeasureDataForEdit: any[];
  Sfield: any = "";
  FieldcheckVisible: boolean = true;
  SelectedFieldCheck: Array<string> = [];
  AccociateMeasurMetric: Array<string> = [];
  IsEditMeasure: boolean;
  IsAddCustomMeasureButton: boolean = false;
  fieldCheckIds: any = [];
  fieldCheckError: boolean = false;
  alertCss: string = "";
  IsDisable: boolean = false;
  popup_source: boolean = false;
  SourceType: Array<string> = [];
  Sourcibility: string;
  Selected_Source: Array<string> = [];
  measureData: Array<any> = new Array<any>();
  Measure: Array<string> = [];
  Measures: Array<any> = new Array<any>();
  MeasuresForFormula: Array<any> = new Array<any>();
  MeasureNames: any = [];
  MeasureName1: string;
  MeasureSource: Array<any> = [];
  MeasureList: any[];
  AssociatedMeasures: any[] = [];
  Measurevalue: string;
  MeasureSources: Array<any> = new Array<any>();
  popupTitle: string;
  ProcessSave1: Array<any> = new Array<any>();
  metricError: boolean = false;

  metricsName: any = [];
  MetricName: any[];
  MetricNames: any = [];
  metricnameError: boolean = false;
  metricsNameExists: boolean = false;
  metricsSearch: any = [];
  Modified: boolean = false;
  NewArray: Array<any> = new Array<any>();
  noBracketsmissing: number = 0;
  notDateMeasure: boolean = false;
  optionsModel: Array<any> = new Array<any>();
  popup_measure: boolean = false;
  popup_workstreamtype: boolean = false;
  popup_TestFormula: boolean = false;
  popup_process: boolean = false;
  popup_visibility: boolean = false;
  Process: Array<string> = [];
  ProcessID: Array<any> = new Array<any>();
  ProcessCategory: Array<any> = new Array<any>();
  ProcessCategoryfinallist: any[];
  processess: string;
  ProcessList: any[];
  ProcessList1: any = [];
  processError: boolean = false;
  RemoveFormulaMeasure: boolean = false;
  ReportedError: boolean = false;
  sameid: any[];
  selectedCreate: boolean = false;
  @Input()
  selectedAction: any;
  popup: string;
  selected_Action: any;
  Selected_Measure: Array<string> = [];
  Selected_MetricID: number;
  SeletedArray: Array<any> = new Array<any>();
  SelectedCategory: any = 0;
  selectedDeliveryFunction: any = [];
  selectedItems: any = [];
  Selected_Process: Array<string> = [];
  SelectedMeasure: string;
  SelectedMeasureForEdit: any = [];
  SelectedUnit: any = 0;
  Selected_Workstream: Array<string> = [];
  Selected_workstream_Trimmed: Array<string> = [];
  selectedWS: any = [];
  selectedWsId: any = [];
  SelectedThreshold: any = 0;
  selectedList: any = [];
  standardMetricError: boolean = false;
  showModal: any = false;
  showModal1: any = false;
  showRA = true;
  showDF = 1;
  sourcibility: string;
  TestMetricFormulaError: boolean = false;
  thresholdError: boolean = false;
  ThresholdList: any[];
  TotalFormulaCheck: boolean = false;
  underWSModelloop: boolean = false;
  unitError: boolean = false;
  UnitList: any[];
  UTCError: boolean = false;
  workstreamTypes: any = [];

  WorkstreamSave: Array<any> = new Array<any>();
  WorkstreamType: Array<string> = [];
  WorkstreamID: Array<any> = new Array<any>();
  WSName: string;
  WSList1: any = [];
  WSModel: any = [];

  deliveryid: any;
  Id: any = [];
  MSEUnitId: any = [];
  Name: any = [];
  unitList: any = [];
  MetricNameList: any = [];
  selectedMetricId: number;
  selectedUnitId: number;
  selectedWorkstreamId: any = [];
  selectedThresholdId: number;
  selectedMeasureId: number = 0;
  deliveryLevelId: number;
  workstreamTypename: string;
  selectedProcessId: any = [];
  selectedProcessCategoryId: number;
  customMeasureList: any = [];
  selectedCustomMeasureId: number;
  customUnitList: any = [];
  selectedCustomUnitId: number;
  fieldCheckList: any = [];
  selectedFieldCheckId: any = [];
  UnitId: string;
  metricId: number;

  selectedWSTypes: any = [];
  @ViewChild('addcustomForm') addCustomMeasureForm: NgForm;
  @ViewChild('addMeasureForm') addMeasureForm: NgForm;

  measureExist: boolean;


  constructor(private bsModalRef: BsModalRef, private metricService: ConfigureMetricService, private sessionStorage: SessionStorage, private modalService: BsModalService, private sharedService: SharedService) { }

  ngOnInit() {
    setTimeout(() => { 
      window.dropdownComponent();
      window.collapseComponent();
      window.tabsComponent();
      window.TooltipFunc();
    }, 500)
   
    this.showModal = true;
    this.showRA = false;
    this.CreateAction = this.selectedAction == 'Create' ? true : false;
    this.e2eUId = this.sessionStorage.endToEndUId;
    if (this.sessionStorage.teamUId != null && this.sessionStorage.teamUId != '') {
      this.deliveryid = this.sessionStorage.teamUId
    }
    else {
      this.deliveryid = this.sessionStorage.endToEndUId
    }
    if (this.sessionStorage.teamUId != null && this.sessionStorage.teamUId != '') {
      this.deliveryLevelId = enumMetricDeliveryLevel.Team;
    }
    else {
      this.deliveryLevelId = enumMetricDeliveryLevel.E2E;
    }
    if (this.deliveryLevelId == 4) { this.customMetricMaster(this.deliveryid); }
    else { this.customMetricMaster(this.e2eUId); }
    this.getMeasureList(this.deliveryLevelId);
    this.customMeasureMaster();
  }

  // new methods
  customMetricMaster(deliveryid: any): void {
    this.metricService.getCustomMetricMaster(deliveryid).subscribe(
      source => {
        this.MetricNameList = source.MetricName;
        this.loadMetricName(source.MetricName);
        this.selectedMetricId = null;
        this.UnitList = source.Unit;
        this.workstreamTypes = source.WorktstreamType;
        this.ThresholdList = source.ThresholdType.sort((a, b) => {
          if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return -1;
          }
        });;
      },
      error => this.errorMessage = <any>error);
  }

  getMeasureList(deliveryLevelId: any): void {
    console.log('measurelist');
    /*  this.loadingStatus = true;*/
    this.metricService.getMeasureList(this.deliveryLevelId).subscribe(
      source => {
        this.MeasureList = source;
        this.loadMeasureName(source);
        setTimeout(function () {
          window.TooltipFunc();
        }, 0);
        //this.loadingStatus = false;
      },
      error => this.errorMessage = <any>error);
  }

  processAndCategoryDetails(selectedWStypes: any, deliveryid: any): void {
    this.metricService.getProcessAndCategory(selectedWStypes, deliveryid).subscribe(
      source => {
        this.ProcessList = source.BusinessProcess.sort((a, b) => {
          if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return -1;
          }
        }); 
        this.ProcessCategory = source.BusinessProcessCategory.sort((a, b) => {
          if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return -1;
          }
        }); 
      }
    )
  }

  customMeasureMaster(): void {
    this.metricService.getCustomMeasureMaster().subscribe(
      source => {
        this.customMeasureList = source.MeasureName; this.selectedCustomMeasureId = null;
        this.customUnitList = source.Unit; this.selectedCustomUnitId = 0;
      }
    )
  }

  getFieldCheck(UnitId: any): void {
    if (UnitId != null) {
      this.metricService.getFieldCheck(UnitId).subscribe(
        source => {
          this.fieldCheckList = source;
          this.selectedFieldCheckId = [];
        },
        error => this.errorMessage = <any>error);
    }
    else {
      this.fieldCheckList = [];
      this.selectedFieldCheckId = [];
    }
  }

  getMetricDetails(MSEMetricId: number, isEditMode: boolean): void {
    var EndtoEndUID = this.sessionStorage.endToEndUId;
    this.metricService.getSelectedMetricDetails(EndtoEndUID, MSEMetricId, this.deliveryLevelId).subscribe(
      source => {
        this.allMetricDetails = source;
        let sel_reportAgainsts = source.ReportedAgainst.map(x => x.Name).join(',');
        if (sel_reportAgainsts != '' || sel_reportAgainsts != null) {
          this.metricService.getProcessAndCategory(source.ReportedAgainst.map(x => x.Name).join(','), this.e2eUId).subscribe(
            source => {
              this.ProcessList = source.BusinessProcess.sort((a, b) => {
                if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return -1;
          }
        }); 
              this.ProcessCategory = source.BusinessProcessCategory.sort((a, b) => {
                if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
                  return -1;
                }
              }); 
              this.BindEditMetric(this.allMetricDetails, isEditMode);
            }
          )
        }
        else {
          this.BindEditMetric(this.allMetricDetails, isEditMode);
        }
      },
      error => this.errorMessage = <any>error);

  }

  // end
  //getProcessList(WorkstreamtypeName: any, e2eUId: any): void {
  //  this.showDF = 1;
  //  this.metricService.getProcessList(WorkstreamtypeName, e2eUId).subscribe(
  //    source => {
  //      this.ProcessList = source;
  //      this.getProcessList1(source);
  //    });
  //}

  //getProcessList1(Process: any[]) {
  //  //PRB0057521 underWSModelloop added not avoid processlist refill
  //  if (this.ProcessList1.length == 0 && this.underWSModelloop == false) {
  //    for (var i = 0; i < Process.length; i++) {
  //      this.ProcessList1.push({ item_id: Process[i].BusinessProcessId, item_text: Process[i].Name });
  //    }
  //    this.underWSModelloop = true;


  //  }
  //  else {
  //    this.sameid = [];
  //    for (var j = 0; j < this.ProcessList1.length; j++) {
  //      for (var k = 0; k < Process.length; k++) {
  //        this.isexists = false;
  //        if (this.ProcessList1[j].item_text == Process[k].Name) {
  //          this.isexists = true;
  //          //filling only unique process in sameid in case of multiple reported against
  //          this.sameid.push({ item_id: Process[k].BusinessProcessId, item_text: Process[k].Name });
  //        }
  //      }
  //    }
  //    //PRB0057521 removal of duplicate records if any from sameid
  //    if (this.sameid.length != 0) {
  //      this.ProcessList1 = this.sameid.filter(function (value, index, array) { return array.indexOf(value) == index; });
  //      this.optionsModel = [];
  //    }
  //    else {
  //      this.ProcessList1 = [];
  //      this.optionsModel = [];
  //    }

  //  }

  //  //Defecct 999383 Fix  MSE: process: DevOps, is not arranged in alphabetical order when Reported against is selected as Conventional Release
  //  if (this.ProcessList1.length != 0) {
  //    this.ProcessList1.sort(function (a, b) {
  //      var nameA = a.item_text.toLowerCase(), nameB = b.item_text.toLowerCase()
  //      if (nameA < nameB) //sort string ascending
  //        return -1
  //      if (nameA > nameB)
  //        return 1
  //      return 0 //default return value (no sorting)
  //    })
  //  }
  //  //logic for process check or check on select reported against
  //  this.selectedList = [];
  //  for (var k = 0; k < this.ProcessList1.length; k++) {
  //    for (var m = 0; m < this.selectedDeliveryFunction.length; m++) {
  //      var wsa = this.selectedDeliveryFunction[m];
  //      var wsb = this.ProcessList1[k].item_id;
  //      if (wsa == wsb) {
  //        this.optionsModel.push({ item_id: this.ProcessList1[k].item_id, item_text: this.ProcessList1[k].item_text });
  //      }
  //    }
  //  }
  //  //  this.arr = this.selectedList.filter(function (item, index, inputArray) {
  //  //      return inputArray.indexOf(item) == index;
  //  //  });
  //  //  this.optionsModel = [];
  //  //  for (var p = 0; p < this.arr.length; p++) {
  //  //      this.optionsModel.push(this.arr[p]);
  //  //}

  //  this.showDF = 0;

  //}

  //loadCustomMetricName(source: any) {
  //  for (var i = 0; i < source.MetricName.length; i++) {
  //    this.metricsSearch.push({ Name: source[i].MetricName.Name });
  //  }
  //}

  //loadUnitList(source: any) {
  //  for (var i = 0; i < source.length; i++) {
  //    this.unitList.push({ Id: source[i].Unit.Id, Name: source[i].Unit.Name });
  //  }
  //}

  loadMeasureName(source: any) {
    for (var i = 0; i < source.length; i++) {
      if (source[i].Source.Name != 'Synops')
        this.MeasureNames.push({ "Id": source[i].Id, "Name": source[i].Name, "Source": source[i].Source }); //Added Unit for Story: 1121115 -I should be allowed to create custom metrics with date measures
    }
  }

  fillFieldcheck(data: any[]): void {
    for (var i = 0; i < data.length; i++) {
      if (data[i].Name != null) {
        this.fieldCheckList.push({ Id: data[i].Id, Name: data[i].Name })
      }

    };
    this.showRA = true;
  }

  loadMetricName(source: any) {

    for (var i = 0; i < source.length; i++) {
      if (source[i].Id != 12) {
        if (this.selectedAction == "Read") {
          this.MetricNames.push({ "id": source[i].Id, "value": source[i].Name });
          this.metricsSearch.push({ id: source[i].Id, name: source[i].Name });
        }
        else {
          if (source[i].Id != 12) {
            this.MetricNames.push({ "id": source[i].Id, "value": source[i].Name });
            this.metricsSearch.push({ id: source[i].Id, name: source[i].Name });
          }
        }
      }
      //this.MetricNamesAuto.push({ "value": source[i].Name });
      //this.loadingStatus = false;
    }
  }

  //AddSelectedWorkstream(): void {

  //  for (var i = 0; i < this.WSModel.length; i++) {
  //    for (var j = 0; j < this.WSList1.length; j++) {
  //      if (this.WSModel[i] == this.WSList1[j].item_id) {
  //        if (this.WorkstreamType.length == 0) {
  //          this.WorkstreamType.push(this.WSList1[j].item_text);
  //          this.WorkstreamID.push(this.WSList1[j].item_id);
  //        }
  //        else
  //          this.isexists = false;
  //        for (var k = 0; k < this.WorkstreamType.length; k++) {
  //          if (this.WSList1[j].item_text == this.WorkstreamType[k]) {
  //            this.isexists = true;
  //            break;
  //          }
  //        }
  //        if (!this.isexists) {
  //          this.WorkstreamType.push(this.WSList1[j].item_text);
  //          this.WorkstreamID.push(this.WSList1[j].item_id);
  //        }
  //      }
  //    }
  //  }
  //  for (var i = 0; i < this.optionsModel.length; i++) {
  //    for (var j = 0; j < this.ProcessList1.length; j++) {
  //      if (this.optionsModel[i] == this.ProcessList1[j].item_id) {
  //        if (this.Process.length == 0) {
  //          this.Process.push(this.ProcessList1[j].item_text);
  //          this.ProcessID.push(this.ProcessList1[j].item_id);
  //        }
  //        else
  //          this.isexists = false;
  //        for (var k = 0; k < this.Process.length; k++) {
  //          if (this.ProcessList1[j].item_text == this.Process[k]) {
  //            this.isexists = true;
  //            break;
  //          }
  //        }
  //        if (!this.isexists) {
  //          this.Process.push(this.ProcessList1[j].item_text);
  //          this.ProcessID.push(this.ProcessList1[j].item_id);
  //        }
  //      }
  //    }
  //  }

  //}

  AddSelectedMeasure(measureData: NgForm): void {
    //this.SelectedMeasure = this.MeasureName1;
    //this.countMeasure = 0;
    //for (var i = 0; i < this.MeasureNames.length; i++) {
    //  if (this.MeasureNames[i].Id == this.selectedMeasureId) {
    //    this.countMeasure = this.countMeasure + 1;
    //    break;
    //  }
    //}
    //if (this.countMeasure == 0) {
    //  this.addMeasure = true;
    //  return;
    //}
    //else
    //  this.addMeasure = false;

    //this.Selected_Measure.push(this.SelectedMeasure);
    //for (var i = 0; i < this.Selected_Measure.length; i++) {
    //  this.isexists = false;
    //  if (this.Measure.length == 0)
    //    this.Measure.push(this.Selected_Measure[i]);
    //  else {
    //    for (var j = 0; j < this.Measure.length; j++) {
    //      if (this.Selected_Measure[i] == this.Measure[j]) {
    //        this.isexists = true;
    //        break;
    //      }


    //    }
    //    if (this.isexists != true) {
    //      this.Measure.push(this.Selected_Measure[i]);
    //      this.existsMeasure = false;
    //    }


    //  }
    //}
    //if (this.isexists == true) {
    //  this.existsMeasure = true;
    //  let filteredNames = this.remove_duplicates(this.Selected_Measure);
    //  return
    //}
    //this.MeasureName1 = "";
    //this.addMeasure = false;

    if (measureData.valid && !this.measureExist) {
      let measure_data = this.MeasureList.filter(x => x.Id == this.selectedMeasureId);
      console.log(measure_data[0]);
      if (measure_data.length > 0) {
        this.AssociatedMeasures.push(measure_data[0]);
        this.MeasureList.filter(x => x.Id == this.selectedMeasureId).map(y => { y.IsSelected = false; return y; });
        this.selectedMeasureId = 0;
        this.addMeasureForm.resetForm();
        setTimeout(function () {
          window.TooltipFunc();
        }, 0);
      }

    }
    this.SaveSourciability();
  }


  RemoveAssociateMeasure(measureId: any) {
    var measure = this.SeletedArray.filter(x => x.id == measureId).map(x => {
      x.id; return x;
    });
    if (measure.length != 0) {
      this.RemoveFormulaMeasure = true;
      return;
    }
    this.AssociatedMeasures = this.AssociatedMeasures.filter(x => x.Id != measureId);
    this.SaveSourciability()
  }
  
  SaveSourciability()
  {
    debugger;
    var flag0 = false;
    var flag1 = false;
    var associatedmeasuredata = this.AssociatedMeasures;
    for (var i = 0; i < associatedmeasuredata.length; i++)
    {
      if (associatedmeasuredata[i].SourceId == 5)
        flag0 = true;
      else
        flag1 = true;
    }
    if (flag1 == true && flag0==false)
      this.sourcibility = "Fully Sourceable";
    else if (flag1 == false && flag0 == true)
      this.sourcibility = "Not Sourceable";
    else if (flag1 == true && flag0 == true)
      this.sourcibility = "Partially Sourceable"
  }
  Addseperator(char: string): void {
    this.AssociatedMeasures.sort(function (a, b) {
      return b.Name.length - a.Name.length;
    });


    var value = char.split(" ");

    var matchpattern = /([a-zA-Z0-9()#%_]+)/g;
    var parenthesisPattern = /([()])/g;

    var index = value.length;

    var searchstring = value[index - 1].toLowerCase();

    var finalstring = searchstring.match(matchpattern);

    var length = this.AssociatedMeasures.length;

    for (var k = 0; k < length; k++) {
      this.MatchedMeasures.pop();
    }

    if (finalstring != null) {
      for (var i = 0; i < this.AssociatedMeasures.length; i++) {
        var val = this.AssociatedMeasures[i].Name.toLowerCase();
        var temp = finalstring.toString().match(parenthesisPattern);
        var index = -1;
       if(temp != null) {
         var text = val.match(parenthesisPattern);
        }
        else  {
         index = val.search(finalstring);
        }
        if (index > -1 || text != null ) {
          this.MatchedMeasures.push({ "id": this.AssociatedMeasures[i].Id, "value": this.AssociatedMeasures[i].Name });
        }
      }
    }

    this.SeletedArray.length = 0;
    var userEnterdFormula = char;
    for (var j = 0; j < this.AssociatedMeasures.length; j++) {
      var mid = this.AssociatedMeasures[j].Id;
      var mname = this.AssociatedMeasures[j].Name;
      var munit = this.AssociatedMeasures[j].Unit[0].Id
      if (userEnterdFormula.indexOf(mname) != -1) {
        this.SeletedArray.push({
          id: mid,
          value: mname,
          unit: munit
        })
        userEnterdFormula = this.replaceAllOccurrences(userEnterdFormula, mname, "1");
      }
    }
    this.RemoveFormulaMeasure = false;
  }

  AreMeasuresInSync(): boolean {
    var flag = true;
    var AssociatedIds = [];
    var FormulaMIds = [];
    for (var j = 0; j < this.AssociatedMeasures.length; j++) {
      AssociatedIds.push(this.AssociatedMeasures[j].Id);
    }
    for (var k = 0; k < this.SeletedArray.length; k++) {
      FormulaMIds.push(this.SeletedArray[k].id);
    }
    for (var j = 0; j < FormulaMIds.length; j++) {
      var index = AssociatedIds.indexOf(FormulaMIds[j], 0);
      if (index == -1) {
        flag = false;
      }
    }
    return flag;
  }

  afterMeasureUpdate(measureData: any[]) {
    var i = this.MeasureSource.length;
    while (i-- > 0) {
      if (this.MeasureSource[i].MeasureId === measureData[0].MeasureId) {
        this.MeasureSource[i].Measure.Name = measureData[0].Name;
        this.MeasureSource[i].Measure.Abbreviation = measureData[0].Abbreviation;
        this.MeasureSource[i].Measure.Definition = measureData[0].Definition;
        break;
      }
    }
    //Added for defect#683940 (edited measure name not showing in formula field suggestion list)
    var count = this.MeasureSource.length;
    while (count-- > 0) {
      if (this.MeasuresForFormula[count].id === measureData[0].MeasureId) {
        this.MeasuresForFormula[count].value = measureData[0].Name;
        break;
      }
    }
  }

  remove_duplicates(arr) {
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
      obj[arr[i]] = true;
    }
    arr = [];
    for (let key in obj) {
      arr.push(key);
    }
    return arr;
  }

  ChangeFormulaMeasure(SelectedMeasure: any): void {

    if (SelectedMeasure.length != 0) {

      var val2 = SelectedMeasure.value;

      var char = this.EnteredFormula

      var value = char.split(" ");

      var matchpattern = /([a-zA-Z0-9()%#_-]+)/g;

      var index = value.length;

      var searchstring = value[index - 1];

      var finalstring = searchstring.match(matchpattern);

      var replaceindex = this.EnteredFormula.lastIndexOf(finalstring[0]);

      var AppendFormula = this.EnteredFormula.slice(0, replaceindex);

      var temp = AppendFormula.concat(val2);

      var length = this.MatchedMeasures.length;

      this.EnteredFormula = temp;



      if (this.SeletedArray.length == 0) {

        this.SeletedArray.push({ "id": SelectedMeasure.id, "value": SelectedMeasure.value });
      }

      else {
        this.isexists = false;
        for (var k = 0; k < this.SeletedArray.length; k++) {
          if (this.SeletedArray[k].id == SelectedMeasure.id) {
            this.isexists = true;
            break;
          }

        }
        if (this.isexists != true) {

          this.SeletedArray.push({ "id": SelectedMeasure.id, "value": SelectedMeasure.value });
        }
      }



      for (var k = 0; k < length; k++) {
        this.MatchedMeasures.pop();
      }
    }

    this.Measurevalue = "";

  }

  changeUnit(value: any) {
    this.unitError = true;
    if (value == 0) {
      this.unitError = true;
      this.Error = "Please fill in all the required fields.";
      this.TestMetricFormulaError = false;
    }
    else {
      this.unitError = false

    }

  }

  CheckForBrackets(): void {

    this.BracketError = false;
    this.TestMetricFormulaError = false;
    this.noBracketsmissing = 0;
    if (this.EnteredFormula != null && this.EnteredFormula != "" && this.EnteredFormula != undefined) {
      var enteredFormula = this.EnteredFormula.toString();

      for (var i = 0, len = enteredFormula.length; i < len; i++) {

        if (enteredFormula[i] == "(") {
          this.noBracketsmissing = this.noBracketsmissing + 1;
        }
        else if (enteredFormula[i] == ")") {
          this.noBracketsmissing = this.noBracketsmissing - 1;
        }
      }
    }
  }

  EditMetric() {
    this.IsEdit = !this.IsEdit;
  }

  //getWorktypeListforEdit(E2eId: any) {
  //  this.metricService.getWorktypeList(E2eId).subscribe(
  //    source => {
  //      this.WorkstreamList = source

  //      for (var i = 0; i < this.WorkstreamList.length; i++) {
  //        this.WSList1.push({ item_id: this.WorkstreamList[i].WorkstreamTypeId, item_text: this.WorkstreamList[i].Name });
  //      }

  //      this.getMetricDetails(this.Selected_MetricID, true);
  //      //this.onWorktypechangeChange();
  //    },
  //    error => this.errorMessage = <any>error);
  //}

  //getThresholdList(): void {
  //  this.metricService.getThresholdList().subscribe(
  //    threshold => {
  //      this.ThresholdList = threshold;
  //    },
  //    error => this.errorMessage = <any>error);
  //}

  saveworkstreamtype(AllMetricDetails: any): void {
    this.applicabledelivery = "";
    this.processess = "";
    this.Frequencys = "";
    for (var i = 0; i < AllMetricDetails.WSType.length; i++) {
      if (i == 0)
        this.applicabledelivery = AllMetricDetails.WSType[i].WorkstreamType.Name;
      else {
        this.isexist = false;
        var apstr = this.applicabledelivery.split(',');
        for (var j = 0; j < apstr.length; j++) {
          if (AllMetricDetails.WSType[i].WorkstreamType.Name == apstr[j]) {
            this.isexist = true;
            break;
          }
        }

        if (!this.isexist) {
          this.applicabledelivery = this.applicabledelivery + ", " + AllMetricDetails.WSType[i].WorkstreamType.Name;
        }

      }
    }
    for (var i = 0; i < AllMetricDetails.Process.length; i++) {
      if (i == 0)
        this.processess = AllMetricDetails.Process[i].BusinessProcess.Name;
      else {
        this.isexist = false;
        var apstr = this.processess.split(',');
        for (var j = 0; j < apstr.length; j++) {
          if (AllMetricDetails.Process[i].BusinessProcess.Name == apstr[j]) {
            this.isexist = true;
            break;
          }
        }
        if (!this.isexist) {
          this.processess = this.processess + ", " + AllMetricDetails.Process[i].BusinessProcess.Name;
        }

      }
    }

    for (var i = 0; i < AllMetricDetails.MetricFrequency.length; i++) {

      if (i == 0)
        this.Frequencys = AllMetricDetails.MetricFrequency[i].Frequency.Name;
      else {
        this.isexist = false;
        var apstr = this.Frequencys.split(',');
        for (var j = 0; j < apstr.length; j++) {
          if (AllMetricDetails.MetricFrequency[i].Frequency.Name == apstr[j]) {
            this.isexist = true;
            break;
          }
        }
        if (!this.isexist) {
          this.Frequencys = this.Frequencys + ", " + AllMetricDetails.MetricFrequency[i].Frequency.Name;
        }

      }
    }

  }

  replaceAllOccurrences(inputString, oldStr, newStr): string {
    while (inputString.indexOf(oldStr) != -1) {
      inputString = inputString.replace(oldStr, newStr);
    }
    return inputString;
  }

  CreateMetricFormula(): void {
    this.FinalFormula = "";
    var length = this.EnteredMeasures.length;

    for (var k = 0; k < length; k++) {
      this.EnteredMeasures.pop();
    }

    var enteredFormula = this.EnteredFormula.toString();

    this.SeletedArray.sort(function (a, b) {
      return b.value.length - a.value.length;
    });

    for (var i = 0; i < this.SeletedArray.length; i++) {
      for (var j = 0; j < this.AssociatedMeasures.length; j++) {

        if (this.AssociatedMeasures[j].Id == this.SeletedArray[i].id) {

          if (enteredFormula.includes(this.SeletedArray[i].value)) {
            this.isexists = false;
            if (this.EnteredMeasures.length == 0)
              this.EnteredMeasures.push({ "id": this.AssociatedMeasures[j].Id, "name": this.AssociatedMeasures[j].Name, "value": "", "unit": this.AssociatedMeasures[j].Unit[0].Id });
            else {
              for (var k = 0; k < this.EnteredMeasures.length; k++) {
                if (this.AssociatedMeasures[j] == this.EnteredMeasures[k]) {
                  this.isexists = true;
                  break;
                }
              }
              if (this.isexists != true)
                this.EnteredMeasures.push({ "id": this.AssociatedMeasures[j].Id, "name": this.AssociatedMeasures[j].Name, "value": "", "unit": this.AssociatedMeasures[j].Unit[0].Id });
            }
            enteredFormula = this.replaceAllOccurrences(enteredFormula, this.AssociatedMeasures[j].Name, this.LableList[i] + ":" + this.SeletedArray[i].id);
          }

          for (var k = 0; k < this.SeletedArray.length - 1; k++) {

            if (enteredFormula.includes(this.SeletedArray[i].value)) {
              enteredFormula = this.replaceAllOccurrences(enteredFormula, this.AssociatedMeasures[j].Name, this.LableList[i] + ":" + this.SeletedArray[i].id);
            }
            else {
              break;
            }
          }
        }
      }
    }

    enteredFormula = enteredFormula.replace(/\s/g, "");
    this.FinalFormula = enteredFormula;
  }


  BindEditMetric(_metricData: any, isEditMode: boolean) {
    this.SeletedArray.length = 0; //story 119736
    this.WSModel = []; //story 119736   
    this.metricsName = _metricData.Name[0].Name;
    this.allSelectedArray.length = 0;
    this.MeasuresForFormula.length = 0;
    this.MatchedMeasures.length = 0;
    this.Abbreviation = _metricData.Abbreviation;
    this.definition = _metricData.Definition;
    this.SelectedUnit = _metricData.Unit.length > 0 ? _metricData.Unit[0].Id : 0;
    this.SelectedThreshold = _metricData.Thresholdtype.length > 0 ? _metricData.Thresholdtype[0].Id : 0;
    this.selectedWSTypes = _metricData.ReportedAgainst.reduce((acc, cum) => {
      if (this.workstreamTypes.filter(x => x.Id == cum.Id).length > 0) {
        acc.push(cum)
      }
      return acc;
    }, []);
    this.selectedDeliveryFunction = this.selectedWSTypes.length == 0 ? [] : _metricData.Deliveryfunctions;
    this.selectedProcessId = _metricData.Deliveryfunctions;
    this.sourcibility = _metricData.RecommendedSource;
    this.SelectedCategory = _metricData.Category.length > 0 ? _metricData.Category[0].Id : 0;
    if (isEditMode) {
      this.IsCustom = _metricData.IsCustom;
    }
    else {
      this.IsCustomFlag = _metricData.IsCustom == "Y" ? true:false;
    }

    this.EnteredFormula = _metricData.Formula;
    this.AssociatedMeasures = _metricData.AssociatedMeasures;

    //this.saveworkstreamtype(_metricData);
    if (_metricData.AssociatedMeasures != undefined) {
      for (var i = 0; i < _metricData.AssociatedMeasures.length; i++) {
        this.SeletedArray.push({
          id: _metricData.AssociatedMeasures[i].Id,
          value: _metricData.AssociatedMeasures[i].Name,
          unit: _metricData.AssociatedMeasures[i].Unit[0].Id //Added unit for Story: 1121115 - As an MSE user, i should be allowed to create custom metrics with date measures
        })
      }
      this.CreateMetricFormula();
      this.calculateTestFormula(this.EnteredMeasures, true)
    }

  }

  //getProcessCategory(WorkstreamtypeName: any): void {

  //  this.metricService.getProcessCategory(WorkstreamtypeName).subscribe(
  //    source => {
  //      this.ProcessCategoryfinallist = source,
  //        this.getProcessCategoryfinal(source)
  //    });

  //}

  getProcessCategoryfinal(ProcessCategoryList: any[]) {

    if (this.ProcessCategory.length == 0) {
      for (var i = 0; i < ProcessCategoryList.length; i++) {
        this.ProcessCategory.push(ProcessCategoryList[i]);
      }

    }
    else {
      this.sameid = [];
      for (var j = 0; j < this.ProcessCategory.length; j++) {

        for (var k = 0; k < ProcessCategoryList.length; k++) {
          this.isexists = false;
          //PRB0061837 - INT_MSE_PROD: Data Driven Functionality is not working properly between 'Reported Against' and 'Category' under Add Custom metric Pop Up.  
          if (this.ProcessCategory[j].Name == ProcessCategoryList[k].Name) {
            this.isexists = true;
            this.sameid.push(ProcessCategoryList[k].Name);
          }
        }
      }
      //PRB0061837 - INT_MSE_PROD: Data Driven Functionality is not working properly between 'Reported Against' and 'Category' under Add Custom metric Pop Up.  
      if (this.sameid.length != 0) {
        this.ProcessCategory = [];
        for (var k = 0; k < ProcessCategoryList.length; k++) {
          this.isexists = false;
          for (var l = 0; l < this.sameid.length; l++) {

            if (ProcessCategoryList[k].Name == this.sameid[l]) {
              this.isexists = true;
              this.ProcessCategory.push(ProcessCategoryList[k]);
            }
          }
        }

      }
    }
  }

  //getGranularityList(): void {
  //  this.metricService.getGranularityList().subscribe(
  //    granularity => {
  //      this.metricService = granularity;
  //    },
  //    error => this.errorMessage = <any>error);
  //}

  //getFrequencyList(): void {
  //  this.metricService.getFrequency().subscribe(
  //    source => {
  //      // this.WorkstreamList = source,
  //      //Remove below comment
  //      //this.BindMFList(source)
  //      //this.loadingStatus = false;
  //    },
  //    error => this.errorMessage = <any>error);
  //}

  //getMetricName(): void {

  //  this.metricService.getMetricName().subscribe(
  //    source => {
  //      this.MetricName = source, this.loadMetricName(source);
  //      //this.getMetricSearchList();
  //    },
  //    error => this.errorMessage = <any>error);


  //}

  //getAllMetricNames(): void {
  //  this.metricService.getAllMetricNames().subscribe(
  //    source => {
  //      for (var i = 0; i < source.length; i++) {
  //        this.allMetricNames.push({ "id": source[i].MetricId, "value": source[i].Name });
  //      }
  //    },
  //    error => this.errorMessage = <any>error);
  //}

  //Removed index which was 3rd parameter as it was not used
  onChange(event: any, selectedMeasure: any) {
    var isChecked = event.currentTarget.checked;
    if (isChecked == true) {
      this.allSelectedArray.push(selectedMeasure);
    }
    else {
      for (var j = 0; j < this.allSelectedArray.length; j++) {
        if (this.allSelectedArray[j].Measure != null) {
          if (selectedMeasure.MeasureId == this.allSelectedArray[j].Measure.MeasureId) {
            this.allSelectedArray.splice(j, 1);
          }
        }
      }
    }

    if (this.allSelectedArray.length > 0) {
      this.isDelete = false;
    }
    else {
      this.isDelete = true;
      this.RemoveFormulaMeasure = false;
    }
  }

  onMeasureClick(selectedMeasure: any) {
    this.SelectedMeasureForEdit = selectedMeasure;
    this.onCustomize1();
  }

  onCustomize1() {
    debugger;
    this.showModal1 = true;
    this.showModal = true;

    const initialState = {};

    this.bsModalRef = this.modalService.show(AdminMeasureComponent, { initialState, backdrop: "static", keyboard: false })

  }

  onCancelClick(type: any) {
    if (type == 1) {
      this.popup_workstreamtype = false;
      this.showModal = false;
    }
    else if (type == 2) {
      this.SelectedMeasure = "";
      this.popup_measure = false;
      this.showModal = false;
    }
    else if (type == 4) {
      this.popup_TestFormula = false;
      this.showModal = false;

    }
    else if (type == 3) {
      this.SelectedMeasureForEdit = [];
      this.showModal1 = false;
      this.showModal = false;
    }
    this.existsMeasure = false;
    for (var j = 0; j < this.MeasureSource.length; j++) {
      this.MeasureSource[j].Measure.checked = false;
      for (var i = 0; i < this.allSelectedArray.length; i++) {
        if (this.allSelectedArray[i].MeasureId == this.MeasureSource[j].MeasureId) {
          this.MeasureSource[j].Measure.checked = true;
        }
      }
    }
    //  }

  }

  //onmeasurechangeChange(SourceName: string): void {
  //  this.SelectedMeasure = SourceName;
  //}

  onmetricNameChange(SelectedMetric: any): void {
    this.TestMetricFormulaError = false;
    this.metricError = false;
    this.metricnameError = false;
    this.standardMetricError = false;
    this.DefinationError = false;
    this.unitError = false;
    this.ReportedError = false;
    this.processError = false;
    this.categoryError = false;
    this.thresholdError = false;
    this.CalculatedMetricValueError = false;
    this.formulaError = false;
    this.CheckFormluleaError = false;
    this.RemoveFormulaMeasure = false;
    //1070487 - comparing based on this.metricsName.value
    if (this.CreateAction == true && SelectedMetric != '' && this.metricsName != null && this.MetricNames != undefined && this.MetricNames.length > 0) {
      let metric = this.MetricNames.filter(x => x.value === this.metricsName)[0];
      if (metric != null) {
        this.getMetricDetails(metric.id, false);
      }
    }
  }

  onProcessChange(SourceName: string): void {
    this.selectedProcessId = [];
    this.addWSP = true;
    this.Selected_Process.push(SourceName);
    for (var i = 0; i <= this.Selected_Process.length; i++) {
      this.selectedProcessId.push(this.Selected_Process[i]["item_id"]);
    }
    if (this.optionsModel.length > 0) {
      this.processError = false
      this.Error = "";
      this.TestMetricFormulaError = false;
    }
    else {
      this.processError = true
      this.Error = "Please fill in all the required fields.";
      this.TestMetricFormulaError = false;
    }
    // error div is empty defect by sagar
    if (this.Error == "") {
      if (this.metricnameError == true || this.DefinationError == true || this.formulaError == true
        || this.unitError == true || this.thresholdError == true || this.categoryError == true) {
        this.Error = "Please fill in all the required fields.";
        this.TestMetricFormulaError = false;
      }
    }
  }


  onReportedAgainstaChange(wstypes: any) {
    if (this.selectedWSTypes.length > 0) {
      this.showDF = 1;
      this.selectedWS = this.optionsModel;
      this.isInfraWS = false;
      this.addWSP = false;
      this.ProcessList = [];
      this.optionsModel = [];
      this.ProcessList1 = [];
      this.ProcessCategory = [];
      this.underWSModelloop = false;
      this.selectedWsId = [];
      this.selectedWsId = this.selectedWSTypes.map(x => x.Id);
      this.Selected_workstream_Trimmed = [];
      if (this.selectedWsId == 10 || this.selectedWsId == 11)
        this.isInfraWS = true;
     
      else
        this.isInfraWS = false;
      //this.isInfraWS = this.selectedWSTypes.filter(x => x.Id == 10 || x.Id == 11) ? true : false;
      this.Selected_Workstream = this.selectedWSTypes.map(x => x.Name);
      for (var i = 0; i < this.Selected_Workstream.length; i++) {
        var string_value = "("
        var no_bracket_string;
        var split_string = this.Selected_Workstream[i].split(string_value);
        no_bracket_string = split_string[0].replace("(", "")
        this.Selected_workstream_Trimmed.push(no_bracket_string);
      }
      this.processAndCategoryDetails(this.Selected_workstream_Trimmed.join(','), this.e2eUId);
      let wsTypes = this.workstreamTypes.map(x => x.Id)
      this.selectedWSTypes = this.selectedWSTypes.filter(a => wsTypes.indexOf(a.Id) > -1); //PRB0059070 & BUG 1092517 - removing WS type from WSModel not available in WSList
      if (this.CreateAction == false) {
        if (this.selectedWSTypes.length > 0) {
          this.ReportedError = false
          this.Error = ""
          this.TestMetricFormulaError = false;
        }
        else {
          this.ReportedError = true
          this.SelectedCategory = 0;
          this.Error = "Please fill in all the required fields.";
          this.TestMetricFormulaError = false;
        }
      }
      //this Else condition is added due to #682421 fixed bug By Madhu
      else {
        if (this.selectedWSTypes.length > 0) {
          this.ReportedError = false
          this.Error = ""
          this.TestMetricFormulaError = false;
        }
        else {
          this.ReportedError = true
          this.Error = "Please fill in all the required fields.";
          this.TestMetricFormulaError = false;
        }
      }
      // error div is empty defect by sagar
      if (this.Error == "") {
        if (this.metricnameError == true || this.DefinationError == true || this.formulaError == true
          || this.unitError == true || this.thresholdError == true || this.categoryError == true) {
          this.Error = "Please fill in all the required fields.";
          this.TestMetricFormulaError = false;
        }
      }
      //this.loadingStatus = false;
    }
    else {
      this.selectedDeliveryFunction = [];
      this.SelectedCategory = 0;
      this.ProcessList = [];
      this.ProcessCategory = [];
      
    }
  }

  NaNCheck(userEnteredFormula): void {
    userEnteredFormula = userEnteredFormula.replace(/\s/g, '');
    var overall_value = this.calculateArithmaticResult(userEnteredFormula);
    //Bug iD #706892 Checking formula creation is not working in custom metrics added by Madhu		
    if (overall_value == "Infinity") {
      overall_value = "0";
    }
    var nanPattern = /[a-zA-Z]/g
    if (overall_value != undefined) {
      var nanFlag = overall_value.match(nanPattern);
    }

    var arithmaticPattern = /[-+/*]/g
    var arithmaticFlag = null;
    if (overall_value != undefined) {
      var test = Number(overall_value);
      if (isNaN(test)) {
        arithmaticFlag = "NaN";
      }
      if (test > 0) {
        arithmaticFlag = overall_value.match(arithmaticPattern);
      }
    }
    if (nanFlag != null) {

      this.formulaError = true;
      this.Error = "Please correct the metric formula.";
      this.TestMetricFormulaError = false;
      return;
    }

    if (arithmaticFlag != null) {

      this.formulaError = true;
      this.Error = "Please correct the metric formula.";
      this.TestMetricFormulaError = false;
      return;
    }
  }

  myListFormatter(data: any): string {
    return data['value'];
  }

  multiply(a: any, b: any) {

    try {

      b = a.split('*');
      var result = b[0] * b[1];
      return result;

    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }
  }

  divide(a: any, b: any) {

    try {

      b = a.split('/');
      var result = b[0] / b[1];
      return result;
    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }
  }

  add(a: any, b: any) {

    try {

      if (a != null) {
        a = a.replace(/^\+/, '').replace(/\++/, '+');
        b = a.split('+');

        //PRB0065805 - 521993 - Bug - The metric 'Actual Backups Failed (After re-run)' calculation is not working properly.
        if (a.match(/--/)) {
          a = a.replace(/--/, '+');
          b = a.split('+');
        }

      }
      return Number(b[0]) + Number(b[1]);
    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }
  }

  subtract(a: any, b: any) {

    try {

      var result: any;
      if (a != null) {
        a = a.replace(/\+-|-\+/g, '-');

        if (a.match(/--/)) {
          return this.add(a, a.replace(/--/, '+'));
        }
        b = a.split('-');
      }

      //PRB0068222 - Metric calculation is wrong, if there is more than one minus sign in formula.
      if (b != null && b[0] != "") {
        result = b[0] - b[1];
      }

      else if (b != null && b[0] == "") {
        result = -1 * b[1] - b[2];
      }
      return result;
    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }
  }

  evalExp(data: any) {

    try {

      if (data != null) {
        data = data.toString().replace(/[\(\)]/g, '');
        var reMD = /-?\d+\.?\d*\s*[\*\/]\s*[+-]?\d+\.?\d*/;
        var reM = /\*/;
        var reAS = /-?\d+\.?\d*\s*[\+-]\s*[+-]?\d+\.?\d*/;
        var reA = /\d\+/;
        var exp: any;
        while (exp = data.match(reMD)) {

          data = exp[0].match(reM) ? data.toString().replace(exp[0], this.multiply(data, exp[0])) : data.toString().replace(exp[0], this.divide(data, exp[0]));
        }

        while (exp = data.match(reAS)) {
          data = exp[0].match(reA) ? data.toString().replace(exp[0], this.add(data, exp[0])) : data.toString().replace(exp[0], this.subtract(data, exp[0]));
        }
      }

      return '' + data;
    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }
  }

  RemoveSelectedMeasures() {
    this.RemoveFormulaMeasure = false;
    if (this.allSelectedArray.length > 0) {
      var selectedArryLength = this.allSelectedArray.length;
      var selectedArrayData = this.allSelectedArray.map((x: any) => Object.assign({}, x));
      //Story 121335 MSE: As an MSE user, I should be able to see an alert message when deleting a measure already used in Metric formula - Soumya - 20/07/2018
      for (var j = 0; j < selectedArrayData.length; j++) {
        for (var n = 0; n < this.SeletedArray.length; n++) {
          if (this.SeletedArray[n].id == selectedArrayData[j].MeasureId) {
            this.RemoveFormulaMeasure = false;
            break;
          }
          //}
        }
      }

      for (var i = 0; i < selectedArryLength; i++) {
        var deletingmeasure = selectedArrayData[i];
        if (this.MeasureSource.length > 0) {

          var measureName = deletingmeasure.Measure.Name;
          var measureId = deletingmeasure.Measure.MeasureId;

          for (var m = 0; m < this.MeasureSource.length; m++) {
            if (this.MeasureSource[m] != null) {

              if (measureId == this.MeasureSource[m].Measure.MeasureId && this.RemoveFormulaMeasure != false) {
                this.MeasureSource.splice(m, 1);

              }
            }
          }
        }

        if (this.MeasureList.length > 0) {
          // Defect id 967970: INT_Story_122165: deletion of measures in associated measuregrid is not working properly for some scenarios.
          for (var m = 0; m < this.MeasureList.length; m++) {
            if (this.MeasureList[m].Measure != null) {

              if (measureId == this.MeasureList[m].Measure.MeasureId && this.RemoveFormulaMeasure != false) {
                this.MeasureList.splice(m, 1);

              }
            }
          }
        }
        if (this.Measure.length > 0) {
          var measureName = deletingmeasure.Measure.Name;
          var index1 = this.Measure.indexOf(measureName, 0);
          this.Measure.splice(index1, 1);
        }
        if (this.Selected_Measure.length > 0) {
          var measureName = deletingmeasure.Measure.Name;
          var i1 = this.Selected_Measure.indexOf(measureName, 0);
          this.Selected_Measure.splice(i1, 1);
        }

        if (this.allSelectedArray.length > 0 && this.RemoveFormulaMeasure != false) {

          var measureName = deletingmeasure.Measure.Name;
          var measureId = deletingmeasure.Measure.MeasureId;

          for (var j = 0; j < this.allSelectedArray.length; j++) {
            if (this.allSelectedArray[j] != null) {
              if (measureId == this.allSelectedArray[j].Measure.MeasureId) {
                this.allSelectedArray.splice(j, 1);
              }
            }
          }
        }
        if (this.MeasuresForFormula.length > 0 && this.RemoveFormulaMeasure != false) {

          var measureName = deletingmeasure.Measure.Name;
          var measureId = deletingmeasure.Measure.MeasureId;

          for (var k = 0; k < this.MeasuresForFormula.length; k++) {
            if (this.MeasuresForFormula[k] != null) {
              if (measureId == this.MeasuresForFormula[k].id) {
                this.MeasuresForFormula.splice(k, 1);
              }
            }
          }
        }
      }
    }

    if (this.allSelectedArray.length > 0 || this.RemoveFormulaMeasure == false) {
      this.isDelete = false;
    }
    else {
      this.isDelete = true;
    }
  }

  ValidateField(fieldValue: any, field) {
    switch (field) {
      case 'name':
        if (fieldValue == "" || fieldValue == undefined) {
          this.metricnameError = true;
          this.Error = "Please fill in all the required fields.";
          this.TestMetricFormulaError = false;

        }
        else {
          this.Error = "";
          this.TestMetricFormulaError = false;
        }
        break;
      case 'def':
        if (fieldValue == "" || fieldValue == undefined) {
          this.DefinationError = true;
          this.Error = "Please fill in all the required fields.";
          this.TestMetricFormulaError = false;
        }
        else {
          this.Error = "";
          this.DefinationError = false;
          this.TestMetricFormulaError = false;
        }
        break;
      case 'formula':
        if (fieldValue == "" || fieldValue == undefined) {
          this.formulaError = true;
          this.Error = "Please fill in all the required fields.";
        }
        else {
          this.Error = "";
          this.formulaError = false;
          this.TestMetricFormulaError = false;
        }
        break;
      case 'Unit':
        if (this.SelectedUnit == 0) {
          this.unitError = true;
          this.Error = "Please fill in all the required fields.";
          this.TestMetricFormulaError = false;
        }
        else {
          this.Error = "";
          this.unitError = false;
          this.TestMetricFormulaError = false;
        }
        break;
      case 'Threshold':
        if (this.SelectedUnit == 0) {
          this.thresholdError = true;
          this.Error = "Please fill in all the required fields.";
          this.TestMetricFormulaError = false;
        }
        if (this.SelectedThreshold == 0) {
          this.thresholdError = true;
          this.Error = "Please fill in all the required fields.";
          this.TestMetricFormulaError = false;
        }
        else {
          this.Error = "";
          this.thresholdError = false;
        }
        break;
      case 'Category':
        if (this.SelectedUnit == 0) {
          this.categoryError = true;
          this.Error = "Please fill in all the required fields.";
          this.TestMetricFormulaError = false;
        }
        if (this.SelectedCategory == 0) {
          this.categoryError = true;
          this.Error = "Please fill in all the required fields.";
          this.TestMetricFormulaError = false;
        }
        else {
          this.Error = "";
          this.categoryError = false;
          this.TestMetricFormulaError = false;
        }
        break;
    }
    // error div empty issue by sagar
    if (this.metricnameError == true || this.DefinationError == true || this.formulaError == true
      || this.unitError == true || this.thresholdError == true || this.categoryError == true) {
      this.Error = "Please fill in all the required fields.";
      this.TestMetricFormulaError = false;
    }
  }

  validateCalculationInput(data: any, flag: boolean): boolean {

    var isDataValidated = true;

    if (data != null) {
      for (var i = 0; i < data.length; i++) {
        var measureData = data[i].value;
        //validation:measure value is mandatory for all measures
        if (measureData == "") {
          isDataValidated = true;
        }
      }

    }
    return isDataValidated;
  }

  calculateMetricsWithDate(formula: any, measureData: any): string {

    try {

      if (measureData != null) {
        var measure_array: any;

        if (formula != null) {
          var jsformula = formula.toString();
          var pattern = /([a-z]+):(\d+)-([a-z]+):(\d+)/g;// for pattern a:1000-b:1001
          var measures = jsformula.match(pattern);
          if (measures != null) {
            measure_array = measures.toString().split(",");
          }

          if (measure_array != null) {
            for (var w = 0; w < measure_array.length; w++) {

              var tag_array: any;
              var measure_tag = measure_array[w];
              var tag_pattern = /([a-z]+):(\d+)/g;// for pattern a:1000
              var tag_ids = measure_tag.match(tag_pattern);

              if (measure_tag != null) {
                tag_array = measure_tag.toString().split(",");


                var tag_pattern_measureid = /(\d+)/g;
                var my_tag_a = tag_array[0];
                var tag_id_measure_a = my_tag_a.match(tag_pattern_measureid);
                if (tag_id_measure_a != null) {
                  var tag_array_a = tag_id_measure_a.toString().split(",");
                  if (tag_array_a != null) {
                    var tag_a = tag_array_a[0];
                    var tag_b = tag_array_a[1];

                    var date_a = "";
                    for (var x = 0; x < measureData.length; x++) {
                      if (tag_a == measureData[x].id) {
                        if (measureData[x].value != null) {
                          date_a = measureData[x].value;
                        }
                        if (date_a != "") {
                          break;
                        }
                      }
                    }

                    var date_b = "";
                    for (var x = 0; x < measureData.length; x++) {
                      if (tag_b == measureData[x].id) {
                        if (measureData[x].value != null) {
                          date_b = measureData[x].value;
                        }
                        if (date_b != "") {
                          break;
                        }
                      }
                    }

                    var datediff = this.dateDiffInDays(date_a, date_b);

                    jsformula = jsformula.replace(new RegExp(measure_tag, 'g'), datediff);
                  }
                }
              }
            }
          }
          else { }
        }
      }
    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }

    return jsformula;
  }

  dateDiffInDays(a: string, b: string): number {
    try {
      // defect Id: 718163 nigative date is not calcuating 
      var timeDiff;
      var date1_convert = this.ConvertDateFormat(a);   //PRB0056332,PRB0056920
      var date1 = new Date(date1_convert);
      var date2_convert = this.ConvertDateFormat(b);  //PRB0056332,PRB0056920
      var date2 = new Date(date2_convert);

      if (date1 < date2) {
        timeDiff = Math.abs(date1.getTime()) - Math.abs(date2.getTime());
      }
      else {
        timeDiff = Math.abs(date1.getTime()) - Math.abs(date2.getTime());
      }

      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      return diffDays;
    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }
  }

  ConvertDateFormat(measureDate: any): string {

    var Convert_date: any;

    Convert_date = String(measureDate).split("-");
    var day = Convert_date[2];
    var month = Convert_date[1];
    var monthnumber = new Date(month + '-1-01').getMonth() + 1;
    var year = Convert_date[0];

    return monthnumber + "-" + day + "-" + year
  }

  calculateArithmaticResult(data: any) {

    try {

      if (data != null) {

        data = data.toString().replace(/\s/g, '').replace(/^\+/, '');
        var rePara = /\([^\(\)]*\)/;
        var exp = data.match(rePara);

        while (exp = data.match(rePara)) {
          data = data.toString().replace(exp[0], this.evalExp(exp[0]));
        }
        if (data == "0/0") {
          data = 0;
        }
      }
      return this.evalExp(data);

    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }
  }

  calculateTestFormula(data: any, flag: boolean): boolean {
    try {

      var measure_array: any;

      if (this.validateCalculationInput(data, flag)) {

        if (data != null) {

          var metricData = this.FinalFormula;

          if (metricData != null) {

            var jsformula = metricData;
            var pattern = /([a-z]+):(\d+)/g;// for pattern a:1000
            var measures = jsformula.match(pattern);

            if (measures != null) {
              measure_array = measures.toString().split(",");

              if (measure_array != null) {

                for (var w = 0; w < measure_array.length; w++) {

                  var isDate = false;
                  var measure_tag = measure_array[w];
                  var tag_pattern = /(\d+)/g;
                  var tag_id = measure_tag.match(tag_pattern);

                  for (var i = 0; i < data.length; i++) {

                    var measureData = data[i];

                    if (measureData != null) {
                      if (tag_id == measureData.id) {

                        var tag_value = measureData.value;
                        if (measureData.unit == 7) { // Story: 1121115 - As an MSE user, i should be allowed to create custom metrics with date measures
                          jsformula = this.calculateMetricsWithDate(jsformula, data);
                          break;
                        }

                        else {
                          if (tag_value != "") {
                            jsformula = jsformula.replace(new RegExp(measure_tag, 'g'), tag_value);
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          var final_formula_with_data = jsformula;


          var overall_value = this.calculateArithmaticResult(final_formula_with_data);

          var nanPattern = /[a-zA-Z]/g
          var nanFlag = overall_value.match(nanPattern);
          this.CalculatedMetricValue = Number(overall_value).toFixed(2);
          this.CalculatedMetricValueTemp = Number(overall_value).toFixed(2);
          this.UTCError = false;
          if (this.CalculatedMetricValue == "NaN") {

            let pattern = /[/*]+/;
            if (pattern.test(jsformula)) {
              this.CalculatedMetricValueTemp = "UTC";
            }
            else
              this.CalculatedMetricValueTemp = "";
          }

        }
        this.CalculatedMetricValueError = false;
        return false;
      }
    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }

    return true;
  }

  showMeasure(): void {

    this.showModal = true;
    this.popup_measure = true;

    this.Measure.length = 0;
    this.Selected_Measure.length = 0;
    for (var i = 0; i < this.MeasureSource.length; i++) {
      var measureName = this.MeasureSource[i].Measure.Name;
      this.Measure.push(measureName);
    }


    this.MeasureName1 = "";
    this.addMeasure = false;
    this.MeasureNames = [];
    //this.getMeasureList();

  }

  showworkstreamtype(): void {
    this.showModal = true;
    this.popup_workstreamtype = true;

  }

  showProcess(): void {
    this.popup_process = !this.popup_process;

  }

  togglepopup(): void {
    this.popup_visibility = !this.popup_visibility;

  }

  //Remove comments
  showErrorMessage(error: any): void {

    //if (global.environment.errorFlag) {
    //  this.ErrorMsg = error;
    //}
    //else {
    this.ErrorMsg = "Error Occured :", "An error has occurred please contact administrator !";
    //}
  }

  Show_TestFormula(): void {
    //  731359 Newly created custom measures are saved even though one of the associated measures are not used in formula. by sagar
    this.Error = false;
    this.DefinationError == false;
    this.metricnameError == false;
    this.metricError = false;
    this.unitError == false;
    this.processError == false;
    this.ReportedError == false;
    this.categoryError == false;
    this.thresholdError == false;
    this.DateMeasure = false;
    this.notDateMeasure = false;
    var flagAssociatMeasure = false;
    for (var i = 0; i < this.AssociatedMeasures.length; i++) {
      var flagmeasureexist = false;
      for (var j = 0; j < this.SeletedArray.length; j++) {
        if (this.AssociatedMeasures[i].Id == this.SeletedArray[j].id) {
          flagmeasureexist = true;
        }
      }
      if (flagmeasureexist != true) {
        flagAssociatMeasure = true;
        break;
      }
    }
    if (flagAssociatMeasure == true) {
      this.TotalFormulaCheck = true;
      return;
    }
    else {
      this.TotalFormulaCheck = false;
    }
    //111081 story changes
    if (this.EnteredFormula == "" || this.EnteredFormula == null) {
      this.TestMetricFormulaError = true;
      return;
    }
    else {
      this.TestMetricFormulaError = false;
    }
    this.count = 0;

    this.Measure.length = 0;
    this.Selected_Measure.length = 0;

    this.count = 0;
    this.Measure.length = 0;
    this.Selected_Measure.length = 0;
    var userEnteredFormula = this.EnteredFormula;
    var dateFormula = this.EnteredFormula;
    this.Measures.length = 0;
    for (var j = 0; j < this.AssociatedMeasures.length; j++) {
      var measuerName = this.AssociatedMeasures[j].Name.trim();
      var unit = this.AssociatedMeasures[j].Unit[0].Id;
      var id = this.AssociatedMeasures[j].Id
      this.Measures.push({ "name": measuerName, "id": id, "unit": unit });
    }
    this.Measures.sort(function (a, b) {
      return b.length - a.length;
    });
    // Story: 1121115 - As an MSE user, i should be allowed to create custom metrics with date measures
    for (var j = 0; j < this.Measures.length; j++) {
      if (this.Measures[j].unit == 7) {
        this.DateMeasure = true;
        break;
      }
    }
    var i = 0;
    if (this.DateMeasure) {
      for (var m = 0; m < this.Measures.length; m++) {
        if (this.Measures[m].unit == 7) {
          dateFormula = this.replaceAllOccurrences(dateFormula, this.Measures[m].name, "a:" + this.Measures[m].id);
        }
        else {
          dateFormula = this.replaceAllOccurrences(dateFormula, this.Measures[m].name, this.Measures[m].id);
          i++;
        }
      }

      dateFormula = dateFormula.replace(/\s/g, '');
      var datecount = dateFormula.split(":");
      if (datecount.length % 2 == 0) {
        dateFormula = "Invalid";
      }
      else {
        var jsformula = dateFormula.trim().toString();
        var pattern = /([a-z]+):(\d+)-([a-z]+):(\d+)/g; // for pattern a:1000-b:1001
        var patternExclude = /([a-z]+):(\d+)[^-]([a-z]+):(\d+)/g; // for patterns other than a:1000-b:1001
        var bpattern = /\(([a-z]+):(\d+)-([a-z]+):(\d+)\)/g; //for pattern (a:1000 - b:1001)
        var invalidmeasures = jsformula.match(patternExclude);
        var formulaWithNumCheck = datecount[2].split("-"); // for formula like a:1000-b:10001-2
        if ((datecount.length - 1) == 2 && i == 0 && formulaWithNumCheck.length < 2) { //if it is only 2 date measures in formula, then no need of brackets check
          var measures = jsformula.match(pattern);
        }
        else {
          var measures = jsformula.match(bpattern);
        }

        //commented for testing   //if (measures != null && invalidmeasures == null) {
        //   var measure_array = measures.toString().split(",");}

        if (measures == null || invalidmeasures != null || measures.length != (datecount.length - 1) / 2) {
          dateFormula = "Invalid";
        }
        else {
          var dateForm = this.replaceAllOccurrences(dateFormula, "a:", "");
          this.NaNCheck(dateForm);
        }
      }
    }
    else {
      this.notDateMeasure = true;
      for (var n = 0; n < this.Measures.length; n++) {
        userEnteredFormula = this.replaceAllOccurrences(userEnteredFormula, this.Measures[n].name, Math.floor((Math.random() * 100) + 1));
      }
    }


    if (this.notDateMeasure) {
      this.NaNCheck(userEnteredFormula);
    }
    if (this.Error == "") {
      this.CheckForBrackets();
      if (this.CalculatedMetricValue == "") {
        this.CalculatedMetricValue = "";
      }
      this.CalculatedMetricValueTemp = "";
      this.UTCError = false;

      if (this.MeasureSource.length == 0 && this.SeletedArray.length == 0) {
        this.BracketError = true;
        this.Error = "Please fill in all the required fields.";
        this.TestMetricFormulaError = false;
        return;
      }

      if (this.AreMeasuresInSync()) {
        this.formulaError = false;
        this.Error = "";
        if (this.noBracketsmissing != 0) {
          this.BracketError = true;
          this.Error = "The brackets are missing , Please check.";
          this.TestMetricFormulaError = false;
        }
        else if (dateFormula == "Invalid") {
          this.formulaError = true;
          this.Error = "Please correct the metric formula.";
          this.TestMetricFormulaError = false;
        }
        else {

          this.CreateMetricFormula();
          //this.popup_TestFormula = true;
          //this.showModal = true;
          this.showMetricFormula = true;
        }
      }
      else {
        this.formulaError = true;
        this.Error = "Please correct the metric formula.";
        this.TestMetricFormulaError = false;
      }
      // error div is empty defect by sagar
      if (this.Error == "") {
        if (this.metricnameError == true || this.DefinationError == true || this.formulaError == true
          || this.unitError == true || this.thresholdError == true || this.categoryError == true) {
          this.Error = "Please fill in all the required fields.";
          this.TestMetricFormulaError = false;
        }
      }
    }
  }

  SaveMetric(): void {
    this.TestMetricFormulaError = false;
    //this.loadingStatus = true;
    if (this.CreateAction == false) {
      for (var i = 0; i < this.allMetricDetails.Measures.length; i++) {
        this.FormulaMeasurelist.push({
          id: this.allMetricDetails.Measures[i].MeasureId,
          name: this.allMetricDetails.Measures[i].Name,
          value: 1
        })
      }
    }
    else {
      this.Measurevalue = "";
    }

    if (this.metricsName == "" || this.metricsName == undefined) {
      this.metricnameError = true;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;

    }
    else {
      this.metricnameError = false;
      this.metricError = false;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;

      // Comman validation funcation call for same metric Name 
      if (this.MetricNameList != null) {
        for (var i = 0; i < this.MetricNameList.length; i++) {
          if ((this.MetricNameList[i].Name).toLowerCase() == (this.metricsName).toLowerCase()) {
            this.metricsNameExists = true;
            this.existingMetricId = this.MetricNameList[i].Id;
            this.Selected_MetricID = this.existingMetricId;
            if (this.IsCustomFlag != true) {
              this.metricError = true;
            }
            else {
              if (this.Modified == true) {
                this.metricError = true;
              }
            }
          }
        }

      }
    }
    if (this.definition == "" || this.definition == undefined) {
      this.DefinationError = true;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;

    }
    else {
      this.DefinationError = false;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;
    }

    if (this.SelectedUnit == 0 || this.SelectedUnit == undefined) {
      this.unitError = true;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;

    }
    else {
      this.unitError = false;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;
    }

    if (this.selectedWSTypes.length > 0) {
      this.ReportedError = false;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;
    }
    else {
      this.ReportedError = true;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;

    }

    if (this.selectedDeliveryFunction.length > 0) {
      this.processError = false;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;
    }
    else {
      this.processError = true;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;

    }

    if (this.SelectedCategory == 0 || this.SelectedCategory == undefined) {
      this.categoryError = true;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;

    }
    else {
      this.categoryError = false;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;
    }

    if (this.SelectedThreshold == 0 || this.SelectedThreshold == undefined) {
      this.thresholdError = true;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;

    }
    else {
      this.thresholdError = false;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;
    }

    if (this.EnteredFormula == "" || this.EnteredFormula == undefined) {
      this.formulaError = true;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;

    }
    else {
      this.formulaError = false;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;
    }

    if (this.CalculatedMetricValue == "" || this.CalculatedMetricValue == undefined) {
      this.CalculatedMetricValueError = true;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;

    }
    else {
      this.CalculatedMetricValueError = false;
      this.TestMetricFormulaError = false;
      this.CalculatedMetricValueError = false;
    }
    //  731359 Newly created custom measures are saved even though one of the associated measures are not used in formula. by sagar
    var flagAssociatMeasure = false;
    for (var i = 0; i < this.AssociatedMeasures.length; i++) {
      var flagmeasureexist = false;
      for (var j = 0; j < this.SeletedArray.length; j++) {
        if (this.AssociatedMeasures[i].Id == this.SeletedArray[j].id) {
          flagmeasureexist = true;
        }
      }
      if (flagmeasureexist != true) {
        flagAssociatMeasure = true;
        break;
      }
    }
    if (flagAssociatMeasure == true) {
      this.TotalFormulaCheck = true;
      //return;
    }
    else {
      this.TotalFormulaCheck = false;
      this.MeasureSources = [];
      this.AssociatedMeasures.forEach(i => {
        this.MeasureSources.push(
          {
            MeasureID: i.Id,
            SourceID: i.SourceId
          });
      });
    }
    if (this.metricsName == "" || this.metricsName == undefined || this.metricnameError == true
      || this.SelectedUnit == 0 || this.SelectedUnit == undefined
      || this.SelectedCategory == 0 || this.SelectedCategory == undefined
      || this.SelectedThreshold == 0 || this.SelectedThreshold == undefined
      || this.definition == "" || this.definition == undefined
      || this.selectedDeliveryFunction.length == 0 || this.selectedWSTypes.length == 0) {
      this.openAccordion('details');
      this.Error = "Please fill in all the required fields.";
      this.TestMetricFormulaError = false;
      //this.loadingStatus = false;
      return;
    }
    else if (this.TotalFormulaCheck || this.EnteredFormula == "" || this.EnteredFormula == undefined) {
      //  731359 Newly created custom measures are saved even though one of the associated measures are not used in formula. by sagar   
      this.openAccordion('formula');
      return;
    }
    else if (this.metricError == true) {
      //this.loadingStatus = false;
      this.openAccordion('details');
      return;
    }
    else {
      this.Error = "";

      if (this.AreMeasuresInSync()) {
        this.formulaError = false;
        this.Error = "";
        this.TestMetricFormulaError = false;
        if (this.CalculatedMetricValue == "" || this.CalculatedMetricValue == undefined || this.CalculatedMetricValue == "UTC" || this.CalculatedMetricValue == "NaN") {
          this.CalculatedMetricValueError = true;
          this.TestMetricFormulaError = false;
          this.loadingStatus = false;
          this.openAccordion('formula');
        }
        else {
          if (this.TestFormulaBeforeSave()) {
            if ((this.CalculatedMetricValue == "" || this.CalculatedMetricValue == undefined || this.CalculatedMetricValue == "UTC" || this.CalculatedMetricValue == "NaN") && !this.DateMeasure) {
              this.CalculatedMetricValueError = true;
              this.TestMetricFormulaError = false;
              this.openAccordion('formula');
            }
            else {
              //this._onLoadingStatus.emit();
              let wstypeIds = this.selectedWSTypes.map(x => x.Id);
              let processIds = this.selectedDeliveryFunction.map(x => x.Id);
              this.NewArray = [];
              this.NewArray.push(
                {
                  Enterpriseid: this.sessionStorage.enterpriseId,
                  MetricId: this.Selected_MetricID,
                  IsAdd: '',
                  MetricName: this.metricsName,
                  Abbreviation: this.Abbreviation,
                  Definition: this.definition,
                  Unit: this.SelectedUnit,
                  Category: this.SelectedCategory,
                  ThresholdType: this.SelectedThreshold,
                  Formula: this.EnteredFormula,
                  JSFormula: this.FinalFormula,
                  Sourcibility: this.sourcibility,
                  MeasureSources: this.MeasureSources,
                  WSID: wstypeIds,
                  ProcessID: processIds,
                  E2EId: 0,
                  DeliveryLevelId: this.deliveryLevelId,
                  EndToEndUId: this.deliveryid,
                  FrequencyID: [2]
                });
              if (this.CreateAction == true) {
                if (this.metricsNameExists == true) {
                  if (this.Modified == true || this.IsCustomFlag != true) {
                    this.NewArray[0].IsAdd = 'add';
                    this.metricService.saveMetric(this.NewArray[0]).subscribe(
                      message => {
                        //this.loadingStatus = false;
                        this.sharedService.openAlertPopup("Metric Saved Successfully.", window.scrollY);

                        this.bsModalRef.hide();
                        //this.setAfterSave.emit({
                        //  parentActionToPerform: 'SaveWS',                          
                        //});
                        this._onSaveClick.emit(this.deliveryid);
                      },
                      error => {
                        console.log(error);
                        this.sharedService.openAlertPopup("Something went wrong!", window.scrollY);
                      });
                  }
                  else {    //119736 - MSE: As an MSE user I should allow custom metric created for an E2E to be available for other E2E also                                       
                    this.metricService.TagExistingMetric(this.NewArray[0]).subscribe(
                      message => {
                        //this.loadingStatus = false;
                        //this.isAlertBox = true;
                        this._onSaveClick.emit(this.deliveryid);
                        this.sharedService.openAlertPopup("Metric Saved Successfully.", window.scrollY);
                        this.bsModalRef.hide();
                      },
                      error => {
                        console.log(error);
                        this.sharedService.openAlertPopup("Something went wrong!", window.scrollY);
                      });
                  }
                }
                else {
                  this.NewArray[0].IsAdd = 'add';
                  this.metricService.saveMetric(this.NewArray[0]).subscribe(
                    message => {
                      //this.loadingStatus = false;
                      //this.isAlertBox = true;
                      this._onSaveClick.emit(this.deliveryid);
                      this.sharedService.openAlertPopup("Metric Saved Successfully.", window.scrollY);
                      this.bsModalRef.hide();
                    },
                    error => {
                      console.log(error);
                      this.sharedService.openAlertPopup("Something went wrong!", window.scrollY);
                    });
                }

              }
              else {
                this.NewArray[0].IsAdd = 'update';
                this.metricService.saveMetric(this.NewArray[0]).subscribe(
                  message => {
                    //this.loadingStatus = false;
                    //this.isAlertBox = true;
                    this._onSaveClick.emit(this.DeliveryId);
                    this.sharedService.openAlertPopup("Metric Saved Successfully.", window.scrollY);
                    this.bsModalRef.hide();
                  },
                  error => {
                    console.log(error);
                    this.sharedService.openAlertPopup("Something went wrong!", window.scrollY);
                  });
              }

            }
          }

        }
      }
      else {

        this.formulaError = true;
        this.Error = "Please correct the metric formula.";
        this.TestMetricFormulaError = false;
        this.loadingStatus = false;
      }
    }
  }

  saveMeasures(): void {
    if (this.Measure.length == 0 && this.MeasureSource.length == 0) {

      return;
    }
    this.MeasureSource.length = 0;
    this.MeasuresForFormula.length = 0;

    for (var i = 0; i < this.Measure.length; i++) {
      for (var j = 0; j < this.MeasureList.length; j++) {

        if (this.MeasureList[j].Measure.Name == this.Measure[i]) {

          this.MeasureSource.push(this.MeasureList[j]);
          this.MeasuresForFormula.push({ "id": this.MeasureList[j].MeasureId, "value": this.MeasureList[j].Measure.Name });

        }
      }
    }
    this.onCancelClick(2);
    this.flag = 0;
    for (var i = 0; i < this.MeasureSource.length; i++) {
      if (this.MeasureSource[i].Source.Name == "Manual") {
        this.flag = this.flag + 1;
      }
    }
    if (this.flag == 0)
      this.sourcibility = "Fully Sourceable";
    else if (this.flag == this.MeasureSource.length)
      this.sourcibility = "Not Sourceable";
    else this.sourcibility = "Partially Sourceable"
  }

  saveMeasuresForFormula(): void {
    this.MeasuresForFormula.length = 0;
    this.Measure.length = 0;
    for (var i = 0; i < this.MeasureSource.length; i++) {
      var measureName = this.AssociatedMeasures[i].Name;
      this.Measure.push(measureName);
    }
    for (var i = 0; i < this.Measure.length; i++) {
      for (var j = 0; j < this.MeasureList.length; j++) {
        if (this.MeasureList[j].Measure.Name == this.Measure[i]) {
          this.MeasuresForFormula.push({ "id": this.AssociatedMeasures[j].Id, "value": this.AssociatedMeasures[j].Name, "unit": this.AssociatedMeasures[j].Unit[0].Id });
        }
      }
    }
  }

  syncMeasureValue(data: any, MeasureId: any, MeasureValue: any, MeasureUnit: any): void {

    try {

      if (data != null) {
        for (var i = 0; i < data.length; i++) {
          var measureData = data[i];
          if (measureData != null) {
            var tag_id = measureData.id;
            if (tag_id == MeasureId) {
              this.EnteredMeasures[i].value = MeasureValue;
              this.EnteredMeasures[i].unit = MeasureUnit;
            }
          }
        }
      }
    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }
  }

  TestFormulaBeforeSave(): boolean {
    var flag = false;
    try {
      this.CreateMetricFormula();
      if (this.EnteredMeasures != null) {
        for (var i = 0; i < this.EnteredMeasures.length; i++) {
          var data = this.EnteredMeasures;
          var measureId = data[i].id;
          var measurevalue = i + 1;
          //added measureunit Story: 1121115 - As an MSE user, i should be allowed to create custom metrics with date measures
          var measureunit = data[i].unit
          this.syncMeasureValue(data, measureId, measurevalue, measureunit);
        }
        this.calculateTestFormula(this.EnteredMeasures, true);

      }
      else {
        this.CalculatedMetricValue = "";
      }
      flag = true;

      return flag;
    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }
  }

  InputValueChanged(event: any) { 
    if (this.Modified != true) {
      this.Modified = true;
    }
  };

  validateNumbersWithDecimal(keyevent: any, inputvalue: any): boolean {

    try {

      var charCode = (keyevent.which) ? keyevent.which : keyevent.keyCode;
      var resultvalue = inputvalue + keyevent.key;
      var dotcount = (inputvalue.match(/\./g) || []).length;
      var dot2count = (resultvalue.match(/\./g) || []).length;
      var noofdecimal = 2; // it decides how many number to be printed after decimal point.
      var temp1 = resultvalue.split('.');
      if (temp1.length == 2) {
        if (temp1[1].length > noofdecimal)// it determines that after decimal user has entered values
          return false;
      }

      if (charCode != 46 && charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;

      // if input already contains decimal then restrict the user from entering it.
      if (dot2count > 1) {
        return false;
      }
      if (temp1[0].length > 16) {
        return false;
      }

      return true;
    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }

  }

  validateMeasureValue(inputvalue: any): boolean {
    try {
      var flag: boolean = true;
      var before_decimalpoint = "";
      var after_decimalpoint = "";

      if (inputvalue != "") {

        var measure_value = inputvalue.toString().split(".");
        before_decimalpoint = measure_value[0];
        if (inputvalue % 1 != 0) {
          after_decimalpoint = measure_value[1];
        }

        if (before_decimalpoint.length > 14 && after_decimalpoint.length > 4) {
          flag = false;
        }
        if (after_decimalpoint.length > 4) {
          flag = false;
        }
      }

      return flag;
    }
    catch (Error) {
      this.showErrorMessage(Error.message);
    }
  }

  //UX metric revamp changes
  ClosePop() {
    this.bsModalRef.hide();
  }

  toggleDetails() {
    if (this.isShowValuefn) {
      this.isShowValuefn = false;
    }
    else {
      this.isShowValuefn = true;
    }
  }

  toggleFormula() {
    if (this.isShowValueFormula) {
      this.isShowValueFormula = false;
    }
    else {
      this.isShowValueFormula = true;
    }
  }

  toggleMeasure() {
    if (this.isShowValue) {
      this.isShowValue = false;
    }
    else {
      this.isShowValue = true;
    }
  }

  //Measure metric methods start

  AddMeasure(Data): void {

    this.loadingStatus = true;//Loding sing for defect 1006518 Measure is getting doubled each time user adds a new measure while creating custom metric. by sagar
    if (this.Unit == 0 || this.Unit == undefined) {
      this.unitError = true;
    }
    else {
      this.unitError = false;
    }

    if (this.measureName == "" || this.measureName == undefined) {

      this.measureNameError = true;

    } else {
      this.measureError = false;
      this.measureNameError = false;
      this.Error = false;
      for (var i = 0; i < this.MeasureList.length; i++) {
        if (this.MeasureList[i].toLowerCase() === this.measureName.toLowerCase()) {
          if (this.measureId != null) {
            if (this.MName == this.measureName) {
              this.measureError = false;
            }
            else {
              this.measureError = true;
              this.loadingStatus = false;//Loding sing for defect 1006518 Measure is getting doubled each time user adds a new measure while creating custom metric. by sagar
              return;
            }
          }
          else {
            this.measureError = true;
            this.loadingStatus = false;
            return;
          }

        }
      }
    }
    if (this.Defination == "" || this.Defination == undefined) {
      this.DefinationError = true;

    } else {
      this.DefinationError = false;
    }

    if (this.Unit == 0 || this.Unit == undefined
      || this.measureName == "" || this.measureName == undefined || this.Defination == "" || this.Defination == undefined) {

      this.Error = "Please fill in all the required fields.";
      this.loadingStatus = false;//Loding sing for defect 1006518 Measure is getting doubled each time user adds a new measure while creating custom metric. by sagar
    }
    else {
     
      if (this.measureId == null) {

        this.measureData.push({
          MeasureId: this.measureId,
          Name: this.measureName,
          Abbreviation: this.Abrrivation,
          Definition: this.Defination,
          UnitId: this.Unit,
          IsCustom: true,
          MeasureSources: [5],
          FiledCheck: this.fieldCheckIds,
          IsInsert: "Add",
          EnterpriseId: this.sessionStorage.enterpriseId
        })

        this.metricService.saveMeasure(this.measureData, this.EnterpriseId, "Add").subscribe(
          message => {
            this.loadingStatus = false;

            if (message == true) {
              //if measure already exists, then show error.
              this.measureError = true;

            }
            else if (message != true) {
              this.isAlertBox = true;
            }
            this.measureData = [];
          },
          error => this.errorMessage = <any>error);
      }
      else {
        
        this.measureData.push({
          MeasureId: this.measureId,
          Name: this.measureName,
          Abbreviation: this.Abrrivation,
          Definition: this.Defination,
          UnitId: this.Unit,
          IsCustom: true,
          MeasureSources: [5],
          FiledCheck: this.fieldCheckIds,
          IsInsert: "Update",
          EnterpriseId: this.sessionStorage.enterpriseId
        })

        this.metricService.saveMeasure(this.measureData, this.EnterpriseId, "Update").subscribe(
          message => {
            this.loadingStatus = false;//Loding sing for defect 1006518 Measure is getting doubled each time user adds a new measure while creating custom metric. by sagar
            this.isAlertBox = true;
          },
          error => this.errorMessage = <any>error);
        this._MeasureSave.emit(this.measureData)
      }
    }

  }

  fillMeasureName(MeasureList: any[]): void {

    for (var i = 0; i < MeasureList.length; i++) {
      if (MeasureList[i].SourceId != 7) {
        this.newMeasureList.push({ id: MeasureList[i].MeasureId, name: MeasureList[i].Name });
      }
      this.MeasureList.push(MeasureList[i].Name);
      this.MeasureIDlist.push(MeasureList[i].MeasureId);
    };
    this.loadingStatus = false;
  }

  getMeasureNamelIst(): void {
    this.metricService.getMeasureNameList().subscribe(
      source => {
        this.fillMeasureName(source);
        this.MeasureIDlist = source;
      }
      ,
      error => this.errorMessage = <any>error);
  }

  onMeasureChange(selectedMeasure: any): void {
    this.measureNameError = false;
    if (this.measureName != "") {
      let measurecnt = this.MeasureList.filter(x => x.Name === selectedMeasure).length;
      if (measurecnt > 0) {
        this.measureNameError = true;
      }
    }
  }

  ValidateFieldMeasure(fieldValue: any, field) {
    switch (field) {
      case 'name':
        if (fieldValue == "" || fieldValue == undefined) {
          this.measureNameError = true;
          this.Error = "Please fill in all the required fields.";
        }
        else {
          this.Error = "";
          this.onMeasureChange(fieldValue)
        }
        break;
      case 'defi':
        if (fieldValue == "" || fieldValue == undefined) {
          this.DefinationError = true;
          this.Error = "Please fill in all the required fields.";

        }
        else {
          this.DefinationError = false;
        }
        break;
    }
  }

  EditMeasure(Measure: any) {
    this.metricService.getMeasureForEdit(Measure, this.DeliveryLevelId).subscribe(
      Data => {
        this.MeasureDataForEdit = Data

        this.BindMeasure(this.MeasureDataForEdit);
      },

      error => this.errorMessage = <any>error);
  }

  BindMeasure(MeasureData: any) {
    this.MName = MeasureData.Name;
    this.measureName = MeasureData.Name;
    this.Abrrivation = MeasureData.Abbreviation;
    this.Defination = MeasureData.Definition;
    this.Unit = MeasureData.UnitId;
    this.getFieldCheckForEditMeasure(this.Unit, MeasureData.FiledCheck);
    this.RecommendedSource = MeasureData.RecommendedSource;
    this.IsCustom = MeasureData.IsCustom;
    this.loadingStatus = false //Added to show loading symbol. Defect# 706344

  }

  getFieldCheckForEditMeasure(UnitId: any, slectedFieldCheck: any): void {
    this.metricService.getFieldCheck(UnitId).subscribe(
      checks => {
        this.Sfield = "";
        this.FieldcheckVisible = false;
        this.fillFieldcheck(checks)
        this.optionsModelFieldCheck = slectedFieldCheck;
        for (var i = 0; i < this.optionsModelFieldCheck.length; i++) {
          //var Fcheck = this.getFCName(this.myOptionsFieldCheck, this.optionsModelFieldCheck[i]);
          //if (Fcheck != null) {
          //  this.SelectedFieldCheck.push(Fcheck);

          //}

        }
        for (var j = 0; j < this.SelectedFieldCheck.length; j++) {
          if (j == 0) {

            this.Sfield = this.SelectedFieldCheck[j];
          }
          else {
            this.Sfield = this.Sfield + ',' + this.SelectedFieldCheck[j];
          }
        }
      }
      ,
      error => this.errorMessage = <any>error);

  }

  BindMeasureMetric(MeasurId: any) {
    this.metricService.getAssociateMetricMeasure(MeasurId).subscribe(
      checks => {
        this.AccociateMeasurMetric = checks;
      }
      ,
      error => this.errorMessage = <any>error);
  }

  changeUnitMeasure(value: any) {

    this.FieldcheckVisible = true;
    if (value == 0) {
      this.FieldcheckVisible = true;
      this.unitError = true;
      this.Error = "Please fill in all the required fields.";
    }
    else {
      this.unitError = false
      this.getFieldCheck(value);
    }

  }

  onChangeFieldCheck() {
    this.fieldCheckIds = [];

    if (this.optionsModelFieldCheck.length > 0) {
      this.fieldCheckError = false;
      this.alertCss = "";
    }
    else {
      this.fieldCheckError = true;
    }

    for (var x = 0; x < this.optionsModelFieldCheck.length; x++) {
      this.fieldCheckIds.push(this.optionsModelFieldCheck[x].id);
    }

  }

  EditMeasureClick() {

    this.IsEdit = !this.IsEdit;
    this.IsDisable = true;
  }

  getFCName(data, value) {
    for (var k = 0; k < data.length; k++) {
      if (data[k].id == value) {
        return data[k];
      }
    }
  }

  getSourceList(): void {
    this.metricService.getSourceList().subscribe(
      source => {

        this.fillsource(source)
      }
      ,
      error => this.errorMessage = <any>error);
  }

  fillsource(wstype: any[]): void {

    //for (var i = 0; i < wstype.length; i++) {
    //  this.myOptions.push({ id: wstype[i].SourceId, name: wstype[i].Name })

    //};
  }

  getUnitListMeasure(FlagEdit: boolean): void {
    this.metricService.getUnitListMeasure(FlagEdit).subscribe(
      unit => {
        this.UnitList = unit
        //this.loadingStatus = false //Added to show loading symbol. Defect# 706344
      },
      error => this.errorMessage = <any>error);

  }

  showsource(): void {

    this.popup_source = !this.popup_source;
  }

  saveSource(): void {

    for (var i = 0; i < this.SourceType.length; i++) {
      if (i == 0)
        this.Sourcibility = this.SourceType[0];
      else this.Sourcibility = this.Sourcibility + ", " + this.SourceType[i];
    }
    this.showsource();


  }

  onsourceChange(SourceName: string): void {
    this.Selected_Source.push(SourceName);

  }

  AddSelectedSource(): void {

    for (var i = 0; i < this.Selected_Source.length; i++) {
      this.isexists = false;
      if (this.SourceType.length == 0)
        this.SourceType.push(this.Selected_Source[i]);
      else {
        for (var j = 0; j < this.SourceType.length; j++) {
          if (this.Selected_Source[i] == this.SourceType[j]) {
            this.isexists = true;
            break;
          }

        }
        if (this.isexists != true)
          this.SourceType.push(this.Selected_Source[i]);
      }
    }
  }

  RemoveSource(SourceName: string): void {

    var index = this.SourceType.indexOf(SourceName, 0);
    var i = this.Selected_Source.indexOf(SourceName, 0);
    if (index > -1) {
      this.SourceType.splice(index, 1);
      this.Selected_Source.splice(i, 1);
    }
  }


  saveCustomMeasure(measureData: NgForm) {
    if (measureData.valid && !this.measureNameError) {
      let custom_metricForm = measureData.value;
      let sel_filedChecks = custom_metricForm.fieldcheck.length > 0 ? custom_metricForm.fieldcheck.map(x => x.Id) : [];
      let formBody = [{
        MeasureId: 0,
        Name: custom_metricForm.measure_name,
        Abbreviation: custom_metricForm.abbreviation,
        Definition: custom_metricForm.definition,
        UnitId: custom_metricForm.unit,
        FiledCheck: sel_filedChecks,
        MeasureSources: [5],
        IsInsert: "Add",
        EnterpriseId: this.sessionStorage.enterpriseId
      }]
      console.log(formBody);
      this.metricService.saveMeasure(formBody, this.sessionStorage.enterpriseId, 'Add').subscribe((res) => {
        //this.addCustomMeasureForm.reset();
        this.addCustomMeasureForm.resetForm();
        this.selectedCustomUnitId = 0;
        this.selectedFieldCheckId = [];

        this.getMeasureList(this.deliveryid);
        this.sharedService.openAlertPopup("Measure saved successfully", window.scrollY);

      }, (error) => { console.log(error) })
    }
  }
  getMeasureExist(measureId: any): void {
    if (measureId != 0) {
      let measurecnt = this.AssociatedMeasures.filter(x => x.Id == measureId).length;
      if (measurecnt > 0) {
        this.measureExist = true;
      }
      else {
        this.measureExist = false;
      }

    }
    this.RemoveFormulaMeasure = false;
  }
  accordionExpanded: boolean = true;
  accordianType: string = 'details';
  showMetricFormula: boolean = false;
  toggleAccordion(accordianType: any) {
    if (this.accordianType == accordianType) {
      this.accordionExpanded = !this.accordionExpanded;
    }
    if (this.accordianType != accordianType) {
      this.accordianType = accordianType;
      this.accordionExpanded = true;
    }
    if (accordianType == 'formula') {
      this.showMetricFormula = false;
    }
    this.RemoveFormulaMeasure = false;
    console.log('accordionType' + this.accordianType);
    console.log('accordionExpanded' + this.accordionExpanded);
  }
  showTestMetricFormula() {
    this.showMetricFormula = true;
  }
  showFormulaPage() {
    this.showMetricFormula = false;
    if (this.EnteredMeasures.length > 0) {
      this.EnteredMeasures = this.EnteredMeasures.reduce((acc, cur) => {
        cur.value = '';
        acc.push(cur);
        return acc;
      }, []);
      $('#formula').click();
      console.log(this.EnteredMeasures);
    }
  }
  onDeliveryFunctionChange(unit: any) {
    if (this.selectedDeliveryFunction.length > 0) {
      this.processError = false;
    }
    else {
      this.processError = true;
    }
  }

  openAccordion(accordionId: string) {
    if ($('#' + accordionId).attr('aria-expanded') != 'true') {
      $('#' + accordionId).click();
    }
  }

  // Measure metric methods end

  /*
   *  ngOnChanges
   * submitForm - not needed
   *  showworkstreamtype
   *  showProcess
   *  togglepopup
   *  getGranularityList
   *  getFrequencyList
   *  BindMFList
   *  getMetricName
   *  loadMetricName
   * onWorktypechangeChange - not sure
   *  myListFormatter - not needed
   *  AddSelectedWorkstream
   *  AddSelectedMeasure
   *  remove_duplicates
   * RemoveMeasure - not needed
   *  saveMeasures
   * RemoveContact - not sure
   * AddSelectedProcess - not sure
   * RemoveProcess - not sure
   * saveProcess - not sure
   *  SaveMetric - Important
   *  TestFormulaBeforeSave - Important
   * ClosePopup - Important
   *  onCancelClick
   *  Show_TestFormula - Important
   *  NaNCheck - Important
   *  AreMeasuresInSync - Important
   *  CheckForBrackets - Important
   *  ChangeFormulaMeasure
   *  syncMeasureValue
   *  validateNumbersWithDecimal
   *  validateMeasureValue
   *  Addseperator
   *  changeUnit
   *  afterMeasureUpdate
   *  EditMetric
   *  getAllMetricNames
   * test1 - not needed
   */
}
