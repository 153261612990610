<form id="metricFilters" name="metricFilters" #metricFilters="ngForm">
  <div id="metric-filter-flyout" class="c-flyout c-contract__flyout -open" tabindex="-1" aria-hidden="true" aria-labelledby="flyoutTitle" style="visibility:visible">
    <div class="c-flyout__header -client-header">
      <div class="c-flyout-head__title">
        <div class="-flyoutTitle" id="flyoutTitle">Filter</div>
      </div>
      <button type="button" class="-dismiss-flyout" aria-label="Close" alt="Close"(click)="closeFilter()"></button>
    </div>
    <div class="c-flyout__body -customScroll">
      <div class="c-myWizard-form__input">
        <div class="c-myWizard-form__input-area">
          <div class="c-select-dropdown   ">
            <label for="metric_name">Metric Name-Abbreviation</label>
            <search-dropdown id="Metrics"
                             name="Metrics"
                             #Metricdropdown="ngModel"
                             [labelInfo]="'Metric Name-Abbreviation'"
                             [options]="metricDropDownActive"
                             [isShow]="true"
                             [errormessage]="'Please select Status'"
                             [formname]="metricFilters"
                             [required]="false"
                             [selectText]="'Select'"
                             [selected]="selectedMetricFilterId"                             
                             [(ngModel)]="selectedMetricFilterId"
                             (callback)="onChangeFilterValues()">
            </search-dropdown>        
          </div>
        </div>
        <div class="c-myWizard-form__input-area">
          <div class="c-select-dropdown   ">
            <label for="delivery_funct">Delivery Functions</label>
            <search-dropdown id="DeliveryFunctions"
                             name="DeliveryFunctions"
                             #DeliveryFunctionsdropdown="ngModel"
                             [labelInfo]="'Deliver Functions'"
                             [options]="deliverFunctionDropDownActive"
                             [isShow]="true"
                             [errormessage]="'Please select Status'"
                             [formname]="metricFilters"
                             [required]="false"
                             [selectText]="'Select'"
                             [selected]="selectedDFFilterId"
                             [(ngModel)]="selectedDFFilterId"
                             (callback)="onChangeFilterValues()">
            </search-dropdown>
            <!--<p class="c-select__error-text">Enter the value</p>-->
          </div>
        </div>
        <div class="c-myWizard-form__input-area">
          <div class="c-select-dropdown   ">
            <label for="report">Reported Against</label>
            <search-dropdown id="ReportAgainst"
                             name="ReportAgainst"
                             #ReportAgainstdropdown="ngModel"
                             [labelInfo]="'Report Against'"
                             [options]="reportAgainstDropdownActive "
                             [isShow]="true"
                             [errormessage]="'Please select Reported Against'"
                             [formname]="metricFilters"
                             [required]="false"
                             [selectText]="'Select'"
                             [selected]="selectedReportAgainstFilterId"
                             [(ngModel)]="selectedReportAgainstFilterId"
                             (callback)="onChangeFilterValues()">
            </search-dropdown>
            <!--<p class="c-select__error-text">Enter the value</p>-->
          </div>
        </div>
        <div class="c-myWizard-form__input-area">
          <div class="c-select-dropdown   ">
            <label for="buisness-context">Business Context</label>
            <search-dropdown id="BusinessContext"
                             name="BusinessContext"
                             #BusinessContextdropdown="ngModel"
                             [labelInfo]="'Business Context'"
                             [options]="businessContextDropdownActive"
                             [isShow]="true"
                             [errormessage]="'Please select Business Context'"
                             [formname]="metricFilters"
                             [required]="false"
                             [selectText]="'Select'"
                             [selected]="selectedbusinessContextFilterId"
                             [(ngModel)]="selectedbusinessContextFilterId"
                             (callback)="onChangeFilterValues()">
            </search-dropdown>
            <!--<p class="c-select__error-text">Enter the value</p>-->
          </div>
        </div>
        <div class="c-myWizard-form__input-area" *ngIf="!isThreshold">
          <div class="c-select-dropdown   ">
            <label for="in-scope">In Scope</label>
            <search-dropdown id="InScope"
                             name="InScope"
                             #InScopedropdown="ngModel"
                             [labelInfo]="'In Scope'"
                             [options]="inScopeDropdownActive "
                             [isShow]="true"
                             [errormessage]="'Please select In Scope'"
                             [formname]="metricFilters"
                             [required]="false"
                             [selectText]="'Select'"
                             [selected]="selectedIsScopeFilterId"
                             [(ngModel)]="selectedIsScopeFilterId"
                             (callback)="onChangeFilterValues()">
            </search-dropdown>
            <!--<p class="c-select__error-text">Enter the value</p>-->
          </div>
        </div>
        <div class="c-myWizard-form__input-area">
          <div class="c-select-dropdown   ">
            <label for="Applicability">Applicability</label>
            <search-dropdown id="Applicability"
                             name="Applicability"
                             #Applicabilitydropdown="ngModel"
                             [labelInfo]="'Applicability'"
                             [options]="applicabilityDropdownActive"
                             [isShow]="true"
                             [errormessage]="'Please select Applicability'"
                             [formname]="metricFilters"
                             [required]="false"
                             [selectText]="'Select'"
                             [selected]="selectedApplicabilityId"
                             [(ngModel)]="selectedApplicabilityId"
                             (callback)="onChangeFilterValues()">
            </search-dropdown>
            <!--<p class="c-select__error-text">Enter the value</p>-->
          </div>
        </div>
      </div>
    </div>
    <div class="c-flyout__footer">
      <div class="-flyout-btn">
        <!--Primary Button starts from here-->
        <a href="javascript:void(0)" role="button" class="o-secondary-button  " (click)="clearFilter()">
          <!---->
          Clear All
        </a>
        <!--Primary Button ends from here-->
        <!--Primary Button starts from here-->
        <a href="javascript:void(0)" role="button" class="o-primary-button  " (click)="applyFilter()">
          <!---->
          Apply
        </a>
        <!--Primary Button ends from here-->
      </div>
    </div>
  </div>
  <div class="c-flyout__overlay"></div>
</form>
