import { Component, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var $: any;
@Component({
  templateUrl: './alert.popup.component.html'
})

export class AlertPopupComponent {
  message: string;
  okButton: string;
  title: string;
  scrollYPosition: any;
  public confirmationResult: EventEmitter<any> = new EventEmitter();

  constructor(
    private bsModalRef: BsModalRef
    , private modalService: BsModalService) { }

  ngOnInit() {
    setTimeout(function () {
      $(".close").focus();
    })
  }
  ngOnDestroy() {
    if ($(".modal-backdrop").length > 0) {
      $('body').addClass("modal-open")
    }
    window.scrollTo(0, this.scrollYPosition);
   
  }
  closeAlertPopup() {
    this.confirmationResult.emit({ result: true });
    this.bsModalRef.hide();
  }
}
