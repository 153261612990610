import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { TileService } from '../core/services/tile.service';
import { SessionStorage } from '../core/context/sessionstorage';
import { UserPreferenceService } from '../core/services/user.preference.service';
import { UserService } from '../core/services/user.service';
import { ReleaseNoteService } from '../core/services/release.note.service';
import cloneDeep from 'lodash/cloneDeep';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var window: any;
var $ = (<any>window).$;

@Component({
  templateUrl: './announcement.component.html',
  selector: 'annoucement'
})
export class AnnouncementComponent implements OnInit {
  @Input() loadAnnouncement: boolean;
  @Input() isScopeSelectorVisible: boolean;

  ReleaseNotes : any

  //Releases: any;
  //ReleaseNoteHistory: any;
  //SelectedRelease: any;
  openSlide: boolean = true;// TODO: testing purpose make it as true
  //ReleaseNote: any;
  //ReleaseName: any;
  //ReleaseDate: any;
  //ReleaseNameHistory: any;
  //ReleaseDateHistory: any;
  //InitReleaseNote: any;
  //InitReleaseNoteHistory: any;
  //SelfSupportURL: any;
  //IsAnnouncement: any;
  //latestReleaseName: any;
  GlobalValue: any;
  Enhancement: any;
  ReleaseNotesPreferenceValue: any;
  //CurrentReleaseName: any;
  //CurrentReleaseDate: any;
  //PreviousReleaseName: any;
  //PreviousReleaseDate: any;
  IschangeCriticalText: string = "Critical! ";
  isCritical: boolean = false;
  //prevReleaseNote: any = [];
  //finalReleaseNote: any = [];
  //IndexNumbersReleaseNote: any = [];
  //IndexNumbersReleaseNoteHistory: any = [];
  //isShowEndToEnd: any = [];
  //modalRef_releaseNote: BsModalRef;
  //isShowValuefn: boolean = true;
  //applicationNames: any = [];
  //prevApplicationNames: any = [];

  constructor(
    private tileService: TileService,
    private releaseService: ReleaseNoteService,
    private sessionStorage: SessionStorage,
    private router: Router,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private userPreferenceService: UserPreferenceService,
    private userService: UserService) { }

  ngOnInit() {

    this.getReleaseNotes();
    setTimeout(() => {
      window.collapseComponent();
    }, 0)
    setTimeout(function () {
      $("body").removeClass("modal-open");
    }, 0);

  }


  //LoadReleases() {
  //  this.closeSlide();
  //  this.modalRef_releaseNote = this.modalService.show(ReleaseNoteHistoryComponent, {
  //    backdrop: "static",
  //    keyboard: false
  //  });
  //  $(".modal-dialog").addClass('custom-modal-releasenote');
  //}
  getReleaseNotes() {
    this.tileService.getReleaseNotes(false,!this.isScopeSelectorVisible).subscribe((response: any) => {
      this.ReleaseNotes = response.ReleaseNotes;
      this.Enhancement = "Upcoming Release";
      this.LoadUserPreference();
      this.ReleaseNotes.forEach((rs, index) => {
        rs.ReleaseNoteApplications.forEach((app, index) => {
          app.IsAppCriticalChange = app.ReleaseNotesList.filter(x=> x.IsChangeCritical == "Y").length > 0 ? true : false;
          app.ReleaseNotesList.forEach((value, index) => {
          
            if (value.IsChangeCritical == "Y") {
            
              if (value.FeatureName.indexOf('<p>') >= 0) {
                var critical = "<p><span class='font-red'>" + this.IschangeCriticalText + "</span>";
                value.FeatureName = value.FeatureName.replace("<p>", critical);
              } else {
                var critical = "<span class='font-red'>" + this.IschangeCriticalText + "</span>";
                value.FeatureName = critical + value.FeatureName;
              }
            }
            if (value.ImpactedApplication) {
              var impactedApp = "<b>Impacted Apps - " + value.ImpactedApplication + "</b>";
              value.Impact = value.Impact + impactedApp;
            }
          });
        });
      });


      setTimeout(() => {
        window.collapseComponent();
      }, 0)
    });
  }

  trackByReleaseName(index: number, item: any): string {
    return item.ReleaseName;
  }

 
  LoadUserPreference() {
   this.userService.getReleaseNoteUserPreference(this.sessionStorage.enterpriseId).subscribe((response: any) => {
     this.ReleaseNotesPreferenceValue = response;
     if (this.GlobalValue != undefined) {
       this.openSlide = (this.GlobalValue == true && this.ReleaseNotesPreferenceValue == false) ? true : false;
     }
   }, function (error) {
     this.ReleaseNotesPreferenceValue = false;
     this.openSlide = true;
   });
  }

  //getReleaseNotes() {
  //  this.tileService.getReleaseNotes().subscribe((response: any) => {
  //    this.ReleaseNoteHistory = response.ReleaseNoteHistory;
  //    this.ReleaseNote = response.ReleaseNote;
  //    this.ReleaseName = cloneDeep(response.Releases[0].Name);
  //    this.ReleaseDate = cloneDeep(response.Releases[0].ReleaseDate);
  //    this.ReleaseNameHistory = cloneDeep(response.ReleaseNoteHistory[0].ReleaseName);
  //    this.ReleaseDateHistory = cloneDeep(response.ReleaseNoteHistory[0].ReleaseDate);
  //    this.InitReleaseNote = cloneDeep(response.ReleaseNote);
  //    this.InitReleaseNoteHistory = cloneDeep(response.ReleaseNoteHistory);
  //    this.SelfSupportURL = response.SelfSupportURL;

  //    this.arrayToObject();
  //    this.arrayToObjectHistory();
  //    this.LoadUserPreference();
  //    setTimeout(function () {
  //      $('input[name="Release_preference"]').focus();
  //    });
  //    this.sessionStorage.userId = 0;
  //    if (response.ReleaseNote && response.ReleaseNote.length != 0) {
  //      this.latestReleaseName = response.ReleaseNote[0].ReleaseName;
  //    }
  //    if (response.ReleaseNoteHistory && response.ReleaseNoteHistory.length != 0) {
  //      this.IsAnnouncement = true;
  //      this.GlobalValue = response.ReleaseNoteHistory[0].ToggleSlider;
  //      this.Enhancement = "Upcoming Release";
  //      this.openSlide = true;
  //      if (this.ReleaseNotesPreferenceValue != undefined) {
  //        this.openSlide = (response.ReleaseNoteHistory[0].ToggleSlider == true && this.ReleaseNotesPreferenceValue == false) ? true : false;
  //      }
  //    }
  //    else {
  //      this.IsAnnouncement = false;
  //    }
  //  }, function (error) {
  //    this.GlobalValue = false;
  //    this.openSlide = false;
  //  });

  //}

  //closeSlide() {
  //  this.sessionStorage.isReleaseAnnouncementClosed = true;
  //  this.bsModalRef.hide();
  //}

  //arrayToObject() {
  //  let finalLoopData: any = [];
  //  this.IndexNumbersReleaseNote = []
  //  let sno: number = 0;

  //  this.ReleaseNote.forEach((value, index) => {
  //    this.finalReleaseNote[index] = value;
  //    this.finalReleaseNote[index].ReleaseNotesList.forEach((value, key) => {
  //      if (this.applicationNames.indexOf(value.ApplicationName) == -1)
  //        this.applicationNames.push(value.ApplicationName);
  //      if (!finalLoopData[value.ApplicationName]) {
  //        finalLoopData[key] = finalLoopData[value.ApplicationName] = [];
  //        sno = sno + 1;
  //      }
  //      if (value.IsChangeCritical == "Y") {
  //        if (value.FeatureName.indexOf('<p>') >= 0) {
  //          var critical = "<p><span class='font-red'>" + this.IschangeCriticalText + "</span>";
  //          value.FeatureName = value.FeatureName.replace("<p>", critical);
  //        } else {
  //          var critical = "<span class='font-red'>" + this.IschangeCriticalText + "</span>";
  //          value.FeatureName = critical + value.FeatureName;
  //        }
  //      }
  //      if (value.ImpactedApplication) {
  //        var impactedApp = "<b>Impacted Apps - " + value.ImpactedApplication + "</b>";
  //        value.Impact = value.Impact + impactedApp;
  //      }
  //      finalLoopData[value.ApplicationName].push(value);
  //      this.IndexNumbersReleaseNote.push(sno);
  //    });
  //    this.finalReleaseNote[index].ReleaseNotesList = finalLoopData;
  //  });

  //}
  //arrayToObjectHistory() {
  //  let prevLoopData: any = [];
  //  this.IndexNumbersReleaseNoteHistory = []
  //  let sno: number = 0;
  //  this.ReleaseNoteHistory.forEach((value, index) => {
  //    this.prevReleaseNote[index] = value;
  //    this.prevReleaseNote[index].ReleaseNotesList.forEach((value, key) => {
  //      if (this.prevApplicationNames.indexOf(value.ApplicationName) == -1)
  //        this.prevApplicationNames.push(value.ApplicationName);
  //      if (!prevLoopData[value.ApplicationName]) {
  //        prevLoopData[key] = prevLoopData[value.ApplicationName] = new Array();
  //        sno = sno + 1;
  //      }
  //      if (value.IsChangeCritical == "Y") {
  //        if (value.FeatureName.indexOf('<p>') >= 0) {
  //          var critical = "<p><span class='font-red'>" + this.IschangeCriticalText + "</span>";
  //          value.FeatureName = value.FeatureName.replace("<p>", critical);
  //        } else {
  //          var critical = "<span class='font-red'>" + this.IschangeCriticalText + "</span>";
  //          value.FeatureName = critical + value.FeatureName;
  //        }
  //      }
  //      if (value.ImpactedApplication) {
  //        var impactedApp = "<b>Impacted Apps - " + value.ImpactedApplication + "</b>";
  //        value.Impact = value.Impact + impactedApp;
  //      }
  //      prevLoopData[value.ApplicationName].push(value);
  //      this.IndexNumbersReleaseNoteHistory.push(sno);
  //    });
  //    this.prevReleaseNote[index].ReleaseNotesList = prevLoopData;
  //  });
  //}

  SaveUserPreferenceForReleaseNote(preferencevalue) {
    this.userService.saveReleaseNoteUserPreference(preferencevalue).subscribe((response: any) => {
    });
  }


  //GetReleaseDetails(releaseNumberId) {
  //  if (!releaseNumberId) {
  //    this.SelectedRelease = null;
  //  }
  //  else {
  //    this.releaseService.getReleaseDetails(releaseNumberId, false, this.ReleaseNoteHistory.IsChangeCriticalId).subscribe((response: any) => {
  //      this.SelectedRelease = response.ReleaseNotesList;
  //      this.SelectedRelease.forEach((item) => {
  //        if (item.IsChangeCritical == "Y") {
  //          if (item.FeatureName.indexOf('<p>') >= 0) {
  //            var critical = "<p><span class='font-red'>" + this.IschangeCriticalText + "</span>";
  //            item.FeatureName = item.FeatureName.replace("<p>", critical);
  //          } else {
  //            var critical = "<span class='font-red'>" + this.IschangeCriticalText + "</span>";
  //            item.FeatureName = critical + item.FeatureName;
  //          }
  //        }
  //        if (item.ImpactedApplication) {
  //          var impactedApp = "<b>Impacted Apps - " + item.ImpactedApplication + "</b>";
  //          item.Impact = item.Impact + impactedApp;
  //        }

  //      });
  //    });
  //  }
  //}

  //GetReleaseDetailsByCritical(isCritical) {
  //  this.tileService.getReleaseNotes(isCritical).subscribe((response: any) => {
  //    this.ReleaseNote = response.ReleaseNote;
  //    this.ReleaseNoteHistory = response.ReleaseNoteHistory;
  //    this.CurrentReleaseName = cloneDeep(this.ReleaseName);
  //    this.CurrentReleaseDate = cloneDeep(this.ReleaseDate);
  //    this.PreviousReleaseName = cloneDeep(this.ReleaseNameHistory);
  //    this.PreviousReleaseDate = cloneDeep(this.ReleaseDateHistory);
  //    this.arrayToObject();
  //    this.arrayToObjectHistory();

  //  });
  //}

  

  //rowTrackByReleaseNoteId(index: number, item: any): any {
  //  return "row_".concat(item.ReleaseNoteId.toString());
  //}
  //toggleAnnouncements() {
  //  if (this.isShowEndToEnd) {
  //    this.isShowEndToEnd = false;
  //  }
  //  else {
  //    this.isShowEndToEnd = true;
  //  }
  //}

}

