import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionStorage } from '../context/sessionstorage';
import { Observable } from 'rxjs';

@Injectable()
export class DEXService {

  constructor(private sessionStorage: SessionStorage, private httpService: HttpService) { }

  getDeliveryExcellencePrinciples(endToEndId: any) {
    return this.httpService.get('/api/DeliveryExcellence/' + endToEndId, true);
  }
  saveDeliveryExcellencePrinciples(data: any): Observable<any> {
    return this.httpService.post('/api/DeliveryExcellence', data, true);
  }
}
