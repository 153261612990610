<!-- <div id="new-url" class="c-flyout c-contract__flyout -open" tabindex="-1" aria-hidden="true" aria-labelledby="flyoutTitle" style="visibility:visible;width:38rem">
  <div class="c-flyout__header" id="phoenixBaseUrlConfigurationPopUp">
    <div class="-flyoutTitle" id="flyoutTitle">New API Base URL</div>
    <button title="Close" type="button" id="closePhoenixPopUp" (click)="cancelBaseUrlConfiguration(phoenixBaseUrlConfigurationForm)" class="-dismiss-flyout" data-dismiss="modal" aria-label="Close Phoenix Popup"><span aria-hidden="true">&times;</span></button>
  </div>
  <form name="phoenixBaseUrlConfigurationForm" #phoenixBaseUrlConfigurationForm="ngForm" novalidate (ngSubmit)="SavePhoenixBaseUrlConfiguration(phoenixBaseUrlConfigurationForm.valid,phoenixBaseUrlConfigurationForm);">

    <div class="c-flyout__body -customScroll">
      <div id="accordionParent-contracts">
        <div class="c-accordion -open">
          <div id="active-contract-1" class="-collapse -show" data-parentid="accordionParent-contracts">
            <div class="c-accordion__container">
              <div class="c-myWizard-form__input" style="grid-template-columns: none;">
                <div class="c-myWizard-form__input-area" style="width:98%">
                  <div class="o-input    -required">
                    <label for="BaseUrl">Base URL</label>
                    <input id="apiBaseUrl" type="text" [ngClass]="{'has-error':phoenixBaseUrlConfigurationForm.submitted && baseUrl.errors?.required}" autocomplete="off" name="baseUrl" #baseUrl="ngModel" class="o-input__input" [(ngModel)]="phoenixBaseUrlConfiguration.BaseUrl" value="" required />
                    <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':phoenixBaseUrlConfigurationForm.submitted && baseUrl.errors?.required}">Please enter Base URL</div>
                  </div>
                </div>
                <div class="c-myWizard-form__input-area" style="width:98%">
                  <div class="o-input    -required">
                    <label for="TenantUrl">Tenant URL</label>
                    <input id="apiBaseUrl" type="text" maxlength="100" [ngClass]="{'has-error':phoenixBaseUrlConfigurationForm.submitted && tenantUrl.errors?.required}" autocomplete="off" name="tenantUrl" #tenantUrl="ngModel" class="o-input__input" [(ngModel)]="phoenixBaseUrlConfiguration.TenantUrl" value="" required />
                    <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':phoenixBaseUrlConfigurationForm.submitted && tenantUrl.errors?.required}">Please enter Tenant URL</div>
                  </div>
                </div>
                <div class="c-myWizard-form__input-area" style="width:98%">
                  <div class="o-input    -required">
                    <label for="ClientId">Client ID</label>
                    <input id="apiBaseUrl" type="text" maxlength="100" [ngClass]="{'has-error':phoenixBaseUrlConfigurationForm.submitted && clientId.errors?.required}" autocomplete="off" name="clientId" #clientId="ngModel" class="o-input__input" [(ngModel)]="phoenixBaseUrlConfiguration.ClientId" value="" required />
                    <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':phoenixBaseUrlConfigurationForm.submitted && clientId.errors?.required}">Please enter Client ID</div>
                  </div>
                </div>
                <div class="c-myWizard-form__input-area" style="width:98%">
                  <div class="o-input    -required">
                    <label for="ClientSecret">Client Secret</label>
                    <input id="apiBaseUrl" type="text" maxlength="100" [ngClass]="{'has-error':phoenixBaseUrlConfigurationForm.submitted && clientSecret.errors?.required}" autocomplete="off" name="clientSecret" #clientSecret="ngModel" class="o-input__input" [(ngModel)]="phoenixBaseUrlConfiguration.ClientSecret" value="" required />
                    <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':phoenixBaseUrlConfigurationForm.submitted && clientSecret.errors?.required}">Please enter Client Secret</div>
                  </div>
                </div>
                <div class="c-myWizard-form__input-area" style="width:98%">
                  <div class="o-input    -required">
                    <label for="Resource">Resource</label>
                    <input id="apiBaseUrl" type="text" maxlength="100" [ngClass]="{'has-error':phoenixBaseUrlConfigurationForm.submitted && resource.errors?.required}" autocomplete="off" name="resource" #resource="ngModel" class="o-input__input" [(ngModel)]="phoenixBaseUrlConfiguration.Resource" value="" required />
                    <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':phoenixBaseUrlConfigurationForm.submitted && resource.errors?.required}">Please enter Resource</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="c-flyout__footer" style="float:right;">
        <div class="c-key-flyout-btn">
          <div class="c-select-card__left"></div>
          <div class="c-select-card__right">
            <button type="submit" href="javascript:void(0)" role="button" aria-label="Click to save"  class="o-secondary-button  -flyout-close">Save</button>
            <a type="button" href="javascript:void(0)" role="button" class="o-primary-button  -flyout-close" aria-label="Click to cancel"
               (click)="cancelBaseUrlConfiguration(phoenixBaseUrlConfigurationForm)" data-dismiss="modal">
              Cancel
            </a>
          </div>
        </div>
      </div>
    </div>
</form>
</div> -->


<form name="phoenixBaseUrlConfigurationForm" #phoenixBaseUrlConfigurationForm="ngForm" novalidate (ngSubmit)="SavePhoenixBaseUrlConfiguration(phoenixBaseUrlConfigurationForm.valid,phoenixBaseUrlConfigurationForm);">
  <div id="addAPIModal" class="c-modal " tabindex="-1" aria-hidden="true" aria-labelledby="modalDialogTitle">
    <div class="c-modal__overlay -dismiss-modal"></div>
    <div class="c-modal__container -size-medium">
      <div class="c-modal__content">
        <div class="c-modal__header">
          <h6 id="modalDialogTitle">Add API Base URL</h6>
          <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close" alt="Close" (click)="cancelBaseUrlConfiguration(phoenixBaseUrlConfigurationForm)"></button>
        </div>

        <div class="c-modal__body">
          <div class="c-myWizard-form__input">
            <div class="c-myWizard-form__input-area">
              <div class="o-input   -required   ">
                <label for="BaseUrl">Base URL</label>
                <input id="apiBaseUrl" type="text" [ngClass]="{'has-error':phoenixBaseUrlConfigurationForm.submitted && baseUrl.errors?.required}" autocomplete="off" name="baseUrl" #baseUrl="ngModel" class="o-input__input" [(ngModel)]="phoenixBaseUrlConfiguration.BaseUrl" value="" placeholder="Enter Base URL" required />
                <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':phoenixBaseUrlConfigurationForm.submitted && baseUrl.errors?.required}">Please enter Base URL</div>

              </div>
            </div>
            <div class="c-myWizard-form__input-area">
              <div class="o-input   -required   ">
                <label for="TenantUrl">Tenant URL</label>
                <input id="apiBaseUrl" type="text" maxlength="100" [ngClass]="{'has-error':phoenixBaseUrlConfigurationForm.submitted && tenantUrl.errors?.required}" autocomplete="off" name="tenantUrl" #tenantUrl="ngModel" class="o-input__input" [(ngModel)]="phoenixBaseUrlConfiguration.TenantUrl" value="" placeholder="Enter Tenant URL" required />
                <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':phoenixBaseUrlConfigurationForm.submitted && tenantUrl.errors?.required}">Please enter Tenant URL</div>
              </div>
            </div>
            <div class="c-myWizard-form__input-area">
              <div class="o-input   -required   ">
                <label for="ClientId">Client ID</label>
                <input id="apiBaseUrl" type="text" maxlength="100" [ngClass]="{'has-error':phoenixBaseUrlConfigurationForm.submitted && clientId.errors?.required}" autocomplete="off" name="clientId" #clientId="ngModel" class="o-input__input" [(ngModel)]="phoenixBaseUrlConfiguration.ClientId" value="" placeholder="Enter Client ID" required />
                <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':phoenixBaseUrlConfigurationForm.submitted && clientId.errors?.required}">Please enter Client ID</div>
              </div>
            </div>
            <div class="c-myWizard-form__input-area">
              <div class="o-input    -required">
                <label for="ClientSecret">Client Secret</label>
                <input id="apiBaseUrl" type="text" maxlength="100" [ngClass]="{'has-error':phoenixBaseUrlConfigurationForm.submitted && clientSecret.errors?.required}" autocomplete="off" name="clientSecret" #clientSecret="ngModel" class="o-input__input" [(ngModel)]="phoenixBaseUrlConfiguration.ClientSecret" value="" placeholder="Enter Client Secret" required />
                <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':phoenixBaseUrlConfigurationForm.submitted && clientSecret.errors?.required}">Please enter Client Secret</div>
              </div>
            </div>
            <!-- <div class="c-myWizard-form__input-area">
              <div class="c-select-dropdown   -required  ">
                    <label for="Client_Secret">Client Secret</label>
                  <div class="c-dropdown" id="Client_Secret">
                      <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">CMT</a>
                      <div class="c-dropdown-menu">
                          <div class="c-dropdown-body">
                              <a href="javascript:;" role="button" class="c-dropdown-item" title="Option 1">Option 1</a>
                              <a href="javascript:;" role="button" class="c-dropdown-item" title="option2">Option 2</a>
                              <a href="javascript:;" role="button" class="c-dropdown-item" title="option3">Option 3</a>
                          </div>
                      </div>
                  </div>
                      <p class="c-select__error-text">Enter the value</p>
              </div>                    </div> -->
            <div class="c-myWizard-form__input-area">
              <div class="o-input   -required   ">
                <label for="Resource">Resource</label>
                <input id="apiBaseUrl" type="text" maxlength="100" [ngClass]="{'has-error':phoenixBaseUrlConfigurationForm.submitted && resource.errors?.required}" autocomplete="off" name="resource" #resource="ngModel" class="o-input__input" [(ngModel)]="phoenixBaseUrlConfiguration.Resource" value="" placeholder="Enter Resource" required />
                <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':phoenixBaseUrlConfigurationForm.submitted && resource.errors?.required}">Please enter Resource</div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-modal__footer">
          <div class="c-modal__footer-btnGroup">
            <!-- add class -dismiss-modal to buttons that'll close the modal -->
            <!--Primary Button starts from here-->
            <button type="submit" href="javascript:void(0)" role="button" aria-label="Click to save" class="o-primary-button -flyout-close">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>



