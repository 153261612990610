import { Component, OnInit } from '@angular/core'
import { TileService } from '../../core/services/tile.service';
import { environment } from '../../../environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { TermsUseComponent } from '../terms.use.component/terms.use.component';
import { DataPrivacyComponent } from '../data.privacy.component/data.privacy.component';
var $ = (<any>window).$;

@Component({
  selector: 'footer-section',
  templateUrl: './footer.component.html',
  providers: [TileService, BsModalService]
})

export class FooterComponent implements OnInit {

  modalRef_termsUse: BsModalRef;
  modalRef_dataPrivacy: BsModalRef;
  path = this.router.url;
  date = new Date();

  constructor(private bsModalService: BsModalService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
     
  }


  openSitemap() {
    this.router.navigate(['SiteMap'], { relativeTo: this.activatedRoute });
  }

  openTermsUse() {
    this.modalRef_termsUse = this.bsModalService.show(TermsUseComponent, {
      backdrop: "static",
      keyboard: false,
      class: 'custom-terms-modal'
    });
  }

  openDataPrivacy() {
    this.modalRef_termsUse = this.bsModalService.show(DataPrivacyComponent, {
      backdrop: "static",
      keyboard: false,
      class: 'custom-data-privacy-modal'
    });
  }

}
