<div>
  <ngx-spinner template="<img src='assets/toolkit/images/loading.gif' width='64px' height='64px'/>"></ngx-spinner>
</div>
<!--<div class="wrapper" style="position:relative;height:auto!important;min-height:100%">
  <header class="container-fluid header">
    <div class="row">
      <div class="header-col col-xs-12 col-sm-4 branding">
        <img src="assets/images/Tiles/accMyWizardLogo.svg" alt="accenture | myWizard" class="img-responsive" />
      </div>
      <div class="header-col col-xs-12 col-sm-2">
        <a href="javascript:void(0);" style="width:200px" data-target="#mainContentHome" class="skip-main">Skip to Main Content </a>
      </div>
      <div class="header-col col-xs-12 col-sm-6 text-right" style="position:relative;">
        <div class="pull-right">
          <div class="clearfix settings-panel">
            <div class="pull-left user-help">
              <a href="javascript:void(0);" title="user help dropdown" attr.aria-haspopup="true" role="listbox" aria-expanded="false" attr.aria-controls="menuhelp" aria-label="user help dropdown" class="glyphicon glyphicon-question-sign glyphicon-icon tile-text" data-toggle="dropdown"></a>
              <ul class="dropdown-menu user-help-dropdown" id="menuhelp" role="menu" attr.aria-haspopup="true"  aria-expanded="false" style="margin:10px 0 0 -131px !important">
              </ul>
            </div>
            <div class="pull-left user-wrap">
              <div class="user-toggle" id="username" data-toggle="dropdown">
                <a href="#enterpriseId" aria-label="EnterpriseId|Click here to get logout Menu" attr.aria-haspopup="true" attr.aria-expanded="false" attr.aria-controls="menulogout" style="text-decoration:none;" class="glyphicon-icon"> <strong>{{enterpriseId}}</strong></a>
              </div>
              <ul role="menu" id="menulogout">
                <li><a id="SignOutId" aria-label="Logout">Logout <span class="glyphicon glyphicon-off"></span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <ngx-spinner template="<img src='assets/images/Tiles/loading.gif' width='64px' height='64px'/>"></ngx-spinner>
  <footer role="contentinfo" class="footer text-center">
    &copy; 2001-{{date|date:'yyyy'}} Accenture. All Rights Reserved. Accenture Confidential. For Internal Use Only.
    <a class="link-color" href="javascript:void(0);" title="Terms of Use" aria-label="Terms of Use(open a Popup)">Terms of Use</a>&nbsp;|&nbsp;
    <a class="link-color" href="javascript:void(0);" title="Data Privacy Statement" aria-label="Data Privacy Statement(opens a Popup)">Data Privacy Statement</a>&nbsp;|&nbsp;
    <a class="link-color" href="javascript:void(0);" target="_self" title="SiteMap" aria-label="Sitemap (Open a Popup)">SiteMap</a>
  </footer>
</div>-->
