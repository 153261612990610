import { Component, Input } from '@angular/core';
import { SessionStorage } from '../../core/context/sessionstorage';
import * as d3 from 'd3';
var Math = window.Math;
import { RIMSummaryService } from '../../core/services/rim.summary.service';
import { Subscription } from 'rxjs';
import { common } from 'src/app/core/d3/common';
@Component({
  selector: 'milestone-tile',
  templateUrl: './milestone.tile.component.html'
})

export class MilestoneTileComponent {
  @Input() rimResponse: any;
  EscalationIssue: any;
  RIMSummaryCount: any = {};
  response: any
  mileStoneOpenCount: any;
  mileStoneOverdueCount: number;
  mileStoneUpComingCount: number;
  mileStoneCompletedCount: number;
  verification: any = 0;
  mileStoneOther: any = 0;
  mileStoneOverdue: any = 0;
  mileStoneActive: any = 0;
  mileStoneUpcoming: any = 0;
  opacity: any = .8;
  mileStoneData: any = null;
  @Input()
  tile: any;
  RIMSummarySubscription: Subscription;
  milestoneDataActive: any;
  milestoneDataOverdue: any;
  milestoneDataUpcoming: any;
  svg: any;
/*  selector = 'reviewMilestone-chart';*/
  //width: any;
  //height: any;


  margin = {
    top: 20,
    bottom: 80,
    left: 50,
    right: 20,
  };

  dotsColor = ['#F8D98C', '#E1E1E1', '#A3DFA5'];

  positions: any = [
    {
      cx: -35,
      cy: -35,
      r: 33,
      color: '#F1AEDC',
      dots: '#F8D98C',
      points: '-15,-48,-36,-80,-120,-80',
      textSize: {
        line: '10px',
        circle: '20px',
      },
    },
    {
      cx: 40,
      cy: -75,
      r: 40,
      color: '#FBE7F8',
      dots: '#E1E1E1',
      points: '60,-78,20,-130,-80,-130',
      textSize: {
        line: '10px',
        circle: '20px',
      },
    },
    {
      cx: 10,
      cy: 50,
      r: 50,
      color: '#BE8DC3',
      dots: '#F8D98C',
      points: '-10, 30, 30 ,-15, 120,-15',
      textSize: {
        line: '10px',
        circle: '20px',
      },
    },
  ];
  radius = [33, 40, 50];
  containerHeight = 900;

  width = 264;

  height = 200;

  selector = 'bubble-milestone-selection';


  constructor(private sessionStorage: SessionStorage, private rimSummaryService: RIMSummaryService) { }
  ngOnChanges() {
    this.rimResponse;
    this.getRIMSummary(this.rimResponse);
  }
  ngOnInit() {
    this.RIMSummarySubscription = this.rimSummaryService.getData().subscribe((response) => {
      if (response != null)
        this.getRIMSummary(response);
    });
  }

  ngOnDestroy() {
    this.RIMSummarySubscription.unsubscribe();
  }
  labelAngle(d: any) {
    return d.startAngle;
  }
  midAngle(d: any) {
    return d.startAngle;
  }
  data: Array<any>;
  countArray: Array<any>;
  getRIMSummary(data) {
    this.response = data;
    let width = 200;
    let height = 50;
    this.data = [];
    this.countArray = [];
   // this.svg = common.getSVG(this.selector, this.width, this.height);
    if (this.response != undefined || this.response != null) {
      this.RIMSummaryCount = this.response.Summary;
      var EndToEndId = this.sessionStorage.endToEndId;
      var Type = this.sessionStorage.selectedSubmissionType == 1 ? "E" : "Cms";
      var CmsId = this.sessionStorage.selectedSubmissionType == 2 ? this.sessionStorage.clientWMSId : null;
      var radius;
      var radius1; var radius2;
      this.mileStoneOpenCount = parseInt(this.RIMSummaryCount.ActiveMilestonesOverAll);
      this.mileStoneOverdueCount = parseInt(this.RIMSummaryCount.OverdueMilestonesOverAll);
      this.mileStoneUpComingCount = parseInt(this.RIMSummaryCount.UpcomingMilestonesOverAll);
      this.mileStoneCompletedCount = parseInt(this.RIMSummaryCount.CompletedMilestonesOverAll);


      if (this.mileStoneOpenCount > 0) {
        this.mileStoneOther = (((this.mileStoneOpenCount - this.mileStoneOverdueCount - this.mileStoneUpComingCount) * 100) / this.mileStoneOpenCount);
        this.mileStoneActive = ((this.mileStoneOpenCount * 100) / (this.mileStoneOpenCount + this.mileStoneCompletedCount)).toFixed(2);
        this.mileStoneOverdue = ((this.mileStoneOverdueCount * 100) / this.mileStoneOpenCount).toFixed(2);
        this.mileStoneUpcoming = ((this.mileStoneUpComingCount * 100) / this.mileStoneOpenCount).toFixed(2);

        }
        this.data.push({ 'data': this.RIMSummaryCount.ActiveMilestonesOverAll, 'id': 'Active', gColor: '#FBE7F8', 'percent': this.mileStoneActive });
        this.data.push({ 'data': this.RIMSummaryCount.OverdueMilestonesOverAll, 'id': 'Overdue', gColor: '#BE8DC3', 'percent': this.mileStoneOverdue });
      this.data.push({ 'data': this.RIMSummaryCount.UpcomingMilestonesOverAll, 'id': 'Upcoming', gColor: '#F1AEDC', 'percent': this.mileStoneUpcoming });
      this.countArray.push(this.mileStoneOpenCount, this.mileStoneOverdueCount, this.mileStoneUpComingCount);
      this.countArray.sort((a, b) => a< b ? -1 : a > b ? 1 : 0);
        this.data.sort((a, b) => a.data < b.data ? -1 : a.data > b.data ? 1 : 0);
      var areEqual = this.countArray.every((val, i, arr) => val === arr[0]);
      if (areEqual) {
        this.radius = [40, 40, 40];
      }
      else {       
        if (this.countArray[0] == this.countArray[1]) {
          this.radius = [33, 33, 40];         
        }
        else if (this.countArray[1] == this.countArray[2]) {
          this.radius = [33, 40, 40];         
        }
        
      }
          this.mileStoneData =
            [
              { gColor: '#FBE7F8', name: 'Active', value: this.mileStoneActive >= 0 && this.mileStoneActive != null ? this.mileStoneActive : 0, slicedValue: this.mileStoneActive >= 0 && this.mileStoneActive != null ? Math.round(this.mileStoneActive.toString()) : 0, count: this.mileStoneOpenCount },
              { gColor: '#BE8DC3', name: 'Overdue', value: this.mileStoneOverdue >= 0 && this.mileStoneOverdue != null ? this.mileStoneOverdue : 0, slicedValue: this.mileStoneOverdue >= 0 && this.mileStoneOverdue != null ? Math.round(this.mileStoneOverdue.toString()) : 0, count: this.mileStoneOverdueCount },
              { gColor: '#F1AEDC', name: 'Upcoming', value: this.mileStoneUpcoming >= 0 && this.mileStoneUpcoming != null ? this.mileStoneUpcoming : 0, slicedValue: this.mileStoneUpcoming >= 0 && this.mileStoneUpcoming != null ? Math.round(this.mileStoneUpcoming.toString()) : 0, count: this.mileStoneUpComingCount },
            ];
       // }
      if (this.mileStoneOpenCount == 0 && this.mileStoneOverdueCount == 0 && this.mileStoneUpComingCount == 0) {
        d3.select("#bubble-milestone-selection").html('<div>No data to display</div>');
      }
      else
      this.drawGraph();
    }
    else {
      d3.select("#bubble-milestone-selection").html('<div>No data to display</div>');
    }
  }

  drawGraph() {
    const elem = (document.getElementsByClassName(this.selector)[0]
          ) as HTMLElement;

    elem.innerHTML = '';
    
    this.width = elem.offsetWidth;
    this.svg = common.getSVG(this.selector, this.width, this.height, true)
      .attr('viewBox', `0 0 ${this.width + 70} ${this.height / 2.0}`)
      .append('g')
      .attr('transform', `translate(${(this.width / 2)+ 40},${(this.height / 4)+ 20})`);
    const g = this.svg.selectAll('g').data(this.data).enter().append('g');

    g.append('polyline')
      .attr('stroke', '#707070')
      .attr('fill', 'none')
      .attr('stroke-width', '1px')
      .attr('stroke-dasharray', '1')
      .attr('points', (d: any, i: any) => this.positions[i].points);

    g.append('circle')
      .attr('cx', (d: any, i: number) => this.positions[i].cx)
      .attr('cy', (d: any, i: number) => this.positions[i].cy)
      .attr('r', (d: any, i: number) => this.radius[i])
      .attr('fill', (d: any, i: number) => d.gColor)
    g.append('text')
      .attr('x', (d: any, i: number) => this.positions[i].cx - 7)
      .attr('y', (d: any, i: number) => this.positions[i].cy + 7)
      .text((d: any) => d.data)
      .attr('font-size', (d: any, i: any) => this.positions[i].textSize.circle)
      .attr('font-weight', '600')
      .attr('font-family', 'graphik')
      .attr('fill', '#505050');
    g.append('circle')
      .attr('cx', (d: any, i: any) => {
        const arr = this.positions[i].points.split(',');
        return arr[arr.length - 2];
      })
      .attr('cy', (d: any, i: any) => {
        const arr = this.positions[i].points.split(',');
        return arr[arr.length - 1];
      })
      .attr('r', 5)
      .attr('fill', (d: any, i: number) => {
        if (this.data[i].id == 'Active') return '#FBE7F8';
        if (this.data[i].id == 'Overdue') return '#BE8DC3';
        if (this.data[i].id == 'Upcoming') return '#F1AEDC'; 
      });
    g.append('text')
      .attr('x', (d: any, i: any) => {
        const arr = this.positions[i].points.split(',');
        return (+arr[arr.length - 2] + +arr[2]) / 2 - 25;
      })
      .attr('y', (d: any, i: any) => {
        const arr = this.positions[i].points.split(',');
        return +arr[arr.length - 1] - 6;
      })
      .text((d: any) => d.id)
      .attr('font-size', '14px')
      .attr('font-family', 'graphik')
      .attr('fill', '#505050')
      .style('transform', 'translate(-3%, 0%)');
    g.append('text')
      .attr('x', (d: any, i: any) => {
        const arr = this.positions[i].points.split(',');
        return (+arr[arr.length - 2] + +arr[2]) / 2 - 30;
      })
      .attr('y', (d: any, i: any) => {
        const arr = this.positions[i].points.split(',');
        return +arr[arr.length - 1] + 15;
      })
      .text((d: any) => `${d.percent}%`)
      .attr('font-size', '14px')
      .attr('font-family', 'graphik')
      .attr('fill', '#505050');
  }
}

