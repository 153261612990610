<!--<div class="c-portfolio-card__body" style="padding-top:0px;">-->
  <div id="bubble-milestone-selection" class="bubble-milestone-selection">
  </div>
  
  <!--<div class="row tile-metrics-horz tile-metrics-horz-vert">
    <div class="col-xs-6">
      <div class="metrics-value"><strong id="ActiveMilestone" [innerHtml]="RIMSummaryCount.ActiveMilestonesOverAll!=null?RIMSummaryCount.ActiveMilestonesOverAll:0"></strong></div>
      <div class="metrics-text"><span>Active</span></div>
    </div>
    <div class="col-xs-6">
      <div class="metrics-value"></div>
      <div class="metrics-text"></div>
    </div>
  </div>
  <div class="row tile-metrics-horz tile-metrics-horz-vert">
    <div class="col-xs-6">
      <div class="metrics-value"><strong id="OverdueMilestone" [innerHtml]="RIMSummaryCount.OverdueMilestonesOverAll!=null?RIMSummaryCount.OverdueMilestonesOverAll:0"></strong></div>
      <div class="metrics-text"><span>Overdue</span></div>
    </div>
    <div class="col-xs-6">
      <div class="metrics-value">
        <strong id="UpComingMilestone" [innerHtml]="RIMSummaryCount.UpcomingMilestonesOverAll!=null?RIMSummaryCount.UpcomingMilestonesOverAll:0">
        </strong>
      </div>
      <div class="metrics-text"><span>Upcoming</span></div>
    </div>
  </div>-->


<!--</div>-->
