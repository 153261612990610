
 <!--<div class="modal-header">
    <button type="button" id="confirmClose" class="close" data-dismiss="modal" (click)="closePopup()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">{{"Please Confirm"}}</h4>
  </div>
  <div class="modal-body" [innerHtml]="message">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="Confirmed()" data-dismiss="modal" [attr.aria-label]="yesButton">
    {{yesButton}}</button>
    <button type="button" class="btn btn-primary" (click)="ConfirmedNo()" data-dismiss="modal" [attr.aria-label]="noButton">
    {{noButton}}</button>
  </div>
<span tabindex="0" onfocus="document.getElementById('confirmClose').focus();"></span>-->

<div id="confirmationModal" class="c-modal" style="visibility: visible;" aria-modal="true" role="dialog" aria-labelledby="modalDialogTitle">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">{{Headertitle}}</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" (click)="closePopup()" aria-label="Close" alt="Close"></button>
      </div>
      <div class="c-modal__body">
        <p class="c-modal__text" [innerHtml]="message"></p>
      </div>
      <div class="c-modal__footer">
        <div class="c-modal__footer-btnGroup">
          <!-- add class -dismiss-modal to buttons that'll close the modal -->
          <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" (click)="ConfirmedNo()" class="o-secondary-button  -dismiss-modal">
            <!---->
            {{noButton}}
          </a>
          <!--Primary Button ends from here-->                        <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" (click)="Confirmed()" class="o-primary-button  -dismiss-modal">
            <!---->
            {{yesButton}}
          </a>
          <!--Primary Button ends from here-->
        </div>
      </div>
    </div>
  </div>
</div>

<span tabindex="0" onfocus="document.getElementById('confirmClose').focus();"></span>
