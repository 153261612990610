import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'WorkstationDateTimeConversion' })
export class WorkstationDateTimeConversionPipe implements PipeTransform {
  constructor() { }
  transform(input: string) {
      const inputsplit = input.split(" ");
      const modifiedDateValue = inputsplit[0].split("-");
      const dateFormat = modifiedDateValue[0] + "/" +
        modifiedDateValue[1] + "/" + modifiedDateValue[2];
      const modifiedDateTimeValue = inputsplit[1].split(".");
      const timeFormat = modifiedDateTimeValue[0];
      input = dateFormat + " " + timeFormat;
      const date = new Date(input + ' UTC');
      return this.getLocalDateTime(date);
  }



  getLocalDateTime(date: any) {
    const monthNames = ["Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"];
    const day = ("0" + date.getDate()).slice(-2);
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = ("0" + (date.getHours() > 12 ? date.getHours() - 12 : date.getHours())).slice(-2);
    const amPm = date.getHours() >= 12 ? "PM" : "AM";
    const min = ("0" + date.getMinutes()).slice(-2);
    return "" + day + "-" + monthNames[monthIndex] + "-" + year + ' ' + hours + ':' + min + ' ' + amPm;
  }
}
