import { forwardRef, ElementRef, Component, Input, EventEmitter, Output } from '@angular/core';
import { SessionStorage } from '../context/sessionstorage';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { cloneDeep } from 'lodash';
declare var window: any;

@Component({
  selector: 'multi-selectdropdown',
  templateUrl: './multiselect.dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectdDropDownComponent),
      multi: true
    }

  ]
})

export class MultiSelectdDropDownComponent implements ControlValueAccessor {

  constructor(private sessionStorage: SessionStorage,
    private _element: ElementRef) { }

  @Input() options: any;
  @Input() disableDropdown: any = false;
  @Input() selected: any = [];
  @Input() selectText: string;
  @Input() selectedparent: any;
  @Input() ishierarchy: boolean;
  @Input() ariaLabelledby: string
  @Input() required: boolean;
  @Input() formname: NgForm;
  @Input() name: string;
  @Input() errormessage: string;
  @Input() maxChipCount: number = 2;
  isShowValidation: boolean = false;
  isDirty: boolean = false;
  IsEditable: boolean = this.sessionStorage.roleAccessCode == "E" ? true : false;
  SFEDisable: boolean = this.sessionStorage.SFEDisable;
 // otherdisabled: boolean;
  SelectedItems: any = [];
  TempOptions: any;
  optionsValue: any;
  SelectorOpen: boolean;
  isrequired: boolean;
  search: string;
  query: string;
  @Input() filterId: any;
  @Output() callback = new EventEmitter<any>();
  @Output() otherdisabled = new EventEmitter();
  @Input() othermodel: any;
  @Input() knowledgemodel: any;
  @Output() knowledgemodeldisabled = new EventEmitter();
  //  form: any;
  ngOnInit() {
    this.LoadInit();
    setTimeout(() => {
      window.dropdownComponent();
    }, 0);
  }
  ngOnChanges() {
    this.query = '';
  }

  LoadInit() {
    //this.otherdisabled = true;
    this.otherdisabled.emit({ isDisabled: true, othermodel: this.othermodel });
    //this.SelectedItems = "None Selected";
    this.SelectedItems = [];
    if (!this.selected || !this.SelectedItems || this.selected.length === 0 && this.SelectedItems !== 'None') {
      //this.isNoneSelected = true;
      this.selectText = this.selectText === undefined ? "Select" : this.selectText;
    }
    if (!this.ishierarchy) {
      if (this.TempOptions == undefined && this.selectedparent != null && this.selectedparent != undefined) {
        this.TempOptions = this.options;
        this.optionsValue = [];
        this.selectedparent.forEach((item: any, key) => {
          var isExist = this.TempOptions.filter(x => x.Id == item)[0];
          if (isExist != null && isExist != undefined) {
            this.optionsValue.push(isExist);
          }
        });
        if (this.selected && this.selected.length > 0) {
          this.selected.forEach((item: any, key) => {
            var isExist = this.TempOptions.filter(x => x.Id == item.Id)[0];
            if (isExist != null && isExist != undefined) {
              this.optionsValue.push(isExist);
            }
          });
        }
        this.optionsValue = this.GetUniqueItems(this.optionsValue, 'Id');
        this.optionsValue.forEach((value: any, key) => {
          value.IsSelected = false;
        });
        this.optionsValue.forEach((value: any, key) => {
          if (this.selected && this.selected.length > 0) {
            var selectedOptions = this.selected.map(({ Id }) => Id)

            if (selectedOptions.indexOf(value.Id) > -1) {
              value.IsSelected = true;
              this.SelectedItems.push(value);
              //if (this.SelectedItems != "" && this.SelectedItems != "None Selected") {
              //  this.SelectedItems = this.SelectedItems + ", " + value.Name;
              //}
              //else {
              //  this.SelectedItems = value.Name;
              //}
              //if (value.Id == 0) {
              //  this.otherdisabled = false;
              //}
              if (value.Id == 0) {
                this.otherdisabled.emit({ isDisabled: false, othermodel: this.othermodel });
              } else {
                this.otherdisabled.emit({ isDisabled: true, othermodel: this.othermodel });
              }
            }
          }
        });

      }

      else {
        if (this.options) {
          this.options.forEach((value: any, key) => {
            value.IsSelected = false;
          });
          this.options.forEach((value: any, key) => {
            if (this.selected && this.selected.length > 0) {
              var selectedOptions = this.selected.map(({ Id }) => Id)
              if (selectedOptions.indexOf(value.Id) > -1) {
                value.IsSelected = true;
                this.SelectedItems.push(value);
                var selectedArray = this.selected.filter(x => x.Id == 0);
                if (selectedArray.length > 0)
                  this.otherdisabled.emit({ isDisabled: false, othermodel: this.othermodel });
                 else 
                  this.otherdisabled.emit({ isDisabled: true, othermodel: this.othermodel });
  
                //if (this.SelectedItems != "" && this.SelectedItems != "None Selected") {
                //  this.SelectedItems = this.SelectedItems + ", " + value.Name;
                //}
                //else {
                //  this.SelectedItems = value.Name;
                //}
                //if (value.Id == 0) {
                //  this.otherdisabled = false;
                //}
                // if (value.Id == 0) {
                //   this.otherdisabled.emit({ isDisabled: false, othermodel: this.othermodel });
                // } else {
                //   this.otherdisabled.emit({ isDisabled: true, othermodel: this.othermodel });
                // }
              }
            }
          });
        }
      }
    }
    else {
      this.options.forEach((cms1: any, key) => {
        cms1.IsSelected = false;
        cms1.WorkstreamCMSListLevel2.forEach((cms2: any, key) => {
          cms2.IsSelected = false;
          cms2.WorkstreamCMSListLevel3.forEach((cms3: any, key) => {
            cms3.IsSelected = false;
            cms3.WorkstreamCMSListLevel4.forEach((cms4: any, key) => {
              cms4.IsSelected = false;
              cms4.WorkstreamCMSListLevel5.forEach((cms5: any, key) => {
                cms5.IsSelected = false;
              });
            });
          });
        });
      });

      if (this.selected && this.selected.length > 0) {
        var selectedOptions = this.selected.map(({ Id }) => Id)
        this.options.forEach((cms1: any, key) => {
          this.SetSelected(selectedOptions, cms1);
          cms1.WorkstreamCMSListLevel2.forEach((cms2: any, key) => {
            this.SetSelected(selectedOptions, cms2);
            cms2.WorkstreamCMSListLevel3.forEach((cms3: any, key) => {
              this.SetSelected(selectedOptions, cms3);
              cms3.WorkstreamCMSListLevel4.forEach((cms4: any, key) => {
                this.SetSelected(selectedOptions, cms4);
                cms4.WorkstreamCMSListLevel5.forEach((cms5: any, key) => {
                  this.SetSelected(selectedOptions, cms5);
                });
              });
            });
          });
        });
      }
    }
    //this.ValidateMultiSelect();
    this.validateCustomControls();
  }

  SetSelected = function (selectedOptions, value) {
    if (selectedOptions.indexOf(value.Id) > -1) {
      value.IsSelected = true;
      // this.SelectedItems = (this.SelectedItems != "" && this.SelectedItems != "None Selected") ? this.SelectedItems + ", " + value.Name : value.Name;

      this.SelectedItems.push(value);
      if (value.Id == 0) {
        this.otherdisabled = false;
      }
    }
  }
  RemoveItem(option, event) {
    if (event) {
      event.stopPropagation();
    }
    var item = this.selected.filter(x => x.Id == option.Id)[0];
    var index = this.selected.indexOf(item);
    if (index >= 0) {
      this.selected.splice(index, 1);
    }
    if (option.Id == 0) {
      this.othermodel = "";
    }
    if(option.Name == 'Knowledge Exchange'){
      this.knowledgemodel = "";
      this.knowledgemodeldisabled.emit({ isDisabled: true, knowledgemodel: this.knowledgemodel });
    }

    this.LoadInit();
    this.isDirty = true;

    if (this.formname != null && this.formname != undefined) {
      this.formname?.controls[this.name]?.markAsDirty();
    }
    this.validateCustomControls();
    this.callback.emit(this.selected);
  }
  OpenSelector() {
    if (this.SelectorOpen) {
      this.SelectorOpen = false;
    }
    else {
      this.SelectorOpen = true;
    }
  }

  OnSelect(option) {

    if (option.IsSelected) {
      option.IsSelected = false;
      var item = this.selected.filter(x => x.Id == option.Id)[0];
      var index = this.selected.indexOf(item);
      if (index >= 0) {
        this.selected.splice(index, 1);
      }

      if (option.Id == 0) {
        this.othermodel = "";
        this.otherdisabled.emit({ isDisabled: true, othermodel: this.othermodel });

      }
      if(option.Name == 'Knowledge Exchange'){
        this.knowledgemodel = "";
        this.knowledgemodeldisabled.emit({ isDisabled: true, knowledgemodel: this.knowledgemodel });
      }
    }
    else {
      if (this.ishierarchy) {
        if (this.selected != null && this.selected != undefined) {
          option.IsSelected = true;
          if (this.selected.length < 1) {
            this.selected.push(option);
          }
          else {
            this.selected = [];
            this.selected.push(option);
          }
          if (option.Id == 0) {
            this.otherdisabled.emit({ isDisabled: false, othermodel: this.othermodel });
          }
        }
        else {
          option.IsSelected = true;
          this.options.forEach((cms1: any, key) => {
            cms1.IsSelected = false;
            cms1.WorkstreamCMSListLevel2.forEach((cms2: any, key) => {
              cms2.IsSelected = false;
              cms2.WorkstreamCMSListLevel3.forEach((cms3: any, key) => {
                cms3.IsSelected = false;
                cms3.WorkstreamCMSListLevel4.forEach((cms4: any, key) => {
                  cms4.IsSelected = false;
                  cms4.WorkstreamCMSListLevel5.forEach((cms5: any, key) => {
                    cms5.IsSelected = false;
                  });
                });
              });
            });
          });
          this.selected = [];
          this.selected.push(option);
          if (option.Id == 0) {
            this.otherdisabled.emit({ isDisabled: false, othermodel: this.othermodel });
          }
          var selectedOptions = this.selected.map(({ Id }) => Id)
          this.options.forEach((cms1: any, key) => {
            this.SetSelected(selectedOptions, cms1);
            cms1.WorkstreamCMSListLevel2.forEach((cms2: any, key) => {
              this.SetSelected(selectedOptions, cms2);
              cms2.WorkstreamCMSListLevel3.forEach((cms3: any, key) => {
                this.SetSelected(selectedOptions, cms3);
                cms3.WorkstreamCMSListLevel4.forEach((cms4: any, key) => {
                  this.SetSelected(selectedOptions, cms4);
                  cms4.WorkstreamCMSListLevel5.forEach((cms5: any, key) => {
                    this.SetSelected(selectedOptions, cms5);
                  });
                });
              });
            });
          });
        }
        this.OpenSelector();
      }
      else {
        option.IsSelected = true;
        if (this.selected == null || this.selected == undefined) {
          this.selected = [];
        }
        this.selected.push(option);
        if (option.Id == 0) {
          this.otherdisabled.emit({ isDisabled: false, othermodel: this.othermodel });
        }

      }
    }
    this.LoadInit();
    this.isDirty = true;

    if (this.formname != null && this.formname != undefined) {
      this.formname?.controls[this.name]?.markAsDirty();
    }
    this.validateCustomControls();
    this.callback.emit(this.selected);
  }

  CheckIfSelected(items) {
    var isExist = items.filter(x => x.IsSelected == true);
    if (isExist.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  validateCustomControls() {

    if (this.required && this.name && this.formname) {
      let dropDownField = this.formname.controls[this.name];
      if (dropDownField) {
        if (this.SelectedItems?.length <= 0) {
          dropDownField.setErrors({ 'incorrect': true });
          dropDownField.setValidators([Validators.required])
        } else {
          dropDownField.setValidators([]);
          dropDownField.updateValueAndValidity()
        }
      }
    }
  }

  SortSelection() {
    this.query = '';
    if(!this.name.includes('CMMI'))
    {
      var optionsCopy = cloneDeep(this.options);
      this.options = optionsCopy.sort((a, b) => a.Name > b.Name ? 1 : -1).sort((a, b) => b.IsSelected - a.IsSelected);
    }
    else{
      var optionsCopy = cloneDeep(this.options);
      this.options = optionsCopy != null ? optionsCopy.sort((a, b) => a.DisplayOrder - b.DisplayOrder).sort((a, b) => b.IsSelected - a.IsSelected) : this.options;
    }
  }

  //ValidateMultiSelect() {
  //  if (this.isrequired) {
  //    /* if (this.SelectedItems == "None Selected") {*/
  //    if (this.SelectedItems?.length <= 0) {
  //      this.isShowValidation = true;
  //      if (this.form != null && this.form != undefined) {
  //        this.form.$invalid = true;
  //      }
  //    }
  //    else {
  //      this.isShowValidation = false;
  //      if (this.form != null && this.form != undefined) {
  //        if (this.form.$valid) {
  //          this.form.$invalid = false;
  //        }
  //        else {
  //          this.form.$invalid = true;
  //        }
  //      }
  //    }
  //  }
  //  else {
  //    this.isShowValidation = false;
  //  }
  //}

  MultiSelectKeyPress(option, $event, ariaLabelledby, type, last) {
    if (event != null) {
      var keycode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keycode == 13) { // code for ENTER key
        if (type == 'input')
          this.OnSelect(option);

        if (type == 'anchor') {
          setTimeout(function () {
            this._element.nativeElement.querySelector('button[name="multiselect_btn_' + this.ariaLabelledby + '"]').focus();
          });
        }
        setTimeout(function () {
          this._element.nativeElement.querySelector('input[name="' + ariaLabelledby + '_focus_0"]').focus();
        });

      }
      else if (keycode == 27) {
        this.SelectorOpen = false;
        setTimeout(function () {
          this._element.nativeElement.querySelector('button[name="multiselect_btn_' + this.ariaLabelledby + '"]').focus();
        });
      }
      else if (keycode == 9) { //TAB key check
        this.SearchFirstElement(ariaLabelledby, type, last);
      }
      else {
        return;
      }
    }
  }

  SearchFirstElement(ariaLabelledby, type, last) {
    if (last == true) {
      setTimeout(function () {
        if (type == 'input')
          this._element.nativeElement.querySelector('input[name="' + ariaLabelledby + '_focus_0"]').focus();
        else if (type == 'anchor')
          this._element.nativeElement.querySelector('a[name="' + ariaLabelledby + '_focus_0"]').focus();
      });
    }
  }




  GetUniqueItems(array, property) {
    var unique = {};
    var distinct = [];
    for (var i in array) {
      if (typeof (unique[array[i][property]]) == "undefined") {
        distinct.push(array[i]);
      }
      unique[array[i][property]] = 0;
    }
    return distinct;
  }

  @Input('value') _value = true;
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;

  }


  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
      this.LoadInit();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  trackByName(index: number, selectedRow: any): string {
    return selectedRow.Name;

  }
}
