import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterByCompanyId' })
export class FilterListPipe implements PipeTransform {
  transform(list: any, searchVal: any) {
    if (!list || !searchVal) {
      return list;
    }
    return list.filter(item => item.CompanyId == searchVal);

  }
}

@Pipe({ name: 'filterByText' })
export class FilterByText implements PipeTransform {
  transform(list: any, searchVal: any, filterId: any) {
    if (!list || !searchVal) {
      return list;
    }
    searchVal = searchVal.toLowerCase();
    return list.filter(item => { return item.Name.toLowerCase().includes(searchVal) });

  }
}

@Pipe({ name: 'filterByParentId' })
export class FilterByParentId implements PipeTransform {
  transform(list: any, value: any) {
    if (!list) {
      return list;
    }
    return list.filter(item => item.ParentId == value);

  }
}



