<!--<div class="c-portfolio-card__body">-->
  <ng-container id="riskIssue-chart" *ngIf="!IsGridEmpty">
    <div class="c-risk">
      <div class="c-risk-table-yaxis">Probability</div>
      <table class="c-risk-table">
        <tbody>
          <tr>
            <td>100%</td>
            <td id="RRIs1005" class="risk_td">{{RIM.s1005 <= 0 ? '' : RIM.s1005}}<img style="height:14px;" [src]="RIM.s1005FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs10010" class="risk_td">{{RIM.s10010 <= 0 ? '' : RIM.s10010}}<img style="height:14px;" [src]="RIM.s10010FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs10015" class="risk_td">{{RIM.s10015 <= 0 ? '' : RIM.s10015}}<img style="height:14px;" [src]="RIM.s10015FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs10020" class="risk_td">{{RIM.s10020 <= 0 ? '' : RIM.s10020}}<img style="height:14px;" [src]="RIM.s10020FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs10025" class="risk_td">{{RIM.s10025 <= 0 ? '' : RIM.s10025}}<img style="height:14px;" [src]="RIM.s10025FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
          </tr>
          <tr>
            <td>80%</td>
            <td id="RRIs804" class="risk_td">{{RIM.s804 <= 0 ? '' : RIM.s804}}<img style="height:14px;" [src]="RIM.s804FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs808" class="risk_td">{{RIM.s808 <= 0 ? '' : RIM.s808}}<img style="height:14px;" [src]="RIM.s808FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs8012" class="risk_td">{{RIM.s8012 <= 0 ? '' : RIM.s8012}}<img style="height:14px;" [src]="RIM.s8012FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs8016" class="risk_td">{{RIM.s8016 <= 0 ? '' : RIM.s8016}}<img style="height:14px;" [src]="RIM.s8016FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs8020" class="risk_td">{{RIM.s8020 <= 0 ? '' : RIM.s8020}}<img style="height:14px;" [src]="RIM.s8020FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
          </tr>
          <tr>
            <td>60%</td>
            <td id="RRIs603" class="risk_td">{{RIM.s603 <= 0 ? '' : RIM.s603}}<img style="height:14px;" [src]="RIM.s603FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs606" class="risk_td">{{RIM.s606 <= 0 ? '' : RIM.s606}}<img style="height:14px;" [src]="RIM.s606FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs609" class="risk_td">{{RIM.s609 <= 0 ? '' : RIM.s609}}<img style="height:14px;" [src]="RIM.s609FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs6012" class="risk_td">{{RIM.s6012 <= 0 ? '' : RIM.s6012}}<img style="height:14px;" [src]="RIM.s6012FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs6015" class="risk_td">{{RIM.s6015 <= 0 ? '' : RIM.s6015}}<img style="height:14px;" [src]="RIM.s6015FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
          </tr>
          <tr>
            <td>40%</td>
            <td id="RRIs402" class="risk_td">{{RIM.s402 <= 0 ? '' : RIM.s402}}<img style="height:14px;" [src]="RIM.s402FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs404" class="risk_td">{{RIM.s404 <= 0 ? '' : RIM.s404}}<img style="height:14px;" [src]="RIM.s404FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs406" class="risk_td">{{RIM.s406 <= 0 ? '' : RIM.s406}}<img style="height:14px;" [src]="RIM.s406FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs408" class="risk_td">{{RIM.s408 <= 0 ? '' : RIM.s408}}<img style="height:14px;" [src]="RIM.s408FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs4010" class="risk_td">{{RIM.s4010 <= 0 ? '' : RIM.s4010}}<img style="height:14px;" [src]="RIM.s4010FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
          </tr>
          <tr>
            <td>20%</td>
            <td id="RRIs201" class="risk_td">{{RIM.s201 <= 0 ? '' : RIM.s201}}<img style="height:14px;" [src]="RIM.s201FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs202" class="risk_td">{{RIM.s202 <= 0 ? '' : RIM.s202}}<img style="height:14px;" [src]="RIM.s202FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs203" class="risk_td">{{RIM.s203 <= 0 ? '' : RIM.s203}}<img style="height:14px;" [src]="RIM.s203FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs204" class="risk_td">{{RIM.s204 <= 0 ? '' : RIM.s204}}<img style="height:14px;" [src]="RIM.s204FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
            <td id="RRIs205" class="risk_td">{{RIM.s205 <= 0 ? '' : RIM.s205}}<img style="height:14px;" [src]="RIM.s205FlagEnabled ? 'assets/images/RequireManagementAttentionFlag.png' : ''" /></td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>20%</td>
            <td>40%</td>
            <td>60%</td>
            <td>80%</td>
            <td>100%</td>
          </tr>
        </tbody>
      </table>
      <div class="c-risk-table-xaxis">Impact</div>
      </div>
  </ng-container>
  <ng-container *ngIf="IsGridEmpty">
    <div style="height:170px">No data to display</div>
  </ng-container>
  <div class="c-risk-data">
      <div class="c-risk-data-item">
        <label>Active</label>
        <span id="ActiveRisk" [innerHTML]="RIMSummaryCount.ActiveRisksOverAll!=null?RIMSummaryCount.ActiveRisksOverAll:0"></span>
      </div>
      <div class="c-risk-data-item">
        <label>Escalations</label>
        <span id="EscalationRisk" [innerHTML]="RIMSummaryCount.EscalationRisk!=null?RIMSummaryCount.EscalationRisk:0"></span>
      </div>
      <div class="c-risk-data-item">
        <label>Overdue</label>
        <span id="OverdueRisk" [innerHTML]="RIMSummaryCount.OverdueRisksOverAll!=null?RIMSummaryCount.OverdueRisksOverAll:0"></span>
      </div>
      <div class="c-risk-data-item">
        <label>Upcoming</label>
        <span id="UpcomingRisk" [innerHTML]="RIMSummaryCount.UpcomingRisksOverAll!=null?RIMSummaryCount.UpcomingRisksOverAll:0"></span>
      </div>
  </div>
<!--</div>-->
