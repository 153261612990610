
<div class="c-myWizard-container c-thresholds">
  <div class="c-myWizard__header">
    <div class="c-myWizard__header-content-left">
      <div class="c-myWizard__header-content-head">
        <a href="javascript:;" class="icon-banner-arrow" role="button" alt="Back"
           aria-label="Back" (click)="OnBackClick()"></a>
        <div class="-header">Thresholds</div>
      </div>
      <div class="c-myWizard__header-content-head" style="font-size: 14px; padding-top: 13px;">
        <span style="border-radius: 5px; padding: 6px 10px 2px 10px;"><i class="c-thresholds-legend" style="background-color: #7500C0;">&nbsp;</i>&nbsp;<label>Core Metrics</label></span>
      </div>
      <!--<p class="c-myWizard-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam</p>-->
    </div>
    <div class="c-myWizard__header-content-right">
      <div class="c-header-data">
        <div class="c-header-dropdown">
          <a href="javascript:;" role="button" data-myw-title="Filter" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="displayRefineBy()" class="-dropdown-icon" data-flyoutid="filter-flyout"><img src="../assets/toolkit/images/thresholds/filter.svg" alt="Filter" /></a>
          <a href="javascript:;" role="button" data-myw-title="User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="DisplayThresholduserUserGuide()" class="-dropdown-icon"><img src="../assets/toolkit/images/user-guide-stories/user-guide.svg" alt="User Guide" /></a>
        </div>       
      </div>
      <div class="c-legends">
        <div class="c-legends-item">
          <i style="background-color: #FF3246;"></i>
          <span>Alert</span>
        </div>
        <div class="c-legends-item">
          <i style="background-color: #FF7800;"></i>
          <span>At Risk</span>
        </div>
        <div class="c-legends-item">
          <i style="background-color: #5AA700;"></i>
          <span>Good</span>
        </div>
        <!--<div class="c-legends-item">
          <i style="background-color: #CCCCCC;"></i>
          <span>Not Reported</span>
        </div>-->
      </div>
    </div>
  </div>
  <div class="c-table-responsive">
    <table class="c-table">
      <thead>
        <tr>
          <th scope="col" width="30%"><a href="javascript:;" [ngClass]="(sortType=='asc')?'c-table-sort active':'c-table-sort'" (click)="changeSorting()">Metric Name (Abbreviation)</a></th>
          <th scope="col" colspan="3">Metric Threshold Settings</th>
          <th scope="col">Business Context</th>
          <th scope="col" class="c-text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let threshold of thresholdData;let i=index">
          <tr>
            <td>
              <div class="c-checkbox">
                <input type="checkbox"
                       [attr.id]="'earnedval'+i"
                       class="c-checkbox__input"
                       name="unitcheckbox"
                       [disabled]="(accessFlag || threshold.WorkstreamTypeId == '12')"
                       [(ngModel)]="threshold.chkValue"
                       (change)="onChange($event,threshold,i)" />
                <i class="c-checkbox__checked"></i>
                <label for="{{'earnedval'+i}}" class="c-checkbox__label">{{threshold.Metric.Name}}<span *ngIf="threshold.Metric.Abbreviation">({{threshold.Metric.Abbreviation}})</span>&nbsp;<i class="c-thresholds-legend" *ngIf="threshold.Applicability == 'Mandatory'" style="background-color: #7500C0;">&nbsp;</i></label>
              </div>
            </td>
            <td *ngIf="threshold.ThresholdTypeid=='2'"
                [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'red-color-configNA':'red-color-config'">
              <!--(click)="onSettingClick(threshold,i,threshold.WorkstreamTypeId)"-->
              <div class="c-table-legend-item">
                <i style="background-color: #FF3246;"></i>
                <div>
                  {{threshold.Threshold1}}
                </div>
              </div>
            </td>
            <td *ngIf="threshold.ThresholdTypeid=='2'"
                [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'amber-color-configNA':'amber-color-config'">
              <!--(click)="onSettingClick(threshold,i,threshold.WorkstreamTypeId)"-->
              <div class="c-table-legend-item">
                <i style="background-color: #FF7800;"></i>
                <div>
                  {{threshold.Threshold2}}
                </div>
              </div>
            </td>
            <td *ngIf="threshold.ThresholdTypeid=='2'"
                [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'green-color-configNA':'green-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #5AA700;"></i>
                <div>
                  {{threshold.Threshold3}}
                </div>
              </div>
            </td>

            <!--this for upword direction-->
            <td *ngIf="threshold.TrendTypeid=='1'" [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'red-color-configNA':'red-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #FF3246;"></i>
                <div *ngIf="threshold.IsTrackbyPhases == false && threshold.Metric.GridCategory =='Overall'">
                  <div> &lt; {{threshold.Threshold1}}<span *ngIf="threshold.ThresholdTypeid == '3'"> %</span> </div>
                </div>
                <div *ngIf="threshold.Metric.GridCategory !='Overall'">
                  <div> &lt; {{threshold.Threshold1}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
              </div>
            </td>
            <td *ngIf="threshold.TrendTypeid=='1'" [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'amber-color-configNA':'amber-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #FF7800;"></i>
                <div *ngIf="threshold.IsTrackbyPhases == false && threshold.Metric.GridCategory =='Overall'">

                  <div>&gt;= {{threshold.Threshold1}}  <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span>,</div>
                  <div>&lt;  {{threshold.Threshold2}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
                <div *ngIf="threshold.Metric.GridCategory !='Overall'">
                  <div>&gt;= {{threshold.Threshold1}}  <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span>,</div>
                  <div>&lt; {{threshold.Threshold2}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
              </div>
            </td>
            <td *ngIf="threshold.TrendTypeid=='1'" [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'green-color-configNA':'green-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #5AA700;"></i>
                <div *ngIf="threshold.IsTrackbyPhases ==false && threshold.Metric.GridCategory =='Overall'">
                  <div>&gt;= {{threshold.Threshold2}}</div>
                </div>
                <div *ngIf="threshold.Metric.GridCategory !='Overall'">
                  <div>&gt;= {{threshold.Threshold2}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
              </div>
            </td>
            <!--this for down word direction-->
            <!--Added 2663 metric condition check for Story 1051466: Global thresholds to be updated for Number of Major Incidents core metric-->
            <td *ngIf="threshold.TrendTypeid=='3'" [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'red-color-configNA':'red-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #FF3246;"></i>
                <div *ngIf="threshold.IsTrackbyPhases == false && threshold.Metric.GridCategory =='Overall'">
                  <div>&gt; {{threshold.Threshold2}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
                <div *ngIf="threshold.Metric.GridCategory !='Overall' && threshold.MetricId != 2663">
                  <div>&gt; {{threshold.Threshold2}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
                <div *ngIf="threshold.MetricId == 2663">
                  <div>&gt;=  {{threshold.Threshold2}}  <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
              </div>
            </td>
            <td *ngIf="threshold.TrendTypeid=='3'" [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'amber-color-configNA':'amber-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #FF7800;"></i>
                <div *ngIf="threshold.IsTrackbyPhases == false && threshold.Metric.GridCategory =='Overall'">

                  <div>&gt; {{threshold.Threshold1}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span>,</div>
                  <div>&lt;=  {{threshold.Threshold2}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
                <div *ngIf="threshold.Metric.GridCategory !='Overall'&& threshold.MetricId != 2663">
                  <div>&gt; {{threshold.Threshold1}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span>,</div>
                  <div>&lt;= {{threshold.Threshold2}}</div>
                </div>
                <div *ngIf="threshold.MetricId == 2663 && threshold.DeliveryStructLevelId != 1">
                  <div>&gt; {{threshold.Threshold1}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span>,</div>
                  <div>&lt; {{threshold.Threshold2}}<span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
              </div>
            </td>
            <td *ngIf="threshold.TrendTypeid=='3'" [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'green-color-configNA':'green-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #5AA700;"></i>
                <div>
                  <div *ngIf="threshold.IsTrackbyPhases == false && threshold.Metric.GridCategory =='Overall'">&lt;= {{threshold.Threshold1}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                  <div *ngIf="threshold.Metric.GridCategory !='Overall' && threshold.MetricId != 2663">&lt;=  {{threshold.Threshold1}}<span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                  <div *ngIf="threshold.MetricId == 2663"> =  {{threshold.Threshold1}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
              </div>
            </td>

            <!--this for Bi-direction-->
            <td *ngIf="threshold.TrendTypeid=='2'" [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'red-color-configNA':'red-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #FF3246;"></i>
                <div *ngIf="threshold.IsTrackbyPhases == false && threshold.Metric.GridCategory =='Overall'">
                  <div>&lt; {{threshold.Threshold1}}  <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span>,</div>
                  <div>&gt;=  {{threshold.Threshold4}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
                <div *ngIf="threshold.Metric.GridCategory !='Overall'">
                  <div>&lt; {{threshold.Threshold1}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span>,</div>
                  <div>&gt;= {{threshold.Threshold4}}  <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
              </div>
            </td>
            <td *ngIf="threshold.TrendTypeid=='2'" [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'amber-color-configNA':'amber-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #FF7800;"></i>
                <div *ngIf="threshold.IsTrackbyPhases == false && threshold.Metric.GridCategory =='Overall'">
                  <div>&gt;= {{threshold.Threshold1}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span> &lt;{{threshold.Threshold2}}<span *ngIf="threshold.ThresholdTypeid == '3'"> %</span>,</div>
                  <div>&gt; {{threshold.Threshold3}} &lt;= {{threshold.Threshold4}}</div>
                </div>
                <div *ngIf="threshold.Metric.GridCategory !='Overall'">
                  <div>&gt;= {{threshold.Threshold1}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span> &lt;{{threshold.Threshold2}}   <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span>,</div>
                  <div>&gt; {{threshold.Threshold3}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span> &lt;= {{threshold.Threshold4}}  <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
              </div>
            </td>
            <td *ngIf="threshold.TrendTypeid=='2'" [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'green-color-configNA':'green-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #5AA700;"></i>
                <div *ngIf="threshold.IsTrackbyPhases == false && threshold.Metric.GridCategory =='Overall'">
                  <div>&gt;= {{threshold.Threshold2}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span>,</div>
                  <div>&lt;= {{threshold.Threshold3}} <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
                <div *ngIf="threshold.Metric.GridCategory !='Overall'">
                  <div>&gt;= {{threshold.Threshold2}}  <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span>,</div>
                  <div>&lt;= {{threshold.Threshold3}}  <span *ngIf="threshold.ThresholdTypeid == '3'"> %</span></div>
                </div>
              </div>
            </td>
            <td *ngIf="(threshold.TrendTypeid==0 ||threshold.TrendTypeid==null) && (threshold.ThresholdTypeid=='1' || threshold.ThresholdTypeid=='3')"
                [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'red-color-configNA':'red-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #FF3246;"></i>
              </div>
            </td>
            <td *ngIf="(threshold.TrendTypeid==0||threshold.TrendTypeid==null) && (threshold.ThresholdTypeid=='1' || threshold.ThresholdTypeid=='3')"
                [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'amber-color-configNA':'amber-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #FF7800;"></i>
              </div>
            </td>
            <td *ngIf="(threshold.TrendTypeid==0||threshold.TrendTypeid==null) && (threshold.ThresholdTypeid=='1' || threshold.ThresholdTypeid=='3')"
                [ngClass]="(accessFlag || threshold.WorkstreamTypeId == '12') ? 'green-color-configNA':'green-color-config'">
              <div class="c-table-legend-item">
                <i style="background-color: #5AA700;"></i>
              </div>
            </td>
            <td>
              <label class="-label">{{threshold.BusinessContextName}}</label>
              <div class="-context">{{threshold.Penalty}}<!--<span>0</span>--></div>
            </td>
            <td class="c-text-center">
              <a *ngIf="!accessFlag" href="javascript:;" class="icon-edit"  data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"
                 (click)="editThreshold(threshold,i,threshold.WorkstreamTypeId)"></a>
              <a *ngIf="accessFlag" href="javascript:;" class="c-view-link" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View">
                <img src="../assets/toolkit/images/view.svg" role="presentation" (click)="editThreshold(threshold,i,threshold.WorkstreamTypeId)" alt="View" />
              </a>
            </td>
          </tr>
          <!--Creating Phases inside Overall metric whenever trackbyphases is true-->
          <ng-container *ngFor="let DGthreshold1 of threshold.DGPhasesThreshold">
            <tr *ngIf="DGthreshold1.length!=0 && threshold.IsTrackbyPhases">
              <td>
                <div class="c-checkbox">
                  <label for="earnedval" class="c-checkbox__label" style="padding-left:50px">{{DGthreshold1._MetricThreshold.Metric.Stage}}</label>
                </div>
              </td>
              <td *ngIf="DGthreshold1._MetricThreshold.ThresholdTypeid=='2'" [ngClass]="accessFlag ? 'red-color-configNA':'red-color-config'">

                <div class="c-table-legend-item">
                  <i style="background-color: #FF3246;"></i>
                  <div>
                    {{DGthreshold1._MetricThreshold.Threshold1}}
                  </div>
                </div>
              </td>
              <td *ngIf="DGthreshold1._MetricThreshold.ThresholdTypeid=='1'" [ngClass]="accessFlag ? 'amber-color-configNA':'amber-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #FF7800;"></i>
                  <div>
                    {{DGthreshold1._MetricThreshold.Threshold2}}
                  </div>
                </div>
              </td>
              <td *ngIf="DGthreshold1._MetricThreshold.ThresholdTypeid=='1'" [ngClass]="accessFlag ? 'green-color-configNA':'green-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #5AA700;"></i>
                  <div>
                    {{DGthreshold1._MetricThreshold.Threshold3}}
                  </div>
                </div>
              </td>

              <!--this for upword direction-->
              <td *ngIf="DGthreshold1._MetricThreshold.TrendTypeid==1" [ngClass]="accessFlag ? 'red-color-configNA':'red-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #FF3246;"></i>
                  <div>
                    &lt; {{DGthreshold1._MetricThreshold.Threshold1}}
                  </div>
                </div>
              </td>
              <td *ngIf="DGthreshold1._MetricThreshold.TrendTypeid==1" [ngClass]="accessFlag ? 'amber-color-configNA':'amber-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #FF7800;"></i>
                  <div>
                    &gt;= {{DGthreshold1._MetricThreshold.Threshold1}},
                  </div>
                  <div>
                    &lt; {{DGthreshold1._MetricThreshold.Threshold2}},
                  </div>
                </div>
              </td>
              <td *ngIf="DGthreshold1._MetricThreshold.TrendTypeid==1" [ngClass]="accessFlag ? 'green-color-configNA':'green-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #5AA700;"></i>
                  <div>
                    &gt;= {{DGthreshold1._MetricThreshold.Threshold2}},
                  </div>
                </div>
              </td>
              <!--this for down word direction-->
              <td *ngIf="DGthreshold1._MetricThreshold.TrendTypeid==3" [ngClass]="accessFlag ? 'red-color-configNA':'red-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #FF3246;"></i>
                  <div>
                    &gt; {{DGthreshold1._MetricThreshold.Threshold2}}
                  </div>
                </div>
              </td>
              <td *ngIf="DGthreshold1._MetricThreshold.TrendTypeid==3" [ngClass]="accessFlag ? 'red-color-configNA':'red-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #FF7800;"></i>
                  <div>
                    &gt; {{DGthreshold1._MetricThreshold.Threshold1}}  ,
                  </div>
                  <div>
                    &lt;= {{DGthreshold1._MetricThreshold.Threshold2}}
                  </div>
                </div>
              </td>
              <td *ngIf="DGthreshold1._MetricThreshold.TrendTypeid==3"
                  [ngClass]="accessFlag ? 'green-color-configNA':'green-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #5AA700;"></i>
                  <div>
                    &lt;= {{DGthreshold1._MetricThreshold.Threshold1}}
                  </div>
                </div>
              </td>

              <!--this for Bi-direction-->
              <td *ngIf="DGthreshold1._MetricThreshold.TrendTypeid=='2'"
                  [ngClass]="accessFlag ? 'red-color-configNA':'red-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #FF3246;"></i>
                  <div>
                    &lt; {{DGthreshold1._MetricThreshold.Threshold1}} ,
                  </div>
                  <div>
                    &gt; {{DGthreshold1._MetricThreshold.Threshold4}}
                  </div>
                </div>
              </td>
              <td *ngIf="DGthreshold1._MetricThreshold.TrendTypeid=='2'"
                  [ngClass]="accessFlag ? 'amber-color-configNA':'amber-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #FF7800;"></i>
                  <div>
                    &gt;= {{DGthreshold1._MetricThreshold.Threshold1}}  &lt; {{DGthreshold1._MetricThreshold.Threshold2}} ,
                  </div>
                  <div>
                    &gt; {{DGthreshold1._MetricThreshold.Threshold3}} &lt;= {{DGthreshold1._MetricThreshold.Threshold4}}
                  </div>
                </div>
              </td>
              <td *ngIf="DGthreshold1._MetricThreshold.TrendTypeid=='2'" [ngClass]="accessFlag ? 'green-color-configNA':'green-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #5AA700;"></i>
                  <div>
                    &gt;= {{DGthreshold1._MetricThreshold.Threshold2}}  ,
                  </div>
                  <div>
                    &lt;= {{DGthreshold1._MetricThreshold.Threshold3}}
                  </div>
                </div>
              </td>
              <td *ngIf="(DGthreshold1._MetricThreshold.TrendTypeid==0||DGthreshold1._MetricThreshold.TrendTypeid==null)  && threshold.ThresholdTypeid=='1'" [ngClass]="accessFlag ? 'red-color-configNA':'red-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #FF3246;"></i>
                </div>
              </td>
              <td *ngIf="(DGthreshold1._MetricThreshold.TrendTypeid==0||DGthreshold1._MetricThreshold.TrendTypeid==null)  && threshold.ThresholdTypeid=='1'" [ngClass]="accessFlag ? 'amber-color-configNA':'amber-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #FF7800;"></i>
                </div>
              </td>
              <td *ngIf="(DGthreshold1._MetricThreshold.TrendTypeid==0||DGthreshold1._MetricThreshold.TrendTypeid==null) && threshold.ThresholdTypeid=='1'" [ngClass]="accessFlag ? 'green-color-configNA':'green-color-config'">
                <div class="c-table-legend-item">
                  <i style="background-color: #5AA700;"></i>
                </div>
              </td>

              <td>
                <label class="-label">{{threshold.BusinessContextName}}</label>
                <div class="-context">{{threshold.Penalty}}<!--<span>0</span>--></div>
              </td>
              <td class="c-text-center">

                <a *ngIf="!accessFlag" class="icon-edit" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"
                   (click)="editThreshold(threshold,i,null)"></a>
                <a *ngIf="accessFlag" href="javascript:;" class="c-view-link" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View">
                <img src="../assets/toolkit/images/view.svg" role="presentation" (click)="editThreshold(threshold,i,null)" alt="View" /></a>
              </td>


            </tr>

          </ng-container>
        </ng-container>
        <ng-container *ngIf="thresholdData == null || thresholdData == undefined || thresholdData.length==0">
          <tr>
            <td colspan="6"> No Records Found </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  
  <div class="c-pagination-container">
    <nav>
      <ul class="c-pagination_list">
        <li class="c-pagination_arrow">
          <a href="javascript:;" class="c-pagination_previous"  (click)="curPage=curPage-1;pagination(curPage)"
             [ngClass]="{'disablebtn': curPage == 0}" [disabled]="curPage == 0" tabindex="-1">
            <img src=" ../assets/toolkit/images/pagination-icon.svg" alt="Previous">
          </a>
        </li>
        <li class="c-pagination_page-item">
          <a href="#" class="c-pagination_link" tabindex="-1">
            <span class="c-pagination_count" [disabled]="curPage == 0">{{curPage + 1}}</span>
            <span class="c-pagination_target" [disabled]="curPage >=  totalMetrics/pageSize - 1"> <span>of </span>  {{noOfPages}}</span>
          </a>
        </li>
        <li class="c-pagination_arrow">
          <a href="javascript:;" class="c-pagination_next"  
            (click)="curPage=curPage+1;pagination(curPage)"
             [ngClass]="{'disablebtn': curPage >=  totalMetrics/pageSize - 1}" tabindex="-1">
            <img src=" ../assets/toolkit/images/pagination-icon.svg" alt="Next">
          </a>
        </li>
      </ul>
    </nav>
    <div class="position-relative">
      <label class="sr-only">Page Selection</label>
    </div>
  </div>
</div>
<div class="c-select-card">
  <div class="app-container">
    <div class="c-select-card__wrapper">
      <div class="c-select-card__right">
        <button class="o-secondary-button" role="button" [ngClass]="{'-disabled':!this.isDisabled || accessFlag}"(click)="onCancelClick()"
           >Reset</button>
        <button class="o-primary-button" role="button" [ngClass]="{'-disabled':!this.isDisabled ||accessFlag}" (click)="showCustomizePopup()"
           >Customize</button>
      </div>
    </div>
  </div>
</div>
<div class="c-select-card__placeholder"></div>

<div id="alertModal" class="c-modal c-alert-modal" *ngIf="showAlertPopup" tabindex="-1" aria-hidden="false" aria-labelledby="modalDialogTitle" aria-modal="true" role="dialog">
  <div class="c-modal__overlay -dismiss-modal" (click)="closeAlertPopup()"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">        
        <h6 id="modalDialogTitle">Alert!</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close" alt="Close" (click)="closeAlertPopup()"></button>
      </div>
      <div class="c-modal__body">
        <div class="c-alert-modal-note">Missing Thresholds for Core Metrics</div>
        <p class="c-modal__text">The Core Metrics listed below will not be categorized as 'Red/Amber/Green' upon submission due to missing thresholds. So, it is recommended to update the thresholds before proceeding with submission.</p>
        <ul class="c-manage-dashboard-list">
          <li *ngFor="let threshold of missedThresolds">{{threshold.Metric.Name}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
