import { Component } from '@angular/core';
import { TileService } from '../../core/services/tile.service';
import { enumArea } from '../../core/context/enum';
import { SessionStorage } from '../../core/context/sessionstorage';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadCastService } from '../../core/services/broadcast.service';

@Component({
  templateUrl: './task.component.html',
  selector: 'task',
  providers: [TileService]
})

export class TaskComponent {

  constructor(private tileService: TileService, private sessionStorage: SessionStorage, private activatedRoute: ActivatedRoute, private router: Router, private broadCastService: BroadCastService) { }

  tiles: any;

  ngOnInit() {
    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['MyWizard/Back/Task'], { queryParams : { endToEndUId: this.activatedRoute.snapshot.params.redirectId } });
      return false;
    }
    this.tileService.getTiles(enumArea.Tasks).subscribe((response) => {
      this.tiles = response;
    });
    this.broadCastService.broadcastScopeSelector({
      isScopeSelectorVisible: true, isScopeSelectorDropDownVisible: true,
      isSetAsDefaultVisible: true
    });
  }
  trackByTileId(index: number, item: any): any {
    return item.TileId;
  }
}
