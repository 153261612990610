<!--<div *ngIf="scopeDetails" role="region" aria-labelledby="Scopetab" class="tab-pane " id="scope" [ngClass]="{'active': (SubmissionType != 2)}">-->
<!--<div role="region" aria-labelledby="Scopetab" class="tab-pane " id="scope" [ngClass]="{'active': (SubmissionType != 2)}">-->


<form *ngIf="scopeDetails" name="scopeform" #scopeform="ngForm" novalidate (ngSubmit)="SaveDelivery(scopeform.valid,scopeform)">
  <div class="c-myWizard-container" aria-labelledby="Scopetab">
    <div class="c-myWizard__header">
      <div class="c-myWizard__header-content-left">
        <div class="c-myWizard__header-content-head">
          <a href="javascript:;" class="icon-banner-arrow" role="button" (click)="OnBackClick()" alt="Back" aria-label="Back"></a>
          <div class="-header">Scope</div>
        </div>
        <div class="c-myWizard-text" *ngIf="!IsProfileCompleted">Complete the preliminary mandatory step to setup your delivery profile</div>
      </div>
      <div class="c-myWizard__header-content-right">
        <download-file source="Scope"></download-file>
      </div>
    </div>
    <div class="c-scope-error" *ngIf="(showDeliveryScopeValidation && !HideDeliveryScope) ||( SubmissionType == 1 && scopeDetails.UserNotificationMessage && this.scopeDetails.IsRBETech)">
      <ul class="c-myWizard-list -customScroll">
        <li *ngIf="SubmissionType == 1 && scopeDetails.UserNotificationMessage && this.scopeDetails.IsRBETech" [innerHTML]="scopeDetails.UserNotificationMessage"></li>
        <li *ngIf="showDeliveryScopeValidation && !HideDeliveryScope">The Delivery Scope selection is not aligned with the applicable Delivery Type. Please select the appropriate Delivery Type or update the Delivery Scope value(s).</li>
      </ul>
    </div>
    <div class="c-myWizard-form__input">
      <div class="c-myWizard-form__input-area">
        <div class="c-custom-select__wrapper" [ngClass]="{'-required':this.scopeDetails.IsRBETech}">
          <label>Primary Technologies</label>
          <multi-selectdropdown name="endtoendTechnology" #endtoendTechnology="ngModel" id="endtoendTechnologiesdropdown" [options]="scopeDetails.PrimaryTechnologies"
                                [selected]="scopeDetails.PrimaryTechonologyInputs" [required]="this.scopeDetails.IsRBETech" [disableDropdown]="RoleAccess=='V'" [formname]="scopeform" [name]="'endtoendTechnology'"
                                [errormessage]="'Please select Primary Technologies'"
                                [(ngModel)]="scopeDetails.PrimaryTechonologyInputs" [ariaLabelledby]="'endtoendTechnologies'"></multi-selectdropdown>
          <!--<p aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':this.scopeDetails.IsRBETech && scopeform.submitted && endtoendTechnology.errors?.required && (scopeDetails.PrimaryTechonologyInputs==''||scopeDetails.PrimaryTechonologyInputs==null||scopeDetails.PrimaryTechonologyInputs==undefined)}">Please select Primary Technologies</p>-->
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div class="c-custom-select__wrapper">
          <label for="genAI">GenAI related productivity covered in the contract? <a *ngIf="scopeDetails.GenAIDisableFlag == 'Y'" href="javascript:;" class="c-view-link" attr.data-myw-title="{{ scopeDetails.GenAIModifiedByUser == 'System' ? 'Rolled up from TMS reporting' : 'Sourced from latest EndToEnd reporting' }}" data-myW-toggle="tooltip" data-myW-placement="top" data-myW-custClass="-view-tooltip"><img src="../assets/toolkit/images/view.svg" role="presentation" alt="View" /></a></label>
          <search-dropdown name="genAI"
                           #genAI="ngModel"
                           [formname]="scopeform"
                           [disableDropdown]="RoleAccess=='V' || scopeDetails.GenAIDisableFlag == 'Y'"
                           [options]="GenAI"
                           [selected]="scopeDetails.GenAIFlag" [isShow]="true"
                           [(ngModel)]="scopeDetails.GenAIFlag"
                           aria-labelledby="GenAI">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area " *ngIf="endToEndType == 'C' && scopeDetails.IsDisplaySustainability">
        <div class="c-custom-select__wrapper -required">
          <label for="TSL">Target Sustainability Level</label>
          <search-dropdown name="TSL"
                           #TSL="ngModel"
                           [formname]="scopeform"
                           [disableDropdown]="RoleAccess=='V'"
                           [options]="scopeDetails.TargetSustainability"
                           [selected]="scopeDetails.TargetSustainabilityLevelId" [isShow]="true"
                           [(ngModel)]="scopeDetails.TargetSustainabilityLevelId"
                           aria-labelledby="TargettSustainabilityLevel">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C' && scopeDetails.IsDisplaySustainability">
        <div class="c-custom-select__wrapper">
          <label class="c-sustainability_label" for="CSL">Current Sustainability Level&nbsp;<span class="required">*</span><a href="javascript:void(0)" data-toggle="modal" class="c-myWizard-select-opt" (click)="getCurrentSustainabilityLevel()"><img *ngIf="RoleAccess !='V'" src="../assets/images/refresh-icon.svg" role="presentation" alt="View" /></a><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer" data-target="#currentSustainabilityAssessment" data-toggle="modal" class="c-sustainability-assessment" (click)="currentSustainabilityAssessment()"><i class="icon-external-link"></i><span>Take Assessment</span></a></label>
          <p class="o-input__input -disabled" [innerHTML]="scopeDetails.CurrentSustainabilityLevelName"></p>
          <!--<search-dropdown name="CSL"
                           #CSL="ngModel"
                           [formname]="scopeform"
                           [disableDropdown]="true"
                           [options]="scopeDetails.CurrentSustainability"
                           [required]="true"
                           [selected]="scopeDetails.CurrentSustainabilityLevelId" [isShow]="true"
                           [(ngModel)]="scopeDetails.CurrentSustainabilityLevelId"
                           aria-labelledby="CurrentSustainabilityLevel">
          </search-dropdown>-->
        </div>
      </div>
      <div class="c-myWizard-form__input-area">
        <div class="c-custom-select__wrapper">
          <label>Services/Factories Used</label>
          <multi-selectdropdown name="serviceused" #serviceused="ngModel" id="ServicesUsed" [options]="scopeDetails.DeliverySubgroupServicesUsed"
                                [selected]="scopeDetails.ServicesUsedInputs" [disableDropdown]="RoleAccess=='V'"
                                [(ngModel)]="scopeDetails.ServicesUsedInputs" [ariaLabelledby]="'ServicesUsed'"></multi-selectdropdown>
        </div>
      </div>

      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'I' || scopeDetails.IsCMWContract">
        <div class="c-custom-select__wrapper ">
          <label>Platform/ Domain/ Offering</label>
          <multi-selectdropdown name="platform" #platform="ngModel" id="Platform" [options]="scopeDetails.PlatformList"
                                [selected]="scopeDetails.PlatformInputs" [disableDropdown]="RoleAccess=='V' || scopeDetails.IsCMWContract" [formname]="scopeform" [name]="'platform'"
                                [(ngModel)]="scopeDetails.PlatformInputs" [ariaLabelledby]="'Platform/ Domain/ Offering'" [errormessage]="'Please select Platform/ Domain/ Offering'"></multi-selectdropdown>

        </div>
      </div>

      <!--<div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'I' || scopeDetails.IsCMWContract">
        <div class="o-input">
          <label for="Platform" id="Platform">Platform/ Domain/ Offering</label>
          <textarea type="text" class="o-input__input" id="Platform" aria-labelledby="Platform/ Domain/ Offering" name="platform" readonly title=" {{ RoleAccess == 'V' || scopeDetails.IsCMWContract  ? scopeDetails.PlatformMapping : scopeDetails.PlatformMapping + '&#013;Click on link to add/change Platform/ Domain/ Offering Used' }}" [(ngModel)]="scopeDetails.PlatformMapping" rows="2"></textarea>
        </div>
      </div>-->

      <div class="c-myWizard-form__input-area">
        <div class="c-custom-select__wrapper">
          <label>Involved Factories</label>
          <multi-selectdropdown name="manageinvolvedfactory" #endtoendTechnology="ngModel" id="InvolvedFactories" [options]="involvedFactoriesList"
                                [selected]="scopeDetails.InvolvedFactoryInputs" [disableDropdown]="RoleAccess == 'V'"
                                [(ngModel)]="scopeDetails.InvolvedFactoryInputs" [ariaLabelledby]="'InvolvedFactories'"></multi-selectdropdown>
        </div>
      </div>

      <div class="c-myWizard-form__input-area -comp-col">
        <div class="o-text-area">
          <label for="Solution_Description" id="SolutionDescription">Solution Description</label>
          <textarea class="o-text-area__input -customScroll" placeholder="Enter the Text" id="Solution_Description" name="solutionDescription" aria-labelledby="SolutionDescription" [disabled]="RoleAccess=='V'" [ngClass]="{'-disabled': RoleAccess === 'V'}" [(ngModel)]="scopeDetails.Description" rows="6"></textarea>
          <!--<span class="o-text-area__total-count">Max Limit 500 Characters</span>-->
        </div>
        <div class="c-custom-select__wrapper -required" *ngIf="isCMMScopeOfWork">
          <label id="CMMScope">Cloud Delivery - Migration Scope Of Work</label>
          <multi-selectdropdown name="manageCMMScope" #manageCMMScope="ngModel" id="CMMScopeOfWorks" [options]="scopeDetails.CMMScopeOfWorks"
                                [selected]="scopeDetails.CMMScopeOfWorkInputs"
                                [required]="true"
                                [formname]="scopeform"
                                [disableDropdown]="RoleAccess == 'V'"
                                [(ngModel)]="scopeDetails.CMMScopeOfWorkInputs" [ariaLabelledby]="'CMMScopeOfWorks'"></multi-selectdropdown>
          <p aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':scopeform.submitted && manageCMMScope.errors?.required}">Please select Cloud Delivery - Migration Scope Of Work</p>
        </div>
        <div class="c-custom-select__wrapper -required" *ngIf="isCDMScopeOfWork">
          <label id="CDMScope">Cloud Data Migration Scope Of Work</label>
          <multi-selectdropdown name="manageCDMScope" #manageCDMScope="ngModel" id="CDMScopeOfWorks" [options]="scopeDetails.CDMScopeOfWorks"
                                [selected]="scopeDetails.CDMScopeOfWorkInputs"
                                [required]="true"
                                [formname]="scopeform"
                                [disableDropdown]="RoleAccess == 'V'"
                                [(ngModel)]="scopeDetails.CDMScopeOfWorkInputs" [ariaLabelledby]="'CDMScopeOfWorks'"></multi-selectdropdown>
          <p aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':scopeform.submitted && manageCDMScope.errors?.required}">Please select Cloud Data Migration Scope Of Work</p>
        </div>
      </div>
    </div>
  </div>
  <!--<hr *ngIf="!CreatePage" />
  <div *ngIf="!CreatePage" class="row">
    <div class="col-xs-12 col-md-6 form-horizontal">
      <div class="form-group">
        <label class="col-xs-12 col-sm-5 col-lg-4 control-label"><strong>Do you wish to report refreshed set of SI Core metrics</strong></label>
        <div class="col-xs-12 col-sm-7 col-lg-8">
          <div class="toggle-switch">
            <label class="switch" for="siCoreMetricsIndicator" tabindex="0" (keypress)="toggleSICoreMetrics($event)">
              <input tabindex="-1" id="siCoreMetricsIndicator" name="siCoreMetricsIndicator" value="{{scopeDetails.SICoreMetricsIndicator}}" class="switch-input" type="checkbox"
                     [disabled]="(RoleAccess=='V' || scopeDetails.IsNewSIMetricsDisabled) " [ngClass]="{'switch-disable' : scopeDetails.IsNewSIMetricsDisabled }" (click)="updateSICoreMetricsIndicator()" [checked]="scopeDetails.SICoreMetricsIndicator" aria-label="Click here select Yes/No" />
              <span class="switch-label" data-on="Yes" data-off="No"></span>
              <span class="switch-handle"></span>
              <span class="switch-text">&nbsp;</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />-->
  <div class="c-scope-phoenix__container">
    <div class="c-myWizard-container">
      <div class="c-checkbox ">
        <input id="phoenixMapping" name="phoenixMappingEnabled" value="{{scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled}}" class="c-checkbox__input" type="checkbox"
               [disabled]="CreatePage ? false : (!IsAdmin || scopeDetails.PhoenixMapping.IsGovernanceAutoIntegration  || RoleAccess == 'V') " [ngClass]="{'switch-disable' : CreatePage ? false : (!IsAdmin || scopeDetails.PhoenixMapping.IsGovernanceAutoIntegration || RoleAccess == 'V' ) }" [checked]="scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled" (click)="phoenixMappingChange($event,scopeform)" aria-label="Click here select Yes/No" />
        <i class="c-checkbox__checked"></i>
        <label class="c-checkbox__label" for="phoenixMapping" (keypress)="phoenixMappingChange($event,scopeform)">Allow myWizard Phoenix Integration</label>
      </div>

      <!--<div class="c-scope-phoenix__input-form">-->
      <div class="c-myWizard-form__input" *ngIf="(CreatePage ? true : IsAdmin) && RoleAccess =='E'&& scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled">
        <div class="c-myWizard-form__input-area">
          <div class="c-custom-select__wrapper   -required  ">
            <div class="c-select-dropdown-add">
              <label for="API">API Base URL</label>
              <a href="javascript:void(0)" data-flyoutid="addAPIModal" role="button" data-target="#addAPIModal" data-toggle="modal" data-modalid="addAPIModal" *ngIf="(CreatePage ? true : IsAdmin) && RoleAccess =='E' && scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled" (click)="showPhoenixBaseUrlConfiguration()"><img src="../assets/toolkit/images/key-contacts/add.svg" role="presentation" alt="Add" />API Base URL</a>
            </div>
            <search-dropdown id="serviceInstanceId" name="serviceInstanceId" #serviceInstanceId="ngModel" [labelInfo]="'APIBaseURL'" [options]="scopeDetails.PhoenixMapping.APIBaseURLs" [isShow]="true" [formname]="scopeform"
                             [required]="scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled"
                             [selectText]="'Select'"
                             [selected]="scopeDetails.PhoenixMapping.ServiceInstanceId"
                             [errormessage]="'Please select API Base URL'"
                             [disableDropdown]="scopeDetails.PhoenixMapping.IsGovernanceAutoIntegration"
                             [(ngModel)]="scopeDetails.PhoenixMapping.ServiceInstanceId">
            </search-dropdown>
            <!--<p aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':scopeform.submitted && serviceInstanceId.errors?.required}">Please select API Base URL</p>-->
          </div>
        </div>
        <div class="c-myWizard-form__input-area" *ngIf="(CreatePage ? true : IsAdmin) && RoleAccess =='E'&& scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled">
          <div class="o-input    ">
            <label for="Client_UID">Client UID</label>
            <input type="text" id="Client_UID" placeholder="Enter client UID" aria-labelledby="PhoenixClientId" name="phoenixClientId" #phoenixClientId="ngModel" value="{{scopeDetails.PhoenixMapping.PhoenixClientId}}" [ngClass]="{'has-error':scopeform.submitted && phoenixClientId?.errors?.required}" [required]="scopeDetails.PhoenixMapping.DeliveryConstructId != '' && scopeDetails.PhoenixMapping.DeliveryConstructId != null" maxlength="50" class="o-input__input" [(ngModel)]="scopeDetails.PhoenixMapping.PhoenixClientId" [disabled]="scopeDetails.PhoenixMapping.IsGovernanceAutoIntegration" autocomplete="off" />
            <!--<p class="o-input__error-text">Enter the value</p>
            <span class="o-input__char-text">30/30 characters</span>-->
            <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':scopeform.submitted && phoenixClientId?.errors?.required}">Please enter Client UID</div>
          </div>
        </div>
        <div class="c-myWizard-form__input-area" *ngIf="(CreatePage ? true : IsAdmin) && RoleAccess =='E'&& scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled">
          <div class="o-input    ">
            <label for="Construct_UID">Delivery Construct UID</label>
            <input type="text" id="Construct_UID" placeholder="Enter delivery construct UID" aria-labelledby="DeliveryConstructId" #deliveryConstructId="ngModel" name="deliveryConstructId" [required]="scopeDetails.PhoenixMapping.PhoenixClientId != '' && scopeDetails.PhoenixMapping.PhoenixClientId != null" [ngClass]="{'has-error':scopeform.submitted && deliveryConstructId?.errors?.required}" value="{{scopeDetails.PhoenixMapping.DeliveryConstructId}}" maxlength="50" class="o-input__input" [(ngModel)]="scopeDetails.PhoenixMapping.DeliveryConstructId" [disabled]="scopeDetails.PhoenixMapping.IsGovernanceAutoIntegration" autocomplete="off" />
            <!--<p class="o-input__error-text">Enter the value</p>
            <span class="o-input__char-text">30/30 characters</span>-->
            <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':scopeform.submitted && deliveryConstructId?.errors?.required}">Please enter Delivery Construct UID</div>
          </div>
        </div>
      </div>
      <!--</div>-->
    </div>
  </div>



  <div class="c-myWizard-container">
    <div class="c-scope-type-container">
      <div class="c-checkbox ">
        <input class="c-checkbox__input" id="capacityServices" name="capacityService" value="{{scopeDetails.CapacityServices}}" type="checkbox" [disabled]="RoleAccess=='V'" [(ngModel)]="scopeDetails.CapacityServices" (change)="capacityServiceChange()" aria-label="Click here select Yes/No" checked />
        <i class="c-checkbox__checked"></i>
        <label for="capacityServices" class="c-checkbox__label" (keypress)="ToggleScope($event)">Capacity Services</label>
      </div>
      <h6 class="c-scope-header">Delivery Type<span *ngIf="!scopeDetails.CapacityServices && DeliveryTypeMandatory" class="scope-field-mandatory">*</span></h6>
      <div class="c-scope-checkbox">
        <div class="c-checkbox " *ngFor="let delivery of scopeDetails.DeliveryType;let i=index; trackBy : trackById;">
          <input class="c-checkbox__input" name="deliverytype" [disabled]="RoleAccess=='V'" id="{{delivery.Id + '_deliverytypeid'}}" type="checkbox" value="{{delivery.Id}}" (click)="deliveryChange(delivery,$event)" [checked]="delivery.IsSelected" aria-label="Click here select Yes/No" />
          <i class="c-checkbox__checked"></i>
          <label class="c-checkbox__label" for="{{delivery.Id + '_deliverytypeid'}}" (keypress)="deliveryChange(delivery,$event)">
            <span class="c-checkbox__label">{{delivery.Name}}</span>
          </label>
        </div>
      </div>
      <!--<div style="margin-top:16px" *ngIf="!DeliveryTypeMandatory"><i>For all RDE=Strategy & Consulting Contracts, please do not make a Delivery Type selection for now</i></div>-->
      <div class="c-methodology" *ngIf="scopeDetails.PhoenixMapping.IsPhoenixMappingEnabled">
        <h6 class="c-scope-header">Methodology & Iteration Type<span class="scope-field-mandatory">*</span></h6>
        <div class="c-myWizard-form__input c-scope-types">
          <div class="c-myWizard-form__input-area" *ngFor="let iterationMethodology of scopeDetails.PhoenixMapping.IterationTypeMethodologies; let i = index;">
            <div class="c-custom-select__wrapper ">
              <!--<a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>-->
              <!--<div class="c-dropdown-menu">
              <div class="c-dropdown-body">-->
              <search-dropdown [labelInfo]="'Methodology'"
                               [options]="iterationMethodology.Methodologies" [isShow]="true" [formname]="scopeform"
                               [disableDropdown]="disableMethodology(iterationMethodology)" [required]="validateMethodology(scopeform)"
                               [selectText]="'Select Methodology'"
                               [selected]="iterationMethodology.MethodologyTypeId" (callback)="methodologyChange(iterationMethodology,i)"
                               id="methodologyId_{{i}}" name="methodology_{{i}}" #methodologyId="ngModel"
                               [(ngModel)]="scopeDetails.PhoenixMapping.IterationTypeMethodologies[i].MethodologyTypeId">
              </search-dropdown>
              <!--</div>
              </div>-->
              <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error': scopeform.submitted && validateMethodology(scopeform)}">Please select Methodology</div>
            </div>
          </div>
          <div class="c-myWizard-form__input-area" *ngFor="let iterationMethodology of  scopeDetails.PhoenixMapping.IterationTypeMethodologies; let i = index;">
            <div class="c-custom-select__wrapper    ">
              <!--<div class="c-dropdown" id="select1">-->
              <!--<a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>-->
              <!--<div class="c-dropdown-menu">
              <div class="c-dropdown-body">-->
              <search-dropdown [labelInfo]="'IterationTypes'" [options]="iterationMethodology.IterationTypes"
                               [isShow]="true" [formname]="scopeform"
                               [disableDropdown]="!iterationMethodology.MethodologyTypeId || RoleAccess !='E'"
                               [required]="iterationMethodology.MethodologyTypeId != null" [selectText]="'Select IterationType'"
                               [filterId]="iterationMethodology.MethodologyTypeId"
                               [selected]="iterationMethodology.IterationTypeId" id="iteration_{{i}}"
                               name="iteration_{{i}}" #iteration="ngModel"
                               [(ngModel)]="scopeDetails.PhoenixMapping.IterationTypeMethodologies[i].IterationTypeId">
              </search-dropdown>
              <div aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error': scopeform.submitted && iteration.errors?.required}">Please select Iteration Type</div>
              <!--</div>
              </div>-->
              <!--</div>-->
            </div>
          </div>

        </div>
        <div class="c-legends-item">
          <i style="background-color: #7500C0;"></i>
          <span>This field is required to enable Phoenix configuration of workstream type to be auto created at E2E/CMS level.</span>
        </div>
      </div>
      <div id="accordion-scope-types" class="c-scope-accordion">
        <div class="c-accordion -open">
          <div class="c-scope-accordion-header">
            <div class="-header">Delivery Functions</div>
            <a href="javascript:;" class="-link" role="button" aria-label="Click here to see the Delivery Functions definitions" data-myw-title="Click here to see the Delivery Functions definitions" data-myw-toggle="tooltip" data-myw-placement="bottom" data-toggle="modal" data-modalid="deliveryModal" (click)="showDeliveryFunctionDescription()">What is it?</a>
            <div class="c-scope-accordion-header-collapse">
              <button type="button" class="c-collapse" ng- data-target="#deliveryFunctions" aria-expanded="true" aria-controls="delivery-func"></button>
            </div>
          </div>

          <div id="delivery-func" class="-collapse -show" [ngClass]="{'-show':isShowValuefn}" data-parentid="accordion-scope-types">
            <div class="c-accordion__container">
              <div class="c-delivery-table -customScroll">
                <div class="c-delivery-table__header">
                  <div class="c-delivery-table__item">PPSM</div>
                  <div class="c-delivery-table__item">Development & Test Management</div>
                  <div class="c-delivery-table__item">Service Management</div>
                  <div class="c-delivery-table__item">Security Management</div>
                </div>
                <div class="c-delivery-table__body">
                  <div class="c-delivery-table__item">
                    <div class="c-checkbox " *ngFor="let ppsm of scopeDetails.TempDeliveryFunctionPPSMList | orderBy:'Name'; trackBy : trackById;">
                      <input name="deliveryFunctionPPSM" class="c-checkbox__input" [disabled]="RoleAccess=='V' || ppsm.IsDisabled" [ngClass]="{'switch-disable' : ppsm.IsDisabled }" id="{{ppsm.Id + '_psmdeliverfunctionid'}}" type="checkbox" value="{{ppsm.Id}}" (click)="deliveryFunctionClick('PPSM', ppsm, $event)" [checked]="ppsm.IsSelected" aria-label="Click here select Yes/No" />
                      <i class="c-checkbox__checked"></i>
                      <label class="c-checkbox__label" for="{{ppsm.Id + '_psmdeliverfunctionid'}}" (keypress)="deliveryFunctionClick('PPSM', ppsm, $event)">
                        <span class="c-checkbox__label" [textContent]="ppsm.Name"></span>
                      </label>
                    </div>
                  </div>

                  <div class="c-delivery-table__item">
                    <div class="c-checkbox " *ngFor="let dtm of scopeDetails.TempDeliveryFunctionDTMList | orderBy:'Name'; trackBy : trackById;">
                      <input name="deliveryFunctionDTM" class="c-checkbox__input" [disabled]="RoleAccess=='V'|| dtm.IsDisabled" [ngClass]="{'switch-disable' : dtm.IsDisabled }" id="{{dtm.Id + '_dtmdeliveryfunctionid'}}" type="checkbox" value="{{dtm.Id}}" (click)="deliveryFunctionClick('DTM', dtm, $event)" [checked]="dtm.IsSelected" aria-label="Click here select Yes/No" />
                      <i class="c-checkbox__checked"></i>
                      <label class="c-checkbox__label" for="{{dtm.Id + '_dtmdeliveryfunctionid'}}" (keypress)="deliveryFunctionClick('DTM', dtm, $event)">
                        <span class="c-checkbox__label" [textContent]="dtm.Name"></span>
                      </label>
                    </div>
                  </div>

                  <div class="c-delivery-table__item">
                    <div class="c-checkbox " *ngFor="let sm of scopeDetails.TempDeliveryFunctionSMList | orderBy:'Name'; trackBy : trackById;">
                      <input name="deliveryFunctionSM" class="c-checkbox__input" [disabled]="RoleAccess=='V'|| sm.IsDisabled" [ngClass]="{'switch-disable' : sm.IsDisabled }" id="{{sm.Id + '_smdeliveryfunctionid'}}" type="checkbox" value="{{sm.Id}}" (click)="deliveryFunctionClick('SM', sm, $event)" [checked]="sm.IsSelected" aria-label="Click here select Yes/No" />
                      <i class="c-checkbox__checked"></i>
                      <label class="c-checkbox__label" for="{{sm.Id + '_smdeliveryfunctionid'}}" (keypress)="deliveryFunctionClick('SM', sm, $event)">
                        <span class="c-checkbox__label" [textContent]="sm.Name"></span>
                      </label>
                    </div>
                  </div>

                  <div class="c-delivery-table__item">
                    <div class="c-checkbox " *ngFor="let ss of scopeDetails.TempDeliveryFunctionSSList; trackBy : trackById;">
                      <input name="deliveryFunctionSS" class="c-checkbox__input" [disabled]="RoleAccess=='V'|| ss.IsDisabled" [ngClass]="{'switch-disable' : ss.IsDisabled }" id="{{ss.Id + '_ssdeliveryfunctionid'}}" type="checkbox" value="{{ss.Id}}" (click)="deliveryFunctionClick('SS', ss, $event)" [checked]="ss.IsSelected" aria-label="Click here select Yes/No" />
                      <i class="c-checkbox__checked"></i>
                      <label class="c-checkbox__label" for="{{ss.Id + '_ssdeliveryfunctionid'}}" (keypress)="deliveryFunctionClick('SS', ss, $event)">
                        <span class="c-checkbox__label" [textContent]="ss.Name"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="c-accordion" [ngStyle]="{'display': !HideDeliveryScope ? 'block' : 'none'}">
          <div class="c-scope-accordion-header">
            <div class="-header">Delivery Scope</div>
            <div class="c-scope-accordion-header-collapse">
              <!--<download-file source="Scope"></download-file>-->
              <button type="button" id="expand" data-target="#delivery-scope" class="c-collapse" aria-expanded="false" aria-controls="delivery-scope"></button>
            </div>
          </div>
          <div id="delivery-scope" class="-collapse" data-parentid="accordion-scope-types" [ngClass]="{'-show':isShowValue}">
            <div class="c-accordion__container">
              <div class="c-scope-checkbox">
                <div class="c-checkbox " *ngFor="let deliveryScope of scopeDetails.TempDeliveryScopeList | orderBy:'Name'; trackBy : trackById;">
                  <input name="deliveryScope" class="c-checkbox__input" id="{{deliveryScope.Id + '_deliverscopeid'}}" [disabled]="RoleAccess=='V'" type="checkbox" value="{{deliveryScope.Id}}" (click)="DeliveryScope(deliveryScope.Id,deliveryScope)" [checked]="deliveryScope.IsSelected" aria-label="Click here select Yes/No" />
                  <i class="c-checkbox__checked"></i>
                  <label class="c-checkbox__label" for="{{deliveryScope.Id + '_deliverscopeid'}}" (keypress)="DeliveryScope(deliveryScope.Id,deliveryScope)">
                    <span class="c-checkbox__label" [textContent]="deliveryScope.Name"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="c-accordion" *ngIf="endToEndType == 'C'">
          <div class="c-scope-accordion-header" *ngIf="endToEndType == 'C'">
            <div class="-header">Class A assets solutioned in Opportunity phase</div>
            <div class="c-scope-accordion-header-collapse">
              <button type="button" class="c-collapse" aria-expanded="false" aria-controls="delivery-class"></button>
            </div>
          </div>
          <div id="delivery-class" class="-collapse" [ngClass]="{'-show':!isShowClassValue}" data-parentid="accordion-scope-types">
            <div class="c-accordion__container">
              <div class="c-scope-accordion-header-link">Click <a href="https://oneasset.accenture.com/console" role="button" target="_blank" rel="noopener noreferrer">here</a> to view more details about the assets.</div>
              <div class="c-table-responsive c-lead__table c-assets-table">
                <table class="c-table">
                  <thead>
                    <tr>
                      <th scope="col">Class A Asset</th>
                      <th scope="col">Initiative</th>
                      <!--<th scope="col">Sub Assets</th>-->
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let class of newArray">
                      <ng-container *ngIf="class.Initiative.length == 0">
                        <tr>
                          <td>{{class.ClassAAsset}}</td>
                          <td>{{class.Initiative}}</td>
                          <!--<td>{{class.SubAsset}}</td>-->
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="class.Initiative.length > 0">
                        <tr>
                          <td [attr.rowspan]="class.Initiative.length">{{class.ClassAAsset}}</td>
                          <td>{{class.Initiative[0]}}</td>
                          <!--<td>{{class.SubAsset[0]}}</td>-->
                        </tr>
                        <ng-container *ngFor="let initiative of class.Initiative; let i = index">
                          <tr *ngIf=" i != 0">
                            <td>{{initiative}}</td>
                            <!--<td>{{class.SubAsset[i]}}</td>-->
                          </tr>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr *ngIf="endToEndType == 'C'" />

  <div *ngIf="!CreatePage" class="c-myWizard-update__container ">
    <div class="c-myWizard-container">
      <div class="c-myWizard-update__row">
        <div class="c-myWizard-update__details">
          <div class="c-myWizard-update__input">
            <label>Last Updated By:</label><span class="-input">{{scopeDetails.LastModifiedBy}}</span>
          </div>
          <div class="c-myWizard-update__input">
            <label>Last Updated On:</label><span class="-input">{{scopeDetails.LastModifiedOn |date:'dd MMM yyyy h:mm a'}}{{scopeDetails.LastModifiedOn == null ? "" : " (GMT)"}}</span>
          </div>
        </div>
        <div class="c-myWizard-update__contract">
          <div class="c-myWizard-update__input">
            <label *ngIf="endToEndType == 'C' && scopeDetails.IsCMWContract">Auto-sourced Contract Details Last From: </label><span *ngIf="endToEndType == 'C' && scopeDetails.IsCMWContract" class="-input">CMW</span>
          </div>
          <div class="c-myWizard-update__input">
            <label *ngIf="endToEndType == 'C' && scopeDetails.IsCMWContract">Auto-sourced Contract Details Last On: </label><span *ngIf="endToEndType == 'C' && scopeDetails.IsCMWContract" class="-input">{{scopeDetails.AutosourcedLastModifiedOn |date:'dd MMM yyyy h:mm a'}} (GMT)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="c-select-card">
    <div class="container">
      <div class="c-select-card__wrapper">
        <div class="c-select-card__left">
          <div class="c-select-card__text">Changes made will be lost unless confirmed. Please click 'Save' button to confirm.</div>
        </div>
        <div class="c-select-card__right">
          <a href="javascript:void(0)" class="o-secondary-button" role="button" type="button" [ngClass]="{'-disabled':RoleAccess=='V'}" (click)="cancel(scopeform)" aria-label="Reset">Reset</a>
          <input href="javascript:void(0)" class="o-primary-button" role="button" type="submit" value="Save" [ngClass]="{'-disabled':RoleAccess=='V'}" aria-label="Save" />
        </div>
      </div>
    </div>
  </div>
  <div class="c-select-card__placeholder"></div>
</form>
<!--</div>-->
<!--</div>-->
<!--<form name="scopeform" #scopeform="ngForm" novalidate (ngSubmit)="SaveDelivery(scopeform.valid,scopeform)">
  <div class="c-myWizard-container">
    <div class="c-myWizard__header">
      <div class="c-myWizard__header-content-left">
        <div class="c-myWizard__header-content-head">
          <a href="javascript:;" class="icon-banner-arrow" role="button" title="Back" aria-label="Back"></a>
          <div class="-header">Scope</div>
        </div>
        <div class="c-myWizard-text">Complete the preliminary mandatory step to setup your project profile</div>
      </div>
    </div>
    <div class="c-myWizard-form__input c-profile-tech">
      <div class="c-myWizard-form__input-area -first-col">
        <div class="c-custom-select__wrapper -required">
          <label>Primary Technologies</label>
          <div class="c-custom-select">
            <a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-custom-select__selected-text icon-arrow-down">
              <div class="c-custom-select__chip">
                <div class="c-myWizard__selected-chip">
                  <span>Actuate</span>-->
<!-- <a href="javascript:;" role="button" aria-label="close">
</a> -->
<!--<i class="icon-cross" aria-label="close"></i>
</div>                <div class="c-myWizard__selected-chip">
  <span>Adaptiva</span>-->
<!-- <a href="javascript:;" role="button" aria-label="close">
</a> -->
<!--<i class="icon-cross" aria-label="close"></i>
                </div>                <div class="c-myWizard__selected-chip-more"><span>+2 more</span></div>
              </div>
            </a>
            <div class="c-custom-select__dropdown">
              <ul class="c-custom-select__list">
                <div class="c-custom-control">
                  <span><i class="icon-search" aria-label="search"></i></span>
                  <input type="text" placeholder="Search">
                </div>
                <a href="javascript:;" role="button" class="c-custom-select__item">
                  <div class="c-checkbox ">
                    <input type="checkbox"
                           id="actuate"
                           class="c-checkbox__input"
                           name="unitcheckbox"
                           checked />
                    <i class="c-checkbox__checked"></i>
                    <label for="actuate" class="c-checkbox__label">Actuate</label>
                  </div>
                </a>
                <a href="javascript:;" role="button" class="c-custom-select__item">
                  <div class="c-checkbox ">
                    <input type="checkbox"
                           id="adaptiva"
                           class="c-checkbox__input"
                           name="unitcheckbox"
                           checked />
                    <i class="c-checkbox__checked"></i>
                    <label for="adaptiva" class="c-checkbox__label">Adaptiva</label>
                  </div>
                </a>
                <a href="javascript:;" role="button" class="c-custom-select__item">
                  <div class="c-checkbox ">
                    <input type="checkbox"
                           id="ACM1"
                           class="c-checkbox__input"
                           name="unitcheckbox" />
                    <i class="c-checkbox__checked"></i>
                    <label for="ACM1" class="c-checkbox__label">Adobe Campaign Management</label>
                  </div>
                </a>
                <a href="javascript:;" role="button" class="c-custom-select__item">
                  <div class="c-checkbox ">
                    <input type="checkbox"
                           id="ACM2"
                           class="c-checkbox__input"
                           name="unitcheckbox" />
                    <i class="c-checkbox__checked"></i>
                    <label for="ACM2" class="c-checkbox__label">Adobe Campaign Management</label>
                  </div>
                </a>
                <a href="javascript:;" role="button" class="c-custom-select__item">
                  <div class="c-checkbox ">
                    <input type="checkbox"
                           id="CQ5"
                           class="c-checkbox__input"
                           name="unitcheckbox" />
                    <i class="c-checkbox__checked"></i>
                    <label for="CQ5" class="c-checkbox__label">Adobe CQ5</label>
                  </div>
                </a>
                <a href="javascript:;" role="button" class="c-custom-select__item">
                  <div class="c-checkbox ">
                    <input type="checkbox"
                           id="airwatch"
                           class="c-checkbox__input"
                           name="unitcheckbox" />
                    <i class="c-checkbox__checked"></i>
                    <label for="airwatch" class="c-checkbox__label">Airwatch</label>
                  </div>
                </a>
              </ul>

            </div>
          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area -second-col">
        <div class="c-select-wrapper   ">
          <label for="Services_Used" title="Services/Factories Used">Services/Factories Used</label>
          <div class="c-select" id="select-industry">
            <a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>
            <div class="c-select__options-list">
              <a href="javascript:;" role="button" class="c-select__option" data-value="option1">Option 1</a>
              <a href="javascript:;" role="button" class="c-select__option" data-value="option2">Option 2</a>
              <a href="javascript:;" role="button" class="c-select__option" data-value="option3">Option 3</a>
            </div>
          </div>
          <p class="c-select__error-text">Enter the value</p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area -first-col">
        <div class="o-input    ">
          <label for="Platform" title="Platform/ Domain/ Offering">Platform/ Domain/ Offering</label>
          <input type="text"
                 class="o-input__input"
                 placeholder="Enter platform details"
                 value=""
                 id="Platform" />
          <p class="o-input__error-text">Enter the value</p>
          <span class="o-input__char-text">30/30 characters</span>
        </div>
      </div>
      <div class="c-myWizard-form__input-area -second-col">
        <div class="c-select-wrapper   ">
          <label for="Involved_Factories" title="Involved Factories">Involved Factories</label>
          <div class="c-select" id="select-industry">
            <a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>
            <div class="c-select__options-list">
              <a href="javascript:;" role="button" class="c-select__option" data-value="option1">Option 1</a>
              <a href="javascript:;" role="button" class="c-select__option" data-value="option2">Option 2</a>
              <a href="javascript:;" role="button" class="c-select__option" data-value="option3">Option 3</a>
            </div>
          </div>
          <p class="c-select__error-text">Enter the value</p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area -comp-col">
        <div class="o-text-area">
          <label for="Solution_Description">Solution Description</label>
          <textarea class="o-text-area__input"
                    placeholder="Enter the Text" id="Solution_Description"></textarea>
          <span class="o-text-area__total-count">Max Limit 500 Characters</span>
        </div>
      </div>
    </div>
  </div>
  <div class="c-scope-phoenix__container">
    <div class="c-myWizard-container">
      <div class="c-checkbox ">
        <input type="checkbox"
               id="Integration"
               class="c-checkbox__input"
               name="unitcheckbox" />
        <i class="c-checkbox__checked"></i>
        <label for="Integration" class="c-checkbox__label">Allow myWizard Phoenix Integration</label>
      </div>        <div class="c-scope-phoenix__input-form">
        <div class="c-myWizard-form__input">
          <div class="c-myWizard-form__input-area -first-col">
            <div class="c-select-wrapper   -required  ">
              <label for="API" title="API Base URL">API Base URL</label>
              <div class="c-select" id="select-industry">
                <a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>
                <div class="c-select__options-list">
                  <a href="javascript:;" role="button" class="c-select__option" data-value="option1">Option 1</a>
                  <a href="javascript:;" role="button" class="c-select__option" data-value="option2">Option 2</a>
                  <a href="javascript:;" role="button" class="c-select__option" data-value="option3">Option 3</a>
                </div>
              </div>
              <p class="c-select__error-text">Enter the value</p>
            </div>
          </div>
          <div class="c-myWizard-form__input-area -second-col">
            <div class="o-input    ">
              <label for="Client_UID" title="Client UID">Client UID</label>
              <input type="text"
                     class="o-input__input"
                     placeholder="Enter client UID"
                     value=""
                     id="Client_UID" />
              <p class="o-input__error-text">Enter the value</p>
              <span class="o-input__char-text">30/30 characters</span>
            </div>
          </div>
          <div class="c-myWizard-form__input-area -first-col">
            <div class="o-input    ">
              <label for="Construct_UID" title="Delivery Construct UID">Delivery Construct UID</label>
              <input type="text"
                     class="o-input__input"
                     placeholder="Enter delivery construct UID"
                     value=""
                     id="Construct_UID" />
              <p class="o-input__error-text">Enter the value</p>
              <span class="o-input__char-text">30/30 characters</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>        <div class="c-myWizard-container">
    <div class="c-scope-type-container">
      <div class="c-checkbox ">
        <input type="checkbox"
               id="capacity"
               class="c-checkbox__input"
               name="unitcheckbox"
               checked />
        <i class="c-checkbox__checked"></i>
        <label for="capacity" class="c-checkbox__label">Capacity Services</label>
      </div>    <h6 class="c-scope-header">Delivery Type</h6>
      <div class="c-scope-checkbox__container">
        <div class="c-scope-delivery-types__left">
          <div class="c-checkbox ">
            <input type="checkbox"
                   id="Application"
                   class="c-checkbox__input"
                   name="unitcheckbox"
                   checked />
            <i class="c-checkbox__checked"></i>
            <label for="Application" class="c-checkbox__label">Application Development - Waterfall</label>
          </div>            <div class="c-checkbox ">
            <input type="checkbox"
                   id="app-maintenance"
                   class="c-checkbox__input"
                   name="unitcheckbox"
                   checked />
            <i class="c-checkbox__checked"></i>
            <label for="app-maintenance" class="c-checkbox__label">Application Maintenance</label>
          </div>            <div class="c-checkbox ">
            <input type="checkbox"
                   id="testing"
                   class="c-checkbox__input"
                   name="unitcheckbox"
                   checked />
            <i class="c-checkbox__checked"></i>
            <label for="testing" class="c-checkbox__label">Testing Only</label>
          </div>            <div class="c-checkbox ">
            <input type="checkbox"
                   id="security-service"
                   class="c-checkbox__input"
                   name="unitcheckbox"
                   checked />
            <i class="c-checkbox__checked"></i>
            <label for="security-service" class="c-checkbox__label">Security Service</label>
          </div>
        </div>
        <div class="c-scope-delivery-types__right">
          <div class="c-checkbox ">
            <input type="checkbox"
                   id="app-dev"
                   class="c-checkbox__input"
                   name="unitcheckbox"
                   checked />
            <i class="c-checkbox__checked"></i>
            <label for="app-dev" class="c-checkbox__label">Application Development - Agile</label>
          </div>            <div class="c-checkbox ">
            <input type="checkbox"
                   id="Infrastructure"
                   class="c-checkbox__input"
                   name="unitcheckbox"
                   checked />
            <i class="c-checkbox__checked"></i>
            <label for="Infrastructure" class="c-checkbox__label">Infrastructure Service</label>
          </div>            <div class="c-checkbox ">
            <input type="checkbox"
                   id="opt"
                   class="c-checkbox__input"
                   name="unitcheckbox"
                   checked />
            <i class="c-checkbox__checked"></i>
            <label for="opt" class="c-checkbox__label">Operations</label>
          </div>
        </div>
      </div>
      <h6 class="c-scope-header">Methodology & Iteration Type</h6>
      <div class="c-myWizard-form__input c-scope-types">
        <div class="c-myWizard-form__input-area -first-col">
          <div class="c-select-wrapper   ">
            <div class="c-select" id="select-industry">
              <a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>
              <div class="c-select__options-list">
                <a href="javascript:;" role="button" class="c-select__option" data-value="option1">Option 1</a>
                <a href="javascript:;" role="button" class="c-select__option" data-value="option2">Option 2</a>
                <a href="javascript:;" role="button" class="c-select__option" data-value="option3">Option 3</a>
              </div>
            </div>
            <p class="c-select__error-text">Enter the value</p>
          </div>
        </div>
        <div class="c-myWizard-form__input-area -second-col">
          <div class="c-select-wrapper -disabled   ">
            <div class="c-select" id="select-industry">
              <a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>
              <div class="c-select__options-list">
                <a href="javascript:;" role="button" class="c-select__option" data-value="option1">Option 1</a>
                <a href="javascript:;" role="button" class="c-select__option" data-value="option2">Option 2</a>
                <a href="javascript:;" role="button" class="c-select__option" data-value="option3">Option 3</a>
              </div>
            </div>
            <p class="c-select__error-text">Enter the value</p>
          </div>
        </div>
        <div class="c-myWizard-form__input-area -first-col">
          <div class="c-select-wrapper   ">
            <div class="c-select" id="select-industry">
              <a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>
              <div class="c-select__options-list">
                <a href="javascript:;" role="button" class="c-select__option" data-value="option1">Option 1</a>
                <a href="javascript:;" role="button" class="c-select__option" data-value="option2">Option 2</a>
                <a href="javascript:;" role="button" class="c-select__option" data-value="option3">Option 3</a>
              </div>
            </div>
            <p class="c-select__error-text">Enter the value</p>
          </div>
        </div>
        <div class="c-myWizard-form__input-area -second-col">
          <div class="c-select-wrapper -disabled   ">
            <div class="c-select" id="select-industry">
              <a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>
              <div class="c-select__options-list">
                <a href="javascript:;" role="button" class="c-select__option" data-value="option1">Option 1</a>
                <a href="javascript:;" role="button" class="c-select__option" data-value="option2">Option 2</a>
                <a href="javascript:;" role="button" class="c-select__option" data-value="option3">Option 3</a>
              </div>
            </div>
            <p class="c-select__error-text">Enter the value</p>
          </div>
        </div>
      </div>
      <div id="accordionParent-scope" class="c-scope-accordion">
        <div class="c-accordion -open">
          <button type="button" class="c-collapse" aria-expanded="true" aria-controls="delivery-func">Delivery Functions<span>What is it?</span></button>
          <div id="delivery-func" class="-collapse -show" data-parentid="accordionParent-scope">
            <div class="c-accordion__container">
              <div class="c-form-tab">
                <div class="c-form-tab__wrapper">
                  <div class="c-scrollable-tabs">
                    <div class="c-scrollable-tabs__container" role="tablist">
                      <button class="c-form-tab__item" role="tab" aria-controls="PPSM" aria-selected="true">PPSM</button>
                      <button class="c-form-tab__item" role="tab" aria-controls="development" aria-selected="false">Development and Test Management</button>
                      <button class="c-form-tab__item" role="tab" aria-controls="service" aria-selected="false">Service Management</button>
                      <button class="c-form-tab__item" role="tab" aria-controls="security" aria-selected="false">Security Management</button>
                    </div>
                  </div>
                </div>
              </div>
              <div role="tabpanel" id="PPSM" class="-show">
                <div class="c-scope-checkbox__container">
                  <div class="c-scope-delivery-types__left">
                    <div class="c-checkbox ">
                      <input type="checkbox"
                             id="Analytics"
                             class="c-checkbox__input"
                             name="unitcheckbox"
                             checked />
                      <i class="c-checkbox__checked"></i>
                      <label for="Analytics" class="c-checkbox__label">Analytics</label>
                    </div>                                <div class="c-checkbox ">
                      <input type="checkbox"
                             id="Deliverable"
                             class="c-checkbox__input"
                             name="unitcheckbox"
                             checked />
                      <i class="c-checkbox__checked"></i>
                      <label for="Deliverable" class="c-checkbox__label">Deliverable Management</label>
                    </div>                                <div class="c-checkbox ">
                      <input type="checkbox"
                             id="Financial"
                             class="c-checkbox__input"
                             name="unitcheckbox"
                             checked />
                      <i class="c-checkbox__checked"></i>
                      <label for="Financial" class="c-checkbox__label">Financial Management</label>
                    </div>                                <div class="c-checkbox ">
                      <input type="checkbox"
                             id="issue"
                             class="c-checkbox__input"
                             name="unitcheckbox" />
                      <i class="c-checkbox__checked"></i>
                      <label for="issue" class="c-checkbox__label">Issue Management</label>
                    </div>                                <div class="c-checkbox ">
                      <input type="checkbox"
                             id="Mobilization"
                             class="c-checkbox__input"
                             name="unitcheckbox"
                             checked />
                      <i class="c-checkbox__checked"></i>
                      <label for="Mobilization" class="c-checkbox__label">Mobilization Management</label>
                    </div>                                <div class="c-checkbox ">
                      <input type="checkbox"
                             id="resource"
                             class="c-checkbox__input"
                             name="unitcheckbox" />
                      <i class="c-checkbox__checked"></i>
                      <label for="resource" class="c-checkbox__label">Resource Management</label>
                    </div>
                  </div>
                  <div class="c-scope-delivery-types__right">
                    <div class="c-checkbox ">
                      <input type="checkbox"
                             id="check-one"
                             class="c-checkbox__input"
                             name="unitcheckbox" />
                      <i class="c-checkbox__checked"></i>
                      <label for="check-one" class="c-checkbox__label">Risk Management</label>
                    </div>                                <div class="c-checkbox ">
                      <input type="checkbox"
                             id="service-platform"
                             class="c-checkbox__input"
                             name="unitcheckbox"
                             checked />
                      <i class="c-checkbox__checked"></i>
                      <label for="service-platform" class="c-checkbox__label">Service Performance</label>
                    </div>                                <div class="c-checkbox ">
                      <input type="checkbox"
                             id="sprint"
                             class="c-checkbox__input"
                             name="unitcheckbox"
                             checked />
                      <i class="c-checkbox__checked"></i>
                      <label for="sprint" class="c-checkbox__label">Sprint Management</label>
                    </div>                                <div class="c-checkbox ">
                      <input type="checkbox"
                             id="time"
                             class="c-checkbox__input"
                             name="unitcheckbox"
                             checked />
                      <i class="c-checkbox__checked"></i>
                      <label for="time" class="c-checkbox__label">Time Tracking</label>
                    </div>                                <div class="c-checkbox ">
                      <input type="checkbox"
                             id="user"
                             class="c-checkbox__input"
                             name="unitcheckbox"
                             checked />
                      <i class="c-checkbox__checked"></i>
                      <label for="user" class="c-checkbox__label">User Relationship Management</label>
                    </div>                                <div class="c-checkbox ">
                      <input type="checkbox"
                             id="work"
                             class="c-checkbox__input"
                             name="unitcheckbox" />
                      <i class="c-checkbox__checked"></i>
                      <label for="work" class="c-checkbox__label">Work Plan Management</label>
                    </div>
                  </div>
                </div>
              </div>
              <div role="tabpanel" id="development">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>
              <div role="tabpanel" id="service">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>
              <div role="tabpanel" id="security">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="c-accordion">
          <button type="button" class="c-collapse" aria-expanded="false" aria-controls="delivery-scope">Delivery Scope <span>What is it?</span></button>
          <div id="delivery-scope" class="-collapse" data-parentid="accordionParent-scope">
            <div class="c-accordion__container">
              Lorem ipsum
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="c-myWizard-update__container ">
    <div class="c-myWizard-container">
      <div class="c-myWizard-update__row">
        <div class="c-myWizard-update__details">
          <div class="c-myWizard-update__input">
            <label>Last Updated On:</label>
            <span class="-input">21 June 2022 8:28 AM (GMT)</span>
          </div>
          <div class="c-myWizard-update__input">
            <label>Last Updated By:</label>
            <span class="-input">Mounika.S.Sripathi</span>
          </div>
        </div>
        <div class="c-myWizard-update__contract">
          <div class="c-myWizard-update__input">
            <label>Auto-Sourced Contract Details From:</label>
            <span class="-input">CMW</span>
          </div>
          <div class="c-myWizard-update__input">
            <label>Auto-Sourced Contract Details From:</label>
            <span class="-input">29 May 2019 5:55 AM (GMT)</span>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="c-select-card">
    <div class="container">
      <div class="c-select-card__wrapper">
        <div class="c-select-card__left"></div>
        <div class="c-select-card__right">
          <a href="javascript:void(0)" class="o-secondary-button" role="button" title="Save As Draft">Save As Draft</a>
          <a href="javascript:void(0)" class="o-primary-button" role="button" title="Continue">Continue</a>
        </div>
      </div>
    </div>
  </div>
  <div class="c-select-card__placeholder"></div>
</form>-->
