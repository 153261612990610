import { Component } from '@angular/core';
import { TileService } from '../../core/services/tile.service';
import { enumArea } from '../../core/context/enum';
import { SessionStorage } from '../../core/context/sessionstorage';
import { Router, ActivatedRoute } from '@angular/router';
import { BroadCastService } from '../../core/services/broadcast.service';
@Component({
  templateUrl: './portfolio.component.html',
  selector:'portfolio',
  providers: [TileService]
})

export class PortfolioComponent {

  constructor(private tileService: TileService, private sessionStorage: SessionStorage, private router: Router, private activatedRoute: ActivatedRoute, private broadCastService: BroadCastService) { }

  tiles: any;

  ngOnInit() {
    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['MyWizard/Back/Portfolio'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId } });
      return false;
    }
    this.tileService.getTiles(enumArea.Portfolio).subscribe((response) => {
      this.tiles = response;
    });
    this.broadCastService.broadcastScopeSelector({
      isScopeSelectorVisible: false, isScopeSelectorDropDownVisible: false,
      isSetAsDefaultVisible: false
    });
  }

  trackByTileId(index: number, item: any): any {
    return item.TileId;
  }
}
