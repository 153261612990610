import { Component, Input } from '@angular/core';
import { ApplicationLoggingService } from '../../services/application.logging.service';
import { DemographicsService } from '../../services/demographics.service';
import { enumModuleAction, enumTile, enumFeatureAccess } from '../../context/enum';
import { ReleaseNoteService } from '../../services/release.note.service';
import { Location } from '@angular/common';
import { SessionStorage } from '../../context/sessionstorage'
declare var userNotification: any;
var $ = (<any>window).$;
declare var window: any;
@Component({
  selector: 'download-file',
  templateUrl: './download.file.component.html'
})

export class DownloadFileComponent {

  @Input() public source: string;
  @Input() public ProjectInformation: any = null;
  @Input() public SelectedId: any = null;
  @Input() public SelectedCriticalChange: any = null;
  demographicsService: any;
  CreatePage: boolean;
  path = this.location.path();
  EndToEndType = this.sessionStorage.endToEndType;

  constructor(private demographicService: DemographicsService, private applicationLoggingService: ApplicationLoggingService, private releaseNoteService: ReleaseNoteService, private sessionStorage: SessionStorage, private location: Location,) { }
  ngOnInit() {
    if (this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1) {
      this.CreatePage = true;
    }
    setTimeout(() => {
      window.TooltipFunc();
      }, 0);
  }
  DownloadTemplate(fileName, elementId, type) {

    switch (elementId) {
      case 'downloadDeliveryProfileGuide':
         userNotification.showUserNotificationModalPopup();
            const btnClose = document.querySelector("#IUnderstandAction");
        if (btnClose) {
          btnClose.addEventListener('click', () => {
            setTimeout(() => {
              window.TooltipFunc();
              }, 0);
            this.demographicService.downloadTemplate(fileName, elementId, type);
            this.applicationLoggingService.applicationUsageLogging(enumModuleAction.Template, enumTile.InitiateProfile, enumFeatureAccess.DeliveryProfileGuide).subscribe();
          })
        }
        break;
      case 'deliveryScopeDetails':
        userNotification.showUserNotificationModalPopup();
        const btnClose1 = document.querySelector("#IUnderstandAction");
        if (btnClose1) {
          btnClose1.addEventListener('click', () => {
            setTimeout(() => {
              window.TooltipFunc();
              }, 0);
            this.demographicService.downloadTemplate(fileName, elementId, type);
            this.applicationLoggingService.applicationUsageLogging(enumModuleAction.Template, enumTile.InitiateProfile, enumFeatureAccess.DeliveryScopeDetails).subscribe();
          })
        }
        break;
      case 'classAssetsDetails':
        userNotification.showUserNotificationModalPopup();
        const btnClose2 = document.querySelector("#IUnderstandAction");
        if (btnClose2) {
          btnClose2.addEventListener('click', () => {
            setTimeout(() => {
              window.TooltipFunc();
              }, 0);
            this.demographicService.downloadTemplate(fileName, elementId, type);
            this.applicationLoggingService.applicationUsageLogging(enumModuleAction.Template, enumTile.InitiateProfile, enumFeatureAccess.ClassAssetsDetails).subscribe();
          })
        }
        break;
      case 'ManageReleaseNote':
        var release = this.ProjectInformation.filter(x => x.Id == this.SelectedId)[0];
        if (release) {
          var fileName = release.Name;
          var isDownloadInprogress = document.getElementById('downloadReleaseNotes').getAttribute('download-active');
          if (isDownloadInprogress == "false") {
            userNotification.showUserNotificationModalPopup();
            const btnClose = document.querySelector("#IUnderstandAction");
            if (btnClose) {
              btnClose.addEventListener('click', () => {
                setTimeout(() => {
                  window.TooltipFunc();
                  }, 0);
            this.releaseNoteService.downloadReleaseNotes(this.SelectedId, 'downloadReleaseNotes', fileName, 'xlsx', this.SelectedCriticalChange);
                this.applicationLoggingService.applicationUsageLogging(enumModuleAction.Template, enumTile.ManageReleaseNotes, enumFeatureAccess.ReleaseNotes).subscribe();
              });
            }
          }
        }
        break;
    }
  }
}
