import { Injectable } from '@angular/core';
import { SessionStorage } from '../context/sessionstorage';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from './http.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationPopupComponent } from '../components/confirmation.popup.component';
import { AlertPopupComponent } from '../components/alert.popup.component';


var $ = (<any>window).$;
@Injectable()
export class SharedService {
  modalRef_alertpopup: BsModalRef;
  modalRef_confirmationPopup: BsModalRef;

  constructor(private sessionStorage: SessionStorage, private router: Router, private activatedRoute: ActivatedRoute,
    private httpService: HttpService
    , private bsModalRef: BsModalRef
    , private modalService: BsModalService

  ) { }


  onBackClick(unSavedTabChanges: boolean, parenTileId: number) {
    if (unSavedTabChanges) {
      this.router.navigate(['/mywizard/childtiles', parenTileId, 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }
    else {
      return false;
    }

  };

  applicationUsageLogging(moduleAction, tileId, featureAccessId) {

    return this.httpService.post('/api/application/usage/logging/' + this.sessionStorage.enterpriseId +
      '?moduleAction=' + moduleAction +
      '&clientId=' + (this.sessionStorage.clientId ? this.sessionStorage.clientId : null) +
      '&endToEndId=' + (this.sessionStorage.endToEndId ? this.sessionStorage.endToEndId : null) +
      '&cmsId=' + (this.sessionStorage.cmsName ? this.sessionStorage.clientWMSId : null) +
      '&workstreamId=' + (this.sessionStorage.workstreamId ? this.sessionStorage.workstreamId : null) +
      '&teamId=' + (this.sessionStorage.teamId ? this.sessionStorage.teamId : null) +
      '&tileId=' + tileId +
      '&featureAccessId=' + featureAccessId, true);
  }

  //Profile Page
  isAdmin(roleTypeCoe) {
    return (roleTypeCoe == null || roleTypeCoe == undefined) ? false : (roleTypeCoe.indexOf('A') == 0) ? true : false;
  }

  UsageLogging(moduleAction, tileID, featureAccessID) {
    this.applicationUsageLogging(moduleAction, tileID, featureAccessID);
  }

  ProcessDemographicAttribute(sectionName, endtoEndId, demographicService) {
    demographicService.processDemographicAttribute(endtoEndId ? endtoEndId : this.sessionStorage.endToEndId, sectionName).subscribe((result) => { });;
  }

  openAlertPopup(alertmesage, scrollYPosition, okButton: string = "Ok", isBrowserAlert: boolean = false, alertTitle: string = "Alert", hideParentModel = true) {
    if (hideParentModel) {
      this.bsModalRef.hide();
    }
    if (!isBrowserAlert) {
      const initialState = { message: alertmesage, scrollYPosition:scrollYPosition, okButton: okButton, title: alertTitle };
      this.modalRef_alertpopup = this.modalService.show(AlertPopupComponent, {
        initialState,
        backdrop: "static",
        keyboard: false
      });
    }
    else {
      alert(alertmesage);
    }
    return this.modalRef_alertpopup;
  }
  openConfirmationPopup(message, yesButton, noButton, scrollYPosition, headertitle = "Alert",hideParentModel = true) {
    if (hideParentModel) {
      this.bsModalRef.hide();
    }
    const initialState = { message: message, yesButton: yesButton, noButton: noButton, scrollYPosition: scrollYPosition , Headertitle: headertitle };//, yesFunction: yesFunction, noFunction: noFunction };
    this.modalRef_confirmationPopup = this.modalService.show(ConfirmationPopupComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
   return this.modalRef_confirmationPopup;
  }
  


}
