import { Component, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule } from "@angular/forms"
import { Validators } from '@angular/forms';
//import { IDropdownSettings } from 'ng-multiselect-dropdown';
//import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
//import { AddMetricService } from '../../core/services/add.metric.service'
import { ConfigureMetricService } from '../../core/services/configure.metrics.services';
import { CommonModule } from '@angular/common';
import { SessionStorage } from '../../core/context/sessionstorage';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SearchDropDownComponent } from '../../core/components/search.dropdown.component'
import { AdminMeasureComponent } from './measure.add.component';
import { enumMetricDeliveryLevel } from '../../core/context/enum';
import { SharedService } from '../../core/services/shared.service';
import { forEach } from 'lodash';
import { environment } from '../../../environments/environment';

var $ = (<any>window).$;
declare var window: any;

@Component({
  selector: 'metric-view',
  templateUrl: './metric.view.component.html',
  providers: [ConfigureMetricService, BsModalService],
})


export class MetricViewComponent {
  public setAfterSave: EventEmitter<any> = new EventEmitter();
  selectedAction: string;
  metricId: any;
  e2eUId: any;
  CreateAction: boolean;
  deliveryId: any;
  deliveryLevelId: any;
  metricData: any;
  errorMessage: string;

  constructor(private bsModalRef: BsModalRef, private metricService: ConfigureMetricService, private sessionStorage: SessionStorage, private modalService: BsModalService, private sharedService: SharedService) {

  }
  Isedit: boolean = true;
  IsCustom: boolean = false;
  metricsName: any;
  metricsNameCopy: any;
  definition: any;
  metricsId: any;
  Abbreviation: any;
  metricError: boolean = false;
  metricnameError: boolean = false;
  DefinationError: boolean = false;
  Error: any;
  metricsSearch: any = [];
  MetricNames: any = [];
  workstreamTypes: any = [];
  selectedWSTypes: any = [];
  accessFlag: boolean;
  Roleaccesscode: any;

  NewArray: Array<any> = new Array<any>();
  @Output() _onSaveClick = new EventEmitter<any>();




  ngOnInit() {
    setTimeout(() => {
      window.dropdownComponent();
      window.collapseComponent();
      window.tabsComponent();
      window.TooltipFunc();
    }, 500)
    this.Roleaccesscode = this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);

    this.accessFlag = this.Roleaccesscode == 'E' ? false : true;
    this.getmetricsmaster();
    this.metricsName = this.metricData.Name[0].Name;
    this.metricsNameCopy = this.metricData.Name[0].Name;
    this.Abbreviation = this.metricData.Abbreviation;
    this.definition = this.metricData.Definition
    this.metricsId = this.metricData.Name[0].Id;
    this.workstreamTypes = this.metricData.ReportedAgainst;

    if (this.metricData.IsCustom == 'Y') {
      this.IsCustom = true;
    }
    this.CreateAction = this.selectedAction == 'Create' ? true : false;
    this.e2eUId = this.sessionStorage.endToEndUId;
    if (this.sessionStorage.teamUId != null && this.sessionStorage.teamUId != '') {
      this.deliveryId = this.sessionStorage.teamUId
    }
    else {
      this.deliveryId = this.sessionStorage.endToEndUId
    }
    if (this.sessionStorage.teamUId != null && this.sessionStorage.teamUId != '') {
      this.deliveryLevelId = enumMetricDeliveryLevel.Team;
    }
    else {
      this.deliveryLevelId = enumMetricDeliveryLevel.E2E;
    }
    
    this.e2eUId = this.sessionStorage.endToEndUId;
  
    //this.selectedMetricDetails(this.metricId);
  }
  getmetricsmaster() {   
    this.e2eUId = this.sessionStorage.endToEndUId;
    this.metricService.getCustomMetricMaster(this.e2eUId).subscribe(
      source => {
        this.loadMetricName(source.MetricName); 
      },
      error => this.errorMessage = <any>error);
  }
  onmetricNameChange(SelectedMetric: any): void {
    
    this.metricError = false;
    this.metricnameError = false;
    this.DefinationError = false;   
  }
  ValidateField(fieldValue: any, field) {
    switch (field) {
      case 'name':
        if (fieldValue == "" || fieldValue == undefined) {
          this.metricnameError = true;
          this.Error = "Please fill in all the required fields.";
        }
        else {
          this.Error = "";
        }
        break;
      case 'def':
        if (fieldValue == "" || fieldValue == undefined) {
          this.DefinationError = true;
          this.Error = "Please fill in all the required fields.";
        }
        else {
          this.Error = "";
          this.DefinationError = false;
        }
        break;
                
    }
    // error div empty issue by sagar
    if (this.metricnameError == true || this.DefinationError == true ) {
      this.Error = "Please fill in all the required fields.";
    }
  }
  loadMetricName(source: any) {

    for (var i = 0; i < source.length; i++) {
      if (source[i].Id != 12) {
        if (this.selectedAction == "Read") {
          this.MetricNames.push({ "id": source[i].Id, "value": source[i].Name });
          this.metricsSearch.push({ id: source[i].Id, name: source[i].Name });
        }
        else {
          if (source[i].Id != 12) {
            this.MetricNames.push({ "id": source[i].Id, "value": source[i].Name });
            this.metricsSearch.push({ id: source[i].Id, name: source[i].Name });
          }
        }
      }
      //this.MetricNamesAuto.push({ "value": source[i].Name });
      //this.loadingStatus = false;
    }
  }
  editclick() {
    this.Isedit = !this.Isedit;
  }
  Savemetric() {
    debugger;
    var namearray = this.metricsSearch;
    for (var i = 0; i < namearray.length; i++)
    {
      if (namearray[i].name == this.metricsName) {
        this.metricError = true;
      }       
    }
    if (this.metricsNameCopy == this.metricsName) {
      this.metricError = false;

    }
    if (this.metricError == false && this.DefinationError==false)
    {      
      this.NewArray = [];
      this.e2eUId = this.sessionStorage.endToEndUId;
      this.NewArray.push(
        {

          Enterpriseid: this.sessionStorage.enterpriseId,
          IsAdd: '',
          MetricId: this.metricsId,
          MetricName: this.metricsName,
          Abbreviation: this.Abbreviation,
          Definition: this.definition,
          DeliveryLevelId: this.deliveryLevelId,
          EndToEndUId: this.e2eUId,
        });
      this.metricService.saveMetric(this.NewArray[0]).subscribe(
        message => {
          //this.loadingStatus = false;
          this.sharedService.openAlertPopup("Metric Saved Successfully.", window.scrollY);

          this.bsModalRef.hide();
          //this.setAfterSave.emit({
          //  parentActionToPerform: 'SaveWS',                          
          //});
          this._onSaveClick.emit(this.e2eUId);
        },
        error => {
          console.log(error);
          this.sharedService.openAlertPopup("Something went wrong!", window.scrollY);
        });
    }

  }
  closePopup() {
    this.bsModalRef.hide();
  }


}
