import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { SessionStorage } from '../../context/sessionstorage';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class ExternalApplicationLandingService {
  constructor(private httpService: HttpService, private sessionStorage: SessionStorage, private msalService: MsalService) { }

  getEnterpriseId() {
    var loggedinUser = this.msalService.instance.getActiveAccount()?.username!;
    var enterpriseId = loggedinUser.substring(0, loggedinUser.indexOf("@"));
    return enterpriseId;
  }

  getClientHierarchy() {
    return this.httpService.get('/api/securitydetails/user/' + this.sessionStorage.enterpriseId +
      '/?endToEndUId=' + this.sessionStorage.endToEndUId + '&clientWMSUId=' + this.sessionStorage.clientWMSUId + '&workstreamUId=' + this.sessionStorage.workstreamUId);
  }

  getTeamsByEndtoEndOrCMS() {
    return this.httpService.get('/api/Team/FilterByEndToEndOrCMS' + '?endToEndUId=' + this.sessionStorage.endToEndUId.toString() +
      (this.sessionStorage.clientWMSUId ? '&cmsUId=' + this.sessionStorage.clientWMSUId : '') + (this.sessionStorage.workstreamUId ? '&workstreamUId=' + this.sessionStorage.workstreamUId : '') + (this.sessionStorage.teamUId ? '&tmsUId=' + sessionStorage.teamUId : ''), true);
  }

  getValidateEndPeriod(endPeriod: any) {
    return this.httpService.get('/api/ValidateEndPeriod/' + endPeriod, true);
  }
}

