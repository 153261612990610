import { Component, ViewEncapsulation } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { DataPrivacyComponent } from '../../../home/data.privacy.component/data.privacy.component';
import { TermsUseComponent } from '../../../home/terms.use.component/terms.use.component';
import { enumArea, enumTileShortNames } from '../../context/enum';
import { SessionStorage } from '../../context/sessionstorage';
import { ScriptLoaderService } from '../../services/script.loader.service';
import { TileService } from '../../services/tile.service';

var $ = (<any>window).$;
declare var window: any;

@Component({
  selector: 'accessdenied',
  templateUrl: './accessdenied.component.html',
  //styleUrls: ['../../../../assets/css/bootstrap.min.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ScriptLoaderService]
})
export class AccessDeniedComponent {

  constructor(private bsModalService: BsModalService, private tileService: TileService, private sessionStorage: SessionStorage, private msalService: MsalService) { }

  tiles: any;
  enterpriseId = this.sessionStorage.enterpriseId;
  modalRef_termsUse: BsModalRef;
  modalRef_dataPrivacy: BsModalRef;
  currentDay: any = (new Date()).getDay();
  date = new Date();
  logOutUrl: string = environment.logOutUrl;

  dateYear = this.date.toLocaleDateString("en", { year: 'numeric' });

  ngOnInit() {
    this.getTiles();

    setTimeout(() => {
      window.dropdownComponent();
    }, 2000);
  }

  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip({ trigger: "hover focus" });
  }

  openTermsUse() {
    this.modalRef_termsUse = this.bsModalService.show(TermsUseComponent, {
      backdrop: "static",
      keyboard: false,
      class: 'custom-terms-modal'
    });
  }

  openDataPrivacy() {
    this.modalRef_termsUse = this.bsModalService.show(DataPrivacyComponent, {
      backdrop: "static",
      keyboard: false,
    });
  }

  trackByTileId(index: number, item: any): any {
    return item.TileId;
  }

  openTermsOfUsePopUp(tile) {
    if (tile.ShortName == enumTileShortNames.TermsOfUse)
      window.open(tile.Url, tile.Target, 'width=480,height=600');
  }

  getTiles() {
    this.tileService.getTiles(enumArea.HelpSelection)
      .subscribe((tiles: any) => {
        this.tiles = tiles;
      });
  }

  logOut() {
    localStorage.clear();
    sessionStorage.clear();
    this.msalService.logout();
    window.location.href = environment.logOutUrl;
  }

}
