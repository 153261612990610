import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { enumArea, ManageWorkTile } from '../../core/context/enum';
import { SessionStorage } from '../../core/context/sessionstorage';
import { TileService } from '../../core/services/tile.service';
import { enumProfileTab } from '../../core/context/enum'
@Component({
  templateUrl: './manage.landing.component.html',
  providers: [TileService]
})

export class ManageLandingComponent {
  constructor(private tileService: TileService, private sessionStorage: SessionStorage, private activatedRoute: ActivatedRoute, private router: Router) { }

  tiles: any;
  ICtiles: any;
  IsDisplay: any;

  ngOnInit() {
    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['MyWizard/Back/Manage'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId } });
      return false;
    }

    //this.tileService.validateProfile(true).subscribe((alertResponse: any) => {
      this.tileService.getTiles(enumArea.Manage).subscribe((response) => {
        this.tiles = response;
        if (this.tiles?.length > 1) {

          this.tileService.getTiles(enumArea.ManageInitiateAndConfigure).subscribe((response) => {
            this.ICtiles = response;
            this.ICtiles[0].IsDisplay = false;
          });
        } else {
          this.tileService.getTiles(enumArea.InitiateAndConfigure).subscribe((response) => {
            this.ICtiles = response;
            this.ICtiles[0].IsDisplay = false;
          });
        }
      });
   
 
  }

  ChangeTile(value: any) {

    if (this.ICtiles[value].IsDisplay) {
      let currentElement = this.ICtiles[value] ;
      Array.prototype.forEach.call(this.ICtiles, e => {
        if (e.IsDisplay && e.TileId == currentElement.TileId) {
          e.IsDisplay = false;
        }
        else {
          e.IsDisplay = true;
        }
      })
    }
  }


  trackByIndex(index: number, item: any): number {
    return index;
  }
  initiateAndConfigure() {
    var url = '';
    if (this.sessionStorage.teamId) {
      this.sessionStorage.profileTab = enumProfileTab.TeamStructure;
      url = "/mywizard/teamstructure/E2E/";
      this.router.navigate(['/mywizard/teamstructure', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }
    else if (this.sessionStorage.workstreamId || this.sessionStorage.clientWMSId) {
      this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
      url = "/mywizard/clientstructure/E2E/";
      this.router.navigate(['/mywizard/clientstructure', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    } else {
      url = "/mywizard/profile/E2E/";
      this.sessionStorage.profileTab = enumProfileTab.Profile;
    }
    this.router.navigate([url, this.sessionStorage.endToEndUId]);

  }

  manage() {
    this.router.navigate(['/mywizard/manage/E2E', this.sessionStorage.endToEndUId]);
  }
}





