import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionStorage } from './../context/sessionstorage';


@Injectable()
export class DownTimeAlertService {
  constructor(private httpService: HttpService, private sessionStorage: SessionStorage) { }

  getDownTimeNotificationAlert(applicationCode: any) {
    return this.httpService.get('/api/ApplicationDownTime/Alert/Application/' + applicationCode + '/' + null);
  }

  getDBConnectionExists() {
    return this.httpService.get('/api/ApplicationDownTime/ServiceOrDBAvailablity');
  }

  getLimitedFunctionality() {
    return this.httpService.get('/api/limited/functionality/enablement/');
  }

  saveLimitedFunctionality(data: any) {
    return this.httpService.post('/api/limited/functionality/enablement/update', data, true);
  }
}
