import { Injectable } from "@angular/core";

@Injectable()
export class SessionStorage {
  get userId(): number {
    return parseInt(sessionStorage.getItem('userid'));
  }
  set userId(value: number) {
    sessionStorage.setItem('userid', value.toString());
  }
  get scopeSelector(): any {
    return this.tryJsonParse(sessionStorage.getItem('scopeSelector'));
  }
  set scopeSelector(value: any) {
    sessionStorage.setItem('scopeSelector', this.tryJsonStringify(value));
  }
  get scopeSelectorTemp(): any {
    return this.tryJsonParse(sessionStorage.getItem('scopeSelectorTemp'));
  }
  set scopeSelectorTemp(value: any) {
    sessionStorage.setItem('scopeSelectorTemp', this.tryJsonStringify(value));
  }

  get commandCenter(): any {
    return this.tryJsonParse(sessionStorage.getItem('commandCenter'));
  }
  set commandCenter(value: any) {
    sessionStorage.setItem('commandCenter', this.tryJsonStringify(value));
  }

  get enterpriseId(): string {
    return sessionStorage.getItem('enterpriseId');

  }
  set enterpriseId(value: string) {
    sessionStorage.setItem('enterpriseId', value);
  }
  get endToEndUId(): string {
    return sessionStorage.getItem('endToEndUId');
  }
  set endToEndUId(value: string) {
    sessionStorage.setItem('endToEndUId', value);
  }
  get endToEndCMSTeams(): any {
    return this.tryJsonParse(sessionStorage.getItem('endToEndCMSTeams'));
  }
  set endToEndCMSTeams(value: any) {
    sessionStorage.setItem('endToEndCMSTeams', this.tryJsonStringify(value));
  }

  get clientWMSId(): number {
    return parseInt(sessionStorage.getItem('clientWMSId') || '0');
  }
  set clientWMSId(value: number) {
    sessionStorage.setItem('clientWMSId', value.toString());
  }

  get teamId(): number {
    return parseInt(sessionStorage.getItem('teamId'));
  }
  set teamId(value: number) {
    sessionStorage.setItem('teamId', value.toString());
  }

  get workstreamId(): number {
    return parseInt(sessionStorage.getItem('workstreamId') || '0');
  }
  set workstreamId(value: number) {
    sessionStorage.setItem('workstreamId', value.toString());
  }

  get selectedSubmissionType(): number {
    return parseInt(sessionStorage.getItem('selectedSubmissionType') || '1');
  }
  set selectedSubmissionType(value: number) {
    sessionStorage.setItem('selectedSubmissionType', value.toString());
  }
  get statusAsOnPeriod(): string {
    return "2018-03-04";
  }
  set statusAsOnPeriod(value: string) {
    sessionStorage.setItem('statusAsOnPeriod', value);
  }

  get clientWMSUId(): string {
    return sessionStorage.getItem('clientWMSUId');
  }
  set clientWMSUId(value: string) {
    sessionStorage.setItem('clientWMSUId', value);
  }

  get workstreamUId(): string {
    return sessionStorage.getItem('workstreamUId');
  }
  set workstreamUId(value: string) {
    sessionStorage.setItem('workstreamUId', value);
  }
  get teamUId(): string {
    return sessionStorage.getItem('teamUId');
  }
  set teamUId(value: string) {
    sessionStorage.setItem('teamUId', value);
  }

  get roleAccessCode(): string {
    return sessionStorage.getItem('roleAccessCode');
  }
  set roleAccessCode(value: string) {
    sessionStorage.setItem('roleAccessCode', value);
  }

  get endToEndType(): string {
    return sessionStorage.getItem('endToEndType');
  }
  set endToEndType(value: string) {
    sessionStorage.setItem('endToEndType', value);
  }

  get clientUId(): string {
    return sessionStorage.getItem('clientUId');
  }
  set clientUId(value: string) {
    sessionStorage.setItem('clientUId', value);
  }
  get endToEndRefCode(): string {
    return sessionStorage.getItem('endToEndRefCode');
  }
  set endToEndRefCode(value: string) {
    sessionStorage.setItem('endToEndRefCode', value);
  }

  get customRoleTypeCode(): any {
    return this.tryJsonParse(sessionStorage.getItem('customRoleTypeCode'));
  }
  set customRoleTypeCode(value: any) {
    sessionStorage.setItem('customRoleTypeCode', this.tryJsonStringify(value));
  }
  get roleType(): string {
    return sessionStorage.getItem('roleType');
  }
  set roleType(value: string) {
    sessionStorage.setItem('roleType', value);
  }

  get roleTypeCode(): string {
    return sessionStorage.getItem('roleTypeCode');
  }
  set roleTypeCode(value: string) {
    sessionStorage.setItem('roleTypeCode', value);
  }

  get endToEndId(): number {
    return parseInt(sessionStorage.getItem('endToEndId') || '0');
  }
  set endToEndId(value: number) {
    sessionStorage.setItem('endToEndId', value.toString());
  }
  get createdE2EId(): number {
    return parseInt(sessionStorage.getItem('createdE2EId') || '0');
  }
  set createdE2EId(value: number) {
    sessionStorage.setItem('createdE2EId', value.toString());
  }

  get endToEndName(): string {
    return sessionStorage.getItem('endToEndName');
  }
  set endToEndName(value: string) {
    sessionStorage.setItem('endToEndName', value);
  }

  get clientId(): number {
    return parseInt(sessionStorage.getItem('clientId'));
  }
  set clientId(value: number) {
    sessionStorage.setItem('clientId', value.toString());
  }

  get clientName(): string {
    return sessionStorage.getItem('clientName');
  }
  set clientName(value: string) {
    sessionStorage.setItem('clientName', value);
  }
  get roleTypeId(): string {
    return sessionStorage.getItem('roleTypeId');
  }
  set roleTypeId(value: string) {
    sessionStorage.setItem('roleTypeId', value);
  }

  get workstreamName(): string {
    return sessionStorage.getItem('workstreamName');
  }
  set workstreamName(value: string) {
    sessionStorage.setItem('workstreamName', value);
  }

  get cmsName(): string {
    return sessionStorage.getItem('cmsName') || '';
  }
  set cmsName(value: string) {
    sessionStorage.setItem('cmsName', value);
  }

  get tmsName(): string {
    return sessionStorage.getItem('tmsName');
  }
  set tmsName(value: string) {
    sessionStorage.setItem('tmsName', value);
  }

  get isEndToEndClosed(): boolean {
    return sessionStorage.getItem('isEndToEndClosed') == 'true';
  }
  set isEndToEndClosed(value: boolean) {
    sessionStorage.setItem('isEndToEndClosed', value.toString());
  }
  get isNavigateFromDD(): boolean {
    return sessionStorage.getItem('isNavigateFromDD') == 'true';
  }
  set isNavigateFromDD(value: boolean) {
    sessionStorage.setItem('isNavigateFromDD', value.toString());
  }

  get profileTab(): number {
    return parseInt(sessionStorage.getItem('profileTab'));
  }
  set profileTab(value: number) {
    sessionStorage.setItem('profileTab', (value || "").toString());
  }

  get parentTileId(): number {
    return parseInt(sessionStorage.getItem('parentTileId'));
  }
  set parentTileId(value: number) {
    sessionStorage.setItem('parentTileId', (value || "").toString());
  }
  get token(): string {
    return sessionStorage.getItem('tmsName');
  }
  set token(value: string) {
    sessionStorage.setItem('token', value);
  }
  get deployRegion(): string {
    return sessionStorage.getItem('deployRegion');
  }
  set deployRegion(value: string) {
    sessionStorage.setItem('deployRegion', value);
  }

  get securityEnterpriseId(): string {
    return sessionStorage.getItem('securityEnterpriseId');
  }
  set securityEnterpriseId(value: string) {
    sessionStorage.setItem('securityEnterpriseId', value);
  }
  get isAdminTabVisible(): boolean {
    return sessionStorage.getItem('isAdminTabVisible') == 'true';
  }
  set isAdminTabVisible(value: boolean) {
    sessionStorage.setItem('isAdminTabVisible', value.toString());
  }
  get isSupportTabVisible(): boolean {
    return sessionStorage.getItem('isSupportTabVisible') == 'true';
  }
  set isSupportTabVisible(value: boolean) {
    sessionStorage.setItem('isSupportTabVisible', value.toString());
  }
  get AdminAccessType(): string {
    return sessionStorage.getItem('AdminAccessType');
  }
  set AdminAccessType(value: string) {
    sessionStorage.setItem('AdminAccessType', value);
  }

  get cardinalClients(): any {
    return this.tryJsonParse(sessionStorage.getItem('cardinalClients'));
  }
  set cardinalClients(value: any) {
    sessionStorage.setItem('cardinalClients', this.tryJsonStringify(value));
  }

  get clientAccessEnabled(): boolean {
    return sessionStorage.getItem('clientAccessEnabled') == 'true';
  }
  set clientAccessEnabled(value: boolean) {
    sessionStorage.setItem('clientAccessEnabled', value.toString());
  }
  get currentPeriod(): string {
    return sessionStorage.getItem('currentPeriod');
  }
  set currentPeriod(value: string) {
    sessionStorage.setItem('currentPeriod', value);
  }
  get isValidEndToEndOverallDeliveryLead(): boolean {
    return sessionStorage.getItem('isValidEndToEndOverallDeliveryLead') == 'null' ? null : sessionStorage.getItem('isValidEndToEndOverallDeliveryLead') == 'true';
  }
  set isValidEndToEndOverallDeliveryLead(value: boolean) {
    sessionStorage.setItem('isValidEndToEndOverallDeliveryLead', (value ? value.toString() : null));
  }
  get isValidEndDate(): boolean {
    return sessionStorage.getItem('isValidEndDate') == 'null' ? null : sessionStorage.getItem('isValidEndDate') == 'true';
  }
  set isValidEndDate(value: boolean) {
    sessionStorage.setItem('isValidEndDate', (value ? value.toString() : null));
  }

  get isUserHasAccess(): boolean {
    return sessionStorage.getItem('isUserHasAccess') == 'true';
  }
  set isUserHasAccess(value: boolean) {
    sessionStorage.setItem('isUserHasAccess', value.toString());
  }
  get SelectedCategory(): number {
    return parseInt(sessionStorage.getItem('SelectedCategory') || '0');
  }
  set SelectedCategory(value: number) {
    sessionStorage.setItem('SelectedCategory', value.toString());
  }
  get Master(): any {
    return this.tryJsonParse(sessionStorage.getItem('Master'));
  }
  set Master(value: any) {
    sessionStorage.setItem('Master', this.tryJsonStringify(value));
  }
  get SelectedSubCategory(): any {
    return parseInt(sessionStorage.getItem('SelectedSubCategory') || '0');
  }
  set SelectedSubCategory(value: any) {
    sessionStorage.setItem('SelectedSubCategory', value);
  }
  get Categories(): any {
    return this.tryJsonParse(sessionStorage.getItem('Categories'));
  }
  set Categories(value: any) {
    sessionStorage.setItem('Categories', this.tryJsonStringify(value));
  }
  get endToEndDeliveryCenterId(): number {
    return parseInt(sessionStorage.getItem('endToEndDeliveryCenterId') || '0');
  }
  set endToEndDeliveryCenterId(value: number) {
    sessionStorage.setItem('endToEndDeliveryCenterId', value.toString());
  }
  get cmmiId(): any {
    return parseInt(sessionStorage.getItem('cmmiId') || '0');
  }
  set cmmiId(value: any) {
    sessionStorage.setItem('cmmiId', value.toString());
  }
  get IsUnsavedData(): boolean {
    return sessionStorage.getItem('IsUnsavedData') == 'true';
  }
  set IsUnsavedData(value: boolean) {
    sessionStorage.setItem('IsUnsavedData', value.toString());
  }

  get SFEDisable(): boolean {
    return sessionStorage.getItem('SFEDisable') == 'true';
  }

  set SFEDisable(value: boolean) {
    sessionStorage.setItem('SFEDisable', value.toString());
  }

  //rootscope variable from AngJS
  get CMSUnsavedData(): number {
    return parseInt(sessionStorage.getItem('CMSUnsavedData'));
  }

  set CMSUnsavedData(value: number) {
    sessionStorage.setItem('CMSUnsavedData', value.toString());
  }

  //rootscope variable from AngJS
  get TabChanges(): boolean {
    return sessionStorage.getItem('TabChanges') == 'true';
  }

  set TabChanges(value: boolean) {
    sessionStorage.setItem('TabChanges', value.toString());
  }

  get isUserAlert(): boolean {
    return sessionStorage.getItem('isUserAlert') == 'true';
  }

  set isUserAlert(value: boolean) {
    sessionStorage.setItem('isUserAlert', value.toString());
  }

  get isReleaseAnnouncementClosed(): boolean {
    return sessionStorage.getItem('isReleaseAnnouncementClosed') == 'true';
  }

  set isReleaseAnnouncementClosed(value: boolean) {
    sessionStorage.setItem('isReleaseAnnouncementClosed', value.toString());
  }
  get IsProfileCompleted(): boolean {
    return sessionStorage.getItem('IsProfileCompleted') == 'null' ? null : sessionStorage.getItem('IsProfileCompleted') == 'true' ;
  }

  set IsProfileCompleted(value: boolean) {
    sessionStorage.setItem('IsProfileCompleted', (value != null ? value.toString() : null));
  }

  get restrictedInstanceIndicator(): string{
    return sessionStorage.getItem('RestrictedInstanceIndicator');
  }
  set restrictedInstanceIndicator(value: string) {
    sessionStorage.setItem('RestrictedInstanceIndicator', value);
  }

  // General function for JSON.parse try/catch
  tryJsonParse(data) {
    let parsedJson;
    try {
      parsedJson = JSON.parse(data);
    } catch (exception) {
      parsedJson = null;
    }
    return parsedJson;
  }

  // General function for JSON.stringify try/catch
  tryJsonStringify(data) {
    let stringifiedJson;
    try {
      stringifiedJson = JSON.stringify(data);
    } catch (exception) {
      stringifiedJson = null;
    }
    return stringifiedJson;
  }

  get EndToEndDCList(): any {
    return sessionStorage.getItem('EndToEndDCList') ;
  }

  set EndToEndDCList(value: any) {
    sessionStorage.setItem('EndToEndDCList', this.tryJsonStringify(value));
  }
}
