<div class="app-wrap -flex">
  <div class="c-myWizard c-user-guide-stories -collapsed">
    <div class="c-myWizard-content">
      <div class="c-myWizard-container">
          <div class="c-user-guide-stories-content">
            <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/team-user-guide.jpg" alt="UserGuide" role="presentation" /></div>
            <div class="c-user-guide-stories-container -team-userguide">
              <div class="c-user-guide-stories-data">
                <div class="c-user-guide-stories-data__header">
                  <div class="-title">Teams may be:</div>
                  <a href="javascript:;" role="button" data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
                </div>
                <div class="c-user-guide-stories-data__body">
                  <ul class="c-myWizard-list c-user-guide-stories__list">
                    <li>Set up by the Account team to support management of the account itself (e.g. client, contractor, sub-team structures, etc.)</li>
                    <li>Setup at the request of various delivery organisations within Accenture (e.g. ATC, Service Groups, Solutions Factories, RBEs etc.)</li>
                    <li>Each team may be involved one, all, or select levels of the CMS Grouped logically (up to five tiers)</li>
                    <li>Overlap: the same resource could be associated to multiple team views</li>
                  </ul>
                </div>
                <div class="c-user-guide-stories-data__footer">
                  <div class="c-user-guide-stories__progress">
                    <div class="c-user-guide-stories__progress-bar">
                      <span style="--value:100%"></span>
                    </div>
                    <div class="c-user-guide-stories__progress-data">
                      <span>1</span>
                      <span>/ 1</span>
                    </div>
                  </div>
                  <div class="c-user-guide-stories__actions">
                    <a href="javascript:;" role="button" class="-prev -disabled">PREV</a>
                    <a href="javascript:;" role="button" class="-next -disabled">NEXT</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
      </div>
      </div>
    </div>
  </div>
