import { Component, EventEmitter } from '@angular/core';
import * as Enumerable from 'linq';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SessionStorage } from '../../../core/context/sessionstorage';
import { SharedService } from '../../../core/services/shared.service';
var $ = (<any>window).$;
import { DemographicsService } from '../../../core/services/demographics.service';

@Component({
  selector: 'manage-customer',
  templateUrl: './manage.customer.component.html',
  providers: [DemographicsService]
})
export class ManageCustomerComponent {

  constructor(private bsModalRef: BsModalRef, private sessionStorage: SessionStorage, private sharedService: SharedService, private demographicsService: DemographicsService,) { }

  public altnernateContactDetails: EventEmitter<any> = new EventEmitter();  // .. Child Popup -> Parent

  profileDetails: any;        // .. Parent -> Child Popup
  endToEndType: string;
  SelectorOpen: boolean;
  selectedItem: any;
  query: string;
  filterId: any;
  showMessage: boolean = false;
  ShowLoader: boolean = false;
  ngOnInit() {
    this.SelectorOpen = false;
    setTimeout(function () {
      $('.close').focus();
     }, 0)
  }
  ngOnChanges() {
    if (!this.profileDetails.CustomerSearchName && this.selectedItem != 'None') {
      this.profileDetails.SelectedId = this.profileDetails.CustomerList == undefined ? "Select" : this.profileDetails.CustomerList[0];
    }
  }

  SearchCustomer = function () {
    if (this.profileDetails.CustomerSearchName != "" && this.profileDetails.CustomerSearchName.length > 2) {
      this.SelectorOpen = true;
      this.ShowLoader = true;
      this.demographicsService.searchCustomer(this.profileDetails.CustomerSearchName, this.endToEndType).subscribe((response: any) => {
       if (response && response.length != 0) {
         this.profileDetails.CustomerList = response;
         this.ShowLoader = false;
          this.showMessage = false;
        }
        else {
         this.profileDetails.CustomerList = response;
         this.ShowLoader = false;
          this.showMessage = true;
          //this.sharedService.openAlertPopup('No matching Customer found.', "Ok", false)
        }
      });
      this.query = "";
    }
    //else {
    //  this.sharedService.openAlertPopup('Please enter minimum three characters to search Customer.', "Ok", false)
    //}
    if (this.profileDetails.CustomerSearchName.length < 3)
      this.SelectorOpen = false;
    this.loadData();
  }

  //Unused now
  BindSelectedCustomer = function (item) {
    this.profileDetails.SelectedId = item.Id;
  }

  SaveCustomer = function () {
    if (this.profileDetails.CustomerSearchName) {
      if (!this.profileDetails.SelectedId) {
        this.sharedService.openAlertPopup('Select a Customer.', window.scrollY, "Ok", false)
        return false;
      }
      else {
        this.profileDetails.SelectedCustomer = Enumerable.from(this.profileDetails.CustomerList).where((x: any) => x.Id == this.profileDetails.SelectedId).firstOrDefault();
        this.profileDetails.Customer = this.profileDetails.SelectedCustomer.Name;
        this.profileDetails.ClientId = this.profileDetails.SelectedCustomer.Id;
        var masterClientId = this.profileDetails.SelectedCustomer.ParentId;
        if (this.profileDetails.SelectedCustomer.Id == this.profileDetails.SelectedCustomer.ParentId) {
          this.profileDetails.ClientName = this.profileDetails.SelectedCustomer.Name;
        }
        else {
          this.demographicsService.masterClientName(masterClientId).subscribe((response: any) => {

            this.profileDetails.ClientName = response;
          });
        }
        this.profileDetails.CustomerSearchName = "";
        this.profileDetails.SelectedCustomer = "";
        this.profileDetails.CustomerList = [];
        this.altnernateContactDetails.emit({ saveData: true });
        this.bsModalRef.hide();
      }
    }
    else {
      this.sharedService.openAlertPopup('Please enter Customer.', window.scrollY, "Ok", false)
    }

  }
  cancel() {
    this.profileDetails.CustomerSearchName = null;
    this.profileDetails.CustomerList = null;
    this.bsModalRef.hide();
  }
  trackById(index: number, item: any): any {
    return item.Id;
  }
  onSelect = function (option) {
    this.formname?.controls[this.name]?.markAsDirty();
    this.profileDetails.CustomerList.forEach(opt => {
      opt.IsSelected = false;
    });
    if (option) {
      option.IsSelected = true;
        this.selected = this.modelType == 'object' ? option : option.Id;
    }
    else {
      this.selected = null;
    }
    this.profileDetails.SelectedId = this.selected;
    this.loadData();
    this.SelectorOpen = false;
    this.profileDetails.CustomerSearchName = this.selectedItem;
    $(".multiselect-container").animate({ scrollTop: 0 }, "fast");
  }
  loadData() {
    this.profileDetails.CustomerList?.forEach(opt => {
      opt.IsSelected = false;

    });

    if (this.profileDetails.CustomerList && (this.profileDetails.SelectedId)) {
      var selectedItem = this.profileDetails.CustomerList.filter(x => x.Id == this.profileDetails.SelectedId)[0];
      if (selectedItem) {
        this.selectedItem = selectedItem.Name;
        selectedItem.IsSelected = true;
      }
      else {
        this.selectedItem = this.profileDetails.CustomerList == undefined ? "Select" : this.profileDetails.CustomerList[0].Name;
      }
    }
    else {
      this.selectedItem = this.profileDetails.CustomerList == undefined ? "Select" : this.profileDetails.CustomerList[0].Name;
    }
  }
  trackByName(selectedRow: any): string {
    return selectedRow.Name;
  }
  OnCustomerChange(event, customer) {
    if (customer != undefined && customer.toString().length >= 3) {
      this.SearchCustomer();
    } else if (customer.toString().length < 3) {
      this.SelectorOpen = false;
    }
  }
  KeyPressAlphanumeric(event) {
    var iKeyCode = event.keyCode;
    if ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || event.charCode == 45 || (event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 33 && event.charCode <= 47) || (event.charCode >= 58 && event.charCode <= 64) || (event.charCode >= 91 && event.charCode <= 96) || (iKeyCode >= 97 && iKeyCode <= 122) || (event.charCode >= 123 && event.charCode <= 126)) { 
      return true;
    } else if (this.profileDetails.CustomerSearchName.length < 3)
      this.SelectorOpen = false;
    event.stopPropagation();
    event.preventDefault();
  }
  OnPaste(event: ClipboardEvent): string {
    event.preventDefault();
    var totalValue = '';
    var pastedValue = '';
    if ((window as any).clipboardData && (window as any).clipboardData.getData) { // IE
      pastedValue = (window as any).clipboardData.getData('Text');
    }
    else if (event.clipboardData && event.clipboardData.getData) { // other browsers
      pastedValue = event.clipboardData.getData('text/plain');
    }
    if (this.ValidationAlphaNumeric(pastedValue)) {
      totalValue = pastedValue;
      this.profileDetails.CustomerSearchName = pastedValue;
      pastedValue = '';
      if (this.profileDetails.CustomerSearchName.length >=3) {
        this.SearchCustomer();
      }
    }
    event.stopPropagation();
    event.preventDefault();
    return this.profileDetails.CustomerSearchName;
  }
  ValidationAlphaNumeric(string) {
    var pattern = /^[ A-Za-z0-9/]*$/;
    return pattern.test(string);
  }
}
