<header class="c-app__header">
  <div class="app-container c-app__header-inner">
    <div class="c-app__header-brand">
      <div class="-logo"><img src="../assets/toolkit/images/logo/mlogo.svg" alt="accenture"></div>
      <span class="c-app__header-title">myWizard</span>
      <div class="c-header__list-wrap" role="navigation">
        <ul class="c-header__list">
          <!--<li class="c-header__item">
            <a href="javascript:void(0);" title="Learn" aria-label="Learn">Learn</a>
          </li>-->
          <li class="c-header__item -active -disableCursor">
            <a href="javascript:void(0);" aria-label="Deliver">Deliver</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="c-app__header-nav">
      <a [href]="(currentDay == 0 || currentDay == 6) ? 'javascript:void(0);' : 'https://teams.microsoft.com/l/chat/0/0?users=myWizard.support@accenture.com'"
         [target]="(currentDay == 0 || currentDay == 6) ? '_self' : '_blank'" rel="noopener norefferer" data-myW-toggle="tooltip" data-myW-placement="bottom" data-myW-custClass="-scopeTooltip" data-myw-title="Live chat is available round the clock from Monday to Friday for myWizard Submission queries and support."
         aria-label="Live chat is available round the clock from Monday to Friday for myWizard Submission queries and support.">
        <img src="../assets/toolkit/images/chat-icon.svg" [ngClass]="(currentDay == 0 || currentDay == 6) ? 'offline-chat-opacity' : 'live-chat-opacity'" aria-label="Live chat is available round the clock from Monday to Friday for myWizard Submission queries and support." alt="Live chat">
      </a>
      <a href="javascript:void(0);" [ngClass]="isScopeSelectorVisible ? 'c-notify' : 'notification-without-alert'"  data-myw-title="Notification" data-myw-toggle="tooltip" data-myw-placement="bottom"aria-label="Notification" data-toggle="modal" (click)="OpenNotificationsPopUp()" data-flyoutid="notification"></a>
      <div class="c-dropdown -right -static -ia-dropdown">
        <!--class -static doesnt have any style, it's for scripting purposes-->
        <a href="javascript:void(0);" class="c-app__header-item "   aria-label="demo.user" attr.aria-haspopup="true" aria-expanded="false" style="text-decoration:none;" data-toggle="dropdown">
          <strong>{{enterpriseId}}</strong>
          <!--<img src="../assets/toolkit/images/user.svg" alt="demo.user">
          <span class="sr-only">demo.user</span>-->
        </a>
        <div class="c-dropdown-menu">
          <!--<div class="c-dropdown-header">
            <div class="c-ia-dropdown__inner">
              <img src="../assets/toolkit/images/user.svg" alt="user">
              <div class="c-ia-dropdown__row">
                <div class="-medium">Jane Doe</div>
                <div class="-light">Application Development Manager</div>
              </div>
            </div>
          </div>-->
          <div class="c-dropdown-body">
            <!--<div class="c-dropdown-divider"></div>-->
            <ng-container *ngFor="let tile of tiles; trackBy: trackByTileId;">
              <a class="c-dropdown-item -align-center" role="button" href="{{tile.Url}}"
                 [attr.aria-label]="(tile.TileId == '159' || tile.TileId == '167') ? tile.Name + '(open the outlook)' : tile.Name + '(Opens a new window)'"
                 [attr.data-myw-title]="(tile.TileId == '159' || tile.TileId == '167') ? tile.Name + '(open the outlook)' : tile.Name + '(Opens a new window)'" data-myw-toggle="tooltip" data-myw-placement="bottom" target="{{tile.TargetPage}}" rel="noopener norefferer">
                <img [src]="tile.Name == 'Contact a Coach' ? '../assets/toolkit/images/coach.svg' : (tile.Name == 'About myWizard' ? '../assets/toolkit/images/support.svg' : '../assets/toolkit/images/training.svg')"
                     role="presentation" [attr.alt]="tile.Name == 'Contact a Coach' ? 'coach' : (tile.Name == 'myWizard Support' ? 'support' : 'training')">
                <span class="-desc">{{tile.Name}}</span>
              </a>
            </ng-container>
            <a class="c-dropdown-item -align-center" role="button"  data-myw-title="Log Out" data-myw-toggle="tooltip" data-myw-placement="bottom" id="SignOutId" target="_self" aria-label="Logout" href="{{logOutUrl}}" (click)="logOut()">
              <img src="../assets/images/logout-icon.svg" role="presentation" alt="user">
              <span class="-desc">Logout</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
 </header>

<div class="c-banner">
  <div class="app-container">
    <div class="c-banner__content" role="navigation">
      <ul class="c-banner__wrapper">
        <li *ngIf="isManageTabVisible">
          <a [routerLink]="['/manage']" [attr.tabindex]="(!IsProfileCompletedStatus) ? -1 : null" [ngClass]="{'-active':isManageActive,'a-disabled':  !IsProfileCompletedStatus}" (click)="setActiveTabOnClick('manage')" aria-label="Manage">Manage</a>
        </li>
        <li *ngIf="isPortfolioTabVisible">
          <a [routerLink]="['/portfolio']" [ngClass]="{'-active':isPortfolioActive}" (click)="setActiveTabOnClick('portfolio')" aria-label="Portfolio">Portfolio</a>
        </li>
        <li *ngIf="isTaskTabVisible">
          <a [routerLink]="['/task']" [attr.tabindex]="(!IsProfileCompletedStatus) ? -1 : null" [ngClass]="{'-active':isTaskActive,'a-disabled':  !IsProfileCompletedStatus}" (click)="setActiveTabOnClick('tasks')" aria-label="Tasks">Tasks</a>
        </li>
        <li *ngIf="isAdminTabVisible">
          <a [routerLink]="['/admin']" [ngClass]="{'-active':isAdminActive}" (click)="setActiveTabOnClick('admin')" aria-label="Admin">Admin</a>
        </li>
        <li *ngIf="isSupportTabVisible">
          <a [routerLink]="['/support']" [ngClass]="{'-active':isSupportActive}" (click)="setActiveTabOnClick('support')" aria-label="Support">Support</a>
        </li>


      </ul>
      <div class="c-banner__input">
        <a *ngIf="deployRegionHeader == 'EU - Account'"  data-myw-title="EU Account" data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-banner-eu -disableCursor">{{deployRegionHeader}}</a>

        <!--<div class="c-banner__scope">
      <a href="javascript:;" role="button" title="Scope" data-flyoutid="scope" class="icon-arrow-down" data-myw-title="<div>Xiaomi Technology LLC / XIAOMI SCM Implementation / Conv CMS / <span> Agile trend workstram with cms </span></div>"  data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custClass="-scopeTooltip">XIAOMI Technology LLC</a>
    </div>-->

      </div>
    </div>
  </div>
  
</div>
   
    <div class="c-banner-scope" *ngIf="isScopeSelectorVisible">
      <div class="app-container">
        <div class="c-banner__input">
         
          <div *ngIf="isScopeSelectorVisible" class="c-banner__scope">
            <a *ngIf="isScopeSelectorDropDownVisible" href="javascript:;" role="button" [(attr.data-myW-title)]="scopeTitle" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custClass="-scopeTooltip" data-flyoutid="scope" (click)="openScopeSelecor($event)" class="icon-arrow-down c-select__selected-text">

              <span>{{clientName}}</span>
              <span>{{endtoendName}}</span>
              <span *ngIf="cmsName != ''">{{cmsName}}</span>
              <span *ngIf="wsName != ''">{{wsName}}</span>
            </a>

            <a *ngIf="!isScopeSelectorDropDownVisible" href="javascript:;" role="button" [ngClass]="{'-disabled' : !isScopeSelectorDropDownVisible}" [(attr.data-myW-title)]="scopeTitle" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custClass="-scopeTooltip" class="icon-arrow-down c-select__selected-text -disabled">

              <span>{{clientName}}</span>
              <span>{{endtoendName}}</span>
              <span *ngIf="cmsName != ''">{{cmsName}}</span>
              <span *ngIf="wsName != ''">{{wsName}}</span>
            </a>
          </div>

          <div *ngIf="isScopeSelectorVisible && endToEndCMSTeams" class="c-select-dropdown">
            <div class="c-dropdown -right -static">
              <search-dropdown id="teams" name="teams" #teams="ngModel" [labelInfo]="'Teams'" 
                               [options]="endToEndCMSTeams.TMS"
                               [isShow]="true"
                               [selectText]="'Cross Team (All)'"
                               [selected]="teamScopeSelector.teamId"
                               [disableDropdown]="isDisableTeamScopeSelector"
                               [(ngModel)]="teamScopeSelector.teamId"
                               (callback)="TeamChange(teamScopeSelector.teamId)"
                               [isDisableTeamScopeSelector]="isDisableTeamScopeSelector">
              </search-dropdown>
            </div>

          </div>
        </div>
      </div>
    </div>
<!--<scope-selector></scope-selector>-->
<!--<nav class="nav-bar navbar-default">
  <div class="navbar-header">
    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-menu" attr.aria-expanded="false">
      <span class="sr-only">Toggle navigation</span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
  </div>
  <div class="elem pageText" style="display:none"></div>
  <div id="navbar-menu" class="collapse navbar-collapse" role="main">
    <ul class="nav navbar-nav">
      <li *ngIf="isPortfolioTabVisible" [ngClass]="{'active':isPortfolioActive}">
        <a [routerLink]="['/portfolio']" (click)="setActiveTabOnClick('portfolio')" aria-label="Portfolio" title="Portfolio">Portfolio</a>
      </li>
      <li *ngIf="isManageTabVisible" [ngClass]="{'active':isManageActive}">
        <a [routerLink]="['/manage']" (click)="setActiveTabOnClick('manage')" aria-label="Manage" title="Manage">Manage</a>
      </li>
      <li *ngIf="isTaskTabVisible" [ngClass]="{'active':isTaskActive}">
        <a [routerLink]="['/task']" (click)="setActiveTabOnClick('tasks')" aria-label="Tasks" title="Tasks">Tasks</a>
      </li>
      <li *ngIf="isAdminTabVisible" [ngClass]="{'active':isAdminActive}">
        <a [routerLink]="['/admin']" (click)="setActiveTabOnClick('admin')" aria-label="Admin" title="Admin">Admin</a>
      </li>
      <li *ngIf="isSupportTabVisible" [ngClass]="{'active':isSupportActive}">
        <a [routerLink]="['/support']" (click)="setActiveTabOnClick('support')" aria-label="Support" title="Support">Support</a>
      </li>
    </ul>
    <div class="navbar-right col-xs-12 col-sm-5">
      <scope-selector></scope-selector>
    </div>
    <div *ngIf="deployRegionHeader != '' && !isCMMIPage" style="position: absolute;top: 71%;left: 82%;padding-top:15px;"><em>{{deployRegionHeader
            }}</em>
</div>
    <div *ngIf="deployRegionHeader != '' && isCMMIPage" style="position: absolute;top: 71%;left: 77%;padding-top:15px;"><em>{{deployRegionHeader}}</em></div>
  </div>
</nav>-->
