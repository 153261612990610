import { TileService } from '../core/services/tile.service';
import { SessionStorage } from '../core/context/sessionstorage';
import { Router, ActivatedRoute } from '@angular/router';
import cloneDeep from 'lodash/cloneDeep';
import { Component } from '@angular/core';
import { UserPreferenceService } from '../core/services/user.preference.service';
import { UserService } from '../core/services/user.service';
import { ReleaseNoteService } from '../core/services/release.note.service';
import { ApplicationLoggingService } from '../core/services/application.logging.service';
import { filter } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AnnouncementComponent } from './announcement.component';
import { enumModuleAction, enumTile, enumFeatureAccess } from '../core/context/enum';
var $ = (<any>window).$;

@Component({
  templateUrl: './release-note-history.component.html',
  selector: 'releasenote'
})

export class ReleaseNoteHistoryComponent {

  currentNote: any = [];
  IschangeCriticalText: string = "Critical! ";
  tiles: any;
  Releases: any;
  ReleaseId: any;
  SelectedReleaseId: any;
  SelectedIsChangeCritical: any;
  ReleaseNumberId: any;
  IsCurrentRelease: any;
  IsChangeCriticalId: any = null;
  ReleaseNoteApplications: any;
  ReleaseNoteChangeTypes: any;
  ReleasesList: any;
  isCritical: boolean = false;
  SelectedRelease: any;
  ReleaseNotesList: any;
  criticalChangesCount: number;
  ReleaseNoteHistory: any = { IsChangeCriticalId: null, ReleaseId: null };
  modalRef_announcement: BsModalRef;
  isScopeSelectorVisible: boolean = false;

  constructor(private tileService: TileService,
    private applicationLoggingService: ApplicationLoggingService,
    private releaseService: ReleaseNoteService,
    private sessionStorage: SessionStorage,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userPreferenceService: UserPreferenceService,
    private userService: UserService) { }


  ngOnInit() {

    this.loadReleaseNotes();
    
   
  }
  loadReleaseNotes() {
    this.releaseService.getReleases().subscribe((response: any) => {
      this.Releases = response;
      this.Releases.splice(0, 1);
      this.Releases.splice(0, 1);
    
      this.ReleaseId = this.Releases[0].Id;
      this.SelectedReleaseId = cloneDeep(this.ReleaseId);
      this.SelectedIsChangeCritical = null;
      this.GetReleaseDetails(this.SelectedReleaseId);
    });
    
  }
  
  GetReleaseDetails(releaseNumberId) {
    this.ReleaseNumberId = releaseNumberId;
    this.IsCurrentRelease = this.Releases[0].Id == releaseNumberId;
    this.releaseService.getReleaseDetails(releaseNumberId, this.IsCurrentRelease, this.ReleaseNoteHistory.IsChangeCriticalId).subscribe((response: any) => {
      this.ReleaseNoteApplications = response.ReleaseNoteApplications;
      this.ReleaseNoteChangeTypes = response.ReleaseNoteChangeTypes;
      this.SelectedRelease = response.ReleaseNotesList;
      this.ReleaseNotesList = cloneDeep(response.ReleaseNotesList);

      this.SelectedRelease.forEach((item) => {
        if (item.IsChangeCritical == "Y") {
          if (item.FeatureName.indexOf('<p>') >= 0) {
            var critical = "<p><span class='font-red'>" + this.IschangeCriticalText + "</span>";
            item.FeatureName = item.FeatureName.replace("<p>", critical);
          } else {
            var critical = "<span class='font-red'>" + this.IschangeCriticalText + "</span>";
            item.FeatureName = critical + item.FeatureName;
          }
        }
        if (item.ImpactedApplication) {
          var impactedApp = "<b>Impacted Apps - " + item.ImpactedApplication + "</b>";
          item.Impact = item.Impact + impactedApp;
        }

      })
      this.criticalChangesCount = this.SelectedRelease.filter(x => x.IsChangeCritical == 'Y').length;
      this.SelectedReleaseId = cloneDeep(this.ReleaseId);
      this.SelectedIsChangeCritical = cloneDeep(this.IsChangeCriticalId);
    });
  }
  Validation(string) {
    var pattern = /^\d+$/;
    return pattern.test(string);
  }

  CloseReleaseNoteHistoryScreen() {
    this.bsModalRef.hide();
  }
  toggleSlide() {
    this.CloseReleaseNoteHistoryScreen();
    const initialState = { isScopeSelectorVisible: this.isScopeSelectorVisible }
    this.modalRef_announcement = this.modalService.show(AnnouncementComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
    $(".modal-dialog").addClass('custom-modal-announcement');
  }
  downloadReleaseNotes() {
    var release = filter(this.Releases, { Id: this.SelectedReleaseId })[0];
    if (release) {
      let fileName = release.Name;
      var isDownloadInprogress = document.getElementById('downloadReleaseNotes').getAttribute('download-active');
      if (isDownloadInprogress == "false") {
        this.releaseService.downloadReleaseNotes(this.SelectedReleaseId, 'downloadReleaseNotes', fileName, 'xlsx', this.SelectedIsChangeCritical);
        this.UsageLogging(enumModuleAction.Template, enumTile.ManageReleaseNotes, enumFeatureAccess.ReleaseNotes);
      }
    }
  }
  closeReleaseNoteHistoryScreen = function () {
    this.SelectedRelease = null;
  }
  UsageLogging(moduleAction, tileID, featureAccessID) {
    this.applicationLoggingService.applicationUsageLogging(moduleAction, tileID, featureAccessID).subscribe();
  }
  trackById(index: number, endtoend: any): any {
    return endtoend.Id;
  }
}
