
<div class="app-wrap">
  <div class="c-myWizard c-user-guide-stories -collapsed">
    <div class="c-myWizard-content">
      <div class="c-myWizard-container">
          <div class="c-user-guide-stories-content">
            <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/matrix-user-guide.jpg" alt="UserGuide" role="presentation" /></div>
            <div class="c-user-guide-stories-container -matrix-userguide">
              <div class="c-user-guide-stories-data">
                <div class="c-user-guide-stories-data__header">
                  <a href="javascript:;" role="button" data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="OnBackClick()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
                </div>
                <div class="c-user-guide-stories-data__body">
                  <div class="c-user-guide-stories__text">The delivery matrix represents how the two dimensions in delivery structure interact: What and who</div>
                  <div class="c-user-guide-stories-legends">
                    <div class="c-legends-item">
                      <i style="background-color: #7500C0;"></i>
                      <span>Team</span>
                    </div>
                    <div class="c-legends-item">
                      <i style="background-color: #E3B7FF;"></i>
                      <span>Team Group</span>
                    </div>
                    <div class="c-legends-item -cms">
                      <i style="background-color: #FAFAFA;"></i>
                      <span>CMS</span>
                    </div>
                  </div>
                </div>
                <div class="c-user-guide-stories-data__footer">
                  <div class="c-user-guide-stories__progress">
                    <div class="c-user-guide-stories__progress-bar">
                      <span style="--value:100%"></span>
                    </div>
                    <div class="c-user-guide-stories__progress-data">
                      <span>1</span>
                      <span>/ 1</span>
                    </div>
                  </div>
                  <div class="c-user-guide-stories__actions">
                    <a href="javascript:;" role="button" class="-prev -disabled">PREV</a>
                    <a href="javascript:;" role="button" class="-next -disabled">NEXT</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
      </div>
      </div>
    </div>
  </div>
