<div class="app-wrapper app-index">
  <header class="c-app__header">
    <div class="app-container c-app__header-inner">
      <div class="c-app__header-brand">
        <div class="-logo"><img src="../assets/toolkit/images/logo/mlogo.svg" alt="accenture"></div>
        <span class="c-app__header-title" title="myWizard">myWizard</span>
        <div class="c-header__list-wrap" role="navigation">
          <ul class="c-header__list">
           
          </ul>
        </div>
      </div>
      <div class="c-app__header-nav">
        <a [href]="(currentDay == 0 || currentDay == 6) ? 'javascript:void(0);' : 'https://teams.microsoft.com/l/chat/0/0?users=myWizard.support@accenture.com'"
           [target]="(currentDay == 0 || currentDay == 6) ? '_self' : '_blank'" rel="noopener norefferer" data-myW-toggle="tooltip" data-myW-placement="bottom" data-myW-custClass="-scopeTooltip" data-myw-title="Live chat is available round the clock from Monday to Friday for myWizard Submission queries and support."
           aria-label="Live chat is available round the clock from Monday to Friday for myWizard Submission queries and support.">
          <img src="../assets/toolkit/images/chat-icon.svg" [ngClass]="(currentDay == 0 || currentDay == 6) ? 'offline-chat-opacity' : 'live-chat-opacity'" aria-label="Live chat is available round the clock from Monday to Friday for myWizard Submission queries and support." alt="Live chat">
        </a>

        <div class="c-dropdown -right -static -ia-dropdown">
          <a href="javascript:void(0);" class="c-app__header-item " title="EnterpriseId" aria-label="demo.user" attr.aria-haspopup="true" aria-expanded="false" style="text-decoration:none;" data-toggle="dropdown">
            <strong>{{enterpriseId}}</strong>
          </a>
          <div class="c-dropdown-menu">
            <div class="c-dropdown-body">
              <a class="c-dropdown-item -align-center" role="button" title="User Guide" aria-label="User Guide" href="javascript:;">
                <img src="../assets/toolkit/images/user-guide.svg" role="presentation" alt="user">
                <span class="-desc">User Guide</span>
              </a>
              <ng-container *ngFor="let tile of tiles; trackBy: trackByTileId;">
                <a class="c-dropdown-item -align-center" role="button" href="{{tile.Url}}"
                   [attr.aria-label]="(tile.TileId == '159' || tile.TileId == '167') ? tile.Name + '(open the outlook)' : tile.Name + '(Opens a new window)'"
                   [attr.title]="(tile.TileId == '159' || tile.TileId == '167') ? tile.Name + '(open the outlook)' : tile.Name + '(Opens a new window)'" target="{{tile.TargetPage}}" rel="noopener norefferer">
                  <img [src]="tile.Name == 'Contact a Coach' ? '../assets/toolkit/images/coach.svg' : (tile.Name == 'myWizard Support' ? '../assets/toolkit/images/support.svg' : '../assets/toolkit/images/training.svg')"
                       role="presentation" [attr.alt]="tile.Name == 'Contact a Coach' ? 'coach' : (tile.Name == 'myWizard Support' ? 'support' : 'training')">
                  <span class="-desc">{{tile.Name}}</span>
                </a>
              </ng-container>
              <a class="c-dropdown-item -align-center" role="button" title="Log Out" id="SignOutId" target="_self" aria-label="Logout" href="{{logOutUrl}}" (click)="logOut()">
                <img src="../assets/images/logout-icon.svg" role="presentation" alt="user">
                <span class="-desc">Logout</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="app-wrap app-container">
    <div class="ad">
      <p class="access-denied-header">You are not authorized to use this page.</p>
      <p class="access-denied-body">Please reach out to your respective Delivery Lead/Delivery Manager to add your enterprise id in "Alternative Contacts" under Manage Tab | "Initiation & Configuration" | "Initiate Profile" | "Key Contacts" OR please reach out <a class="hyperlink" href="mailto:myWizard.Support@accenture.com">myWizard.Support&#64;accenture.com.</a></p>
    </div>
  </div>

  <footer-section></footer-section>
</div>
