import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Output } from '@angular/core';
import * as Enumerable from 'linq';
import { SessionStorage } from '../../../core/context/sessionstorage';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import cloneDeep from 'lodash/cloneDeep';
import { enumEntityType, enumAssociationType, enumDeliveryAccountability, enumFeatureAccess, enumModuleAction, enumSubmissionType, enumTile, enumUserPreference } from '../../../core/context/enum';
import { DemographicsService } from '../../../core/services/demographics.service';
import { SharedService } from '../../../core/services/shared.service';
var $ = (<any>window).$;
declare var window: any;
import { DatePipe } from '@angular/common';
import { TMSService } from '../../../core/services/tms.service';
import { NgForm } from '@angular/forms';
import * as dayjs from 'dayjs';
import { ImagePath } from '../../../core/context/constants';
import { TeamMemberFilterComponent } from './team.members.filter.component';
import { TeamStructureSharedService } from '../team.structure.shared.service';
import { BroadCastService } from '../../../core/services/broadcast.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'team-add-edit',
  templateUrl: './team.add.edit.component.html',
  providers: [DemographicsService, DatePipe, TMSService, TeamStructureSharedService ]
})
export class TeamAddEditComponent {

  dayjs: any = dayjs;
  DateValidation: any = {
    IsValidStartDate: true, IsValidStartDateEndDate: true, IsValidEndDate: true, IsValidEndDateStartDate: true,
  };
  tmsHierarchy: any;
  teamStatus: any;
  teamStatusDropDownDisable: any;
  IsEditTMS: any;
  Action: any;
  tmsGroupDetail: any;
  tmsDetail: any;
  TempAccountGroups: any[];
  TempDeliveryUnits: any[];
  TempDeliveryGroups: any[];
  DeliveryAccountabilityTypes: any[] = [];
  IsDisableAccType: any;
  IsDisableAGDGDU: any;
  ActiveDropdownDirective: boolean;
  DisableAddButton: boolean;
  DisableAddGroupButton: boolean;
  currentSelectedTMS: any;
  RoleAccess = this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);
  teamMemberFilterAttribute: any = [];
  tmsRDDA: any = {};
  confirmationMessage: string;
  popupId: string;
  DraggedItems: any[];
  IsTMSShowModifiedDetails: boolean;
  draggdropcount: number;
  dragging: number;
  EndToEndRefCode: any;
  EndToEndName: any;
  EndToEndType: any;
  EndToEndStartDate: any;
  EndToEndEndDate: any;
  popupTMSDetailsTab: string = "tmsTeamDetails";
  popupTMSMembersTab: string = "tmsTeamMembers";
  IsEditable: any;
  TeamMemberDetails: any;
  isSaveButtonClick: boolean = false;
  isActive: boolean = true;
  onSave: any;
  StatusFlag: any;
  PopUpTitle: any;
  modalRef_TMFilter: BsModalRef;
  value: any;
  PopUpHeader: any;
  resourceFlag: any = -1;
  associatedResourceFlag: any = -1;
  scrollYPosition: any;
  @Output() teamAddEditPopupData: EventEmitter<any> = new EventEmitter();  // .. Child Popup -> Parent
  popupGroupName: any;
  endDateValue: boolean = true;
  startDateValue: boolean = true;

  constructor(private bsModalRef: BsModalRef, private tmsService: TMSService, private sharedService: SharedService,
    private sessionStorage: SessionStorage, private ref: ChangeDetectorRef, private demographicService: DemographicsService
    , private teamStructureSharedService: TeamStructureSharedService, private modalService: BsModalService,
    private datePipe: DatePipe, private broadCastService:
      BroadCastService
  ) { }

  ngOnInit() {   
    //ux revamp : changes to flyup popup
    $('body').addClass('block-scroll');
    if (this.tmsHierarchy.DeliveryAccountabilityTypes && (this.tmsHierarchy.DeliveryAccountabilityTypes.length <= 0) || this.tmsHierarchy.DeliveryAccountabilityTypes == null || this.tmsHierarchy.DeliveryAccountabilityTypes == '')
      this.tmsHierarchy.DeliveryAccountabilityTypes = [];
    this.PopUpTitle = this.PopUpHeader; 
    if (this.Action == 'Edit') {
      this.UpdateCMSModel(this.tmsDetail.CMSDetails, this.tmsDetail.WorkStreamDetails, this.Action);
      
    }
    this.ActivateTMSFirstTab();
    setTimeout(() => {
      window.dropdownComponent();
      window.tabsComponent();
      window.collapseComponent();
      window.TooltipFunc();
    }, 0);
    this.StatusFlag = [{ Id: '1', Name: 'Open' }, { Id: '2', Name: 'Closed' }];
  }

  ngOnDestroy() {
    window.scrollTo(0, this.scrollYPosition);
  }

  ActivateTMSFirstTab() {
    $("#" + this.popupTMSMembersTab).removeClass('active');
    $('#' + this.popupTMSDetailsTab).addClass('active');
  }

  GetRddaTeamMembers() {
    if (!(this.tmsRDDA && this.tmsRDDA.EndToEndTeamMembers && this.tmsRDDA.TeamMemberFilterAttribute)) {
      this.tmsService.getTeamMembers().subscribe((response: any) => {
        this.tmsRDDA.EndToEndTeamMembers = response.EndToEndTeamMembers;
        this.tmsRDDA.TeamMemberFilterAttribute = response.TeamMemberFilterAttribute;
        this.teamMemberFilterAttribute = response.TeamMemberFilterAttribute;
        
        if (this.tmsRDDA.TeamMemberFilterAttribute) {
          if (this.tmsRDDA.TeamMemberFilterAttribute.Skills && this.tmsRDDA.TeamMemberFilterAttribute.Skills.length > 0) {
            this.tmsRDDA.TeamMemberFilterAttribute.Skills.forEach(function (item) {
              item.IsSelected = false;
            });
          }
          else {
            this.tmsRDDA.TeamMemberFilterAttribute.Skills = []; //if null, then set []
          }

          if (this.tmsRDDA.TeamMemberFilterAttribute.Technology && this.tmsRDDA.TeamMemberFilterAttribute.Technology.length > 0) {
            this.tmsRDDA.TeamMemberFilterAttribute.Technology.forEach(function (item) {
              item.IsSelected = false;
            });
          }
          else {
            this.tmsRDDA.TeamMemberFilterAttribute.Technology = []; //if null, then set []
          }

          if (this.tmsRDDA.TeamMemberFilterAttribute.SkillProficiency && this.tmsRDDA.TeamMemberFilterAttribute.SkillProficiency.length > 0) {
            this.tmsRDDA.TeamMemberFilterAttribute.SkillProficiency.forEach(function (item) {
              item.IsSelected = false;
            });
          }
          else {
            this.tmsRDDA.TeamMemberFilterAttribute.SkillProficiency = []; //if null, then set []
          }

          if (this.tmsRDDA.TeamMemberFilterAttribute.CareerRole && this.tmsRDDA.TeamMemberFilterAttribute.CareerRole.length > 0) {
            this.tmsRDDA.TeamMemberFilterAttribute.CareerRole.forEach(function (item) {
              item.IsSelected = false;
            });
          }
          else {
            this.tmsRDDA.TeamMemberFilterAttribute.CareerRole = []; //if null, then set []
          }

          if (this.tmsRDDA.TeamMemberFilterAttribute.CareerLevel && this.tmsRDDA.TeamMemberFilterAttribute.CareerLevel.length > 0) {
            this.tmsRDDA.TeamMemberFilterAttribute.CareerLevel.forEach(function (item) {
              item.IsSelected = false;
            });
          }
          else {
            this.tmsRDDA.TeamMemberFilterAttribute.CareerLevel = [];  //if null, then set []
          }

          if (this.tmsRDDA.TeamMemberFilterAttribute.WorkLocation && this.tmsRDDA.TeamMemberFilterAttribute.WorkLocation.length > 0) {
            this.tmsRDDA.TeamMemberFilterAttribute.WorkLocation.forEach(function (item) {
              item.IsSelected = false;
            });
          }
          else {
            this.tmsRDDA.TeamMemberFilterAttribute.WorkLocation = [];  //if null, then set []
          }
        }

        this.SetScopeForTeamMemberTab();
      });
    }
    else {
      this.SetScopeForTeamMemberTab();

    }
    setTimeout(() => {
      window.tabsComponent();
    }, 0);

  }

  SetScopeForTeamMemberTab() {
    if (this.Action == "Edit") {
      this.SetEndToEndTeamMembers();
    }
    else {
      this.tmsDetail.EndToEndTeamMembers = cloneDeep(this.tmsRDDA.EndToEndTeamMembers);
      this.tmsDetail.StaticEndToEndTeamMembers = cloneDeep(this.tmsRDDA.EndToEndTeamMembers);
      this.TeamMemberDetails = cloneDeep(this.tmsRDDA.EndToEndTeamMembers);
    }
  }

  SetEndToEndTeamMembers() {
    this.tmsDetail.EndToEndTeamMembers = cloneDeep(this.tmsRDDA.EndToEndTeamMembers);
    this.TeamMemberDetails = cloneDeep(this.tmsRDDA.EndToEndTeamMembers);
    var endToEndTeams = cloneDeep(this.tmsRDDA.EndToEndTeamMembers);
    endToEndTeams.forEach((data: any, index: any) => {
      var teamMember = this.tmsDetail.TeamMembers.filter((x: any) => x.Name == data.Name)[0];
      if (teamMember) {
        var endToendTeamMember = this.tmsDetail.EndToEndTeamMembers.filter((x: any) => x.Name == data.Name)[0];
        if (endToendTeamMember) {
          this.tmsDetail.EndToEndTeamMembers.splice(this.tmsDetail.EndToEndTeamMembers.indexOf(endToendTeamMember), 1);
        }
      }
    });
    this.tmsDetail.StaticEndToEndTeamMembers = cloneDeep(this.tmsDetail.EndToEndTeamMembers);
  }

  onSelectTeamMember(item: any, i: any) {
    this.resourceFlag = i;
    if (this.tmsDetail.SelectedEndToEndTeamMember.length > 0) {
      this.tmsDetail.SelectedEndToEndTeamMember.pop();
    }
    this.tmsDetail.SelectedEndToEndTeamMember.push(item);
  }

  onSelectRemoveTeamMember(item: any, i: any) {
    this.associatedResourceFlag = i;
    if (this.tmsDetail.SelectedTeamMember.length > 0) {
      this.tmsDetail.SelectedTeamMember.pop();
    }
    this.tmsDetail.SelectedTeamMember.push(item);
  }

  ClosePopUp(form: NgForm, event) {
    if (event != null) {
      var keycode = event.keyCode;
      if (keycode == 13) { // code for ENTER key
        if (this.tmsHierarchy.Type == 'TMS' && this.Action == 'Edit') {
          $('#' + 'TMS' + this.tmsDetail.TMSId).focus();
          $('#tmsAddEdit').modal('hide');
        }
        else if (this.tmsHierarchy.Type == 'TMS' && this.Action == 'ADD') {
          $('#AddTeamDetails').focus();
          $('#tmsAddEdit').modal('hide');
        }
        else if (this.tmsHierarchy.Type == 'Group' && this.Action == 'Edit') {
          $('#' + 'TMSGRP' + this.tmsGroupDetail.TMSGroupId).focus();
          $('#tmsAddEdit').modal('hide');
        }
        else {
          $('#AddGroupDetails').focus();
          $('#tmsAddEdit').modal('hide');
        }
      }
      $("body").removeClass("hide-parent-scroll");
    }

    this.teamAddEditPopupData.emit({ DisableAddButton: false });
    this.teamAddEditPopupData.emit({ DisableAddGroupButton: false });
    this.teamAddEditPopupData.emit({ ActiveDropdownDirective: false });
    this.teamAddEditPopupData.emit({ currentSelectedTMS: null });

    this.ActiveDropdownDirective = false;
    this.DisableAddButton = false;
    this.DisableAddGroupButton = false;
    this.currentSelectedTMS = null;
    this.ResetSelection();
    this.bsModalRef.hide();
    this.RestoreScrollbar();
  }

  ResetSelection() {
    this.currentSelectedTMS = null;
    if (this.tmsHierarchy) {
      this.tmsHierarchy.TMSGroupLevelOne.forEach((tmsGroup1: any, key: any) => {
        tmsGroup1.IsSelected = false;
        tmsGroup1.TMS.forEach((tms: any, key: any) => {
          tms.IsSelected = false;
        });
        tmsGroup1.TMSGroupLevelTwo.forEach((tmsGroup2: any, key: any) => {
          tmsGroup2.IsSelected = false;
          tmsGroup2.TMS.forEach((tms: any, key: any) => {
            tms.IsSelected = false;
          });
          tmsGroup2.TMSGroupLevelThree.forEach((tmsGroup3: any, key: any) => {
            tmsGroup3.IsSelected = false;
            tmsGroup3.TMS.forEach((tms: any, key: any) => {
              tms.IsSelected = false;
            });
            tmsGroup3.TMSGroupLevelFour.forEach((tmsGroup4: any, key: any) => {
              tmsGroup4.IsSelected = false;
              tmsGroup4.TMS.forEach((tms: any, key: any) => {
                tms.IsSelected = false;
              });
              tmsGroup4.TMSGroupLevelFive.forEach((tmsGroup5: any, key: any) => {
                tmsGroup5.IsSelected = false;
                tmsGroup5.TMS.forEach((tms: any, key: any) => {
                  tms.IsSelected = false;
                });
              });
            });
          });
        });
      });
      this.tmsHierarchy.TMS.forEach((tms: any, key: any) => {
        tms.IsSelected = false;
      });
      this.tmsHierarchy.Teams.forEach((tms: any, key: any) => {
        tms.IsSelected = false;
      });
      this.tmsHierarchy.TMSGroups.forEach((tms: any, key: any) => {
        tms.IsSelected = false;
      });
      this.DisableAddButton = false;
      this.DisableAddGroupButton = false;
    }
  }

  RemoveResourceToTeam() {
    this.associatedResourceFlag = -1;
    this.tmsDetail.SelectedTeamMember.forEach((data: any, index: any) => {
      this.tmsDetail.EndToEndTeamMembers.push(data);
      this.tmsDetail.StaticEndToEndTeamMembers.push(data);
      var teamMember = this.tmsDetail.TeamMembers.filter((x: any) => x.Name == data.Name)[0];
      if (teamMember) {
        this.tmsDetail.TeamMembers.splice(this.tmsDetail.TeamMembers.indexOf(teamMember), 1);
      }
    });
    this.tmsDetail.SelectedTeamMember = [];
    this.ApplyFilter(null);
  }

  ApplyFilter(triggerEvent) {
    var filteredData = cloneDeep(this.tmsDetail.StaticEndToEndTeamMembers);
    if (this.teamMemberFilterAttribute) {
      var level = this.teamMemberFilterAttribute.SelectedCarreerLevel == undefined ? "" : this.teamMemberFilterAttribute.SelectedCarreerLevel;
      var location = this.teamMemberFilterAttribute.SelectedWorkLocation == undefined ? "" : this.teamMemberFilterAttribute.SelectedWorkLocation;
      var skill = this.teamMemberFilterAttribute.SelectedSkills == undefined ? "" : this.teamMemberFilterAttribute.SelectedSkills;
      var proficiency = this.teamMemberFilterAttribute.SelectedSkillProficiency == undefined ? "" : this.teamMemberFilterAttribute.SelectedSkillProficiency;
      var technology = this.teamMemberFilterAttribute.SelectedTechnology == undefined ? "" : this.teamMemberFilterAttribute.SelectedTechnology;
      var role = this.teamMemberFilterAttribute.SelectedCareerRole == undefined ? "" : this.teamMemberFilterAttribute.SelectedCareerRole;

      if (level) {
        filteredData = filteredData.filter((x: any) => x.CareerLevel == level);
      }
      if (location) {
        filteredData = filteredData.filter((x: any) => x.CurrentWorkLocation == location);
      }
      if (skill) {
        filteredData = filteredData.filter((x: any) => x.Skill == skill);
      }
      if (proficiency) {
        filteredData = filteredData.filter((x: any) => x.SkillProficiency == proficiency);
      }
      if (technology) {
        filteredData = filteredData.filter((x: any) => x.Technology == technology);
      }
      if (role) {
        filteredData = filteredData.filter((x: any) => x.CareerRole == role);
      }
    }
    if (this.tmsDetail.searchText) {
      filteredData = filteredData.filter((x: any) => x.Name.indexOf(this.tmsDetail.searchText) > -1);
    }

    this.tmsDetail.EndToEndTeamMembers = filteredData;
    this.tmsRDDA.TeamMemberFilterAttribute = this.teamMemberFilterAttribute;
    if (triggerEvent != null && triggerEvent == true) {
      $('#Filter_Info').focus();
    }
  }

  AddResourceToTeam() {
    this.resourceFlag = -1;
    this.tmsDetail.SelectedEndToEndTeamMember.forEach((data: any, index: any) => {
      this.tmsDetail.TeamMembers.push(data);
      var endToendTeamMember = this.tmsDetail.EndToEndTeamMembers.filter((x: any) => x.Name == data.Name)[0];
      if (endToendTeamMember) {
        this.tmsDetail.EndToEndTeamMembers.splice(this.tmsDetail.EndToEndTeamMembers.indexOf(endToendTeamMember), 1);
      }
      var staticEndToendTeamMember = this.tmsDetail.StaticEndToEndTeamMembers.filter((x: any) => x.Name == data.Name)[0];
      if (staticEndToendTeamMember) {
        this.tmsDetail.StaticEndToEndTeamMembers.splice(this.tmsDetail.StaticEndToEndTeamMembers.indexOf(staticEndToendTeamMember), 1);
      }
    });
    this.tmsDetail.SelectedEndToEndTeamMember = [];
  }

  OpenFilterPopup() {
    this.teamMemberFilterAttribute = cloneDeep(this.tmsRDDA.TeamMemberFilterAttribute);
    const initialState = {
      teamMemberFilterAttribute: cloneDeep(this.teamMemberFilterAttribute)
    };
    this.modalRef_TMFilter = this.modalService.show(TeamMemberFilterComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
    this.modalRef_TMFilter.content.getFilteredParams.subscribe(result => {
      if (result.enableScrollBar) {
        $("body").css("overflow", "hidden");
        $("modal-container").css("overflow-y", "scroll");
      }
      this.teamMemberFilterAttribute = result.filteredValue;
      this.ApplyFilter(true);
    });
  }

  RestoreScrollbar() {
    $("body").css("overflow", "");
    $("modal-container").css("overflow-y", "");
  }

  SaveTMS(formvalid, formname) {

    if (this.tmsHierarchy.Type == "TMS") {
      if ((this.tmsDetail.StartDate != null && this.tmsDetail.StartDate != "" && this.tmsDetail.StartDate != undefined) && (this.tmsDetail.EndDate != null && this.tmsDetail.EndDate != "" && this.tmsDetail.EndDate != undefined) && formvalid) {
        if (this.validateDateFormat(this.tmsDetail.StartDate)) {
          if (this.validateDateFormat(this.tmsDetail.EndDate)) {
            formvalid = true;
          }
          else {
            formvalid = false;
          }
        }
        else
          formvalid = false;
      }
      else {
        this.DateValidation.IsValidStartDate = true;
        this.DateValidation.IsValidEndDate = true;
        this.DateValidation.IsValidStartDateEndDate = true;
        this.DateValidation.IsValidEndDateStartDate = true;
      }
      var activeTab = $(".c-scrollable-tabs__container button.active")[0].id;
      if (activeTab == "tabTeamMembers")
        this.isActive = false;
      else
        this.isActive = true;
    }
    if (formvalid) {
      if (this.tmsHierarchy.Type == "Group") {
        this.tmsGroupDetail.UserName = this.sessionStorage.enterpriseId;
        this.tmsGroupDetail.EndToEndId = this.sessionStorage.endToEndId;
        this.tmsHierarchy.TMSGroupDetails = this.tmsGroupDetail;
        this.tmsHierarchy.TMSDetails = null;
       }
      else {
        if (this.DateValidationLogic()) {
          var validationPOC = [];
          $('auto-complete input[id*="peoplePicker_"]').each((i: any, x: any) => {
            if (x.classList.contains('input-blank-error') && x.value.length > 0) {
              validationPOC.push(false);
            } else {
              validationPOC.push(true);
            }
          });

          if (validationPOC.indexOf(false) != -1) {
            var popUpData = this.sharedService.openAlertPopup('Please Enter Valid Enterprise ID', window.scrollY);
            popUpData.content.confirmationResult.subscribe((result) => {
              if (result.result) {
                $("body").addClass("hide-parent-scroll");
              }
            })
            $("[name='tmsAddEditForm']").find('.input-blank-error:visible:first').focus();
            return false;
          }

          if (!this.ValidateStartAndEndDate()) {
            return false;
          }
          this.tmsDetail.UserName = this.sessionStorage.enterpriseId;
          this.tmsDetail.EndToEndId = this.sessionStorage.endToEndId;
          this.tmsDetail.TMSStatusId = this.teamStatus.Id;
          this.tmsHierarchy.TMSDetails = this.tmsDetail;
          this.tmsHierarchy.TMSGroupDetails = null;
          if (this.tmsDetail.TMSStatusId == "2") {
            this.confirmationMessage = 'You are trying to close the Team. Teams will not be visible in the system once updated to "Closed". Please click on Ok to proceed.';
            var confirmationModel = this.sharedService.openConfirmationPopup(this.confirmationMessage, "Ok", "Cancel", window.scrollY);
            confirmationModel.content.confirmationResult.subscribe(response => {
              if (response.result) {
                this.SaveTMSAndTMSGroup();
              }
              $("body").addClass("hide-parent-scroll");
            });
            return false;
          }
        }
      }
      if (this.ValidateDuplicate() && this.DateValidationLogic()) {
        this.SaveTMSAndTMSGroup();
        if (formname != undefined) formname.form.markAsPristine();
      }
    }
    else {
      if ((this.tmsHierarchy.Type == "TMS" && $(".c-scrollable-tabs__container button.active")[0].id == "tabTeamDetails") || this.tmsHierarchy.Type == "Group") {
        let invalidField = $("[name='tmsAddEditForm']").find('.ng-invalid:visible:first')[0];

        //search-dropdowns other than "TeamReportTypeSearchDropdown"
        if (invalidField.tagName == "SEARCH-DROPDOWN" && invalidField.id != "TeamReportTypeSearchDropdown") {
          $('#' + invalidField.id + ' .multiselect').focus();
        }
        else if (invalidField.id == "TeamReportTypeSearchDropdown") {
          var isValidFlag = this.tmsDetail.SelectedDeliveryAccountability != null && this.tmsDetail.SelectedDeliveryAccountability != 0 && this.tmsDetail.SelectedDeliveryAccountability != 4 && this.tmsHierarchy.Type == 'TMS';
          if (!isValidFlag) {
            formname.form.controls["TeamReportTypeSearchDropdown"].status = "valid";
            //focusing next invalid element
            var nextInvalidField = $("[name='tmsAddEditForm']").find('.ng-invalid')[1];
            if (nextInvalidField.tagName == "AUTO-COMPLETE") {
              $('#peoplePicker_' + nextInvalidField.id).focus();
            }
            else if (nextInvalidField.tagName == "SEARCH-DROPDOWN") {
              $('#' + nextInvalidField.id + ' .multiselect').focus();
            }
            else {
              $('#' + nextInvalidField.id).focus();
            }
          }
          else {
            $('#' + invalidField.id + ' .multiselect').focus();
          }
        }
        else if (invalidField.tagName == "AUTO-COMPLETE") {
          $('#peoplePicker_' + invalidField.id).focus();
        }
        else {
          $('#' + invalidField.id).focus();
        }
      }
    }
    this.RestoreScrollbar();
  }

  DateValidationLogic() {
    var isValidTeamDates = true;
    if (this.tmsDetail && this.tmsDetail.TMSStatusId != 2 && this.tmsHierarchy.Type == 'TMS' && this.tmsDetail.StartDate && this.tmsDetail.EndDate) {
      this.EndToEndStartDate = this.datePipe.transform(this.tmsHierarchy.EndToEndStartDate, 'dd MMM yyyy');
      this.EndToEndEndDate = this.datePipe.transform(this.tmsHierarchy.EndToEndEndDate, 'dd MMM yyyy');
      var e2eStartDate = new Date(this.EndToEndStartDate)
      var tmsStartDate = new Date(this.tmsDetail.StartDate);
      var e2eEndDate = new Date(this.EndToEndEndDate)
      var tmsEndDate = new Date(this.tmsDetail.EndDate);

      if (tmsStartDate < e2eStartDate) {
        isValidTeamDates = this.DateValidation.IsValidStartDate = false;
      }
      else {
        this.DateValidation.IsValidStartDate = true;
      }

      if (tmsEndDate > e2eEndDate) {
        isValidTeamDates = this.DateValidation.IsValidEndDate = false;
      }
      else {
        this.DateValidation.IsValidEndDate = true;
      }

      if (tmsEndDate < e2eStartDate) {
        isValidTeamDates = this.DateValidation.IsValidEndDateStartDate = false;
      }
      else {
        this.DateValidation.IsValidEndDateStartDate = true;
      }

      if (tmsStartDate > e2eEndDate) {
        isValidTeamDates = this.DateValidation.IsValidStartDateEndDate = false;
      }
      else {
        this.DateValidation.IsValidStartDateEndDate = true;
      }

 
    }
    return isValidTeamDates;
  }

  ValidateStartAndEndDate() {
    var startDate = new Date(this.tmsDetail.StartDate);
    var endDate = new Date(this.tmsDetail.EndDate);
    if (!(dayjs(this.tmsDetail.EndDate, 'DD MMM YYYY').format('DD MMM YYYY') === this.tmsDetail.EndDate)) {
      return this.endDateValue = false;
    }
    if (!(dayjs(this.tmsDetail.StartDate, 'DD MMM YYYY').format('DD MMM YYYY') === this.tmsDetail.StartDate)) {
      return this.startDateValue = false;
    }
    if (this.tmsDetail.StartDate && this.tmsDetail.EndDate && (startDate > endDate)) {
      var popUpData = this.sharedService.openAlertPopup('Start date can not be greater than the End date.', window.scrollY);
      popUpData.content.confirmationResult.subscribe((result) => {
        if (result.result) {
          $("body").addClass("hide-parent-scroll");
        }
      })
      return false;
    }
    else {
      return true;
    }
  }

  ValidateDuplicate() {
    if (this.tmsHierarchy.Type == "Group") {
      var GroupNames = cloneDeep(this.tmsHierarchy.AllTMSGroups);
      GroupNames.forEach((group: any, key: any) => {
        group.TMSGroupName = group.TMSGroupName.replace(new RegExp("'", 'g'), " ");
      });
      var selectedTMSGroup = Enumerable.from(GroupNames).where((x: any) => x.TMSGroupId != this.tmsGroupDetail.TMSGroupId && x.TMSGroupName.toUpperCase() == this.tmsGroupDetail.TMSGroupName.toUpperCase().replace(new RegExp("'", 'g'), " ")).select((x: any) => x).toArray();
      var currentTMSGroup = Enumerable.from(GroupNames).where((x: any) => x.TMSGroupId == this.tmsGroupDetail.TMSGroupId).select((x: any) => x).toArray();
      var groupName = currentTMSGroup && currentTMSGroup.length > 0 ? currentTMSGroup[0].TMSGroupName : "";
      var exsistingGroupName = selectedTMSGroup && selectedTMSGroup.length > 0 ? selectedTMSGroup[0].TMSGroupName : "";
      if (selectedTMSGroup && selectedTMSGroup.length > 0 && groupName != exsistingGroupName) {
        this.confirmationMessage = "TMS group name already exists. Please click Yes to continue.";
        //$("#tmsConfirmationPopUp").modal('show');
        //$('.close').focus();

        var confirmationModel = this.sharedService.openConfirmationPopup(this.confirmationMessage, "Yes", "No", window.scrollY);
        confirmationModel.content.confirmationResult.subscribe(response => {
          if (response.result) {
            this.SaveTMSAndTMSGroup();
          }
        });
        return false;
      }
    }
    else {
      var TMSNames = cloneDeep(this.tmsHierarchy.AllTeams);
      TMSNames.forEach((tms: any, key: any) => {
        tms.TMSName = tms.TMSName.replace(new RegExp("'", 'g'), " ");
      });
      var selectedTMS: any = Enumerable.from(TMSNames).where((x: any) => x.TMSId != this.tmsDetail.TMSId && x.TMSName.toUpperCase() == this.tmsDetail.TMSName.toUpperCase().replace(new RegExp("'", 'g'), " ") + "'").toArray();
      var currentTMS: any = Enumerable.from(TMSNames).where((x: any) => x.TMSId == this.tmsDetail.TMSId).toArray();
      var tmsName = currentTMS && currentTMS.length > 0 ? currentTMS[0].TMSName : "";
      var exsistingTMSName = selectedTMS && selectedTMS.length > 0 ? selectedTMS[0].TMSName : "";
      if (selectedTMS && selectedTMS.length > 0 && tmsName != exsistingTMSName) {
        this.confirmationMessage = "TMS name already exists. Please click Yes to continue.";

        //$.element("#tmsConfirmationPopUp").modal('show');
        //$.element('.close').focus();

        var confirmationModel = this.sharedService.openConfirmationPopup(this.confirmationMessage, "Yes", "No", window.scrollY);
        confirmationModel.content.confirmationResult.subscribe(response => {
          if (response.result) {
            this.SaveTMSAndTMSGroup();
          }
          $("body").addClass("hide-parent-scroll");
        });
        return false;
      }
    }
    return true;
  }

  SaveTMSAndTMSGroup() {
    if (this.tmsHierarchy.Type == "Group") {
      this.SaveTMSGroupDetails();
    }
    else {
      this.SaveTMSDetails();
    }
    //$rootScope.TMSUnsavedData = 0;
    //$rootScope.TabChanges = false;
  }
  ShowAlert(message) {
    if (!$('#tms_alertPopUp').hasClass('in')) {
      this.sharedService.openAlertPopup(message, this.scrollYPosition, "Ok")
    }
  }

  SaveTMSGroupDetails() {
    this.tmsService.saveTeamGroupForStructure(this.tmsHierarchy.TMSGroupDetails).subscribe((response: any) => {
      setTimeout(() => {
        window.TooltipFunc();
      }, 0);
      // $('#' + this.popupId).modal('hide');
      this.bsModalRef.hide();
      //this.UpdateTMSGroupAndTMS();
      //this.onSave = 'Group';
      this.teamAddEditPopupData.emit({ isSaveButtonClick: true });
      //this.broadCastService.broadcastGroupNameUpdate({
      //  updatedGroupName: true
      //});
      //this.teamStructureSharedService.UpdateTMSGroupAndTMS(this);

      this.ShowAlert("Saved successfully");
      $("body").removeClass("hide-parent-scroll");
      setTimeout(function test() {
        $('.close').focus();
      }, 0);    
      this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd + "|" + enumEntityType.TMS, null, this.demographicService);
    });
  }

  SaveTMSDetails() {
    this.tmsService.saveTeamForStructure(this.tmsHierarchy.TMSDetails).subscribe((response: any) => {
      this.bsModalRef.hide();
      //this.UpdateTMSGroupAndTMS();
      this.teamAddEditPopupData.emit({ isSaveButtonClick: true });
      //this.teamStructureSharedService.UpdateTMSGroupAndTMS(this);
      this.ShowAlert("Saved successfully");
      $("body").removeClass("hide-parent-scroll");
      setTimeout(function test() {
        $('.close').focus();
      }, 0);
      this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd + "|" + enumEntityType.TMS, null, this.demographicService);
    });
  }


  ChangeSelectionMode(selection, ws, cms, cms1, event) {
    if (this.tmsDetail.PhoenixCreationIndicator || (ws != null && ws.WorkstreamStatusName == 'Closed'))
      return;
    //if (event != null) {
    //  var keycode = (event.keyCode ? event.keyCode : event.which);
    //  if (keycode !== 13 && keycode !== 1) { // code for Enter key
    //    return;
    //  }
    //  else if (event.currentTarget.previousSibling.disabled) {
    //    return;
    //  }
    //}
    selection = selection == "None" || selection == "Partial" ? "Full" : "None";
    if (ws) {
      var workStreamDetailSingle = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == ws.WorkstreamId && x.CMSId == ws.CMSId)[0];
      if (workStreamDetailSingle) {
        workStreamDetailSingle.SelectionMode = selection;
        //ws.SelectionMode = selection;
        ws.POC = ws.SelectionMode == enumAssociationType.None ? "" : ws.POC;
        //this.UpdateSharedWSPOC(workStreamDetailSingle, ws.WorkstreamId); 

        var workStreamDetails = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == ws.WorkstreamId);
        if (workStreamDetails.length > 1) {
          workStreamDetails.forEach((ws: any, key: any) => {
            if (ws.POC != "") {
              workStreamDetailSingle.POC = ws.POC;
            }
          });
        }

        var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == workStreamDetailSingle.CMSId)[0];
        if (cmsDetail) {
          var siblingWorkstrems = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.CMSId == workStreamDetailSingle.CMSId);
          var siblingCMS = this.tmsDetail.CMSDetails.filter((x: any) => x.SelectedParentId == workStreamDetailSingle.CMSId);

          if (selection == enumAssociationType.Full) {
            var noneSelectionedSiblings = siblingWorkstrems.filter((x: any) => x.SelectionMode == enumAssociationType.None);
            var noneSelectionedCMSSiblings = siblingCMS.filter((x: any) => x.SelectionMode == enumAssociationType.None);
            if (noneSelectionedSiblings.length > 0 || noneSelectionedCMSSiblings.length > 0) {
              cmsDetail.SelectionMode = enumAssociationType.Partial;
            }
            else {
              cmsDetail.SelectionMode = enumAssociationType.Full;
            }
          }
          else {
            var fullySelectionedSiblings = siblingWorkstrems.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
            var fullySelectionedCMSSiblings = siblingCMS.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
            if (fullySelectionedSiblings.length > 0 || fullySelectionedCMSSiblings.length > 0) {
              cmsDetail.SelectionMode = enumAssociationType.Partial;
            }
            else {
              cmsDetail.SelectionMode = enumAssociationType.None;
            }
          }
          this.UpdateParentSelectionMode(cmsDetail.SelectionMode, cmsDetail);
          if (selection == enumAssociationType.None) {
            workStreamDetails.POC = "";
          }
          if (cmsDetail.SelectionMode == enumAssociationType.None || cmsDetail.SelectionMode == enumAssociationType.Partial) {
            cmsDetail.POC = "";
          }
        }
        //else return;       
      }
      //else return;
    }
    else if (cms) {
      var cmsDetail1 = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cms.CMSId)[0];
      if (cmsDetail1) {
        cmsDetail1.SelectionMode = selection;
        cmsDetail1.POC = cmsDetail1.SelectionMode == enumAssociationType.None ? "" : cmsDetail1.POC;
        //Child Checkbox update Start
        this.UpdateCMSWorkstreamDetails(selection, cms.CMSId);
        var cmsDetail2 = this.tmsDetail.CMSDetails.filter((x: any) => x.SelectedParentId == cmsDetail1.CMSId);

        if (cmsDetail2.length > 0) {
          cmsDetail2.forEach((cms2: any, key: any) => {
            cms2.SelectionMode = selection;
            cms2.POC = cms2.SelectionMode == enumAssociationType.None ? "" : cms2.POC;
            this.UpdateCMSWorkstreamDetails(selection, cms2.CMSId);
            var cmsDetail3 = this.tmsDetail.CMSDetails.filter((x: any) => x.SelectedParentId == cms2.CMSId);
            if (cmsDetail3.length > 0) {
              cmsDetail3.forEach((cms3: any, key: any) => {
                cms3.SelectionMode = selection;
                cms3.POC = cms3.SelectionMode == enumAssociationType.None ? "" : cms3.POC;
                this.UpdateCMSWorkstreamDetails(selection, cms3.CMSId);
                var cmsDetail4 = this.tmsDetail.CMSDetails.filter((x: any) => x.SelectedParentId == cms3.CMSId);
                if (cmsDetail4.length > 0) {
                  cmsDetail4.forEach((cms4: any, key: any) => {
                    cms4.SelectionMode = selection;
                    cms4.POC = cms4.SelectionMode == enumAssociationType.None ? "" : cms4.POC;
                    this.UpdateCMSWorkstreamDetails(selection, cms4.CMSId);
                    var cmsDetail5 = this.tmsDetail.CMSDetails.filter((x: any) => x.SelectedParentId == cms4.CMSId);
                    if (cmsDetail5.length > 0) {
                      cmsDetail5.forEach((cms5: any, key: any) => {
                        cms5.SelectionMode = selection;
                        cms5.POC = cms5.SelectionMode == enumAssociationType.None ? "" : cms5.POC;
                        this.UpdateCMSWorkstreamDetails(selection, cms5.CMSId);
                      });
                    }
                  });
                }
              });
            }
          });
        }
        //Child Checkbox update End
        //Parent Checkbox Update
        this.UpdateParentSelectionMode(selection, cmsDetail1);
      }
    }
    this.UpdateCMSModel(this.tmsDetail.CMSDetails, this.tmsDetail.WorkStreamDetails, 'CheckEvent');
  }

  UpdateCMSWorkstreamDetails(selection, cmsId) {
    var workStreamDetails1 = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.CMSId == cmsId);
    workStreamDetails1.forEach((ws: any, key: any) => {
      ws.SelectionMode = selection;
      ws.POC = ws.SelectionMode == enumAssociationType.None ? "" : ws.POC;
      if (ws.SelectionMode == enumAssociationType.Full) {
        this.UpdateSharedWSPOC(ws, ws.WorkstreamId);
      }
    });
  }

  UpdateParentSelectionMode(selection, cmsDetail1) {
    var cmsDetail2 = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsDetail1.SelectedParentId)[0];
    if (cmsDetail2) {
      this.SetPartialSelection(selection, cmsDetail1, cmsDetail2);
      var cmsDetail3 = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsDetail2.SelectedParentId)[0];
      if (cmsDetail3) {
        this.SetPartialSelection(selection, cmsDetail2, cmsDetail3);
        var cmsDetail4 = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsDetail3.SelectedParentId)[0];
        if (cmsDetail4) {
          this.SetPartialSelection(selection, cmsDetail3, cmsDetail4);
          var cmsDetail5 = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsDetail4.SelectedParentId)[0];
          if (cmsDetail5) {
            this.SetPartialSelection(selection, cmsDetail4, cmsDetail5);
          }
        }
      }
    }
  }

  UpdateSharedWSPOC(workStreamDetail, workstreamId) {
    var workStreamDetails = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == workstreamId);
    if (workStreamDetails.length > 1) {
      workStreamDetails.forEach((ws: any, key: any) => {
        if (ws.POC != "") {
          workStreamDetail.POC = ws.POC;
        }
      });
    }
  }

  SetPartialSelection(selection, childCMSDetails, cmsDetail) {
    var cmsSiblingDetails = this.tmsDetail.CMSDetails.filter((x: any) => x.SelectedParentId == childCMSDetails.SelectedParentId);
    var siblingWorkstrems = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.CMSId == childCMSDetails.SelectedParentId);
    if (selection == enumAssociationType.Full) {
      var noneSelectionedSiblings = cmsSiblingDetails.filter((x: any) => x.SelectionMode == enumAssociationType.None);
      var noneSelectionedSiblingsWorkstream = siblingWorkstrems.filter((x: any) => x.SelectionMode == enumAssociationType.None);
      if (noneSelectionedSiblings.length > 0 || childCMSDetails.SelectionMode == enumAssociationType.Partial || noneSelectionedSiblingsWorkstream.length > 0) {
        cmsDetail.SelectionMode = enumAssociationType.Partial;
      }
      else {
        cmsDetail.SelectionMode = enumAssociationType.Full;
      }
    }
    else {
      var fullySelectionedSiblings = cmsSiblingDetails.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
      var fullySelectionedSiblingsWorkstream = siblingWorkstrems.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
      if (fullySelectionedSiblings.length > 0 || childCMSDetails.SelectionMode == enumAssociationType.Partial || fullySelectionedSiblingsWorkstream.length > 0) {
        cmsDetail.SelectionMode = enumAssociationType.Partial;
      }
      else {
        cmsDetail.SelectionMode = enumAssociationType.None;
      }
    }
    if (cmsDetail.SelectionMode == enumAssociationType.None || cmsDetail.SelectionMode == enumAssociationType.Partial) {
      cmsDetail.POC = "";
    }
  }

  UpdateCMSModel(cmsDetail, workstreamDetail, action) {
    this.StatusFlag = [{ Id: '1', Name: 'Open' }, { Id: '2', Name: 'Closed' }];
    this.tmsDetail.CMSLevelOne = [];
    this.tmsDetail.WorkStreamStructure = [];
    var cmsDetails = cloneDeep(cmsDetail);
    var workstreamDetails = cloneDeep(workstreamDetail);
    this.tmsDetail.CMSDetails = cmsDetails;
    this.tmsDetail.WorkStreamDetails = workstreamDetails;
    var cmsLevelOne = cmsDetails.filter((x: any) => x.SelectedParentId == null);
    cmsLevelOne.forEach((cms1: any, key: any) => {
      cms1.Tier = 1;
      cms1.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms1.CMSId);
      var cmsLevelTwo = cmsDetails.filter((x: any) => x.SelectedParentId == cms1.CMSId);
      if (cms1.WorkStreamStructure.length > 0 || cmsLevelTwo.length > 0) {
        var noneSelectedWorkstream = cms1.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
        var noneSelectedcms = cmsLevelTwo.filter((x: any) => x.SelectionMode == enumAssociationType.None);
        var fullSelectedWorkstream = cms1.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
        var fullSelectedcms = cmsLevelTwo.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
        if (cms1.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelTwo.length == fullSelectedcms.length) {
          cms1.SelectionMode = enumAssociationType.Full;
          this.UpdateSelectionModeCMS(enumAssociationType.Full, cms1.CMSId, null, null, null, null);
        }
        else if (cms1.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelTwo.length > noneSelectedcms.length) {
          cms1.SelectionMode = enumAssociationType.Partial;
          this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, null, null, null, null);
        }
      }
      cms1.WorkStreamStructure.forEach((ws1: any, key: any) => {
        ws1.SelectionMode = cms1.SelectionMode == enumAssociationType.Full || ws1.SelectionMode == enumAssociationType.Full
          ? enumAssociationType.Full
          : enumAssociationType.None;
        this.UpdateSelectionModelWorkstream(ws1.SelectionMode, ws1.WorkstreamId, ws1.CMSId);
      });
      cmsLevelTwo.forEach((cms2: any, key: any) => {
        cms2.Tier = 2;
        cms2.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms2.CMSId);
        var cmsLevelThree = cmsDetails.filter((x: any) => x.SelectedParentId == cms2.CMSId);
        if (cms2.WorkStreamStructure.length > 0 || cmsLevelThree.length > 0) {
          var noneSelectedWorkstream = cms2.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
          var noneSelectedcms = cmsLevelThree.filter((x: any) => x.SelectionMode == enumAssociationType.None);
          var fullSelectedWorkstream = cms2.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
          var fullSelectedcms = cmsLevelThree.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
          if (cms2.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelThree.length == fullSelectedcms.length) {
            cms2.SelectionMode = enumAssociationType.Full;
            this.UpdateSelectionModeCMS(enumAssociationType.Full, null, cms2.CMSId, null, null, null);
          }
          else if (cms2.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelThree.length > noneSelectedcms.length) {
            cms1.SelectionMode = enumAssociationType.Partial;
            cms2.SelectionMode = enumAssociationType.Partial;
            this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, null, null, null);
          }
        }
        cms2.WorkStreamStructure.forEach((ws2: any, key: any) => {
          ws2.SelectionMode = cms2.SelectionMode == enumAssociationType.Full || ws2.SelectionMode == enumAssociationType.Full ? enumAssociationType.Full : enumAssociationType.None;
          this.UpdateSelectionModelWorkstream(ws2.SelectionMode, ws2.WorkstreamId, ws2.CMSId);
        });
        cmsLevelThree.forEach((cms3: any, key: any) => {
          cms3.Tier = 3;
          cms3.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms3.CMSId);
          var cmsLevelFour = cmsDetails.filter((x: any) => x.SelectedParentId == cms3.CMSId);
          if (cms3.WorkStreamStructure.length > 0 || cmsLevelFour.length > 0) {
            var noneSelectedWorkstream = cms3.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
            var noneSelectedcms = cmsLevelFour.filter((x: any) => x.SelectionMode == enumAssociationType.None);
            var fullSelectedWorkstream = cms3.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
            var fullSelectedcms = cmsLevelFour.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
            if (cms3.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelFour.length == fullSelectedcms.length) {
              cms3.SelectionMode = enumAssociationType.Full;
              this.UpdateSelectionModeCMS(enumAssociationType.Full, null, null, cms3.CMSId, null, null);
            }
            else if (cms3.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelFour.length > noneSelectedcms.length) {
              cms1.SelectionMode = enumAssociationType.Partial;
              cms2.SelectionMode = enumAssociationType.Partial;
              cms3.SelectionMode = enumAssociationType.Partial;
              this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, cms3.CMSId, null, null);
            }
          }
          cms3.WorkStreamStructure.forEach((ws3: any, key: any) => {
            ws3.SelectionMode = cms3.SelectionMode == enumAssociationType.Full || ws3.SelectionMode == enumAssociationType.Full ? enumAssociationType.Full : enumAssociationType.None;
            this.UpdateSelectionModelWorkstream(ws3.SelectionMode, ws3.WorkstreamId, ws3.CMSId);
          });
          cmsLevelFour.forEach((cms4: any, key: any) => {
            cms4.Tier = 4;
            cms4.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms4.CMSId);
            var cmsLevelFive = cmsDetails.filter((x: any) => x.SelectedParentId == cms4.CMSId);
            if (cms4.WorkStreamStructure.length > 0 || cmsLevelFive.length > 0) {
              var noneSelectedWorkstream = cms4.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
              var noneSelectedcms = cmsLevelFive.filter((x: any) => x.SelectionMode == enumAssociationType.None);
              var fullSelectedWorkstream = cms4.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
              var fullSelectedcms = cmsLevelFive.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
              if (cms4.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelFive.length == fullSelectedcms.length) {
                cms4.SelectionMode = enumAssociationType.Full;
                this.UpdateSelectionModeCMS(enumAssociationType.Full, null, null, null, cms4.CMSId, null);
              }
              else
                if (cms4.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelFive.length > noneSelectedcms.length) {
                  cms1.SelectionMode = enumAssociationType.Partial;
                  cms2.SelectionMode = enumAssociationType.Partial;
                  cms3.SelectionMode = enumAssociationType.Partial;
                  cms4.SelectionMode = enumAssociationType.Partial;
                  this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, cms3.CMSId, cms4.CMSId, null)
                }
            }
            cms4.WorkStreamStructure.forEach((ws4: any, key: any) => {
              ws4.SelectionMode = cms4.SelectionMode == enumAssociationType.Full || ws4.SelectionMode == enumAssociationType.Full ? enumAssociationType.Full : enumAssociationType.None;
              this.UpdateSelectionModelWorkstream(ws4.SelectionMode, ws4.WorkstreamId, ws4.CMSId);
            });
            cmsLevelFive.forEach((cms5: any, key: any) => {
              cms5.Tier = 5;
              cms5.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms5.CMSId);
              if (cms5.WorkStreamStructure.length > 0) {
                var noneSelectedWorkstream = cms5.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
                var fullSelectedWorkstream = cms5.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
                if (cms5.WorkStreamStructure.length == fullSelectedWorkstream.length) {
                  cms5.SelectionMode = enumAssociationType.Full;
                  this.UpdateSelectionModeCMS(enumAssociationType.Full, null, null, null, null, cms5.CMSId);
                }
                else if (cms5.WorkStreamStructure.length > noneSelectedWorkstream.length) {
                  cms1.SelectionMode = enumAssociationType.Partial;
                  cms2.SelectionMode = enumAssociationType.Partial;
                  cms3.SelectionMode = enumAssociationType.Partial;
                  cms4.SelectionMode = enumAssociationType.Partial;
                  cms5.SelectionMode = enumAssociationType.Partial;
                  this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, cms3.CMSId, cms4.CMSId, cms5.CMSId);
                }
              }
              cms5.WorkStreamStructure.forEach((ws5: any, key: any) => {
                ws5.SelectionMode = cms5.SelectionMode == enumAssociationType.Full || ws5.SelectionMode == enumAssociationType.Full ? enumAssociationType.Full : enumAssociationType.None;
                this.UpdateSelectionModelWorkstream(ws5.SelectionMode, ws5.WorkstreamId, ws5.CMSId);
              });
            });
            cms4.CMSLevelFive = cmsLevelFive;
          });
          cms3.CMSLevelFour = cmsLevelFour;
        });
        cms2.CMSLevelThree = cmsLevelThree;
      });
      cms1.CMSLevelTwo = cmsLevelTwo;
    });
    this.tmsDetail.CMSLevelOne = cmsLevelOne;
    this.tmsDetail.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSName == null);
    //if (!this.$$phase)
    //  this.$apply();
    //this.ref.detectChanges();

    setTimeout(function () {
      var roleAccessCode = this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);
      $('.associationCheckbox').each(function (index, data) {        
        var selectionMode = $(this).attr('data-selectionmode');
        if (roleAccessCode != 'V' || action == 'Edit') {
          if (selectionMode == enumAssociationType.None)
            $(this).prop('checked', false);
          $(this).removeClass('-indeterminate');
          if (selectionMode == enumAssociationType.Full) {
            //$(this).addClass('.c-checkbox__input');
            $(this).removeClass('-indeterminate');
            $(this).prop('checked', true);
          }
          if (selectionMode == enumAssociationType.Partial) {            
            $(this).addClass('-indeterminate');
          }
          else {
            $(this).prop('indeterminate', false);
          }
        }
      });
    });
    this.ref.detectChanges();
  }

  UpdateSelectionModeCMS(selectionMode, cmsId1: null, cmsId2: null, cmsId3: null, cmsId4: null, cmsId5: null) {
    if (cmsId1) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId1)[0];
      cmsDetail.SelectionMode = selectionMode;
    }
    if (cmsId2) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId2)[0];
      cmsDetail.SelectionMode = selectionMode;
    }
    if (cmsId3) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId3)[0];
      cmsDetail.SelectionMode = selectionMode;
    }
    if (cmsId4) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId4)[0];
      cmsDetail.SelectionMode = selectionMode;
    }
    if (cmsId5) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId5)[0];
      cmsDetail.SelectionMode = selectionMode;
    }
  }


  workStreamIconSelection(workStreamTypeId) {
    if (workStreamTypeId == 1)
      return ImagePath.ADImage;
    else if (workStreamTypeId == 2)
      return ImagePath.AMImage;
    else if (workStreamTypeId == 3 || workStreamTypeId == 4 || workStreamTypeId == 5 || workStreamTypeId == 6 || workStreamTypeId == 7 || workStreamTypeId == 8 || workStreamTypeId == 9)
      return ImagePath.AgileImage;
    else if (workStreamTypeId == 10 || workStreamTypeId == 11)
      return ImagePath.InfrastructureImage;
    else if (workStreamTypeId == 12)
      return ImagePath.OperationsImage;
    else if (workStreamTypeId == 13)
      return ImagePath.SecurityImage;
    else
      return null;
    // return this.workStreamTypeIcon;
  };

  UpdateSelectionModelWorkstream(selectionMode, workstreamId, cmsId) {
    var workStreamDetails = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == workstreamId && x.CMSId == cmsId)[0];
    workStreamDetails.SelectionMode = selectionMode;
  }

  DeliveryGroupChangeForTMS() {
    this.FilterDUListData();
    this.tmsDetail.SelectedDeliveryUnitId = null;
    this.tmsDetail.SelectedDeliveryUnitId = null;
  }

  AccountabilityChange() {
    this.ActiveDropdownDirective = false;
    if (this.tmsDetail.SelectedDeliveryAccountability) {
      this.DeliveryAccountabilityType();
      this.IsDisableAccType = this.tmsDetail.SelectedDeliveryAccountability == 4 || this.tmsDetail.SelectedDeliveryAccountability == 0 ? true : false;
      this.IsDisableAGDGDU = (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureGDN) ? false : true;
      this.tmsDetail.SelectedDeliveryAccountabilityType = null;
    }
    else {
      this.tmsDetail.SelectedDeliveryAccountabilityType = null;
      this.IsDisableAccType = true;
      this.IsDisableAGDGDU = true;
      this.tmsHierarchy.DeliveryAccountabilityTypes = [];
      this.tmsDetail.SelectedDeliveryAccountabilityType = null;
    }
    this.ActiveDropdownDirective = true;
  }
  DeliveryAccountabilityType() {
    this.IsDisableAccType = false;
    if (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureOG) {
      this.tmsHierarchy.DeliveryAccountabilityTypes = cloneDeep(this.tmsHierarchy.ManagementClientGroups);
      this.tmsDetail.SelectedDeliveryAccountabilityType = this.tmsDetail.SelectedClientGroup;
      this.IsDisableAGDGDU = true;
    }
    else if (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureGDN) {
      this.tmsHierarchy.DeliveryAccountabilityTypes = cloneDeep(this.tmsHierarchy.DeliveryCenters);
      this.tmsDetail.SelectedDeliveryAccountabilityType = this.tmsDetail.SelectedDeliveryCenter;
      this.IsDisableAGDGDU = false;
    }
    else if (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AvanadeGU) {
      this.tmsHierarchy.DeliveryAccountabilityTypes = cloneDeep(this.tmsHierarchy.AvanadeGeographicUnits);
      this.tmsDetail.SelectedDeliveryAccountabilityType = this.tmsDetail.SelectedAvanadeGeographicUnit;
      this.IsDisableAGDGDU = true;
    }
    else {
      this.tmsHierarchy.DeliveryAccountabilityTypes = [];
      this.tmsDetail.SelectedDeliveryAccountabilityType = null;
      this.IsDisableAccType = true;
    }
  }

  AccountGroupChangeForTMS() {
    this.FilterDGListData();
    this.TempDeliveryUnits = [];
    this.tmsDetail.SelectedDeliveryGroupId = null;
    this.tmsDetail.SelectedDeliveryUnitId = null;
  }

  AccountabilityTypeChange() {
    this.tmsDetail.MMEWMUIds = [];
    this.FilterAGListData();
    this.tmsDetail.SelectedAccountGroupId = null;
    this.tmsDetail.SelectedDeliveryGroupId = null;
    this.tmsDetail.SelectedDeliveryUnitId = null;
    this.tmsDetail.SelectedInteractionStyleId = null;
    this.FilterAGListData();
    this.FilterDUListData();
    this.FilterDGListData();
  }
  FilterAGListData() {
    var MainList = cloneDeep(this.tmsHierarchy.AccountGroups);
    this.TempAccountGroups = cloneDeep(MainList.filter((x: any) => x.ParentId == this.tmsDetail.SelectedDeliveryAccountabilityType));
  }

  FilterDGListData() {
    var MainList = cloneDeep(this.tmsHierarchy.DeliveryGroups);
    this.TempDeliveryGroups = cloneDeep(MainList.filter((x: any) => x.ParentId == this.tmsDetail.SelectedAccountGroupId));
  }

  FilterDUListData() {
    var MainList = cloneDeep(this.tmsHierarchy.DeliveryUnits);
    this.TempDeliveryUnits = cloneDeep(MainList.filter((x: any) => x.ParentId == this.tmsDetail.SelectedDeliveryGroupId));
  }

  trackByName(selectedRow: any): string {
    return selectedRow.Name;
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
  trackByWorkstreamId(index: number, item: any): any {
    return item.WorkstreamId;
  }

  trackByCMSId(index: number, item: any): any {
    return item.CMSId;
  }
  trackById(index: number, item: any): any {
    return item.Id;
  }

  changeCheckboxValue(event) {
    var existingValue = event.currentTarget.value;
    return existingValue;
  }

  validateDateFormat(dateValue) {

    if (dateValue != undefined && dateValue != "") {
      if (dateValue.match(/^(([0-9])|([0-2][0-9])|([3][0-1]))\ (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\ \d{4}$/))
        return true;
      else
        return false;
    }
    if (dateValue == "" || dateValue == undefined) {
      return true;
    }
  }
}
