<footer class="c-app__footer">
  <div class="c-app__footer-inner app-container">
    <div class="c-app__footer-left">
      <div class="c-app__footer-logoWrap">
        <img src="../assets/toolkit/images/logo/logo.svg" class="c-app__footer-logo" alt="Accenture">
        <div class="c-app__footer-appName">
          myWizard
        </div>
      </div>
      <div class="c-app__footer-legalWrap">
        <div class="c-app__footer-legalWrap-inner">
          <div>Copyright 2001-{{date|date:'yyyy'}} Accenture.</div>
          <div>Accenture confidential. For internal use only.</div>
        </div>
        <div class="c-app__footer-legalStatements">
          <a href="javascript:;" (click)="openTermsUse()" aria-label="Terms of Use">Terms of Use</a>
          <a href="javascript:;" (click)="openDataPrivacy()" aria-label="Privacy Statements">Privacy Statements</a>
        </div>
      </div>
    </div>
    <div class="c-app__footer-center">
      <a  data-myw-title="Coming Soon" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="About">About</a>
      <a href="https://kxdocuments.accenture.com/contribution/682492d9-aef2-4702-91ba-7892acd7b2c6?referrer=https://collections.accenture.com/collection.aspx?b=013ab14d-ee58-ec11-b131-0a0b3d281f9d" target="_blank" rel="noopener noreferrer" aria-label="Help Guide">Help Guide</a>
      <a href="mailto:mywizard.coaches@accenture.com?Subject=myWizard%20:%20Contact%20a%20Coach" target="_blank" rel="noopener noreferrer" aria-label="Contact Us">Contact Us</a>
    </div>
    <div class="c-app__footer-right">
      <div class="c-app__footer-feedbackTitle">Request Support</div>
      <div class="c-app__footer-feedbackTxt">Do you have any questions? Do you require support? Do you want to provide general feedback?</div>
      <a href="https://support.accenture.com/support_portal" class="c-app__footer-feedbackBtn" role="button" target="_blank" rel="noopener noreferrer">Request Assistance</a>
    </div>
  </div>
</footer>
