<ng-container *ngIf="narrativeStatus">
  <div class="c-manage-dashboard-legend-container">
    <div class="c-manage-dashboard-legend">
      <div class="c-manage-dashboard-legend-item">
        <i [ngStyle]="{'background-color': narrativeStatus?.OverAllTrend }"></i>
        <span>Overall</span>
      </div>
      <div class="c-manage-dashboard-legend-item">
        <i [ngStyle]="{'background-color': narrativeStatus?.ClientTrend }"></i>
        <span>Client</span>
      </div>
    </div>
    <div class="c-manage-dashboard-legend">
      <div class="c-manage-dashboard-legend-item">
        <i [ngStyle]="{'background-color': narrativeStatus?.DeliveryTrend }"></i>
        <span>Delivery</span>
      </div>
      <div class="c-manage-dashboard-legend-item">
        <i [ngStyle]="{'background-color': narrativeStatus?.FinancialTrend }"></i>
        <span>Financials</span>
      </div>
    </div>
    <div class="c-manage-dashboard-legend">
      <div class="c-manage-dashboard-legend-item">
        <i [ngStyle]="{'background-color': narrativeStatus?.StaffingTrend }"></i>
        <span>People/ Resources</span>
      </div>
      <div class="c-manage-dashboard-legend-item" data-myw-title="Continuous Improvement / Intelligent Operations" data-myw-toggle="tooltip" data-myw-placement="bottom" >
        <i [ngStyle]="{'background-color': narrativeStatus?.ContinuousImprovementTrend }"></i>
        <span>Continuous Improvement</span>
      </div>
    </div>
  </div>

  <div class="c-manage-dashboard-report">
    <div class="c-manage-dashboard-report-item">
      <div class="c-manage-dashboard-report-data">
        <div class="-title">Last Submitted</div>
        <ng-container *ngIf="narrativeStatus.SubmittedDate && narrativeStatus.SubmittedDate != 'N/A'">
          <label>{{narrativeStatus.SubmittedEndPeriod}}</label>
          <span>On {{narrativeStatus.SubmittedDate}}</span>
        </ng-container>
        <ng-container *ngIf="!narrativeStatus.SubmittedDate || narrativeStatus.SubmittedDate == 'N/A'">
          <!--<label>Apr Month End</label>-->
          <span>N/A</span>
        </ng-container>

      </div>
    </div>
    <div class="c-manage-dashboard-report-item">
      <div class="c-manage-dashboard-report-data">
        <div class="-title">
          <div> Next Due</div>
          <a href="javascript:;" class="icon-timer" data-myw-title="All due dates are converted to your workstation's time zone" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-manage-tooltip">&nbsp;</a>
        </div>
        <ng-container *ngIf="narrativeStatus.DueDate != 'Optional' && narrativeStatus.DueDate != 'N/A' && narrativeStatus.DueDate">
          <label>{{narrativeStatus.NextDueDatePeriod}}</label>
          <span>On {{narrativeStatus.DueDate | WorkstationDateTimeConversion}}</span>
        </ng-container>
        <ng-container *ngIf="narrativeStatus.DueDate == 'Optional' || narrativeStatus.DueDate == 'N/A' || !narrativeStatus.DueDate">
          <!--<label>Apr Month End</label>-->
          <span>{{narrativeStatus.DueDate}}</span>
        </ng-container>

      </div>
    </div>
  </div>

  <!--<div class="c-manage-dashboard-note">
    <div class="c-legends-item">
      <i style="background-color: #7500C0;"></i>
      <span>All due dates are converted to your workstation's time zone</span>
    </div>
  </div>-->
</ng-container>
