<!--<div class="modal-header">
  <button type="button" id="Close_Manage_popup" class="close" data-dismiss="modal" aria-label="Close Manage Contract Popup" (keypress)="CancelGrid($event)" (click)="CancelGrid($event)"><span aria-hidden="true">&times;</span></button>
  <h4 class="modal-title">{{attributeName + ' List'}}</h4>
</div>
<div class="modal-body form-horizontal">
  <div class="form-group row condensed-row">
    <label class="col-xs-12 col-sm-4 control-label-contract-align" id="contractIdHeader" for="ContractIdInfo"><button aria-label="contractStatusInfo" *ngIf="showStatus" data-target="#multicontractinfo" data-toggle="modal" class="glyphicon glyphicon-info-sign" style="font-size:14px ; color:black ; text-decoration-line: none; border:none;background-color:transparent;" (click)="showMultiContractInfo()"></button>{{attributeName}}</label>
    <div class="col-xs-12 col-sm-5">

      <input type="text" *ngIf="showStatus" class="form-control" [(ngModel)]="selectedValue" value="" id="ContractIdInfo" (keypress)="KeyPressNumeric($event)" autocomplete="off" maxlength="10" />
      <search-dropdown *ngIf="entity != 'profile'" [options]="AssociatedContractsEndToEnd"
                       [isShow]="!showStatus" [labelInfo]="'ContractIdInfo'" [modelType]="'object'"
                       [selected]="selectedValue" [selectText]="'Select'" [(ngModel)]="selectedValue">
      </search-dropdown>
    </div>
    <div class="col-xs-12 col-sm-3">
      <hr class="empty visible-xs-block" />
      <button type="button" *ngIf="entity=='profile'" class="btn btn-primary" [disabled]="!selectedValue" aria-label="Add Contract ID" (click)="AddAttributesToArrayForProfile(gridData,selectedValue, true);">
        Add
      </button>
      <button type="button" *ngIf="entity !='profile'" class="btn btn-primary" [disabled]="!selectedValue" aria-label="Add Contract ID" (click)="AddAttributesToArray(gridData,selectedValue);">
        Add
      </button>
    </div>
  </div>
  <div class="table-responsive">
    <p id="ContractIdList" class="screenReaderOnly">This table lists Contract Ids along with status and comments.</p>
    <table class="table table-striped table-hover" aria-describedby="ContractIdList">
      <thead>
        <tr>
          <th scope="col">{{attributeName}}</th>
          <th scope="col" *ngIf="showStatus">Status</th>
          <th scope="col" *ngIf="showStatus">Comments</th>
          <th scope="col" class="nowrap td-width-10">Delete</th>
        </tr>
      </thead>
      <tbody id="contractTableVal">
        <tr *ngFor="let data of gridData; let i=index; trackBy : trackById">
          <td>
            {{ data.Name }}
          </td>
          <td *ngIf="showStatus">
            {{data.Status}}
          </td>
          <td *ngIf="showStatus" [attr.title]="data.Comments">
            <span *ngIf="data.WarningIndicator == 'Y'" style="color:red;">Warning! </span>{{data.Comments}}
          </td>
          <td [attr.title]="data.IsBase && showStatus ? 'Currently, removing the contract which initiated the E2E setup is not supported.':''">
            <a *ngIf="showStatus" class="link-color" href="javascript:void(0);" [ngClass]="{'contract-basedelete-disabled':data.IsBase}" (click)="DetagContract(data.Name, data.IsAssociatedToCMSTMSWS, data)">Delete</a>
            <a *ngIf="!showStatus" class="link-color" href="javascript:void(0);" [attr.aria-label]="'Delete Contract Id ' + data.Name" onclick="alert(getAttribute('aria-label') +' ' + 'Success')" (click)="gridData.splice(i, 1)">Delete</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
<div class="modal-footer">
  <span *ngIf="showStatus" style="padding-left: 5px;" class=" pull-left text-left">Contracts added are tagged to the E2E instantaneously. Kindly use the delete option to detag the association with E2E.</span>
  <button *ngIf="!showStatus" type="button" data-dismiss="modal" aria-label="Ok" (click)="SaveGridData();" class="btn btn-primary">Ok</button>
  <button *ngIf="!showStatus" type="button" class="btn btn-primary" aria-label="Cancel"
          (click)="CancelGrid(null);" data-dismiss="modal" (keypress)="CancelGrid($event);">
    Cancel
  </button>

  <button *ngIf="showStatus" type="button" class="btn btn-primary" (click)="CancelGrid($event);" data-dismiss="modal">
    Close
  </button>
</div>
<span tabindex="0" onfocus="document.getElementById('Close_Manage_popup').focus();"> </span>-->

<div id="addContact" class="c-modal c-add-contact" tabindex="-1" aria-hidden="false" aria-labelledby="modalDialogTitle">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">{{attributeName + ' List'}}</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close" alt="Close" (click)="CancelGrid($event);" id="Close_Manage_popup"></button>
      </div>
      <div class="c-modal__body">
        <div class="c-add-contact__form">
          <div class="c-add-contact__form-input">
            <div class="c-add-contact__form-area">
              <div class="c-custom-select__wrapper  ">
                <label for="entId">
                  {{attributeName}}
                  <a href="javascript:void(0)" aria-label="contractStatusInfo" *ngIf="showStatus" data-target="#multicontractinfo" data-toggle="modal" class="c-myWizard-select-opt" (click)="showMultiContractInfo()">What is it?</a>
                </label>

                <input type="text" *ngIf="showStatus" class="o-input__input" [(ngModel)]="selectedValue" value="" id="ContractIdInfo" (keypress)="KeyPressNumeric($event)" autocomplete="off" maxlength="10" />
                <search-dropdown *ngIf="entity != 'profile'" [options]="AssociatedContractsEndToEnd"
                                 [isShow]="!showStatus" [labelInfo]="'ContractIdInfo'" [modelType]="'object'"
                                 [selected]="selectedValue" [selectText]="'Select'" [(ngModel)]="selectedValue">
                </search-dropdown>

              </div>
            </div>

          </div>
          <a href="javascript:;" class="c-add-contact__form-img" *ngIf="entity=='profile'" [disabled]="!selectedValue" (click)="AddAttributesToArrayForProfile(gridData,selectedValue, true);"><img src="../assets/toolkit/images/key-contacts/add.svg" alt="Add"></a>
          <a href="javascript:;" class="c-add-contact__form-img" *ngIf="entity!='profile'" [disabled]="!selectedValue" (click)="AddAttributesToArray(gridData,selectedValue);"><img src="../assets/toolkit/images/key-contacts/add.svg" alt="Add"></a>
          <!--<a href="javascript:;" class="c-add-contact__form-img" (click)="AddAttributesToArray(gridData,selectedValue);"><img src="../assets/toolkit/images/key-contacts/add.svg" alt="Add"></a>-->
        </div>
        <div class="c-table-responsive">
          <table class="c-table">
            <thead>
              <tr>
                <th scope="col" width="25%">{{attributeName}}</th>
                <th scope="col" width="25%" *ngIf="showStatus">Status</th>
                <th scope="col" width="25%" *ngIf="showStatus">Comments</th>
                <th scope="col" width="25%" class="nowrap td-width-10" style="text-align: center;">Delete</th>
               </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of gridData; let i=index; trackBy : trackById">
                <td>
                  {{ data.Name }}
                </td>
                <td *ngIf="showStatus">
                  {{data.Status}}
                </td>
                <td *ngIf="showStatus">
                  <span *ngIf="data.WarningIndicator == 'Y'" style="color:red;">Warning! </span>{{data.Comments}}
                </td>
               <!-- <td [attr.data-myw-title]="data.IsBase && showStatus ? 'Currently, removing the contract which initiated the E2E setup is not supported.': undefined" data-myw-toggle="tooltip" data-myw-placement="bottom" style="text-align: center;">
                  <a *ngIf="showStatus" href="javascript:void(0);" [ngClass]="{'-disabled':data.IsBase}" class="icon-delete" (click)="DetagContract(data.Name, data.IsAssociatedToCMSTMSWS, data)"></a>
                  <a *ngIf="!showStatus" href="javascript:void(0);" [attr.aria-label]="'Delete Contract Id ' + data.Name" class="icon-delete" onclick="alert(getAttribute('aria-label') +' ' + 'Success')" (click)="gridData.splice(i, 1)"></a>
                </td> -->

                <td style="text-align: center;">
                    <a *ngIf="showStatus" href="javascript:void(0);" [ngClass]="{'-disabled': data.IsBase}" class="icon-delete"
                    (click)="DetagContract(data.Name, data.IsAssociatedToCMSTMSWS, data)" [attr.data-myw-title]="data.IsBase && showStatus ? 'Currently, removing the contract which initiated the E2E setup is not supported.': 'Delete'" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                    <a *ngIf="!showStatus"href="javascript:void(0);" [attr.aria-label]="'Delete Contract Id ' + data.Name" class="icon-delete"
                    onclick="alert(getAttribute('aria-label') + ' Success')" (click)="gridData.splice(i, 1)" [attr.data-myw-title]="data.IsBase && showStatus ? 'Currently, removing the contract which initiated the E2E setup is not supported.': 'Delete'" data-myw-toggle="tooltip" data-myw-placement="bottom"></a> 
                </td>
                <!--<ng-template #noTooltip>
                  <td style="text-align: center;">
                        <a *ngIf="showStatus" href="javascript:void(0);" [ngClass]="{'-disabled': data.IsBase}" class="icon-delete"
                        (click)="DetagContract(data.Name, data.IsAssociatedToCMSTMSWS, data)"></a>
                        <a *ngIf="!showStatus" href="javascript:void(0);" [attr.aria-label]="'Delete Contract Id ' + data.Name" class="icon-delete"
                        onclick="alert(getAttribute('aria-label') + ' Success')" (click)="gridData.splice(i, 1)"></a>
                  </td>
                </ng-template>-->
               </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="c-modal__footer">
        <div class="c-modal__footer-btnGroup">
          <span *ngIf="showStatus" style="padding-left: 5px;" class=" pull-left text-left">Contracts added are tagged to the E2E instantaneously. Kindly use the delete option to detag the association with E2E.</span>
          <!-- add class -dismiss-modal to buttons that'll close the modal -->
          <!--Primary Button starts from here-->
          <a href="javascript:void(0)" *ngIf="!showStatus" role="button" class="o-secondary-button  -dismiss-modal" (click)="CancelGrid(null);" (keypress)="CancelGrid($event);">
            <!---->
            Cancel
          </a>
          <a href="javascript:void(0)" *ngIf="showStatus" role="button" class="o-secondary-button  -dismiss-modal" (click)="CancelGrid($event);">
            <!---->
            Close
          </a>
          <!--Primary Button ends from here-->                    <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" class="o-primary-button  -dismiss-modal" (click)="SaveGridData();" *ngIf="!showStatus">
            <!---->
            Save
          </a>
          <!--Primary Button ends from here-->
        </div>
      </div>
    </div>
  </div>
</div>
<span tabindex="0" onfocus="document.getElementById('Close_Manage_popup').focus();"> </span>
