<div id="priorityModal" class="c-modal c-priority-modal" tabindex="-1" aria-labelledby="modalDialogTitle" aria-modal="true" role="dialog">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">Delivery Functions Definition</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close BCM Priority Description Popup" alt="Close" (click)="closeModal()"></button>
      </div>
      <div class="c-modal__body">
        <ul>
          <li><div>Review the Delivery Functions below. Functions enabled as 'Yes' are  initial suggestions based on project delivery demographics, but further modification can be made to align to your Project Delivery for all except core delivery functions which are mandatory. These selections feed into Recommendation Engine, which drives recommendation of assets/ methods/tools, for your project.</div></li>
        </ul>
      </div>
    </div>
  </div>
</div>


