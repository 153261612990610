<!--<div class="tab-content">
  <div class="page-title">
    <div class="row">
      <div class="col-xs-12 col-md-8 ">
        <button class="btn btn-primary cms-tms-expand" (click)="ExpandAll();" aria-label="Expand All">Expand All</button>
        <button class="btn btn-primary cms-tms-collapse" (click)="CollapseAll();" aria-label="Collapse All">Collapse All</button>
        <button class="btn btn-primary cms-tms-add" data-toggle="modal" id="AddCMSDetails" (click)="Add(1)" [disabled]="IsWorkStreamSelected || IsFifthLevelCMS || !IsEditable || IsCMSSelected" title="Open a popup" aria-label="Add Client Management Structure(Open a popup)">Add Client Management Structure</button>
        <button class="btn btn-primary cms-tms-add" data-toggle="modal" id="AddWSDetails" (click)="Add(2)" [disabled]="IsWorkStreamSelected || !IsEditable || IsCMSSelected" title="Open a popup" aria-label="Add Workstream(Open a popup)">Add Workstream</button>
      </div>
      <div class="col-md-4 text-right" *ngIf="IsConfigurationManager">
        <a class="link-color" href="javascript:void(0);" (click)="NavigateDeliveryTool();" aria-label="Navigate to Delivery Tool Data Mapping">Navigate to Delivery Tool Data Mapping</a>
      </div>
    </div>
    <div class="row padding-top-10" *ngIf="SubmissionType == 1">
      <div class="col-xs-12 col-md-12 ">
        <span style="color:#ff0000">{{cmsHierarchy.UserNotificationMessage}}</span>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="cmsHierarchy.IsEndToEndDeliveryTypeAgile">
    <div class="col-xs-12 form-horizontal">
      <div>
        <strong>Auto-approval for Agile workstreams from external tools</strong>

        <div class="toggle-switch auto-approval-toggle">
          <label class="switch" for="agilewsapprover" tabindex="0" (keypress)="ToggleScope($event)">
            <input tabindex="-1" id="agilewsapprover" name="agilewsapprover" [value]="cmsHierarchy.AutoCreatedAgileWSApproved" class="switch-input" type="checkbox"
                   [disabled]="!IsEditable" [(ngModel)]="cmsHierarchy.AutoCreatedAgileWSApproved"
                   (change)="AutoCreatedWSApprovalChange(cmsHierarchy.AutoCreatedAgileWSApproved, $event)" aria-label="Click here select Yes/No" />
            <span class="switch-label" data-on="Yes" data-off="No"></span>
            <span class="switch-handle"></span>
            <span class="switch-text">&nbsp;</span>
          </label>
        </div>
      </div>
      <div class="auto-approval-label" *ngIf="!cmsHierarchy.AutoCreatedAgileWSApproved">
        This setting is not applicable to closed WS, as those will be system-approved, irrespective of user preference
      </div>
    </div>
  </div>
  <form name="clientstructureform" #clientstructureform="ngForm" novalidate>
    <div class="cms-tms-wrap" aria-describedby="CmsHierarchy">
      <p id="CmsHierarchy" class="screenReaderOnly"> Lists Delivery Structure for the E2E along with CMS, Workstreams. </p>
      <ul class="cms-tms-static-nonstatic">
        <li class="cms-tms-item-leaf cms-tms-item-no-nesting static">
          <div *ngIf="EndToEndType == 'I'" class="cms-tms-item endtoend-row  endtoend-row-initiative">

            <span tabindex="0"><span class="screenReaderOnly">Initiative</span>{{EndToEndName}}</span>
          </div>
          <div *ngIf="EndToEndType == 'O'" class="cms-tms-item endtoend-row  endtoend-row-opportunity">

            <span tabindex="0"><span class="screenReaderOnly">Opportunity</span>{{EndToEndRefCode}} : {{EndToEndName}}</span>
          </div>
          <div *ngIf="EndToEndType == 'C'" class="cms-tms-item endtoend-row endtoend-row-contract">
            <span tabindex="0"><span class="screenReaderOnly">Contract</span>{{EndToEndRefCode}} : {{EndToEndName}}</span>
          </div>

        </li>
        <li aria-expanded="true">
          <ul class="cms-tms-list" click-elsewhere (clickOutside)="Resetcms()">
            <li *ngFor="let ws0 of cmsHierarchy.WorkStreamStructure;" [attr.data-item]="ws0 | json" [attr.data-wsId]="ws0.WorkstreamId" [attr.data-wsfactoryindicator]="ws0.FactoryWorkstreamIndicator" (click)="SetSelectedValues($event,null,ws0);$event.stopPropagation();" class="cms-tms-item-leaf cms-tms-item-no-nesting">
              <div *ngIf="ws0.FactoryName == null" class="cms-tms-item cms-item-waterfall cms-tms-item-no-nesting" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws0.IsSelected}">
                <span tabindex="0" class="cms-tms-item-handle"></span>
                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!='' && ws0.FactoryName == null)">{{ws0.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}},&nbsp;<span class="small">{{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}}</span></span>
                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate=='' && ws0.FactoryName == null)">{{ws0.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}</span>
                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!='' && ws0.FactoryName == null)"><img class="workstream-img" [attr.title]="ws0.WorkstreamTypeName" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws0.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}})</span></span>
                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate=='' && ws0.FactoryName == null)"><img class="workstream-img" [attr.title]="ws0.WorkstreamTypeName" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws0.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;</span>
                <button id="WS{{ws0.WorkstreamId}}" class="btnHeader glyphicon icon tile-text" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" (click)="Edit(null,ws0);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Workstream':'View Workstream'+ '-' + ws0.WorkStreamName + '(opens as popup)'"></button>
              </div>
              <div *ngIf="ws0.FactoryName != null" class="cms-tms-item cms-item-waterfall-factory cms-tms-item-no-nesting" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws0.IsSelected}">
                <span tabindex="0" class="cms-tms-item-handle"></span>
                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!=''&& ws0.FactoryName != null)">{{ws0.FactoryName}} - {{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}},&nbsp;<span class="small">{{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}}</span></span>
                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate==''&& ws0.FactoryName != null)">{{ws0.FactoryName}} - {{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}</span>
                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!=''&& ws0.FactoryName != null)"><img class="workstream-img" [attr.title]="ws0.WorkstreamTypeName" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws0.FactoryName}} -  {{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}})</span></span>
                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate==''&& ws0.FactoryName != null)"><img class="workstream-img" [attr.title]="ws0.WorkstreamTypeName" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws0.FactoryName}} - {{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;</span>
                <button id="WS{{ws0.WorkstreamId}}" class="btnHeader glyphicon pull-right tile-text" (click)="Edit(null,ws0);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Factory Workstream':'View Factory Workstream'+ '-' + ws0.FactoryName + '-' + ws0.WorkStreamName + '(opens as popup)'">
                  <img style="width:20px;" alt="View Workstream Details" src="assets/images/icon_dashboard_read.png">
                </button>
              </div>
            </li>
            <li *ngFor="let cms1 of cmsHierarchy.CMSLevelOne; " [attr.data-item]="cms1 | json" [attr.data-cmsId]="cms1.CMSId" (click)="SetSelectedValues($event,cms1,null);$event.stopPropagation();" [ngClass]="{open : !cms1.IsChildCollapsed || (cms1.CMSLevelTwo.length <= 0 && cms1.WorkStreamStructure.length <= 0)}" aria-expanded="{{cms1.IsSelected?'true':'false'}}">
              <div class="cms-tms-item" [ngClass]="{'cms-tms-item-selected' : cms1.IsSelected}" data-type="CMS">
                <span tabindex="0" class="cms-tms-item-handle"></span>
                <button *ngIf="cms1.CMSLevelTwo.length > 0 || cms1.WorkStreamStructure.length > 0" class="btnHeader glyphicon glyphicon-triangle-right tile-text"></button>
                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-cms' : cms1.CmsStatusId == 2}">{{cms1.CmsStatusId == 2 ? "[CLOSED]" + cms1.CMSName : cms1.CMSName}}</span>
                <button id="CMS{{cms1.CMSId}}" class="btnHeader glyphicon icon tile-text" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" (click)="Edit(cms1,null);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit CMS':'View CMS'+ '-' + cms1.CMSName +'(opens as popup)'"></button>
                <button *ngIf="IsEditable && !cms1.PhoenixMapping.IsPhoenixMappingAdded" class="btnHeader glyphicon glyphicon-trash icon tile-text" (click)="Delete(cms1,'cms',$event);$event.stopPropagation();" [attr.aria-label]="'Delete this CMS - '+ cms1.CMSName + '(opens as popup)'"></button>
              </div>
              <ul>
                <li *ngFor="let ws1 of cms1.WorkStreamStructure; " [attr.data-item]="ws1 | json" [attr.data-wsId]="ws1.WorkstreamId" [attr.data-wsfactoryindicator]="ws1.FactoryWorkstreamIndicator" (click)="SetSelectedValues($event,null,ws1);$event.stopPropagation();" class="cms-tms-item-leaf cms-tms-item-no-nesting">
                  <div *ngIf="ws1.FactoryName == null" class="cms-tms-item cms-item-waterfall cms-tms-item-no-nesting" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws1.IsSelected}">
                    <span tabindex="0" class="cms-tms-item-handle"></span>
                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!='' && ws1.FactoryName == null)">{{ws1.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}},&nbsp;<span class="small">{{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}}</span>1111</span>
                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate=='' && ws1.FactoryName == null)">{{ws1.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}</span>
                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!='' && ws1.FactoryName == null)"><img class="workstream-img" [attr.title]="ws1.WorkstreamTypeName" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws1.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}})</span></span>
                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate=='' && ws1.FactoryName == null)"><img class="workstream-img" [attr.title]="ws1.WorkstreamTypeName" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws1.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;</span>
                    <button id="WS{{ws1.WorkstreamId}}" class="btnHeader glyphicon icon tile-text" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" (click)="Edit(null,ws1);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Workstream':'View Workstream'+ '-' + ws1.WorkStreamName +'(opens as popup)'"></button>
                  </div>
                  <div *ngIf="ws1.FactoryName != null" class="cms-tms-item cms-item-waterfall-factory cms-tms-item-no-nesting" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws1.IsSelected}">
                    <span tabindex="0" class="cms-tms-item-handle"></span>
                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!=''&& ws1.FactoryName != null)">{{ws1.FactoryName}} - {{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}},&nbsp;<span class="small">{{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}}</span></span>
                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate==''&& ws1.FactoryName != null)">{{ws1.FactoryName}} - {{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}</span>
                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!=''&& ws1.FactoryName != null)"><img class="workstream-img" [attr.title]="ws1.WorkstreamTypeName" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws1.FactoryName}} - {{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}})</span></span>
                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate==''&& ws1.FactoryName != null)"><img class="workstream-img" [attr.title]="ws1.WorkstreamTypeName" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws1.FactoryName}} - {{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;</span>
                    <button id="WS{{ws1.WorkstreamId}}" class="btnHeader glyphicon icon pull-right tile-text" (click)="Edit(null,ws1);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Factory Workstream':'View Factory Workstream'+ '-' + ws1.FactoryName+  '-' + ws1.WorkStreamName +'(opens as popup)'">
                      <img style="width:20px;" alt="View Workstream Details" src="assets/images/icon_dashboard_read.png">
                    </button>
                  </div>
                </li>
                <li *ngFor="let cms2 of cms1.CMSLevelTwo; " [attr.data-item]="cms2 | json" [attr.data-cmsId]="cms2.CMSId" (click)="SetSelectedValues($event,cms2,null);$event.stopPropagation();" [ngClass]="{open : !cms2.IsChildCollapsed || (cms2.CMSLevelThree.length <= 0 && cms2.WorkStreamStructure.length <= 0)}" aria-expanded="{{cms2.IsSelected?'true':'false'}}">
                  <div class="cms-tms-item" [ngClass]="{'cms-tms-item-selected' : cms2.IsSelected}" data-type="CMS">
                    <span tabindex="0" class="cms-tms-item-handle"></span>
                    <button *ngIf="cms2.CMSLevelThree.length > 0 || cms2.WorkStreamStructure.length > 0" class="btnHeader glyphicon glyphicon-triangle-right tile-text"></button>
                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-cms' : cms2.CmsStatusId == 2}">{{cms2.CmsStatusId == 2 ? "[CLOSED]" + cms2.CMSName : cms2.CMSName}}</span>
                    <button id="CMS{{cms2.CMSId}}" class="btnHeader glyphicon icon tile-text" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" (click)="Edit(cms2,null);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit CMS':'View CMS' + '-'+ cms2.CMSName +'(opens as popup)'"></button>
                    <button *ngIf="IsEditable && !cms2.PhoenixMapping.IsPhoenixMappingAdded" class="btnHeader glyphicon glyphicon-trash icon tile-text" (click)="Delete(cms2,'cms',$event);$event.stopPropagation();" [attr.aria-label]="'Delete this CMS -' + cms2.CMSName +'(opens as popup)'"></button>
                  </div>
                  <ul>
                    <li *ngFor="let ws2 of cms2.WorkStreamStructure; " [attr.data-item]="ws2 | json" [attr.data-wsId]="ws2.WorkstreamId" [attr.data-wsfactoryindicator]="ws2.FactoryWorkstreamIndicator" (click)="SetSelectedValues($event,null,ws2);$event.stopPropagation();" class="cms-tms-item-leaf cms-tms-item-no-nesting">
                      <div *ngIf="ws2.FactoryName == null" class="cms-tms-item cms-item-waterfall cms-tms-item-no-nesting" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws2.IsSelected}">
                        <span tabindex="0" class="cms-tms-item-handle"></span>
                        <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!='' && ws2.FactoryName == null)">{{ws2.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}},&nbsp;<span class="small">{{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}}</span></span>
                        <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='' && ws2.FactoryName == null)">{{ws2.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}</span>
                        <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!='' && ws2.FactoryName == null)"><img class="workstream-img" [attr.title]="ws2.WorkstreamTypeName" [src]="workStreamIconSelection(ws2.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws2.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}})</span></span>
                        <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='' && ws2.FactoryName == null)"><img class="workstream-img" [attr.title]="ws2.WorkstreamTypeName" [src]="workStreamIconSelection(ws2.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws2.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;</span>
                        <button id="WS{{ws2.WorkstreamId}}" class="btnHeader glyphicon icon tile-text" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" (click)="Edit(null,ws2);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Workstream':'View Workstream' + '-' + ws2.WorkStreamName + '(opens as popup)'"></button>
                      </div>
                      <div *ngIf="ws2.FactoryName != null" class="cms-tms-item cms-item-waterfall-factory cms-tms-item-no-nesting" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws2.IsSelected}">
                        <span tabindex="0" class="cms-tms-item-handle"></span>
                        <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!='' && ws2.FactoryName != null)">{{ws2.FactoryName}} - {{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}},&nbsp;<span class="small">{{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}}</span></span>
                        <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='' && ws2.FactoryName != null)">{{ws2.FactoryName}} - {{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}</span>
                        <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!='' && ws2.FactoryName != null)"><img class="workstream-img" [attr.title]="ws2.WorkstreamTypeName" [src]="workStreamIconSelection(ws2.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws2.FactoryName}} - {{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}})</span></span>
                        <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='' && ws2.FactoryName != null)"><img class="workstream-img" [attr.title]="ws2.WorkstreamTypeName" [src]="workStreamIconSelection(ws2.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws2.FactoryName}} - {{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;</span>
                        <button id="WS{{ws2.WorkstreamId}}" class="btnHeader glyphicon icon pull-right tile-text" (click)="Edit(null,ws2);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Factory Workstream':'View Factory Workstream'+ '-' + ws2.FactoryName + '-' + ws2.WorkStreamName + '(opens as popup)'">
                          <img style="width:20px;" alt="View Workstream Details" src="assets/images/icon_dashboard_read.png">
                        </button>

                      </div>
                    </li>
                    <li *ngFor="let cms3 of cms2.CMSLevelThree; " [attr.data-item]="cms3 | json" [attr.data-cmsId]="cms3.CMSId" (click)="SetSelectedValues($event,cms3,null);$event.stopPropagation();" [ngClass]="{open : !cms3.IsChildCollapsed || (cms3.CMSLevelFour.length <= 0 && cms3.WorkStreamStructure.length <= 0)}" aria-expanded="{{cms3.IsSelected?'true':'false'}}">
                      <div class="cms-tms-item" [ngClass]="{'cms-tms-item-selected' : cms3.IsSelected}" data-type="CMS">
                        <span tabindex="0" class="cms-tms-item-handle"></span>
                        <button *ngIf="cms3.CMSLevelFour.length > 0 || cms3.WorkStreamStructure.length > 0" class="btnHeader glyphicon glyphicon-triangle-right tile-text"></button>
                        <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-cms' : cms3.CmsStatusId == 2}">{{cms3.CmsStatusId == 2 ? "[CLOSED]" + cms3.CMSName : cms3.CMSName}}</span>
                        <button id="CMS{{cms3.CMSId}}" class="btnHeader glyphicon icon tile-text" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" (click)="Edit(cms3,null);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit CMS':'View CMS' +'-' + cms3.CMSName+'(opens as popup)'"></button>
                        <button *ngIf="IsEditable && !cms3.PhoenixMapping.IsPhoenixMappingAdded" class="btnHeader glyphicon glyphicon-trash icon tile-text" (click)="Delete(cms3,'cms',$event);$event.stopPropagation();" [attr.aria-label]="'Delete this CMS -' + cms3.CMSName +'(opens as popup)'"></button>
                      </div>
                      <ul>
                        <li *ngFor="let ws3 of cms3.WorkStreamStructure;" [attr.data-item]="ws3 | json" [attr.data-wsId]="ws3.WorkstreamId" [attr.data-wsfactoryindicator]="ws3.FactoryWorkstreamIndicator" (click)="SetSelectedValues($event,null,ws3);$event.stopPropagation();" class="cms-tms-item-leaf cms-tms-item-no-nesting">
                          <div *ngIf="ws3.FactoryName == null" class="cms-tms-item cms-item-waterfall cms-tms-item-no-nesting" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws3.IsSelected}">
                            <span tabindex="0" class="cms-tms-item-handle"></span>
                            <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!='' && ws3.FactoryName == null)">{{ws3.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}},&nbsp;<span class="small">{{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}}</span></span>
                            <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='' && ws3.FactoryName == null)">{{ws3.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}</span>
                            <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!='' && ws3.FactoryName == null)"><img class="workstream-img" [attr.title]="ws3.WorkstreamTypeName" [src]="workStreamIconSelection(ws3.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws3.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}})</span></span>
                            <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='' && ws3.FactoryName == null)"><img class="workstream-img" [attr.title]="ws3.WorkstreamTypeName" [src]="workStreamIconSelection(ws3.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws3.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;</span>
                            <button id="WS{{ws3.WorkstreamId}}" class="btnHeader glyphicon icon tile-text" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" (click)="Edit(null,ws3);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Workstream':'View Workstream'+ '-' + ws3.WorkStreamName + '(opens as popup)'"></button>
                          </div>
                          <div *ngIf="ws3.FactoryName !=null" class="cms-tms-item cms-item-waterfall-factory cms-tms-item-no-nesting" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws3.IsSelected}">
                            <span tabindex="0" class="cms-tms-item-handle"></span>
                            <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!='' && ws3.FactoryName != null)">{{ws3.FactoryName}} - {{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}},&nbsp;<span class="small">{{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}}</span></span>
                            <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='' && ws3.FactoryName != null)">{{ws3.FactoryName}} - {{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}</span>
                            <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!='' && ws3.FactoryName != null)"><img class="workstream-img" [attr.title]="ws3.WorkstreamTypeName" [src]="workStreamIconSelection(ws3.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws3.FactoryName}} - {{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}})</span></span>
                            <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='' && ws3.FactoryName != null)"><img class="workstream-img" [attr.title]="ws3.WorkstreamTypeName" [src]="workStreamIconSelection(ws3.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws3.FactoryName}} - {{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;</span>
                            <button id="WS{{ws3.WorkstreamId}}" class="btnHeader glyphicon icon pull-right tile-text" (click)="Edit(null,ws3);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Factory Workstream':'View Factory Workstream' + '-' + ws3.FactoryName+ '-' + ws3.WorkStreamName +'(opens as popup)'">
                              <img style="width:20px;" alt="View Workstream Details" src="assets/images/icon_dashboard_read.png">
                            </button>
                          </div>
                        </li>
                        <li *ngFor="let cms4 of cms3.CMSLevelFour;" [attr.data-item]="cms4 | json" [attr.data-cmsId]="cms4.CMSId" (click)="SetSelectedValues($event,cms4,null);$event.stopPropagation();" [ngClass]="{open : !cms4.IsChildCollapsed || (cms4.CMSLevelFive.length <= 0 && cms4.WorkStreamStructure.length <= 0)}" aria-expanded="{{cms4.IsSelected?'true':'false'}}">
                          <div class="cms-tms-item" [ngClass]="{'cms-tms-item-selected' : cms4.IsSelected}" data-type="CMS">
                            <span tabindex="0" class="cms-tms-item-handle"></span>
                            <button *ngIf="cms4.CMSLevelFive.length > 0 || cms4.WorkStreamStructure.length > 0" class="btnHeader glyphicon glyphicon-triangle-right tile-text"></button>
                            <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-cms' : cms4.CmsStatusId == 2}">{{cms4.CmsStatusId == 2 ? "[CLOSED]" + cms4.CMSName : cms4.CMSName}}</span>
                            <button id="CMS{{cms4.CMSId}}" class="btnHeader glyphicon icon tile-text" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" (click)="Edit(cms4,null);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit CMS':'View CMS'+  '-' + cms4.CMSName + '(opens as popup)'"></button>
                            <button *ngIf="IsEditable && !cms4.PhoenixMapping.IsPhoenixMappingAdded" class="btnHeader glyphicon glyphicon-trash icon tile-text" (click)="Delete(cms4,'cms',$event);$event.stopPropagation();" [attr.aria-label]="'Delete this CMS -' + cms4.CMSName +'(opens as popup)'"></button>
                          </div>
                          <ul>
                            <li *ngFor="let ws4 of cms4.WorkStreamStructure; " [attr.data-item]="ws4 | json" [attr.data-wsId]="ws4.WorkstreamId" [attr.data-wsfactoryindicator]="ws4.FactoryWorkstreamIndicator" (click)="SetSelectedValues($event,null,ws4);$event.stopPropagation();" class="cms-tms-item-leaf cms-tms-item-no-nesting">
                              <div *ngIf="ws4.FactoryName == null" class="cms-tms-item cms-item-waterfall cms-tms-item-no-nesting" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws4.IsSelected}">
                                <span tabindex="0" class="cms-tms-item-handle"></span>
                                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!='' && ws4.FactoryName == null)">{{ws4.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}},&nbsp;<span class="small">{{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}}</span></span>
                                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='' && ws4.FactoryName == null)">{{ws4.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}</span>
                                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!='' && ws4.FactoryName == null)"><img class="workstream-img" [attr.title]="ws4.WorkstreamTypeName" [src]="workStreamIconSelection(ws4.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws4.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}})</span></span>
                                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='' && ws4.FactoryName == null)"><img class="workstream-img" [attr.title]="ws4.WorkstreamTypeName" [src]="workStreamIconSelection(ws4.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws4.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;</span>
                                <button id="WS{{ws4.WorkstreamId}}" class="btnHeader glyphicon icon tile-text" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" (click)="Edit(null,ws4);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Workstream':'View Workstream' + '-' + ws4.WorkStreamName + '(opens as popup)'"></button>
                              </div>
                              <div *ngIf="ws4.FactoryName != null" class="cms-tms-item cms-item-waterfall-factory cms-tms-item-no-nesting" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws4.IsSelected}">
                                <span tabindex="0" class="cms-tms-item-handle"></span>
                                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!='' && ws4.FactoryName != null)">{{ws4.FactoryName}} - {{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}},&nbsp;<span class="small">{{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}}</span></span>
                                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='' && ws4.FactoryName != null)">{{ws4.FactoryName}} - {{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}</span>
                                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!='' && ws4.FactoryName != null)"><img class="workstream-img" [attr.title]="ws4.WorkstreamTypeName" [src]="workStreamIconSelection(ws4.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws4.FactoryName}} - {{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}})</span></span>
                                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='' && ws4.FactoryName != null)"><img class="workstream-img" [attr.title]="ws4.WorkstreamTypeName" [src]="workStreamIconSelection(ws4.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws4.FactoryName}} - {{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;</span>
                                <button id="WS{{ws4.WorkstreamId}}" class="btnHeader glyphicon icon pull-right tile-text" (click)="Edit(null,ws4);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Factory Workstream':'View Factory Workstream'+ ' - ' + ws4.FactoryName + '-' + ws4.WorkStreamName+ '(opens as popup)'">
                                  <img style="width:20px;" alt="View Workstream Details" src="assets/images/icon_dashboard_read.png">
                                </button>
                              </div>
                            </li>
                            <li *ngFor="let cms5 of cms4.CMSLevelFive; " [attr.data-item]="cms5 | json" [attr.data-cmsId]="cms5.CMSId" (click)="SetSelectedValues($event,cms5,null);$event.stopPropagation();" [ngClass]="{open : !cms5.IsChildCollapsed || (cms5.WorkStreamStructure.length <= 0)}" aria-expanded="{{cms5.IsSelected?'true':'false'}}">
                              <div class="cms-tms-item" [ngClass]="{'cms-tms-item-selected' : cms5.IsSelected}" data-type="CMS">
                                <span tabindex="0" class="cms-tms-item-handle"></span>
                                <button *ngIf="cms5.WorkStreamStructure.length > 0" class="btnHeader glyphicon glyphicon-triangle-right tile-text"></button>
                                <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-cms' : cms5.CmsStatusId == 2}">{{cms5.CmsStatusId == 2 ? "[CLOSED]" + cms5.CMSName : cms5.CMSName}}</span>
                                <button id="CMS{{cms5.CMSId}}" class="btnHeader glyphicon icon tile-text" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" (click)="Edit(cms5,null);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit CMS':'View CMS'+ '-' + cms5.CMSName + '(opens as popup)'"></button>
                                <button *ngIf="IsEditable && !cms5.PhoenixMapping.IsPhoenixMappingAdded" class="btnHeader glyphicon glyphicon-trash icon tile-text" (click)="Delete(cms5,'cms',$event);$event.stopPropagation();" [attr.aria-label]="'Delete this CMS -' + cms5.CMSName +'(opens as popup)'"></button>
                              </div>
                              <ul>
                                <li *ngFor="let ws5 of cms5.WorkStreamStructure; " [attr.data-item]="ws5 | json" [attr.data-wsId]="ws5.WorkstreamId" [attr.data-wsfactoryindicator]="ws5.FactoryWorkstreamIndicator" (click)="SetSelectedValues($event,null,ws5);$event.stopPropagation();" class="cms-tms-item-leaf cms-tms-item-no-nesting">
                                  <div *ngIf="ws5.FactoryName == null" class="cms-tms-item cms-item-waterfall cms-tms-item-no-nesting disabledClass" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws5.IsSelected}">
                                    <span tabindex="0" class="cms-tms-item-handle"></span>
                                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!='' && ws5.FactoryName == null)">{{ws5.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}},&nbsp;<span class="small">{{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}}</span></span>
                                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='' && ws5.FactoryName == null)">{{ws5.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}</span>
                                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!='' && ws5.FactoryName == null)"><img class="workstream-img" [attr.title]="ws5.WorkstreamTypeName" [src]="workStreamIconSelection(ws5.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws5.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}})</span></span>
                                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='' && ws5.FactoryName == null)"><img class="workstream-img" [attr.title]="ws5.WorkstreamTypeName" [src]="workStreamIconSelection(ws5.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws5.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;</span>
                                    <button id="WS{{ws5.WorkstreamId}}" class="btnHeader glyphicon icon tile-text" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" (click)="Edit(null,ws5);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Workstream':'View Workstream'+ '-' + ws5.WorkStreamName + '(opens as popup)'"></button>
                                  </div>
                                  <div *ngIf="ws5.FactoryName != null" class="cms-tms-item cms-item-waterfall-factory cms-tms-item-no-nesting disabledClass" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws5.IsSelected}">
                                    <span tabindex="0" class="cms-tms-item-handle"></span>
                                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!='' && ws5.FactoryName != null)">{{ws5.FactoryName}} - {{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}},&nbsp;<span class="small">{{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}}</span></span>
                                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='' && ws5.FactoryName != null)">{{ws5.FactoryName}} - {{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}</span>
                                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!='' && ws5.FactoryName != null)"><img class="workstream-img" [attr.title]="ws5.WorkstreamTypeName" [src]="workStreamIconSelection(ws5.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws5.FactoryName}} - {{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}})</span></span>
                                    <span tabindex="0" class="cms-tms-item-text" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='' && ws5.FactoryName != null)"><img class="workstream-img" [attr.title]="ws5.WorkstreamTypeName" [src]="workStreamIconSelection(ws5.WorkstreamTypeId)" alt="" />&nbsp;-&nbsp;{{ws5.FactoryName}} - {{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;</span>
                                    <button id="WS{{ws5.WorkstreamId}}" class="btnHeader glyphicon icon pull-right tile-text" (click)="Edit(null,ws5);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit Factory Workstream':'View Factory Workstream' + '-' + ws5.FactoryName + '-' + ws5.WorkStreamName + '(opens as popup)'">
                                      <img style="width:20px;" alt="View Workstream Details" src="assets/images/icon_dashboard_read.png">
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="pull-right modified-details" *ngIf="IsShowModifiedDetails">
      <span class="small"><strong> Updated By: </strong></span><span>{{cmsHierarchy.LastModifiedByUserForCMS}}</span>
      <br />
      <span class="small"><strong>Last Updated On: </strong></span><span>{{cmsHierarchy.LastModifiedByOnForCMS |date:'dd MMM yyyy h:mm a'}} (GMT)</span>
    </div>
    <div class="col-xs-12 text-right" style="padding-top:25px">

      <button data-toggle="modal" class="btn btn-primary cms-tms-edit" aria-label="Save" [disabled]="!IsEditable || (!DraggedItems || DraggedItems.length <=0)"
              *ngIf="(cmsHierarchy.WorkStreamStructure == undefined || cmsHierarchy.WorkStreamStructure == null ? false : cmsHierarchy.WorkStreamStructure.length > 0) || (cmsHierarchy.CMSLevelOne == undefined || cmsHierarchy.CMSLevelOne == null ? false : cmsHierarchy.CMSLevelOne.length > 0)" (click)="SaveDraggedCMS()">
        Save
      </button>
      <button data-toggle="modal" class="btn btn-primary cms-tms-edit" aria-label="Reset" [disabled]="!IsEditable || (!DraggedItems || DraggedItems.length <=0)"
              *ngIf="(cmsHierarchy.WorkStreamStructure == undefined || cmsHierarchy.WorkStreamStructure == null ? false : cmsHierarchy.WorkStreamStructure.length > 0) || (cmsHierarchy.CMSLevelOne == undefined || cmsHierarchy.CMSLevelOne == null ? false : cmsHierarchy.CMSLevelOne.length > 0)" (click)="CancelDraggAndDrop()">
        Reset
      </button>
    </div>
  </form>
  <div class="modal" id="alertPopUp" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog-wrap">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" id="cms_alertpopup" class="close" data-dismiss="modal" aria-label="Close" (click)="CloseAlert()"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Alert</h4>
          </div>
          <div class="modal-body">
            {{Message}}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close" (click)="CloseAlert()">Close</button>
          </div>
        </div>
      </div>
    </div>
    <span tabindex="0" onfocus="document.getElementById('cms_alertpopup').focus();"></span>
  </div>
</div>-->

<form name="clientstructureform" #clientstructureform="ngForm" novalidate>
  <div class="c-myWizard-container c-client-structure">
    <div class="c-myWizard__header">
      <div class="c-myWizard__header-content-left">
        <div class="c-myWizard__header-content-head">
          <a href="javascript:;" class="icon-banner-arrow" role="button" alt="Back" aria-label="Back" (click)="OnBackClick()"></a>
          <div class="-header">Client Structure</div>
        </div>
        <!--<div class="row padding-top-10" *ngIf="cmsHierarchy.DisplayRevisedSICoreMetricsAlert && SubmissionType == 1">
        <div class="col-xs-12 col-md-12 ">
          <span style="color:#ff0000">
            There are no active Conventional Release(s) and Agile Release(s) type of workstream. Update “<b>Do you wish to report revised SI Core metrics</b>” flag to “<b>Yes</b>” in
            <a href="javascript:void(0)" class="notification-link"
               (click)="RedirectToScopeTab()">Scope</a> tab if you wish to report revised SI Core metrics.
          </span>
        </div>
      </div>-->
        <!--<div class="c-myWizard-text" *ngIf="SubmissionType == 1"><span>{{cmsHierarchy.UserNotificationMessage}}</span></div>-->
      </div>
      <div class="c-myWizard__header-content-right">
        <div class="c-header-data">
          <a href="javascript:void(0);" class="-delivery" (click)="NavigateDeliveryTool();"><i class="icon-external-link"></i><span>Delivery Tool Data Mapping</span></a>
          <div class="c-header-dropdown">
            <a href="javascript:;" role="button" data-myw-title="User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="DisplayCMSUserGuide()" class="-dropdown-icon"><img src="../assets/toolkit/images/user-guide-stories/user-guide.svg" alt="User Guide" /></a>
          </div>
        </div>

        <div class="c-header-data">
          <a class="c-myWizard__header-details" role="button" data-flyoutid="client-structure" id="AddCMSDetails" (click)="Add(1)"
             [ngClass]="{'-disabled':(IsWorkStreamSelected || IsFifthLevelCMS || !IsEditable || IsCMSSelected)}"
             [attr.tabindex]="(IsWorkStreamSelected || IsFifthLevelCMS || !IsEditable || IsCMSSelected) ? -1 : null">
            <img src="../assets/toolkit/images/key-contacts/add.svg" alt="Details" role="presentation" />
            <span>Client Management Structure</span>
          </a>
          <a class="c-myWizard__header-details -disabled" role="button" data-flyoutid="client-workspace" id="AddWSDetails" (click)="Add(2)"
             [ngClass]="{'-disabled':(IsWorkStreamSelected || !IsEditable || IsCMSSelected)}"
             [attr.tabindex]="(IsWorkStreamSelected || !IsEditable || IsCMSSelected) ? -1 : null">
            <img src="../assets/toolkit/images/key-contacts/add.svg" alt="Details" role="presentation" />
            <span>Workstream</span>
          </a>
        </div>
      </div>
    </div>
    <div class="c-scope-error" *ngIf="SubmissionType == 1 && cmsHierarchy.UserNotificationMessage != ''">
      <ul class="c-myWizard-list">
        <li>{{cmsHierarchy.UserNotificationMessage}}</li>
      </ul>
    </div>
    <div id="client-parentscm" class="c-client-struc-data">
      <div class="c-myWizard__title">
        <div class="c-myWizard__title-text" *ngIf="EndToEndType == 'I'"> <span>{{EndToEndName}}</span></div>
        <div class="c-myWizard__title-text" *ngIf="EndToEndType == 'O'"> {{EndToEndRefCode}} : <span>{{EndToEndName}}</span></div>
        <div class="c-myWizard__title-text" *ngIf="EndToEndType == 'C'"> {{EndToEndRefCode}} : <span>{{EndToEndName}}</span></div>
        <div class="c-twoSideToggle">
          <div class="c-twoSideToggle__switch">
            <span class="-prev" [ngClass]="CollapseExpand?'':'-active'">Collapse</span>
            <label class="c-twoSideToggle__switch-label">
              <input type="checkbox" [(ngModel)]="isExpanded" (change)="collapseParent($event)" name="isExpanded">
              <span></span>
            </label>
            <span class="-next" [ngClass]="CollapseExpand?'-active':''">Expand</span>
          </div>
        </div>
      </div>
      <div class="c-list-table">
        <div class="c-list-team">
          <ul class="cms-tms-list" click-elsewhere (clickOutside)="Resetcms()">
            <li class="c-list_content" *ngFor="let ws0 of cmsHierarchy.WorkStreamStructure;"
                [attr.data-item]="ws0 | json" [attr.data-wsId]="ws0.WorkstreamId"
                [attr.data-wsfactoryindicator]="ws0.FactoryWorkstreamIndicator"
                (click)="SetSelectedValues($event,null,ws0);$event.stopPropagation();">
              <div class="c-list" *ngIf="ws0.FactoryName == null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws0.IsSelected}">
                <div class="c-list-moveable">
                  <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                </div>
                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!='' && ws0.FactoryName == null)">
                  <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws0.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}},&nbsp;<span class="small">{{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}}</span></span>
                </div>
                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate=='' && ws0.FactoryName == null)">
                  <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws0.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}</span>
                </div>
                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!='' && ws0.FactoryName == null)">
                  <img class="workstream-img" [attr.data-myw-title]="ws0.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws0.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}})</span></span>
                </div>
                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate=='' && ws0.FactoryName == null)">
                  <img class="workstream-img" [attr.data-myw-title]="ws0.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws0.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;</span>
                </div>
                <div *ngIf="IsEditable" class="c-list-icons">
                  <a href="javascript:;" id="WS{{ws0.WorkstreamId}}" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit" (click)="Edit(null,ws0);$event.stopPropagation();"></a>
                  <!--[ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }"-->
                  <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" [ngClass]="{' ':IsEditable }"
                     *ngIf="ws0.FactoryWorkstreamIndicator != 'Y'  && !ws0.PhoenixCreationIndicator && ws0.WorkstreamTypeId != 12"
                     (click)="DeleteWS(ws0)"></a>
                </div>
                <div *ngIf="!IsEditable" class="c-list-icons">
                  <a href="javascript:;" class="c-view-link" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" role="presentation" (click)="Edit(null,ws0);$event.stopPropagation();" alt="View" /></a>
                </div>
              </div>
              <div class="c-list" *ngIf="ws0.FactoryName != null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws0.IsSelected}">
                <div class="c-list-moveable cms-tms-item-handle">
                  <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                </div>
                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!=''&& ws0.FactoryName != null)">
                  <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws0.FactoryName}} - {{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}},&nbsp;<span class="small">{{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}}</span></span>
                </div>
                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate=='' && ws0.FactoryName == null)">
                  <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws0.FactoryName}} - {{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}</span>
                </div>
                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!='' && ws0.FactoryName == null)">
                  <img class="workstream-img" [attr.data-myw-title]="ws0.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws0.FactoryName}} -  {{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}})</span></span>
                </div>
                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate=='' && ws0.FactoryName == null)">
                  <img class="workstream-img" [attr.data-myw-title]="ws0.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws0.FactoryName}} - {{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;</span>
                </div>
                <div *ngIf="IsEditable" class="c-list-icons">
                  <a href="javascript:;" id="WS{{ws0.WorkstreamId}}" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit" (click)="Edit(null,ws0);$event.stopPropagation();"
                     [attr.aria-label]="'Edit Factory Workstream'+ '-' + ws0.FactoryName + '-' + ws0.WorkStreamName + '(opens as popup)'"></a>
                  <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" [ngClass]="{' ':IsEditable }"
                     *ngIf="ws0.FactoryWorkstreamIndicator != 'Y'  && IsEditable && !ws0.PhoenixCreationIndicator  && ws0.WorkstreamTypeId != 12"
                     (click)="DeleteWS(ws0)"></a>
                </div>
                <div *ngIf="!IsEditable" class="c-list-icons">
                  <a href="javascript:;" class="c-view-link" [attr.aria-label]="'View Factory Workstream'+ '-' + ws0.FactoryName + '-' + ws0.WorkStreamName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" role="presentation" (click)="Edit(null,ws0);$event.stopPropagation();" alt="View" /></a>
                </div>
              </div>
            </li>
            <li class="c-list_content" *ngFor="let cms1 of cmsHierarchy.CMSLevelOne; " [ngClass]="{open : (cms1.CMSLevelTwo.length <= 0 && cms1.WorkStreamStructure.length <= 0)}" [attr.data-item]="cms1 | json" [attr.data-cmsId]="cms1.CMSId" (click)="SetSelectedValues($event,cms1,null);$event.stopPropagation();" aria-expanded="{{cms1.IsSelected?'true':'false'}}">
              <div class="c-list cms-tms-item"
                   [ngClass]="{'cms-tms-item-selected' : cms1.IsSelected,'c-list-child' : !(cms1.CMSLevelTwo.length == 0 && cms1.WorkStreamStructure.length == 0)}"
                   data-type="CMS">
                <div class="c-list-moveable cms-tms-item-handle">
                  <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button">
                    <img src="assets/toolkit/images/client-structure/drag.svg" alt="drag">
                  </a>
                </div>
                <div class="c-list-text">
                  <a href="javascript:;" class="icon-arrow-down -expand" *ngIf="cms1.CMSLevelTwo.length > 0 || cms1.WorkStreamStructure.length > 0"
                     aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom"(click)="expandCMSStructureList($event,cms1.CMSId);$event.stopPropagation();"></a>
                  <span [ngClass]="{'closed-cms' : cms1.CmsStatusId == 2}">{{cms1.CmsStatusId == 2 ? "[CLOSED]" + cms1.CMSName : cms1.CMSName}}</span>
                </div>
                <div *ngIf="IsEditable" class="c-list-icons">
                  <a href="javascript:;" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" id="CMS{{cms1.CMSId}}"
                     [ngClass]="{' glyphicon-pencil ':IsEditable }"
                     (click)="Edit(cms1,null);$event.stopPropagation();" [attr.aria-label]="IsEditable?'Edit CMS':'View CMS'+ '-' + cms1.CMSName +'(opens as popup)'"></a>
                  <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"
                     *ngIf="!cms1.PhoenixMapping.IsPhoenixMappingAdded"
                     (click)="Delete(cms1,'cms',$event);$event.stopPropagation();"
                     [attr.aria-label]="'Delete this CMS - '+ cms1.CMSName + '(opens as popup)'"></a>
                </div>
                <div *ngIf="!IsEditable" class="c-list-icons">
                  <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" role="presentation" (click)="Edit(cms1,null);$event.stopPropagation();" alt="View" /></a>
                </div>
              </div>
              <ul *ngIf="cms1.WorkStreamStructure.length>0|| cms1.CMSLevelTwo.length>0">
                <li class="c-list_content" *ngFor="let ws1 of cms1.WorkStreamStructure;" [attr.data-item]="ws1 | json" [attr.data-wsId]="ws1.WorkstreamId" [attr.data-wsfactoryindicator]="ws1.FactoryWorkstreamIndicator" (click)="SetSelectedValues($event,null,ws1);$event.stopPropagation();">
                  <div class="c-list" *ngIf="ws1.FactoryName == null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws1.IsSelected}">
                    <div class="c-list-moveable">
                      <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                    </div>
                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!='' && ws1.FactoryName == null)">
                      <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws1.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}},&nbsp;<span class="small">{{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}}</span></span>
                    </div>
                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate=='' && ws1.FactoryName == null)">
                      <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws1.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}</span>
                    </div>
                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!='' && ws1.FactoryName == null)">
                      <img class="workstream-img" [attr.data-myw-title]="ws1.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws1.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}})</span></span>
                    </div>
                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate=='' && ws1.FactoryName == null)">
                      <img class="workstream-img" [attr.data-myw-title]="ws1.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws1.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;</span>
                    </div>
                    <div *ngIf="IsEditable" class="c-list-icons">
                      <a href="javascript:;" id="WS{{ws1.WorkstreamId}}" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit" (click)="Edit(null,ws1);$event.stopPropagation();" [ngClass]="{' glyphicon-pencil ':IsEditable}"></a>
                      <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete"
                         *ngIf="ws1.FactoryWorkstreamIndicator != 'Y' && !ws1.PhoenixCreationIndicator && ws1.WorkstreamTypeId != 12"
                         (click)="DeleteWS(ws1)"
                         [ngClass]="{' ':IsEditable }"></a>
                    </div>
                    <div *ngIf="!IsEditable" class="c-list-icons">
                      <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(null,ws1);$event.stopPropagation();" role="presentation" alt="View" /></a>
                    </div>
                  </div>
                  <div class="c-list" *ngIf="ws1.FactoryName != null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws1.IsSelected}">
                    <div class="c-list-moveable">
                      <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                    </div>
                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!=''&& ws1.FactoryName != null)">
                      <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws1.FactoryName}} - {{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}},&nbsp;<span class="small">{{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}}</span></span>
                    </div>
                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate=='' && ws1.FactoryName == null)">
                      <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws1.FactoryName}} - {{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}</span>
                    </div>
                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!='' && ws1.FactoryName == null)">
                      <img class="workstream-img" [attr.data-myw-title]="ws1.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws1.FactoryName}} -  {{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}})</span></span>
                    </div>
                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate=='' && ws1.FactoryName == null)">
                      <img class="workstream-img" [attr.data-myw-title]="ws1.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws1.FactoryName}} - {{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;</span>
                    </div>
                    <div *ngIf="IsEditable" class="c-list-icons">
                      <a href="javascript:;" id="WS{{ws1.WorkstreamId}}" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="Edit(null,ws1);$event.stopPropagation();" [attr.aria-label]="'Edit Factory Workstream'+ '-' + ws1.FactoryName+  '-' + ws1.WorkStreamName +'(opens as popup)'"></a>
                      <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" [ngClass]="{' ':IsEditable }"
                         *ngIf="ws1.FactoryWorkstreamIndicator != 'Y' && !ws1.PhoenixCreationIndicator && ws1.WorkstreamTypeId != 12"
                         (click)="DeleteWS(ws1)"></a>
                    </div>
                    <div *ngIf="!IsEditable" class="c-list-icons">
                      <a href="javascript:;" class="c-view-link" [attr.aria-label]="'View Factory Workstream'+ '-' + ws1.FactoryName+  '-' + ws1.WorkStreamName +'(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(null,ws1);$event.stopPropagation();" role="presentation" alt="View" /></a>
                    </div>
                  </div>
                </li>
                <li class="c-list_content" *ngFor="let cms2 of cms1.CMSLevelTwo; " [ngClass]="{open : (cms2.CMSLevelThree.length <= 0 && cms2.WorkStreamStructure.length <= 0)}" [attr.data-item]="cms2 | json" [attr.data-cmsId]="cms2.CMSId" (click)="SetSelectedValues($event,cms2,null);$event.stopPropagation();" aria-expanded="{{cms2.IsSelected?'true':'false'}}">
                  <div class="c-list cms-tms-item"
                       [ngClass]="{'cms-tms-item-selected' : cms2.IsSelected,'c-list-child' :!(cms2.CMSLevelThree.length == 0 && cms2.WorkStreamStructure.length == 0)}"
                       data-type="CMS">
                    <div class="c-list-moveable">
                      <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                    </div>
                    <div class="c-list-text cms-tms-item-text">
                      <a href="javascript:;" class="icon-arrow-down -expand" aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" *ngIf="cms2.CMSLevelThree.length > 0 || cms2.WorkStreamStructure.length > 0" (click)="expandCMSStructureList($event,cms2.CMSId);$event.stopPropagation();"></a>
                      <span [ngClass]="{'closed-cms' : cms2.CmsStatusId == 2}">{{cms2.CmsStatusId == 2 ? "[CLOSED]" + cms2.CMSName : cms2.CMSName}}</span>
                    </div>
                    <div *ngIf="IsEditable" class="c-list-icons">
                      <a href="javascript:;" class="icon-edit" role="button" data-flyoutid="client-edit" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit" id="CMS{{cms2.CMSId}}"
                         (click)="Edit(cms2,null);$event.stopPropagation();"
                         [attr.aria-label]="'Edit CMS' + '-'+ cms2.CMSName +'(opens as popup)'"
                         [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }"></a>
                      <a href="javascript:;" class="icon-delete" role="button" *ngIf="!cms2.PhoenixMapping.IsPhoenixMappingAdded" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"
                         (click)="Delete(cms2,'cms',$event);$event.stopPropagation();" [attr.aria-label]="'Delete this CMS -' + cms2.CMSName +'(opens as popup)'"></a>
                    </div>
                    <!--<div class="c-list-icons" *ngIf="IsEditable && !cms2.PhoenixMapping.IsPhoenixMappingAdded">
                    <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"
                       (click)="Delete(cms2,'cms',$event);$event.stopPropagation();" [attr.aria-label]="'Delete this CMS -' + cms2.CMSName +'(opens as popup)'"></a>
                  </div>-->
                    <div *ngIf="!IsEditable" class="c-list-icons">
                      <a href="javascript:;" class="c-view-link" [attr.aria-label]="'View CMS' + '-'+ cms2.CMSName +'(opens as popup)'" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(cms2,null);$event.stopPropagation();" role="presentation" alt="View" /></a>
                    </div>
                  </div>
                  <ul *ngIf="cms2.CMSLevelThree.length > 0  || cms2.WorkStreamStructure.length > 0">
                    <li class="c-list_content" *ngFor="let ws2 of cms2.WorkStreamStructure; " [attr.data-item]="ws2 | json" [attr.data-wsId]="ws2.WorkstreamId" [attr.data-wsfactoryindicator]="ws2.FactoryWorkstreamIndicator" (click)="SetSelectedValues($event,null,ws2);$event.stopPropagation();">
                      <div class="c-list cms-tms-item" *ngIf="ws2.FactoryName == null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws2.IsSelected}">
                        <div class="c-list-moveable">
                          <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                        </div>
                        <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!='' && ws2.FactoryName == null)">
                          <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws2.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}},&nbsp;<span class="small">{{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}}</span></span>
                        </div>
                        <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='' && ws2.FactoryName == null)">
                          <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws2.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}</span>
                        </div>
                        <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!='' && ws2.FactoryName == null)">
                          <img class="workstream-img" [attr.data-myw-title]="ws2.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws2.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws2.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}})</span></span>
                        </div>
                        <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='' && ws2.FactoryName == null)">
                          <img class="workstream-img" [attr.data-myw-title]="ws2.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws2.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws2.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;</span>
                        </div>
                        <div *ngIf="IsEditable" class="c-list-icons">
                          <a href="javascript:;" id="WS{{ws2.WorkstreamId}}" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit" (click)="Edit(null,ws2);$event.stopPropagation();"
                             [ngClass]="{' glyphicon-pencil ':IsEditable }"></a>
                          <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete"
                             *ngIf="ws2.FactoryWorkstreamIndicator != 'Y' && !ws2.PhoenixCreationIndicator && ws2.WorkstreamTypeId != 12"
                             (click)="DeleteWS(ws2)"></a>
                        </div>
                        <div *ngIf="!IsEditable" class="c-list-icons">
                          <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(null,ws2);$event.stopPropagation();" role="presentation" alt="View" /></a>
                        </div>
                      </div>
                      <div class="c-list cms-tms-item" *ngIf="ws2.FactoryName != null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws2.IsSelected}">
                        <div class="c-list-moveable cms-tms-item-handle">
                          <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                        </div>
                        <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!=''&& ws2.FactoryName != null)">
                          <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws2.FactoryName}} - {{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}},&nbsp;<span class="small">{{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}}</span></span>
                        </div>
                        <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='' && ws2.FactoryName == null)">
                          <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws2.FactoryName}} - {{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}</span>
                        </div>
                        <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!='' && ws2.FactoryName == null)">
                          <img class="workstream-img" [attr.data-myw-title]="ws2.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws2  .WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws2.FactoryName}} -  {{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}})</span></span>
                        </div>
                        <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='' && ws2.FactoryName == null)">
                          <img class="workstream-img" [attr.data-myw-title]="ws2.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws2.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws2.FactoryName}} - {{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;</span>
                        </div>
                        <div *ngIf="IsEditable" class="c-list-icons">
                          <a href="javascript:;" id="WS{{ws2.WorkstreamId}}" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" [attr.aria-label]="'Edit Factory Workstream'+ '-' + ws2.FactoryName + '-' + ws2.WorkStreamName + '(opens as popup)'"
                             (click)="Edit(null,ws2);$event.stopPropagation();"></a>
                          <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete"
                             *ngIf="ws2.FactoryWorkstreamIndicator != 'Y' && !ws2.PhoenixCreationIndicator && ws2.WorkstreamTypeId != 12"
                             (click)="DeleteWS(ws2)"></a>
                        </div>
                        <div *ngIf="!IsEditable" class="c-list-icons">
                          <a href="javascript:;" class="c-view-link" [attr.aria-label]="'View Factory Workstream'+ '-' + ws2.FactoryName + '-' + ws2.WorkStreamName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(null,ws2);$event.stopPropagation();" role="presentation" alt="View" /></a>
                        </div>
                      </div>
                    </li>
                    <li class="c-list_content" *ngFor="let cms3 of cms2.CMSLevelThree; " [ngClass]="{open :  (cms3.CMSLevelFour.length <= 0 && cms3.WorkStreamStructure.length <= 0)}" [attr.data-item]="cms3 | json" [attr.data-cmsId]="cms3.CMSId" (click)="SetSelectedValues($event,cms3,null);$event.stopPropagation();" aria-expanded="{{cms3.IsSelected?'true':'false'}}">
                      <div class="c-list cms-tms-item"
                           [ngClass]="{'cms-tms-item-selected' : cms3.IsSelected,'c-list-child' : !(cms3.CMSLevelFour.length == 0 && cms3.WorkStreamStructure.length == 0)}"
                           data-type="CMS">
                        <div class="c-list-moveable cms-tms-item-handle">
                          <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                        </div>
                        <div class="c-list-text cms-tms-item-text">
                          <a href="javascript:;" class="icon-arrow-down -expand" aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom"
                             *ngIf="cms3.CMSLevelFour.length > 0 || cms3.WorkStreamStructure.length > 0"
                             (click)="expandCMSStructureList($event,cms3.CMSId);$event.stopPropagation();"></a>
                          <span [ngClass]="{'closed-cms' : cms3.CmsStatusId == 2}">{{cms3.CmsStatusId == 2 ? "[CLOSED]" + cms3.CMSName : cms3.CMSName}}</span>
                        </div>
                        <div *ngIf="IsEditable" class="c-list-icons">
                          <a href="javascript:;" class="icon-edit" role="button" data-flyoutid="client-edit" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" id="CMS{{cms3.CMSId}}"
                             (click)="Edit(cms3,null);$event.stopPropagation();"
                             [attr.aria-label]="'Edit CMS'+'-' + cms3.CMSName+'(opens as popup)'"
                             [ngClass]="{' glyphicon-pencil ':IsEditable}"></a>
                          <a href="javascript:;" class="icon-delete" role="button" *ngIf="!cms3.PhoenixMapping.IsPhoenixMappingAdded" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" (click)="Delete(cms3,'cms',$event);$event.stopPropagation();" [attr.aria-label]="'Delete this CMS -' + cms3.CMSName +'(opens as popup)'"></a>
                        </div>
                        <!--<div class="c-list-icons" *ngIf="IsEditable && !cms3.PhoenixMapping.IsPhoenixMappingAdded">
                        <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"aria-label="Delete" (click)="Delete(cms3,'cms',$event);$event.stopPropagation();" [attr.aria-label]="'Delete this CMS -' + cms3.CMSName +'(opens as popup)'"></a>
                      </div>-->
                        <div *ngIf="!IsEditable" class="c-list-icons">
                          <a href="javascript:;" class="c-view-link" [attr.aria-label]="'View CMS' +'-' + cms3.CMSName+'(opens as popup)'"
                             [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(cms3,null);$event.stopPropagation();" role="presentation" alt="View" /></a>
                        </div>
                      </div>
                      <ul *ngIf="(cms3.CMSLevelFour.length > 0 || cms3.WorkStreamStructure.length > 0)">
                        <li class="c-list_content" *ngFor="let ws3 of cms3.WorkStreamStructure;" [attr.data-item]="ws3 | json" [attr.data-wsId]="ws3.WorkstreamId" [attr.data-wsfactoryindicator]="ws3.FactoryWorkstreamIndicator" (click)="SetSelectedValues($event,null,ws3);$event.stopPropagation();">
                          <div class="c-list" *ngIf="ws3.FactoryName == null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws3.IsSelected}">
                            <div class="c-list-moveable">
                              <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                            </div>
                            <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!='' && ws3.FactoryName == null)">
                              <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws3.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}},&nbsp;<span class="small">{{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}}</span></span>
                            </div>
                            <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='' && ws3.FactoryName == null)">
                              <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws3.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}</span>
                            </div>
                            <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!='' && ws3.FactoryName == null)">
                              <img class="workstream-img" [attr.data-myw-title]="ws3.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws3.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws3.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}})</span></span>
                            </div>
                            <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='' && ws3.FactoryName == null)">
                              <img class="workstream-img" [attr.data-myw-title]="ws3.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws3.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws3.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;</span>
                            </div>
                            <div *ngIf="IsEditable" class="c-list-icons">
                              <a href="javascript:;" id="WS{{ws3.WorkstreamId}}" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"
                                 [attr.aria-label]="'Edit Workstream'+ '-' + ws3.WorkStreamName + '(opens as popup)'"
                                 (click)="Edit(null,ws3);$event.stopPropagation();"
                                 [ngClass]="{' glyphicon-pencil ':IsEditable}"></a>
                              <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" [ngClass]="{' ':IsEditable }"
                                 *ngIf="ws3.FactoryWorkstreamIndicator != 'Y' && !ws3.PhoenixCreationIndicator && ws3.WorkstreamTypeId != 12"
                                 (click)="DeleteWS(ws3)"></a>
                            </div>
                            <div *ngIf="!IsEditable" class="c-list-icons">
                              <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" [attr.aria-label]="IsEditable?'Edit Workstream':'View Workstream'+ '-' + ws3.WorkStreamName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(null,ws3);$event.stopPropagation();" role="presentation" alt="View" /></a>
                            </div>
                          </div>
                          <div class="c-list" *ngIf="ws3.FactoryName != null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws3.IsSelected}">
                            <div class="c-list-moveable">
                              <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                            </div>
                            <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!=''&& ws3.FactoryName != null)">
                              <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws3.FactoryName}} - {{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}},&nbsp;<span class="small">{{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}}</span></span>
                            </div>
                            <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='' && ws3.FactoryName == null)">
                              <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws3.FactoryName}} - {{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}</span>
                            </div>
                            <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!='' && ws3.FactoryName == null)">
                              <img class="workstream-img" [attr.data-myw-title]="ws3.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws3  .WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws3.FactoryName}} -  {{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}})</span></span>
                            </div>
                            <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='' && ws3.FactoryName == null)">
                              <img class="workstream-img" [attr.data-myw-title]="ws3.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws3.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws3.FactoryName}} - {{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;</span>
                            </div>
                            <div *ngIf="IsEditable" class="c-list-icons">
                              <a href="javascript:;" id="WS{{ws3.WorkstreamId}}" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"
                                 [attr.aria-label]="'Edit Factory Workstream' + '-' + ws3.FactoryName+ '-' + ws3.WorkStreamName +'(opens as popup)'"
                                 (click)="Edit(null,ws3);$event.stopPropagation();"></a>
                              <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" [ngClass]="{' ':IsEditable }"
                                 *ngIf=" ws3.FactoryWorkstreamIndicator != 'Y' && !ws3.PhoenixCreationIndicator && ws3.WorkstreamTypeId != 12"
                                 (click)="DeleteWS(ws3)"></a>
                            </div>
                            <div *ngIf="!IsEditable" class="c-list-icons">
                              <a href="javascript:;" class="c-view-link" [attr.aria-label]="'View Factory Workstream' + '-' + ws3.FactoryName+ '-' + ws3.WorkStreamName +'(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(null,ws3);$event.stopPropagation();" role="presentation" alt="View" /></a>
                            </div>
                          </div>
                        </li>
                        <li class="c-list_content" *ngFor="let cms4 of cms3.CMSLevelFour;" [ngClass]="{open : (cms4.CMSLevelFive.length <= 0 && cms4.WorkStreamStructure.length <= 0)}" [attr.data-item]="cms4 | json" [attr.data-cmsId]="cms4.CMSId" (click)="SetSelectedValues($event,cms4,null);$event.stopPropagation();" aria-expanded="{{cms4.IsSelected?'true':'false'}}">
                          <div class="c-list cms-tms-item"
                               [ngClass]="{'cms-tms-item-selected' : cms4.IsSelected,'c-list-child' : !(cms4.CMSLevelFive.length == 0 && cms4.WorkStreamStructure.length == 0) }"
                               data-type="CMS">
                            <div class="c-list-moveable">
                              <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                            </div>
                            <div class="c-list-text cms-tms-item-text">
                              <a href="javascript:;" *ngIf="cms4.CMSLevelFive.length > 0 || cms4.WorkStreamStructure.length > 0" class="icon-arrow-down -expand" aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="expandCMSStructureList($event,cms4.CMSId);$event.stopPropagation();"></a>
                              <span [ngClass]="{'closed-cms' : cms4.CmsStatusId == 2}">{{cms4.CmsStatusId == 2 ? "[CLOSED]" + cms4.CMSName : cms4.CMSName}}</span>
                            </div>
                            <div *ngIf="IsEditable" class="c-list-icons">
                              <a href="javascript:;" id="CMS{{cms4.CMSId}}" class="icon-edit" role="button"
                                 data-flyoutid="client-edit" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"
                                 (click)="Edit(cms4,null);$event.stopPropagation();"
                                 [ngClass]="{' glyphicon-pencil ':IsEditable}"
                                 [attr.aria-label]="'Edit CMS'+  '-' + cms4.CMSName + '(opens as popup)'"></a>
                              <a href="javascript:;"
                                 class="icon-delete" *ngIf="!cms4.PhoenixMapping.IsPhoenixMappingAdded" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"
                                 (click)="Delete(cms4,'cms',$event);$event.stopPropagation();" [attr.aria-label]="'Delete this CMS -' + cms4.CMSName +'(opens as popup)'"></a>
                            </div>
                            <!--<div class="c-list-icons">
                            <a href="javascript:;" *ngIf="IsEditable && !cms4.PhoenixMapping.IsPhoenixMappingAdded"
                               class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"
                               (click)="Delete(cms4,'cms',$event);$event.stopPropagation();" [attr.aria-label]="'Delete this CMS -' + cms4.CMSName +'(opens as popup)'"></a>
                          </div>-->
                            <div *ngIf="!IsEditable" class="c-list-icons">
                              <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }"
                                 [attr.aria-label]="'View CMS'+  '-' + cms4.CMSName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(cms4,null);$event.stopPropagation();" role="presentation" alt="View" /></a>
                            </div>
                          </div>
                          <ul *ngIf=" cms4.CMSLevelFive.length > 0 || cms4.WorkStreamStructure.length > 0">
                            <li class="c-list_content" *ngFor="let ws4 of cms4.WorkStreamStructure; " [attr.data-item]="ws4 | json" [attr.data-wsId]="ws4.WorkstreamId" [attr.data-wsfactoryindicator]="ws4.FactoryWorkstreamIndicator" (click)="SetSelectedValues($event,null,ws4);$event.stopPropagation();">
                              <div class="c-list" *ngIf="ws4.FactoryName == null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws4.IsSelected}">
                                <div class="c-list-moveable">
                                  <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                </div>
                                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!='' && ws4.FactoryName == null)">
                                  <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws4.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}},&nbsp;<span class="small">{{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}}</span></span>
                                </div>
                                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='' && ws4.FactoryName == null)">
                                  <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws4.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}</span>
                                </div>
                                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!='' && ws4.FactoryName == null)">
                                  <img class="workstream-img" [attr.data-myw-title]="ws4.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws4.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws4.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}})</span></span>
                                </div>
                                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='' && ws4.FactoryName == null)">
                                  <img class="workstream-img" [attr.data-myw-title]="ws4.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws4.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws4.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;</span>
                                </div>
                                <div *ngIf="IsEditable" class="c-list-icons">
                                  <a href="javascript:;" id="WS{{ws4.WorkstreamId}}" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"
                                     [attr.aria-label]="'Edit Workstream' + '-' + ws4.WorkStreamName + '(opens as popup)'"
                                     (click)="Edit(null,ws4);$event.stopPropagation();"
                                     [ngClass]="{' glyphicon-pencil ':IsEditable}"></a>
                                  <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" [ngClass]="{' ':IsEditable }"
                                     *ngIf="ws4.FactoryWorkstreamIndicator != 'Y' && !ws4.PhoenixCreationIndicator && ws4.WorkstreamTypeId != 12"
                                     (click)="DeleteWS(ws4)"></a>
                                </div>
                                <div *ngIf="!IsEditable" class="c-list-icons">
                                  <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" [attr.aria-label]="IsEditable?'Edit Workstream':'View Workstream' + '-' + ws4.WorkStreamName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(null,ws4);$event.stopPropagation();" role="presentation" alt="View" /></a>
                                </div>
                              </div>
                              <div class="c-list " *ngIf="ws4.FactoryName != null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws4.IsSelected}">
                                <div class="c-list-moveable">
                                  <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                </div>
                                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!=''&& ws4.FactoryName != null)">
                                  <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws4.FactoryName}} - {{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}},&nbsp;<span class="small">{{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}}</span></span>
                                </div>
                                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='' && ws4.FactoryName == null)">
                                  <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws4.FactoryName}} - {{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}</span>
                                </div>
                                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!='' && ws4.FactoryName == null)">
                                  <img class="workstream-img" [attr.data-myw-title]="ws4.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws4  .WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws4.FactoryName}} -  {{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}})</span></span>
                                </div>
                                <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='' && ws4.FactoryName == null)">
                                  <img class="workstream-img" [attr.data-myw-title]="ws4.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws4.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws4.FactoryName}} - {{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;</span>
                                </div>
                                <div *ngIf="IsEditable" class="c-list-icons">
                                  <a href="javascript:;" id="WS{{ws4.WorkstreamId}}" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"
                                     [attr.aria-label]="'Edit Factory Workstream'+ ' - ' + ws4.FactoryName + '-' + ws4.WorkStreamName+ '(opens as popup)'"
                                     (click)="Edit(null,ws4);$event.stopPropagation();"></a>
                                  <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" [ngClass]="{' ':IsEditable }"
                                     *ngIf="ws4.FactoryWorkstreamIndicator != 'Y' && !ws4.PhoenixCreationIndicator && ws4.WorkstreamTypeId != 12"
                                     (click)="DeleteWS(ws4)"></a>
                                </div>
                                <div *ngIf="!IsEditable" class="c-list-icons">
                                  <a href="javascript:;" class="c-view-link" [attr.aria-label]="'View Factory Workstream'+ ' - ' + ws4.FactoryName + '-' + ws4.WorkStreamName+ '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(null,ws4);$event.stopPropagation();" role="presentation" alt="View" /></a>
                                </div>
                              </div>
                            </li>
                            <li class="c-list_content" *ngFor="let cms5 of cms4.CMSLevelFive; " [ngClass]="{open : (cms5.WorkStreamStructure.length <= 0)}" [attr.data-item]="cms5 | json" [attr.data-cmsId]="cms5.CMSId" (click)="SetSelectedValues($event,cms5,null);$event.stopPropagation();" aria-expanded="{{cms5.IsSelected?'true':'false'}}">
                              <div class="c-list cms-tms-item" [ngClass]="{'cms-tms-item-selected' : cms5.IsSelected,'c-list-child' : cms5.WorkStreamStructure.length >0}" data-type="CMS">
                                <div class="c-list-moveable">
                                  <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                </div>
                                <div class="c-list-text cms-tms-item-text">
                                  <a href="javascript:;" *ngIf=" cms5.WorkStreamStructure.length > 0" class="icon-arrow-down -expand" aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="expandCMSStructureList($event,cms5.CMSId);$event.stopPropagation();"></a>
                                  <span [ngClass]="{'closed-cms' : cms5.CmsStatusId == 2}">{{cms5.CmsStatusId == 2 ? "[CLOSED]" + cms5.CMSName : cms5.CMSName}}</span>
                                </div>
                                <div *ngIf="IsEditable" class="c-list-icons">
                                  <a href="javascript:;" id="CMS{{cms5.CMSId}}" class="icon-edit" role="button"
                                     data-flyoutid="client-edit" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"
                                     [ngClass]="{' glyphicon-pencil ':IsEditable}"
                                     (click)="Edit(cms5,null);$event.stopPropagation();"
                                     [attr.aria-label]="'Edit CMS'+  '-' + cms5.CMSName + '(opens as popup)'"></a>

                                  <a href="javascript:;" class="icon-delete" role="button" *ngIf="!cms5.PhoenixMapping.IsPhoenixMappingAdded" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"
                                     (click)="Delete(cms5,'cms',$event);$event.stopPropagation();"
                                     [attr.aria-label]="'Delete this CMS -' + cms5.CMSName +'(opens as popup)'"></a>
                                </div>
                                <!--<div class="c-list-icons">
                                <a href="javascript:;" *ngIf="IsEditable && !cms5.PhoenixMapping.IsPhoenixMappingAdded" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"
                                   (click)="Delete(cms5,'cms',$event);$event.stopPropagation();"
                                   [attr.aria-label]="'Delete this CMS -' + cms5.CMSName +'(opens as popup)'"></a>
                              </div>-->
                                <div *ngIf="!IsEditable" class="c-list-icons">
                                  <a href="javascript:;" class="c-view-link" [attr.aria-label]="'View CMS'+  '-' + cms5.CMSName + '(opens as popup)'" [ngClass]="{' glyphicon-pencil ':IsEditable,'glyphicon-binacular pull-right' : !IsEditable }" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(cms5,null);$event.stopPropagation();" role="presentation" alt="View" /></a>
                                </div>
                              </div>
                              <ul *ngIf="cms5.WorkStreamStructure.length >0">
                                <li class="c-list_content" *ngFor="let ws5 of cms5.WorkStreamStructure; " [attr.data-item]="ws5 | json" [attr.data-wsId]="ws5.WorkstreamId"
                                    [attr.data-wsfactoryindicator]="ws5.FactoryWorkstreamIndicator"
                                    (click)="SetSelectedValues($event,null,ws5);$event.stopPropagation();">
                                  <div class="c-list" *ngIf="ws5.FactoryName == null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws5.IsSelected}">
                                    <div class="c-list-moveable">
                                      <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                    </div>
                                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!='' && ws5.FactoryName == null)">
                                      <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws5.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}},&nbsp;<span class="small">{{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}}</span></span>
                                    </div>
                                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='' && ws5.FactoryName == null)">
                                      <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws5.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}</span>
                                    </div>
                                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!='' && ws5.FactoryName == null)">
                                      <img class="workstream-img" [attr.data-myw-title]="ws5.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws5.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws5.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}})</span></span>
                                    </div>
                                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='' && ws5.FactoryName == null)">
                                      <img class="workstream-img" [attr.data-myw-title]="ws5.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws5.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws5.IsDraftMode == true ?  "[Draft]":""}}&nbsp;{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;</span>
                                    </div>
                                    <div *ngIf="IsEditable" class="c-list-icons">
                                      <a href="javascript:;" id="WS{{ws5.WorkstreamId}}"
                                         class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"
                                         [attr.aria-label]="'Edit Workstream'+ '-' + ws5.WorkStreamName + '(opens as popup)'"
                                         (click)="Edit(null,ws5);$event.stopPropagation();"
                                         [ngClass]="{' glyphicon-pencil ':IsEditable}"></a>
                                      <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" [ngClass]="{' ':IsEditable }"
                                         *ngIf="ws5.FactoryWorkstreamIndicator != 'Y' && !ws5.PhoenixCreationIndicator && ws5.WorkstreamTypeId != 12"
                                         (click)="DeleteWS(ws5)"></a>
                                    </div>
                                    <div *ngIf="!IsEditable" class="c-list-icons">
                                      <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" [attr.aria-label]="'View Workstream'+ '-' + ws5.WorkStreamName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(null,ws5);$event.stopPropagation();" role="presentation" alt="View" /></a>
                                    </div>
                                  </div>
                                  <div class="c-list" *ngIf="ws5.FactoryName != null" data-type="Workstream" [ngClass]="{'cms-tms-item-selected' : ws5.IsSelected}">
                                    <div class="c-list-moveable">
                                      <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                    </div>
                                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!=''&& ws5.FactoryName != null)">
                                      <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws5.FactoryName}} - {{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}},&nbsp;<span class="small">{{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}}</span></span>
                                    </div>
                                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='' && ws5.FactoryName == null)">
                                      <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span></span><span>{{ws5.FactoryName}} - {{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}</span>
                                    </div>
                                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!='' && ws5.FactoryName == null)">
                                      <img class="workstream-img" [attr.data-myw-title]="ws5.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws5  .WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws5.FactoryName}} -  {{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}})</span></span>
                                    </div>
                                    <div class="c-list-text cms-tms-item-text" [ngClass]="{'closed-workstream -disabled' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='' && ws5.FactoryName == null)">
                                      <img class="workstream-img" [attr.data-myw-title]="ws5.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws5.WorkstreamTypeId)" alt="Agile"><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws5.FactoryName}} - {{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;</span>
                                    </div>
                                    <div *ngIf="IsEditable" class="c-list-icons">
                                      <a href="javascript:;" id="WS{{ws5.WorkstreamId}}"
                                         class="icon-edit"
                                         role="button"
                                         data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"
                                         [attr.aria-label]="'Edit Factory Workstream' + '-' + ws5.FactoryName + '-' + ws5.WorkStreamName + '(opens as popup)'"
                                         (click)="Edit(null,ws5);$event.stopPropagation();"></a>
                                      <a href="javascript:;" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" [ngClass]="{' ':IsEditable }"
                                         *ngIf="ws5.FactoryWorkstreamIndicator != 'Y' && !ws5.PhoenixCreationIndicator && ws5.WorkstreamTypeId != 12"
                                         (click)="DeleteWS(ws5)"></a>
                                    </div>
                                    <div *ngIf="!IsEditable" class="c-list-icons">
                                      <a href="javascript:;" class="c-view-link" [attr.aria-label]="'View Factory Workstream' + '-' + ws5.FactoryName + '-' + ws5.WorkStreamName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="Edit(null,ws5);$event.stopPropagation();" role="presentation" alt="View" /></a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                  </ul>
                </li>


              </ul>
            </li>

          </ul>
        </div>
      </div>

    </div>


  </div>
  <div class="c-myWizard-update__container " *ngIf="IsShowModifiedDetails">
    <div class="c-myWizard-container">
      <div class="c-myWizard-update__row">
        <div class="c-myWizard-update__details">
          <div class="c-myWizard-update__input">
            <label>Last Updated On:</label>
            <span class="-input">{{cmsHierarchy.LastModifiedByOnForCMS |date:'dd MMM yyyy h:mm a'}} (GMT)</span>
          </div>
          <div class="c-myWizard-update__input">
            <label>Last Updated By:</label>
            <span class="-input">{{cmsHierarchy.LastModifiedByUserForCMS}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="c-select-card" *ngIf="(cmsHierarchy.WorkStreamStructure == undefined || cmsHierarchy.WorkStreamStructure == null ? false : cmsHierarchy.WorkStreamStructure.length > 0) || (cmsHierarchy.CMSLevelOne == undefined || cmsHierarchy.CMSLevelOne == null ? false : cmsHierarchy.CMSLevelOne.length > 0)">
    <div class="app-container">
      <div class="c-select-card__wrapper">
        <div class="c-select-card__left">
          <div class="c-select-card__text">Changes made will be lost unless confirmed. Please click 'Save' button to confirm.</div>
          <!--<div class="c-select-card__text">Are you sure you want to move workstream from CMS2 to individual work stream</div>-->
        </div>
        <div class="c-select-card__right">
          <button class="o-secondary-button" role="button" [ngClass]="{'-disabled':!IsEditable || (!DraggedItems || DraggedItems.length <=0)}" (click)="CancelDraggAndDrop()">Reset</button>
          <button class="o-primary-button " role="button" [ngClass]="{'-disabled':!IsEditable || (!DraggedItems || DraggedItems.length <=0)}" *ngIf="(cmsHierarchy.WorkStreamStructure == undefined || cmsHierarchy.WorkStreamStructure == null ? false : cmsHierarchy.WorkStreamStructure.length > 0) || (cmsHierarchy.CMSLevelOne == undefined || cmsHierarchy.CMSLevelOne == null ? false : cmsHierarchy.CMSLevelOne.length > 0)" (click)="SaveDraggedCMS()">Save</button>
        </div>
      </div>
    </div>
  </div>
  <div class="c-select-card__placeholder" *ngIf="(cmsHierarchy.WorkStreamStructure == undefined || cmsHierarchy.WorkStreamStructure == null ? false : cmsHierarchy.WorkStreamStructure.length > 0) || (cmsHierarchy.CMSLevelOne == undefined || cmsHierarchy.CMSLevelOne == null ? false : cmsHierarchy.CMSLevelOne.length > 0)"></div>

  <!--<div class="modal" id="alertPopUp" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog-wrap">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" id="cms_alertpopup" class="close" data-dismiss="modal" aria-label="Close" (click)="CloseAlert()"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Alert</h4>
          </div>
          <div class="modal-body">
            {{Message}}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close" (click)="CloseAlert()">Close</button>
          </div>
        </div>
      </div>
    </div>
    <span tabindex="0" onfocus="document.getElementById('cms_alertpopup').focus();"></span>
  </div>-->

</form>

