import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as Enumerable from 'linq';
import { BsModalRef } from 'ngx-bootstrap/modal';
import cloneDeep from 'lodash/cloneDeep';
import { enumEntityType } from '../../../core/context/enum';
import { DemographicsService } from '../../../core/services/demographics.service';
import { SharedService } from '../../../core/services/shared.service';
import { SessionStorage } from '../../../core/context/sessionstorage';
var $ = (<any>window).$;
declare var window: any;
@Component({
  selector: 'metro-cities',
  templateUrl: './metro.cities.component.html',
  providers: [DemographicsService]
})
export class MetroCitiesComponent {

  constructor(private bsModalRef: BsModalRef,
    private demographicsService: DemographicsService,
    private sharedService: SharedService,
    private sessionStorage: SessionStorage) { }

  enterpriseId: any;
  KeyContactsDetails: any;
  @Input()
  deliveryCenter: any;
  @Input()
  RoleAccess: string;
  @Input()
  E2eDemoGraphics: any;

  @Output() 
  MetroCityDetailsOutputEmitter = new EventEmitter();  // .. Child Popup -> Parent
  @Output()
  MetroCityFormValidEmitter = new EventEmitter();  // .. Child Popup -> Parent


  CountryList: any;
  action: any="Add";
  MetroCityList: any;
  FacilityList: any;
  InteractionStyles: any;
  EndtoEndDeliveryCenterId: any;
  GdnType = "ATC";
  ModalTitle: string;
  IsATC = true;
  IsSaveButtonDisplay: boolean = false;
  IsFacilityDisplay: boolean;
  MetroCity: any;
  Country: any;
  index: any;
  isDisplay: boolean = false;
  tempPortfolioMetroCities: any;
  InteractionStyleId: any = "";
  currentMetroCities: any;
  CountryId: any = "";
  MetroCityId: any = "";
  FacilityId: any = "";

  public metroCityDetailsEventEmitter: EventEmitter<any> = new EventEmitter();  // .. Child Popup -> Parent

  ngOnInit() {
    //$('.modal-header button').focus();
    this.enterpriseId = this.sessionStorage.enterpriseId;
    this.MetroCityInfo(this.deliveryCenter.EndToEndDeliveryCenterId, this.deliveryCenter.DcCountryName, this.deliveryCenter.AccountGroup,
      this.deliveryCenter.DeliveryGroup, this.deliveryCenter.DeliveryUnit, this.deliveryCenter.DcCountryId);
      setTimeout(function () {
        window.TooltipFunc();
      }, 0);
  }



  MetroCityInfo(endtoendDeliveryCenterId, portfoio, accountGroup, deliveryGroup, deliveryUnit, dcCountryId) {
    this.CountryList = [];
    this.MetroCityList = [];
    this.FacilityList = [];
    this.InteractionStyles = [];
    this.EndtoEndDeliveryCenterId = endtoendDeliveryCenterId;
    if (dcCountryId == null) {
      this.GdnType = "ATC";
      this.ModalTitle = portfoio + ' | ' + accountGroup + ' | ' + deliveryGroup + ' | ' + deliveryUnit;
      this.IsATC = true;
    }
    else if (dcCountryId == 998) {
      this.GdnType = "CSG";
      this.ModalTitle = portfoio;
      this.IsATC = false;
    }
    else if (dcCountryId == 997) {
      this.GdnType = "AGU";
      this.ModalTitle = portfoio;
      this.IsATC = false;
    }
    this.demographicsService.getMetroCityDetails(endtoendDeliveryCenterId, this.GdnType, this.E2eDemoGraphics.E2EId).subscribe((response: any) => {
      this.IsSaveButtonDisplay = true;
      this.IsFacilityDisplay = true;
      this.MetroCity = response;
      this.CountryList = cloneDeep(this.MetroCity.CountryList);
      this.InteractionStyles = cloneDeep(this.MetroCity.InteractionStyles);
      setTimeout(function () {
        window.TooltipFunc();
      }, 0);
      if (this.IsATC) {
        this.Country = { Id: '' };
        var country = Enumerable.from(this.MetroCity.CountryList).where((x: any) => x.Name == portfoio).firstOrDefault();
        this.Country = country;
        if (this.Country == undefined) {
          this.Country = { Id: '' };
          this.IsFacilityDisplay = false;
        }
        else {
          this.MetroCityList = Enumerable.from(this.MetroCity.MetroCityList).where((x: any) => x.ParentId == this.Country.Id).toArray();
        }
      }
      else {
        this.Country = { Id: '' };
      }
      if (this.MetroCity.PortfolioMetroCities.length > 0) {
        this.MetroCity.PortfolioMetroCities.forEach((item: any) => {
          var metroCity: any = Enumerable.from(this.MetroCity.MetroCityList).where((x: any) => x.Id == item.MetroCityId).firstOrDefault();
          if (item.FacilityId) {
            var facility: any = Enumerable.from(this.MetroCity.FacilityList).where((x: any) => x.Id == item.FacilityId).firstOrDefault();
          } else { item.FacilityId = '' }
          if (item.InteractionStyleId) {
            var interactionStyle: any = Enumerable.from(this.MetroCity.InteractionStyles).where((x: any) => x.Id == item.InteractionStyleId).firstOrDefault();
          } else { item.InteractionStyleId = '' }

          if (this.IsATC) {
            item.CountryId = this.Country.Id;
            item.Country = this.Country.Name;
          }
          else {
            var country: any = Enumerable.from(this.MetroCity.CountryList).where((x: any) => x.Id == item.CountryId).firstOrDefault();
            item.CountryId = country.Id;
            item.Country = country.Name;
          }
          item.MetroCityName = metroCity.Name;
          item.Facility = item.FacilityId ? facility.Name : "";
          item.InteractionStyle = item.InteractionStyleId ? interactionStyle.Name : "";
        });
      }
      else {
        this.IsSaveButtonDisplay = false;
      }
      this.tempPortfolioMetroCities = cloneDeep(this.MetroCity.PortfolioMetroCities);
    });
  }

  CountryChange(selectedId) {
    this.MetroCityList = Enumerable.from(this.MetroCity.MetroCityList).where((x: any) => x.ParentId == selectedId).toArray();
    this.FacilityList = [];
  }
  MetroCityChange(selectedId) {
    this.FacilityList = Enumerable.from(this.MetroCity.FacilityList).where((x: any) => x.ParentId == selectedId).toArray();
  }
  InteractionStyleChange(selectedId) {
    if (selectedId == 3) {
      this.sharedService.openAlertPopup("Facility will be disabled for client site", window.scrollY);
    }
  }

  AddMetroCity(countryId, metroCityId, facilityId, interactionStyleId, metrocitiesaddeditform) {
    metrocitiesaddeditform.submitted = true;
    if (!countryId || !metroCityId || (interactionStyleId != 3 && !facilityId) || !metrocitiesaddeditform.valid) {
      this.MetroCityFormValidEmitter.emit(metrocitiesaddeditform.valid);
      return false;
    }
    this.MetroCityFormValidEmitter.emit(metrocitiesaddeditform.valid);

    var rowExist = Enumerable.from(this.MetroCity.PortfolioMetroCities).where((x: any) => x.CountryId == countryId && x.MetroCityId == metroCityId && x.FacilityId == facilityId && x.InteractionStyleId == interactionStyleId && x.IsMarkedForDeletion != true).select((s:any) => s ).firstOrDefault();
    if (rowExist != undefined) {
        var currentMetroCity = this.MetroCity.PortfolioMetroCities[this.index];
        if(currentMetroCity != rowExist){
      this.sharedService.openAlertPopup("Selected combination already exists", window.scrollY);
      this.ResetAddMetroCityRow();

      return false;
      }
    }
    var country: any = Enumerable.from(this.MetroCity.CountryList).where((x: any) => x.Id == countryId).firstOrDefault();
    var metroCity: any = Enumerable.from(this.MetroCityList).where((x: any) => x.Id == metroCityId).firstOrDefault();
    if (facilityId) {
      var facility: any = Enumerable.from(this.FacilityList).where((x: any) => x.Id == facilityId).firstOrDefault();
    }
    if (interactionStyleId) {
      var interactionStyle: any = Enumerable.from(this.InteractionStyles).where((x: any) => x.Id == interactionStyleId).firstOrDefault();
    }
    if (this.action == "Add") {
      var defaultMetroCity = {
        EndtoEndDeliveryCenterId: this.EndtoEndDeliveryCenterId,
        EndToEndId: this.E2eDemoGraphics.E2EId,
        EndToEndMetroCityFacilityUId: null,
        GDNType: this.GdnType,
        Country: country.Name,
        CountryId: country.Id,
        MetroCityName: metroCity.Name,
        MetroCityId: metroCity.Id,
        Facility: facilityId ? facility.Name : "",
        FacilityId: facilityId ? facility.Id : "",
        InteractionStyle: interactionStyleId ? interactionStyle.Name : "",
        InteractionStyleId: interactionStyleId ? interactionStyle.Id : '',
        IsMarkedForDeletion: false
      }

      this.MetroCity.PortfolioMetroCities.push(defaultMetroCity);
      this.IsSaveButtonDisplay = true;
    }
    else {
      var metroCityEditValue = this.MetroCity.PortfolioMetroCities[this.index];
      metroCityEditValue.MetroCityId = this.MetroCityId;
      metroCityEditValue.MetroCityName = metroCity.Name;
      metroCityEditValue.FacilityId = this.FacilityId;
      metroCityEditValue.Facility = facilityId ? facility.Name : "";
      metroCityEditValue.InteractionStyleId = this.InteractionStyleId;
      metroCityEditValue.InteractionStyle = interactionStyleId ? interactionStyle.Name : "";
    }
    this.tempPortfolioMetroCities = cloneDeep(this.MetroCity.PortfolioMetroCities);
    this.ResetAddMetroCityRow();
    this.emitMetroCity();
    metrocitiesaddeditform.submitted = false;
  }

  EditMetroCity(index: any) {
    this.setDisplay();
    this.action = "Edit";
    this.index = index;
    if (!this.IsATC) {
      var countryValue = this.MetroCity.PortfolioMetroCities[this.index];
      this.Country.Id = countryValue.CountryId
      this.CountryList = cloneDeep(this.MetroCity.CountryList);
    }
    var metroCityValue = this.MetroCity.PortfolioMetroCities[this.index];
    this.MetroCityId = metroCityValue.MetroCityId;
    this.FacilityId = metroCityValue.FacilityId;
    this.MetroCityChange(this.MetroCityId);
    this.MetroCityList = Enumerable.from(this.MetroCity.MetroCityList).where((x: any) => x.ParentId == this.Country.Id).toArray();
    this.InteractionStyleId = metroCityValue.InteractionStyleId;
  }


  DeleteMetroCity(index) {
    this.MetroCity.PortfolioMetroCities[index].IsMarkedForDeletion = true;
    var isNewRowAvailable = Enumerable.from(this.MetroCity.PortfolioMetroCities).where((x: any) => x.IsMarkedForDeletion == false).select((s: any) => new Object({ IsMarkedForDeletion: s.IsMarkedForDeletion })).firstOrDefault();

    var isNewRowAvailable = Enumerable.from(this.MetroCity.PortfolioMetroCities).where((x: any) => x.IsMarkedForDeletion == false).select((s: any) => new Object({ IsMarkedForDeletion: s.IsMarkedForDeletion })).firstOrDefault();
    var isExistingRowAvailable = Enumerable.from(this.MetroCity.PortfolioMetroCities).where((x: any) => x.EndToEndMetroCityFacilityUId != null).select((s: any) => new Object({ IsMarkedForDeletion: s.IsMarkedForDeletion })).firstOrDefault();
    if (isExistingRowAvailable) {
      this.IsSaveButtonDisplay = true;
    }
    else {
      this.IsSaveButtonDisplay = isNewRowAvailable != null;
    }
    this.ResetAddMetroCityRow()
    this.emitMetroCity();
  }

  ResetAddMetroCityRow() {
    this.action = "Add";
    if (!this.IsATC) {
      this.Country = { Id: '' };
      this.CountryList.forEach(x => { if (x.IsSelected) { x.IsSelected = false }})
      this.FacilityList.forEach(x => { if (x.IsSelected) { x.IsSelected = false; } });
      this.MetroCityList.forEach(x => { if (x.IsSelected) { x.IsSelected = false; } });
      this.MetroCityList = [];
      this.InteractionStyles = cloneDeep(this.MetroCity.InteractionStyles);
    }
    this.InteractionStyleId = "";
    this.CountryId = "";
    this.MetroCityId = "";
    this.FacilityId = "";
    this.FacilityList.forEach(x => { if (x.IsSelected) { x.IsSelected = false; } });
    this.FacilityList = [];
    this.MetroCityList.forEach(x => { if (x.IsSelected) { x.IsSelected = false; } });
    this.InteractionStyles = cloneDeep(this.MetroCity.InteractionStyles);
  }
  CancelGrid() {
    this.InteractionStyleId = "";
    this.InteractionStyles = [];
    if ($('#metroCityModel').find('.has-error').length > 0) {
      $(document.querySelector('#metroCityModel')).find('.has-error').removeClass('has-error');
    }
    this.bsModalRef.hide();
  }

  //EditCountry(countryId, metroCityId, facilityId, interactionStyleId, index) {
  //  var changedCountry: any = Enumerable.from(this.MetroCity.CountryList).where((x: any) => x.Id == countryId).firstOrDefault();
  //  this.MetroCity.PortfolioMetroCities[index].Country = (changedCountry == undefined) ? "" : changedCountry.Name;
  //  this.MetroCity.PortfolioMetroCities[index].MetroCityName = "";
  //  this.MetroCity.PortfolioMetroCities[index].Facility = "";
  //  this.DesignMetroCityGrid(countryId, metroCityId, facilityId, interactionStyleId, index);
  //  this.MetroCity.PortfolioMetroCities[index].MetroCityId = "";
  //  this.MetroCity.PortfolioMetroCities[index].FacilityId = "";
  //}
  //EditMetroCity(countryId, metroCityId, facilityId, interactionStyleId, index) {
  //  var changedMetroCity: any = Enumerable.from(this.MetroCity.MetroCityList).where((x: any) => x.Id == metroCityId).firstOrDefault();
  //  this.MetroCity.PortfolioMetroCities[index].MetroCityName = (changedMetroCity == undefined) ? "" : changedMetroCity.Name;
  //  this.MetroCity.PortfolioMetroCities[index].Facility = "";
  //  this.DesignMetroCityGrid(countryId, metroCityId, facilityId, interactionStyleId, index);
  //  this.MetroCity.PortfolioMetroCities[index].FacilityId = "";
  //}
  EditFacility(countryId, metroCityId, facilityId, interactionStyleId, index) {
    var changedFacility: any = Enumerable.from(this.MetroCity.FacilityList).where((x: any) => x.Id == facilityId).firstOrDefault();
    this.MetroCity.PortfolioMetroCities[index].Facility = (changedFacility == undefined) ? "" : changedFacility.Name;
    this.DesignMetroCityGrid(countryId, metroCityId, facilityId, interactionStyleId, index);
  }
  EditInteractionStyle(countryId, metroCityId, facilityId, interactionStyleId, index) {
    var changedInteraction: any = Enumerable.from(this.MetroCity.InteractionStyles).where((x: any) => x.Id == interactionStyleId).firstOrDefault();
    this.MetroCity.PortfolioMetroCities[index].InteractionStyle = (changedInteraction == undefined) ? "" : changedInteraction.Name;
    if (interactionStyleId == 3) {
      this.sharedService.openAlertPopup("Facility will be disabled for client site", window.scrollY);
      this.MetroCity.PortfolioMetroCities[index].Facility = "";
      this.MetroCity.PortfolioMetroCities[index].FacilityId = null;
      facilityId = '';
    }
    this.DesignMetroCityGrid(countryId, metroCityId, facilityId, interactionStyleId, index);
  }
  DesignMetroCityGrid(countryId, metroCityId, facilityId, interactionStyleId, index) {
    this.currentMetroCities = cloneDeep(this.MetroCity.PortfolioMetroCities);
    this.currentMetroCities.splice(index, 1);
    var row = Enumerable.from(this.currentMetroCities).where((x: any) => x.CountryId == countryId && x.MetroCityId == metroCityId && x.FacilityId == facilityId && x.InteractionStyleId == interactionStyleId && x.IsMarkedForDeletion != true).firstOrDefault();
    if (row != undefined) {
      this.sharedService.openAlertPopup("Selected combination already exists", window.scrollY);
      this.MetroCity.PortfolioMetroCities[index].CountryId = this.tempPortfolioMetroCities[index].CountryId;
      this.MetroCity.PortfolioMetroCities[index].MetroCityId = this.tempPortfolioMetroCities[index].MetroCityId;
      this.MetroCity.PortfolioMetroCities[index].FacilityId = this.tempPortfolioMetroCities[index].FacilityId;
      this.MetroCity.PortfolioMetroCities[index].InteractionStyleId = this.tempPortfolioMetroCities[index].InteractionStyleId;
      this.MetroCity.PortfolioMetroCities[index].Country = this.tempPortfolioMetroCities[index].Country;
      this.MetroCity.PortfolioMetroCities[index].MetroCityName = this.tempPortfolioMetroCities[index].MetroCityName;
      this.MetroCity.PortfolioMetroCities[index].Facility = this.tempPortfolioMetroCities[index].Facility;
      this.MetroCity.PortfolioMetroCities[index].InteractionStyle = this.tempPortfolioMetroCities[index].InteractionStyle;
    }
    this.tempPortfolioMetroCities = cloneDeep(this.MetroCity.PortfolioMetroCities);
  }
  SaveMetroCity() {
    if ($('#metroCityModel').find('-error').length < 1) {
      this.demographicsService.SaveMetroCityDetails(this.MetroCity.PortfolioMetroCities).subscribe((response: any) => {
        this.sharedService.openAlertPopup('Saved Successfully', window.scrollY);
        this.InteractionStyleId = '';
        this.InteractionStyles = [];
        this.bsModalRef.hide();
        if (response != null) {
          this.KeyContactsDetails.LastModifiedOn = response.replace("Z", "");
          this.KeyContactsDetails.LastModifiedBy = this.enterpriseId;
        }
        this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, this.E2eDemoGraphics.E2EId, this.demographicsService);
      });
    }
    else {
      this.sharedService.openAlertPopup('Please fill all required Fields.', window.scrollY);
      return false;
    }
  }

  trackById(index: number, item: any): any {
    return item.Id;
  }
  trackByIndex(index: number, item): number {
    return index;
  }

  setDisplay() {
    this.isDisplay = true;
  }

  emitMetroCity() {
    this.MetroCityDetailsOutputEmitter.emit(this.MetroCity);
  }
}
