import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorage } from '../core/context/sessionstorage';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { enumProfileTab, ManageWorkTile } from '../core/context/enum';
import { environment } from '../../environments/environment';
import { TileService } from '../core/services/tile.service';
declare var window: any;
import { Subscription } from 'rxjs';
import { BroadCastService } from '../core/services/broadcast.service';


var $ = (<any>window).$;

@Component({
  templateUrl: './notifications-alerts.component.html',
  providers: [TileService]
})
export class NotificationsAlertsComponent implements OnInit {
  loadAnnouncement: boolean = false;
  
  modalRef_releaseNote: BsModalRef;
  tiles: any;
  showMandatoryAlert: any;
  isDisableAlertNavigation: any;
  endToEndType = this.sessionStorage.endToEndType;
  alertNotificationCount: number = 0;
  isScopeSelectorVisible: boolean;
  constructor(
    private sessionStorage: SessionStorage,
    private router: Router,
    private bsModalRef: BsModalRef,
    private tileService: TileService, private broadCastService: BroadCastService) {
    
  }

  ngOnInit() {
    //$('body').addClass('stop-scrolling');
   $('body').addClass('hide-scrollingg');
   
    //if (!this.loadAnnouncement) {
    this.getTileDetailsForTileAlerts();
  //}
   
    
  }

  ngAfterViewInit() {
    window.tabsComponent();
      setTimeout(() => {
      this.isDisableAlertNavigation = this.IsDisableAlertNavigation();
      this.showMandatoryAlert = this.ShowMandatoryAlert();
    });
  }

  ngOnDestroy() {
    $('body').removeClass('hide-scrollingg');
    //$('body').removeClass('stop-scrolling');
  }
  AlertClick = function (url) {
    if (url != null)
      window.open(url, '_blank');
  }
  IsDisableAlertNavigation = function () {
    if (this.sessionStorage.teamId || this.sessionStorage.clientWMSId || this.sessionStorage.workstreamId) {
      return true;
    }
    return false;
  }
  ShowMandatoryAlert = function () {

    if (this.sessionStorage.IsProfileCompleted) {
      return false;
    }
    return true;

  }  
  getTileDetailsForTileAlerts() {
    if (this.isScopeSelectorVisible) {
      this.tileService.validateProfile(true).subscribe((alertResponse: any) => {
        //this.tileService.getTiles(enumArea.Manage).subscribe((response) => {
        //  this.tiles = response;
        this.isValidOverallDeliveryLeadAndEndDate(alertResponse);
      });
    }
    else {
      this.loadAnnouncement = true;
    }
  }


  isValidOverallDeliveryLeadAndEndDate(response: any) {

    this.sessionStorage.isValidEndToEndOverallDeliveryLead = response.IsEndToEndOverallDeliveryLeadValid;
    this.sessionStorage.isValidEndDate = response.IsEndDateValid;
  //  var tile = this.tiles.filter(x => x.TileId == parseInt(ManageWorkTile.InitiationAndConfiguration))[0];
   // if (tile) {
    this.tiles = {};
      this.tiles.IsValidEndToEndOverallDeliveryLead = response.IsEndToEndOverallDeliveryLeadValid;
      this.tiles.IsValidEndDate = response.IsEndDateValid;
      this.tiles.IsValidDeliveryTypeBasedWorkStream = response.IsWorkStreamCreated;
      this.tiles.IsValidServiceGroupDeliveryType = response.IsServiceGroupDeliveryType;
      this.tiles.IsAnticipatedContractAvailableInCMW = response.IsAnticipatedContractAvailableInCMW;
      this.tiles.IsPMOLeadMandatory = response.IsPMOLeadMandatory;
      this.tiles.IsMissingCoreMetrics = response.IsMissingCoreMetrics;
    this.tiles.automationJourneyAlert = response?.AJNotifications;
    this.tiles.IsCurrentSustainabilityLevelUpdated = response.IsCurrentSustainabilityLevelUpdated;
    this.alertNotificationCount = this.AlertNotificationCount();
  //  }

  }

  AlertNotificationProfile = function (tileId) {
    this.closeSlide();
    this.sessionStorage.parentTileId = tileId;
    this.sessionStorage.profileTab = enumProfileTab.Profile;
    this.router.navigate(['/mywizard/profile', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
  }
  AlertKeyContact = function (tileId) {
    this.closeSlide();
    this.sessionStorage.parentTileId = tileId;
    this.sessionStorage.profileTab = enumProfileTab.KeyContacts;
    this.router.navigate(['/mywizard/keycontacts', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
  }
  AlertWorkStream = function (tileId) {
    this.closeSlide();
    this.sessionStorage.parentTileId = tileId;
    this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
    this.router.navigate(['/mywizard/clientstructure', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
  }
  AlertScope = function (tileId) {
    this.closeSlide();
    this.sessionStorage.parentTileId = tileId;
    this.sessionStorage.profileTab = enumProfileTab.Scope;
    this.router.navigate(['/mywizard/scope', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
  }
  AlertMissingCoreMetrics = function () {
    this.closeSlide();
    var url = environment.MetricsUrl;
    this.sessionStorage.profileTab = enumProfileTab.Thresholds;
    this.router.navigate(['/mywizard/metricthreshold', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
  }

  AlertNotificationCount = function () {
    var alertCount = 0;
    if (this.tiles) {
      if (!this.sessionStorage.IsProfileCompleted) {
        alertCount = alertCount + 1;
      }
      else {
        if (this.tiles.IsValidEndDate == false) {
          alertCount = alertCount + 1;
        }
        if (this.tiles.IsValidEndToEndOverallDeliveryLead == false) {
          alertCount = alertCount + 1;
        }
        if (this.tiles.IsValidDeliveryTypeBasedWorkStream == false) {
          alertCount = alertCount + 1;
        }
        if (this.tiles.IsMissingCoreMetrics == true) {
          alertCount = alertCount + 1;
        }
        if (this.tiles.IsValidServiceGroupDeliveryType == false) {
          alertCount = alertCount + 1;
        }
        if (this.tiles.IsAnticipatedContractAvailableInCMW == true) {
          alertCount = alertCount + 1;
        }
        if (this.tiles.IsPMOLeadMandatory == true) {
          alertCount = alertCount + 1;
        }
        if (this.tiles.IsCurrentSustainabilityLevelUpdated == true && this.endToEndType == 'C') {
          alertCount = alertCount + 1;
        }  
        if (this.tiles.automationJourneyAlert?.length > 0) {
          alertCount = alertCount + this.tiles.automationJourneyAlert?.length;
        }
      }
    }
    return alertCount;
  }
  closeSlide() {
    this.bsModalRef.hide();
  } 
 
}

