import { Component, ChangeDetectorRef, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { SessionStorage } from '../../core/context/sessionstorage';
import { ImagePath } from '../../core/context/constants';
import { ApplicationMessages, enumModuleAction, enumTile, enumFeatureAccess, enumUserPreference, enumCMS, enumEntityType, enumSubmissionType, enumDeliveryType, enumProfileTab } from '../../core/context/enum';
import { CMSMenuService } from '../../core/services/cms.menu.service';
import { UserPreferenceService } from '../../core/services/user.preference.service';
import { DemographicsService } from '../../core/services/demographics.service';
import { ApplicationLoggingService } from '../../core/services/application.logging.service';
import { SharedService } from '../../core/services/shared.service';
import { InitiateProfileSharedService } from './initiate.profile.shared.service';
import { CMSAddEditComponent } from '../initiate.profile.component/model.popup.component/cms.add.edit.component'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
//import { SlideShowComponent } from './model.popup.component/slide.show.component';
import { HeaderComponent } from '../header.component/header.component';
import { UnsavedChangesService } from '../../core/services/unsaved.changes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TileService } from '../../core/services/tile.service';
var $ = (<any>window).$;
declare var window: any;
import * as Enumerable from "linq";
import { ScopeSelectorComponent } from '../scope.selector.component/scope.selector.component';
import { InitiateProfileComponent } from './initiate.profile.component';
import { BroadCastService } from '../../core/services/broadcast.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'cms-menu',
  templateUrl: './client.structure.component.html',
  providers: [CMSMenuService, UserPreferenceService, DemographicsService, ApplicationLoggingService, DatePipe, BsModalService, HeaderComponent, ScopeSelectorComponent]
})

export class ClientStructureComponent {
  @ViewChild('clientstructureform') clientstructureForm: NgForm;
  unsavedSubscription: Subscription;
  unsavedConfirm: boolean = false;
  IsShowModifiedDetails: boolean = true;
  IsAdmin: boolean = this.sessionStorage.roleTypeCode == "AA" || this.sessionStorage.roleTypeCode == "AC" || this.sessionStorage.roleTypeCode == "AV" ? true : false;
  IsEditable: boolean = this.sessionStorage.roleAccessCode == 'V' ? false : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? false : true);
  IsslideShowChecked: boolean;
  IsConfigurationManager: boolean;
  IsCMSClosed: boolean = false;
  IsShowMultiContractLink: boolean = false;
  IsCMSSelected: boolean;
  IsWorkStreamSelected: boolean;
  IsFifthLevelCMS: boolean;
  EndToEndType = this.sessionStorage.endToEndType;
  EndToEndRefCode = this.sessionStorage.endToEndRefCode;
  EndToEndName: string = this.sessionStorage.endToEndName;
  SFEDisable: boolean = false;
  SubmissionType: any = this.sessionStorage.selectedSubmissionType;
  Action: string;
  AssociatedContractsEndToEnd: any = [];
  cmsHierarchy: any = {};
  currentSelectedCMS: any = {};
  currentSelectedWS: any = {};
  TempcurrentSelectedCMS: any = {};
  TempcurrentSelectedWS: any = {};
  dateMandatory: boolean;
  dragging: number = 0;
  draggdropcount: number;
  DisableAddButton: boolean;
  DraggedItems: any = [];
  DeletedCMS: any = [];
  DeletedWorkstream: any = [];
  confirmationMessage: string;
  Message: string;
  IsReset: boolean;
  PopupTitle: string;
  redirectValue: any;
  redirectId: any;
  TypeOptions: any = [];
  UserPreferenceDetail: any = {};
  workStreamTypeIcon: any;
  keyContactTabHighlight: boolean;
  ProfileTabHighlight: boolean;
  dragDropMessage: string = "Please save the drag & drop changes to continue";
  isExpanded: boolean = false;
  isSICoreMetricsFlagUpdated: boolean = false;
  @Output() InitiateProfileTabSetup = new EventEmitter();
  modalRef: BsModalRef;
  modalRef_SlideShow: BsModalRef;
  isShowEndToEnd: boolean = true;
  CollapseExpand: boolean = false;
  AfterSaveCollapseFlag: boolean = false;
  trackExpandedElements: any = [];
  toggleCollpaseAndExpand: any = [];

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedConfirm && this.sessionStorage.endToEndUId) {
      if (this.clientstructureForm.form.dirty) { return confirm('Changes you made may not be saved.'); }
      else { return true; }
    } else {
      return true;
    }
  }

  constructor(
    private tileService: TileService,
    private sessionStorage: SessionStorage,
    private CMSMenuService: CMSMenuService,
    private userPreferenceService: UserPreferenceService,
    private demographicsService: DemographicsService,
    private applicationLoggingService: ApplicationLoggingService,
    private modalService: BsModalService,
    private sharedService: SharedService,
    private initiateProfileSharedService: InitiateProfileSharedService,
    private scopeSelectorComponent: ScopeSelectorComponent,
    private headerComponent: HeaderComponent,
    private initiateProfileComponent: InitiateProfileComponent,
    private router: Router,
    private ref: ChangeDetectorRef,
    private unsavedChangesService: UnsavedChangesService,
    private activatedRoute: ActivatedRoute,
    private broadCastService: BroadCastService
  ) {
    this.unsavedSubscription = this.unsavedChangesService.currentMessage.subscribe((data) => {
      if (data == "clientstructure") {
        if (this.clientstructureForm.form.dirty) {
          if (confirm("Changes you made may not be saved.")) {
            this.unsavedConfirm = true;
            this.sessionStorage.isUserAlert = false;
          } else {
            this.sessionStorage.isUserAlert = true;
          }
        }
        else {
          this.sessionStorage.isUserAlert = false;
        }
      }
    })
  }

  ngOnInit() {
    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['MyWizard/Back/Profile'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId, profileTabCode: 'CMS' } });
      return false;
    }
    this.initiateProfileSharedService.ValidateDemographicTabHighlight(this);
    this.initiateProfileSharedService.errorTabHighlight.subscribe(result => {
      this.InitiateProfileTabSetup.emit({ keyContactTabHighlight: this.keyContactTabHighlight, ProfileTabHighlight: this.ProfileTabHighlight, tabId: enumProfileTab.ClientStructure });
    });
    this.LoadCMSStructure(false); 
    this.VerifyConfigurationManagerRole();
    this.dateMandatory = false;
    this.LoadSlideShow();

    setTimeout(() => {
      window.TooltipFunc();
      window.collapseComponent();
      window.dropdownComponent();
    }, 500)
  }

  ngOnDestroy() {
    this.unsavedSubscription.unsubscribe();
  }

  LoadSlideShow() {
    this.UserPreferenceDetail = {};
    this.UserPreferenceDetail.EnterpriseId = this.sessionStorage.enterpriseId;
    this.UserPreferenceDetail.PreferenceId = enumUserPreference.HideSlideShow;
    this.UserPreferenceDetail.PreferenceValue = "Y";
    //this.userPreferenceService.IsShowSlide(this.UserPreferenceDetail).subscribe((response: any) => {
    //  if (response && response == true) {
    //    this.IsslideShowChecked = false;
    //    const initialState = { IsslideShowChecked: this.IsslideShowChecked };
    //    this.modalRef_SlideShow = this.modalService.show(SlideShowComponent, {
    //      initialState,
    //      backdrop: "static",
    //      keyboard: false
    //    });
    //    $(".modal-dialog").addClass('custom-modal-cmstmspopup');
    //  }
    //});
  }

  ShowAlertToReset() {
    var alertModel = this.sharedService.openAlertPopup(this.Message, window.scrollY);
    alertModel.content.confirmationResult.subscribe(response => {
      if (response.result) {
        this.CloseAlert();
      }
    });
  }

  LoadCMSStructure(isPageReLoad) {
    this.CMSMenuService.CMSHierarchyStructure().subscribe((response: any) => {
      if (response) {
        setTimeout(function () {
          window.TooltipFunc();
        }, 0);
        this.cmsHierarchy = response;
        this.AssociatedContractsEndToEnd = response.AssociatedContractsEndToEnd;
        if (response.MutiContractPreferenceClientIds) {
          var multiContractPreference = response.MutiContractPreferenceClientIds.filter((x: any) => x.Name == this.sessionStorage.clientId).length;
          if (multiContractPreference > 0) {
            this.IsShowMultiContractLink = true;
          }
        }
        (window as any).scrollTo(0, $('.dynamic-content').offsetTop);
        //} ////commented service method here.

        if (this.cmsHierarchy.WorkstreamDataList.length == 0 && this.cmsHierarchy.CMSDataList.length == 0) {
          this.IsShowModifiedDetails = false;
        }
        if (this.cmsHierarchy.WorkstreamDataList.length != 0) {
          this.SFEDisable = true;
          this.SFEDisable = this.SFEDisable;
        }
        else {
          this.SFEDisable = false;
          this.sessionStorage.SFEDisable = this.SFEDisable;
        }
        this.UpdateModel();
        this.DraggedItems = [];
        this.sessionStorage.CMSUnsavedData = 0;
        this.SubmissionType = this.sessionStorage.selectedSubmissionType; //added from top to here
        this.currentSelectedCMS = null;
        this.currentSelectedWS = null;
        this.TempcurrentSelectedCMS = null;
        this.TempcurrentSelectedWS = null;
        this.IsWorkStreamSelected = false;
        this.IsCMSSelected = false;
        this.IsFifthLevelCMS = false;
        this.draggdropcount = 0;
        this.TypeOptions = [{ name: "Agile/Waterfall Workstream", id: 2 }, { name: "CMS", id: 1 }];//{ name: "SD Workstream", id: 3 }
        this.cmsHierarchy.TypeId = 0;
        this.dragging = 0;
        if (this.IsEditable) {
          var _this = this;
          $('.cms-tms-list').nestedSortable({
            forcePlaceholderSize: true, handle: 'div.c-list-moveable', helper: 'clone', items: 'li:not(.static)', placeholder: 'cms-tms-item-placeholder',
            revert: 250, tabSize: 25, tolerance: 'pointer', toleranceElement: '> div', isTree: false, listType: 'ul',
            leafClass: 'cms-tms-item-leaf', disableNestingClass: 'cms-tms-item-no-nesting', protectRoot: false,maxLevels: 5,
            sort: function (event, ui) {
              _this.dragging = 1;
            },
            change: function (event, ui) {
              _this.dragging = 1;
            },
            stop: function (event, ui) {
              _this.dragging = 0;
              if (_this.draggdropcount >= 3) {
                _this.sharedService.openAlertPopup(_this.dragDropMessage, window.scrollY);
                return false;
              }
              var draggedItemType = ui.item[0].firstElementChild.dataset.type;
              if (draggedItemType == "CMS") {
                var draggedCMSId = ui.item[0].dataset.cmsid;
                var droppedCMSId = ui.item[0].parentNode.parentNode.dataset.cmsid;

                var draggedItem = ui.item[0].dataset.item;
                var droppedItem = ui.item[0].parentNode.parentNode.dataset.item;

                if (_this.ValidateDrop(ui, draggedItem, droppedItem, "cms")) {
                  var result = _this.DropCMS(draggedCMSId, droppedCMSId, draggedItem, droppedItem);
                  if (result)
                    _this.CMSExpandAndCollapse(droppedCMSId);
                  return result;
                }
                else {
                  return false;
                }

              }
              if (draggedItemType == "Workstream") {
                var draggedWorkstreamId = ui.item[0].dataset.wsid;
                var droppedCMSId = ui.item[0].parentNode.parentNode.dataset.cmsid;

                var draggedItem = ui.item[0].dataset.item;
                var droppedItem = ui.item[0].parentNode.parentNode.dataset.item;

                if (_this.ValidateDrop(ui, draggedItem, droppedItem, "ws")) {
                  var result = _this.DropWS(draggedWorkstreamId, droppedCMSId, draggedItem, droppedItem);
                  if (result)
                    _this.CMSExpandAndCollapse(droppedCMSId);
                  return result;
                }
                else {
                  return false;
                }
              }
            },
          });
          if (!this.AfterSaveCollapseFlag)
          this.CollapseAfterSave();
          //this.toggleCollpaseAndExpand = []
          //var allElements = document.querySelectorAll('li');
          //allElements.forEach(ele => {
          //  var childElement = ele.querySelector('.c-list-child');
          //  if (childElement != null) {
          //    var expandId = childElement.closest('li');
          //    var allChildIndex = this.toggleCollpaseAndExpand.indexOf(expandId.dataset.cmsid.toString());
          //    if (allChildIndex == -1)
          //      this.toggleCollpaseAndExpand.push(expandId.dataset.cmsid.toString());
          //  }
          //})
          //this.trackExpandedElements = [];
          //var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
          //toggleState.forEach(e => {
          //  $(e).prop('checked', false);
          //  this.CollapseExpand = false;
          //})
          //this.ref.detectChanges();
          this.sharedService.UsageLogging(enumModuleAction.Tile, enumTile.InitiateProfile, enumFeatureAccess.ClientStructure);
        }
        if (this.trackExpandedElements.length > 0)
          this.expandParent(_this.trackExpandedElements);
      }
    });
  }

  CollapseAfterSave() {
    this.toggleCollpaseAndExpand = []
    var allElements = document.querySelectorAll('li');
    allElements.forEach(ele => {
      var childElement = ele.querySelector('.c-list-child');
      if (childElement != null) {
        var expandId = childElement.closest('li');
        var allChildIndex = this.toggleCollpaseAndExpand.indexOf(expandId.dataset.cmsid.toString());
        if (allChildIndex == -1)
          this.toggleCollpaseAndExpand.push(expandId.dataset.cmsid.toString());
      }
    })
    this.trackExpandedElements = [];
    var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
    toggleState.forEach(e => {
      $(e).prop('checked', false);
      this.CollapseExpand = false;
    })
    this.ref.detectChanges();
  }

  CMSExpandAndCollapse(droppedCMSId: any) {
    if (droppedCMSId != undefined) {
      var indexOfElements = this.trackExpandedElements.findIndex(x => x == droppedCMSId.toString());
      if (indexOfElements == -1)
        this.trackExpandedElements.push(droppedCMSId.toString());
      var indexOfAllElements = this.toggleCollpaseAndExpand.findIndex(x => x == droppedCMSId.toString());
      if (indexOfAllElements == -1)
        this.toggleCollpaseAndExpand.push(droppedCMSId.toString());
      if (this.trackExpandedElements.length > 0)
        this.expandParent(this.trackExpandedElements);
    }
    else {
      this.toggleCollpaseAndExpand = [];
      var allElements = document.querySelectorAll('li');
      allElements.forEach(ele => {
        var childElement = ele.querySelector('.c-list-child');
        if (childElement != null) {
          var expandId = childElement.closest('li');
          this.toggleCollpaseAndExpand.push(expandId.dataset.cmsid.toString());
        }
      })
      if (this.toggleCollpaseAndExpand.length == 0) {
        this.trackExpandedElements = [];
        var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
        toggleState.forEach(e => {
          $(e).prop('checked', false);
          this.CollapseExpand = false;
        })
        this.ref.detectChanges();
      }
      else {
        if (this.trackExpandedElements.length > 0) {
          var elementToDelete = [];
          this.trackExpandedElements.forEach(e => {
            if (this.toggleCollpaseAndExpand.indexOf(e.toString()) == -1) {
              elementToDelete.push(e.toString());
            }
          });
          if (elementToDelete.length > 0) {
            elementToDelete.forEach(x => {
              var indexToDelete = this.trackExpandedElements.indexOf(x.toString());
              if (indexToDelete > -1) {
                this.trackExpandedElements.splice(indexToDelete, 1);
                this.expandParent(this.trackExpandedElements);
              }
            })
          }
          else {
            this.expandParent(this.trackExpandedElements);
          }
        }
        else {
          var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
          toggleState.forEach(e => {
            $(e).prop('checked', false);
            this.CollapseExpand = false;
          })
          this.ref.detectChanges();
        }

      }
    }
  }

  UpdateModel() {
    var dataSet = cloneDeep(this.cmsHierarchy);
    this.cmsHierarchy = dataSet;
    this.cmsHierarchy.CMSLevelOne = [];
    this.cmsHierarchy.WorkStreamStructure = [];
   
    var cmsLevelOne = this.cmsHierarchy.CMSDataList.filter((x: any) => x.SelectedParentId == null);

    cmsLevelOne.forEach((cms1: any) => {
      cms1.Tier = 1;
      cms1.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSId == cms1.CMSId);
      var cmsLevelTwo = this.cmsHierarchy.CMSDataList.filter((x: any) => x.SelectedParentId == cms1.CMSId);

      cmsLevelTwo.forEach((cms2: any) => {
        cms2.Tier = 2;
        cms2.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSId == cms2.CMSId);
        var cmsLevelThree = this.cmsHierarchy.CMSDataList.filter((x: any) => x.SelectedParentId == cms2.CMSId);

        cmsLevelThree.forEach((cms3: any) => {
          cms3.Tier = 3;
          cms3.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSId == cms3.CMSId);
          var cmsLevelFour = this.cmsHierarchy.CMSDataList.filter((x: any) => x.SelectedParentId == cms3.CMSId);

          cmsLevelFour.forEach((cms4: any) => {
            cms4.Tier = 4;
            cms4.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSId == cms4.CMSId);
            var cmsLevelFive = this.cmsHierarchy.CMSDataList.filter((x: any) => x.SelectedParentId == cms4.CMSId);

            cmsLevelFive.forEach((cms5: any) => {
              cms5.Tier = 5;
              cms5.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSId == cms5.CMSId);
            });
            cms4.CMSLevelFive = cmsLevelFive;
          });
          cms3.CMSLevelFour = cmsLevelFour;
        });
        cms2.CMSLevelThree = cmsLevelThree;
      });
      cms1.CMSLevelTwo = cmsLevelTwo;
    });
    this.cmsHierarchy.CMSLevelOne = cmsLevelOne;
    this.cmsHierarchy.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSName == null);

    //var elements = $('.ui-sortable li');
    //if (elements.length > 0) {
    //  elements.toArray().forEach((el: any) => {
    //    $(el).remove();
    //  });
    //}
    this.ref.detectChanges();
    
  }

  removeElement(){
    var elements = $('.ui-sortable li');
    if (elements.length > 0) {
      elements.toArray().forEach((el: any) => {
        $(el).remove();
      });
    }
  }

  NavigateDeliveryTool() {

    var url = this.cmsHierarchy.NavigateDeliveryToolApplicationUrl;
    if (url) {
      url = url.replace('{endToEndUId}', this.tileService.isValidGUId(this.sessionStorage.endToEndUId) ? this.sessionStorage.endToEndUId : '');
      url = url.replace('{client}', this.tileService.isValidString(this.sessionStorage.clientName) ? this.sessionStorage.clientName : '');
      url = url.replace('{enterpriseId}', this.tileService.isValidString(this.sessionStorage.enterpriseId) ? this.sessionStorage.enterpriseId : '');
      (window as any).open(url, "_blank");
    }
  }

  ToggleScope(event) {
    if (event.keyCode == 13) {
      if (this.cmsHierarchy.AutoCreatedAgileWSApproved) {
        this.cmsHierarchy.AutoCreatedAgileWSApproved = false;
        this.AutoCreatedWSApprovalChange(false, event);
      }
      else {
        this.cmsHierarchy.AutoCreatedAgileWSApproved = true;
        this.AutoCreatedWSApprovalChange(true, event);
      }
    }
  }

  AutoCreatedWSApprovalChange(isUserApproved, event) {
    var alertMessage = "";
    if (isUserApproved) {
      alertMessage = ApplicationMessages.AgileWorkstreamsAutoApprovalMessageForYes;
    }
    else {
      alertMessage = ApplicationMessages.AgileWorkstreamsAutoApprovalMessageForNo;
    }
    var results = this.sharedService.openConfirmationPopup(alertMessage, "OK", "Cancel", window.scrollY);
    results.content.confirmationResult.subscribe(response => {
      if (!response.result) {
        this.cmsHierarchy.AutoCreatedAgileWSApproved = isUserApproved ? false : true;
        event.target.checked = this.cmsHierarchy.AutoCreatedAgileWSApproved;
        return false;
      } else {
        if (results) {
          this.UserPreferenceDetail = {};
          this.UserPreferenceDetail.EnterpriseId = this.sessionStorage.enterpriseId;
          this.UserPreferenceDetail.AgileWorkstreamsAutoApproved = isUserApproved;
          this.UserPreferenceDetail.EndToEndId = this.sessionStorage.endToEndId;
          this.CMSMenuService.UpdateUserPreferenceForAutoCreatedWS(this.UserPreferenceDetail).subscribe((response: any) => {
            if (!response) {
              this.sharedService.openAlertPopup(ApplicationMessages.AgileWorkstreamsAutoApprovalMessageDuringProcessing, window.scrollY);
              this.cmsHierarchy.AutoCreatedAgileWSApproved = isUserApproved ? false : true;
              event.target.checked = this.cmsHierarchy.AutoCreatedAgileWSApproved;

            }
            else {
              if (this.cmsHierarchy.AutoCreatedAgileWSApproved) {
                this.ProcessDemographicAttribute(this.sessionStorage.endToEndId, null); // no params sent from AngJS, so setting default values taken from same function definition
                this.LoadCMSStructure(true);
                this.CollapseAfterSave();
              }
            }
          });
        }
      }
    });


  }

  VerifyConfigurationManagerRole() {
    if (this.sessionStorage.roleTypeCode == "AA" || this.sessionStorage.roleTypeCode == "AC" || this.sessionStorage.roleTypeCode == "AV" || this.sessionStorage.roleTypeCode == "PM") {
      this.IsConfigurationManager = true;
    }
  }

  GetCMSIdsForWorkstream(cmsDrag: any, isParent) {
    var Workstream;
    if (isParent) {
      Workstream = cmsDrag.WorkStreamStructure;
    }
    else {
      Workstream = Enumerable.from(cmsDrag).selectMany((x: any) => x.WorkStreamStructure).toArray();
    }
    var cmsList = Enumerable.from(Workstream).selectMany((x: any) => x.SelectedWorkstreamCms).toArray();
    cmsList = this.GetUniqueItems(cmsList, 'Id');
    return cmsList;
  }

  IsExist(DraggedWorkstreamCms, cmsId) {
    var draggedWorkstreamCms = cloneDeep(DraggedWorkstreamCms);
    var isCMSExist = draggedWorkstreamCms.filter((x: any) => x.Id == cmsId);
    if (isCMSExist.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  PushList(cmsDrag, DraggedWorkstreamCms, isParent) {
    var list = this.GetCMSIdsForWorkstream(cmsDrag, isParent);
    if (list.length > 0) {
      DraggedWorkstreamCms.push.apply(DraggedWorkstreamCms, list);
    }
  }

  ValidateDrop(ui, draggedItem, droppedItem, type) {
    let draggedItemJson = this.tryJsonParse(draggedItem);
    let droppedItemJson = this.tryJsonParse(droppedItem);
    var valid = true;
    var cmsDrop = droppedItem != undefined ? droppedItemJson : droppedItem;
    var DraggedWorkstreamCms = [];

    if (type == "cms") {
      var cmsDrag = draggedItemJson;
      switch (cmsDrag.Tier) {
        case 1:
          this.PushList(cmsDrag, DraggedWorkstreamCms, true);
          var Level2 = cmsDrag.CMSLevelTwo;
          if (Level2.length > 0) {
            this.PushList(Level2, DraggedWorkstreamCms, false);
            var Level3 = Enumerable.from(Level2).selectMany((x: any) => x.CMSLevelThree).toArray();
            if (Level3.length > 0) {
              this.PushList(Level3, DraggedWorkstreamCms, false);
              var Level4 = Enumerable.from(Level3).selectMany((x: any) => x.CMSLevelFour).toArray();
              if (Level4.length > 0) {
                this.PushList(Level4, DraggedWorkstreamCms, false);
                var Level5 = Enumerable.from(Level4).selectMany((x: any) => x.CMSLevelFive).toArray();
                if (Level5.length > 0) {
                  this.PushList(Level5, DraggedWorkstreamCms, false);
                }
              }
            }
          }
          DraggedWorkstreamCms = this.GetUniqueItems(DraggedWorkstreamCms, 'Id');
          break;
        case 2:
          this.PushList(cmsDrag, DraggedWorkstreamCms, true);
          var cmsLevel3 = cmsDrag.CMSLevelThree;
          if (cmsLevel3.length > 0) {
            this.PushList(cmsLevel3, DraggedWorkstreamCms, false);
            var Level4 = Enumerable.from(cmsLevel3).selectMany((x: any) => x.CMSLevelFour).toArray();
            if (Level4.length > 0) {
              this.PushList(Level4, DraggedWorkstreamCms, false);
              var Level5 = Enumerable.from(Level4).selectMany((x: any) => x.CMSLevelFive).toArray();
              if (Level5.length > 0) {
                this.PushList(Level5, DraggedWorkstreamCms, false);
              }
            }
          }
          DraggedWorkstreamCms = this.GetUniqueItems(DraggedWorkstreamCms, 'Id');
          break;
        case 3:
          this.PushList(cmsDrag, DraggedWorkstreamCms, true);
          var cmsLevel4 = cmsDrag.CMSLevelFour;
          if (cmsLevel4.length > 0) {
            this.PushList(cmsLevel4, DraggedWorkstreamCms, false);
            var Level5 = Enumerable.from(cmsLevel4).selectMany((x: any) => x.CMSLevelFive).toArray();
            if (Level5.length > 0) {
              this.PushList(Level5, DraggedWorkstreamCms, false);
            }
          }
          DraggedWorkstreamCms = this.GetUniqueItems(DraggedWorkstreamCms, 'Id');
          break;
        case 4:
          this.PushList(cmsDrag, DraggedWorkstreamCms, true);
          if (cmsDrag.CMSLevelFive.length > 0) {
            this.PushList(cmsDrag.CMSLevelFive, DraggedWorkstreamCms, false);
          }
          DraggedWorkstreamCms = this.GetUniqueItems(DraggedWorkstreamCms, 'Id');
          break;
        default:
          break;
      }
    }
    else if (type == "ws") {
      var wsDrag = draggedItemJson;

      //copied function code directly here - GetCMSIdsForWorkstreamDragDrop()
      var cmsList = wsDrag.SelectedWorkstreamCms;
      cmsList = cmsList.filter((x: any) => x.Id != wsDrag.CMSId);
      cmsList = this.GetUniqueItems(cmsList, 'Id');
      if (cmsList.length > 0) {
        DraggedWorkstreamCms.push.apply(DraggedWorkstreamCms, cmsList);
      }
    }
    if (cmsDrop != undefined) {
      switch (cmsDrop.Tier) {
        case 1:
          var Level1cmsid = ui.item[0].parentNode.parentNode.dataset.cmsid;
          valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level1cmsid));
          break;
        case 2:
          var Level1cmsid = ui.item[0].parentNode.parentNode.parentNode.parentNode.dataset.cmsid;
          valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level1cmsid));
          if (valid) {
            var Level2cmsid = ui.item[0].parentNode.parentNode.dataset.cmsid;
            valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level2cmsid));
          }
          break;
        case 3:
          var Level1cmsid = ui.item[0].parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.dataset.cmsid;
          valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level1cmsid));
          if (valid) {
            var Level2cmsid = ui.item[0].parentNode.parentNode.parentNode.parentNode.dataset.cmsid;
            valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level2cmsid));
            if (valid) {
              var Level3cmsid = ui.item[0].parentNode.parentNode.dataset.cmsid;
              valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level3cmsid));
            }
          }
          break;
        case 4:
          var Level1cmsid = ui.item[0].parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.dataset.cmsid;
          valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level1cmsid));
          if (valid) {
            var Level2cmsid = ui.item[0].parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.dataset.cmsid;
            valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level2cmsid));
            if (valid) {
              var Level3cmsid = ui.item[0].parentNode.parentNode.parentNode.parentNode.dataset.cmsid;
              valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level3cmsid));
              if (valid) {
                var Level4cmsid = ui.item[0].parentNode.parentNode.dataset.cmsid;
                valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level4cmsid));
              }
            }
          }
          break;
        case 5:
          if (type == "ws") {
            var Level1cmsid = ui.item[0].parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.dataset.cmsid;
            valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level1cmsid));
            if (valid) {
              var Level2cmsid = ui.item[0].parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.dataset.cmsid;
              valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level2cmsid));
              if (valid) {
                var Level3cmsid = ui.item[0].parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.dataset.cmsid;
                valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level3cmsid));
                if (valid) {
                  var Level4cmsid = ui.item[0].parentNode.parentNode.parentNode.parentNode.dataset.cmsid;
                  valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level4cmsid));
                  if (valid) {
                    var Level5cmsid = ui.item[0].parentNode.parentNode.dataset.cmsid;
                    valid = this.IsExist(DraggedWorkstreamCms, parseInt(Level5cmsid));
                  }
                }
              }
            }
          }
          break;
        default:
          break;
      }
      if (type == "ws") {
        switch (cmsDrop.Tier) {
          case 1:
            if (valid) {
              var Level2 = cmsDrop.CMSLevelTwo;
              if (Level2.length > 0) {
                Level2.forEach((cms2: any, key) => {
                  if (valid) {
                    valid = this.IsExist(DraggedWorkstreamCms, parseInt(cms2.CMSId));
                  }
                });
                if (valid) {
                  var Level3 = Enumerable.from(Level2).selectMany((x: any) => x.CMSLevelThree).toArray();
                  if (Level3.length > 0) {
                    Level3.forEach((cms3: any, key) => {
                      if (valid) {
                        valid = this.IsExist(DraggedWorkstreamCms, parseInt(cms3.CMSId));
                      }
                    });
                    if (valid) {
                      var Level4 = Enumerable.from(Level3).selectMany((x: any) => x.CMSLevelFour).toArray();
                      if (Level4.length > 0) {
                        Level4.forEach((cms4: any, key) => {
                          if (valid) {
                            valid = this.IsExist(DraggedWorkstreamCms, parseInt(cms4.CMSId));
                          }
                        });
                        if (valid) {
                          var Level5 = Enumerable.from(Level4).selectMany((x: any) => x.CMSLevelFive).toArray();
                          if (Level5.length > 0) {
                            Level5.forEach((cms5: any, key) => {
                              if (valid) {
                                valid = this.IsExist(DraggedWorkstreamCms, parseInt(cms5.CMSId));
                              }
                            });
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            break;
          case 2:
            if (valid) {
              //check - replaces Level3 => cmsLevel3
              var cmsLevel3 = cmsDrop.CMSLevelThree;
              if (cmsLevel3.length > 0) {
                cmsLevel3.forEach((cms3: any, key) => {
                  if (valid) {
                    valid = this.IsExist(DraggedWorkstreamCms, parseInt(cms3.CMSId));
                  }
                });
                if (valid) {
                  var Level4 = Enumerable.from(cmsLevel3).selectMany((x: any) => x.CMSLevelFour).toArray();
                  if (Level4.length > 0) {
                    Level4.forEach((cms4: any, key) => {
                      if (valid) {
                        valid = this.IsExist(DraggedWorkstreamCms, parseInt(cms4.CMSId));
                      }
                    });
                    if (valid) {
                      var Level5 = Enumerable.from(Level4).selectMany((x: any) => x.CMSLevelFive).toArray();
                      if (Level5.length > 0) {
                        Level5.forEach((cms5: any, key) => {
                          if (valid) {
                            valid = this.IsExist(DraggedWorkstreamCms, parseInt(cms5.CMSId));
                          }
                        });
                      }
                    }
                  }
                }
              }
            }
            break;
          case 3:
            if (valid) {
              //Level4 => cmsLevel4
              var cmsLevel4 = cmsDrop.CMSLevelFour;
              if (cmsLevel4.length > 0) {
                Level4.forEach((cms4: any, key) => {
                  if (valid) {
                    valid = this.IsExist(DraggedWorkstreamCms, parseInt(cms4.CMSId));
                  }
                });
                if (valid) {
                  var Level5 = Enumerable.from(cmsLevel4).selectMany((x: any) => x.CMSLevelFive).toArray();
                  if (Level5.length > 0) {
                    Level5.forEach((cms5: any, key) => {
                      if (valid) {
                        valid = this.IsExist(DraggedWorkstreamCms, parseInt(cms5.CMSId));
                      }
                    });
                  }
                }
              }
            }
            break;
          case 4:
            if (valid) {
              if (cmsDrop.CMSLevelFive.length > 0) {
                cmsDrop.CMSLevelFive.forEach((cms5: any, key) => {
                  if (valid) {
                    valid = this.IsExist(DraggedWorkstreamCms, parseInt(cms5.CMSId));
                  }
                });
              }
            }
            break;
          case 5:
            if (valid) {
              valid = this.IsExist(DraggedWorkstreamCms, parseInt(cmsDrop.CMSId));
            }
            break;
          default:
            break;
        }
      }
    }
    return valid;
  }

  DropWS(draggedWSId, droppedCMSId, draggedItemTemp, droppedItemTemp) {
    if (!draggedWSId) {
      return false;
    }
    var draggedItem = this.tryJsonParse(cloneDeep(draggedItemTemp));
    let droppedItemJson = this.tryJsonParse(droppedItemTemp);
    var droppedItem = droppedItemTemp != undefined ? droppedItemJson : droppedItemTemp;
    droppedCMSId = droppedCMSId == null || droppedCMSId == undefined ? null : droppedCMSId;
    var tempDraggedItems = cloneDeep(this.DraggedItems);

    if (draggedItem.CMSName == null && (droppedCMSId == null || droppedCMSId == undefined)) { //Restrict to drag drop from first level to first level
      return false;
    }
    if (draggedItem.CMSId == droppedCMSId) { //Restrict to drag drop from first level to first level
      return false;
    }

    var draggedWS = tempDraggedItems.filter((x: any) => x.WorkstreamId == draggedWSId && x.WorkstreamDropedParentId == droppedCMSId);
    var cmsStatusId = droppedItem ? droppedItem.CmsStatusId : 0;
    var workstreamStatusName = draggedItem ? draggedItem.WorkstreamStatusName : "";
    if (draggedWS == null || draggedWS == undefined || draggedWS.length <= 0) {
      var draggedWSItem = {
        Id: this.draggdropcount,
        CMSId: null,
        SelectedParentId: null,
        WorkstreamId: draggedWSId,
        WorkstreamDraggedParentId: draggedItem.CMSId,
        WorkstreamDropedParentId: droppedCMSId,
        EndToEndId: draggedItem.EndToEndId,
        UserName: this.sessionStorage.enterpriseId
      }
      tempDraggedItems.push(draggedWSItem);
      this.DraggedItems = tempDraggedItems;
      this.draggdropcount = this.draggdropcount + 1;
      this.sessionStorage.CMSUnsavedData = 1;

    }
    else {
      draggedWS[0].WorkstreamDropedParentId = droppedCMSId;
      this.draggdropcount = this.draggdropcount + 1;
      this.sessionStorage.CMSUnsavedData = 1;
    }

    var selectedws = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.WorkstreamId == parseInt(draggedWSId) && x.CMSId == parseInt(draggedItem.CMSId));
    if (selectedws && selectedws.length > 0) {
      selectedws[0].CMSId = droppedCMSId == null ? null : parseInt(droppedCMSId);
      if (selectedws[0].CMSId == null)
        selectedws[0].CMSName = null;
      else
        selectedws[0].CMSName = droppedItem.CMSName;
    }

    var selectedwscmslists = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.WorkstreamId == parseInt(draggedWSId));
    if (selectedwscmslists && selectedwscmslists.length > 0) {
      selectedwscmslists.forEach((selectedws: any, key) => {
        var selectedwscms = selectedws.SelectedWorkstreamCms.filter((x: any) => x.Id == parseInt(draggedItem.CMSId));
        if (selectedwscms && selectedwscms.length > 0) {
          selectedwscms[0].Id = droppedCMSId == null ? null : parseInt(droppedCMSId);
          if (selectedwscms[0].Id == null)
            selectedwscms[0].Name = null;
          else
            selectedwscms[0].Name = droppedItem.CMSName;
        }
      });
    }
    this.removeElement();
    this.UpdateModel();

    if (cmsStatusId == enumCMS.ClosedCMS && workstreamStatusName != "Closed") {
      // no need to use $apply,  Angular takes care of it - https://angular.io/guide/upgrade
      this.Message = "The client structure you’re trying to associate to is closed. Please associate to an active structure.";
      this.IsReset = true;
      this.ShowAlertToReset();

    }
    this.clientstructureForm.form.markAsDirty();
    return true;
  }

  DropCMS(draggedCMSId, droppedCMSId, draggedItemTemp, droppedItemTemp) {
    if (!draggedCMSId) {
      return false;
    }
    var draggedItem = this.tryJsonParse(cloneDeep(draggedItemTemp));
    let droppedItemJson = this.tryJsonParse(droppedItemTemp);
    var droppedItem = droppedItemTemp != undefined ? droppedItemJson : droppedItemTemp;
    droppedCMSId = droppedCMSId == null || droppedCMSId == undefined ? null : droppedCMSId;

    if (!droppedCMSId && draggedItem.Tier == 1) { //Restrict to drag drop from first level to first level
      return false;
    }

    //Restrict to drag drop to five levels
    if (!this.ValidateFiveLevel(draggedItem, droppedItem)) {
      return false;
    }

    //Restrict movement count for invalid move
    if (draggedItem.SelectedParentId == droppedCMSId) { //Restrict to drag drop from first level to first level
      return false;
    }
    var draggedCMS = this.DraggedItems.filter((x: any) => x.CMSId == draggedCMSId);
    var cmsStatusId = droppedItem ? droppedItem.CmsStatusId : 0;
    var cmsDraggedStatusId = draggedItem ? draggedItem.CmsStatusId : 0;
    if (draggedCMS == null || draggedCMS == undefined || draggedCMS.length <= 0) {
      var draggedCMSItem = {
        Id: this.draggdropcount,
        CMSId: draggedCMSId,
        SelectedParentId: droppedCMSId,
        WorkstreamId: null,
        WorkstreamDraggedParentId: null,
        WorkstreamDropedParentId: null,
        EndToEndId: null,
        UserName: this.sessionStorage.enterpriseId
      }
      this.DraggedItems.push(draggedCMSItem);
      this.draggdropcount = this.draggdropcount + 1;
      this.sessionStorage.CMSUnsavedData = 1;
    }
    else {
      draggedCMS[0].SelectedParentId = droppedCMSId;
      this.draggdropcount = this.draggdropcount + 1;
      this.sessionStorage.CMSUnsavedData = 1;
    }

    var selectedcms = this.cmsHierarchy.CMSDataList.filter((x: any) => x.CMSId == parseInt(draggedCMSId));
    if (selectedcms && selectedcms.length > 0) {
      selectedcms[0].SelectedParentId = droppedCMSId == null ? null : parseInt(droppedCMSId);
    }
    this.removeElement();
    this.UpdateModel();
    if (cmsStatusId == enumCMS.ClosedCMS && cmsDraggedStatusId == enumCMS.OpenCMS) {
      this.Message = "The client structure you’re trying to associate to is closed. Please associate to an active structure.";
      this.IsReset = true;
      this.ShowAlertToReset();
    }

    this.clientstructureForm.form.markAsDirty();
    return true;
  }

  ValidateFiveLevel(draggedItem, droppedItem) {
    var draggedLevelCount = this.LevelCount(draggedItem);
    var dropedItemTier = droppedItem ? droppedItem.Tier : 0;
    if (draggedLevelCount + dropedItemTier <= 5) {
      return true;
    } else {
      return false;
    }
  }

  LevelCount(cmsDrag) {
    var level = 1;
    if (cmsDrag.Tier == 1) {
      level = level > 1 ? level : 1;
      cmsDrag.CMSLevelTwo.forEach((cms2: any, key) => {
        level = level > 2 ? level : 2;
        cms2.CMSLevelThree.forEach((cms3: any, key) => {
          level = level > 3 ? level : 3;
          cms3.CMSLevelFour.forEach((cms4: any, key) => {
            level = level > 4 ? level : 4;
            cms4.CMSLevelFive.forEach((cms5: any, key) => {
              level = level > 5 ? level : 5;
            });
          });
        });
      });
    }
    else if (cmsDrag.Tier == 2) {
      level = level > 1 ? level : 1;
      cmsDrag.CMSLevelThree.forEach((cms3: any, key) => {
        level = level > 2 ? level : 2;
        cms3.CMSLevelFour.forEach((cms4: any, key) => {
          level = level > 3 ? level : 3;
          cms4.CMSLevelFive.forEach((cms5: any, key) => {
            level = level > 4 ? level : 4;
          });
        });
      });
    }
    else if (cmsDrag.Tier == 3) {
      level = level > 1 ? level : 1;
      cmsDrag.CMSLevelFour.forEach((cms4: any, key) => {
        level = level > 2 ? level : 2;
        cms4.CMSLevelFive.forEach((cms5: any, key) => {
          level = level > 3 ? level : 3;
        });
      });
    }
    else if (cmsDrag.Tier == 4) {
      level = level > 1 ? level : 1;
      cmsDrag.CMSLevelFive.forEach((cms5: any, key) => {
        level = level > 2 ? level : 2;
      });
    }
    else {
      level = level > 1 ? level : 1;
    }
    return level;
  }

  SaveDraggedCMS() {
    if (this.DraggedItems && this.DraggedItems.length > 0) {
      this.CMSMenuService.SaveDraggedCMS(this.DraggedItems).subscribe((response: any) => {
        var elements = $('.ui-sortable li');
        elements.toArray().forEach((el: any) => {
          $(el).remove()
        });
        this.DraggedItems = [];
        this.UpdateCMSAndWorkstream();
        this.draggdropcount = 0;
        this.sessionStorage.CMSUnsavedData = 0;
        this.ProcessDemographicAttribute(null, enumEntityType.EndToEnd + "|" + enumEntityType.CMS + "|" + enumEntityType.Workstream);
      });
    }
    this.clientstructureForm.form.markAsPristine();
  }

  CancelDraggAndDrop() {
    if (this.DraggedItems && this.DraggedItems.length > 0) {
      var elements = $('.ui-sortable li');
      elements.toArray().forEach((el: any) => {
        $(el).remove();
      });
      this.DraggedItems = [];
      this.LoadCMSStructure(true);
      this.CollapseAfterSave();
      this.ResetSelection();
      this.draggdropcount = 0;
      this.sessionStorage.CMSUnsavedData = 0;
    }
    this.clientstructureForm.form.markAsPristine();
  }

  Delete(data, entity, event) {
    if (this.dragging) {
      return false;
    }
    if (this.DraggedItems && this.DraggedItems.length > 0) {
      this.Message = "Please save the drag & drop changes to continue";
      this.sharedService.openAlertPopup(this.dragDropMessage, window.scrollY);
      return false;
    }
    this.Action = "Delete";
    data.UserName = this.sessionStorage.enterpriseId;
    this.ResetSelection();
    data.IsSelected = true;
    this.DeletedCMS = [];
    this.DeletedWorkstream = [];
    var tempDeletedCMS = [];
    var tempDeletedWorkstream = [];
    var endtoendId = this.sessionStorage.endToEndId;

    if (entity == 'cms') {
      this.cmsHierarchy.TypeId = 1;
      if (data.CMSId != 0 && data.CMSId != undefined && data.CMSId != null) {
        //var list = $(event.currentTarget).parent('div').parent('li').find('.cms-tms-item');
        var list = $(event.currentTarget).parent('div').parent('div').parent('li').find('.cms-tms-item');
        if (list.length > 0)
          list = list.toArray();

        list.forEach(function (item, key) {
          var type = $(item).attr('data-type');
          if (type == "CMS") {
            var cms = {
              CMSId: $(item).parent('li').attr('data-cmsId'),
              UserName: data.UserName,
              EndToEndId: endtoendId,
              SelectedParentId: data.SelectedParentId
            }
            tempDeletedCMS.push(cms);
          }
          else {
            var workstream = {
              WorkstreamId: $(item).parent('li').attr('data-wsId'),
              CMSId: $(item).parent('li').parent('ul').parent('li').attr('data-cmsId'),
              UserName: data.UserName,
              EndToEndId: endtoendId,
              FactoryWorkstreamIndicator: $(item).parent('li').attr('data-wsfactoryindicator') == undefined ? "" : $(item).parent('li').attr('data-wsfactoryindicator')
            }
            tempDeletedWorkstream.push(workstream);
          }
        });

        this.DeletedCMS = tempDeletedCMS;
        this.DeletedWorkstream = tempDeletedWorkstream;
        if (this.DeletedWorkstream.length > 0)
          this.confirmationMessage = "The Client Management Structure that you are trying to delete will no longer exist, along with the workstreams associated to it. If you wish to retain the workstreams in scope, click 'Cancel' to facilitate drag and drop of workstreams to be associated with other client management structures before proceeding to delete.";
        else if (this.DeletedCMS.length > 1)
          this.confirmationMessage = "The Client Management Structure that you are trying to delete will no longer exist, along with the Client Management Structure associated to it. If you wish to retain them in scope, click 'Cancel' to facilitate drag and drop the Client Management Structure to be associated with other client management structures before proceeding to delete.";
        else
          this.confirmationMessage = "The Client Management Structure that you are trying to delete will no longer exist. Are you sure you want to proceed?";

        var confirmationModel = this.sharedService.openConfirmationPopup(this.confirmationMessage, "Ok", "Cancel", window.scrollY);
        confirmationModel.content.confirmationResult.subscribe(response => {
          if (response.result) {
            this.ConfirmedYes();
          } else {
            this.ConfirmedNo();
          }
        });

      }
    }
  }

  ConfirmedYes() {
    if (this.Action == "Delete") {
      var cmsHierarchyDetails = {
        DeletedCMS: this.DeletedCMS,
        DeletedWorkstream: this.DeletedWorkstream
      }
      if (this.cmsHierarchy.TypeId == 1) {
        this.CMSMenuService.DeleteCMS(cmsHierarchyDetails).subscribe((response: any) => {
          if (this.sessionStorage.clientWMSId) {
            this.sessionStorage.clientWMSId = 0;
            this.sessionStorage.clientWMSUId = '';
            this.sessionStorage.cmsName = "";
            this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
            if (this.sessionStorage.workstreamId) {
              this.sessionStorage.workstreamUId = '';
              this.sessionStorage.workstreamId = 0;
              this.sessionStorage.workstreamName = null;
            }
            var cmsParent = this.cmsHierarchy.CMSDataList.filter((x: any) => x.CMSId == this.DeletedCMS[0].SelectedParentId)[0];
            if (cmsParent) {
              this.sessionStorage.clientWMSUId = cmsParent.CMSUId;
              this.sessionStorage.clientWMSId = cmsParent.CMSId;
              this.sessionStorage.cmsName = cmsParent.CMSName;
              this.sessionStorage.selectedSubmissionType = enumSubmissionType.ClientWMS;
            }
          }
          else {
            this.LoadCMSTabData();
          }
          this.TempcurrentSelectedCMS = null;
          this.currentSelectedCMS = null;

          this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });
          this.Message = "Deleted successfully";
          this.IsReset = false;
          this.sharedService.openAlertPopup(this.Message, window.scrollY)
          this.ProcessDemographicAttribute(null, enumEntityType.EndToEnd + "|" + enumEntityType.CMS + "|" + enumEntityType.Workstream);
        });
      }
      else if (this.cmsHierarchy.TypeId == 2) {
        this.CMSMenuService.DeleteWorkstream(cmsHierarchyDetails).subscribe((response: any) => {
          if (response != "") {
            this.isSICoreMetricsFlagUpdated = true;
          }
          if (this.sessionStorage.workstreamId) {
            this.sessionStorage.workstreamUId = '';
            this.sessionStorage.workstreamId = 0;
            this.sessionStorage.workstreamName = null;
            this.sessionStorage.clientWMSId = 0;
            this.sessionStorage.clientWMSUId = '';
            this.sessionStorage.cmsName = "";
            this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
            var cmsParent = this.cmsHierarchy.CMSDataList.filter((x: any) => x.CMSId == this.DeletedWorkstream[0].CMSId)[0];
            if (cmsParent && cmsParent.CMSName != null) {
              this.sessionStorage.clientWMSUId = cmsParent.CMSUId;
              this.sessionStorage.clientWMSId = cmsParent.CMSId;
              this.sessionStorage.cmsName = cmsParent.CMSName;
              this.sessionStorage.selectedSubmissionType = enumSubmissionType.ClientWMS;
            }
          }
          else {
            this.LoadCMSTabData();
          }
          this.TempcurrentSelectedWS = null;
          this.currentSelectedWS = null;

          this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });
          if (!this.isSICoreMetricsFlagUpdated) {
            this.Message = "Deleted successfully";
            this.IsReset = false;
            this.sharedService.openAlertPopup(this.Message, window.scrollY);
          }
          else {
            this.Message = 'Deleted successfully.<br/><b>Note:</b> All the active Conventional/Agile release type of workstreams are now deleted.';
            this.IsReset = false;
            this.sharedService.openAlertPopup(this.Message, window.scrollY);
          }
          this.ProcessDemographicAttribute(null, enumEntityType.EndToEnd + "|" + enumEntityType.Workstream);
        });
      }
    }
  }

  ConfirmedNo() {
    if (this.Action == "Delete") {
      this.Action = "Edit";
    }
  }

  CloseAlert() {
    if (this.IsReset) {
      this.CancelDraggAndDrop();
    }
  }

  SetSelectedValues(event, cms, ws) {
    if (ws != null && ws != undefined) {
      var a = event.target.id;
      var b = "WS" + ws.WorkstreamId;
      if (a == b)
        return false;
    }
    if (this.dragging) {
      return false;
    }
    this.TempcurrentSelectedCMS = cloneDeep(cms);
    this.currentSelectedCMS = cloneDeep(cms);
    this.TempcurrentSelectedWS = cloneDeep(ws);
    this.currentSelectedWS = cloneDeep(ws);
    this.IsWorkStreamSelected = false;
    this.IsCMSSelected = false;
    this.IsFifthLevelCMS = false;
    if (cms) {
      this.currentSelectedCMS.TempListCMSClientStakeholder = cloneDeep(this.currentSelectedCMS.ClientStakeholderList);
      cms.IsChildCollapsed = !cms.IsChildCollapsed;
      if (cms.IsChildCollapsed) {
        this.currentSelectedCMS = null;
        this.TempcurrentSelectedCMS = null;
        this.ResetSelection();
      }
      else {
        this.cmsHierarchy.TypeId = 1;
        this.ResetSelection();
        cms.IsSelected = true;
      }
      var selectedCMS = this.cmsHierarchy.CMSDataList.filter((x: any) => x.CMSId == cms.CMSId);
      if (selectedCMS && selectedCMS.length > 0) {
        selectedCMS[0].IsChildCollapsed = cms.IsChildCollapsed;
        selectedCMS[0].IsSelected = cms.IsSelected;
      }
      if (this.currentSelectedCMS != null && this.currentSelectedCMS.Tier == 5) {
        this.IsFifthLevelCMS = true;
      }
      if (cms.CmsStatusId == enumCMS.ClosedCMS) {
        this.IsCMSSelected = true;
      }
    }
    else {
      if (ws.IsSelected) {
        this.currentSelectedWS = null;
        this.TempcurrentSelectedWS = null;
        this.ResetSelection();
      }
      else {
        this.cmsHierarchy.TypeId = 2;
        this.ResetSelection();
        this.IsWorkStreamSelected = true;
        ws.IsSelected = true;
      }
      var selectedWS = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.WorkstreamId == ws.WorkstreamId && x.CMSId == ws.CMSId);
      if (selectedWS && selectedWS.length > 0) {
        selectedWS[0].IsSelected = ws.IsSelected;
      }
    }
  }

  Edit(cms, ws) {
    if (this.dragging) {
      return false;
    }
    this.Action = "Edit";
    this.TempcurrentSelectedCMS = cloneDeep(cms);
    this.currentSelectedCMS = cloneDeep(cms);
    this.TempcurrentSelectedWS = cloneDeep(ws);
    this.currentSelectedWS = cloneDeep(ws);
    this.IsWorkStreamSelected = false;
    this.IsCMSSelected = false;
    this.IsFifthLevelCMS = false;
    this.ResetSelection();

    if (this.DraggedItems && this.DraggedItems.length > 0) {
      this.Message = "Please save the drag & drop changes to continue";
      this.IsReset = false;
      this.sharedService.openAlertPopup(this.dragDropMessage, window.scrollY);
      return false;
    }
    else {
      if (this.cmsHierarchy.TypeId == 0) {
        this.cmsHierarchy.TypeId = this.currentSelectedCMS != null ? 1 : (this.currentSelectedWS != null ? 2 : 0);
      }
      this.cmsHierarchy.IsTypeStatusShow = false;
      if (cms == null) { //WS
        if (ws.IsSelected) {
          this.currentSelectedWS = null;
          this.TempcurrentSelectedWS = null;
        }
        else {
          this.cmsHierarchy.TypeId = 2;
          this.IsWorkStreamSelected = true;
          ws.IsSelected = true;
        }

        var selectedWS = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.WorkstreamId == ws.WorkstreamId && x.CMSId == ws.CMSId);
        if (selectedWS && selectedWS.length > 0) {
          selectedWS[0].IsSelected = ws.IsSelected;
        }
        if (this.currentSelectedWS.SelectedWorkstreamAvailablityWindow.length == 0) {
          this.currentSelectedWS.SelectedWorkstreamAvailablityWindow = cloneDeep(this.cmsHierarchy.GetDefaultWeekDays);
        }
        this.cmsHierarchy.TempWorkstreamStatus = cloneDeep(this.cmsHierarchy.WorkstreamStatus);
        if (this.currentSelectedWS.FactoryWorkstreamIndicator == "Y") {
          this.SFEDisable = true;
          this.PopupTitle = this.currentSelectedWS.WorkStreamName;
        }
        else {
          this.SFEDisable = false;
          this.PopupTitle = "Edit : " + this.currentSelectedWS.WorkStreamName;
        }
        this.sessionStorage.SFEDisable = this.SFEDisable;

      }
      else { //CMS
        this.currentSelectedCMS.TempListCMSClientStakeholder = cloneDeep(this.currentSelectedCMS.ClientStakeholderList);
        if (cms.IsSelected) {
          cms.IsSelected = false;
        }
        else {
          this.cmsHierarchy.TypeId = 1;
          cms.IsSelected = true;
        }
        var selectedCMS = this.cmsHierarchy.CMSDataList.filter((x: any) => x.CMSId == cms.CMSId);
        if (selectedCMS && selectedCMS.length > 0) {
          selectedCMS[0].IsChildCollapsed = cms.IsChildCollapsed;
          selectedCMS[0].IsSelected = cms.IsSelected;
        }
        this.PopupTitle = this.sessionStorage.SFEDisable == true ? this.currentSelectedCMS.CMSName : "Edit : " + this.currentSelectedCMS.CMSName;
      }

      const initialState = {
        Action: this.Action,
        PopupTitle: this.PopupTitle,
        cmsHierarchy: cloneDeep(this.cmsHierarchy),
        TempcurrentSelectedCMS: cloneDeep(cms),
        currentSelectedCMS: cloneDeep(cms),
        TempcurrentSelectedWS: cloneDeep(ws),
        currentSelectedWS: cloneDeep(ws),
        IsFifthLevelCMS: false,
        SFEDisable: this.SFEDisable,
        IsShowMultiContractLink: this.IsShowMultiContractLink,
        scrollYPosition: window.scrollY,
        AssociatedContractsEndToEnd: cloneDeep(this.AssociatedContractsEndToEnd)
      };
      this.modalRef = this.modalService.show(CMSAddEditComponent, { initialState, backdrop: "static", keyboard: false });
      $(".modal-dialog").addClass('modal-lg');
      this.modalRef.content.setAfterSave.subscribe(result => {
        if (result.parentActionToPerform == 'SaveCMS') {
          this.currentSelectedCMS = result.currentSelectedCMS;
          this.AfterSaveCMSDetails();
          this.LoadCMSTabData();
        }
        else if (result.parentActionToPerform == 'SaveWS') {
          this.currentSelectedWS = result.currentSelectedWS;
          this.Action = result.popupAction;
          this.isSICoreMetricsFlagUpdated = result.isFlageUpdated;
          this.AfterSaveWSDetails();
          this.LoadCMSTabData();
        }
        else if (result.parentActionToPerform == 'Reset') {
          this.Action = result.popupAction;
          this.AfterCMSPopupClose();
        }
        else if (result.parentActionToPerform == 'DeleteWS') {
          this.Action = result.popupAction;
          this.DeletedWorkstream = result.DeletedWorkstream;
          this.isSICoreMetricsFlagUpdated = result.isFlageUpdated;
          this.ConfirmedYes();
        }
      });
    }

  }

  AfterCMSPopupClose() {
    this.currentSelectedCMS = null;
    this.TempcurrentSelectedCMS = null;
    this.currentSelectedWS = null;
    this.TempcurrentSelectedWS = null;
    this.ResetSelection();
    this.cmsHierarchy.TypeId = 0;
    this.sessionStorage.TabChanges = false;
  }
  //newly created
  AfterSaveCMSDetails() {
    this.UpdateCMSAndWorkstream();
    if (this.currentSelectedCMS.LifeCycle == 2) {
      this.ProcessDemographicAttribute(null, enumEntityType.EndToEnd + "|" + enumEntityType.CMS);
      this.sessionStorage.clientWMSId = 0;
      this.sessionStorage.cmsName = "";
      this.sessionStorage.selectedSubmissionType = 1;
      this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
      this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });
      return false;
    }
    else {
      if (this.sessionStorage.cmsName && this.sessionStorage.cmsName != this.currentSelectedCMS.CMSName && this.Action == "Edit") {
        this.sessionStorage.cmsName = this.currentSelectedCMS.CMSName;
        this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });
      }
      this.ProcessDemographicAttribute(null, enumEntityType.EndToEnd + "|" + enumEntityType.CMS);
    }
    this.sessionStorage.TabChanges = false;
  }

  AfterSaveWSDetails() {
    this.UpdateCMSAndWorkstream();
    if (this.sessionStorage.workstreamName && this.sessionStorage.workstreamName != this.currentSelectedWS.WorkStreamName && this.Action == "Edit") {
      this.sessionStorage.workstreamName = this.currentSelectedWS.WorkStreamName;
      this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });
    }
    this.sessionStorage.TabChanges = false;
    this.ProcessDemographicAttribute(null, enumEntityType.EndToEnd + "|" + enumEntityType.Workstream);
    if (this.currentSelectedWS.ClientEndToEndId) {
      this.ProcessDemographicAttribute(this.currentSelectedWS.ClientEndToEndId, enumEntityType.EndToEnd + "|" + enumEntityType.Workstream);
    }
    this.sessionStorage.TabChanges = false;
  }

  Add(type) {
    if (this.DraggedItems && this.DraggedItems.length > 0) {
      this.Message = "Please save the drag & drop changes to continue";
      this.IsReset = false;
      this.sharedService.openAlertPopup(this.Message, window.scrollY);
      return false;
    }
    else {
      this.Action = "ADD";
      this.SFEDisable = false;
      this.cmsHierarchy.TypeId = type;
      this.cmsHierarchy.IsTypeStatusShow = type == 1 ? true : false;
      this.PopupTitle = type == 1 ? "Add Client Management Structure" : "Add Workstream";
      const initialState = {
        Action: this.Action,
        PopupTitle: this.PopupTitle,
        cmsHierarchy: cloneDeep(this.cmsHierarchy),
        currentSelectedCMS: cloneDeep(this.currentSelectedCMS),
        currentSelectedWS: cloneDeep(this.currentSelectedWS),
        TempcurrentSelectedCMS: cloneDeep(this.TempcurrentSelectedCMS), //newly added, for LoadCMSWSData()
        IsShowMultiContractLink: this.IsShowMultiContractLink,
        AssociatedContractsEndToEnd: cloneDeep(this.AssociatedContractsEndToEnd),
        SFEDisable: this.SFEDisable
      };

      // to load the cmsaddedit component dynamically
      //const addWSCompFactory = this.componentFactoryResolver.resolveComponentFactory(CMSAddEditComponent);
      //const hostViewContainerRef = this.cmsHost.viewContainerRef;
      //hostViewContainerRef.clear();
      //const compRef = hostViewContainerRef.createComponent(addWSCompFactory);
      //compRef.instance.cmsObj = initialState;

      this.modalRef = this.modalService.show(CMSAddEditComponent, { initialState, backdrop: "static", keyboard: false });
      $(".modal-dialog").addClass('modal-lg');


      //subscription for close window
      //this.closeSub = compRef.instance.close.subscribe(() => {
      //  this.closeSub.unsubscribe();
      //  hostViewContainerRef.clear();
      //})

      /*     compRef.instance.setAfterSave.subscribe(result => {*/
      this.modalRef.content.setAfterSave.subscribe(result => {
        if (result.parentActionToPerform == 'SaveCMS') {
          this.currentSelectedCMS = result.currentSelectedCMS;
          this.AfterSaveCMSDetails();
          this.LoadCMSTabData();
        }
        else if (result.parentActionToPerform == 'SaveWS') {
          this.currentSelectedWS = result.currentSelectedWS;
          this.Action = result.popupAction;
          this.isSICoreMetricsFlagUpdated = result.isFlageUpdated;
          this.AfterSaveWSDetails();
          this.LoadCMSTabData();
        }
        else if (result.parentActionToPerform == 'Reset') {
          this.Action = result.popupAction;
          this.AfterCMSPopupClose();
        }
      });
    }
  }

  Resetcms() {
    this.TempcurrentSelectedCMS = null;
    this.ResetSelection();
  }

  ResetSelection() {
    this.IsWorkStreamSelected = false;
    this.IsCMSSelected = false;
    this.IsFifthLevelCMS = false;

    if (isEqual(this.cmsHierarchy, {}))
      return;
    this.cmsHierarchy.WorkStreamStructure.forEach((ws0: any, key) => {
      ws0.IsSelected = false;
    });
    this.cmsHierarchy.CMSLevelOne.forEach((cms1: any, key) => {
      cms1.IsSelected = false;
      cms1.WorkStreamStructure.forEach((ws1: any, key) => {
        ws1.IsSelected = false;
      });
      cms1.CMSLevelTwo.forEach((cms2: any, key) => {
        cms2.IsSelected = false;
        cms2.WorkStreamStructure.forEach((ws2: any, key) => {
          ws2.IsSelected = false;
        });
        cms2.CMSLevelThree.forEach((cms3: any, key) => {
          cms3.IsSelected = false;
          cms3.WorkStreamStructure.forEach((ws3: any, key) => {
            ws3.IsSelected = false;
          });
          cms3.CMSLevelFour.forEach((cms4: any, key) => {
            cms4.IsSelected = false;
            cms4.WorkStreamStructure.forEach((ws4: any, key) => {
              ws4.IsSelected = false;
            });
            cms4.CMSLevelFive.forEach((cms5: any, key) => {
              cms5.IsSelected = false;
              cms5.WorkStreamStructure.forEach((ws5: any, key) => {
                ws5.IsSelected = false;
              });
            });
          });
        });
      });
    });

    this.cmsHierarchy.WorkstreamDataList.forEach((ws: any, key) => {
      ws.IsSelected = false;
    });
    this.cmsHierarchy.CMSDataList.forEach((cms: any, key) => {
      cms.IsSelected = false;
    });
  }
  //ExpandAll(type) { // type is not not passed and being used from function call in html
  ExpandAll() {
    this.isExpanded = true;
    this.currentSelectedCMS = null;
    this.TempcurrentSelectedWS = null;
    this.currentSelectedWS = null;
    this.TempcurrentSelectedCMS = null;
    this.DisableAddButton = false;
    this.IsWorkStreamSelected = false;
    this.IsCMSSelected = false;
    this.IsFifthLevelCMS = false;
    this.cmsHierarchy.WorkStreamStructure.forEach((ws0: any, key) => {
      ws0.IsSelected = false;
    });
    this.cmsHierarchy.CMSLevelOne.forEach((cms1: any, key) => {
      cms1.IsChildCollapsed = false;
      cms1.IsSelected = false;
      cms1.WorkStreamStructure.forEach((ws1: any, key) => {
        ws1.IsSelected = false;
      });
      cms1.CMSLevelTwo.forEach((cms2: any, key) => {
        cms2.IsChildCollapsed = false;
        cms2.IsSelected = false;
        cms2.WorkStreamStructure.forEach((ws2: any, key) => {
          ws2.IsSelected = false;
        });
        cms2.CMSLevelThree.forEach((cms3: any, key) => {
          cms3.IsChildCollapsed = false;
          cms3.IsSelected = false;
          cms3.WorkStreamStructure.forEach((ws3: any, key) => {
            ws3.IsSelected = false;
          });
          cms3.CMSLevelFour.forEach((cms4: any, key) => {
            cms4.IsChildCollapsed = false;
            cms4.IsSelected = false;
            cms4.WorkStreamStructure.forEach((ws4: any, key) => {
              ws4.IsSelected = false;
            });
            cms4.CMSLevelFive.forEach((cms5: any, key) => {
              cms5.IsChildCollapsed = false;
              cms5.IsSelected = false;
              cms5.WorkStreamStructure.forEach((ws5: any, key) => {
                ws5.IsSelected = false;
              });
            });
          });
        });
      });
    });

    this.cmsHierarchy.WorkstreamDataList.forEach((ws: any, key) => {
      ws.IsSelected = false;
    });
    this.cmsHierarchy.CMSDataList.forEach((cms: any, key) => {
      cms.IsSelected = false;
      cms.IsChildCollapsed = false;
    });
  }
  // type is not not passed and being used from function call in html
  CollapseAll() {
    this.isExpanded = false;
    this.currentSelectedCMS = null;
    this.TempcurrentSelectedWS = null;
    this.currentSelectedWS = null;
    this.TempcurrentSelectedCMS = null;
    this.DisableAddButton = false;
    this.IsWorkStreamSelected = false;
    this.IsCMSSelected = false;
    this.IsFifthLevelCMS = false;
    this.cmsHierarchy.WorkStreamStructure.forEach((ws0, key) => {
      ws0.IsSelected = false;
    });
    this.cmsHierarchy.CMSLevelOne.forEach((cms1, key) => {
      cms1.IsChildCollapsed = true;
      cms1.IsSelected = false;
      cms1.WorkStreamStructure.forEach((ws1, key) => {
        ws1.IsSelected = false;
      });
      cms1.CMSLevelTwo.forEach((cms2, key) => {
        cms2.IsChildCollapsed = true;
        cms2.IsSelected = false;
        cms2.WorkStreamStructure.forEach((ws2, key) => {
          ws2.IsSelected = false;
        });
        cms2.CMSLevelThree.forEach((cms3, key) => {
          cms3.IsChildCollapsed = true;
          cms3.IsSelected = false;
          cms3.WorkStreamStructure.forEach((ws3, key) => {
            ws3.IsSelected = false;
          });
          cms3.CMSLevelFour.forEach((cms4, key) => {
            cms4.IsChildCollapsed = true;
            cms4.IsSelected = false;
            cms4.WorkStreamStructure.forEach((ws4, key) => {
              ws4.IsSelected = false;
            });
            cms4.CMSLevelFive.forEach((cms5, key) => {
              cms5.IsChildCollapsed = true;
              cms5.IsSelected = false;
              cms5.WorkStreamStructure.forEach((ws5, key) => {
                ws5.IsSelected = false;
              });
            });
          });
        });
      });
    });

    this.cmsHierarchy.WorkstreamDataList.forEach((ws, key) => {
      ws.IsSelected = false;
    });
    this.cmsHierarchy.CMSDataList.forEach((cms, key) => {
      cms.IsSelected = false;
      cms.IsChildCollapsed = true;
    });
  }

  UpdateCMSAndWorkstream() {
    this.CMSMenuService.CMSHierarchyStructure().subscribe((response: any) => {
      var elements = $('.ui-sortable li');
      elements.toArray().forEach((el: any) => {
        $(el).remove()
      });
      this.DraggedItems = [];
      this.draggdropcount = 0;
      this.sessionStorage.CMSUnsavedData = 0;
      this.dragging = 0;
      this.IsShowModifiedDetails = true;

      var existingCms = cloneDeep(this.cmsHierarchy.CMSDataList);
      var existingWorkstream = cloneDeep(this.cmsHierarchy.WorkstreamDataList);
      this.cmsHierarchy = response;
      if (response) {
        this.AssociatedContractsEndToEnd = response.AssociatedContractsEndToEnd;
        if (response.MutiContractPreferenceClientIds) {
          var multiContractPreference = response.MutiContractPreferenceClientIds.filter((x: any) => x.Name == this.sessionStorage.clientId).length;
          if (multiContractPreference > 0) {
            this.IsShowMultiContractLink = true;
          }
        }
      }
      existingCms.forEach((item: any) => {
        var cms = this.cmsHierarchy.CMSDataList.filter((x: any) => x.CMSId == item.CMSId)[0];
        if (cms) {
          cms.IsChildCollapsed = item.IsChildCollapsed;
          cms.IsSelected = item.IsSelected;
          if (item.IsSelected == true) {
            this.currentSelectedCMS = cms;
          }
        }
      });
      existingWorkstream.forEach((item: any) => {
        var workstream = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.WorkstreamId == item.WorkstreamId && x.CMSId == item.CMSId)[0];
        if (workstream) {
          workstream.IsSelected = item.IsSelected
        }
      });
      this.UpdateModel();

      if (this.cmsHierarchy.WorkstreamDataList.length == 0 && this.cmsHierarchy.CMSDataList.length == 0) {
        this.IsShowModifiedDetails = false;
      }
      this.expandParent(this.trackExpandedElements);
      if (!this.isSICoreMetricsFlagUpdated) {
        this.Message = "Saved successfully";
        this.IsReset = false;
        this.sharedService.openAlertPopup(this.Message, window.scrollY);
      } else {
        this.Message = 'Saved successfully.<br/><b>Note:</b> All the active Conventional/Agile release type of workstreams are now closed.';
        this.IsReset = false;
        this.sharedService.openAlertPopup(this.Message, window.scrollY);
      }
    });
  }


  workStreamIconSelection(workStreamTypeId) {
    if (workStreamTypeId == 1)
      this.workStreamTypeIcon = ImagePath.ADImage;
    else if (workStreamTypeId == 2)
      this.workStreamTypeIcon = ImagePath.AMImage;
    else if (workStreamTypeId == 3 || workStreamTypeId == 4 || workStreamTypeId == 5 || workStreamTypeId == 6 || workStreamTypeId == 7 || workStreamTypeId == 8 || workStreamTypeId == 9)
      this.workStreamTypeIcon = ImagePath.AgileImage;
    else if (workStreamTypeId == 10 || workStreamTypeId == 11)
      this.workStreamTypeIcon = ImagePath.InfrastructureImage;
    else if (workStreamTypeId == 12)
      this.workStreamTypeIcon = ImagePath.OperationsImage;
    else if (workStreamTypeId == 13)
      this.workStreamTypeIcon = ImagePath.SecurityImage;
    else
      this.workStreamTypeIcon = null;
    return this.workStreamTypeIcon;
  }

  ProcessDemographicAttribute(endtoEndId, sectionName) {
    this.demographicsService.processDemographicAttribute(endtoEndId == null || endtoEndId == undefined || endtoEndId == "" ? this.sessionStorage.endToEndId : endtoEndId, sectionName).subscribe((response: any) => {
    });
  }

  GetUniqueItems(array, property) {
    var unique = {};
    var distinct = [];
    for (var i in array) {
      if (typeof (unique[array[i][property]]) == "undefined") {
        distinct.push(array[i]);
      }
      unique[array[i][property]] = 0;
    }
    return distinct;
  }

  LoadCMSTabData() {
    this.sessionStorage.teamId = 0;
    this.IsShowModifiedDetails = true;
    this.SFEDisable = false;
    this.headerComponent.ScopeSelecorVisible({
      isScopeSelectorVisible: true,
      isScopeSelectorDropDownVisible: true,
      isSetAsDefaultVisible: false,
      isClientDropDownDisabled: true
    });
    this.AfterSaveCollapseFlag = true;
    this.LoadCMSStructure(false);
    this.CollapseAfterSave();
    //if (setfocus) {
    setTimeout(function () {
      $(".cms-tms-expand").focus();
    })
    //}
    this.VerifyConfigurationManagerRole();
  }

  trackByWorkstreamId(index: number, item: any): any {
    return item.WorkstreamId;
  }
  // General function for try/catch
  tryJsonParse(data) {
    let parsedJson;
    try {
      parsedJson = JSON.parse(data);
    } catch (exception) {
      parsedJson = null;
    }
    return parsedJson;
  }
  //ux revamp changes 
  toggleClientStructure() {
    if (this.isShowEndToEnd) {
      this.isShowEndToEnd = false;
    }
    else {
      this.isShowEndToEnd = true;
    }
  }

  toggleExpandCollapse() {
    if (this.isExpanded) {
      this.ExpandAll();
    }
    else {
      this.CollapseAll();
    }
  }

  DeleteWS(workstream: any) {
    console.log(workstream)
    if (workstream != undefined && workstream != null) {
      this.DeletedWorkstream = [];
      this.cmsHierarchy.TypeId = 2;
      var deletedWS = {
        WorkstreamId: workstream.WorkstreamId,
        CMSId: workstream.CMSId,
        UserName: this.sessionStorage.enterpriseId,
        EndToEndId: this.sessionStorage.endToEndId,
        FactoryWorkstreamIndicator: workstream.FactoryWorkstreamIndicator
      }
      if (workstream.FactoryWorkstreamIndicator != "Y") {

        this.confirmationMessage = "The workstream you are about to delete will no longer exist. Are you sure you want to proceed?";
        var confirmationModel = this.sharedService.openConfirmationPopup(this.confirmationMessage, "Ok", "Cancel",window.scrollY);
        confirmationModel.content.confirmationResult.subscribe(response => {
          if (response.result) {
            this.Action = "Delete";
            this.DeletedWorkstream.push(deletedWS);
            this.ConfirmedYes();
            console.log(response.result);
          }
        });
      }
      else {
        this.sharedService.openAlertPopup("Factory workstream can not be deleted", window.scrollY);
      }
    }
  }

  OnBackClick() {
    this.initiateProfileComponent.OnBackClick();
  }

  expandParent(elements: any) {
    elements.forEach(se => {
      document.querySelectorAll('li').forEach(x => {
          if (x.dataset.cmsid == se.toString()) {
            var element = x.querySelector('.c-list-child');
            if (element != null) {
              element.classList.add('expand');
              $(element.nextSibling).addClass('open');
            }
          }
      })
    })
    if ((this.toggleCollpaseAndExpand.sort().toString() === this.trackExpandedElements.sort().toString()) && (this.toggleCollpaseAndExpand.length > 0 && this.trackExpandedElements.length > 0)) {
      var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
      toggleState.forEach(e => {
        $(e).prop('checked', true);
        this.CollapseExpand = true;
      })
      this.ref.detectChanges();
    }
  }

  collapseParent(e) {
    var track = this.trackExpandedElements
    var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
    var child = document.querySelectorAll(".c-list");
    function expand() {
      child.forEach((ele) => {
        if (ele.querySelector('.-expand')) {
          ele.classList.add('expand');
          ele.nextElementSibling.classList.add('open');
          var elementLI = ele.closest('li');
          var index = track.findIndex(x => x == elementLI.dataset.cmsid);
          if (index == -1)
            track.push(elementLI.dataset.cmsid);

        }
      })
    }
    function collapse() {
      child.forEach((ele) => {
        if (ele.classList.contains('expand')) {
          ele.classList.remove('expand');
          ele.nextElementSibling.classList.remove('open');
          var elementLI = ele.closest('li');
          var index = track.findIndex(x => x == elementLI.dataset.cmsid);
          if (index > -1)
            track.splice(index, 1);

        }
      })
    }
    toggleState.forEach(() => {
      if (e.target.checked) {
        expand();
        this.trackExpandedElements = track;
        this.CollapseExpand = true;
      }
      else {
        collapse();
        this.trackExpandedElements = track;
        this.CollapseExpand = false;
      }
    })
  }

  expandCMSStructureList(element, elementId) {
    var parentnode = element.currentTarget.closest('.c-list')
    if (parentnode) {
      if (parentnode.classList.contains('expand')) {
        var close = parentnode.closest('li').querySelectorAll('.c-list');
        close.forEach((ele) => {
          if (ele.classList.contains('expand')) {
            ele.classList.remove('expand');
            ele.nextSibling.classList.remove('open');
            var index = this.trackExpandedElements.findIndex(x => x == elementId.toString());
            if (index > -1)
              this.trackExpandedElements.splice(index, 1);
            else {
              var innerIndex = this.trackExpandedElements.findIndex(x => x == ele.closest('li').dataset.cmsid.toString());
              this.trackExpandedElements.splice(innerIndex, 1);
            }
            if (this.toggleCollpaseAndExpand.sort().toString() !== this.trackExpandedElements.sort().toString()) {
              var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
              toggleState.forEach(e => {
                $(e).prop('checked', false);
                this.CollapseExpand = false;
              })
              this.ref.detectChanges();
            }
          }
        })
      }
      else {
        parentnode.classList.add('expand');
        parentnode.nextSibling.classList.add('open');
        var index = this.trackExpandedElements.findIndex(x => x == elementId.toString())
        if (index == -1)
          this.trackExpandedElements.push(elementId.toString());

          this.toggleCollpaseAndExpand = [];
        var allElements = document.querySelectorAll('li');
        allElements.forEach(ele => {
          var childElement = ele.querySelector('.c-list-child');
          if (childElement != null) {
            var expandId = childElement.closest('li');
            if (expandId.dataset.cmsid != undefined) {
              var allChildIndex = this.toggleCollpaseAndExpand.indexOf(expandId.dataset.cmsid.toString());
              if (allChildIndex == -1)
                this.toggleCollpaseAndExpand.push(expandId.dataset.cmsid.toString());
            }
          }
        })
        //this.trackExpandedElements.push(elementId);
        if (this.toggleCollpaseAndExpand.sort().toString() === this.trackExpandedElements.sort().toString()) {
          var toggleState = document.querySelectorAll('.c-twoSideToggle__switch-label input[type="checkbox"]');
          toggleState.forEach(e => {
            $(e).prop('checked', true);
            this.CollapseExpand = true;
          })
          this.ref.detectChanges();
        }

      }
    }
  }

  DisplayCMSUserGuide() {
    this.router.navigate(['/mywizard/cmsuserguide']);
  }
} // end of component
