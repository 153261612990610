import { Component, Input } from '@angular/core'
import { SessionStorage } from '../../core/context/sessionstorage';
import { AvanadeEACService } from '../../core/services/avanadeEAC.service';

@Component({
  selector: 'eac-forecast',
  templateUrl: './eac.forecast.component.html',
  providers: [AvanadeEACService]
})
export class EACForecastComponent {
  AvanadeEAC: any;
  @Input()
  tile: any;
  constructor(private avanadeEACService: AvanadeEACService, private sessionStorage: SessionStorage) { } 
  ngOnInit() {
    this.avanadeEACService.GetAvanadeEAC().subscribe((response) => {
      this.AvanadeEAC = response;
    });
  }
}
