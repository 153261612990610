<div role="tabpanel" id="add-custom">
  <div class="c-table-responsive c-lead__table">
    <table class="c-table">
      <thead>
        <tr>
          <th scope="col">Measure Name</th>
          <th scope="col">Definition</th>
          <th scope="col">Sourcing</th>
          <th scope="col" width="60px" class="c-text-center">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td class="c-text-center"><a href="javascript:;" class="icon-delete -disabled" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete"></a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="c-myWizard-form__input">
    <div class="c-myWizard-form__input-area">
      <div class="o-input    ">
        <label for="end-metric-name">Name</label>
        <input type="text" class="o-input__input" placeholder="Measure Name" value="" id="end-metric-name">
        <input type="text"  name="city" list="Measurename" 
               style="height: 34px !important; border-radius: 4px!important; border-width: thin "               
               (change)="onmeasureChange($event.target.value)" maxlength="255" (blur)="ValidateField($event.target.value,'name')"
               [disabled]="IsEdit" [(ngModel)]="measureName"
               placeholder="Measure Name">
               <!-- [ngClass]="measureNameError||measureError ? 'form-control border_red ':'form-control '">-->
        <datalist id="Measurename">
          <option *ngFor="let item of newMeasureList" [value]="item.name">{{item.name}}</option>
          <!--<option value="Cambridge">-->
        </datalist>
        <p class="o-input__error-text">Enter the value</p>
        <p class="o-input__char-text">30/30 characters</p>
      </div>
    </div>
    <div class="c-myWizard-form__input-area">
      <div class="o-input    ">
        <label for="custom-abbreviation">Abbreviation</label>
        <input type="text" class="o-input__input" placeholder="Enter Abbreviation"
               name="abbreviation" value="" id="custom-abbreviation"
               [disabled]="IsEdit" [(ngModel)]="Abrrivation"
               maxlength="50">               
        <p class="o-input__error-text">Enter the value</p>
        <p class="o-input__char-text">30/30 characters</p>
      </div>
    </div>
    <div class="c-myWizard-form__input-area -comp-col">
      <div class="o-text-area">
        <label for="custom-desp">Definition</label>
        <textarea class="o-text-area__input" placeholder="Enter" id="custom-desp"
                  name="definition" rows="3" cols="3" maxlength="1000"
                  [disabled]="IsEdit" [(ngModel)]="Defination"
                  (blur)="ValidateField($event.target.value,'defi')"></textarea>                  
                  <!--[ngClass]="DefinationError ? 'form-control border_red':'form-control '" ></textarea>-->
      </div>
    </div>
  </div>
  <div class="c-myWizard-form__input three-col-input">
    <div class="c-myWizard-form__input-area">
      <div class="c-select-dropdown   ">
        <label for="custom-unit">Unit</label>
        <div class="c-dropdown" id="custom-unit">
          <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>
          <div class="c-dropdown-menu">
            <div class="c-dropdown-body">
              <search-dropdown id="ddlUnit"
                                       [options]="UnitList"
                                       [isShow]="true"
                                       [errormessage]="'Please select Unit'"
                                      [selectText]="'Select'"
                                       [selected]=""
                                       [disableDropdown]="IsAddMeasureButton==false"
                                       [(ngModel)]="Unit">

              </search-dropdown>
              <!--(change)="changeUnit($event.target.value)"-->
              <!--<select style="color: #555;" id="ddlUnit" (change)="changeUnit($event.target.value)"
                      [disabled]="IsAddMeasureButton==false" [(ngModel)]="Unit">-->
                      <!-- [ngClass]="unitError ? 'form-control border_red':'form-control '"  >-->
                <!--<option value="0">Select</option>
                <option *ngFor="let unit of UnitList" [value]="unit.UnitId">{{unit.Name}}</option>
              </select>-->
            </div>
          </div>
        </div>
        <p class="c-select__error-text">Enter the value</p>
      </div>
    </div>
    <div class="c-myWizard-form__input-area">
      <div class="c-select-dropdown   ">
        <label for="field-check">Field Check</label>
        <div class="c-dropdown" id="field-check" *ngIf="!IsEdit">
          <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>
          <div class="c-dropdown-menu">
            <div class="c-dropdown-body">
              <!--<ng-multiselect-dropdown *ngIf="showRA" [placeholder]="'Select'"
                                       [settings]="dropdownSettings"
                                       [data]="myOptionsFieldCheck"
                                       [(ngModel)]="optionsModelFieldCheck"
                                       (onSelect)="onChangeFieldCheck($event)">
              </ng-multiselect-dropdown>
              <ng-multiselect-dropdown *ngIf="!showRA" [placeholder]="'Select'"
                                       [settings]="dropdownSettingsDisabled"
                                       [data]="myOptionsFieldCheck"
                                       [(ngModel)]="optionsModelFieldCheck"
                                       [disabled]="true">
              </ng-multiselect-dropdown>-->
              <multi-selectdropdown *ngIf="showRA"
                                            [options]="myOptionsFieldCheck"
                                            [selected]=""
                                            [selectedparent]=""
                                            [(ngModel)]="optionsModelFieldCheck"
                                            [ishierarchy]="false"
                                            [ariaLabelledby]="'FieldCheck'">

              </multi-selectdropdown>
              <multi-selectdropdown *ngIf="!showRA"
                                            [options]="myOptionsFieldCheck"
                                            [selected]=""
                                            [selectedparent]=""
                                            [(ngModel)]="optionsModelFieldCheck"
                                            [ishierarchy]="false"
                                            [disableDropdown]="true"
                                            [ariaLabelledby]="'FieldCheck'">
              </multi-selectdropdown>
            </div>
          </div>
        </div>
        <!--<div *ngIf="IsEdit" class="form-group autocompleteInput">
          <div class="col-xs-12 col-sm-12" *ngFor="let item of SelectedFieldCheck">
            {{item.name}}
          </div>
        </div>-->
        <p class="c-select__error-text">Enter the value</p>
      </div>
    </div>
    <div class="c-myWizard-form__input-area">
      <div class="c-select-dropdown -disabled   ">
        <label for="recommended-source">Recommended Source</label>
        <div class="c-dropdown" id="recommended-source">
          <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="c-select__selected-text icon-arrow-down">Select</a>
          <div class="c-dropdown-menu">
            <div class="c-dropdown-body">
              <span *ngIf="IsAddMeasureButton==false">{{RecommendedSource}}</span>
              <span *ngIf="IsAddMeasureButton==true">Manual</span>
            </div>
          </div>
        </div>
        <p class="c-select__error-text">Enter the value</p>
      </div>
    </div>
  </div>
</div>
