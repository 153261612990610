import { ChangeDetectorRef, Component, Output, EventEmitter } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import * as Enumerable from 'linq';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorage } from '../../../core/context/sessionstorage';

var $ = (<any>window).$;
declare var window: any;

@Component({
  templateUrl: './metrics.userguide.component.html'
})

export class MetricsUserGuideComponent {

  content1: boolean = false;
  content2: boolean = true;
  content3: boolean = true;
  content4: boolean = true;
  content5: boolean = true;
  content6: boolean = true;
  content7: boolean = true;
  prevButton: boolean = true;
  nextButton: boolean = false;
  constructor(
    private modalService: BsModalService,
    private sessionStorage: SessionStorage,
    private ref: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {

  }

  OnClose() {
    this.router.navigate(['/mywizard/metricselection', 'E2E', this.sessionStorage.endToEndUId]);
  }

  DisplayNextContent(option: any) {
    if (option == 1) {
      this.content1 = true;
      this.content2 = false;
      this.content3 = true;
      this.content4 = true;
      this.content5 = true;
      this.content6 = true;
      this.content7 = true;
      this.prevButton = false;
      this.nextButton = false;
    }
    if (option == 2) {
      this.content1 = true;
      this.content2 = true;
      this.content3 = false;
      this.content4 = true;
      this.content5 = true;
      this.content6 = true;
      this.content7 = true;
      this.prevButton = false;
      this.nextButton = false;
    }
    if (option == 3) {
      this.content1 = true;
      this.content2 = true;
      this.content3 = true;
      this.content4 = false;
      this.content5 = true;
      this.content6 = true;
      this.content7 = true;
      this.prevButton = false;
      this.nextButton = false;
      window.scrollTo(0, 130);
    }
    if (option == 4) {
      this.content1 = true;
      this.content2 = true;
      this.content3 = true;
      this.content4 = true;
      this.content5 = false;
      this.content6 = true;
      this.content7 = true;
      this.prevButton = false;
      this.nextButton = false;
      window.scrollTo(0, 500);
    }
    if (option == 5) {
      this.content1 = true;
      this.content2 = true;
      this.content3 = true;
      this.content4 = true;
      this.content5 = true;
      this.content6 = false;
      this.content7 = true;
      this.prevButton = false;
      this.nextButton = false;
    }
    if (option == 6) {
      this.content1 = true;
      this.content2 = true;
      this.content3 = true;
      this.content4 = true;
      this.content5 = true;
      this.content6 = true;
      this.content7 = false;
      this.prevButton = false;
      this.nextButton = true;
      window.scrollTo(0, 450);
    }

  }

  DisplayPrevContent(option: any) {
    if (option == 2) {
      this.content1 = false;
      this.content2 = true;
      this.content3 = true;
      this.content4 = true;
      this.content5 = true;
      this.content6 = true;
      this.content7 = true;
      this.prevButton = true;
      this.nextButton = false;
    }
    if (option == 3) {
      this.content1 = true;
      this.content2 = false;
      this.content3 = true;
      this.content4 = true;
      this.content5 = true;
      this.content6 = true;
      this.content7 = true;
      this.prevButton = false;
      this.nextButton = false;
    }
    if (option == 4) {
      this.content1 = true;
      this.content2 = true;
      this.content3 = false;
      this.content4 = true;
      this.content5 = true;
      this.content6 = true;
      this.content7 = true;
      this.prevButton = false;
      this.nextButton = false;
    }
    if (option == 5) {
      this.content1 = true;
      this.content2 = true;
      this.content3 = true;
      this.content4 = false;
      this.content5 = true;
      this.content6 = true;
      this.content7 = true;
      this.prevButton = false;
      this.nextButton = false;
      window.scrollTo(0, 130);
    }
    if (option == 6) {
      this.content1 = true;
      this.content2 = true;
      this.content3 = true;
      this.content4 = true;
      this.content5 = false;
      this.content6 = true;
      this.content7 = true;
      this.prevButton = false;
      this.nextButton = false;
      window.scrollTo(0, 500);
    }
    if (option == 7) {
      this.content1 = true;
      this.content2 = true;
      this.content3 = true;
      this.content4 = true;
      this.content5 = true;
      this.content6 = false;
      this.content7 = true;
      this.prevButton = false;
      this.nextButton = false;
    }
  }
}
