import { Component, ViewChild, HostListener } from '@angular/core';
import { DEXService } from '../../core/services/dex.service';
import { SessionStorage } from '../../core/context/sessionstorage';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
declare var window: any;
var $ = (<any>window).$;
import { Observable } from 'rxjs';
import { AlertPopupComponent } from '../../core/components/alert.popup.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HeaderComponent } from '../header.component/header.component';
import { BroadCastService } from '../../core/services/broadcast.service';


@Component({
  templateUrl: './dex.component.html',
  providers: [DEXService, HeaderComponent]

})
export class DEXComponent {

  tabChanges = false;
  redirectId = this.sessionStorage.endToEndUId;
  endToEndId = this.sessionStorage.endToEndId;
  deliveryExcellencePrinciples: any;//= sessionStorage.endToEndUId;
  dexTabData: any;
  parentTileId = this.sessionStorage.parentTileId;
  roleAccess = this.sessionStorage.roleAccessCode;
  sectionName: any;
  section2Name: any;
  section1ID: number;
  section2ID: number;
  lastModifiedBy: any;
  lastModifiedOn: any;
  dexQuestion: any;
  questionId: number;
  mdlOpen: boolean = false;
  isUserAlert: boolean = false;
  isFormSubmitted: any;
  DexQuesId: any;
  modalRef_alertpopup: BsModalRef;
  showGuidelinesflag: boolean = false;
  deliveryLeadFlag: boolean = false;

  @ViewChild('deliveryexcellenceprinciplesform') deliveryexcellenceprinciplesform: NgForm;
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
  if (this.deliveryexcellenceprinciplesform.dirty){
      return confirm('Changes you made may not be saved.');
    }
  else {
      return true;
        }
  }

  constructor(
    private dexservice: DEXService,
    private router: Router,
    private sessionStorage: SessionStorage,
    private activatedRoute: ActivatedRoute,
    private headerComponent: HeaderComponent, private modalService: BsModalService, private broadCastService: BroadCastService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.DexQuesId = 2;
    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['MyWizard/Back/DEX'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId } });
      return false;
    }
    this.LoadDeliveryExcellencePrinciples();
    this.broadCastService.broadcastScopeSelector({
      isScopeSelectorVisible: true,
      isScopeSelectorDropDownVisible: false,
      isClientDropDownDisabled: false,
      isSetAsDefaultVisible: false,
      isDisableTeamScopeSelector: true
    });;
    setTimeout(() => {
      window.collapseComponent();
      window.dropdownComponent();
      window.tabsComponent();
    }, 0);
  }

  getTabName(tabName: any) {
    return tabName.split('</b>')[0].replace('<b>', '');
  }
  getGuidelineName(name: any) {
    return name.split('</b>')[0].replace('<b>', '');
    //if (tabName == "Delivery Lead Certification")
    //  return "Managed Delivery Compliance";
    //else
    //  return tabName;
  }
  getQuestion(question: any) {
    return question.split('</b>')[1].replace('<br>','');
  }

  async SaveDex(formvalid: any, deliveryexcellenceprinciplesform: NgForm) {
    if (this.ValidationCheck()) {
      this.isFormSubmitted = deliveryexcellenceprinciplesform.submitted;
      this.deliveryExcellencePrinciples.EnterpriseId = this.sessionStorage.enterpriseId;
      this.deliveryExcellencePrinciples.endToEndId = this.sessionStorage.endToEndId;
      this.dexservice.saveDeliveryExcellencePrinciples(this.deliveryExcellencePrinciples).subscribe((response: any) => {
        const initialState = { message: 'Saved Successfully', scrollYPosition: window.scrollY, okButton: 'Ok', title: 'Alert' };
        this.modalRef_alertpopup = this.modalService.show(AlertPopupComponent, {
          initialState,
          backdrop: "static",
          keyboard: false
        });
        if (deliveryexcellenceprinciplesform != undefined)
          deliveryexcellenceprinciplesform.form.markAsPristine();
        this.LoadDeliveryExcellencePrinciples();
      });
    }
    else {
      const initialState = { message: 'Please fill all required Fields.', scrollYPosition: window.scrollY, okButton: 'Ok', title: 'Alert' };
      this.modalRef_alertpopup = this.modalService.show(AlertPopupComponent, {
        initialState,
        backdrop: "static",
        keyboard: false
      });
      await this.focusErrorField();
    }
  }

  ValidationCheck() {
    let formvalid: boolean = true;
    let i: number = 0;
    this.deliveryExcellencePrinciples.DexData.forEach((item: any) => {
      if (item.SectionId == 2) {
        i = i + 1;
        if (item.DexAnswerId == null || item.DexAnswerId == 0)// user has selected drop down as -select-
        {
          $("[name='dropDownErrorMessage']").attr("[ngClass]", "is-error");
          formvalid = false;
        }
        else if ((item.DexAnswerId == 10) && (item.DexRationalComment == null || item.DexRationalComment.trim() == '')) // User has selected dropdown value as no
        {
          $("[name='RationaleTesterrormessage']").attr("[ngClass]", "is-error");
          formvalid = false;
        }
      }
    });
    return formvalid;
  }

  ManagedDeliveryPopup(data: any) {
    this.questionId = data;
    this.mdlOpen = true;
    setTimeout(function closePopup() {
      $('.close').focus();
    }, 0);
  }

  closeModal() {
    this.mdlOpen = false;
  }

  LoadDeliveryExcellencePrinciples() {
    this.dexservice.getDeliveryExcellencePrinciples(this.sessionStorage.endToEndId).subscribe(response => {
      this.deliveryExcellencePrinciples = response;
      this.sectionName = this.deliveryExcellencePrinciples.DexData[0].SectionName;
      this.section2Name = this.deliveryExcellencePrinciples.DexData[1].SectionName;
      this.section1ID = this.deliveryExcellencePrinciples.DexData[0].SectionId;
      this.section2ID = this.deliveryExcellencePrinciples.DexData[1].SectionId;
      this.dexQuestion = this.deliveryExcellencePrinciples.DexData[0].DexQuestionName;
      this.lastModifiedBy = this.deliveryExcellencePrinciples.LastModifiedBy;
      this.lastModifiedOn = this.deliveryExcellencePrinciples.LastModifiedOn;
      this.dexTabData = this.deliveryExcellencePrinciples.DexData.filter(x => x.DexQuestionId == 2)
      this.DexQuesId = this.dexTabData[0].DexQuestionId;
    });
  }

  viewTabData(data: any) {
    this.dexTabData.pop();
    this.DexQuesId = data.DexQuestionId;
    this.dexTabData.push(data);
    this.deliveryLeadFlag = false;
  }

  OnBackClick(deliveryexcellenceprinciplesform: NgForm) {
    this.router.navigate(['/mywizard/childtiles/' + this.parentTileId + '/E2E/' + this.redirectId], { relativeTo: this.activatedRoute });
  }

  ChangeDexAnswers(event: any) {
    var yesFlag = false;
    var noFlag = false;
    var nullFlag = false;
    this.deliveryExcellencePrinciples.DexData.forEach((item: any) => {
      if (item.SectionId == 2 && item.DexAnswerId != null && item.DexAnswerId != 0) {
        if (item.DexAnswerId == 9 || item.DexAnswerId == 10) {
          noFlag = true;
        }
        else {
          yesFlag = true;
        }
      }
      else if (item.SectionId == 2 && (item.DexAnswerId == null || item.DexAnswerId == 0)) {
        nullFlag = true;
      }
    });

    if (yesFlag && !noFlag && !nullFlag) {
      this.deliveryExcellencePrinciples.DexData[0].DexAnswerId = 1;
    }
    else {
      this.deliveryExcellencePrinciples.DexData[0].DexAnswerId = 10;
    }
  }

  cancel() {
    this.LoadDeliveryExcellencePrinciples();
  }

  ResetDefaultScope(deliveryexcellenceprinciplesform: NgForm) {
    if (deliveryexcellenceprinciplesform != undefined) deliveryexcellenceprinciplesform.form.markAsPristine();
    this.tabChanges = false;
  }

  focusErrorField() {
    return new Promise(done => {
      setTimeout(() => {
        $("[name='deliveryexcellenceprinciplesform']").find('.has-error')[0].focus();
      }, 0);
    });
  }
  trackById(index: number, item: any): string {
    return item.Id;
  }

  trackByDexQuestionId(index: number, item: any): string {
    return item.DexQuestionId;
  }
  showGuidelines(flag) {
    if(flag)
      this.showGuidelinesflag = false;
    else
      this.showGuidelinesflag = true;
  }
  showGuidelinesForSpecificDelivery(flag){
    if (flag)
      this.deliveryLeadFlag = false;
    else
      this.deliveryLeadFlag = true;
  }
}
