import { Pipe, PipeTransform } from '@angular/core';
var $ = (<any>window).$;

@Pipe({ name: 'commaseparated' })
export class CommaSeparatedPipe implements PipeTransform {
  transform(list: any) {
    if (list && list.length > 0) {
        if (list[0].Name) {
          var names = $.map(list, function (item) { return item.Name ? item.Name.trim() : item.Name; });
          return names.join(', ');
        } else {
          return list.join(', ');
        }
      }
  }
}
