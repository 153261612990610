import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../services/http.service'
import { SessionStorage } from '../context/sessionstorage';
import { Observable } from 'rxjs';
/*var $ = (<any>window).$;*/
@Injectable()

export class AddMeasureService {

  constructor(private httpService: HttpService, private sessionStorage: SessionStorage) { }

  //check access flag
  private accessFlag = false;

  getUnitList(FlagEdit: boolean): Observable<any> {
    let body: Object = { FlagEdit: FlagEdit, EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    /*let encryptData = this.encryptHelper.encrypt(dataAsString);*/
    //this.webapiUrl = global.environment.maaswebapi + 'Admin/Measures/GetMeasuresUnitList?FlagEdit=' + FlagEdit;
    /*this.webapiUrl = global.environment.maaswebapi + 'Admin/Measures/GetMeasuresUnitList';*/
    // return this.http.get(this.webapiUrl);
    return this.httpService.post('/api/Metrics/ ', dataAsString);
  }

  getFieldCheck(UnitId: any): Observable<any> {
    let body: Object = { UnitId: UnitId, EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    //let encryptData = this.encryptHelper.encrypt(dataAsString);
    //this.webapiUrl = global.environment.maaswebapi + 'Admin/Measures/GetFieldCheck';
    return this.httpService.post('/api/Metrics/ ', dataAsString);
  }

  getMeasureNameList(): Observable<any> {
    let body: Object = { EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    //let encryptData = this.encryptHelper.encrypt(dataAsString);
    //this.webapiUrl = global.environment.maaswebapi + 'Admin/Measures/GetMeasuresName';
    return this.httpService.post('/api/Metrics/ ', dataAsString);
  }

  getAssociateMetricMeasure(MeasureId: any): Observable<any> {
    let body: Object = { MeasureId: MeasureId, EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    //let encryptData = this.encryptHelper.encrypt(dataAsString);
    //this.webapiUrl = global.environment.maaswebapi + 'Admin/Measures/GetAssociateMetricMeasure';
    return this.httpService.post('/api/Metrics/ ', dataAsString);
  }

  getMeasureForEdit(UnitId: any, levelId: any): Observable<any> {
    let body: Object = { UnitId: UnitId, levelId: levelId, EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    //let encryptData = this.encryptHelper.encrypt(dataAsString);
    //this.webapiUrl = global.environment.maaswebapi + 'Admin/Measures/getMeasureForEdit';
    return this.httpService.post('/api/Metrics/ ', dataAsString);
  }

  getSourceList(): Observable<any> {
    let body: Object = { EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    //let encryptData = this.encryptHelper.encrypt(dataAsString);
    //this.webapiUrl = global.environment.maaswebapi + 'Admin/Measures/GetSourceList';
    return this.httpService.post('/api/Metrics/ ', dataAsString);
  }

  saveMeasure(MeasureSaveData: any, enterpriseId: any, IsInsert: any): Observable<any> {
    let body: Object = { MeasureSaveData: MeasureSaveData, EnterpriseId: enterpriseId, IsInsert: IsInsert, accessFlag: this.accessFlag };
    let dataAsString: string = JSON.stringify(body);
    //let encryptData = this.encryptHelper.encrypt(dataAsString);
    //this.webapiUrl = global.environment.maaswebapi + 'Admin/Measures/SaveMeasure';
    return this.httpService.post('/api/Metrics/ ', MeasureSaveData);
  }
}
