import { Component, EventEmitter } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
var $ = (<any>window).$;
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DemographicsService } from '../../../core/services/demographics.service';
import { enumEntityType } from '../../../core/context/enum';
import { SharedService } from '../../../core/services/shared.service';
import { MultiContractInfoComponent } from './multi.contract.info.component';
import * as Enumerable from 'linq';
import { SessionStorage } from '../../../core/context/sessionstorage';
import { environment } from '../../../../../src/environments/environment';
declare var window: any;
@Component({
  selector: 'manage-popup',
  templateUrl: './manage.contracts.component.html',
  providers: [DemographicsService, SharedService, BsModalService]
})

export class ManageContractsComponent {
  AssociatedContractsEndToEnd: any = [];
  attributeName: string;
  entity: string;
  CMSId: any;
  WorkstreamId: any;
  gridData: any = [];
  showStatus: boolean;
  selectedValue: any = {};
  associateContractPopUpId: any;
  E2eDemoGraphics: any;
  DeliveryPortfolioDetails: any;
  keyCode: any;
  public contracts: EventEmitter<any> = new EventEmitter();  // .. Child Popup -> Parent
  profileDetails: any;
  constructor(private bsModalRef: BsModalRef, private demographicsService: DemographicsService, private sharedService: SharedService, private modalService: BsModalService, private sessionStorage: SessionStorage) { }
  modalRef_load: BsModalRef;
  ngOnInit() {
    this.selectedValue = null;
    setTimeout(function () {
      window.TooltipFunc();
      $('.close').focus();
    }, 500);
  }

  AddAttributesToArray(updatedGridData, newSelectedValue) {
    var gridData = cloneDeep(updatedGridData);
    var selectedValue = cloneDeep(newSelectedValue);
    if (Object.keys(selectedValue).length == 0) {
      return false;
    }

    var contractCode = this.AssociatedContractsEndToEnd.filter((x: any) => x.Id == selectedValue.Id).map((x: any) => x.Name)[0];
    var multiContract = {
      MultiEndToEndEntityAssociationUId: null,
      EndToEndId: selectedValue.Id,
      ContractCode: contractCode,
      Name: contractCode,
      EntityTypeCode: this.entity == "cms" ? "C" : "W",
      CMSId: this.CMSId,
      WorkstreamId: this.WorkstreamId
    }
    if (gridData) {
      var isDuplicate = gridData.filter((x: any) => x.ContractCode == contractCode)[0];
      if (isDuplicate) {
        this.sharedService.openAlertPopup("Selected " + this.attributeName + " already exists", window.scrollY, "Ok", false, "Alert", false);
      }
      else {
        gridData.push(multiContract);
        this.sharedService.openAlertPopup(this.attributeName + " " + multiContract.ContractCode + " " + "added", window.scrollY,"Ok",false,"Alert",false);
      }
      if (environment.restrictedInstance) {
      gridData.forEach(i => {
        if (i.Status == "Requested") {
          this.sharedService.openAlertPopup("Please reach out to support team to associate this contract.", window.scrollY, "Ok", false, "Alert", false);
        }
      });
    }
    }
    else {
      gridData = [];
      gridData.push(multiContract)
    }
    this.gridData = gridData;
    this.selectedValue = {};
  }

  SaveGridData() {
    this.contracts.emit({
      popupAction: 'GetData', contracts: this.gridData, enableScrollBar: true
    });
    this.selectedValue = null;
    this.bsModalRef.hide();
  }

  CancelGrid(event) {
    this.selectedValue = null;
    this.bsModalRef.hide();
    if (this.showStatus != null) {
      this.contracts.emit({ popupAction: 'Close', contracts: cloneDeep(this.gridData), enableScrollBar: true });
    }
    if (event.keyCode == 13 || event.keyCode == 32 || event == null) {
      this.contracts.emit({ popupAction: 'Close', enableScrollBar: true });
    }
  }

  KeyPressNumeric(event) {
    var iKeyCode = event.keyCode;
    if (iKeyCode >= 48 && iKeyCode <= 57) {
      return true;
    }
    event.stopPropagation();
    event.preventDefault();
  }

  trackById(index: number, data: any): string {
    return data.Id;
  }


  AddAttributesToArrayForProfile(gridData, selectedValue, isProfile) {
    if (!selectedValue) {
      return false;
    }
    if (selectedValue.length != 10) {
      this.sharedService.openAlertPopup("Please enter a valid contract number", window.scrollY, "Ok", false, "Alert", false);
      return false;
    }
    var regexp = new RegExp("^[0-9]{10}$");
    if (!regexp.test(selectedValue)) {
      selectedValue = null;
      this.sharedService.openAlertPopup('Please enter 10 digit number', window.scrollY, "Ok", false, "Alert", false);
      event.stopPropagation();
      event.preventDefault();
      return false;
    }


    var multiContract = {
      MultiContractUId: null,
      ContractCode: selectedValue,
      Name: selectedValue,
      MultiContractEntityAssociationUId: null,
      EntityTypeCode: "E",
      CMSId: null,
      WorkstreamId: null,
    }
    if (gridData) {
      var isDuplicate = gridData.filter(x => x.ContractCode == selectedValue)[0];
      if (isDuplicate) {
        this.sharedService.openAlertPopup("Selected " + this.attributeName + " already exists", window.scrollY, "Ok", false, "Alert", false);
      }
      else {
        if (isProfile) {
          var baseDeployRegion;
          if (this.profileDetails.DeployRegionName == 1)
            baseDeployRegion = "ANZ"
          else if (this.profileDetails.DeployRegionName == 2)
            baseDeployRegion = "EU"
          else if (this.profileDetails.DeployRegionName == 3)
            baseDeployRegion = "US"
          var baseEndToEndId = this.E2eDemoGraphics.E2EId;
          var baseClientId = this.profileDetails.MasterClientId;
          var baseCompanyId = this.DeliveryPortfolioDetails.CompanyId;
          var baseLifecyclePhaseId = this.profileDetails.LifeCyclePhaseId;
          this.demographicsService.validateContract(selectedValue, baseEndToEndId, baseClientId, baseCompanyId, baseLifecyclePhaseId, baseDeployRegion).subscribe(
            (response: any) => {
              if (response) {
                gridData = response;
               }
              else {
                this.sharedService.openAlertPopup("The contract you are trying to associate is financially inactive. Kindly associate a financially active contract.", window.scrollY, "Ok", false, "Alert", false);
              }
              this.gridData = gridData;
              this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, this.E2eDemoGraphics.E2EId, this.demographicsService);
              if (environment.restrictedInstance) {
                this.gridData.forEach(i => {
                  if (i.ContractCode == selectedValue && i.Status == "Requested") {
                    this.sharedService.openAlertPopup("Please reach out to support team to associate this contract.", window.scrollY, "Ok", false, "Alert", false);
                  }
                });
              }
            });
         
        }
        else {
          gridData.push(multiContract);
        }
      }
    }
    else {
      this.gridData = [];
      if (isProfile) {
        var baseDeployRegion;
        if (this.profileDetails.DeployRegionName == 1)
          baseDeployRegion = "ANZ"
        else if (this.profileDetails.DeployRegionName == 2)
          baseDeployRegion = "EU"
        else if (this.profileDetails.DeployRegionName == 3)
          baseDeployRegion = "US"
        baseEndToEndId = this.E2eDemoGraphics.E2EId;
        baseClientId = this.profileDetails.ClientId;
        baseCompanyId = this.DeliveryPortfolioDetails.CompanyId;
        baseLifecyclePhaseId = this.profileDetails.LifeCyclePhaseId;
        this.demographicsService.validateContract(selectedValue, baseEndToEndId, baseClientId, baseCompanyId, baseLifecyclePhaseId, baseDeployRegion).subscribe(
          (response: any) => {
            if (response) {
              gridData = response;
            }
            else {
              this.sharedService.openAlertPopup("The contract you are trying to associate is financially inactive. Kindly associate a financially active contract.", window.scrollY, "Ok", false, "Alert", false);
            }
            this.gridData = gridData;
            this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, this.E2eDemoGraphics.E2EId, this.demographicsService);
            if (environment.restrictedInstance) {
            this.gridData.forEach(i => {
              if (i.ContractCode == selectedValue && i.Status == "Requested") {
                this.sharedService.openAlertPopup("Please reach out to support team to associate this contract.", window.scrollY, "Ok", false, "Alert", false);
              }
            });
          }
          });
        
      }
      else {
        this.gridData.push(multiContract)
      }
    }
    $('#ContractIdInfo').val('');
  }
  showMultiContractInfo() {
    this.modalRef_load = this.modalService.show(MultiContractInfoComponent, {
      backdrop: "static",
      keyboard: false
    });
   }
  DetagContract = function (contractCode, isAssociatedToCMSTMSWS, selectedRow) {
    var baseEndToEndId = this.E2eDemoGraphics.E2EId;
    var isBaseEndToEnd = (this.profileDetails.ContractCode == contractCode) ? true : false;
    var taggedContracts = Enumerable.from(this.profileDetails.AssociatedContractsList).where((x: any) => x.Status == "Added").select((s: any) => s).count();
    if (selectedRow.IsBase) {
      var result = this.sharedService.openAlertPopup("Currently, removing the contract which initiated the E2E setup is not supported.", window.scrollY, "Ok", false, "Alert", false);
      result.content.confirmationResult.subscribe(response => {
        if (response.result) {
          this.demographicsService.DeatagContract(contractCode, isBaseEndToEnd, baseEndToEndId).subscribe(
            (response: any) => {
              this.gridData = response;
              this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, this.E2eDemoGraphics.E2EId, this.demographicsService);
            });
        }
      else{
        return false;
      } });
    }
    else if ((selectedRow.Status == "Added") && taggedContracts == 1) {
      var result = this.sharedService.openAlertPopup("You are about to detag all the contracts from E2E. Please retain atleast one contract at the E2E level.", window.scrollY, "Ok", false, "Alert", false);
      result.content.confirmationResult.subscribe(response => {
        if (!response.result) {
          this.demographicsService.DeatagContract(contractCode, isBaseEndToEnd, baseEndToEndId).subscribe(
            (response: any) => {
              this.gridData = response;
              this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, this.E2eDemoGraphics.E2EId, this.demographicsService);
            }); 
        }
      else{
        return false;
      } });
    }
    else if (isAssociatedToCMSTMSWS) {
      var result = this.sharedService.openConfirmationPopup('This contract is tagged to one or many CMS/WS . Detagging contract from EndToEnd will automatically disassociate contract from the CMS/WS . Please click OK if you want to proceed.', "OK", "Cancel", window.scrollY, "Alert",false);
      result.content.confirmationResult.subscribe(response => {
        if (!response.result) {
          this.demographicsService.DeatagContract(contractCode, isBaseEndToEnd, baseEndToEndId).subscribe(
            (response: any) => {
              this.gridData = response;
              this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, this.E2eDemoGraphics.E2EId, this.demographicsService);
            });
        }
      else{
        return false;
      } });
    }
    else {
     // var result = this.sharedService.openAlertPopup('You are about to detag the contract from E2E. Please click OK if you want to proceed', "Ok", false, "Alert", false);
      var result = this.sharedService.openConfirmationPopup('You are about to detag the contract from E2E. Please click OK if you want to proceed', "OK", "Cancel", window.scrollY, "Alert", false);
          
      result.content.confirmationResult.subscribe(response => {
        if (response.result) {
          this.demographicsService.DeatagContract(contractCode, isBaseEndToEnd, baseEndToEndId).subscribe(
            (response: any) => {
              this.gridData = response;
              this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, this.E2eDemoGraphics.E2EId, this.demographicsService);
            });
        } 
      else{
        return false;
      }});
    }
    // this.demographicsService.DeatagContract(contractCode, isBaseEndToEnd, baseEndToEndId).subscribe(
    //   (response: any) => {
    //     this.gridData = response;
    //     this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, this.E2eDemoGraphics.E2EId, this.demographicsService);
    //   });

  }
  



}
