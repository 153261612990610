<div id="announcementModal" [ngClass]="{ '-show' : ReleaseNotesPreferenceValue }" class="c-modal -static" tabindex="-1" style="visibility: visible;" aria-modal="true" role="dialog" aria-labelledby="modalDialogTitle">
  <div class="c-modal__overlay"></div>
  <div class="c-modal__container">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">Announcement</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" (click)="closeSlide()" aria-label="Close" alt="Close"></button>
      </div>
      <div class="c-modal__body">

        <div class="c-modal__body-label">A New Release is available:</div>
        <div class="c-modal__body-text">Release Name : {{ReleaseName}} ({{ReleaseDate | date:'dd MMM yyyy'}})</div>
       </div>
      <div class="c-modal__footer">
        <div class="c-modal__footer-btnGroup">
          <!-- add class -dismiss-modal to buttons that'll close the modal -->
          <!--Primary Button starts from here-->
          <!-- <a href="javascript:void(0)" type="button" class="o-secondary-button  -dismiss-modal" style="font-size: .750rem;" id="HideAnnouncement"
         (click)="SaveUserPreferenceForReleaseNote(true)"
         [(ngModel)]="ReleaseNotesPreferenceValue">Hide till next Release</a> -->
          <!--Primary Button ends from here-->
          <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" class="o-primary-button  -dismiss-modal" (click)="OpenNotificationsPopUp()" style="font-size: .750rem;">
            <!---->
            View Recent Release Updates
          </a>
          <!--Primary Button ends from here-->
        </div>
      </div>
    </div>
  </div>
</div>
