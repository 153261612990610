<!--<div class="tile-wrap">
  <div class="row page-title" *ngIf="ChildTiles">
    <div class="col-xs-12 col-sm-8 col-lg-10" id="note">
      <h1>
        <a href="javascript:" aria-label="Click to go back" class="return-url back-link" (click)="OnBackClick()"><span class="glyphicon glyphicon-menu-left"></span>Back</a>
        {{ChildTiles.ParentTileName}}
      </h1>
    </div>
  </div>
  <ng-container *ngIf="ChildTiles && ChildTiles.ChildTiles.length>0">
    <div class="row">
      <div *ngFor="let tile of ChildTiles.ChildTiles; trackBy : trackByName;">
        <tile [tile]="tile">    {{tile.Name}}</tile>
      </div>
    </div>
  </ng-container>
</div>-->


<div class="c-portfolio">
  <div class="app-container">

    <div class="c-myWizard__header">
      <div class="c-myWizard__header-content-left">
        <div class="c-myWizard__header-content-head">
          <a href="javascript:;" class="icon-banner-arrow" aria-label="Click to go back" (click)="OnBackClick()" role="button" alt="Back"></a>
          <div class="-header" *ngIf="ChildTiles != undefined"> {{ChildTiles.ParentTileName}}</div>
        </div>
      </div>
    </div>
    <div class="c-myWizard-card" *ngIf="ChildTiles && ChildTiles.ChildTiles.length>0">
      <ng-container *ngFor="let tile of ChildTiles.ChildTiles; ">
        <div class="c-myWizard-card-tile" [ngClass]="{ ' -col-span' : tile.ShortDescription == 'RRI'} ">
          <tile [tile]="tile" [rimResponse]="rimResponse"></tile>
        </div>
      </ng-container>

    </div>
  </div>
</div>



