import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../services/http.service'
import { SessionStorage } from '../context/sessionstorage';
import { Observable } from 'rxjs';
//import { EncryptionService } from './encrypton.service';
var $ = (<any>window).$;

@Injectable()
export class ConfigureMetricService {
  constructor(private httpService: HttpService, private sessionStorage: SessionStorage) { }
  checkDeliveryData(selectedContract: any): Observable<any> {
    let body: Object = { selectedContract: selectedContract, EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    let encryptData = dataAsString//this.encryptionService.encrypt(dataAsString);
    return this.httpService.post('/api/ConfigureMetrics/CheckDeliveryData', encryptData, true);
  }
  getMetricConfigData(selectedContract: any, selectedLevel: number): Observable<any> {
    let body: Object = { selectedContract: selectedContract, selectedLevel: selectedLevel };
    let dataAsString: string = JSON.stringify(body);
    return this.httpService.get('/api/ConfigureMetrics/MetricConfigData' +'?selCont=' + selectedContract + '&selLev=' + selectedLevel,true);
  }
  getBusinessContext(): Observable<any> {
    let body: Object = { EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    let encryptData = dataAsString//this.encryptionService.encrypt(dataAsString);
    return this.httpService.post('/api/ConfigureMetrics/BusinessContext', encryptData);
  }
  getApplicabilitySource(E2EId: any, MetricId: number, selectedLevel: number): Observable<any> {
    return this.httpService.get('/api/ConfigureMetrics/ApplicabilitySource/' + E2EId + '/' + MetricId + '/' + selectedLevel);
  }
  saveMetrics(metricConfigData: any, selectedContract: any, selectedLevel: number, UserId: any) { //117507 added userID
    let body: Object = { metricConfigData: metricConfigData, selectedContract: selectedContract, selectedLevel: selectedLevel, EnterpriseId: UserId };
    let dataAsString: string = JSON.stringify(body);
    var res: Array<any> = new Array<any>();
    res.push({
      MCD: metricConfigData,
      SELC: selectedContract,
      SELVL: selectedLevel,
      EID: UserId
    })
    //let encryptData = this.encryptionService.encrypt(dataAsString);
    return this.httpService.post('/api/ConfigureMetrics/Update', res,true);
  }
  getBusinessProcess(E2EId: any): Observable<any> {
    return null;
  }

  getTrendDirection(DeliveryId: number): Observable<any> {   
    return this.httpService.get('/api/ConfigureMetrics/TrendDirections?deliveryuid=' + DeliveryId);
  }
  getThresholdConfig(DeliveryId: number, levelId: number, enterpriseId: any) {    
    return this.httpService.get('/api/ConfigureMetrics/MetricThresholdsData?DeliveryId=' + DeliveryId + "&LevelId=" + levelId, true);
  }

  // Add Metric popup calls start
  // latest metric pop calls
  getCustomMetricMaster(deliveryid: any): Observable<any> {
    return this.httpService.get('/api/ConfigureMetrics/CustomMetricMaster?deliveryuid=' + deliveryid, true);
  }

  getMeasureList(levelId: any): Observable<any> {
    return this.httpService.get('/api/ConfigureMetrics/MeasureList/' + levelId, true);
  }

  getProcessAndCategory(workstreamTypename: string, deliveryuid: any): Observable<any> {
    return this.httpService.get('/api/ConfigureMetrics/ProcessAndCategoryDetails/' + workstreamTypename + '/' + deliveryuid);

  }

  getCustomMeasureMaster(): Observable<any> {
    return this.httpService.get('/api/ConfigureMetrics/CustomMeasureMaster', true);
  }

  getFieldCheck(UnitId: any): Observable<any> {
    return this.httpService.get('/api/ConfigureMetrics/FieldCheck/' + UnitId);
  }

  getSelectedMetricDetails(deliveryUId: any, metricId: any, levelId: any): Observable<any> {
    return this.httpService.get('/api/ConfigureMetrics/SelectedMetricDetails/' + deliveryUId + '/' + metricId + '/' + levelId,true);
  }
  private accessFlag = false;
  //getUnitList(FlagEdit: boolean): Observable<any> {
  //  let body: Object = { FlagEdit: FlagEdit, EnterpriseId: this.sessionStorage.enterpriseId };
  //  let dataAsString: string = JSON.stringify(body);
  //  let encryptData = this.encryptionService.encrypt(dataAsString);
  //  return this.httpService.post('/api/ConfigureMetrics/GetCustomMetricMaster', encryptData);
  //}

  //getMetricDetails(MetricID: number, LevelId: number, E2EId: any): Observable<any> {
  //  let body: Object = { MetricID: MetricID, LevelId: LevelId, EnterpriseId: this.sessionStorage.enterpriseId, E2EId: E2EId };
  //  let dataAsString: string = JSON.stringify(body);
  //  let encryptData = this.encryptionService.encrypt(dataAsString);
  //  return this.httpService.post('/api/ConfigureMetrics/GetCustomMetricMaster', encryptData);
  //}

  //getThresholdList(): Observable<any> {
  //  let body: Object = { EnterpriseId: this.sessionStorage.enterpriseId };
  //  let dataAsString: string = JSON.stringify(body);
  //  let encryptData = this.encryptionService.encrypt(dataAsString);
  //  return this.httpService.post('/api/ConfigureMetrics/GetCustomMetricMaster', encryptData);
  //}

  //getProcessList(WorskstreamTypeName: any, E2EId: any): Observable<any> {
  //  let body: Object = { WorskstreamTypeName: WorskstreamTypeName, E2EId: E2EId, EnterpriseId: this.sessionStorage.enterpriseId };
  //  let dataAsString: string = JSON.stringify(body);
  //  let encryptData = this.encryptionService.encrypt(dataAsString);
  //  return this.httpService.post('/api/ConfigureMetrics/GetCustomMetricMaster', encryptData);
  //}

  //getProcessCategory(WorskstreamTypeName: any): Observable<any> {
  //  let body: Object = { WorskstreamTypeName: WorskstreamTypeName, EnterpriseId: this.sessionStorage.enterpriseId };
  //  let dataAsString: string = JSON.stringify(body);
  //  let encryptData = this.encryptionService.encrypt(dataAsString);
  //  return this.httpService.post('/api/Metrics/ ', encryptData);
  //} 

  //getWorktypeList(deliveryid: any) {
  //  let body: Object = { deliveryid: deliveryid, EnterpriseId: this.sessionStorage.enterpriseId };
  //  let dataAsString: string = JSON.stringify(body);
  //  let encryptData = this.encryptionService.encrypt(dataAsString);
  //  return this.httpService.post('/api/ConfigureMetrics/GetCustomMetricMaster', encryptData);
  //}

  //getGranularityList(): Observable<any> {
  //  let body: Object = { EnterpriseId: this.sessionStorage.enterpriseId };
  //  let dataAsString: string = JSON.stringify(body);
  //  let encryptData = this.encryptionService.encrypt(dataAsString);
  //  return this.httpService.post('/api/ConfigureMetrics/GetCustomMetricMaster', encryptData);
  //}

  //getFrequency(): Observable<any> {
  //  let body: Object = { EnterpriseId: this.sessionStorage.enterpriseId };
  //  let dataAsString: string = JSON.stringify(body);
  //  let encryptData = this.encryptionService.encrypt(dataAsString);
  //  return this.httpService.post('/api/ConfigureMetrics/GetCustomMetricMaster', encryptData);
  //}

  //getMetricName(): Observable<any> {
  //  let body: Object = { EnterpriseId: this.sessionStorage.enterpriseId };
  //  let dataAsString: string = JSON.stringify(body);
  //  let encryptData = this.encryptionService.encrypt(dataAsString);
  //  //this.webapiUrl = global.environment.maaswebapi + 'Admin/Metrics/GetMetricName';
  //  return this.httpService.post('/api/ConfigureMetrics/GetCustomMetricMaster', encryptData);
  //}

  getAllMetricNames(): Observable<any> {
    let body: Object = { EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    let encryptData = dataAsString//this.encryptionService.encrypt(dataAsString);
    //this.webapiUrl = global.environment.maaswebapi + 'Admin/Metrics/GetAllMetricNames';
    return this.httpService.post('/api/ConfigureMetrics/GetCustomMetricMaster', encryptData);
  }
  // Export metrics to Excel based on applicability rule
  exportToExcel(Exceldata: any, e2eUId:any): Observable<any> {
    debugger;
    //let body: Object = { DeliveryId: DeliveryId, levelId: levelId, EnterpriseId: this.sessionStorage.enterpriseId, type: type };
    //let dataAsString: string = JSON.stringify(body);
   // let inputData = this.encryptionService.encrypt(dataAsString);
    //this.metricsUrl = global.environment.maaswebapi + 'MetricConfigure/ExportToExcel';
    return this.httpService.post('/api/ConfigureMetrics/ExportToExcel?endtoenduid=' + e2eUId, Exceldata,true);
  }
  saveMetric(MetricSaveData: any): Observable<any> {
    //let body: Object = { MetricSaveData: MetricSaveData, EnterpriseId: this.sessionStorage.enterpriseId };
    //let dataAsString: string = JSON.stringify(body);
    //let encryptData = this.encryptionService.encrypt(dataAsString);
    return this.httpService.post('/api/ConfigureMetrics/SaveCustomMetric ', MetricSaveData,true);
  }

  TagExistingMetric(MetricSaveData: any): Observable<any> {
    //let body: Object = { MetricSaveData: MetricSaveData };
    //let dataAsString: string = JSON.stringify(body);
    //let encryptData = this.encryptionService.encrypt(dataAsString);
    return this.httpService.post('/api/ConfigureMetrics/TagExistingMetric ', MetricSaveData,true);

  }



  // Add Metric popup calls end

  // Add measure start


  getUnitListMeasure(FlagEdit: boolean): Observable<any> {
    let body: Object = { FlagEdit: FlagEdit, EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    return this.httpService.post('/api/Metrics/ ', dataAsString);
  }

  getMeasureNameList(): Observable<any> {
    let body: Object = { EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    return this.httpService.post('/api/Metrics/ ', dataAsString);
  }

  getMeasureForEdit(UnitId: any, levelId: any): Observable<any> {
    let body: Object = { UnitId: UnitId, levelId: levelId, EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    return this.httpService.post('/api/Metrics/ ', dataAsString);
  }

  getSourceList(): Observable<any> {
    let body: Object = { EnterpriseId: this.sessionStorage.enterpriseId };
    let dataAsString: string = JSON.stringify(body);
    return this.httpService.post('/api/Metrics/ ', dataAsString);
  }

  saveMeasure(MeasureSaveData: any, enterpriseId: any, IsInsert: any): Observable<any> {
    //let data: Object = { MeasureSaveData: MeasureSaveData, EnterpriseId: enterpriseId, IsInsert: IsInsert, accessFlag: this.accessFlag };
    //let dataAsString: string = JSON.stringify(body);
    return this.httpService.post('/api/ConfigureMetrics/Measures/SaveMeasure', MeasureSaveData,true);
  }
  getAssociateMetricMeasure(MeasureId: any): Observable<any> {
    return this.httpService.get('/api/ConfigureMetrics/AssociateMetricMeasure?measureId=' + MeasureId);
  }

  // Add Metric popup calls end

  /* Other Configuration Setting API calls*/
  getAggregatedTeams(endtoEndUId: any): Observable<any> {
    return this.httpService.get('/api/ConfigureMetrics/AggregatedTeams/' + endtoEndUId,true);
  }
  updateAgrregatedTeams(teams: any, e2eUId: any): Observable<any> {
    return this.httpService.post('/api/ConfigureMetrics/UpdateAggregatedTeams?endtoenduid=' + e2eUId, teams,true);
  }
  saveThresholds(thresholdData: any, phase: any, gridCategory: any): Observable<any> {
    return this.httpService.post('/api/ConfigureMetrics/UpdateMetricsThreshold?phase='+ phase+'&gridCategory='+ gridCategory, thresholdData,true  );
  }
  restoreThresholds(deleteData: any): Observable<any> {
    var data: Object = { deleteData: deleteData };
    const replacer = (key, value) =>
      typeof value === 'undefined' ? null : value;
    let dataAsString: string = JSON.stringify(data, replacer);
    //let encryptData = this.encryptHelper.encrypt(dataAsString);
    // var saveThresholdUrl = global.environment.maaswebapi + 'ThresholdConfigure/UpdateMetricsThreshold';
    return this.httpService.post('/api/ConfigureMetrics/RestoreDefaultConfig',deleteData,true);
  }
}
