import { Component, OnInit } from '@angular/core'
import { SessionStorage } from "../context/sessionstorage";
import { enumSubmissionType, enumProfileTab, enumRoleType } from '../context/enum';
import { DownTimeAlertService } from '../services/downtime.alert.service';
import { ExternalApplicationLandingService } from '../services/ExternalApplication/external.application.landing.service';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
var $ = (<any>window).$;

@Component({
  selector: 'ExternalApplicationLandingComponent',
  templateUrl: './external.application.landing.component.html',
  providers: [DownTimeAlertService, ExternalApplicationLandingService, UserService]

})
export class ExternalApplicationLandingComponent {

  isDBDown: boolean;
  cmsUId: any;
  teamUId: any;
  tabCode: string;
  endToEndUId: any;
  workstreamUId: any;
  endPeriod: any;
  endToEndCMSTeams: any;
  profileTabCode: any;
  ActiveId: any;
  app: any;
  class: string;

  constructor(
    private sessionStorage: SessionStorage,
    private downTimeAlertService: DownTimeAlertService,
    private externalApplicationLandingService: ExternalApplicationLandingService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.tabCode = this.activatedRoute.snapshot.params.tabCode != undefined ? this.activatedRoute.snapshot.params.tabCode : "Manage";
    if (this.tabCode.toLowerCase() == "DeliveryDashboard".toLowerCase()) {
      this.router.navigate(['/customerror', 'customerror', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }

    this.endToEndUId = this.activatedRoute.snapshot.queryParams.endToEndUId;
    this.cmsUId = this.activatedRoute.snapshot.queryParams.cmsUId;
    this.workstreamUId = this.activatedRoute.snapshot.queryParams.workstreamUId;
    this.teamUId = this.activatedRoute.snapshot.queryParams.teamUId;
    this.endPeriod = this.activatedRoute.snapshot.queryParams.endPeriod;
    this.app = this.activatedRoute.snapshot.queryParams.app;
    this.profileTabCode = this.activatedRoute.snapshot.queryParams.profileTabCode;
    this.getDownTimeAlert();
  }
  getDownTimeAlert() {

    // Commented for future reference
    //this.downTimeAlertService.getDBConnectionExists().subscribe((response: any) => {
    //  this.isDBDown = false;
    //  if (response) {
    //    if (!(response.Users.indexOf(this.sessionStorage.enterpriseId) > -1)) {
    //      this.isDBDown = response.IsDBConnectionExists ? false : (response.IsEnabled ? false : true);
    //    }
    //  }
    //  if (this.isDBDown) {
    //    var url = Environment.ClientUrl + 'core/components/gone.fishingcomponent.html'
    //    window.open(url, '_self');
    //  }
    //});


    this.endToEndUId = this.endToEndUId != undefined && this.endToEndUId != "null" ? this.endToEndUId : "";
    this.cmsUId = this.cmsUId != undefined && this.cmsUId != "null" ? this.cmsUId : "";
    this.workstreamUId = this.workstreamUId != undefined && this.workstreamUId != "null" ? this.workstreamUId : "";
    this.teamUId = this.teamUId != undefined && this.teamUId != "null" ? this.teamUId : "";
    this.endPeriod = this.endPeriod != undefined && this.endPeriod != "null" ? this.endPeriod : "";
    this.app = this.app != undefined && this.app != "null" ? this.app : "";
    this.profileTabCode = this.profileTabCode != undefined && this.profileTabCode != "null" ? this.profileTabCode : "";


    this.sessionStorage.endToEndUId = this.endToEndUId;
    this.sessionStorage.clientWMSUId = this.cmsUId;
    this.sessionStorage.workstreamUId = this.workstreamUId;
    this.sessionStorage.teamUId = this.teamUId;
    if (this.cmsUId) {
      if (this.teamUId) {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.CMSTMS;
      }
      else {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.ClientWMS;
      }
    }
    else {
      if (this.sessionStorage.teamUId) {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.Team;
      }
      else {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
      }
    }

    if (this.tabCode == 'Portfolio' || this.tabCode == 'Security' || (this.endToEndUId || this.cmsUId || this.workstreamUId || this.teamUId)) {
      this.userAndProjectValidationService(this.externalApplicationLandingService, sessionStorage);
    }
    else {
      this.router.navigate(['/welcome', 'welcome', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }
  }
  userAndProjectValidationService(externalApplicationLandingService: any, sessionStorage) {
    var response = this.externalApplicationLandingService.getEnterpriseId();
    if (response) {
      this.sessionStorage.enterpriseId = response;
      this.class = "UserWait";
    }
    if (this.teamUId) {
      forkJoin([
        this.externalApplicationLandingService.getClientHierarchy(),
        this.externalApplicationLandingService.getTeamsByEndtoEndOrCMS()
      ]).subscribe((response: any) => {
        this.endToEndCMSTeams = response[1];
        if (this.endPeriod) {
          this.externalApplicationLandingService.getValidateEndPeriod(this.endPeriod).subscribe((responseForEndPeriod: any) => {
            this.sessionStorage.statusAsOnPeriod = responseForEndPeriod[0];
            this.redirectBasedOnTabCodeAndAccess(response[0], ExternalApplicationLandingService, this.sessionStorage);
          });
        }
        else {
          this.redirectBasedOnTabCodeAndAccess(response[0], ExternalApplicationLandingService, this.sessionStorage);
        }
      });
    }
    else {
      this.externalApplicationLandingService.getClientHierarchy().subscribe((response) => {
        if (this.endPeriod) {
          this.externalApplicationLandingService.getValidateEndPeriod(this.endPeriod).subscribe((responseForEndPeriod) => {
            this.sessionStorage.statusAsOnPeriod = responseForEndPeriod[0];
            this.redirectBasedOnTabCodeAndAccess(response, ExternalApplicationLandingService, this.sessionStorage);
          });
        } else {
          this.redirectBasedOnTabCodeAndAccess(response, ExternalApplicationLandingService, this.sessionStorage);
        }
      });
    }

  }

  redirectBasedOnTabCodeAndAccess(response, ExternalApplicationLandingService, sessionStorage: any) {
    if (response) {
      if (this.hasAccess(this.tabCode, response, ExternalApplicationLandingService)) {
        this.userService.getClientAccess(this.sessionStorage.enterpriseId).subscribe((resp) => {
          this.sessionStorage.cardinalClients = resp.Clients;
          if (resp.ClientIndicator == 'Y') {
            this.sessionStorage.clientAccessEnabled = true;
            this.redirectBasedOnTabCode(sessionStorage, this.tabCode, this.ActiveId, response)
            this.autoTriggerClick();
          }
          else {
            this.sessionStorage.clientAccessEnabled = false;
            this.redirectBasedOnTabCode(sessionStorage, this.tabCode, this.ActiveId, response)
            this.autoTriggerClick();
          }
        });
      }
      else {
        this.router.navigate(['/welcome', 'welcome', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
      }
    }
    else {
      this.router.navigate(['/welcome', 'welcome', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }
  }

  hasAccess(tabCode: any, response: any, ExternalApplicationLandingService: any) {
    var HasAccess = false;
    var selectedClient = response.SelectedClient;
    var selectedEndToEndGroup = selectedClient.SelectedContractOpportunityInitiativeGroup;
    if (selectedEndToEndGroup == null)
      return HasAccess;
    else
      var selectedEndToEnd = selectedEndToEndGroup;
    var RoleTypeId = selectedEndToEnd.RoleTypeCode;

    switch (this.tabCode) {
      case "Portfolio":
      case "Manage":
      case "Management":
      case "Initiate":
      case "Profile":
    
        HasAccess = RoleTypeId != null
          && RoleTypeId != 0
          && RoleTypeId != enumRoleType.TeamMember;
        break;
      case "Task":
      default:
        HasAccess = RoleTypeId != null && RoleTypeId != 0;
        break;
    }
    return HasAccess;
  }

  supportTabVisible() {
    this.userService.getTabVisibility(this.sessionStorage.enterpriseId).subscribe((response: any) => {
      if (response != null) {
        this.sessionStorage.isSupportTabVisible = response.ISupportTabVisible;
        this.sessionStorage.isAdminTabVisible = response.IsAdminTabVisible;
        this.sessionStorage.AdminAccessType = response.AdminAccessType;
        this.sessionStorage.customRoleTypeCode = response.CustomRoleTypeCode;
      }
    });
  }

  autoTriggerClick() {
    setTimeout(function () {
      $('#mainContent').click(); // temporary fix for page not refreshed post token generation 
    }, 4000);
  }

  redirectBasedOnTabCode(sessionStorage, tabcode, ActiveId, response) {
    if (response.SelectedClient != null) {
      this.setSession(response);
      this.supportTabVisible();
      this.sessionStorage.parentTileId = null;
      switch (this.tabCode) {
        //Default Landing to Manage Work For FDS Pilot Release
        case "Manage":
          this.router.navigate(['/mywizard/manage', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          break;
        case "Portfolio":
          var clientCountForCardinal = this.sessionStorage.cardinalClients.filter((x: any) => x.MasterClientId == this.sessionStorage.clientId).length;
          if (this.sessionStorage.clientAccessEnabled == true && clientCountForCardinal > 0) {
            this.router.navigate(['/mywizard/manage', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          }
          else {
            this.router.navigate(['/mywizard/portfolio', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          }
          break;
        case "Task":
          this.router.navigate(['/mywizard/tasks', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          break;
        case "Management":
          this.router.navigate(['/mywizard/childtiles', "98", 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          break;
        case "Initiate":
          this.router.navigate(['/mywizard/childtiles', "176", 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          break;
        case "Profile":
          this.SetProfileTab();
          this.sessionStorage.parentTileId = 176;
          if(this.profileTabCode == "Scope"){
            this.router.navigate(['/mywizard/scope', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          } else if (this.profileTabCode == "CMS") {
            this.router.navigate(['/mywizard/clientstructure', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          } else if (this.profileTabCode == "MetricSelection") {
            this.router.navigate(['/mywizard/metricselection', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          } else if (this.profileTabCode == "MetricThreshold") {
            this.router.navigate(['/mywizard/metricthreshold', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          } else if (this.profileTabCode == "OtherConfig") {
            this.router.navigate(['/mywizard/otherconfigsettings', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          }
          else {
            this.router.navigate(['/mywizard/profile', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          } 
          break;
        case "AvanadeEAC":
          this.sessionStorage.parentTileId = 98;
          this.router.navigate(['/mywizard/avanadeeacforecast', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          break;
        case "DEX":
          this.sessionStorage.parentTileId = 98;
          this.router.navigate(['/mywizard/deliveryexcellenceprinciples', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
          break;
        case "Security":
          this.router.navigate(['/mywizard/security'], { relativeTo: this.activatedRoute });
          break;

        

      }
    }
    else {
      this.router.navigate(['/welcome', 'welcome', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }
  }

  setSession(response) {
    this.sessionStorage.workstreamId = 0;
    this.sessionStorage.isSupportTabVisible = false;
    this.sessionStorage.isAdminTabVisible = false;
    var selectedClient = response.SelectedClient;
    var selectedEndToEndGroup = selectedClient.SelectedContractOpportunityInitiativeGroup;
    var selectedEndToEnd = selectedEndToEndGroup;
    this.sessionStorage.IsProfileCompleted = null;
    if (selectedEndToEnd != null) {
      this.sessionStorage.currentPeriod = response.CurrentEndPeriod;
      this.sessionStorage.clientName = selectedClient.Name;
      this.sessionStorage.clientId = selectedClient.Id;
      this.sessionStorage.endToEndName = selectedEndToEnd.EndToEndName;
      this.sessionStorage.endToEndId = selectedEndToEnd.EndToEndId;
      this.sessionStorage.endToEndUId = selectedEndToEnd.EndToEndUId;
      this.sessionStorage.roleTypeCode = selectedEndToEnd.RoleTypeCode;
      this.sessionStorage.roleType = selectedEndToEnd.RoleType;
      this.sessionStorage.restrictedInstanceIndicator = selectedEndToEnd.RestrictedInstanceIndicator;
      //this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode == 'V' ? selectedEndToEnd.RoleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : selectedEndToEnd.RoleAccessCode);
      this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode;
      this.sessionStorage.endToEndType = selectedEndToEnd.EndToEndType;
      this.sessionStorage.endToEndRefCode = selectedEndToEnd.EndToEndRefCode;
      this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
      this.sessionStorage.clientWMSId = 0;
      this.sessionStorage.clientWMSUId = "";
      this.sessionStorage.cmsName = "";
      this.sessionStorage.teamId = 0;
      this.sessionStorage.teamUId = "";
      this.sessionStorage.tmsName = "";
      this.sessionStorage.workstreamName = "";
      this.sessionStorage.workstreamId = 0;
      this.sessionStorage.workstreamUId = "";
      this.sessionStorage.deployRegion = selectedEndToEnd.Instance;
      if (this.cmsUId) {
        if (selectedEndToEnd.SelectedCMS) {
          this.sessionStorage.selectedSubmissionType = enumSubmissionType.ClientWMS;
          this.sessionStorage.clientWMSId = selectedEndToEnd.SelectedCMS.Id;
          this.sessionStorage.clientWMSUId = selectedEndToEnd.SelectedCMS.CMSUId;
          this.sessionStorage.cmsName = selectedEndToEnd.SelectedCMS.Name;
        }
        if (this.workstreamUId && selectedEndToEnd.SelectedWorkstream) {
          this.sessionStorage.workstreamId = selectedEndToEnd.SelectedWorkstream.WorkstreamId;
          this.sessionStorage.workstreamUId = selectedEndToEnd.SelectedWorkstream.WorkstreamUId;
          this.sessionStorage.workstreamName = selectedEndToEnd.SelectedWorkstream.WorkStreamName;

        }
      }
      else if (this.workstreamUId && selectedEndToEnd.SelectedWorkstream) {
        this.sessionStorage.workstreamId = selectedEndToEnd.SelectedWorkstream.WorkstreamId;
        this.sessionStorage.workstreamUId = selectedEndToEnd.SelectedWorkstream.WorkstreamUId;
        this.sessionStorage.workstreamName = selectedEndToEnd.SelectedWorkstream.WorkStreamName;
        this.sessionStorage.clientWMSId = selectedEndToEnd.SelectedWorkstream.CMSId;
        this.sessionStorage.clientWMSUId = selectedEndToEnd.SelectedWorkstream.CMSUId;

      }
      if (this.teamUId) {
        this.sessionStorage.teamUId = this.endToEndCMSTeams.SelectedTMS.UId;
        this.sessionStorage.teamId = this.endToEndCMSTeams.SelectedTMS.Id;
        this.sessionStorage.tmsName = this.endToEndCMSTeams.SelectedTMS.Name;

      }
      this.SubmissionType();

    }
  }

  SubmissionType() {
    if (this.sessionStorage.workstreamId) {
      if (this.sessionStorage.teamId) {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.Team;
        this.sessionStorage.profileTab = enumProfileTab.TeamStructure;

      }
      else {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.Workstream;
        this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
      }
    }
    else if (this.sessionStorage.clientWMSId) {
      if (this.sessionStorage.teamId) {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.CMSTMS;
        this.sessionStorage.profileTab = enumProfileTab.TeamStructure;

      }
      else {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.ClientWMS;
        this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
      }
    }
    else {
      if (this.sessionStorage.teamId) {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.Team;
        this.sessionStorage.profileTab = enumProfileTab.TeamStructure;
      }
      else {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
        this.SetProfileTab();
      }
    }

  }
  SetProfileTab() {
    this.sessionStorage.isValidEndToEndOverallDeliveryLead = null;
    this.sessionStorage.isValidEndDate = null;
    if (this.profileTabCode == "Scope") {
      this.sessionStorage.profileTab = enumProfileTab.Scope;
    }
    else if (this.profileTabCode == "CMS") {
      this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
    }
    else {
      this.sessionStorage.profileTab = enumProfileTab.Profile;
    }
  };
}


