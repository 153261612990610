export const environment = {
  production: true,
  name: "uat",
  serviceUrl: "https://mywizarddmuatprdapi.accenture.com",
  clientUrl: "https://mywizarddmuatprd.accenture.com/",
  logOutUrl: "https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb/oauth2/logout",
  MetricsUrl: "https://mywizardmetrics.accenture.com/",
  supportURL: "https://accentureinternal.service-now.com/support_portal/",
  isAuthRequired: true,
  azureAD: {
    clientId: "dfe15cdd-3280-4440-a4b1-119ebd8df91f",
    authority: "https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb",
    redirectUri: "https://mywizarddmuatprd.accenture.com/",
    resourceURL: 'https://mywizarddmuatprdapi.accenture.com',
    resourceId: ['a4341547-d6d9-4e8a-a0ed-3496d7a45775/read_mywizard', 'a4341547-d6d9-4e8a-a0ed-3496d7a45775/write_mywizard'],
  },
  restrictedInstance: false,
  currentSustainabilityDAFUrl: "https://mmddafuat.accenture.com/SA/E2E"
};
