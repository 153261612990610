<!--<div class="row tile-metrics tile-metrics-xs">
    <div class="col-xs-6">
        <div class="metrics-value" id="criticalSLA" [innerHTML]="AMCriticalSLA"></div>
        <div class="metrics-text"><span>Critical SLA %</span></div>
        <div id="amcriticalslacompliance" class="metrics-status" [ngClass]="AMCriticalSLARagIndicator"></div>
    </div>
    <div class="col-xs-6">
        <div class="metrics-value" id="SLA" [innerHTML]="AMSLA"></div>
        <div class="metrics-text"><span>SLA Compliance %</span></div>
        <div id="amslacompliance" class="metrics-status" [ngClass]="AMSLARagIndicator"></div>
      </div>
</div>
<hr />
<p>{{tile.Description}}</p>-->

<div class="c-manage-dashboard-application">
  <div class="c-manage-dashboard-application-item">
    <div class="c-manage-dashboard-application-content" [ngClass]="AMCriticalSLARagIndicator">
      <div id="criticalSLA" [innerHTML]="AMCriticalSLA"></div>
      <span>Critical SLA %</span>
    </div>
  </div>
  <div class="c-manage-dashboard-application-item">
    <div class="c-manage-dashboard-application-content" [ngClass]="AMSLARagIndicator">
      <div id="SLA" [innerHTML]="AMSLA"></div>
      <span>SLA Compliance %</span>
    </div>
  </div>
</div>
<p class="c-manage-dashboard__image-text">{{tile.Description}}</p>
