import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DemographicsService } from '../../../core/services/demographics.service';
declare var window: any;
var $ = (<any>window).$;

@Component({
  templateUrl: './multi.contract.details.component.html',
  providers: [DemographicsService]
})

export class MultiContractDetailComponent {

  constructor(private bsModalRef: BsModalRef, private demographicService: DemographicsService) { }

  financialActiveContracts: any;
  financialInactiveContracts: any;
  selectedContractInfo: any;
  DeliveryPortfolioDetails: any;// .. Parent -> Child Popup
  contractInfo: any = [];
  currentSelectedTab: any;
  autoSourcedLabelShow: boolean = false;
  activeTempIndex: any = null;
  inactiveTempIndex: any = null;

  ngOnInit() {}

  ngAfterViewInit() {
    window.tabsComponent();
   }

  getSelectedContractDetails(selectedContractInfo, index, contractType) {
    this.activeTempIndex = (this.activeTempIndex != null && contractType == 'activeContracts') ? this.activeTempIndex : null;
    this.inactiveTempIndex = (this.inactiveTempIndex != null && contractType == 'inActiveContracts') ? this.inactiveTempIndex : null;
    this.demographicService.getSelectedContractInfo(selectedContractInfo.EndToEndId, selectedContractInfo.ContractCode).subscribe((response: any) => {
      if (response.ServiceGroups) {
        response.ServiceGroups.forEach((value: any) => {
          value.DominantServiceGroupId = value.DominantServiceGroupId != '' ? parseInt(value.DominantServiceGroupId) : 0;
        });
      }
      this.contractInfo = response;
      if (this.activeTempIndex != index) {
        $("#activebutton-" + this.activeTempIndex).attr("aria-expanded", "false");
        $("#active-contract-" + this.activeTempIndex).removeClass("-show");
      }
      if (this.inactiveTempIndex != index) {
        $("#inactivebutton-" + this.inactiveTempIndex).attr("aria-expanded", "false");
        $("#inactive-contract-" + this.inactiveTempIndex).removeClass("-show");
      }
      if (contractType == 'activeContracts') {
        if (this.currentSelectedTab == $("#active-contract-" + index)[0].id) {
          $("#active-contract-" + index).removeClass("-show");
          $("#activebutton-" + index).attr("aria-expanded", "false");
          this.currentSelectedTab = null;
        }
        else {
          $("#active-contract>#accordionParent-contracts>.c-accordion>div.-show").removeClass("-show");
          this.currentSelectedTab = $("#active-contract-" + index)[0].id;
          $("#active-contract-" + index).addClass("-show");
          $("#activebutton-" + index).attr("aria-expanded", "true");
        }
      } else if (contractType == 'inActiveContracts') {
        if (this.currentSelectedTab == $("#inactive-contract-" + index)[0].id) {
          $("#inactive-contract-" + index).removeClass("-show");
          $("#inactivebutton-" + index).attr("aria-expanded", "false");
          this.currentSelectedTab = null;
        }
        else {
          $("#inactive-contract>#accordionParent-contracts>.c-accordion>div.-show").removeClass("-show");
          this.currentSelectedTab = $("#inactive-contract-" + index)[0].id;
          $("#inactive-contract-" + index).addClass("-show");
          $("#inactivebutton-" + index).attr("aria-expanded", "true");
        }
      }
      if (contractType == 'activeContracts') {
        this.activeTempIndex = index;
      } else {
        this.inactiveTempIndex = index;
      }
      var isactive = contractType == 'activeContracts' ? true : false;
      this.ShowAutoSourcedLabel(isactive, false, null);
    });
  }

  close() {
    this.bsModalRef.hide();
  }
  ShowAutoSourcedLabel(isactive: boolean, isTabChange, contractType) {
    if (isTabChange) {
      this.currentSelectedTab = null;
      if (contractType == 'activeContracts') {
        $("#inactivebutton-" + this.inactiveTempIndex).attr("aria-expanded", "false");
        $("#inactive-contract-" + this.inactiveTempIndex).removeClass("-show");
      } else if (contractType == 'inActiveContracts') {
        $("#activebutton-" + this.activeTempIndex).attr("aria-expanded", "false");
        $("#active-contract-" + this.activeTempIndex).removeClass("-show");
      }
    }
    if (isactive)
      this.autoSourcedLabelShow = (this.currentSelectedTab && this.financialActiveContracts.length > 0) ? true : false;
    else
      this.autoSourcedLabelShow = (this.currentSelectedTab && this.financialInactiveContracts.length > 0) ? true : false;
  }
  dominantChange(ServiceGroup) {
    this.DeliveryPortfolioDetails.ServiceGroups.forEach((x: any) => {
      x.Dominant = (x.EndToEndServiceGroupId === ServiceGroup.EndToEndServiceGroupId);
    });
  }
  trackByServiceGroupId(index: number, item: any): any {
    return item.ServiceGroupId;
  }
}
