import { Injectable } from '@angular/core'
import { SessionStorage } from '../context/sessionstorage';
import { HttpService } from './http.service';

@Injectable()
export class ApplicationLoggingService {

  constructor(private sessionStorage: SessionStorage, private httpService: HttpService) { }

  applicationUsageLogging(moduleAction: string, tileId: number, featureAccessId: number) {

    return this.httpService.post('/api/application/usage/logging/' + this.sessionStorage.enterpriseId +
      '?moduleAction=' + moduleAction +
      '&clientId=' + (this.sessionStorage.clientId ? this.sessionStorage.clientId : null) +
      '&endToEndId=' + (this.sessionStorage.endToEndId ? this.sessionStorage.endToEndId : null) +
      '&cmsId=' + (this.sessionStorage.cmsName ? this.sessionStorage.clientWMSId : null) +
      '&workstreamId=' + (this.sessionStorage.workstreamId ? this.sessionStorage.workstreamId : null) +
      '&teamId=' + (this.sessionStorage.teamId ? this.sessionStorage.teamId : null) +
      '&tileId=' + tileId +
      '&featureAccessId=' + featureAccessId, true);
  };

}
