

  <div class="c-portfolio-card" >
    <div class="c-portfolio-card__header">
      <span *ngIf="tile.ShortDescription == 'AVPR'" class="-header-note">Non Europe</span>
      <span *ngIf="tile.ShortDescription == 'AVPRE'" class="-header-note">Europe</span>
      <div class="-title" [ngClass]="{'tile-name-long' : (tile.ShortDescription == 'AMCC' || tile.ShortDescription == 'PHC' || tile.ShortDescription == 'AOHAParent' || tile.ShortDescription == 'AOHAD')}">{{tile.Name}}</div>
    </div>
    <ng-container [ngSwitch]="tile.ShortDescription">

      <managment class="c-portfolio-card__body" *ngSwitchCase="'MG'" [tile]="tile"></managment>
      <delivery-dashboard class="c-portfolio-card__body" *ngSwitchCase="'GDDS'" [tile]="tile"></delivery-dashboard>
      <eac-forecast class="c-portfolio-card__body" *ngSwitchCase="'EAC'" [tile]="tile"></eac-forecast>
      <reporting-component  class="c-portfolio-card__body" *ngSwitchCase="'PM'" [tile]="tile"></reporting-component>
      <issue class="c-portfolio-card__body" id="issue_tile_content" *ngSwitchCase="'RI'" [tile]="tile"></issue>
      <risk-tile class="c-portfolio-card__body" [tile]="tile" *ngSwitchCase="'RRI'"></risk-tile>
      <milestone-tile class="c-portfolio-card__body" *ngSwitchCase="'RM'" [tile]="tile" [rimResponse]="rimResponse"></milestone-tile>
      <app-automation-journey class="c-portfolio-card__body" [tile]="tile" *ngSwitchCase="'AJ'" ></app-automation-journey>
      <pca-portfolio class="c-portfolio-card__body" [tile]="tile" *ngSwitchCase="'PRS'"></pca-portfolio>
      <pca-portfolio class="c-portfolio-card__body" [tile]="tile" *ngSwitchCase="'SGRS'"></pca-portfolio>
      <pca-portfolio class="c-portfolio-card__body" [tile]="tile" *ngSwitchCase="'ICIDB'"></pca-portfolio>
      <action-manager class="c-portfolio-card__body" id="action_manager_tile_content" [tile]="tile" *ngSwitchCase="'AM'"></action-manager>
      <pca-manage class="c-portfolio-card__body" [tile]="tile" *ngSwitchCase="'PR'"></pca-manage>
      <pca-manage class="c-portfolio-card__body" [tile]="tile" *ngSwitchCase="'SGR'"></pca-manage>
      <pca-manage class="c-portfolio-card__body" [tile]="tile" *ngSwitchCase="'ICISM'"></pca-manage>
      <am-command-center class="c-portfolio-card__body" [tile]="tile" *ngSwitchCase="'AMCC'"></am-command-center>


      <div *ngSwitchDefault class="c-portfolio-card__body">
        <div *ngIf="tile && tile.ImagePath != ''" class="c-manage-dashboard__image">
          <img [src]="tile.ImagePath" alt="{{tile.Name}}" />
        </div>
        <p *ngIf="tile.Description != ''" class="c-manage-dashboard__image-text" [ngClass]="(tile && tile.ImagePath != '') ? 'c-manage-dashboard__image-text' : 'c-portfolio-card__text'" [innerHTML]="tile.Description">
        </p>
      </div>
    </ng-container>
    <div class="c-portfolio-card__footer">
      <a href="javascript:;" (click)="GetURL()" role="button" class="icon-external-link">{{tile.Type}}</a>
    </div>
  </div>

    <!--<div class="col-xs-12 col-sm-6 col-lg-3" *ngIf="tile.Width == 1">
      <div [ngClass]="{'stacked-tile':IsStacked}" class="tile">
        <h2>
          <a (click)="GetURL()" href="javascript:void(0);" class="tile-title">{{tile.Name
          }}</a>



          </h2>
          <managment *ngIf="tile.ShortDescription == 'MG'" [tile]="tile"></managment>
          <delivery-dashboard *ngIf="tile.ShortDescription == 'GDDS'" [tile]="tile"></delivery-dashboard>
          <eac-forecast *ngIf="tile.ShortDescription== 'EAC'" [tile]="tile"></eac-forecast>
          <reporting-component *ngIf="tile.ShortDescription == 'PM'" [tile]="tile"></reporting-component>
          <issue *ngIf="tile.ShortDescription=='RI'" [tile]="tile"></issue>
          <div *ngIf="tile.ShortDescription != 'MG' && tile.ShortDescription != 'GDDS' && tile.ShortDescription != 'EAC' && tile.ShortDescription != 'PM' && tile.ShortDescription != 'RI'" class="tile-img-wrap">
            <milestone-tile *ngIf="tile.ShortDescription == 'RM'" [tile]="tile"></milestone-tile>
            <div *ngIf="tile.ShortDescription != 'MG' && tile.ShortDescription != 'RM'" class="tile-img-wrap">
              <img [className]="(tile.ShortDescription=='AVPR' || tile.ShortDescription=='AVPRE') ? 'tile-aj-img': 'tile-img'" [src]="tile.ImagePath" alt="{{tile.Name}}" />
              <hr *ngIf="IsHrRow" [ngClass]="{'inlinehr':IsInlineStyle}" />
              <p class='text-left' *ngIf="IsHRRowDescription">{{tile.Description}}</p>
            </div>
          </div>
</div>
    </div>


    <div class="col-xs-12 col-sm-6 col-lg-3" *ngIf="tile.Width == 3">
      <div [ngClass]="{'stacked-tile':IsStacked}" class="tile">
        <h2>
          <a (click)="GetURL()" href="javascript:void(0)" class="tile-title">{{tile.Name}}</a>
          <div *ngIf="tile.ShortDescription=='AJ'" class="clearfix tile-alerts-wrap tile-alerts-wrap-half"
               [ngClass]="{'disabled-mode': Alerts == null}">
            <div class="tile-alerts">
              <div class="tile-alerts-va bell" data-toggle="dropdown" aria-expanded="false"><span>{{Alerts?.length>0?Alerts.length:''}}</span></div>
              <div class="dropdown-alerts">
                <ul>
                  <li *ngFor="let alert of Alerts; trackBy: trackByIndex;">
                    <a (click)="AlertClick(alert.URL)" [attr.title]="alert.AlertTooltip" [innerHTML]="alert.Message"></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </h2>
        <automation-journey [tile]="tile" *ngIf="tile.ShortDescription=='AJ'" (Notifications)="Alerts=$event.value"></automation-journey>
        <marketplace [tile]="tile" *ngIf="tile.ShortDescription=='MASOrdr'"></marketplace>
        <pca-portfolio [tile]="tile" *ngIf="tile.ShortDescription=='SGRS' || tile.ShortDescription=='PRS' || tile.ShortDescription=='ICIDB'"></pca-portfolio>
        <div [tile]="tile" *ngIf="tile.ShortDescription=='IMAGP'">
          <img class="tile-img" [src]="tile.ImagePath" alt="{{tile.Name}}" />
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-12 col-lg-6" *ngIf="tile.Width == 2 || tile.Width == 4">

      <div [ngClass]="{'stacked-tile':IsStacked}" class="tile">
        <h2><a (click)="GetURL()" href="javascript:" class="tile-title">{{tile.Name}}</a> </h2>
        <risk-tile [tile]="tile" *ngIf="tile.ShortDescription=='RRI'"></risk-tile>
        <div class="row col-2" *ngIf="tile.ShortDescription!='RRI'">
          <div class="col-xs-12 col-sm-6" [ngSwitch]="tile.ShortDescription">
            <managment [tile]="tile" *ngSwitchCase="'MG'"></managment>

            <action-manager [tile]="tile" *ngSwitchCase="'AM'"></action-manager>
            <pca-manage [tile]="tile" *ngSwitchCase="'PR'"></pca-manage>
            <pca-manage [tile]="tile" *ngSwitchCase="'SGR'"></pca-manage>
            <pca-manage [tile]="tile" *ngSwitchCase="'ICISM'"></pca-manage>
            <div *ngSwitchDefault class="tile-img-wrap">
              <img class="tile-img" [src]="tile.ImagePath" alt="{{tile.Name}}" />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6" [ngSwitch]="tile.ShortDescription">
            <am-command-center [tile]="tile" *ngSwitchCase="'AMCC'"></am-command-center>
            <div *ngSwitchDefault class="media-body">
              <p [innerHtml]="tile.Description"></p>
            </div>
          </div>
        </div>
      </div>

    </div>-->
