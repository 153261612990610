<form *ngIf="KeyContactsDetails" name="contactsform" #contactsform="ngForm" novalidate>
  <div class="c-myWizard-container c-key-contacts">
    <div class="c-myWizard__header">
      <div class="c-myWizard__header-content-left">
        <div class="c-myWizard__header-content-head">
          <a href="javascript:;" class="icon-banner-arrow" role="button" (click)="OnBackClick()" alt="Back" aria-label="Back"></a>
          <div class="-header">Key Contacts</div>
        </div>
        <div class="c-myWizard-text" *ngIf="!IsProfileCompleted">Complete the preliminary mandatory step to setup your delivery profile</div>
        <div>
          <p *ngIf="KeyContactsDetails.DeliveryAccountability!=null && KeyContactsDetails.DeliveryAccountability!=''" class="c-myWizard-text">
            You are viewing <span style="font-weight: bold;" [innerHtml]="KeyContactsDetails.DeliveryAccountabilityType"
                                  *ngIf="KeyContactsDetails.DeliveryAccountability!=null && KeyContactsDetails.DeliveryAccountability!=''"></span>
            <span style="font-weight: bold;" *ngIf="KeyContactsDetails.DeliveryAccountability!=null && KeyContactsDetails.DeliveryAccountability!=''"> - </span>
            <span style="font-weight: bold;" [innerHtml]="KeyContactsDetails.DeliveryAccountability"></span> led reporting portfolio
          </p>
        </div>
      </div>

      <div class="c-myWizard__header-content-right">
        <download-file source="DeliveryProfileGuide"></download-file>
        <div class="c-header-data">
          <a href="javascript:;" class="c-myWizard__header-details" role="button" [ngClass]="{'-disabled': RoleAccess=='V'}"  [attr.tabindex]="RoleAccess === 'V' ? -1 : null" data-flyoutid="key-portfolio" (click)="showReportingPortfolio(null,null)">
            <img src="../assets/toolkit/images/key-contacts/add.svg" alt="Details" role="presentation" />
            <span href="javascript:void(0)" aria-label="Click here to Add Reporting Portfolio(opens a new window)">Reporting Portfolio</span>
          </a>
          <a href="javascript:;" class="c-myWizard__header-details" role="button" [ngClass]="{'-disabled': RoleAccess=='V'}"  [attr.tabindex]="RoleAccess === 'V' ? -1 : null" data-toggle="modal" data-modalid="addContact" (click)="showAlternativeContacts(null)">
            <img src="../assets/toolkit/images/key-contacts/add.svg" alt="Details" role="presentation" />
            <span href="" aria-label="Click here to Manage Alternative Contacts(opens a new window)">Alternative Contacts</span>
          </a>
        </div>
      </div>
    </div>
    <div class="c-scope-error" *ngIf="KeyContactsDetails.IsPMOLeadRoleRequired">
      <ul class="c-myWizard-list -customScroll">
        <li>
          <p *ngIf="KeyContactsDetails.IsPMOLeadRoleRequired">Please update <a href="https://in.accenture.com/deploymentenablement/si-transformation/ipc-landing/" target="_blank" rel="noopener noreferrer" class="-link">intelligent Program Control</a> (iPC) Lead name under Alternative Contacts. iPC is a framework that enables effective PMO startup and execution to empower SI delivery leads to drive programs and maximize CCI.</p>
        </li>
      </ul>
    </div>
    <div class="c-myWizard-form__input">
      <div class="c-myWizard-form__input-area">
        <div class="o-input   -required   " [ngClass]="{'-error':KeyContactsDetails && ((!IsValidEndToEndOverallDeliveryLead && !KeyContactsDetails.ContractDeliveryLead ) || (KeyContactsDetails.ContractDeliveryLead == '' ))}">
          <label for="delivery_lead">Overall Delivery Lead</label>
          <div (keypress)="onChange()">
            <auto-complete id="contractDeliveryLead" #contractdeliverylead="ngModel" name="contractdeliverylead" [disabled]="RoleAccess=='V'"
                           hiddenFieldID="hiddenContractDeliveryLead" ariaLabelledby="OverallDeliveryLead"
                           [selected]="KeyContactsDetails.ContractDeliveryLead"
                           [(ngModel)]="KeyContactsDetails.ContractDeliveryLead"
                           [required]="true"
                           [formname]="contactsform"
                           (selectPeoplePicker)="onChange();getDeliveryLeadLocation(KeyContactsDetails.ContractDeliveryLead);"
                           [isInValidAccentureEmp]="(!IsValidEndToEndOverallDeliveryLead || (!IsValidEndToEndOverallDeliveryLead && !KeyContactsDetails.ContractDeliveryLead)) && !CreatePage"
                           [ngClass]="{'is-error':KeyContactsDetails && ((!IsValidEndToEndOverallDeliveryLead && !KeyContactsDetails.ContractDeliveryLead ) || (KeyContactsDetails.ContractDeliveryLead == '' ))}">
            </auto-complete>
          </div>
          <div *ngIf="!IsValidEndToEndOverallDeliveryLead && KeyContactsDetails.ContractDeliveryLead" class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status" [ngClass]="{'is-error':(KeyContactsDetails && !IsValidEndToEndOverallDeliveryLead  && !CreatePage)}">This EID is no longer valid. Please enter a valid EID</div>
          <div aria-live="polite" aria-atomic="true" class="o-input__error-text" role="status" [ngClass]="{'is-error':KeyContactsDetails && ((!IsValidEndToEndOverallDeliveryLead && !KeyContactsDetails.ContractDeliveryLead ) || (KeyContactsDetails.ContractDeliveryLead == '' ))}">Please enter Overall Delivery Lead</div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area">
        <div class="o-input">
          <label for="deliveryleadlocation">Delivery Lead Location(DTE)</label>
          <p class="o-input__input -disabled" [innerHTML]="KeyContactsDetails.DeliveryLeadLocation"></p>
        </div>
      </div>
    </div> 
    <div class="c-form-tab">
      <div class="c-form-tab__wrapper">
        <div class="c-scrollable-tabs">
          <span class="c-scrollable-tabs__arrow icon-chevron-left -left -hidden"></span>
          <div class="c-scrollable-tabs__container-wrapper">
            <div class="c-scrollable-tabs__container" role="tablist">
              <button class="c-form-tab__item" role="tab" aria-controls="key_portfolio" aria-selected="true">Involved Reporting Portfolios </button>
              <button class="c-form-tab__item" role="tab" aria-controls="key_network" *ngIf="endToEndType == 'C'">Service Group Network </button><!-- *ngIf="E2eDemoGraphics.E2EType=='C'"-->
              <button class="c-form-tab__item" role="tab" aria-controls="key_contacts">Alternative Contacts </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div role="tabpanel" id="key_portfolio" class="-show">
      <div class="c-table-responsive c-key-table -customScroll" style="" [ngStyle]="{'border' : IsTableMandatory ? '1px solid #ff0601' : ''}" [ngClass]="{'c-key-table-opportunity-initiative': endToEndType == 'C'}">
        <table class="c-table">
          <thead>
            <tr>
              <th scope="col" width="110px"></th>
              <th scope="col" width="120px">Portfolio</th>
              <th scope="col" width="130px" *ngIf="endToEndType == 'C'"><a href="javascript:;">Effort Contribution(%)</a></th>
              <th scope="col" width="70px" class="c-text-center">Led by</th>
              <th scope="col" width="100px">Leading AG,DG,DU</th>
              <th scope="col" width="150px">Delivery Center</th>
              <th scope="col" width="150px" *ngIf="endToEndType == 'C'">Involved Services</th>
              <th scope="col" width="180px">Delivery Lead / POC</th>
              <th scope="col" width="150px">Account Group</th>
              <th scope="col" width="180px">Account Group Lead</th>
              <th scope="col" width="150px">Delivery Group</th>
              <th scope="col" width="180px">Delivery Group Lead</th>
              <th scope="col" width="150px">Delivery Unit</th>
              <th scope="col" width="180px">Delivery Unit Lead</th>
            </tr>
          </thead>

          <tbody *ngIf="endToEndType == 'C'">
            <tr *ngIf="slicedGridData.length == 0">
              <td colspan="14"> No Records Found </td>
            </tr>
            <tr *ngFor="let deliverycenter of slicedGridData;let i = index; trackBy : trackByDeliveryCenterId;">
              <ng-container *ngIf="deliverycenter.OperationDeliveryCenterId==null">
                <td>
                  <div *ngIf="RoleAccess!='V'" class="c-key-icon c-key-icon-single-item">
                    <a href="javascript:;" class="icon-edit" role="button" (click)="showReportingPortfolio(deliverycenter,i)" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit"></a>
                    <a href="javascript:;" *ngIf="deliverycenter.DcCountryName != 'OnShore' && deliverycenter.DcCountryId != 997 && deliverycenter.DcCountryId != 998 && deliverycenter.EndToEndDeliveryCenterId != deliverycenter.DCLeadingdeliveryCenterId && deliverycenter.EffortsContributed == null" class="icon-delete"
                       (click)="DeleteData(deliverycenter.LeadingAGDGDUId, deliverycenter.DCLeadingdeliveryCenterId, deliverycenter.DeliveryCenterId, deliverycenter.DeliveryUnitId, endToEndType, deliverycenter.EndToEndDeliveryCenterId, deliverycenter.DeliveryCenterId,deliverycenter.DeliveryLead,deliverycenter.AccountGroupLead,deliverycenter.DeliveryGroupLead,deliverycenter.DeliveryUnitLead )"
                       aria-label="Delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                  </div>
                  <div *ngIf="RoleAccess=='V'" class="c-key-icon c-key-icon-single-item">
                    <a href="javascript:;" class="c-view-link" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" role="presentation" (click)="showReportingPortfolio(deliverycenter,i)" alt="View" /></a>
                  </div>
                </td>
                <td> {{deliverycenter.DcCountryName}}</td>
                <td><a href="javascript:;" class="c-key-link"  *ngIf="deliverycenter.EffortsContributed != null" data-myW-title="Represents effort for the complete ATC , not specific to Account Group, Delivery Group, Delivery Unit" data-myW-toggle="tooltip" data-myW-placement="top"><u>{{deliverycenter.EffortsContributed}}</u></a></td>
                <td class="c-text-center">
                  <div class="c-radio">
                    <input class="c-radio__input" #dcleadingcontract="ngModel" type="radio" disabled
                           name="{{'dcleadingcontract_'+ i}}"
                           [value]="deliverycenter.EndToEndDeliveryCenterId" [(ngModel)]="deliverycenter.DCLeadingdeliveryCenterId"
                           />
                    <i class="c-radio__checked" [value]="deliverycenter.EndToEndDeliveryCenterId"></i>
                  </div>
                </td>

                <td class="c-text-center">
                  <div class="c-radio">
                    <input class="c-radio__input" #leadingagdgduid="ngModel" type="radio" [disabled]="deliverycenter.DcCountryName != LedByPortfolio || RoleAccess=='V'"
                           name="{{'leadingagdgduid_'+ i}}"
                           (click)="LeadingAGDGDUChange(deliverycenter)" [value]="deliverycenter.EndToEndDeliveryCenterId" [(ngModel)]="deliverycenter.LeadingAGDGDUId" />
                    <i class="c-radio__checked" [value]="deliverycenter.EndToEndDeliveryCenterId"></i>
                  </div>
                </td>
                <td>{{deliverycenter.DeliveryCenterName}}</td>
                <td>{{deliverycenter.InvolvedServices}}</td>
                <td>
                  {{deliverycenter.DeliveryLead}}
                </td>
                <td>
                  {{deliverycenter.AccountGroup}}
                </td>
                <td>{{deliverycenter.AccountGroupLead}}</td>

                <td>{{deliverycenter.DeliveryGroup}}</td>
                <td>
                  {{deliverycenter.DeliveryGroupLead}}
                </td>
                <td>{{deliverycenter.DeliveryUnit}}</td>

                <td>{{deliverycenter.DeliveryUnitLead}}</td>
              </ng-container>
              <ng-container *ngIf="deliverycenter.OperationDeliveryCenterId!=null">
                <td></td>
                <td [innerHtml]="deliverycenter.DcCountryName"></td>
                <td><a href="javascript:;" class="c-key-link" data-myW-title="Represents effort for the complete ATC , not specific to Account Group, Delivery Group, Delivery Unit" data-myW-toggle="tooltip" data-myW-placement="top"><u>{{deliverycenter.EffortsContributed}}</u></a></td>
                <td class="c-text-center">
                  <div class="c-radio">
                    <input class="c-radio__input" #dcleadingcontract="ngModel" type="radio" disabled name="{{'dcleadingcontract_'+ i}}"
                           [value]="deliverycenter.EndToEndDeliveryCenterId" [(ngModel)]="deliverycenter.DCLeadingdeliveryCenterId" />
                    <i class="c-radio__checked" [value]="deliverycenter.EndToEndDeliveryCenterId"></i>
                  </div>
                </td>
                <td class="c-text-center">
                  <div class="c-radio">
                    <input class="c-radio__input" #leadingagdgduid="ngModel" type="radio" [disabled]="deliverycenter.DcCountryName != LedByPortfolio  || RoleAccess=='V'"
                           name="{{'leadingagdgduid_'+ i}}"
                           (click)="LeadingAGDGDUChange(deliverycenter)" [value]="deliverycenter.EndToEndDeliveryCenterId" [(ngModel)]="deliverycenter.LeadingAGDGDUId" />
                    <i class="c-radio__checked" [value]="deliverycenter.EndToEndDeliveryCenterId"></i>
                  </div>
                </td>
                <td *ngIf="deliverycenter.OperationDeliveryCenter!=0"
                    [innerHtml]="deliverycenter.OperationDeliveryCenter"></td>
                <td>{{deliverycenter.InvolvedServices}}</td>
                <td>{{deliverycenter.DeliveryLead}}</td>
                <td colspan="6"></td>

              </ng-container>
          </tbody>

          <tbody *ngIf="endToEndType =='I' || endToEndType =='O'">
            <tr *ngIf="slicedGridData.length == 0">
              <td colspan="13"> No Records Found </td>
            </tr>
            <tr *ngFor="let gridData of slicedGridData;let i = index;  trackBy : trackByIndex;">
              <td>
                <div *ngIf="RoleAccess!='V'" class="c-key-icon c-key-icon-single-item">
                  <a href="javascript:;" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="showReportingPortfolio(gridData,i)" aria-label="Edit"></a>
                  <a href="javascript:;" *ngIf="gridData.DcCountryName != 'OnShore' && gridData.DcCountryId != 997 && gridData.DcCountryId != 998 && gridData.EndToEndDeliveryCenterId != gridData.DCLeadingdeliveryCenterId && gridData.EffortsContributed == null" class="icon-delete"
                     (click)="DeleteData(gridData.LeadingAGDGDUId, gridData.DCLeadingdeliveryCenterId, gridData.DeliveryCenterId, gridData.DeliveryUnitId, endToEndType, gridData.EndToEndDeliveryCenterId,gridData.DeliveryCenterId,gridData.DeliveryLead,gridData.AccountGroupLead,gridData.DeliveryGroupLead,gridData.DeliveryUnitLead)"
                     aria-label="Delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                </div>
                <div *ngIf="RoleAccess=='V'" class="c-key-icon c-key-icon-single-item">
                  <a href="javascript:;" class="c-view-link" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" role="presentation" (click)="showReportingPortfolio(gridData,i)" alt="View" /></a>
                </div>
              </td>
              <td>{{gridData.DcCountryName}}</td>
              <td *ngIf="endToEndType == 'O'" class="c-text-center">
                <div class="c-radio">
                  <input class="c-radio__input" #oppinitdcleadingcontract="ngModel" name="{{'oppinitdcleadingcontract_'+ i}}"
                         disabled type="radio" [value]="gridData.EndToEndDeliveryCenterId"
                         [(ngModel)]="gridData.DCLeadingdeliveryCenterId" />
                  <i class="c-radio__checked" [value]="gridData.EndToEndDeliveryCenterId"></i>
                </div>
              </td>
              <td *ngIf="endToEndType == 'I'" class="c-text-center">
                <div class="c-radio">
                  <input class="c-radio__input" #oppinitdcleadingcontract="ngModel" name="{{'oppinitdcleadingcontract_'+ i}}"
                         disabled type="radio" [value]="gridData.EndToEndDeliveryCenterId"
                         [(ngModel)]="gridData.DCLeadingdeliveryCenterId" />
                  <i class="c-radio__checked" [value]="gridData.EndToEndDeliveryCenterId"></i>
                </div>
              </td>
              <td class="c-text-center">
                <div class="c-radio">
                  <input class="c-radio__input" #leadingagdgduid="ngModel" type="radio" [disabled]="gridData.DcCountryName != LedByPortfolio || RoleAccess=='V'"
                         name="{{'leadingagdgduid_'+ i}}"
                         (click)="LeadingAGDGDUChange(gridData)" [value]="gridData.EndToEndDeliveryCenterId" [(ngModel)]="gridData.LeadingAGDGDUId" />
                  <i class="c-radio__checked" [value]="gridData.EndToEndDeliveryCenterId"></i>
                </div>
              </td>
              <td [innerHtml]="gridData.DeliveryCenterName"></td>
              <td>
                {{gridData.DeliveryLead}}
              </td>

              <td>

                {{gridData.AccountGroup}}

              </td>

              <td>{{gridData.AccountGroupLead}}</td>

              <td>{{gridData.DeliveryGroup}}</td>
              <td>
                {{gridData.DeliveryGroupLead}}
              </td>
              <td>{{gridData.DeliveryUnit}}</td>

              <td>{{gridData.DeliveryUnitLead}}</td>
            </tr>
          </tbody>

        </table>

      </div>
      <div class="c-pagination-container" *ngIf="noOfPages > 1">
        <nav>
          <ul class="c-pagination_list">
            <li class="c-pagination_arrow">
              <a href="javascript:;" class="c-pagination_previous" [ngClass]="{'disablebtn': curPage == 0}" [disabled]="curPage == 0" (click)="curPage=curPage-1;paginationClick(curPage)">
                <img src=" ../assets/toolkit/images/pagination-icon.svg" alt="Previous">
              </a>
            </li>
            <li class="c-pagination_page-item">
              <a href="javascript:;" class="c-pagination_link">
                <span class="c-pagination_count default-cursor">{{curPage  + 1}}</span>
                <span class="c-pagination_target default-cursor"> <span>of </span> {{noOfPages}}</span>
              </a>
            </li>
            <li class="c-pagination_arrow">
              <a href="javascript:;" class="c-pagination_next" [ngClass]="{'disablebtn': curPage >= noOfPages - 1 }" [disabled]="curPage >= noOfPages - 1" (click)="curPage=curPage+1;paginationClick(curPage)">
                <img src=" ../assets/toolkit/images/pagination-icon.svg" alt="Next">
              </a>
            </li>
          </ul>
        </nav>
        <div class="position-relative">
          <label class="sr-only">Page Selection</label>
        </div>
      </div>

    </div>
    <div role="tabpanel" id="key_network">
      <div class="c-table-responsive">
        <table class="c-table">
          <thead>
            <tr>
              <th scope="col" width="90px"></th>
              <th scope="col" width="120px">Dominant</th>
              <th scope="col" width="175px">Service Group</th>
              <th scope="col" width="125px">Allocation(%)</th>
              <th scope="col">Delivery Lead / POC</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="KeyContactsDetails.EndToEndServiceGroupContacts.length == 0">
              <td colspan="5"> No Records Found </td>
            </tr>
            <tr *ngFor="let item of KeyContactsDetails.EndToEndServiceGroupContacts; let i = index; trackBy : trackByIndex;">
              <td *ngIf="RoleAccess!='V'">
                <a href="javascript:;" class="icon-edit" role="button" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="showServiceGroupNetwork(item,i)" aria-label="Edit"></a>
              </td>
              <td *ngIf="RoleAccess=='V'">
                <a href="javascript:;" class="c-view-link" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" role="presentation" (click)="showServiceGroupNetwork(item,i)" alt="View"/></a>
              </td>
              <td>
                <input class="c-radio__checked" #dominantKC="ngModel" type="radio" name="{{'dominantKC_'+ i}}" aria-label="Select one row to indicate which area is leading opportunity E2E." [value]="item.ServiceGroupId" [(ngModel)]="item.DominantServiceGroupId" [disabled]="true" value="Dominant" />
              </td>
              <td>{{item.ServiceGroupName}}</td>
              <td>{{item.Allocation}}</td>
              <td>{{item.DeliveryLead}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div role="tabpanel" id="key_contacts">
      <div class="c-table-responsive c-lead__table">
        <table class="c-table">
          <thead>
            <tr>
              <th scope="col" width="200px">Enterprise ID</th>
              <th scope="col">Role</th>
              <th scope="col">&nbsp;</th>
              <th scope="col" width="150px" class="c-text-center">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="KeyContactsDetails.EndToEndAlternateContacts.length == 0">
              <td colspan="4"> No Records Found </td>
            </tr>
            <tr *ngFor="let item of KeyContactsDetails.EndToEndAlternateContacts; trackBy : trackByIndex;">
              <td>{{item.EnterpriseId}}</td>
              <td>{{item.AlternateContactRoleName}}</td>
              <td>{{item.AlternateContactRoleTypeId == "4" ? item.OtherRole : ""}}</td>
              <td>
                <div *ngIf="RoleAccess!='V'" class="c-key-icon">
                  <a href="javascript:;" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"aria-label="Delete" class="icon-delete" [ngClass]="(RoleCheck(item.AlternateContactRoleName) ) ? '-disabled' : ''" (click)="RemoveContact(item.AlternateContactRoleTypeId,item.EnterpriseId,item.AlternateContactRoleName)"></a> <!--[ngClass]="(RoleCheck(item.AlternateContactRoleName) ) ? 'hyper-link-disable' : ''"-->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
    <div class="c-myWizard-update__container " *ngIf="!CreatePage">
      <div class="c-myWizard-container">
        <div class="c-myWizard-update__row">
          <div class="c-myWizard-update__details">
            <div class="c-myWizard-update__input">
              <label>Last Updated On:</label>
              <span class="-input">{{KeyContactsDetails.LastModifiedOn |date:'dd MMM yyyy h:mm a'}}{{KeyContactsDetails.LastModifiedOn == null ? "" : " (GMT)"}}</span>
            </div>
            <div class="c-myWizard-update__input">
              <label>Last Updated By:</label>
              <span class="-input">{{KeyContactsDetails.LastModifiedBy}}</span>
            </div>
          </div>
          <div class="c-myWizard-update__contract">
            <div class="c-myWizard-update__input">
              <label *ngIf="(endToEndType == 'C' && KeyContactsDetails.IsCMWContract) || (endToEndType == 'O' && KeyContactsDetails.IsCMWOpportunity) || (endToEndType == 'O' && KeyContactsDetails.CreatedByApp == 'AOIO')">Auto-sourced {{(endToEndType== 'C' && KeyContactsDetails.IsCMWContract) ? "Contract" : (((endToEndType == 'O' && KeyContactsDetails.IsCMWOpportunity) || (endToEndType == 'O' && KeyContactsDetails.CreatedByApp == 'AOIO')) ? "Opportunity" : "")}} Details Last From:</label>
              <span class="-input" *ngIf="(endToEndType == 'C' && KeyContactsDetails.IsCMWContract) || (endToEndType == 'O' && KeyContactsDetails.IsCMWOpportunity) || (endToEndType == 'O' && KeyContactsDetails.CreatedByApp == 'AOIO')">{{(endToEndType == 'C' && KeyContactsDetails.IsCMWContract) || (endToEndType == 'O' && KeyContactsDetails.IsCMWOpportunity) ? "CMW" : "NEST"}}</span>
            </div>
            <div class="c-myWizard-update__input">
              <label *ngIf="(endToEndType == 'C' && KeyContactsDetails.IsCMWContract) || (endToEndType == 'O' && KeyContactsDetails.IsCMWOpportunity) || (endToEndType == 'O' && KeyContactsDetails.CreatedByApp == 'AOIO')">Auto-sourced {{(endToEndType== 'C' && KeyContactsDetails.IsCMWContract) ? "Contract" : (((endToEndType == 'O' && KeyContactsDetails.IsCMWOpportunity) || (endToEndType == 'O' && KeyContactsDetails.CreatedByApp == 'AOIO')) ? "Opportunity" : "")}} Details Last On:</label>
              <span class="-input" *ngIf="(endToEndType == 'C' && KeyContactsDetails.IsCMWContract) || (endToEndType == 'O' && KeyContactsDetails.IsCMWOpportunity) || (endToEndType == 'O' && KeyContactsDetails.CreatedByApp == 'AOIO')">{{(endToEndType== 'C' && KeyContactsDetails.IsCMWContract) ? (KeyContactsDetails.AutosourcedLastModifiedOn |date:'dd MMM yyyy h:mm a') : (((endToEndType == 'O' && KeyContactsDetails.IsCMWOpportunity) || (endToEndType == 'O' && KeyContactsDetails.CreatedByApp == 'AOIO'))? (KeyContactsDetails.AutosourcedOpportunityLastModifiedOn |date:'dd MMM yyyy h:mm a') : "")}} (GMT)</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  <div class="c-select-card">
    <div class="container">
      <div class="c-select-card__wrapper">
        <div class="c-select-card__left">
          <div class="c-select-card__text">Changes made will be lost unless confirmed. Please click 'Save' button to confirm.</div>
        </div>
        <div class="c-select-card__right">
          <button href="javascript:void(0)" class="o-secondary-button" type="button" [disabled]="RoleAccess=='V'" [ngClass]="{'-disabled': RoleAccess=='V' }" (click)="cancel(contactsform)" aria-label="Reset">Reset</button>
          <button href="javascript:void(0)" class="o-primary-button" type="submit" value="submit" [disabled]="RoleAccess=='V'" [ngClass]="{'-disabled': RoleAccess=='V' }" (click)="saveKeyContacts(contactsform.valid,contactsform)" aria-label="Save">Save</button>
        </div>
      </div>
    </div>
  </div>
  <div class="c-select-card__placeholder"></div>    <!-- /body -->
</form>
