<div id="privacyModal" class="c-modal" tabindex="-1" aria-labelledby="modalDialogTitle">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">Data Privacy Statement</h6>
        <button type="button" (click)="closeModal()" class="c-modal__header-close -dismiss-modal" aria-label="Close" alt="Close"></button>
      </div>
      <div class="c-modal__body">
        <p class="c-modal__text">Accenture is committed to keeping your personal data secure, and processing it in accordance with, applicable data protection laws and our internal policies, including <a href="https://policies.accenture.com/policy/0090" target="_blank" rel="noopener noreferrer" class="-link">Accenture Global Data Privacy Policy 0090.</a> Please ensure that the harvesting of data and its usage for Analytics, reporting in myWizard, has been approved by the client. Do not include yours/other's personal, health, financial or sensitive personal data in free form text fields (e.g. "Description"), or upload screen shots containing personal data. If there is a need to mask personal information, please use the <a href="https://alm.accenture.com/wiki/display/MYW/Data+Masking+in+Manual+Uploads" target="_blank" class="-link">Utility</a> to do so before uploading the excel template. By uploading, you consent that you are conforming to the above.</p>
      </div>
    </div>
  </div>
</div>
