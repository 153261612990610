  <div class="c-myWizard-container">
  <div class="c-alertWrap">
    <!-- Alert -->
    <!-- NOTE: Add one of '-success', '-warning', '-danger', '-info' depending on the requirement, Default is '-success' -->
    <div class="o-toaster__wrap -success">
      <div class="o-toaster__icon">
        <div class="-icon"></div>
      </div>
      <div class="o-toaster__content">
        <div class="o-toaster__header">
          <div class="-title">Record Saved Successfully</div>
          <a href="javascript:;" role="button" class="-alert-close"></a>
        </div>
      </div>
    </div>
    <!-- Alert -->
  </div>
  <div class="c-myWizard__header">
    <div class="c-myWizard__header-content-left">
      <div class="c-myWizard__header-content-head">
        <a href="javascript:;" class="icon-banner-arrow" role="button" alt="Back"
           aria-label="Back" (click)="OnBackClick()"></a>
        <div class="-header">Other Configuration Settings</div>
      </div>      
    </div>
    <div class="c-myWizard__header-content-right">
      <div class="c-header-data">
        <div class="c-header-dropdown">
          <a href="javascript:;" role="button" data-myw-title="User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="DisplayOtherconfigUserGuide()" class="-dropdown-icon"><img src="../assets/toolkit/images/user-guide-stories/user-guide.svg" alt="User Guide" /></a>
        </div>
      </div>
    </div>
    </div>
  <div class="c-checkbox   ">
    <input type="checkbox" id="check-one" class="c-checkbox__input" name="unitcheckbox"
           [(ngModel)]="isAggregated" (click)="changeaggregation($event)"[disabled]="accessFlag" />
    <i class="c-checkbox__checked"></i>
    <label for="check-one" class="c-checkbox__label">
      Do you want to aggregate manually sourced
      measures from one or more teams?
    </label>
  </div>
  <ng-container *ngIf="isAggregated">
    <div class="c-configuration-title">Select Teams</div>
    <div class="c-configuration-input">
      <div class="c-checkbox" *ngFor="let team of aggregatedTeams">
        <input type="checkbox" [attr.id]="team.Name" class="c-checkbox__input"[disabled]="accessFlag" name="unitcheckbox"
               [checked]="team.IsSelected" [(ngModel)]="team.IsSelected"(change)="onChange(team.UId)" />
        <i class="c-checkbox__checked"></i>
        <label [attr.for]="team.Name" class="c-checkbox__label ">{{team.Name}}</label>
      </div>

    </div>
  </ng-container>
</div>


<div class="c-select-card">
  <div class="app-container">
    <div class="c-select-card__wrapper">
      <div class="c-select-card__left">
        <div class="c-select-card__text">Changes made will be lost unless confirmed. Please click 'Save' button to confirm.</div>
      </div>
      <div class="c-select-card__right">
        <a href="javascript:void(0)" class="o-secondary-button" role="button"
           (click)="cancelSettings()" [ngClass]="isButtonDisable('cancel')||!accessFlag?'':'-disabled'">Reset</a>
        <a href="javascript:void(0)" class="o-primary-button -config-save" role="button" [ngClass]="isButtonDisable('save')||!accessFlag?'':'-disabled'"
           (click)="UpdateAggregatedTeams()">Save</a>
      </div>
    </div>
  </div>
</div>
<div class="c-select-card__placeholder"></div>



<!--<div class="mainContentConatiner col-sm-12 col-xs-12 col-lg-12"
     *ngIf="this.objGlobal.getSessionValue('ssCMSWSUId') == null
 && this.objGlobal.getSessionValue('ssWSUId') == null
 && this.objGlobal.getSessionValue('ssCMSUId') == null">
  <div class="">
    <div *ngIf="teamError">
      <div>
        <div class="alert alert-danger">
          <span>Teams are not available for selected contract.</span>
        </div>
      </div>
    </div>
    <div *ngIf="myOptions.length!=0" class="row">
      <div *ngIf="isError" class="alert alert-danger">
        <strong>Error! </strong>{{Error}}
      </div>

      <div class="col-sm-4">
        <div class="form-group col-xs-12">
          <label>Do you want to aggregate manually sourced measures from one or more teams?</label>
        </div>
      </div>
      <div *ngIf="accessFlag" class="col-sm-3 pull-left marginLeft">
        <label [ngClass]="IsShow  ? 'switch yesDefault mandatory ' : 'switch yesDefault mandatory viewer' ">
          <input class="switch-input" [disabled]="accessFlag" (change)="isManuallyChange($event)" checked [(ngModel)]="IsShow" type="checkbox">
          <span class="switch-label" data-on="No" data-off="yes"></span> <span class="switch-handle"></span>
        </label>
      </div>
      <div *ngIf="!accessFlag" class="col-sm-3 pull-left marginLeft">
        <label [ngClass]="accessFlag  ? 'switch yesDefault mandatory viewer' : 'switch yesDefault' ">
          <input class="switch-input" [disabled]="accessFlag" (change)="isManuallyChange($event)" checked [(ngModel)]="IsShow" type="checkbox">
          <span class="switch-label" data-on="No" data-off="yes"></span> <span class="switch-handle"></span>
        </label>
      </div>
    </div>

    <div class="row configureContent" *ngIf="IsShow">
      <div class="col-sm-4">
        <label class="form-label">Select Teams</label>
      </div>
      <div *ngIf="!accessFlag" class="col-sm-2 pull-left marginLeft">
        <ss-multiselect-dropdown (ngModelChange)="onChange($event)" [options]="myOptions" [texts]="myTexts" [settings]="mysettings" [(ngModel)]="optionsModel"></ss-multiselect-dropdown>
      </div>
      <div *ngIf="accessFlag" class="col-sm-2 pull-left marginLeft">
        <input type="text" Class="form-control"
               value="{{SelectedTeam}}" title="{{SelectedTeam}}" disabled />
      </div>
    </div>
    <div class="col-sm-12 btnWrapper " *ngIf="myOptions.length!=0">
      <div class="col-sm-4"></div>
      <div class="col-sm-2 btnContainer otherConfig">
        <button class="btn btn-primary" [disabled]="accessFlag" (click)="TeamUpdateClick(optionsModel)" (keyup.enter)="TeamUpdateClick(optionsModel)">Save</button>
        <button (click)="cancelClick()" (keyup.enter)="cancelClick()" [disabled]="accessFlag" class="btn btn-primary">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="this.objGlobal.getSessionValue('ssCMSWSUId') != null || this.objGlobal.getSessionValue('ssWSUId') != null || this.objGlobal.getSessionValue('ssCMSUId') != null" class="collapse navbar-collapse accessScopeSelectorStatement">
  Metric Configuration is not applicable for the selected scope(CMS/Workstream). In order to view the metrics, please select either the Endtoend or Team from the scope selector.
</div>
<alert-box tabindex="0"></alert-box>-->
