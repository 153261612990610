import { Component, HostListener, Input } from '@angular/core';
import { TileService } from '../../core/services/tile.service';
import { SessionStorage } from '../../core/context/sessionstorage';
import { enumSubmissionType } from '../../core/context/enum';
import * as d3 from 'd3';

@Component({
  selector: 'action-manager',
  templateUrl: './action.manager.component.html'
})

export class ActionManagerComponent {
  deliveryConstructUId: any;
  teamUId: any = null;
  submissionType: any;
  ActionManager: any = {};
  openCount: number;
  OverdueCount: number;
  UpComingCount: number;
  /*VerifyCount: number;*/
  verification: any = 0;
  //other: any = 0;
  active: any = 0;
  overdue: any = 0;
  opacityHover: any = 1;
  otherOpacityOnHover: any = .8;
  duration: any = 750;
  padding: any = 10;
  opacity: any = .8;
  OtherCount: number;
  @Input() tile: any;

  constructor(private sessionStorage: SessionStorage, private tileService: TileService) { }
  data: any = [];

  svg: any;

  width: any;

  height: any;

  rx: any = 5;

  ry: any = 5;

  containerHeight: any;

  containerWidth: any;

  barWidth = 25;
  minHeight = 120;

  margin = {
    top: 20,
    right: 0,
    bottom: 40,
    left: 0,
  };

  yMaxValue = 100;

  selector = 'it-spend-selector';

  renderSVG(): any {
    const elem = document.getElementsByClassName(
      this.selector
    )[0] as HTMLElement;
    elem.innerHTML = '';
    this.svg = d3
      .select(`.${this.selector}`)
      .append('svg')
      .attr('id', 'app-svg')
      .attr('width', this.containerWidth)
      .attr('height', this.containerHeight);
    this.width = this.containerWidth - this.margin.left - this.margin.right;
    this.height = this.containerHeight - this.margin.top - this.margin.bottom - 20;
  }

  graph(): any {
    return this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top + 20})`)
      .attr('id', 'graph-container');
  }

  xScale(): any {
    return d3
      .scaleBand()
      .domain(this.data.map((d: any) => d.name))
      .rangeRound([0, this.width])
      .paddingInner(0.7)
      .paddingOuter(0.5);
  }

  yScale(): any {
    return d3
      .scaleLinear()
      .rangeRound([this.height, 0])
      .domain([
        0,
        this.yMaxValue % 2 === 0 ? this.yMaxValue + 1 : this.yMaxValue,
      ])
      .nice();
  }

  renderHorizontalLines(): any {
    const makeYLines = () => d3.axisLeft(this.yScale()).tickPadding(12);
    this.graph()
      .append('g')
      .attr('class', 'grid')
      .attr('stroke-width', '1px')
      .attr('color', '#EDEDED')
      .attr('font-family', 'Graphik')
      .attr('font-weight', 500)
      .call(makeYLines().ticks(1).tickSize(-this.width))
      .call((d: any) => {
        d.select('.domain').remove();
        d.selectAll('.tick')._groups[0][1].remove();
      });
  }

  renderBar(): any {
    const bar = this.graph().append('g').selectAll('g').data(this.data)
      .enter();

    bar
      .append('g')
      .attr('class', 'barRx')
      .append('path')
      .attr('d', (item: any) => (item.value > 0
        ? `
        M${this.xScale()(item.name) - this.barWidth / 5} , ${this.yScale()(
          item.value
        )}
        a${this.rx},${this.ry} 0 0 1 ${this.rx},${-this.ry}
        h ${this.barWidth}
        a${this.rx},${this.ry} 0 0 1 ${this.rx},${this.ry}
        v${this.height - this.yScale()(item.value)}
        h -${this.barWidth + 10}
        Z
        `
        : null))
      .style('fill', (d: any) => d.gColor);
  }

  renderBarValues(): any {
    this.graph()
      .selectAll('text')
      .data(this.data)
      .join('text')
      .attr('class', 'bar-label1')
      .attr('font-size', '12px')
      .attr('font-family', 'Graphik')
      .attr('font-weight', 500)
      .attr('fill', '#515151')
      .attr('text-anchor', 'middle')
      .attr('x', (d: any) => this.xScale()(d.name) + this.barWidth / 2)
      .attr('y', (d: any) => this.yScale()(d.value) - (d.value > 0 ? 20 : 15))
      .text((d: any) => `${d.name}`);

    this.graph()
      .selectAll('text')
      .data(this.data)
      .join('text')
      .attr('class', 'bar-label2')
      .attr('font-size', '10px')
      .attr('font-family', 'Graphik')
      .attr('font-weight', 500)
      .attr('fill', '#515151')
      .attr('text-anchor', 'middle')
      .attr('x', (d: any) => this.xScale()(d.name) + this.barWidth / 2)
      .attr('y', (d: any) => this.yScale()(d.value) - 9)
      .text((d: any) => (d.value > 0 ? `${d.value}%` : null));
  }

  renderXAxis(): any {
    //this.renderCircle();
    this.renderCircleText();
  }

  renderCircle() {
    this.graph()
      .selectAll()
      .append('g')
      .data(this.data)
      .enter()
      .append('circle')
      .attr('class', 'circClass')
      .attr('cx', (d: any) => this.xScale()(d.name) + this.barWidth / 2)
      .attr('cy', () => this.yScale()(-35))
      .attr('r', 12.5)
      .attr('fill', '#E1E1E1');
  }

  renderCircleText() {
    const xAxis = this.graph()
      .append('g')
      .attr('class', 'x-axis')
    //.attr('transform', `translate(0,${this.height})`);

    xAxis
      .selectAll('text')
      .data(this.data)
      .join('text')
      .attr('font-family', 'Graphik')
      .attr('font-weight', 500)
      .attr('font-size', '12px')
      .attr('color', '#515151')
      .attr('x', (d: any) => `${this.xScale()(d.name) + this.barWidth / 2}`)
      .attr('y', () => this.yScale()(-35) + 5)
      .attr('text-anchor', 'middle')
      .text((d: any) => d.count);
  }

  drawGraph(): void {
    this.renderSVG();
    this.renderHorizontalLines();
    this.renderBar();
    this.renderBarValues();
    this.renderXAxis();
  }

  //@HostListener('window:resize', ['$event'])
  //onResize(): any {
  //  this.drawGraph();
  //}

  ngOnInit() {
    this.submissionType = this.sessionStorage.workstreamUId ? (this.sessionStorage.teamUId ? enumSubmissionType.WorkstreamTMS : enumSubmissionType.Workstream) : this.sessionStorage.selectedSubmissionType;

    switch (this.submissionType) {
      case 1: {
        this.deliveryConstructUId = this.sessionStorage.endToEndUId;
        break;
      }
      case 2: {
        this.deliveryConstructUId = this.sessionStorage.clientWMSUId;
        break;
      }
      case 3: {
        this.deliveryConstructUId = this.sessionStorage.workstreamUId;
        break;
      }
      case 4: {
        this.deliveryConstructUId = this.sessionStorage.endToEndUId;
        this.teamUId = this.sessionStorage.teamUId;
        break;
      }
      case 5: {
        this.deliveryConstructUId = this.sessionStorage.clientWMSUId;
        this.teamUId = this.sessionStorage.teamUId;
        break;
      }
      case 6: {
        this.deliveryConstructUId = this.sessionStorage.workstreamUId;
        this.teamUId = this.sessionStorage.teamUId;
        break;
      }

    }

    this.tileService.getActionManager(this.deliveryConstructUId, this.teamUId).subscribe((response: any) => {    
      this.ActionManager = response;
      this.ActionManager.OpenCount = response.OpenCount;
      this.ActionManager.OverdueCount = response.OverdueCount;
      this.ActionManager.UpcomingCount = response.UpcomingCount;
      this.openCount = response.OpenCount;
      this.OverdueCount = response.OverdueCount;
      this.UpComingCount = response.UpcomingCount;
      this.OtherCount = response.OpenCount - response.OverdueCount - response.UpcomingCount;
      this.active = (this.openCount / (this.openCount + response.OnHoldCount + response.ClosedCount)) * 100;
      this.verification = this.UpComingCount * 100 / this.openCount;
      //this.other = this.OtherCount * 100 / this.openCount;
      this.overdue = this.OverdueCount * 100 / this.openCount;

      if (this.OverdueCount > 0 || this.UpComingCount > 0 || this.OtherCount > 0) {
        var amData =
          [
            { gColor: '#FBE7F8', count: this.openCount, name: 'Open', value: this.active >= 0 && this.active != null ? Number(this.active.toFixed(2)) : 0, slicedValue: this.active >= 0 && this.active != null ? parseInt(this.active.toString()) : 0 },
            { gColor: '#BE8DC3', count: this.OverdueCount, name: 'Overdue', value: this.overdue >= 0 && this.overdue != null ? Number(this.overdue.toFixed(2)) : 0, slicedValue: this.overdue >= 0 && this.overdue != null ? parseInt(this.overdue.toString()) : 0 },
            { gColor: '#F3B0DE', count: this.UpComingCount, name: 'Upcoming', value: this.verification >= 0 && this.verification != null ? Number(this.verification.toFixed(2)) : 0, slicedValue: this.verification >= 0 && this.verification != null ? parseInt(this.verification.toString()) : 0 },
          ];

        //amData = amData.filter(x => x.value != 0)
        let e = document.getElementById('action_manager_tile_content');
        this.containerHeight = e.clientHeight - this.minHeight;
        this.containerWidth = e.clientWidth - this.margin.bottom;
        this.data = amData;
        this.drawGraph();

      }
      else {
        d3.select("#it-spend-selector").html('<div style="height:100px">No data to display</div>');
      }
    });
  }
}
