import { Component, Input } from '@angular/core';
import { RIMSummaryService } from '../../core/services/rim.summary.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'risk-tile',
  templateUrl: './risk.tile.component.html'
})
export class RiskTileComponent {

  RIMSummaryCount: any = {};
  response:any
  RIM: any = {};
  @Input()
  tile: any;
  green: string = "#A3DFA5";
  amber: string = "#FFD985";
  red: string = "#FF806B";
  GreenThreshold: number;
  AmberThreshold: number;
  IsGridEmpty: boolean = false;
  RIMSummarySubscription: Subscription;

  constructor(private rimSummaryService: RIMSummaryService) { }

  ngOnInit() {
    this.RIMSummarySubscription = this.rimSummaryService.getData().subscribe((response) => {
      if (response != null)
        this.getRIMSummary(response);
    })
  }

  ngOnDestroy() {
    this.RIMSummarySubscription.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.IsGridEmpty == false) {
      this.setGridColor();
    }
  }

  getRIMSummary(data) {
    this.response = data;
    if (this.response != undefined || this.response != null) {
      this.RIMSummaryCount = this.response.Summary;
      this.RIM = this.response.TableColumnMapping;
      this.GreenThreshold = this.response.GreenThreshold;
      this.AmberThreshold = this.response.AmberThreshold;
    }
    if ((this.GreenThreshold == 0) && (this.AmberThreshold == 0)) {
      this.IsGridEmpty = true;
    } else {
      this.setGridColor();
    }
  }

  setGridColor(){
  var ids = document.getElementsByClassName('risk_td');
    for (var i = 0; i < ids.length; i++){
      var cellValue = Number(ids[i].id.slice(ids[i].id.length - 2)); // cellValue = Impact * Probability
      switch (true) {
        case cellValue <= this.GreenThreshold:
          document.getElementById(ids[i].id).style.backgroundColor = this.green;
          break;
        case cellValue > this.GreenThreshold && cellValue <= this.AmberThreshold:
          document.getElementById(ids[i].id).style.backgroundColor = this.amber;
          break;
        case cellValue > this.AmberThreshold:
          document.getElementById(ids[i].id).style.backgroundColor = this.red;
          break;
      }
    }
  }

}
