<div class="bubble-report-metrics__wrapper">
  <div id="bubble-report-metrics" class="bubble-report-metrics"
      >


  </div>
  <div class="tooltip"></div>
</div>
 

  <div class="c-manage-dashboard-report" *ngIf="complianceStatus != undefined">
    <div class="c-manage-dashboard-report-item">
      <div class="c-manage-dashboard-report-data">
        <div class="-title">
          <div>Last Submitted</div>
        </div>
        <ng-container *ngIf="complianceStatus.SubmittedEndPeriod && complianceStatus.SubmittedEndPeriod  != 'N/A'">
          <label>{{complianceStatus.SubmittedEndPeriod}}</label>
          <span>On {{complianceStatus.SubmittedDate}}</span>
        </ng-container>
        <ng-container *ngIf="!complianceStatus.SubmittedEndPeriod || complianceStatus.SubmittedEndPeriod  == 'N/A'">
          <span>{{complianceStatus.SubmittedDate}}</span>
        </ng-container>
      </div>
    </div>
    <div class="c-manage-dashboard-report-item">
      <div class="c-manage-dashboard-report-data">
        <div class="-title">
          <div> Next Due</div>
          <a href="javascript:;" class="icon-timer" data-myw-title="All due dates are converted to your workstation's time zone" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-manage-tooltip">&nbsp;</a>
        </div>
        <ng-container *ngIf="complianceStatus.DueDate != 'Optional' && complianceStatus.DueDate != 'N/A' && complianceStatus.DueDate">
          <label>{{complianceStatus.NextDueDatePeriod}}</label>
          <span>On {{complianceStatus.DueDate | WorkstationDateTimeConversion}}</span>
        </ng-container>
        <ng-container *ngIf="complianceStatus.DueDate == 'Optional' || complianceStatus.DueDate == 'N/A' || !complianceStatus.DueDate">
          <span>{{complianceStatus.DueDate}}</span>
        </ng-container>
      </div>
    </div>
  </div>
 
  <!--<div class="c-manage-dashboard-note">
    <div class="c-legends-item">
      <i style="background-color: #7500C0;"></i>
      <span>All due dates are converted to your workstation's time zone</span>
    </div>
  </div>-->
