<div *ngIf="AvanadeEAC">
  <div class="c-manage-dashboard__image">
    <img class="tile-img" src="assets/images/Tiles/Management/EACForecast.svg" alt="EACForecast.svg" />
  </div>
  <div class="tile-due-date">
    <div class="tile-width">
      <br />
      <label>Last Submitted:</label>&nbsp;<span>{{AvanadeEAC.ModifiedOn == null ? "" :AvanadeEAC.ModifiedOn | date:'dd MMM yyyy'}}</span>
    </div>
  </div>
</div>

