<div class="app-wrap -flex">
  <div class="c-myWizard c-user-guide-stories -collapsed">
    <div class="c-myWizard-content">
      <div class="c-myWizard-container">
          <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': prevContent}">
            <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/client-user-guide-1.jpg" alt="UserGuide" role="presentation" /></div>
            <div class="c-user-guide-stories-container -client-userguide1">
              <div class="c-user-guide-stories-data">
                <div class="c-user-guide-stories-data__header">
                  <div class="-title">Comprised of one-or-more work streams:</div>
                  <a href="javascript:;" role="button" data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
                </div>
                <div class="c-user-guide-stories-data__body">
                  <ul class="c-myWizard-list c-user-guide-stories__list">
                    <li>Conventional Release or Project</li>
                    <li>Infrastructure Release</li>
                    <li>Application or Application Group</li>
                    <li>Operations</li>
                    <li>Sprints, Agile Releases</li>
                    <li>Security Service</li>
                  </ul>
                </div>
                <div class="c-user-guide-stories-data__footer">
                  <div class="c-user-guide-stories__progress">
                    <div class="c-user-guide-stories__progress-bar">
                      <span style="--value:50%"></span>
                    </div>
                    <div class="c-user-guide-stories__progress-data">
                      <span>1</span>
                      <span>/ 2</span>
                    </div>
                  </div>
                  <div class="c-user-guide-stories__actions">
                    <a href="javascript:;" role="button" class="-prev" [ngClass]="{'-disabled': prevButton}">PREV</a>
                    <a href="javascript:;" role="button" class="-next" [ngClass]="{'-disabled': nextButton}" (click)="DisplayNextContent()">NEXT</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': nextContent}">
            <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/client-user-guide-2.jpg" alt="UserGuide" role="presentation" /></div>
            <div class="c-user-guide-stories-container -client-userguide2">
              <div class="c-user-guide-stories-data">
                <div class="c-user-guide-stories-data__header">
                  <div class="-title">Should Represnt</div>
                  <a href="javascript:;" role="button" data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
                </div>
                <div class="c-user-guide-stories-data__body">
                  <ul class="c-myWizard-list c-user-guide-stories__list">
                    <li>Distinct Stakeholder groups within the client</li>
                    <li>Separate Divisions or Lines of Business within the client</li>
                    <li>Analytical views of interest to the client</li>
                    <li>How we would organise to discuss and report results to the client</li>
                  </ul>
                  <div class="-title">Should Not Represnt</div>
                  <ul class="c-myWizard-list">
                    <li>Accenture organisations (Centres, Service groups, RBEs)</li>
                  </ul>
                </div>
                <div class="c-user-guide-stories-data__footer">
                  <div class="c-user-guide-stories__progress">
                    <div class="c-user-guide-stories__progress-bar">
                      <span style="--value:100%"></span>
                    </div>
                    <div class="c-user-guide-stories__progress-data">
                      <span>2</span>
                      <span>/ 2</span>
                    </div>
                  </div>
                  <div class="c-user-guide-stories__actions">
                    <a href="javascript:;" role="button" class="-prev" [ngClass]="{'-disabled': prevButton}" (click)="DisplayPrevContent()">PREV</a>
                    <a href="javascript:;" role="button" class="-next" [ngClass]="{'-disabled': nextButton}">NEXT</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
      </div>
    </div>
  </div>
</div>
