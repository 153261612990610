
  <ngx-spinner template="<img src='assets/toolkit/images/loading.gif' width='64px' height='64px'/>"></ngx-spinner> <!--to do -- need to remove this line as this is implemented globaly-->
    <div class="c-dropdown -right -static c-header-dropdown">
      <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" class="-dropdown-icon"><div class="display-inline" data-myw-title="Download" data-myw-toggle="tooltip" data-myw-placement="bottom"><img src="../assets/toolkit/images/key-contacts/download.svg" alt="Download" /></div></a>
      <div class="c-dropdown-menu">
        <div class="c-dropdown-body">
          <div>
            <a *ngIf="source=='DeliveryProfileGuide' || source=='Scope'"
               href="javascript:;" class="c-dropdown-item" id="downloadDeliveryProfileGuide" role="button" data-myw-title="Click here to know more about the fields available in Profile, Scope & Key Contacts" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="DownloadTemplate('DeliveryProfileGuide','downloadDeliveryProfileGuide','xlsx')">
               Delivery Profile Guide
            </a>
            <a *ngIf="source=='Scope'"
               href="javascript:;" class="c-dropdown-item" id="deliveryScopeDetails" role="button" data-myw-title="Click here to know about the mapping between Delivery Scope and Delivery Type" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="DownloadTemplate('Delivery Scope alignment with Delivery Type','deliveryScopeDetails','xlsx')">
               Delivery Scope with Delivery Type mapping
            </a>
            <a *ngIf="source=='Scope' && EndToEndType == 'C' && !CreatePage"
               href="javascript:;" class="c-dropdown-item" id="classAssetsDetails" role="button" data-myw-title="Click here to know about the Class A assets" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="DownloadTemplate('Class A Assets Details','classAssetsDetails','xlsx')">
               Details on Class A assets
            </a>
            <a *ngIf="source=='ManageReleaseNote'"
               href="javascript:;" class="c-dropdown-item" download-active="false" id="downloadReleaseNotes" name="downloadReleaseNotes" aria-label="Download release notes" role="button"  data-myw-title="Click to download the file of Manage Release Notes" data-myw-toggle="tooltip" data-myw-placement="bottom"
               (click)="DownloadTemplate('ReleaseNotes','ManageReleaseNote','xlsx')">
              Manage Release Note
            </a>
          </div>
        </div>
      </div>
    </div>






