<div id="deleteModal" class="c-modal " style="visibility: visible;" aria-modal="true" role="dialog" tabindex="-1" aria-labelledby="modalDialogTitle">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">{{title}}</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close" (click)="closeAlertPopup()" alt="Close"></button>
      </div>
      <div class="c-modal__body">
        <p class="c-modal__text" [innerHTML]="message"></p>
      </div>
      <div class="c-modal__footer">
        <div class="c-modal__footer-btnGroup">
          <button type="button" class="o-secondary-button" (click)="closeAlertPopup()" data-dismiss="modal" [attr.aria-label]="okButton">{{okButton}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="c-modal__overlay -dismiss-modal"></div>
<span tabindex="0" onfocus="document.getElementById('helpalertclosepopup').focus();"> </span>
