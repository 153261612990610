import { Component, Input } from '@angular/core';

@Component({
  selector: 'pca-portfolio',
  templateUrl: './pca.portfolio.component.html'
})

export class PCAPortfolioComponent {

  @Input()
  tile: any;
  constructor() { } 

  ngOnInit() {
  }
  
}

