import { Component, Output, EventEmitter, HostListener, Injectable, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorage } from '../../core/context/sessionstorage';
import { ApplicationMessages, enumModuleAction, enumTile, enumFeatureAccess, enumUserPreference, enumCMS, enumEntityType, enumSubmissionType, enumDeliveryType, enumProfileTab, enumMetricDeliveryLevel } from '../../core/context/enum';
import { Observable } from 'rxjs';
import { ConfigureMetricService } from '../../core/services/configure.metrics.services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MetricFilterComponent } from './metrics.filter.component';
import { AdminMetricComponent } from './metric.add.edit.component';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
import { InitiateProfileComponent } from '../initiate.profile.component/initiate.profile.component';
import { SharedService } from '../../core/services/shared.service';
import { InitiateProfileSharedService } from '../initiate.profile.component/initiate.profile.shared.service';
import { MetricViewComponent } from './metric.view.component';
import { environment } from '../../../environments/environment';
import { forEach } from 'lodash';
declare var userNotification: any;
declare var window: any;
var $ = (<any>window).$;


@Component({
  selector: 'metric-selection',
  templateUrl: './metricselection.context.component.html',
  providers: [ConfigureMetricService, BsModalService]
})

export class MetricSelectionContextComponent {
  @Output() InitiateProfileTabSetup = new EventEmitter();
  enterpriseId: string;
  deliveryId: string;
  deliveryLevelId: number;
  e2eId: number;
  teamId: number;
  teamName: string;
  selectedTeamId: any;
  selectedTeamName: string;
  selectedLevelId: boolean;
  selectedE2EId: any;
  errorMessage: string;
  loadingStatus: boolean;
  selectedProcess: string;
  filtMetricName: string;
  filtMetricId: any;
  selectedSearchContext: any;
  selectedWorkstream: string;
  metricsConfigData: any;
  metricConfigDatadup: any;
  SICoreMetricsIndicator: any;
  message: string;
  metricsConfigAllData: any;
  teamList: any;
  businessContexts: any[];
  accessFlag: boolean;
  oldBusinessContext: Array<string> = [];
  DeliveryId: any;
  DeliveryLevelId: any;
  metrinConfigJsonString: string;
  tempmetricConfig: any;
  E2EId: any;
  selectedCreate: boolean = false;
  selectedAction: any;
  Metricid: number;
  showModal1: any = false;
  popup: string;
  isDirty: boolean = false;

  multiSelectProcessOptionsSearch: any[];
  multiSelectWorkstreamOptionsSearch: any[];
  businessContextSearchAll: any[];
  searchInScope: any[];
  searchApplicability: any[];
  selectApplicability: string = '0';

  metricsSearchData: any = []

  multiAllBussinessProcess: any[];
  ProcessWorkstreamMappingProcess: any[];
  ProcessWorkstreamMapping: any[];

  multiSelectProcessApplicabilityOptions: any = [];
  multiSelectProcessOptions: any = [];
  AllmultiSelectProcessOptions: any = [];

  filtReportingStatus: string = '0';

  /*Paging*/
  curPage: number = 0;
  curPageAFSM: number = 0;
  pageSize: number = 10;
  totalMetrics: number;
  noOfPages: number;
  startIndex: number = 0;
  endIndex: number;
  filterMetricData: any = [];

  /* Filters */
  metricDropDown: any = [];
  deliverFunctionDropDown: any = [];
  reportAgainstDropdown: any = [];
  businessContextDropdown: any = [];
  inScopeDropdown: any = [];
  applicabilityDropdown: any = [];

  selectedMetricFilterId: number = 0;
  selectedDFFilterId: number = 0;
  selectedReportAgainstFilterId: number = 0;
  selectedbusinessContextFilterId: number = 0;
  selectedIsScopeFilterId: number = 0;
  selectedApplicabilityId: number = 0;

  sortBy: any = 'MetricName';
  sortType: any = 'asc';
  reverse1: boolean = false;
  reverse2: boolean = false;
  reverse3: boolean = false;

  metricConfig: any = [];

  lastUpdateBy: string;
  lastUpdateOn: string;

  ApplicabilitySourceData: string = "";
  modalRef: BsModalRef;
  ApplicabilityPopup: boolean = false;
  metricsSet: string = "0";
  metricsType: string = "Revised";

  //excel variables
  worksheetName: string;
  excelFileName: string
  exportData: Array<any> = null;
  exportDataExcel: any[];
  tempThreshold1: any;
  tempThreshold2: any;
  tempThreshold3: any;
  tabledefaultheight: boolean = false;
  tablerecords: any;
  commentflag: boolean = false;
  Roleaccesscode: any;

  columns = {
    MetricID: 'Metric ID', MetricName: 'Metric Name', DeliveryType: 'Delivery Type', DeliveryFunction: 'Delivery Function', WorkstreamType: 'Workstream Type', Definition: 'Definition', Formula: 'Formula',
    ThresholdType: 'Threshold Type', Applicability: 'Applicability', Threshold1: 'Threshold 1', Threshold2: 'Threshold 2', Threshold3: 'Threshold 3',
    Unit: 'Unit', Sourcing: 'Sourcing', Category: 'Category'
  };
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  e2eflag: string;
  keyContactTabHighlight: boolean;
  ProfileTabHighlight: boolean;

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    if (this.isDirty) {
      return confirm('Changes you made may not be saved.');
    }
    else {return true; }
    
  }
  constructor(
    private sessionStorage: SessionStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metricService: ConfigureMetricService,
    private modalService: BsModalService,
    private sharedService: SharedService,
    private initiateProfileComponent: InitiateProfileComponent,
    private initiateProfileSharedService: InitiateProfileSharedService,
  ) {
    this.excelFileName = 'ApplicabilityInfo.xlsx';
  }
  ngOnInit() {

    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['MyWizard/Back/Profile'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId, profileTabCode: 'MetricSelection' } });
      return false;
    }
    //this.initiateProfileSharedService.ValidateDemographicTabHighlight(this);
    //this.initiateProfileSharedService.errorTabHighlight.subscribe(result => {
    //  this.InitiateProfileTabSetup.emit({ keyContactTabHighlight: this.keyContactTabHighlight, ProfileTabHighlight: this.ProfileTabHighlight, tabId: enumProfileTab.MetricSelection });
    //});
    this.Roleaccesscode = this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);

    this.accessFlag = this.Roleaccesscode == 'E' ? false : true;

    this.enterpriseId = this.sessionStorage.enterpriseId;

    if (this.sessionStorage.endToEndId != null && this.sessionStorage.endToEndId != undefined) {
      this.e2eId = this.sessionStorage.endToEndId;
    }
    if (this.sessionStorage.tmsName != null && this.sessionStorage.tmsName != undefined) {
      this.teamName = this.sessionStorage.tmsName;
    }
    if (this.sessionStorage.teamId != null && this.sessionStorage.teamId != undefined) {
      this.selectedTeamId = this.sessionStorage.teamId;
      this.teamId = this.selectedTeamId;
    }
    
    if (this.sessionStorage.teamUId != null && this.sessionStorage.teamUId != '') {
      this.deliveryLevelId = enumMetricDeliveryLevel.Team;
      this.deliveryId = this.sessionStorage.teamUId
    }
    else {
      this.deliveryLevelId = enumMetricDeliveryLevel.E2E;
      this.deliveryId = this.sessionStorage.endToEndUId
    }
    if (this.deliveryId != null && this.deliveryLevelId != null) {
      // this.getBusinessContext();
      //this.checkDeliveryData(this.deliveryId);
      this.selectedE2EId = this.deliveryId;
      if (this.deliveryLevelId == enumMetricDeliveryLevel.Team) {
        this.selectedLevelId = true;
      }
      else {
        this.selectedLevelId = false;
      }

    }

    this.getMetricData(this.deliveryId, this.deliveryLevelId)
    setTimeout(() => {
      window.TooltipFunc();
      window.dropdownComponent();
    }, 500);

  }

  //ngAfterViewInit() {
  //  setTimeout(() => {
  //    window.dropdownComponent();
  //  }, 100)
  //}

  getBusinessContext(): void {
    this.metricService.getBusinessContext().subscribe(
      contexts => { this.businessContexts = contexts },
      error => {
        this.errorMessage = <any>error
      });
  }
  OnBackClick() {
    this.initiateProfileComponent.OnBackClick();
  }
  //Function For Check Delivery Function Exist
  checkDeliveryData(selectedContract: any): void {
    //this.sessionStorage.setSessionValue("tempSelectedTeamId", "0");
    //this.sessionStorage.setSessionValue("selectedTeamID", "0");
    if (this.deliveryLevelId == 4) {
      this.metricService.checkDeliveryData(selectedContract).subscribe(
        existData => {
          if (existData.toString() == "1") {
            if (this.deliveryId != null && this.deliveryLevelId != null) {
              this.getMetricData(this.deliveryId, this.deliveryLevelId)
            }
          }
          else if (existData.toString() == "0") {
            this.loadingStatus = false;
            this.errorMessage = 'Metrics Configuration is not applicable until at least one delivery type or delivery function is configured.';
            //this.baseURL = global.environment.backButtonUrl;
            // Changed BackButtonUrl As per Story 118456 - MSE: A a MSE user, I should be able to use the 'back' navigation from configure metric and submission overview screens to go back to the respective tiles.
            //this.baseURL = global.environment.backButtonUrl + "Profile?endToEndUId=" + this.DeliveryId + "&profileTabCode=Scope";
          }
        },
        error => this.errorMessage = <any>error);
    }
    else {
      if (this.deliveryId != null && this.deliveryLevelId != null) {
        this.getMetricData(this.deliveryId, this.deliveryLevelId)
      }
      this.selectedTeamId = this.deliveryId;
      this.selectedTeamName = this.teamName;

    }
  }

  //Get Metric Config Data for contract using this function
  getMetricData(selectedContract: any, selectedLevel: any): void {
    //Subscribe Method
    this.tabledefaultheight = false;
    this.selectedMetricFilterId = 0;
    this.selectedDFFilterId = 0;
    this.selectedReportAgainstFilterId = 0;
    this.selectedbusinessContextFilterId= 0;
    this.selectedIsScopeFilterId= 0;
    this.selectedApplicabilityId= 0;
    this.metricService.getMetricConfigData(selectedContract, selectedLevel).subscribe(
      metrics => {
        this.metricsConfigAllData = metrics[0].deliveryStructReporting;
        this.SICoreMetricsIndicator = metrics[0].SICoreMetricsIndicator;
        this.businessContexts = metrics[0].busineesContextMaster.map(x => { x.IsSelected = false; return x; });
        this.lastUpdateBy = metrics[0].LastUpdatedBy;
        this.lastUpdateOn = metrics[0].LastUpdatedOn;
        this.e2eflag = metrics[0].e2eflag;

        this.message = '';
        this.loadingStatus = false;
        this.metricsConfigAllData.forEach((x) => {
          if (x.BusinessContextId != null && x.BusinessContextId != 0)
            x.BusinessContextValue = this.getBusinessContextValue(x.BusinessContextId);
          else
            x.BusinessContextValue = 'Select'

          x.isModified = 0;
        });

        ///*filter dropdown initialization*/
        this.loadFilterDropdownOptions(metrics);
       
        this.tempmetricConfig = JSON.parse(JSON.stringify(this.metricsConfigAllData));
        this.filterMetricData = JSON.parse(JSON.stringify(this.metricsConfigAllData));
        ////this.getMetricForCancel(this.metricsConfigData);

        //default sorting on metric name
        //this.metricsConfigAllData = this.metricsConfigAllData.sort((a, b) => {
        //  if (a[this.sortBy] < b[this.sortBy]) {
        //    return -1;
        //  }
        //});
        if (this.sortType == 'asc') {
          this.metricsConfigAllData = this.metricsConfigAllData.sort((a, b) => {
            
            if ((a[(this.sortBy)]).toLowerCase() < (b[this.sortBy]).toLowerCase()) {
              return -1;
            }
          });
          this.tempmetricConfig = this.tempmetricConfig.sort((a, b) => {

            if ((a[(this.sortBy)]).toLowerCase() < (b[this.sortBy]).toLowerCase()) {
              return -1;
            }
          });
          this.filterMetricData = this.filterMetricData.sort((a, b) => {

            if ((a[(this.sortBy)]).toLowerCase() < (b[this.sortBy]).toLowerCase()) {
              return -1;
            }
          });

        }
        else {
          this.metricsConfigAllData = this.metricsConfigAllData.sort((a, b) => {
            if ((a[(this.sortBy)]).toLowerCase() > (b[this.sortBy]).toLowerCase()) {
              return -1;
            }
          });
          this.tempmetricConfig = this.tempmetricConfig.sort((a, b) => {

            if ((a[(this.sortBy)]).toLowerCase() > (b[this.sortBy]).toLowerCase()) {
              return -1;
            }
          });
          this.filterMetricData = this.filterMetricData.sort((a, b) => {

            if ((a[(this.sortBy)]).toLowerCase() > (b[this.sortBy]).toLowerCase()) {
              return -1;
            }
          });
        }
        this.reverse();
        ///*Paging*/        
        this.totalMetrics = this.metricsConfigAllData.length;
        if (this.endIndex == null)
          this.endIndex = this.pageSize;
        //this.metricsConfigData = this.metricsConfigAllData.slice(this.startIndex, this.endIndex);
        let configData = this.filterMetricData.slice(this.startIndex, this.endIndex);
        this.metricsConfigData = JSON.parse(JSON.stringify(this.selectBusinessContext(configData)));
        this.metricConfigDatadup = this.metricsConfigData
        this.setCommentflag(this.metricConfigDatadup);
        this.noOfPages = this.numberOfPages();

        //381838 - 'Select' in businessContext field is getting duplicated in config screen
        //if (this.businessContexts.length == 9) {
        //  this.businessContexts.unshift({ BusinessContextId: null, Name: "--Select--" })
        //}
        //this.getLastConfigUpdatedBy(); //117507
        //this.getMetricForSearch(this.metricsConfigData) 

      },

      error => this.errorMessage = <any>error);

  }

  loadFilterDropdownOptions(metrics: any) {
    this.metricDropDown = metrics[0].deliveryStructReporting.reduce((acc, cur) => {
      if (acc.filter(x => x.Id == cur.MetricId).length == 0) {
        var name = cur.MetricName;
        if (cur.Abbreviation != null)
        {
          name = cur.MetricName + '(' + cur.Abbreviation + ')';
        }
        
        acc.push({ Id: cur.MetricId, Name: name, isActive: true })
      }
      return acc;
    }, []).sort((a, b) => {
      if (a.Name < b.Name)
        return -1;
    });
    //this.deliverFunctionDropDown = metrics.DeliveryFunctions;
    this.deliverFunctionDropDown = metrics[0].deliveryStructReporting.reduce((acc, cur) => {
      if ((cur.BusiWSMappng.length > 0)) {
        cur.BusiWSMappng.forEach((item) => {
          let bpId = item.BusinnesProcessId == 0 ? -1 : item.BusinnesProcessId;
          if (acc.filter(x => x.Id === bpId).length == 0) {
            acc.push({ Id: bpId, Name: item.BusinnesProcessName, isActive: true })
          }
        })

      }
      return acc;
    }, []).sort((a, b) => {
      if (a.Name < b.Name)
        return -1;
    });

    this.reportAgainstDropdown = metrics[0].deliveryStructReporting.reduce((acc, cur) => {
      if (cur.BusiWSMappng.length > 0) {
        cur.BusiWSMappng.forEach((item) => {
          let wsId = item.WorkstreamTypeId == 0 ? -1 : item.WorkstreamTypeId;
          if (acc.filter(x => x.Id === wsId).length == 0) {
            acc.push({ Id: wsId , Name: item.WorkstreamTypeName, isActive: true });

          }
        })
      }
      return acc;

    }, []).sort((a, b) => {
      if (a.Name < b.Name)
        return -1;
    });    

    this.ReportedagainstSuffixAdd(this.reportAgainstDropdown);
    //this.businessContextDropdown = metrics.BusinessContexts;
    this.businessContextDropdown = metrics[0].deliveryStructReporting.filter(x => x.BusinessContextId != null && x.BusinessContextId != 0).reduce((acc, cur) => {
      if (cur.BusinessContextId != null && cur.BusinessContextId != undefined) {
        if (acc.filter(x => x.Id == cur.BusinessContextId).length == 0) {
          acc.push({ Id: cur.BusinessContextId == 0 ? -1 : cur.BusinessContextId, Name: this.getBusinessContextValue(cur.BusinessContextId), isActive: true })
        }
      }
      return acc;
    }, []).sort((a, b) => {
      if (a.Name < b.Name)
        return -1;
    });
    //this.inScopeDropdown = metrics.InScope;
    this.inScopeDropdown = metrics[0].deliveryStructReporting.reduce((acc, cur) => {
      if (cur.IsSelected != null && cur.IsSelected != undefined) {
        if (acc.filter(x => x.Id == (cur.IsSelected == true ? 1 : -1)).length == 0) {
          acc.push({ Id: cur.IsSelected == true ? 1 : -1, Name: cur.IsSelected == true ? 'Yes' : 'No', isActive: true })
        }
      }
      return acc;
    }, []).sort((a, b) => {
      if (a.Name > b.Name)
        return -1;
    });


    //this.applicabilityDropdown = metrics.Applicability;
    this.applicabilityDropdown = metrics[0].deliveryStructReporting.reduce((acc, cur) => {
      if (cur.ApplicabilityId != null && cur.ApplicabilityId != undefined) {
        if (acc.filter(x => x.Id == cur.ApplicabilityId).length == 0) {
          acc.push({ Id: cur.ApplicabilityId == 0 ? -1 : cur.ApplicabilityId, Name: cur.Applicability, isActive: true })
        }
      }
      return acc;
    }, []).sort((a, b) => {
      if (a.Name < b.Name)
        return -1;
    });

  }

  //FUnction to add suffix to reported against in filter 
  ReportedagainstSuffixAdd(reprotedagainst: any)
  {
    reprotedagainst.forEach((item) => {
      if (item.Id == 1 || item.Id == 3 || item.Id == 5 || item.Id == 6 || item.Id == 11) {
        item.Name = item.Name + "(s)";
      }
      if (item.Id == 2) {
        item.Name = "Application(s) / Application Group(s)";
      }
      if (item.Id == 4) {
        item.Name = "Agile Sprint(s) 0";
      }
    })    
  }
  //Push Metric Name for Search
  //getMetricForSearch(metrics: any[]): void {
  //  //386809 - MSE_PROD: Refine by - For newly created custom metric is not appearing when user searches with Delivery function/Reported against:
  //  this.loadrefineBy = true;
  //  this.displayRefineBy();
  //  this.businessContextSearch = [];
  //  this.businessContextSearchAll = [];
  //  this.oldBusinessContext.length = 0;
  //  this.metricsSearch = [];
  //  if (metrics.length != 0) {
  //    for (var i = 0; i < metrics.length; i++) {
  //      if (metrics[i].Metric.Abbreviation != null)
  //        this.metricsSearch.push({ id: metrics[i].MetricId, name: metrics[i].Metric.Name + "-" + metrics[i].Metric.Abbreviation });
  //      else
  //        this.metricsSearch.push({ id: metrics[i].MetricId, name: metrics[i].Metric.Name });
  //      this.oldBusinessContext.push(metrics[i].PenaltyDescription + "~" + metrics[i].BusinessContextId);
  //      if (metrics[i].BusinessContextId != null) {
  //        var bussinssContextName = this.businessContexts.filter(busContext => busContext.BusinessContextId == metrics[i].BusinessContextId);
  //        if (bussinssContextName.length > 0) {
  //          if (this.businessContextSearch.length > 0) {
  //            var data = this.businessContextSearch.filter(businessContextSearchData => businessContextSearchData.name.indexOf(bussinssContextName[0].Name) >= 0);
  //            if (data.length == 0) {
  //              this.businessContextSearch.push({ id: metrics[i].BusinessContextId, name: bussinssContextName[0].Name });
  //            }
  //          }
  //          else {
  //            this.businessContextSearch.push({ id: metrics[i].BusinessContextId, name: bussinssContextName[0].Name });
  //          }

  //          this.businessContextSearchAll.push({ BusinessContextId: metrics[i].BusinessContextId, MetricId: metrics[i].MetricId, MetricName: metrics[i].Metric.Name, BusinessContextName: bussinssContextName[0].Name });
  //        }
  //      }
  //    }
  //  }
  //  else {
  //    this.errorMessage = 'No metrics available for selected contract.';
  //    this.baseURL = global.environment.backButtonUrl;

  //    // Changed BackButtonUrl As per Story 118456 - MSE: A a MSE user, I should be able to use the 'back' navigation from configure metric and submission overview screens to go back to the respective tiles.
  //    this.baseURL = this.baseURL + "Initiate?endToEndUId=" + this.DeliveryId;

  //  }
  //  //Filter by Assending order in filterbysection dropdown lists.
  //  if (this.businessContextSearch.length > 0) {
  //    this.businessContextSearch = this.businessContextSearch.sort((a, b) => {
  //      if (a.name < b.name) return -1;
  //      else if (a.name > b.name) return 1;
  //      else return 0;
  //    });
  //  }
  //  //386809 - MSE_PROD: Refine by - For newly created custom metric is not appearing when user searches with Delivery function/Reported against: Flag reenabling
  //  this.loadrefineBy = false;
  //  this.displayRefineBy();

  //}



  //Get Team Data

  //getTeamsForE2E(e2eId: any): void {
  //  this.metricService.getTeamsForE2E(e2eId).subscribe(
  //    data => {
  //      this.teamList = data;
  //    });
  //}
  //onCustomize(metricId: any): void {

  //}

  changeSwitchs(metricData: any): void {
    let oldBusinessContextId = this.tempmetricConfig.filter(x => x.MetricId == metricData.MetricId).map(x => x.BusinessContextId)[0];
    let oldIsSelected = this.tempmetricConfig.filter(x => x.MetricId == metricData.MetricId).map(x => x.IsSelected)[0];
    let indextoUpdate = this.tempmetricConfig.findIndex(x => x.MetricId == metricData.MetricId);
    this.metricsConfigAllData[indextoUpdate].isModified = (metricData.BusinessContextId != oldBusinessContextId || metricData.IsSelected != oldIsSelected) ? 1 : 0;
    this.metricsConfigAllData[indextoUpdate].IsSelected = metricData.IsSelected;
    let indextoUpdate1 = this.metricConfigDatadup.findIndex(x => x.MetricId == metricData.MetricId);
    this.metricConfigDatadup[indextoUpdate1].IsSelected = metricData.IsSelected;
    this.setCommentflag(this.metricConfigDatadup);
    this.isMetricModified() ? this.isDirty = true : this.isDirty = false;
  }

  changeContext(SelectedContextId: any, index: number, metricData: any): void {
    this.isDirty = true;
    let indextoUpdate = this.metricsConfigAllData.findIndex(x => x.MetricId == metricData.MetricId);
    let indextoUpdate1 = this.metricConfigDatadup.findIndex(x => x.MetricId == metricData.MetricId);
    if (SelectedContextId == null) {
      metricData.BusinessContextId = null;
      metricData.BusinessContextValue = 'Select';

      //Updating in metric object in whole metric json
      this.metricsConfigAllData[indextoUpdate] = metricData;
      this.metricConfigDatadup[indextoUpdate1] = metricData; 
    }
    else {
      metricData.BusinessContextId = SelectedContextId;
      metricData.BusinessContextValue = this.getBusinessContextValue(SelectedContextId);
      //Updating in metric object in whole metric json
      this.metricsConfigAllData[indextoUpdate] = metricData;
      this.metricConfigDatadup[indextoUpdate1] = metricData; 
    }
    let oldBusinessContextId = this.tempmetricConfig.filter(x => x.MetricId == metricData.MetricId).map(x => x.BusinessContextId)[0];
    let oldIsSelected = this.tempmetricConfig.filter(x => x.MetricId == metricData.MetricId).map(x => x.IsSelected)[0];
    let oldPenaltyDescription = this.tempmetricConfig.filter(x => x.MetricId == metricData.MetricId).map(x => x.PenaltyDescription)[0];
    this.metricsConfigAllData[indextoUpdate].isModified = (metricData.BusinessContextId != oldBusinessContextId || metricData.IsSelected != oldIsSelected) ? 1 : 0;

    if (SelectedContextId != oldBusinessContextId) {
      metricData.PenaltyDescription = "";
      this.metricsConfigAllData[index].PenaltyDescription = "";
    }
    else {
      metricData.PenaltyDescription = oldPenaltyDescription;
      this.metricsConfigAllData[index].PenaltyDescription = oldPenaltyDescription;
    }
    //$('#a' + index).attr('aria-expanded', 'false');
    //$('#bc' + index).removeClass('-open');
    this.setCommentflag(this.metricConfigDatadup);
  }

  getBusinessContextValue(businessContextId: number) {
    if (businessContextId != null)
      return this.businessContexts.filter(x => x.Id == businessContextId).map(a => a.Name)[0];
    else
      return null;
  }

  showContextDropdown(index: any) {
    var isopend = $('#a' + index).attr('aria-expanded')
    if (isopend == 'true') {
      $('#a' + index).attr('aria-expanded', 'false');
      $('#bc' + index).removeClass('-open');
    }
    else {
      $('#a' + index).attr('aria-expanded', 'true');
      $('#bc' + index).addClass('-open');
    }
  }

  cancelConfigFromChild(): void {
    this.checkDeliveryData(this.DeliveryId);
  }

  saveConfig(metricData: any[]): void {
    //if (this.key == 13) {
    //  this.alertBox.hide();
    //  this.key = "";
    //}
    //else {
    //this.loadingStatus = true;
    //this.saveStatus = false;
    if (this.accessFlag) {
      return;
    }
    this.errorMessage = '';
    this.isDirty = false;
    //if (this.saveStatus == false) {
    this.metricService.saveMetrics(this.metricsConfigAllData, this.deliveryId, this.deliveryLevelId, this.enterpriseId).subscribe(message => {
      this.getMetricData(this.deliveryId, this.deliveryLevelId);
      var _message = message.toString();
      //this.setMetricsAfterSave(this.metricsConfigAllData);
      //this.objGlobal.setSessionValue("MetricConfig", JSON.stringify(this.metricsConfigAllData));
      //this.alertBox.showConfig("Metric Configuration", _message, "No");
      this.sharedService.openAlertPopup(_message, window.scrollY);

      //if (this.objGlobal.getSessionValue("selectedTeamID") != null && this.objGlobal.getSessionValue("selectedTeamID") != undefined) {
      //  var tid = this.objGlobal.getSessionValue("selectedTeamID");
      //  this.objGlobal.setSessionValue("tempSelectedTeamId", tid);
      //}
    },
      error => this.errorMessage = <any>error);
  }
  //else {

  //}
  //this.getMetricForSearch(this.metricsConfigData);
  //}
  //}

  numberOfPages() {
    if (this.filterMetricData != undefined && this.filterMetricData != null) {
      let pagesCnt = Math.ceil(this.filterMetricData.length / this.pageSize);
      return pagesCnt == 0 ? 1 : pagesCnt;
    }
    else
      return 0;
  }

  pagination(pageNo: number) {
    this.startIndex = (pageNo) * this.pageSize;
    this.endIndex = (pageNo) * this.pageSize + this.pageSize;
    //this.metricsConfigData = this.filterMetricData.slice(this.startIndex, this.endIndex);
    let configData = this.filterMetricData.slice(this.startIndex, this.endIndex);
    this.metricsConfigData = JSON.parse(JSON.stringify(this.selectBusinessContext(configData)));
    this.metricConfigDatadup = this.metricsConfigData
    this.setCommentflag(this.metricConfigDatadup);
    setTimeout(function () {
      window.TooltipFunc();
    }, 0);
  }
  selectBusinessContext(metricData: any): any {
    let bContexts = this.businessContexts;
    metricData.forEach(x => {
      x.businessContexts = bContexts.map(y => {
        if (x.BusinessContextId == y.Id) {
          y.IsSelected = true;
        }
        else {
          y.IsSelected = false;
        }
        return y;
      })
      return x;
    });
    return metricData;
  }

  isMetricModified() {
    if (this.metricsConfigAllData) {
      let modifiedCnt = this.metricsConfigAllData.filter(x => x.isModified == 1).length;
      return modifiedCnt > 0 ? true : false;
    }
  }
  //download applicability
  showApplicabilityPopup() {
    this.showdisclaimerpopup("1");
  }
  showdisclaimerpopup(type: any) {
    this.ApplicabilityPopup = false;
    userNotification.showUserNotificationModalPopup();
    var btnClose = document.querySelector(".notification-button-close");
    if (btnClose) {
      btnClose.addEventListener('click', () => {
        this.exportToExcel(type);
      });
    }
  }
  //User Notification Disclaimer popup
  initializeUserNotificationContent(): void {
    const content = {
      DataSourceUId: "00100000-0010-0000-0000-000000000000",
      TemplateUId: "00200000-0010-0000-0000-000000000000",
      ServiceUrl: 'data/UserNotification.json',
      ActiveLanguage: 'English',
    }
    userNotification.init(content);
  }
  //Export metrics to Excel based on applicability rule  
  exportToExcel(type: any): void {
    var workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    class excelexportdata
    {
      DeliveryId: any;
      DeliveryLevelId: any;
      type: any;
    }
    let ed = new excelexportdata();
    ed.DeliveryId = this.deliveryId;
    ed.DeliveryLevelId = this.deliveryLevelId;
    ed.type = false;
    if (type=="1")
    ed.type = true;

    this.metricService.exportToExcel(ed, this.deliveryId).subscribe(
      data => {
        this.exportDataExcel = data;
        for (var i = 0; i < this.exportDataExcel.length; i++) {
          this.exportData = new Array<any>();
          this.worksheetName = this.exportDataExcel[i].PortFolioName.toString();
          if (this.worksheetName.length > 30)     //Limiting the sheetname to 30 characters
            this.worksheetName = this.worksheetName.substring(0, 30);
          workbook.addWorksheet(this.worksheetName, { views: [{ activeCell: 'A1', showGridLines: true }] }, { pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 } })
          var sheet = workbook.getWorksheet(this.worksheetName);
          sheet.columns = [//removed bold true from header====1.9--UI-- Bug(412315):Fonts are bold in downloaded applicability sheet from configure page, Only header
            { key: 'MetricID', width: 20, style: { color: 'FF00FF00' } },
            { key: 'MetricName', width: 40, style: { color: 'FF00FF00' } },
            { key: 'DeliveryType', width: 40, style: { color: 'FF00FF00' } },//Added Delivery Type as a part of User strory:2402839
            { key: 'WorkstreamType', width: 30, style: { color: 'FF00FF00' } },
            { key: 'DeliveryFunction', width: 30, style: { color: 'FF00FF00' } },
            { key: 'Category', width: 30, style: { color: 'FF00FF00' } },
            { key: 'Definition', width: 120, style: { color: 'FF00FF00' } },
            { key: 'Formula', width: 120, style: { color: 'FF00FF00' } },
            { key: 'Applicability', width: 40, style: { color: 'FF00FF00' } },
            { key: 'ThresholdType', width: 20, style: { color: 'FF00FF00' } },
            { key: 'Threshold1', width: 30, style: { color: 'FF00FF00' } },
            { key: 'Threshold2', width: 30, style: { color: 'FF00FF00' } },
            { key: 'Threshold3', width: 30, style: { color: 'FF00FF00' } },
            { key: 'Unit', width: 12, style: { color: 'FF00FF00' } },
            { key: 'Sourcing', width: 14, style: { color: 'FF00FF00' } },
          ];

          this.exportData.push(this.columns);
          var indx = 0;
          for (var x = 0; x < this.exportDataExcel[i].exportToExcel.length; x++) {
            //setting the threshold columns depending on the threshold type
            if (this.exportDataExcel[i].exportToExcel[x].ThresholdType == 'Trend') {
              this.tempThreshold1 = this.exportDataExcel[i].exportToExcel[x].Threshold1;
              this.tempThreshold2 = this.exportDataExcel[i].exportToExcel[x].Threshold2;
              this.tempThreshold3 = this.exportDataExcel[i].exportToExcel[x].Threshold3;
            }

            else if (this.exportDataExcel[i].exportToExcel[x].ThresholdType == 'Range' && this.exportDataExcel[i].exportToExcel[x].TrendTypeid == null) {
              this.tempThreshold1 = "";
              this.tempThreshold2 = "";
              this.tempThreshold3 = "";
            }
            else if (this.exportDataExcel[i].exportToExcel[x].ThresholdType == 'Range' && this.exportDataExcel[i].exportToExcel[x].TrendTypeid != null) {
              if (this.exportDataExcel[i].exportToExcel[x].TrendTypeid == 1) {
                this.tempThreshold1 = '< ' + this.exportDataExcel[i].exportToExcel[x].Threshold1;
                this.tempThreshold2 = '>= ' + this.exportDataExcel[i].exportToExcel[x].Threshold1 + ',  < ' + this.exportDataExcel[i].exportToExcel[x].Threshold2;
                this.tempThreshold3 = '>= ' + this.exportDataExcel[i].exportToExcel[x].Threshold2;
              }
              else if (this.exportDataExcel[i].exportToExcel[x].TrendTypeid == 2) {
                this.tempThreshold1 = '< ' + this.exportDataExcel[i].exportToExcel[x].Threshold1 + ',   > ' + this.exportDataExcel[i].exportToExcel[x].Threshold4;
                this.tempThreshold2 = '>= ' + this.exportDataExcel[i].exportToExcel[x].Threshold1 + ',  < ' + this.exportDataExcel[i].exportToExcel[x].Threshold2 + ',    > ' + this.exportDataExcel[i].exportToExcel[x].Threshold3 + ',  <=' + this.exportDataExcel[i].exportToExcel[x].Threshold4;
                this.tempThreshold3 = '>= ' + this.exportDataExcel[i].exportToExcel[x].Threshold2 + ',  <= ' + this.exportDataExcel[i].exportToExcel[x].Threshold3;
              }
              else if (this.exportDataExcel[i].exportToExcel[x].TrendTypeid == 3 && this.exportDataExcel[i].exportToExcel[x].MetricId != 2663) {
                this.tempThreshold1 = '> ' + this.exportDataExcel[i].exportToExcel[x].Threshold2;
                this.tempThreshold2 = '> ' + this.exportDataExcel[i].exportToExcel[x].Threshold1 + ',  <= ' + this.exportDataExcel[i].exportToExcel[x].Threshold2;
                this.tempThreshold3 = '<= ' + this.exportDataExcel[i].exportToExcel[x].Threshold1;
              }
              //Story: 1051466 - Bug: 1091603 global thresholds showing wrong threshold range for Number of Major Incidents core metric in Applicability sheet
              else if (this.exportDataExcel[i].exportToExcel[x].TrendTypeid == 3 && this.exportDataExcel[i].exportToExcel[x].MetricId == 2663) {
                this.tempThreshold1 = '>= ' + this.exportDataExcel[i].exportToExcel[x].Threshold2;
                this.tempThreshold2 = "";
                this.tempThreshold3 = '= ' + this.exportDataExcel[i].exportToExcel[x].Threshold1;
              }
            }
            else if (this.exportDataExcel[i].exportToExcel[x].ThresholdType == 'Relative Trend' && this.exportDataExcel[i].exportToExcel[x].TrendTypeid != null) {
              if (this.exportDataExcel[i].exportToExcel[x].TrendTypeid == 1) {
                this.tempThreshold1 = '< ' + this.exportDataExcel[i].exportToExcel[x].Threshold1 + ' %';
                this.tempThreshold2 = '>= ' + this.exportDataExcel[i].exportToExcel[x].Threshold1 + ' %' + ',  < ' + this.exportDataExcel[i].exportToExcel[x].Threshold2 + ' %';
                this.tempThreshold3 = '>= ' + this.exportDataExcel[i].exportToExcel[x].Threshold2 + ' %';
              }
              else if (this.exportDataExcel[i].exportToExcel[x].TrendTypeid == 2) {
                this.tempThreshold1 = '< ' + this.exportDataExcel[i].exportToExcel[x].Threshold1 + ' %' + ',   > ' + this.exportDataExcel[i].exportToExcel[x].Threshold4 + ' %';
                this.tempThreshold2 = '>= ' + this.exportDataExcel[i].exportToExcel[x].Threshold1 + ' %' + ',  < ' + this.exportDataExcel[i].exportToExcel[x].Threshold2 + ' %' + ',    > ' + this.exportDataExcel[i].exportToExcel[x].Threshold3 + ' %' + ',  <=' + this.exportDataExcel[i].exportToExcel[x].Threshold4 + ' %';
                this.tempThreshold3 = '>= ' + this.exportDataExcel[i].exportToExcel[x].Threshold2 + ' %' + ',  <= ' + this.exportDataExcel[i].exportToExcel[x].Threshold3 + ' %';
              }
              else if (this.exportDataExcel[i].exportToExcel[x].TrendTypeid == 3) {
                this.tempThreshold1 = '> ' + this.exportDataExcel[i].exportToExcel[x].Threshold2 + ' %';
                this.tempThreshold2 = '> ' + this.exportDataExcel[i].exportToExcel[x].Threshold1 + ' %' + ',  <= ' + this.exportDataExcel[i].exportToExcel[x].Threshold2 + ' %';
                this.tempThreshold3 = '<= ' + this.exportDataExcel[i].exportToExcel[x].Threshold1 + ' %';
              }
            }
            else if (this.exportDataExcel[i].exportToExcel[x].ThresholdType == 'Relative Trend' && this.exportDataExcel[i].exportToExcel[x].TrendTypeid == null) {
              this.tempThreshold1 = "";
              this.tempThreshold2 = "";
              this.tempThreshold3 = "";
            }

            var singleMeasure = {
              MetricID: this.exportDataExcel[i].exportToExcel[x].MetricId,
              MetricName: this.exportDataExcel[i].exportToExcel[x].MetricName,
              DeliveryType: this.exportDataExcel[i].exportToExcel[x].DeliveryType,//Added Delivery Type as a part of User strory:2402839
              WorkstreamType: this.exportDataExcel[i].exportToExcel[x].Workstreamtype,
              DeliveryFunction: this.exportDataExcel[i].exportToExcel[x].DeliveryFunction,
              Category: this.exportDataExcel[i].exportToExcel[x].Category,
              Definition: this.exportDataExcel[i].exportToExcel[x].Definition,
              Formula: this.exportDataExcel[i].exportToExcel[x].MetricFormula,
              Applicability: this.exportDataExcel[i].exportToExcel[x].Applicability,
              ThresholdType: this.exportDataExcel[i].exportToExcel[x].ThresholdType,
              Threshold1: this.tempThreshold1,
              Threshold2: this.tempThreshold2,
              Threshold3: this.tempThreshold3,
              Unit: this.exportDataExcel[i].exportToExcel[x].Unit,
              Sourcing: this.exportDataExcel[i].exportToExcel[x].Sourcing
            }
            this.exportData.push(singleMeasure);

            if (this.exportDataExcel[i].exportToExcel[x].MetricId == '2412') {
              indx = this.exportData.indexOf(singleMeasure) + 1;
            }
          }
          sheet.addRows(this.exportData);

          sheet.getRow(1).font = {//reduced the size and set bold as false from header====1.9--UI-- Bug(412315):Fonts are bold in downloaded applicability sheet from configure page, Only header

            size: 11,
            bold: false
          };

          ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1'].map(key => {//set header bold====1.9--UI-- Bug(412315):Fonts are bold in downloaded applicability sheet from configure page, Only header
            sheet.getCell(key).font = {
              size: 14,
              alignment: { horizontal: 'center' },
              bold: true
            };
          });//setting text color of header

          ['G' + indx.toString()].map(key => {
            sheet.getCell(key).dataValidation = {
              type: 'decimal', //this is just to display prompts , it is not effective as no validation formula as been written here.
              showInputMessage: true,
              promptTitle: 'Note:',
              prompt: 'Mandatory for Capacity Services and Recommended for other Delivery Types (AD,AM, Agile, Testing)'
            };
          });

          sheet.getCell('G' + indx.toString()).border = {
            color: { argb: '80FF6666' },
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };

          sheet.getColumn('Threshold1').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '80f8cbad' }
          };
          sheet.getColumn('Threshold2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '80ffe699' }
          };
          sheet.getColumn('Threshold3').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '80c6e0b4' }
          };
        }
        workbook.xlsx.writeBuffer().then(data => {

          var blob = new Blob([data], { type: this.blobType });

          FileSaver.saveAs(blob, this.excelFileName, true);
        });
      }, error => this.errorMessage = <any>error);
  }
  //to show filter popup
  displayRefineBy(): void {
    const initialState = {
      configureMetrics: this.metricsConfigAllData,
      metricDropDown: this.metricDropDown,
      deliverFunctionDropDown: this.deliverFunctionDropDown,
      reportAgainstDropdown: this.reportAgainstDropdown,
      businessContextDropdown: this.businessContextDropdown,
      inScopeDropdown: this.inScopeDropdown,
      applicabilityDropdown: this.applicabilityDropdown,
      selectedMetricFilterId: this.selectedMetricFilterId,
      selectedDFFilterId: this.selectedDFFilterId,
      selectedReportAgainstFilterId: this.selectedReportAgainstFilterId,
      selectedbusinessContextFilterId: this.selectedbusinessContextFilterId,
      selectedIsScopeFilterId: this.selectedIsScopeFilterId,
      selectedApplicabilityId: this.selectedApplicabilityId
    };
    this.modalRef = this.modalService.show(MetricFilterComponent, { initialState, backdrop: "static", keyboard: false });
    this.modalRef.content.applyFilters.subscribe(result => {
      this.selectedMetricFilterId = result.selectedMetricFilterId,
        this.selectedDFFilterId = result.selectedDFFilterId,
        this.selectedReportAgainstFilterId = result.selectedReportAgainstFilterId,
        this.selectedbusinessContextFilterId = result.selectedbusinessContextFilterId,
        this.selectedIsScopeFilterId = result.selectedIsScopeFilterId,
        this.selectedApplicabilityId = result.selectedApplicabilityId
       this.tabledefaultheight = false;
       this.applyFilter();
    })
  }

  applyFilter() {
    this.filterMetricData = JSON.parse(JSON.stringify(this.metricsConfigAllData)); //to avoid two way binding
    let filterId = 0;
    if (this.selectedMetricFilterId != null && this.selectedMetricFilterId != 0) {
      filterId = this.selectedMetricFilterId == -1 ? 0 : this.selectedMetricFilterId;
      this.filterMetricData = this.filterMetricData.filter(x => x.MetricId == filterId).map(x => x);
    }
    if (this.selectedDFFilterId != null && this.selectedDFFilterId != 0) {
      filterId = this.selectedDFFilterId == -1 ? 0 : this.selectedDFFilterId;
      this.filterMetricData = this.filterMetricData.filter((x) => {
        let isExist = false;
        x.BusiWSMappng.forEach((item) => {
          if (item.BusinnesProcessId == filterId) {
            isExist = true;
          }
        })
        return isExist;
      }).map(x => x);
    }
    if (this.selectedReportAgainstFilterId != null && this.selectedReportAgainstFilterId != 0) {
      filterId = this.selectedReportAgainstFilterId == -1 ? 0 : this.selectedReportAgainstFilterId;
      this.filterMetricData = this.filterMetricData.filter((x) => {
        let isExist = false;
        x.BusiWSMappng.forEach((item) => {
          if (item.WorkstreamTypeId == filterId) {
            isExist = true;
          }
        })
        return isExist;

      }).map(x => x);
    }
    if (this.selectedbusinessContextFilterId != null && this.selectedbusinessContextFilterId != 0) {
      filterId = this.selectedbusinessContextFilterId == -1 ? 0 : this.selectedbusinessContextFilterId;
      this.filterMetricData = this.filterMetricData.filter(x => x.BusinessContextId == filterId).map(x => x);
    }
    if (this.selectedIsScopeFilterId != null && this.selectedIsScopeFilterId != 0) {
      filterId = this.selectedIsScopeFilterId == -1 ? 0 : this.selectedIsScopeFilterId;
      this.filterMetricData = this.filterMetricData.filter(x => x.IsSelected == filterId).map(x => x);
    }
    if (this.selectedApplicabilityId != null && this.selectedApplicabilityId != 0) {
      filterId = this.selectedApplicabilityId == -1 ? 0 : this.selectedApplicabilityId;
      this.filterMetricData = this.filterMetricData.filter(x => x.ApplicabilityId == filterId).map(x => x);
    }
    //this.metricsConfigData = JSON.parse(JSON.stringify(this.filterMetricData));
    this.sortMetrics();

    /*pagination */
    this.totalMetrics = this.filterMetricData.length;
    this.noOfPages = this.numberOfPages();
    this.curPage = 0;
    this.pagination(0);
  }

  sortMetrics() {
    this.reverse();
    if (this.sortType == 'asc') {
      this.filterMetricData = this.filterMetricData.sort((a, b) => {
        if ((a[this.sortBy]).toLowerCase() < (b[this.sortBy]).toLowerCase()) {
          return -1;
        }
      });

    }
    else {
      this.filterMetricData = this.filterMetricData.sort((a, b) => {
        if ((a[this.sortBy]).toLowerCase() > (b[this.sortBy]).toLowerCase()) {
          return -1;
        }
      });
    }    
  }

  changeSorting(sortBy) {
    if (sortBy != this.sortBy) {
      this.sortBy = sortBy;
      this.sortType = 'asc';
    }
    else {
      this.sortType = this.sortType == 'asc' ? 'desc' : 'asc';
    }
    this.reverse();
    this.applyFilter();
  }

  onCustomize(value: number) {

    this.selectedCreate = false;
    if (value == -1) {
      this.selectedCreate = true;
      this.selectedAction = "Create";
      this.popup = "Add Custom Metric";

      const initialState = {
        selectedCreate: this.selectedCreate,
        selectedAction: this.selectedAction,
        metricId: this.Metricid,
        popup: this.popup
      };

      this.modalRef = this.modalService.show(AdminMetricComponent, { initialState, backdrop: "static", keyboard: false });
      this.modalRef.content._onSaveClick.subscribe(result => {
        if (result != null) {
          this.getMetricData(this.deliveryId, this.deliveryLevelId)
        }
      })
    }
    else {
      this.selectedCreate = false;
      this.selectedAction = "Read";
      this.Metricid = value;
      this.popup = "Metric Details";

      this.metricService.getSelectedMetricDetails(this.sessionStorage.endToEndUId, this.Metricid, this.deliveryLevelId).subscribe(
        source => {
          let assoMeasures = source.AssociatedMeasures;
          if (source.AssociatedMeasures.length > 0) {
            let measure_count = 1;
            assoMeasures.forEach((measure) => {
              this.metricService.getAssociateMetricMeasure(measure.Id).subscribe((metrics) => {
                source.AssociatedMeasures.filter(x => x.Id == measure.Id).map((y) => y.AssociatedMetric = metrics);
                if (measure_count == source.AssociatedMeasures.length) {
                  this.openMetriceDetailsPopup(source); //opens popup when receives the all api calls response
                }
                measure_count++;
              })
            })
          }
          else {
            this.openMetriceDetailsPopup(source);
          }
        },
        error => { console.log(error); });
    }
  }
  openMetriceDetailsPopup(metricData: any) {

    metricData.selectedWSTypes = metricData.ReportedAgainst.reduce((acc, cur) => {
      acc = acc == "" ? cur.Name : acc + ", " + cur.Name;
      return acc;
    }, "");
    metricData.selectedDeliveryFunctions = metricData.Deliveryfunctions.reduce((acc, cur) => {
      acc = acc == "" ? cur.Name : acc + ", " + cur.Name;
      return acc;
    }, "");
    console.log(metricData);
    const initialState = {
      selectedCreate: this.selectedCreate,
      selectedAction: this.selectedAction,
      metricId: this.Metricid,
      popup: this.popup,
      metricData: metricData
    };
    this.modalRef = this.modalService.show(MetricViewComponent, { initialState, backdrop: "static", keyboard: false });
    this.modalRef.content.setAfterSave.subscribe(result => {
      if (result != null) {
        // this.getMetricData(this.deliveryId, this.deliveryLevelId)
      }
    })
  }

  resetChanges() {
    // confirmation box we need to show
    this.getMetricData(this.deliveryId, this.deliveryLevelId);
  }
  reverse() {
    if (this.sortBy == "MetricName" && this.sortType =="asc") {
      this.reverse1 = true;
      this.reverse2 = false;
      this.reverse3 = false;
    }
    else if (this.sortBy == "ApplicabilitySource" && this.sortType == "asc") {
      this.reverse2 = true;
      this.reverse1 = false;
      this.reverse3 = false;
    }
    else if (this.sortBy == "Applicability" && this.sortType == "asc") {
      this.reverse3 = true;
      this.reverse2 = false;
      this.reverse1 = false;
    }
    else  {
      this.reverse3 = false;
      this.reverse2 = false;
      this.reverse1 = false;
    }
  }
  getApplicability(ApplicabilitySource): void {
    this.ApplicabilitySourceData = "";
    // LevelId Parameter added to display team level applicabilty on mousehover(StoryId-117252 )
    this.metricService.getApplicabilitySource(this.deliveryId, ApplicabilitySource, this.deliveryLevelId).subscribe(
      metrics => {
        this.ApplicabilitySourceData = metrics.toString();
      });
  }
  DisplayMetricsuserUserGuide() {
    this.router.navigate(['/mywizard/metricsuserguide']);
  }
  settableheight(asd: any) {
    //debugger;
    this.tablerecords = asd;
    if (asd == 1 || asd == 2) {
      this.tabledefaultheight = true;
    }    
  }
  Resettableheight(asd1: any) {
   // debugger;
    if (!((this.tablerecords == 1 || this.tablerecords == 2) && this.tabledefaultheight== true))
    this.tabledefaultheight = false;
  }
  setCommentflag(metricdata: any) {
    this.commentflag = false;
    metricdata.forEach((item) => {
      if ((item.BusinessContextId == 2 || item.BusinessContextId == 3) && item.IsSelected== true && this.commentflag == false) {
        this.commentflag = true;
      }
      
    })
  }
  onchangecomment(SelectedMetric: any,value: any): void {
    let indextoUpdate = this.metricsConfigAllData.findIndex(x => x.MetricId == SelectedMetric.MetricId);
    this.metricsConfigAllData[indextoUpdate].PenaltyDescription = value;
    
  }
  
}
