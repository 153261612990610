<div class="app-wrap -flex">
  <!-- body -->
  <div class="c-user-guide-stories -collapsed">
    <div class="c-myWizard-content">
      <div class="c-myWizard-container">
        <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': content1}">
          <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/metric-user-guide-1.jpg" alt="UserGuide" role="presentation" /></div>
          <div class="c-user-guide-stories-container -metric-userguide1">
            <div class="c-user-guide-stories-data">
              <div class="c-user-guide-stories-data__header">
                <a href="javascript:;" role="button"  data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom"  (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
              </div>
              <div class="c-user-guide-stories-data__body">
                <div class="c-user-guide-stories__text">To download the applicability sheet at global and portfolio levels</div>
              </div>
              <div class="c-user-guide-stories-data__footer">
                <div class="c-user-guide-stories__progress">
                  <div class="c-user-guide-stories__progress-bar">
                    <span style="--value:14%"></span>
                  </div>
                  <div class="c-user-guide-stories__progress-data">
                    <span>1</span>
                    <span>/ 7</span>
                  </div>
                </div>
                <div class="c-user-guide-stories__actions">
                  <a href="javascript:;" role="button" class="-prev" title="Previous" [ngClass]="{'-disabled': prevButton} ">PREV</a>
                  <a href="javascript:;" role="button" class="-next" title="Next" [ngClass]="{'-disabled': nextButton}" (click)="DisplayNextContent(1)">NEXT</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': content2}">
          <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/metric-user-guide-2.jpg" alt="UserGuide" role="presentation" /></div>
          <div class="c-user-guide-stories-container -metric-userguide2">
            <div class="c-user-guide-stories-data">
              <div class="c-user-guide-stories-data__header">
                <a href="javascript:;" role="button"  data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom"  (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
              </div>
              <div class="c-user-guide-stories-data__body">
                <div class="c-user-guide-stories__text">Manage metrics display by using keywords or filters</div>
              </div>
              <div class="c-user-guide-stories-data__footer">
                <div class="c-user-guide-stories__progress">
                  <div class="c-user-guide-stories__progress-bar">
                    <span style="--value:28%"></span>
                  </div>
                  <div class="c-user-guide-stories__progress-data">
                    <span>2</span>
                    <span>/ 7</span>
                  </div>
                </div>
                <div class="c-user-guide-stories__actions">
                  <a href="javascript:;" role="button" class="-prev" title="Previous" [ngClass]="{'-disabled': prevButton}" (click)="DisplayPrevContent(2)">PREV</a>
                  <a href="javascript:;" role="button" class="-next" title="Next" [ngClass]="{'-disabled': nextButton}" (click)="DisplayNextContent(2)">NEXT</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': content3}">
          <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/metric-user-guide-3.jpg" alt="UserGuide" role="presentation" /></div>
          <div class="c-user-guide-stories-container -metric-userguide3">
            <div class="c-user-guide-stories-data">
              <div class="c-user-guide-stories-data__header">
                <a href="javascript:;" role="button"  data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom"  (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
              </div>
              <div class="c-user-guide-stories-data__body">
                <div class="c-user-guide-stories__text">Add Custom Metric as needed</div>
              </div>
              <div class="c-user-guide-stories-data__footer">
                <div class="c-user-guide-stories__progress">
                  <div class="c-user-guide-stories__progress-bar">
                    <span style="--value:42%"></span>
                  </div>
                  <div class="c-user-guide-stories__progress-data">
                    <span>3</span>
                    <span>/ 7</span>
                  </div>
                </div>
                <div class="c-user-guide-stories__actions">
                  <a href="javascript:;" role="button" class="-prev" title="Previous" [ngClass]="{'-disabled': prevButton}" (click)="DisplayPrevContent(3)">PREV</a>
                  <a href="javascript:;" role="button" class="-next" title="Next" [ngClass]="{'-disabled': nextButton}" (click)="DisplayNextContent(3)">NEXT</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': content4}">
          <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/metric-user-guide-4.jpg" alt="UserGuide" role="presentation" /></div>
          <div class="c-user-guide-stories-container -metric-userguide4">
            <div class="c-user-guide-stories-data">
              <div class="c-user-guide-stories-data__header">
                <a href="javascript:;" role="button"  data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom"  (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
              </div>
              <div class="c-user-guide-stories-data__body">
                <div class="c-user-guide-stories__text">Sort the column by clicking the column headers</div>
              </div>
              <div class="c-user-guide-stories-data__footer">
                <div class="c-user-guide-stories__progress">
                  <div class="c-user-guide-stories__progress-bar">
                    <span style="--value:56%"></span>
                  </div>
                  <div class="c-user-guide-stories__progress-data">
                    <span>4</span>
                    <span>/ 7</span>
                  </div>
                </div>
                <div class="c-user-guide-stories__actions">
                  <a href="javascript:;" role="button" class="-prev" title="Previous" [ngClass]="{'-disabled': prevButton}" (click)="DisplayPrevContent(4)">PREV</a>
                  <a href="javascript:;" role="button" class="-next" title="Next" [ngClass]="{'-disabled': nextButton}" (click)="DisplayNextContent(4)">NEXT</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': content5}">
          <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/metric-user-guide-5.jpg" alt="UserGuide" role="presentation" /></div>
          <div class="c-user-guide-stories-container -metric-userguide5">
            <div class="c-user-guide-stories-data">
              <div class="c-user-guide-stories-data__header">
                <a href="javascript:;" role="button"  data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom"  (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
              </div>
              <div class="c-user-guide-stories-data__body">
                <div class="c-user-guide-stories__text">Click the link to learn more about the Metric</div>
              </div>
              <div class="c-user-guide-stories-data__footer">
                <div class="c-user-guide-stories__progress">
                  <div class="c-user-guide-stories__progress-bar">
                    <span style="--value:70%"></span>
                  </div>
                  <div class="c-user-guide-stories__progress-data">
                    <span>5</span>
                    <span>/ 7</span>
                  </div>
                </div>
                <div class="c-user-guide-stories__actions">
                  <a href="javascript:;" role="button" class="-prev" title="Previous" [ngClass]="{'-disabled': prevButton}" (click)="DisplayPrevContent(5)">PREV</a>
                  <a href="javascript:;" role="button" class="-next" title="Next" [ngClass]="{'-disabled': nextButton}" (click)="DisplayNextContent(5)">NEXT</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': content6}">
          <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/metric-user-guide-6.jpg" alt="UserGuide" role="presentation" /></div>
          <div class="c-user-guide-stories-container -metric-userguide6">
            <div class="c-user-guide-stories-data">
              <div class="c-user-guide-stories-data__header">
                <a href="javascript:;" role="button"  data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom"  (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
              </div>
              <div class="c-user-guide-stories-data__body">
                <div class="c-user-guide-stories__text">Select the appropriate Business Context for the Metrics</div>
              </div>
              <div class="c-user-guide-stories-data__footer">
                <div class="c-user-guide-stories__progress">
                  <div class="c-user-guide-stories__progress-bar">
                    <span style="--value:84%"></span>
                  </div>
                  <div class="c-user-guide-stories__progress-data">
                    <span>6</span>
                    <span>/ 7</span>
                  </div>
                </div>
                <div class="c-user-guide-stories__actions">
                  <a href="javascript:;" role="button" class="-prev" title="Previous" [ngClass]="{'-disabled': prevButton}" (click)="DisplayPrevContent(6)">PREV</a>
                  <a href="javascript:;" role="button" class="-next" title="Next" [ngClass]="{'-disabled': nextButton}" (click)="DisplayNextContent(6)">NEXT</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-user-guide-stories-content" [ngClass]="{'-disabled': content7}">
          <div class="c-user-guide-stories-content__image"><img src="../assets/toolkit/images/user-guide-stories/metric-user-guide-7.jpg" alt="UserGuide" role="presentation" /></div>
          <div class="c-user-guide-stories-container -metric-userguide7">
            <div class="c-user-guide-stories-data">
              <div class="c-user-guide-stories-data__header">
                <a href="javascript:;" role="button"  data-myw-title="Close User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom"  (click)="OnClose()" class="icon-cross c-user-guide-stories-close" aria-label="Close"></a>
              </div>
              <div class="c-user-guide-stories-data__body">
                <div class="c-user-guide-stories__text">Mark Metrics as In Scope or Out of Scope. Mandatory Metrics are set as In Scope by default and it cannot be set Out of Scope. Both optional Metrics and the Recommended metrics are set as out of Scope by default.</div>
              </div>
              <div class="c-user-guide-stories-data__footer">
                <div class="c-user-guide-stories__progress">
                  <div class="c-user-guide-stories__progress-bar">
                    <span style="--value:100%"></span>
                  </div>
                  <div class="c-user-guide-stories__progress-data">
                    <span>7</span>
                    <span>/ 7</span>
                  </div>
                </div>
                <div class="c-user-guide-stories__actions">
                  <a href="javascript:;" role="button" class="-prev" title="Previous" [ngClass]="{'-disabled': prevButton}" (click)="DisplayPrevContent(7)">PREV</a>
                  <a href="javascript:;" role="button" class="-next" title="Next" [ngClass]="{'-disabled': nextButton}">NEXT</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>    <!-- /body -->
</div>
