<!--<div class="scope-selector" [ngClass]="{'scope-selector-disabled' : !isScopeSelectorDropDownVisible}" *ngIf="isScopeSelectorVisible"
     (ScopeSelecorVisible)="ScopeSelecorVisible($event)" (RefreshScopeSelector)="RefreshScopeSelector($event)"
     click-elsewhere (clickOutside)="scopeSelectorPopupActive=false">
  <nav class="scope-selector-toggle" [attr.aria-label]="title">
    <div on-escape="closeScope()">
      <div>
        <button class="btnHeader" [attr.title]="clientName + '(Open a popup)'" (keypress)="LoadScopeSelector(false,$event)" (click)="LoadScopeSelector()" id="Clientfocus" [attr.aria-label]="clientName + '(Open a popup)'">
          <span>{{clientName?.length > 10 ? clientName?.substring(0, 10) + '...' : clientName}}</span>
        </button>
        <button class="btnHeader" [attr.title]="endToEndName + '(Open a popup)'" id="Endtoendfocus" (keypress)="LoadScopeSelector(false,$event)" (click)="LoadScopeSelector()">
          <span [attr.aria-label]="endToEndName + '(Open a popup)'">
            <span class="scopeSelector-name"> {{endToEndName?.length > 10 ? endToEndName?.substring(0, 10) + '...' : endToEndName}}</span>
            <span *ngIf="isScopeSelectorDropDownVisible && ((cmsName == '' || cmsName == null || cmsName == undefined) && (workstreamName == '' || workstreamName == null || workstreamName == undefined))" (click)="LoadScopeSelector()" class="glyphicon glyphicon-triangle-bottom custom_glyphicon scopeSelector-arrow"></span>
          </span>
        </button>
        <button class="btnHeader" [attr.title]="cmsName + '(Open a popup)'" id="Cmsfocus" (keypress)="LoadScopeSelector(false,$event)" *ngIf="cmsName != ''" (click)="LoadScopeSelector()">

          <span [attr.aria-label]="cmsName + '(Open a popup)'">
            <span class="scopeSelector-name">{{cmsName.length > 10 ? cmsName.substring(0, 10) + '...' : cmsName}}</span>
            <span *ngIf="isScopeSelectorDropDownVisible && ((workstreamName == '' || workstreamName == null || workstreamName == undefined))" (click)="LoadScopeSelector()" class="glyphicon glyphicon-triangle-bottom custom_glyphicon scopeSelector-arrow"></span>
          </span>
        </button>

        <button class="btnHeader" [attr.title]="workstreamName + '(Open a popup)'" id="Wsfocus" (keypress)="LoadScopeSelector(false,$event)" *ngIf="(workstreamName != '' && workstreamName != undefined && workstreamName != null)" (click)="LoadScopeSelector()">
          <span [attr.aria-label]="workstreamName + '(Open a popup)'">
            <span class="scopeSelector-name">{{workstreamName.length > 10 ? workstreamName.substring(0, 10) + '...' : workstreamName}}</span>
            <span *ngIf="isScopeSelectorDropDownVisible" (click)="LoadScopeSelector()" (keypress)="LoadScopeSelector(false,$event)" tabindex="0" class="glyphicon glyphicon-triangle-bottom custom_glyphicon scopeSelector-arrow"></span>
          </span>
        </button>
        <button class="btnHeader" [attr.title]="tmsName ? tmsName : 'All' + '(Open a dropdown)'" (keypress)="isDisableTeamScopeSelector || OpenTeamScopeSelector($event)" data-toggle="dropdown" (click)="isDisableTeamScopeSelector || OpenTeamScopeSelector()" aria-haspopup="true" aria-expanded="false" [disabled]="isDisableTeamScopeSelector">
          <span [ngClass]="{'team-disabled':isDisableTeamScopeSelector}" class="multiselect dropdown-toggle btnHeader team-name" [attr.aria-label]="tmsName ? tmsName : 'All' + '(Open a dropdown)'">
            {{(tmsName == '' || tmsName == undefined) ? 'All' : (tmsName.length > 4 ? tmsName.substring(0, 4) + '..' : tmsName)}}
          </span>
          <img src="assets/images/select_team_icon.png" alt="Team" style="height:22px" />
        </button>

        <ul class="multiselect-container-team panel-body dropdown-menu dropdown-menu-team cms_team_select team-dropdown">
          <li (keypress)="TeamChange(null,$event)" (click)="TeamChange(null)" [ngClass]="{active : teamScopeSelector.teamId == 0 }">
            <a href="javascript:void(0);">
              <label class="checkbox">
                <input type="checkbox">Cross Team (All)
              </label>
            </a>
          </li>
          <ng-container *ngIf="endToEndCMSTeams && endToEndCMSTeams.TMS.length>0">
            <li *ngFor="let teams of endToEndCMSTeams.TMS;" [ngClass]="{active : teamScopeSelector.teamId == teams.Id }" (click)="TeamChange(teams)" (keypress)="TeamChange(teams,$event)">
              <a href="javascript:void(0);">
                <label class="checkbox">
                  <input type="checkbox">{{teams.Name}}
                </label>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </nav>
  <div on-escape="closeScope()" class="scope-selector-new" role="dialog" style="display:block" *ngIf="scopeSelectorPopupActive">
    <div class="ss-filter-wrap">
      <div class="ss-filter-text">
        <input placeholder="Please enter minimum three characters to search" id="txtscopeselectorsearch" style="max-width:97%" type="text"
               maxlength="50" (keydown)="SearchKeyUp()" (keypress)="SearchKeyUp()" (keyup)="SearchKeyUp()"
               [(ngModel)]="SearchText"
               (ngModelChange)="changed($event)" class="form-control" autocomplete="off" />
        <span class="glyphicon glyphicon-info-sign" style="font-size:14px ; color:black; text-decoration-line: none;" tabindex="0"
              title="Please enter any three characters of Contract ID/Opp.ID/Client Name/Initiative/Opportunity Name/Preferred Display Name. If Preferred display name is not provided, a contract name can be searched as well."></span>
      </div>
      <select class="form-control" [(ngModel)]="clientId" (change)="ClientChange()" id="SelectedClientId" *ngIf="(!scopeSelector.SearchText)">
        <option *ngFor="let item of scopeSelector.Clients;" [ngValue]="item.Id">{{item.Name}}</option>
      </select>

    </div>
    <div class="loader-scope-selector" *ngIf="ShowLoader"></div>
    <div class="ss-no-result" *ngIf="IsZeroSearchResult" aria-live="polite" aria-atomic="true" role="status">No matching results found.</div>
    <div class="ss-menu-wrap" *ngIf="(!ShowLoader && (SearchText != null && SearchText.length >= 3))">
      <ul>
        <li *ngFor="let filteredDetails of filteredScopeDetails;" (keypress)="GetHierarchy(filteredDetails,$event)"
            (click)="GetHierarchy(filteredDetails)" tabindex="0">
          <span class="ss-client" *ngIf="filteredDetails.EntityType == 'Client'">
            <span>
              {{
filteredDetails.Name
              }}
            </span>
          </span>
          <span *ngIf="filteredDetails.EntityType == 'O'" class="ss-opportunity">
            <span>
              {{filteredDetails.EndToEndRefCode}} : {{filteredDetails.Name}}
            </span>
          </span>

          <span *ngIf="filteredDetails.EntityType == 'C'" class="ss-contract">
            <span>
              {{filteredDetails.EndToEndRefCode}} : {{filteredDetails.Name}}
            </span>
          </span>
          <span *ngIf="filteredDetails.EntityType == 'I'" class="ss-initiative">
            <span> {{filteredDetails.Name}}</span>
          </span>

        </li>
      </ul>

    </div>
    <div class="ss-menu-wrap" id="scopeSelectorHierarchy" *ngIf="(!ShowLoader && (SearchText == null || SearchText.length < 3))">
      <ul>
        <li *ngFor="let cioGroup of contractOpportunityInitiativeGroups;" [ngClass]="{open : !cioGroup.IsChildCollapsed}" tabindex="0" (keypress)="SelectEndToEnd($event, cioGroup, null,'keypress');$event.stopPropagation();"
            (click)="SelectEndToEnd($event, cioGroup, null);$event.stopPropagation();">
          <span *ngIf="cioGroup.EndToEndType == 'O'" class="ss-opportunity" [ngClass]="{selectedmenu : cioGroup.IsSelected, 'ss-sub' : ((cioGroup.CMSLevelOne|activeList).length>0 || cioGroup.StandaloneWorkstreams.length>0)}">
            <span>
              {{cioGroup.EndToEndRefCode}} : {{cioGroup.EndToEndName}}
            </span>
          </span>

          <span *ngIf="cioGroup.EndToEndType == 'C'" class="ss-contract" [ngClass]="{selectedmenu : cioGroup.IsSelected, 'ss-sub' : ((cioGroup.CMSLevelOne|activeList).length>0 || cioGroup.StandaloneWorkstreams.length>0)}">
            <span>
              {{cioGroup.EndToEndRefCode}} : {{cioGroup.EndToEndName}}
            </span>
          </span>
          <span *ngIf="cioGroup.EndToEndType == 'I'" class="ss-initiative" [ngClass]="{selectedmenu : cioGroup.IsSelected, 'ss-sub' : ((cioGroup.CMSLevelOne|activeList).length>0 || cioGroup.StandaloneWorkstreams.length>0)}">
            <span>{{cioGroup.EndToEndName}}</span>
          </span>

          <div *ngIf="!cioGroup.IsGroup">
            <ul>
              <li *ngFor="let ws of cioGroup.StandaloneWorkstreams;" (click)="SelectWorkstream(ws, null, cioGroup);$event.stopPropagation();" tabindex="0" (keypress)="SelectWorkstream(ws, null, cioGroup,null,null,null,null,null,$event);$event.stopPropagation();">
                <span [ngClass]="{selectedmenu : ws.IsSelected}"><span [ngClass]="{'ss-ws-ad':ws.DeliveryTypeId==1,'ss-ws-agile':ws.DeliveryTypeId==2,'ss-ws-am':ws.DeliveryTypeId==3,'ss-ws-infra':ws.DeliveryTypeId==4, 'ss-ws-ops':ws.DeliveryTypeId==6, 'ss-ws-sec':ws.DeliveryTypeId==7, 'workstream-readonly' : IsWorkStreamSelectable}"> {{ ws.WorkStreamName }}</span></span>
              </li>
              <li *ngFor="let cms1 of (cioGroup.CMSLevelOne | activeList);" (click)="SelectCMS($event,null, cioGroup, cms1);$event.stopPropagation();" [ngClass]="{open : !cms1.IsChildCollapsed,'ss-exp-col' : (((cms1.CMSLevelTwo | activeList)).length > 0 || cms1.Workstreams.length>0) }" tabindex="0" (keypress)="SelectCMS($event,null, cioGroup, cms1,null,null,null,null,'keypress');$event.stopPropagation();">
                <span [ngClass]="{selectedmenu : cms1.IsSelected}"><span [ngClass]="{'default-cursor' : !IsCMSSelectable}"> {{ cms1.Name }}</span></span>
                <div>
                  <ul>
                    <li *ngFor="let ws of cms1.Workstreams;" (click)="SelectWorkstream(ws, null, cioGroup, cms1);$event.stopPropagation();" tabindex="0" (keypress)="SelectWorkstream(ws, null, cioGroup, cms1,null,null,null,null,$event);$event.stopPropagation();">
                      <span [ngClass]="{selectedmenu : ws.IsSelected}"><span [ngClass]="{'ss-ws-ad':ws.DeliveryTypeId==1,'ss-ws-agile':ws.DeliveryTypeId==2,'ss-ws-am':ws.DeliveryTypeId==3,'ss-ws-infra':ws.DeliveryTypeId==4, 'ss-ws-ops':ws.DeliveryTypeId==6, 'ss-ws-sec':ws.DeliveryTypeId==7, 'workstream-readonly' : IsWorkStreamSelectable}"> {{ ws.WorkStreamName }}</span></span>
                    </li>
                    <li *ngFor="let cms2 of (cms1.CMSLevelTwo | activeList);" (click)="SelectCMS($event,null, cioGroup, cms1,cms2);$event.stopPropagation();" [ngClass]="{open : !cms2.IsChildCollapsed,'ss-exp-col' : (((cms2.CMSLevelThree | activeList)).length > 0 || cms2.Workstreams.length>0)}" tabindex="0" (keypress)="SelectCMS($event,null, cioGroup, cms1,cms2,null,null,null,'keypress');$event.stopPropagation();">
                      <span [ngClass]="{selectedmenu : cms2.IsSelected}"><span [ngClass]="{'default-cursor' : !IsCMSSelectable}"> {{ cms2.Name }}</span></span>
                      <div>
                        <ul>
                          <li *ngFor="let ws of cms2.Workstreams;" (click)="SelectWorkstream(ws, null, cioGroup, cms1,cms2);$event.stopPropagation();" tabindex="0" (keypress)="SelectWorkstream(ws, null, cioGroup, cms1,cms2,null,null,null,$event);$event.stopPropagation();">
                            <span [ngClass]="{selectedmenu : ws.IsSelected}"><span [ngClass]="{'ss-ws-ad':ws.DeliveryTypeId==1,'ss-ws-agile':ws.DeliveryTypeId==2,'ss-ws-am':ws.DeliveryTypeId==3,'ss-ws-infra':ws.DeliveryTypeId==4, 'ss-ws-ops':ws.DeliveryTypeId==6, 'ss-ws-sec':ws.DeliveryTypeId==7, 'workstream-readonly' : IsWorkStreamSelectable}"> {{ ws.WorkStreamName }}</span></span>
                          </li>
                          <li *ngFor="let cms3 of (cms2.CMSLevelThree | activeList);" (click)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3);$event.stopPropagation();" [ngClass]="{open : !cms3.IsChildCollapsed,'ss-exp-col' : (((cms3.CMSLevelFour | activeList)).length > 0 || cms3.Workstreams.length>0)}" tabindex="0" (keypress)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3,null,null,'keypress');$event.stopPropagation();">
                            <span [ngClass]="{selectedmenu : cms3.IsSelected}"><span [ngClass]="{'default-cursor' : !IsCMSSelectable}"> {{ cms3.Name }}</span></span>

                            <div>
                              <ul>
                                <li *ngFor="let ws of cms3.Workstreams;" (click)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3);$event.stopPropagation();" tabindex="0" (keypress)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3,null,null,$event);$event.stopPropagation();">
                                  <span [ngClass]="{selectedmenu : ws.IsSelected}"><span [ngClass]="{'ss-ws-ad':ws.DeliveryTypeId==1,'ss-ws-agile':ws.DeliveryTypeId==2,'ss-ws-am':ws.DeliveryTypeId==3,'ss-ws-infra':ws.DeliveryTypeId==4, 'ss-ws-ops':ws.DeliveryTypeId==6, 'ss-ws-sec':ws.DeliveryTypeId==7, 'workstream-readonly' : IsWorkStreamSelectable}"> {{ ws.WorkStreamName }}</span></span>
                                </li>
                                <li *ngFor="let cms4 of (cms3.CMSLevelFour  | activeList);" (click)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3, cms4);$event.stopPropagation();" [ngClass]="{open : !cms4.IsChildCollapsed,'ss-exp-col' : (((cms4.CMSLevelFive  | activeList)).length > 0 || cms4.Workstreams.length>0)}" tabindex="0" (keypress)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3, cms4,null,'keypress');$event.stopPropagation();">
                                  <span [ngClass]="{selectedmenu : cms4.IsSelected}"><span [ngClass]="{'default-cursor' : !IsCMSSelectable}"> {{ cms4.Name }}</span></span>

                                  <div>
                                    <ul>
                                      <li *ngFor="let ws of cms4.Workstreams;" (click)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3, cms4);$event.stopPropagation();" tabindex="0" (press)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3, cms4,null,$event);$event.stopPropagation();">
                                        <span [ngClass]="{selectedmenu : ws.IsSelected}"><span [ngClass]="{'ss-ws-ad':ws.DeliveryTypeId==1,'ss-ws-agile':ws.DeliveryTypeId==2,'ss-ws-am':ws.DeliveryTypeId==3,'ss-ws-infra':ws.DeliveryTypeId==4, 'ss-ws-ops':ws.DeliveryTypeId==6, 'ss-ws-sec':ws.DeliveryTypeId==7, 'workstream-readonly' : IsWorkStreamSelectable}"> {{ ws.WorkStreamName }}</span></span>
                                      </li>
                                      <li *ngFor="let cms5 of (cms4.CMSLevelFive  | activeList);" (click)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3, cms4, cms5);$event.stopPropagation();" [ngClass]="{open : !cms5.IsChildCollapsed,'ss-exp-col' : cms5.Workstreams.length>0}" tabindex="0" (press)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3, cms4, cms5,'keypress');$event.stopPropagation();">
                                        <span [ngClass]="{selectedmenu : cms5.IsSelected}"><span [ngClass]="{'default-cursor' : !IsCMSSelectable}"> {{ cms5.Name }}</span></span>
                                        <div>
                                          <ul>
                                            <li *ngFor="let ws of cms5.Workstreams;" (click)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3, cms4, cms5);$event.stopPropagation();" tabindex="0" (press)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3, cms4, cms5,$event);$event.stopPropagation();">
                                              <span [ngClass]="{selectedmenu : ws.IsSelected}"><span [ngClass]="{'ss-ws-ad':ws.DeliveryTypeId==1,'ss-ws-agile':ws.DeliveryTypeId==2,'ss-ws-am':ws.DeliveryTypeId==3,'ss-ws-infra':ws.DeliveryTypeId==4, 'ss-ws-ops':ws.DeliveryTypeId==6, 'ss-ws-sec':ws.DeliveryTypeId==7, 'workstream-readonly' : IsWorkStreamSelectable}"> {{ ws.WorkStreamName }}</span></span>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>

                                    </ul>
                                  </div>


                                </li>

                              </ul>
                            </div>

                          </li>

                        </ul>
                      </div>
                    </li>

                  </ul>
                </div>
              </li>

            </ul>
          </div>
        </li>
      </ul>

    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-7 col-lg-6 ss-legends-wrap">
          <span class="ss-client">Client</span>
          <span class="ss-contract">Contract</span>
          <span class="ss-opportunity">Opportunity</span>
          <span class="ss-initiative">Initiative</span>
        </div>
        <div class="col-xs-12 col-sm-5 col-lg-6 ss-btn-wrap">
          <button class="btn btn-primary" [disabled]="(!currentSelectedEndToEnd && !currentSelectedCMS && !currentSelectedWorkstream) || (SearchText != null && SearchText.length >= 3)" *ngIf="isSetAsDefaultVisible" (click)="SetAsDefault()" aria-label="Set as Default">Set as Default</button>
          <button [disabled]="(!currentSelectedEndToEnd && !currentSelectedCMS && !currentSelectedWorkstream) ||  (SearchText != null && SearchText.length >= 3)" class="btn btn-primary" (click)="Apply()" aria-label="Apply">Apply</button>
        </div>
      </div>
    </div>
  </div>
  <span tabindex="0" onfocus="document.getElementById('txtscopeselectorsearch').focus();"> </span>

</div>



<script type="text/javascript">
  var $document = $(document);
  $document.ready(function () {
    var $span = $('.ss-menu-wrap li span');
    $span.click(function () {
      let $this = $(this);
      $this.parent('li').toggleClass('open').siblings('li').removeClass('open').find('li').removeClass('open');
    });
  });
</script>-->

<div id="scope" on-escape="closeScope()" role="dialog" class="c-flyout c-contract__flyout c-scope-flyout  -open" tabindex="-1" aria-hidden="true" aria-labelledby="flyoutTitle" aria-modal="true" style="visibility:visible;">

  <div class="c-flyout__header">
    <div class="c-scope__header">
      <button type="button" class="-dismiss-flyout" aria-label="Close" (click)="closeSlide()"></button>
    </div>

    <div class="c-search-container">
      <div class="c-search-icon">
        <a href="javascript:;" role="button" title="Search"><i class="icon-search" aria-label="search" title="Please enter any three characters of Contract ID/Opp.ID/Client Name/Initiative/Opportunity Name/Preferred Display Name. If Preferred display name is not provided, a contract name can be searched as well."></i></a>
      </div>
      <input placeholder="Please enter minimum three characters to search" id="txtscopeselectorsearch" type="text"
             maxlength="50" (keydown)="SearchKeyUp()" (keypress)="SearchKeyUp()" (keyup)="SearchKeyUp()"
             [(ngModel)]="SearchText"
             (ngModelChange)="changed($event)" class="c-search-control" autocomplete="off" />


    </div>
    <!-- </div>
    <div class="c-flyout__body -customScroll" id="scopeSelectorBody"> -->

    <div class="c-myWizard-form__input">
      <div class="c-myWizard-form__input-area -comp-col">
        <div class="c-custom-select__wrapper">

          <search-dropdown id="SelectedClientId" [options]="scopeSelector.Clients"
                           [isShow]="!scopeSelector.SearchText" [labelInfo]="'SelectClient'"
                           [selected]="clientId" (callback)="ClientChange()"
                           [(ngModel)]="clientId">
          </search-dropdown>
        </div>


      </div>
    </div>
    <div class="loader-scope-selector" *ngIf="ShowLoader"></div>

    

  </div>
  <div class="c-flyout__body -customScroll" *ngIf="(!ShowLoader && (SearchText != null && SearchText.length >= 3))">
    <div class="c-no-match" *ngIf="IsZeroSearchResult" aria-live="polite" aria-atomic="true" role="status">No matching results found.</div>
    <div class="c-accordion" *ngFor="let filteredDetails of filteredScopeDetails;" (keypress)="GetHierarchy(filteredDetails,$event)"
         (click)="GetHierarchy(filteredDetails)" tabindex="0">
      <button type="button" class="c-collapse  -removeaccordion" *ngIf="filteredDetails.EntityType == 'Client'">
        <img src="assets/toolkit/images/banner/client.svg" role="presentation" alt="Client" />
        {{
             filteredDetails.Name
        }}

      </button>
      <button type="button" class="c-collapse  -removeaccordion" *ngIf="filteredDetails.EntityType == 'O'">

        <img src="assets/toolkit/images/banner/opportunity.svg" role="presentation" alt="Contract" /><span>
          {{filteredDetails.EndToEndRefCode}} : {{filteredDetails.Name}}
        </span>
      </button>

      <button type="button" class="c-collapse  -removeaccordion" *ngIf="filteredDetails.EntityType == 'C'">

        <img src="assets/toolkit/images/banner/contract.svg" role="presentation" alt="Contract" />
        <span>
          {{filteredDetails.EndToEndRefCode}} : {{filteredDetails.Name}}
        </span>
      </button>
      <button type="button" class="c-collapse  -removeaccordion" *ngIf="filteredDetails.EntityType == 'I'">
        <img src="assets/toolkit/images/banner/initiative.svg" role="presentation" alt="Contract" />   <span>
          {{filteredDetails.Name}}
        </span>
      </button>



    </div>
  </div>
  <div class="c-flyout__body -customScroll" id="scopeSelectorBody" *ngIf="(!ShowLoader && (SearchText == null || SearchText.length < 3))">
    <div id="accordionParent-scope">



      <div *ngFor="let cioGroup of contractOpportunityInitiativeGroups;" class="c-accordion" [ngClass]="{'-open' : !cioGroup.IsChildCollapsed}" tabindex="0" (keypress)="SelectEndToEnd($event, cioGroup, null,'keypress');$event.stopPropagation();"
           (click)="SelectEndToEnd($event, cioGroup, null);$event.stopPropagation();">
        <button *ngIf="cioGroup.EndToEndType == 'O'" type="button" class="c-collapse" [ngClass]="{'-text-highlight' : cioGroup.IsSelected, '-removeaccordion' : ((cioGroup.CMSLevelOne|activeList).length <= 0 && cioGroup.StandaloneWorkstreams.length <= 0)}"
                [attr.aria-expanded]="!cioGroup.IsChildCollapsed" [attr.aria-controls]="cioGroup.EndToEndUId">
          <img src="assets/toolkit/images/banner/opportunity.svg" role="presentation" alt="Contract" /><span>{{cioGroup.EndToEndRefCode}} : {{cioGroup.EndToEndName}}</span>
        </button>
        <button *ngIf="cioGroup.EndToEndType == 'C'" type="button" class="c-collapse" [ngClass]="{'-text-highlight' : cioGroup.IsSelected,'-removeaccordion' : ((cioGroup.CMSLevelOne|activeList).length <= 0 && cioGroup.StandaloneWorkstreams.length <= 0)}" [attr.aria-expanded]="!cioGroup.IsChildCollapsed" [attr.aria-controls]="cioGroup.EndToEndUId">
          <img src="assets/toolkit/images/banner/contract.svg" role="presentation" alt="Contract" /><span>{{cioGroup.EndToEndRefCode}} : {{cioGroup.EndToEndName}}</span>
        </button>
        <button *ngIf="cioGroup.EndToEndType == 'I'" type="button" class="c-collapse" [ngClass]="{'-text-highlight' : cioGroup.IsSelected,'-removeaccordion' : ((cioGroup.CMSLevelOne|activeList).length <= 0 && cioGroup.StandaloneWorkstreams.length <= 0)}" [attr.aria-expanded]="!cioGroup.IsChildCollapsed" [attr.aria-controls]="cioGroup.EndToEndUId">
          <img src="assets/toolkit/images/banner/initiative.svg" role="presentation" alt="Contract" />
          <span>
            {{cioGroup.EndToEndName}}
          </span>
        </button>
        <div id="{{cioGroup.EndToEndUId}}" class="-collapse" [ngClass]="{'-show' : !cioGroup.IsChildCollapsed}" data-parentid="accordionParent-scope" *ngIf="((cioGroup.CMSLevelOne|activeList).length>0 || cioGroup.StandaloneWorkstreams.length>0)">
          <div class="c-accordion__container">
            <ul class="c-scope-list">

              <li *ngFor="let ws of cioGroup.StandaloneWorkstreams;" [ngClass]="{'-active' : ws.IsSelected}" (click)="SelectWorkstream(ws, null, cioGroup);$event.stopPropagation();" tabindex="0" (keypress)="SelectWorkstream(ws, null, cioGroup,null,null,null,null,null,$event);$event.stopPropagation();">
                <a class="c-scope-list-title" role="button" [attr.title]="ws.WorkstreamType" [ngClass]="{'default-cursor' : IsWorkStreamSelectable}">
                  <img src="assets/toolkit/images/banner/workstream.svg" role="presentation" alt="Workstream" />

                  <div> {{ ws.WorkStreamName }}</div>
                </a>

              </li>

              <li *ngFor="let cms1 of (cioGroup.CMSLevelOne | activeList);" (click)="SelectCMS($event,null, cioGroup, cms1);$event.stopPropagation();"
                  [ngClass]="{'-expand' : !cms1.IsChildCollapsed,'c-scope-list-expand' : (((cms1.CMSLevelTwo | activeList)).length > 0 || cms1.Workstreams.length>0),'-active_cms' : cms1.IsSelected }" tabindex="0" (keypress)="SelectCMS($event,null, cioGroup, cms1,null,null,null,null,'keypress');$event.stopPropagation();">

                <a class="c-scope-list-title" href="javascript:;" [ngClass]="{'default-cursor' : !IsCMSSelectable}" role="button"><div>{{ cms1.Name }}</div></a>
                <div class="c-scope-list-inner">
                  <ul>
                    <li *ngFor="let ws of cms1.Workstreams;" (click)="SelectWorkstream(ws, null, cioGroup, cms1);$event.stopPropagation();" tabindex="0" (keypress)="SelectWorkstream(ws, null, cioGroup, cms1,null,null,null,null,$event);$event.stopPropagation();" [ngClass]="{'-active' : ws.IsSelected }">
                      <a class="c-scope-list-title" role="button"  [attr.title]="ws.WorkstreamType" [ngClass]="{'default-cursor' : IsWorkStreamSelectable}">
                        <img src="assets/toolkit/images/banner/workstream.svg" role="presentation" alt="Workstream" />
                        <div>{{ ws.WorkStreamName }}</div>
                      </a>
                    </li>

                    <li *ngFor="let cms2 of (cms1.CMSLevelTwo | activeList);" (click)="SelectCMS($event,null, cioGroup, cms1,cms2);$event.stopPropagation();"
                        [ngClass]="{'-expand' : !cms2.IsChildCollapsed,'c-scope-list-expand' : (((cms2.CMSLevelThree | activeList)).length > 0 || cms2.Workstreams.length>0),'-active_cms' : cms2.IsSelected}" tabindex="0" (keypress)="SelectCMS($event,null, cioGroup, cms1,cms2,null,null,null,'keypress');$event.stopPropagation();">
                      <a class="c-scope-list-title" href="javascript:;" [ngClass]="{'default-cursor' : !IsCMSSelectable}" role="button"><div>{{ cms2.Name }}</div></a>

                      <div class="c-scope-list-inner">
                        <ul>
                          <li *ngFor="let ws of cms2.Workstreams;" (click)="SelectWorkstream(ws, null, cioGroup, cms1, cms2);$event.stopPropagation();" tabindex="0" (keypress)="SelectWorkstream(ws, null, cioGroup, cms1,cms2,null,null,null,$event);$event.stopPropagation();" [ngClass]="{'-active' : ws.IsSelected }">
                            <a class="c-scope-list-title"  role="button" [attr.title]="ws.WorkstreamType" [ngClass]="{'default-cursor' : IsWorkStreamSelectable}">
                              <img src="assets/toolkit/images/banner/workstream.svg" role="presentation" alt="Workstream" />
                              <div>{{ ws.WorkStreamName }}</div>
                            </a>
                          </li>

                          <li *ngFor="let cms3 of (cms2.CMSLevelThree | activeList);" (click)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3);$event.stopPropagation();" [ngClass]="{'-expand' : !cms3.IsChildCollapsed,'c-scope-list-expand' : (((cms3.CMSLevelFour | activeList)).length > 0 || cms3.Workstreams.length>0),'-active_cms' : cms3.IsSelected}" tabindex="0" (keypress)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3,null,null,'keypress');$event.stopPropagation();">
                            <a class="c-scope-list-title" [ngClass]="{'default-cursor' : !IsCMSSelectable}" role="button"><div>{{ cms3.Name }}</div></a>

                            <div class="c-scope-list-inner">
                              <ul>
                                <li *ngFor="let ws of cms3.Workstreams;" (click)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3);$event.stopPropagation();" tabindex="0" (keypress)="SelectWorkstream(ws, null, cioGroup, cms1,cms2,cms3,null,null,$event);$event.stopPropagation();" [ngClass]="{'-active' : ws.IsSelected }">
                                  <a class="c-scope-list-title"  role="button" [attr.title]="ws.WorkstreamType" [ngClass]="{'default-cursor' : IsWorkStreamSelectable}">
                                    <img src="assets/toolkit/images/banner/workstream.svg" role="presentation" alt="Workstream" />
                                    <div>{{ ws.WorkStreamName }}</div>
                                  </a>
                                </li>

                                <li *ngFor="let cms4 of (cms3.CMSLevelFour  | activeList)" (click)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3, cms4);$event.stopPropagation();" [ngClass]="{'-expand' : !cms4.IsChildCollapsed,'c-scope-list-expand' :(((cms4.CMSLevelFive  | activeList)).length > 0 || cms4.Workstreams.length>0),'-active_cms' : cms4.IsSelected}" tabindex="0" (keypress)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3,cms4,null,'keypress');$event.stopPropagation();">
                                  <a class="c-scope-list-title" [ngClass]="{'default-cursor' : !IsCMSSelectable}" role="button"><div>{{ cms4.Name }}</div></a>

                                  <div class="c-scope-list-inner">
                                    <ul>
                                      <li *ngFor="let ws of cms4.Workstreams;" (click)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3,cms4);$event.stopPropagation();" tabindex="0" (keypress)="SelectWorkstream(ws, null, cioGroup, cms1,cms2,cms3,cms4,null,$event);$event.stopPropagation();" [ngClass]="{'-active' : ws.IsSelected }">
                                        <a class="c-scope-list-title"  role="button" [attr.title]="ws.WorkstreamType" [ngClass]="{'default-cursor' : IsWorkStreamSelectable}">
                                          <img src="assets/toolkit/images/banner/workstream.svg" role="presentation" alt="Workstream" />
                                          <div>{{ ws.WorkStreamName }}</div>
                                        </a>
                                      </li>

                                      <li *ngFor="let cms5 of (cms4.CMSLevelFive  | activeList)" (click)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3, cms4, cms5);$event.stopPropagation();" [ngClass]="{'-expand' : !cms5.IsChildCollapsed,'c-scope-list-expand' : cms5.Workstreams.length>0,'-active_cms' : cms5.IsSelected}" tabindex="0" (keypress)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3,cms4,cms5,'keypress');$event.stopPropagation();">
                                        <a class="c-scope-list-title" [ngClass]="{'default-cursor' : !IsCMSSelectable}"><div>{{ cms5.Name }}</div></a>

                                        <div class="c-scope-list-inner">
                                          <ul>
                                            <li *ngFor="let ws of cms5.Workstreams;" (click)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3,cms4, cms5);$event.stopPropagation();" tabindex="0" (keypress)="SelectWorkstream(ws, null, cioGroup, cms1,cms2,cms3,cms4,cms5,$event);$event.stopPropagation();" [ngClass]="{'-active' : ws.IsSelected }">
                                              <a class="c-scope-list-title"  role="button" [attr.title]="ws.WorkstreamType" [ngClass]="{'default-cursor' : IsWorkStreamSelectable}">
                                                <img src="assets/toolkit/images/banner/workstream.svg" role="presentation" alt="Workstream" />
                                                <div>{{ ws.WorkStreamName }}</div>
                                              </a>
                                            </li>



                                          </ul>
                                        </div>


                                      </li>


                                    </ul>
                                  </div>


                                </li>

                              </ul>
                            </div>


                          </li>


                        </ul>
                      </div>


                    </li>


                  </ul>
                </div>
              </li>


            </ul>

          </div>

        </div>
      </div>


    </div>
  </div>
  <div class="c-flyout__footer">
    <div class="-flyout-btn">
      <a href="javascript:void(0)" role="button" class="o-secondary-button" [disabled]="(!currentSelectedEndToEnd && !currentSelectedCMS && !currentSelectedWorkstream) || (SearchText != null && SearchText.length >= 3)"  (click)="SetAsDefault()" aria-label="Apply & Set as Default">Apply & Set as Default</a>
      <a href="javascript:void(0)" role="button" class="o-primary-button " [disabled]="(!currentSelectedEndToEnd && !currentSelectedCMS && !currentSelectedWorkstream) ||  (SearchText != null && SearchText.length >= 3)" (click)="Apply()" aria-label="Apply">Apply</a>
    </div>
  </div>
  <div class="c-scope__footer">
    <div class="c-scope__footer-item">
      <img src="assets/toolkit/images/banner/client.svg" role="presentation" alt="Client" />
      <span>Client</span>
    </div>
    <div class="c-scope__footer-item">
      <img src="assets/toolkit/images/banner/opportunity.svg" role="presentation" alt="Opportunity" />
      <span>Opportunity</span>
    </div>
    <div class="c-scope__footer-item">
      <img src="assets/toolkit/images/banner/contract.svg" role="presentation" alt="Contract" />
      <span>Contract</span>
    </div>
    <div class="c-scope__footer-item">
      <img src="assets/toolkit/images/banner/initiative.svg" role="presentation" alt="Initiative" />
      <span>Initiative</span>
    </div>
    <div class="c-scope__footer-item">
      <img src="assets/toolkit/images/banner/workstream.svg" role="presentation" alt="Workstream" />
      <span>Workstream</span>
    </div>

  </div>
</div>

<div id="flyout" class="c-flyout__overlay"></div>

