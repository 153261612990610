import { ChangeDetectorRef, Component, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import Enumerable from 'linq';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SessionStorage } from '../../../core/context/sessionstorage';
import cloneDeep from 'lodash/cloneDeep';
import { MetroCitiesComponent } from './metro.cities.component';
import { Location } from '@angular/common';
import { DemographicsService } from '../../../core/services/demographics.service';
import { SharedService } from '../../../core/services/shared.service';
import { enumEntityType } from '../../../core/context/enum';
import { AppConstantsDescription } from '../../../core/context/constants';
import { environment } from '../../../../environments/environment';

var $ = (<any>window).$;
declare var window: any;

@Component({
  templateUrl: './reporting.portfolio.add.edit.component.html',
  providers: [DemographicsService]
})

export class ReportingPortfolioAddEditComponent {


  action: string = "Add";
  path = this.location.path();
  portfolioName: any = "";
  KeyContactsDetails: any;
  E2eDemoGraphics: any;
  deliveryCenter: any;
  MetroCity: any;
  showCGGU: boolean = false;

  DCforContract: any;
  DCforOppInit: any;
  AccountGroups: any;
  DeliveryGroups: any;
  DeliveryUnits: any;
  DeliveryLead: any;
  index: any;
  E2EType: any;
  tempData: any;
  AccountGroupLead: any = "";
  DeliveryGroupLead: any = "";
  DeliveryUnitLead: any = "";
  metroCityFormStatus: boolean = true;

  tempEndToEndDeliveryCenterContactsForOppInit: any;
  tempEndToEndDeliveryCenterContacts: any;

  DeliveryCenterId: any;
  AccountGroupId: any;
  DeliveryGroupId: any;
  DeliveryUnitId: any;
  RoleAccess = this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1 ? this.sessionStorage.roleAccessCode : this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance  ? 'V' : this.sessionStorage.roleAccessCode);

  modalRefMetroCityPopup: BsModalRef;
  scrollYPosition: any;

  public reportingAddEdit: EventEmitter<any> = new EventEmitter();  // .. Child Popup -> Parent
  public alternateSaveDetails: EventEmitter<any> = new EventEmitter();  // .. Child Popup -> Parent


  constructor(private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private demographicsService: DemographicsService,
    private sharedService: SharedService,
    private cdref: ChangeDetectorRef,
    private sessionStorage: SessionStorage,
    private location: Location) { }

  ngOnInit() {

    setTimeout(() => {
      window.dropdownComponent();
      window.collapseComponent();
      window.tabsComponent();
    }, 0);
    if (this.action == 'Edit') {
      var tempId = this.deliveryCenter?.DcCountryId == 997 || this.deliveryCenter?.DcCountryId == 998;
      this.showCGGU = tempId && this.deliveryCenter?.DcCountryName.startsWith("Accenture CG") || this.deliveryCenter?.DcCountryName.startsWith("Avanade GU");

      if (this.E2EType == 'C') {
        var contractItem = this.KeyContactsDetails.EndToEndDeliveryCenterContacts[this.index];
        this.portfolioName = contractItem.DcCountryName;
        this.AccountGroupLead = contractItem.AccountGroupLead;
        this.DeliveryGroupLead = contractItem.DeliveryGroupLead;
        this.DeliveryUnitLead = contractItem.DeliveryUnitLead;
      }
      else {
        var item = this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[this.index];
        this.portfolioName = item.DcCountryName;
        this.AccountGroupLead = item.AccountGroupLead;
        this.DeliveryGroupLead = item.DeliveryGroupLead;
        this.DeliveryUnitLead = item.DeliveryUnitLead;
      }
      this.AccountGroups = Enumerable.from(this.KeyContactsDetails.AccountGroups).where((x: any) => x.ParentId == this.deliveryCenter.DeliveryCenterId).toArray();
      this.DeliveryGroups = Enumerable.from(this.KeyContactsDetails.DeliveryGroups).where((x: any) => x.ParentId == this.AccountGroupId).toArray();
      this.DeliveryUnits = Enumerable.from(this.KeyContactsDetails.DeliveryUnits).where((x: any) => x.ParentId == this.DeliveryGroupId).toArray();

    }
  }
  ngOnDestroy() {
    window.scrollTo(0, this.scrollYPosition);
  }
  CloseModal() {
    this.AccountGroups.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.DeliveryGroups.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.DeliveryUnits.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.DCforOppInit.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.bsModalRef.hide();
    $("body").removeClass("hide-parent-scroll");
  }

  showMetroCity() {
    const initialState = {
      KeyContactsDetails: this.KeyContactsDetails, deliveryCenter: this.deliveryCenter, enterpriseId: this.sessionStorage.enterpriseId,
      RoleAccess: this.RoleAccess, E2eDemoGraphics: this.E2eDemoGraphics
    };
    this.modalRefMetroCityPopup = this.modalService.show(MetroCitiesComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
  }

  DcChangeforNewRecord(Id) {
    this.AccountGroups = Enumerable.from(this.KeyContactsDetails.AccountGroups).where((x: any) => x.ParentId == Id).toArray();
    this.AccountGroupId = "";
    this.DeliveryGroupId = "";
    this.DeliveryUnitId = "";
    this.AccountGroups.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.DeliveryGroups.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.DeliveryUnits.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.DeliveryGroups = [];
    this.DeliveryUnits = [];
    this.cdref.detectChanges();
  }

  AccountGroupsChangeforNewRecord(Id) {
    this.DeliveryGroups = Enumerable.from(this.KeyContactsDetails.DeliveryGroups).where((x: any) => x.ParentId == Id).toArray();
    this.DeliveryUnits = [];
    this.DeliveryGroupId = "";
    this.DeliveryUnitId = "";
    this.DeliveryGroups.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.DeliveryUnits.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.cdref.detectChanges();
  }

  DeliveryGroupsChangeforNewRecord(Id) {
    this.DeliveryUnits = Enumerable.from(this.KeyContactsDetails.DeliveryUnits).where((x: any) => x.ParentId == Id).toArray();
    this.DeliveryUnitId = "";
    this.DeliveryUnits.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.cdref.detectChanges();
  }

  AccountGroupChange(accountGroupId, gridData, index) {

    gridData.DeliveryGroupId = "";
    gridData.DeliveryUnitId = "";
    if (this.E2EType == "C") {

      var changedAccountGroup = Enumerable.from(this.KeyContactsDetails.AccountGroups).where((x: any) => x.Id == accountGroupId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].AccountGroup = (changedAccountGroup == undefined) ? "" : changedAccountGroup.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryUnit = "";
    }
    else {
      var changedAccountGroup = Enumerable.from(this.KeyContactsDetails.AccountGroups).where((x: any) => x.Id == accountGroupId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].AccountGroup = (changedAccountGroup == undefined) ? "" : changedAccountGroup.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnit = "";
    }
    this.AccountGroups.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.DeliveryGroups.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.DeliveryUnits.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.DeliveryUnits = [];
  }
  DeliveryGroupChange(deliveryGroupId, gridData, index) {
    gridData.DeliveryUnitId = "";
    if (this.E2EType == "C") {
      var changedDeliveryGroup = Enumerable.from(this.KeyContactsDetails.DeliveryGroups).where((x: any) => x.Id == deliveryGroupId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryGroup = (changedDeliveryGroup == undefined) ? "" : changedDeliveryGroup.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryUnit = "";
    }
    else {
      var changedDeliveryGroup = Enumerable.from(this.KeyContactsDetails.DeliveryGroups).where((x: any) => x.Id == deliveryGroupId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryGroup = (changedDeliveryGroup == undefined) ? "" : changedDeliveryGroup.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnit = "";
    }
    this.DeliveryGroups.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
    this.DeliveryUnits.forEach(x => { if (x.IsSelected) { x.IsSelected = false } })
  }
  DeliveryUnitChange(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, index) {

    if (this.E2EType == "C") {
      var changedDeliveryUnit = Enumerable.from(this.KeyContactsDetails.DeliveryUnits).where((x: any) => x.Id == deliveryUnitId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryUnit = (changedDeliveryUnit == undefined) ? "" : changedDeliveryUnit.Name;
      if (deliveryUnitId != "") {
        this.DCChangeContract(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, index);
      }
    }
    else {
      var changedDeliveryUnit = Enumerable.from(this.KeyContactsDetails.DeliveryUnits).where((x: any) => x.Id == deliveryUnitId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnit = (changedDeliveryUnit == undefined) ? "" : changedDeliveryUnit.Name;
      if (deliveryUnitId != "") {
        this.DCChange(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, index);
      }
    }
  }


  DCChange(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, index) {
    this.tempData = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit);
    this.tempData.splice(index, 1);
    var dcExist = Enumerable.from(this.tempData).where((x: any) => x.DeliveryCenterId == deliveryCenterId && x.AccountGroupId == accountGroupId && x.DeliveryGroupId == deliveryGroupId && x.DeliveryUnitId == deliveryUnitId).select((s: any) => s).firstOrDefault();
    if (dcExist != undefined) {
      this.sharedService.openAlertPopup("Selected DC already exists", window.scrollY);
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryCenterId = this.tempEndToEndDeliveryCenterContactsForOppInit[index].DeliveryCenterId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].AccountGroupId = this.tempEndToEndDeliveryCenterContactsForOppInit[index].AccountGroupId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryGroupId = this.tempEndToEndDeliveryCenterContactsForOppInit[index].DeliveryGroupId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnitId = this.tempEndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnitId;
    }
    else {
      var changedDC = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryCenterName = changedDC.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].AccountGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnit = "";
    }
    this.tempEndToEndDeliveryCenterContactsForOppInit = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit);
  }


  DCChangeContract(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, index) {
    this.tempData = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContacts);
    this.tempData.splice(index, 1);
    var dcExist = Enumerable.from(this.tempData).where((x: any) => x.DeliveryCenterId == deliveryCenterId && x.AccountGroupId == accountGroupId && x.DeliveryGroupId == deliveryGroupId && x.DeliveryUnitId == deliveryUnitId).firstOrDefault();
    if (dcExist != undefined) {
      this.sharedService.openAlertPopup("Selected DC already exists", window.scrollY);
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryCenterId = this.tempEndToEndDeliveryCenterContacts[index].DeliveryCenterId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].AccountGroupId = this.tempEndToEndDeliveryCenterContacts[index].AccountGroupId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryGroupId = this.tempEndToEndDeliveryCenterContacts[index].DeliveryGroupId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryUnitId = this.tempEndToEndDeliveryCenterContacts[index].DeliveryUnitId;
    }
    else {
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DuplicateDCExists = false;
      var changedDC: any = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryCenterName = changedDC.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].AccountGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryUnit = "";
    }
    this.tempEndToEndDeliveryCenterContacts = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContacts);
  }

  DeiveryLeadChange(deliveryLead, $event) {

  }


  AddDataContract(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, deliveryLead, reportingaddeditform) {
    reportingaddeditform.submitted = true;
    if (!this.showCGGU) {
      if (deliveryCenterId == "" || accountGroupId == "" || deliveryGroupId == "" || deliveryUnitId == "" || !reportingaddeditform.valid || (this.action == 'Edit' && !this.metroCityFormStatus)) {
        return false;
      }
    }
    var enterpriseId = this.DeliveryLead;
    if (enterpriseId?.length > 0) {
      if (cloneDeep($('#hiddenDeliveryLead')).val() != enterpriseId) {
        var popUpData = this.sharedService.openAlertPopup('Please enter valid Enterprise ID', window.scrollY);
        popUpData.content.confirmationResult.subscribe((result) => {
          if (result.result) {
            $("body").addClass("hide-parent-scroll");
          }
        })
        return false;
      }
    }

    if (!this.showCGGU) {
        var dcExist = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContacts).where((x: any) => x.DeliveryCenterId == deliveryCenterId && x.AccountGroupId == accountGroupId && x.DeliveryGroupId == deliveryGroupId && x.DeliveryUnitId == deliveryUnitId).select((s: any) => s).firstOrDefault();
      if (dcExist != undefined) {
        var currentSelectedDC = this.KeyContactsDetails.EndToEndDeliveryCenterContacts[this.index];
        if(currentSelectedDC != dcExist){
        var popUpData = this.sharedService.openAlertPopup("Selected DC already exists", window.scrollY);
        popUpData.content.confirmationResult.subscribe((result) => {
          if (result.result) {
            $("body").addClass("hide-parent-scroll");
          }
        })
        this.ResetAddDominentRow();
        return false;
        }
      }
      var country: any = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).select((s: any) => new Object({ CountryId: s.ParentId })).firstOrDefault();
      var locationData: any = Enumerable.from(this.KeyContactsDetails.LocationHierarchy).where((x: any) => x.Id == country.CountryId).select((s: any) => new Object({ LocationHierarchyId: s.Id, LocationHierarchyName: s.Name })).firstOrDefault();
      var deliveryCenterData: any = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).select((s: any) => new Object({ DeliveryCenterId: s.Id, DeliveryCenterName: s.Name })).firstOrDefault();
      var accountGroupData: any = Enumerable.from(this.KeyContactsDetails.AccountGroups).where((x: any) => x.Id == accountGroupId).select((s: any) => new Object({ AccountGroupId: s.Id, AccountGroupName: s.Name })).firstOrDefault();
      var deliveryGroupData: any = Enumerable.from(this.KeyContactsDetails.DeliveryGroups).where((x: any) => x.Id == deliveryGroupId).select((s: any) => new Object({ DeliveryGroupId: s.Id, DeliveryGroupName: s.Name })).firstOrDefault();
      var deliveryUnitData: any = Enumerable.from(this.KeyContactsDetails.DeliveryUnits).where((x: any) => x.Id == deliveryUnitId).select((s: any) => new Object({ DeliveryUnitId: s.Id, DeliveryUnitName: s.Name })).firstOrDefault();
      var DeliveryCenterId = (deliveryCenterData == undefined) ? 0 : deliveryCenterData.DeliveryCenterId;
      var DeliveryCenterName = (deliveryCenterData == undefined) ? "" : deliveryCenterData.DeliveryCenterName;
      var LocationHierarchyId = (locationData == undefined) ? 0 : locationData.LocationHierarchyId;
      var LocationHierarchyName = (locationData == undefined) ? "" : locationData.LocationHierarchyName;
      var AccountGroupId = (accountGroupData == undefined) ? 0 : accountGroupData.AccountGroupId;
      var AccountGroup = (accountGroupData == undefined) ? "" : accountGroupData.AccountGroupName;
      var DeliveryGroupId = (deliveryGroupData == undefined) ? 0 : deliveryGroupData.DeliveryGroupId;
      var DeliveryGroup = (deliveryGroupData == undefined) ? "" : deliveryGroupData.DeliveryGroupName;
      var DeliveryUnitId = (deliveryUnitData == undefined) ? 0 : deliveryUnitData.DeliveryUnitId;
      var DeliveryUnitName = (deliveryUnitData == undefined) ? "" : deliveryUnitData.DeliveryUnitName;

    if (this.action == 'Add') {
      var gridData = {
        DCLeadingdeliveryCenterId: -1,
        EndToEndDeliveryCenterId: null,
        LedByIndicator: false,
        LeadingAGDGDU: false,
        EffortsContributed : null,
        DcCountryId: LocationHierarchyId,
        DcCountryName: LocationHierarchyName,
        DeliveryCenterId: DeliveryCenterId,
        DeliveryCenterName: DeliveryCenterName,
        DeliveryGroupId: DeliveryGroupId,
        DeliveryGroup: DeliveryGroup,
        AccountGroupId: AccountGroupId,
        AccountGroup: AccountGroup,
        DeliveryUnitId: DeliveryUnitId,
        LeadingAGDGDUId : -1,
        DeliveryUnit: DeliveryUnitName,
        DeliveryLead: deliveryLead,
        InvolvedServices: AppConstantsDescription.TechnologyInvolvedService,
      };
      if (this.KeyContactsDetails.EndToEndDeliveryCenterContacts == null) {
        this.KeyContactsDetails.EndToEndDeliveryCenterContacts = [];
      }
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts.push(gridData);
      }
      else {
        var keyContactEditValue = this.KeyContactsDetails.EndToEndDeliveryCenterContacts[this.index];
        keyContactEditValue.DeliveryLead = deliveryLead;
        keyContactEditValue.AccountGroup = AccountGroup;
        keyContactEditValue.AccountGroupId = AccountGroupId;
        keyContactEditValue.DeliveryGroup = DeliveryGroup;
        keyContactEditValue.DeliveryGroupId = DeliveryGroupId;
        keyContactEditValue.DeliveryUnit = DeliveryUnitName;
        keyContactEditValue.DeliveryUnitId = DeliveryUnitId;
        this.SaveMetroCity();
      }
    }
    else {
      var keyContactEditValue = this.KeyContactsDetails.EndToEndDeliveryCenterContacts[this.index];
      keyContactEditValue.DeliveryLead = deliveryLead;
      this.SaveMetroCity();
    }
    this.reportingAddEdit.emit({ KeyContactsDetails: this.KeyContactsDetails });
    $("body").removeClass("hide-parent-scroll");
    this.CloseModal();
    //this.ResetAddDominentRow();
  }
  AddData(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, deliveryLead, reportingaddeditform) {
    reportingaddeditform.submitted = true;
    if (!this.showCGGU) {
      if (deliveryCenterId == "" || accountGroupId == "" || deliveryGroupId == "" || deliveryUnitId == "" || !reportingaddeditform.valid || (this.action == 'Edit' && !this.metroCityFormStatus)) {
        return false;
      }
    }
    //var enterpriseId = $("#deliveryLead").val();
    var enterpriseId = this.DeliveryLead;
    if (enterpriseId?.length > 0) {
      if ($('#hiddenDeliveryLead').val() != enterpriseId) {
        var popUpData = this.sharedService.openAlertPopup('Please enter valid Enterprise ID', window.scrollY);
        popUpData.content.confirmationResult.subscribe((result) => {
          if (result.result) {
            $("body").addClass("hide-parent-scroll");
          }
        })
        return false;
      }
    }

    if (!this.showCGGU) {
        var dcExist = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit).where((x: any) => x.DeliveryCenterId == deliveryCenterId && x.AccountGroupId == accountGroupId && x.DeliveryGroupId == deliveryGroupId && x.DeliveryUnitId == deliveryUnitId).select((s: any) => s).firstOrDefault();
      if (dcExist != undefined){
        var currentSelectedDC = this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[this.index];
        if(currentSelectedDC != dcExist){
        var popUpData = this.sharedService.openAlertPopup("Selected DC already exists", window.scrollY);
        popUpData.content.confirmationResult.subscribe((result) => {
          if (result.result) {
            $("body").addClass("hide-parent-scroll");
          }
        })
        this.ResetAddDominentRow();
        return false;
        }
      }
      var country: any = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).select((s: any) => new Object({ CountryId: s.ParentId })).firstOrDefault();
      var locationData: any = Enumerable.from(this.KeyContactsDetails.LocationHierarchy).where((x: any) => x.Id == country.CountryId).select((s: any) => new Object({ LocationHierarchyId: s.Id, LocationHierarchyName: s.Name })).firstOrDefault();
      var deliveryCenterData: any = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).select((s: any) => new Object({ DeliveryCenterId: s.Id, DeliveryCenterName: s.Name })).firstOrDefault();
      var accountGroupData: any = Enumerable.from(this.KeyContactsDetails.AccountGroups).where((x: any) => x.Id == accountGroupId).select((s: any) => new Object({ AccountGroupId: s.Id, AccountGroupName: s.Name })).firstOrDefault();
      var deliveryGroupData: any = Enumerable.from(this.KeyContactsDetails.DeliveryGroups).where((x: any) => x.Id == deliveryGroupId).select((s: any) => new Object({ DeliveryGroupId: s.Id, DeliveryGroupName: s.Name })).firstOrDefault();
      var deliveryUnitData: any = Enumerable.from(this.KeyContactsDetails.DeliveryUnits).where((x: any) => x.Id == deliveryUnitId).select((s: any) => new Object({ DeliveryUnitId: s.Id, DeliveryUnitName: s.Name })).firstOrDefault();
      var DeliveryCenterId = (deliveryCenterData == undefined) ? 0 : deliveryCenterData.DeliveryCenterId;
      var DeliveryCenterName = (deliveryCenterData == undefined) ? "" : deliveryCenterData.DeliveryCenterName;
      var LocationHierarchyId = (locationData == undefined) ? 0 : locationData.LocationHierarchyId;
      var LocationHierarchyName = (locationData == undefined) ? "" : locationData.LocationHierarchyName;
      var AccountGroupId = (accountGroupData == undefined) ? 0 : accountGroupData.AccountGroupId;
      var AccountGroup = (accountGroupData == undefined) ? "" : accountGroupData.AccountGroupName;
      var DeliveryGroupId = (deliveryGroupData == undefined) ? 0 : deliveryGroupData.DeliveryGroupId;
      var DeliveryGroup = (deliveryGroupData == undefined) ? "" : deliveryGroupData.DeliveryGroupName;
      var DeliveryUnitId = (deliveryUnitData == undefined) ? 0 : deliveryUnitData.DeliveryUnitId;
      var DeliveryUnitName = (deliveryUnitData == undefined) ? "" : deliveryUnitData.DeliveryUnitName;

      if (this.action == 'Add') {
        var gridData = {
          DCLeadingdeliveryCenterId: -1,
          EndToEndDeliveryCenterId: null,
          LedByIndicator: false,
          LeadingAGDGDU: false,
          EffortsContributed : null,
          DcCountryId: LocationHierarchyId,
          DcCountryName: LocationHierarchyName,
          DeliveryCenterId: DeliveryCenterId,
          DeliveryCenterName: DeliveryCenterName,
          DeliveryGroupId: DeliveryGroupId,
          DeliveryGroup: DeliveryGroup,
          AccountGroupId: AccountGroupId,
          LeadingAGDGDUId : -1,
          AccountGroup: AccountGroup,
          DeliveryUnitId: DeliveryUnitId,
          DeliveryUnit: DeliveryUnitName,
          DeliveryLead: deliveryLead,
        };
        if (this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit == null) {
          this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit = [];
        }
        this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit.push(gridData);
      }
      else {
        var keyContactEditValue = this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[this.index];
        keyContactEditValue.DeliveryLead = deliveryLead;
        keyContactEditValue.AccountGroup = AccountGroup;
        keyContactEditValue.AccountGroupId = AccountGroupId;
        keyContactEditValue.DeliveryGroup = DeliveryGroup;
        keyContactEditValue.DeliveryGroupId = DeliveryGroupId;
        keyContactEditValue.DeliveryUnit = DeliveryUnitName;
        keyContactEditValue.DeliveryUnitId = DeliveryUnitId;
        this.SaveMetroCity();
      }
    }
    else {
      var keyContactEditValue = this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[this.index];
      keyContactEditValue.DeliveryLead = deliveryLead;
      this.SaveMetroCity();
    }
    this.reportingAddEdit.emit({ KeyContactsDetails: this.KeyContactsDetails });
    this.CloseModal();
    //this.ResetAddDominentRow();
    return true;
  }

  UpdateMetroCityDetails(metrocity: any) {
    this.MetroCity = metrocity;
  }
  UpdateMetroCityFormStatus(formStatus: any) {
    this.metroCityFormStatus = formStatus;
  }

  SaveMetroCity() {
    if ($('#metroCityModel').find('-error').length < 1) {
      if (this.MetroCity) {
        this.demographicsService.SaveMetroCityDetails(this.MetroCity.PortfolioMetroCities).subscribe((response: any) => {
          this.sharedService.openAlertPopup('Saved Successfully', window.scrollY);
          if (response != null) {
            this.KeyContactsDetails.LastModifiedOn = response.replace("Z", "");
            this.KeyContactsDetails.LastModifiedBy = this.sessionStorage.enterpriseId;
          }
          this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, this.E2eDemoGraphics.E2EId, this.demographicsService);
        });
      }
      $("body").removeClass("hide-parent-scroll");
    }
    else {
      this.sharedService.openAlertPopup('Please fill all required Fields.', window.scrollY);
      return false;
    }
  }

  ResetAddDominentRow() {

    this.DeliveryLead = "";
    $("#deliveryLead").val('');
    $("#deliveryLead").removeClass("input-blank-error");
    this.KeyContactsDetails.DeliveryCenterId = "";
    this.AccountGroupId = "";
    this.DeliveryGroupId = "";
    this.DeliveryUnitId = "";
  }

  trackById(index: number, item: any): any {
    return item.Id;
  }

}
