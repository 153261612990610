import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
var $ = (<any>window).$;
declare var window: any;

@Component({
  selector: 'terms-use',
  templateUrl: './terms.use.component.html'
})
export class TermsUseComponent {

  constructor(private bsModalRef : BsModalRef) { }

  ngOnInit() {
      setTimeout(function () {
          window.TooltipFunc();
          $('.close').focus();
      }, 0)
  }

  closeModal() {
    this.bsModalRef.hide();
  }



}
