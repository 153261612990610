import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorage } from '../../core/context/sessionstorage';
import { Location } from "@angular/common";
import { enumRoleType, enumSubmissionType, enumProfileTab, enumUserPreference, enumModuleAction, enumPath } from '../../core/context/enum'
import { UserService } from '../../core/services/user.service';
import { UserPreferenceService } from '../../core/services/user.preference.service';
import { ApplicationLoggingService } from '../../core/services/application.logging.service';
import { TileConstants } from '../../core/context/constants';
import { UnsavedChangesService } from '../../core/services/unsaved.changes.service';
var $ = (<any>window).$;
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { BroadCastService } from '../../core/services/broadcast.service';
import { SharedService } from '../../core/services/shared.service';
import { environment } from '../../../environments/environment';
declare var window: any;
@Component({
  selector: 'scope-selector',
  templateUrl: './scope.selector.component.html',
  providers: [UserService, ApplicationLoggingService, UserPreferenceService]
})

export class ScopeSelectorComponent  {
  public setE2EName: EventEmitter<any> = new EventEmitter();
  title: string;
  modalRef_releaseNote: BsModalRef;
  isCMMIPage: any;
  isPlatformAccessPage: any; 
  ScopeSelectorRefreshSubscription: Subscription;
  constructor(private router: Router, private sessionStorage: SessionStorage,
    private activatedRoute: ActivatedRoute, private userService: UserService, private userPreferenceService: UserPreferenceService, private location: Location,
    private _element: ElementRef, private applicationLoggingService: ApplicationLoggingService
    , private ref: ChangeDetectorRef, private unsavedChangesService: UnsavedChangesService, private bsModalRef: BsModalRef, private broadCastService: BroadCastService,private sharedService: SharedService) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.modelChanged
      .pipe(
        debounceTime(500))
      .subscribe(() => {
        this.FilterContracts();
      })

    this.ScopeSelectorRefreshSubscription = this.broadCastService.refreshScopeSelectorData().subscribe((response) => {
      if (response != null) {
        this.RefreshScopeSelector(response);
      }
    });

  }

  modelChanged = new Subject<void>();
  clientName: string = this.sessionStorage.clientName;
 // scopeSelectorPopupActive: boolean = false; -- Not required. Scope selector implemented as modal dialog
  scopeSelector: any = {};
  ShowLoader: boolean = false;
  showDefault: boolean = true;
  IsZeroSearchResult = false;
  IsCMSSelectable: boolean = true;
  IsWorkStreamSelectable: boolean = false;
  isSetAsDefaultVisible: boolean = this.location.path().indexOf('manage') > -1 ? true : false;
  redirectValue: string = 'E2E';
  SearchText: string = '';
  focusElement: any;
  contractOpportunityInitiativeGroups: any;

  currentSelectedEndToEnd: any;
  currentSelectedCMS: any;
  currentSelectedWorkstream: any;
  filteredScopeDetails: any;
  dummy: string;
  clientId: any;
  ngOnInit() {
    this.LoadScopeSelector(false);
 
   // this.SetTitle();
    setTimeout(() => {
      window.collapseComponent();
    }, 0)

  }

  RefreshScopeSelector(scopeSelectorAttributes) {
    this.contractOpportunityInitiativeGroups = null;
    this.clientName = this.sessionStorage.clientName;
    if (scopeSelectorAttributes.IsLoad) {
      this.LoadScopeSelector(true);
    }
    this.SetTitle();
  };

  closeSlide() {
    this.bsModalRef.hide();
  }

  SetTitle() {
    var title = "";    
    title = this.clientName;
    title = this.sessionStorage.endToEndName ? title + " | " + this.sessionStorage.endToEndName : title;
    title = this.sessionStorage.cmsName ? title + " | " + this.sessionStorage.cmsName : title;
    title = this.sessionStorage.workstreamName ? title + " | " + this.sessionStorage.workstreamName : title; 
    this.title = title ;

    this.broadCastService.broadcastRefreshScopeSelectorTitle({ endToEndName: this.title, scopeTitle: this.title, tmsName: this.sessionStorage.tmsName });
  }

  ScrollTop(): void {
    setTimeout(function () {
      $('#accordionParent-scope').scrollTop(0);
      if ($('#accordionParent-scope .-open').position()) {
        $('#scopeSelectorBody').scrollTop($('#accordionParent-scope .-open').position().top);
      }
    }, 0);
  }

  CalculateClosedFlagatCMSLevel(cmsLevelOne) {
    cmsLevelOne.forEach((cms1: any, key) => {
      var cmsLevelTwo = cms1.CMSLevelTwo.filter((x: any) => x.ClosedFlag === 'Active');
      if (cmsLevelTwo && cmsLevelTwo.length > 0) {
        cms1.ClosedFlag = 'Active';
      }
      cms1.CMSLevelTwo.forEach((cms2: any, key) => {
        var cmsLevelThree = cms2.CMSLevelThree.filter((x: any) => x.ClosedFlag === 'Active');
        if (cmsLevelThree && cmsLevelThree.length > 0) {
          cms1.ClosedFlag = cms2.ClosedFlag = 'Active';
        }
        cms2.CMSLevelThree.forEach((cms3: any, key) => {
          var cmsLevelFour = cms3.CMSLevelFour.filter((x: any) => x.ClosedFlag === 'Active');
          if (cmsLevelFour && cmsLevelFour.length > 0) {
            cms1.ClosedFlag = cms2.ClosedFlag = cms3.ClosedFlag = 'Active';
          }
          cms3.CMSLevelFour.forEach((cms4: any, key) => {
            var cmsLevelFive = cms4.CMSLevelFive.filter((x: any) => x.ClosedFlag === 'Active');
            if (cmsLevelFive && cmsLevelFive.length > 0) {
              cms1.ClosedFlag = cms2.ClosedFlag = cms3.ClosedFlag = cms4.ClosedFlag = 'Active';
            }
            cmsLevelFive.forEach((cms5: any, key) => {
              cms1.ClosedFlag = cms2.ClosedFlag = cms3.ClosedFlag = cms4.ClosedFlag = cms5.ClosedFlag = 'Active';
            })

          })
        });
      });
    });

  }

  CalculateClosedFlag(endToEndGroup: any) {
    endToEndGroup.forEach((endToEnd, key) => {
        this.CalculateClosedFlagatCMSLevel(endToEnd.CMSLevelOne);
    });
  }

  SetCurrentSelectedItem() {
    var selectedClient = this.scopeSelector.SelectedClient;
    this.clientId = this.scopeSelector.SelectedClient.Id;
    if (selectedClient != null && selectedClient.SelectedContractOpportunityInitiativeGroup != null) {
      this.currentSelectedEndToEnd = selectedClient.SelectedContractOpportunityInitiativeGroup;
      this.currentSelectedCMS = this.currentSelectedEndToEnd.SelectedCMS;
      this.currentSelectedWorkstream = this.currentSelectedEndToEnd.SelectedWorkstream;
    }
  }

  FilterContractOpportunityInitiatives() {
    var client = this.scopeSelector.SelectedClient;

    if (client != null && client != undefined) {

      if (client.ContractOpportunityInitiativeGroups == null || client.ContractOpportunityInitiativeGroups == undefined || client.ContractOpportunityInitiativeGroups.length == 0) {
        this.ShowLoader = true;
        this.userService.getEndToEndByClient(client.ClientUId).subscribe((response: any) => {
          client.ContractOpportunityInitiativeGroups = response.ContractOpportunityInitiativeGroups;
          this.contractOpportunityInitiativeGroups = client.ContractOpportunityInitiativeGroups;
          this.ShowLoader = false;
          this.CalculateClosedFlag(this.contractOpportunityInitiativeGroups);

        });
      }
      else {
        this.contractOpportunityInitiativeGroups = client.ContractOpportunityInitiativeGroups;
        this.SetCurrentSelectedItem();
        this.CalculateClosedFlag(this.contractOpportunityInitiativeGroups);

      }

    }
  }
  SetDefaultEndToEnd(isSetDefaultEndToEnd: boolean) {
    if (isSetDefaultEndToEnd) {
      // this.scopeSelectorPopupActive = false;
      this.closeSlide();
      var selectedClient = this.scopeSelector.SelectedClient;

      var selectedEndToEndGroup = selectedClient.SelectedContractOpportunityInitiativeGroup;
      var selectedEndToEnd = selectedEndToEndGroup;
      this.sessionStorage.clientName = selectedClient.Name;
      this.sessionStorage.endToEndName = selectedEndToEnd.EndToEndName;
      this.sessionStorage.cmsName = "";
      this.sessionStorage.workstreamName = "";
      this.sessionStorage.endToEndId = selectedEndToEnd.EndToEndId;
      this.sessionStorage.endToEndUId = selectedEndToEnd.EndToEndUId;
      this.sessionStorage.roleTypeCode = selectedEndToEnd.RoleTypeCode;
      this.sessionStorage.restrictedInstanceIndicator = selectedEndToEnd.RestrictedInstanceIndicator;
      //this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode == 'V' ? selectedEndToEnd.RoleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : selectedEndToEnd.RoleAccessCode);
      this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode;
      this.sessionStorage.endToEndType = selectedEndToEnd.EndToEndType;
      this.sessionStorage.roleType = selectedEndToEnd.RoleType;
      this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
      this.sessionStorage.clientWMSId = 0;
      this.sessionStorage.clientWMSUId = '';
     
      this.sessionStorage.workstreamId = 0;
      this.sessionStorage.clientId = selectedEndToEnd.ClientId;
      this.clientName = this.sessionStorage.clientName;
      this.sessionStorage.deployRegion = selectedEndToEnd.Instance;
    }

  }
 

  LoadScopeSelector(isSetDefaultEndToEnd: boolean = false, event: any = null) {
    this.focusElement = null;
    if (event != null) {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode !== 13) { // code for ENTER key
        return;
      }

    }
    this.SearchText = null;
  

      if (event) {
        this.focusElement = event.target.id;
      }
      this.userService.getScopeDetails().subscribe((response) => {
        this.scopeSelector = response;
        this.clientId = this.scopeSelector.SelectedClient.Id;
        this.FilterContractOpportunityInitiatives();
        this.SetDefaultEndToEnd(isSetDefaultEndToEnd);
      //  this.scopeSelectorPopupActive = isSetDefaultEndToEnd ? false : true;

        setTimeout(function () {
        });

        if (isSetDefaultEndToEnd) {
          this.SetTitle();
          this.router.navigate(['/mywizard/manage', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
        }
        this.ScrollTop();
      });
    

    this.IsZeroSearchResult = false;
    this.IsCMSSelectable = this.location.path().indexOf('manage') > -1 || this.location.path().indexOf('home') > -1 ||
      this.location.path().indexOf('matrixstructure') > -1 || this.location.path().indexOf('childtiles/98') > -1 || this.location.path().indexOf('childtiles/138') > -1 ||
      this.location.path().indexOf('teamstructure') > -1 || this.location.path().indexOf('clientstructure') > -1 ||
      this.location.path().indexOf('childtiles/176') > -1 ||
      TileConstants.ProfileTiles.indexOf(this.location.path().split('/')[2]) > -1 || this.location.path().indexOf('childtiles/176') > -1 ||
      this.location.path().indexOf('tasks') > -1 || this.location.path().indexOf('childtiles/102') > -1 ||
      this.location.path().indexOf('childtiles/103') > -1 || this.location.path().indexOf('childtiles/138') > -1 ;

    this.IsWorkStreamSelectable = this.location.path().indexOf('cmmi') > -1;
    this.ScrollTop();
  }
  closeScope() {
    this.closeSlide();
    if (this.focusElement) {
      $('#' + this.focusElement).focus();
    }
  }

  SetSelectedEndToEndAndCMS(endToEndGroup: any, endtoEnd: any, cms: any, workstream: any) {
    var selectedClient = this.scopeSelector.SelectedClient;
    if (endToEndGroup) {
      endToEndGroup.SelectedContractOpportunityInitiative = endtoEnd;
      endToEndGroup.SelectedContractOpportunityInitiative.SelectedCMS = null;
      endToEndGroup.SelectedContractOpportunityInitiative.SelectedWorkstream = null;
      if (cms) {
        endToEndGroup.SelectedContractOpportunityInitiative.SelectedCMS = cms;
      }
      if (workstream) {
        endToEndGroup.SelectedContractOpportunityInitiative.SelectedWorkstream = workstream;
      }
      selectedClient.SelectedContractOpportunityInitiativeGroup = endToEndGroup;

    }
    else if (endtoEnd) {
      endtoEnd.SelectedCMS = null;
      endtoEnd.SelectedWorkstream = null;
      if (cms) {
        endtoEnd.SelectedCMS = cms;
      }
      if (workstream) {
        endtoEnd.SelectedWorkstream = workstream;
      }
      selectedClient.SelectedContractOpportunityInitiativeGroup = endtoEnd;
    }

  }
  WorkstreamReset(workstreams: any) {
    if (workstreams != null && workstreams!=undefined) {
    workstreams.forEach((ws, key) => {
      ws.IsSelected = false;
    });
      }
  }
  ResetScopeSelectorCollapsed(): void {
    if (this.contractOpportunityInitiativeGroups != null && this.contractOpportunityInitiativeGroups!=undefined)
    this.contractOpportunityInitiativeGroups.forEach((cio) => {
      cio.IsChildCollapsed = true;
      cio.IsSelected = false;
      this.WorkstreamReset(cio.StandaloneWorkstreams);
      cio.ContractOpportunityInitiatives.forEach((endToEndGroup: any) => {
        endToEndGroup.IsChildCollapsed = true;
        endToEndGroup.IsSelected = false;
        this.WorkstreamReset(endToEndGroup.StandaloneWorkstreams);
        endToEndGroup.CMSLevelOne.forEach((cms1: any) => {
          cms1.IsChildCollapsed = true;
          cms1.IsSelected = false;
          this.WorkstreamReset(cms1.Workstreams);
          cms1.CMSLevelTwo.forEach((cms2: any) => {
            cms2.IsChildCollapsed = true;
            cms2.IsSelected = false;
            this.WorkstreamReset(cms2.Workstreams);
            cms2.CMSLevelThree.forEach((cms3: any) => {
              cms3.IsChildCollapsed = true;
              cms3.IsSelected = false;
              this.WorkstreamReset(cms3.Workstreams);
              cms3.CMSLevelFour.forEach((cms4: any) => {
                cms4.IsChildCollapsed = true;
                cms4.IsSelected = false;
                this.WorkstreamReset(cms4.Workstreams);
                cms4.CMSLevelFive.forEach((cms5: any) => {
                  cms5.IsChildCollapsed = true;
                  cms5.IsSelected = false;
                  this.WorkstreamReset(cms5.Workstreams);
                });
              });
            });
          });
        });
      });

      cio.CMSLevelOne.forEach((cms1: any) => {
        cms1.IsChildCollapsed = true;
        cms1.IsSelected = false;
        this.WorkstreamReset(cms1.Workstreams);
        cms1.CMSLevelTwo.forEach((cms2: any) => {
          cms2.IsChildCollapsed = true;
          cms2.IsSelected = false;
          this.WorkstreamReset(cms2.Workstreams);
          cms2.CMSLevelThree.forEach((cms3: any) => {
            cms3.IsChildCollapsed = true;
            cms3.IsSelected = false;
            this.WorkstreamReset(cms3.Workstreams);
            cms3.CMSLevelFour.forEach((cms4: any) => {
              cms4.IsChildCollapsed = true;
              cms4.IsSelected = false;
              this.WorkstreamReset(cms4.Workstreams);
              cms4.CMSLevelFive.forEach((cms5: any) => {
                cms5.IsChildCollapsed = true;
                cms5.IsSelected = false;
                this.WorkstreamReset(cms5.Workstreams);
              });
            });
          });
        });
      });


    });

  }
  ClientChange() {
    this.scopeSelector.SelectedClient = this.scopeSelector.Clients.filter(x => x.Id == this.clientId)[0];
    this.ResetScopeSelectorCollapsed();
    this.contractOpportunityInitiativeGroups = [];
    this.currentSelectedWorkstream = null;
    this.currentSelectedEndToEnd = null;
    this.currentSelectedCMS = null;
    this.SearchText = null;
    this.IsZeroSearchResult = false;
    this.FilterContractOpportunityInitiatives();
  };
  SelectWorkstream(ws: any, endToEndGroup: any, endToEnd: any, cms1?: any, cms2?: any, cms3?: any, cms4?: any, cms5?: any, event?: any) {
    if (event != null) {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode !== 13) { // code for ENTER key
        return;
      }
    }

    var cmmiTeamVisible = this.location.path().indexOf('cmmi') > -1 ? true : false;
    if (cmmiTeamVisible) {
      this.IsWorkStreamSelectable = true;
      return false;
    }
    this.currentSelectedEndToEnd = endToEnd;
    this.currentSelectedWorkstream = ws;
    this.currentSelectedCMS = null;

    var isChildCollapsed = false;
    this.ResetScopeSelectorCollapsed();
    ws.IsSelected = true;

    if (cms5) {
      cms5.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms5;
      cms4.IsChildCollapsed = isChildCollapsed;
      cms3.IsChildCollapsed = isChildCollapsed;
      cms2.IsChildCollapsed = isChildCollapsed;
      cms1.IsChildCollapsed = isChildCollapsed;

    }
    else if (cms4) {
      cms4.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms4;

      cms3.IsChildCollapsed = isChildCollapsed;
      cms2.IsChildCollapsed = isChildCollapsed;
      cms1.IsChildCollapsed = isChildCollapsed;

    }
    else if (cms3) {
      cms3.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms3;

      cms2.IsChildCollapsed = isChildCollapsed;
      cms1.IsChildCollapsed = isChildCollapsed;

    }
    else if (cms2) {
      cms2.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms2;

      cms1.IsChildCollapsed = isChildCollapsed;

    }
    else if (cms1) {
      cms1.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms1;

    }


    endToEnd.IsChildCollapsed = isChildCollapsed;
    if (endToEndGroup) {
      endToEndGroup.IsChildCollapsed = isChildCollapsed;
    }
    this.SetSelectedEndToEndAndCMS(endToEndGroup, this.currentSelectedEndToEnd, this.currentSelectedCMS, this.currentSelectedWorkstream);

  }

  SelectCMS(event: any, endToEndGroup: any, endToEnd: any, cms1?: any, cms2?: any, cms3?: any, cms4?: any, cms5?: any, eventtype?: any) {

    if (event != null && eventtype == "keypress") {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode !== 13) { // code for ENTER key
        return;
      }
    }
    this.currentSelectedEndToEnd = null;
    this.currentSelectedCMS = null;
    this.currentSelectedWorkstream = null;
    this.currentSelectedEndToEnd = endToEnd;
    if (cms5) {
      var isChildCollapsed = !cms5.IsChildCollapsed;

    }
    else if (cms4) {
      var isChildCollapsed = !cms4.IsChildCollapsed;
    }
    else if (cms3) {
      var isChildCollapsed = !cms3.IsChildCollapsed;
    }
    else if (cms2) {
      var isChildCollapsed = !cms2.IsChildCollapsed;
    }
    else if (cms1) {
      var isChildCollapsed = !cms1.IsChildCollapsed;
    }
    this.ResetScopeSelectorCollapsed();
    if (cms5) {
      cms5.IsChildCollapsed = isChildCollapsed;
      cms5.IsSelected = this.IsCMSSelectable ? true : false;
      this.currentSelectedCMS = cms5;
      if (!isChildCollapsed) {
        cms4.IsChildCollapsed = isChildCollapsed;
        cms3.IsChildCollapsed = isChildCollapsed;
        cms2.IsChildCollapsed = isChildCollapsed;
        cms1.IsChildCollapsed = isChildCollapsed;
        endToEnd.IsChildCollapsed = isChildCollapsed;
      }
      else {
        cms4.IsChildCollapsed = !isChildCollapsed;
        cms3.IsChildCollapsed = !isChildCollapsed;
        cms2.IsChildCollapsed = !isChildCollapsed;
        cms1.IsChildCollapsed = !isChildCollapsed;
        endToEnd.IsChildCollapsed = !isChildCollapsed;
      }
    }
    else if (cms4) {
      cms4.IsChildCollapsed = isChildCollapsed;
      cms4.IsSelected = this.IsCMSSelectable ? true : false;

      this.currentSelectedCMS = cms4;
      if (!isChildCollapsed) {
        cms3.IsChildCollapsed = isChildCollapsed;
        cms2.IsChildCollapsed = isChildCollapsed;
        cms1.IsChildCollapsed = isChildCollapsed;
        endToEnd.IsChildCollapsed = isChildCollapsed;
      }
      else {
        cms3.IsChildCollapsed = !isChildCollapsed;
        cms2.IsChildCollapsed = !isChildCollapsed;
        cms1.IsChildCollapsed = !isChildCollapsed;
        endToEnd.IsChildCollapsed = !isChildCollapsed;
      }
    }
    else if (cms3) {
      cms3.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms3;
      cms3.IsSelected = this.IsCMSSelectable ? true : false;

      if (!isChildCollapsed) {
        cms2.IsChildCollapsed = isChildCollapsed;
        cms1.IsChildCollapsed = isChildCollapsed;
        endToEnd.IsChildCollapsed = isChildCollapsed;
      }
      else {
        cms2.IsChildCollapsed = !isChildCollapsed;
        cms1.IsChildCollapsed = !isChildCollapsed;
        endToEnd.IsChildCollapsed = !isChildCollapsed;
      }
    }
    else if (cms2) {
      cms2.IsChildCollapsed = isChildCollapsed;
      cms2.IsSelected = this.IsCMSSelectable ? true : false;

      this.currentSelectedCMS = cms2;
      if (!isChildCollapsed) {
        cms1.IsChildCollapsed = isChildCollapsed;
        endToEnd.IsChildCollapsed = isChildCollapsed;
      }
      else {
        cms1.IsChildCollapsed = !isChildCollapsed;
        endToEnd.IsChildCollapsed = !isChildCollapsed;
      }
    }
    else if (cms1) {
      cms1.IsChildCollapsed = isChildCollapsed;
      cms1.IsSelected = this.IsCMSSelectable ? true : false;
      this.currentSelectedCMS = cms1;
      if (!isChildCollapsed) {
        endToEnd.IsChildCollapsed = isChildCollapsed;
      }
      else {
        endToEnd.IsChildCollapsed = !isChildCollapsed;
      }
    }

    if (endToEndGroup) {
      if (!isChildCollapsed) {
        endToEndGroup.IsChildCollapsed = isChildCollapsed;
      }
      else {
        endToEndGroup.IsChildCollapsed = !isChildCollapsed;
      }
    }

    if (this.IsCMSSelectable) {
      this.SetSelectedEndToEndAndCMS(endToEndGroup, this.currentSelectedEndToEnd, this.currentSelectedCMS, null);

    }
    else {
      this.currentSelectedCMS = null;
      endToEnd.IsSelected = true;
    }
  }

  SelectEndToEnd(event: any, endToEnd: any, endToEndGroup: any, eventype?: string) {
    if (eventype == "keypress") {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode !== 13) { // code for ENTER key
        return;
      }
    }
    this.currentSelectedEndToEnd = null;
    this.currentSelectedWorkstream = null;
    this.currentSelectedCMS = null;
    var isChildCollapsed = !endToEnd.IsChildCollapsed;
    this.ResetScopeSelectorCollapsed();
    var element = $(event.currentTarget)
    endToEnd.IsChildCollapsed = isChildCollapsed;
    this.currentSelectedEndToEnd = endToEnd;
    var element = $(event.currentTarget);
    endToEnd.IsSelected = true;
    if (endToEndGroup) {
      if (!isChildCollapsed) {
        endToEndGroup.IsChildCollapsed = isChildCollapsed;
      }
      else {
        endToEndGroup.IsChildCollapsed = !isChildCollapsed;
      }
    }


    this.SetSelectedEndToEndAndCMS(endToEndGroup, this.currentSelectedEndToEnd, null, null);
  }


  refreshPage(state: string, parentTileId: number = null) {
    if (parentTileId) {
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([state, parentTileId, 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }
    else {
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([state, 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }
  }

  splitURL(url: string) {
    var hashes: string[] = url.split('/');
    var urlMyWizard = hashes[1];  // It will give mywizard from URL
    var urlPage = hashes[2];  // It will give pages like manage, admin and tasks etc... from URL

    return '/' + urlMyWizard + '/' + urlPage;
  }


  Apply() {
    this.isCMMIPage = this.router.url.indexOf(enumPath.CMMI) > -1;
    this.isPlatformAccessPage = this.router.url.indexOf(enumPath.PlatformAccess) > -1;
    if (this.splitURL(this.router.url) == "/mywizard/profile") {
      this.unsavedChangesService.broadcast("profile");
      if (this.sessionStorage.isUserAlert) {
        this.closeScope();
        return false;
      }
    }
    else if (this.splitURL(this.router.url) == "/mywizard/scope") {
      this.unsavedChangesService.broadcast("scope");
      if (this.sessionStorage.isUserAlert) {
        this.closeScope();
        return false;
      }
    }
    else if (this.splitURL(this.router.url) == "/mywizard/keycontacts") {
      this.unsavedChangesService.broadcast("keycontacts");
      if (this.sessionStorage.isUserAlert) {
        this.closeScope();
        return false;
      }
    }
    else if (this.splitURL(this.router.url) == "/mywizard/clientstructure") {
      this.unsavedChangesService.broadcast("clientstructure");
      if (this.sessionStorage.isUserAlert) {
        return false;
      }
    }
    else if (this.splitURL(this.router.url) == "/mywizard/teamstructure") {
      this.unsavedChangesService.broadcast("teamstructure");
      if (this.sessionStorage.isUserAlert) {
        return false;
      }
    }
    else if (this.splitURL(this.router.url) == "/mywizard/cmmi") {
      this.unsavedChangesService.broadcast("cmmi");
      if (this.sessionStorage.isUserAlert) {
        return false;
      }
    }
    else if (this.splitURL(this.router.url) == "/mywizard/platformaccess") {
      this.unsavedChangesService.broadcast("platformaccess");
      if (this.sessionStorage.isUserAlert) {
        return false;
      }
    }
    if (this.currentSelectedCMS || this.currentSelectedEndToEnd || this.currentSelectedWorkstream) {
      this.SelectedScopeselectorDetails();

      this.closeSlide();
      this.SetTitle();

      if (this.sessionStorage.roleTypeCode == enumRoleType.TeamMember) {
        this.router.navigate(['/mywizard/tasks', 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
      }
      else if (this.location.path().indexOf('home') <= -1) {
        this.CheckMandatoryFieldsAndRedirection();
      }
    
      else {
        this.refreshPage(this.splitURL(this.router.url));
      }
      this.ScopeSelectorAndURLNavigrationLogging();

    }
    this.sessionStorage.isValidEndToEndOverallDeliveryLead = null;
    this.sessionStorage.isValidEndDate = null;
  };


  CheckMandatoryFieldsAndRedirection() {
    this.userService.IsDeliveryMandatoryFilled(this.sessionStorage.endToEndId).subscribe((response) => {
      if (response == 0) {

        this.sessionStorage.clientWMSId = 0;
        this.sessionStorage.clientWMSUId = '';
        this.sessionStorage.cmsName = "";
     
        this.sessionStorage.tmsName = "";
        this.sessionStorage.workstreamId = 0;
        this.sessionStorage.workstreamUId = "";
        this.sessionStorage.workstreamName = "";
        this.sessionStorage.IsProfileCompleted = false;

        this.SetTitle();
        if (TileConstants.ProfileTiles.indexOf(this.location.path().split('/')[2]) > -1) {
          this.sessionStorage.profileTab = enumProfileTab.Profile;
          if (this.sessionStorage.selectedSubmissionType != 1) {
            this.sharedService.openAlertPopup("Please complete the details on Profile/Scope/Key Contacts tab to enable all features", window.scrollY);
          }
          this.refreshPage('/mywizard/profile');
        }        
        else {
          this.refreshPage('/mywizard/home');
        }
        this.sessionStorage.selectedSubmissionType = 1;
      }
      else {
        this.sessionStorage.profileTab = this.sessionStorage.selectedSubmissionType == 1 ? enumProfileTab.Profile : enumProfileTab.ClientStructure;
        this.sessionStorage.IsProfileCompleted = true;
        if (this.location.path().indexOf('childtiles/176') > -1) {
          this.refreshPage(this.splitURL(this.router.url), 176);
        }
        else if (this.location.path().indexOf('childtiles/98') > -1) {
          this.refreshPage(this.splitURL(this.router.url), 98);
        }
        else if (this.location.path().indexOf('childtiles/138') > -1) {
          this.refreshPage(this.splitURL(this.router.url), 138);
        }
        else if (this.location.path().indexOf('childtiles/102') > -1) {
          this.refreshPage(this.splitURL(this.router.url), 102);
        }
        else if (this.location.path().indexOf('childtiles/103') > -1) {
          this.refreshPage(this.splitURL(this.router.url), 103);
        }
        else if (this.location.path().indexOf('childtiles/128') > -1) {
          this.refreshPage(this.splitURL(this.router.url), 128);
        } else if (TileConstants.ProfileTiles.indexOf(this.location.path().split('/')[2]) > -1 && enumProfileTab.ClientStructure == this.sessionStorage.profileTab) {
          this.refreshPage('/mywizard/clientstructure');
        } else if (this.location.path().indexOf('manage') > -1 || this.location.path().indexOf('tasks') > -1 || this.location.path().indexOf('portfolio') > -1) {
          this.refreshPage(this.splitURL(this.router.url));
        }
        else if (this.isCMMIPage || this.isPlatformAccessPage) {
          this.refreshPage(this.splitURL(this.router.url));
        }
         
        else {
          this.refreshPage('/mywizard/profile');
        }
      }
      this.broadCastService.broadcastProfileCompletionStatus(this.sessionStorage.IsProfileCompleted);
    });
  }

  SelectedScopeselectorDetails() {
    if (this.currentSelectedWorkstream) {
      var selectedWorkstream = this.currentSelectedWorkstream;
      var selectedCMS = this.currentSelectedCMS;
      var selectedEndToEnd = this.currentSelectedEndToEnd;
      this.sessionStorage.clientId = this.scopeSelector.SelectedClient.Id;
      this.sessionStorage.clientUId = this.scopeSelector.SelectedClient.ClientUId;
      this.sessionStorage.endToEndId = selectedEndToEnd.EndToEndId;
      this.sessionStorage.endToEndUId = selectedEndToEnd.EndToEndUId;
      this.sessionStorage.endToEndType = selectedEndToEnd.EndToEndType;
      this.sessionStorage.selectedSubmissionType = enumSubmissionType.Workstream;
      this.sessionStorage.roleTypeCode = selectedEndToEnd.RoleTypeCode;
      this.sessionStorage.roleType = selectedEndToEnd.RoleType;
      this.sessionStorage.restrictedInstanceIndicator = selectedEndToEnd.RestrictedInstanceIndicator;
      //this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode == 'V' ? selectedEndToEnd.RoleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : selectedEndToEnd.RoleAccessCode);
      this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode;
      this.sessionStorage.endToEndName = selectedEndToEnd.EndToEndName;

      this.clientName = this.sessionStorage.clientName = this.scopeSelector.SelectedClient.Name;
      this.sessionStorage.workstreamId = selectedWorkstream.WorkstreamId;
      this.sessionStorage.workstreamUId = selectedWorkstream.WorkstreamUId;
      this.sessionStorage.workstreamName = selectedWorkstream.WorkStreamName;

     
      this.sessionStorage.tmsName = "";
      this.sessionStorage.deployRegion = selectedEndToEnd.Instance;
      if (selectedCMS) {
        this.sessionStorage.clientWMSId = selectedCMS.Id
        this.sessionStorage.cmsName = selectedCMS.Name;
        this.sessionStorage.clientWMSUId = selectedCMS.CMSUId;
      }
      else {
        this.sessionStorage.clientWMSId = selectedWorkstream.CMSId;
        this.sessionStorage.clientWMSUId = selectedWorkstream.CMSUId;
        this.sessionStorage.cmsName = "";

      }
      this.sessionStorage.IsProfileCompleted = null;

    }
    else if (this.currentSelectedCMS) {
      var selectedCMS = this.currentSelectedCMS;
      var selectedEndToEnd = this.currentSelectedEndToEnd;
      this.sessionStorage.clientId = this.scopeSelector.SelectedClient.Id;
      this.sessionStorage.clientUId = this.scopeSelector.SelectedClient.ClientUId;
      this.sessionStorage.endToEndId = selectedEndToEnd.EndToEndId;
      this.sessionStorage.endToEndUId = selectedEndToEnd.EndToEndUId;
      this.sessionStorage.endToEndType = selectedEndToEnd.EndToEndType;
      this.sessionStorage.endToEndRefCode = selectedEndToEnd.EndToEndRefCode;
      this.sessionStorage.selectedSubmissionType = enumSubmissionType.ClientWMS;
      this.sessionStorage.roleTypeCode = selectedEndToEnd.RoleTypeCode;
      this.sessionStorage.roleType = selectedEndToEnd.RoleType;
      this.sessionStorage.restrictedInstanceIndicator = selectedEndToEnd.RestrictedInstanceIndicator;
      //this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode == 'V' ? selectedEndToEnd.RoleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : selectedEndToEnd.RoleAccessCode);
      this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode;
      this.sessionStorage.endToEndName = selectedEndToEnd.EndToEndName;

      this.clientName = this.sessionStorage.clientName = this.scopeSelector.SelectedClient.Name;
      this.sessionStorage.clientWMSId = selectedCMS.Id;
      this.sessionStorage.clientWMSUId = selectedCMS.CMSUId;
      this.sessionStorage.cmsName = selectedCMS.Name;
     
      this.sessionStorage.tmsName = "";
      this.sessionStorage.workstreamId = 0;
      this.sessionStorage.workstreamUId = "";
      this.sessionStorage.workstreamName = "";
      this.sessionStorage.deployRegion = selectedEndToEnd.Instance;
      this.sessionStorage.IsProfileCompleted = null;

    }
    else {
      var selectedEndToEnd = this.currentSelectedEndToEnd;
      this.sessionStorage.clientId = this.scopeSelector.SelectedClient.Id;
      this.sessionStorage.clientUId = this.scopeSelector.SelectedClient.ClientUId;
      this.sessionStorage.endToEndId = selectedEndToEnd.EndToEndId;
      this.sessionStorage.endToEndUId = selectedEndToEnd.EndToEndUId;
      this.sessionStorage.endToEndType = selectedEndToEnd.EndToEndType;
      this.sessionStorage.endToEndRefCode = selectedEndToEnd.EndToEndRefCode;
      this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
      this.sessionStorage.roleTypeCode = selectedEndToEnd.RoleTypeCode;
      this.sessionStorage.roleType = selectedEndToEnd.RoleType;
      this.sessionStorage.restrictedInstanceIndicator = selectedEndToEnd.RestrictedInstanceIndicator;
      //this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode == 'V' ? selectedEndToEnd.RoleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : selectedEndToEnd.RoleAccessCode);
      this.sessionStorage.roleAccessCode = selectedEndToEnd.RoleAccessCode;
      this.sessionStorage.endToEndName = selectedEndToEnd.EndToEndName;

      this.clientName = this.sessionStorage.clientName = this.scopeSelector.SelectedClient.Name;
      this.sessionStorage.clientWMSId = 0;
      this.sessionStorage.clientWMSUId = "";
      
      this.sessionStorage.cmsName = "";
      this.sessionStorage.tmsName = "";
      this.sessionStorage.workstreamId = 0;
      this.sessionStorage.workstreamUId = "";
      this.sessionStorage.workstreamName = "";
      this.sessionStorage.deployRegion = selectedEndToEnd.Instance;
      this.sessionStorage.IsProfileCompleted = null;

    }
  }
  SetAsDefault() {
    if (this.currentSelectedCMS) {
      var userPreferenceDetails = [
        {
          EnterpriseId: this.sessionStorage.enterpriseId,
          PreferenceId: enumUserPreference.DefaultEndToEndPreference,
          PreferenceValue: this.currentSelectedEndToEnd.EndToEndId
        },
        {
          EnterpriseId: this.sessionStorage.enterpriseId,
          PreferenceId: enumUserPreference.DefaultCMSPreference,
          PreferenceValue: this.currentSelectedCMS.Id
        }
      ]
      this.SaveUserPreference(userPreferenceDetails);
    }
    else if (this.currentSelectedEndToEnd) {
      var userPreferenceDetails = [
        {
          EnterpriseId: this.sessionStorage.enterpriseId,
          PreferenceId: enumUserPreference.DefaultEndToEndPreference,
          PreferenceValue: this.currentSelectedEndToEnd.EndToEndId
        }
      ]
      this.SaveUserPreference(userPreferenceDetails);
    }
    else {
      return false;
    }
  }
  SaveUserPreference(userPreferenceDetail) {
    this.userPreferenceService.saveUserPreference(userPreferenceDetail).subscribe((response) => {
      this.Apply();
    });
  }
  FilterContracts() {
    if (this.SearchText && this.SearchText.length >= 3) {
      this.ShowLoader = true;
      this.userService.getFilteredScopeDetails(this.SearchText, 0, 0).subscribe((response: any) => {
        this.filteredScopeDetails = response;
        this.ShowLoader = false;
        this.currentSelectedEndToEnd = null;
        this.currentSelectedCMS = null;
        this.currentSelectedWorkstream = null;
        if (response && response.length <= 0) {
          if (!this.SearchText || this.SearchText.length == 0) {
            this.IsZeroSearchResult = false;
          }
          else {
            this.IsZeroSearchResult = true;
          }

        }
        else {
          this.IsZeroSearchResult = false;
        }
      }, function (error) {
        this.IsZeroSearchResult = true;
      });
    }
    else if (this.SearchText == null || this.SearchText.length < 3) {
      this.IsZeroSearchResult = false;
      this.SetCurrentSelectedItem();

    }
  }

  SearchKeyUp() {
    if (!this.SearchText || this.SearchText.length == 0) {
      this.IsZeroSearchResult = false;
      this.SetCurrentSelectedItem();
    }

  }

  changed(event) {
  
   this.modelChanged.next();
  }
  GetHierarchy(selectedItem: any, event?: any) {
    if (event != null) {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode !== 13) { // code for ENTER key
        return;
      }
    }
    var endToEndUID = '';
    var clientUID = '';
    if (selectedItem.EntityType == 'Client') {
      clientUID = selectedItem.UID;
      endToEndUID = '';
    }
    else {
      endToEndUID = selectedItem.UID;
      clientUID = '';
    }
    this.userService.getClientHierarchyByUID(this.sessionStorage.enterpriseId, endToEndUID, null, null, clientUID).subscribe((response) => {
      this.SearchText = null;
      this.scopeSelector = response;
      this.FilterContractOpportunityInitiatives();
      this.ScrollTop();
    });
  }





  get endToEndName() {
    return this.sessionStorage.endToEndName;
  }
  get cmsName() {
    return this.sessionStorage.cmsName;
  }

  get workstreamName() {
    return this.sessionStorage.workstreamName;
  }
  get tmsName() {
    return this.sessionStorage.tmsName;
  }



  ScopeSelectorAndURLNavigrationLogging() {
    this.applicationLoggingService.applicationUsageLogging(enumModuleAction.ScopeSelector, null, null).subscribe();
  }

}
