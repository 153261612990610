import { Component } from '@angular/core'
import { SessionStorage } from '../../core/context/sessionstorage';
import { TileService } from '../../core/services/tile.service';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
import * as d3 from 'd3';
import * as D3 from 'd3-hexbin';
import { common } from 'src/app/core/d3/common';

declare var window: any;
@Component({
  selector: 'reporting-component',
  templateUrl: './reporting.tile.component.html'
})
export class ReportingTileComponent {
  reviewReportMetricsTile: any;
  SubmissionType: number;
  redCount: number;
  greenCount: number;
  amberCount: number;
  complianceStatus: any;
  margin = {
    top: 50, right: 20, bottom: 20, left: 50,
  };
  containerHeight = 200;
  containerWidth = 224;
  selector = 'bubble-report-metrics';
  svg: any;
  MapColumns = 1;
  MapRows = 3;
  data: any;
  count: Array<any>;
  colors = ['#FF806B', '#A3DFA5', '#FFD985'];
  radius: any = [20, 25, 30]; 
  chart: any;
  radScale: any;

  width: number = 214;

  height: number = 86;

  minRadius = 20;

  maxRadius = 32;
  tooltip: any;

  ngOnInit() {   
    this.getProjectMetricsDetails();
    this.getComplianceStatus();
    setTimeout(() => {
     
      window.TooltipFunc();
    }, 0);
  }
  constructor(private tileService: TileService, private sessionStorage: SessionStorage) { }
  countArray: Array<any>;
  getProjectMetricsDetails() {
    this.count = [];
    this.countArray = [];
    var dateSplit = this.sessionStorage.currentPeriod.split('-');
    this.tileService.getProjectMetrics(dateSplit[0], dateSplit[1], dateSplit[2], this.sessionStorage.endToEndUId ? this.sessionStorage.endToEndUId : null,
      this.sessionStorage.clientWMSUId ? this.sessionStorage.clientWMSUId : null, this.sessionStorage.workstreamUId ? this.sessionStorage.workstreamUId : null,
      this.sessionStorage.teamUId ? this.sessionStorage.teamUId : null, this.sessionStorage.selectedSubmissionType).subscribe((response: any) => {
        
        this.reviewReportMetricsTile = response;
        this.SubmissionType = this.sessionStorage.selectedSubmissionType;
        this.redCount = parseInt(response.RedMetricCount || 0);
        this.greenCount = parseInt(response.GreenMetricCount || 0);
        this.amberCount = parseInt(response.AmberMetricCount || 0);        
        this.count.push({ 'name': 'red', 'value': this.redCount, 'label': 'Issue(s) requiring management attention', status: '#FFD985' },
          { 'name': 'green', 'value': this.greenCount, 'label': 'No material concerns that require management attention', status: '#FF806B' },
          { 'name': 'amber', 'value': this.amberCount, 'label': 'Risks requiring mitigating actions to prevent issues', status: '#A3DFA5' });
        this.count = this.count.sort((a, b) => a.value < b.value ? -1 : a.value > b.value ? 1 : 0);       

        if (this.count[0].value > 0 || this.count[1].value > 0 || this.count[2].value > 0) {
          this.renderSVG();
          this.renderTooltip();
          this.renderCircles();
        }
        else {         
          d3.select("#bubble-report-metrics").html('<div style="height: 100px"> No data to display</div>');
        }
        setTimeout(() => {
          window.TooltipFunc();
        }, 0);
      });
  }
  getComplianceStatus() {
    this.tileService.getComplianceStatus(this.sessionStorage.endToEndUId ? this.sessionStorage.endToEndUId : null, this.sessionStorage.clientWMSUId ? this.sessionStorage.clientWMSUId : null,
      this.sessionStorage.workstreamUId ? this.sessionStorage.workstreamUId : null, this.sessionStorage.teamUId ? this.sessionStorage.teamUId : null, this.sessionStorage.selectedSubmissionType).subscribe((response: any) => {
       
this.complianceStatus = response;
        this.complianceStatus.DueDate = dayjs(this.complianceStatus.DueDate, "DD-MMM-YYYY hh:mm A").isValid() ?
          dayjs.utc(this.complianceStatus.DueDate, "DD-MMM-YYYY hh:mm A").local().format('DD-MMM-YYYY hh:mm A') : this.complianceStatus.DueDate;
        setTimeout(() => {
          window.TooltipFunc();
        }, 0);
      });
  }

  renderSVG() {
    const elem = document.getElementsByClassName(this.selector)[0] as HTMLElement;
    if (elem) {
      elem.innerHTML = '';
      this.width = elem.clientWidth;
      this.height = elem.clientHeight;
      this.svg = common.getSVG(this.selector, this.width, this.height);
      this.chart = this.svg
        .append('g')
        .attr('class', 'chart-group');
    }
  }

  renderTooltip() {
    const tooltipNode = document.querySelector(`.${this.selector} + .tooltip`);
    this.tooltip = d3.select(tooltipNode);
  }
  // sort the data Array in ascending order
  getValueArray() {
    this.data.sort((a: any, b: any) => a.percentage - b.percentage);
  }
 
  renderCircles() {
    const valLimits: any = d3.extent(this.count, (d: any) => d.value);
    this.radScale = d3.scaleLinear()
      .domain(valLimits)
      .range([this.minRadius, this.maxRadius]);
    const padding = 2;
    let xDisplacement = 0;
    const yDisplacement = this.count.reduce((acc: number, curr: any, index: number) => Math.max(acc, (index % 2 === 0 ? this.radScale(curr.value) : -1)), -1);
    const circleGroup = this.chart
      .append('g')
      .attr('class', 'circle-group')
      .attr('transform', `translate(${0},${this.maxRadius})`);
    circleGroup.selectAll('g.node')
      .data(this.count)
      .enter()
      .append('g')
      .attr('class', 'node')
      .each((d: any, i: number, nodes: any) => {
        const currentRadius = this.radScale(d.value);
        const xPos = xDisplacement + currentRadius;
        const yPos = i % 2 ? currentRadius * 0.67 : 0;
        d3.select(nodes[i]).append('circle')
          .attr('cx', xPos)
          .attr('cy', yPos)
          .attr('r', currentRadius)
          //.attr('fill', d.status);
          .attr('fill', (d: any, i: number) => {
            if (d.name == 'red') return '#FF806B';
            if (d.name == 'green') return '#A3DFA5';
            if (d.name == 'amber') return '#FFD985';
          });
       
        d3.select(nodes[i])
          .append('text')
          .attr('x', xPos)
          .attr('y', yPos)
          .attr('dy', '0.33em')
          .attr('font-size', 16)
          .attr('font-weight', 400)
          .attr('fill', '#000')
          .attr('font-family', 'Graphik')
          .attr('text-anchor', 'middle')
          .text(d.value);
        d3.select(nodes[i])
          .on('mouseenter', () => {
            const groupSize = (circleGroup.node() as SVGGElement)?.getBBox()?.width || 0;
            this.tooltip
              .html(d.label);
            const tooltipWidth = (this.tooltip.node() as HTMLElement)?.clientWidth || 0;
            console.log(this.width);
            this.tooltip
              .style('left', `${xPos + ((this.width - groupSize) / 2) - (tooltipWidth / 2)}px`)
              .style('top', `${yPos + yDisplacement + currentRadius - 5}px`)
              .style('font-size','10px')
              .style('visibility', 'visible');
          })
          .on('mouseleave', () => {
            this.tooltip
              .style('visibility', 'hidden');
          });
        xDisplacement += (currentRadius * 2) + padding;
      });
    const groupSize = (circleGroup.node() as SVGGElement)?.getBBox()?.width || 0;
    circleGroup
      .attr('transform', `translate(${(this.width - groupSize) / 2},${yDisplacement})`);
  }
}
