import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { TileService } from '../../core/services/tile.service';
import * as d3 from 'd3';
import { common } from '../../core/d3/common'

@Component({
  selector: 'app-automation-journey',
  templateUrl: './automation.journey.component.html'
})

export class AutomationJourneyComponent {
  SavingsGraphData: any = [];
  CurrentPlanTextStyle: any;
  CurrentPlanValueStyle: any;
  OpportunityGraphData: any = [];
  greenMetrics: number = 0
  amberMetrics: number = 0
  redMetrics: number = 0
  TotalOpportunitiesCount: number = 0;

  svg: any;

  data: any;

  chart: any;

  viewData: any = [];

  totalValuePlaned: any;

  currentActual: any;

  currentPlan: any;

  seletor = 'automation-journey-bar-chart-section';

  margin = {
    top: 0, bottom: 0, left: 60, right: 0,
  };

  width = 0;

  height = 0;

  YSCALE_BW = 16;

  rx = this.YSCALE_BW / 2;

  ry = this.YSCALE_BW / 2;
  constructor(private tileService: TileService) { }
  @Input()
  Tile: any;
  @Output() Notifications = new EventEmitter();
  ngOnInit() {
    this.data = [];
    this.tileService.getAutomationJourney().subscribe((response: any) => {
     
      this.SavingsGraphData = response.SavingsGraphData;
      

      if (this.SavingsGraphData != null && this.SavingsGraphData.length > 0) {

        this.CurrentPlanTextStyle = this.SavingsGraphData[0].CurrentPlanPercentage < 80 ? -20 : -52;
        this.CurrentPlanValueStyle = this.SavingsGraphData[0].CurrentPlanPercentage > 80 ? -52 : '';
        this.totalValuePlaned = this.SavingsGraphData[0].Total != '$0k' ? this.SavingsGraphData[0].Total : '0';
        this.currentActual = this.SavingsGraphData[0].CurrentActual != '$0k' ? this.SavingsGraphData[0].CurrentActual : '';
        this.currentPlan = this.SavingsGraphData[0].CurrentPlan != '$0k' ? this.SavingsGraphData[0].CurrentPlan : '';
      }
      else {
        this.CurrentPlanTextStyle = '';
        this.CurrentPlanValueStyle = '';
        this.totalValuePlaned = '0';
        this.currentActual = '';
        this.currentPlan = '';
      }
      this.TotalOpportunitiesCount = response.TotalOpportunitiesCount;
      this.OpportunityGraphData = response.OpportunityGraphData;

      if (this.OpportunityGraphData != null) {
        this.greenMetrics = this.OpportunityGraphData.filter(x => x.Name == 'Completed').map(x => x.Y)[0];
        this.amberMetrics = this.OpportunityGraphData.filter(x => x.Name == 'In Progress').map(x => x.Y)[0];
        this.redMetrics = this.OpportunityGraphData.filter(x => x.Name == 'Backlog').map(x => x.Y)[0];
        this.data.push(
          { "label": "Completed", "value": this.greenMetrics == undefined ? 40 : this.greenMetrics, "color": '#80cf80' },
          { "label": "Backlog", "value": this.redMetrics == undefined ? 40 : this.redMetrics, "color": '#d48a9a'},
          { "label": "In Progress", "value": this.amberMetrics == undefined ? 20 : this.amberMetrics, "color": '#ffd980' });
      }

      if ((this.redMetrics == 0 || this.redMetrics == undefined) && (this.greenMetrics == 0 || this.greenMetrics == undefined) && (this.amberMetrics == 0 || this.amberMetrics == undefined)) {
        d3.select(".automation-journey-bar-chart-container").html('<div style = "height: 100px">No data to display</div>');

      }
      if (this.redMetrics > 0 || this.amberMetrics > 0 || this.greenMetrics > 0) {
        d3.select('#app-svg').remove();
        this.data = this.OpportunityGraphData.filter(x => x.Name == "Completed" || x.Name == "Backlog" || x.Name == "In Progress" );
        this.viewData = this.data;
        this.OpportunityGraphData.forEach(x => {
          if (x.Name == "Completed")
            x.Color = '#A3DFA5';
          else if (x.Name == "In Progress")
            x.Color = '#FFD985';
          else if (x.Name == "Backlog")
            x.Color = '#FF7E6B';
        });
        this.drawGraph();
       
      }
    });
    
  }
 
  renderSVG(): void {
    const elem = document.getElementsByClassName(this.seletor)[0] as HTMLElement;
    elem.innerHTML = '';
    this.svg = common.getSVG(this.seletor, elem.offsetWidth, elem.offsetHeight);
    this.width = elem.offsetWidth - this.margin.left - this.margin.right;
    this.height = elem.offsetHeight - this.margin.top - this.margin.bottom;
  }

  xScale(): any {
    return d3.scaleLinear()
      .domain([0, 100]).nice()
      .range([0, this.width]);
  }

  yScale(): any {
    return d3.scaleBand()
      .range([this.height, 0])
      .domain(this.viewData.map((s: any) => s.Name))
      .padding(0.6);
  }

  renderChart(): any {
    this.chart = this.svg.append('g')
      .attr('class', 'svgstyle')
      .attr('transform', `translate(${[this.margin.left, this.margin.top]})`);
    return this.chart;
  }

  renderXAxis(): any {
    this.chart.append('g')
      .attr('transform', `translate(0 , ${this.height})`)
      .call(d3.axisBottom(this.xScale()).tickSize(0))
      .call((d: any) => {
        d.selectAll('path')
          .style('transform', 'translateY(-1px)')
          .attr('stroke', '#E4E4E4')
          .attr('stroke-width', '2px');
        d.selectAll('text').remove();
      });
  }

  renderYAxis(): any {
    this.chart.append('g')
      .attr('class', 'axis y-axis')
      .style('font-family', 'Graphik')
      .call(d3.axisLeft(this.yScale()).tickSize(0))
      .call((d: any) => {
        d.selectAll('path')
          .style('transform', 'translate(-1px, 1px)')
          .attr('stroke', '#E4E4E4')
          .attr('stroke-width', '2px');
        d.selectAll('text')
          .attr('x', `-${this.margin.left}`)
          .attr('font-size', '10px')
          .attr('font-family', 'Graphik')
          .attr('font-weight', 400)
          .attr('fill','#515151')
          .attr('text-anchor', 'start');
      });
  }

  renderBar(): any {
    const bars = this.chart.selectAll()
      .data(this.viewData)
      .join('g');

    bars
      .append('path')
      .attr('class', 'colorbar')
      .attr('d', (d: any) => {
        const maxVal: any = (d3.max(this.OpportunityGraphData, (dataIterator: any) => dataIterator.Y));
        const rcWidth = this.xScale()(d.Y / maxVal) * 80;
        if (d.Y > 0) {
          return `
            M${0},${this.yScale()(d.Name) + this.yScale().bandwidth() / 2 - this.ry}
            h${rcWidth}
            a${this.rx},${this.ry} 0 0 1 ${this.rx},${this.ry}
            v 0.5
            a${this.rx},${this.ry} 0 0 1 ${-this.rx},${this.ry}
            h${-rcWidth}Z
          `;
        }
        return null;
      })
      .attr('fill', (d: any) => {
        let clr = 'none';
        if (d.Y > 0) {
          clr = d.Color;
        }
        return clr;
      });

    bars
      .append('text')
      .attr('x', (g: any) => {
        const maxVal: any = (d3.max(this.OpportunityGraphData, (dataIterator: any) => dataIterator.Y));
        return (this.xScale()(g.Y / maxVal) * 80) + 20;
      })
      .attr('y', (g: any) => this.yScale()(g.Name) + this.yScale().bandwidth() / 2 + 4)
      .attr('font-size', '10px')
      .attr('font-family', 'Graphik')
      .attr('font-weight', 500)
      .attr('text-anchor', 'middle')
      .attr('fill', '#515151')
      .text((d: any) => `${d.Y}`);
  }

  drawGraph(): any {
    this.renderSVG();
    this.renderChart();
    this.renderXAxis();
    this.renderYAxis();
    this.renderBar();
  }

  //@HostListener('window:resize', ['$event'])
  //onResize(): any {
  //  common.clearSVG();
  //  this.drawGraph();
  //}
 
  }

  
