import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesService {

  currentMessage: Observable<any>;
  private messageSource = new Subject<any>();
  
  constructor() {
    this.currentMessage = this.messageSource.asObservable();
  }

  broadcast(message: string) {
    this.messageSource.next(message)
  }

}
