<div id="contract-details" class="c-flyout c-contract__flyout -open" tabindex="-1" aria-hidden="true" aria-labelledby="flyoutTitle" style="visibility:visible;">
  <div class="c-flyout__header">
    <h6 id="flyoutTitle">Contract Details</h6>
    <button type="button" class="-dismiss-flyout" aria-label="Close" alt="Close" (click)="close()"></button>
  </div>
  <div class="c-flyout__body -customScroll">
    <div class="c-form-tab">
      <div class="c-form-tab__wrapper">
        <div class="c-scrollable-tabs">
          <div class="c-scrollable-tabs__container" role="tablist">
            <button class="c-form-tab__item" role="tab" aria-controls="active-contract" (click)="ShowAutoSourcedLabel(true, true, 'activeContracts')" aria-selected="true">{{financialActiveContracts.length}} Financially Active Contracts</button>
            <button class="c-form-tab__item" role="tab" aria-controls="inactive-contract" (click)="ShowAutoSourcedLabel(false, true, 'inActiveContracts')" aria-selected="false">{{financialInactiveContracts.length}} Financially Inactive Contracts</button>
          </div>
        </div>
      </div>
    </div>
    <div role="tabpanel" id="active-contract" class="-show">
      <div id="accordionParent-contracts">
        <div class="c-accordion" *ngFor="let selectedContractInfo of financialActiveContracts; let i = index;">
          <button type="button" id="{{'activebutton-' + i}}" class="c-collapse" aria-expanded="false" [attr.aria-controls]="'active-contract-' + i" (click)="getSelectedContractDetails(selectedContractInfo, i, 'activeContracts')">{{selectedContractInfo.ContractCode}} : <span>{{selectedContractInfo.Name}}</span></button>
          <div id="{{'active-contract-' + i}}" class="-collapse" data-parentid="accordionParent-contracts">
            <div class="c-accordion__container">
              <div class="c-accordion__form">
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Contract Name :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.Contract"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Client :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.ClientName"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Company :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.Company"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Customer :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.Customer"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Market :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.ManagementMarket"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Market Unit :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.ManagementMarketUnit"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Primary Client Group :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.ManagementClientGroupName"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Industry Segment :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.IndustrySegment"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">MMS Opportunity ID(S) :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.Opportunities"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">RDE :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.RBE"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Pricing Structure :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.PricingSTructure"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Country/Location :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.Country"></div>
                </div>
                <div class="c-accordion__input-form" *ngIf="contractInfo.AvanadeChannelId != 0">
                  <div class="c-accordion__label">Avanade Geographic Area :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.AvanadeGeographicArea"></div>
                </div>
                <div class="c-accordion__input-form" *ngIf="contractInfo.AvanadeChannelId != 0">
                  <div class="c-accordion__label">Avanade Geographic Unit :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.AvanadeGeographicUnit"></div>
                </div>
                <div class="c-accordion__input-form" *ngIf="contractInfo.AvanadeChannelId != 0">
                  <div class="c-accordion__label">Avanade Country/Location :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.AvanadeCountry"></div>
                </div>
              </div>
              <h5>Service Group</h5>
              <div class="c-table-responsive c-lead__table">
                <p id="ServiceGroupNetwork" class="screenReaderOnly">This table lists service groups involved along with dominant service group.</p>
                <table class="c-table">
                  <thead>
                    <tr>      
                      <th scope="col">Dominant</th>
                      <th scope="col">Service Group</th>
                      <th scope="col">Allocation (%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let servicegroup of contractInfo.ServiceGroups; trackBy : trackByServiceGroupId;">
                      <td>
                        <div class="c-radio">
                          <input type="radio" aria-labelledby="Dominant" class="c-radio__input"
                                 [disabled]="true" name="{{'serviceGroup_'+servicegroup.ServiceGroupId}}"
                                 [ngValue]="servicegroup.ServiceGroupId" [(ngModel)]="servicegroup.DominantServiceGroupId"
                                 (change)="dominantChange(servicegroup)" [value]="servicegroup.ServiceGroupId" />
                          <i class="c-radio__checked"></i>
                        </div>
                      </td>  
                      <td [innerHtml]="servicegroup.ServiceGroupName"></td>
                      <td [innerHtml]="servicegroup.Allocation"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div role="tabpanel" id="inactive-contract">
      <div id="accordionParent-contracts">
        <div class="c-accordion " *ngFor="let selectedContractInfo of financialInactiveContracts; let j = index;">
          <button type="button" id="{{'inactivebutton-' + j}}" class="c-collapse" aria-expanded="false" [attr.aria-controls]="'inactive-contract-' + j" (click)="getSelectedContractDetails(selectedContractInfo, j, 'inActiveContracts')">{{selectedContractInfo.ContractCode}} : <span>{{selectedContractInfo.Name}}</span></button>
          <div id="{{'inactive-contract-' + j}}" class="-collapse" data-parentid="accordionParent-contracts">
            <div class="c-accordion__container">
              <div class="c-accordion__form">
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Contract Name :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.Contract"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Client :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.ClientName"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Company :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.Company"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Customer :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.Customer"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Market :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.ManagementMarket"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Market Unit :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.ManagementMarketUnit"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Primary Client Group :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.ManagementClientGroupName"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Industry Segment :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.IndustrySegment"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">MMS Opportunity ID(S) :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.Opportunities"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">RDE :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.RBE"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Pricing Structure :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.PricingSTructure"></div>
                </div>
                <div class="c-accordion__input-form">
                  <div class="c-accordion__label">Country/Location :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.Country"></div>
                </div>
                <div class="c-accordion__input-form" *ngIf="contractInfo.AvanadeChannelId != 0">
                  <div class="c-accordion__label">Avanade Geographic Area :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.AvanadeGeographicArea"></div>
                </div>
                <div class="c-accordion__input-form" *ngIf="contractInfo.AvanadeChannelId != 0">
                  <div class="c-accordion__label">Avanade Geographic Unit :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.AvanadeGeographicUnit"></div>
                </div>
                <div class="c-accordion__input-form" *ngIf="contractInfo.AvanadeChannelId != 0">
                  <div class="c-accordion__label">Avanade Country/Location :</div>
                  <div class="c-accordion__input" [innerHtml]="contractInfo.AvanadeCountry"></div>
                </div>
              </div>
              <h5>Service Group</h5>
              <div class="c-table-responsive c-lead__table">
                <p id="ServiceGroupNetwork" class="screenReaderOnly">This table lists service groups involved along with dominant service group.</p>
                <table class="c-table">
                  <thead>
                    <tr> 
                      <th scope="col">Dominant</th>
                      <th scope="col">Service Group</th>
                      <th scope="col">Allocation (%)</th> 
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let servicegroup of contractInfo.ServiceGroups; trackBy : trackByServiceGroupId;">
                      <td>
                        <div class="c-radio">
                          <input type="radio" aria-labelledby="Dominant" class="c-radio__input"
                                 [disabled]="true" name="{{'serviceGroup_'+servicegroup.ServiceGroupId}}"
                                 [ngValue]="servicegroup.ServiceGroupId" [(ngModel)]="servicegroup.DominantServiceGroupId"
                                 (change)="dominantChange(servicegroup)" [value]="servicegroup.ServiceGroupId" />
                          <i class="c-radio__checked"></i>
                        </div>
                      </td>
                      <td [innerHtml]="servicegroup.ServiceGroupName"></td>
                      <td [innerHtml]="servicegroup.Allocation"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="c-flyout__footer" *ngIf="autoSourcedLabelShow">
    <div class="c-flyout-update">
      <div class="c-flyout-update__input">
        <label>Auto-Sourced Contract Details Last From:</label>
        <span class="-input">CMW</span>
      </div>
      <div class="c-flyout-update__input">
        <label>Auto-Sourced Contract Details Last On:</label>
        <span class="-input">{{contractInfo.AutosourcedLastModifiedOn |date:'dd MMM yyyy h:mm a'}} (GMT)</span>
      </div>
    </div>
  </div>
</div>
<div class="c-flyout__overlay"></div>
