<div *ngIf="tiles && tiles?.length > 1" class="c-index">
  <div class="app-container">
    <div class="c-index-container">
      <div class="c-index__content">
        <!--<app-it-spend-benchmark></app-it-spend-benchmark>
  <app-honeycomb-chart></app-honeycomb-chart>
  <app-pie-chart></app-pie-chart>-->
        <div class="-header">Welcome back!</div>
        <p>
          Manage your Delivery Profile, enable integration with myWizard execution solutions and report Delivery status for Portfolio Governance.
        </p>
        <div>
          <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" class="o-primary-button" (click)="manage()">
            <!---->
            Manage
          </a>
          <!--Primary Button ends from here-->
        </div>
      </div>
      <div class="c-index__carousel">
        <div class="c-index__carousel-container c-manage__carousel-container">
          <div class="c-carousel__wrap ">
            <div class="c-carousel c-index-carousel">
              <div class="c-carousel__scroll-wrap c-carousel__indicators-container">
                <div class="c-carousel__scroll-block">
                  <div class="c-carousel__scroller">
                    <div class="c-carousel__scroller-area">
                      <div class="c-carousel__scroller-card">
                        <div class="c-carousel__scroller-content">
                          <ul *ngFor="let tile of ICtiles">
                            <li *ngIf="!tile.IsDisplay">
                              <div class="c-carousel__content-title">{{tile.Name}}</div>
                              <p>{{tile.Description}}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="c-carousel__indicators-wrap">
                      <a class="c-carousel__indicator" href="javascript:;" (click)="ChangeTile(0)" role="button" [ngClass]="(ICtiles != undefined && !ICtiles[0].IsDisplay) ? '-active' : ''"></a>
                      <a class="c-carousel__indicator" href="javascript:;" (click)="ChangeTile(1)" role="button" [ngClass]="(ICtiles != undefined && !ICtiles[1].IsDisplay) ? '-active' : ''"></a>
                      <a class="c-carousel__indicator" href="javascript:;" (click)="ChangeTile(2)" role="button" [ngClass]="(ICtiles != undefined && !ICtiles[2].IsDisplay) ? '-active' : ''"></a>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
<div class="c-index" *ngIf="tiles && tiles?.length <= 1">
  <div class="app-container">
    <div class="c-index-container">
      <div class="c-index__content">
        <div class="-header">Hi, there.</div>
        <p>
          Please setup the delivery profile by clicking on “Initiate & Configure”, to enable the platform.
        </p>
        <div>
          <!--Primary Button starts from here-->
          <a href="javascript:void(0)" role="button" class="o-primary-button" (click)="initiateAndConfigure()">
            <!---->
            Initiate & Configure
          </a>
          <!--Primary Button ends from here-->
        </div>
      </div>
      <div class="c-index__carousel">
        <div class="c-index__carousel-container">
          <div class="c-carousel__wrap ">
            <div class="c-carousel c-index-carousel">
              <div class="c-carousel__scroll-wrap c-carousel__indicators-container">
                <div class="c-carousel__scroll-block">
                  <div class="c-carousel__scroller">
                    <div class="c-carousel__scroller-area">
                      <div class="c-carousel__scroller-card">
                        <div class="c-carousel__scroller-content">
                          <ul *ngFor="let tile of ICtiles">
                            <li *ngIf="!tile.IsDisplay">
                              <div class="c-carousel__content-title">{{tile.Name}}</div>
                              <p>{{tile.Description}}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="c-carousel__indicators-wrap">
                      <a class="c-carousel__indicator" href="javascript:;" (click)="ChangeTile(0)" role="button" [ngClass]="(ICtiles != undefined && !ICtiles[0].IsDisplay) ? '-active' : ''"></a>
                      <a class="c-carousel__indicator" href="javascript:;" (click)="ChangeTile(1)" role="button" [ngClass]="(ICtiles != undefined && !ICtiles[1].IsDisplay) ? '-active' : ''"></a>
                      <a class="c-carousel__indicator" href="javascript:;" (click)="ChangeTile(2)" role="button" [ngClass]="(ICtiles != undefined && !ICtiles[2].IsDisplay) ? '-active' : ''"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
