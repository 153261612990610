import { Injectable, Output, EventEmitter } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../services/http.service'
import { SessionStorage } from '../context/sessionstorage';
@Injectable()
export class UserPreferenceService {

  constructor(private http: HttpClient, private sessionStorage: SessionStorage, private httpService: HttpService) { }

  saveUserPreference(userPreferenceDetail: any) {
    return this.httpService.post('/api/SaveUserPreference/', userPreferenceDetail);
  }
  IsShowSlide(userPreferenceDetail: any) {
    return this.httpService.post('/api/IsShowSlide/', userPreferenceDetail);
  }
  SaveSlideShow(userPreferenceDetail: any) {
    return this.httpService.post('/api/SaveSlideShow/', userPreferenceDetail);
  }

  getUserPreference(enterpriseId: number, endToEndId: number, PageName: any) {
    return this.httpService.get('/api/User/' + enterpriseId + '/Preferences?endToEndId=' + this.sessionStorage.endToEndId + '&pageName=' + PageName)
  }

  SaveUserPreference(userPreference: any) {
    return this.httpService.post('/api/User/Preference', userPreference);
  }

  getUserPreferenceByPreferenceId(enterpriseId: string, preferenceId: number) {
    return this.httpService.get('/api/User/' + enterpriseId + '/Preferences/' + preferenceId);
  }

  ClearReleaseNotePreference() {
    return this.httpService.post('/api/users/clearreleasenotepreference/' + this.sessionStorage.enterpriseId, null);
  }

  UserPreferenceUpdate(enterpriseId: number, preferenceId: number, preferencevalue: any) {
    return this.httpService.post('/api/users/userpreferenceupdate/preferenceid/' + preferenceId + '/?preferencevalue=' + preferencevalue + '&enterpriseId=' + enterpriseId + '&modifiedbyuser=' + this.sessionStorage.enterpriseId, null);
  }

}
