<!--<div class="modal-header">
  <button type="button" id="Close" class="close" data-dismiss="modal" (keypress)="ResetSelectionCMS(null,$event);" (click)="ResetSelectionCMS(null, null);" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <h4 class="modal-title">{{PopupTitle}}</h4>
</div>
<div class="modal-body">
  <div class="cms-tms-tab cms-tms-tab-tmspopup">
    <ul id="tabs" *ngIf="cmsHierarchy.TypeId == 1">
      <li id="tabCMSDetails" [ngClass]="{'active':isActive, 'tab-highlight-error': activeBasic}">
        <a href="{{'#' + popupCMSDetailsTab}}" (click)="UpdateClass()" data-toggle="tab" title="Basic Info related to CMS/Workstream" aria-label="Basic Info related to CMS/Workstream">Basic Info</a>
      </li>
      <li id="tabPhoenixMapping" *ngIf="cmsHierarchy.IsPhoenixMappingEnabled && IsAdmin" [ngClass]="{'tab-highlight-error': activePhoenix}">
        <a href="{{'#' + phoenixMappingContent}}" data-toggle="tab" aria-label="AI Fusion Mapping">Phoenix Mapping</a>
      </li>
    </ul>
    <ul *ngIf="cmsHierarchy.TypeId != 1">
      <li class="active"><label class="btn btn-primary basicinfo-button" tabindex="0" title="Basic Info related to CMS/Workstream" aria-label="Basic Info related to CMS/Workstream">Basic Info</label></li>
    </ul>
  </div>
  <div *ngIf="cmsHierarchy.TypeId == 1">
    <form id="currentSelectedCMSform" name="currentSelectedCMSform" #currentSelectedCMSform="ngForm" novalidate (ngSubmit)="Save(currentSelectedCMSform.form.valid, currentSelectedCMSform);">
      <div *ngIf="cmsHierarchy.TypeId == 1" class="tab-content">
        <div class="tab-pane active" id="{{popupCMSDetailsTab}}">
          <p>Required fields are marked with an asterisk(<span class="required">*</span>)</p>
          <div class="row">
            <div class="col-xs-12 col-md-6 form-horizontal">
              <div class="form-group">
                <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="CMSStructureNameinput"><strong>Structure Name &nbsp;<span class="required">*</span></strong></label>
                <div class="col-xs-12 col-sm-7 col-lg-8">
                  <input type="text" class="form-control" id="CMSStructureNameinput" name="CMSName" #CMSName="ngModel" [disabled]="IsCMSClosed || !IsEditable" maxlength="50" [ngClass]="{'has-error':currentSelectedCMSform.submitted && CMSName.invalid}" [(ngModel)]="currentSelectedCMS.CMSName" [required]="true" />
                  <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage validation-message-width" [ngClass]="{'is-error':currentSelectedCMSform.submitted && CMSName.invalid}">Please enter Client Management Structure Name</div>
                </div>
              </div>
              <div class="form-group bottom-margin-cms">
                <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="CMSDeliveryLead"><strong>Delivery Lead</strong></label>
                <div class="col-xs-12 col-sm-7 col-lg-8">

                  <auto-complete id="cmsDeliveryLead" #cmsDeliveryLead="ngModel" name="cmsDeliveryLead" [disabled]="IsCMSClosed || !IsEditable"
                                 hiddenFieldID="hiddenCMSDeliveryLead" ariaLabelledby="CMSDeliveryLead"
                                 [selected]="currentSelectedCMS.DeliveryLead"
                                 [(ngModel)]="currentSelectedCMS.DeliveryLead">
                  </auto-complete>

                </div>
              </div>
              <div class="form-group bottom-margin-cms">
                <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="CMSStatusdropdown"><strong>Status</strong></label>
                <div class="col-xs-12 col-sm-7 col-lg-8">
                  <select id="CMSStatusdropdown" name="CMSStatusdropdown" #CMSStatusdropdown="ngModel" class="form-control" [disabled]="IsStatusDropdownDisable || !IsEditable" [(ngModel)]="SelectedLifeCycle.Id">
                    <option *ngFor="let item of CmsLifeCycle; trackBy : trackById;" [value]="item.Id">{{item.Name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div *ngIf="EndToEndType == 'C' && IsShowMultiContractLink" class="col-xs-12 col-md-6 form-horizontal">
              <div class="form-group">
                <div class="col-xs-12 col-sm-8 col-lg-4">
                  <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="Contracttxt"><strong>Associated Contracts</strong></label>
                  <br />
                </div>
                <div class="col-xs-12 col-sm-7 col-lg-8">
                  <textarea name="Contract" id="Contracttxt" [disabled]="true" class="form-control" rows="2">{{ currentSelectedCMS.Contracts | commaseparated}}</textarea>
                </div>
              </div>
              <div class="form-group">
                <div class="col-xs-12 col-sm-8 col-lg-12 manage-contract-link">
                  <a class="col-xs-12 col-sm-5 col-lg-8 link link-font-size " [ngClass]="{'team-disabled' : IsCMSClosed}" *ngIf="IsEditable" id="Manage_Info_cms"
                     (click)="showManagePopup(currentSelectedCMS.Contracts,'Contract ID','cms',false)" href="javascript:void(0);"
                     aria-label="Add/Delete Contracts">Manage Contract IDs</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-lg-9">
              <div class="form-group cms-client-wrap">
                <label for="ClientStakeholderandtheirroletable"><strong>Client Stakeholder(s) and their role</strong></label>
                <p class="screenReaderOnly" id="ClientStakeholder(s)">List of Client Stakeholders and their roles</p>
                <div class="table-responsive">
                  <table id="ClientStakeholderandtheirroletable" class="table table-striped table-bordered" aria-describedby="ClientStakeholder(s)">
                    <tbody>
                      <tr *ngFor="let item of currentSelectedCMS.CMSClientStakeholders | slice:0:20; let ind = index; trackBy: customTrackBy;">
                        <td><input aria-label="Name" name="enterpriseId_{{ind}}" [disabled]="IsCMSClosed || !IsEditable" type="text" class="form-control" placeholder="Name" [(ngModel)]="currentSelectedCMS.CMSClientStakeholders[ind].Name" /></td>
                        <td><input aria-label="Email Id" name="emailId_{{ind}}" [disabled]="IsCMSClosed || !IsEditable" type="text" class="form-control" placeholder="Email Id" [(ngModel)]="currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress" /></td>
                        <td><input aria-label="Role" name="role_{{ind}}" [disabled]="IsCMSClosed || !IsEditable" type="text" class="form-control" placeholder="Role" [(ngModel)]="currentSelectedCMS.CMSClientStakeholders[ind].RoleName" /></td>
                        <td class="text-center"><button aria-label="DeleteClientStakeholder" [disabled]="IsCMSClosed || !IsEditable" class="btnHeader" (click)="DeleteClientStakeholder(ind)">&times;</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button type="button" [disabled]="IsCMSClosed || !IsEditable" class="btn btn-primary" (click)="AddClientStakeholder()" aria-label="Add Client Stakeholders and their role">Add</button>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane" id="{{phoenixMappingContent}}" *ngIf="cmsHierarchy.IsPhoenixMappingEnabled">
          <div class="row">
            <div class="col-xs-12 col-md-12 col-lg-8">
              <div class="form-group">
                <label class="col-xs-12 col-sm-5 col-lg-4 control-label"><strong>Delivery Construct UID</strong></label>
                <div class="col-xs-12 col-sm-7 col-lg-8">
                  <input type="text" aria-labelledby="DeliveryConstructId" name="deliveryConstructId" #deliveryConstructId="ngModel" [required]="currentSelectedCMS.PhoenixMapping.PhoenixClientId != '' && currentSelectedCMS.PhoenixMapping.PhoenixClientId != null"
                         value="{{currentSelectedCMS.PhoenixMapping.DeliveryConstructId}}" [disabled]="!IsAdmin || !cmsHierarchy.IsPhoenixMappingEnabled || cmsHierarchy.IsGovernanceAutoIntegration" maxlength="50" class="form-control" [(ngModel)]="currentSelectedCMS.PhoenixMapping.DeliveryConstructId" autocomplete="off"
                         [ngClass]="{'has-error':currentSelectedCMSform.submitted && deliveryConstructId.invalid}" />
                  <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage" [ngClass]="{'is-error':currentSelectedCMSform.submitted && deliveryConstructId.invalid}">Please enter Delivery Construct UID</div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-xs-12 col-sm-5 col-lg-4 control-label"><strong>Client UID</strong></label>
                <div class="col-xs-12 col-sm-7 col-lg-8">
                  <input type="text" aria-labelledby="PhoenixClientId" name="phoenixClientId" #phoenixClientId="ngModel" [required]="currentSelectedCMS.PhoenixMapping.DeliveryConstructId!= '' && currentSelectedCMS.PhoenixMapping.DeliveryConstructId!= null"
                         value="{{currentSelectedCMS.PhoenixMapping.PhoenixClientId}}" [disabled]="!IsAdmin || !cmsHierarchy.IsPhoenixMappingEnabled || cmsHierarchy.IsGovernanceAutoIntegration" maxlength="50" class="form-control" [(ngModel)]="currentSelectedCMS.PhoenixMapping.PhoenixClientId" autocomplete="off"
                         [ngClass]="{'has-error':currentSelectedCMSform.submitted && phoenixClientId.invalid}" />
                  <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage" [ngClass]="{'is-error':currentSelectedCMSform.submitted && phoenixClientId.invalid}">Please enter Client UID</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div *ngIf="IsCMSEdit" class="pull-right text-left">
            <div *ngIf="cmsHierarchy.TypeId == 1">
              <span class="small bold">Last Updated By: </span><span>{{currentSelectedCMS.ModifiedByUser}}</span>
              <br />
              <span class="small bold">Last Updated On: </span><span>{{currentSelectedCMS.ModifiedOn |date:'dd MMM yyyy h:mm a'}} (GMT)</span>
            </div>
            <br />
          </div>
          <div style="clear:both;">
            <button type="submit" value="submit" [disabled]="IsCMSClosed || !IsEditable" *ngIf="cmsHierarchy.TypeId == 1" class="btn btn-primary" aria-label="Save">Save</button>
            <button [disabled]="IsCMSClosed || !IsEditable" type="button" class="btn btn-primary" (click)="ResetSelectionCMS(currentSelectedCMSform, null);" data-dismiss="modal" aria-label="Cancel">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>-->
<!--//AgileWorkstream-->
<!--<div *ngIf="cmsHierarchy.TypeId == 2" class="tab-content">-->
<!-- Basic Info -->
<!--<p>Required fields are marked with an asterisk(<span class="required">*</span>)</p>
<form name="currentSelectedWSform" #currentSelectedWSform="ngForm" novalidate (ngSubmit)="Save(currentSelectedWSform.form.valid,currentSelectedWSform)">
  <div class="tab-pane active" id="basicInfoWaterAgileTab">
    <div class="row">
      <div class="col-xs-12 col-md-10 form-horizontal">
        <div class="form-group">
          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="WorkstreamTypedropdown"><strong>Workstream Type &nbsp;<span class="required">*</span></strong></label>
          <div class="col-xs-12 col-sm-7 col-lg-8">
            <input type="text" id="WorkstreamTypedropdown" *ngIf="!cmsHierarchy.IsTypeStatusShow" name="WorkstreamTypeName" class="form-control" [(ngModel)]="currentSelectedWS.WorkstreamTypeName" [disabled]="true" aria-labelledby="WorkstreamTypelbl" />
            <search-dropdown id="WorkstreamTypeSearchDropdown" name="WorkstreamTypeSearchDropdown" #WorkstreamTypeSearchDropdown="ngModel" [options]="cmsHierarchy.WorkstreamTypes" [isShow]="cmsHierarchy.IsTypeStatusShow" [errormessage]="'Please select Workstream Type'" [formname]="currentSelectedWSform"
                             [selected]="currentSelectedWS.WorkstreamTypeId" (callback)="SetTimePicker(true)" [selectText]="'Select'" [required]="true"
                             [disableDropdown]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [labelInfo]="'WorkstreamType'"
                             [(ngModel)]="currentSelectedWS.WorkstreamTypeId">
            </search-dropdown>
            <div *ngIf="cmsHierarchy.WorkstreamTypes.length <= 0 && cmsHierarchy.IsTypeStatusShow" class="cms-tms-help" title="Please select a valid delivery type in Delivery Profile page for Workstream Creation"><span class="glyphicon glyphicon-info-sign"></span></div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="WSNameinput"><strong>Name &nbsp;<span class="required">*</span></strong></label>
          <div class="col-xs-12 col-sm-7 col-lg-8">
            <input type="text" class="form-control" id="WSNameinput" name="WorkStreamName" #WorkStreamName="ngModel"
                   [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || currentSelectedWS.PhoenixCreationIndicator"
                   maxlength="75" [(ngModel)]="currentSelectedWS.WorkStreamName" [required]="true"
                   [ngClass]="{'has-error': currentSelectedWSform.submitted && WorkStreamName.invalid}" />
            <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage" [ngClass]="{'is-error':currentSelectedWSform.submitted && WorkStreamName.invalid}">Please enter Workstream Name</div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="WSDeliveryLead"><strong>Workstream Lead Name</strong></label>
          <div class="col-xs-12 col-sm-7 col-lg-8">


            <auto-complete id="wsDeliveryLead" #wsDeliveryLead="ngModel" name="wsDeliveryLead" [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')"
                           hiddenFieldID="hiddenWSDeliveryLead" ariaLabelledby="WSDeliveryLead"
                           [selected]="currentSelectedWS.DeliveryLead"
                           [(ngModel)]="currentSelectedWS.DeliveryLead">
            </auto-complete>

            <div class="form-errormessage"></div>
          </div>
        </div>

        <div class="form-group" style="height: 59px;">
          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="WSStatusdropdown"><strong>Status &nbsp;<span class="required">*</span></strong></label>
          <div class="col-xs-12 col-sm-7 col-lg-8">
            <select id="WSStatusdropdown" name="WorkstreamStatusId" #WorkstreamStatusId="ngModel" class="form-control" [required]="true" [(ngModel)]="currentSelectedWS.WorkstreamStatusId" aria-labelledby="WSStatus"
                    [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.WorkstreamTypeId==null|| currentSelectedWS.WorkstreamTypeId==undefined || currentSelectedWS.WorkstreamTypeId < 1) || currentSelectedWS.PhoenixCreationIndicator"
                    [ngClass]="{'has-error' : currentSelectedWSform.submitted && WorkstreamStatusId.invalid}">
              <option role="option" value="">--Select--</option>
              <option *ngFor="let item of cmsHierarchy.TempWorkstreamStatus; trackBy : trackById;" [value]="item.Id">{{item.Name}}</option>
            </select>
            <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage" *ngIf="WorkstreamStatusId.invalid" [ngClass]="{'is-error':currentSelectedWSform.submitted}">Please enter Workstream Status</div>
          </div>
        </div>

        <div class="form-group">
          <span class="col-xs-12 col-sm-5 col-lg-4 control-label"><strong>Start &amp; End Date &nbsp;<span class="required">*</span></strong></span>
          <div class="col-xs-12 col-sm-3 col-lg-3">

            <input name="startdateform" #startdate="ngModel" (onSelectDate)="currentSelectedWS.StartDate = $event.value;" type="text"
                   data-showmessage="Open a Calendar Start date"
                   data-described="Calendar_Control_startdate"
                   [required]="true"
                   [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || currentSelectedWS.PhoenixCreationIndicator"
                   [ngClass]="{'has-error':(!DateValidation.IsValidStartDate) || (!DateValidation.IsValidStartDateEndDate) || (currentSelectedWSform.submitted && (startdate.invalid||dayjs(startdate, 'DD MMM YYYY', true).isValid() || !DateValidation.IsValidStartDate || !DateValidation.IsValidStartDateEndDate))}"
                   maxlength="11" datepicker class="form-control date" id="startdate" [(ngModel)]="currentSelectedWS.StartDate" autocomplete="off" />



            <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage validation-message"
                 [ngClass]="{'is-error':(!DateValidation.IsValidStartDate) || (!DateValidation.IsValidStartDateEndDate)|| (currentSelectedWSform.submitted && (startdate.invalid || dayjs(startdate, 'DD MMM YYYY', true).isValid() || !DateValidation.IsValidStartDate || !DateValidation.IsValidStartDateEndDate))}">
              <span *ngIf="startdate.invalid">Please enter Start Date</span>
              <span *ngIf="startdate && dayjs(startdate, 'DD MMM YYYY', true).isValid()">Please enter the date in "dd mmm yyyy" format</span>
              <span *ngIf="!DateValidation.IsValidStartDate && !startdate.invalid && !dayjs(startdate, 'DD MMM YYYY', true).isValid() && DateValidation.IsValidStartDateEndDate">
                Workstream Start Date is lesser than E2E Start Date ({{EndToEndStartDate}})
              </span>
              <span *ngIf="!DateValidation.IsValidStartDateEndDate && !startdate.invalid && !dayjs(startdate, 'DD MMM YYYY', true).isValid() && DateValidation.IsValidStartDate">
                Workstream Start Date is greater than E2E End Date ({{EndToEndEndDate}})
              </span>
            </div>
            <p id="Calendar_Control_startdate" class="screenReaderOnly">
              1.Ctrl + UP/DOWN/LEFT/RIGHT keys for move to previous / next day<br />
              2.PAGE UP/PAGE DOWN keys for move to previous / next month<br />
              3.SHIFT + CTRL + PAGE UP/PAGE DOWN keys for move to previous/ next year
            </p>
          </div>
          <div class="col-xs-12 col-sm-1 col-lg-2 text-center" style="padding-top:7px;">To</div>
          <div class="col-xs-12 col-sm-3 col-lg-3">
            <input name="enddate" #enddate="ngModel" aria-labelledby="WSStartandEnddatelbl" type="text"
                   (onSelectDate)="currentSelectedWS.EndDate = $event.value;"
                   data-showmessage="Open a Calendar End date" data-described="Calendar_Control_enddate"
                   [required]="true"
                   [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || currentSelectedWS.PhoenixCreationIndicator"
                   [ngClass]="{'has-error': (!DateValidation.IsValidEndDate) || (!DateValidation.IsValidEndDateStartDate) || (currentSelectedWSform.submitted && (enddate.invalid || dayjs(enddate, 'DD MMM YYYY', true).isValid() || !DateValidation.IsValidEndDate || !DateValidation.IsValidEndDateStartDate))}"
                   maxlength="11" datepicker class="form-control date" id="enddate" [(ngModel)]="currentSelectedWS.EndDate" autocomplete="off" />

            <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage validation-message" [ngClass]="{'is-error': (!DateValidation.IsValidEndDate)||(!DateValidation.IsValidEndDateStartDate) || (currentSelectedWSform.submitted && (enddate.invalid || dayjs(enddate, 'DD MMM YYYY', true).isValid() || !DateValidation.IsValidEndDate || !DateValidation.IsValidEndDateStartDate))}">
              <span *ngIf="enddate.invalid">Please enter End Date</span>
              <span *ngIf="enddate && dayjs(enddate, 'DD MMM YYYY', true).isValid()">Please enter the date in "dd mmm yyyy" format</span>
              <span *ngIf="!DateValidation.IsValidEndDate && !enddate.invalid && !dayjs(enddate, 'DD MMM YYYY', true).isValid() && DateValidation.IsValidEndDateStartDate">
                Workstream End Date is greater than E2E End Date ({{EndToEndEndDate}})
              </span>
              <span *ngIf="!DateValidation.IsValidEndDateStartDate && !enddate.invalid && !dayjs(enddate, 'DD MMM YYYY', true).isValid() && DateValidation.IsValidEndDate">
                Workstream End Date is lesser than E2E Start Date ({{EndToEndStartDate}})
              </span>
            </div>
            <p id="Calendar_Control_enddate" class="screenReaderOnly">
              1.Ctrl + UP/DOWN/LEFT/RIGHT keys for move to previous / next day<br />
              2.PAGE UP/PAGE DOWN keys for move to previous / next month<br />
              3.SHIFT + CTRL + PAGE UP/PAGE DOWN keys for move to previous/ next year
            </p>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" style="line-height:15px;" for="InitialBudgetAtCompletionlbl"><span class="bold">Initial Budget at Completion (BAC)</span></label>
          <div class="col-xs-12 col-sm-7 col-lg-8">
            <input [disabled]="SFEDisable || IsNotValidDeliverytype || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" type="text" id="InitialBudgetAtCompletionlbl" maxlength="16" name="WorkStreamBAC" (paste)="OnPaste($event, 'BAC')" (keypress)="KeyPressNumeric($event)" class="form-control" [(ngModel)]="currentSelectedWS.BAC" value="" />
            <div class="form-errormessage"></div>
          </div>
        </div>

        <div class="form-group" *ngIf="currentSelectedWS.WorkstreamTypeId == 1">
          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="WSCutoffforPlannedValuedropdown"><strong>Cut off for Planned Value</strong></label>
          <div class="col-xs-12 col-sm-7 col-lg-8">
            <search-dropdown [options]="cmsHierarchy.CutOffForPlannedValue" id="WSCutoffforPlannedValuedropdown" name="WSCutoffforPlannedValuedropdown" #WSCutoffforPlannedValuedropdown="ngModel" [isShow]="true"
                             [errormessage]="'Please select Cut off for Planned Value'" [formname]="currentSelectedWSform"
                             [disableDropdown]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')"
                             [selected]="currentSelectedWS.CutOffForPlannedValue" [labelInfo]="'WSCutoffforPlannedValue'" [selectText]="'Select'" [(ngModel)]="currentSelectedWS.CutOffForPlannedValue">
            </search-dropdown>


          </div>
        </div>

        <div class="form-group" *ngIf="currentSelectedWS.WorkstreamTypeId == 1 || currentSelectedWS.WorkstreamTypeId == 6" style="padding-bottom:15px">
          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="WSIsCodeReviewDropDown"><strong>Is Code Review in-scope</strong></label>
          <div class="col-xs-8 col-sm-4 col-lg-2">
            <select name="WSIsCodeReviewDropDown" class="form-control" [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [(ngModel)]="currentSelectedWS.IsCodeReview" [selected]="IsCodeReviewUpdated()">
              <option role="option" value="null">--Select--</option>
              <option role="option" value='Y'>Yes</option>
              <option role="option" value='N'>No</option>
            </select>
          </div>

          <label class="col-xs-8 col-sm-4 col-lg-2" for="WSCodeReviewOutofScopeReason"><strong>If No, specify a reason</strong></label>
          <div class="col-xs-12 col-sm-5 col-lg-4">
            <select name="WSCodeReviewOutofScopeReason" [(ngModel)]="currentSelectedWS.CodeReviewOutofScopeReasonId" [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || currentSelectedWS.IsCodeReview != 'N'" class="form-control">
              <option role="option" value="null">--Select--</option>
              <option *ngFor="let item of cmsHierarchy.WorkStreamCodeReviewOutofScopeReason; trackBy : trackById;" [value]="item.Id">{{item.Name}}</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="WSTechnologiesdropdown"><strong>Primary Technologies</strong></label>
          <div class="col-xs-12 col-sm-7 col-lg-8">
            <multi-selectdropdown name="wsTechnology" #wsTechnology="ngModel" id="WSTechnologiesdropdown" [options]="cmsHierarchy.Technology"
                                  [disableDropdown]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')"
                                  [selected]="currentSelectedWS.SelectedTechnology" [selectedparent]="cmsHierarchy.SelectedEndToEndTechnologies"
                                  [(ngModel)]="currentSelectedWS.SelectedTechnology" [ishierarchy]="false" [ariaLabelledby]="'WSTechnologies'"></multi-selectdropdown>
            <div class="form-errormessage"></div>
          </div>
        </div>

        <div class="form-group" *ngIf="cmsHierarchy.WorkstreamCMSListLevel1.length > 0">

          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="WSAssociateWorkstreamtoselectedCMSdropdown" style="line-height:15px;"><strong>Associate Workstream to selected CMS</strong></label>

          <div *ngIf="!IsDisableCMSWSDropdown()" class="col-xs-12 col-sm-7 col-lg-8">
            <multi-selectdropdown name="workstreamCMS" #workstreamCMS="ngModel" id="WSAssociateWorkstreamtoselectedCMSdropdown" [options]="cmsHierarchy.WorkstreamCMSListLevel1"
                                  [selected]="currentSelectedWS.SelectedWorkstreamCms" [ishierarchy]="true"
                                  [(ngModel)]="currentSelectedWS.SelectedWorkstreamCms" [ariaLabelledby]="'WSAssociateWorkstreamtoselectedCMS'"></multi-selectdropdown>
            <div class="form-errormessage"></div>
          </div>
          <div *ngIf="IsDisableCMSWSDropdown()" class="col-xs-12 col-sm-7 col-lg-8">
            <div class="btn-group dropdown-multiselect" style="width: 100%;">
              <span id="info_multiselectdropdown" class="screenReaderOnly">Use the tab key to navigate through the items in the list. </span>
              <button name="{{'multiselect_btn_' + 'WSAssociateWorkstreamtoselectedCMS' }}" [disabled]="true" title="{{currentSelectedWS.CMSName}}" type="button"
                      class="multiselect dropdown-toggle btn btn-default multiselect-text-align cms-disabled"
                      style="width: 100%; overflow: hidden; text-overflow: ellipsis; text-align: left;" aria-expanded="true" aria-haspopup="true" aria-labelledby="WSAssociateWorkstreamtoselectedCMS" aria-describedby="info_multiselectdropdown">
                <span *ngIf="currentSelectedWS.CMSName != null" class="multiselect-text">{{currentSelectedWS.CMSName}}</span>
                <span *ngIf="currentSelectedWS.CMSName == null" class="multiselect-text">None Selected</span>
                <strong class="caret "></strong>
              </button>
            </div>
            <div class="form-errormessage"></div>
          </div>
          <a href="javascript:void(0);" id="mseInfoIcon" class="glyphicon glyphicon-info-sign pull-right" style="font-size:14px;color:black;text-decoration-line: none;top: 25%;width: 1%;position:absolute;" aria-label="Metrics submission for a Workstream, would reflect in the currently mapped CMS until the current PED (including those disassociated from current CMS). Any new mapping related to client structure will not display prior metrics - previously submitted metrics however will be shown at E2E." title="Metrics submission for a Workstream, would reflect in the currently mapped CMS until the current PED (including those disassociated from current CMS). Any new mapping related to client structure will not display prior metrics - previously submitted metrics however will be shown at E2E."></a>
        </div>
        <div *ngIf="cmsHierarchy.IsFactory && !SFEDisable" class="form-group">
          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="WSExternalE2Edropdown"><strong>External E2E (C/O/II)</strong></label>
          <div class="col-xs-12 col-sm-7 col-lg-8" disabled="SFEdropdownDisable || !IsEditable">
            <search-dropdown id="WSExternalE2Edropdown" name="WSExternalE2Edropdown" #WSExternalE2Edropdown="ngModel" [options]="cmsHierarchy.SFEEndtoEnds" [disableDropdown]="SFEdropdownDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [isShow]="true"
                             [formname]="currentSelectedWSform" [selected]="currentSelectedWS.ClientEndToEndId" [selectText]="'Select'"
                             [labelInfo]="'WSExternalE2E'" [(ngModel)]="currentSelectedWS.ClientEndToEndId">
            </search-dropdown>
          </div>
        </div>

        <div *ngIf="SFEDisable" class="form-group">
          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="WSFactoryNameinput"><strong>Factory Name</strong></label>
          <div class="col-xs-12 col-sm-7 col-lg-8">
            <input id="WSFactoryNameinput" name="factoryName" type="text" class="form-control" [(ngModel)]="currentSelectedWS.FactoryName" [disabled]="true" />
          </div>
        </div>
      </div>
      <div *ngIf="EndToEndType == 'C' && IsShowMultiContractLink" class="col-xs-12 col-md-10 form-horizontal">
        <div class="form-group">
          <label class="col-xs-12 col-sm-5 col-lg-4 control-label" for="Contracttext">
            <strong>Associated Contracts</strong>
            <br />
            <a class="col-xs-12 col-sm-5 col-lg-8 link link-font-size" [ngClass]="{'team-disabled' : currentSelectedWS.WorkstreamStatusName == 'Closed'}" style="padding-left:0px;" *ngIf="IsEditable" id="Manage_Info_ws"
               (click)="showManagePopup(currentSelectedWS.Contracts,'Contract ID','ws', false)" href="javascript:void(0);"
               aria-label="Add/Delete Contracts">Manage Contract IDs</a>
          </label>
          <div class="col-xs-12 col-sm-7 col-lg-8">
            <textarea name="Contract" id="Contracttext" [disabled]="true"
                      class="form-control" rows="2">{{ currentSelectedWS.Contracts | commaseparated}}</textarea>
          </div>
        </div>
      </div>
    </div>
    <br><hr *ngIf="currentSelectedWS.WorkstreamTypeId == 0">-->
<!-- Replaced id from 2(AM) to 0. It is a request to hide this section//-->
<!--<div class="row" *ngIf="currentSelectedWS.WorkstreamTypeId == 0">-->
<!-- Replaced id from 2(AM) to 0. It is a request to hide this section//-->
<!--<div class="col-xs-12">
        <div class="form-group">
          <label for="ApplicationAvailabilitywindow"><strong>Application Availability Window(s)</strong></label>
          <p id="ApplicationAvailability" class="screenReaderOnly"></p>
          <div class="table-responsive table-app">
            <table class="table table-striped table-bordered" id="ApplicationAvailabilitywindow" aria-describedby="ApplicationAvailability">
              <thead>
                <tr>
                  <th scope="col" id="Monday" class="td-width-14" colspan="2">Monday</th>
                  <th scope="col" id="Tuesday" class="td-width-14" colspan="2">Tuesday</th>
                  <th scope="col" id="Wednesday" class="td-width-14" colspan="2">Wednesday</th>
                  <th scope="col" id="Thursday" class="td-width-14" colspan="2">Thursday</th>
                  <th scope="col" id="Friday" class="td-width-14" colspan="2">Friday</th>
                  <th scope="col" id="Saturday" class="td-width-14" colspan="2">Saturday</th>
                  <th scope="col" id="Sunday" class="td-width-14" colspan="2">Sunday</th>
                </tr>
              </thead>
              <tbody>
                <tr>

                  <td headers="Monday">
                    <input type="checkbox" id="currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].WeekdayId" name="active0" value="currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].WeekdayId"
                           [disabled]="!IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [(ngModel)]="active0" (change)="dayChange(currentSelectedWS.SelectedWorkstreamAvailablityWindow[0],active0)"
                           [checked]="currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].WeekdayId)  > -1" aria-labelledby="Monday" />
                  </td>
                  <td headers="Monday">
                    <div class="form-group has-feedback">
                      <input name="active0_startTime" [disabled]="!IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].WeekdayId) > -1 || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed'))" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].WeekdayId) > -1) && (currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].StartTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].StartTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].StartTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Monday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                    <span>to</span>
                    <div class="form-group has-feedback">
                      <input name="active0_endTime" [disabled]="!IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].WeekdayId) > -1 || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed'))" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].WeekdayId) > -1) && (currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].EndTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].EndTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[0].EndTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Monday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                  </td>

                  <td headers="Tuesday">
                    <input type="checkbox" id="currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].WeekdayId" name="active1" value="currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].WeekdayId"
                           [disabled]="!IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [(ngModel)]="active1" (change)="dayChange(currentSelectedWS.SelectedWorkstreamAvailablityWindow[1],active1)"
                           [checked]="currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].WeekdayId) > -1" aria-labelledby="Tuesday" />
                  </td>
                  <td headers="Tuesday">
                    <div class="form-group has-feedback">
                      <input name="active1_startTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].WeekdayId) > -1) && (currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].StartTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].StartTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].StartTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Tuesday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                    <span>to</span>
                    <div class="form-group has-feedback">
                      <input name="active1_endTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].WeekdayId) > -1) &&(currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].EndTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].EndTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[1].EndTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Tuesday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                  </td>
                  <td headers="Wednesday">
                    <input type="checkbox" id="currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].WeekdayId" name="active2" value="currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].WeekdayId"
                           [disabled]="!IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [(ngModel)]="active2" (change)="dayChange(currentSelectedWS.SelectedWorkstreamAvailablityWindow[2],active2)"
                           [checked]="currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].WeekdayId) > -1" aria-labelledby="Wednesday" />
                  </td>
                  <td headers="Wednesday">
                    <div class="form-group has-feedback">
                      <input name="active2_startTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].WeekdayId) > -1) && (currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].StartTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].StartTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].StartTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Wednesday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                    <span>to</span>
                    <div class="form-group has-feedback">
                      <input name="active2_endTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].WeekdayId) > -1) &&(currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].EndTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].EndTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[2].EndTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Wednesday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                  </td>
                  <td headers="Thursday">
                    <input type="checkbox" id="currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].WeekdayId" name="active3" value="currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].WeekdayId"
                           [disabled]="!IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [(ngModel)]="active3" (change)="dayChange(currentSelectedWS.SelectedWorkstreamAvailablityWindow[3],active3)"
                           [checked]="currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].WeekdayId) > -1" aria-labelledby="Thursday" />
                  </td>
                  <td headers="Thursday">
                    <div class="form-group has-feedback">
                      <input name="active3_startTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].WeekdayId) > -1) && (currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].StartTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].StartTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].StartTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Thursday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                    <span>to</span>
                    <div class="form-group has-feedback">
                      <input name="active3_endTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].WeekdayId) > -1) &&(currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].EndTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].EndTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[3].EndTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Thursday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                  </td>
                  <td headers="Friday">
                    <input type="checkbox" id="currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].WeekdayId" name="active4" value="currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].WeekdayId"
                           [disabled]="!IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [(ngModel)]="active4" (change)="dayChange(currentSelectedWS.SelectedWorkstreamAvailablityWindow[4],active4)"
                           [checked]="currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].WeekdayId) > -1" aria-labelledby="Friday" />
                  </td>
                  <td headers="Friday">
                    <div class="form-group has-feedback">
                      <input name="active4_startTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].WeekdayId) > -1) && (currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].StartTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].StartTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].StartTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Friday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                    <span>to</span>
                    <div class="form-group has-feedback">
                      <input name="active4_endTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].WeekdayId) > -1) &&(currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].EndTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].EndTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[4].EndTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Friday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                  </td>
                  <td headers="Saturday">
                    <input type="checkbox" id="currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].WeekdayId" name="active5" value="currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].WeekdayId"
                           [disabled]="!IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [(ngModel)]="active5" (change)="dayChange(currentSelectedWS.SelectedWorkstreamAvailablityWindow[5],active5)"
                           [checked]="currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].WeekdayId) > -1" aria-labelledby="Saturday" />
                  </td>
                  <td headers="Saturday">
                    <div class="form-group has-feedback">
                      <input name="active5_startTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].WeekdayId) > -1) && (currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].StartTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].StartTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].StartTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Saturday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                    <span>to</span>
                    <div class="form-group has-feedback">
                      <input name="active5_endTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].WeekdayId) > -1) &&(currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].EndTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].EndTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[5].EndTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Saturday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                  </td>
                  <td headers="Sunday">
                    <input type="checkbox" id="currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].WeekdayId" name="active6" value="currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].WeekdayId"
                           [disabled]="!IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [(ngModel)]="active6" (change)="dayChange(currentSelectedWS.SelectedWorkstreamAvailablityWindow[6],active6)"
                           [checked]="currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].WeekdayId) > -1" aria-labelledby="Sunday" />
                  </td>
                  <td headers="Sunday">
                    <div class="form-group has-feedback">
                      <input name="active6_startTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].WeekdayId) > -1) && (currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].StartTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].StartTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].StartTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Sunday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                    <span>to</span>
                    <div class="form-group has-feedback">
                      <input name="active6_endTime" [disabled]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable || !(currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].WeekdayId) > -1)" [ngClass]="{'has-error': ((currentSelectedWS.SelectedDays.indexOf(currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].WeekdayId) > -1) &&(currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].EndTime == null || currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].EndTime == ''))}" type="text" class="form-control time" [(ngModel)]="currentSelectedWS.SelectedWorkstreamAvailablityWindow[6].EndTime" onkeypress="return false" onkeydown="return false" aria-labelledby="Sunday" />
                      <span class="glyphicon glyphicon-time form-control-feedback"></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col-xs-12 col-sm-12 col-lg-12 text-left ">
        <div [ngClass]="{'visibility-hidden' : !currentSelectedWS.IsDraftMode}" class="col-xs-12 col-sm-7 col-lg-7 alert-message">
          Draft :Please validate the workstream details auto-populated by the system, as generated in the external tools. Kindly modify as required and click on 'Save' to confirm the workstream creation.
        </div>
        <div *ngIf="cmsHierarchy.TypeId == 2 && IsCMSEdit" class="col-xs-12 col-sm-7 col-lg-5">
          <span class="small bold">Last Updated By: </span><span>{{currentSelectedWS.ModifiedByUser}}</span>
          <br />
          <span class="small bold">Last Updated On: </span><span>{{currentSelectedWS.ModifiedOn |date:'dd MMM yyyy h:mm a'}} (GMT)</span>
          <br />
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-lg-12 padding-top-10">

        <div class="col-xs-12 col-sm-7 col-lg-9">
          <button *ngIf="Action == 'Edit' && !SFEDisable && IsEditable && !currentSelectedWS.PhoenixCreationIndicator && cmsHierarchy.TypeId == 2 && currentSelectedWS.WorkstreamTypeId != 12" type="button" class="btn btn-primary delete-workstream-button status-error"
                  (click)="DeleteWS();" aria-label="Delete Workstream">
            <span class="glyphicon glyphicon-trash icon"></span>
            Delete Workstream
          </button>
        </div>
        <div class="col-xs-12 col-sm-7 col-lg-3">
          <button [disabled]="SFEDisable || !IsEditable" type="submit" value="submit" *ngIf="cmsHierarchy.TypeId == 2" class="btn btn-primary" aria-label="Save">Save</button>
          <button [disabled]="IsCMSClosed || SFEDisable || !IsEditable" type="button" class="btn btn-primary" (click)="ResetSelectionCMS(currentSelectedWSform, null);" data-dismiss="modal" aria-label="Cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</form>-->
<!-- /Basic Info -->
<!--</div>
</div>-->



<form id="currentSelectedCMSform" name="currentSelectedCMSform" #currentSelectedCMSform="ngForm" novalidate (ngSubmit)="Save(currentSelectedCMSform.form.valid, currentSelectedCMSform);" *ngIf="cmsHierarchy.TypeId == 1">
  <div id="client-workspace" class="c-flyout c-contract__flyout -open" tabindex="-1" aria-labelledby="flyoutTitle" aria-modal="true" role="dialog" style="visibility:visible;">
    <div class="c-flyout__header -client-header">
      <div class="c-flyout-head__title">
        <div class="-flyoutTitle" id="flyoutTitle">{{PopupTitle}}</div>
        <span>Add your details below</span>
      </div>
      <button type="button" class="-dismiss-flyout" aria-label="Close" alt="Close" (click)="ResetSelectionCMS(currentSelectedCMSform, null);"></button>
    </div>
    <div class="c-flyout__body -customScroll">
      <div class="c-form-tab">
        <div class="c-form-tab__wrapper">
          <div class="c-scrollable-tabs">
            <span class="c-scrollable-tabs__arrow icon-chevron-left -left -hidden"></span>
            <div class="c-scrollable-tabs__container-wrapper">
              <div class="c-scrollable-tabs__container" role="tablist" style="position: relative; transform: translateX(0px);">
                <button class="c-form-tab__item" role="tab" [attr.aria-controls]="popupCMSDetailsTab"  aria-selected="true"  [ngClass]="{'active':isActive, 'tab-highlight-error': activeBasic}" aria-label="Basic Info related to CMS/Workstream">Basic Info </button>
                <button class="c-form-tab__item" role="tab" [attr.aria-controls]="phoenixMappingContent" aria-selected="false" *ngIf="cmsHierarchy.IsPhoenixMappingEnabled && IsAdmin" aria-label="AI Fusion Mapping" [ngClass]="{'tab-highlight-error': activePhoenix}">Phoenix Mapping</button>
              </div>
            </div>
            <span class="c-scrollable-tabs__arrow icon-chevron-right -right -hidden"></span>
          </div>
        </div>
      </div>

      <div role="tabpanel" id="{{popupCMSDetailsTab}}" class="-show">
        <div class="c-myWizard-form__input">
          <div class="c-myWizard-form__input-area">
            <div class="o-input   -required   ">
              <label for="CMSStructureNameinput">Structure Name</label>
              <input type="text"
                     class="o-input__input"
                     placeholder="Structure Name"
                     id="CMSStructureNameinput" name="CMSName" #CMSName="ngModel"
                     [disabled]="IsCMSClosed || !IsEditable" maxlength="50"
                     [ngClass]="{'has-error':currentSelectedCMSform.submitted && CMSName.invalid}"
                     [(ngModel)]="currentSelectedCMS.CMSName" [required]="true" />
              <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status" [ngClass]="{'is-error':currentSelectedCMSform.submitted && CMSName.invalid}">Please enter Structure Name</p>
            </div>
          </div>
          <div class="c-myWizard-form__input-area">
            <div class="o-input    ">
              <label for="delivery_lead">Delivery Lead</label>
              <auto-complete id="cmsDeliveryLead"
                             #cmsDeliveryLead="ngModel"
                             name="cmsDeliveryLead"
                             [disabled]="IsCMSClosed || !IsEditable"
                             hiddenFieldID="hiddenCMSDeliveryLead"
                             ariaLabelledby="DeliveryLead"
                             [selected]="currentSelectedCMS.DeliveryLead"
                             [(ngModel)]="currentSelectedCMS.DeliveryLead">
              </auto-complete>
            </div>
          </div>
          <div class="c-myWizard-form__input-area">
            <div class="c-custom-select__wrapper">
              <label for="status">Status</label>
              <search-dropdown id="CMSStatusdropdown"
                               name="CMSStatusdropdown"
                               #CMSStatusdropdown="ngModel"
                               [labelInfo]="'Status'"
                               [options]="CmsLifeCycle"
                               [isShow]="true"
                               [errormessage]="'Please select Status'"
                               [formname]="currentSelectedCMSform"
                               [required]="false"
                               [selected]="SelectedLifeCycle.Id"
                               [disableDropdown]="IsStatusDropdownDisable || !IsEditable"
                               [(ngModel)]="SelectedLifeCycle.Id">
              </search-dropdown>
            </div>
          </div>
          <div class="c-myWizard-form__input-area"  *ngIf="EndToEndType == 'C' && IsShowMultiContractLink">
            <div class="o-input">
              <label for="pricing" id="pricing">
                Associated Contracts <a [ngClass]="{'team-disabled' : IsCMSClosed}" *ngIf="IsEditable" id="Manage_Info_cms"
                                        (click)="showManagePopup(currentSelectedCMS.Contracts,'Contract ID','cms',false)" href="javascript:void(0);"
                                        class="c-myWizard-select-opt"
                                        aria-label="Add/Delete Contracts">Manage Contract IDs</a>
              </label>
              <p aria-labelledby="PricingStructure" id="pricing" name="pricingStructure" class="o-text-area__input -customScroll -textAreaInput">{{ currentSelectedCMS.Contracts | commaseparated}}</p>
            </div>
          </div>
          <!--<div class="c-myWizard-form__input-area" *ngIf="EndToEndType == 'C' && IsShowMultiContractLink">
            <div class="o-input ">
              <label for="structure_name">
                Associated Contracts <a [ngClass]="{'team-disabled' : IsCMSClosed}" *ngIf="IsEditable" id="Manage_Info_cms"
                                        (click)="showManagePopup(currentSelectedCMS.Contracts,'Contract ID','cms',false)" href="javascript:void(0);"
                                        class="c-myWizard-select-opt"
                                        aria-label="Add/Delete Contracts">Manage Contract IDs</a>
              </label>
              <textarea type="text"
                        class="o-input__input"
                        name="Contract" id="Contracttxt" [disabled]="true"
                        [ngValue]="currentSelectedCMS.Contracts | commaseparated" rows="2">{{ currentSelectedCMS.Contracts | commaseparated}}</textarea>
            </div>
          </div>-->
        </div>

        <div class="c-client-role">
          <div>Client Stakeholder & their role</div>
          <a href="javascript:;" aria-label="Client Stakeholders and their role" (click)="AddClientStakeholder()" [disabled]="IsCMSClosed || !IsEditable" *ngIf="!IsCMSClosed && IsEditable"><img src="../assets/toolkit/images/key-contacts/add.svg" alt="Add" /><span>Client Stakeholder</span></a>
        </div>
        <div class="c-table-responsive c-lead__table">
          <table class="c-table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email ID</th>
                <th scope="col">Designation</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of currentSelectedCMS.CMSClientStakeholders | slice:0:20; let ind = index; trackBy: customTrackBy;">
                <td><input aria-label="Name" name="enterpriseId_{{ind}}" [disabled]="IsCMSClosed || !IsEditable" [required]="currentSelectedCMS.CMSClientStakeholders[ind].Name == '' && (currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == '' || currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == null) && currentSelectedCMS.CMSClientStakeholders[ind].RoleName == ''" type="text" class="o-input__input" [ngClass]="{'has-error':(currentSelectedCMSform.submitted  && currentSelectedCMS.CMSClientStakeholders[ind].Name == '' && (currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == '' || currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == null)&& currentSelectedCMS.CMSClientStakeholders[ind].RoleName == '') || (!isClientStakeHolderFieldsFilled && (currentSelectedCMS.CMSClientStakeholders[ind].Name == '' && (currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == '' || currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == null)&& currentSelectedCMS.CMSClientStakeholders[ind].RoleName == ''))}" placeholder="Name" [(ngModel)]="currentSelectedCMS.CMSClientStakeholders[ind].Name" /></td>
                <td><input aria-label="Email Id" name="emailId_{{ind}}" [disabled]="IsCMSClosed || !IsEditable" [required]="currentSelectedCMS.CMSClientStakeholders[ind].Name == '' && (currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == '' || currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == null) && currentSelectedCMS.CMSClientStakeholders[ind].RoleName == ''" type="text" class="o-input__input" [ngClass]="{'has-error':(currentSelectedCMSform.submitted  && currentSelectedCMS.CMSClientStakeholders[ind].Name == '' && (currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == '' || currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == null)&& currentSelectedCMS.CMSClientStakeholders[ind].RoleName == '' ) || (!isClientStakeHolderFieldsFilled && (currentSelectedCMS.CMSClientStakeholders[ind].Name == '' && (currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == '' || currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == null)&& currentSelectedCMS.CMSClientStakeholders[ind].RoleName == ''))}" placeholder="Email Id" [(ngModel)]="currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress" /></td>
                <td><input aria-label="Role" name="role_{{ind}}" [disabled]="IsCMSClosed || !IsEditable" [required]="currentSelectedCMS.CMSClientStakeholders[ind].Name == '' && (currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == '' || currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == null) && currentSelectedCMS.CMSClientStakeholders[ind].RoleName == ''" type="text" class="o-input__input" [ngClass]="{'has-error':(currentSelectedCMSform.submitted && currentSelectedCMS.CMSClientStakeholders[ind].Name == '' && (currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == '' || currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == null)&& currentSelectedCMS.CMSClientStakeholders[ind].RoleName == '' ) || (!isClientStakeHolderFieldsFilled && (currentSelectedCMS.CMSClientStakeholders[ind].Name == '' && (currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == '' || currentSelectedCMS.CMSClientStakeholders[ind].EmailAddress == null)&& currentSelectedCMS.CMSClientStakeholders[ind].RoleName == ''))}" placeholder="Role" [(ngModel)]="currentSelectedCMS.CMSClientStakeholders[ind].RoleName" /></td>
                <td class="text-center">

                  <a href="javascript:;" class="icon-delete -disabled" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" [disabled]="IsCMSClosed || !IsEditable"
                     aria-label="DeleteClientStakeholder" [ngClass]="{'-disabled' : (IsCMSClosed || !IsEditable) }" (click)="DeleteClientStakeholder(ind)"></a>
                  <!--<button aria-label="DeleteClientStakeholder" [disabled]="IsCMSClosed || !IsEditable" class="btnHeader" (click)="DeleteClientStakeholder(ind)">&times;</button>-->

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div role="tabpanel" id="{{phoenixMappingContent}}" *ngIf="cmsHierarchy.IsPhoenixMappingEnabled">
        <div class="c-myWizard-form__input">
          <div class="c-myWizard-form__input-area">
            <div class="o-input">
              <label for="deliveryconstructuid">Delivery Construct UID</label>
              <input type="text" aria-labelledby="DeliveryConstructId" name="deliveryConstructId" #deliveryConstructId="ngModel"
                     [required]="currentSelectedCMS.PhoenixMapping.PhoenixClientId != '' && currentSelectedCMS.PhoenixMapping.PhoenixClientId != null"
                     value="{{currentSelectedCMS.PhoenixMapping.DeliveryConstructId}}"
                     [disabled]="!IsAdmin || !cmsHierarchy.IsPhoenixMappingEnabled || cmsHierarchy.IsGovernanceAutoIntegration"
                     maxlength="50"
                     class="o-input__input" [(ngModel)]="currentSelectedCMS.PhoenixMapping.DeliveryConstructId" autocomplete="off"
                     [ngClass]="{'has-error':currentSelectedCMSform.submitted && deliveryConstructId.invalid}" />

              <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status" [ngClass]="{'is-error':currentSelectedCMSform.submitted && deliveryConstructId.invalid}">Please enter Delivery Construct UID</p>
            </div>
          </div>
          <div class="c-myWizard-form__input-area">
            <div class="o-input">
              <label for="clientuid">Client UID</label>
              <input type="text" aria-labelledby="PhoenixClientId" name="phoenixClientId"
                     #phoenixClientId="ngModel"
                     [required]="currentSelectedCMS.PhoenixMapping.DeliveryConstructId!= '' && currentSelectedCMS.PhoenixMapping.DeliveryConstructId!= null"
                     value="{{currentSelectedCMS.PhoenixMapping.PhoenixClientId}}"
                     [disabled]="!IsAdmin || !cmsHierarchy.IsPhoenixMappingEnabled || cmsHierarchy.IsGovernanceAutoIntegration"
                     maxlength="50"
                     class="o-input__input" [(ngModel)]="currentSelectedCMS.PhoenixMapping.PhoenixClientId" autocomplete="off"
                     [ngClass]="{'has-error':currentSelectedCMSform.submitted && phoenixClientId.invalid}" />
              <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status" [ngClass]="{'is-error':currentSelectedCMSform.submitted && phoenixClientId.invalid}">Please enter Client UID </p>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="c-flyout__footer">
      <div class="-flyout-btn">
        <!--Primary Button starts from here-->
        <button type="submit" role="button" class="o-primary-button  -flyout-close" [ngClass]="{'-disabled': !IsEditable}" [disabled]="IsCMSClosed || !IsEditable" *ngIf="cmsHierarchy.TypeId == 1" aria-label="Save">
          <!---->
          Save
        </button>
        <!--Primary Button ends from here-->
      </div>
      <div class="-updation" *ngIf="cmsHierarchy.TypeId == 1 && IsCMSEdit">
        <span>Last Updated by :</span>&nbsp;{{currentSelectedCMS.ModifiedByUser}}
      </div>
      <div class="-updation" *ngIf="cmsHierarchy.TypeId == 1 && IsCMSEdit">
        <span>Last Updated on :</span>&nbsp;{{currentSelectedCMS.ModifiedOn |date:'dd MMM yyyy h:mm a'}} (GMT)
      </div>
    </div>
  </div>

  <div class="c-flyout__overlay"></div>
</form>

<form *ngIf="cmsHierarchy.TypeId == 2" name="currentSelectedWSform" #currentSelectedWSform="ngForm" novalidate (ngSubmit)="Save(currentSelectedWSform.form.valid,currentSelectedWSform)">
  <div id="client-workspace" class="c-flyout c-contract__flyout -open" tabindex="-1" aria-labelledby="flyoutTitle" aria-modal="true" role="dialog" style="visibility:visible">
    <div class="c-flyout__header -client-header">
      <div class="c-flyout-head__title">
        <div class="-flyoutTitle" id="flyoutTitle">{{PopupTitle}}</div>
        <span>Add your details below</span>
      </div>
      <button type="button" class="-dismiss-flyout" aria-label="Close" alt="Close" (click)="ResetSelectionCMS(currentSelectedWSform, null)"></button>
    </div>
    <div class="c-flyout__body -customScroll">
      <div class="c-myWizard-form__input">
        <div class="c-myWizard-form__input-area">
          <div class="o-input   -required   " [ngClass]="{'-disabled': (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')}" *ngIf="!cmsHierarchy.IsTypeStatusShow">
            <label for="WorkstreamTypedropdown">Workstream Type</label>
            <input type="text"
                   name="WorkstreamTypeName"
                   class="o-input__input"
                   placeholder="Workstream Type"
                   id="WorkstreamTypedropdown"
                   [(ngModel)]="currentSelectedWS.WorkstreamTypeName"
                   *ngIf="!cmsHierarchy.IsTypeStatusShow"
                   [disabled]="true"
                   aria-labelledby="WorkstreamTypelbl" />
          </div>
          <div class="c-custom-select__wrapper" *ngIf="cmsHierarchy.IsTypeStatusShow" [ngClass]="{'-disabled': (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')}">
            <label for="ws_type">Workstream Type<span *ngIf="cmsHierarchy.IsTypeStatusShow" class="required">&nbsp;*</span><a *ngIf="cmsHierarchy.WorkstreamTypes.length <= 0 && cmsHierarchy.IsTypeStatusShow" href="javascript:void(0)" aria-label="Please select a valid delivery type in Delivery Profile page for Workstream Creation" data-toggle="modal" class="c-myWizard-select-opt" (click)="showModalPopup('workstreamType')">What is it?</a></label>
            <!--<i class="icon-info" *ngIf="cmsHierarchy.WorkstreamTypes.length <= 0 && cmsHierarchy.IsTypeStatusShow" data-myw-title="Please select a valid delivery type in Delivery Profile page for Workstream Creation" data-myw-toggle="tooltip" data-myw-placement="bottom"></i>-->
            <search-dropdown id="WorkstreamTypeSearchDropdown"
                             name="WorkstreamTypeSearchDropdown"
                             #WorkstreamTypeSearchDropdown="ngModel"
                             [labelInfo]="'WorkstreamType'"
                             [options]="cmsHierarchy.WorkstreamTypes"
                             [isShow]="cmsHierarchy.IsTypeStatusShow"
                             [errormessage]="'Please select Workstream Type'"
                             [formname]="currentSelectedWSform"
                             [required]="true"
                             [selectText]="'Select'"
                             [selected]="currentSelectedWS.WorkstreamTypeId"
                             (callback)="SetTimePicker(true)"
                             [disableDropdown]="(currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')"
                             [(ngModel)]="currentSelectedWS.WorkstreamTypeId">
            </search-dropdown>

            <!--<div *ngIf="cmsHierarchy.WorkstreamTypes.length <= 0 && cmsHierarchy.IsTypeStatusShow" class="cms-tms-help" title="Please select a valid delivery type in Delivery Profile page for Workstream Creation"><span class="glyphicon glyphicon-info-sign"></span></div>-->

          </div>
        </div>
        <div class="c-myWizard-form__input-area">
          <div class="o-input   -required   " [ngClass]="{'-disabled': SFEDisable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')}">
            <label for="name">Name</label>
            <input type="text"
                   id="WSNameinput"
                   name="WorkStreamName"
                   #WorkStreamName="ngModel"
                   class="o-input__input"
                   placeholder="Name"
                   [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || currentSelectedWS.PhoenixCreationIndicator"
                   maxlength="75" [(ngModel)]="currentSelectedWS.WorkStreamName"
                   [required]="true"
                   [ngClass]="{'has-error': currentSelectedWSform.submitted && WorkStreamName.invalid}" />
            <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status" [ngClass]="{'is-error':currentSelectedWSform.submitted && WorkStreamName.invalid}">Please enter Name</p>
          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input">
        <div class="c-myWizard-form__input-area -comp-col">
          <div class="o-input    " [ngClass]="{'-disabled': SFEDisable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')}">
            <label for="ws_name">Workstream Lead Name</label>
            <auto-complete id="wsDeliveryLead" #wsDeliveryLead="ngModel" name="wsDeliveryLead" [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')"
                           hiddenFieldID="hiddenWSDeliveryLead" ariaLabelledby="DeliveryLead"
                           [selected]="currentSelectedWS.DeliveryLead"
                           [(ngModel)]="currentSelectedWS.DeliveryLead">
            </auto-complete>
            <!--<p class="o-input__error-text"></p>-->
          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input">
        <div class="c-myWizard-form__input-area">
          <div class="c-custom-select__wrapper -required">
            <label>Status</label>
            <search-dropdown id="WSStatusdropdown"
                             name="WorkstreamStatusId"
                             #WorkstreamStatusId="ngModel"
                             [labelInfo]="'WorkstreamStatusId'"
                             [options]="cmsHierarchy.TempWorkstreamStatus"
                             [isShow]="true"
                             [errormessage]="'Please select Status'"
                             [formname]="currentSelectedWSform"
                             [required]="true"
                             [selectText]="'Select'"
                             [selected]="currentSelectedWS.WorkstreamStatusId"
                             [disableDropdown]="SFEDisable || !IsEditable || (currentSelectedWS.WorkstreamTypeId==null|| currentSelectedWS.WorkstreamTypeId==undefined || currentSelectedWS.WorkstreamTypeId < 1) || currentSelectedWS.PhoenixCreationIndicator"
                             [(ngModel)]="currentSelectedWS.WorkstreamStatusId">
            </search-dropdown>
            <!--<p class="o-input__error-text" *ngIf="WorkstreamStatusId.invalid" [ngClass]="{'is-error': currentSelectedWSform.submitted}">Please enter Workstream Status</p>-->

          </div>
        </div>
        <div class="c-myWizard-form__input-area">
          <div class="o-input" [ngClass]="{'-disabled': SFEDisable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')}">
            <label for="bac">Initial budget at Completion (BAC)</label>
            <input type="text"
                   class="o-input__input"
                   placeholder=""
                   value=""
                   id="InitialBudgetAtCompletionlbl"
                   maxlength="16"
                   name="WorkStreamBAC"
                   (paste)="OnPaste($event, 'BAC')"
                   (keypress)="KeyPressNumeric($event)"
                   [(ngModel)]="currentSelectedWS.BAC"
                   [disabled]="SFEDisable || IsNotValidDeliverytype || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" />
          </div>
        </div>
        <div class="c-myWizard-form__input-area">
          <div class="o-input-date  -required  " [ngClass]="{'-disabled': SFEDisable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')}">
            <label for="startDate">Start Date</label>
            <input type="text"
                   name="startdateform"
                   #startdate="ngModel"
                   (onSelectDate)="currentSelectedWS.StartDate = $event.value;"
                   data-described="Calendar_Control_startdate"
                   [required]="true"
                   [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || currentSelectedWS.PhoenixCreationIndicator"
                   [ngClass]="{'has-error':(!DateValidation.IsValidStartDate)
                   || (!DateValidation.IsValidStartDateEndDate)
                   || (currentSelectedWSform.submitted
                   && (startdate.invalid
                   || !validateDateFormat(currentSelectedWS.StartDate)
                   || !DateValidation.IsValidStartDate
                   || !DateValidation.IsValidStartDateEndDate
                   || !startDateValue))}"
                   maxlength="11"
                   datepicker
                   class="o-input-date__input"
                   placeholder="DD MMM YYYY"
                   id="startdate"
                   [(ngModel)]="currentSelectedWS.StartDate"
                   autocomplete="off" />
            <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status"
               [ngClass]="{'is-error':(!DateValidation.IsValidStartDate)
               || (!DateValidation.IsValidStartDateEndDate)
               || (currentSelectedWSform.submitted
               && (startdate.invalid
               || !validateDateFormat(currentSelectedWS.StartDate)
               || !DateValidation.IsValidStartDate
               || !DateValidation.IsValidStartDateEndDate))}"
               *ngIf="startdate.invalid">Please enter Start Date</p>
            <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status"
               [ngClass]="{'is-error':(!DateValidation.IsValidStartDate)
               || (!DateValidation.IsValidStartDateEndDate)
               || (currentSelectedWSform.submitted
               && (startdate.invalid
               || !validateDateFormat(currentSelectedWS.StartDate)
               || !DateValidation.IsValidStartDate
               || !DateValidation.IsValidStartDateEndDate
               || !startDateValue))}"
               *ngIf="startdate
               && !validateDateFormat(currentSelectedWS.StartDate)
               && currentSelectedWS.StartDate != null
               && currentSelectedWS.StartDate != ''
               || !startDateValue">Please enter the date in "dd mmm yyyy" format</p>
            <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status"
               [ngClass]="{'is-error':(!DateValidation.IsValidStartDate)
               || (!DateValidation.IsValidStartDateEndDate)
               || (currentSelectedWSform.submitted
               && (startdate.invalid
               || !validateDateFormat(currentSelectedWS.StartDate)
               || !DateValidation.IsValidStartDate
               || !DateValidation.IsValidStartDateEndDate))}"
               *ngIf="!DateValidation.IsValidStartDate && currentSelectedWS.StartDate != null && currentSelectedWS.StartDate != '' && validateDateFormat(currentSelectedWS.StartDate)">
              Workstream Start Date is lesser than E2E Start Date ({{EndToEndStartDate}})
            </p>
            <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status"
               [ngClass]="{'is-error':(!DateValidation.IsValidStartDate)
               || (!DateValidation.IsValidStartDateEndDate)
               || (currentSelectedWSform.submitted
               && (startdate.invalid
               || !validateDateFormat(currentSelectedWS.StartDate)
               || !DateValidation.IsValidStartDate
               || !DateValidation.IsValidStartDateEndDate))}"
               *ngIf="validateDateFormat(currentSelectedWS.StartDate)
               && !DateValidation.IsValidStartDateEndDate
               && currentSelectedWS.StartDate != null
               && currentSelectedWS.StartDate != ''">
              Workstream Start Date is greater than E2E End Date ({{EndToEndEndDate}})
            </p>
            <p id="Calendar_Control_startdate" class="screenReaderOnly">
              1.Ctrl + UP/DOWN/LEFT/RIGHT keys for move to previous / next day<br />
              2.PAGE UP/PAGE DOWN keys for move to previous / next month<br />
              3.SHIFT + CTRL + PAGE UP/PAGE DOWN keys for move to previous/ next year
            </p>
          </div>
        </div>
        <div class="c-myWizard-form__input-area">
          <div class="o-input-date  -required  " [ngClass]="{'-disabled': SFEDisable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')}">
            <label for="endDate">End Date</label>
            <input name="enddate"
                   placeholder="DD MMM YYYY"
                   #enddate="ngModel" aria-labelledby="WSStartandEnddatelbl" type="text"
                   class="o-input-date__input"
                   (onSelectDate)="currentSelectedWS.EndDate = $event.value;"
                   data-described="Calendar_Control_enddate"
                   [required]="true"
                   [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || currentSelectedWS.PhoenixCreationIndicator"
                   [ngClass]="{'has-error': (!DateValidation.IsValidEndDate)
                   || (!DateValidation.IsValidEndDateStartDate)
                   || (currentSelectedWSform.submitted && (enddate.invalid
                   || !validateDateFormat(currentSelectedWS.EndDate)
                   || !DateValidation.IsValidEndDate
                   || !DateValidation.IsValidEndDateStartDate
                   || !endDateValue)), '-disabled': SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || currentSelectedWS.PhoenixCreationIndicator}"
                   maxlength="11" datepicker id="enddate" [(ngModel)]="currentSelectedWS.EndDate" autocomplete="off" />

            <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status"
               [ngClass]="{'is-error': (!DateValidation.IsValidEndDate)
               || (!DateValidation.IsValidEndDateStartDate)
               || (currentSelectedWSform.submitted
               && (enddate.invalid
               || !validateDateFormat(currentSelectedWS.EndDate)
               || !DateValidation.IsValidEndDate
               || !DateValidation.IsValidEndDateStartDate))}" *ngIf="enddate.invalid">Please enter End Date</p>
            <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status"
               [ngClass]="{'is-error': (!DateValidation.IsValidEndDate)
               || (!DateValidation.IsValidEndDateStartDate)
               || (currentSelectedWSform.submitted
               && (enddate.invalid
               || !validateDateFormat(currentSelectedWS.EndDate)
               || !DateValidation.IsValidEndDate
               || !DateValidation.IsValidEndDateStartDate
               || !endDateValue))}"
               *ngIf="enddate
               && !validateDateFormat(currentSelectedWS.EndDate)
               && currentSelectedWS.EndDate != null
               && currentSelectedWS.EndDate != ''
               || !endDateValue">Please enter the date in "dd mmm yyyy" format</p>
            <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status"
               [ngClass]="{'is-error': (!DateValidation.IsValidEndDate)
               || (!DateValidation.IsValidEndDateStartDate)
               || (currentSelectedWSform.submitted
               && (enddate.invalid
               || !validateDateFormat(currentSelectedWS.EndDate)
               || !DateValidation.IsValidEndDate
               || !DateValidation.IsValidEndDateStartDate))}"
               *ngIf="!DateValidation.IsValidEndDate
               && validateDateFormat(currentSelectedWS.EndDate)">
              Workstream End Date is greater than E2E End Date ({{EndToEndEndDate}})
            </p>
            <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status"
               [ngClass]="{'is-error': (!DateValidation.IsValidEndDate)
               || (!DateValidation.IsValidEndDateStartDate)
               || (currentSelectedWSform.submitted
               && (enddate.invalid
               || validateDateFormat(currentSelectedWS.EndDate)
               || !DateValidation.IsValidEndDate
               || !DateValidation.IsValidEndDateStartDate))}"
               *ngIf="!DateValidation.IsValidEndDateStartDate
               && validateDateFormat(currentSelectedWS.EndDate)">
              Workstream End Date is lesser than E2E Start Date ({{EndToEndStartDate}})
            </p>
            <p id="Calendar_Control_enddate" class="screenReaderOnly">
              1.Ctrl + UP/DOWN/LEFT/RIGHT keys for move to previous / next day<br />
              2.PAGE UP/PAGE DOWN keys for move to previous / next month<br />
              3.SHIFT + CTRL + PAGE UP/PAGE DOWN keys for move to previous/ next year
            </p>
          </div>
        </div>
        <div class="c-myWizard-form__input-area" *ngIf="currentSelectedWS.WorkstreamTypeId == 1">
          <div class="c-custom-select__wrapper">
            <label for="WSCutoffforPlannedValuedropdown">Cut off for Planned Value</label>
            <p class="c-select__error-text">Enter the value</p>
            <search-dropdown id="WSCutoffforPlannedValuedropdown"
                             name="WSCutoffforPlannedValuedropdown"
                             #WSCutoffforPlannedValuedropdown="ngModel"
                             [labelInfo]="'WSCutoffforPlannedValue'"
                             [options]="cmsHierarchy.CutOffForPlannedValue"
                             [isShow]="true"
                             [errormessage]="'Please select Cut off for Planned Value'"
                             [formname]="currentSelectedWSform"
                             [required]="true"
                             [selectText]="'Select'"
                             [selected]="currentSelectedWS.CutOffForPlannedValue"
                             [disableDropdown]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')"
                             [(ngModel)]="currentSelectedWS.CutOffForPlannedValue">
            </search-dropdown>
          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input" *ngIf="currentSelectedWS.WorkstreamTypeId == 1 || currentSelectedWS.WorkstreamTypeId == 6">
        <div class="c-myWizard-form__input-area">
          <div class="c-custom-select__wrapper">
            <label for="bac">Is Code Review in-scope</label>
            <!--<select name="WSIsCodeReviewDropDown" class="form-control" [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [(ngModel)]="currentSelectedWS.IsCodeReview" [selected]="IsCodeReviewUpdated()">
              <option role="option" value="null">--Select--</option>
              <option role="option" value='Y'>Yes</option>
              <option role="option" value='N'>No</option>
            </select>-->
            <search-dropdown id="WSIsCodeReviewDropDown"
                             name="WSIsCodeReviewDropDown"
                             #WSIsCodeReviewDropDown="ngModel"
                             [labelInfo]="'WSIsCodeReviewDropDown'"
                             [options]="codeReviewDropDown"
                             [isShow]="true"
                             [errormessage]="'Please select Is Code Review in-scope'"
                             [formname]="currentSelectedWSform"
                             [required]="false"
                             [selectText]="'Select'"
                             [selected]="IsCodeReviewUpdated()"
                             [disableDropdown]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')"
                             [(ngModel)]="currentSelectedWS.IsCodeReview">
            </search-dropdown>
          </div>
        </div>
        <div class="c-myWizard-form__input-area">
          <div class="c-custom-select__wrapper">
            <label for="bac">If No, specify a reason</label>
            <!--<select name="WSCodeReviewOutofScopeReason" [(ngModel)]="currentSelectedWS.CodeReviewOutofScopeReasonId" [disabled]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || currentSelectedWS.IsCodeReview != 'N'" class="o-input__input">
              <option role="option" value="null">--Select--</option>
              <option *ngFor="let item of cmsHierarchy.WorkStreamCodeReviewOutofScopeReason; trackBy : trackById;" [value]="item.Id">{{item.Name}}</option>
            </select>-->

            <search-dropdown id="WSCodeReviewOutofScopeReason"
                             name="WSCodeReviewOutofScopeReason"
                             #WSCodeReviewOutofScopeReason="ngModel"
                             [labelInfo]="'WSCodeReviewOutofScopeReason'"
                             [options]="cmsHierarchy.WorkStreamCodeReviewOutofScopeReason"
                             [isShow]="true"
                             [errormessage]="'Please specify a reason'"
                             [formname]="currentSelectedWSform"
                             [required]="false"
                             [selectText]="'Select'"
                             [selected]="currentSelectedWS.CodeReviewOutofScopeReasonId"
                             [disableDropdown]="SFEDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || currentSelectedWS.IsCodeReview != 'N'"
                             [(ngModel)]="currentSelectedWS.CodeReviewOutofScopeReasonId">
            </search-dropdown>
          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input">
        <div class="c-myWizard-form__input-area">
          <div class="c-custom-select__wrapper" [ngClass]="{'-disabled': currentSelectedWS.WorkstreamStatusName == 'Closed'}">
            <label>Primary Technologies</label>
            <multi-selectdropdown name="wsTechnology"
                                  #wsTechnology="ngModel"
                                  id="WSTechnologiesdropdown"
                                  [options]="cmsHierarchy.SelectedEndToEndTechnologies"
                                  [disableDropdown]="((currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed') || !IsEditable) "
                                  [selected]="currentSelectedWS.SelectedTechnology"
                                  [selectedparent]=""
                                  [(ngModel)]="currentSelectedWS.SelectedTechnology"
                                  [ishierarchy]="false"
                                  [ariaLabelledby]="'WSTechnologies'"></multi-selectdropdown>
          </div>
        </div>
        <div class="c-myWizard-form__input-area" *ngIf="cmsHierarchy.WorkstreamCMSListLevel1.length > 0">
          <div class="c-custom-select__wrapper" *ngIf="!IsDisableCMSWSDropdown()">
            <label for="technologies">
              Associate to CMS
              <a href="javascript:void(0)" aria-label="Click here to see the Associate Workstream" data-toggle="modal" class="c-myWizard-select-opt" (click)="showModalPopup('associateToCMS')">What is it?</a>
              <!--<i href="javascript:void(0);" id="mseInfoIcon" class="icon-info"
              aria-label="Metrics submission for a Workstream, would reflect in the currently mapped CMS until the current PED (including those disassociated from current CMS). Any new mapping related to client structure will not display prior metrics - previously submitted metrics however will be shown at E2E."
              title="Metrics submission for a Workstream, would reflect in the currently mapped CMS until the current PED (including those disassociated from current CMS). Any new mapping related to client structure will not display prior metrics - previously submitted metrics however will be shown at E2E."></i>-->

            </label>
            <hierarchy-dropdown name="workstreamCMS" #workstreamCMS="ngModel"
                                id="WSAssociateWorkstreamtoselectedCMSdropdown"
                                [options]="cmsHierarchy.WorkstreamCMSListLevel1"
                                [selected]="currentSelectedWS.SelectedWorkstreamCms"
                                [formname]="currentSelectedWSform"
                                [required]="false" [name]="'workstreamCMS'"
                                [(ngModel)]="currentSelectedWS.SelectedWorkstreamCms"
                                [ariaLabelledby]="'WSAssociateWorkstreamtoselectedCMS'"
                                [disableDropdown]="(!IsEditable) "
                                [selectText]="'Select'">

            </hierarchy-dropdown>



          </div>
          <div class="c-custom-select__wrapper c-hierarchical-select__wrapper -disabled" *ngIf="IsDisableCMSWSDropdown()">
            <label for="technologies">
              Associate to CMS
              <a href="javascript:void(0)" aria-label="Click here to see the Associate Workstream" data-toggle="modal" class="c-myWizard-select-opt" (click)="showModalPopup('associateToCMS')">What is it?</a>
              <!--<i href="javascript:void(0);" id="mseInfoIcon" class="icon-info"
              aria-label="Metrics submission for a Workstream, would reflect in the currently mapped CMS until the current PED (including those disassociated from current CMS). Any new mapping related to client structure will not display prior metrics - previously submitted metrics however will be shown at E2E."
              title="Metrics submission for a Workstream, would reflect in the currently mapped CMS until the current PED (including those disassociated from current CMS). Any new mapping related to client structure will not display prior metrics - previously submitted metrics however will be shown at E2E."></i>-->

            </label>
            <span id="info_multiselectdropdown" class="screenReaderOnly">Use the tab key to navigate through the items in the list. </span>
            <a href="javascript:;" role="button" aria-expanded="false" aria-haspopup="true" class="c-custom-select__selected-text icon-arrow-down -disabled">
              <div name="{{'multiselect_btn_' + 'WSAssociateWorkstreamtoselectedCMS' }}"
                   class="c-custom-select__chip -disabled"
                   aria-labelledby="WSAssociateWorkstreamtoselectedCMS" aria-describedby="info_multiselectdropdown">
                <div class="c-myWizard__selected-chip">
                  <span *ngIf="currentSelectedWS.CMSName != null" class="multiselect-text">{{currentSelectedWS.CMSName}}</span>
                  <span *ngIf="currentSelectedWS.CMSName == null" class="multiselect-text">None Selected</span>
                  <i class="disabled" aria-label="close"></i>
                </div>
              </div>
            </a>
            <!--<i href="javascript:void(0);" id="mseInfoIcon" class="icon-info"
               style="font-size:14px;color:black;text-decoration-line: none;top: 25%;width: 1%;position:absolute;"
               aria-label="Metrics submission for a Workstream, would reflect in the currently mapped CMS until the current PED (including those disassociated from current CMS). Any new mapping related to client structure will not display prior metrics - previously submitted metrics however will be shown at E2E."
               title="Metrics submission for a Workstream, would reflect in the currently mapped CMS until the current PED (including those disassociated from current CMS). Any new mapping related to client structure will not display prior metrics - previously submitted metrics however will be shown at E2E."></i>-->

          </div>
        </div>
        <div class="c-myWizard-form__input-area" *ngIf="cmsHierarchy.IsFactory && !SFEDisable">
          <div class="c-custom-select__wrapper">
            <label for="bac">External E2E (C/O/II)</label>
            <search-dropdown id="WSExternalE2Edropdown" name="WSExternalE2Edropdown" #WSExternalE2Edropdown="ngModel" [options]="cmsHierarchy.SFEEndtoEnds" [disableDropdown]="SFEdropdownDisable || !IsEditable || (currentSelectedWS.RowStatusId != 2 && currentSelectedWS.WorkstreamStatusName == 'Closed')" [isShow]="true"
                             [formname]="currentSelectedWSform" [selected]="currentSelectedWS.ClientEndToEndId" [selectText]="'Select'"
                             [labelInfo]="'WSExternalE2E'" [(ngModel)]="currentSelectedWS.ClientEndToEndId">
            </search-dropdown>
          </div>
        </div>
        <div class="c-myWizard-form__input-area" *ngIf="SFEDisable">
          <div class="o-input">
            <label for="WSFactoryNameinput"><strong>Factory Name</strong></label>
            <input id="WSFactoryNameinput" name="factoryName" type="text" class="form-control" [(ngModel)]="currentSelectedWS.FactoryName" [disabled]="true" />
          </div>
        </div>

        <div class="c-myWizard-form__input-area" *ngIf="EndToEndType == 'C' && IsShowMultiContractLink">
          <!-- <div class="c-myWizard-form__input-area" *ngIf="EndToEndType == 'C' && IsShowMultiContractLink">-->
          <div class="o-input">
            <label for="name">
              Associated Contracts  <a [ngClass]="{'team-disabled' : currentSelectedWS.WorkstreamStatusName == 'Closed'}" *ngIf="IsEditable" id="Manage_Info_ws"
                                       (click)="showManagePopup(currentSelectedWS.Contracts,'Contract ID','ws', false)" href="javascript:void(0);"
                                       class="c-myWizard-select-opt"
                                       aria-label="Add/Delete Contracts">Manage Contract IDs</a>
            </label>
            <p aria-labelledby="PricingStructure" id="Contracttext" name="Contracttext" class="o-text-area__input -customScroll -textAreaInput">{{ currentSelectedWS.Contracts | commaseparated}}</p>
            <!--<textarea class="o-input__input" [disabled]="true" rows="2" id="Contracttext">{{ currentSelectedWS.Contracts | commaseparated}}</textarea>-->
          </div>
        </div>
      </div>
    </div>
    <div class="c-flyout__footer">
      <div class="-flyout-btn">
        <!--Primary Button starts from here-->
        <button [disabled]="SFEDisable || !IsEditable" [ngClass]="{'-disabled': !IsEditable }" type="submit" value="submit" *ngIf="cmsHierarchy.TypeId == 2" class="o-primary-button  -flyout-close">
          Save
        </button>
        <!--Primary Button ends from here-->
      </div>
      <div class="-updation" *ngIf="cmsHierarchy.TypeId == 2 && IsCMSEdit">
        <span>Last Updated by :</span>&nbsp;{{currentSelectedWS.ModifiedByUser}}
      </div>
      <div class="-updation" *ngIf="cmsHierarchy.TypeId == 2 && IsCMSEdit">
        <span>Last Updated on :</span>&nbsp;{{currentSelectedWS.ModifiedOn |date:'dd MMM yyyy h:mm a'}}
      </div>
    </div>

  </div>
  <div class="c-flyout__overlay"></div>
</form>

<div *ngIf="displayAssociateWorkstreamPopup" id="associateWorkstreamModal" class="c-modal c-priority-modal" tabindex="-1" aria-labelledby="modalDialogTitle" aria-modal="true" role="dialog">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">Associate to CMS</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close Associate Workstream" alt="Close" (click)="closeModal('associateToCMS')"></button>
      </div>
      <div class="c-modal__body">
        Metrics submission for a Workstream, would reflect in the currently mapped CMS until the current PED (including those disassociated from current CMS). Any new mapping related to client structure will not display prior metrics - previously submitted metrics however will be shown at E2E.
      </div>
    </div>
  </div>
</div>

<div *ngIf="displayWorkstreamTypePopup" id="workstreamTypeModal" class="c-modal c-priority-modal" tabindex="-1" aria-labelledby="modalDialogTitle" aria-modal="true" role="dialog">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">Workstream Type</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close Workstream Type" alt="Close" (click)="closeModal('workstreamType')"></button>
      </div>
      <div class="c-modal__body">
        Please select a valid delivery type in Delivery Profile page for Workstream Creation.
      </div>
    </div>
  </div>
</div>
