<form *ngIf="DeliveryPortfolioDetails" name="deliveryportfolioform" #deliveryportfolioform="ngForm" novalidate
      (ngSubmit)="saveDeliveryPortfolio(deliveryportfolioform.valid,deliveryportfolioform)" id="portfolio">
  <div class="c-myWizard-container">
    <div class="c-myWizard__header">
      <div class="c-myWizard__header-content-left">
        <div class="c-myWizard__header-content-head">
          <a href="javascript:;" class="icon-banner-arrow" role="button" (click)="OnBackClick()" alt="Back" aria-label="Back"></a>
          <div class="-header">Delivery Portfolio</div>
        </div>
        <div class="c-myWizard__header-content-right">
        </div>
      </div>
    </div>

    <div class="c-myWizard-form__input" [ngSwitch]="endToEndType">
      <div class="c-myWizard-form__input-area" [ngSwitch]="E2eDemoGraphics.E2EType">
        <div [ngClass]="endToEndType != 'C' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'" *ngIf="endToEndType != 'C'">
          <label for="company">Company</label>
          <p *ngSwitchCase="'C'" [textContent]="DeliveryPortfolioDetails.CompanyName"></p>
          <div *ngSwitchDefault>
            <search-dropdown id="company" name="company" #company="ngModel" [options]="DeliveryPortfolioDetails.Companies" [isShow]="true" [errormessage]="'Please select Company'" [formname]="deliveryportfolioform"
                             [disableDropdown]="RoleAccess=='V' || RoleTypeCode == false" [required]="true" label-info="'Company'"
                             [selected]="DeliveryPortfolioDetails.CompanyId" (callback)="CompanyChange(DeliveryPortfolioDetails.CompanyId)" [selectText]="'Select'"
                             [(ngModel)]="DeliveryPortfolioDetails.CompanyId">
            </search-dropdown>
          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="E2eDemoGraphics.E2EType=='C'">
        <label>RDE</label>
        <p [textContent]="DeliveryPortfolioDetails.RBEName"></p>
      </div>
      <div class="c-myWizard-form__input-area" [ngSwitch]="E2eDemoGraphics.E2EType">
        <div [ngClass]="endToEndType == 'O' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'">
          <label for="managementMarketId" id="OperatingGroup">Market</label>
          <p *ngSwitchCase="'C'" [textContent]="DeliveryPortfolioDetails.ManagementMarket"></p>
          <div *ngSwitchCase="'I'">
            <search-dropdown id="managementMarketId" name="managementMarketId" #managementMarketId="ngModel" [options]="DeliveryPortfolioDetails.ManagementMarkets" [isShow]="true"
                             [disableDropdown]="RoleAccess=='V'" [labelInfo]="'OperatingGroup'"
                             [selected]="DeliveryPortfolioDetails.ManagementMarketId" (callback)="ManagementMarkeChange(DeliveryPortfolioDetails.ManagementMarketId)" [selectText]="'Select'"
                             [(ngModel)]="DeliveryPortfolioDetails.ManagementMarketId">
            </search-dropdown>
          </div>
          <div *ngSwitchDefault>
            <search-dropdown id="managementMarketId" name="managementMarketId" #managementMarketId="ngModel" [options]="DeliveryPortfolioDetails.ManagementMarkets" [isShow]="true" [errormessage]="'Please select Market'" [formname]="deliveryportfolioform"
                             [disableDropdown]="RoleAccess=='V'" [required]="true" [labelInfo]="'OperatingGroup'"
                             [selected]="DeliveryPortfolioDetails.ManagementMarketId" (callback)="ManagementMarkeChange(DeliveryPortfolioDetails.ManagementMarketId)" [selectText]="'Select'"
                             [(ngModel)]="DeliveryPortfolioDetails.ManagementMarketId">
            </search-dropdown>
          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" [ngSwitch]="E2eDemoGraphics.E2EType">
        <div [ngClass]="endToEndType == 'O' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'">
          <label for="managementMarketUnitId">Market Unit</label>
          <p *ngSwitchCase="'C'" [textContent]="DeliveryPortfolioDetails.ManagementMarketUnit"></p>
          <div *ngSwitchCase="'I'">
            <search-dropdown id="managementMarketUnitId" name="managementMarketUnitId" #managementMarketUnitId="ngModel" [options]="DeliveryPortfolioDetails.MMU" [isShow]="true" [labelInfo]="'MarketUnit'"
                             [disableDropdown]="RoleAccess=='V' || DeliveryPortfolioDetails.ManagementMarketId == null" [filterId]="DeliveryPortfolioDetails.ManagementMarketId"
                             [selected]="DeliveryPortfolioDetails.ManagementMarketUnitId" (callback)="MarketUnitChange(DeliveryPortfolioDetails.ManagementMarketUnitId)" [selectText]="'Select'"
                             [(ngModel)]="DeliveryPortfolioDetails.ManagementMarketUnitId">
            </search-dropdown>
          </div>
          <div *ngSwitchDefault>
            <search-dropdown id="managementMarketUnitId" name="managementMarketUnitId" #managementMarketUnitId="ngModel" [options]="DeliveryPortfolioDetails.MMU" [isShow]="true" [labelInfo]="'MarketUnit'" [errormessage]="'Please select Market Unit'" [formname]="deliveryportfolioform"
                             [disableDropdown]="RoleAccess=='V' || DeliveryPortfolioDetails.ManagementMarketId == null" [required]="DeliveryPortfolioDetails.ManagementMarketId != null" [filterId]="DeliveryPortfolioDetails.ManagementMarketId"
                             [selected]="DeliveryPortfolioDetails.ManagementMarketUnitId" (callback)="MarketUnitChange(DeliveryPortfolioDetails.ManagementMarketUnitId)" [selectText]="'Select'"
                             [(ngModel)]="DeliveryPortfolioDetails.ManagementMarketUnitId">
            </search-dropdown>
          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" [ngSwitch]="E2eDemoGraphics.E2EType">
        <div [ngClass]="endToEndType == 'O' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'">
          <label for="managementClientGroupId">Primary Client Group</label>
          <p *ngSwitchCase="'C'" [textContent]="DeliveryPortfolioDetails.ManagementClientGroupName"></p>
          <div *ngSwitchCase="'I'">
            <search-dropdown id="managementClientGroupId" name="managementClientGroupId" #managementClientGroupId="ngModel" [options]="DeliveryPortfolioDetails.MCG" [isShow]="true"
                             [disableDropdown]="RoleAccess=='V'" [labelInfo]="'ClientServiceGroup'"
                             [selected]="DeliveryPortfolioDetails.ManagementClientGroupId" (callback)="ClientGroupChange()" [selectText]="'Select'"
                             [(ngModel)]="DeliveryPortfolioDetails.ManagementClientGroupId">
            </search-dropdown>
          </div>
          <div *ngSwitchDefault>
            <search-dropdown id="managementClientGroupId" name="managementClientGroupId" #managementClientGroupId="ngModel" [options]="DeliveryPortfolioDetails.MCG" [isShow]="true" [errormessage]="'Please select Primary Client Group'" [formname]="deliveryportfolioform"
                             [disableDropdown]="RoleAccess=='V'" [required]="true" [labelInfo]="'ClientServiceGroup'"
                             [selected]="DeliveryPortfolioDetails.ManagementClientGroupId" (callback)="ClientGroupChange()" [selectText]="'Select'"
                             [(ngModel)]="DeliveryPortfolioDetails.ManagementClientGroupId">
            </search-dropdown>
          </div>
        </div>
      </div>

      <div class="c-myWizard-form__input-area" *ngIf="CreatePage && endToEndType == 'O'">
        <div class="c-custom-select__wrapper">
          <label for="industrySegment">Industry Segment</label>
          <search-dropdown id="IndustrySegmentInfo" name="industrysegment" #industrysegment="ngModel" [options]="DeliveryPortfolioDetails.IndustrySegmentsForOpp" [isShow]="true"
                           [selected]="DeliveryPortfolioDetails.SelectedIndustrySegmentForOpp" [disableDropdown]="RoleAccess=='V'" [formname]="deliveryportfolioform" [name]="'IndustrySegement'"
                           [(ngModel)]="DeliveryPortfolioDetails.SelectedIndustrySegmentForOpp" (callback)="IndustrySegmentChange()" [ariaLabelledby]="'IndustrySegement'" [selectText]="'Select'"></search-dropdown>
        </div>
      </div>

      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'I'">
        <div class="c-custom-select__wrapper">
          <label>Industry Segment</label>
          <multi-selectdropdown name="industrysegment" #industrysegment="ngModel" id="IndustrySegmentInfo" [options]="DeliveryPortfolioDetails.IndustrySegments"
                                [selected]="DeliveryPortfolioDetails.IndustryInputs" [disableDropdown]="RoleAccess=='V'" [formname]="deliveryportfolioform" [name]="'IndustrySegement'"
                                [(ngModel)]="DeliveryPortfolioDetails.IndustryInputs" (callback)="IndustrySegmentChange()" [ariaLabelledby]="'IndustrySegement'" [errormessage]="'Please select Industry Segment'"></multi-selectdropdown>

        </div>
      </div>

      <!--<div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'I'">
    <div>
      <label id="IndustrySegmentInfo"><strong>Industry Segment</strong></label>
      <br /><a name="manageindustrysegment" class="link link-font-size" *ngIf="RoleAccess !='V'  && endToEndType == 'I'" (click)="showIndustrySegment()" href="javascript:void(0)" aria-label="Click here to add/change Industry Segment(opens a new window)">Click here to add/change Industry Segment</a>
    </div>
    <textarea name="industrysegment" #industrysegment="ngModel" aria-labelledby="IndustrySegment" readonly title="{{ RoleAccess=='V' ? DeliveryPortfolioDetails.IndustrySegement: DeliveryPortfolioDetails.IndustrySegement +'&#013;Click on link to add/change industry segment'}}" [required]="true" [(ngModel)]="DeliveryPortfolioDetails.IndustrySegement" [disabled]="true"></textarea>
    <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage" [ngClass]="{'has-error': industrysegment?.errors?.required}">Please select Industry Segment</div>
  </div>-->

      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'I'">
        <div class="c-custom-select__wrapper">
          <label id="SubgroupCategory" for="selectedSubgroupCategory">Services/Factories Provider Category</label>
          <search-dropdown id="selectedSubgroupCategory" name="selectedSubgroupCategory" #selectedSubgroupCategory="ngModel" [options]="DeliveryPortfolioDetails.SubgroupCategory" [isShow]="true"
                           [disableDropdown]="RoleAccess=='V' || DisableSubgroupDropdown" (callback)="SubgroupCategoryChange()" [labelInfo]="'SubgroupCategory'"
                           [selected]="DeliveryPortfolioDetails.SelectedSubgroupCategory" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.SelectedSubgroupCategory">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'I'">
        <div [ngClass]="DeliveryPortfolioDetails.SelectedSubgroupCategory != null ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'">
          <label for="selectedDeliverySubgroup">Services/Factories Provider</label>
          <search-dropdown id="selectedDeliverySubgroup" name="selectedDeliverySubgroup" #selectedDeliverySubgroup="ngModel" [options]="DeliveryPortfolioDetails.DeliverySubgroup" [isShow]="true" [required]="DeliveryPortfolioDetails.SelectedSubgroupCategory != null" [errormessage]="'Please select Services/Factories Provider'" [formname]="deliveryportfolioform"
                           (callback)="SubGroupChange()" [labelInfo]="'Subgroup'"
                           [disableDropdown]="RoleAccess=='V' || DisableSubgroupDropdown" [filterId]="DeliveryPortfolioDetails.SelectedSubgroupCategory"
                           [selected]="DeliveryPortfolioDetails.SelectedDeliverySubgroup" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.SelectedDeliverySubgroup">
          </search-dropdown>
        </div>
      </div>
    </div>

    <div class="c-myWizard-form__input" [ngSwitch]="endToEndType">
      <div class="c-myWizard-form__input-area" *ngIf="DeliveryPortfolioDetails.CompanyId == 1" [ngSwitch]="E2eDemoGraphics.E2EType">
        <div [ngClass]="endToEndType != 'C' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'">
          <label for="countryId">Country/Location</label>
          <p *ngSwitchCase="'C'" [textContent]="DeliveryPortfolioDetails.AccentureGeographicCountryName"></p>
          <div *ngSwitchDefault>
            <search-dropdown id="countryId" name="countryId" #countryId="ngModel" [options]="DeliveryPortfolioDetails.Countries" [isShow]="true" [errormessage]="'Please select Country/Location'" [formname]="deliveryportfolioform"
                             [disableDropdown]="RoleAccess=='V'" [required]="true" [labelInfo]="'Country'"
                             [selected]="DeliveryPortfolioDetails.CountryId" [selectText]="'Select'"
                             [(ngModel)]="DeliveryPortfolioDetails.CountryId">
            </search-dropdown>
          </div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area">
        <div class="c-select-dropdown" *ngIf="DeliveryPortfolioDetails.CompanyId==2">
          <label for="avanadeChannelId">Avanade Channel</label>
          <input type="text" aria-labelledby="Avanade channel" class="o-input__input -disabled" value="Direct" [disabled]="true" />
        </div>
        <div class="c-custom-select__wrapper" *ngIf="DeliveryPortfolioDetails.CompanyId!=2">
          <label for="avanadeChannelId">Avanade Channel</label>
          <search-dropdown id="avanadeChannelId" name="avanadeChannelId" #avanadeChannelId="ngModel" [options]="DeliveryPortfolioDetails.AvanadeChannel" [disableDropdown]="RoleAccess=='V'" [isShow]="true" [labelInfo]="'AvanadeChannel'"
                           [selected]="DeliveryPortfolioDetails.AvanadeChannelId" (callback)="avanadeChannelChange()"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeChannelId">
          </search-dropdown>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="DeliveryPortfolioDetails.AvanadeChannelId != 0">
        <div class="c-select-dropdown">
          <div class="o-input">
            <label for="avanadeGeographicArea">Avanade Geographic Area</label>
            <p class="o-input__input -disabled" [innerHTML]="DeliveryPortfolioDetails.AvanadeGeographicAreaName"></p>
          </div>
          <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="DeliveryPortfolioDetails.AvanadeChannelId != 0">
        <div class="c-custom-select__wrapper -required">
          <label for="avanadeGeographicUnit">Avanade Geographic Unit</label>
          <search-dropdown id="avanadeGeographicUnit" name="avanadeGeographicUnit" #avanadeGeographicUnit="ngModel" [options]="DeliveryPortfolioDetails.AvandeGeographicUnits" [isShow]="true" [formname]="deliveryportfolioform"
                           [disableDropdown]="RoleAccess=='V'" [required]="true" [selectText]="'Select'" [labelInfo]="'AvanadeGeographicUnit'" [errormessage]="'Please select Avanade Geographic Unit'"
                           [selected]="DeliveryPortfolioDetails.AvanadeGeographicUnitId" (callback)="avanadeGeoUnitChange(DeliveryPortfolioDetails.AvanadeGeographicUnitId,true)"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeGeographicUnitId">
          </search-dropdown>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="DeliveryPortfolioDetails.AvanadeChannelId != 0">
        <div class="c-custom-select__wrapper -required">
          <label>Avanade Country/Location</label>
          <search-dropdown id="avanadeCountry" name="avanadeCountry" #avanadeCountry="ngModel" [options]="AvanadeGeoCountryLoad" [disableDropdown]="RoleAccess=='V'" [errormessage]="'Please select Avanade Country/Location'" [formname]="deliveryportfolioform"
                           [required]="true" [selectText]="'Select'" [isShow]="true" [labelInfo]="'AvanadeCountry'"
                           [selected]="DeliveryPortfolioDetails.AvanadeCountryId"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeCountryId">
          </search-dropdown>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="DeliveryPortfolioDetails.AvanadeChannelId==1 || DeliveryPortfolioDetails.AvanadeChannelId==2">
        <div class="c-custom-select__wrapper">
          <label>Avanade GSA</label>
          <search-dropdown id="avanadeGSAId" name="avanadeGSAId" #avanadeGSAId="ngModel" [options]="DeliveryPortfolioDetails.AvanadeGSA" [disableDropdown]="RoleAccess=='V'" [isShow]="true" [labelInfo]="'AvanadeGSA'" [formname]="deliveryportfolioform"
                           [required]="ValidationMarketUnit && DeliveryPortfolioDetails.AvanadeChannelId!=0 && DeliveryPortfolioDetails.AvanadeGSAId==null && DeliveryPortfolioDetails.CompanyId == 2"
                           [selected]="DeliveryPortfolioDetails.AvanadeGSAId" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeGSAId">
          </search-dropdown>
        </div>
      </div>
    </div>

  </div>

  <!--<h2 *ngIf="E2eDemoGraphics.E2EType=='C'">Service Group Network</h2>
  <div *ngIf="E2eDemoGraphics.E2EType=='C'" class="row">
    <div class="col-xs-12 col-lg-6">
      <p id="ServiceGroupNetwork" class="screenReaderOnly">This table lists service groups involved along with dominant service group.</p>
      <div class="table-responsive">
        <table class="table table-striped table-hover" aria-describedby="ServiceGroupNetwork">
          <thead>
            <tr>
              <th id="Dominant" scope="col">Dominant</th>
              <th scope="col">Service Group</th>
              <th scope="col">Allocation(%)</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let servicegroup of DeliveryPortfolioDetails.ServiceGroups; trackBy: trackByServiceGroupId;">
              <td>
                <input type="radio" aria-labelledby="Dominant" value="Dominant" [disabled]="true" name="dominant" ngValue="servicegroup.ServiceGroupId" [(ngModel)]="servicegroup.DominantServiceGroupId" (change)="dominantChange(servicegroup)" />
              </td>
              <td [textContent]="servicegroup.ServiceGroupName"></td>
              <td [textContent]="servicegroup.Allocation"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>-->

  <div class="c-myWizard-update__container" *ngIf="!CreatePage">
    <div class="c-myWizard-container">
      <div class="c-myWizard-update__row">
        <div class="c-myWizard-update__details">
          <div class="c-myWizard-update__input">
            <label>Last Updated On:</label>
            <span class="-input">{{DeliveryPortfolioDetails.LastModifiedOn |date:'dd MMM yyyy h:mm a'}}{{DeliveryPortfolioDetails.LastModifiedOn == null ? "" : " (GMT)"}}</span>
          </div>
          <div class="c-myWizard-update__input">
            <label>Last Updated By:</label>
            <span class="-input">{{DeliveryPortfolioDetails.LastModifiedBy}}</span>
          </div>
        </div>
        <div class="c-myWizard-update__contract">
          <div class="c-myWizard-update__input" [hidden]="endToEndType == 'C'">
            <label [hidden]="endToEndType == 'C'">Auto-sourced Contract Details Last  From: </label><label [hidden]="endToEndType == 'C'">CMW</label>
          </div>
          <div class="c-myWizard-update__input" [hidden]="endToEndType == 'C'">
            <label [hidden]="endToEndType == 'C'">Auto-sourced Contract Details Last  On: </label><label [hidden]="endToEndType == 'C'">{{DeliveryPortfolioDetails.AutosourcedLastModifiedOn |date:'dd MMM yyyy h:mm a'}} (GMT)</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="c-select-card">
    <div class="container">
      <div class="c-select-card__wrapper">
        <div class="c-select-card__left"><div class="c-select-card__text">Changes made will be lost unless confirmed. Please click 'Save' button to confirm.</div></div>
        <div class="c-select-card__right">
          <!--<button href="javascript:void(0)" class="o-secondary-button" form="deliveryportfolioform" role="button" title="Save" type="submit" value="submit" [disabled]="RoleAccess=='V'" aria-label="Save">Save</button>-->
          <button href="javascript:void(0)" class="o-secondary-button" role="button" type="button" [disabled]="RoleAccess=='V'" (click)="cancel(deliveryportfolioform)" aria-label="Reset">Reset</button>
          <input href="javascript:void(0)" class="o-primary-button" role="button" type="submit" value="Save" [disabled]="RoleAccess=='V'" aria-label="Save" />
        </div>
      </div>
    </div>
  </div>
  <div class="c-select-card__placeholder"></div>
</form>
