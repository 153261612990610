import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PlatformAccessService } from '../../core/services/platform.access.service';
import { SessionStorage } from '../../core/context/sessionstorage';
import { Router, ActivatedRoute } from '@angular/router';
var $ = (<any>window).$;
import { ScopeSelectorComponent } from '../scope.selector.component/scope.selector.component';
import { HeaderComponent } from '../header.component/header.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { SharedService } from '../../core/services/shared.service';
import { BroadCastService } from '../../core/services/broadcast.service';
declare var window: any;
@Component({
  templateUrl: './platformaccess.component.html',
  providers: [PlatformAccessService, ScopeSelectorComponent, BsModalService, HeaderComponent]
})

export class PlatformAccess {
  curPage: number = 0;
  curPageAFSM: number = 0;
  pageSize: number = 10;
  isViewerRole: boolean = false;
  Clientdropdownshow = true;
  projectdropdownshow = true;
  loadasfmgrid = false;
  disableField: boolean = false;
  projectButton = 'Select';
  searchTextEntirepriseId: any;
  searchTextResourceName: any;
  searchTextEmail: any;
  searchTextCountry: any;
  searchTextRole: any;
  clientButton = 'Select';
  redirectId = this.sessionStorage.endToEndUId;
  ParentTileId = this.sessionStorage.parentTileId;
  redirectValue = 'E2E';
  Users: any;
  TotalUsers: any;
  TotalUserscount: number;
  NoOfPages: number;
  FilterUsers: any;
  platformAccess: any;
  Clients: any;
  projects: any;
  selectedRoleAD: any;
  selectedRoleAM: any;
  selectedRoleASFM: any;
  selectedFactoryRoleASFM: any;
  selectedRoleMyWizard: any;
  selectedAll: boolean = false;
  roledataMyWizard: any;
  newcollection = new Array();
  columnDefs: any;
  frameworkComponents: any;
  startIndex: number = 0;
  endIndex: number;
  searchValue: any;
  SearchRecords: any;
  //SearchEnterpriseID: any;
  //SearchResourceName: any;
  //SearchResourceEmail: any;
  //SearchCountry: any;
  //SearchRole: any;
  //isPopupOpen: any;
  selectedUserList: any;
  modalRef_announcement: BsModalRef;
  ListPlatformUsers: any;
  event: any;
  PlatformAccessTabSetup = new EventEmitter();

  constructor(private platformService: PlatformAccessService, private sessionStorage: SessionStorage, private router: Router, private activatedRoute: ActivatedRoute
    , private scopeSelectorComponent: ScopeSelectorComponent, private modalService: BsModalService, private bsModalRef: BsModalRef, private headerComponent: HeaderComponent, private sharedService: SharedService, private broadCastService: BroadCastService) { }

  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }
  OnBackClick() {
    this.router.navigate(['/mywizard/manage', 'E2E', this.redirectId], { relativeTo: this.activatedRoute });
    //this.router.navigate(['/mywizard/childtiles', this.ParentTileId, 'E2E', this.redirectId], { relativeTo: this.activatedRoute });
    this.headerComponent.ScopeSelecorVisible({
      isScopeSelectorVisible: true, isScopeSelectorDropDownVisible: true,
      isSetAsDefaultVisible: true
    });
  }
  @Output() ScopeSelecorVisible = new EventEmitter();
  ngOnInit() {
    window.scrollTo(0, 0);
    if (sessionStorage.roleAccessCode != "E")
      this.isViewerRole = true;
    this.getData();
    this.bsModalRef.hide();
    setTimeout(() => {
      window.TooltipFunc();
      window.dropdownComponent();
      window.tabsComponent();
    }, 0);
    this.broadCastService.broadcastScopeSelector({
      isScopeSelectorVisible: true,
      isScopeSelectorDropDownVisible: true,
      isClientDropDownDisabled: false,
      isSetAsDefaultVisible: false,
      isDisableTeamScopeSelector: true
    });
  }

  getData() {
    this.platformService.getPlatformData().subscribe(response => {
      let User: any = response;
      this.TotalUsers = User.ListPlatformUsers;
      this.TotalUserscount = this.TotalUsers.length;
      this.startIndex = 0;
      this.endIndex = this.pageSize;
      this.Users = this.TotalUsers.slice(this.startIndex, this.endIndex);

      this.platformAccess = response;
      if (this.platformAccess.ListPlatformUsers && this.platformAccess.ListPlatformUsers.length > 0) {
        $("#platformaccess").attr("style", "height:350px");

        this.Clients = this.platformAccess.ListClientDetails;
        this.projects = this.platformAccess.ListASFMDetails;
      }
      this.NoOfPages = this.numberOfPages();
      this.numberofpagesafsm();
      this.getDelivery();
      if (this.isViewerRole) {
        this.disableField = true;
      }
      this.selectedAll = false;
    });
  }

  callFilterPagination() {
    this.TotalUserscount = this.FilterUsers.length;
    this.NoOfPages = this.FilternumberOfPages();
  }
  search() {
    this.SearchRecords = this.searchTextEntirepriseId.toLocaleLowerCase();
    this.searchResults();
  }
  //searchTextResourceNames() {
  //  this.SearchResourceName = this.searchTextResourceName.toLocaleLowerCase();
  //  this.searchResults();
  //}
  //searchTextEmails() {
  //  this.SearchResourceEmail = this.searchTextEmail.toLocaleLowerCase();
  //  this.searchResults();
  //}
  //searchTextCountrys() {
  //  this.SearchCountry = this.searchTextCountry.toLocaleLowerCase();
  //  this.searchResults();
  //}
  //searchTextRoles() {
  //  this.SearchRole = this.searchTextRole.toLocaleLowerCase();
  //  this.searchResults();
  //}
  searchResults() {
    this.FilterUsers = this.platformAccess.ListPlatformUsers.filter((searchText) => {
      return (
        (!this.SearchRecords || ((searchText.EnterpriseId.toLowerCase().indexOf(this.SearchRecords) !== -1) ||
          (searchText && searchText.FullName.toLowerCase().indexOf(this.SearchRecords) !== -1) ||
          (searchText && searchText.EmailID.toLowerCase().indexOf(this.SearchRecords) !== -1) ||
          (searchText && searchText.Country.toLowerCase().indexOf(this.SearchRecords) !== -1) ||
          (searchText && searchText.Role.toLowerCase().indexOf(this.SearchRecords) !== -1)))
        //&& (!this.SearchResourceName || (searchText && searchText.FullName.toLowerCase().indexOf(this.SearchResourceName) !== -1))
        //&& (!this.SearchResourceEmail || (searchText && searchText.EmailID.toLowerCase().indexOf(this.SearchResourceEmail) !== -1))
        //&& (!this.SearchCountry || (searchText && searchText.Country.toLowerCase().indexOf(this.SearchCountry) !== -1))
        //&& (!this.SearchRole || (searchText && searchText.Role.toLowerCase().indexOf(this.SearchRole) !== -1))
      )
    });
    this.curPage = 0;
    this.Users = this.FilterUsers.slice(0, this.pageSize);
    this.callFilterPagination();
  }
  paginationClick(pageno: number) {
    this.startIndex = (pageno) * this.pageSize;
    this.endIndex = (pageno) * this.pageSize + this.pageSize;
    if (!this.SearchRecords != undefined && this.SearchRecords != null)
      this.Users = this.FilterUsers.slice(this.startIndex, this.endIndex);
    else
      this.Users = this.TotalUsers.slice(this.startIndex, this.endIndex);
    this.selectedUserList = this.Users.filter((x: any) => x.Selected == true);
    if (this.selectedUserList && this.selectedUserList.length == this.Users.length) {
      this.selectedAll = true;
    } else {
      this.selectedAll = false;
    }
    setTimeout(function () {
      window.TooltipFunc();
    }, 0);
  }
  numberOfPages() {
    if (this.TotalUsers != undefined && this.TotalUsers != null)
      return Math.ceil(this.TotalUsers.length / this.pageSize);
    else
      return 0;
  }
  FilternumberOfPages() {
    if (this.FilterUsers != undefined && this.FilterUsers != null)
      return Math.ceil(this.FilterUsers.length / this.pageSize);
    else
      return 0;
  }
  numberofpagesafsm() {
    if (this.platformAccess != undefined && this.platformAccess != null && this.platformAccess.ListASFMUsers != null)
      return 0;
  };
  getDelivery() {
    var roledataAD = ['select', 'Delivery Manager', 'Team'];
    var roledataAM = ['select', 'Project Manager', 'Team Member'];
    var roledataFactoryASFM = ['select', 'Factory Manager', 'Team'];
    var roledataASFM = ['select', 'Client Lead', 'Client Service Lead', 'Project Manager'];
    this.roledataMyWizard = [{ Id: 1, Name: 'select' }, { Id: 2, Name: 'Project Manager' }, { Id: 3, Name: 'Team Member' }];

    this.selectedRoleAD = roledataAD[0];
    this.selectedRoleAM = roledataAM[0];
    this.selectedRoleASFM = roledataASFM[0];
    this.selectedFactoryRoleASFM = roledataFactoryASFM[0];
    this.selectedRoleMyWizard = this.roledataMyWizard[0].Name;
    this.Clientdropdownshow = false;
    this.projectdropdownshow = false;
    this.curPage = 0;
    this.searchTextEntirepriseId = null;
    this.SearchRecords = null;
    this.selectedAll = false;
  }


  grantRevokeAccess(IsGrantAccess: any) {
    this.GrantRevokeAccess(IsGrantAccess);
  };

  disableAccess() {
    var count = 0;
    if (this.Users != null && this.Users != undefined) {
      this.Users.forEach(user => {
        if (user.Selected == true) count++;
      });
    }
    return count;
  }

  disableAccessASFM() {
    var countASFM = 0;
    if (this.platformAccess != null && this.platformAccess != undefined && this.platformAccess.ListASFMUsers != null) {
      this.platformAccess.ListASFMUsers.forEach(user => {
        if (user.SelectedASFM == true) countASFM++;
      });
    }

    return countASFM;
  }

  grantAccessASFM(IsGrantAccess: any) {
    if (IsGrantAccess) {
      if ($('#PlatformAccessEnterpriseId').val() != $('#hidePlatformAccessEnterpriseId').val()) {
        this.sharedService.openAlertPopup('Please Enter Valid EnterpriseId.', window.scrollY);
        return false;
      }
    }
    var SFEdetails = "";
    if (IsGrantAccess) {
      if (this.selectedRoleASFM == "Project Manager") {
        this.platformAccess.ListASFMDetails.forEach(project => {
          if (project.selected == true) {
            SFEdetails = SFEdetails + project.Id + ",";
          }
        });
        SFEdetails = SFEdetails.replace(/,\s*$/, "");
        if (SFEdetails == "") {
          this.sharedService.openAlertPopup('Please Select Any Items in Project List', window.scrollY);
          return false;
        }
      }
      else if (this.selectedRoleASFM == "Client Lead" || this.selectedRoleASFM == "Client Service Lead") {
        this.platformAccess.ListClientDetails.forEach(client => {
          if (client.selected == true) {
            SFEdetails = SFEdetails + client.Id + ",";
          }
        });
        SFEdetails = SFEdetails.replace(/,\s*$/, "");
        if (SFEdetails == "") {
          this.sharedService.openAlertPopup('Please Select Any Items in Client List', window.scrollY);
          return false;
        }

      }
    }
    GrantRevokeAccessASFM(IsGrantAccess, SFEdetails);
  };
  checkAll(event: any) {
    if (event.target.checked) {
      this.selectedAll = true;
      this.selectedUserList = this.Users;
    } else {
      this.selectedAll = false;
      this.selectedUserList = [];
    }
    this.Users.forEach(user => {
      user.Selected = this.selectedAll;
    });
  };

  selectUser(event: any, user: any) {
    if (event.target.checked) {
      this.Users.filter((x: any) => {
        if (x.EnterpriseId == user.EnterpriseId) {
          user.Selected = true;
        }
      })
      this.selectedUserList = this.Users.filter((x: any) => x.Selected == user.Selected);
      if (this.selectedUserList && this.selectedUserList.length == this.Users.length) {
        this.selectedAll = true;
      } else {
        this.selectedAll = false;
      }
    }
    else {
      this.selectedAll = false;
    }
  }

  ChangeRole(event: any) {
    this.selectedRoleMyWizard = event.target.value;
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  GrantRevokeAccess(IsGrantAccess: any) {
    var GrantAccess = ({
      E2EId: this.sessionStorage.endToEndId,
      ADResourceRole: [],
      AMResourceRole: [],
      DeliveryType: this.platformAccess.DeliveryTypes,
      FactoryIndicator: this.platformAccess.FactoryIndicator,
      MyWizardIndicator: this.platformAccess.MyWizardIndicator,
      IsGrantAccess: IsGrantAccess,
      ModifiedBy: this.sessionStorage.enterpriseId
    });
    var IsRolePresent;
    var showAlertAD = false;
    var showAlertAM = false;
    let showFactoryAlert = false;
    var alertMessage = "";
    let hideAlert = false;
    var existingUsersWithRole = new Array();
    var userCount = 0;
    let hasMultipleRole = false;
    if (this.selectedRoleMyWizard == 1 || this.selectedRoleMyWizard == 2 || this.selectedRoleMyWizard == 3) {
      this.selectedRoleMyWizard = this.roledataMyWizard[this.selectedRoleMyWizard - 1].Name;
    }
    IsRolePresent = this.CheckEmptyRole(IsGrantAccess);
    if (!IsRolePresent) {
      this.sharedService.openAlertPopup('There are no roles associated with the user to revoke access.', window.scrollY);
      return false;
    }

    if (this.platformAccess.MyWizardIndicator) {
      if (IsGrantAccess && this.selectedRoleMyWizard == 'select') {
        this.sharedService.openAlertPopup('select any role on MyWizard.', window.scrollY);
        return false;
      }
      this.TotalUsers.forEach(user => {
        if (user.Selected == true) {
          if (user.Role != null && user.Role != "" && user.Role != this.selectedRoleMyWizard && IsGrantAccess) {
            hasMultipleRole = true;
            user.Selected = false;
            existingUsersWithRole[userCount] = user.EnterpriseId;
            userCount += 1;
          }
          else if (user.Role == this.selectedRoleMyWizard && IsGrantAccess) {
            showFactoryAlert = true;
            user.Selected = false;
          }
          else {
            user.Selected = true;
            hideAlert = true;
            GrantAccess.ADResourceRole.push({ EnterpriseId: user.EnterpriseId, IsGrantAccess: IsGrantAccess, Role: IsGrantAccess ? this.selectedRoleMyWizard : user.Role, SFEOrClientId: 0 });
            user.Selected = false;
          }
        }
      });
      if (hasMultipleRole != false && hideAlert != false) {
        alertMessage = "One or more resource(s) have been provided both Project Manager and Team Member roles. Both roles cannot coexist for the same resource, please revoke either of the roles to associate a distinct role.";
        this.sharedService.openAlertPopup(alertMessage, window.scrollY);
      }
      else if (hasMultipleRole != false && hideAlert == false) {
        alertMessage = "One or more resource(s) have been provided both Project Manager and Team Member roles. Both roles cannot coexist for the same resource, please revoke either of the roles to associate a distinct role.";
        this.sharedService.openAlertPopup(alertMessage, window.scrollY);
        this.selectedAll = false;
        return false;
      }
      else if (showFactoryAlert != false && hideAlert != false) {
        alertMessage = "The user role that you are trying to provide is already associated to one or more of the selected users. Please reassign with a different role.";
        this.sharedService.openAlertPopup(alertMessage, window.scrollY);
      }
      else if (showFactoryAlert != false && hideAlert == false) {
        alertMessage = "The user role that you are trying to provide is already associated to one or more of the selected users. Please reassign with a different role.";
        this.sharedService.openAlertPopup(alertMessage, window.scrollY);
        this.selectedAll = false;
        return false;
      }
    }

    this.platformService.saveUsers(GrantAccess).subscribe(response => {
      var messagedata = IsGrantAccess ? "Access grant failed for selected user(s). Please try after sometime" : "Access revoke failed for selected user(s). Please try after sometime";
      if (response == "Success") {
        messagedata = IsGrantAccess ? "Access has been granted successfully for selected user(s)." : "Access has been successfully revoked for selected user(s).";
      }
      this.sharedService.openAlertPopup(messagedata, window.scrollY);
      this.getData();
    });
  }

  CheckEmptyRole(IsGrantAccess) {
    var IsRolePresent = true;
    if (!IsGrantAccess) {
      IsRolePresent = false;
      this.Users.forEach(user => {
        if (!IsRolePresent) {
          if (user.Selected == true && user.Role != null && user.Role != undefined && user.Role != "") {
            IsRolePresent = true;
          }
        }
      });
    }
    return IsRolePresent;
  }


} // END of Export


function LoadAutoCompleteTextForContacts(UserService) {
  $('#PlatformAccessEnterpriseId').autocomplete({
    source: function (request, response) {

      GetEnterPriseIdListfromText(UserService, $('#PlatformAccessEnterpriseId').val(), response);
    },
    minLength: 2,
    select: function (event, ui) {

      $('#PlatformAccessEnterpriseId').val(ui.item.value);
      $('#hidePlatformAccessEnterpriseId').val(ui.item.value);

      return false;
    }
  });
}
function GetEnterPriseIdListfromText(UserService, text, response) {

  UserService.getEnterPriseIdList(text).then(function (json) {
    response($.map(json, function (item) {

      return {
        value: item.EnterpriseId

      }

    }));
  });

}

//function GrantRevokeAccess1(IsGrantAccess: any) {

//  var GrantAccess = ({
//    E2EId: this.sessionStorage.endToEndId,
//    ADResourceRole: [],
//    AMResourceRole: [],
//    DeliveryType: this.platformAccess.DeliveryTypes,
//    FactoryIndicator: this.platformAccess.FactoryIndicator,
//    MyWizardIndicator: this.platformAccess.MyWizardIndicator,
//    IsGrantAccess: IsGrantAccess,
//    ModifiedBy: sessionStorage.enterpriseId
//  });
//  var IsRolePresent;
//  var showAlertAD = false;
//  var showAlertAM = false;
//  var showFactoryAlert = false;
//  var alertMessage = "";
//  var hideAlert = false;
//  var existingUsersWithRole = new Array();
//  var userCount = 0;
//  var hasMultipleRole = false;
//  IsRolePresent = CheckEmptyRole1(IsGrantAccess, this);
//  if (!IsRolePresent) {
//     this.sharedService.openAlertPopup('There are no roles associated with the user to revoke access.');
//    return false;
//  }

//  if (this.platformAccess.MyWizardIndicator) {
//    if (IsGrantAccess && this.selectedRoleMyWizard == 'select') {
//       this.sharedService.openAlertPopup('select any role on MyWizard.');
//      return false;
//    }
//    this.Users.forEach(user => {
//      if (user.Selected == true) {
//        if (user.Role != null && user.Role != "" && user.Role != this.selectedRoleMyWizard && IsGrantAccess) {
//          hasMultipleRole = true;
//          user.Selected = false;
//          existingUsersWithRole[userCount] = user.EnterpriseId;
//          userCount += 1;
//        }
//        else if (user.Role == this.selectedRoleMyWizard && IsGrantAccess) {
//          showFactoryAlert = true;
//          user.Selected = false;
//        }
//        else {
//          user.Selected = true;
//          hideAlert = true;
//          GrantAccess.ADResourceRole.push({ EnterpriseId: user.EnterpriseId, IsGrantAccess: IsGrantAccess, Role: IsGrantAccess ? this.selectedRoleMyWizard : user.Role, SFEOrClientId: 0 });
//        }
//      }
//    });
//    if (this.hasMultipleRole == true && this.hideAlert == true) {
//      alertMessage = "One or more resource(s) have been provided both Delivery Manager and Team Member roles. Both roles cannot coexist for the same resource, please revoke either of the roles to associate a distinct role.";
//       this.sharedService.openAlertPopup(alertMessage);
//    }
//    else if (this.hasMultipleRole == true && hideAlert == false) {
//      alertMessage = "One or more resource(s) have been provided both Delivery Manager and Team Member roles. Both roles cannot coexist for the same resource, please revoke either of the roles to associate a distinct role.";
//       this.sharedService.openAlertPopup(alertMessage);
//      this.selectedAll = false;
//      return false;
//    }
//    else if (this.showFactoryAlert == true && this.hideAlert == true) {
//      alertMessage = "The user role that you are trying to provide is already associated to one or more of the selected users. Please reassign with a different role.";
//       this.sharedService.openAlertPopup(alertMessage);
//    }
//    else if (this.showFactoryAlert == true && hideAlert == false) {
//      alertMessage = "The user role that you are trying to provide is already associated to one or more of the selected users. Please reassign with a different role.";
//       this.sharedService.openAlertPopup(alertMessage);
//      this.selectedAll = false;
//      return false;
//    }
//  }

//}

function GrantRevokeAccessASFM(IsGrantAccess, SFEdetails) {
  var showASFMAlert = false;
  if (IsGrantAccess && this.selectedRoleASFM == 'select') {
    this.sharedService.openAlertPopup('select any role on ASFM', window.scrollY);
    return false;
  }
  var GrantAccess = ({
    E2EId: sessionStorage.e2eProjectId,
    ADResourceRole: [],
    AMResourceRole: [],
    DeliveryType: this.platformAccess.DeliveryTypes,
    FactoryIndicator: this.platformAccess.FactoryIndicator,
    ModifiedBy: sessionStorage.EnterpriseId
  });
  if (this.platformAccess.FactoryIndicator && !IsGrantAccess) {
    this.platformAccess.ListASFMUsers.forEach(user => {
      if (user.SelectedASFM == true) {
        GrantAccess.ADResourceRole.push({ EnterpriseId: user.EnterpriseId, IsGrantAccess: IsGrantAccess, Role: IsGrantAccess ? this.selectedRoleASFM : user.Role, SFEOrClientId: IsGrantAccess ? SFEdetails : user.SFEE2Eid + user.ClientId })
      }
    });
  }
  else if (this.platformAccess.FactoryIndicator && IsGrantAccess) {
    this.platformAccess.ListASFMUsers.forEach(user => {
      if (user.EnterpriseId == $('#PlatformAccessEnterpriseId').val() && user.Role == this.selectedRoleASFM) {
        showASFMAlert = true;
      }
    });
    if (showASFMAlert) {
      var alertMessage = "The user role that you are trying to provide is already associated to one or more of the selected users. Please reassign with a different role.";
      this.sharedService.openAlertPopup(alertMessage, window.scrollY);
      return false;
    }
    else {
      GrantAccess.ADResourceRole.push({ EnterpriseId: $('#PlatformAccessEnterpriseId').val(), IsGrantAccess: IsGrantAccess, Role: this.selectedRoleASFM, SFEOrClientId: IsGrantAccess ? SFEdetails : this.user.SFEE2Eid + this.user.ClientId })
    }
  }

  this.platformService.saveUsers(GrantAccess).then(function (response) {
    if (response) {
      var messagedata = IsGrantAccess ? "Access has been granted successfully for selected user(s)." : "Access has been successfully revoked for selected user(s).";
      this.sharedService.openAlertPopup(messagedata, window.scrollY);
      this.paEnterpriseId = null;
      this.clearAllProjects();
      this.clearAllClients();
      this.getData();
    }
  });
  this.clearPopUp();
}

function CheckEmptyRole1(IsGrantAccess, $scope) {
  var IsRolePresent = true;
  if (!IsGrantAccess) {
    IsRolePresent = false;
    this.Users.forEach(user => {
      if (!IsRolePresent) {
        if (user.Selected == true && user.Role != null && user.Role != undefined && user.Role != "") {
          IsRolePresent = true;
        }
      }
    });
  }
  return IsRolePresent;
}




