import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SessionStorage } from '../../../../core/context/sessionstorage';
import { DemographicsService } from '../../../../core/services/demographics.service';
import { ScopeSelectorComponent } from '../../../scope.selector.component/scope.selector.component';
import { InitiateProfileSharedService } from '../../initiate.profile.shared.service';
import { ScopeComponent } from '../../scope.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HeaderComponent } from 'src/app/home/header.component/header.component';
import { InitiateProfileComponent } from '../../initiate.profile.component';
import { SharedService } from '../../../../core/services/shared.service';

var $ = (<any>window).$;

@Component({
  templateUrl: './phoenix.url.configuration.component.html',
  providers: [DemographicsService, InitiateProfileSharedService, ScopeSelectorComponent,HeaderComponent,InitiateProfileComponent]
})

export class PhoenixUrlConfigurationComponent {
  scrollYPosition: any;
  constructor(private scopeComponent: ScopeComponent, private sessionStorage: SessionStorage, private demographicService: DemographicsService,
    private initiateProfileSharedService: InitiateProfileSharedService, private scopeSelectorComponent: ScopeSelectorComponent, private bsModalRef: BsModalRef,private sharedService: SharedService) { }

  public apiModalPopupData: EventEmitter<any> = new EventEmitter();
  scopeDetails: any;
  name: any;
  public phoenixBaseUrlConfiguration = <any>{};
  User: string;
  endToEndType = this.sessionStorage.endToEndType;
  ngOnInit() {
    $('body').addClass('stop-scrolling');
    setTimeout(function () {
      $('.close').focus();
    }, 0)
  }
   
  SavePhoenixBaseUrlConfiguration(isValid: any, phoenixBaseUrlConfigurationForm: NgForm) {
    if (isValid) {
      this.phoenixBaseUrlConfiguration.User = this.sessionStorage.enterpriseId;
      this.demographicService.savePhoenixBaseUrlConfiguration(this.phoenixBaseUrlConfiguration).subscribe((response: any) => {
        if (response.ResponseMessage == "The Base URL is already present") {
          this.sharedService.openAlertPopup(response.ResponseMessage, window.scrollY);
        } else {
          $('#phoenixBaseUrlConfigurationPopUp').modal('hide');
        }
        this.sharedService.openAlertPopup('Saved Successfully', window.scrollY);
       

      });
      if (phoenixBaseUrlConfigurationForm != undefined) phoenixBaseUrlConfigurationForm.resetForm();
      this.bsModalRef.hide();
    }
    this.apiModalPopupData.emit({ saveData: true });

  }
  cancelBaseUrlConfiguration(phoenixBaseUrlConfigurationForm: NgForm) {
    if (phoenixBaseUrlConfigurationForm != undefined) phoenixBaseUrlConfigurationForm.resetForm();
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    $('body').removeClass('stop-scrolling');
    window.scrollTo(0, this.scrollYPosition);
  }
}
