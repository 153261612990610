import { Component, Input } from '@angular/core'
import { SessionStorage } from '../../core/context/sessionstorage';
import { TileService } from '../../core/services/tile.service';
import * as dayjs from 'dayjs';
var $ = (<any>window).$;
declare var window: any;

declare var window: any;
@Component({
  selector: 'delivery-dashboard',
  templateUrl: './delivery.dashboard.component.html'
})
export class DeliveryDashboardComponent {
  narrativeStatus: any;
  SubmissionType: number;
  redCount: number;
  greenCount: number;
  amberCount: number;
  @Input()
  Tile: any;
  constructor(private tileService: TileService, private sessionStorage: SessionStorage) { }
  ngOnInit() {
    this.getNarrativeStatusDetails();
    //this.getMetricDetails();
    setTimeout(() => {
      window.TooltipFunc();
    }, 0);
  }
  getNarrativeStatusDetails() {
    this.tileService.getGovernanceNarrativeStatus(this.sessionStorage.selectedSubmissionType).subscribe((response: any) => {
     
      this.narrativeStatus = response;
      this.narrativeStatus.DueDate = dayjs(this.narrativeStatus.DueDate, "DD-MMM-YYYY hh:mm A").isValid() ?
        dayjs.utc(this.narrativeStatus.DueDate, "DD-MMM-YYYY hh:mm A").local().format('DD-MMM-YYYY hh:mm A') : this.narrativeStatus.DueDate;
        setTimeout(() => {
          window.TooltipFunc();
        }, 0);
    });
  }
  getMetricDetails() {
    var dateSplit = this.sessionStorage.currentPeriod.split('-');
    this.tileService.getProjectMetrics(dateSplit[0], dateSplit[1], dateSplit[2], this.sessionStorage.endToEndUId ?
      this.sessionStorage.endToEndUId : null, this.sessionStorage.clientWMSUId ? this.sessionStorage.clientWMSUId : null,
      this.sessionStorage.workstreamUId ? this.sessionStorage.workstreamUId : null, this.sessionStorage.teamUId ? this.sessionStorage.teamUId : null,
      this.sessionStorage.selectedSubmissionType).subscribe((response: any) => {
        this.redCount = parseInt(response.RedMetricCount || 0);
        this.greenCount = parseInt(response.GreenMetricCount || 0);
        this.amberCount = parseInt(response.AmberMetricCount || 0);

      });
  }
}
