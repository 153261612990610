import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionStorage } from '../context/sessionstorage';

@Injectable()
export class TileService {

  constructor(private httpService: HttpService, private sessionStorage: SessionStorage) { }

  getTiles(areaname) {
    let tileInput = {
      endtoendUId: this.sessionStorage.endToEndUId == '' ? null : this.sessionStorage.endToEndUId,
      workstreamId: this.sessionStorage.workstreamId == undefined ? 0 : this.sessionStorage.workstreamId,
      roleTypeCode: this.sessionStorage.roleTypeCode == undefined ? null : this.sessionStorage.roleTypeCode,
      enterpriseId: this.sessionStorage.enterpriseId == undefined ? null : this.sessionStorage.enterpriseId,
      teamUId: this.sessionStorage.teamUId == '' ? null : this.sessionStorage.teamUId,
      cmsUId: this.sessionStorage.clientWMSUId == '' ? null : this.sessionStorage.clientWMSUId,
      workstreamUId: this.sessionStorage.workstreamUId == '' ? null : this.sessionStorage.workstreamUId
    }
    var isEnableLoader = areaname == "Help Section" ? false : true;
    var deployRegion = this.sessionStorage.deployRegion == '' ? null : this.sessionStorage.deployRegion;
    var clientId = !this.sessionStorage.clientId ? 0 : this.sessionStorage.clientId;

    return this.httpService.post('/api/tile/tab/' + areaname + '/client/' + clientId + '/endtoend/' +
      this.sessionStorage.endToEndId + '/entity/' + this.sessionStorage.selectedSubmissionType + '/region/' + deployRegion, tileInput, isEnableLoader);
  }
  getReleaseNotes(isCritical: boolean = false, isScopeSelectorVisible: boolean) {
    return this.httpService.get('/api/ReleaseNotes?isCritical=' + (isCritical == undefined ? false : isCritical), isScopeSelectorVisible);
  }
  getTileforParent(tileid) {
    var tmsUId = this.sessionStorage.teamUId == '' ? null : this.sessionStorage.teamUId; 
    var cmsId = (this.sessionStorage.selectedSubmissionType != undefined && this.sessionStorage.selectedSubmissionType != null && this.sessionStorage.selectedSubmissionType == 2) ? this.sessionStorage.clientWMSId : 0;
    var workstreamId = this.sessionStorage.workstreamId == undefined ? 0 : this.sessionStorage.workstreamId;
    var endtoendUId = this.sessionStorage.endToEndUId == '' ? null : this.sessionStorage.endToEndUId;
    var deployRegion = this.sessionStorage.deployRegion == '' ? null : this.sessionStorage.deployRegion;
    var roleTypeCode = this.sessionStorage.roleTypeCode == undefined ? null : this.sessionStorage.roleTypeCode;
    return this.httpService.get('/api/tile/' + tileid + '/child/client/' + this.sessionStorage.clientId + '/endtoend/' + this.sessionStorage.endToEndId + '/entity/' + this.sessionStorage.selectedSubmissionType + '/region/' + deployRegion + '?endtoendUId=' + endtoendUId + '&cmsId=' + cmsId + '&tmsUId=' + tmsUId + '&workstreamId=' + workstreamId + '&enterpriseId=' + this.sessionStorage.enterpriseId + '&roleTypeCode=' + roleTypeCode, true);
  }
  getGovernanceNarrativeStatus(submissionType) {
    return this.httpService.get('/api/Tile/NarrativeTrend/' + submissionType + '/' + this.sessionStorage.currentPeriod + '/' + this.sessionStorage.endToEndUId + '/' +
      (this.sessionStorage.cmsName ? this.sessionStorage.clientWMSUId : null) + '/' + (this.sessionStorage.teamUId ? this.sessionStorage.teamUId : null)
      + '/' + (this.sessionStorage.workstreamUId ? this.sessionStorage.workstreamUId : null));
  }
  getProvisonDeliveryPlatform(e2eProjectId) {
    return this.httpService.get('/api/Projects/' + e2eProjectId + '/Provisions');
  }
  getRIM() {
    return this.httpService.get('/api/RIMSummary?EndToEndUId=' + (this.sessionStorage.endToEndUId ? this.sessionStorage.endToEndUId : "null") + '&CmsUId=' + (this.sessionStorage.clientWMSUId ? this.sessionStorage.clientWMSUId : "null") + '&WorkstreamUId=' + (this.sessionStorage.workstreamUId ? this.sessionStorage.workstreamUId : "null") + '&TeamUId=' + (this.sessionStorage.teamUId ? this.sessionStorage.teamUId : "null") + '&SubmissionType=' + this.sessionStorage.selectedSubmissionType + '&deployRegion=' + this.sessionStorage.deployRegion, false);
  }
  getProjectMetrics(year, month, day, endToEndUId, cmsUId, workstreamUId, teamUId, selectedSubmissionType) {
    return this.httpService.get('/api/Metrics/ReportMetrics/' + year + '/' + month + '/' + day + "?endToEndUId=" + endToEndUId + "&cmsUId=" + cmsUId + "&workstreamUId=" + workstreamUId + "&teamUId=" + teamUId + "&submissionType=" + selectedSubmissionType);
  }
  getCommandCenterMetrics(year, month, day, endToEndUId, cmsUId, teamUId, selectedSubmissionType) {
    return this.httpService.get('/api/Metrics/CommandCenter/' + year + '/' + month + '/' + day + "?endToEndUId=" + endToEndUId + "&cmsUId=" + cmsUId + "&teamUId=" + teamUId + "&submissionType=" + selectedSubmissionType);
  }
  getAutomationJourney() {
    var deployRegion = this.sessionStorage.deployRegion == '' ? null : this.sessionStorage.deployRegion;
    return this.httpService.get('/api/AutomationJourney/' + this.sessionStorage.endToEndUId + '/' + deployRegion);
  }
  getComplianceStatus(endToEndUId, cmsUId, workstreamUId, teamUId, selectedSubmissionType) {
    return this.httpService.get("/api/Metrics/ComplianceStatus/?endToEndUId=" + endToEndUId + "&cmsUId=" + cmsUId + "&workstreamUId=" + workstreamUId + "&teamUId=" + teamUId + "&submissionType=" + selectedSubmissionType);
  }
  NotificationMessage() {
    return this.httpService.get('/api/NotificationMessage', true);
  }
  validateProfile(isExternalAPI) {
    var deployRegion = this.sessionStorage.deployRegion == '' ? null : this.sessionStorage.deployRegion;
    return this.httpService.get('/api/endtoend/' + this.sessionStorage.endToEndId + '/' + this.sessionStorage.endToEndUId + '/profile/valid/' + this.sessionStorage.selectedSubmissionType + '/' + this.sessionStorage.endToEndType + '/' + deployRegion + '?endtoendTypeCode=' + this.sessionStorage.endToEndRefCode + '&isExternalAPI=' + isExternalAPI, true);
  }
  getActionManager(deliveryConstructUId, teamUId) {
    return this.httpService.get('/api/ActionManager/' + deliveryConstructUId + '?teamUId=' + teamUId);
  }
  isValidGUId(guid) {
    return true ? /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(guid) : false;
  }
  isValidString(str) {
    str = str.toLowerCase();
    return false ? /^[(&lt;script&gt;)(&lt;\/script&gt;)(<script>)(<\/script>)(alert\()(alert )(onload=)(onmouseover=)(onerror=),\g]+$/.test(str) : true;
    }
  isValidId(id) {
    return true ? (/^\d+$/.test(id.toString())) : false;
  }

}
