import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../core/services/shared.service';

var $ = (<any>window).$;
declare var window: any;

@Component({
  templateUrl: './service.group.network.edit.component.html',
})


export class ServiceGroupNetworkEditComponent {

  i: any;
  item: any;
  RoleAccess: any;
  KeyContactsDetails: any;
  isInvalidEnterpriseId: boolean = false;
  oldItem: any;
  DeliveryLead: any;

  public ServiceGroupNetworkKeyContactsDetails : EventEmitter<any> = new EventEmitter();  // .. Child Popup -> Parent


  constructor(private bsModalRef: BsModalRef,private sharedService: SharedService) { }

  ngOnInit() {
    this.oldItem = this.KeyContactsDetails.EndToEndServiceGroupContacts[this.i].DeliveryLead;
    this.DeliveryLead = this.item.DeliveryLead;
   
  }

  DeiveryLeadChange(deliveryLead, $event) {

  }

  CancelService() {
    this.item.DeliveryLead = this.oldItem;
    this.bsModalRef.hide();
  }
  SaveService() {
    var enterpriseId = this.DeliveryLead;
    if (enterpriseId?.length > 0) {
      if ($('#hiddenserviceGroupLead_').val() != enterpriseId) {
        this.sharedService.openAlertPopup('Please enter valid Enterprise ID', window.scrollY);
        this.isInvalidEnterpriseId = true;
        return false;
      }
    }

    this.KeyContactsDetails.EndToEndServiceGroupContacts[this.i].DeliveryLead = this.DeliveryLead;
    this.ServiceGroupNetworkKeyContactsDetails.emit({ KeyContactsDetails: this.KeyContactsDetails });
    this.bsModalRef.hide();
  }
}

