import { Component, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var window: any;


@Component({
  selector: 'metric-filter',
  templateUrl: './metrics.filter.component.html',
  providers: [BsModalService]
})
export class MetricFilterComponent {
  configureMetrics: any;

  metricDropDown: any;
  metricDropDownActive: any; //Dropdowns will load the options which have isActive=true only

  deliverFunctionDropDown: any;
  deliverFunctionDropDownActive: any;

  reportAgainstDropdown: any;
  reportAgainstDropdownActive: any;

  businessContextDropdown: any;
  businessContextDropdownActive: any;

  inScopeDropdown: any;
  inScopeDropdownActive: any;

  applicabilityDropdown: any;
  applicabilityDropdownActive: any;

  selectedMetricFilterId: number;
  selectedDFFilterId: number;
  selectedReportAgainstFilterId: number;
  selectedbusinessContextFilterId: number;
  selectedIsScopeFilterId: number;
  selectedApplicabilityId: number;
  filterMetricData: any;

  isThreshold: any;
  public applyFilters: EventEmitter<any> = new EventEmitter();

  constructor(
    private bsModalRef: BsModalRef,
    private modalService: BsModalService
  ) {

  }

  ngOnInit() {
    setTimeout(() => {
      window.dropdownComponent();
      window.tabsComponent();
    }, 0);
    this.metricDropDownActive = this.metricDropDown.filter(x => x.isActive == true);
    this.deliverFunctionDropDownActive = this.deliverFunctionDropDown.filter(x => x.isActive == true);
    this.reportAgainstDropdownActive = this.reportAgainstDropdown.filter(x => x.isActive == true);
    this.businessContextDropdownActive = this.businessContextDropdown.filter(x => x.isActive == true);
    if (!this.isThreshold) {
      this.inScopeDropdownActive = this.inScopeDropdown.filter(x => x.isActive == true);
    }
    this.applicabilityDropdownActive = this.applicabilityDropdown.filter(x => x.isActive == true);
  }

  //to close filter popup
  closeFilter() {
    this.bsModalRef.hide();
  }

  //to apply the filter in grid
  applyFilter() {
    this.applyFilters.emit({
      selectedMetricFilterId: this.selectedMetricFilterId,
      selectedDFFilterId: this.selectedDFFilterId,
      selectedReportAgainstFilterId: this.selectedReportAgainstFilterId,
      selectedbusinessContextFilterId: this.selectedbusinessContextFilterId,
      selectedIsScopeFilterId: this.selectedIsScopeFilterId,
      selectedApplicabilityId: this.selectedApplicabilityId
    });
    this.closeFilter();
  }

  //clearing the filter in the grid and filter options
  clearFilter() {
    this.selectedMetricFilterId = 0;
    this.selectedDFFilterId = 0;
    this.selectedReportAgainstFilterId = 0;
    this.selectedbusinessContextFilterId = 0;
    this.selectedIsScopeFilterId = 0;
    this.selectedApplicabilityId = 0;

    this.metricDropDownActive.map(x => {
      x.IsSelected = false;
    })
    this.businessContextDropdownActive.map(x => {
      x.IsSelected = false;
    })
    this.deliverFunctionDropDownActive.map(x => {
      x.IsSelected = false;
    })
    this.reportAgainstDropdownActive.map(x => {
      x.IsSelected = false;
    })
    if (!this.isThreshold) {
      this.inScopeDropdownActive.map(x => {
        x.IsSelected = false;
      })
    }   
    this.applicabilityDropdownActive.map(x => {
      x.IsSelected = false;
    })


    this.applyFilters.emit({
      selectedMetricFilterId: this.selectedMetricFilterId,
      selectedDFFilterId: this.selectedDFFilterId,
      selectedReportAgainstFilterId: this.selectedReportAgainstFilterId,
      selectedbusinessContextFilterId: this.selectedbusinessContextFilterId,
      selectedIsScopeFilterId: this.selectedIsScopeFilterId,
      selectedApplicabilityId: this.selectedApplicabilityId
    })
    this.onChangeFilterValues();
  }



  //method will call if change any options in the filters 
  onChangeFilterValues() {

    this.filterMetricsToLoadOptions('MetricId');
    this.metricDropDown = this.refreshDropdowns('MetricId', this.metricDropDown);
    this.metricDropDownActive = this.metricDropDown.filter(x => x.isActive == true);

    this.filterMetricsToLoadOptions('BusinnesProcessId');
    this.deliverFunctionDropDown = this.refreshDropdowns('BusinnesProcessId', this.deliverFunctionDropDown);
    this.deliverFunctionDropDownActive = this.deliverFunctionDropDown.filter(x => x.isActive == true);

    this.filterMetricsToLoadOptions('WorkstreamTypeId');
    this.reportAgainstDropdown = this.refreshDropdowns('WorkstreamTypeId', this.reportAgainstDropdown);
    this.reportAgainstDropdownActive = this.reportAgainstDropdown.filter(x => x.isActive == true);

    this.filterMetricsToLoadOptions('BusinessContextId');
    this.businessContextDropdown = this.refreshDropdowns('BusinessContextId', this.businessContextDropdown);
    this.businessContextDropdownActive = this.businessContextDropdown.filter(x => x.isActive == true);

    this.filterMetricsToLoadOptions('IsSelected');
    this.inScopeDropdown = this.refreshDropdowns('IsSelected', this.inScopeDropdown);
    this.inScopeDropdownActive = this.inScopeDropdown.filter(x => x.isActive == true);

    this.filterMetricsToLoadOptions('ApplicabilityId');
    this.applicabilityDropdown = this.refreshDropdowns('ApplicabilityId', this.applicabilityDropdown);
    this.applicabilityDropdownActive = this.applicabilityDropdown.filter(x => x.isActive == true);

  }
  //to load the options for dropdown based on other selections
  filterMetricsToLoadOptions(propName: string) {
    this.filterMetricData = JSON.parse(JSON.stringify(this.configureMetrics));
    if (propName != 'MetricId') //to metric dropdown options, we don't need to apply the filter on metricId
      this.filterMetrics(this.selectedMetricFilterId, 'MetricId');
    if (propName != 'BusinnesProcessId')
      this.filterMetrics(this.selectedDFFilterId, 'BusinnesProcessId');
    if (propName != 'WorkstreamTypeId')'BusinnesProcessId'
      this.filterMetrics(this.selectedReportAgainstFilterId, 'WorkstreamTypeId');
    if (propName != 'BusinessContextId')
      this.filterMetrics(this.selectedbusinessContextFilterId, 'BusinessContextId');
    if (propName != 'IsSelected')
      this.filterMetrics(this.selectedIsScopeFilterId, 'IsSelected');
    if (propName != 'ApplicabilityId')
      this.filterMetrics(this.selectedApplicabilityId, 'ApplicabilityId');
  }

  // To filter the metrics based on selected filters
  filterMetrics(selectedFilterId, propName) {
    if (selectedFilterId != null && selectedFilterId != undefined && selectedFilterId != 0) {
      let filterId = selectedFilterId == -1 ? 0 : selectedFilterId;
      if (propName == 'BusinnesProcessId' || propName == 'WorkstreamTypeId') {
        this.filterMetricData = this.filterMetricData.filter((x) => {
          let isExist = false;
          x.BusiWSMappng.forEach((item) => {
            if (item[propName] == filterId) {
              isExist = true;
            }
          })
          return isExist;
        })
      }
      else {
        this.filterMetricData = this.filterMetricData.filter(x => x[propName] == filterId).map(x => x);
      }
    }
  }

  //set the isActive value to true which are require to show in dropdown options after applying the filters
  refreshDropdowns(propName: string, dropDownList: any) {
    var Ids = [];
    debugger;
    if (propName == 'BusinnesProcessId' || propName == 'WorkstreamTypeId') {
      Ids = this.filterMetricData.reduce((acc, cur) => {     
        cur.BusiWSMappng.forEach((item) => {
          acc.push(item[propName])
        })
        return acc;
      },[])
    }
    else {
      Ids = this.filterMetricData.map(x => x[propName]);
    }
    if (propName == 'IsSelected') {  // The Inscope value is boolean type in Metric Json, so changing to 1 if it is true or 0 for false.
      Ids.forEach((x, index) => {
        Ids[index] = x == true ? 1 : 0;
      })
    }
    dropDownList.forEach((x) => {
      let Id = x.Id == -1 ? 0 : x.Id;
      if (Ids.indexOf(Id) == -1) {
        x.isActive = false;
      }
      else {
        x.isActive = true;
      }
    })
    return dropDownList;
  }
}
