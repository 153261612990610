import { ChangeDetectorRef, Component, Output, EventEmitter } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import * as Enumerable from 'linq';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorage } from '../../../core/context/sessionstorage';

var $ = (<any>window).$;
declare var window: any;

@Component({
  templateUrl: './otherconfiguration.userguide.component.html'
})

export class OtherConfigurationUserGuideComponent {

  constructor(
    private modalService: BsModalService,
    private sessionStorage: SessionStorage,
    private ref: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {

  }

  OnClose() {
    this.router.navigate(['/mywizard/otherconfigsettings', 'E2E', this.sessionStorage.endToEndUId]);
  }
}
