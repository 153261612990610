import { ChangeDetectorRef, Component, Output, EventEmitter } from '@angular/core';
import { TileService } from '../../core/services/tile.service';
import { SessionStorage } from '../../core/context/sessionstorage';
import { SharedService } from '../../core/services/shared.service';
import { TMSService } from '../../core/services/tms.service';
import { CMSMenuService } from '../../core/services/cms.menu.service';
import { UserPreferenceService } from '../../core/services/user.preference.service';
import { DemographicsService } from '../../core/services/demographics.service';
import { enumSubmissionType, enumDeliveryAccountability, enumUserPreference, enumDeployRegion, enumAssociationType, enumProfileTab, enumDeliveryType, enumModuleAction, enumEntityType, enumCMS, enumFeatureAccess, enumTile } from '../../core/context/enum';
import cloneDeep from 'lodash/cloneDeep';
import * as Enumerable from 'linq';
import { DatePipe } from '@angular/common';
import { ImagePath } from '../../core/context/constants';
import { NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
//import { SlideShowComponent } from './model.popup.component/slide.show.component';
import { CMSAddEditComponent } from '../initiate.profile.component/model.popup.component/cms.add.edit.component'
//import { TeamAddEditComponent } from './model.popup.component/team.add.edit.component';
//import { TeamStructureSharedService } from './team.structure.shared.service';
import { InitiateProfileSharedService } from './initiate.profile.shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from '../header.component/header.component';
import { ScopeSelectorComponent } from '../scope.selector.component/scope.selector.component';
import { BroadCastService } from '../../core/services/broadcast.service';
import { InitiateProfileComponent } from './initiate.profile.component';
import { TeamAddEditComponent } from './model.popup.component/team.add.edit.component';
import { environment } from '../../../environments/environment';
var $ = (<any>window).$;
declare var window: any;

@Component({
  templateUrl: './matrix.structure.component.html',
  providers: [TileService, TMSService, CMSMenuService, UserPreferenceService, DemographicsService, DatePipe, BsModalService,
    HeaderComponent,
    ScopeSelectorComponent
  ]
})

export class MatrixStructureComponent {

  modalRef_cmstms: BsModalRef;
  endToEndName: string = this.sessionStorage.endToEndName;
  EndToEndType: string = this.sessionStorage.endToEndType;
  EndToEndRefCode: string = this.sessionStorage.endToEndRefCode;
  EndToEndName: string = this.sessionStorage.endToEndName;
  tmsRDDA: any = {};
  teamStatus: any = {};
  IsMultiSelect: any = false;
  EditMode: any = "";
  IsDisableAccType: any = false;
  IsDisableAGDGDU: any = false;
  popupId: any = "tmsStructureAddEdit"
  popupIdCMS: any = "cmsStructureAddEdit";
  popupTMSDetailsTab: any = "tmsStructureTeamDetail";
  AssociatedContractsEndToEnd: any = null;
  IsShowMultiContractLink: any = false;
  IsCMSClosed: any = false;
  IsAdmin: any = this.sessionStorage.roleTypeCode == "AA" || this.sessionStorage.roleTypeCode == "AC" || this.sessionStorage.roleTypeCode == "AV" ? true : false;
  DateValidation: any = {
    IsValidStartDate: true,
    IsValidStartDateEndDate: true,
    IsValidEndDate: true,
    IsValidEndDateStartDate: true,
  }
  associateContractPopUpId: any = "modalManagePopupStructure";
  popupCMSDetailsTab: any = "cmsStructureBasicDetails";
  phoenixMappingContent: any = "cmsPhoenixMappingDetails";
  Action: string;
  cmsTMSDetails: any;
  tmsHierarchy: any;
  IsEditable: boolean;
  relationWorkStream: any;
  tmsDetail: any;
  EndToEndStartDate: any;
  EndToEndEndDate: any;
  IsEditTMS: any;
  type: any;
  cmsHierarchy: any;
  ActiveDropdownDirective: any;
  tmsGroupDetail: any;
  PopUpHeader: string;
  TeamMemberDetails: any;
  TempDeliveryUnits: any[];
  confirmationMessage: string;
  Message: string;
  DeletedCMS: any;
  DeletedWorkstream: any;
  entity: string;
  currentSelectedWS: any;
  TempAccountGroups: any;
  SelectedData: any;
  currentSelectedCMS: any;
  attributeName: string;
  gridData: any;
  showStatus: any;
  TempcurrentSelectedWS: any;
  redirectId = this.sessionStorage.endToEndUId;
  redirectValue = 'E2E';
  RoleAccess = this.sessionStorage.roleAccessCode;
  EndToEndDCList: any;
  TempDeliveryGroups: any;
  TabChanges: any;
  teamMemberFilterAttribute: any;
  oldContractList: any;
  CmsLifeCycle: { Id: number; Name: string; }[];
  SelectedLifeCycle: { Id: number; Name: string; };
  SelectedSFEndtoEnd: any;
  PopupTitle: any;
  IsWorkStreamSelected: boolean;
  TempcurrentSelectedCMS: any;
  SFEDisable: boolean;
  SFEdropdownDisable: boolean;
  IsCMSEdit: boolean;
  IsFifthLevelCMS: boolean;
  DisableTypeOption: boolean;
  IsStatusDropdownDisable: boolean;
  IsNotValidDeliverytype: boolean;
  IsReset: boolean;
  IsslideShowChecked: boolean;
  UserPreferenceDetail: any;
  workStreamTypeIcon: any;
  keyContactTabHighlight: boolean;
  ProfileTabHighlight: boolean;
  @Output() InitiateProfileTabSetup = new EventEmitter();
  modalRef: BsModalRef;
  modalRef_cms: BsModalRef;
  modalRef_teamAddEditPopup: BsModalRef;
  SelectedWorkstream: any;
  SelectedCMS: any;
  teamStatusDropDownDisable: boolean;
  currentSelectedTMS: any;
  isShowEndToEnd: boolean = true;
  popupGroupName: string;
  constructor(
    private tileService: TileService,
    private sessionStorage: SessionStorage,
    private sharedService: SharedService,
    private initiateProfileSharedService: InitiateProfileSharedService,
    private tmsService: TMSService,
    private cMSMenuService: CMSMenuService,
    private userPreferenceService: UserPreferenceService,
    private datepipe: DatePipe,
    private e2edemographicsService: DemographicsService,
    private modalService: BsModalService,
    private scopeSelectorComponent: ScopeSelectorComponent,
    private headerComponent: HeaderComponent,
    private initiateProfileComponent: InitiateProfileComponent,
    private ref: ChangeDetectorRef,
    //private teamStructureSharedService: TeamStructureSharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private broadCastService: BroadCastService
  ) { }

  ngOnInit() {   
    if (!this.sessionStorage.endToEndUId) {
      this.router.navigate(['MyWizard/Back/Profile'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId } });
      return false;
    }
    this.initiateProfileSharedService.ValidateDemographicTabHighlight(this);
    this.initiateProfileSharedService.errorTabHighlight.subscribe(result => {
      this.InitiateProfileTabSetup.emit({ keyContactTabHighlight: this.keyContactTabHighlight, ProfileTabHighlight: this.ProfileTabHighlight, tabId: enumProfileTab.MatrixStructure });
    });
    this.LoadSlideShow();
    this.GetCMSTMSHierarchy(true);
    this.broadCastService.broadcastScopeSelector({
      isScopeSelectorVisible: true, isScopeSelectorDropDownVisible: true,
      isClientDropDownDisabled: true, isSetAsDefaultVisible: false,
    });
    this.headerComponent.RefreshTeamScopeSelector();
    setTimeout(() => {
      window.TooltipFunc();
    }, 0);    
  }

  OnBackClick() {
    this.initiateProfileComponent.OnBackClick();
  }

  showSlideShow() {
    const initialState = { IsslideShowChecked: this.IsslideShowChecked };
    //this.modalRef_cmstms = this.modalService.show(SlideShowComponent, {
    //  initialState,
    //  backdrop: "static",
    //  keyboard: false
    //});
    $(".modal-dialog").addClass('custom-modal-cmstmspopup');
  }

  LoadSlideShow() {
    this.UserPreferenceDetail = {};
    this.UserPreferenceDetail.EnterpriseId = this.sessionStorage.enterpriseId;
    this.UserPreferenceDetail.PreferenceId = enumUserPreference.HideSlideShow;
    this.UserPreferenceDetail.PreferenceValue = "Y";
    this.userPreferenceService.IsShowSlide(this.UserPreferenceDetail).subscribe((response: any) => {
      if (response && response == true) {
        this.IsslideShowChecked = false;
        this.showSlideShow();
      } else {
        this.IsslideShowChecked = true;
      }
    });
  }


  GetRddaTeamMembers() {
    if (!(this.tmsRDDA && this.tmsRDDA.EndToEndTeamMembers && this.tmsRDDA.TeamMemberFilterAttribute)) {
      this.tmsService.getTeamMembers().subscribe((response: any) => {
        this.tmsRDDA.EndToEndTeamMembers = response.EndToEndTeamMembers;
        this.tmsRDDA.TeamMemberFilterAttribute = response.TeamMemberFilterAttribute;
        this.SetScopeForTeamMemberTab();
      });
    }
    else {
      this.SetScopeForTeamMemberTab();
    }
  }

  SetScopeForTeamMemberTab() {
    if (this.Action == "Edit") {
      this.SetEndToEndTeamMembers();
    }
  }


  GetCMSTMSHierarchy(isShowSlide: any) {
    this.tmsService.getStructure().subscribe((response: any) => {
      setTimeout(() => {
        window.TooltipFunc();
      }, 0); 
      this.cmsTMSDetails = response;
      this.tmsHierarchy = response.TmsHierarchy;
      this.UpdateModel();
      this.IsEditable = this.sessionStorage.roleAccessCode == 'V' ? false : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? false : true);
      this.IsMultiSelect = false;
      if (response.MutiContractPreferenceClientIds) {
        var multiContractPreference = Enumerable.from(response.MutiContractPreferenceClientIds).where((x: any) => x.Name == this.sessionStorage.clientId).select((x: any) => x.Name).count();
        if (multiContractPreference > 0) {
          this.IsShowMultiContractLink = true;
        }
      }
      if (this.sessionStorage.teamId != 0 && this.sessionStorage.teamId != undefined) {
        this.cmsTMSDetails.SelectedTeam = this.sessionStorage.teamId;

      }
      else {
        this.cmsTMSDetails.SelectedTeam = null;
      }
      window.scrollTo(0, $('.dynamic-content').offsetTop);
      setTimeout(() => {
        this.setHeight();
      }, 0);
    });
    this.UsageLogging(enumModuleAction.Tile, enumTile.InitiateProfile, enumFeatureAccess.MatrixStructure);    
  }

  SetRelationWorkStream(cmsId: any = null, workStreamId: any = null, cms1: any = null, cms2: any = null, cms3: any = null, cms4: any = null, cms5: any = null) {
    var rel = {
      CMSId: cmsId,
      WorkStreamId: workStreamId,
      CMSList: []
    }
    if (workStreamId) {
      if (cms1) {
        rel.CMSList.push(cms1);
      }
      if (cms2) {
        rel.CMSList.push(cms2);
      }
      if (cms3) {
        rel.CMSList.push(cms3);
      }
      if (cms4) {
        rel.CMSList.push(cms4);
      }
      if (cms5) {
        rel.CMSList.push(cms5);
      }
    }
    this.relationWorkStream.push(rel);
  }

  UpdateCMSModel(cmsDetail, workstreamDetail, isEditTMS) {
    var cmsDetails = cloneDeep(cmsDetail);
    var workstreamDetails = cloneDeep(workstreamDetail);
    if (!isEditTMS) {
      this.tmsDetail = {
        CMSDetails: [],
        WorkStreamDetails: []
      };
    }
    this.tmsDetail.CMSDetails = cmsDetails;
    this.tmsDetail.WorkStreamDetails = workstreamDetails;
    var cmsLevelOne = cmsDetails.filter((x: any) => x.SelectedParentId == null);

    cmsLevelOne.forEach((cms1: any, key: any) => {
      cms1.Tier = 1;
      cms1.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms1.CMSId);
      var cmsLevelTwo = cmsDetails.filter((x: any) => x.SelectedParentId == cms1.CMSId);
      if (cms1.SelectionMode == enumAssociationType.Partial) {
        cms1.SelectionMode = enumAssociationType.Partial;
        this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId);
      }
      else if (cms1.WorkStreamStructure.length > 0 || cmsLevelTwo.length > 0) {
        var noneSelectedWorkstream = cms1.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
        var noneSelectedcms = cmsLevelTwo.filter((x: any) => x.SelectionMode == enumAssociationType.None);
        var fullSelectedWorkstream = cms1.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
        var fullSelectedcms = cmsLevelTwo.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
        if (cms1.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelTwo.length == fullSelectedcms.length) {
          cms1.SelectionMode = enumAssociationType.Full;
          this.UpdateSelectionModeCMS(enumAssociationType.Full, cms1.CMSId, null, null, null, null);
        }
        else if (cms1.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelTwo.length > noneSelectedcms.length) {
          cms1.SelectionMode = enumAssociationType.Partial;
          this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, null, null, null, null);
        }
      }
      cms1.WorkStreamStructure.forEach((ws1: any, key: any) => {
        ws1.SelectionMode = cms1.SelectionMode == enumAssociationType.Full || ws1.SelectionMode == enumAssociationType.Full
          ? enumAssociationType.Full
          : enumAssociationType.None;
        this.UpdateSelectionModelWorkstream(ws1.SelectionMode, ws1.WorkstreamId, ws1.CMSId);
      });
      cmsLevelTwo.forEach((cms2: any, key: any) => {
        cms2.Tier = 2;
        cms2.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms2.CMSId);
        var cmsLevelThree = cmsDetails.filter((x: any) => x.SelectedParentId == cms2.CMSId);
        if (cms2.SelectionMode == enumAssociationType.Partial) {
          cms1.SelectionMode = cms2.SelectionMode = enumAssociationType.Partial;
          this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, null, null, null);
        }
        else if (cms2.WorkStreamStructure.length > 0 || cmsLevelThree.length > 0) {
          var noneSelectedWorkstream = cms2.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
          var noneSelectedcms = cmsLevelThree.filter((x: any) => x.SelectionMode == enumAssociationType.None);
          var fullSelectedWorkstream = cms2.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
          var fullSelectedcms = cmsLevelThree.filter((x: any) => x.SelectionMode == enumAssociationType.Full);

          if (cms2.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelThree.length == fullSelectedcms.length) {
            cms2.SelectionMode = enumAssociationType.Full;
            this.UpdateSelectionModeCMS(enumAssociationType.Full, null, cms2.CMSId, null, null, null);
          }
          else if (cms2.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelThree.length > noneSelectedcms.length) {
            cms1.SelectionMode = cms2.SelectionMode = enumAssociationType.Partial;
            this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, null, null, null);
          }
        }
        cms2.WorkStreamStructure.forEach((ws2: any, key: any) => {
          ws2.SelectionMode = cms2.SelectionMode == enumAssociationType.Full || ws2.SelectionMode == enumAssociationType.Full
            ? enumAssociationType.Full
            : enumAssociationType.None;
          this.UpdateSelectionModelWorkstream(ws2.SelectionMode, ws2.WorkstreamId, ws2.CMSId);

        });
        cmsLevelThree.forEach((cms3: any, key: any) => {
          cms3.Tier = 3;
          cms3.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms3.CMSId);
          var cmsLevelFour = cmsDetails.filter((x: any) => x.SelectedParentId == cms3.CMSId);

          if (cms3.SelectionMode == enumAssociationType.Partial) {
            cms1.SelectionMode = cms2.SelectionMode = cms3.SelectionMode = enumAssociationType.Partial;
            this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, cms3.CMSId, null, null);
          }
          else if (cms3.WorkStreamStructure.length > 0 || cmsLevelFour.length > 0) {
            var noneSelectedWorkstream = cms3.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
            var noneSelectedcms = cmsLevelFour.filter((x: any) => x.SelectionMode == enumAssociationType.None);
            var fullSelectedWorkstream = cms3.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);
            var fullSelectedcms = cmsLevelFour.filter((x: any) => x.SelectionMode == enumAssociationType.Full);

            if (cms3.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelFour.length == fullSelectedcms.length) {
              cms3.SelectionMode = enumAssociationType.Full;
              this.UpdateSelectionModeCMS(enumAssociationType.Full, null, null, cms3.CMSId, null, null);
            }
            else if (cms3.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelFour.length > noneSelectedcms.length) {
              cms1.SelectionMode = cms2.SelectionMode = cms3.SelectionMode = enumAssociationType.Partial;
              this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, cms3.CMSId, null, null);
            }
          }
          cms3.WorkStreamStructure.forEach((ws3: any, key: any) => {
            ws3.SelectionMode = cms3.SelectionMode == enumAssociationType.Full || ws3.SelectionMode == enumAssociationType.Full
              ? enumAssociationType.Full
              : enumAssociationType.None;
            this.UpdateSelectionModelWorkstream(ws3.SelectionMode, ws3.WorkstreamId, ws3.CMSId);

          });
          cmsLevelFour.forEach((cms4: any, key: any) => {
            cms4.Tier = 4;
            cms4.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms4.CMSId);
            var cmsLevelFive = cmsDetails.filter((x: any) => x.SelectedParentId == cms4.CMSId);
            if (cms4.SelectionMode == enumAssociationType.Partial) {
              cms1.SelectionMode = cms2.SelectionMode = cms3.SelectionMode = cms4.SelectionMode = enumAssociationType.Partial;
              this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, cms3.CMSId, cms4.CMSId, null);
            }
            else if (cms4.WorkStreamStructure.length > 0 || cmsLevelFive.length > 0) {
              var noneSelectedWorkstream = cms4.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
              var noneSelectedcms = cmsLevelFive.filter((x: any) => x.SelectionMode == enumAssociationType.None);
              var fullSelectedWorkstream = cms4.WorkStreamStructure.filter((x: any) => enumAssociationType.Full);
              var fullSelectedcms = cmsLevelFive.filter((x: any) => x.SelectionMode == enumAssociationType.Full);

              if (cms4.WorkStreamStructure.length == fullSelectedWorkstream.length && cmsLevelFive.length == fullSelectedcms.length) {
                cms4.SelectionMode = enumAssociationType.Full;
                this.UpdateSelectionModeCMS(enumAssociationType.Full, null, null, null, cms4.CMSId, null);
              }
              else
                if (cms4.WorkStreamStructure.length > noneSelectedWorkstream.length || cmsLevelFive.length > noneSelectedcms.length) {
                  cms1.SelectionMode = cms2.SelectionMode = cms3.SelectionMode = cms4.SelectionMode = enumAssociationType.Partial;
                  this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, cms3.CMSId, cms4.CMSId, null);
                }
            }
            cms4.WorkStreamStructure.forEach((ws4: any, key: any) => {
              ws4.SelectionMode = cms4.SelectionMode == enumAssociationType.Full || ws4.SelectionMode == enumAssociationType.Full
                ? enumAssociationType.Full
                : enumAssociationType.None;
              this.UpdateSelectionModelWorkstream(ws4.SelectionMode, ws4.WorkstreamId, ws4.CMSId);

            });
            cmsLevelFive.forEach((cms5: any, key: any) => {
              cms5.Tier = 5;
              cms5.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSId == cms5.CMSId);
              if (cms5.SelectionMode == enumAssociationType.Partial) {
                cms1.SelectionMode = cms2.SelectionMode = cms3.SelectionMode = cms4.SelectionMode = cms5.SelectionMode = enumAssociationType.Partial;
                this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, cms3.CMSId, cms4.CMSId, cms5.CMSId);
              }
              else if (cms5.WorkStreamStructure.length > 0) {
                var noneSelectedWorkstream = cms5.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.None);
                var fullSelectedWorkstream = cms5.WorkStreamStructure.filter((x: any) => x.SelectionMode == enumAssociationType.Full);

                if (cms5.WorkStreamStructure.length == fullSelectedWorkstream.length) {
                  cms5.SelectionMode = enumAssociationType.Full;
                  this.UpdateSelectionModeCMS(enumAssociationType.Full, null, null, null, null, cms5.CMSId);
                }
                else if (cms5.WorkStreamStructure.length > noneSelectedWorkstream.length) {
                  cms1.SelectionMode = cms2.SelectionMode = cms3.SelectionMode = cms4.SelectionMode = cms5.SelectionMode = enumAssociationType.Partial;
                  this.UpdateSelectionModeCMS(enumAssociationType.Partial, cms1.CMSId, cms2.CMSId, cms3.CMSId, cms4.CMSId, cms5.CMSId);
                }
              }
              cms5.WorkStreamStructure.forEach((ws5: any, key: any) => {
                ws5.SelectionMode = cms5.SelectionMode == enumAssociationType.Full || ws5.SelectionMode == enumAssociationType.Full
                  ? enumAssociationType.Full
                  : enumAssociationType.None;
                this.UpdateSelectionModelWorkstream(ws5.SelectionMode, ws5.WorkstreamId, ws5.CMSId);
              });
            });

            cms4.CMSLevelFive = cmsLevelFive;
          });
          cms3.CMSLevelFour = cmsLevelFour;
        });
        cms2.CMSLevelThree = cmsLevelThree;
      });
      cms1.CMSLevelTwo = cmsLevelTwo;
    });

    this.tmsDetail.CMSLevelOne = cmsLevelOne;
    this.tmsDetail.WorkStreamStructure = workstreamDetails.filter((x: any) => x.CMSName == null);
    this.ref.detectChanges();
    if (isEditTMS) {
      setTimeout(function test() {
        $('.associationCheckbox').each(function (index, data) {
          var selectionMode = $(this).attr('data-selectionmode');
          if (selectionMode == enumAssociationType.Partial) {
            $(this).prop('indeterminate', true);
          }
          else {
            $(this).prop('indeterminate', false);
          }

        });
      }
        , 0);
    }
  }

  UpdateCMSModelforTMS(cmsDetail, workstreamDetail, tms, tmsGroup1, tmsGroup2, tmsGroup3, tmsGroup4, tmsGroup5) {
    this.UpdateCMSModel(cmsDetail, workstreamDetail, false);
   // this.SetAttributeClass(0, 0, "", tms, "");
    this.relationWorkStream.forEach((rw: any, key: any) => {
      if (rw.WorkStreamId == null) {
        var cmsitem = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == rw.CMSId);
        if (cmsitem[0]) this.SetAttributeClass(null, rw.CMSId, cmsitem[0].SelectionMode, tms, cmsitem[0].CMSName);
        this.SetAvailableClassTeamGroup(tms.RelationWorkStream, rw, tmsGroup1, tmsGroup2, tmsGroup3, tmsGroup4, tmsGroup5);
      }
      else {
        var wsitem = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.CMSId == rw.CMSId && x.WorkstreamId == rw.WorkStreamId);
        if (wsitem[0]) this.SetAttributeClass(rw.WorkStreamId, rw.CMSId, wsitem[0].SelectionMode, tms, wsitem[0].WorkStreamName);
        this.SetAvailableClassTeamGroup(tms.RelationWorkStream, rw, tmsGroup1, tmsGroup2, tmsGroup3, tmsGroup4, tmsGroup5);
      }
    });
  }

  UpdateSelectionModelWorkstream(selectionMode, workstreamId, cmsId) {
    var workStreamDetails = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == workstreamId && x.CMSId == cmsId)[0];
    workStreamDetails.SelectionMode = selectionMode;
  }



  UpdateSelectionModeCMS(selectionMode, cmsId1: any = null, cmsId2: any = null, cmsId3: any = null, cmsId4: any = null, cmsId5: any = null) {
    if (cmsId1) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId1)[0];
      cmsDetail.SelectionMode = selectionMode;
    }
    if (cmsId2) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId2);
      cmsDetail.SelectionMode = selectionMode;
    }
    if (cmsId3) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId3);
      cmsDetail.SelectionMode = selectionMode;
    }
    if (cmsId4) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId4);
      cmsDetail.SelectionMode = selectionMode;

    }
    if (cmsId5) {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId5);
      cmsDetail.SelectionMode = selectionMode;
    }
  }

  SetAttributeClass(wsId, cmsId, mode, tm, cmsWorkStreamName) {
    var relWork = {
      RelatedClass: "",
      CMSId: cmsId,
      WorkStreamId: wsId,
      CMSWorkStreamName: cmsWorkStreamName
    }
    relWork.RelatedClass = mode == "Full" ? "icon-tick" : mode == "Partial" ? "-partial" : "",
      tm.RelationWorkStream.push(relWork);
  }

  SetActiveClass(tms: any = null, tmsGroup1: any = null, tmsGroup2: any = null, tmsGroup3: any = null, tmsGroup4: any = null, tmsGroup5: any = null) {
    tms.forEach((tm: any, key: any) => {
      tm.RelationWorkStream = [];
      this.UpdateCMSModelforTMS(tm.CMSDetails, tm.WorkStreamDetails, tm, tmsGroup1, tmsGroup2, tmsGroup3, tmsGroup4, tmsGroup5);
    });
  }

  SetTeamGroupAvailableForCMS(relationWorkStream, cmsid) {
    var cmsTeamGroups = relationWorkStream.filter((x: any) => x.CMSId == cmsid);
    if (cmsTeamGroups) {
      cmsTeamGroups.forEach((cmsTeamGroup: any, key: any) => {
        cmsTeamGroup.RelatedClass = "icon-tick";
      });
    }
  };

  SetTeamGroupAvailableForWorkstream(tmsgroup, rw, groupTMSData) {    
    var workStreamTeamGroups = tmsgroup.RelationWorkStream.filter((x: any) => x.WorkStreamId == rw.WorkStreamId && x.CMSId == rw.CMSId);
    if (workStreamTeamGroups) {
      var TeamList = Enumerable.from(groupTMSData).selectMany((x: any) => x.RelationWorkStream).toArray();
      if (rw.WorkStreamId == null) {
        var items = Enumerable.from(TeamList).where((x: any) => x.CMSId == rw.CMSId && x.WorkStreamId == null).toArray();
      }
      else {
        var items = Enumerable.from(TeamList).where((x: any) => x.CMSId == rw.CMSId && x.WorkStreamId == rw.WorkStreamId).toArray();
      }
      var containsFullSelection = Enumerable.from(items).where((x: any) => x.RelatedClass == "icon-tick").toArray();
      if (containsFullSelection.length > 0) {
        var containsPartialSelection = Enumerable.from(items).where((x: any) => x.RelatedClass == "-partial" || x.RelatedClass == "").toArray();
        if (containsPartialSelection.length > 0) {
          workStreamTeamGroups.forEach((workStreamTeamGroup: any, key: any) => {
            workStreamTeamGroup.RelatedClass = "-partial";
          });
        }
        else {
          workStreamTeamGroups.forEach((workStreamTeamGroup: any, key: any) => {
            workStreamTeamGroup.RelatedClass = "icon-tick";
          });
        }
      }
      else {
        var containsOnlyPartialSelection = Enumerable.from(items).where((x: any) => x.RelatedClass == "-partial").toArray();
        if (containsOnlyPartialSelection.length > 0) {
          workStreamTeamGroups.forEach((workStreamTeamGroup: any, key: any) => {
            workStreamTeamGroup.RelatedClass = "-partial";
          });
        }
      }

    }
  }
  SetAvailableClassTeamGroup(relWork, rw, tmsGroup1, tmsGroup2, tmsGroup3, tmsGroup4, tmsGroup5) {
    var groupData = [];
    if (tmsGroup5) {
      this.SetTeamGroupAvailableForWorkstream(tmsGroup5, rw, tmsGroup5.TMS);
      if (tmsGroup4) {
        groupData = cloneDeep(tmsGroup4.TMS);
        groupData.push.apply(groupData, tmsGroup5.TMS);
      }
    }
    else {
      if (tmsGroup4) groupData = cloneDeep(tmsGroup4.TMS);
    }
    if (tmsGroup4) {

      this.SetTeamGroupAvailableForWorkstream(tmsGroup4, rw, groupData);
      if (tmsGroup3) {
        groupData.push.apply(groupData, tmsGroup3.TMS);
      }
    }
    else {
      if (tmsGroup3) groupData = cloneDeep(tmsGroup3.TMS);
    }
    if (tmsGroup3) {
      this.SetTeamGroupAvailableForWorkstream(tmsGroup3, rw, groupData);
      if (tmsGroup2) {
        groupData.push.apply(groupData, tmsGroup2.TMS);
      }
    }
    else {
      if (tmsGroup2) groupData = cloneDeep(tmsGroup2.TMS);
    }
    if (tmsGroup2) {
      this.SetTeamGroupAvailableForWorkstream(tmsGroup2, rw, groupData);
      if (tmsGroup1) {
        groupData.push.apply(groupData, tmsGroup1.TMS);
      }
    }
    else {
      if (tmsGroup1) groupData = cloneDeep(tmsGroup1.TMS);
    }
    if (tmsGroup1) {
      this.SetTeamGroupAvailableForWorkstream(tmsGroup1, rw, groupData);
    }
  }

  SetAvailableClassTeamGroupforCMS(cmsid, tmsGroup1, tmsGroup2, tmsGroup3, tmsGroup4, tmsGroup5) {
    if (tmsGroup1) {
      this.SetTeamGroupAvailableForCMS(tmsGroup1.RelationWorkStream, cmsid);
      if (tmsGroup2) {
        this.SetTeamGroupAvailableForCMS(tmsGroup2.RelationWorkStream, cmsid);
        if (tmsGroup3) {
          this.SetTeamGroupAvailableForCMS(tmsGroup3.RelationWorkStream, cmsid);
          if (tmsGroup4) {
            this.SetTeamGroupAvailableForCMS(tmsGroup4.RelationWorkStream, cmsid);
            if (tmsGroup5) {
              this.SetTeamGroupAvailableForCMS(tmsGroup5.RelationWorkStream, cmsid);
            }
          }
        }

      }
    }
  }


  SetActiveClassTeamGroup(tmsGroup) {
    tmsGroup.RelationWorkStream = [];
    this.relationWorkStream.forEach((rw: any, key: any) => {
      var relWork = {
        RelatedClass: "",
        WorkStreamId: rw.WorkStreamId,
        CMSId: rw.CMSId
      }
      tmsGroup.RelationWorkStream.push(relWork);
    });
  }

  UpdateModel() {
    this.cmsHierarchy = this.cmsTMSDetails.CMSHierarchy;
    this.relationWorkStream = [];
    this.FormateCMSHierarchyStructure();
    this.FormateTMSHierarchyStructure();
  }

  FormateTMSHierarchyStructure() {
    var teamHierarchy = this.cmsTMSDetails.TmsHierarchy;
    this.tmsHierarchy = this.cmsTMSDetails.TmsHierarchy;
    this.tmsHierarchy.TMSGroupLevelOne = [];
    this.tmsHierarchy.TMS = [];
    var tmsGroupLevelOne = teamHierarchy.TMSGroups.filter((x: any) => x.ParentTMSId == null);
    tmsGroupLevelOne.forEach((tmsGroup1: any, key: any) => {      
      tmsGroup1.Tier = 1;     
      this.SetActiveClassTeamGroup(tmsGroup1);
      tmsGroup1.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == tmsGroup1.TMSGroupId);
      this.SetActiveClass(tmsGroup1.TMS, tmsGroup1, null, null, null, null);
      var tmsGroupLevelTwo = teamHierarchy.TMSGroups.filter((x: any) => x.ParentTMSId == tmsGroup1.TMSGroupId);
      tmsGroupLevelTwo.forEach((tmsGroup2: any, key: any) => {
        tmsGroup2.Tier = 2;
        this.SetActiveClassTeamGroup(tmsGroup2);
        tmsGroup2.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == tmsGroup2.TMSGroupId);
        this.SetActiveClass(tmsGroup2.TMS, tmsGroup1, tmsGroup2, null, null, null);

        var tmsGroupLevelThree = teamHierarchy.TMSGroups.filter((x: any) => x.ParentTMSId == tmsGroup2.TMSGroupId);
        tmsGroupLevelThree.forEach((tmsGroup3: any, key: any) => {
          tmsGroup3.Tier = 3;
          this.SetActiveClassTeamGroup(tmsGroup3);
          tmsGroup3.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == tmsGroup3.TMSGroupId);
          this.SetActiveClass(tmsGroup3.TMS, tmsGroup1, tmsGroup2, tmsGroup3, null, null);

          var tmsGroupLevelFour = teamHierarchy.TMSGroups.filter((x: any) => x.ParentTMSId == tmsGroup3.TMSGroupId);
          tmsGroupLevelFour.forEach((tmsGroup4: any, key: any) => {
            tmsGroup4.Tier = 4;
            this.SetActiveClassTeamGroup(tmsGroup4);
            tmsGroup4.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == tmsGroup4.TMSGroupId);
            this.SetActiveClass(tmsGroup4.TMS, tmsGroup1, tmsGroup2, tmsGroup3, tmsGroup4, null);

            var tmsGroupLevelFive = teamHierarchy.TMSGroups.filter((x: any) => x.ParentTMSId == tmsGroup4.TMSGroupId);
            tmsGroupLevelFive.forEach((tmsGroup5: any, key: any) => {
              tmsGroup5.Tier = 5;
              this.SetActiveClassTeamGroup(tmsGroup5);
              tmsGroup5.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == tmsGroup5.TMSGroupId);
              this.SetActiveClass(tmsGroup5.TMS, tmsGroup1, tmsGroup2, tmsGroup3, tmsGroup4, tmsGroup5);
            });
            tmsGroup4.TMSGroupLevelFive = tmsGroupLevelFive;
          });
          tmsGroup3.TMSGroupLevelFour = tmsGroupLevelFour;
        });
        tmsGroup2.TMSGroupLevelThree = tmsGroupLevelThree;
      });
      tmsGroup1.TMSGroupLevelTwo = tmsGroupLevelTwo;
    });
    this.tmsHierarchy.TMSGroupLevelOne = tmsGroupLevelOne;
    this.tmsHierarchy.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == null);   
    this.SetActiveClass(this.tmsHierarchy.TMS, null, null, null, null, null);    
  }

  FormateCMSHierarchyStructure() {
    this.cmsHierarchy.CMSLevelOne = [];
    this.cmsHierarchy.WorkStreamStructure = [];
    //this.SetRelationWorkStream(0, 0);
    var cmsLevelOne = this.cmsHierarchy.CMSDataList.filter((x: any) => x.SelectedParentId == null);
    this.cmsHierarchy.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSName == null);
    this.cmsHierarchy.WorkStreamStructure.forEach((ws: any, key: any) => {
      this.SetRelationWorkStream(ws.CMSId, ws.WorkstreamId);
    });
    cmsLevelOne.forEach((cms1: any, key: any) => {
      cms1.Tier = 1;
      cms1.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSId == cms1.CMSId);

      this.SetRelationWorkStream(cms1.CMSId, null);
      cms1.WorkStreamStructure.forEach((ws: any, key: any) => {
        this.SetRelationWorkStream(cms1.CMSId, ws.WorkstreamId, cms1.CMSId);
      });
      var cmsLevelTwo = this.cmsHierarchy.CMSDataList.filter((x: any) => x.SelectedParentId == cms1.CMSId);
      cmsLevelTwo.forEach((cms2: any, key: any) => {
        cms2.Tier = 2;
        cms2.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSId == cms2.CMSId);
        this.SetRelationWorkStream(cms2.CMSId, null);
        cms2.WorkStreamStructure.forEach((ws: any, key: any) => {
          this.SetRelationWorkStream(cms2.CMSId, ws.WorkstreamId, cms1.CMSId, cms2.CMSId);
        });
        var cmsLevelThree = this.cmsHierarchy.CMSDataList.filter((x: any) => x.SelectedParentId == cms2.CMSId);

        cmsLevelThree.forEach((cms3: any, key: any) => {
          cms3.Tier = 3;
          cms3.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSId == cms3.CMSId);
          this.SetRelationWorkStream(cms3.CMSId, null);
          cms3.WorkStreamStructure.forEach((ws: any, key: any) => {
            this.SetRelationWorkStream(cms3.CMSId, ws.WorkstreamId, cms1.CMSId, cms2.CMSId, cms3.CMSId);
          });
          var cmsLevelFour = this.cmsHierarchy.CMSDataList.filter((x: any) => x.SelectedParentId == cms3.CMSId);
          cmsLevelFour.forEach((cms4: any, key: any) => {
            cms4.Tier = 4;
            cms4.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSId == cms4.CMSId);
            this.SetRelationWorkStream(cms4.CMSId, null);
            cms4.WorkStreamStructure.forEach((ws: any, key: any) => {
              this.SetRelationWorkStream(cms4.CMSId, ws.WorkstreamId, cms1.CMSId, cms2.CMSId, cms3.CMSId, cms4.CMSId);
            });
            var cmsLevelFive = this.cmsHierarchy.CMSDataList.filter((x: any) => x.SelectedParentId == cms4.CMSId);
            cmsLevelFive.forEach((cms5: any, key: any) => {
              cms5.Tier = 5;
              cms5.WorkStreamStructure = this.cmsHierarchy.WorkstreamDataList.filter((x: any) => x.CMSId == cms5.CMSId);
              this.SetRelationWorkStream(cms5.CMSId, null);
              cms5.WorkStreamStructure.forEach((ws: any, key: any) => {
                this.SetRelationWorkStream(cms5.CMSId, ws.WorkstreamId, cms1.CMSId, cms2.CMSId, cms3.CMSId, cms4.CMSId, cms5.CMSId);
              });
            });
            cms4.CMSLevelFive = cmsLevelFive;
          });
          cms3.CMSLevelFour = cmsLevelFour;
        });
        cms2.CMSLevelThree = cmsLevelThree;
      });
      cms1.CMSLevelTwo = cmsLevelTwo;
    });
    this.cmsHierarchy.CMSLevelOne = cmsLevelOne;
  }

  workStreamIconSelection(workStreamTypeId) {
    if (workStreamTypeId == 1)
      this.workStreamTypeIcon = ImagePath.ADImage;
    else if (workStreamTypeId == 2)
      this.workStreamTypeIcon = ImagePath.AMImage;
    else if (workStreamTypeId == 3 || workStreamTypeId == 4 || workStreamTypeId == 5 || workStreamTypeId == 6 || workStreamTypeId == 7 || workStreamTypeId == 8 || workStreamTypeId == 9)
      this.workStreamTypeIcon = ImagePath.AgileImage;
    else if (workStreamTypeId == 10 || workStreamTypeId == 11)
      this.workStreamTypeIcon = ImagePath.InfrastructureImage;
    else if (workStreamTypeId == 12)
      this.workStreamTypeIcon = ImagePath.OperationsImage;
    else if (workStreamTypeId == 13)
      this.workStreamTypeIcon = ImagePath.SecurityImage;
    else
      this.workStreamTypeIcon = null;

    return this.workStreamTypeIcon;
  }

  AccountGroupChangeForTMS(accountGroupId, index) {
    this.FilterDGListData();
    this.TempDeliveryUnits = [];
    this.tmsDetail.SelectedDeliveryGroupId = null;
    this.tmsDetail.SelectedDeliveryUnitId = null;
  }

  DeliveryGroupChangeForTMS(deliveryGroupId, index) {
    this.FilterDUListData();
    this.tmsDetail.SelectedDeliveryUnitId = null;
  }

  EditTMS(tms, tmsGroup) {
    this.DateValidation.IsValidStartDate = true;
    this.DateValidation.IsValidEndDate = true;
    this.DateValidation.IsValidEndDateStartDate = true;
    this.DateValidation.IsValidStartDateEndDate = true;
    this.ActiveDropdownDirective = false;
    this.Action = "Edit";
    this.EditMode = "TMS";
    this.teamStatus.Id = "1";
    this.ResetSelection();

    setTimeout(function () {
      $('.close').focus();
    });

    if (tmsGroup) {
      this.tmsHierarchy.Type = this.type = "Group";
      this.currentSelectedTMS = tmsGroup;
      tmsGroup.IsSelected = true;
      this.tmsGroupDetail = cloneDeep(this.currentSelectedTMS);
      this.PopUpHeader = "Edit Group Name";
      this.popupGroupName = this.tmsGroupDetail.TMSGroupName;
      var selectedTMSGroup = this.tmsHierarchy.TMSGroups.filter((x: any) => x.TMSGroupId == tmsGroup.TMSGroupId);
      if (selectedTMSGroup && selectedTMSGroup.length > 0) {
        selectedTMSGroup[0].IsSelected = tmsGroup.IsSelected
      }
      this.IsEditTMS = true;
      $("#teamLead").removeClass('input-blank-error');
      setTimeout(function () {
        this.ActiveDropdownDirective = true;
        $('.close').focus();
      });
      if (!$('#' + this.popupId).hasClass('in')) {
        $('#' + this.popupId + ' .modal-dialog').draggable({ containment: 'parent' });
        this.IsEditTMS = true;
        const initialState = {
          DateValidation: this.DateValidation, tmsHierarchy: this.tmsHierarchy, teamStatus: this.teamStatus, teamStatusDropDownDisable: this.teamStatusDropDownDisable,
          IsEditTMS: this.IsEditTMS, Action: this.Action, tmsGroupDetail: this.tmsGroupDetail, tmsDetail: this.tmsDetail, TempAccountGroups: this.TempAccountGroups,
          TempDeliveryUnits: this.TempDeliveryUnits, TempDeliveryGroups: this.TempDeliveryGroups, IsDisableAccType: this.IsDisableAccType, IsDisableAGDGDU: this.IsDisableAGDGDU,
          EndToEndRefCode: this.EndToEndRefCode, EndToEndName: this.EndToEndName, EndToEndType: this.EndToEndType, EndToEndStartDate: this.EndToEndStartDate,
          EndToEndEndDate: this.EndToEndEndDate, popupTMSDetailsTab: this.popupTMSDetailsTab, IsEditable: this.IsEditable, ActiveDropdownDirective: this.ActiveDropdownDirective, PopUpHeader: this.PopUpHeader, popupGroupName: this.popupGroupName
        };
        this.modalRef_teamAddEditPopup = this.modalService.show(TeamAddEditComponent, {
          initialState,
          backdrop: "static",
          keyboard: false
        });
        $(".modal-dialog").addClass('custom-add-group');
        this.modalRef_teamAddEditPopup.content.teamAddEditPopupData.subscribe(result => {
          if (result.isSaveButtonClick)
            this.GetCMSTMSHierarchy(false);
          this.headerComponent.RefreshTeamScopeSelector();
          this.ProcessDemographicAttribute(enumEntityType.EndToEnd + "|" + enumEntityType.TMS, null);
        });
      }
      // END

    }
    else {
      this.tmsService.getTeamsByTeamId(tms.TMSId, 0).subscribe((response: any) => {
        if (!$('#' + this.popupId).hasClass('in')) {
          $('#' + this.popupId).modal('show');
          $('.close').focus();
          $('#' + this.popupId + ' .modal-dialog').draggable({ containment: 'parent' });
          this.IsEditTMS = true;
        }
        this.tmsDetail = response.TMSDetails;
        this.tmsHierarchy.Type = this.type = "TMS";
        tms.IsSelected = true;
        this.PopUpHeader = "Edit : " + this.tmsDetail.TMSName;
        this.DeliveryAccountabilityType();
        this.tmsDetail.SelectedEndToEndTeamMember = [];
        this.tmsDetail.SelectedTeamMember = [];
        this.tmsDetail.searchText = "";
        this.tmsDetail.StartDate = this.datepipe.transform(this.tmsDetail.StartDate, 'dd MMM yyyy');
        this.tmsDetail.EndDate = this.datepipe.transform(this.tmsDetail.EndDate, 'dd MMM yyyy');
        $('#hiddenTeamLeadStructure').val(this.tmsDetail.TMSLead);
        this.tmsDetail.MMEWMUId = "";
        $('#hiddenTeamLead').val(this.tmsDetail.TMSLead);
        var selectedTMS = this.tmsHierarchy.Teams.filter((x: any) => x.TMSId == tms.TMSId);
        if (selectedTMS && selectedTMS.length > 0) {
          selectedTMS[0].IsSelected = tms.IsSelected;
        }
        this.TeamDateValidation();
        this.UpdateCMSModel(this.tmsDetail.CMSDetails, this.tmsDetail.WorkStreamDetails, true);

        this.FilterAGListData();
        this.FilterDUListData();
        this.FilterDGListData();
        this.ActivateFirstTab();

        $("#teamLead").removeClass('input-blank-error');
        setTimeout(function () {
          this.ActiveDropdownDirective = true;
          $('.close').focus();
        });
        if (!$('#' + this.popupId).hasClass('in')) {
          $('#' + this.popupId + ' .modal-dialog').draggable({ containment: 'parent' });
          this.IsEditTMS = true;
          const initialState = {
            DateValidation: this.DateValidation, tmsHierarchy: this.tmsHierarchy, teamStatus: this.teamStatus, teamStatusDropDownDisable: this.teamStatusDropDownDisable,
            IsEditTMS: this.IsEditTMS, Action: this.Action, tmsGroupDetail: this.tmsGroupDetail, tmsDetail: this.tmsDetail, TempAccountGroups: this.TempAccountGroups,
            TempDeliveryUnits: this.TempDeliveryUnits, TempDeliveryGroups: this.TempDeliveryGroups, IsDisableAccType: this.IsDisableAccType, IsDisableAGDGDU: this.IsDisableAGDGDU,
            EndToEndRefCode: this.EndToEndRefCode, EndToEndName: this.EndToEndName, EndToEndType: this.EndToEndType, EndToEndStartDate: this.EndToEndStartDate,
            EndToEndEndDate: this.EndToEndEndDate, popupTMSDetailsTab: this.popupTMSDetailsTab, IsEditable: this.IsEditable, ActiveDropdownDirective: this.ActiveDropdownDirective, PopUpHeader: this.PopUpHeader
          };
          this.modalRef_teamAddEditPopup = this.modalService.show(TeamAddEditComponent, {
            initialState,
            backdrop: "static",
            keyboard: false
          });
          $(".modal-dialog").addClass('custom-add-group');
          this.modalRef_teamAddEditPopup.content.teamAddEditPopupData.subscribe(result => {
            if (result.isSaveButtonClick)
              if (this.sessionStorage.tmsName && this.sessionStorage.tmsName != this.tmsDetail.TMSName && this.Action == "Edit") {
                this.sessionStorage.tmsName = this.tmsDetail.TMSName;
              }
            this.GetCMSTMSHierarchy(false);
            this.headerComponent.RefreshTeamScopeSelector();
            this.ProcessDemographicAttribute(enumEntityType.EndToEnd + "|" + enumEntityType.TMS, null);
          });
        }
      });
    }
  }
  ActivateFirstTab() {
    $("#teamMembers").removeClass('active');
    $('#' + this.popupTMSDetailsTab).addClass('active');
    $("#tabTeamMembers").removeClass('active');
    $("#tabTeamDetails").addClass('active');
  }

  DeliveryAccountabilityType() {
    this.IsDisableAccType = false;
    if (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureOG) {
      this.tmsHierarchy.DeliveryAccountabilityTypes = cloneDeep(this.tmsHierarchy.ManagementClientGroups);
      this.tmsDetail.SelectedDeliveryAccountabilityType = this.tmsDetail.SelectedClientGroup;
      this.IsDisableAGDGDU = true;
    }
    else if (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureGDN) {
      this.tmsHierarchy.DeliveryAccountabilityTypes = cloneDeep(this.tmsHierarchy.DeliveryCenters);
      this.tmsDetail.SelectedDeliveryAccountabilityType = this.tmsDetail.SelectedDeliveryCenter;
      this.IsDisableAGDGDU = false;
    }
    else if (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AvanadeGU) {
      this.tmsHierarchy.DeliveryAccountabilityTypes = cloneDeep(this.tmsHierarchy.AvanadeGeographicUnits);
      this.tmsDetail.SelectedDeliveryAccountabilityType = this.tmsDetail.SelectedAvanadeGeographicUnit;
      this.IsDisableAGDGDU = true;
    }
    else {
      this.tmsHierarchy.DeliveryAccountabilityTypes = [];
      this.tmsDetail.SelectedDeliveryAccountabilityType = null;
      this.IsDisableAccType = true;
    }
  }


  SetEndToEndTeamMembers() {
    this.tmsDetail.EndToEndTeamMembers = cloneDeep(this.tmsRDDA.EndToEndTeamMembers);
    this.TeamMemberDetails = cloneDeep(this.tmsRDDA.EndToEndTeamMembers);
    var endToEndTeams = cloneDeep(this.tmsRDDA.EndToEndTeamMembers);
    endToEndTeams.forEach((data: any, index: any) => {
      var teamMember = this.tmsDetail.TeamMembers.filter((x: any) => x.Name == data.Name);
      if (teamMember) {
        var endToendTeamMember = this.tmsDetail.EndToEndTeamMembers.filter((x: any) => x.Name == data.Name);
        if (endToendTeamMember) {
          this.tmsDetail.EndToEndTeamMembers.splice(this.tmsDetail.EndToEndTeamMembers.indexOf(endToendTeamMember), 1);
        }
      }
    });
    this.tmsDetail.StaticEndToEndTeamMembers = cloneDeep(this.tmsDetail.EndToEndTeamMembers);
  }

  UpdateSharedWSPOC(workStreamDetail, workstreamId) {
    var workStreamDetails = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == workstreamId);
    if (workStreamDetails.length > 1) {
      workStreamDetails.forEach((ws: any, key: any) => {
        if (ws.POC != "") {
          workStreamDetail.POC = ws.POC;
        }
      });
    }
  }

  UpdateCMSWorkstreamDetials(selection, cmsId) {
    var workStreamDetails1 = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.CMSId == cmsId);
    workStreamDetails1.forEach((ws: any, key: any) => {
      ws.SelectionMode = selection;
      ws.POC = ws.SelectionMode == enumAssociationType.None ? "" : ws.POC;
      if (ws.SelectionMode == enumAssociationType.Full) {
        this.UpdateSharedWSPOC(ws, ws.WorkstreamId);
      }
    });
  }

  UpdateParentSelectionMode(selection, cmsDetail1) {
    var cmsDetail2 = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsDetail1.SelectedParentId);
    if (cmsDetail2) {
      this.SetPartialSelection(selection, cmsDetail1, cmsDetail2);
      var cmsDetail3 = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsDetail2.SelectedParentId);
      if (cmsDetail3) {
        this.SetPartialSelection(selection, cmsDetail2, cmsDetail3);
        var cmsDetail4 = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsDetail3.SelectedParentId);
        if (cmsDetail4) {
          this.SetPartialSelection(selection, cmsDetail3, cmsDetail4);
          var cmsDetail5 = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsDetail4.SelectedParentId);
          if (cmsDetail5) {
            this.SetPartialSelection(selection, cmsDetail4, cmsDetail5);
          }
        }
      }
    }
  }

  SetPartialSelection(selection, childCMSDetails, cmsDetail) {
    var cmsSiblingDetails = this.tmsDetail.CMSDetails.filter((x: any) => x.SelectedParentId == childCMSDetails.SelectedParentId)
    var siblingWorkstrems = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.CMSId == childCMSDetails.SelectedParentId)
    if (selection == enumAssociationType.Full) {
      var noneSelectionedSiblings = cmsSiblingDetails.filter((x: any) => x.SelectionMode == enumAssociationType.None)
      var noneSelectionedSiblingsWorkstream = siblingWorkstrems.filter((x: any) => x.SelectionMode == enumAssociationType.None)
      if (noneSelectionedSiblings.length > 0 || childCMSDetails.SelectionMode == enumAssociationType.Partial || noneSelectionedSiblingsWorkstream.length > 0) {
        cmsDetail.SelectionMode = enumAssociationType.Partial;
      }
      else {
        cmsDetail.SelectionMode = enumAssociationType.Full;
      }
    }
    else {
      var fullySelectionedSiblings = cmsSiblingDetails.filter((x: any) => x.SelectionMode == enumAssociationType.Full)
      var fullySelectionedSiblingsWorkstream = siblingWorkstrems.filter((x: any) => x.SelectionMode == enumAssociationType.Full)
      if (fullySelectionedSiblings.length > 0 || childCMSDetails.SelectionMode == enumAssociationType.Partial || fullySelectionedSiblingsWorkstream.length > 0) {
        cmsDetail.SelectionMode = enumAssociationType.Partial;
      }
      else {
        cmsDetail.SelectionMode = enumAssociationType.None;
      }
    }
    if (cmsDetail.SelectionMode == enumAssociationType.None || cmsDetail.SelectionMode == enumAssociationType.Partial) {
      cmsDetail.POC = "";
    }
  }

  PeoplepickerSelection(even, args) {
    var type = $(args.element[0]).closest('div.tms-item').attr('data-type');
    var cmsworkstreamId = $(args.element[0]).attr('data-cmsworkstreamid');
    cmsworkstreamId = parseInt(cmsworkstreamId);
    if (type == "Workstream") {
      var workStreamDetails = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == cmsworkstreamId)
      workStreamDetails.forEach((ws: any, key: any) => {
        if (ws.SelectionMode == enumAssociationType.Full) {
          ws.POC = args.poc;
          ws.IsPOCValid = true;
        }
      });
    }
    else {
      var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsworkstreamId)
      cmsDetail.POC = args.poc;
      cmsDetail.IsPOCValid = true;
    }
    this.UpdateCMSModel(this.tmsDetail.CMSDetails, this.tmsDetail.WorkStreamDetails, true);
  }

  PeoplePickerSelection(workstreamId, cmsId, poc, IsPOCValid, event) {
    if ((event != null && event != undefined && event.keyCode == 9) || ((event.ctrlKey == true || event.key == "Control") && (event.keyCode == 67 || event.keyCode == 17 || event.keyCode == 65))) {
      return false;
    }
    else {
      if (workstreamId) {
        var workStreamDetail = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == workstreamId && x.CMSID == cmsId);
        setTimeout(() => {
          workStreamDetail.IsPOCValid = false;
        }, 0);
        if (poc == '') {
          var workStreamDetails = this.tmsDetail.WorkStreamDetails.filter((x: any) => x.WorkstreamId == workstreamId);
          workStreamDetails.forEach((ws: any, key: any) => {
            if (ws.SelectionMode == enumAssociationType.Full) {
              ws.POC = poc;
              setTimeout(() => {
                ws.IsPOCValid = true;
              }, 0);
            }
          });
        }
      }
      else {
        var cmsDetail = this.tmsDetail.CMSDetails.filter((x: any) => x.CMSId == cmsId);
        setTimeout(() => {
          cmsDetail.IsPOCValid = false;
        }, 0);
        if (poc == '') {
          cmsDetail.POC = poc;
          setTimeout(() => {
            cmsDetail.IsPOCValid = true;
          }, 0);
        }
      }
    }
  }

  ChangeTeamLead($event, teamLead) {
    if (!(($event.ctrlKey == true || $event.key == "Control") && ($event.keyCode == 67 || $event.keyCode == 17 || $event.keyCode == 65))) {
      if (teamLead == null || teamLead == '') {
        $($event.currentTarget).removeClass('input-blank-error');
      }
      else if ($("#hiddenTeamLead").val() != "" && $("#hiddenTeamLead").val() != teamLead) {
        $($event.currentTarget).addClass('input-blank-error');
      }
      else {
        return false;
      }
    }
  }

  ClosePopUp(form, event) {
    if (event != null) {
      var keycode = event.keyCode;
      if (keycode == 13) { // code for ENTER key
        if (this.type == "TMS") {
          $('#' + 'MatrixTMS' + this.tmsDetail.TMSId).focus();
          $('#tmsAddEdit').modal('hide');
        }
        else {
          $('#' + 'MatrixGroup' + this.tmsGroupDetail.TMSGroupId).focus();
          $('#tmsAddEdit').modal('hide');
        }
      }
    }
    this.ResetSelection();
    if (form != undefined) form.$setPristine();
  }

  EditCMSHierarchy(Type, data) {
    this.DateValidation.IsValidStartDate = true;
    this.DateValidation.IsValidEndDate = true;
    this.DateValidation.IsValidEndDateStartDate = true;
    this.DateValidation.IsValidStartDateEndDate = true;
    this.SelectedData = data;
    this.IsCMSEdit = true;
    this.Action = "Edit";
    this.EditMode = "CMS";
    this.IsWorkStreamSelected = false;
    this.IsFifthLevelCMS = false;
    this.IsMultiSelect = false;
    this.cmsHierarchy.IsTypeStatusShow = false;
    this.DisableTypeOption = true;

    data.IsSelected = true;
    if (Type == 1) {
      this.SelectedCMS = data;
      this.cMSMenuService.GETCMS(data.CMSId).subscribe((response: any) => {
        this.TempcurrentSelectedCMS = cloneDeep(response);
        this.currentSelectedCMS = cloneDeep(response);
        this.oldContractList = response.Contracts;
        this.TempcurrentSelectedWS = cloneDeep(null);
        this.currentSelectedWS = cloneDeep(null);
        this.AssociatedContractsEndToEnd = response.AssociatedContractsEndToEnd;
        this.cmsHierarchy.TypeId = 1;
        this.cmsHierarchy.IsPhoenixMappingEnabled = response.PhoenixMapping != null ? response.PhoenixMapping.IsPhoenixMappingEnabled : false;
        this.cmsHierarchy.IsGovernanceAutoIntegration = response.PhoenixMapping != null ? response.PhoenixMapping.IsGovernanceAutoIntegration : false;

        this.currentSelectedCMS.TempListCMSClientStakeholder = cloneDeep(this.currentSelectedCMS.ClientStakeholderList);
        if (this.sessionStorage.SFEDisable == true) {
          this.PopupTitle = this.currentSelectedCMS.CMSName;
        }
        else {
          this.PopupTitle = "Edit : " + this.currentSelectedCMS.CMSName;
        }

        var cmsstatus = [{ Id: 1, Name: "Open" }];
        this.CmsLifeCycle = cmsstatus;
        this.SelectedLifeCycle = this.CmsLifeCycle[0];
        this.IsCMSClosed = false;
        this.cMSMenuService.GETAllowCMSClosed(data.CMSId).subscribe((response: any) => {
          if (response && response >= 1) {
            this.CmsLifeCycle.push({ Id: 2, Name: "Closed" });
            if (response == 1) {
              this.SelectedLifeCycle = this.CmsLifeCycle[1];
              this.IsStatusDropdownDisable = true;
              this.IsCMSClosed = true;
            }
            else {
              this.IsStatusDropdownDisable = false;
              this.IsCMSClosed = false;
            }

          }
        });
        $(document.querySelector('#hiddenCMSDeliveryLead')).val(this.currentSelectedCMS.DeliveryLead);
        $('#' + this.popupIdCMS).modal('show');
        setTimeout(function test() {
          $('.close').focus();
        }, 0);
        $('#' + this.popupIdCMS + ' .modal-dialog').draggable({ containment: 'parent' });

        this.callCMSEditPopup();
      });
      this.ActivateCMSFirstTab();
    }
    if (Type == 2) {
      this.SelectedWorkstream = data;
      this.cMSMenuService.GETWorkstream(data.WorkstreamId).subscribe((response: any) => {
        this.TempcurrentSelectedWS = cloneDeep(response);
        this.currentSelectedWS = cloneDeep(response);
        this.oldContractList = response.Contracts;
        this.TempcurrentSelectedCMS = cloneDeep(null);
        this.currentSelectedCMS = cloneDeep(null);
        this.cmsHierarchy.TypeId = 2;
        this.AssociatedContractsEndToEnd = response.AssociatedContractsEndToEnd;
        this.IsWorkStreamSelected = true;
        if (this.currentSelectedWS.SelectedWorkstreamAvailablityWindow.length == 0) {
          this.currentSelectedWS.SelectedWorkstreamAvailablityWindow = cloneDeep(this.cmsHierarchy.GetDefaultWeekDays);
        }
        this.IsMultiSelect = true;
        this.cmsHierarchy.TempWorkstreamStatus = cloneDeep(this.cmsHierarchy.WorkstreamStatus);
        this.currentSelectedWS.StartDate = this.datepipe.transform(this.currentSelectedWS.StartDate, 'dd MMM yyyy');
        this.currentSelectedWS.EndDate = this.datepipe.transform(this.currentSelectedWS.EndDate, 'dd MMM yyyy');

        this.SelectedSFEndtoEnd = this.currentSelectedWS.SolutionFactoryE2EName;
        if (this.currentSelectedWS.FactoryWorkstreamIndicator == "Y") {
          this.SFEDisable = true;
          this.sessionStorage.SFEDisable = this.SFEDisable;
          this.PopupTitle = this.currentSelectedWS.WorkStreamName;
        }
        else {
          this.SFEDisable = false;
          this.PopupTitle = "Edit : " + this.currentSelectedWS.WorkStreamName;
        }
        if (this.currentSelectedWS.ClientEndToEndId != null) { this.SFEdropdownDisable = true; }
        else { this.SFEdropdownDisable = false; }
        var deliveryType = this.cmsHierarchy.WorkstreamTypes.filter((x: any) => x.Id == this.currentSelectedWS.WorkstreamTypeId);
        if (deliveryType.length > 0) {
          if (deliveryType[0].ParentId == enumDeliveryType.AD || deliveryType[0].ParentId == enumDeliveryType.Agile) {
            this.IsNotValidDeliverytype = false;
          }
          else {
            this.IsNotValidDeliverytype = true;
          }
        }
        $(document.querySelector('#hiddenWSDeliveryLead')).val(this.currentSelectedWS.DeliveryLead);
        $('#' + this.popupIdCMS).modal('show');
        setTimeout(function test() {
          $('.close').focus();
        }, 0);
        $('#' + this.popupIdCMS + ' .modal-dialog').draggable({ containment: 'parent' });
        this.WSDateValidation();

        this.callCMSEditPopup();
      });
    }

  }

  callCMSEditPopup() {
    // Open EDIT Popup Start
    const initialState = {
      Action: this.Action,
      PopupTitle: this.PopupTitle,
      cmsHierarchy: cloneDeep(this.cmsHierarchy),
      SelectedWorkstream: cloneDeep(this.SelectedWorkstream),
      SelectedCMS: cloneDeep(this.SelectedCMS),
      TempcurrentSelectedCMS: cloneDeep(this.TempcurrentSelectedCMS),
      currentSelectedCMS: cloneDeep(this.currentSelectedCMS),
      TempcurrentSelectedWS: cloneDeep(this.TempcurrentSelectedWS),
      currentSelectedWS: cloneDeep(this.currentSelectedWS),
      IsWorkStreamSelected: false,
      IsCMSSelected: false,
      IsFifthLevelCMS: false,
      IsCMSEdit: true,
      IsMultiSelect: false,
      DisableTypeOption: this.DisableTypeOption,
      SelectedSFEndtoEnd: this.SelectedSFEndtoEnd,
      SFEDisable: this.SFEDisable,
      IsShowMultiContractLink: this.IsShowMultiContractLink,
      AssociatedContractsEndToEnd: cloneDeep(this.AssociatedContractsEndToEnd)
    };
    this.modalRef = this.modalService.show(CMSAddEditComponent, { initialState, backdrop: "static", keyboard: false });
    $(".modal-dialog").addClass('modal-lg');
    setTimeout(function () {
      $('.close').focus();
    });
    this.modalRef.content.setAfterSave.subscribe(result => {
      this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });
      if (result.parentActionToPerform == 'SaveCMS') {
        this.Message = "Saved successfully";
        this.IsReset = false;
        this.sharedService.openAlertPopup(this.Message, window.scrollY);
        this.GetCMSTMSHierarchy(false);
        this.ProcessDemographicAttribute(null, enumEntityType.EndToEnd + "|" + enumEntityType.CMS + "|" + enumEntityType.Workstream);
      }
      else if (result.parentActionToPerform == 'SaveWS') {
        this.currentSelectedWS = result.currentSelectedWS;
        this.Action = result.popupAction;

        this.Message = "Saved successfully";
        this.IsReset = false;
        this.sharedService.openAlertPopup(this.Message, window.scrollY)
        this.GetCMSTMSHierarchy(false);
        this.ProcessDemographicAttribute(null, enumEntityType.EndToEnd + "|" + enumEntityType.CMS + "|" + enumEntityType.Workstream);
      }

      else if (result.parentActionToPerform == 'DeleteWS') {
        this.Action = result.popupAction;
        this.DeletedWorkstream = result.DeletedWorkstream;
        this.ConfirmedYes();
      }
    });
    this.ResetSelectionCMS();
    // Edit Popup END
  }

  SaveCMSAndWorkstream() {
    if (this.cmsHierarchy.TypeId == 1) {
      this.currentSelectedCMS.UserName = this.sessionStorage.enterpriseId;
      this.currentSelectedCMS.LifeCycle = this.SelectedLifeCycle.Id;
      this.currentSelectedCMS.EndToEndType = this.sessionStorage.endToEndType;
      this.FormateMultiContractForInsertDelete(this.currentSelectedCMS.Contracts, this.oldContractList);

      this.cMSMenuService.SaveCMS(this.currentSelectedCMS).subscribe((response: any) => {
        this.cMSMenuService.savePhoenixConfiguration(response).subscribe((result: any) => {
        });
        $('#' + this.popupIdCMS).modal('hide');
        this.ResetSelectionCMS();
        this.GetCMSTMSHierarchy(false);
        if (this.SelectedLifeCycle.Id == 2) {
          this.sessionStorage.clientWMSId = 0;
          this.sessionStorage.cmsName = "";
          this.sessionStorage.selectedSubmissionType = 1;
          return false;
        }
        else {
          if (this.sessionStorage.cmsName && this.sessionStorage.cmsName != this.currentSelectedCMS.CMSName && this.Action == "Edit") {
            this.sessionStorage.cmsName = this.currentSelectedCMS.CMSName;
          }

        }
        this.Message = "Saved successfully";
        $("#alertPopUpStrucutre").modal('show');
        $('.close').focus();
        this.ProcessDemographicAttribute(enumEntityType.EndToEnd + "|" + enumEntityType.CMS);
      });
    }
    else if (this.cmsHierarchy.TypeId == 2) {
      this.currentSelectedWS.UserName = this.sessionStorage.enterpriseId;
      this.currentSelectedWS.EndToEndType = this.sessionStorage.endToEndType;
      this.FormateMultiContractForInsertDelete(this.currentSelectedWS.Contracts, this.oldContractList);
      var cmsStatus = this.currentSelectedWS.SelectedWorkstreamCms.filter((x: any) => x.CmsStatusId == 0);
      if (this.currentSelectedWS.CMSStatusId == enumCMS.ClosedCMS && !this.IsClosedWorkstream() && cmsStatus != undefined) {
        this.currentSelectedWS.SelectedWorkstreamCms = [];
      }
      this.cMSMenuService.SaveWorkStream(this.currentSelectedWS).subscribe((response: any) => {
        $('#' + this.popupIdCMS).modal('hide');
        this.ResetSelectionCMS();
        this.GetCMSTMSHierarchy(false);
        if (this.sessionStorage.workstreamName && this.sessionStorage.workstreamName != this.currentSelectedWS.WorkStreamName && this.Action == "Edit") {
          this.sessionStorage.workstreamName = this.currentSelectedWS.WorkStreamName;
        }

        this.Message = "Saved successfully";
        $("#alertPopUpStrucutre").modal('show');
        $('.close').focus();
        this.ProcessDemographicAttribute(enumEntityType.EndToEnd + "|" + enumEntityType.Workstream);
        if (this.currentSelectedWS.ClientEndToEndId) {
          this.ProcessDemographicAttribute(enumEntityType.EndToEnd + "|" + enumEntityType.Workstream, this.currentSelectedWS.ClientEndToEndId);
        }
      });
    }
  }


  ResetSelection() {
    this.TabChanges = false;
    this.tmsHierarchy.TMSGroupLevelOne.forEach((tmsGroup1: any, key: any) => {
      tmsGroup1.IsSelected = false;
      tmsGroup1.TMS.forEach((tms: any, key: any) => {
        tms.IsSelected = false;
      });
      tmsGroup1.TMSGroupLevelTwo.forEach((tmsGroup2: any, key: any) => {
        tmsGroup2.IsSelected = false;
        tmsGroup2.TMS.forEach((tms: any, key: any) => {
          tms.IsSelected = false;
        });
        tmsGroup2.TMSGroupLevelThree.forEach((tmsGroup3: any, key: any) => {
          tmsGroup3.IsSelected = false;
          tmsGroup3.TMS.forEach((tms: any, key: any) => {
            tms.IsSelected = false;
          });
          tmsGroup3.TMSGroupLevelFour.forEach((tmsGroup4: any, key: any) => {
            tmsGroup4.IsSelected = false;
            tmsGroup4.TMS.forEach((tms: any, key: any) => {
              tms.IsSelected = false;
            });
            tmsGroup4.TMSGroupLevelFive.forEach((tmsGroup5: any, key: any) => {
              tmsGroup5.IsSelected = false;
              tmsGroup5.TMS.forEach((tms: any, key: any) => {
                tms.IsSelected = false;
              });
            });
          });
        });
      });
    });

    this.tmsHierarchy.TMS.forEach((tms: any, key: any) => {
      tms.IsSelected = false;
    });

    this.tmsHierarchy.Teams.forEach((tms: any, key: any) => {
      tms.IsSelected = false;
    });
    this.tmsHierarchy.TMSGroups.forEach((tms: any, key: any) => {
      tms.IsSelected = false;
    });
  }

  ResetSelectionCMS(form: NgForm = undefined, event: any = null) {
    if (this.Action == "Delete") {
      this.Action = "Edit";
    }
    if (event != null) {
      var keycode = event.keyCode;
      if (keycode == 13) { // code for ENTER key
        if (this.cmsHierarchy.TypeId == 2) {
          $('#' + 'MatrixWS' + this.SelectedData.WorkstreamId).focus();
          $('#cmsAddEdit').modal('hide');
        }
        else {
          $('#' + 'MatrixCMS' + this.SelectedData.CMSId).focus();
          $('#cmsAddEdit').modal('hide');
        }
      }
    }
    this.TabChanges = false;
    if (form != undefined) {
      form.form.markAsPristine();
    }

    else {
      // TODO: need to check with the popup's
    }


    this.cmsHierarchy.WorkStreamStructure.forEach((ws0: any, key: any) => {
      ws0.IsSelected = false;
    });
    this.cmsHierarchy.CMSLevelOne.forEach((cms1: any, key: any) => {
      cms1.IsSelected = false;
      cms1.WorkStreamStructure.forEach((ws1: any, key: any) => {
        ws1.IsSelected = false;
      });
      cms1.CMSLevelTwo.forEach((cms2: any, key: any) => {
        cms2.IsSelected = false;
        cms2.WorkStreamStructure.forEach((ws2: any, key: any) => {
          ws2.IsSelected = false;
        });
        cms2.CMSLevelThree.forEach((cms3: any, key: any) => {
          cms3.IsSelected = false;
          cms3.WorkStreamStructure.forEach((ws3: any, key: any) => {
            ws3.IsSelected = false;
          });
          cms3.CMSLevelFour.forEach((cms4: any, key: any) => {
            cms4.IsSelected = false;
            cms4.WorkStreamStructure.forEach((ws4: any, key: any) => {
              ws4.IsSelected = false;
            });
            cms4.CMSLevelFive.forEach((cms5: any, key: any) => {
              cms5.IsSelected = false;
              cms5.WorkStreamStructure.forEach((ws5: any, key: any) => {
                ws5.IsSelected = false;
              });
            });
          });
        });
      });
    });

    this.cmsHierarchy.WorkstreamDataList.forEach((ws: any, key: any) => {
      ws.IsSelected = false;
    });
    this.cmsHierarchy.CMSDataList.forEach((cms: any, key: any) => {
      cms.IsSelected = false;
    });
  }


  AccountabilityChange() {
    if (this.tmsDetail.SelectedDeliveryAccountability) {
      this.DeliveryAccountabilityType();
      this.tmsDetail.SelectedDeliveryAccountabilityType = null;
      this.IsDisableAGDGDU = (this.tmsDetail.SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureGDN) ? false : true;
      this.IsDisableAccType = this.tmsDetail.SelectedDeliveryAccountability == 4 || this.tmsDetail.SelectedDeliveryAccountability == 0 ? true : false;
    }
    else {
      this.tmsHierarchy.DeliveryAccountabilityTypes = [];
      this.tmsDetail.SelectedDeliveryAccountabilityType = null;
      this.IsDisableAccType = true;
      this.IsDisableAGDGDU = true;
    }
  }

  AccountabilityTypeChange() {
    this.tmsDetail.MMEWMUIds = [];
    this.FilterAGListData();
    this.tmsDetail.SelectedAccountGroupId = null;
    this.tmsDetail.SelectedDeliveryGroupId = null;
    this.tmsDetail.SelectedDeliveryUnitId = null;
    this.FilterAGListData();
    this.FilterDUListData();
    this.FilterDGListData();
  }

  FilterAGListData() {
    var MainList = cloneDeep(this.tmsHierarchy.AccountGroups);
    this.TempAccountGroups = cloneDeep(MainList.filter((x: any) => x.ParentId == this.tmsDetail.SelectedDeliveryAccountabilityType));
  }
  FilterDGListData() {
    var MainList = cloneDeep(this.tmsHierarchy.DeliveryGroups);
    this.TempDeliveryGroups = cloneDeep(MainList.filter((x: any) => x.ParentId == this.tmsDetail.SelectedAccountGroupId));
  }
  FilterDUListData() {
    var MainList = cloneDeep(this.tmsHierarchy.DeliveryUnits);
    this.TempDeliveryUnits = cloneDeep(MainList.filter((x: any) => x.ParentId == this.tmsDetail.SelectedDeliveryGroupId));
  }

  ConfirmedYes() {
    if (this.Action == "Delete") {
      var cmsHierarchyDetails = {
        DeletedCMS: this.DeletedCMS,
        DeletedWorkstream: this.DeletedWorkstream
      }
      this.cMSMenuService.DeleteWorkstream(cmsHierarchyDetails).subscribe((response: any) => {
        if (this.sessionStorage.workstreamId) {
          this.sessionStorage.workstreamUId = '';
          this.sessionStorage.workstreamId = 0;
          this.sessionStorage.workstreamName = null;
          this.sessionStorage.clientWMSId = 0;
          this.sessionStorage.clientWMSUId = '';
          this.sessionStorage.cmsName = "";
          this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
          var cmsParent = this.cmsHierarchy.CMSDataList.filter((x: any) => x.CMSId == this.DeletedWorkstream[0].CMSId);
          if (cmsParent && cmsParent.CMSName != null) {
            this.sessionStorage.clientWMSUId = cmsParent.CMSUId;
            this.sessionStorage.clientWMSId = cmsParent.CMSId;
            this.sessionStorage.cmsName = cmsParent.CMSName;
            this.sessionStorage.selectedSubmissionType = enumSubmissionType.ClientWMS;
          }

        } else {
          this.GetCMSTMSHierarchy(false);
        }
        this.TempcurrentSelectedWS = null;
        this.currentSelectedWS = null;
        this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });
        this.Message = "Deleted successfully";
        this.IsReset = false;
        this.sharedService.openAlertPopup(this.Message, window.scrollY);
        this.ProcessDemographicAttribute(null, enumEntityType.EndToEnd + "|" + enumEntityType.CMS + "|" + enumEntityType.Workstream);
      });
    }
    else if (this.Action == "Edit") {
      this.SaveCMSAndWorkstream();
    }
  }

  ProcessDemographicAttribute(sectionName: any = null, endtoEndId: any = null) {
    this.e2edemographicsService.processDemographicAttribute(endtoEndId ? endtoEndId : this.sessionStorage.endToEndId, sectionName).subscribe((response: any) => {
    });
  }

  showManagePopup(attributeVal, name, entity, showStatus) {
    this.gridData = cloneDeep(attributeVal);
    this.attributeName = name;
    this.entity = entity;
    this.showStatus = showStatus;
    $("#" + this.associateContractPopUpId).modal('show');
    $('.close').focus();
  }

  AddAttributesToArray(gridData, selectedValue) {
    if (!selectedValue) {
      return false;
    }
    var contractCode = this.AssociatedContractsEndToEnd.filter((x: any) => x.Id == selectedValue).Name;
    var multiContract = {
      MultiEndToEndEntityAssociationUId: null,
      EndToEndId: selectedValue,
      ContractCode: contractCode,
      Name: contractCode,
      EntityTypeCode: this.entity == "cms" ? "C" : "W",
      CMSId: this.currentSelectedCMS ? this.currentSelectedCMS.CMSId : null,
      WorkstreamId: this.currentSelectedWS ? this.currentSelectedWS.WorkStreamId : null,
    }
    if (gridData) {
      var isDuplicate = gridData.filter((x: any) => x.ContractCode == contractCode);
      if (isDuplicate) {
        this.sharedService.openAlertPopup("Selected " + this.attributeName + " already exists", window.scrollY);
      }
      else {
        gridData.push(multiContract);
        this.sharedService.openAlertPopup(this.attributeName + " " + multiContract.ContractCode + " " + "added", window.scrollY);
      }
    }
    else {
      gridData = [];
      gridData.push(multiContract)
    }
  }

  WSDateValidation() {
    var isValidWorkstreamDates = true;
    if (this.currentSelectedWS.WorkstreamStatusName != 'Closed') {
      this.EndToEndStartDate = this.datepipe.transform(this.cmsHierarchy.EndToEndStartDate, 'dd MMM yyyy');
      this.EndToEndEndDate = this.datepipe.transform(this.cmsHierarchy.EndToEndEndDate, 'dd MMM yyyy');
      var e2eStartDate = new Date(this.EndToEndStartDate)
      var wsStartDate = new Date(this.currentSelectedWS.StartDate);
      var e2eEndDate = new Date(this.EndToEndEndDate)
      var wsEndDate = new Date(this.currentSelectedWS.EndDate);

      if (wsStartDate < e2eStartDate) {
        isValidWorkstreamDates = this.DateValidation.IsValidStartDate = false;
      }

      if (wsEndDate > e2eEndDate) {
        isValidWorkstreamDates = this.DateValidation.IsValidEndDate = false;
      }

      if (wsEndDate < e2eStartDate) {
        isValidWorkstreamDates = this.DateValidation.IsValidEndDateStartDate = false;
      }
      if (wsStartDate > e2eEndDate) {
        isValidWorkstreamDates = this.DateValidation.IsValidStartDateEndDate = false;
      }
    }
    return isValidWorkstreamDates;
  }


  TeamDateValidation() {
    var isValidTeamDates = true;
    this.EndToEndStartDate = this.datepipe.transform(this.tmsHierarchy.EndToEndStartDate, 'dd MMM yyyy');
    this.EndToEndEndDate = this.datepipe.transform(this.tmsHierarchy.EndToEndEndDate, 'dd MMM yyyy');
    var e2eStartDate = new Date(this.EndToEndStartDate)
    var e2eEndDate = new Date(this.EndToEndEndDate)
    if (this.tmsDetail.StartDate != null) {
      var tmsStartDate = new Date(this.tmsDetail.StartDate);
    }
    if (this.tmsDetail.EndDate != null) {
      var tmsEndDate = new Date(this.tmsDetail.EndDate);
    }

    if (tmsStartDate < e2eStartDate) {
      isValidTeamDates = this.DateValidation.IsValidStartDate = false;
    }

    if (tmsEndDate > e2eEndDate) {
      isValidTeamDates = this.DateValidation.IsValidEndDate = false;
    }

    if (tmsEndDate < e2eStartDate) {
      isValidTeamDates = this.DateValidation.IsValidEndDateStartDate = false;
    }
    if (tmsStartDate > e2eEndDate) {
      isValidTeamDates = this.DateValidation.IsValidStartDateEndDate = false;
    }
    return isValidTeamDates;
  }


  IsClosedWorkstream() {
    if (this.currentSelectedWS) {
      return (this.currentSelectedWS.WorkstreamStatusId == 3 || this.currentSelectedWS.WorkstreamStatusId == 8 ||
        this.currentSelectedWS.WorkstreamStatusId == 10 || this.currentSelectedWS.WorkstreamStatusId == 16);
    }
    else {
      return false;
    }
  }


  FormateMultiContractForInsertDelete(updatedContractList, oldContractList) {
    if (oldContractList && oldContractList.length > 0) {
      updatedContractList.forEach((contract: any, key: any) => {
        var isExists = oldContractList.filter((x: any) => x.ContractCode == contract.ContractCode);
        if (!isExists) {
          contract.Action = "Insert";
        }

      });
      oldContractList.forEach((contract: any, key: any) => {
        var isExists = updatedContractList.filter((x: any) => x.ContractCode == contract.ContractCode);
        if (!isExists) {
          contract.Action = "Delete";
          updatedContractList.push(contract);
        }
      });
    }
    else {
      updatedContractList.forEach((contract: any, key: any) => {
        contract.Action = "Insert";
      });
    }
  }

  UsageLogging(moduleAction, tileID, featureAccessID) {
    this.sharedService.applicationUsageLogging(moduleAction, tileID, featureAccessID).subscribe();
  }

  ActivateCMSFirstTab() {
    $("#" + this.phoenixMappingContent).removeClass('active');
    $('#' + this.popupCMSDetailsTab).addClass('active');
    $("#tabPhoenixMapping").removeClass('active');
    $("#tabCMSDetails").addClass('active');
  }

  trackByTMSGroupId(index: number, item: any): any {
    return item.TMSGroupId;
  }

  trackByTMSId(index: number, item: any): any {
    return item.TMSId;
  }

  trackByWorkstreamId(index: number, item: any): any {
    return item.WorkstreamId;
  }

  trackByCMSId(index: number, item: any): any {
    return item.CMSId;
  }

  //ux revamp changes 
  toggleMatrixStructure() {
    if (this.isShowEndToEnd) {
      this.isShowEndToEnd = false;
    }
    else {
      this.isShowEndToEnd = true;
    }
  }

  DisplayMatrixUserGuide() {
    this.router.navigate(['/mywizard/matrixuserguide']);
  }


  setHeight() {
    
    //export const teamMatrix = () => {
    var expand = document.querySelectorAll('.c-list-team-data .-teamExpand');
    var item = document.querySelectorAll('.c-matrix-list-col > .c-matrix-col-list > .c-team-matrix-table-list');
    var heightEle = document.querySelectorAll('.c-list_content-header');
    var data = document.querySelectorAll('.c-list-team-data');
    var step = 0;
    var height = 0;
    var maxHeight = 0;
    //if (item) {
    //  item.forEach(element => {
    //    var items = element.querySelectorAll('.c-matrix-col-list');
    //    step = 0;
    //    //items.forEach(ele => {
    //    //  ele.setAttribute('data-step', step);
    //    //  step = step + 1;
    //    //})
    //  })
    //}
    if (data) {
      console.log('calling setHeight');
      data.forEach(element => {
        height = element.getBoundingClientRect().height;
        console.log('calling setHeight');
        if (height > maxHeight)
          maxHeight = height;
        //heightEle.forEach(h => {
        //  h.style.minHeight = maxHeight + "px";
        //})
        for (var i = 0; i < heightEle.length; i++) {
          heightEle[i].setAttribute('style', 'min-height :' + maxHeight + 'px');
         
        }
      })
    }
    if (expand) {
      expand.forEach(element => {
        console.log('calling setHeight expand');
        element.addEventListener('click', (e) => {
          if (element.closest('.c-team-matrix-table-list').classList.contains('-open')) {
            element.closest('.c-team-matrix-table-list').classList.remove('-open');
            height = element.closest('.c-list-team-data').getBoundingClientRect().height;
            data.forEach(item => {
              maxHeight = item.getBoundingClientRect().height;
              if (maxHeight > height)
                height = maxHeight;
              //heightEle.forEach(h => {
              //  h.style.minHeight = height + "px";
              //})
              for (var i = 0; i < heightEle.length; i++) {
                heightEle[i].setAttribute('style','min-height:' + height + 'px');
              }
            })
          }
          else {
            element.closest('.c-team-matrix-table-list').classList.add('-open');
            data.forEach(item => {
              height = item.getBoundingClientRect().height;
              if (height > maxHeight)
                maxHeight = height;
              //heightEle.forEach(h => {
              //  h.style.minHeight = maxHeight + "px";
              //})
              for (var i = 0; i < heightEle.length; i++) {
                heightEle[i].setAttribute('style','min-height:' + maxHeight + 'px');
              }
            })
          }
        })
      })
    }
    //}
  }
}
