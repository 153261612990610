<form name="teamstructureform" #teamstructureform="ngForm" novalidate>

  <div class="c-myWizard-container c-team-structure">
    <div class="c-myWizard__header">
      <div class="c-myWizard__header-content-left">
        <div class="c-myWizard__header-content-head">
          <a href="javascript:;" class="icon-banner-arrow" role="button" (click)="OnBackClick()" alt="Back" aria-label="Back"></a>
          <div class="-header">Team Structure</div>
        </div>
      </div>
      <div class="c-myWizard__header-content-right">
        <div class="c-header-data">
          <div class="c-header-dropdown">
            <!--<div class="c-dropdown -right -static">
              <a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" title="Download" class="-dropdown-icon"><img src="../assets/toolkit/images/key-contacts/download.svg" alt="Download" /></a>
              <div class="c-dropdown-menu">
                <div class="c-dropdown-body">
                  <a href="javascript:;" class="c-dropdown-item" role="button" title="Download Delivery Profile Guide">Download Delivery Profile Guide</a>
                </div>
              </div>
            </div>-->
            <a href="javascript:;" role="button" data-myw-title="User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="DisplayTMSUserGuide()" class="-dropdown-icon"><img src="../assets/toolkit/images/user-guide-stories/user-guide.svg" alt="User Guide" /></a>
          </div>
        </div>
        <div class="c-header-data">
          <a class="c-myWizard__header-details" role="button" data-toggle="modal" (click)="AddTMS('Group')" [ngClass]="{'-disabled':!IsEditable || DisableAddGroupButton}" [attr.tabindex]="(!IsEditable || DisableAddGroupButton) ? -1 : null" data-modalid="addTeam">
            <img src="../assets/toolkit/images/key-contacts/add.svg" alt="Details" role="presentation" />
            <span>Group</span>
          </a>
          <a class="c-myWizard__header-details" role="button" data-flyoutid="team-addTeam" (click)="AddTMS('TMS')" [ngClass]="{'-disabled':!IsEditable || DisableAddButton}" [attr.tabindex]="(!IsEditable || DisableAddButton) ? -1 : null" aria-label="Add Team (Open a popup)">
            <img src="../assets/toolkit/images/key-contacts/add.svg" alt="Details" role="presentation" />
            <span>Team</span>
          </a>
        </div>
      </div>
    </div>

    <div class="c-myWizard__title">
      <div class="c-myWizard__title-text">
        <ul>
          <li>
            <div *ngIf="EndToEndType == 'I'">
              <span tabindex="0"><span class="-title"></span> {{EndToEndName}}</span>
            </div>
            <div *ngIf="EndToEndType == 'O'">
              <span tabindex="0"><span class="-title"></span>{{EndToEndRefCode}} : {{EndToEndName}}</span>
            </div>
            <div *ngIf="EndToEndType == 'C'">
              <span tabindex="0"><span class="-title"></span>{{EndToEndRefCode}} : {{EndToEndName}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="c-myWizard__title-data">
        <div class="c-legends">
          <div class="c-legends-item">
            <i style="background-color: #7500C0;"></i>
            <span>Team</span>
          </div>
          <div class="c-legends-item">
            <i style="background-color: #E3B7FF;"></i>
            <span>Team Group</span>
          </div>
        </div>
        <div class="c-twoSideToggle">
          <div class="c-twoSideToggle__switch">
            <span class="-prev" [ngClass]="CollapseExpand ? '' : '-active'">Collapse</span>
            <label class="c-twoSideToggle__switch-label">
              <input type="checkbox" (change)="collapseParent($event)">
              <span></span>
            </label>
            <span class="-next" [ngClass]="CollapseExpand ? '-active' : ''">Expand</span>
          </div>
        </div>
        </div>
      </div>
    <div id="client-parentscm">
        <div class="c-accordion -open">
          <div id="client-scm1" class="-collapse -show" data-parentid="client-parentscm">
            <div class="c-accordion__container">
              <div class="c-list-table">
                <div class="c-list-team">
                  <ul class="cms-tms-list" click-elsewhere (clickOutside)="ResetSelection()">
                    <ng-container *ngIf="tmsHierarchy && tmsHierarchy.TMS.length>0">
                      <li class="c-list_content" *ngFor="let tms of tmsHierarchy.TMS" [attr.data-tmsId]="tms.TMSId" [attr.data-item]="tms| json" (click)="SelectTMS(tms);$event.stopPropagation();">
                        <div class="c-list" data-type="Team" [ngClass]="{'cms-tms-item-selected' : tms.IsSelected}">
                          <div class="c-list-moveable">
                            <a href="javascript:;" class="-drag" aria-label="Drag"  data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                          </div>
                          <div class="c-list-text">
                            <i style="background-color: #7500C0;"></i>
                            <span tabindex="0" class="cms-tms-item-text" *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span>&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                            <span tabindex="0" class="cms-tms-item-text" *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span></span>
                            <span tabindex="0" class="cms-tms-item-text" *ngIf="(tms.ReportingType==null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                            <span tabindex="0" class="cms-tms-item-text" *ngIf="(tms.ReportingType==null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}</span>
                          </div>
                          <div *ngIf="IsEditable" class="c-list-icons">
                            <a href="javascript:;" id="TMS{{tms.TMSId}}" class="icon-edit" role="button" data-flyoutid="team-edit"  data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"aria-label="Edit" [ngClass]="{' glyphicon-pencil ':IsEditable}" (click)="EditTMS(null, tms);$event.stopPropagation();" [attr.aria-label]="'Edit Team'+ '-' + tms.TMSName  + '(opens as popup)'"></a>
                            <a href="javascript:;" *ngIf="!tms.PhoenixCreationIndicator" (click)="DeleteTMS(null, tms, $event);$event.stopPropagation();" class="icon-delete" role="button"  data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" data-toggle="modal" data-modalid="deleteModal"></a>
                          </div>
                          <div *ngIf="!IsEditable" class="c-list-icons">
                            <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" [attr.aria-label]="'View Team'+ '-' + tms.TMSName  + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="EditTMS(null, tms);$event.stopPropagation();" role="presentation" alt="View"/></a>
                          </div>
                        </div>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="tmsHierarchy && tmsHierarchy.TMSGroupLevelOne.length>0">
                      <li class="c-list_content" *ngFor="let tmsGroup1 of tmsHierarchy.TMSGroupLevelOne" [ngClass]="{open : (tmsGroup1.TMSGroupLevelTwo.length <= 0 && tmsGroup1.TMS.length <= 0) }" [attr.data-item]="tmsGroup1| json" [attr.data-tmsgroupid]="tmsGroup1.TMSGroupId" (click)="SelectTMSGroup(tmsGroup1);$event.stopPropagation();" aria-expanded="{{tmsGroup1.IsSelected?'true':'false'}}">
                        <div class="c-list" data-type="Group" [ngClass]="{'cms-tms-item-selected' : tmsGroup1.IsSelected, 'c-list-child': tmsGroup1.TMSGroupLevelTwo.length > 0 || tmsGroup1.TMS.length > 0}">
                          <div class="c-list-moveable">
                            <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom"role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                          </div>
                          <div class="c-list-text cms-tms-item-text">
                            <i style="background-color: #E3B7FF;"></i>
                            <a href="javascript:;" class="icon-arrow-down -expand" *ngIf="tmsGroup1.TMSGroupLevelTwo.length > 0 || tmsGroup1.TMS.length > 0"
                                aria-lable="Expand"  data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom"(click)="ExpandTeamStructureList($event, tmsGroup1.TMSGroupId);$event.stopPropagation();"></a>
                            <span tabindex="0" class="cms-tms-item-text">{{tmsGroup1.TMSGroupName}}</span>
                          </div>
                          <div *ngIf="IsEditable" class="c-list-icons">
                            <a href="javascript:;" id="TMSGRP{{tmsGroup1.TMSGroupId}}" [ngClass]="{' glyphicon-pencil ':IsEditable}" (click)="EditTMS(tmsGroup1,null);$event.stopPropagation();" [attr.aria-label]="'Edit Group'+ '-' + tmsGroup1.TMSGroupName + '(opens as popup)'" class="icon-edit" role="button" data-flyoutid="team-edit"  data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"aria-label="Edit"></a>
                            <a href="javascript:;" *ngIf="IsEditable" (click)="DeleteTMS(tmsGroup1,null, $event);$event.stopPropagation();" class="icon-delete" role="button"  data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom"aria-label="Delete" data-toggle="modal" data-modalid="deleteModal"></a>
                          </div>
                          <div *ngIf="!IsEditable" class="c-list-icons">
                            <a href="javascript:;" class="c-view-link" [attr.aria-label]="'View Group'+ '-' + tmsGroup1.TMSGroupName + '(opens as popup)'" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="EditTMS(tmsGroup1,null);$event.stopPropagation();" role="presentation" alt="View"/></a>
                          </div>
                        </div>
                        <ul *ngIf="tmsGroup1.TMSGroupLevelTwo.length > 0 || tmsGroup1.TMS.length > 0">
                          <li class="c-list_content" *ngFor="let tms of tmsGroup1.TMS" [attr.data-tmsId]="tms.TMSId" [attr.data-item]="tms| json" (click)="SelectTMS(tms);$event.stopPropagation();">
                            <div class="c-list" data-type="Team" [ngClass]="{'cms-tms-item-selected' : tms.IsSelected}">
                              <div class="c-list-moveable">
                                <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                              </div>
                              <div class="c-list-text">
                                <i style="background-color: #7500C0;"></i>
                                <span tabindex="0" class="cms-tms-item-text"  *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span>&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                                <span tabindex="0" class="cms-tms-item-text"  *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span></span>
                                <span tabindex="0" class="cms-tms-item-text"  *ngIf="(tms.ReportingType==null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                                <span tabindex="0" class="cms-tms-item-text"  *ngIf="(tms.ReportingType==null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}</span>
                              </div>
                              <div *ngIf="IsEditable" class="c-list-icons">
                                <a href="javascript:;" id="TMS{{tms.TMSId}}" [ngClass]="{' glyphicon-pencil ':IsEditable}" (click)="EditTMS(null, tms);$event.stopPropagation();" [attr.aria-label]="'Edit Group'+ '-' + tms.TMSName + '(opens as popup)'" class="icon-edit" role="button" data-flyoutid="team-edit"  data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"aria-label="Edit"></a>
                                <a href="javascript:;" *ngIf="!tms.PhoenixCreationIndicator" (click)="DeleteTMS(null, tms, $event);$event.stopPropagation();" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" data-toggle="modal" data-modalid="deleteModal"></a>
                              </div>
                              <div *ngIf="!IsEditable" class="c-list-icons">
                                <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" [attr.aria-label]="'View Group'+ '-' + tms.TMSName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="EditTMS(null,tms);$event.stopPropagation();" role="presentation" alt="View"/></a>
                              </div>
                            </div>
                          </li>
                          <li *ngFor="let tmsGroup2 of tmsGroup1.TMSGroupLevelTwo" [ngClass]="{open : (tmsGroup2.TMSGroupLevelThree.length <= 0 && tmsGroup2.TMS.length <= 0)}" class="c-list_content" [attr.data-item]="tmsGroup2| json" [attr.data-tmsgroupid]="tmsGroup2.TMSGroupId" (click)="SelectTMSGroup(tmsGroup2);$event.stopPropagation();" aria-expanded="{{tmsGroup2.IsSelected?'true':'false'}}">
                            <div class="c-list" data-type="Group"
                                  [ngClass]="{'cms-tms-item-selected' : tmsGroup2.IsSelected, 'c-list-child':tmsGroup2.TMSGroupLevelThree.length > 0 || tmsGroup2.TMS.length > 0}">
                              <div class="c-list-moveable">
                                <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                              </div>
                              <div class="c-list-text cms-tms-item-text">
                                <i style="background-color: #E3B7FF;"></i>
                                <a href="javascript:;" class="icon-arrow-down -expand"
                                    *ngIf="tmsGroup2.TMSGroupLevelThree.length > 0 || tmsGroup2.TMS.length > 0"
                                    aria-lable="Expand"  data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="ExpandTeamStructureList($event, tmsGroup2.TMSGroupId);$event.stopPropagation();"></a>

                                <span tabindex="0" class="cms-tms-item-text">{{tmsGroup2.TMSGroupName}}</span>
                              </div>
                              <div *ngIf="IsEditable" class="c-list-icons">
                                <a href="javascript:;" id="TMSGRP{{tmsGroup2.TMSGroupId}}" [ngClass]="{' glyphicon-pencil ':IsEditable}" (click)="EditTMS(tmsGroup2, null);$event.stopPropagation();" [attr.aria-label]="'Edit Group'+ '-' + tmsGroup2.TMSGroupName + '(opens as popup)'" class="icon-edit" role="button" data-flyoutid="team-edit"  data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit"></a>
                                <a href="javascript:;" (click)="DeleteTMS(tmsGroup2, null, $event);$event.stopPropagation();" class="icon-delete" role="button"  data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" data-toggle="modal" data-modalid="deleteModal"></a>
                              </div>
                              <div *ngIf="!IsEditable" class="c-list-icons">
                                <a href="javascript:;" class="c-view-link" [attr.aria-label]="'View Group'+ '-' + tmsGroup2.TMSGroupName + '(opens as popup)'" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="EditTMS(tmsGroup2, null);$event.stopPropagation();" role="presentation" alt="View"/></a>
                              </div>
                            </div>
                            <ul *ngIf="tmsGroup2.TMSGroupLevelThree.length > 0 || tmsGroup2.TMS.length > 0">
                              <li class="c-list_content" *ngFor="let tms of tmsGroup2.TMS" [attr.data-tmsId]="tms.TMSId" [attr.data-item]="tms| json" (click)="SelectTMS(tms);$event.stopPropagation();">
                                <div class="c-list" data-type="Team" [ngClass]="{'cms-tms-item-selected' : tms.IsSelected}">
                                  <div class="c-list-moveable">
                                    <a href="javascript:;" class="-drag" aria-label="Drag"  data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                  </div>
                                  <div class="c-list-text">
                                    <i style="background-color: #7500C0;"></i>
                                    <span tabindex="0" *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span>&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                                    <span tabindex="0" *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span></span>
                                    <span tabindex="0" *ngIf="(tms.ReportingType==null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                                    <span tabindex="0" *ngIf="(tms.ReportingType==null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}</span>
                                  </div>
                                  <div *ngIf="IsEditable" class="c-list-icons">
                                    <a href="javascript:;" id="TMS{{tms.TMSId}}" [ngClass]="{' glyphicon-pencil ':IsEditable}" (click)="EditTMS(null, tms);$event.stopPropagation();" [attr.aria-label]="'Edit Group'+ '-' + tms.TMSName + '(opens as popup)'" class="icon-edit" role="button" data-flyoutid="team-edit"  data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom"aria-label="Edit"></a>
                                    <a href="javascript:;" *ngIf="!tms.PhoenixCreationIndicator" (click)="DeleteTMS(null, tms,$event);$event.stopPropagation();" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" data-toggle="modal" data-modalid="deleteModal"></a>
                                  </div>
                                  <div *ngIf="!IsEditable" class="c-list-icons">
                                    <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" [attr.aria-label]="'View Group'+ '-' + tms.TMSName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="EditTMS(null,tms);$event.stopPropagation();" role="presentation" alt="View"/></a>
                                  </div>
                                </div>
                              </li>
                              <li *ngFor="let tmsGroup3 of tmsGroup2.TMSGroupLevelThree" [ngClass]="{open : (tmsGroup3.TMSGroupLevelFour.length <= 0 && tmsGroup3.TMS.length <= 0)}" class="c-list_content" [attr.data-item]="tmsGroup3| json" [attr.data-tmsgroupid]="tmsGroup3.TMSGroupId" (click)="SelectTMSGroup(tmsGroup3);$event.stopPropagation();" aria-expanded="{{tmsGroup3.IsSelected?'true':'false'}}">
                                <div class="c-list" data-type="Group"
                                      [ngClass]="{'cms-tms-item-selected': tmsGroup3.IsSelected, 'c-list-child': tmsGroup3.TMSGroupLevelFour.length > 0 || tmsGroup3.TMS.length > 0}">
                                  <div class="c-list-moveable">
                                    <a href="javascript:;" class="-drag" aria-label="Drag"  data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                  </div>
                                  <div class="c-list-text cms-tms-item-text">
                                    <i style="background-color: #E3B7FF;"></i>
                                    <a href="javascript:;" class="icon-arrow-down -expand"
                                        *ngIf="tmsGroup3.TMSGroupLevelFour.length > 0 || tmsGroup3.TMS.length > 0"
                                        aria-lable="Expand"  data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="ExpandTeamStructureList($event, tmsGroup3.TMSGroupId);$event.stopPropagation();"></a>
                                    <span tabindex="0" class="cms-tms-item-text">{{tmsGroup3.TMSGroupName}}</span>
                                  </div>
                                  <div *ngIf="IsEditable" class="c-list-icons">
                                    <a href="javascript:;" id="TMSGRP{{tmsGroup3.TMSGroupId}}" [ngClass]="{' glyphicon-pencil ':IsEditable}" (click)="EditTMS(tmsGroup3, null);$event.stopPropagation();" [attr.aria-label]="'Edit Group'+ '-' + tmsGroup3.TMSGroupName + '(opens as popup)'" class="icon-edit" role="button" data-flyoutid="team-edit"  data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit"></a>
                                    <a href="javascript:;" *ngIf="IsEditable" (click)="DeleteTMS(tmsGroup3, null, $event);$event.stopPropagation();" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" data-toggle="modal" data-modalid="deleteModal"></a>
                                  </div>
                                  <div *ngIf="!IsEditable" class="c-list-icons">
                                    <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" [attr.aria-label]="'View Group'+ '-' + tmsGroup3.TMSGroupName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="EditTMS(tmsGroup3,null);$event.stopPropagation();" role="presentation" alt="View"/></a>
                                  </div>
                                </div>
                                <ul *ngIf="tmsGroup3.TMSGroupLevelFour.length > 0 || tmsGroup3.TMS.length > 0">
                                  <li class="c-list_content" *ngFor="let tms of tmsGroup3.TMS" [attr.data-item]="tms| json" [attr.data-tmsId]="tms.TMSId" (click)="SelectTMS(tms);$event.stopPropagation();">
                                    <div class="c-list" data-type="Team" [ngClass]="{'cms-tms-item-selected' : tms.IsSelected}">
                                      <div class="c-list-moveable">
                                        <a href="javascript:;" class="-drag" aria-label="Drag"  data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                      </div>
                                      <div class="c-list-text">
                                        <i style="background-color: #7500C0;"></i>
                                        <span tabindex="0"  *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span>&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                                        <span tabindex="0"  *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span></span>
                                        <span tabindex="0"  *ngIf="(tms.ReportingType==null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                                        <span tabindex="0"  *ngIf="(tms.ReportingType==null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}</span>
                                      </div>
                                      <div *ngIf="IsEditable" class="c-list-icons">
                                        <a href="javascript:;" id="TMS{{tms.TMSId}}" [ngClass]="{' glyphicon-pencil ':IsEditable}" (click)="EditTMS(null, tms);$event.stopPropagation();" [attr.aria-label]="'Edit Group'+ '-' + tms.TMSName + '(opens as popup)'" class="icon-edit" role="button" data-flyoutid="team-edit"  data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit"></a>
                                        <a href="javascript:;" *ngIf="!tms.PhoenixCreationIndicator" (click)="DeleteTMS(null, tms, $event);$event.stopPropagation();" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" data-toggle="modal" data-modalid="deleteModal"></a>
                                      </div>
                                      <div *ngIf="!IsEditable" class="c-list-icons">
                                        <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" [attr.aria-label]="'View Group'+ '-' + tms.TMSName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="EditTMS(null,tms);$event.stopPropagation();" role="presentation" alt="View"/></a>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="c-list_content" *ngFor="let tmsGroup4 of tmsGroup3.TMSGroupLevelFour" [ngClass]="{open : (tmsGroup4.TMSGroupLevelFive.length <= 0 && tmsGroup4.TMS.length <= 0)}" [attr.data-item]="tmsGroup4| json" [attr.data-tmsgroupid]="tmsGroup4.TMSGroupId" (click)="SelectTMSGroup(tmsGroup4);$event.stopPropagation();" aria-expanded="{{tmsGroup4.IsSelected?'true':'false'}}">
                                    <div class="c-list" data-type="Group"
                                          [ngClass]="{'cms-tms-item-selected' : tmsGroup4.IsSelected, 'c-list-child': tmsGroup4.TMSGroupLevelFive.length > 0 || tmsGroup4.TMS.length > 0}">
                                      <div class="c-list-moveable">
                                        <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                      </div>
                                      <div class="c-list-text cms-tms-item-text">
                                        <i style="background-color: #E3B7FF;"></i>
                                        <a href="javascript:;" class="icon-arrow-down -expand"
                                            *ngIf="tmsGroup4.TMSGroupLevelFive.length > 0 || tmsGroup4.TMS.length > 0"
                                            aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="ExpandTeamStructureList($event, tmsGroup4.TMSGroupId);$event.stopPropagation();"></a>
                                        <span tabindex="0" class="cms-tms-item-text">{{tmsGroup4.TMSGroupName}}</span>
                                      </div>
                                      <div *ngIf="IsEditable" class="c-list-icons">
                                        <a href="javascript:;" id="TMSGRP{{tmsGroup4.TMSGroupId}}" [ngClass]="{' glyphicon-pencil ':IsEditable}" (click)="EditTMS(tmsGroup4, null);$event.stopPropagation();" [attr.aria-label]="'Edit Group'+ '-' + tmsGroup4.TMSGroupName + '(opens as popup)'" class="icon-edit" role="button" data-flyoutid="team-edit" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit"></a>
                                        <a href="javascript:;" *ngIf="IsEditable" (click)="DeleteTMS(tmsGroup4, null, $event);$event.stopPropagation();" class="icon-delete" role="button"  data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" data-toggle="modal" data-modalid="deleteModal"></a>
                                      </div>
                                      <div *ngIf="!IsEditable" class="c-list-icons">
                                        <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" [attr.aria-label]="'View Group'+ '-' + tmsGroup4.TMSGroupName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="EditTMS(tmsGroup4, null);$event.stopPropagation();" role="presentation" alt="View"/></a>
                                      </div>
                                    </div>
                                    <ul *ngIf="tmsGroup4.TMSGroupLevelFive.length > 0 || tmsGroup4.TMS.length > 0">
                                      <li class="c-list_content" *ngFor="let tms of tmsGroup4.TMS" [attr.data-item]="tms| json" [attr.data-tmsId]="tms.TMSId" (click)="SelectTMS(tms);$event.stopPropagation();">
                                        <div class="c-list" data-type="Team" [ngClass]="{'cms-tms-item-selected' : tms.IsSelected}">
                                          <div class="c-list-moveable">
                                            <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                          </div>
                                          <div class="c-list-text">
                                            <i style="background-color: #7500C0;"></i>
                                            <span tabindex="0"  *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span>&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                                            <span tabindex="0"  *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span></span>
                                            <span tabindex="0"  *ngIf="(tms.ReportingType==null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                                            <span tabindex="0"  *ngIf="(tms.ReportingType==null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}</span>
                                          </div>
                                          <div *ngIf="IsEditable" class="c-list-icons">
                                            <a href="javascript:;" id="TMS{{tms.TMSId}}" [ngClass]="{' glyphicon-pencil ':IsEditable}" (click)="EditTMS(null, tms);$event.stopPropagation();" [attr.aria-label]="'Edit Group'+ '-' + tms.TMSName + '(opens as popup)'" class="icon-edit" role="button" data-flyoutid="team-edit"  data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit"></a>
                                            <a href="javascript:;" *ngIf="!tms.PhoenixCreationIndicator" (click)="DeleteTMS(null, tms, $event);$event.stopPropagation();" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" data-toggle="modal" data-modalid="deleteModal"></a>
                                          </div>
                                          <div *ngIf="!IsEditable" class="c-list-icons">
                                            <a href="javascript:;" class="c-view-link" [attr.aria-label]="IsEditable?'Edit Group':'View Group'+ '-' + tms.TMSName + '(opens as popup)'" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="EditTMS(null,tms);$event.stopPropagation();" role="presentation" alt="View"/></a>
                                          </div>
                                        </div>
                                      </li>
                                      <li class="c-list_content" *ngFor="let tmsGroup5 of tmsGroup4.TMSGroupLevelFive" [ngClass]="{open : (tmsGroup5.TMS.length <= 0)}" [attr.data-item]="tmsGroup5| json" [attr.data-tmsgroupid]="tmsGroup5.TMSGroupId" (click)="SelectTMSGroup(tmsGroup5);$event.stopPropagation();" aria-expanded="{{tmsGroup5.IsSelected?'true':'false'}}">
                                        <div class="c-list" data-type="Group"
                                              [ngClass]="{'cms-tms-item-selected' : tmsGroup5.IsSelected, 'c-list-child': tmsGroup5.TMS.length > 0}">
                                          <div class="c-list-moveable">
                                            <a href="javascript:;" class="-drag" aria-label="Drag" data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                          </div>
                                          <div class="c-list-text cms-tms-item-text">
                                            <i style="background-color: #E3B7FF;"></i>
                                            <a href="javascript:;" class="icon-arrow-down -expand"
                                                *ngIf="tmsGroup5.TMS.length > 0"
                                                aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="ExpandTeamStructureList($event, tmsGroup5.TMSGroupId);$event.stopPropagation();"></a>
                                            <span class="cms-tms-item-text">{{tmsGroup5.TMSGroupName}}</span>
                                          </div>
                                          <div *ngIf="IsEditable" class="c-list-icons">
                                            <a href="javascript:;" id="TMSGRP{{tmsGroup5.TMSGroupId}}" [ngClass]="{' glyphicon-pencil ':IsEditable}" (click)="EditTMS(tmsGroup5, null);$event.stopPropagation();" [attr.aria-label]="'Edit Group' + '-' + tmsGroup5.TMSGroupName + '(opens as popup)'" class="icon-edit" role="button" data-flyoutid="team-edit" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit"></a>
                                            <a href="javascript:;" *ngIf="IsEditable" (click)="DeleteTMS(tmsGroup5, null, $event);$event.stopPropagation();" class="icon-delete" role="button"  data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" data-toggle="modal" data-modalid="deleteModal"></a>
                                          </div>
                                          <div *ngIf="!IsEditable" class="c-list-icons">
                                            <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" [attr.aria-label]="'View Group' + '-' + tmsGroup5.TMSGroupName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="EditTMS(tmsGroup5, null);$event.stopPropagation();" role="presentation" alt="View"/></a>
                                          </div>
                                        </div>
                                        <ul *ngIf="tmsGroup5.TMS.length > 0">
                                          <li class="c-list_content" *ngFor="let tms of tmsGroup5.TMS" [attr.data-item]="tms| json" [attr.data-tmsId]="tms.TMSId" (click)="SelectTMS(tms);$event.stopPropagation();">
                                            <div class="c-list" data-type="Team" [ngClass]="{'cms-tms-item-selected' : tms.IsSelected}">
                                              <div class="c-list-moveable">
                                                <a href="javascript:;" class="-drag" aria-label="Drag"  data-myw-title="Drag" data-myw-toggle="tooltip" data-myw-placement="bottom" role="button"><img src="assets/toolkit/images/client-structure/drag.svg" alt="drag"></a>
                                              </div>
                                              <div class="c-list-text">
                                                <i style="background-color: #7500C0;"></i>
                                                <span tabindex="0"  *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span>&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                                                <span tabindex="0"  *ngIf="(tms.ReportingType!=null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.ReportingType}}</span></span>
                                                <span tabindex="0"  *ngIf="(tms.ReportingType==null)&&(tms.TMSLead!=null&&tms.TMSLead!='')">{{tms.TMSName}}&nbsp;-&nbsp;<span class="small">{{tms.TMSLead}}</span></span>
                                                <span tabindex="0"  *ngIf="(tms.ReportingType==null)&&(tms.TMSLead==null||tms.TMSLead=='')">{{tms.TMSName}}</span>
                                              </div>
                                              <div *ngIf="IsEditable" class="c-list-icons">
                                                <a href="javascript:;" id="TMS{{tms.TMSId}}" [ngClass]="{' glyphicon-pencil ':IsEditable}" (click)="EditTMS(null, tms);$event.stopPropagation();" [attr.aria-label]="'Edit Group'+ '-' + tms.TMSName + '(opens as popup)'" class="icon-edit" role="button" data-flyoutid="team-edit" data-myw-title="Edit" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Edit"></a>
                                                <a href="javascript:;" *ngIf="!tms.PhoenixCreationIndicator" (click)="DeleteTMS(null, tms, $event);$event.stopPropagation();" class="icon-delete" role="button" data-myw-title="Delete" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Delete" data-toggle="modal" data-modalid="deleteModal"></a>
                                              </div>
                                              <div *ngIf="!IsEditable" class="c-list-icons">
                                                <a href="javascript:;" class="c-view-link" [ngClass]="{'glyphicon-binacular pull-right' : !IsEditable }" [attr.aria-label]="'View Group'+ '-' + tms.TMSName + '(opens as popup)'" role="button" data-myw-title="View" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="View"><img src="../assets/toolkit/images/view.svg" (click)="EditTMS(null,tms);$event.stopPropagation();" role="presentation" alt="View"/></a>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  <div class="c-myWizard-update__container ">
    <div class="c-myWizard-container">
      <div class="c-myWizard-update__row">
        <div class="c-myWizard-update__details">
          <div class="c-myWizard-update__input">
            <label>Last Updated By:</label>
            <span class="-input" *ngIf="tmsHierarchy">{{tmsHierarchy.LastModifiedByUserForTMS}}</span>
          </div>
          <div class="c-myWizard-update__input">
            <label>Last Updated On:</label>
            <span class="-input" *ngIf="tmsHierarchy">{{tmsHierarchy.LastModifiedByOnForTMS |date:'dd MMM yyyy h:mm a'}} (GMT)</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="c-select-card" *ngIf="tmsHierarchy && (tmsHierarchy.TMS.length > 0 ||tmsHierarchy.TMSGroupLevelOne.length > 0)">
    <div class="container">
      <div class="c-select-card__wrapper">
        <div class="c-select-card__left">
          <div class="c-select-card__text">Changes made will be lost unless confirmed. Please click 'Save' button to confirm.</div>
          <!--<div class="c-select-card__text">Are you sure you want to move workstream from CMS2 to individual work stream</div>-->
        </div>
        <div class="c-select-card__right">
          <button data-toggle="modal" (click)="CancelDragAndDrop()" [ngClass]="{'-disabled':!IsEditable || (!DraggedItems || DraggedItems.length <=0)}" class="o-secondary-button" role="button">
            Reset
          </button>

          <button class="o-primary-button" role="button" (click)="SaveDragAndDrop()" [ngClass]="{'-disabled':!IsEditable || (!DraggedItems || DraggedItems.length <=0)}">
            Save
          </button>

        </div>
      </div>
    </div>
  </div>
  <div class="c-select-card__placeholder"></div>
</form>
