import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionStorage } from '../context/sessionstorage';
import { Observable } from 'rxjs';

@Injectable()
export class SecurityService {
  constructor(private sessionStorage: SessionStorage, private httpService: HttpService) { }
  getEndToEndList(roleTypeCode: any) {
    return this.httpService.get('/api/EndToEndList/' + roleTypeCode, true);
  }
  getClientList() {
    return this.httpService.get('/api/ClientList', true);
  }
  getSecurityDetails(selectedapplicationType:any) {
    return this.httpService.get('/api/security/section' + '/?selectedApplicationType=' + selectedapplicationType,true);
  }
  getSecurityInformation(enterpriseId: any, roleTypeCode: any, selectedapplicationType:any) {
    return this.httpService.get('/api/Security/' + enterpriseId + '/' + roleTypeCode + "/?selectedApplicationType=" + selectedapplicationType, true);
  }
  insertSecurityInformation(data: any): Observable<any> {
    return this.httpService.post('/api/Security', data, true);
  }
  deleteSecurityInformation(data: any): Observable<any> {
    return this.httpService.post('/api/Security/Delete', data, true);
  }
  deleteSecurityInformationLogging(data: any): Observable<any> {
    return this.httpService.post('/api/Security/Delete/Log', data, true);
  }
  deleteSecurityInformationByUser(enterpriseId: any, selectedapplicationType) {
    return this.httpService.post('/api/Security/Delete/' + enterpriseId + "/?modifiedBy=" + this.sessionStorage.enterpriseId + "&selectedApplicationType=" + selectedapplicationType + '&applicationName=FDS', true);
  }
  verifyUserRole(enterpriseId: any, roleTypeCode: any, endtoEndUId: any) {
    return this.httpService.get('/api/Security/VerifyRole/' + enterpriseId + '/' + roleTypeCode + '/' + endtoEndUId);
  }
  getUserRoleType(enterpriseId: string) {
    return this.httpService.get('/api/security/useraccess?enterpriseId=' + enterpriseId, true);
  }  
}
