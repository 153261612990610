<div class="c-portfolio">
  <div class="app-container">
    <div class="c-myWizard-card">

      <ng-container *ngFor="let tile of tiles; trackBy : trackByIndex;">
        <div class="c-myWizard-card-tile">
          <tile [tile]="tile"></tile>
        </div>
      </ng-container>

    </div>
  </div>
</div>




