<!--<form name="avandeEACform" #avandeEACform="ngForm" novalidate (ngSubmit)="SaveAvanade(avandeEACform.valid,avandeEACform)" id="avanade">
  <div class="row page-title" style="margin-top:20px;">
    <div class="col-xs-12 col-md-7">
      <h1>
        <a href="javascript:void(0)" class="back-link" (click)="OnBackClick()"><span class="glyphicon glyphicon-menu-left"></span>Back</a>
        Avanade EAC Forecast
      </h1>
    </div>
  </div>
  <p>Required fields are marked with an asterisk (<span class="required">*</span>) .</p>
  <br />
  <div class="row">
    <div class="col-xs-12 col-md-6 form-horizontal">
      <div class="form-group">
        <label class="col-xs-12 col-sm-5 col-lg-6 control-label bold eac-label-text-left" for="EacLikelihood_Info" id="EACLikelihood" style="padding-right:30px;text-align: left;">EAC Likelihood</label>
        <div class="col-xs-12 col-sm-7 col-lg-6">
          <select id="EacLikelihood_Info" name="EACLike" #EACLike="ngModel" role="listbox" [disabled]="roleAccess=='V'"
                  [(ngModel)]="AvanadeEAC.EacLikelihoodId" [required]="true" class="form-control"
                  (change)="ChangeEACLikelihood(AvanadeEAC.EacLikelihoodId);">
            <option value="0">--Select--</option>
            <option *ngFor="let Eac of AvanadeEAC.EACLikelihood; trackBy : trackByName;" role="option" [value]="Eac.Id">{{Eac.Name}}</option>
          </select>
          <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage"></div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-xs-12 col-sm-5 col-lg-6 control-label bold eac-label-text-left" for="RevenueRecognition_Info" id="RevenueRecognition">
          Revenue Recognition &nbsp;<span *ngIf="AvanadeEAC.EacLikelihoodId == 1 || AvanadeEAC.EacLikelihoodId == 2 || AvanadeEAC.EacLikelihoodId== 3" class="required">*</span>
        </label>
        <div class="col-xs-12 col-sm-7 col-lg-6">
          <select id="RevenueRecognition_Info" name="Revenue" #Revenue="ngModel" role="listbox"
                  [disabled]="roleAccess=='V' || AvanadeEAC.EacLikelihoodId == 0"
                  [(ngModel)]="AvanadeEAC.EacRevenueRecognitionId" [required]="(AvanadeEAC.EacLikelihoodId == 1 || AvanadeEAC.EacLikelihoodId ==2 ||  AvanadeEAC.EacLikelihoodId ==3) && AvanadeEAC.EacRevenueRecognitionId == ''" class="form-control"
                  [ngClass]="{'has-error':avandeEACform.submitted && Revenue.invalid}">
            <option value="">--Select--</option>
            <option *ngFor="let Eac of AvanadeEAC.EACRevenueRecognition; trackBy : trackByName;" role="option" [value]="Eac.Id">{{Eac.Name}}</option>
          </select>

          <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage"
               [ngClass]="{'is-error':avandeEACform.submitted && Revenue.invalid}">Please Select Revenue Recognition</div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-xs-12 col-sm-5 col-lg-6 control-label bold eac-label-text-left" for="ForecastedEACChange_Info" id="ForecastedEACChange">Forecasted EAC Change in(Select FY & QTR)</label>
        <div class="col-xs-12 col-sm-7 col-lg-6">
          <select id="ForecastedEACChange_Info" name="SelectEACChange" #SelectEACChange="ngModel"
                  [disabled]="roleAccess=='V' ||  isDisabled || AvanadeEAC.EacLikelihoodId == 0"
                  [(ngModel)]="AvanadeEAC.EacForecastedChange" class="form-control"
                  (change)="OnChangeQuarter(AvanadeEAC.EacForecastedChange)">
            <option value="0">--Select--</option>
            <option *ngFor="let Eac of quarter; let i=index; trackBy : trackByQuarter;" role="option" [value]="Eac.quarter">{{Eac.quarter}}</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="col-xs-12 col-sm-5 col-lg-6 control-label bold eac-label-text-left" for="MonthEACChange_Info" id="MonthEACChange">Month EAC Change to be Processed</label>
        <div class="col-xs-12 col-sm-7 col-lg-6">
          <select id="MonthEACChange_Info" name="SelectMonth" #SelectMonth="ngModel" [disabled]="roleAccess=='V' ||  isDisabled  || AvanadeEAC.EacLikelihoodId == 0" class="form-control"
                  [(ngModel)]="AvanadeEAC.EacMonthChange" role="listbox">
            <option value="0">--Select--</option>
            <option *ngFor="let Eac of months; let i=index; trackBy : trackByName;" role="option" [value]="Eac.Name">{{Eac.Name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-6 form-horizontal">
      <div class="form-group">
        <label id="FullCCIEACChangeAmount" class="col-xs-12 col-sm-5 col-lg-12 control-label bold eac-label-text-left">Full CCI EAC Change Amount:</label>
      </div>
      <div class="form-group">
        <label style="padding-left: 10% !important;text-align: left !important;" id="LikelyOutcome" class="col-xs-12 col-sm-5 col-lg-6 control-label bold eac-label-padding eac-label-text-left">Likely Outcome($000s(+/-))</label>
        <div class="col-xs-12 col-sm-7 col-lg-6">
          <input name="Likelyoutcome" aria-labelledby="LikelyOutcome" maxlength="10" [disabled]="roleAccess=='V' ||  isDisabled || AvanadeEAC.EacLikelihoodId == 0" type="text" [(ngModel)]="AvanadeEAC.LikelyOutcome" (keypress)="KeyPressNumeric($event, AvanadeEAC.LikelyOutcome)" (paste)="AvanadeEAC.LikelyOutcome = PasteNumeric($event)" class="form-control" />
        </div>
      </div>
      <div class="form-group">
        <label style="padding-left: 10% !important;text-align: left !important;" id="WorstCase" class="col-xs-12 col-sm-5 col-lg-6 control-label bold eac-label-padding eac-label-text-left">Worst Case($000s(+/-))</label>
        <div class="col-xs-12 col-sm-7 col-lg-6">
          <input name="Worstcase" aria-labelledby="WorstCase" maxlength="10" [disabled]="roleAccess=='V' ||  isDisabled || AvanadeEAC.EacLikelihoodId == 0" type="text" [(ngModel)]="AvanadeEAC.WorstCase" (keypress)="KeyPressNumeric($event, AvanadeEAC.WorstCase)" (paste)="AvanadeEAC.WorstCase = PasteNumeric($event)" class="form-control" />
        </div>
      </div>
      <div class="form-group padding-top-10">
        <label id="EstimatedFullContractLifeNR" class="col-xs-12 col-sm-5 col-lg-6 control-label bold eac-label-text-left">Estimated Full Contract Life NR Change($000s(+/-))</label>
        <div class="col-xs-12 col-sm-7 col-lg-6">
          <input name="EstimatedFullContractLifeNR" aria-labelledby="EstimatedFullContractLifeNR" maxlength="10"
                 [disabled]="roleAccess=='V' ||  isDisabled || AvanadeEAC.EacLikelihoodId == 0" type="text" [(ngModel)]="AvanadeEAC.EstimatedFullContract" (keypress)="KeyPressNumeric($event, AvanadeEAC.EstimatedFullContract)" (paste)="AvanadeEAC.EstimatedFullContract = PasteNumeric($event)" class="form-control" />
        </div>
      </div>
      <div class="form-group">
        <label class="col-xs-12 col-sm-5 col-lg-6 control-label bold eac-label-text-left" for="Execution_Info" id="ExecutionNonExecutionEAC"> Execution / Non Execution EAC</label>
        <div class="col-xs-12 col-sm-7 col-lg-6">
          <select id="Execution_Info" name="Execution" #Execution="ngModel" [disabled]="roleAccess=='V' || isDisabled || AvanadeEAC.EacLikelihoodId == 0" class="form-control"
                  role="listbox" [(ngModel)]="AvanadeEAC.EacExecutionId">
            <option value="0">--Select--</option>
            <option *ngFor="let Eac of AvanadeEAC.EACExecution; trackBy : trackByName;" role="option" [value]="Eac.Id">{{Eac.Name}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="row padding-top-10">
    <div class="col-xs-12 text-right">
      <button class="btn btn-primary" type="submit" value="submit" [disabled]="roleAccess=='V'" aria-label="Save">Save</button>
      <button class="btn btn-primary" id="btnCancel_Main" type="button" [disabled]="roleAccess=='V'" (click)="cancelEAC($event);" aria-label="Cancel">Cancel</button>
      <div class="pull-left text-left">
        <span class="small bold">Updated By: </span><span class="small">{{AvanadeEAC.ModifiedByUser}}</span><br>
        <span class="small bold">Updated On: </span><span class="small">{{AvanadeEAC.ModifiedOn|date:'dd MMM yyyy h:mm a'}}{{AvanadeEAC.ModifiedOn == null ? "" : " (GMT)"}}</span>
      </div>
    </div>
  </div>
  <div class="modal" id="EacLikeLiHood" data-backdrop="static" data-keyboard="false" [ngStyle]="{'display': EacLikeLiHoodPopUp ? 'block' : 'none', 'opacity': 1}">
    <div class="modal-dialog-wrap">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button id="btnClose_EACLikelyHood" type="button" class="close" data-dismiss="modal" (click)="cancelEAC($event)" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Please confirm</h4>
          </div>
          <div class="modal-body">
            {{confirmationMessage}}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="Confirm()" data-dismiss="modal" aria-label="Ok">Ok</button>
            <button type="button" class="btn btn-primary" id="btnCancel_EACLikelyHood" data-dismiss="modal" (click)="cancelEAC($event)" aria-label="Cancel">Cancel</button>
          </div>
        </div>
        <span tabindex="0" onfocus="document.getElementById('Close_EAC_Popup').focus();"> </span>
      </div>
    </div>
  </div>

  <div class="modal" id="EACSection" [ngStyle]="{'display': EACSectionPopUp ? 'block' : 'none', 'opacity': 1}" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog-wrap">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button id="btnClose_EACSection" type="button" class="close" data-dismiss="modal" (click)="cancelEAC($event)" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Please confirm</h4>
          </div>
          <div class="modal-body">
            {{confirmationMessage}}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="ConfirmSave(avandeEACform.valid, avandeEACform)" data-dismiss="modal" aria-label="Ok">Ok</button>
            <button type="button" class="btn btn-primary" id="btnCancel_EACSection" data-dismiss="modal" (click)="cancelEAC($event)" aria-label="Cancel">Cancel</button>
          </div>
        </div>
        <span tabindex="0" onfocus="document.getElementById('Close_Section').focus();"> </span>
      </div>
    </div>
  </div>
</form>-->


<form name="avandeEACform" #avandeEACform="ngForm" novalidate (ngSubmit)="SaveAvanade(avandeEACform.valid,avandeEACform)" id="avanade">
  <div class="app-wrapper app-index">
    <div class="app-wrap">
      <div class="c-avanade">
        <div class="app-container">
          <div class="c-myWizard__header">
            <div class="c-myWizard__header-content-left">
              <div class="c-myWizard__header-content-head">
                <a href="javascript:Void(0)" class="icon-banner-arrow" (click)="OnBackClick()" role="button" alt="Back" aria-label="Back"></a>
                <div class="-header">Avanade EAC Forecast</div>
              </div>
            </div>
          </div>
          <div class="c-plan-content">
            <div class="c-myWizard-form__input">
              <div class="c-myWizard-form__input-area">
                <div class="c-custom-select__wrapper ">
                    <label for="EacLikelihood_Info" id="EACLikelihood">EAC Likelihood</label>
                    <search-dropdown id="EacLikelihood_Info" name="EACLike" #EACLike="ngModel"
                                     [selectText]="'Select'"
                                     [options]="AvanadeEAC.EACLikelihood"
                                     [selected]="AvanadeEAC.EacLikelihoodId"
                                     [isShow]="true"
                                     [required]="true"
                                     class="form-control"
                                     [formname]="avandeEACform"
                                     [(ngModel)]="AvanadeEAC.EacLikelihoodId"
                                     [disableDropdown]="roleAccess=='V'"
                                     (callback)="ChangeEACLikelihood(AvanadeEAC.EacLikelihoodId)">

                    </search-dropdown>
                    <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage"></div>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-custom-select__wrapper">
                  <label for="RevenueRecognition_Info" id="RevenueRecognition">Revenue Recognition &nbsp;<span *ngIf="AvanadeEAC.EacLikelihoodId == 1 || AvanadeEAC.EacLikelihoodId == 2 || AvanadeEAC.EacLikelihoodId== 3" class="required">*</span></label>
                  <search-dropdown id="RevenueRecognition_Info" name="Revenue" #Revenue="ngModel"
                                   [selectText]="'Select'"
                                   [options]="AvanadeEAC.EACRevenueRecognition"
                                   [selected]="AvanadeEAC.EacRevenueRecognitionId"
                                   [isShow]="true"
                                   [formname]="avandeEACform"
                                   class="form-control"
                                   [required]="(AvanadeEAC.EacLikelihoodId == 1 || AvanadeEAC.EacLikelihoodId ==2 ||  AvanadeEAC.EacLikelihoodId ==3) && AvanadeEAC.EacRevenueRecognitionId == null"
                                   [(ngModel)]="AvanadeEAC.EacRevenueRecognitionId"
                                   [disableDropdown]="roleAccess=='V' || AvanadeEAC.EacLikelihoodId == 0 "
                                   [ngClass]="{'has-error':avandeEACform.submitted && Revenue.invalid && (AvanadeEAC.EacLikelihoodId == 1 || AvanadeEAC.EacLikelihoodId ==2 ||  AvanadeEAC.EacLikelihoodId ==3) && (AvanadeEAC.EacRevenueRecognitionId == null && AvanadeEAC.EacLikelihoodId !=4)}"
                                   [errormessage]="'Please select Revenue Recognition'">
                  </search-dropdown>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-custom-select__wrapper">
                    <label for="ForecastedEACChange_Info" id="ForecastedEACChange">Forecasted EAC Change in(Select FY &amp; QTR)</label>
                    <search-dropdown id="ForecastedEACChange_Info" name="SelectEACChange" #SelectEACChange="ngModel"
                                     [selectText]="'Select'"
                                     [options]="quarter"
                                     [isShow]="true"
                                     [selected]="AvanadeEAC.EacForecastedChange"
                                     (callback)="OnChangeQuarter(AvanadeEAC.EacForecastedChange)"
                                     class="form-control"
                                     [formname]="avandeEACform"
                                     [(ngModel)]="AvanadeEAC.EacForecastedChange"
                                     [disableDropdown]="roleAccess=='V' || isDisabled || AvanadeEAC.EacLikelihoodId == 0"
                                     >
                    </search-dropdown>
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-custom-select__wrapper">
                    <label for="MonthEACChange_Info" id="MonthEACChange">Month EAC Change to be Processed</label>
                    <search-dropdown id="MonthEACChange_Info" name="SelectMonth" #SelectMonth="ngModel"
                                     [selectText]="'Select'"
                                     [options]="months"
                                     [selected]="AvanadeEAC.EacMonthChange"
                                     [isShow]="true"
                                     [formname]="avandeEACform"
                                     class="form-control"
                                     [(ngModel)]="AvanadeEAC.EacMonthChange"
                                     [disableDropdown]="roleAccess=='V' || isDisabled || AvanadeEAC.EacLikelihoodId == 0">
                    </search-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="c-plan-content">
            <div class="c-plan-content-title">Full CCI EAC Change Amount</div>
            <div class="c-myWizard-form__input">
              <div class="c-myWizard-form__input-area">
                <div class="o-input ">
                  <label for="Likelyoutcome">Likely Outcome($000s(+/-))</label>
                  <input name="Likelyoutcome" type="text" class="o-input__input" id="Likelyoutcome"
                         [ngClass] ="(roleAccess=='V' ||  isDisabled || AvanadeEAC.EacLikelihoodId == 0) ? 'o-input__input -disabled' : 'o-input__input'"
                         aria-labelledby="LikelyOutcome"
                         maxlength="10" placeholder=""
                         [disabled]="roleAccess=='V' ||  isDisabled || AvanadeEAC.EacLikelihoodId == 0"
                         [(ngModel)]="AvanadeEAC.LikelyOutcome" autocomplete="off"
                         (keypress)="KeyPressNumeric($event, AvanadeEAC.LikelyOutcome)"
                         (paste)="AvanadeEAC.LikelyOutcome = PasteNumeric($event)" />
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="o-input">
                  <label for="Worstcase">Worst Case($000s(+/-))</label>
                  <input name="Worstcase" type="text" class="o-input__input" id="Worstcase"
                         [ngClass] ="(roleAccess=='V' ||  isDisabled || AvanadeEAC.EacLikelihoodId == 0) ? 'o-input__input -disabled' : 'o-input__input'"
                         aria-labelledby="WorstCase"
                         maxlength="10" placeholder=""
                         [disabled]="roleAccess=='V' ||  isDisabled || AvanadeEAC.EacLikelihoodId == 0"
                         [(ngModel)]="AvanadeEAC.WorstCase" autocomplete="off"
                         (keypress)="KeyPressNumeric($event, AvanadeEAC.WorstCase)"
                         (paste)="AvanadeEAC.WorstCase = PasteNumeric($event)" />
                </div>
              </div>
            </div>
          </div>
          <div class="c-plan-content">
            <div class="c-myWizard-form__input">
              <div class="c-myWizard-form__input-area">
                <div class="o-input">
                  <label for="EstimatedFullContractLifeNR">Estimated Full Contract Life NR Change($000s(+/-))</label>
                  <input name="EstimatedFullContractLifeNR" type="text" class="o-input__input" id="EstimatedFullContractLifeNR"
                         [ngClass] ="(roleAccess=='V' ||  isDisabled || AvanadeEAC.EacLikelihoodId == 0) ? 'o-input__input -disabled' : 'o-input__input'"
                         aria-labelledby="EstimatedFullContractLifeNR"
                         maxlength="10" placeholder=""
                         [disabled]="roleAccess=='V' ||  isDisabled || AvanadeEAC.EacLikelihoodId == 0"
                         [(ngModel)]="AvanadeEAC.EstimatedFullContract" autocomplete="off"
                         (keypress)="KeyPressNumeric($event, AvanadeEAC.EstimatedFullContract)"
                         (paste)="AvanadeEAC.EstimatedFullContract = PasteNumeric($event)" />
                </div>
              </div>
              <div class="c-myWizard-form__input-area">
                <div class="c-custom-select__wrapper">
                    <label for="Execution_Info">Execution / Non Execution EAC</label>
                    <search-dropdown id="Execution_Info" name="Execution" #Execution="ngModel"
                                     [selectText]="'Select'"
                                     [options]="AvanadeEAC.EACExecution"
                                     [selected]="AvanadeEAC.EacExecutionId"
                                     [isShow]="true"
                                     [formname]="avandeEACform"
                                     [(ngModel)]="AvanadeEAC.EacExecutionId"
                                     [disableDropdown]="roleAccess=='V' || isDisabled || AvanadeEAC.EacLikelihoodId == 0" class="form-control">
                    </search-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-myWizard-update__container ">
          <div class="app-container">
            <div class="c-myWizard-update__row">
              <div class="c-myWizard-update__details">
                <div class="c-myWizard-update__input">
                  <label>Last Updated On:</label>
                  <span class="-input">{{AvanadeEAC.ModifiedOn|date:'dd MMM yyyy h:mm a'}}{{AvanadeEAC.ModifiedOn == null ? "" : " (GMT)"}}</span>
                </div>
                <div class="c-myWizard-update__input">
                  <label>Last Updated By:</label>
                  <span class="-input">{{AvanadeEAC.ModifiedByUser}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-select-card">
        <div class="app-container">
          <div class="c-select-card__wrapper">
            <div class="c-select-card__left">
              <div class="c-select-card__text">Changes made will be lost unless confirmed. Please click 'Save' button to confirm.</div>
            </div>
            <div class="c-select-card__right">
              <button class="o-secondary-button" type="button" id="btnCancel_Main" [disabled]="roleAccess=='V'" (click)="cancelEAC($event);" role="button" [ngClass]="{'-disabled': roleAccess=='V' }" >Reset</button>
              <button class="o-primary-button" type="submit" value="submit" [disabled]="roleAccess=='V'" aria-label="Save" [ngClass]="{'-disabled': roleAccess=='V' }" >Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="c-select-card__placeholder"></div>
    </div>
  </div>
  <div id="EacLikeLiHood" class="c-modal" style="visibility: visible;" [ngStyle]="{'display': EacLikeLiHoodPopUp ? 'block' : 'none', 'opacity': 1}" data-backdrop="static" data-keyboard="false" aria-modal="true" role="dialog" aria-labelledby="modalDialogTitle">
    <div class="c-modal__overlay -dismiss-modal"></div>
    <div class="c-modal__container -size-medium">
      <div class="c-modal__content">
        <div class="c-modal__header">
          <h6 id="modalDialogTitle">Please confirm</h6>
          <button type="button" id="btnClose_EACLikelyHood" class="c-modal__header-close -dismiss-modal" (click)="cancelEAC($event)" aria-label="Close" alt="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="c-modal__body">
          <p class="c-modal__text">{{confirmationMessage}}</p>
        </div>
        <div class="c-modal__footer">
          <div class="c-modal__footer-btnGroup">
            <button type="button" class="o-primary-button  -dismiss-modal" (click)="Confirm()" data-dismiss="modal" aria-label="Ok">Ok</button>
            <button type="button" class="o-secondary-button  -dismiss-modal" id="btnCancel_EACLikelyHood" data-dismiss="modal" (click)="cancelEAC($event)" aria-label="Cancel">Cancel</button>
          </div>
        </div>
      </div>
      <span tabindex="0" onfocus="document.getElementById('Close_Section').focus();"> </span>
    </div>
  </div>
  <div id="EACSection" class="c-modal" style="visibility: visible;" [ngStyle]="{'display': EACSectionPopUp ? 'block' : 'none', 'opacity': 1}" data-backdrop="static" data-keyboard="false" aria-modal="true" role="dialog" aria-labelledby="modalDialogTitle">
    <div class="c-modal__overlay -dismiss-modal"></div>
    <div class="c-modal__container -size-medium">
      <div class="c-modal__content">
        <div class="c-modal__header">
          <h6 id="modalDialogTitle">Please confirm</h6>
          <button type="button" id="btnClose_EACSection" class="c-modal__header-close -dismiss-modal" (click)="cancelEAC($event)" aria-label="Close" alt="Close" ><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="c-modal__body">
          <p class="c-modal__text">{{confirmationMessage}}</p>
        </div>
        <div class="c-modal__footer">
          <div class="c-modal__footer-btnGroup">
            <button type="button" class="o-primary-button  -dismiss-modal" (click)="ConfirmSave(avandeEACform.valid, avandeEACform)" data-dismiss="modal" aria-label="Ok">Ok</button>
            <button type="button" class="o-secondary-button  -dismiss-modal" id="btnCancel_EACSection" data-dismiss="modal" (click)="cancelEAC($event)" aria-label="Cancel">Cancel</button>
          </div>
        </div>
      </div>
      <span tabindex="0" onfocus="document.getElementById('Close_Section').focus();"> </span>
    </div>
  </div>

</form>
