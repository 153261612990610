<form name="matrixstructureform" #matrixstructureform="ngForm" novalidate>
  <div class="c-myWizard-container c-matrix-structure">
    <div class="c-myWizard__header">
      <div class="c-myWizard__header-content-left">
        <div class="c-myWizard__header-content-head">
          <a href="javascript:;" class="icon-banner-arrow" role="button" (click)="OnBackClick()" alt="Back"
             aria-label="Back"></a>
          <div class="-header">Matrix Structure</div>
        </div>
        <!--<p class="c-myWizard-text">Complete the preliminary mandatory step to setup your delivery profile, prior to setting up integration & using the platform.</p>-->
      </div>
      <div class="c-myWizard__header-content-right">

        <div class="c-header-data">
          <div class="c-dropdown -right -static c-header-dropdown">
            <!--<a href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" aria-haspopup="true" title="Download" class="-dropdown-icon"><img src="../assets/toolkit/images/key-contacts/download.svg" alt="Download"></a>
            <div class="c-dropdown-menu">
              <div class="c-dropdown-body">
                <a href="javascript:;" class="c-dropdown-item" role="button" title="Download Delivery Profile Guide">Download Delivery Profile Guide</a>
              </div>
            </div>-->
            <a href="javascript:;" role="button" data-myw-title="User Guide" data-myw-toggle="tooltip" data-myw-placement="bottom" (click)="DisplayMatrixUserGuide()" class="-dropdown-icon"><img src="../assets/toolkit/images/user-guide-stories/user-guide.svg" alt="User Guide" /></a>
          </div>
        </div>
      </div>
    </div>


    <div class="c-myWizard__title customMatrixClass">
      <div class="c-myWizard__title-text">{{EndToEndType == 'O' || EndToEndType == 'C' ?  EndToEndRefCode + ':' : ''}}  <span>{{EndToEndName}}</span></div>
      <div class="c-legends">
        <div class="c-legends-item">
          <i style="background-color: #7500C0;"></i>
          <span>Team</span>
        </div>
        <div class="c-legends-item">
          <i style="background-color: #E3B7FF;"></i>
          <span>Team Group</span>
        </div>
      </div>
    </div>

    <div class="c-team-matrix-table -customScroll">
     

        <div class="c-team-matrix-table-content">
          <div class="c-team-matrix-table__left">
            <div class="c-matrix-list-col">
              <div class="c-list_content-header">&nbsp;</div>

              <ul>

                <ng-container *ngIf="cmsHierarchy && cmsHierarchy.WorkStreamStructure.length>0">

                  <li class="c-list_content" *ngFor="let ws0 of cmsHierarchy.WorkStreamStructure; trackBy : trackByWorkstreamId;">
                    <div class="c-list" [ngClass]="{'cms-tms-item-selected' : ws0.IsSelected}">

                      <div class="c-list-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!='')">
                        <a href="javascript:;" id="MatrixWS{{ws0.WorkstreamId}}" role="button" data-flyoutid="matrix-flyout" [attr.aria-label]="ws0.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws0)"><span>{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}},&nbsp;<span class="small">{{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}}</span></span></a>
                      </div>
                      <div class="c-list-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName==null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate=='')">
                        <a href="javascript:;" id="MatrixWS{{ws0.WorkstreamId}}" role="button" data-flyoutid="matrix-flyout" [attr.aria-label]="ws0.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws0)"><span>{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}</span></a>
                      </div>
                      <div class="c-list-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate!=null&&ws0.EndDate!=null&&ws0.StartDate!=''&&ws0.EndDate!='')">
                        <a href="javascript:;" id="MatrixWS{{ws0.WorkstreamId}}" role="button" data-flyoutid="matrix-flyout" [attr.aria-label]="ws0.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws0)" [attr.data-myw-title]="(ws0.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws0.WorkStreamName : ws0.WorkStreamName) +' (' + (ws0.StartDate|date:'dd MMM yyyy') + ' to ' &nbsp;+(ws0.EndDate|date:'dd MMM yyyy') + ')'" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws0.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="Workstream Type-{{ws0.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span> {{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws0.StartDate|date:'dd MMM yyyy'}} to {{ws0.EndDate|date:'dd MMM yyyy'}})</span></span></a>
                      </div>
                      <div class=" c-list-text" [ngClass]="{'closed-workstream' : ws0.WorkstreamStatusName == 'Closed'}" *ngIf="(ws0.WorkstreamTypeName!=null)&&(ws0.StartDate==null||ws0.EndDate==null||ws0.StartDate==''||ws0.EndDate=='')">
                        <a href="javascript:;" id="MatrixWS{{ws0.WorkstreamId}}" role="button" data-flyoutid="matrix-flyout" [attr.aria-label]="ws0.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws0)"  [attr.data-myw-title]="(ws0.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws0.WorkStreamName : ws0.WorkStreamName)" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws0.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws0.WorkstreamTypeId)" alt="Workstream Type-{{ws0.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws0.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws0.WorkStreamName : ws0.WorkStreamName}}&nbsp;</span></a>
                      </div>

                    </div>
                  </li>
                </ng-container>
                <ng-container *ngIf="cmsHierarchy && cmsHierarchy.CMSLevelOne.length>0">
                  <li class="c-list_content" *ngFor="let cms1 of cmsHierarchy.CMSLevelOne; trackBy : trackByCMSId;">
                    <div class="c-list c-list-child " [ngClass]="{'cms-tms-item-selected' : cms1.IsSelected}" data-type="CMS">

                      <div class="c-list-text">
                        <a href="javascript:;" class="icon-arrow-down -expand" *ngIf="cms1.CMSLevelTwo.length > 0 || cms1.WorkStreamStructure.length > 0" aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                        <a href="javascript:;" [attr.aria-label]="cms1.CMSName + '(opens a popup)'" id="MatrixCMS{{cms1.CMSId}}" (click)="EditCMSHierarchy(1,cms1)" [ngClass]="{'closed-cms' : cms1.CmsStatusId == 2}">{{cms1.CmsStatusId == 2 ? "[CLOSED]" + cms1.CMSName : cms1.CMSName}}</a>
                      </div>
                      <!--Checkbox comes here-->

                    </div>
                    <ul class="open">
                      <li class="c-list_content cms-tms-item-leaf cms-tms-item-no-nesting" *ngFor="let ws1 of cms1.WorkStreamStructure; trackBy : trackByWorkstreamId;">
                        <div class="c-list" [ngClass]="{'cms-tms-item-selected' : ws1.IsSelected}">

                          <div class="c-list-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!='')">
                            <a href="javascript:;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws1.WorkstreamId}}" [attr.aria-label]="ws1.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws1)"><span>{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}},&nbsp;<span class="small">{{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}}</span></span></a>
                          </div>
                          <div class="c-list-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName==null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate=='')">
                            <a href="javascript:;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws1.WorkstreamId}}" [attr.aria-label]="ws1.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws1)"><span>{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}</span></a>
                          </div>
                          <div class="c-list-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate!=null&&ws1.EndDate!=null&&ws1.StartDate!=''&&ws1.EndDate!='')">
                            <a href="javascript:;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws1.WorkstreamId}}" [attr.aria-label]="ws1.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws1)" [attr.data-myw-title]="(ws1.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws1.WorkStreamName : ws1.WorkStreamName) + ' (' + (ws1.StartDate|date:'dd MMM yyyy') &nbsp;+ ' to ' &nbsp;+(ws1.EndDate|date:'dd MMM yyyy') + ')'" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws1.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="Workstream Type-{{ws1.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws1.StartDate|date:'dd MMM yyyy'}} to {{ws1.EndDate|date:'dd MMM yyyy'}})</span></span></a>
                          </div>
                          <div class="c-list-text" [ngClass]="{'closed-workstream' : ws1.WorkstreamStatusName == 'Closed'}" *ngIf="(ws1.WorkstreamTypeName!=null)&&(ws1.StartDate==null||ws1.EndDate==null||ws1.StartDate==''||ws1.EndDate=='')">
                            <a href="javascript:;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws1.WorkstreamId}}" [attr.aria-label]="ws1.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws1)" [attr.data-myw-title]="(ws1.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws1.WorkStreamName : ws1.WorkStreamName)" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws1.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws1.WorkstreamTypeId)" alt="Workstream Type-{{ws1.WorkstreamTypeName}}" /><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span><span>{{ws1.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws1.WorkStreamName : ws1.WorkStreamName}}&nbsp;</span></a>
                          </div>

                        </div>
                      </li>
                      <li class="c-list_content" *ngFor="let cms2 of cms1.CMSLevelTwo; trackBy : trackByCMSId;">
                        <div class="c-list c-list-child" [ngClass]="{'cms-tms-item-selected' : cms2.IsSelected}" data-type="CMS">

                          <div class="c-list-text">
                            <a href="javascript:;" class="icon-arrow-down -expand" *ngIf="cms2.CMSLevelThree.length > 0 || cms2.WorkStreamStructure.length > 0" aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                            <a href="javascript:;" [attr.aria-label]="cms2.CMSName + '(opens a popup)'" id="MatrixCMS{{cms2.CMSId}}" (click)="EditCMSHierarchy(1,cms2)" [ngClass]="{'closed-cms' : cms2.CmsStatusId == 2}">{{cms2.CmsStatusId == 2 ? "[CLOSED]" + cms2.CMSName : cms2.CMSName}}</a>
                          </div>
                          <!--<span *ngIf="cms2.CMSLevelThree.length > 0 || cms2.WorkStreamStructure.length > 0" class="icon-arrow-down -expand"></span>
                  <a href="javascript:;" [attr.aria-label]="cms2.CMSName + '(opens a popup)'" id="MatrixCMS{{cms2.CMSId}}" (click)="EditCMSHierarchy(1,cms2)" class="cms-tms-item-text link-color" [ngClass]="{'closed-cms' : cms2.CmsStatusId == 2}">{{cms2.CmsStatusId == 2 ? "[CLOSED]" + cms2.CMSName : cms2.CMSName}}</a>-->

                        </div>
                        <ul class="open">
                          <li *ngFor="let ws2 of cms2.WorkStreamStructure; trackBy : trackByWorkstreamId;">
                            <div class="c-list" [ngClass]="{'cms-tms-item-selected' : ws2.IsSelected}">
                              <div class="c-list-text">
                                <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws2.WorkstreamId}}" [attr.aria-label]="ws2.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws2)" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!='')">{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}},&nbsp;<span class="small">{{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}}</span></a>

                                <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws2.WorkstreamId}}" [attr.aria-label]="ws2.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws2)" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName==null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='')">{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}</a>

                                <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws2.WorkstreamId}}" [attr.aria-label]="ws2.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws2)" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate!=null&&ws2.EndDate!=null&&ws2.StartDate!=''&&ws2.EndDate!='')" [attr.data-myw-title]="(ws2.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws2.WorkStreamName : ws2.WorkStreamName) + ' (' + (ws2.StartDate|date:'dd MMM yyyy') + ' to ' +(ws2.EndDate|date:'dd MMM yyyy') + ')'" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws2.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws2.WorkstreamTypeId)" alt="Workstream Type-{{ws2.WorkstreamTypeName}}" />&nbsp;-&nbsp;{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws2.StartDate|date:'dd MMM yyyy'}} to {{ws2.EndDate|date:'dd MMM yyyy'}})</span></a>

                                <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws2.WorkstreamId}}" [attr.aria-label]="ws2.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws2)" [ngClass]="{'closed-workstream' : ws2.WorkstreamStatusName == 'Closed'}" *ngIf="(ws2.WorkstreamTypeName!=null)&&(ws2.StartDate==null||ws2.EndDate==null||ws2.StartDate==''||ws2.EndDate=='')" [attr.data-myw-title]="(ws2.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws2.WorkStreamName : ws2.WorkStreamName)" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws2.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws2.WorkstreamTypeId)" alt="Workstream Type-{{ws2.WorkstreamTypeName}}" />&nbsp;-&nbsp;{{ws2.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws2.WorkStreamName : ws2.WorkStreamName}}&nbsp;</a>
                              </div>

                            </div>
                          </li>
                          <li class="c-list_content" *ngFor="let cms3 of cms2.CMSLevelThree; trackBy : trackByCMSId;">
                            <div class="c-list c-list-child" [ngClass]="{'cms-tms-item-selected' : cms3.IsSelected}" data-type="CMS">

                              <div class="c-list-text">
                                <a href="javascript:;" class="icon-arrow-down -expand" *ngIf="cms3.CMSLevelFour.length > 0 || cms3.WorkStreamStructure.length > 0" aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                                <a href="javascript:;" [attr.aria-label]="cms3.CMSName + '(opens a popup)'" id="MatrixCMS{{cms3.CMSId}}" (click)="EditCMSHierarchy(1,cms3)" [ngClass]="{'closed-cms' : cms3.CmsStatusId == 2}">{{cms3.CmsStatusId == 2 ? "[CLOSED]" + cms3.CMSName : cms3.CMSName}}</a>
                              </div>
                              <!--<span *ngIf="cms3.CMSLevelFour.length > 0 || cms3.WorkStreamStructure.length > 0" class="glyphicon glyphicon-triangle-right"></span>
                      <a href="javascript:void(0);" [attr.aria-label]="cms3.CMSName + '(opens a popup)'" id="MatrixCMS{{cms3.CMSId}}" (click)="EditCMSHierarchy(1,cms3)" class="cms-tms-item-text link-color" [ngClass]="{'closed-cms' : cms3.CmsStatusId == 2}">{{cms3.CmsStatusId == 2 ? "[CLOSED]" + cms3.CMSName : cms3.CMSName}}</a>-->

                            </div>
                            <ul class="open">
                              <li *ngFor="let ws3 of cms3.WorkStreamStructure; trackBy : trackByWorkstreamId;">

                                <div class="c-list" [ngClass]="{'cms-tms-item-selected' : ws3.IsSelected}">
                                  <div class="c-list-text">
                                    <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws3.WorkstreamId}}" [attr.aria-label]="ws3.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws3)" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!='')">{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}},&nbsp;<span class="small">{{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}}</span></a>

                                    <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws3.WorkstreamId}}" [attr.aria-label]="ws3.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws3)" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName==null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='')">{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}</a>

                                    <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws3.WorkstreamId}}" [attr.aria-label]="ws3.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws3)" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate!=null&&ws3.EndDate!=null&&ws3.StartDate!=''&&ws3.EndDate!='')" [attr.data-myw-title]="(ws3.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws3.WorkStreamName : ws3.WorkStreamName) + ' (' + (ws3.StartDate|date:'dd MMM yyyy') + ' to ' +(ws3.EndDate|date:'dd MMM yyyy') + ')'" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws3.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws3.WorkstreamTypeId)" alt="Workstream Type-{{ws3.WorkstreamTypeName}}" />&nbsp;-&nbsp;{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws3.StartDate|date:'dd MMM yyyy'}} to {{ws3.EndDate|date:'dd MMM yyyy'}})</span></a>

                                    <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws3.WorkstreamId}}" [attr.aria-label]="ws3.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws3)" [ngClass]="{'closed-workstream' : ws3.WorkstreamStatusName == 'Closed'}" *ngIf="(ws3.WorkstreamTypeName!=null)&&(ws3.StartDate==null||ws3.EndDate==null||ws3.StartDate==''||ws3.EndDate=='')" [attr.data-myw-title]="(ws3.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws3.WorkStreamName : ws3.WorkStreamName)" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws3.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws3.WorkstreamTypeId)" alt="Workstream Type-{{ws3.WorkstreamTypeName}}" />&nbsp;-&nbsp;{{ws3.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws3.WorkStreamName : ws3.WorkStreamName}}&nbsp;</a>
                                  </div>

                                </div>
                              </li>
                              <li class="c-list_content" *ngFor="let cms4 of cms3.CMSLevelFour; trackBy : trackByCMSId;">
                                <div class="c-list c-list-child" [ngClass]="{'cms-tms-item-selected' : cms4.IsSelected}" data-type="CMS">

                                  <div class="c-list-text">
                                    <a href="javascript:;" class="icon-arrow-down -expand" *ngIf="cms4.CMSLevelFive.length > 0 || cms4.WorkStreamStructure.length > 0" aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                                    <a href="javascript:;" [attr.aria-label]="cms4.CMSName + '(opens a popup)'" id="MatrixCMS{{cms4.CMSId}}" (click)="EditCMSHierarchy(1,cms4)" [ngClass]="{'closed-cms' : cms4.CmsStatusId == 2}">{{cms4.CmsStatusId == 2 ? "[CLOSED]" + cms4.CMSName : cms4.CMSName}}</a>
                                  </div>
                                  <!--<span *ngIf="cms4.CMSLevelFive.length > 0 || cms4.WorkStreamStructure.length > 0" class="glyphicon glyphicon-triangle-right"></span>
                          <a href="javascript:void(0);" [attr.aria-label]="cms4.CMSName + '(opens a popup)'" id="MatrixCMS{{cms4.CMSId}}" (click)="EditCMSHierarchy(1,cms4)" class="cms-tms-item-text link-color" [ngClass]="{'closed-cms' : cms4.CmsStatusId == 2}">{{cms4.CmsStatusId == 2 ? "[CLOSED]" + cms4.CMSName : cms4.CMSName}}</a>-->

                                </div>
                                <ul class="open">

                                  <li *ngFor="let ws4 of cms4.WorkStreamStructure; trackBy : trackByWorkstreamId;">

                                    <div class="c-list" [ngClass]="{'cms-tms-item-selected' : ws4.IsSelected}">
                                      <div class="c-list-text">
                                        <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws4.WorkstreamId}}" [attr.aria-label]="ws4.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws4)" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!='')">{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}},&nbsp;<span class="small">{{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}}</span></a>

                                        <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws4.WorkstreamId}}" [attr.aria-label]="ws4.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws4)" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName==null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='')">{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}</a>

                                        <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws4.WorkstreamId}}" [attr.aria-label]="ws4.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws4)" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate!=null&&ws4.EndDate!=null&&ws4.StartDate!=''&&ws4.EndDate!='')" [attr.data-myw-title]="(ws4.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws4.WorkStreamName : ws4.WorkStreamName) + ' (' + (ws4.StartDate|date:'dd MMM yyyy') + ' to '+(ws4.EndDate|date:'dd MMM yyyy') + ')'" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws4.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom"> [src]="workStreamIconSelection(ws4.WorkstreamTypeId)" alt="Workstream Type-{{ws4.WorkstreamTypeName}}" />&nbsp;-&nbsp;{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws4.StartDate|date:'dd MMM yyyy'}} to {{ws4.EndDate|date:'dd MMM yyyy'}})</span></a>

                                        <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws4.WorkstreamId}}" [attr.aria-label]="ws4.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws4)" [ngClass]="{'closed-workstream' : ws4.WorkstreamStatusName == 'Closed'}" *ngIf="(ws4.WorkstreamTypeName!=null)&&(ws4.StartDate==null||ws4.EndDate==null||ws4.StartDate==''||ws4.EndDate=='')" [attr.data-myw-title]="(ws4.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws4.WorkStreamName : ws4.WorkStreamName)" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws4.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom"> [src]="workStreamIconSelection(ws4.WorkstreamTypeId)" alt="Workstream Type-{{ws4.WorkstreamTypeName}}" />&nbsp;-&nbsp;{{ws4.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws4.WorkStreamName : ws4.WorkStreamName}}&nbsp;</a>
                                      </div>

                                    </div>
                                  </li>
                                  <li class="c-list_content" *ngFor="let cms5 of cms4.CMSLevelFive; trackBy : trackByCMSId;">
                                    <div class="c-list c-list-child" [ngClass]="{'cms-tms-item-selected' : cms5.IsSelected}" data-type="CMS">

                                      <div class="c-list-text">
                                        <a href="javascript:;" class="icon-arrow-down -expand" *ngIf="cms5.WorkStreamStructure.length > 0" aria-lable="Expand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom"></a>
                                        <a href="javascript:;" [attr.aria-label]="cms5.CMSName + '(opens a popup)'" id="MatrixCMS{{cms5.CMSId}}" (click)="EditCMSHierarchy(1,cms5)" [ngClass]="{'closed-cms' : cms5.CmsStatusId == 2}">{{cms5.CmsStatusId == 2 ? "[CLOSED]" + cms5.CMSName : cms5.CMSName}}</a>
                                      </div>
                                      <!--<span *ngIf="cms5.WorkStreamStructure.length > 0" class="glyphicon glyphicon-triangle-right"></span>
                              <a href="javascript:void(0);" [attr.aria-label]="cms5.CMSName + '(opens a popup)'" id="MatrixCMS{{cms5.CMSId}}" (click)="EditCMSHierarchy(1,cms5)" class="cms-tms-item-text link-color" [ngClass]="{'closed-cms' : cms5.CmsStatusId == 2}">{{cms5.CmsStatusId == 2 ? "[CLOSED]" + cms5.CMSName : cms5.CMSName}}</a>-->

                                    </div>
                                    <ul class="open">
                                      <li *ngFor="let ws5 of cms5.WorkStreamStructure; trackBy : trackByWorkstreamId;">

                                        <div class="c-list" [ngClass]="{'cms-tms-item-selected' : ws5.IsSelected}">
                                          <div class="c-list-text">
                                            <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws5.WorkstreamId}}" [attr.aria-label]="ws5.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws5)" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!='')">{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}},&nbsp;<span class="small">{{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}}</span></a>
                                            <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws5.WorkstreamId}}" [attr.aria-label]="ws5.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws5)" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName==null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='')">{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}</a>

                                            <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws5.WorkstreamId}}" [attr.aria-label]="ws5.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws5)" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate!=null&&ws5.EndDate!=null&&ws5.StartDate!=''&&ws5.EndDate!='')" [attr.data-myw-title]="(ws5.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws5.WorkStreamName : ws5.WorkStreamName) + ' (' + (ws5.StartDate|date:'dd MMM yyyy') + ' to ' +(ws5.EndDate|date:'dd MMM yyyy') + ')'" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws5.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws5.WorkstreamTypeId)" alt="Workstream Type-{{ws5.WorkstreamTypeName}}" />&nbsp;-&nbsp;{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;&nbsp;<span class="small">({{ws5.StartDate|date:'dd MMM yyyy'}} to {{ws5.EndDate|date:'dd MMM yyyy'}})</span></a>

                                            <a href="javascript:void;" role="button" data-flyoutid="edit-cms" id="MatrixWS{{ws5.WorkstreamId}}" [attr.aria-label]="ws5.WorkStreamName + '(opens a popup)'" (click)="EditCMSHierarchy(2,ws5)" [ngClass]="{'closed-workstream' : ws5.WorkstreamStatusName == 'Closed'}" *ngIf="(ws5.WorkstreamTypeName!=null)&&(ws5.StartDate==null||ws5.EndDate==null||ws5.StartDate==''||ws5.EndDate=='')" [attr.data-myw-title]="(ws5.WorkstreamStatusName == 'Closed' ? '[CLOSED]' + ws5.WorkStreamName : ws5.WorkStreamName)" data-myw-toggle="tooltip" data-myw-placement="bottom" data-myw-custclass="-customTooltip"><img class="workstream-img" [attr.data-myw-title]="ws5.WorkstreamTypeName" data-myw-toggle="tooltip" data-myw-placement="bottom" [src]="workStreamIconSelection(ws5.WorkstreamTypeId)" alt="Workstream Type-{{ws5.WorkstreamTypeName}}" />&nbsp;-&nbsp;{{ws5.WorkstreamStatusName == 'Closed' ? "[CLOSED]" + ws5.WorkStreamName : ws5.WorkStreamName}}&nbsp;</a>
                                          </div>

                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ng-container>

              </ul>
            </div>
          </div>

          <div class="c-team-matrix-table__right -customScroll">
            <div class="c-matrix-list-col" *ngIf="tmsHierarchy != undefined">
              <ul class="c-matrix-col-list">
                <ng-container *ngIf="tmsHierarchy && tmsHierarchy.TMS.length>0">
                  <li class="c-team-matrix-table-list -open" *ngFor="let tms of tmsHierarchy.TMS; trackBy : trackByTMSId;">
                    <div>
                      <div class="c-matrix-list-col-item">
                        <div class="c-list_content-header">
                          <div class="c-list-team-data">
                            <a href="javascript:;" role="button" (click)="EditTMS(tms,null)" class="c-legends-item" data-flyoutid="edit-details"><i style="background-color: #7500C0;"></i><span>{{tms.TMSName}}</span></a>
                            <!--<a href="javascript:;" role="button" class="-teamExpand" title="Expand" aria-label="Expand"><span>&nbsp;</span></a>-->
                          </div>
                        </div>
                        <ul class="c-list_content-body">
                          <li *ngFor="let workstream of tms.RelationWorkStream; trackBy: trackByWorkstreamId;" tabindex="{{ workstream.RelatedClass !== '' ? 0 : '' }}" [attr.aria-label]="tms.TMSName + ' is associated with ' + workstream.CMSWorkStreamName" class="c-list-team-data">
                            <div *ngIf="workstream.RelatedClass === 'icon-tick' || workstream.RelatedClass === '-partial'"
                                 [attr.data-myw-title]="workstream.RelatedClass === 'icon-tick' ? 'Fully associated' : 'Partially associated'"
                                 data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                            <div *ngIf="workstream.RelatedClass !== 'icon-tick' && workstream.RelatedClass !== '-partial'" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </li>
                </ng-container>

                <ng-container *ngIf="tmsHierarchy && tmsHierarchy.TMSGroupLevelOne.length>0">
                  <!--<ul class="c-matrix-col-list" data-step="0">-->
                  <li class="c-team-matrix-table-list -open" *ngFor="let tmsGroup1 of tmsHierarchy.TMSGroupLevelOne; trackBy : trackByTMSGroupId;">
                    <div>
                      <div class="c-matrix-list-col-item">
                        <div class="c-list_content-header">
                          <div class="c-list-team-data">
                            <a href="javascript:;" role="button" (click)="EditTMS(null,tmsGroup1)" id="MatrixGroup{{tmsGroup1.TMSGroupId}}" class="c-legends-item" [attr.aria-label]="tmsGroup1.TMSGroupName + '(opens a popup)'" data-flyoutid="edit-details" ><i style="background-color: #E3B7FF;"></i><span>{{tmsGroup1.TMSGroupName}}</span></a>
                            <a href="javascript:;" *ngIf="tmsGroup1.TMS.length > 0 || tmsGroup1.TMSGroupLevelTwo.length > 0" role="button" class="-teamExpand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Expand" ><span>&nbsp;</span></a>
                          </div>
                        </div>
                        <ul class="c-list_content-body">
                          <li *ngFor="let workstream of tmsGroup1.RelationWorkStream; trackBy : trackByWorkstreamId;" tabindex="{{workstream.RelatedClass!=''?0:''}}" [attr.aria-label]="tmsGroup1.TMSGroupName + 'is associated with' + workstream.CMSWorkStreamName" class="c-list-team-data">
                            <div *ngIf="workstream.RelatedClass === 'icon-tick' || workstream.RelatedClass === '-partial'"
                                 [attr.data-myw-title]="workstream.RelatedClass === 'icon-tick' ? 'Fully associated' : 'Partially associated'"
                                 data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                            <div *ngIf="workstream.RelatedClass !== 'icon-tick' && workstream.RelatedClass !== '-partial'" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                          </li>
                        </ul>
                      </div>
                    </div>


                    <ng-container>
                      <ul class="c-matrix-col-list" data-step="0">
                      <!--<ul class="class="c-matrix-col-list" >-->
                      <li class="c-team-matrix-table-list -open" *ngFor="let tms of tmsGroup1.TMS; trackBy : trackByTMSId;">
                        <div>
                          <div class="c-matrix-list-col-item">
                            <div class="c-list_content-header">
                              <div class="c-list-team-data">
                                <a href="javascript:;" role="button" (click)="EditTMS(tms,null)" class="c-legends-item" data-flyoutid="edit-details" ><i style="background-color: #7500C0;"></i><span>{{tms.TMSName}}</span></a>
                                <!--<a href="javascript:;" role="button" class="-teamExpand" title="Expand" aria-label="Expand"><span>&nbsp;</span></a>-->
                              </div>
                            </div>
                            <ul class="c-list_content-body">
                              <li *ngFor="let workstream of tms.RelationWorkStream; trackBy: trackByWorkstreamId;" tabindex="{{workstream.RelatedClass!=''?0:''}}" [attr.aria-label]="tms.TMSName + ' is associated with ' + workstream.CMSWorkStreamName" class="c-list-team-data">
                                <div *ngIf="workstream.RelatedClass === 'icon-tick' || workstream.RelatedClass === '-partial'"
                                     [attr.data-myw-title]="workstream.RelatedClass === 'icon-tick' ? 'Fully associated' : 'Partially associated'"
                                     data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                <div *ngIf="workstream.RelatedClass !== 'icon-tick' && workstream.RelatedClass !== '-partial'" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </li>
                      <!--</ul>-->
                      <li class="c-team-matrix-table-list -open" *ngFor="let tmsGroup2 of tmsGroup1.TMSGroupLevelTwo; trackBy : trackByTMSGroupId;">
                        <div>
                          <div class="c-matrix-list-col-item">
                            <div class="c-list_content-header">
                              <div class="c-list-team-data">
                                <a href="javascript:;" role="button" (click)="EditTMS(null,tmsGroup2)" class="c-legends-item" data-flyoutid="edit-details" ><i style="background-color: #E3B7FF;"></i><span>{{tmsGroup2.TMSGroupName}}</span></a>
                                <a href="javascript:;" *ngIf="tmsGroup2.TMS.length > 0 || tmsGroup2.TMSGroupLevelThree.length > 0" role="button" class="-teamExpand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Expand" ><span>&nbsp;</span></a>
                              </div>
                            </div>
                            <ul class="c-list_content-body">
                              <li *ngFor="let workstream of tmsGroup2.RelationWorkStream; trackBy: trackByWorkstreamId;" tabindex="{{ workstream.RelatedClass !== '' ? 0 : '' }}" [attr.aria-label]="tmsGroup2.TMSGroupName + ' is associated with ' + workstream.CMSWorkStreamName" class="c-list-team-data">
                                <div *ngIf="workstream.RelatedClass === 'icon-tick' || workstream.RelatedClass === '-partial'"
                                     [attr.data-myw-title]="workstream.RelatedClass === 'icon-tick' ? 'Fully associated' : 'Partially associated'"
                                     data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                <div *ngIf="workstream.RelatedClass !== 'icon-tick' && workstream.RelatedClass !== '-partial'" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                              </li>

                            </ul>
                          </div>
                        </div>
                        <ul class="c-matrix-col-list" data-step="1">
                          <li class="c-team-matrix-table-list -open" *ngFor="let tms of tmsGroup2.TMS; trackBy : trackByTMSId;">
                            <div>
                              <div class="c-matrix-list-col-item">
                                <div class="c-matrix-list-col-item" id="TeamA">
                                  <div class="c-list_content-header">
                                    <div class="c-list-team-data">
                                      <a href="javascript:;" role="button" (click)="EditTMS(tms,null)" class="c-legends-item" data-flyoutid="edit-details" ><i style="background-color: #7500C0;"></i><span>{{tms.TMSName}}</span></a>
                                      <!--<a href="javascript:;" role="button" class="-teamExpand" title="Expand" aria-label="Expand"><span>&nbsp;</span></a>-->
                                    </div>
                                  </div>
                                  <ul class="c-list_content-body">
                                    <li *ngFor="let workstream of tms.RelationWorkStream; trackBy: trackByWorkstreamId;" tabindex="{{ workstream.RelatedClass !== '' ? 0 : '' }}" [attr.aria-label]="tms.TMSName + ' is associated with ' + workstream.CMSWorkStreamName" class="c-list-team-data">
                                      <div *ngIf="workstream.RelatedClass === 'icon-tick' || workstream.RelatedClass === '-partial'"
                                           [attr.data-myw-title]="workstream.RelatedClass === 'icon-tick' ? 'Fully associated' : 'Partially associated'"
                                           data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                      <div *ngIf="workstream.RelatedClass !== 'icon-tick' && workstream.RelatedClass !== '-partial'" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                    </li>

                                  </ul>
                                </div>
                              </div>
                            </div>
                            </li>
                            <!--<ul class="c-matrix-col-list">-->
                          <li class="c-team-matrix-table-list -open" *ngFor="let tmsGroup3 of tmsGroup2.TMSGroupLevelThree; trackBy : trackByTMSGroupId;">
                            <div>
                              <div class="c-matrix-list-col-item">
                                <div class="c-list_content-header">
                                  <div class="c-list-team-data">
                                    <a href="javascript:;" role="button" (click)="EditTMS(null,tmsGroup3)" id="MatrixGroup{{tmsGroup3.TMSGroupId}}" class="c-legends-item" [attr.aria-label]="tmsGroup3.TMSGroupName + '(opens a popup)'" data-flyoutid="edit-details" ><i style="background-color: #E3B7FF;"></i><span>{{tmsGroup3.TMSGroupName}}</span></a>
                                    <a href="javascript:;" *ngIf="tmsGroup3.TMS.length > 0 || tmsGroup3.TMSGroupLevelFour.length > 0 " role="button" class="-teamExpand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Expand" ><span>&nbsp;</span></a>
                                  </div>
                                </div>
                                <ul class="c-list_content-body">
                                  <li *ngFor="let workstream of tmsGroup3.RelationWorkStream; trackBy : trackByWorkstreamId;" tabindex="{{workstream.RelatedClass!=''?0:''}}" [attr.aria-label]="tmsGroup3.TMSGroupName + 'is associated with' + workstream.CMSWorkStreamName" class="c-list-team-data">
                                    <div *ngIf="workstream.RelatedClass === 'icon-tick' || workstream.RelatedClass === '-partial'"
                                         [attr.data-myw-title]="workstream.RelatedClass === 'icon-tick' ? 'Fully associated' : 'Partially associated'"
                                         data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                    <div *ngIf="workstream.RelatedClass !== 'icon-tick' && workstream.RelatedClass !== '-partial'" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <!--</li>-->

                            <ul class="c-matrix-col-list" data-step="2">
                              <li class="c-team-matrix-table-list -open" *ngFor="let tms of tmsGroup3.TMS; trackBy : trackByTMSId;">
                                <div>
                                  <div class="c-matrix-list-col-item">
                                    <div class="c-matrix-list-col-item">
                                      <div class="c-list_content-header">
                                        <div class="c-list-team-data">
                                          <a href="javascript:;" role="button" (click)="EditTMS(tms,null)" class="c-legends-item" data-flyoutid="edit-details" ><i style="background-color: #7500C0;"></i><span>{{tms.TMSName}}</span></a>
                                          <!--<a href="javascript:;" role="button" class="-teamExpand" title="Expand" aria-label="Expand"><span>&nbsp;</span></a>-->
                                        </div>
                                      </div>
                                      <ul class="c-list_content-body">
                                        <li *ngFor="let workstream of tms.RelationWorkStream; trackBy: trackByWorkstreamId;" tabindex="{{ workstream.RelatedClass != '' ? 0 : '' }}" [attr.aria-label]="tms.TMSName + ' is associated with ' + workstream.CMSWorkStreamName" class="c-list-team-data">
                                          <div *ngIf="workstream.RelatedClass === 'icon-tick' || workstream.RelatedClass === '-partial'"
                                               [attr.data-myw-title]="workstream.RelatedClass === 'icon-tick' ? 'Fully associated' : 'Partially associated'"
                                               data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                          <div *ngIf="workstream.RelatedClass !== 'icon-tick' && workstream.RelatedClass !== '-partial'" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                        </li>

                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <li class="c-team-matrix-table-list -open" *ngFor="let tmsGroup4 of tmsGroup3.TMSGroupLevelFour; trackBy : trackByTMSGroupId;">
                                <div>
                                  <div class="c-matrix-list-col-item">
                                    <div class="c-list_content-header">
                                      <div class="c-list-team-data">
                                        <a href="javascript:;" role="button" (click)="EditTMS(null,tmsGroup4)" id="MatrixGroup{{tmsGroup4.TMSGroupId}}" class="c-legends-item" [attr.aria-label]="tmsGroup4.TMSGroupName + '(opens a popup)'" data-flyoutid="edit-details" ><i style="background-color: #E3B7FF;"></i><span>{{tmsGroup4.TMSGroupName}}</span></a>
                                        <a href="javascript:;" *ngIf="tmsGroup4.TMS.length > 0 || tmsGroup4.TMSGroupLevelFive.length > 0" role="button" class="-teamExpand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Expand" ><span>&nbsp;</span></a>
                                      </div>
                                    </div>
                                    <ul class="c-list_content-body">
                                      <li *ngFor="let workstream of tmsGroup4.RelationWorkStream; trackBy : trackByWorkstreamId;" tabindex="{{workstream.RelatedClass!=''?0:''}}" [attr.aria-label]="tmsGroup4.TMSGroupName + 'is associated with' + workstream.CMSWorkStreamName" class="c-list-team-data">
                                        <div *ngIf="workstream.RelatedClass === 'icon-tick' || workstream.RelatedClass === '-partial'"
                                             [attr.data-myw-title]="workstream.RelatedClass === 'icon-tick' ? 'Fully associated' : 'Partially associated'"
                                             data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                        <div *ngIf="workstream.RelatedClass !== 'icon-tick' && workstream.RelatedClass !== '-partial'" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>


                                <ul class="c-matrix-col-list" data-step="3">
                                  <li class="c-team-matrix-table-list -open" *ngFor="let tms of tmsGroup4.TMS; trackBy : trackByTMSId;">
                                    <div>
                                      <div class="c-matrix-list-col-item">
                                        <div class="c-list_content-header">
                                          <div class="c-list-team-data">
                                            <a href="javascript:;" role="button" (click)="EditTMS(tms,null)" class="c-legends-item" data-flyoutid="edit-details" ><i style="background-color: #7500C0;"></i><span>{{tms.TMSName}}</span></a>
                                            <!--<a href="javascript:;" role="button" class="-teamExpand" title="Expand" aria-label="Expand"><span>&nbsp;</span></a>-->
                                          </div>
                                        </div>
                                        <ul class="c-list_content-body">
                                          <li *ngFor="let workstream of tms.RelationWorkStream; trackBy: trackByWorkstreamId;" tabindex="{{ workstream.RelatedClass != '' ? 0 : '' }}" [attr.aria-label]="tms.TMSName + ' is associated with ' + workstream.CMSWorkStreamName" class="c-list-team-data">
                                            <div *ngIf="workstream.RelatedClass === 'icon-tick' || workstream.RelatedClass === '-partial'"
                                                 [attr.data-myw-title]="workstream.RelatedClass === 'icon-tick' ? 'Fully associated' : 'Partially associated'"
                                                 data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                            <div *ngIf="workstream.RelatedClass !== 'icon-tick' && workstream.RelatedClass !== '-partial'" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                          </li>

                                        </ul>
                                      </div>
                                    </div>
                                  </li>

                                 
                                  <li class="c-team-matrix-table-list -open" *ngFor="let tmsGroup5 of tmsGroup4.TMSGroupLevelFive; trackBy : trackByTMSGroupId;">
                                    <div>
                                      <div class="c-matrix-list-col-item">
                                        <div class="c-list_content-header">
                                          <div class="c-list-team-data">
                                            <a href="javascript:;" role="button" (click)="EditTMS(null,tmsGroup5)" id="MatrixGroup{{tmsGroup5.TMSGroupId}}" class="c-legends-item" [attr.aria-label]="tmsGroup5.TMSGroupName + '(opens a popup)'" data-flyoutid="edit-details" ><i style="background-color: #E3B7FF;"></i><span>{{tmsGroup5.TMSGroupName}}</span></a>
                                            <a href="javascript:;" *ngIf="tmsGroup5.TMS.length > 0" role="button" class="-teamExpand" data-myw-title="Expand/ Collapse" data-myw-toggle="tooltip" data-myw-placement="bottom" aria-label="Expand" ><span>&nbsp;</span></a>
                                          </div>
                                        </div>
                                        <ul class="c-list_content-body">
                                          <li *ngFor="let workstream of tmsGroup5.RelationWorkStream; trackBy : trackByWorkstreamId;" tabindex="{{workstream.RelatedClass!=''?0:''}}" [attr.aria-label]="tmsGroup5.TMSGroupName + 'is associated with' + workstream.CMSWorkStreamName" class="c-list-team-data">
                                            <div *ngIf="workstream.RelatedClass === 'icon-tick' || workstream.RelatedClass === '-partial'"
                                                 [attr.data-myw-title]="workstream.RelatedClass === 'icon-tick' ? 'Fully associated' : 'Partially associated'"
                                                 data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                            <div *ngIf="workstream.RelatedClass !== 'icon-tick' && workstream.RelatedClass !== '-partial'" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                    <ul class="c-matrix-col-list" data-step="4">
                                      <li class="c-team-matrix-table-list -open" *ngFor="let tms of tmsGroup5.TMS; trackBy : trackByTMSId;">
                                        <div>
                                          <div class="c-matrix-list-col-item">
                                            <div class="c-list_content-header">
                                              <div class="c-list-team-data">
                                                <a href="javascript:;" role="button" (click)="EditTMS(tms,null)" class="c-legends-item" data-flyoutid="edit-details" ><i style="background-color: #7500C0;"></i><span>{{tms.TMSName}}</span></a>
                                                <!--<a href="javascript:;" role="button" class="-teamExpand" title="Expand" aria-label="Expand"><span>&nbsp;</span></a>-->
                                              </div>
                                            </div>
                                            <ul class="c-list_content-body">
                                              <li *ngFor="let workstream of tms.RelationWorkStream; trackBy: trackByWorkstreamId;" tabindex="{{ workstream.RelatedClass != '' ? 0 : '' }}" [attr.aria-label]="tms.TMSName + ' is associated with ' + workstream.CMSWorkStreamName" class="c-list-team-data">
                                                <div *ngIf="workstream.RelatedClass === 'icon-tick' || workstream.RelatedClass === '-partial'"
                                                     [attr.data-myw-title]="workstream.RelatedClass === 'icon-tick' ? 'Fully associated' : 'Partially associated'"
                                                     data-myw-toggle="tooltip" data-myw-placement="bottom" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                                <div *ngIf="workstream.RelatedClass !== 'icon-tick' && workstream.RelatedClass !== '-partial'" class="c-list-team-data-check {{ workstream.RelatedClass }}"></div>
                                              </li>

                                            </ul>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                  
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <!--</ul>-->

                        </ul>
                      </li>
                      </ul>
                    </ng-container>
                  </li>
                </ng-container>
              </ul>
                     

               
                  
            </div>
       
          </div>
        </div>
         

    </div>
  </div>
  <!--<div class="c-select-card -fixed">
    <div class="container">
      <div class="c-select-card__wrapper">
        <div class="c-select-card__left">
          <div class="c-select-card__text">Are you sure you want to move workstream from CMS2 to individual work stream</div>
        </div>
        <div class="c-select-card__right">
          <button class="o-secondary-button" role="button" title="Reset">Reset</button>
          <button class="o-primary-button " role="button" title="Save">Save</button>
        </div>
      </div>
    </div>
  </div>-->

  <div class="c-select-card__placeholder"></div>
</form>
