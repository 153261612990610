<div id="reviewIssues-chart">

</div>
<!--<div class="row tile-metrics-horz tile-metrics-horz-vert">
  <div class="col-xs-6">
    <div class="metrics-value">
      <strong id="ActiveIssue" name="ActiveIssue" [innerHTML]="RIMSummaryCount.ActiveIssueOverAll!=null?RIMSummaryCount.ActiveIssueOverAll:0"></strong>
    </div>
    <div class="metrics-text"><span>Active</span></div>
  </div>
  <div class="col-xs-6">
    <div class="metrics-value">
      <strong id="EscalationIssue" [innerHTML]="RIMSummaryCount.EscalationIssue!=null?RIMSummaryCount.EscalationIssue:0"></strong>
    </div>
    <div class="metrics-text"><span>Escalations</span></div>
  </div>
</div>
<div class="row tile-metrics-horz tile-metrics-horz-vert">
  <div class="col-xs-6">
    <div class="metrics-value">
      <strong id="OverdueIssue" [innerHTML]="RIMSummaryCount.OverdueIssuesOverAll!=null?RIMSummaryCount.OverdueIssuesOverAll:0"></strong>
    </div>
    <div class="metrics-text"><span>Overdue</span></div>
  </div>
  <div class="col-xs-6">
    <div class="metrics-value">
      <strong id="UpcomingIssue" [innerHTML]="RIMSummaryCount.UpcomingIssuesOverAll!=null?RIMSummaryCount.UpcomingIssuesOverAll:0"></strong>
    </div>
    <div class="metrics-text"><span>Upcoming</span></div>
  </div>
</div>-->
