<!--<div class="c-portfolio-card__body" *ngIf="NarrativeStatus">-->
  <div class="c-manage-dashboard-legends" *ngIf="NarrativeStatus">
    <div class="c-manage-dashboard-legend">
      <div class="c-manage-dashboard-legend-item">
        <i [ngStyle]="{'background-color': NarrativeStatus?.OverAllTrend }"></i>
        <span>Overall</span>
        <!--<div class="metrics-value"><img *ngIf="NarrativeStatus.OverAllTrend" [src]="NarrativeStatus?.OverAllTrend" alt="Overall" /></div>-->

      </div>
      <div class="c-manage-dashboard-legend-item">
        <i [ngStyle]="{'background-color': NarrativeStatus?.ClientTrend }"></i>
        <span>Client</span>
        <!--<div class="metrics-value"><img *ngIf="NarrativeStatus.ClientTrend" alt="Client" [src]="NarrativeStatus?.ClientTrend" /></div>-->

      </div>
    </div>
    <div class="c-manage-dashboard-legend">
      <div class="c-manage-dashboard-legend-item">
        <i [ngStyle]="{'background-color': NarrativeStatus?.DeliveryTrend }"></i>
        <span>Delivery</span>
        <!--<div class="metrics-value"><img *ngIf="NarrativeStatus.DeliveryTrend" alt="Delivery" [src]="NarrativeStatus?.DeliveryTrend" /></div>-->

      </div>
      <div class="c-manage-dashboard-legend-item">
        <i [ngStyle]="{'background-color': NarrativeStatus?.FinancialTrend }"></i>
        <span>Financials</span>
        <!--<div class="metrics-value"><img *ngIf="NarrativeStatus.FinancialTrend" alt="Financials" [src]="NarrativeStatus?.FinancialTrend" /></div>-->

      </div>
    </div>
    <div class="c-manage-dashboard-legend">
      <div class="c-manage-dashboard-legend-item">
        <i [ngStyle]="{'background-color': NarrativeStatus?.StaffingTrend }"></i>
        <span>People/ Resources</span>
        <!--<div class="metrics-value"><img *ngIf="NarrativeStatus.StaffingTrend" alt="Resources" [src]="NarrativeStatus?.StaffingTrend" /></div>-->

      </div>
      <div class="c-manage-dashboard-legend-item"  data-myw-title="Continuous Improvement / Intelligent Operations" data-myw-toggle="tooltip" data-myw-placement="bottom">
        <i [ngStyle]="{'background-color': NarrativeStatus?.ContinuousImprovementTrend }"></i>
        <span>Continuous Improvement</span>
        <!--<div class="metrics-value"><img *ngIf="NarrativeStatus.ContinuousImprovementTrend" alt="Cont Improv" [src]="NarrativeStatus?.ContinuousImprovementTrend" /></div>-->

      </div>
    </div>
  </div>
  <div class="c-manage-dashboard-metrics">
    <div class="c-manage-dashboard-metrics-text">Metrics count by RAG</div>
    <div class="c-manage-dashboard-metrics-data">
      <div class="c-manage-dashboard-metrics-item -red">{{RedCount}}</div>
      <div class="c-manage-dashboard-metrics-item -amber">{{AmberCount}}</div>
      <div class="c-manage-dashboard-metrics-item -green">{{GreenCount}}</div>
    </div>
  </div>
<!--</div>-->


<!--<div *ngIf="NarrativeStatus" class="row col-2">
  <div class="col-xs-12 col-sm-6 tile-metrics-horz-small tile-metrics-horz-narrative">
    <div class="row tile-metrics-horz">
      <div class="col-xs-12 narrativeindicator">
        <div class="metrics-text"><span title="Overall">Overall</span></div>
        <div class="metrics-value"><img *ngIf="NarrativeStatus.OverAllTrend" [src]="NarrativeStatus?.OverAllTrend" alt="Overall" /></div>

      </div>
    </div>
    <div class="row tile-metrics-horz">
      <div class="col-xs-12 narrativeindicator">
        <div class="metrics-text"><span title="Client">Client</span></div>
        <div class="metrics-value"><img *ngIf="NarrativeStatus.ClientTrend" alt="Client" [src]="NarrativeStatus?.ClientTrend" /></div>
      </div>
    </div>
    <div class="row tile-metrics-horz">
      <div class="col-xs-12 narrativeindicator">
        <div class="metrics-text"><span title="Delivery">Delivery</span></div>
        <div class="metrics-value"><img *ngIf="NarrativeStatus.DeliveryTrend" alt="Delivery" [src]="NarrativeStatus?.DeliveryTrend" /></div>
      </div>
    </div>
    <div class="row tile-metrics-horz">
      <div class="col-xs-12 narrativeindicator">
        <div class="metrics-text"><span title="Financials">Financials</span></div>
        <div class="metrics-value"><img *ngIf="NarrativeStatus.FinancialTrend" alt="Financials" [src]="NarrativeStatus?.FinancialTrend" /></div>
      </div>
    </div>
    <div class="row tile-metrics-horz">
      <div class="col-xs-12 narrativeindicator">
        <div class="metrics-text"><span class="delivery-font" title="People / Resources">Resources</span></div>
        <div class="metrics-value"><img *ngIf="NarrativeStatus.StaffingTrend" alt="Resources" [src]="NarrativeStatus?.StaffingTrend" /></div>
      </div>
    </div>
    <div class="row tile-metrics-horz">
      <div class="col-xs-12 narrativeindicator">
        <div class="metrics-text"><span title="Continuous Improvement / Intelligent Operations">Cont Impr</span></div>
        <div class="metrics-value"><img *ngIf="NarrativeStatus.ContinuousImprovementTrend" alt="Cont Improv" [src]="NarrativeStatus?.ContinuousImprovementTrend" /></div>
      </div>
    </div>
  </div>
    <div class="col-xs-12 col-sm-6">
        <p class="text-center">Metrics count by RAG</p>
        <div class="row tile-metrics tile-metrics-xs tile-metrics-narrative">
            <div class="col-xs-12">
                <div class="metrics-value"><strong>{{RedCount}}</strong></div>
                <div class="metrics-status"><img src="assets/images/Tiles/rag_red.png" alt="Red" /></div>
            </div>
            <div class="col-xs-12">
                <div class="metrics-value"><strong>{{AmberCount}}</strong></div>
                <div class="metrics-status"><img src="assets/images/Tiles/rag_amber.png" alt="Amber" /></div>
            </div>
            <div class="col-xs-12">
                <div class="metrics-value"><strong>{{GreenCount}}</strong></div>
                <div class="metrics-status"><img src="assets/images/Tiles/rag_green.png" alt="Green" /></div>
            </div>
        </div>
    </div>
</div>-->
