import { ChangeDetectorRef, Component, Output, EventEmitter } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import * as Enumerable from 'linq';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorage } from '../../../core/context/sessionstorage';

var $ = (<any>window).$;
declare var window: any;

@Component({
  templateUrl: './matrix.userguide.component.html'
})

export class MatrixUserGuideComponent {

   constructor(
     private modalService: BsModalService,
     private sessionStorage: SessionStorage,
     private ref: ChangeDetectorRef,
     private router: Router,
     private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    //if (!this.sessionStorage.endToEndUId) {
    //  this.router.navigate(['MyWizard/Back/Profile'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId } });
    //  return false;
    //}
  }

  OnBackClick() {
    this.router.navigate(['/mywizard/matrixstructure', 'E2E', this.sessionStorage.endToEndUId]);
  }
}
