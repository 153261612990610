import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
declare var window: any;
var $ = (<any>window).$;

@Component({
  templateUrl: './delivery.functions.description.component.html'
})

export class DeliveryFunctionsDescriptionComponent{
  scrollYPosition: any;
  
  constructor(private bsModalRef: BsModalRef) { }
  ngOnDestroy() {
    window.scrollTo(0, this.scrollYPosition);
  }

  closeModal() {
    this.bsModalRef.hide();
  }

}
