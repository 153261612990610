<div id="priorityModal" class="c-modal c-priority-modal" tabindex="-1" aria-labelledby="modalDialogTitle" aria-modal="true" role="dialog">
  <div class="c-modal__overlay -dismiss-modal"></div>
  <div class="c-modal__container -size-medium">
    <div class="c-modal__content">
      <div class="c-modal__header">
        <h6 id="modalDialogTitle">BCM Priority Definition</h6>
        <button type="button" class="c-modal__header-close -dismiss-modal" aria-label="Close BCM Priority Description Popup" alt="Close" (click)="closeModal()"></button>
      </div>
      <div class="c-modal__body">
        <ul>
          <li><div><span>Priority 0</span> - DANGER TO LIFE OR CRITICAL INFRASTRUCTURE (e.g. distribution of medicines, health email or hospital services, country or multi-client infrastructure or services)</div></li>
          <li><div><span>Priority 1</span> - CRITICAL TO CLIENT BUSINESS CONTINUITY (Directly impacts client critical time sensitive business functions e.g. ability to sell, manufacture, distribute, manage payroll, month end closing)</div></li>
          <li><div><span>Priority 2</span> - IMPORTANT (Directly impacts either client critical non time sensitive or client noncritical time sensitive business functions e.g. monthly financial reporting, consolidation, reporting data warehouses)</div></li>
          <li><div><span>Priority 3</span> - OTHER (Impacts client non critical non time sensitive business functions e.g. back office administration)</div></li>
        </ul>
      </div>
    </div>
  </div>
</div>


