import { Injectable } from '@angular/core';
import { SessionStorage } from '../../core/context/sessionstorage';
import { enumDeliveryAccountability } from '../../core/context/enum';
import cloneDeep from 'lodash/cloneDeep';
declare var $: any;
var $ = (<any>window).$;
declare var window: any;

@Injectable()

export class TeamStructureSharedService {

  constructor(private sessionStorage: SessionStorage) { }

  UpdateTMSGroupAndTMS(componentRef, expandedElementsList: any) {
    componentRef.tmsService.getTeamsByEndToEnd().subscribe((response) => {
     setTimeout(function () {
          window.TooltipFunc();
        }, 0);
      var elements = $('.ui-sortable li');
      elements.toArray().forEach((el: any) => {
        $(el).remove();
      });
      componentRef.DraggedItems = [];
      componentRef.draggdropcount = 0;
      componentRef.ActiveDropdownDirective = false;
      componentRef.dragging = 0;
      componentRef.IsTMSShowModifiedDetails = true;
      var existingTmsGroup = cloneDeep(componentRef.tmsHierarchy.TMSGroups);
      var existingTms = cloneDeep(componentRef.tmsHierarchy.Teams);
      componentRef.tmsHierarchy = response;
      existingTmsGroup.forEach((item: any) => {
        var tmsGroup = componentRef.tmsHierarchy.TMSGroups.filter((x: any) => x.TMSGroupId == item.TMSGroupId);
        if (tmsGroup) {
          tmsGroup.IsChildCollapsed = item.IsChildCollapsed
          tmsGroup.IsSelected = item.IsSelected
        }
      });
      existingTms.forEach((item: any) => {
        var tms = componentRef.tmsHierarchy.Teams.filter((x: any) => x.TMSId == item.TMSId);
        if (tms) {
          tms.IsChildCollapsed = item.IsChildCollapsed
          tms.IsSelected = item.IsSelected
        }
      });
      this.UpdateModel(componentRef);
      if (componentRef.tmsHierarchy.TMSGroups.length == 0 && componentRef.tmsHierarchy.Teams.length == 0) {
        componentRef.IsTMSShowModifiedDetails = false;
      }
      if (expandedElementsList.length > 0) {
        expandedElementsList.forEach(se => {
          document.querySelectorAll('li').forEach(x => {
            if (x.dataset.tmsgroupid == se.toString()) {
              var element = x.querySelector('.c-list-child');
              if (element != null) {
                element.classList.add('expand');
                $(element.nextSibling).addClass('open');
              }
            }
          })
        })
      }
      else {
        var child = document.querySelectorAll(".c-list");
        child.forEach((ele) => {
          if (ele.classList.contains('expand')) {
            ele.classList.remove('expand');
            ele.nextElementSibling.classList.remove('open');
          }
        })
      }
    });
  }

  UpdateModel(componentRef) {
    var teamHierarchy = cloneDeep(componentRef.tmsHierarchy);
    setTimeout(function () {
      window.TooltipFunc();
    }, 0);
    componentRef.tmsHierarchy = teamHierarchy;
    componentRef.tmsHierarchy.TMSGroupLevelOne = [];
    componentRef.tmsHierarchy.TMS = [];
    
    var tmsGroupLevelOne = teamHierarchy.TMSGroups.filter((x: any) => x.ParentTMSId == null);
    tmsGroupLevelOne.forEach((tmsGroup1: any, key: any) => {
      tmsGroup1.Tier = 1;
      tmsGroup1.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == tmsGroup1.TMSGroupId);
      tmsGroup1.TMS.forEach((tms: any, key: any) => {
        tms.ReportingType = this.getvalues(tms.SelectedDeliveryAccountability, tms.SelectedDeliveryCenter, tms.SelectedAvanadeGeographicUnit, tms.SelectedClientGroup, componentRef);
        tms.AgDgDu = this.getToolTip(tms.SelectedDeliveryAccountability, tms.SelectedAccountGroupId, tms.SelectedDeliveryGroupId, tms.SelectedDeliveryUnitId, componentRef);
      });
      var tmsGroupLevelTwo = teamHierarchy.TMSGroups.filter((x: any) => x.ParentTMSId == tmsGroup1.TMSGroupId);
      tmsGroupLevelTwo.forEach((tmsGroup2: any, key: any) => {
        tmsGroup2.Tier = 2;
        tmsGroup2.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == tmsGroup2.TMSGroupId);
        tmsGroup2.TMS.forEach((tms: any, key: any) => {
          tms.ReportingType = this.getvalues(tms.SelectedDeliveryAccountability, tms.SelectedDeliveryCenter, tms.SelectedAvanadeGeographicUnit, tms.SelectedClientGroup, componentRef);
          tms.AgDgDu = this.getToolTip(tms.SelectedDeliveryAccountability, tms.SelectedAccountGroupId, tms.SelectedDeliveryGroupId, tms.SelectedDeliveryUnitId, componentRef);
        });
        var tmsGroupLevelThree = teamHierarchy.TMSGroups.filter((x: any) => x.ParentTMSId == tmsGroup2.TMSGroupId);
        tmsGroupLevelThree.forEach((tmsGroup3: any, key: any) => {
          tmsGroup3.Tier = 3;
          tmsGroup3.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == tmsGroup3.TMSGroupId);
          tmsGroup3.TMS.forEach((tms: any, key: any) => {
            tms.ReportingType = this.getvalues(tms.SelectedDeliveryAccountability, tms.SelectedDeliveryCenter, tms.SelectedAvanadeGeographicUnit, tms.SelectedClientGroup, componentRef);
            tms.AgDgDu = this.getToolTip(tms.SelectedDeliveryAccountability, tms.SelectedAccountGroupId, tms.SelectedDeliveryGroupId, tms.SelectedDeliveryUnitId, componentRef);
          });
          var tmsGroupLevelFour = teamHierarchy.TMSGroups.filter((x: any) => x.ParentTMSId == tmsGroup3.TMSGroupId);
          tmsGroupLevelFour.forEach((tmsGroup4: any, key: any) => {
            tmsGroup4.Tier = 4;
            tmsGroup4.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == tmsGroup4.TMSGroupId);
            tmsGroup4.TMS.forEach((tms: any, key: any) => {
              tms.ReportingType = this.getvalues(tms.SelectedDeliveryAccountability, tms.SelectedDeliveryCenter, tms.SelectedAvanadeGeographicUnit, tms.SelectedClientGroup, componentRef);
              tms.AgDgDu = this.getToolTip(tms.SelectedDeliveryAccountability, tms.SelectedAccountGroupId, tms.SelectedDeliveryGroupId, tms.SelectedDeliveryUnitId, componentRef);
            });
            var tmsGroupLevelFive = teamHierarchy.TMSGroups.filter((x: any) => x.ParentTMSId == tmsGroup4.TMSGroupId);
            tmsGroupLevelFive.forEach((tmsGroup5: any, key: any) => {
              tmsGroup5.Tier = 5;
              tmsGroup5.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == tmsGroup5.TMSGroupId);
              tmsGroup5.TMS.forEach((tms: any, key: any) => {
                tms.ReportingType = this.getvalues(tms.SelectedDeliveryAccountability, tms.SelectedDeliveryCenter, tms.SelectedAvanadeGeographicUnit, tms.SelectedClientGroup, componentRef);
                tms.AgDgDu = this.getToolTip(tms.SelectedDeliveryAccountability, tms.SelectedAccountGroupId, tms.SelectedDeliveryGroupId, tms.SelectedDeliveryUnitId, componentRef);
              });
            });
            tmsGroup4.TMSGroupLevelFive = tmsGroupLevelFive;
          });
          tmsGroup3.TMSGroupLevelFour = tmsGroupLevelFour;
        });
        tmsGroup2.TMSGroupLevelThree = tmsGroupLevelThree;
      });
      tmsGroup1.TMSGroupLevelTwo = tmsGroupLevelTwo;
    });
    componentRef.tmsHierarchy.TMSGroupLevelOne = tmsGroupLevelOne;
    componentRef.tmsHierarchy.TMS = teamHierarchy.Teams.filter((x: any) => x.ParentTMSId == null);
    componentRef.tmsHierarchy.TMS.forEach((tms: any, key: any) => {
      tms.ReportingType = this.getvalues(tms.SelectedDeliveryAccountability, tms.SelectedDeliveryCenter, tms.SelectedAvanadeGeographicUnit, tms.SelectedClientGroup, componentRef);
      tms.AgDgDu = this.getToolTip(tms.SelectedDeliveryAccountability, tms.SelectedAccountGroupId, tms.SelectedDeliveryGroupId, tms.SelectedDeliveryUnitId, componentRef);
    });

    //var elements = $('.ui-sortable li');
    //if (elements.length > 0) {
    //  elements.toArray().forEach((el: any) => {
    //    $(el).remove();
    //  });
    //}
   // this.removeElement();
    componentRef.ref.detectChanges();
    
  }
  
  getvalues(SelectedDeliveryAccountability, SelectedDeliveryCenter, SelectedAvanadeGeographicUnit, SelectedClientGroup, componentRef) {
    if (SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureGDN) {
      var deliveryCenter = componentRef.tmsHierarchy.DeliveryCenters.filter((x: any) => x.Id == SelectedDeliveryCenter)[0];
      if (deliveryCenter)
        componentRef.ReportingType = 'Accenture ATC - ' + deliveryCenter.Name;
      else
        componentRef.ReportingType = 'Accenture ATC';
      return componentRef.ReportingType;
    }
    else if (SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureOG) {
      var ClientServiceGroup = componentRef.tmsHierarchy.ManagementClientGroups.filter((x: any) => x.Id == SelectedClientGroup)[0];
      if (ClientServiceGroup)
        componentRef.ReportingType = 'Accenture CG - ' + ClientServiceGroup.Name;
      else
        componentRef.ReportingType = 'Accenture CG';
      return componentRef.ReportingType;
    }
    else if (SelectedDeliveryAccountability == enumDeliveryAccountability.AvanadeGU) {
      var AvanadeGeographicUnit = componentRef.tmsHierarchy.AvanadeGeographicUnits.filter((x: any) => x.Id == SelectedAvanadeGeographicUnit)[0];
      if (AvanadeGeographicUnit)
        componentRef.ReportingType = 'Avanade GU - ' + AvanadeGeographicUnit.Name;
      else
        componentRef.ReportingType = 'Avanade GU';
      return componentRef.ReportingType;
    }
    else if (SelectedDeliveryAccountability == enumDeliveryAccountability.None) {
      componentRef.ReportingType = 'None';
      return componentRef.ReportingType;
    }
    else if (SelectedDeliveryAccountability == enumDeliveryAccountability.AccountSpecific) {
      componentRef.ReportingType = 'Account Specific';
      return componentRef.ReportingType;
    }
    else {
      componentRef.ReportingType = null;
      return componentRef.ReportingType;
    }
  };
  getToolTip(SelectedDeliveryAccountability, agId, dgId, duId, componentRef) {
    if (SelectedDeliveryAccountability == enumDeliveryAccountability.AccentureGDN) {
      var ag = componentRef.tmsHierarchy.AccountGroups.filter((x: any) => x.Id == agId)[0];
      var dg = componentRef.tmsHierarchy.DeliveryGroups.filter((x: any) => x.Id == dgId)[0];
      var du = componentRef.tmsHierarchy.DeliveryUnits.filter((x: any) => x.Id == duId)[0];
      if (ag && dg && du)
        return ag.Name + "," + dg.Name + "," + du.Name;
      else
        return "";
    }
    else {
      return "";
    }
  };



}
