import { Component } from '@angular/core';
import { TileService } from '../../core/services/tile.service';
import { enumArea, } from '../../core/context/enum';
import { SessionStorage } from '../../core/context/sessionstorage';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './support.component.html',
  selector: 'support',
  providers: [TileService]
})

export class SupportComponent {

  tiles: any;

  constructor(private tileService: TileService, private sessionStorage: SessionStorage, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.tileService.getTiles(enumArea.Support).subscribe((response: any) => {
      this.tiles = response;
    })
  }
  trackByTileId(index: number, item: any): any {
    return item.TileId;
  }
}

